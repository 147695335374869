import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ElementRef, Output, EventEmitter } from '@angular/core'
import { KirjanpitoImageHandlerService, TositeJaNaytettavaSivut } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { Observable } from 'rxjs'
import { NaytettavaKirjaus } from '../kirjanpito.component'

@Component({
  selector: '[app-kirjanpito-tosite-esikatselu]',
  templateUrl: './tosite-esikatselu.component.html',
  styleUrls: ['./tosite-esikatselu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTositeEsikatseluComponent {

  @Output() liitaTositeValittuunKirjaukseen: EventEmitter<TositeJaNaytettavaSivut> = new EventEmitter()

  @Input() naytettavaKirjausObservable: Observable<NaytettavaKirjaus>
  @Input() tositteet: TositeJaNaytettavaSivut[]
  @Input() isMainEnhancedOn: boolean = false
  mainDivRef: ElementRef<HTMLDivElement>

  constructor(
    private _hostElement: ElementRef<HTMLDivElement>,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService
  ) {
    this.mainDivRef = this._hostElement
  }

  syoevent(event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
  }

  liitaTositeValittuunKirjaukseenHandler(tosite: TositeJaNaytettavaSivut) {
    this.liitaTositeValittuunKirjaukseen.next(tosite)
  }

  ruksiaNapautettiinHandler(tosite: TositeJaNaytettavaSivut) {
    this._kirjanpitoImageHandlerService.lopetaEsikatselu()
  }

}
