import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { NaytettavaJatkuva } from '../tositteet/tosite-kirjaamattomat.component'
import { KirjattavaKuitti, KirjattavaRaahattuTiedosto } from 'app/_jaettu-lemonator/model/kirjanpito'

export interface TositeMuokkaaYliajosummaaDialogData {
  naytettavaJatkuva: NaytettavaJatkuva
}

@Component({
  templateUrl: './tosite.muokkaa-yliajosummaa.dialog.html'
})
export class TositeMuokkaaYliajosummaaDialog implements OnInit {

  form: UntypedFormGroup
  namename: string = 'assf' + Math.random()

  private _yliajosumma: number
  private _kirjattavaObjekti: KirjattavaKuitti | KirjattavaRaahattuTiedosto

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: TositeMuokkaaYliajosummaaDialogData,
    private _dialogRef: MatDialogRef<TositeMuokkaaYliajosummaaDialog>,
    private _formValidationService: FormValidationService
  ) {
    this._kirjattavaObjekti = this._data.naytettavaJatkuva.kuitti?.kirjattavaKuitti || this._data.naytettavaJatkuva.raahattava?.raahattava
    const currentYliajosumma = this._kirjattavaObjekti?.a || null
    this.form = new UntypedFormGroup({
      'summa': new UntypedFormControl(currentYliajosumma),
    })
    this.form.get('summa').valueChanges.subscribe(value => {
      this._yliajosumma = value
    })
  }

  ngOnInit() {

  }

  tallenna() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    if (this._yliajosumma === undefined || this._yliajosumma === null) {
      delete this._kirjattavaObjekti.a
    } else {
      this._kirjattavaObjekti.a = this._yliajosumma
    }

    this._dialogRef.close(true)

  }

}
