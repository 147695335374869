import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot } from '@angular/router'

import { TositeService } from '../service/tosite/tosite.service'

import { FirestoreTosite } from '../../_jaettu/model/tosite'
import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'

import { of as observableOf, Observable, combineLatest } from 'rxjs'
import { switchMap, take, map } from 'rxjs/operators'

import { AsiakasService } from '../service/asiakas/asiakas.service'

export interface TositeKatseleComponentData {
  tosite: FirestoreTosite
  asiakas: Asiakas
}

export interface TositeKatseleComponentExistingData {
  tosite: FirestoreTosite
  asiakas: Asiakas
}

@Injectable()
export class TositeKatseleComponentDataResolve  {

  private existingData: TositeKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private tositeService: TositeService,
    private asiakasService: AsiakasService
  ) {

  }

  asetaOlemassaolevaData(data: TositeKatseleComponentExistingData) {
    this.existingData = data
  }

  private annaAsiakasObservable(asiakasAvain: string, existingData: TositeKatseleComponentExistingData): Observable<Asiakas> {
    if (
      existingData &&
      existingData.asiakas &&
      existingData.asiakas.avain === asiakasAvain
    ) {
      return observableOf(existingData.asiakas)
    } else {
      return this.asiakasService.annaAsiakasObservable(asiakasAvain)
    }
  }

  private annaTositeObservable(asiakas: Asiakas, tositeAvain: string, existingData: TositeKatseleComponentExistingData): Observable<FirestoreTosite> {
    if (
      existingData &&
      existingData.tosite &&
      existingData.tosite.avain === tositeAvain
    ) {
      return observableOf(existingData.tosite)
    } else {
      return this.tositeService.annaKuittiObservableAsiakkaalle(asiakas.asiakasId + '', tositeAvain)
    }
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TositeKatseleComponentData> {
    const tositeAvain = route.params['tositeAvain']
    const asiakasAvain = route.params['asiakasAvain']

    const asiakasObservable = this.annaAsiakasObservable(asiakasAvain, this.existingData)
    const tositeObservable = this.annaAsiakasObservable(asiakasAvain, this.existingData).pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this.annaTositeObservable(asiakas, tositeAvain, this.existingData)
        }
      })
    )

    return combineLatest([asiakasObservable, tositeObservable]).pipe(
      take(1),
      map(results => {
        const asiakas = results[0]
        const tosite = results[1]
        if (!asiakas) {
          this.router.navigate(['asiakkaat'])
          return null
        }
        if (!tosite) {
          this.router.navigate(['asiakkaat', asiakasAvain, 'tositteet', 'selaa'])
          return null
        }
        this.asiakasService.asetaNykyinenAsiakas(asiakas)
        const arvo: TositeKatseleComponentData = { tosite: tosite, asiakas: asiakas }
        return arvo
      })
    )
  }

}
