import { FlatattuHierarkia, Kirjanpitotili, TilienSummanErityismarker, TilienSummat } from '../../model/kirjanpito'
import { HierarkiaKirjanpitotili, TilikarttaJaettuService } from '../tilikartta-jaettu.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'

export interface SumResult {
  debetSumma: number
  kreditSumma: number
}
export interface AccountAndChildrenSumsResult {
  summat: Map<string, SumResult>
}

export interface ErottavanTilinTiedot {
  tili: Kirjanpitotili
  kredit: number
  debit: number
}

export class VeroilmoitusSummatService {

  private _hierarkiat: HierarkiaKirjanpitotili[] = []
  private _tilitMap: Map<string, Kirjanpitotili> = new Map()
  private _hierarkiatMap: Map<string, Set<string>> = new Map()

  public annaTilinJaLastenNumerot(tilinumero: string): Set<string> {
    return this._hierarkiatMap.get(tilinumero)
  }

  public annaTiliRangeJaLapset(alkaen: number, paattyen: number): Set<string> {
    const tiliRange: string[] = []
    for (let i = alkaen; i < paattyen + 1; i++) {
      tiliRange.push(i + '')
    }
    return this.annaTilienJaLastenNumerot(...tiliRange)
  }

  public annaTilienJaLastenNumerot(...tilinumerot: string[]): Set<string> {
    const ret: Set<string> = new Set()
    if (tilinumerot) {
      for (const tilinumero of tilinumerot) {
        const numerotSet = this.annaTilinJaLastenNumerot(tilinumero)
        if (numerotSet) {
          for (const numero of numerotSet) {
            ret.add(numero)
          }
        }
      }
    }
    return ret
  }

  constructor(
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _currencyService: CurrencyService,
    private _tilit: Kirjanpitotili[]
  ) {
    this._hierarkiat = this._tilikarttaJaettuService.muutaTililistausHierarkiaksi(this._tilit)
    this._lisaaHierarkiaTilitMappiin(this._hierarkiat)
    for (const tili of this._tilit) {
      this._tilitMap.set(tili.numero, tili)
    }
  }

  private _lisaaHierarkiaTilitMappiin(hierarkiat: HierarkiaKirjanpitotili[]) {
    for (const hierarkia of hierarkiat) {
      const flatted = this._flattaaHierarkia([hierarkia])
      const setForThisHierarchy: Set<string> = new Set(flatted.map(fh => fh.kirjanpitotili.numero))
      this._hierarkiatMap.set(hierarkia.kirjanpitotili.numero, setForThisHierarchy)
      if (hierarkia.lapset) {
        this._lisaaHierarkiaTilitMappiin(hierarkia.lapset)
      }
    }
  }

  annaTilitJoissaOnErottavaSumma(affectedAccounts: Set<string>, arvot: TilienSummat, addAccount: (tili: string) => boolean, specialIdentifier?: TilienSummanErityismarker): ErottavanTilinTiedot[] {
    const identifier: string = specialIdentifier ? specialIdentifier : ''
    const loppud = identifier + 'd'
    const loppuk = identifier + 'k'
    const erottavatTilit: ErottavanTilinTiedot[] = []
    for (const tili of this._tilit) {
      if (!affectedAccounts.has(tili.numero)) {
        const arvoDebit = arvot['t' + tili.numero + loppud] || 0
        const arvoKredit = arvot['t' + tili.numero + loppuk] || 0
        if (arvoKredit !== arvoDebit) {
          const erottava: ErottavanTilinTiedot = {
            debit: this._currencyService.roundHalfUp(arvoDebit, 4),
            kredit: this._currencyService.roundHalfUp(arvoKredit, 4),
            tili: tili
          }
          if (addAccount(erottava.tili.numero)) {
            erottavatTilit.push(erottava)
          }
        }
      }
    }
    return erottavatTilit.sort((a, b) => a.tili.numero.localeCompare(b.tili.numero))
  }

  /**
   * TODO: THIS DOESN'T WORK AT ALL AT THE MOMENT!
   * The set, _affectedAccountsCache, contains all accunts that are _used_ in veroilmoitus.
   * We need the accounts that are present in tilikartta, but NOT in _affectedAccountsCache
   * And we need the kirjaukset ONLY for accounts that have balance !== 0.
   * We further want to find the kirjaukset that cause the balance to not be 0
   */
  // annaKirjauksetTileilleJoistaErottaminenJohtuu(): Raportointikirjaus[] {
  //   const kirjaukset: Raportointikirjaus[] = []
  //   for (const kirjaus of this._raportointikirjaukset) {
  //     if (kirjaus.r?.length) {
  //       for (const rivi of kirjaus.r) {
  //         if (this._affectedAccountsCache.has(Number(rivi.t))) {
  //           kirjaukset.push(kirjaus)
  //           break
  //         }
  //       }
  //     }
  //   }
  //   return kirjaukset
  // }

  /**
   * It you want to pull numbers to a custom calc, use the special identifier of the account, like
   * @param tilinumero The account number: '2341'
   * @param arvot The values to use in calculations.
   * @param specialIdentifier
   */
  // , log?: 'log'
  laskeDebetMinusKredit(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker, skipAffectedAccountsFor?: string[]): number {
    const hierarkianTilinumerot = this._hierarkiatMap.get(tilinumero)

    if (!hierarkianTilinumerot) {
      return 0
    }

    const identifier: string = specialIdentifier ? specialIdentifier : ''
    const loppud = identifier + 'd'
    const loppuk = identifier + 'k'
    let tilinSumma = 0
    for (const tili of hierarkianTilinumerot) {
      // if (tili === '2830') {
      //   throw new Error('Here4')
      // }
      if (!skipAffectedAccountsFor || !skipAffectedAccountsFor.includes(tili)) {
        affectedAccountsCache.add(tili)
      }
      const arvoDebit = arvot['t' + tili + loppud]
      if (arvoDebit) {
        // if (log) { console.log('Lisätään tilin ' + tili + ' summa +' + arvoDebit) }
        tilinSumma += arvoDebit
      }
      const arvoKredit = arvot['t' + tili + loppuk]
      if (arvoKredit) {
        // if (log) { console.log('Lisätään tilin ' + tili + ' summa -' + arvoKredit) }
        tilinSumma -= arvoKredit
      }
    }

    // if (log) { console.log('Kokonaissumma: ' + tilinSumma) }

    return this._currencyService.roundHalfUp(tilinSumma, 4)
  }

  laskeDebetMinusKreditTilialueelta(tilinumeroAlku: number, tilinumeroLoppu: number, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker, log?: 'log'): number {
    let summa = 0
    for (let alku = tilinumeroAlku; alku <= tilinumeroLoppu; alku++) {
      // if (log) {
      //   const toAdd = this.laskeDebetMinusKredit(alku + '', arvot, affectedAccountsCache, specialIdentifier)
      //   console.log('Add account ' + alku + ', sum ' + toAdd + ' to sum ' + summa + ' totaling ' + (summa + toAdd))
      // }
      summa += this.laskeDebetMinusKredit(alku + '', arvot, affectedAccountsCache, specialIdentifier)
    }
    // if (log) {
    //   console.log('Total is ' + summa)
    // }
    return summa
  }

  /**
   * It you want to pull numbers to a custom calc, use the special identifier of the account, like
   * @param tilinumero The account number: '2341'
   * @param arvot The values to use in calculations.
   * @param specialIdentifier
   */
  laskeKreditMinusDebet(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker): number {
    const hierarkianTilinumerot = this._hierarkiatMap.get(tilinumero)

    if (!hierarkianTilinumerot) {
      return 0
    }

    const identifier: string = specialIdentifier ? specialIdentifier : ''
    let tilinSumma = 0
    for (const tili of hierarkianTilinumerot) {
      // if (tili === '2830') {
      //   throw new Error('Here3')
      // }
      affectedAccountsCache.add(tili)
      const arvoDebit = arvot['t' + tili + identifier + 'd']
      if (arvoDebit) {
        tilinSumma -= arvoDebit
      }
      const arvoKredit = arvot['t' + tili + identifier + 'k']
      if (arvoKredit) {
        tilinSumma += arvoKredit
      }
    }

    return this._currencyService.roundHalfUp(tilinSumma, 4)
  }

  /**
   * It you want to pull numbers to a custom calc, use the special identifier of the account, like
   * @param tilinumero The account number: '2341'
   * @param arvot The values to use in calculations.
   * @param specialIdentifier
   */
  laskeDebet(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker): number {
    return this._laskeKreditTaiDebit(tilinumero, arvot, affectedAccountsCache, 'd', specialIdentifier)
  }

  /**
   * It you want to pull numbers to a custom calc, use the special identifier of the account, like
   * @param tilinumero The account number: '2341'
   * @param arvot The values to use in calculations.
   * @param specialIdentifier
   */
  laskeKredit(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker): number {
    return this._laskeKreditTaiDebit(tilinumero, arvot, affectedAccountsCache, 'k', specialIdentifier)
  }

  private _laskeKreditTaiDebit(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, kreditVaiDebit: 'k' | 'd', specialIdentifier?: TilienSummanErityismarker): number {
    const hierarkianTilinumerot = this._hierarkiatMap.get(tilinumero)

    if (!hierarkianTilinumerot) {
      return 0
    }

    const identifier: string = specialIdentifier ? specialIdentifier : ''
    const end = identifier + kreditVaiDebit
    let tilinSumma = 0
    for (const tili of hierarkianTilinumerot) {
      // if (tili === '2830') {
      //   throw new Error('Here2')
      // }
      affectedAccountsCache.add(tili)
      const arvo = arvot['t' + tili + end]
      if (arvo) {
        tilinSumma += arvo
      }
    }

    return this._currencyService.roundHalfUp(tilinSumma, 4)
  }

  annaTilinJaLapsienSummat(tilinumero: string, arvot: TilienSummat, affectedAccountsCache: Set<string>, specialIdentifier?: TilienSummanErityismarker): AccountAndChildrenSumsResult {

    const valitunTilinJaLapsienTilinumerot = this._hierarkiatMap.get(tilinumero)

    if (!valitunTilinJaLapsienTilinumerot) {
      return { summat: new Map() }
    }

    const tilienSummat: Map<string, SumResult> = new Map()

    const identifier: string = specialIdentifier ? specialIdentifier : ''
    for (const tnro of valitunTilinJaLapsienTilinumerot) {
      // if (tnro === '2830') {
      //   throw new Error('Here1')
      // }
      affectedAccountsCache.add(tnro)
      const arvoKredit = arvot['t' + tnro + identifier + 'k']
      const arvoDebit = arvot['t' + tnro + identifier + 'd']
      const res: SumResult = { debetSumma: arvoDebit || 0, kreditSumma: arvoKredit || 0 }
      tilienSummat.set(tnro, res)
    }

    return { summat: tilienSummat }
  }

  private _flattaaHierarkia(hierarkiaArr: HierarkiaKirjanpitotili[]): FlatattuHierarkia[] {
    const flattened: FlatattuHierarkia[] = []
    for (const juuri of hierarkiaArr) {
      this._flattaa(juuri, 1, flattened)
    }
    return flattened
  }

  private _flattaa(hierarkia: HierarkiaKirjanpitotili, level: number, kaikki: FlatattuHierarkia[]) {
    kaikki.push(this._makeFlat(hierarkia, level))
    if (hierarkia.lapset) {
      for (const lapsi of hierarkia.lapset) {
        this._flattaa(lapsi, level + 1, kaikki)
      }
    }
  }

  private _makeFlat(node: HierarkiaKirjanpitotili, level: number): FlatattuHierarkia {
    return {
      level: level,
      kirjanpitotili: node.kirjanpitotili
    }
  }

}
