import { Directive, ElementRef, OnInit } from '@angular/core'

/**
 * Appends the required asterisk to placeholder content for <textarea> and <input> fields that are required.
 * Note that the asterisk is applied automatically for mat-form-fields that contain mat-labels (mat-label acts both as a placeholder and label).
 * However, there are cases where the placeholder is provided instead and there is no need for the label. For such cases, this directive triggers and
 * provides similar user experience.
 *
 * Note that this directive's logic has been implemented for mat-select via CSS. Search for keyword 'PlaceholderWithRequiredIndicatorDirective' from global CSS.
 * This is due to mat-select having a separate element that can be injected conditionally with content via the pseudo selectors.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'textarea[placeholder][required], input[placeholder][required]'
})
export class PlaceholderWithRequiredIndicatorDirective implements OnInit {
  constructor(
    private _element: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
  ) {
  }

  ngOnInit() {
    if (this._element?.nativeElement?.placeholder) {
      this._element.nativeElement.placeholder += ' *'
    }
  }
}
