import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'

import { AsiakasService, AsiakkaanAvainTiedot } from '../../_angular/service/asiakas/asiakas.service'
import { BankBalance, BankBalancesPerMonth } from '../../_jaettu/model/tiliote'
import { MatTableDataSource } from '@angular/material/table'

import { takeUntil } from 'rxjs/operators'
import { DateService } from '../../_shared-core/service/date.service'
import { MatSort } from '@angular/material/sort'
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

export interface BankBalanceListaus extends BankBalance {
  iban: string
}
@Component({
  templateUrl: './paivasaldot-listaus.component.html'
})
export class PaivasaldoListausComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  @ViewChild(MatSort, { static: true }) _sort: MatSort

  form: UntypedFormGroup
  dataSource = new MatTableDataSource<BankBalanceListaus>([])
  naytettavatKolumnit: string[] = ['paiva', 'iban', 'saldo', 'edited']
  lataaObservable: BehaviorSubject<boolean> = new BehaviorSubject(true)
  lataaPaivaJaIban: BehaviorSubject<string> = new BehaviorSubject('')

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _kirjanpitoUriService: KirjanpitoUriService
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      'monthRange': new UntypedFormControl(null)
    })

    const today = this._dateService.currentLocalDate()

    this.dataSource.sort = this._sort
    this.dataSource.sortData = (data: BankBalanceListaus[], sort: MatSort): BankBalanceListaus[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') {
        return data
      }

      const directionMultiplier = direction === 'asc' ? 1 : -1
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

      if (active === 'iban') {
        return data.sort((a, b) => {
          return collator.compare(a.iban, b.iban) * directionMultiplier
        })
      }
      if (active === 'paiva') {
        return data.sort((a, b) => {
          return (a.paiva - b.paiva) * directionMultiplier
        })
      }
      if (active === 'saldo') {
        return data.sort((a, b) => {
          return (a.saldo - b.saldo) * directionMultiplier
        })
      }
      if (active === 'edited') {
        return data.sort((a, b) => {
          return (a.edited.toMillis() - b.edited.toMillis()) * directionMultiplier
        })
      }
      return data
    }
    this._sort.active = 'paiva'
    this._sort.direction = 'desc'

    this.form.get('monthRange').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async monthRange => {
      this.lataaObservable.next(true)

      this.dataSource.data = []

      const startDate = this._dateService.lisaaKuukausiaPaikallinen(today, -monthRange)
      const startMonthNum = this._dateService.localMonthToNumber({ year: startDate.year, month: startDate.month })

      const asiakasAvainTiedot: AsiakkaanAvainTiedot = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const uri = this._kirjanpitoUriService.annaBankTransactionsClosingBalancesCollectionUri(asiakasAvainTiedot.avain)

      const kuukausidenLoppusaldot: BankBalancesPerMonth[] = await this._firebaseLemonaid.firestoreCollection<BankBalancesPerMonth>(uri).where('kuukausi', '>=', startMonthNum).get()

      for (const kuukaudenSaldot of kuukausidenLoppusaldot) {
        const listausdata: BankBalanceListaus[] = Object.values(kuukaudenSaldot?.saldot || {}).map(a => {
          const b = a as BankBalanceListaus
          b.iban = kuukaudenSaldot.iban
          return b
        })
        this.dataSource.data = this.dataSource.data.concat(listausdata)
      }
      this.lataaObservable.next(false)
    })

    this.form.get('monthRange').setValue(1) // Set 1 month as starting value
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  get saldoTiedotDatasource(): MatTableDataSource<BankBalanceListaus> {
    return this.dataSource
  }

}
