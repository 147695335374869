import { Directive, ElementRef, EventEmitter, Output } from '@angular/core'

@Directive({
  selector: '[appOnDisabled]'
})
export class OnDisabledDirective {

  private changes: MutationObserver

  @Output()
  public appOnDisabled = new EventEmitter<boolean>()

  constructor(private elementRef: ElementRef<HTMLInputElement>) {

    const element = this.elementRef.nativeElement
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations
        .filter((mutation: MutationRecord) => mutation.attributeName === 'disabled')
        .forEach((mutation: MutationRecord) => this.appOnDisabled.emit(!!element.disabled))
    })

    this.changes.observe(element, {
      attributes: true,
      childList: false,
      characterData: false
    })

  }

}
