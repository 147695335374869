import { Component, OnInit, Inject, ErrorHandler, OnDestroy, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { MatCheckbox } from '@angular/material/checkbox'

import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'

import { AsiakkaatDataSourceStateService } from './asiakkaat.datasource.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { KirjanpitajanSarake, KirjanpitajanSarakkeenTila } from 'app/_jaettu-lemonator/model/kirjanpitaja'

import { firstValueFrom, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface KirjanpitajanSarakkeenMuokkausDialogData {
  kirjanpitajaUid: string
  sarake: KirjanpitajanSarake
}

@Component({
  templateUrl: './kirjanpitajan-sarakkeen-muokkaus.dialog.html'
})
export class KirjanpitajanSarakkeenMuokkausDialog implements OnInit, OnDestroy {

  @ViewChild('sarakeAktiivinenCheckbox', { read: MatCheckbox, static: true }) sarakeAktiivinenCheckbox: MatCheckbox
  @ViewChild('sarakkeenNimiInput', { read: MatInput, static: true }) sarakkeenNimiInput: MatInput
  @ViewChildren('tilanNimiInput', { read: MatInput }) tilanNimiInputit: QueryList<MatInput>

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  sarake: KirjanpitajanSarake = null
  form: UntypedFormGroup
  namename: string = 'aussf' + Math.random()
  lisaa: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitajanSarakkeenMuokkausDialogData,
    private _dialogRef: MatDialogRef<KirjanpitajanSarakkeenMuokkausDialog>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorHandler: ErrorHandler,
    private _copyService: KopioijaPalvelu,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _stateService: AsiakkaatDataSourceStateService
  ) {

    this.sarake = this._data.sarake ? this._copyService.cloneObjectDeep(this._data.sarake) : this.annaUusiSarake()

    this.lisaa = !this.sarake.avain

    this.form = new UntypedFormGroup({
      'aktiivinen': new UntypedFormControl({ value: this.sarake.aktiivinen, disabled: false }, []),
      'nimi': new UntypedFormControl({ value: this.sarake.nimi, disabled: !this.sarake.aktiivinen }, [Validators.required]),
      'tilat': new UntypedFormArray([])
    })
    this.form.get('aktiivinen').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.sarake.aktiivinen = !!value
      if (this.sarake.aktiivinen) {
        this.form.get('nimi').enable()
        this.annaTilaControllit().forEach(a => a.enable())
        setTimeout(() => {
          this.sarakkeenNimiInput.focus()
          this._changeDetectorRef.markForCheck()
        }, 10)
      } else {
        this.form.get('nimi').disable()
        this.annaTilaControllit().forEach(a => a.disable())
      }
    })
    this.form.get('nimi').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.sarake.nimi = value
    })

    if (!this.sarake.tilat) {
      this.sarake.tilat = []
    }

    const formArray = this.annaTilatFormArray()
    for (const tila of this.sarake.tilat) {
      const tilanFormGroup = this.annaFormGroupTilalle(tila, this.sarake)
      formArray.push(tilanFormGroup)
    }

  }

  private annaUusiSarake(): KirjanpitajanSarake {
    const sarake: KirjanpitajanSarake = {
      aktiivinen: true,
      avain: null,
      nimi: '',
      tilat: [this.annaUusiTila()],
      jarjestys: null,
      tallennettu: null,
      tallentanut: null
    }
    firstValueFrom(this._stateService.kaikkiSarakkeetObservable).then(kaikki => {
      sarake.jarjestys = (kaikki?.length || 0) + 1
    })
    return sarake
  }

  private annaFormGroupTilalle(tila: KirjanpitajanSarakkeenTila, sarake: KirjanpitajanSarake): UntypedFormGroup {
    const tilanFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl({ value: tila.nimi, disabled: !sarake.aktiivinen }, [Validators.required]),
      'kuvaus': new UntypedFormControl({ value: tila.kuvaus, disabled: !sarake.aktiivinen }, []),
      'vari': new UntypedFormControl({ value: tila.vari, disabled: !sarake.aktiivinen }, []),
      'oletus': new UntypedFormControl({ value: tila.oletus, disabled: !sarake.aktiivinen }, [])
    })
    tilanFormGroup.get('nimi').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      tila.nimi = value
    })
    tilanFormGroup.get('kuvaus').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      tila.kuvaus = value
    })
    tilanFormGroup.get('vari').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      tila.vari = value
    })
    tilanFormGroup.get('oletus').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.sarake.tilat.forEach(t => t.oletus = false)
      this.annaTilaControllit().forEach(c => {
        if (c !== tilanFormGroup) {
          const oletus = c.get('oletus') as UntypedFormControl
          oletus.setValue(false, { emitModelToViewChange: true, emitViewToModelChange: false, onlySelf: true, emitEvent: false })
        }
      })
      tila.oletus = value
    })
    return tilanFormGroup
  }

  private annaUusiTila(): KirjanpitajanSarakkeenTila {
    const uusiTila: KirjanpitajanSarakkeenTila = {
      avain: null,
      kuvaus: '',
      nimi: '',
      vari: null,
      oletus: false
    }
    return uusiTila
  }

  lisaaTila() {
    const uusiTila = this.annaUusiTila()
    const tilanFormGroup = this.annaFormGroupTilalle(uusiTila, this.sarake)
    if (!this.sarake.tilat) {
      this.sarake.tilat = []
    }
    this.sarake.tilat.push(uusiTila)
    this.annaTilatFormArray().push(tilanFormGroup)
    setTimeout(() => {
      this.tilanNimiInputit.last.focus()
      this._changeDetectorRef.markForCheck()
    }, 10)
  }

  annaTilaControllit(): UntypedFormGroup[] {
    return this.annaTilatFormArray().controls as UntypedFormGroup[]
  }

  private annaTilatFormArray(): UntypedFormArray {
    return this.form.get('tilat') as UntypedFormArray
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.sarake.aktiivinen) {
        this.sarakkeenNimiInput.focus()
      } else {
        this.sarakeAktiivinenCheckbox.focus()
      }
      this._changeDetectorRef.markForCheck()
    }, 10)
  }

  tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    return this._kirjanpitajaService.tallennaSarake(this._data.kirjanpitajaUid, this.sarake).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
