<div mat-dialog-content>

  <lemon-dialog-header>
    Kirjanpitoaineiston latauslinkki
  </lemon-dialog-header>

  <div>
    Kirjanpitoaineisto voidaan ladata alla olevasta linkistä yhden viikon ajan:
  </div>
  <div style="margin: 1em; color: gray;">
    {{url}}
  </div>
  <button mat-button (click)="toClipboard()" class="secondarybutton">Kopioi leikepöydälle napauttamalla tätä</button>

</div>
<div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
  <button class="primarybutton" mat-button mat-dialog-close>OK</button>
</div>