<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <div *ngIf="commonError" class="red-text">{{commonError}}</div>

  <form [formGroup]="form" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <mat-form-field floatPlaceholder="never" style="width: 350px; margin-right: 1.5vw;">
          <mat-label>Tili</mat-label>
          <mat-select formControlName="valittuTili">
            <mat-option [value]="null">Kaikki</mat-option>
            <mat-option *ngFor="let tili of tilit" [value]="tili">
              {{ tili.iban }} (pool: {{ tili.handle }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field floatPlaceholder="never" style="width: 150px; margin-right: 1.5vw;">
          <mat-label>{{ 'Näytä' }}</mat-label>
<mat-select  formControlName="tulleetTaiLahteneet">
            <mat-option [value]="null">kaikki</mat-option>
            <mat-option [value]="'tulleet'">tilille tulleet rahat</mat-option>
            <mat-option [value]="'lahteneet'">tililtä lähteneet rahat</mat-option>
          </mat-select>
        </mat-form-field> -->

        <!-- <mat-form-field style="width: 250px; margin-right: 1.5vw;">
          <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
          <mat-label>{{ 'Etsi tilitapahtumia' }}</mat-label>
<input type="text" formControlName="vapaahaku"  matInput [name]="'a' + name" />
        </mat-form-field> -->

        <div>
          <div style="display: flex; flex-flow: row wrap;">
            <mat-form-field style="width: 200px; margin-right: 10px;">
              <mat-label>Näytä alkaen</mat-label>
              <input matInput [matDatepicker]="dp1" formControlName="alkaa">
              <!-- <mat-hint>Alkaa</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="width: 200px;">
              <mat-label>... loppuen</mat-label>
              <input matInput [matDatepicker]="dp2" formControlName="loppuu">
              <!-- <mat-hint>Loppuu</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <button mat-button class="primarybutton" (click)="hae()">Hae</button>

      </div>

    </div>

  </form>

  <div style="position: relative;">
    <table mat-table multiTemplateDataRows class="laskut-table" matSort [dataSource]="dataSource" [matSortDisableClear]="false" style="min-height: 94px;">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pvm </th>
        <td mat-cell *matCellDef="let row"> {{row.date | ldatetime | async}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Summa </th>
        <td mat-cell *matCellDef="let row"> {{row.amount}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="maksajaTaiSaaja">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Maksaja tai maksun saaja</th>
        <td mat-cell *matCellDef="let row"> {{row.maksajaTaiSaaja}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="liitteet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 500px;">Liitteet</th>
        <td mat-cell *matCellDef="let row" style="width: 500px; min-height: 21px;">
          <span class="liite-link" *ngFor="let liite of row.liitteet" (click)="downloadAttachment(liite, $event)">{{liite.nimi ? liite.nimi + ' ' : liite.avain }}</span>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
      <mat-row *matRowDef="let row; columns: naytettavatKolumnit" (click)="naytaDetaileja(row)"></mat-row>

    </table>
    <!-- <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!dataSource?.lataa && dataSource?.filteredData?.length == 0">
      <div style="width: 100%; padding-top: 40px; text-align: center;">
        Hakuehdoilla ei löydy yhtään tilitapahtumaa
      </div>
    </div>
    <div lemon-loading [show]="dataSource?.lataa"></div> -->
  </div>

  <!-- <mat-paginator #paginator [pageSizeOptions]="[50, 200, 500]">
    </mat-paginator> -->

  <div style="margin: 50px;" *ngIf="holviCustomerData || holviUserData">
    <h2 class="light-blue-text">Yritys</h2>
    <div [innerHTML]="holviCustomerData | ljson"></div>

    <h2 class="light-blue-text">Holvi-käyttäjä</h2>
    <div [innerHTML]="holviUserData | ljson"></div>
  </div>
</div>