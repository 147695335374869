import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, AfterViewInit } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatDialog } from '@angular/material/dialog'
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl } from '@angular/forms'
import { Observable, Subject, combineLatest, map, of, startWith, switchMap, takeUntil, tap } from 'rxjs'
import { DateService } from '../_shared-core/service/date.service'
import { LemonKuukausiDateAdapter } from '../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { Platform } from '@angular/cdk/platform'
import { YhteisomyyntilaskujenLataaminenDialog, YhteisomyyntilaskujenLataaminenDialogData } from './yhteisomyyntilaskujen-lataaminen.dialog'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { YhteisomyyntilaskujenCsvVastaus, YhteisomyyntilaskujenListauspyynto } from 'app/_jaettu/lemonator/model/tositteiden-lataaminen'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { MatTableDataSource } from '@angular/material/table'
import { OmaveroYhteisomyyntilaskuLahetetty } from 'app/_jaettu-sans-lemonaid-angular/model/omavero'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus, AsiakkaanNimitiedot } from 'app/_jaettu-lemonator/model/asiakas'
import { MatSort } from '@angular/material/sort'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

interface OmaveroYhteisomyyntilaskuLahetettyList extends OmaveroYhteisomyyntilaskuLahetetty {
  asiakas: string
  debugLink: string
}

interface YhteisoMyyntiIlmoituksetForm {
  status: FormControl<string>
  start: FormControl<Date>
  end: FormControl<Date>
}

@Component({
  templateUrl: './yhteisomyyntilaskut.component.html',
  styleUrls: ['./yhteisomyyntilaskut.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ],
})
export class YhteisomyyntilaskutComponent implements OnInit, OnDestroy, AfterViewInit {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  kuukausi: Date = new Date()
  form: UntypedFormGroup
  hakuform: FormGroup<YhteisoMyyntiIlmoituksetForm>
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  naytettavatKolumnit: string[] = ['asiakasNimi', 'jakso', 'maaKoodi', 'alvTunnus', 'tavaramyynnit', 'palvelumyynnit', 'kolmikantamyynnit', 'lahetetty', 'status']
  dataSource = new MatTableDataSource<OmaveroYhteisomyyntilaskuLahetettyList>([])
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  kuukaudenNimi = ''
  lahetyksenTila: { status: string }[] = [
    { status: 'Onnistuneet' },
    { status: 'Virheelliset' },
    { status: 'Kaikki statukset' }
  ]

  constructor(
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _dateAdapter: DateAdapter<Date>,
    private _dialog: MatDialog,
    private _firebaseLemonator: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _fileSaverService: FileSaverService,
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService,
    private _kirjanpitoUriService: KirjanpitoUriService
  ) {
    this.kuukausi = this._dateService.lisaaKuukausia(new Date(), -1)
    this.kuukaudenNimi = this._dateAdapter.getMonthNames('long')[this.kuukausi.getMonth()]
  }

  ngOnInit() {

    this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((onDevaaja: boolean) => {
      if (onDevaaja) {
        this.naytettavatKolumnit = [...this.naytettavatKolumnit, 'debug']
      }
    })

    this.form = new UntypedFormGroup({
      'vuosikk': new UntypedFormControl(this.kuukausi, [Validators.required]),
    })
    this.form.get('vuosikk').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      this.kuukausi = value
      this.kuukaudenNimi = this._dateAdapter.getMonthNames('long')[this.kuukausi.getMonth()]
    })

    const today = new Date()
    this.hakuform = new FormGroup<YhteisoMyyntiIlmoituksetForm>({
      status: new FormControl<string>(''),
      start: new FormControl<Date>((this._dateService.lisaaKuukausia(today, -1)), Validators.required),
      end: new FormControl<Date>((this._dateService.lisaaKuukausia(today, -1)), Validators.required),
    })

    const start = this.hakuform.get('start')
    const end = this.hakuform.get('end')
    const status = this.hakuform.get('status')

    const statukset = {
      'Onnistuneet': ['success'],
      'Virheelliset': ['error'],
      'Kaikki statukset': ['success', 'error']
    }

    const asiakkaatMapObservable = this._firebaseLemonator.functionsCall<AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus>('annaAsiakkaidenNimitiedot', {}, { timeout: 540 * 1000 }).then(
      asiakkaatVastaus => {
        const m: Map<string, AsiakkaanNimitiedot> = new Map()
        if (asiakkaatVastaus?.nimitiedot) {
          for (const a of asiakkaatVastaus.nimitiedot) {
            m.set(a.avain, a)
          }
        }
        return m
      }
    )

    const ilmoituksetObservable = combineLatest([
      start.valueChanges.pipe(startWith(start.value)),
      end.valueChanges.pipe(startWith(end.value)),
      status.valueChanges.pipe(startWith(status.value))
    ]).pipe(
      tap(() => this._ladataanService.aloitaLataaminen()),
      switchMap((([startValue, endValue, statusValue]) => {
        if (!startValue || !endValue || !statusValue) {
          return of<OmaveroYhteisomyyntilaskuLahetetty[]>([])
        }
        const startNumberMonth = this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(startValue))
        const endNumberMonth = this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(endValue))

        return this._firebaseLemonator.firestoreCollectionGroup<OmaveroYhteisomyyntilaskuLahetetty>('yhteisomyynti-ilmoitukset')
          .orderBy('lahetetty', 'desc')
          .where('status', 'in', statukset[statusValue])
          .where('jakso', '>=', startNumberMonth)
          .where('jakso', '<=', endNumberMonth)
          .listen()
      }))
    )

    combineLatest([ilmoituksetObservable, asiakkaatMapObservable]).pipe(
      map(([ilmot, asiakkaatMap]) => {
        const result: OmaveroYhteisomyyntilaskuLahetettyList[] = ilmot as OmaveroYhteisomyyntilaskuLahetettyList[]
        for (const res of result) {
          const asiakas = asiakkaatMap.get(res.asiakasAvain)
          if (asiakas) {
            res.asiakas = asiakas.nimi + ' (' + asiakas.ytunnus + ')'
          } else {
            res.asiakas = asiakas.avain
          }
          res.debugLink = this._debugService.createFirestoreLink(this._kirjanpitoUriService.annaOmaveroYhteisomyyntilaskuLahetettyDebugDataUri(res.asiakasAvain, res.lahetysAvain))
        }
        // Sorttaus Y-tunnuksen mukaan
        result.sort((a, b) => {
          const asiakasA = asiakkaatMap.get(a.asiakasAvain)
          const asiakasB = asiakkaatMap.get(b.asiakasAvain)

          if (asiakasA && asiakasB) {
            return asiakasA.ytunnus.localeCompare(asiakasB.ytunnus, undefined, { numeric: true })
          }
        })
        return result
      })
    ).subscribe(ilmoitukset => {
      this.dataSource.data = ilmoitukset
      this._ladataanService.lopetaLataaminen()
    })

    status.setValue(this.lahetyksenTila[0].status)

  }

  ngAfterViewInit() {
    this.dataSource.sort = this._sort
    if (this.dataSource.paginator) {
      this.dataSource.paginator.pageSize = 50000
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerStart(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.hakuform.get('start').setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerEnd(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.hakuform.get('end').setValue(normalizedMonth)
    datepicker.close()
  }

  lataa() {

    if (!this.form.valid) {
      return
    }

    const data: YhteisomyyntilaskujenLataaminenDialogData = {
      kuukausi: this.kuukausi.getMonth() + 1,
      vuosi: this.kuukausi.getFullYear()
    }

    this._dialog.open(YhteisomyyntilaskujenLataaminenDialog, {
      data: data
    })

  }

  private _csvLatausKaynnissa = false
  async lataaCsv() {

    if (!this.form.valid) {
      return
    }

    if (this._csvLatausKaynnissa) {
      return
    }
    this._csvLatausKaynnissa = true

    try {

      this._ladataanService.aloitaLataaminen()

      const data: YhteisomyyntilaskujenListauspyynto = {
        kuukausi: this.kuukausi.getMonth() + 1,
        vuosi: this.kuukausi.getFullYear()
      }

      const vastaus = await this._firebaseLemonator.functionsCall<YhteisomyyntilaskujenListauspyynto, YhteisomyyntilaskujenCsvVastaus>('integraatiotOmaveroYhteisomyyntiCsv', data)
      const filename = 'Yhteisömyyntilaskut-CSV-' + data.vuosi + '-' + data.kuukausi + '.csv'
      this._fileSaverService.saveStringAs(vastaus.csv, filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._csvLatausKaynnissa = false
    }

  }

}
