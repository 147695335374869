import { Component, OnInit, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { MatDialog } from '@angular/material/dialog'
import { MatSelect } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'

import { combineLatest, Observable, of, BehaviorSubject, firstValueFrom } from 'rxjs'
import { map, switchMap, tap, startWith } from 'rxjs/operators'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'

import { TositteidenKuukausisummat } from '../_jaettu/lemonator/model/tositteiden-lataaminen'

import { AsiakkaanTositteidenLataaminenDialogData, AsiakkaanTositteidenLataaminenDialog } from './asiakkaan-tositteiden-lataaminen.dialog'
import { AsiakkaanTositteidenLataushistoriaDialog, AsiakkaanTositteidenLataushistoriaDialogData } from './asiakkaan-tositteiden-lataushistoria.dialog'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'
import { NumberMonth, Timestamp } from '../_shared-core/model/common'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DateService } from 'app/_shared-core/service/date.service'
import { HaePalkkatiedotRequest } from 'app/_jaettu-lemonator/model/tyojono'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'

export interface TositteenLataamistiedotMaksutavalleEdit {
  maksutavanTunniste: number
  maksutavanNimi: string
  ladattuja: number
  lataamatta: number
}

export interface TositteidenKuukausisummatEdit {
  vuosi: number
  kk: number
  ladattuja: number
  lataamatta: number
  maksutavoittain: TositteenLataamistiedotMaksutavalleEdit[]
  paivitetty: Timestamp
}

interface MainForm {
  selectedMonthZip: FormControl<NumberMonth>
  selectedMonthFetch: FormControl<NumberMonth>
}

@Component({
  templateUrl: './asiakkaan-tositteet.component.html',
  styleUrls: ['./asiakkaan-tositteet.component.css']
})
export class AsiakkaanTositteetComponent implements OnInit {

  @ViewChild(MatSort) _sort: MatSort
  @ViewChild('naytaKaikki', { read: MatSelect, static: true }) naytaKaikkiSelect: MatSelect

  private ladataanSubject = new BehaviorSubject(true)

  kayttajatNaytettavatKolumnit: string[] = ['etunimi', 'sukunimi', 'email']

  form: FormGroup<MainForm>

  selectableMonths: NumberMonth[] = []

  asiakasObservable: Observable<Asiakas>
  kuukaudetObservable: Observable<TositteidenKuukausisummatEdit[]>
  eiUusiaTositteitaObservable: Observable<boolean>
  eiTositteitaObservable: Observable<boolean>
  ladataanKuukausiaObservable = this.ladataanSubject.asObservable()

  kuititUriObservable: Observable<string>
  kuititEncodedUriObservable: Observable<string>
  laskutUriObservable: Observable<string>
  laskutEncodedUriObservable: Observable<string>
  kuukausisummakortitUriObservable: Observable<string>
  kuukausisummakortitEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  isSuperUserObservable: Observable<boolean>

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService,
    private _ladataanService: LadataanService,
    private _formValidationService: FormValidationService,
    private _snackbar: MatSnackBar,
    private _dateService: DateService
  ) { }

  ngOnInit() {

    this.selectableMonths = this._getSelectableMonths()

    this.isSuperUserObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(tiedot => tiedot?.rooli === KirjanpitajanRooli.SUPER)
    )

    const previousMonth = this._dateService.lisaaKuukausiaLocalMonth(this._dateService.currentLocalMonth(), -1)
    const previousMonthAsNumber = this._dateService.localMonthToNumber(previousMonth)
    this.form = new FormGroup<MainForm>({
      'selectedMonthZip': new FormControl<NumberMonth>(previousMonthAsNumber, [Validators.required]),
      'selectedMonthFetch': new FormControl<NumberMonth>(previousMonthAsNumber, [Validators.required])
    })

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.kuititUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.asiakasId) {
          return 'kuitit/' + avainTiedot.asiakasId + '/kuitit'
        }
        return ''
      })
    )
    this.kuititEncodedUriObservable = this.kuititUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )
    this.laskutUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.asiakasId) {
          return 'laskut/' + avainTiedot.asiakasId + '/laskut'
        }
        return ''
      })
    )
    this.laskutEncodedUriObservable = this.laskutUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )
    this.kuukausisummakortitUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.asiakasId) {
          return 'asiakkaat/' + avainTiedot.avain + '/lataamattomat-tositteet-kuukausittain'
        }
        return ''
      })
    )
    this.kuukausisummakortitEncodedUriObservable = this.kuukausisummakortitUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable

    this.naytaKaikkiSelect.value = false
    const naytaKaikkiObservable = this.naytaKaikkiSelect.valueChange.asObservable().pipe(
      startWith(this.naytaKaikkiSelect.value)
    )

    const kuukausisummatObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, naytaKaikkiObservable]).pipe(
      tap(() => {
        setTimeout(() => {
          this.ladataanSubject.next(true)
        }, 0)
      }),
      switchMap(([asiakas, naytaKaikki]) => {
        if (asiakas && asiakas.avain) {
          if (naytaKaikki) {
            return this._firebase.firestoreCollection<TositteidenKuukausisummat>('asiakkaat/' + asiakas.avain + '/lataamattomat-tositteet-kuukausittain').listen()
          } else {
            return this._firebase.firestoreCollection<TositteidenKuukausisummat>('asiakkaat/' + asiakas.avain + '/lataamattomat-tositteet-kuukausittain').where('lataamatta', '>', 0).listen()
          }
        }
        return of<TositteidenKuukausisummat[]>([])
      })
    )

    this.kuukaudetObservable = combineLatest([kuukausisummatObservable, this._asiakasService.nykyisenAsiakkaanJaKayttajienRoolienJaLaskunMaksutavatObservable]).pipe(
      map(([summat, maksutavat]) => {
        const kuukaudetAukiRajaytettyna: TositteidenKuukausisummatEdit[] = []

        for (const alkuperainen of summat) {
          const lisattava: TositteidenKuukausisummatEdit = {
            kk: alkuperainen.kk,
            ladattuja: alkuperainen.ladattuja,
            lataamatta: alkuperainen.lataamatta,
            maksutavoittain: [],
            paivitetty: alkuperainen.paivitetty,
            vuosi: alkuperainen.vuosi
          }
          for (const mtapa of maksutavat) {

            const alkuperaisesta = alkuperainen.maksutavoittain.filter(maksutavoittain => {
              return maksutavoittain.maksutavanTunniste === mtapa.tunniste
            })

            lisattava.maksutavoittain.push({
              ladattuja: alkuperaisesta.length === 1 ? alkuperaisesta[0].ladattuja : 0,
              lataamatta: alkuperaisesta.length === 1 ? alkuperaisesta[0].lataamatta : 0,
              maksutavanNimi: mtapa.nimi,
              maksutavanTunniste: mtapa.tunniste
            })
          }
          kuukaudetAukiRajaytettyna.push(lisattava)
        }

        return kuukaudetAukiRajaytettyna.sort((a, b) => {
          if (a.vuosi !== b.vuosi) {
            return b.vuosi - a.vuosi
          }
          return b.kk - a.kk
        })
      }),
      tap(() => {
        setTimeout(() => {
          this.ladataanSubject.next(false)
        }, 0)
      }),
      lemonShare()
    )

    this.eiUusiaTositteitaObservable = combineLatest([this.kuukaudetObservable, naytaKaikkiObservable, this.ladataanKuukausiaObservable]).pipe(
      map(([kuukaudet, naytaKaikki, ladataan]) => {
        return !ladataan && kuukaudet.length < 1 && !naytaKaikki
      }),
      startWith(false)
    )

    this.eiTositteitaObservable = combineLatest([this.kuukaudetObservable, naytaKaikkiObservable, this.ladataanKuukausiaObservable]).pipe(
      map(([kuukaudet, naytaKaikki, ladataan]) => {
        return !ladataan && kuukaudet.length < 1 && naytaKaikki
      }),
      startWith(false)
    )

  }

  private _getSelectableMonths(): NumberMonth[] {

    const selectableMonths: NumberMonth[] = []

    const currentNumberMonth = this._dateService.numberDateToNumberMonth(this._dateService.currentNumberDate())
    let loopedMonth: NumberMonth = this._dateService.lisaaKuukausiaKuukaudenNumero(currentNumberMonth, -1)

    const endMonth: NumberMonth = 2209

    while (loopedMonth >= endMonth) {
      selectableMonths.push(loopedMonth)
      loopedMonth = this._dateService.lisaaKuukausiaKuukaudenNumero(loopedMonth, -1)
    }
    return selectableMonths
  }

  lataaKaikki(kuukausi: TositteidenKuukausisummatEdit) {
    firstValueFrom(this.asiakasObservable).then(asiakas => {
      if (asiakas) {

        const data: AsiakkaanTositteidenLataaminenDialogData = {
          asiakas: asiakas,
          valittuKuukausi: kuukausi,
          vainLataamattomat: false
        }

        this._dialog.open(AsiakkaanTositteidenLataaminenDialog, {
          disableClose: true,
          data: data
        })

      }
    })
  }

  lataaUudet(kuukausi: TositteidenKuukausisummatEdit) {
    firstValueFrom(this.asiakasObservable).then(asiakas => {
      if (asiakas) {

        const data: AsiakkaanTositteidenLataaminenDialogData = {
          asiakas: asiakas,
          valittuKuukausi: kuukausi,
          vainLataamattomat: true
        }

        this._dialog.open(AsiakkaanTositteidenLataaminenDialog, {
          disableClose: true,
          data: data
        })

      }
    })
  }

  get selectedMonthZip(): FormControl<NumberMonth> {
    return this.form.get('selectedMonthZip') as FormControl<NumberMonth>
  }

  get selectedMonthFetch(): FormControl<NumberMonth> {
    return this.form.get('selectedMonthFetch') as FormControl<NumberMonth>
  }

  peruuta() {
    this._router.navigate(['asiakkaat'])
  }

  naytaHistoria() {
    firstValueFrom(this.asiakasObservable).then(asiakas => {
      if (asiakas) {
        const data: AsiakkaanTositteidenLataushistoriaDialogData = {
          asiakas: asiakas
        }
        this._dialog.open(AsiakkaanTositteidenLataushistoriaDialog, { data: data })
      }
    })
  }

  async fetchSalaryFromPalkkausfi() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakas = await firstValueFrom(this.asiakasObservable)
      const localMonth = this._dateService.numberToLocalMonth(this.selectedMonthFetch.value)

      const requestData: HaePalkkatiedotRequest = {
        monthToFetch: localMonth,
        asiakasAvain: asiakas.avain
      }
      await this._firebase.functionsCall<HaePalkkatiedotRequest, null>('palkkausfiPalkkatiedotHaeYksittaisenAsiakkaanTiedot', requestData)

      this._ladataanService.lopetaLataaminen()

      this._snackbar.open('Palkkojen haku käynnistettiin. Haussa kestää tavallisesti n. 5 minuuttia.', 'OK')
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async downloadSalaryZip() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakas = await firstValueFrom(this.asiakasObservable)
      const localMonth = this._dateService.numberToLocalMonth(this.selectedMonthZip.value)

      const requestData: { month: NumberMonth, asiakasAvain: string } = {
        month: this.selectedMonthZip.value,
        asiakasAvain: asiakas.avain
      }
      const resp = await this._firebase.functionsCall<{ month: NumberMonth, asiakasAvain: string }, { downloadUrl: string }>('downloadSalaryZip', requestData, { timeout: 640 * 1000 })

      this._ladataanService.lopetaLataaminen()
      if (resp.downloadUrl) {
        document.location = resp.downloadUrl
        // saveAs(new Blob([output], { type: 'application/pdf' }), 'Palkat ' + asiakas.nimi + ' ' + localMonth.month + '.' + localMonth.year + '.zip', { autoBom: false })
      } else {
        this._snackbar.open('Annetulle kuukaudelle ei löytynyt palkkatositteita. Jos tämä on mielestäsi virhe, ota yhteys tekniseen tukeen.')
      }
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

}
