<mat-dialog-content>

  <lemon-dialog-header>
    <span *ngIf="!uusiSalasana">Vaihda käyttäjän salasana</span>
    <span *ngIf="uusiSalasana">Salasana vaihdettiin onnistuneesti</span>
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <p *ngIf="!uusiSalasana && !commonError"> Haluatko varmasti vaihtaa käyttäjän salasanan? </p>

    <div *ngIf="uusiSalasana">
      <p>HUOM! Salasanaa ei tallenneta! Tämän dialogin sulkeminen hävittää sen lopullisesti!</p>
      <p>Salasana: {{uusiSalasana}}</p>
    </div>

    <div *ngIf="commonError" class="error-text">
      {{commonError}}
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button *ngIf="!uusiSalasana && !commonError" class="primarybutton" (click)="vaihdaSalasana()" mat-button>Vaihda salasana</button>
  <button *ngIf="uusiSalasana || commonError" class="primarybutton" mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>