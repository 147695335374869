/* eslint-disable @typescript-eslint/naming-convention */
import { TuettuKieli } from '../../_shared-core/model/common'

export interface Korvausparametrit {
  [key: string]: string
}

export class TilinpaatosTranslationService {

  localize(avain: string, vuosi: number, kieli: TuettuKieli, parametrit?: Korvausparametrit): string {
    let teksti = this._raakaString(avain, vuosi, kieli)
    if (teksti === undefined || teksti === null) {
      return 'Ei käännöstä avaimelle ' + avain + ' kielellä ' + kieli
    }
    if (parametrit) {
      for (const key of Object.keys(parametrit)) {
        const value = parametrit[key]
        // console.log('replace ', '{{' + key + '}}', 'with', value)
        teksti = teksti.replace('{{' + key + '}}', value)
      }
    }
    return teksti
  }
  private _getTextsByYear(vuosi: number) {
    if (vuosi === 2021) {
      return this._get2021Texts()
    } else {
      throw new Error('No translations available for year ' + vuosi)
    }
  }

  private _raakaString(avain: string, vuosi: number, kieli: TuettuKieli): string | null {
    const kaannokset = this._getTextsByYear(vuosi)
    if (kaannokset) {
      const kaannoksetKielelle = kaannokset[kieli]
      if (kaannoksetKielelle) {
        const avaintenOsat = avain.split('.')
        if (avaintenOsat.length > 1) {
          let nykyinen = kaannoksetKielelle[avaintenOsat[0]]
          avaintenOsat.shift()
          for (const avaimenOsa of avaintenOsat) {
            if (nykyinen) {
              nykyinen = nykyinen[avaimenOsa]
            }
          }
          if (nykyinen) {
            return nykyinen
          }
        } else {
          return kaannoksetKielelle[avaintenOsat[0]]
        }
      }
    }
    return null
  }

  /*
  ** NB! Texts only valid for 2021
  */
  private _get2021Texts() {
    return {
      'fi': {
        'sisallys': 'Sisällys',
        'sivu': 'Sivu',
        'sisallysluettelo': 'Sisällysluettelo',
        'tase': 'Tase',
        'tuloslaskelma': 'Tuloslaskelma',
        'liitetiedot': 'Liitetiedot',
        'luettelo-kirjanpidoista': 'Luettelo kirjanpidoista ja aineistoista',
        'tositteiden-lajit': 'Tositteiden lajit ja säilytystavat',
        'tilinpaatoksen-allekirjoitus': 'Tilinpäätöksen allekirjoitus',
        'tilinpaatosmerkinta': 'Tilinpäätösmerkintä',
        'tilinpaatos-on-sailytettava': 'Tämä tilinpäätös on säilytettävä {{pvm}} asti.',
        'tilinpaatoksen-on-laatinut': 'Tilinpäätöksen on laatinut',
        'liitetietoja-ei-ole-tehty': 'Liitetietoja ei ole tehty vuodelle {{year}}.',
        'asiakirja-luotu': 'Asiakirja luotu',
        'PMA1_1_5': {
          'title': 'Tilinpäätöksen laatimissäännöstö (PMA 1:1)',
          'explanation': 'Varmista, onko yhtiö mikroyritys. Päättyneellä ja sitä edeltäneellä tilikaudella',
          'mikroyritys': 'Tilinpäätös on laadittu valtioneuvoston pien- ja mikroyrityksen tilinpäätöksessä esitettävistä tiedoista antaman asetuksen mikroyrityssäännöstön mukaisesti.',
          'ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI': 'taseen loppusumma on 350 000 euroa tai yli',
          'ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI': 'liikevaihto on 700 000 euroa tai yli',
          'ONKO_MIKROYRITYS_HENKILOSTO_YLI': 'palveluksessa on 10 henkilöä tai enemmän'
        },
        'PMA3_1': {
          'title': 'Tilinpäätöksen laatimisperiaatteet (PMA 3:1)',
          'noudatetut-arvostusperiaatteet': 'Noudatetut arvostusperiaatteet ja -menetelmät sekä jaksotusperiaatteet ja -menetelmät:',
          'pysyvien-vastaavien-hyodykkeista': 'Pysyviin vastaaviin kuuluvien hyödykkeiden arvostamisessa ja jaksottamisessa käytetyt menetelmät ja periaatteet:',
          'tilinpaatos-on-laadittu': 'Tilinpäätös on laadittu noudattaen pien- ja mikroyrityksen tilinpäätösasetuksen 3 luvun 1 §:n 2 ja 3 momentissa säädettyjä arvostamisen ja jaksottamisen olettamaperiaatteita ja -menetelmiä.',
          'muilta-osin-on-laadittu': 'Muilta osin tilinpäätös on laadittu noudattaen pien- ja mikroyrityksen tilinpäätösasetuksen 3 luvun 1 §:n 2 ja 3 momentissa säädettyjä arvostamisen ja jaksottamisen olettamaperiaatteita ja -menetelmiä.',
        },
        'PMA3_1_A': {
          'PMA3_1_A_1': 'vaihto-omaisuuden käyttöjärjestys poikkeaa hankintajärjestyksen mukaisesta käytöstä',
          'PMA3_1_A_2': 'taseelle aktivoidun aineettoman oikeuden poistoaika poikkeaa 10 vuodesta',
          'PMA3_1_A_3': 'kehitysmenoja, liikearvoa tai muuta pitkävaikutteista menoa on aktivoitu taseeseen',
          'PMA3_1_A_4': 'pitkän valmistusajan vaativista suoritteista syntyvä tulo on kirjattu tuotoksi valmistusajan perusteella',
          'PMA3_1_A_5': 'rahoitusvälineitä ja/tai sijoituskiinteistöjä on merkitty hankintahintaa korkeampaan käypään arvoonsa',
          'PMA3_1_A_6': 'ulkomaanrahan määräisiä saamisia, velkoja tai muita sitoumuksia on muutettu Suomen rahaksi käyttäen jotain muuta kuin tilinpäätöspäivän kurssia',
        },
        'PMA3_1_B': {
          'PMA3_1_B_1': 'hankintamenoon on luettu hankinnasta tai valmistuksesta johtuvia välillisiä menoja tai korkomenoja',
          'PMA3_1_B_2': 'koneiden ja kaluston tai rakennusten suunnitelmapoistot poikkeavat verotuksessa hyväksyttävistä enimmäispoistoista',
          'PMA3_1_B_3': 'pysyviin vastaaviin kuuluviin hyödykkeisiin on kirjattu arvonkorotuksia',
          'PMA3_1_B_4': 'saatuja avustuksia ei ole kirjattu hankintamenon vähennykseksi',
          'PMA3_1_B_5': 'vähäarvoisia hyödykkeitä tai alle kolmen vuoden kaluston hankintamenoja on aktivoitu taseeseen',
          'PMA3_1_B_6': 'rahoitusleasing -sopimuksella hankittua omaisuutta on aktivoitu taseeseen'
        },
        'PMA3_2': {
          'title': 'Poikkeukselliset erät (PMA 3:2)',
          'PMA3_2_1': 'Yrityksellä on olennaisia poikkeuksellisia tuotto- ja kulueriä'
        },
        'PMA3_3': {
          'title': 'Tilikauden jälkeiset olennaiset tapahtumat (PMA 3:3)',
          'PMA3_3_1': 'Tilikauden jälkeen on ollut olennaisia tapahtumia',
        },
        'PMA3_4': {
          'title': 'Arvonkorotusrahastot (PMA 3:4)',
          'PMA3_4_1': 'Pysyviin vastaaviin kuuluvaan hyödykkeeseen on tehty arvonkorotus'
        },
        'PMA3_6': {
          'title': 'Pitkäaikaiset lainat (PMA 3:6)',
          'PMA3_6_1': 'Yrityksellä on velkoja, jotka erääntyvät yli viiden vuoden kuluttua',
        },
        'PMA3_7': {
          'title': ' Annetut vakuudet ja taseen ulkopuoliset sitoumukset ja järjestelyt sekä eläkevastuut (PMA 3:7)',
          'PMA3_7_1': 'esineoikeudelliset vakuudet',
          'PMA3_7_2': 'taseen ulkopuolisten taloudellisten sitoumusten yhteismäärä',
          'PMA3_7_3': 'tiedot taseen ulkopuolisten järjestelyjen luonteesta ja liiketoiminnallisesta tarkoituksesta',
          'PMA3_7_4': 'eläkevastuu, jota ei ole siirretty vakuutuslaitosten kannettavaksi'
        },
        'PMA3_8': {
          'title': ' Tiedot emoyrityksestä (PMA 3:8)',
          'PMA3_8_1': 'Yrityksellä on emoyritys',
        },
        'PMA3_9': {
          'title': 'Lainat toimitusjohtajalle ja hallintoelimiin kuuluville henkilöille sekä heidän puolestaan annetut sitoumukset (PMA 3:9)',
          'PMA3_9_1': 'Yritys on antanut lainoja toimitusjohtajalle tai hallintoelimiin kuuluville henkilöille'
        },
        'PMA3_10': {
          'title': 'Liiketoimet intressitahojen kanssa (PMA 3:10)',
          'PMA3_10_1': 'Yritys on tehnyt olennaisia liiketoimia intressitahojen kanssa, joita ei olla toteutettu tavanomaisin kaupallisin ehdoin'
        },
        'PMA3_11': {
          'title': 'Henkilöstö (PMA 3:11)',
          'keskimaarainen-lukumaara-start': 'Henkilöstön keskimääräinen lukumäärä tilikaudella oli',
          'keskimaarainen-lukumaara-end': 'henkilöä.'
        },
        'PMA3_12': {
          'title': 'Omien osakkeiden ja osuuksien hankinnat ja luovutukset (PMA 3:12)',
          'PMA3_12_1': 'Yhtiö on hankkinut tai luovuttanut omia osakkeitaan'
        },
        'PMA4_4_2': {
          'title': 'Toimintakertomusta vastaavat tiedot (PMA 4:4.2)',
          'yhtion-ensimmainen': 'Tilikausi on yhtiön ensimmäinen',
          'on aiempia': 'Yhtiöllä on aiempia tilikausia (syötä vertailutiedot)',
        },
        'MUUT': {
          'title': 'Muut tiedot',
        },
        'oman-paaoman-muutokset': 'Oman pääoman muutokset',
        'tilikauden-aikana-tapahtuneet': {
          'title': 'Syötä tilikauden aikana tapahtuneet pääoman muutokset',
          'OSAKEANTI': 'Tilikaudella on tehty osakeanti',
          'SVOP_LISAYKSET': 'Tilikaudella on tehty lisäys sijoitetun vapaan pääoman rahastoon',
          'SVOP_VAHENNYKSET': 'Tilikaudella on tehty vähennys sijoitetun vapaan pääoman rahastoon',
          'OSINKOJEN_MAARA': 'Tilikaudella on jaettu osinkoja',
          'YHTIO_ON_MYYNYT_OSAKKEITAAN': 'Yhtiö on hankkinut tilikaudella omia osakkeitaan',
          'YHTIO_ON_OSTANUT_OSAKKEITAAN': 'Yhtiö on luovuttanut tilikaudella omia osakkeitaan',
          'YHTIOLLA_PAAOMALAINAA': 'Yhtiöllä on pääomalainaa',
          'EDELLISEN_TILIKAUDEN_OIKAISUT': 'Tilikaudella on oikaistu edellisten tilikausien virheitä',
          'YHTION_OSAKKEIDEN_LUNASTUKSET_TAI_LUOVUTUKSET': 'Yhtiö on lunastanut tai luovuttanut omia osakkeitaan tilikauden aikana',
        },
        'osakepaaoma-tilikauden-alussa': 'Osakepääoma tilikauden alussa',
        'osakeanti': 'Osakeanti',
        'osakepaaoma-tilikauden-lopussa': 'Osakepääoma tilikauden lopussa',
        'sidottu-oma-paaoma': 'Sidottu oma pääoma',
        'sijoitetun-paaoman-rahasto-tilikauden-alussa': 'Sijoitetun vapaan oman pääoman rahasto tilikauden alussa',
        'lisaykset-tilikaudella': 'Lisäykset tilikaudella',
        'vahennykset-tilikaudella': 'Vähennykset tilikaudella',
        'sijoitetun-paaoman-rahasto-tilikauden-lopussa': 'Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa',
        'edellisten-voitto-alussa': 'Edellisten tilikausien voitto (tappio) tilikauden alussa',
        'osingonjako-tilikaudella': 'Osingonjako tilikaudella',
        'edellisten-tilikausien-oikaisut': 'Edellisten tilikausien virheiden oikaisut',
        'omien-osakkeiden-lunastukset': 'Omien osakkeiden lunastukset',
        'omien-osakkeiden-luovutukset': 'Omien osakkeiden luovutukset',
        'edellisten-voitto-lopussa': 'Edellisten tilikausien voitto (tappio) tilikauden lopussa',
        'tilikauden-voitto': 'Tilikauden voitto (tappio)',
        'vapaa-oma-paaoma': 'Vapaa oma pääoma',
        'oma-paaoma-yhteensa': 'Oma pääoma yhteensä',
        'oma-paaoma-paaomalainat': 'Oma pääoma + pääomalainat',
        'paaomalainojen-lainaehdot': 'Pääomalainojen pääasialliset lainaehdot ja lainoille kertynyt kuluksi kirjaamaton korko',
        'jakokelpoinen-oma-paaoma': 'Jakokelpoinen oma pääoma',
        'sijoitetun-paaoman-rahasto': 'Sijoitetun vapaan oman pääoman rahasto',
        'edellisten-tilikausien-voitto': 'Edellisten tilikausien voitto (tappio)',
        'jakokelpoinen-yhteensa': 'Yhteensä',
        'hallituksen-ehdotus-jakokelpoisen': 'Hallituksen ehdotus jakokelpoisen vapaan oman pääoman käytöstä',
        'todettiin-etta-osoittaa-voittoa': 'Todettiin, että vahvistettu tulos osoittaa {{profit}} euron voittoa.',
        'todettiin-etta-osoittaa-tappiota': 'Todettiin, että vahvistettu tulos osoittaa {{profit}} euron tappiota.',
        'osinkoa-ei-jaeta-voitto': 'Hallitus ehdottaa, että osinkoa ei jaeta ja tilikauden voitto kirjataan edellisten tilikausien voitto/tappio -tilille.',
        'osinkoa-ei-jaeta-tappio': ' Hallitus ehdottaa, että osinkoa ei jaeta ja tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.',
        'osinkoa-jaetaan-voitto': 'Hallitus ehdottaa, että osinkoa jaetaan {{dividendPerShare}} euroa per osake eli yhteensä {{dividendTotal}} euroa.\nOsinko on nostettavissa {{dividendPayoutTime}}. Loppuosa tilikauden voitosta kirjataan edellisten tilikausien voitto/tappio -tilille.\n\nHallituksen näkemyksen mukaan ehdotettu osinko ei vaaranna yhtiön maksukykyä.',
        'osinkoa-jaetaan-tappio': 'Hallitus ehdottaa, että osinkoa jaetaan {{dividendPerShare}} euroa per osake eli yhteensä {{dividendTotal}} euroa.\nOsinko on nostettavissa {{dividendPayoutTime}}. Tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.\n\nHallituksen näkemyksen mukaan ehdotettu osinko ei vaaranna yhtiön maksukykyä.',
        'heti-yhtiokokouksen-jalkeen': 'heti yhtiökokouksen jälkeen',
        'kun-poytakirja-on-allekirjoitettu': 'kun varsinaisen yhtiökokouksen pöytäkirja on allekirjoitettu',
        'KPL2_7a': {
          'title': 'Luettelo kirjanpidoista ja aineistoista (KPL 2:7a)',
          'julkiset': 'Julkiset kirjanpitokirjat',
          'tulos-tase-liitetiedot': 'Tulos ja tase sekä tilinpäätöksen liitetiedot',
          'ei-julkiset': 'Ei-julkiset kirjanpitokirjat',
          'tase-erittelyt': 'Tase-erittelyt',
          'tilikartta': 'Tilikartta',
          'tilikauden-paivakirjat-paakirja': 'Tilikauden päiväkirjat ja pääkirja',
          'tilinpaatos': 'Tilinpäätös',
          'tilikohtainen-tuloslaskelma': 'Tilikohtainen tuloslaskelma',
          'tilikohtainen-tase': 'Tilikohtainen tase',
          'tililuettelo': 'Tililuettelo',
          'paa-ja-paivakirjat': 'Pää- ja päiväkirjat'
        },
        'luettelo-esitetty-pdf-dokumentissa': 'Luettelo kirjanpidoista ja aineistoista on esitetty erillisessä pdf-dokumentissa. Dokumenttia säilytetään tilinpäätöksen yhteydessä Tilitoimisto Lemon Tree Oy:n tietojärjestelmässä tai kirjanpitovelvollisen hallinnoimilla sähköisillä tallennusvälineillä.',
        'KPL2_7b': {
          'title': 'Tositteiden lajit ja säilytystavat (KPL 2:7b)',
          'tositelajit': 'Tositelajit',
          'tilikauden-tositteet-ja-muu': 'Tilikauden tositteet ja muu kirjanpitoaineisto sähköisillä tallennusvälineillä',
        },
        'KPL3_7': {
          'title': 'Tilinpäätöksen allekirjoitus (KPL 3:7)',
          'tilinpaatoksen-hyvaksymispvm': 'Tilinpäätöksen hyväksymispäivämäärä',
          'allekirjoitettu-sahkoisesti': 'Allekirjoitettu sähköisesti',
        },
        'TTL3': {
          'title': 'Tilintarkastus (TTL 3)',
          'TTL3_4': 'Tilinpäätösmerkintä (TTL 3:4)'
        },
        'ALLEKIRJOITUKSET': {
          'title': 'Allekirjoitukset',
          'ALLEKIRJOITUSTEN_TEKSTI': 'Allekirjoittajat'
        }
      }
    }
  }
}
