import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef, ViewChild, Input } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { KirjanpitoVeroilmoitusRadioOption } from '../components/veroilmoitus-row-radio.component'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { VeroilmoituksenValueConstants } from 'app/_jaettu-lemonator/model/kirjanpito'
import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'

import { AutojenTyypit } from 'app/_jaettu-lemonator/service/veroilmoitus/tmi/veroilmoitus-laskenta-2022-tmi.service'
import { KirjanpitoVeroilmoitusBaseSpecializedComponent } from '../components/base-veroilmoitus.component'
import { Router } from '@angular/router'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { KirjanpitoVeroilmoitusRowNumberComponent } from '../components/veroilmoitus-row-number.component'
import { KirjanpitoVeroilmoitusCheckOption } from '../components/veroilmoitus-row-check.component'
import { firstValueFrom } from 'rxjs'

@Component({
  selector: '[app-kirjanpito-veroilmoitus-tmi-2022]',
  templateUrl: './veroilmoitus-tmi-2022.component.html',
  styleUrls: ['./veroilmoitus-tmi-2022.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusTmi2022Component extends KirjanpitoVeroilmoitusBaseSpecializedComponent implements OnInit, OnDestroy {

  @ViewChild('edit800', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent800: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit801', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent801: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit802', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent802: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit804', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent804: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit805', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent805: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit806', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent806: KirjanpitoVeroilmoitusRowNumberComponent

  @Input() lukittu: boolean

  v342Korvausparametrit: Korvausparametrit = {}
  v347Korvausparametrit: Korvausparametrit = {}
  v916Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yhdenkertainen', value: VeroilmoituksenValueConstants.FALSE }, { label: 'Kahdenkertainen', value: VeroilmoituksenValueConstants.TRUE }]
  ajojenSelvitystyyppiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Ajopäiväkirjaan', value: '1' }, { label: 'Muuhun selvitykseen', value: '2' }]
  v396Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yksityisajojen kulut on vähennetty kirjanpidossa', value: '1' }, { label: 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa', value: '2' }]
  kyllaEiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Ei', value: VeroilmoituksenValueConstants.FALSE }]
  tyohuonevahennysOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kaavamainen työhuonevähennys', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Työhuonevähennys todellisten kulujen mukaan', value: VeroilmoituksenValueConstants.FALSE }]
  ctrl395Options: KirjanpitoVeroilmoitusRadioOption[] = [
    { label: 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta', value: 'DEFAULT' },
    { label: 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta', value: '1' },
    { label: 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa', value: '2' }
  ]

  private _henkiloautojaOption: KirjanpitoVeroilmoitusCheckOption = { label: 'Henkilöautoja', value: AutojenTyypit.HENKILOAUTOJA, checked: false }
  private _pakujaOption: KirjanpitoVeroilmoitusCheckOption = { label: 'Pakettiautoja', value: AutojenTyypit.PAKETTIAUTOJA, checked: false }
  private _muitaAutojaOption: KirjanpitoVeroilmoitusCheckOption = { label: 'Muita kulkuneuvoja', value: AutojenTyypit.MUITA_AJONEUVOJA, checked: false }
  v379Options: KirjanpitoVeroilmoitusCheckOption[] = [this._henkiloautojaOption, this._pakujaOption, this._muitaAutojaOption]

  kaksinkertainenKirjanpito: boolean = false
  muutaIrtainta: boolean = false
  tyohuonevahennys: boolean = false

  constructor(
    private _router: Router,
    protected _asiakasService: AsiakasService,
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _changeDetector: ChangeDetectorRef
  ) {
    super(_currencyService, _veroilmoitusTranslationService, _changeDetector, _asiakasService)
  }

  ngOnInit() {

    // Set editable values to form
    this.veroilmoitusObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(veroilmo => {

      this.veroilmoitus = veroilmo
      if (this.veroilmoitus) {
        this.asiakkaanNimi = this.veroilmoitus.perustiedot.asiakas.nimi

        // See this.v916Options
        this.kaksinkertainenKirjanpito = this._getValue('916', 'string') === VeroilmoituksenValueConstants.TRUE
        this.muutaIrtainta = this._getValue('muuta-irtainta-omaisuutta', 'string') === VeroilmoituksenValueConstants.TRUE
        this.tyohuonevahennys = this._getValue('tyohuone-vahennys-option', 'string') === VeroilmoituksenValueConstants.TRUE

        // 379 has special initialization logic
        const arvoMap = this.annaKaikkiArvotMapissa('379', this.veroilmoitus.arvot)
        this._henkiloautojaOption.checked = arvoMap.has(AutojenTyypit.HENKILOAUTOJA) || arvoMap.has(AutojenTyypit.HENKILOAUTOJA_JA_PAKETTIAUTOJA)
        this._pakujaOption.checked = arvoMap.has(AutojenTyypit.PAKETTIAUTOJA) || arvoMap.has(AutojenTyypit.HENKILOAUTOJA_JA_PAKETTIAUTOJA)
        this._muitaAutojaOption.checked = arvoMap.has(AutojenTyypit.MUITA_AJONEUVOJA)

        // Korvausparametrit are input for localization texts
        const v342KorvausValue = this._getValue('v342Korvaus1', 'currency') || 0
        this.v342Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v342KorvausValue, 'fi')
        const v347KorvausValue = this._getValue('v347Korvaus1', 'currency') || 0
        this.v347Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v347KorvausValue, 'fi')

      } else {
        this.asiakkaanNimi = ''
        this.v342Korvausparametrit['summa'] = ''
        this.v347Korvausparametrit['summa'] = ''
      }

      this._changeDetector.markForCheck()
    })

  }

  taytaPoistotEhdotus() {
    this.editComponent800.tallennaAnnettuArvo(this._annaEhdotus('800'))
    this.editComponent801.tallennaAnnettuArvo(this._annaEhdotus('801'))
    this.editComponent802.tallennaAnnettuArvo(this._annaEhdotus('802'))
    this.editComponent804.tallennaAnnettuArvo(this._annaEhdotus('804'))
    this.editComponent805.tallennaAnnettuArvo(this._annaEhdotus('805'))
    this.editComponent806.tallennaAnnettuArvo(this._annaEhdotus('806'))
  }

  async avaaKirjanpidonUlkopuolisetKulutLomake() {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    this._router.navigate(['asiakkaat', asiakas.avain, 'kirjanpidon-ulkopuoliset-vahennykset'])
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
