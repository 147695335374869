<div mat-dialog-content>
  <lemon-dialog-header>
    Valitse kieli /<!-- Ändra språk /--> Choose language
  </lemon-dialog-header>
  <mat-radio-group [(ngModel)]="kieli" style="display: inline-flex;flex-direction: column;" cdkFocusRegionstart>
    <mat-radio-button value="fi">Suomi</mat-radio-button>
    <!-- <mat-radio-button value="sv">Svenska</mat-radio-button> -->
    <mat-radio-button value="en">English</mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</div>
