<mat-dialog-content>

  <lemon-dialog-header>
    Haluatko varmasti lähettää taikalinkin?
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <p>Haluatko varmasti lähettää taikalinkin uudelleen käyttäjälle?</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Kyllä, lähetä</button>
</mat-dialog-actions>