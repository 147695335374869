<div mat-dialog-content class="all">

  <lemon-dialog-header>
    {{'lasku.sposti-dialog.laheta-lasku-spostilla' | translate | async}}
  </lemon-dialog-header>

  <div class="containerii" style="backface-visibility: hidden;">
    <div class="keskitettava">
      <form (ngSubmit)="send()" [formGroup]="emailForm" novalidate>
        <div style="margin-bottom: 0.5em">
          <mat-chip-grid #vastaanottajaChips style="margin-bottom: 0.5em">
            <mat-chip-option *ngFor="let vastaanottaja of email.vastaanottajat" [selectable]="false" [removable]="true" (removed)="poistaAsiakas(vastaanottaja)">
              {{vastaanottaja}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-grid>
        </div>
        <mat-form-field>
          <mat-label>{{ 'lasku.sposti-dialog.lisaavastaanottaja' | translate | async }}</mat-label>
          <input type="email" autocomplete="kirjanpitotietoarkki" [matChipInputFor]="vastaanottajaChips" cdkFocusRegionstart (blur)="lisaa()" formControlName="lisaavastaanottaja" (matChipInputTokenEnd)="lisaa()" matInput data-lpignore="true" />
          <!-- <mat-icon matSuffix (click)="lisaa()">add</mat-icon> -->
          <mat-error>{{fieldErrors.lisaavastaanottaja}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'lasku.sposti-dialog.aihe' | translate | async }}</mat-label>
          <input #aihe type="text" formControlName="aihe" matInput required />
          <mat-error>{{fieldErrors.aihe}}</mat-error>
        </mat-form-field>

        <mat-form-field style="margin-top: 20px;">
          <mat-label>{{ 'lasku.sposti-dialog.otsikko' | translate | async }}</mat-label>
          <input type="text" formControlName="otsikko" matInput required />
          <mat-error>{{fieldErrors.otsikko}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'lasku.sposti-dialog.teksti' | translate | async }}</mat-label>
          <textarea rows="2" formControlName="teksti" matInput cdkTextareaAutosize required></textarea>
          <mat-error>{{fieldErrors.teksti}}</mat-error>
        </mat-form-field>
      </form>

      <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 1em; margin-bottom: 1em;">
        {{'lasku.validation.asetukset.oletuksia-sposti' | translate | async}}
      </div>

      <div style="display: flex; align-items: center; justify-content: center; margin-top: 1em;">
        <button class="primarybutton" mat-button (click)="send()" [disabled]="!emailForm.valid">{{'lasku.sposti-dialog.laheta' | translate | async}}</button>
      </div>
    </div>
    <div class="keskitettava">
      <div style="margin: 0; font-size: 15px;" class="light-blue-text">{{'lasku.sposti-dialog.esikatselu' | translate | async}}</div>
      <lasku-email-esikatselu-perinteinen [varit]="varit" [tiedot]="esikatselutiedot | async"></lasku-email-esikatselu-perinteinen>
    </div>
  </div>

</div>