import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { combineLatest, firstValueFrom, Observable, of, pipe, range, throwError, timer, zip } from 'rxjs'
import { switchMap, map, mergeMap, retryWhen } from 'rxjs/operators'

import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { FirebaseLemonator } from './firebase-lemonator.service'

const isDev = environment.environment === EnvironmentType.LOCAL_DEV || environment.environment === EnvironmentType.DEV
// export const LEMONATOR_HTTPS_API = environment.environment === EnvironmentType.DEV ? 'https://dev.lemonator.lemontree.fi' : 'https://lemonator.lemontree.fi'
export const LEMONATOR_HTTPS_IMAGES_API = isDev ? 'https://kuvat-dev.lemonator.lemontree.fi' : 'https://kuvat.lemonator.lemontree.fi'
export const LEMONATOR_CF_API = isDev ? 'https://europe-west1-dev-lemonator-web.cloudfunctions.net' : 'https://europe-west1-eu-lemonator.cloudfunctions.net'

// export type LEMONATOR_API = typeof LEMONATOR_HTTPS_API | typeof LEMONATOR_HTTPS_IMAGES_API
export type LEMONATOR_API = typeof LEMONATOR_HTTPS_IMAGES_API | typeof LEMONATOR_CF_API


// https://angular.io/guide/practical-observable-usage#exponential-backoff
export function backoff(maxTries: number, delay: number) {
  return pipe(
    retryWhen(attempts =>
      zip(range(1, maxTries + 1), attempts).pipe(
        mergeMap(([i, err]) => (i > maxTries) ? throwError(err) : of(i)),
        map(i => i * i),
        mergeMap(v => timer(v * delay))
      )
    )
  )
}


@Injectable()
export class LemonHttpService {

  constructor(
    private http: HttpClient,
    private _firebaseLemonator: FirebaseLemonator
  ) { }

  getIdTokenObservable(forceRefresh?: true): Observable<string> {
    // if (api === LEMONATOR_HTTPS_API || api === LEMONATOR_HTTPS_IMAGES_API) {
    return this._firebaseLemonator.authUserObservable.pipe(
      switchMap(user => {
        if (user) {
          return this._firebaseLemonator.authGetIdToken(user, forceRefresh)
        }
        return of<string>(null)
      })
    )
    // }
    //  else if (api === Api.LEMONAID) {
    //   return this.lemonaidAuth.idToken
    // }
    // throw new Error('Unknown API ' + api)
  }

  getAppCheckTokenObservable(forceRefresh: boolean = false): Observable<string> {
    return this._firebaseLemonator.authUserObservable.pipe(
      switchMap(user => {
        if (user) {
          return this._firebaseLemonator.appCheckGetToken(forceRefresh)
        }
        return of<string>(null)
      })
    )
  }

  // getIdTokenPromise(api: Api): Promise<string> {
  //   if (api === LEMONATOR_HTTPS_API) {
  //     return this.auth.auth.currentUser.getIdToken()
  //   } else if (api === Api.LEMONTREE) {
  //     return this.lemontreeAuth.auth.currentUser.getIdToken()
  //   }
  //   throw new Error('Unknown API ' + api)
  // }

  // public getBinaryObservableForAccessToken(uri: string, idToken: string, api: Api): Observable<Blob | null> {
  //   // Headers
  //   const headers = new HttpHeaders({ 'X-L': idToken })
  //   // console.log('getBinaryObservable this.http.get')
  //   return this.http.get(api + uri, { headers: headers, responseType: 'blob' })
  // }

  // public getBinaryObservable(uri: string, api?: Api): Observable<Blob | null> {
  //   const p = api ? api : LEMONATOR_HTTPS_API
  //   return this.getIdTokenObservable(p).pipe(
  //     switchMap(idToken => {
  //       if (idToken) {
  //         return this.getBinaryObservableForAccessToken(uri, idToken, p)
  //       }
  //       // console.log('getBinaryObservable return null')
  //       return of<Blob | null>(null)
  //     })
  //   )
  // }

  public async getBinary(uri: string, api: LEMONATOR_API, forceRefresh?: true): Promise<Blob> {
    const [token, appCheckToken] = await Promise.all([firstValueFrom(this.getIdTokenObservable(forceRefresh)), firstValueFrom(this.getAppCheckTokenObservable(forceRefresh))])
    if (!token) { return Promise.reject('The token is null.') }
    if (!appCheckToken) { return Promise.reject('The appCheckToken is null.') }
    // Headers
    const headers = new HttpHeaders({
      'X-L': token,
      'X-Firebase-AppCheck': appCheckToken
    })
    return firstValueFrom(this.http.get(api + uri, { headers: headers, responseType: 'blob' }))
  }

  public async postJsonGetBinary(uri: string, payload: any, api: LEMONATOR_API, forceRefresh?: true): Promise<Blob> {
    const [token, appCheckToken] = await Promise.all([firstValueFrom(this.getIdTokenObservable(forceRefresh)), firstValueFrom(this.getAppCheckTokenObservable(forceRefresh))])
    if (!token) { return Promise.reject('The token is null.') }
    if (!appCheckToken) { return Promise.reject('The appCheckToken is null.') }
    // Headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-L': token,
      'X-Firebase-AppCheck': appCheckToken
    })
    return firstValueFrom(this.http.post(api + uri, payload, { headers: headers, responseType: 'blob' }))
  }

  public async postJsonGetJsonWithCredentials<T, P>(uri: string, payload: P, api: LEMONATOR_API, forceRefresh?: true): Promise<T> {
    const [token, appCheckToken] = await Promise.all([firstValueFrom(this.getIdTokenObservable(forceRefresh)), firstValueFrom(this.getAppCheckTokenObservable(forceRefresh))])
    if (!token) { return Promise.reject('The token is null.') }
    if (!appCheckToken) { return Promise.reject('The appCheckToken is null.') }
    // Headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-L': token,
      'X-Firebase-AppCheck': appCheckToken
    })
    return firstValueFrom(this.http.post<T>(api + uri, payload, { headers: headers, responseType: 'json', withCredentials: true }))
  }

  public getJsonWithCredentials<T>(uri: string, api: LEMONATOR_API, forceRefresh?: true): Observable<T> {
    return combineLatest([this.getIdTokenObservable(forceRefresh), this.getAppCheckTokenObservable(forceRefresh)]).pipe(
      switchMap(([idToken, appCheckToken]) => {
        if (idToken && appCheckToken) {
          // Headers
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-L': idToken,
            'X-Firebase-AppCheck': appCheckToken
          })
          return this.http.get<T>(api + uri, { headers: headers, responseType: 'json', withCredentials: true })
        }
        return of<T>(null)
      })
    )
  }

}
