<div class="list-page">

  <div>
    <div style="position: relative;">
      <mat-table matSort [dataSource]="pankkitiedotDataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="asiakasAvain">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Avain </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.asiakasAvain}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="asiakkaanNimi">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nimi </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.asiakkaanNimi }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ytunnus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Y-tunnus </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.ytunnus || '&nbsp;'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pankit">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Pankit </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.pankit || '&nbsp;'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row *matRowDef="let row; columns: naytettavatKolumnit"></mat-row>

      </mat-table>

      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!(lataaObservable | async) && pankkitiedotDataSource?.filteredData?.length == 0">
        <div style="width: 100%; padding-top: 40px; text-align: center;">Tietoja ei löytynyt.</div>
      </div>
      <div *ngIf="lataaObservable | async">
        <div lemon-loading [show]="true"></div>
        Ladattu {{ ladattuObservable | async }} asiakasta.
      </div>

    </div>
  </div>

</div>