import { Injectable } from '@angular/core'
import { FirebaseLemonator } from '../firebase-lemonator.service'

import { KirjanpitajanRooli } from '../../../_jaettu/lemonator/model/kirjanpitaja'

import { Kirjanpitaja, KirjanpitajanNimitiedot, AnnaKirjanpitajienNimitiedotPyynto, AnnaKirjanpitajienNimitiedotVastaus, KirjanpitajanSarake, KirjanpitajanAsetukset } from '../../../_jaettu-lemonator/model/kirjanpitaja'
import { KirjautunutKayttajaService, KirjanpitajanTiedot } from '../kirjautunut-kayttaja.service'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'

import { Observable, of } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { IFirestoreBatch } from 'app/_jaettu-angular/base-firebase.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

@Injectable()
export class KirjanpitajaService {
  kirjanpitajatObservable: Observable<Kirjanpitaja[]>
  kirjautuneenKayttajanKirjanpitajaObservable: Observable<Kirjanpitaja>
  kirjautuneenKayttajanAsetuksetObservable: Observable<KirjanpitajanAsetukset>

  kirjautuneenKayttajanKirjanpitajanSarakkeetObservable: Observable<KirjanpitajanSarake[]>
  herraHolvinSarakkeetObservable: Observable<KirjanpitajanSarake[]>

  kirjanpitajienNimitiedotObservable: Observable<KirjanpitajanNimitiedot[]>
  kirjanpitajienNimitiedotMapObservable: Observable<Map<string, KirjanpitajanNimitiedot>>

  constructor(
    private _firebase: FirebaseLemonator,
    private timestampService: TimestampService,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) {
    this.kirjanpitajienNimitiedotObservable = this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          const pyynto: AnnaKirjanpitajienNimitiedotPyynto = {}
          return this._firebase.functionsCall<AnnaKirjanpitajienNimitiedotPyynto, AnnaKirjanpitajienNimitiedotVastaus>('kirjanpitajatNimitiedotLataa', pyynto).then(
            resp => {
              return resp?.nimitiedot || []
            })
        }
        return of<KirjanpitajanNimitiedot[]>([])
      }),
      map(kirjanpitajat => {
        return kirjanpitajat.sort((a, b) => {
          return a.sukunimi.localeCompare(b.sukunimi)
        })
      }),
      lemonShare()
    )

    this.kirjanpitajienNimitiedotMapObservable = this.kirjanpitajienNimitiedotObservable.pipe(
      map(nimitiedot => {
        const m: Map<string, KirjanpitajanNimitiedot> = new Map()
        if (nimitiedot) {
          for (const n of nimitiedot) {
            m.set(n.avain, n)
          }
        }
        return m
      })
    )

    this.herraHolvinSarakkeetObservable = this._firebase.firestoreCollection<KirjanpitajanSarake>('kirjanpitajat/QgPvtcCjoOdf6Zg7lgMwqLWp2BG2/kirjanpitajan-sarakkeet').listen()

    this.kirjautuneenKayttajanKirjanpitajanSarakkeetObservable = this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return this._firebase.firestoreCollection<KirjanpitajanSarake>('kirjanpitajat/' + kirjanpitajanTiedot.uid + '/kirjanpitajan-sarakkeet').listen()
        }
        return of<KirjanpitajanSarake[]>([])
      })
    )

    this.kirjautuneenKayttajanAsetuksetObservable = this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return this._firebase.firestoreDoc<KirjanpitajanAsetukset>('/kirjanpitajat/' + kirjanpitajanTiedot.uid + '/kirjanpitajan-asetukset/' + kirjanpitajanTiedot.uid).listen()
        }
        return of<KirjanpitajanAsetukset>(null)
      })
    )

    this.kirjautuneenKayttajanKirjanpitajaObservable = this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return this._firebase.firestoreDoc<Kirjanpitaja>('/kirjanpitajat/' + kirjanpitajanTiedot.uid).listen()
        }
        return of<Kirjanpitaja>(null)
      })
    )

    this.kirjanpitajatObservable = this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          if (kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER) {
            return this._firebase.firestoreCollection<Kirjanpitaja>('/kirjanpitajat').listen()
          } else {
            return this._firebase.firestoreCollection<Kirjanpitaja>('/kirjanpitajat').where('avain', '==', kirjanpitajanTiedot.uid).listen()
          }
        }
        return of<Kirjanpitaja[]>([])
      }),
      map(kirjanpitajat => {
        return kirjanpitajat.sort((a, b) => {
          return a.sukunimi.localeCompare(b.sukunimi)
        })
      })
    )
  }

  public annaKirjanpitajaObservable(avain: string): Observable<Kirjanpitaja> {
    return this._firebase.firestoreDoc<Kirjanpitaja>('kirjanpitajat/' + avain).listen()
  }

  public async paivitaKirjanpitaja(kirjanpitaja: Kirjanpitaja): Promise<void> {
    const kirjanpitajanTiedot = await this.kirjautunutKayttajaService.getKirjanpitajanTiedot()
    const batch = this._firebase.firestoreBatch()

    this.lisaaKirjanpitajaBatchiin(kirjanpitaja, kirjanpitajanTiedot, batch)

    return batch.commit()
  }

  public async tallennaSarake(kirjanpitajaUid: string, sarake: KirjanpitajanSarake): Promise<void> {
    const kirjanpitajanTiedot = await this.kirjautunutKayttajaService.getKirjanpitajanTiedot()

    // Lue avaimet
    if (!sarake.avain) { sarake.avain = this._firebase.firestoreCreateId() }
    for (const tila of sarake.tilat) {
      if (!tila.avain) { tila.avain = this._firebase.firestoreCreateId() }
    }

    sarake.tallennettu = this.timestampService.now()
    sarake.tallentanut = kirjanpitajanTiedot.uid

    // Luo uri
    const sarakkeenUri = 'kirjanpitajat/' + kirjanpitajaUid + '/kirjanpitajan-sarakkeet/' + sarake.avain
    const sarakkeenHistoriaUri = 'kirjanpitajat/' + kirjanpitajaUid + '/kirjanpitajan-sarakkeet/' + sarake.avain + '/kirjanpitajan-sarakkeet-historia/' + this._firebase.firestoreCreateId()

    // Luo batch
    const batch = this._firebase.firestoreBatch()
    batch.set<KirjanpitajanSarake>(sarakkeenUri, sarake)
    batch.set<KirjanpitajanSarake>(sarakkeenHistoriaUri, sarake)

    return batch.commit()
  }

  private lisaaKirjanpitajaBatchiin(kirjanpitaja: Kirjanpitaja, kirjanpitajanTiedot: KirjanpitajanTiedot, batch: IFirestoreBatch) {
    // Aseta avain
    // EI KOSKAAN AVAINTA, NÄMÄ OVAT AINA ENNALTA LUOTUJA!!

    // Aseta muut tiedot
    kirjanpitaja.paivitetty = this.timestampService.now()
    kirjanpitaja.paivittaja = kirjanpitajanTiedot.uid
    if (!kirjanpitaja.luoja) { kirjanpitaja.luoja = kirjanpitajanTiedot.uid }
    if (!kirjanpitaja.luotu) { kirjanpitaja.luotu = this.timestampService.now() }

    // Luo reffit
    const uri = 'kirjanpitajat/' + kirjanpitaja.avain
    const historiaUri = uri + '/historia/' + this._firebase.firestoreCreateId()

    // Aseta batchiin
    batch.set<Kirjanpitaja>(uri, kirjanpitaja, { merge: true })
    batch.set<Kirjanpitaja>(historiaUri, kirjanpitaja)
  }

  getHardcodedSalesTeamKirjanpitajat(): Pick<Kirjanpitaja, 'avain' | 'etunimi' | 'sukunimi'>[] {
    return [
      { avain: 'QL4CBlJTLdQLtbb4k8AURFYeg3I3', etunimi: 'Sanna', sukunimi: 'Vihersaari' },
      { avain: 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1', etunimi: 'Laura', sukunimi: 'Pajunen' },
      { avain: 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2', etunimi: 'Holvi', sukunimi: 'Zen' }
    ]
  }

}
