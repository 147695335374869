import { Injectable, Inject } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { DOCUMENT } from '@angular/common'

import { LEMONATOR_HTTPS_IMAGES_API } from '../lemon-http.service'

import { AsiakkaanAvainTiedot } from '../asiakas/asiakas.service'

import { TositeUriService } from 'app/_jaettu/service/tosite/tosite-uri.service'

import { KirjaukseenLiitetynTiedostonSivu, KirjaukseenLiitetynTiedostonSivunTyyppi, KirjaukseenLiitettyjenTiedostojenSivut } from 'app/_jaettu-lemonator/model/kirjanpito'

import { LruMap } from '../../../_jaettu/data-structures/lru-map'
import { HttpClient } from '@angular/common/http'
import { PdfService, SivuPdfsta } from 'app/_jaettu-angular/service/pdf.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { firstValueFrom } from 'rxjs'

export interface KirjauksiinLiitettyjenTiedostojenLataustilanne { kuukausi: LocalMonth, total: number, done: number }
export interface KirjattavienKuittienTiedostojenLataustilanne { total: number, done: number }

@Injectable()
export class KirjanpitoImageService {

  private _fileCache: Map<string, SafeUrl> = new LruMap(20)

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _tositeUriService: TositeUriService,
    private _http: HttpClient,
    private _pdfService: PdfService
  ) { }

  cacheOneImage(sivunAvain: string, safeUrl: SafeUrl) {
    this._fileCache.set(sivunAvain, safeUrl)
  }

  async getImageCache(): Promise<Cache> {
    const defaultView = this._document.defaultView
    const caches = defaultView.caches
    return caches.open('lemon-images')
  }

  async getPdfImages(url: string): Promise<SivuPdfsta[]> {
    const resp = await firstValueFrom(this._http.get(url, { responseType: 'blob' }))
    const arrayBuffer = await resp.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer)
    const pdfObject = await this._pdfService.yritaParsiaPdf(uint8Array)
    return this._pdfService.hoidaPdfnRajayttaminenSivuiksi(pdfObject, 800)
  }

  annaPdfSivujenObservablet(asiakas: AsiakkaanAvainTiedot, sivunAvain: string, sivu: KirjaukseenLiitetynTiedostonSivu): { pakattu: SafeUrl, parempi?: SafeUrl }[] {

    if (sivu.y && sivu.c) {
      const urlit: { pakattu: SafeUrl, parempi?: SafeUrl }[] = []
      for (const s of sivu.c) {
        const fromCache = this._fileCache.get(s)
        if (fromCache) {
          // console.log('fromCache', fromCache)
          urlit.push({ pakattu: fromCache })
        } else {
          const kuvanUrlPacked = this._tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', sivu.y, s + '_packed', 'webp')
          const kuvanUrlQuality = this._tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', sivu.y, s + '_quality', 'webp')
          urlit.push({
            pakattu: LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrlPacked,
            parempi: LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrlQuality
          })
        }
      }
      return urlit
    }

    return null
  }

  annaRaahatunPdfSivujenUrlit(asiakas: AsiakkaanAvainTiedot, sivunAvain: string, sivu: KirjaukseenLiitetynTiedostonSivu): { pakattu: SafeUrl, parempi?: SafeUrl }[] {

    if (sivu.d) {
      const urlit: { pakattu: SafeUrl, parempi?: SafeUrl }[] = []
      for (const s of sivu.d) {
        const fromCache = this._fileCache.get(s)
        if (fromCache) {
          // console.log('fromCache', fromCache)
          urlit.push({ pakattu: fromCache })
        } else {
          urlit.push({
            pakattu: LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + asiakas.avain + '/kirjanpito/tositteet/' + s + '_packed.webp',
            parempi: LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + asiakas.avain + '/kirjanpito/tositteet/' + s + '_quality.webp'
          })
        }
      }
      return urlit
    }

    return null
  }

  annaSivunKuvanUrl(asiakas: AsiakkaanAvainTiedot, sivunAvain: string, sivu: KirjaukseenLiitetynTiedostonSivu, pakattu: boolean): SafeUrl | null {
    const fromCache = this._fileCache.get(sivunAvain)
    if (fromCache) {
      // console.log('fromCache', fromCache)
      return fromCache
    }
    return this.annaKuvanUrl(asiakas, sivunAvain, sivu, pakattu)
  }

  annaSivujenAvaimetJarjestettyna(sivut: KirjaukseenLiitettyjenTiedostojenSivut, holviasiakas: boolean) {
    if (sivut && sivut.t) {
      if (holviasiakas) {
        return Object.keys(sivut.t).sort((a, b) => {
          return sivut.t[b].o - sivut.t[a].o
        })
      }
      return Object.keys(sivut.t).sort((a, b) => {
        return sivut.t[a].o - sivut.t[b].o
      })
    }
    return null
  }

  annaKuvanUrl(asiakas: AsiakkaanAvainTiedot, sivunAvain: string, sivu: KirjaukseenLiitetynTiedostonSivu, pakattu: boolean): string {
    if (asiakas && sivunAvain && sivu) {
      if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_JPEG) {
        // Even though suggested format jpeg, we have optimized webp for this.
        const kuvanAvain = pakattu ? sivunAvain + '_packed' : sivunAvain + '_quality'
        const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', sivu.k, kuvanAvain, 'webp')
        return LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrl
      } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_WEBP) {
        const kuvanAvain = pakattu ? sivunAvain + '_packed' : sivunAvain + '_quality'
        const kuvanUrl = asiakas.avain + '/kirjanpito/tositteet/' + kuvanAvain + '.webp'
        return LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrl
      } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_PDF) {
        const kuvanUrl = asiakas.avain + '/kirjanpito/tositteet/' + sivunAvain + '.pdf'
        return LEMONATOR_HTTPS_IMAGES_API + '/api/1/kirjanpito/tosite/' + kuvanUrl
      } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_PDF) {
        const kuvanUrl = this._tositeUriService.annaCloudStorageAlkuperainenUri(asiakas.asiakasId + '', sivu.k, sivunAvain, sivu.f)
        return LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuitit/kuvat/alkuperainen/' + kuvanUrl
      } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_MUU) {
        const kuvanUrl = this._tositeUriService.annaCloudStorageAlkuperainenUri(asiakas.asiakasId + '', sivu.k, sivunAvain, sivu.f)
        return LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuitit/kuvat/alkuperainen/' + kuvanUrl
      }
    }
    return null
  }

}
