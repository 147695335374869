import { ChangeDetectionStrategy, Component, Input, OnChanges, HostBinding, ChangeDetectorRef, Output, EventEmitter } from '@angular/core'
import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { TallennaArvoBaseEvent, TallennaArvoEvent, TallennaArvotEvent, VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { MatDialog } from '@angular/material/dialog'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'
import { firstValueFrom } from 'rxjs'

export interface KirjanpitoVeroilmoitusCheckOption {
  label: string
  value: string
  checked: boolean
}

@Component({
  selector: '[app-veroilmoitus-row-numb2]',
  templateUrl: './veroilmoitus-row-number-double.component.html',
  styleUrls: ['./veroilmoitus-row.styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s')
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoVeroilmoitusRowNumberDoubleComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges {

  @Output() tallennaArvo: EventEmitter<TallennaArvoBaseEvent> = new EventEmitter<TallennaArvoBaseEvent>()

  @Input() lukittu: boolean
  @Input() numero1: string
  @Input() numero2: string
  @Input() korvausparametrit: Korvausparametrit
  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot
  @Input() showPen: boolean = true
  @Input() editing: boolean = false
  @Input() repeatingMetadata: RepeatingMetadata
  @Input() hideCol1: boolean = false
  @Input() hideCol2: boolean = false

  @Input() desimaaleja: number = 2
  @Input() format: 'currency' | 'number' | 'string' | 'km' | 'percentage' | 'kpl'
  @Input() log: boolean = false
  @Input() naytaEhdotus: boolean = false

  @HostBinding('class') classes = 'block-row centered-row double-row'

  label: string = ''
  header1: string = ''
  header2: string = ''
  help: string = null
  end: string = ''
  onkoNumeerinen: boolean = true

  saving1: boolean = false
  saveSuccess1: boolean = false
  saveFailure1: boolean = false

  saving2: boolean = false
  saveSuccess2: boolean = false
  saveFailure2: boolean = false

  value1: number = null
  value2: number = null

  constructor(
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    protected _currencyService: CurrencyService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero1 && this.numero2 && this.veroilmoitus?.perustiedot?.asiakas) {

      // Set label
      const numero = this.numero1 + '-' + this.numero2
      this.label = this._localizeNumero(numero, this.veroilmoitus, this.korvausparametrit)
      this.header1 = this._localizeNumeroHeader(this.numero1, this.veroilmoitus, this.korvausparametrit)
      this.header2 = this._localizeNumeroHeader(this.numero2, this.veroilmoitus, this.korvausparametrit)
      this.help = this._localizeNumeroHelp(numero, this.veroilmoitus, this.korvausparametrit)

      this.classes = this.classes.replace('has-col-header', '')
      if (this.header1 || this.header2) {
        this.classes += ' has-col-header'
      }

      const punakynaTunniste = this.annaTiedonTunniste(numero, this.repeatingMetadata)
      // Enabled / Disabled state
      if (
        this.veroilmoitus.punakynaNumbers &&
        this.veroilmoitus.punakynaNumbers[punakynaTunniste] === 1 &&
        !this.editing
      ) {
        // if (this.log) { console.log('ENABLE CHECK COMPONENTISSA DUE TO PUNAKYNÄ') }
        this.editing = true
      }

      const identifier1 = this.annaTiedonTunniste(this.numero1, this.repeatingMetadata)
      const identifier2 = this.annaTiedonTunniste(this.numero2, this.repeatingMetadata)

      // Set value
      const val1 = this._getNumberValue(identifier1, this.veroilmoitus, this.repeatingMetadata)
      if (this.value1 !== val1) { this.value1 = val1 }
      const val2 = this._getNumberValue(identifier2, this.veroilmoitus, this.repeatingMetadata)
      if (this.value2 !== val2) { this.value2 = val2 }


    } else {
      this.label = ''
      this.help = null
      this.value1 = null
      this.value2 = null
    }
    this.end = this._getEndMarking()
    this.onkoNumeerinen = this.format === 'currency' || this.format === 'km' || this.format === 'percentage' || this.format === 'number' || this.format === 'kpl'
  }

  private _getEndMarking(): string {
    if (this.format === 'currency') {
      return '€'
    }
    if (this.format === 'km') {
      return 'km'
    }
    if (this.format === 'percentage') {
      return '%'
    }
    if (this.format === 'kpl') {
      return 'kpl'
    }
    return ''
  }

  public onBlur1() {
    // console.log(this.numero, 'On blur', this.value)
    this.tallennaAnnetutArvot(true, false, this.value1, this.value2)
  }

  public onBlur2() {
    // console.log(this.numero, 'On blur', this.value)
    this.tallennaAnnetutArvot(false, true, this.value1, this.value2)
  }

  public onChange1() {
    // console.log(this.numero, 'On change', this.value)
    const identifier = this.annaTiedonTunniste(this.numero1, this.repeatingMetadata)
    const tallennusData: TallennaArvoEvent = {
      arvo: this.value1,
      numero: identifier,
      tyyppi: 'one',
      updateOnly: true
    }
    this.tallennaArvo.next(tallennusData)
  }

  public onChange2() {
    // console.log(this.numero, 'On change', this.value)
    const identifier = this.annaTiedonTunniste(this.numero2, this.repeatingMetadata)
    const tallennusData: TallennaArvoEvent = {
      arvo: this.value2,
      numero: identifier,
      tyyppi: 'one',
      updateOnly: true
    }
    this.tallennaArvo.next(tallennusData)
  }

  public tallennaAnnetutArvot(tallenna1: boolean, tallenna2: boolean, arvo1: any, arvo2: any, extraOnSuccess?: () => void, punakyna?: boolean) {
    const arvot: { numero: string, arvo: string | number }[] = []
    if (tallenna1) {
      this.saveFailure1 = false
      this.saveSuccess1 = false
      this.saving1 = true
      const value1 = this._annaTallentamistaVarten(this.numero1, arvo1, this.onkoNumeerinen, this.veroilmoitus)
      const identifier1 = this.annaTiedonTunniste(this.numero1, this.repeatingMetadata)
      arvot.push({ numero: identifier1, arvo: value1 })
    }
    if (tallenna2) {
      this.saveFailure2 = false
      this.saveSuccess2 = false
      this.saving2 = true
      const value2 = this._annaTallentamistaVarten(this.numero2, arvo2, this.onkoNumeerinen, this.veroilmoitus)
      const identifier2 = this.annaTiedonTunniste(this.numero2, this.repeatingMetadata)
      arvot.push({ numero: identifier2, arvo: value2 })
    }

    const tunniste = this.annaTiedonTunniste(this.numero1 + '-' + this.numero2, this.repeatingMetadata)
    const tallennusData: TallennaArvotEvent = {
      arvot: arvot,
      numero: tunniste,
      tyyppi: 'multiple',
      doneCallback: (outcome) => {
        if (tallenna1) { this.saving1 = false }
        if (tallenna2) { this.saving2 = false }
        if (outcome === 'error') {
          if (tallenna1) {
            this.saveFailure1 = true
            this.saveSuccess1 = false
          }
          if (tallenna2) {
            this.saveFailure2 = true
            this.saveSuccess2 = false
          }
        } else if (outcome === 'success') {
          if (tallenna1) {
            this.saveFailure1 = false
            this.saveSuccess1 = true
          }
          if (tallenna2) {
            this.saveFailure2 = false
            this.saveSuccess2 = true
          }
        } else {
          if (tallenna1) {
            this.saveFailure1 = false
            this.saveSuccess1 = false
          }
          if (tallenna2) {
            this.saveFailure2 = false
            this.saveSuccess2 = false
          }
        }
        this._changeDetectorRef.markForCheck()
        setTimeout(() => {
          if (tallenna1) { this.saveSuccess1 = false }
          if (tallenna2) { this.saveSuccess2 = false }
          if (extraOnSuccess) {
            extraOnSuccess()
          }
          this._changeDetectorRef.markForCheck()
          setTimeout(() => {
            this._changeDetectorRef.markForCheck()
            this._changeDetectorRef.detectChanges()
          }, 250)
        }, 1000)
      }
    }
    if (punakyna === true) {
      tallennusData.setPunakynaOpen = true
    }
    if (punakyna === false) {
      tallennusData.removePunakynaOpen = true
    }
    this.tallennaArvo.next(tallennusData)
  }

  async toggleEdit() {

    const dialogText = this.editing ? 'Oletko varma, että haluat palauttaa automatiikan tälle riville? Huomaa, että menetät tällöin mahdollisen itse syöttämäsi arvon.' :
      'Tämän rivin arvo täytetään automaattisesti. Haluatko varmasti yliajaa automatiikan? Huomaa, että mahdolliset muutokset kirjanpidossa eivät tämän jälkeen enää päivity tälle riville.'

    const dialogData: AreYouSureDialogData = {
      rightAction: 'Kyllä',
      leftAction: 'Ei',
      text: dialogText,
      header: this.editing ? 'Palauta automatiikka?' : 'Muokkaa automaattista arvoa?'
    }

    await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(yes => {
      if (yes) {
        if (this.editing) {
          this.tallennaAnnetutArvot(true, true, undefined, undefined, () => {
            this.editing = false
          }, false)
        } else {
          this.editing = true
          this.tallennaAnnetutArvot(true, true, this.value1, this.value2, () => { }, true)
        }
      }
    })

  }

  ehdotus() {
    this.value1 = this._annaNumeroEhdotus(this.numero1, this.veroilmoitus)
    this.value2 = this._annaNumeroEhdotus(this.numero2, this.veroilmoitus)
    this.tallennaAnnetutArvot(true, true, this.value1, this.value2)
  }

}
