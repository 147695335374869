<ng-template #otsikko>
  <div style="display: flex; justify-content: space-between; align-items: center;" class="otsikko">
    <div class="light-blue-text">YTJ-tiedot</div>
  </div>
</ng-template>
<ng-container *ngIf="selectedYtjAsiakasData; let ytjAsiakasData else otsikko">
  <div style="display: flex; justify-content: space-between; align-items: center;" class="otsikko">
    <div class="light-blue-text">YTJ-tiedot</div>
    <div style="font-size: 11.2px;" class="dark-gray-text">Tiedot tarkistettu viimeksi YTJ:stä {{ytjAsiakasData.tiedotPaivitetty}}</div>
  </div>
  <div class="info-box">

    <table class="ytj-table">
      <tr>
        <td>
          <table class="ytj-table-1">
            <tr>
              <td>Toiminimi</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['toiminimi']">{{ytjAsiakasData.toiminimi}}</td>
            </tr>
            <tr>
              <td>Päätoimiala</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['paatoimiala']">{{ytjAsiakasData.paatoimiala}} ({{ytjAsiakasData.paatoimialakoodi}})</td>
            </tr>
            <tr>
              <td>Kotipaikka</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['kotipaikka']">{{ytjAsiakasData.koti}}</td>
            </tr>
            <tr *ngIf="ytjAsiakasData.onkoPostiosoite">
              <td>Postiosoite</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['postiosoite.co'] ||
                !!ytjAsiakasData.versionChangesMap['postiosoite.katu'] ||
                !!ytjAsiakasData.versionChangesMap['postiosoite.nro'] ||
                !!ytjAsiakasData.versionChangesMap['postiosoite.tmp'] ||
                !!ytjAsiakasData.versionChangesMap['postiosoite.maa'] ||
                !!ytjAsiakasData.versionChangesMap['postiosoite.lisatiedot']">
                <div *ngIf="ytjAsiakasData.postiosoite.co">
                  CO {{ytjAsiakasData.postiosoite.co}}
                </div>
                {{ytjAsiakasData.postiosoite.katu}} {{ytjAsiakasData.postiosoite.postinro}} {{ytjAsiakasData.postiosoite.postitmp}} {{ytjAsiakasData.postiosoite.maa}}
                <div *ngIf="ytjAsiakasData.postiosoite.lisatiedot">
                  {{ytjAsiakasData.postiosoite.lisatiedot}}
                </div>
              </td>
            </tr>
            <tr *ngIf="ytjAsiakasData.onkoKayntiosoite">
              <td>Käyntiosoite</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['kayntiosoite.co'] ||
                !!ytjAsiakasData.versionChangesMap['kayntiosoite.katu'] ||
                !!ytjAsiakasData.versionChangesMap['kayntiosoite.nro'] ||
                !!ytjAsiakasData.versionChangesMap['kayntiosoite.tmp'] ||
                !!ytjAsiakasData.versionChangesMap['kayntiosoite.maa'] ||
                !!ytjAsiakasData.versionChangesMap['kayntiosoite.lisatiedot']">
                <div *ngIf="ytjAsiakasData.kayntiosoite.co">
                  CO {{ytjAsiakasData.kayntiosoite.co}}
                </div>
                {{ytjAsiakasData.kayntiosoite.katu}} {{ytjAsiakasData.kayntiosoite.postinro}} {{ytjAsiakasData.kayntiosoite.postitmp}} {{ytjAsiakasData.kayntiosoite.maa}}
                <div *ngIf="ytjAsiakasData.kayntiosoite.lisatiedot">
                  {{ytjAsiakasData.kayntiosoite.lisatiedot}}
                </div>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>Konkurssi</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['konkurssissa']">{{ytjAsiakasData.konkurssissa ? 'Kyllä' : 'Ei'}}</td>
            </tr>
            <tr>
              <td>Velkajarjestelyssa</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['velkajarjestelyssa']">{{ytjAsiakasData.velkajarjestelyssa ? 'Kyllä' : 'Ei'}}</td>
            </tr>
            <tr>
              <td>Saneerauksessa</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['saneerauksessa']">{{ytjAsiakasData.saneerauksessa ? 'Kyllä' : 'Ei'}}</td>
            </tr>
            <tr>
              <td>Selvitystilassa</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['selvitystilassa']">{{ytjAsiakasData.selvitystilassa ? 'Kyllä' : 'Ei'}}</td>
            </tr>
            <tr>
              <td>Toiminta keskeytetty</td>
              <td [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['toimintakeskeytetty']">{{ytjAsiakasData.toimintakeskeytetty ? 'Kyllä' : 'Ei'}}</td>
            </tr>
          </table>
        </td>
        <td>
          <table class="rekisteri-table">
            <tr *ngFor="let rekisteri of ytjAsiakasData.rekisterit" [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['rekisteri']">
              <td>{{rekisteri.nimi}}</td>
              <td>{{rekisteri.tila}} ({{rekisteri.p | lokaalipvm | async}})</td>
            </tr>
            <ng-container *ngIf="ytjAsiakasData.yhteystiedot?.length">
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr *ngFor="let yhteystieto of ytjAsiakasData.yhteystiedot; let i = index" [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['yhteystiedot']">
                <td>Yhteystieto {{i+1}}</td>
                <td>{{yhteystieto.yhteystieto}}</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="ytjAsiakasData.henkilo">
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr [class.ytj-changed]="!!ytjAsiakasData.versionChangesMap['henkilo']">
                <td>Yhteyshenkilö</td>
                <td>{{ytjAsiakasData.henkilo}}</td>
              </tr>
            </ng-container>
          </table>
        </td>
      </tr>
    </table>

    <div style="display: flex;">
      <div class="blue-text">
        <button [disabled]="!ytjPrevActive" mat-button (click)="selectDisplayedYtjHistory($event, 'prev', ytjAsiakasData.asiakasAvain)">&lt; Edellinen versio</button>
        <span style="font-size: 80%; padding: 0 5px;" class="dark-gray-text">versio tallennettu {{ytjAsiakasData.versioLuotu}}</span>
        <button [disabled]="!ytjNextActive" mat-button (click)="selectDisplayedYtjHistory($event, 'next', ytjAsiakasData.asiakasAvain)">Seuraava versio ></button>
      </div>
      <button class="primarybutton" *ngIf="kuittaaMuutoksetNahdyiksiNakyvissaObservable | async" style="margin-left: 20px;" mat-button (click)="kuittaaMuutoksetNahdyiksi($event)">Kuittaa muutokset nähdyiksi</button>
    </div>

  </div>

</ng-container>