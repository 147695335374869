<mat-dialog-content>

  <lemon-dialog-header>
    Jaksota kirjaus
  </lemon-dialog-header>

  <div class="tosite-tiedot">

    <div *ngIf="virhe" class="error-text" style="font-size: 120%; padding: 15px;">
      {{virhe}}
    </div>

    <form [formGroup]="form" novalidate style="margin: 0;">
      <mat-form-field>
        <mat-label>Päivämäärä</mat-label>
        <input type="text" [max]="viimeinenPaiva" [min]="ensimmainenPaiva" [matDatepicker]="picker" [name]="namename" formControlName="kirjauksenPvm" required matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-error>Jaksotuksen päivämäärä puuttuu</mat-error>
      </mat-form-field>
      <mat-datepicker #picker></mat-datepicker>
      <mat-form-field>
        <mat-label>Maksutapa</mat-label>
        <mat-select formControlName="maksutapa">
          <mat-option *ngFor="let mt of kaytettavatMaksutavat" [value]="'' + mt.tunniste">{{ mt.nimi }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button [disabled]="!!virhe" (click)="jaksota()">Jaksota</button>
</mat-dialog-actions>