import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Validators, AbstractControl, FormControl, FormGroup } from '@angular/forms'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { MaaService, Maa } from '../../_jaettu-angular/service/maa.service'

import { Asiakas, Asiakastiedot } from '../../_jaettu-lemonator/model/asiakas'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { AsiakasKopioija } from '../../_angular/service/asiakas/asiakas.kopioija'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { Kirjanpitaja } from '../../_jaettu-lemonator/model/kirjanpitaja'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'
import { DateService } from '../../_shared-core/service/date.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'

import { Subject, ReplaySubject, Observable, of } from 'rxjs'
import { takeUntil, switchMap, map } from 'rxjs/operators'
import { DebugService } from 'app/_angular/service/debug.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'

interface MainForm {
  nimi: FormControl<string>
  ytunnus: FormControl<string>
  katuosoite: FormControl<string>
  maa: FormControl<string>
  postinro: FormControl<string>
  postitoimipaikka: FormControl<string>

  laskunKieli: FormControl<TuettuKieli>
  kuvausKuvaus: FormControl<string>
  kuvausAloitus: FormControl<string>
  kuvausHuomioita: FormControl<string>

  palaveriOnkoPidetty: FormControl<boolean>
  palaveriTyyppi: FormControl<'puhelin' | 'Google Meet' | 'toimisto'>
  palaveriPvm: FormControl<Date>

  tositemaaratKuitit: FormControl<number>
  tositemaaratMyyntilaskut: FormControl<number>
  tositemaaratMyyntiraportit: FormControl<number>
  tositemaaratPalkkalaskelmat: FormControl<number>
  tositemaaratMuuta: FormControl<string>

  maksutavatLasku: FormControl<boolean>
  maksutavatKorttipaate: FormControl<boolean>
  maksutavatKorttipaateLisa: FormControl<boolean>
  maksutavatVerkkokauppa: FormControl<boolean>
  maksutavatVerkkokauppaMaksutavat: FormControl<string>
  maksutavatKateinen: FormControl<boolean>
  maksutavatTilitykset: FormControl<boolean>
  maksutavatTilityksetMita: FormControl<string>

  pankkiPankki: FormControl<string>
  pankkiYhteys: FormControl<boolean>
  pankkiEiTilia: FormControl<boolean>

  auto: FormControl<boolean>
  autoTyyppi: FormControl<'henkilo' | 'paketti'>
  autoAjo: FormControl<'enemmanyksityis' | 'enemmantyo' | 'kokonaantyo'>
  autoLisa: FormControl<string>

  sijoitus: FormControl<boolean>
  sijoitusLisa: FormControl<string>

  ohjelmisto: FormControl<'lemonaid' | 'holvi' | 'molemmat'>
  ohjelmistoLisa: FormControl<string>

  palkatLisa: FormControl<string>

  ossRekisterissa: FormControl<boolean>
  estaAlvKasittely: FormControl<boolean>
  testiasiakas: FormControl<boolean>
  tilinpaatosRekisteroidaanBruttotuloslaskelmalla: FormControl<boolean>

  saleDoneBy: FormControl<string>
}

@Component({
  templateUrl: './asiakastiedot.component.html',
  styleUrls: ['./asiakastiedot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsiakasAsetuksetAsiakastiedotComponent implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  suodatetutMaat: ReplaySubject<Maa[]> = new ReplaySubject(1)

  asiakasUriObservable: Observable<string>
  asiakasEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  asiakkaanApixTiedotObservable: Observable<Partial<ApixReceivedInvoiceConfig>>

  form: FormGroup<MainForm>
  fieldErrors: FieldErrors = {}

  isAdminObservable: Observable<boolean>
  asiakas: Asiakas = null
  kirjanpitajat: Kirjanpitaja[] = []
  inputname = 'asdfewqrphoki' + Math.random()
  tiedot: Asiakastiedot = null
  ytunnusVirhe: boolean = false

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _asiakasService: AsiakasService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _asiakasKopioija: AsiakasKopioija,
    private _copyService: KopioijaPalvelu,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _maaService: MaaService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _timestampService: TimestampProviderBase,
    private _dateService: DateService,
    private _codeCheckService: CodeCheckService,
    private _asiakasUriService: AsiakasUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) {
    this.asiakas = this._asiakasKopioija.annaUusiAsiakas()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.asiakasUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'asiakkaat/' + avainTiedot.avain
        }
        return ''
      })
    )
    this.asiakasEncodedUriObservable = this.asiakasUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.isAdminObservable = this._kirjautunutKayttajaService.kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable

    this.asiakkaanApixTiedotObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(avainTiedot => {
        if (avainTiedot?.avain) {
          const uri = 'customers/' + avainTiedot.avain + '/apix-received-invoice-config/' + avainTiedot.avain
          return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>(uri).listen().pipe(map(data => data || {}))
        }
        return of<Partial<ApixReceivedInvoiceConfig>>({})
      })
    )


    // const lemonaidFirestore = await LemonatorFirebaseService.lemonaidFirestore
    //   const kirjanpitajaPromise = LemonatorServices.inject.kirjanpitajaService.annaKirjanpitaja(asiakas.kasittelija)
    //   const asiakkaanApixTiedotPromise = lemonaidFirestore.doc(this._apixUriService.getReceivedInvoiceConfigUri(asiakas.avain)).get().then(doc => doc.data() as ApixReceivedInvoiceConfig)

    this.form = new FormGroup<MainForm>({
      'nimi': new FormControl<string>('', [Validators.required]),
      'ytunnus': new FormControl<string>('', [Validators.required]),
      'katuosoite': new FormControl<string>('', []),
      'maa': new FormControl<string>('', []),
      'postinro': new FormControl<string>('', []),
      'postitoimipaikka': new FormControl<string>('', []),

      'laskunKieli': new FormControl<TuettuKieli>(null, []),

      'kuvausKuvaus': new FormControl<string>(null, []),
      'kuvausAloitus': new FormControl<string>(null, []),
      'kuvausHuomioita': new FormControl<string>(null, []),

      'palaveriOnkoPidetty': new FormControl<boolean>(null, []),
      'palaveriTyyppi': new FormControl<'puhelin' | 'Google Meet' | 'toimisto'>(null, []),
      'palaveriPvm': new FormControl<Date>(null, []),

      'tositemaaratKuitit': new FormControl<number>(null, []),
      'tositemaaratMyyntilaskut': new FormControl<number>(null, []),
      'tositemaaratMyyntiraportit': new FormControl<number>(null, []),
      'tositemaaratPalkkalaskelmat': new FormControl<number>(null, []),
      'tositemaaratMuuta': new FormControl<string>(null, []),

      'maksutavatLasku': new FormControl<boolean>(null, []),
      'maksutavatKorttipaate': new FormControl<boolean>(null, []),
      'maksutavatKorttipaateLisa': new FormControl<boolean>(null, []),
      'maksutavatVerkkokauppa': new FormControl<boolean>(null, []),
      'maksutavatVerkkokauppaMaksutavat': new FormControl<string>(null, []),
      'maksutavatKateinen': new FormControl<boolean>(null, []),
      'maksutavatTilitykset': new FormControl<boolean>(null, []),
      'maksutavatTilityksetMita': new FormControl<string>(null, []),

      'pankkiPankki': new FormControl<string>(null, []),
      'pankkiYhteys': new FormControl<boolean>(null, []),
      'pankkiEiTilia': new FormControl<boolean>(null, []),

      'auto': new FormControl<boolean>(null, []),
      'autoTyyppi': new FormControl<'henkilo' | 'paketti'>(null, []),
      'autoAjo': new FormControl<'enemmanyksityis' | 'enemmantyo' | 'kokonaantyo'>(null, []),
      'autoLisa': new FormControl<string>(null, []),

      'sijoitus': new FormControl<boolean>(null, []),
      'sijoitusLisa': new FormControl<string>(null, []),

      'ohjelmisto': new FormControl<'lemonaid' | 'holvi' | 'molemmat'>(null, []),
      'ohjelmistoLisa': new FormControl<string>(null, []),

      'palkatLisa': new FormControl<string>(null, []),

      'ossRekisterissa': new FormControl<boolean>(null, []),
      'estaAlvKasittely': new FormControl<boolean>(null, []),
      'testiasiakas': new FormControl<boolean>(null, []),
      'tilinpaatosRekisteroidaanBruttotuloslaskelmalla': new FormControl<boolean>(null, []),

      'saleDoneBy': new FormControl<string>({ value: null, disabled: true })
    })

    // Bind to changes
    this.form.get('nimi').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.nimi = this._validationService.processValue(value) } })
    this.form.get('ytunnus').valueChanges.subscribe(value => {
      if (this.asiakas) {
        this.asiakas.ytunnus = this._validationService.processValue(value)
        if (this.asiakas.ytunnus && !this._codeCheckService.isValidYTunnus(this.asiakas.ytunnus)) {
          this.ytunnusVirhe = true
        } else {
          this.ytunnusVirhe = false
        }
      }
    })
    this.form.get('katuosoite').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.katuosoite = this._validationService.processValue(value) } })
    this.form.get('maa').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.maa = this._validationService.processValue(value) } })
    this.form.get('postinro').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.postinro = this._validationService.processValue(value) } })
    this.form.get('postitoimipaikka').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.postitmp = this._validationService.processValue(value) } })

    this.form.get('laskunKieli').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.laskunKieli = value || null } })

    this.form.get('kuvausKuvaus').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.kuvausKuvaus = value || null } })
    this.form.get('kuvausAloitus').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.kuvausAloitus = value || null } })
    this.form.get('kuvausHuomioita').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.kuvausHuomioita = value || null } })

    this.form.get('palaveriOnkoPidetty').valueChanges.subscribe(value => {
      if (this.asiakas) {
        this.asiakas.onkoPalaveriPidetty = this._validationService.processValue(value)
      }
      // if (value) {
      //   this.form.get('palaveriPvm').setValidators([Validators.required])
      //   this.form.get('palaveriTyyppi').setValidators([Validators.required])
      // } else {
      //   this.form.get('palaveriPvm').clearValidators()
      //   this.form.get('palaveriTyyppi').clearValidators()
      // }
      // this.form.get('palaveriPvm').updateValueAndValidity()
      // this.form.get('palaveriTyyppi').updateValueAndValidity()
    })
    this.form.get('palaveriTyyppi').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.palaveriTyyppi = this._validationService.processValue(value) } })
    this.form.get('palaveriPvm').valueChanges.subscribe(value => { if (this.asiakas) { this.asiakas.palaveriPidettyPvm = this._timestampService.dateToTimestamp(value) } })

    this.form.get('tositemaaratKuitit').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.tositemaaratKuitit = this._validationService.processValue(value) } })
    this.form.get('tositemaaratMyyntilaskut').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.tositemaaratMyyntilaskut = this._validationService.processValue(value) } })
    this.form.get('tositemaaratMyyntiraportit').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.tositemaaratMyyntiraportit = this._validationService.processValue(value) } })
    this.form.get('tositemaaratPalkkalaskelmat').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.tositemaaratPalkkalaskelmat = this._validationService.processValue(value) } })
    this.form.get('tositemaaratMuuta').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.tositemaaratMuuta = this._validationService.processValue(value) } })

    this.form.get('maksutavatLasku').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatLasku = this._validationService.processValue(value) } })
    this.form.get('maksutavatKorttipaate').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatKorttipaate = this._validationService.processValue(value) } })
    this.form.get('maksutavatKorttipaateLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatKorttipaateLisa = this._validationService.processValue(value) } })
    this.form.get('maksutavatVerkkokauppa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatVerkkokauppa = this._validationService.processValue(value) } })
    this.form.get('maksutavatVerkkokauppaMaksutavat').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatVerkkokauppaMaksutavat = this._validationService.processValue(value) } })
    this.form.get('maksutavatKateinen').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatKateinen = this._validationService.processValue(value) } })
    this.form.get('maksutavatTilitykset').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatTilitykset = this._validationService.processValue(value) } })
    this.form.get('maksutavatTilityksetMita').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.maksutavatTilityksetMita = this._validationService.processValue(value) } })

    this.form.get('pankkiPankki').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.pankkiPankki = this._validationService.processValue(value) } })
    this.form.get('pankkiYhteys').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.pankkiYhteys = this._validationService.processValue(value) } })
    this.form.get('pankkiEiTilia').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.pankkiEiTilia = this._validationService.processValue(value) } })

    this.form.get('auto').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.auto = this._validationService.processValue(value) } })
    this.form.get('autoTyyppi').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.autoTyyppi = this._validationService.processValue(value) } })
    this.form.get('autoAjo').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.autoAjo = this._validationService.processValue(value) } })
    this.form.get('autoLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.autoLisa = this._validationService.processValue(value) } })

    this.form.get('sijoitus').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.sijoitus = this._validationService.processValue(value) } })
    this.form.get('sijoitusLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.sijoitusLisa = this._validationService.processValue(value) } })

    this.form.get('ohjelmisto').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.ohjelmisto = this._validationService.processValue(value) } })
    this.form.get('ohjelmistoLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.ohjelmistoLisa = this._validationService.processValue(value) } })

    this.form.get('palkatLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.palkatLisa = this._validationService.processValue(value) } })

    this.form.get('ossRekisterissa').valueChanges.subscribe(value => {
      if (this.asiakas) {
        if (value) {
          this.asiakas.ossRekisterissa = 1
        } else {
          delete this.asiakas.ossRekisterissa
        }
      }
    })
    this.form.get('estaAlvKasittely').valueChanges.subscribe(value => {
      if (this.asiakas) {
        if (value) {
          this.asiakas.kasittelePalkatAlvittomana = 1
        } else {
          delete this.asiakas.kasittelePalkatAlvittomana
        }
      }
    })

    this.form.get('testiasiakas').valueChanges.subscribe(value => {
      if (this.asiakas) {
        if (value) {
          this.asiakas.testiasiakas = 1
        } else {
          delete this.asiakas.testiasiakas
        }
      }
    })

    this.form.get('tilinpaatosRekisteroidaanBruttotuloslaskelmalla').valueChanges.subscribe(value => {
      if (this.asiakas) {
        if (value) {
          this.asiakas.tilinpaatosRekisteroidaanBruttotuloslaskelmalla = 1
        } else {
          delete this.asiakas.tilinpaatosRekisteroidaanBruttotuloslaskelmalla
        }
      }
    })

    // this.form.get('riskiarvio').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.riskiarvio = this._validationService.processValue(value) } })
    // this.form.get('riskiarvioLisa').valueChanges.subscribe(value => { if (this.tiedot) { this.tiedot.risikiarvioLisa = this._validationService.processValue(value) } })

    this._maaService.kaikkiMaatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(maat => {
      this.suodatetutMaat.next(maat)
    })

    const asiakasJaAsiakastiedotObservable: Observable<{ asiakas: Asiakas, tiedot: Asiakastiedot }> = this._asiakasService.nykyinenAsiakasObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreDoc<Asiakastiedot>(this._asiakasUriService.annaAsiakastiedotUri(asiakas.avain)).listen().pipe(
            map(asiakastiedot => {
              if (asiakas) {
                if (asiakastiedot) {
                  return { asiakas: asiakas, tiedot: asiakastiedot }
                }
                const t: Asiakastiedot = {
                  asiakasAvain: null,

                  kuvausKuvaus: null,
                  kuvausAloitus: null,
                  kuvausHuomioita: null,

                  tositemaaratKuitit: null,
                  tositemaaratMyyntilaskut: null,
                  tositemaaratMyyntiraportit: null,
                  tositemaaratPalkkalaskelmat: null,
                  tositemaaratMuuta: null,

                  maksutavatLasku: null,
                  maksutavatKorttipaate: null,
                  maksutavatKorttipaateLisa: null,
                  maksutavatVerkkokauppa: null,
                  maksutavatVerkkokauppaMaksutavat: null,
                  maksutavatKateinen: null,
                  maksutavatTilitykset: null,
                  maksutavatTilityksetMita: null,

                  pankkiPankki: null,
                  pankkiYhteys: null,
                  pankkiEiTilia: null,

                  auto: null,
                  autoTyyppi: null,
                  autoAjo: null,
                  autoLisa: null,

                  sijoitus: null,
                  sijoitusLisa: null,

                  ohjelmisto: null,
                  ohjelmistoLisa: null,

                  palkatLisa: null,

                  // riskiarvio: null,
                  // risikiarvioLisa: null,
                  riskiarvioStatus: 'puuttuu',

                  avain: null,
                  luoja: null,
                  luotu: null,
                  paivittaja: null,
                  paivitetty: null,
                  poistettu: null
                }
                return { asiakas: asiakas, tiedot: t }
              }
              return null
            })
          )
        }
        return of(null)
      })
    )

    asiakasJaAsiakastiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asiakasJaTiedot => {
      if (asiakasJaTiedot) {
        this.asiakas = this._asiakasKopioija.kopioiAsiakas(asiakasJaTiedot.asiakas)
        this.tiedot = this._copyService.cloneObjectDeep(asiakasJaTiedot.tiedot)
        this.alustaLomakkeenTiedot(this.asiakas, this.tiedot)
      }
      setTimeout(() => {
        this.nimiInput.focus()
        this._changeDetectorRef.markForCheck()
      }, 10)
    })

    this._kirjanpitajaService.kirjanpitajatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kirjanpitajat => {
      this.kirjanpitajat = kirjanpitajat
    })

  }

  private alustaLomakkeenTiedot(asiakas: Asiakas, tiedot: Asiakastiedot) {
    this.form.get('nimi').setValue(this._validationService.processValue(asiakas.nimi))
    this.form.get('ytunnus').setValue(this._validationService.processValue(asiakas.ytunnus))
    this.form.get('katuosoite').setValue(this._validationService.processValue(asiakas.katuosoite))
    this.form.get('maa').setValue(this._validationService.processValue(asiakas.maa))
    this.form.get('postinro').setValue(this._validationService.processValue(asiakas.postinro))
    this.form.get('postitoimipaikka').setValue(this._validationService.processValue(asiakas.postitmp))

    this.form.get('laskunKieli').setValue(this._validationService.processValue(asiakas.laskunKieli || 'fi'))

    this.form.get('kuvausKuvaus').setValue(tiedot.kuvausKuvaus || '')
    this.form.get('kuvausAloitus').setValue(tiedot.kuvausAloitus || '')
    this.form.get('kuvausHuomioita').setValue(tiedot.kuvausHuomioita || '')

    this.form.get('palaveriTyyppi').setValue(this._validationService.processValue(asiakas.palaveriTyyppi))
    this.form.get('palaveriPvm').setValue(this._dateService.timestampToDate(asiakas.palaveriPidettyPvm))

    const pidetty = this._validationService.processValue(asiakas.onkoPalaveriPidetty)
    this.form.get('palaveriOnkoPidetty').setValue(pidetty)
    if (pidetty) {
      this.form.get('palaveriPvm').setValidators([Validators.required])
      this.form.get('palaveriTyyppi').setValidators([Validators.required])
    } else {
      this.form.get('palaveriPvm').clearValidators()
      this.form.get('palaveriTyyppi').clearValidators()
    }
    this.form.get('palaveriPvm').updateValueAndValidity()
    this.form.get('palaveriTyyppi').updateValueAndValidity()

    this.form.get('tositemaaratKuitit').setValue(this._validationService.processValue(tiedot.tositemaaratKuitit))
    this.form.get('tositemaaratMyyntilaskut').setValue(this._validationService.processValue(tiedot.tositemaaratMyyntilaskut))
    this.form.get('tositemaaratMyyntiraportit').setValue(this._validationService.processValue(tiedot.tositemaaratMyyntiraportit))
    this.form.get('tositemaaratPalkkalaskelmat').setValue(this._validationService.processValue(tiedot.tositemaaratPalkkalaskelmat))
    this.form.get('tositemaaratMuuta').setValue(this._validationService.processValue(tiedot.tositemaaratMuuta))

    this.form.get('maksutavatLasku').setValue(this._validationService.processValue(tiedot.maksutavatLasku))
    this.form.get('maksutavatKorttipaate').setValue(this._validationService.processValue(tiedot.maksutavatKorttipaate))
    this.form.get('maksutavatKorttipaateLisa').setValue(this._validationService.processValue(tiedot.maksutavatKorttipaateLisa))
    this.form.get('maksutavatVerkkokauppa').setValue(this._validationService.processValue(tiedot.maksutavatVerkkokauppa))
    this.form.get('maksutavatVerkkokauppaMaksutavat').setValue(this._validationService.processValue(tiedot.maksutavatVerkkokauppaMaksutavat))
    this.form.get('maksutavatTilitykset').setValue(this._validationService.processValue(tiedot.maksutavatTilitykset))
    this.form.get('maksutavatTilityksetMita').setValue(this._validationService.processValue(tiedot.maksutavatTilityksetMita))
    this.form.get('maksutavatKateinen').setValue(this._validationService.processValue(tiedot.maksutavatKateinen))

    this.form.get('pankkiPankki').setValue(this._validationService.processValue(tiedot.pankkiPankki))
    this.form.get('pankkiYhteys').setValue(this._validationService.processValue(tiedot.pankkiYhteys))
    this.form.get('pankkiEiTilia').setValue(this._validationService.processValue(tiedot.pankkiEiTilia))

    this.form.get('auto').setValue(this._validationService.processValue(tiedot.auto))
    this.form.get('autoTyyppi').setValue(this._validationService.processValue(tiedot.autoTyyppi))
    this.form.get('autoAjo').setValue(this._validationService.processValue(tiedot.autoAjo))
    this.form.get('autoLisa').setValue(this._validationService.processValue(tiedot.autoLisa))

    this.form.get('sijoitus').setValue(this._validationService.processValue(tiedot.sijoitus))
    this.form.get('sijoitusLisa').setValue(this._validationService.processValue(tiedot.sijoitusLisa))

    this.form.get('ohjelmisto').setValue(this._validationService.processValue(tiedot.ohjelmisto))
    this.form.get('ohjelmistoLisa').setValue(this._validationService.processValue(tiedot.ohjelmistoLisa))

    this.form.get('palkatLisa').setValue(this._validationService.processValue(tiedot.palkatLisa))

    this.form.get('ossRekisterissa').setValue(!!asiakas.ossRekisterissa)
    this.form.get('estaAlvKasittely').setValue(!!asiakas.kasittelePalkatAlvittomana)
    this.form.get('testiasiakas').setValue(!!asiakas.testiasiakas)
    this.form.get('tilinpaatosRekisteroidaanBruttotuloslaskelmalla').setValue(!!asiakas.tilinpaatosRekisteroidaanBruttotuloslaskelmalla)

    if (asiakas.saleDoneBy) {
      const salesTeam = this._kirjanpitajaService.getHardcodedSalesTeamKirjanpitajat()
      const saleByKirjanpitaja = salesTeam.find(st => st.avain === asiakas.saleDoneBy)
      const nimi = saleByKirjanpitaja ? (saleByKirjanpitaja.etunimi + ' ' + saleByKirjanpitaja.sukunimi) : 'Muu'
      this.form.get('saleDoneBy').setValue(nimi)
    }

  }

  async save() {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this._validationService.naytaEnsimmainenVirhe()
      return
    }

    this._ladataanService.aloitaLataaminen()

    const olemassaOleva = await this._asiakasService.annaAsiakkaatYtunnuksella(this.asiakas.ytunnus).then(vastaus => {
      if (vastaus && vastaus.loytyneet) {
        for (const asiakas of vastaus.loytyneet) {
          if (asiakas.asiakasAvain !== this.asiakas.avain) {
            return asiakas
          }
        }
      }
      return null
    })

    if (olemassaOleva) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this.form.get('ytunnus').setErrors({ tupla: olemassaOleva })
      this._ladataanService.lopetaLataaminen()
      return
    }

    this._asiakasService.paivitaAsiakasJaTiedot(this.asiakas, this.tiedot, 'tilikaudet-eivat-voineet-muuttua', false, false).then(() => {
      this._asiakasService.asetaNykyinenAsiakas(this.asiakas)
      this._ladataanService.lopetaLataaminen()
      this._snackbar.open('Asiakas tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'top' })
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })

  }

  get katuosoite() {
    return this.get('katuosoite')
  }

  get nimi() {
    return this.get('nimi')
  }

  get palaveriTyyppi() {
    return this.get('palaveriTyyppi')
  }

  get ytunnus() {
    return this.get('ytunnus')
  }

  get postinro() {
    return this.get('postinro')
  }

  get postitoimipaikka() {
    return this.get('postitoimipaikka')
  }

  get maa() {
    return this.get('maa')
  }

  get saleDoneBy() {
    return this.form.get('saleDoneBy')
  }

  private get(name: string): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }

  name(): string {
    return '' + Math.random()
  }

}
