import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { FormControl, FormGroup, Validators } from '@angular/forms'

import { Kirjanpitaja, KirjanpitajanAsetukset } from '../_jaettu-lemonator/model/kirjanpitaja'

import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { KirjanpitajanAllekirjoitusService } from 'app/_jaettu-lemonator/service/kirjanpitajan-allekirjoitus.service'

import { KirjanpitoRaporttiValilehti, KirjanpitoRaporttiEnsinNaytettava } from '../_jaettu-lemonator/model/kirjanpitaja'

import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

import { firstValueFrom, Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

interface SettingsForm {
  phoneNumber: FormControl<string>
  emailEndTextEn: FormControl<string>
  emailEndTextFi: FormControl<string>
  lomaviestiActive: FormControl<boolean>
  lomaviestiFi: FormControl<string>
  lomaviestiEn: FormControl<string>
}

@Component({
  templateUrl: './kirjanpitajan-asetukset.component.html',
  styleUrls: ['./kirjanpitajan-asetukset.component.css'],
})

export class KirjanpitajanAsetuksetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  kirjanpitajaObservable: Observable<Kirjanpitaja>
  kirjanpitajanAsetuksetObservable: Observable<KirjanpitajanAsetukset>
  kirjanpidonRaportit: { avain: KirjanpitoRaporttiValilehti, nimi: String }[] = []
  kumpiRaporttiEnsin: { avain: KirjanpitoRaporttiEnsinNaytettava, nimi: String }[] = []

  phoneNumber: FormControl<string> = new FormControl<string>('', [Validators.required, Validators.pattern(/^[\d ()+-]+$/)])
  emailEndTextFi: FormControl<string> = new FormControl<string>('', Validators.required)
  emailEndTextEn: FormControl<string> = new FormControl<string>('', Validators.required)
  lomaviestiActive: FormControl<boolean> = new FormControl<boolean>(false)
  lomaviestiFi: FormControl<string> = new FormControl<string>('')
  lomaviestiEn: FormControl<string> = new FormControl<string>('')

  form: FormGroup<SettingsForm> = new FormGroup({
    phoneNumber: this.phoneNumber,
    emailEndTextFi: this.emailEndTextFi,
    emailEndTextEn: this.emailEndTextEn,
    lomaviestiActive: this.lomaviestiActive,
    lomaviestiFi: this.lomaviestiFi,
    lomaviestiEn: this.lomaviestiEn
  })

  commonError: string

  constructor(
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _router: Router,
    private _kirjanpitajanAllekirjoitusService: KirjanpitajanAllekirjoitusService,
    private _snackbar: MatSnackBar,
    private _firebase: FirebaseLemonator,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {
    this.kirjanpitajaObservable = this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable
    this.kirjanpitajaObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((accountant: Kirjanpitaja) => {
      if (accountant?.puhelin) {
        this.phoneNumber.setValue(accountant.puhelin)
      }

      if (accountant.emailEndText?.['fi']) {
        this.emailEndTextFi.setValue(accountant.emailEndText['fi'])
      } else {
        const defaultEmailEndTextFi = this._createDefaultEmailEndText(accountant, 'fi')
        this.emailEndTextFi.setValue(defaultEmailEndTextFi)
      }

      if (accountant.emailEndText?.['en']) {
        this.emailEndTextEn.setValue(accountant.emailEndText['en'])
      } else {
        const defaultEmailEndTextEn = this._createDefaultEmailEndText(accountant, 'en')
        this.emailEndTextEn.setValue(defaultEmailEndTextEn)
      }

      if (accountant.lomaviesti?.['fi'] || accountant.lomaviesti?.['en']) {
        console.log(accountant.lomaviesti?.['fi'], accountant.lomaviesti?.['en'])
        this.lomaviestiActive.setValue(true)
      }

      if (accountant.lomaviesti?.['fi']) {
        this.lomaviestiFi.setValue(accountant.lomaviesti['fi'])
      }

      if (accountant.lomaviesti?.['en']) {
        this.lomaviestiEn.setValue(accountant.lomaviesti['en'])
      }

    })


    this.kirjanpitajanAsetuksetObservable = this._kirjanpitajaService.kirjautuneenKayttajanAsetuksetObservable

    this.kirjanpidonRaportit.push({ avain: 'tase-ja-tulos', nimi: 'Tase ja tulos' })
    this.kirjanpidonRaportit.push({ avain: 'viralliset', nimi: 'Virallinen tase ja tulos' })
    this.kirjanpidonRaportit.push({ avain: 'paakirja', nimi: 'Pääkirja' })

    this.kumpiRaporttiEnsin.push({ avain: 'tase', nimi: 'tase ensin' })
    this.kumpiRaporttiEnsin.push({ avain: 'tulos', nimi: 'tulos ensin' })
  }

  async oletusRaporttiMuuttui(event: KirjanpitoRaporttiValilehti) {
    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
    if (kirjanpitajanTiedot) {
      this._firebase.firestoreSetData<Partial<KirjanpitajanAsetukset>>('/kirjanpitajat/' + kirjanpitajanTiedot.uid + '/kirjanpitajan-asetukset/' + kirjanpitajanTiedot.uid, { kirjanpidonOletusraportti: event }, { merge: true }).then(() => {
        this._snackbar.open('Oletusraportti tallennettiin', 'OK', { verticalPosition: 'top', duration: 1500 })
      })
    }
  }

  async ensinNaytettavaRaporttiMuuttui(event: KirjanpitoRaporttiEnsinNaytettava) {
    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
    if (kirjanpitajanTiedot) {
      this._firebase.firestoreSetData<Partial<KirjanpitajanAsetukset>>('/kirjanpitajat/' + kirjanpitajanTiedot.uid + '/kirjanpitajan-asetukset/' + kirjanpitajanTiedot.uid, { kirjanpidonEnsinNaytettevaRaportti: event }, { merge: true }).then(() => {
        this._snackbar.open('Ensin näytettävä raportti tallennettiin', 'OK', { verticalPosition: 'top', duration: 1500 })
      })
    }
  }

  async saveForm() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    this.commonError = null
    this._ladataanService.aloitaLataaminen()

    try {

      const phoneNumber = this.form.get('phoneNumber').value
      const emailEndTextFi = this.emailEndTextFi.value
      const emailEndTextEn = this.emailEndTextEn.value
      const lomaviestiActive = this.lomaviestiActive.value
      const lomaviestiFi = lomaviestiActive ? this.lomaviestiFi.value : null // Overwrite lomaviesti text if not active
      const lomaviestiEn = lomaviestiActive ? this.lomaviestiEn.value : null // Overwrite lomaviesti text if not active

      const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
      if (!kirjanpitajanTiedot || !phoneNumber) {
        return
      }
      const updateData: Pick<Kirjanpitaja, 'puhelin' | 'emailEndText' | 'lomaviesti'> = {
        puhelin: phoneNumber
      }

      if (!updateData.emailEndText) {
        updateData.emailEndText = {} as any
      }
      updateData.emailEndText['fi'] = emailEndTextFi
      updateData.emailEndText['en'] = emailEndTextEn

      if (!updateData.lomaviesti) {
        updateData.lomaviesti = {} as any
      }
      updateData.lomaviesti['fi'] = lomaviestiFi
      updateData.lomaviesti['en'] = lomaviestiEn

      await this._firebase.firestoreUpdateData<Partial<Kirjanpitaja>>('kirjanpitajat/' + kirjanpitajanTiedot.uid, updateData)

      this._snackbar.open('Tiedot tallennettu', 'OK', { verticalPosition: 'top', duration: 1500 })

    } catch (error) {
      this._errorHandler.handleError(error)
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  back() {
    this._router.navigate(['/'])
  }

  copySignature(kirjanpitaja: Kirjanpitaja, kieli: TuettuKieli) {
    const signature = this._kirjanpitajanAllekirjoitusService.getSignatureHtml(kirjanpitaja, kieli)
    this._copyToClipboard(signature)
    this._snackbar.open('Allekirjoituksesi on tallennettu leikepöydälle. Liitä (Cmd+V) se sähköpostin allekirjoituksien muokkauskentälle.', 'OK', {
      verticalPosition: 'top',
      duration: 1500,
    })
  }

  private _copyToClipboard(html: string) {
    // TODO: Find way to use already rendered signature
    const container = document.createElement('div')
    container.innerHTML = html
    container.style.position = 'fixed'
    container.style.pointerEvents = 'none'
    container.style.opacity = '0'
    document.body.appendChild(container)
    window.getSelection().removeAllRanges()
    const range = document.createRange()
    range.selectNode(container)
    window.getSelection().addRange(range)
    document.execCommand('copy')
    document.body.removeChild(container)
  }

  private _createDefaultEmailEndText(accountant: Kirjanpitaja, lang: TuettuKieli) {
    if (lang === 'fi') {
      return 'Ystävällisin terveisin,\n\n' + accountant.etunimi
    } else if (lang === 'en') {
      return 'Kind regards,\n\n' + accountant.etunimi
    } else {
      throw new Error('No email text set up for ' + lang)
    }
  }

}
