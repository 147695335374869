import { Component, OnInit, OnDestroy, ErrorHandler, Input } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Subject, of, BehaviorSubject } from 'rxjs'
import { takeUntil, map, switchMap, tap } from 'rxjs/operators'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { Ajoneuvo, KirjanpidonUlkopuolisetKulut } from '../../_jaettu/model/kayttaja'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { LuoUlkopuolisetKulutTaikalinkkiRequest, LuoUlkopuolisetKulutTaikalinkkiResponse } from 'app/_jaettu-lemonator/model/kirjanpito'

@Component({
  selector: '[app-ulkopuoliset-kulut]',
  templateUrl: './ulkopuoliset-kulut-lemonator.component.html',
  styleUrls: ['./ulkopuoliset-kulut-lemonator.component.css']
})
export class UlkopuolisetKulutLemonatorComponent implements OnInit, OnDestroy {

  private yearSubject: BehaviorSubject<number> = new BehaviorSubject(null)

  @Input() set setYear(value: number) {
    this.yearSubject.next(value)
    this.commonError = null
  }
  @Input() asiakasAvain: string


  private ngUnsubscribe: Subject<void> = new Subject<void>()

  ulkopuolisetKulutForm: UntypedFormGroup
  commonError: string
  lomakePaivitetty: string
  lomakkeenPaivittaja: string

  private _ulkopuolisetKulut: KirjanpidonUlkopuolisetKulut
  private _ajoneuvot: Ajoneuvo[] = []

  onIlmoitettavaa: boolean = true

  namename = 'asruopeq' + Math.random()

  private _saveStarted: boolean

  paasaantoinenKotiVahennys: string = '940' // 2023
  osaaikainenKotiVahennys: string = '470' // 2023
  satunnainenKotiVahennys: string = '235' // 2023

  constructor(
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _snackbar: MatSnackBar,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _copyService: KopioijaPalvelu,
    private _timestampService: TimestampService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _firebase: FirebaseLemonator,
    private _dateService: DateService
  ) { }

  ngOnInit() {

    this.ulkopuolisetKulutForm = new UntypedFormGroup({
      'eiIlmoitettavaa': new UntypedFormControl(),
      'ajoneuvot': new UntypedFormArray([]),
      'tiedotPerustuvat': new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      'yli10hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'yli6hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'ulkomaanMatkapaivienLukumaara': new UntypedFormControl(null),
      'ulkomaanPaivarahojenYhteismaara': new UntypedFormControl(null),
      'kotiYrityksenTyotilana': new UntypedFormControl()
    })

    if (this.asiakasAvain) {
      this.yearSubject.asObservable().pipe(
        tap(() => this._ladataanService.aloitaLataaminen()),
        switchMap(vuosi => {
          if (!vuosi) {
            return of(null)
          }
          return this._firebaseLemonaid.firestoreDoc<KirjanpidonUlkopuolisetKulut>('customers/' + this.asiakasAvain + '/customer-ulkopuoliset-kulut/' + this.asiakasAvain + '_' + vuosi).listen().pipe(
            map(firestoresta => {
              this.lomakePaivitetty = firestoresta?.paivitetty ? this._dateService.muotoilePaivaJaAikaDate(firestoresta?.paivitetty?.toDate(), 'fi') : 'ei koskaan'
              // this.lomakkeenPaivittaja = firestoresta?.paivittaja || ''

              if (firestoresta) {
                return firestoresta
              }
              const blankForm: KirjanpidonUlkopuolisetKulut = this.createBlankForm(this.asiakasAvain)
              return blankForm
            })
          )
        }),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(lomake => {

        this.ajoneuvotFormArray.valueChanges.pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(value => {
          if (!value?.length) {
            this.tiedotPerustuvat.disable()
          } else {
            this.tiedotPerustuvat.enable()
          }
        })

        if (lomake) {
          this._ulkopuolisetKulut = this._copyService.cloneObjectDeep(lomake)
          this.alustaLomakkeenTiedot(lomake)
        } else {
          this.commonError = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
        }

        this.tiedotPerustuvat.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
          this._ulkopuolisetKulut.tiedotPerustuvat = this._validationService.processValue(value)
        })

        this.yli10hKotimaanMatkojenLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ulkopuolisetKulut.yli10hKotimaanMatkojenLukumaara = this._validationService.processValue(value) })
        this.yli6hKotimaanMatkojenLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ulkopuolisetKulut.yli6hKotimaanMatkojenLukumaara = this._validationService.processValue(value) })
        this.ulkomaanMatkapaivienLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ulkopuolisetKulut.ulkomaanMatkapaivienLukumaara = this._validationService.processValue(value) })
        this.ulkomaanPaivarahojenYhteismaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ulkopuolisetKulut.ulkomaanPaivarahojenYhteismaara = this._validationService.processValue(value) })
        this.kotiYrityksenTyotilana.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ulkopuolisetKulut.kotiYrityksenTyotilana = this._validationService.processValue(value) })

        this._ladataanService.lopetaLataaminen()

      })

      this.eiIlmoitettavaa.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(eiIlmoitettavaa => {
        this.onIlmoitettavaa = !eiIlmoitettavaa
        this._ulkopuolisetKulut.eiIlmoitettavaa = eiIlmoitettavaa

        if (eiIlmoitettavaa) {

          this.tiedotPerustuvat.disable()
        } else {
          const onkoAjoneuvoja = !!this.ulkopuolisetKulutForm.get('ajoneuvot').value?.length

          if (onkoAjoneuvoja) {
            this.tiedotPerustuvat.enable()
          }
        }

      })
    }
  }


  get ajoneuvotFormArray(): UntypedFormArray {
    return this.ulkopuolisetKulutForm.get('ajoneuvot') as UntypedFormArray
  }
  get ajoneuvotFormGroupit(): UntypedFormGroup[] {
    return this.ajoneuvotFormArray.controls as UntypedFormGroup[]
  }

  poistaAjoneuvo(index: number) {
    this.ajoneuvotFormArray.removeAt(index)
    this._ajoneuvot.splice(index, 1)
  }

  lisaaAjoneuvo(ajn?: Ajoneuvo) {
    const ajoneuvo: Ajoneuvo = {
      rekisteritunnus: ajn?.rekisteritunnus || '',
      tyyppi: ajn?.tyyppi || null,
      kokonaisKmMaara: ajn?.kokonaisKmMaara || null,
      tyoAjoissaKm: ajn?.tyoAjoissaKm || null
    }

    const ajoneuvoFormControl = new UntypedFormGroup({
      'rekisteritunnus': new UntypedFormControl(ajoneuvo.rekisteritunnus, Validators.required),
      'tyyppi': new UntypedFormControl(ajoneuvo.tyyppi, Validators.required),
      'kokonaisKmMaara': new UntypedFormControl(ajoneuvo.kokonaisKmMaara, Validators.required),
      'tyoAjoissaKm': new UntypedFormControl(ajoneuvo.tyoAjoissaKm, Validators.required)
    })

    const tyoajoisssaKm = ajoneuvoFormControl.get('tyoAjoissaKm')

    ajoneuvoFormControl.get('rekisteritunnus').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { ajoneuvo.rekisteritunnus = value || null })
    ajoneuvoFormControl.get('tyyppi').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { ajoneuvo.tyyppi = value || null })
    ajoneuvoFormControl.get('kokonaisKmMaara').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      ajoneuvo.kokonaisKmMaara = value || null
      if (ajoneuvo.kokonaisKmMaara < ajoneuvo.tyoAjoissaKm) {
        tyoajoisssaKm.setErrors({ toolarge: true })
      } else {
        if (tyoajoisssaKm?.errors?.toolarge) {
          delete tyoajoisssaKm.errors.toolarge
        }
        tyoajoisssaKm.setErrors(Object.keys(tyoajoisssaKm.errors || {}).length ? tyoajoisssaKm.errors : null)
      }
    })
    tyoajoisssaKm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      ajoneuvo.tyoAjoissaKm = value || null
      if (ajoneuvo.kokonaisKmMaara < ajoneuvo.tyoAjoissaKm) {
        tyoajoisssaKm.setErrors({ toolarge: true })
      } else {
        if (tyoajoisssaKm?.errors?.toolarge) {
          delete tyoajoisssaKm.errors.toolarge
        }
        tyoajoisssaKm.setErrors(Object.keys(tyoajoisssaKm.errors || {}).length ? tyoajoisssaKm.errors : null)
      }
    })

    this.ajoneuvotFormArray.push(ajoneuvoFormControl)
    this._ajoneuvot.push(ajoneuvo)
  }

  get eiIlmoitettavaa() {
    return this.ulkopuolisetKulutForm.get('eiIlmoitettavaa')
  }
  get tiedotPerustuvat() {
    return this.ulkopuolisetKulutForm.get('tiedotPerustuvat')
  }
  get yli10hKotimaanMatkojenLukumaara() {
    return this.ulkopuolisetKulutForm.get('yli10hKotimaanMatkojenLukumaara')
  }
  get yli6hKotimaanMatkojenLukumaara() {
    return this.ulkopuolisetKulutForm.get('yli6hKotimaanMatkojenLukumaara')
  }
  get ulkomaanPaivarahojenYhteismaara() {
    return this.ulkopuolisetKulutForm.get('ulkomaanPaivarahojenYhteismaara')
  }
  get ulkomaanMatkapaivienLukumaara() {
    return this.ulkopuolisetKulutForm.get('ulkomaanMatkapaivienLukumaara')
  }
  get kotiYrityksenTyotilana() {
    return this.ulkopuolisetKulutForm.get('kotiYrityksenTyotilana')
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async tallenna() {
    if (this._saveStarted) {
      return
    }
    if (!this.ulkopuolisetKulutForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.ulkopuolisetKulutForm)
      return
    }

    // Show loading indicator
    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {
      const batch = this._firebaseLemonaid.firestoreBatch()

      const now = this._timestampService.now()
      this._ulkopuolisetKulut.luotu = this._ulkopuolisetKulut.luotu || now
      this._ulkopuolisetKulut.paivitetty = now

      const kirjanpitajaTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      this._ulkopuolisetKulut.luoja = this._ulkopuolisetKulut.luoja || kirjanpitajaTiedot.uid
      this._ulkopuolisetKulut.paivittaja = kirjanpitajaTiedot.uid

      this._ulkopuolisetKulut.ajoneuvot = [...this._ajoneuvot]

      // Set disabled field as null
      if (this.tiedotPerustuvat.disabled) {
        this._ulkopuolisetKulut.tiedotPerustuvat = null
      }
      // Save main doc
      batch.set('customers/' + this.asiakasAvain + '/customer-ulkopuoliset-kulut/' + this.asiakasAvain + '_' + this.yearSubject.value, this._ulkopuolisetKulut)

      // Create history entry
      const historyCopy = this._copyService.cloneObjectDeep(this._ulkopuolisetKulut)
      const historyAvain = this._firebaseLemonaid.firestoreCreateId()
      historyCopy.avain = historyAvain
      batch.set('customers/' + this.asiakasAvain + '/customer-ulkopuoliset-kulut/' + this.asiakasAvain + '_' + this.yearSubject.value + '/customer-ulkopuoliset-kulut-history/' + historyAvain, historyCopy)

      // Commit batch
      await batch.commit()

      // All is good, let's advance
      this._snackbar.open('Tallennus onnistui', 'OK', { duration: 5000, verticalPosition: 'top' })
      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false

    } catch (error) {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
      this._errorHandler.handleError(error)
    }
  }
  private createBlankForm(asiakasAvain: string): KirjanpidonUlkopuolisetKulut {
    const blank: KirjanpidonUlkopuolisetKulut = {
      avain: this._firebaseLemonaid.firestoreCreateId(),
      asiakasAvain: asiakasAvain,
      vuosi: this.yearSubject.value,
      ajoneuvot: [],
      tiedotPerustuvat: null,
      yli10hKotimaanMatkojenLukumaara: null,
      yli6hKotimaanMatkojenLukumaara: null,
      ulkomaanMatkapaivienLukumaara: null,
      ulkomaanPaivarahojenYhteismaara: null,
      kotiYrityksenTyotilana: null,
      paivitetty: null,
      paivittaja: null,
      luoja: null,
      luotu: null,
      poistettu: false
    }
    return blank
  }
  private alustaLomakkeenTiedot(ulkopuolisetKulut: KirjanpidonUlkopuolisetKulut) {

    this._ajoneuvot = []
    this.ajoneuvotFormArray.clear()

    // NB! Don't change order for these - timing issue with subscriptions
    if (ulkopuolisetKulut.ajoneuvot?.length) {
      for (const ajoneuvo of ulkopuolisetKulut.ajoneuvot) {
        this.lisaaAjoneuvo(ajoneuvo)
      }
    }

    this.eiIlmoitettavaa.setValue(ulkopuolisetKulut.eiIlmoitettavaa || null)
    this.tiedotPerustuvat.setValue(ulkopuolisetKulut.tiedotPerustuvat)
    this.yli10hKotimaanMatkojenLukumaara.setValue(ulkopuolisetKulut.yli10hKotimaanMatkojenLukumaara)
    this.yli6hKotimaanMatkojenLukumaara.setValue(ulkopuolisetKulut.yli6hKotimaanMatkojenLukumaara)
    this.ulkomaanMatkapaivienLukumaara.setValue(ulkopuolisetKulut.ulkomaanMatkapaivienLukumaara)
    this.ulkomaanPaivarahojenYhteismaara.setValue(ulkopuolisetKulut.ulkomaanPaivarahojenYhteismaara)
    this.kotiYrityksenTyotilana.setValue(ulkopuolisetKulut.kotiYrityksenTyotilana)
  }

  async luoTaikalinkki() {

    const req: LuoUlkopuolisetKulutTaikalinkkiRequest = {
      asiakasAvain: this.asiakasAvain,
      verovuosi: this.yearSubject.value
    }

    this._ladataanService.aloitaLataaminen()

    const taikalinkkiBlobType = 'text/plain'
    const getTaikalinkkiBlob = (taikalinkki: string): Blob => {
      return new Blob([taikalinkki], { type: taikalinkkiBlobType })
    }

    const taikalinkkiPromise: Promise<Blob> = this._firebase.functionsCall<LuoUlkopuolisetKulutTaikalinkkiRequest, LuoUlkopuolisetKulutTaikalinkkiResponse>('kirjanpidonUlkopuolisetKulutAnnaUusiTaikalinkki', req).then(resp => {
      if (!resp || resp.e) {
        if (resp?.e === 'veroilmoitus-sent') {
          this._snackbar.open('Valitun vuoden veroilmoitus on jo lähetetty.', 'OK', { verticalPosition: 'top', duration: 1500 })
        } else {
          this._errorHandler.handleError(new Error(resp ? resp.e : 'unknown-error'))
        }
        return getTaikalinkkiBlob('')
      } else {
        this._snackbar.open('Taikalinkki on tallennettu leikepöydälle. Liitä (Cmd+V) se sähköpostin muokkauskentälle.', 'OK', { verticalPosition: 'top', duration: 1500 })
        return getTaikalinkkiBlob(resp.taikalinkki)
      }
    }).catch(err => {
      this._errorHandler.handleError(err)
      return getTaikalinkkiBlob('')
    })

    // The following clipboard write is supported on Chromium and Safari
    const clipboardItem = new ClipboardItem({ [taikalinkkiBlobType]: taikalinkkiPromise })
    await navigator.clipboard.write([clipboardItem])
    this._ladataanService.lopetaLataaminen()
  }

}
