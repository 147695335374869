import { Component, OnInit, Inject } from '@angular/core'
import { Validators, FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { ViitenumeroService } from 'app/_shared-core/service/viitenumero.service'
import { StringService } from 'app/_shared-core/service/string.service'

export interface KirjausMuokkaaViitenumeroaDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  asiakas: Asiakas
}

interface MainForm {
  viitenumero: FormControl<string>
}

@Component({
  templateUrl: './kirjaus.muokkaa-viitenumeroa.dialog.html'
})
export class KirjausMuokkaaViitenumeroaDialog implements OnInit {

  form: FormGroup<MainForm>
  namename: string = 'assf' + Math.random()

  private _uusiViitenumero: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausMuokkaaViitenumeroaDialogData,
    private _dialogRef: MatDialogRef<KirjausMuokkaaViitenumeroaDialog>,
    private _formValidationService: FormValidationService,
    private _viitenumeroService: ViitenumeroService,
    private _stringService: StringService
  ) {

    this.form = new FormGroup<MainForm>({
      'viitenumero': new FormControl<string>(this.data.naytettavaKirjaus.kirjaus.kirjauksenViitenumero, [Validators.required, this._validateViitenumero])
    })
    this.form.get('viitenumero').valueChanges.subscribe(value => {
      const muotoiltu = this._viitenumeroService.muotoileViitenumero(value) // Poistaa etunollat jne.
      const whitespacePoistettu = this._stringService.removeAllWhiteSpaces(muotoiltu)
      this._uusiViitenumero = whitespacePoistettu
    })

  }

  ngOnInit() { }

  tallenna() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (!this._uusiViitenumero || this.data.naytettavaKirjaus.kirjaus.maksutavanTunniste === this._uusiViitenumero) {
      this.data.naytettavaKirjaus.uusiViitenumero = null
    } else {
      this.data.naytettavaKirjaus.uusiViitenumero = this._uusiViitenumero
    }

    this._dialogRef.close(true)

  }

  get viitenumero() {
    return this.form?.get('viitenumero')
  }

  private _validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

}
