import { Injectable } from '@angular/core'
import { AsiakasService } from '../asiakas/asiakas.service'

import { BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

@Injectable()
export class KirjanpitoKelloService {

  private _kelloOnPysaytettySubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  kelloOnPysaytettyObservable = this._kelloOnPysaytettySubject.asObservable()

  constructor(
    private _asiakasService: AsiakasService
  ) {
    this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      distinctUntilChanged((a, b) => a?.avain === b?.avain)
    ).subscribe(() => {
      this._kelloOnPysaytettySubject.next(false)
    })
  }

  pysaytaKello() {
    this._kelloOnPysaytettySubject.next(true)
  }

  kaynnistaKello() {
    this._kelloOnPysaytettySubject.next(false)
  }

}
