import { ChangeDetectionStrategy, Component, Input, OnChanges, HostBinding } from '@angular/core'
import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'

@Component({
  selector: '[app-veroilmoitus-row-header]',
  templateUrl: './veroilmoitus-row-header.component.html',
  styleUrls: ['./veroilmoitus-row.styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusRowHeaderComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges {

  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot
  @Input() numero: string
  @Input() korvausparametrit: Korvausparametrit

  @HostBinding('class') classes = 'block-row header'

  label: string = ''

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero && this.veroilmoitus?.perustiedot?.asiakas) {
      this.label = this._localizeNumero(this.numero, this.veroilmoitus, this.korvausparametrit)
    }
  }

}
