<div class="list-page">
  <app-asiakas-otsikko otsikko="Holvi" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>
  <div>
    <h3 class="light-blue-text" style="margin-bottom: 30px;">Holvi-arkiston lataaminen</h3>
    <div *ngIf="!tiedostoaLadataan">
      <div style="margin-bottom: 14px;" class="dark-gray-text">
        Työjonossa on {{kasittelemattomiaObservable | async}} holvitietuetta käsittelemättä
        <div *ngIf="(kasittelemattomiaObservable | async) > 0 && !(driverObservable | async)" class="error-text" style="padding: 3em; font-size: 120%;">
          Ajo näyttää keskeytyneen. Voit käynnistää sen turvallisesti uudelleen tästä: <button mat-button style="margin-left: 1em;" class="secondarybutton" (click)="restart()">Käynnistä uudelleen</button>
        </div>
      </div>
      <form [formGroup]="maksutavatForm">
        <mat-form-field>
          <mat-label>Valitse maksutapa</mat-label>
          <mat-select formControlName="valittu" required>
            <mat-option *ngFor="let maksutapa of holviMaksutavatObservable | async" [value]="maksutapa">
              {{ maksutapa.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="valittuFormControl?.errors?.required && (valittuFormControl?.dirty || valittuFormControl?.touched)">Maksutapa ei ole valittu. Vain maksutapa, jonka "Kirjauksien muodostus" on "Tuodaan Holvista", voidaan valita. Voit päivittää asetukset napauttamalla "Lemonaid" -> "Maksutavat".</mat-error>
        </mat-form-field>
      </form>
      <div [class.hidden]="!maksutavatLoaded" class="drag-drop-area">
        <ngx-file-drop #droppi dropZoneClassName="maksutapa-container" (onFileOver)="fileOver()" (onFileLeave)="fileLeave()" (onFileDrop)="fileDrop($event)" (click)="fileInput.click()" class="droppi" style="cursor: pointer;">
          <ng-template ngx-file-drop-content-tmp>
            <div style="padding: 3em;">Vedä tiedosto tähän tai klikkaa</div>
          </ng-template>
        </ngx-file-drop>
      </div>
      <div class="error-box red-text" *ngIf="latausvirhe">{{ latausvirhe }}</div>
      <mat-progress-spinner style="margin-top: 5%" mode="indeterminate" *ngIf="!maksutavatLoaded"></mat-progress-spinner>
      <div style="font-weight: bold;">
        Sivulta on nyt turvallista navigoida pois.
      </div>
    </div>
    <div *ngIf="tiedostoaLadataan">
      <h2>Ole hyvä ja odota, kunnes lataaminen on valmis.</h2>
      <h3 style="margin-top: 3em;">1. Ladataan tiedostoa palvelimelle</h3>
      <mat-progress-bar *ngIf="donePercentageObservable" mode="determinate" [value]="donePercentageObservable | async"></mat-progress-bar>
      <h3 style="margin-top: 3em;" *ngIf="tiedostoaKasitellaan">2. Käsitellään zip-tiedostoa...</h3>
      <mat-progress-bar *ngIf="tiedostoaKasitellaan" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>


  <div style="margin-top: 60px;" *ngIf="holviApiMaksutapaActiveObservable | async">
    <h3 class="light-blue-text" style="margin: 30px 0;">Lataa tilitapahtumat heti kirjanpitoon</h3>

    <div *ngIf="apiLoadRunning">
      Lataus on käynnissä:
    </div>
    <ng-container *ngIf="holviApiLoadRunningObservable | async; let apiRunningData">
      <div *ngIf="apiLoadRunning" style="padding: 30px;">
        <ng-container *ngIf="!apiRunningData.running">
          <div>1. Haetaan tietoja Holvin rajapinnasta...</div>
          <div>2. Holvitietojen purku työjonoon odottaa tietojen hakemista.</div>
          <div>3. Tilitapahtumien käsittely odottaa tietojen purkua.</div>
        </ng-container>
        <ng-container *ngIf="apiRunningData.running">
          <div *ngIf="apiRunningData.holviTransactionsLeft > 0">1. Tietojen haku Holvista on valmis.</div>
          <div *ngIf="apiRunningData.holviTransactionsLeft > 0">2. Puretaan Holvin tietoja työjonoon, jäljellä {{ apiRunningData.holviTransactionsLeft }}...</div>
          <div *ngIf="apiRunningData.holviTransactionsLeft > 0">3. Tilitapahtumien käsittely odottaa tietojen purkua, jonossa {{apiRunningData.transactionsLeft}}.</div>

          <div *ngIf="1 > apiRunningData.holviTransactionsLeft">1. Tietojen haku Holvista on valmis.</div>
          <div *ngIf="1 > apiRunningData.holviTransactionsLeft">2. Holvitietojen purku työjonoon valmis.</div>
          <div *ngIf="1 > apiRunningData.holviTransactionsLeft">3. Käsitellään tilitapahtumia, jäljellä {{apiRunningData.transactionsLeft}}...</div>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="apiLoadRunning" style="padding-bottom: 30px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <ng-container *ngIf="!apiLoadRunning">
      <form [formGroup]="holviTransactionSyncForm">

        <mat-form-field style="width: 300px; margin-right: 30px;">
          <mat-label>Hae tilitapahtumat alkaen</mat-label>
          <input matInput type="text" [matDatepicker]="fetchStartPicker" formControlName="start" required>
          <mat-datepicker-toggle matSuffix [for]="fetchStartPicker"></mat-datepicker-toggle>
          <mat-error *ngIf="holviTransactionSyncForm.get('start')?.errors?.required && (holviTransactionSyncForm.get('start')?.dirty || holviTransactionSyncForm.get('start')?.touched)">
            Päivämäärä puuttuu
          </mat-error>
          <mat-error *ngIf="holviTransactionSyncForm.get('start')?.errors?.startBeforeApiEnabled && (holviTransactionSyncForm.get('start')?.dirty || holviTransactionSyncForm.get('start')?.touched)">
            Päivämäärä ei saa olla ennen Holvi-yhteyden voimassaolon alkua
          </mat-error>
          <mat-error *ngIf="holviTransactionSyncForm.get('start')?.errors?.endBeforeStart && (holviTransactionSyncForm.get('start')?.dirty || holviTransactionSyncForm.get('start')?.touched)">
            Loppupäivämäärä on ennen alkua
          </mat-error>
        </mat-form-field>

        <mat-datepicker #fetchStartPicker></mat-datepicker>

        <mat-form-field style="width: 300px;">
          <mat-label>Hae tilitapahtumat loppuen</mat-label>
          <input matInput type="text" [matDatepicker]="fetchEndPicker" formControlName="end" required>
          <mat-datepicker-toggle matSuffix [for]="fetchEndPicker"></mat-datepicker-toggle>
          <mat-error *ngIf="holviTransactionSyncForm.get('end')?.errors?.required && (holviTransactionSyncForm.get('end')?.dirty || holviTransactionSyncForm.get('end')?.touched)">
            Päivämäärä puuttuu
          </mat-error>
          <mat-error *ngIf="holviTransactionSyncForm.get('end')?.errors?.endBeforeStart && (holviTransactionSyncForm.get('end')?.dirty || holviTransactionSyncForm.get('end')?.touched)">
            Loppupäivämäärä on ennen alkua
          </mat-error>
        </mat-form-field>

        <mat-datepicker #fetchEndPicker></mat-datepicker>

      </form>
      <ng-template *ngIf="fetchError" class="red-rext">{{ fetchError }}</ng-template>
      <div style="margin-top: 30px;">
        <button class="primarybutton" type="button" mat-button (click)="startHolviTransactionSync()">Hae</button>
      </div>
    </ng-container>

    <div style="margin: 30px 0; font-weight: bold;">Historia</div>
    <table style="margin-bottom: 30px;">
      <tr *ngFor="let log of displayedFetchLogsObservable | async">
        <td style="width: 150px;">{{ log.created | ltimestamptime | async }}
        <td style="width: 120px;">{{ log.kirjanpitajanNimi }}</td>
        <td style="width: 80px;" [class.red-text]="log.result !== 'success'">{{ log.result === 'success' ? 'Onnistui' : 'Epäonnistui'}}</td>
        <td style="width: 600px;">{{log.extraInfo }}</td>
      </tr>
    </table>
  </div>
</div>

<input #fileInput type="file" style="visibility: hidden;" accept="zip,application/zip,application/x-zip,application/x-zip-compressed" (change)="lataa($event)" />