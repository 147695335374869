import { Component, OnInit, ErrorHandler } from '@angular/core'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { KirjanpitoUriService } from '../../_jaettu-lemonator/service/kirjanpito-uri.service'

import { KirjanpitoEmailLahetys } from '../../_jaettu-lemonator/model/kirjanpito'

import { combineLatest, Observable, of as observableOf } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { DateService } from 'app/_shared-core/service/date.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

interface NaytettavatKirjanpitoEmailLahetys {
  aihe: string
  kuukausi: string
  luotu: string
  lahetetty: string
  luojaNimi: string
  recipients: string
}
@Component({
  templateUrl: './kirjanpito-lahetykset.component.html',
  styleUrls: ['./kirjanpito-lahetykset.component.css']
})
export class AsiakkaanKirjanpitoLahetyksetComponent implements OnInit {

  naytettavatLahetyksetObservable: Observable<NaytettavatKirjanpitoEmailLahetys[]>

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator
  ) { }

  ngOnInit() {

    const lahetystenRaakadataObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakastiedot => {
        if (!asiakastiedot) {
          return observableOf<KirjanpitoEmailLahetys[]>([])
        }
        const uri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksetCollection(asiakastiedot.avain)
        return this._firebase.firestoreCollection<KirjanpitoEmailLahetys>(uri)
          .orderBy('luotu', 'desc')
          .limit(50)
          .listen()
      })
    )

    this.naytettavatLahetyksetObservable = combineLatest([lahetystenRaakadataObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([lahetykset, kirjanpitajienNimitiedotMap]) => {
        return lahetykset.map(lahetys => {
          const naytettava: NaytettavatKirjanpitoEmailLahetys = {
            aihe: lahetys.aihe,
            kuukausi: lahetys.kuukausi.month + '/' + lahetys.kuukausi.year,
            luotu: this._dateService.muotoilePaivaJaAikaDate(lahetys.luotu?.toDate(), 'fi'),
            lahetetty: this._dateService.muotoilePaivaJaAikaDate(lahetys.lahetetty?.toDate(), 'fi'),
            luojaNimi: this.annaKirjanpitajanNimi(lahetys.luoja, kirjanpitajienNimitiedotMap),
            recipients: lahetys.recipients.reduce((a, b) => (a ? a + ' ; ' : '') + b.email, '')
          }
          return naytettava
        })
      })
    )

  }

  annaKirjanpitajanNimi(avain: string, kirjanpitajienNimitiedotMap: Map<string, KirjanpitajanNimitiedot>): string {
    if (!avain) {
      return 'ei avainta'
    }
    const nimitiedot = kirjanpitajienNimitiedotMap?.get(avain) || null
    if (nimitiedot) {
      return nimitiedot.etunimi + ' ' + nimitiedot.sukunimi
    }
    return 'Ei tietoja avaimella ' + avain
  }

}
