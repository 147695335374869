import { Component, OnInit, ViewChild, OnDestroy, ErrorHandler, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatInput } from '@angular/material/input'

import { AsiakkaatDataSourceStateService, LukemanTyyppiVanha } from './asiakkaat.datasource.service'

import { AsiakasService, LokalisoituYritysmuoto, LokalisoituAlvIlmoitusjakso } from '../../_angular/service/asiakas/asiakas.service'

import { AlvIlmoitusjakso, EiYhtiomuotoaYhtiomuoto } from '../../_jaettu-lemonator/model/asiakas'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'

import { Subject, Observable } from 'rxjs'
import { map, takeUntil, debounceTime } from 'rxjs/operators'

import { LemonKuukausiDateAdapter } from 'app/_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { Platform } from '@angular/cdk/platform'
import { LocalMonth } from 'app/_shared-core/model/common'

import { DateService } from 'app/_shared-core/service/date.service'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { VersionTarkistusPalvelu } from 'app/_angular/service/version-tarkistus.palvelu'

export interface KuukausiJaValmiusaste extends LocalMonth {
  valmiusaste: number
  valmiusasteMuotoiltu: string
}

// interface TyontekoInfo {
//   asiakkaita: number,
//   asiakkaitaJoillaHinta: number,
//   nykyinenKuukausilaskutus: Big
//   keskimaarainenhinta: Big,
//   keskimaarainenPaivatavoite: Big
//   tehtyTanaan: Big
//   progressio: Big
//   seuraavaErapaiva: Date
//   paiviaSeuraavaanErapaivaan: number
//   progressioonLisattava: Big
//   dynaaminenSumma: Big
//   palluroidenSumma: Big
//   huutomerkkienSumma: Big
// }

@Component({
  templateUrl: './asiakkaat.component-old.html',
  styleUrls: ['./asiakkaat.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class AsiakkaatComponentOld implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('hakuInput', { read: MatInput, static: true }) hakuInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  // tyontekoInfoObservable: Observable<TyontekoInfo>
  naytaTehoObservable: Observable<boolean>
  etsiName = 'mhgyukytwere' + Math.random()
  form: FormGroup

  yritysmuodot: LokalisoituYritysmuoto[]
  alvjaksot: LokalisoituAlvIlmoitusjakso[]
  minMonthStart = new Date(2015, 0, 1)
  maxMonthStart = new Date(2099, 11, 1)
  minMonthEnd = new Date(2015, 0, 1)
  maxMonthEnd = new Date(2099, 11, 1)

  private _vuosikkalkuFormControl: FormControl<Date>
  private _vuosikkloppuFormControl: FormControl<Date>

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _asiakkaatDataSourceStateService: AsiakkaatDataSourceStateService,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _zone: NgZone
  ) {

    this.alvjaksot = this._asiakasService.alvIlmoitusjaksot
    this.yritysmuodot = this._asiakasService.yritysmuodotJaEiTiedossa

    this._route.url.pipe(
      map(urlSegments => {
        for (const o of urlSegments) {
          if (o.path === 'holvi') {
            return true
          }
        }
        return false
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(showHolviAsiakkaat => {
      if (this._asiakkaatDataSourceStateService.naytaHolviPoolAsiakkaatSubject.value !== showHolviAsiakkaat) {
        // console.log('Set näytä holvi pool', showHolviAsiakkaat)
        this._asiakkaatDataSourceStateService.naytaHolviPoolAsiakkaatSubject.next(showHolviAsiakkaat)
      }
    })

    const aloitusaikavali = this._asiakkaatDataSourceStateService.getCurrentTimespan()
    const alkaa = this._dateService.localDateToDate({ year: aloitusaikavali.start.year, month: aloitusaikavali.start.month, day: 12 })
    this.minMonthEnd = alkaa
    const loppuu = this._dateService.localDateToDate({ year: aloitusaikavali.end.year, month: aloitusaikavali.end.month, day: 12 })
    this.maxMonthStart = loppuu

    const nimiFormControl = new FormControl<string>(this._asiakkaatDataSourceStateService.vapaaTekstihakuObservable.value, [])
    const yhtiomuotoFormControl = new FormControl<Yritysmuoto | EiYhtiomuotoaYhtiomuoto>(this._asiakkaatDataSourceStateService.yhtiomuotoObservable.value, [])
    const alvjaksoFormControl = new FormControl<AlvIlmoitusjakso>(this._asiakkaatDataSourceStateService.alvjaksoObservable.value, [])
    this._vuosikkalkuFormControl = new FormControl<Date>(alkaa, [])
    this._vuosikkloppuFormControl = new FormControl<Date>(loppuu, [])
    const minuutitvaitehotFormControl = new FormControl<LukemanTyyppiVanha>(this._asiakkaatDataSourceStateService.lukemanTyyppiVanhaObservable.value, [])
    const vaintoimiavaativatFormControl = new FormControl<boolean>(this._asiakkaatDataSourceStateService.naytaVainToimiaVaativatAsiakkaatObservable.value, [])

    this.form = new FormGroup({
      'nimi': nimiFormControl,
      'yhtiomuoto': yhtiomuotoFormControl,
      'alvjakso': alvjaksoFormControl,
      'vuosikkalku': this._vuosikkalkuFormControl,
      'vuosikkloppu': this._vuosikkloppuFormControl,
      'minuutitvaitehot': minuutitvaitehotFormControl,
      'vaintoimiavaativat': vaintoimiavaativatFormControl
    })
    nimiFormControl.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this._zone.run(() => {
        if (value) {
          const val = value.trim().toLowerCase()
          this._asiakkaatDataSourceStateService.vapaaTekstihakuOldListObservable.next(val)
        } else {
          this._asiakkaatDataSourceStateService.vapaaTekstihakuOldListObservable.next(null)
        }
      })
    })
    yhtiomuotoFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('Asetettu', value)
      this._asiakkaatDataSourceStateService.yhtiomuotoObservable.next(value)
    })
    alvjaksoFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('Asetettu', value)
      this._asiakkaatDataSourceStateService.alvjaksoObservable.next(value)
    })
    this._vuosikkalkuFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      if (this._vuosikkalkuFormControl.valid && value) {
        this.minMonthEnd = value
        this._asiakkaatDataSourceStateService.setStartMonth({ month: value.getMonth() + 1, year: value.getFullYear() })
      }
    })
    this._vuosikkloppuFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      if (this._vuosikkloppuFormControl.valid && value) {
        // console.log('asetettu vuosikkloppu 2', value)
        this.maxMonthStart = value
        this._asiakkaatDataSourceStateService.setEndMonth({ month: value.getMonth() + 1, year: value.getFullYear() })
      }
    })
    minuutitvaitehotFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(arvo => {
      this._asiakkaatDataSourceStateService.lukemanTyyppiVanhaObservable.next(arvo)
    })
    vaintoimiavaativatFormControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this._asiakkaatDataSourceStateService.naytaVainToimiaVaativatAsiakkaatObservable.next(!!value)
    })

    this._asiakkaatDataSourceStateService.valittuAikavaliObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(aikavali => {
      if (aikavali) {
        if (aikavali.start) {
          const nykyinen = this._vuosikkalkuFormControl.value as Date
          const month = (nykyinen?.getMonth() ?? -2) + 1
          const year = nykyinen?.getFullYear() ?? -1
          if (month !== aikavali.start.month || year !== aikavali.start.year) {
            const date = this._dateService.localDateToDate({ year: aikavali.start.year, month: aikavali.start.month, day: 12 })
            this._vuosikkalkuFormControl.setValue(date, { emitModelToViewChange: true, emitViewToModelChange: false, emitEvent: false, onlySelf: true })
          }
        }
        if (aikavali.end) {
          const nykyinen = this._vuosikkloppuFormControl.value as Date
          const month = (nykyinen?.getMonth() ?? -2) + 1
          const year = nykyinen?.getFullYear() ?? -1
          if (month !== aikavali.end.month || year !== aikavali.end.year) {
            const date = this._dateService.localDateToDate({ year: aikavali.end.year, month: aikavali.end.month, day: 12 })
            this._vuosikkloppuFormControl.setValue(date, { emitModelToViewChange: true, emitViewToModelChange: false, emitEvent: false, onlySelf: true })
          }
        }
      }
    })
    this._asiakasService.asetaNykyinenAsiakas(null)
  }

  chosenMonthHandlerAlku(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this._vuosikkalkuFormControl.setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerLoppu(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this._vuosikkloppuFormControl.setValue(normalizedMonth)
    datepicker.close()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._asiakasService.asetaNykyinenAsiakas(null)
      this.hakuInput.focus()
      this._changeDetectorRef.markForCheck()
    }, 10)
  }

  ngOnInit() {
    this._versionTarkistusPalvelu.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versio => {
      this._versionTarkistusPalvelu.tarkistaVersio(versio)
    })

    // this.tyontekoInfoObservable = this._asiakasDataSourceService.kaikkiKirjanpitajanAsiakkaat.pipe(
    //   map(asiakkaat => {

    //     const paluuarvo: TyontekoInfo = {
    //       asiakkaita: 0,
    //       asiakkaitaJoillaHinta: 0,
    //       keskimaarainenhinta: new Big(0),
    //       nykyinenKuukausilaskutus: new Big(0),
    //       keskimaarainenPaivatavoite: new Big(0),
    //       tehtyTanaan: new Big(0),
    //       progressio: new Big(0),
    //       seuraavaErapaiva: null,
    //       paiviaSeuraavaanErapaivaan: null,
    //       progressioonLisattava: new Big(0),
    //       dynaaminenSumma: new Big(0),
    //       palluroidenSumma: new Big(0),
    //       huutomerkkienSumma: new Big(0)
    //     }

    //     for (const asiakas of asiakkaat) {
    //       paluuarvo.asiakkaita++
    //       if (asiakas.voimassaolevaHinta && asiakas.voimassaolevaHinta.hinta) {
    //         paluuarvo.nykyinenKuukausilaskutus = paluuarvo.nykyinenKuukausilaskutus.plus(asiakas.voimassaolevaHinta.hinta)
    //         paluuarvo.asiakkaitaJoillaHinta++
    //       }
    //       if (asiakas.paivanSaldo) {
    //         paluuarvo.tehtyTanaan = paluuarvo.tehtyTanaan.plus(asiakas.paivanSaldo)
    //       }
    //       if (asiakas.palluroidenSumma) {
    //         paluuarvo.palluroidenSumma = paluuarvo.palluroidenSumma.plus(asiakas.palluroidenSumma)
    //         paluuarvo.progressio = paluuarvo.progressio.minus(asiakas.palluroidenSumma)
    //       }
    //       if (asiakas.huutomerkkienSumma) {
    //         paluuarvo.huutomerkkienSumma = paluuarvo.huutomerkkienSumma.plus(asiakas.huutomerkkienSumma)
    //         paluuarvo.progressio = paluuarvo.progressio.minus(asiakas.huutomerkkienSumma)
    //       }
    //       if (asiakas.dynaaminenHinta) {
    //         paluuarvo.dynaaminenSumma = paluuarvo.dynaaminenSumma.plus(asiakas.dynaaminenHinta)
    //       }
    //       if (asiakas.vahennysProgressiosta) {
    //         paluuarvo.progressioonLisattava = paluuarvo.progressioonLisattava.plus(asiakas.vahennysProgressiosta)
    //       }
    //     }
    //     if (paluuarvo.asiakkaitaJoillaHinta > 0) {
    //       paluuarvo.keskimaarainenhinta = paluuarvo.nykyinenKuukausilaskutus.div(paluuarvo.asiakkaitaJoillaHinta)
    //       paluuarvo.keskimaarainenPaivatavoite = paluuarvo.nykyinenKuukausilaskutus.div(20)
    //     }

    //     paluuarvo.dynaaminenSumma = new Big(0).minus(paluuarvo.keskimaarainenPaivatavoite).plus(paluuarvo.dynaaminenSumma)

    //     const nyt = this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara())

    //     paluuarvo.seuraavaErapaiva = this._dateService.localDateToDate(nyt.day > 12 ? this._dateService.lisaaKuukausiaPaikallinen(nyt, 1) : nyt)
    //     paluuarvo.seuraavaErapaiva.setDate(12)
    //     paluuarvo.paiviaSeuraavaanErapaivaan = this._dateService.laskeArkipaivatPaivienValilla(this._aikakone.annanNykyinenPaivamaara(), paluuarvo.seuraavaErapaiva)
    //     paluuarvo.progressio = paluuarvo.progressio.plus(paluuarvo.progressioonLisattava)

    //     // console.log('Lasketaan tyontekoinfoa.')

    //     return paluuarvo

    //   }),
    //   lemonShare()
    // )

  }

}
