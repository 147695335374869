import { Component, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { MatSnackBar } from '@angular/material/snack-bar'

import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { YllapitoUriService } from '../../_jaettu-lemonator/service/yllapito-uri.service'
import { LemonaidKayttoehdotLemonatorissa, KayttoehdotLemonatorTyojono, TextInDiffLanguages } from '../../_jaettu/model/kayttoehdot'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { firstValueFrom } from 'rxjs'

export interface KayttoehtojenJulkaiseminenDialogData {
  textInDiffLangs: TextInDiffLanguages
}

@Component({
  templateUrl: './kayttoehtojen-julkaiseminen.dialog.html'
})
export class KayttoehtojenJulkaiseminenDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: KayttoehtojenJulkaiseminenDialogData,
    private dialogRef: MatDialogRef<KayttoehtojenJulkaiseminenDialog>,
    private _ladataanService: LadataanService,
    private _firebase: FirebaseLemonator,
    private _timestampService: TimestampService,
    private _snackbar: MatSnackBar,
    private _errorHandler: ErrorHandler,
    private _uriService: YllapitoUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  peruta() {
    this.dialogRef.close()
  }

  async julkaise() {
    try {
      this._ladataanService.aloitaLataaminen()

      const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

      const batch = this._firebase.firestoreBatch()

      const kayttoehdot: LemonaidKayttoehdotLemonatorissa = {
        pvm: this._timestampService.now(),
        uid: kirjanpitajanTiedot.uid,
        textInDiffLangs: this.data.textInDiffLangs,
      }
      batch.set(this._uriService.annaLemonaidKayttoehtojenMainUri(), kayttoehdot)

      const versionAvain = this._firebase.firestoreCreateId()

      const avainData = {
        avain: versionAvain
      }
      batch.set(this._uriService.annaLemonaidKayttoehtojenAvainUri(), avainData)

      const kayttoehdotVersio: LemonaidKayttoehdotLemonatorissa = {
        pvm: this._timestampService.now(),
        uid: kirjanpitajanTiedot.uid,
        textInDiffLangs: this.data.textInDiffLangs,
      }
      batch.set(this._uriService.annaLemonaidKayttoehdonVersionUri(versionAvain), kayttoehdotVersio)

      const tyojonoAvain = this._firebase.firestoreCreateId()
      const tyojono: KayttoehdotLemonatorTyojono = {
        pvm: this._timestampService.now(),
        uudenVersionAvain: versionAvain
      }
      batch.set(this._uriService.annaLemonaidKayttoehdonTyojononUri(tyojonoAvain), tyojono)

      await batch.commit()

      this._ladataanService.lopetaLataaminen()
      this._snackbar.open('Julkaiseminen onnistui', 'OK', { duration: 5000 })
      this.dialogRef.close()

    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
      this._snackbar.open('Julkaiseminen ei onnistunut', 'OK', { duration: 5000 })
      this.dialogRef.close()
    }
  }

}
