<div class="veroilmoitus-title">Elinkeinotoiminnan veroilmoitus</div>
<div class="veroilmoitus-subtitle">Yhteisö (6B) 2021</div>

<div *ngIf="!veroilmoitus && lukittu">
  Tältä vuodelta ei ole tehty veroilmotusta.
</div>

<!-- #tabs -->
<mat-tab-group [(selectedIndex)]="activeTabIndex" class="hide-all-overflows" *ngIf="veroilmoitus">
  <mat-tab label='1. Perustiedot' #taustatiedot>

    <div app-veroilmoitus-part numero="asiakastiedot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div class="block-row">
        <div class="tili-num"></div>
        <div class="long-col">Nimi</div>
        <div class="short-col">{{ asiakkaanNimi }}</div>
      </div>
      <div app-veroilmoitus-row-read-only numero="010" [veroilmoitus]="veroilmoitus"></div>
      <div app-veroilmoitus-row-dater numero="054" [veroilmoitus]="veroilmoitus" [showPen]="false" [editing]="false"></div>
    </div>

    <div app-veroilmoitus-part numero="yhteystiedot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-read-only numero="041" [veroilmoitus]="veroilmoitus"></div>
      <div app-veroilmoitus-row-read-only numero="042" [veroilmoitus]="veroilmoitus"></div>
      <div app-veroilmoitus-row-read-only numero="044" [veroilmoitus]="veroilmoitus"></div>
    </div>

    <div app-veroilmoitus-part numero="osakkeenomistajat-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-radio numero="185" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" direction="horizontal" [options]="kyllaEiOptions" [twoCol]="true"></div>

      <ng-container *ngFor="let repeatingMetadata of osakasRepeatingMetadatas; let i = index; trackBy: trackByRepeatingMetadata">
        <hr *ngIf="i > 0" />
        <div app-veroilmoitus-row-strin numero="701" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="false"></div>
        <div app-veroilmoitus-row-strin numero="703" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="false"></div>
        <div app-veroilmoitus-row-numbe numero="570" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="false" format="kpl" [desimaaleja]="0"></div>
        <div app-veroilmoitus-row-numbe numero="706" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="708" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="707" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="709" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="864" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      </ng-container>
    </div>

    <div app-veroilmoitus-part numero="osakkaiden-huoneistot-part" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" class="blue-block" [optional]="true">

      <ng-container *ngFor="let repeatingMetadata of osakasHuoneistotRepeatingMetadatas; let i = index; trackBy: trackByRepeatingMetadata">
        <hr *ngIf="i > 0" />
        <div app-veroilmoitus-row-strin numero="722" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-strin numero="724" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-dater numero="729" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="730" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
        <div style="flex-basis: 100%; padding: 7px 30px 7px 40px; display: flex; justify-content: flex-end;">

        </div>
      </ng-container>

    </div>

    <!-- (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [optional]="true" -->
    <div app-veroilmoitus-part numero="jaettavat-osingot-part" [veroilmoitus]="veroilmoitus" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">

      <!-- <div class="block-row">
        <div class="tili-num"></div>
        <div class="long-col error">Lemon Treen prosessissa osinkoja ei normaalisti ilmoiteta tässä.</div>
      </div> -->
      <!-- <div app-veroilmoitus-row-radio numero="onko-yhtio-paattanyt" [veroilmoitus]="veroilmoitus" [options]="kyllaEiOptions" [showPen]="false" [editing]="true" direction="vertical" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" class="hide-num"></div> -->

      <div app-veroilmoitus-row-header numero="jaettavat-osingot" [veroilmoitus]="veroilmoitus"></div>
      <ng-container *ngIf="osingotRepeatingMetadatas?.length > 0; else eiosinkoja">
        <ng-container *ngFor="let repeatingMetadata of osingotRepeatingMetadatas; let i = index; trackBy: trackByRepeatingMetadata">

          <div app-veroilmoitus-row-date numero="537" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
          <div app-veroilmoitus-row-date numero="538" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
          <div app-veroilmoitus-row-numbe numero="522" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>

          <div style="flex-basis: 100%; padding: 7px 30px 7px 40px; display: flex; justify-content: flex-end;">
          </div>

        </ng-container>
      </ng-container>

      <ng-template #eiosinkoja>
        <div style="padding: 15px;">Ei jaettuja osinkoja</div>
      </ng-template>


      <div app-veroilmoitus-row-header numero="vapaan-jaettavat-varat" [veroilmoitus]="veroilmoitus"></div>
      <ng-container *ngIf="vapaastaOmastaPaaomastaJaettuRepeatingMetadatas?.length > 0; else eivapaita">
        <ng-container *ngFor="let repeatingMetadata of vapaastaOmastaPaaomastaJaettuRepeatingMetadatas; let i = index; trackBy: trackByRepeatingMetadata">

          <div app-veroilmoitus-row-numbe numero="293" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
          <div app-veroilmoitus-row-date numero="294" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
          <div app-veroilmoitus-row-date numero="295" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>

          <div style="flex-basis: 100%; padding: 7px 30px 7px 40px; display: flex; justify-content: flex-end;">
          </div>

        </ng-container>
      </ng-container>

      <ng-template #eivapaita>
        <div style="padding: 15px;">Ei vapaasta omasta pääomasta jaettuja varoja</div>
      </ng-template>

      <div style="flex-basis: 100%; padding: 7px 30px 7px 40px;">
      </div>

    </div>

    <div app-veroilmoitus-part numero="omistusmuutos-ja-paaoma-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="531" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="number" [desimaaleja]="0"></div>
      <div app-veroilmoitus-row-radio numero="586" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
      <div app-veroilmoitus-row-numbe numero="571" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="kpl" [desimaaleja]="0"></div>
      <div app-veroilmoitus-row-numbe numero="591" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="592" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-radio numero="169" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab>
  <mat-tab label='2. Tuloverolaskelma'>
    <h2>Elinkeinotoiminnan tuotot</h2>

    <div app-veroilmoitus-part numero="liiketoiminta-ja-oma-kaytto-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="330" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="331" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="toiminnan-muut-tuotot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="332" numero2="333" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div *ngIf="nayta332tai333Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Täytä lomakkeet 71A ja 71B OmaVerossa!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="260" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div *ngIf="nayta260Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Täytä lomakkeet 71A ja 71B OmaVerossa!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="334" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [naytaEhdotus]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="335" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="336" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="rahoitustuotot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="337" numero2="338" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div *ngIf="nayta337tai338Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Täytä tarvittavat lomakkeet OmaVerossa!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="339" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="340" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [naytaEhdotus]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="341" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="344" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="345" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="muut-tuotot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="346" numero2="347" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="439" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div *ngIf="nayta439Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Täytä lomake 65 OmaVerossa!</div>
      </div>
      <div app-veroilmoitus-row-numb2 numero1="350" numero2="351" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="261" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div *ngIf="nayta261Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Täytä lomakkeet 71A ja 71B OmaVerossa!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="353" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="elinkeinotoiminnan-veronalaiset-tuotot-part" class="gray-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">354</div>
        <div class="long-col">Elinkeinotoiminnan veronalaiset tuotot yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="354" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <div app-veroilmoitus-part numero="verottomat-erat-kirjanpidossa-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="357" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="320" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="318" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="358" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <h2>Elinkeinotoiminnan kulut</h2>

    <div app-veroilmoitus-part numero="materiaalit-ja-palvelut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="359" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="360" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="henkilostokulut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="361" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="362" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="363" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="poistot-ja-kayttoomaisuuden-arvoalentumiset-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 #edit364_365 numero1="364" numero2="365" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numb2 numero1="366" numero2="367" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div class="block-row">
        <div class="tili-num"></div>
        <div>
          <h3 style="margin: 0 0 8px; font-weight: 500; font-size: 20px; line-height: 32px;">Erittely (lomake 62 A)</h3>
          <div style="margin-left: 10px;" class="error">Jos muuta kuin irtainta käyttöomaisuutta, täytä lomake OmaVerossa!</div>
        </div>
      </div>
      <div app-veroilmoitus-row-numbe #edit800 numero="800" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe #edit801 numero="801" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe #edit802 numero="802" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="803" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe #edit804 numero="804" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="805" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe #edit806 numero="806" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe #edit807 numero="807" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="847" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="848" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="849" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-end"></div>

      <div class="ehdotusnappula-container group-end">
        <button class="primarybutton" (click)="taytaPoistotEhdotus()" *ngIf="!lukittu" mat-button>Hae ehdotukset</button>
      </div>

    </div>

    <div app-veroilmoitus-part numero="toiminnan-muut-kulut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="368" numero2="369" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numb2 numero1="370" numero2="371" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numb2 numero1="372" numero2="373" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div *ngIf="nayta372tai373Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 71A ja/tai 71B!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="374" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="375" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="376" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="377" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="vahennyskelvottomat-kulut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="378" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="379" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="380" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="381" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="382" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="383" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="384" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="rahoituskulut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="none1" numero2="328" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start hide-num1 hide-col1"></div>
      <div app-veroilmoitus-row-numbe numero="386" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="387" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="398" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="399" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numb2 numero1="388" numero2="dnt" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="hide-num2 hide-col2"></div>
      <div app-veroilmoitus-row-numb2 numero1="389" numero2="390" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="391" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="392" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="muut-kulut-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numb2 numero1="none1" numero2="327" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start hide-num1 hide-col1"></div>
      <div app-veroilmoitus-row-numbe numero="436" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div *ngIf="nayta436Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 65!</div>
      </div>
      <div app-veroilmoitus-row-numb2 numero1="394" numero2="395" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numb2 numero1="270" numero2="271" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" [naytaEhdotus]="true"></div>
      <div *ngIf="nayta270tai271Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 71B!</div>
      </div>
      <div app-veroilmoitus-row-numb2 numero1="272" numero2="273" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="396" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="504" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">397</div>
        <div class="long-col">Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="397" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-dark-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">500<br />505</div>
        <div class="long-col">Elinkeinotoiminnan tulos</div>
        <div app-veroilmoitus-value-read-only numero="elinkeinotoiminnan-tulos" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab>
  <mat-tab label='3. Varallisuuslaskelma'>

    <h2>Varat</h2>

    <div app-veroilmoitus-part numero="kayttoomaisuus-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="400" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="401" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="402" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div *ngIf="nayta402Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 18!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="403" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="404" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="405" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div *ngIf="nayta405Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 18!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="406" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="407" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="408" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="409" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="410" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part numero="vaihto-omaisuus-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="411" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="412" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="413" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="414" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="415" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="416" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="417" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="418" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part numero="rahoitusomaisuus-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="419" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="420" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="421" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="422" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="423" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="424" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div *ngIf="nayta424Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 8A!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="425" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="463" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="426" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="427" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="428" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="429" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part numero="muu-omaisuus-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="430" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div *ngIf="nayta430Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 8A!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="431" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div *ngIf="nayta431Teksti" class="block-row information">
        <div class="tili-num"></div>
        <div class="long-col error">Erittele OmaVerossa lomakkeella 18!</div>
      </div>
      <div app-veroilmoitus-row-numbe numero="432" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="437" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="438" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="433" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="434" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">435</div>
        <div class="long-col">Varat yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="435" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <h2>Velat</h2>

    <div app-veroilmoitus-part numero="velat-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="450" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="451" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="452" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="453" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="454" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="455" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="456" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="457" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="464" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="465" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="458" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="459" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">460</div>
        <div class="long-col">Velat yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="460" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
      <div class="block-row group-start groupd-end">
        <div class="tili-num">461</div>
        <div class="long-col">Lyhytaikaiset velat yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="461" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
      <div class="block-row group-start groupd-end">
        <div class="tili-num">462</div>
        <div class="long-col">Pitkäaikaiset velat yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="462" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-dark-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">573<br />574</div>
        <div class="long-col">Nettovarallisuuus</div>
        <div app-veroilmoitus-value-read-only numero="nettovarallisuus" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <h2>Oma pääoma</h2>

    <div app-veroilmoitus-part numero="sidottu-oma-paaoma-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="470" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="495" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="vapaa-oma-paaoma-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="496" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="497" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="474" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="475" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="476" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="477" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="true" [editing]="false" format="currency" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-veronalaiset-tuotot-part" [veroilmoitus]="veroilmoitus" class="gray-block centered-row">
      <div class="block-row group-start groupd-end fat-end-row">
        <div class="tili-num">478</div>
        <div class="long-col">Oma pääoma yhteensä</div>
        <div app-veroilmoitus-value-read-only numero="478" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab>
  <mat-tab label='4. Lisätiedot'>

    <div app-veroilmoitus-part numero="tilintarkastus-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-radio numero="177" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="tilintarkastusOptions" direction="vertical"></div>
      <div app-veroilmoitus-row-radio *ngIf="nayta178Radio" numero="178" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kylla1Ei2Options" direction="horizontal"></div>
    </div>

    <div app-veroilmoitus-part numero="lisatiedot-part" [veroilmoitus]="veroilmoitus" class="blue-block">
      <div app-veroilmoitus-row-radio numero="173" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
      <div app-veroilmoitus-row-radio numero="174" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
      <div app-veroilmoitus-row-radio numero="176" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
      <div app-veroilmoitus-row-radio numero="184" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
      <div app-veroilmoitus-row-radio numero="182" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true" [options]="kyllaEiOptions" direction="horizontal" [twoCol]="true"></div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab>
  <!-- <mat-tab label='5. Muut selvitykset'>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab> -->
  <mat-tab label="5. Lähetä">
    <ng-content></ng-content>
  </mat-tab>
</mat-tab-group>