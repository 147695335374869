import { Kirjanpitotili, Kirjaus, Kirjausrivi } from '../model/kirjanpito'

export class KirjanpitoReskontraUriService {

  getVientiAvainSeparator() {
    return '<||||>'
  }
  createVientiAvain(kirjausAvain: Kirjaus['avain'], kirjausriviAvain: Kirjausrivi['av']) {
    return kirjausAvain + this.getVientiAvainSeparator() + kirjausriviAvain
  }

  getReskontratCollection(asiakasAvain: string, tilinumero: Kirjanpitotili['numero']) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-reskontrat/' + asiakasAvain + '_' + tilinumero
  }

  getReskontraViennitCollectionName() {
    return '/kirjanpito-reskontra-viennit'
  }

  getReskontraViennitCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-reskontra-viennit'
  }
  getReskontranVientiUri(asiakasAvain: string, vientiAvain: string) {
    return this.getReskontraViennitCollection(asiakasAvain) + '/' + vientiAvain
  }
  getReskontranVientHistoriaUri(asiakasAvain: string, vientiAvain: string, historiaAvain: string) {
    return this.getReskontranVientiUri(asiakasAvain, vientiAvain) + '/kirjanpito-reskontra-vienti-historia/' + historiaAvain
  }

  getReskontraPaivitaKirjauksetTyojonoUri(asiakasAvain: string, tyojonoId: string) {
    return 'tyojono/' + asiakasAvain + '/reskontra-paivita-kirjaukset/' + tyojonoId
  }
}
