<div class="list-page kapea" style="max-width: 1200px;">

  <h2>Asiakkaalle lähetetyt kirjanpito sähköpostit</h2>

  <div style="padding-left: 3em; padding-right: 3em;">

    <ng-container *ngIf="naytettavatLahetyksetObservable | async; let naytettavatLahetykset else ladataan">
      <table *ngIf="naytettavatLahetykset.length else tyhja">
        <thead>
          <td>Aihe</td>
          <td>Kuukausi</td>
          <td>Luotu Lemonatorissa</td>
          <td>Lähetetty Postmarkista</td>
          <td>Luoja</td>
          <td>Saaja(t)</td>
        </thead>
        <tr class="bordered-row" *ngFor="let lahetys of naytettavatLahetykset">
          <td>{{ lahetys.aihe }}</td>
          <td>{{ lahetys.kuukausi }} </td>
          <td>{{ lahetys.luotu }}</td>
          <td>{{ lahetys.lahetetty }}</td>
          <td>{{ lahetys.luojaNimi }}</td>
          <td>{{ lahetys.recipients }}</td>
        </tr>
      </table>
    </ng-container>

    <ng-template #tyhja>
      <div class="no-emails-text">Sähköposteja ei ole vielä lähetetty</div>
    </ng-template>

    <ng-template #ladataan>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>

  </div>

</div>