import { PipeTransform, Pipe } from '@angular/core'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampService } from '../service/timestamp-service'
import { Timestamp, LocalDate } from '../../_shared-core/model/common'

@Pipe({
  name: 'ljson'
})
export class PrettyPrintJsonPipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService,
    private dateService: DateService,
    private timestampService: TimestampService
  ) {

  }

  transform(val) {
    if (val === undefined) {
      return 'undefined'
    }
    if (val === null) {
      return 'null'
    }
    return JSON.stringify(val, (key: string, value: any): any => {
      if (this.onkoTimestamp(value)) {
        if (value.toDate) {
          return this.dateService.muotoilePaiva(value as Timestamp, 'fi')
        } else {
          const timestamp = this.timestampService.newTimestamp(value.seconds, value.nanoseconds)
          return this.dateService.muotoilePaiva(timestamp, 'fi')
        }
      }
      if (this.onkoLocalDate(value)) {
        return this.dateService.muotoilePaikallinenPaiva(value as LocalDate, 'fi')
      }
      return value
    }, 2)
      .replace(/ /g, '&nbsp;')
      .replace(/(?:\r\n|\r|\n)/g, '<br/>')
  }

  onkoTimestamp(value: any): boolean {
    return value &&
      Object.keys(value).length === 2 &&
      this.currencyService.onkoNumero(value.seconds) &&
      this.currencyService.onkoNumero(value.nanoseconds)
  }

  onkoLocalDate(value: any): boolean {
    return value &&
      Object.keys(value).length === 3 &&
      this.currencyService.onkoNumero(value.day) &&
      this.currencyService.onkoNumero(value.month) &&
      this.currencyService.onkoNumero(value.year)
  }

}
