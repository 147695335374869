
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { FirebaseLemonator } from '../service/firebase-lemonator.service'

import { Asiakas, AsiakkaanMaksutapa, Kayttaja } from '../../_jaettu-lemonator/model/asiakas'
import { Kirjanpitotili, AlvMaaritys } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaService } from '../service/tilikartta.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from '../service/kirjanpitaja/kirjanpitaja.service'
import { AsiakasService } from '../service/asiakas/asiakas.service'
import { AsiakasKopioija } from '../service/asiakas/asiakas.kopioija'
import { KayttajaKopioija } from '../service/asiakas/kayttaja.kopioija'
import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'
import { AlvLemonatorService } from '../service/alv-lemonator.service'

import { of as observableOf, Observable, combineLatest, of } from 'rxjs'
import { map, take, tap, switchMap, filter, catchError } from 'rxjs/operators'

export interface KayttajaComponentData {
  asiakas: Asiakas
  kayttaja: Kayttaja
  muidenKayttajienEmailit: string[]
}

@Injectable()
export class AsiakasComponentDataResolve  {

  private existingData: Asiakas = null

  constructor(
    private asiakasService: AsiakasService,
    private asiakasKopioija: AsiakasKopioija,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) {

  }

  asetaOlemassaolevaData(data: Asiakas) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Asiakas> {
    return this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitaja => {
        if (!kirjanpitaja) {
          return of<Asiakas>(null)
        }
        const asiakasAvain = route.params['asiakasAvain']
        if (asiakasAvain === 'uusi') {
          const uusiAsiakas = this.asiakasKopioija.annaUusiAsiakas()
          this.asiakasService.asetaNykyinenAsiakas(uusiAsiakas)
          return observableOf(uusiAsiakas)
        } else if (this.existingData != null && this.existingData.avain === asiakasAvain) {
          this.asiakasService.asetaNykyinenAsiakas(this.existingData)
          return observableOf(this.existingData)
        } else {
          return this.asiakasService.annaAsiakasObservable(asiakasAvain).pipe(
            catchError(err => {
              console.error('Error while loading asiakas, returning null', err)
              return of<Asiakas>(null)
            }),
            take(1),
            tap(asiakas => {
              this.asiakasService.asetaNykyinenAsiakas(asiakas)
            })
          )
        }
      })
    )
  }

}

@Injectable()
export class KayttajaComponentDataResolve  {

  private existingData: KayttajaComponentData = null

  constructor(
    private asiakasService: AsiakasService,
    private kayttajaKopioija: KayttajaKopioija,
    private _firebase: FirebaseLemonator
  ) {

  }

  asetaOlemassaolevaData(data: KayttajaComponentData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<KayttajaComponentData> {
    const asiakasAvain = route.params['asiakasAvain']
    const kayttajaAvain = route.params['kayttajaAvain']

    const asiakasObservable = this._annaAsiakasObservable(asiakasAvain)
    const muidenKayttajienEmailitObservable = this._annaAsiakkaanMuidenKayttajienEmailitObservable(asiakasAvain, kayttajaAvain)
    const kayttajaObservable = asiakasObservable.pipe(
      switchMap(asiakas => {
        return this._annaKayttajaObservable(asiakas, kayttajaAvain)
      })
    )

    return combineLatest([asiakasObservable, kayttajaObservable, muidenKayttajienEmailitObservable]).pipe(
      map(([asiakas, kayttaja, muidenKayttajienEmailit]) => {
        const data: KayttajaComponentData = {
          asiakas: asiakas,
          kayttaja: kayttaja,
          muidenKayttajienEmailit: muidenKayttajienEmailit
        }
        return data
      })
    )
  }

  private _annaAsiakasObservable(asiakasAvain: string): Observable<Asiakas> {
    if (this.existingData && this.existingData.asiakas && this.existingData.asiakas.avain === asiakasAvain) {
      this.asiakasService.asetaNykyinenAsiakas(this.existingData.asiakas)
      return observableOf(this.existingData.asiakas)
    }
    return this.asiakasService.annaAsiakasObservable(asiakasAvain).pipe(
      take(1),
      tap(asiakas => {
        this.asiakasService.asetaNykyinenAsiakas(asiakas)
      })
    )
  }

  private _annaKayttajaObservable(asiakas: Asiakas, kayttajaAvain: string): Observable<Kayttaja> {
    if (kayttajaAvain === 'uusi') {
      return this._firebase.firestoreCollection<AsiakkaanMaksutapa>('asiakkaat/' + asiakas.avain + '/maksutavat').listen().pipe(
        switchMap(maksutavat => {
          return observableOf(this.kayttajaKopioija.annaUusiKayttaja(maksutavat))
        }),
        take(1)
      )
    }
    if (this.existingData && this.existingData.kayttaja && this.existingData.kayttaja.avain === kayttajaAvain) {
      return observableOf(this.existingData.kayttaja)
    }
    return this._firebase.firestoreDoc<Kayttaja>('/asiakkaat/' + asiakas.avain + '/kayttajat/' + kayttajaAvain).listen().pipe(take(1))
  }

  private _annaAsiakkaanMuidenKayttajienEmailitObservable(asiakasAvain: string, kayttajaAvain: string): Observable<string[]> {
    if (!asiakasAvain || !kayttajaAvain) {
      return observableOf([])
    }
    if (this.existingData?.muidenKayttajienEmailit) {
      return observableOf(this.existingData.muidenKayttajienEmailit)
    }

    return this._firebase.firestoreCollection<Kayttaja>('asiakkaat/' + asiakasAvain + '/kayttajat').where('avain', '!=', kayttajaAvain).listen().pipe(
      map(kayttajat => {
        return kayttajat.map(k => k.email)
      }),
      take(1)
    )
  }
}

export interface KirjanpitoComponentData {
  asiakas: Asiakas
  tilitMap: Map<string, Kirjanpitotili>
  alvMap: Map<string, AlvMaaritys>
  kirjanpitajienNimitiedotMap: Map<string, KirjanpitajanNimitiedot>
  maksutavatMap: Map<string, AsiakkaanMaksutapa>
}
@Injectable()
export class KirjanpitoComponentDataResolve  {

  private existingData: Asiakas = null

  constructor(
    private asiakasService: AsiakasService,
    private tilikarttaService: TilikarttaService,
    private kirjanpitajaService: KirjanpitajaService,
    private alvLemonatorService: AlvLemonatorService
  ) { }

  asetaOlemassaolevaData(data: Asiakas) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<KirjanpitoComponentData> {
    const asiakasAvain = route.params['asiakasAvain']
    let asiakasObservable: Observable<Asiakas> = null
    if (this.existingData != null && this.existingData.avain === asiakasAvain) {
      this.asiakasService.asetaNykyinenAsiakas(this.existingData)
      asiakasObservable = observableOf(this.existingData)
    } else {
      asiakasObservable = this.asiakasService.annaAsiakasObservable(asiakasAvain).pipe(
        tap(asiakas => {
          this.asiakasService.asetaNykyinenAsiakas(asiakas)
        })
      )
    }

    const tilitMapObservable = this.tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      filter(tilit => !!tilit),
      map(tilit => {
        const tiliMap: Map<string, Kirjanpitotili> = new Map()
        for (const tili of tilit) {
          tiliMap.set(tili.numero, tili)
        }
        return tiliMap
      })
    )
    const maksutavatObservable = this.asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      filter(maksutavat => !!maksutavat),
      map(maksutavat => {
        const maksutavatMap: Map<string, AsiakkaanMaksutapa> = new Map()
        for (const maksutapa of maksutavat) {
          maksutavatMap.set(maksutapa.tunniste + '', maksutapa)
        }
        return maksutavatMap
      })
    )

    return combineLatest([asiakasObservable, tilitMapObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, maksutavatObservable, this.alvLemonatorService.annaAlvMaaritykset()]).pipe(
      map(([asiakas, tilitMap, kirjanpitajienNimitiedotMap, maksutavatMap, alvMap]) => {
        const data: KirjanpitoComponentData = {
          maksutavatMap: maksutavatMap,
          asiakas: asiakas,
          alvMap: alvMap,
          tilitMap: tilitMap,
          kirjanpitajienNimitiedotMap: kirjanpitajienNimitiedotMap
        }
        return data
      }),
      take(1)
    )

  }

}
