import { Component, OnInit, OnDestroy, ErrorHandler, ChangeDetectionStrategy, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { Subject, Observable, of as observableOf, combineLatest, BehaviorSubject, merge, firstValueFrom } from 'rxjs'
import { map, switchMap, tap, startWith, takeUntil, withLatestFrom } from 'rxjs/operators'

import { KirjattavaKuitti, KirjattavaLasku, KirjattavanLaskunPoistoPyynto, KirjattavanRaahatunTiedostonPoistoPyynto, KirjattavanKuitinPoistoPyynto, KirjattavaRaahattuTiedosto, KirjaukseenLiitettyjenTiedostojenSivut, JatkuvaTositeMuutosPyynto, PaivitaTositteenKorostusPyynto } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { FirestoreTosite, PALKKATOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID } from 'app/_jaettu/model/tosite'
import { LaskuBase, LaskunTyypit } from 'app/_jaettu/model/lasku'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'

import { AreYouSureDialog, AreYouSureDialogData } from '../../_jaettu-angular/_components/are-you-sure.dialog'
import { KirjanpitoImageHandlerService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { NaytettavaKirjaus } from '../kirjanpito.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MAKSUTAPA_MYYNTITOSITE } from 'app/_jaettu/model/kayttaja'
import { LocalMonth } from 'app/_shared-core/model/common'
import { KirjanpitoTositeService } from 'app/_angular/service/kirjanpito/kirjanpito-tosite.service'
import { TositeMuokkaaYliajosummaaDialog, TositeMuokkaaYliajosummaaDialogData } from '../dialogit/tosite.muokkaa-yliajosummaa.dialog'
import { TositeTyyppi } from './tosite-standalone.component'
import { LaskuSharedService } from 'app/_jaettu/service/lasku/lasku-shared.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { AsiakkaanMaksutavat } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'


export interface NaytettavaKuitti {
  kirjattavaKuitti: KirjattavaKuitti
  // kuunVaihde: boolean
  tallennetaan: boolean
  poistetaan: boolean
  summa: string
  selite: string
  pvm: string
  kuitti: FirestoreTosite
}

export interface NaytettavaLasku {
  kirjattavaLasku: KirjattavaLasku
  // kuunVaihde: boolean
  tallennetaan: boolean
  poistetaan: boolean
  summa: string
  asiakas: string
  pvm: string
  lasku: LaskuBase
  viitenumero: string
}

export interface NaytettavaRaahattava {
  raahattava: KirjattavaRaahattuTiedosto
  tallennetaan: boolean
  poistetaan: boolean
  avain: string
}

export interface NaytettavaJatkuva {
  avain: string
  selite: string
  raahattava: NaytettavaRaahattava
  kuitti: NaytettavaKuitti
  // lasku: NaytettavaLasku
  pvm?: string
  summa?: string
}

interface MaksutapaJaKuitit {
  maksutapa: AsiakkaanMaksutapa
  kuitit: NaytettavaKuitti[]
  naytaLukumaara: boolean
  maksutavanTunniste: string
  maara: number
  korostettuja: number
}

@Component({
  selector: '[app-kirjanpito-tosite-kirjaamattomat]',
  templateUrl: './tosite-kirjaamattomat.component.html',
  styleUrls: ['./tosite-kirjaamattomat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTositeKirjaamattomatTositteetComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  private _liitetytLaskutSubject = new BehaviorSubject<Set<string>>(new Set())
  private _liitetytKuititSubject = new BehaviorSubject<Set<string>>(new Set())
  // private _esikatseluAuki = false

  @Input() naytettavaKirjausObservable: Observable<NaytettavaKirjaus>
  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Input() valittuKirjanpidonMaksutapaObservable: Observable<AsiakkaanMaksutapa>

  @Output() maksutavatJoissaOnKirjaamattomiaTositteita: EventEmitter<AsiakkaanMaksutapa[]> = new EventEmitter()

  maksutavatObservable: Observable<MaksutapaJaKuitit[]>
  valitunMaksutavanKuititObservable: Observable<NaytettavaKuitti[]>
  kirjaamattomatLaskutObservable: Observable<NaytettavaLasku[]>
  kirjaamattomatYhteisomyyntiLaskutObservable: Observable<NaytettavaLasku[]>
  kirjaamattomatRaahattavatObservable: Observable<NaytettavaRaahattava[]>
  jatkuvatTositteetObservable: Observable<NaytettavaJatkuva[]>
  kirjaamattomienLaskujenMaaraObservable: Observable<{ total: number, korostettuja: number }>
  kirjaamattomienYhteisomyyntiLaskujenMaaraObservable: Observable<{ total: number, korostettuja: number }>
  kirjaamattomienRaahattavienMaaraObservable: Observable<{ total: number, korostettuja: number }>
  jatkuvienTositteidenMaaraObservable: Observable<{ total: number, korostettuja: number }>
  onkoListaTyhjaObservable: Observable<boolean>
  ladataanObservable: Observable<boolean>
  tiedostoaPoistetaanObservable: Observable<boolean>
  private _rawMaksutavat: Observable<AsiakkaanMaksutapa[]>

  valittuMaksutapa: string = ''
  private _valittuMaksutapaSubject = new BehaviorSubject<string>(this.valittuMaksutapa)
  valittuMaksutapaObservable = this._valittuMaksutapaSubject.asObservable()

  private _naytaMyosTulevatKuukaudet = new BehaviorSubject<boolean>(false)

  valittuTosite: string
  valittuLasku: string
  valittuRaahattava: string
  valittuJatkuva: string

  private _valitutSivut: KirjaukseenLiitettyjenTiedostojenSivut

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _kirjanpitoTositeService: KirjanpitoTositeService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _timestampProvider: TimestampProviderBase,
    private _dialog: MatDialog,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService,
    private _changeDetector: ChangeDetectorRef,
    private _snackbar: MatSnackBar,
    private _laskuSharedService: LaskuSharedService
  ) { }

  naytaMyosTulevaKuukaudet(nayta: boolean) {
    this._naytaMyosTulevatKuukaudet.next(!!nayta)
  }

  ngOnInit() {

    this._rawMaksutavat = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => [AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT, AsiakkaanMaksutavat.MAKSUTAPA_PALKKATOSITE].concat(maksutavat || []))
    )

    const rajoitaKuukauteenObservable = combineLatest([this.valittuKuukausiObservable, this._naytaMyosTulevatKuukaudet]).pipe(
      map(([kuukausi, naytaMyosTulevat]) => {
        // console.log('Tulevat', naytaMyosTulevat, kuukausi)
        return naytaMyosTulevat ? null : kuukausi
      })
    )

    const naytettavatRaahattavatObservable: Observable<NaytettavaRaahattava[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<NaytettavaRaahattava[]>([])
        }

        return this._firebase.firestoreCollection<KirjattavaRaahattuTiedosto>(this._kirjanpitoUriService.annaKirjattavatRaahatutCollectionUri(asiakas.avain)).where('p', '==', false).where('l', '==', false).listenSnapshots().pipe(
          map(snaps => {
            return snaps.map(a => {
              const p: NaytettavaRaahattava = {
                avain: a.id,
                tallennetaan: false,
                poistetaan: false,
                raahattava: a.data() as KirjattavaRaahattuTiedosto
              }
              return p
            })
          })
        )
      }),
      lemonShare()
    )

    this.kirjaamattomatRaahattavatObservable = naytettavatRaahattavatObservable.pipe(
      map(naytettavat => {
        return naytettavat.filter(n => !n.raahattava.j)
      }),
      tap(naytettavat => {
        if (naytettavat && naytettavat.length > 0) {
          const sivut: KirjaukseenLiitettyjenTiedostojenSivut[] = []
          for (const naytettava of naytettavat) {
            const sivu = this._kirjanpitoImageHandlerService.muunnaKirjattavaRaahattavaLiitetyiksiSivuiksi(naytettava)
            sivut.push(sivu)
          }
          // Do not block, we don't care how it goes as it's only for caching.
          // this._kirjanpitoImageService.lisaaKirjattavienKuittienTiedostotCacheen(asiakas, sivut).catch(err => { this._errorHandler.handleError(err) })
        }
        return naytettavat
      })
    )

    this.tiedostoaPoistetaanObservable = merge(
      this._kirjanpitoImageHandlerService.tositteenLiitoksenPoistoAloitettiinObservable,
      this._kirjanpitoImageHandlerService.tositteenLiitoksenPoistoEpaonnistuiObservable,
      this._kirjanpitoImageHandlerService.tositteenLiitoksenPoistoOnnistuiObservable
    ).pipe(
      map(() => {
        const iterator = this._kirjanpitoImageHandlerService.annaKaikkiTositteetJoidenPoistaminenOnKesken()
        return !this._iteratorIsEmpty(iterator)
      }),
      startWith(false)
    )

    this.kirjaamattomienRaahattavienMaaraObservable = this.kirjaamattomatRaahattavatObservable.pipe(
      map(raahattavat => {
        if (!raahattavat?.length) {
          return null
        }
        return { total: raahattavat.length, korostettuja: raahattavat.filter(r => r.raahattava.ko).length }
      })
    )

    const kaikkiNaytettavatKuitit: Observable<NaytettavaKuitti[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<KirjattavaKuitti[]>([])
        }
        return this._firebase.firestoreCollection<KirjattavaKuitti>(this._kirjanpitoUriService.annaKirjattavatFirestoreTositteetCollectionUri(asiakas.avain))
          .where('kirjattuPvm', '==', null)
          .where('poistettu', '==', false)
          .where('kirjanpitajanPoistama', '==', false)
          .listen()
      }),
      map(kuitit => {
        return kuitit.map(k => {
          const kuitti = k.kuitti[k.kuitti.length - 1]
          const nk: NaytettavaKuitti = {
            // kuunVaihde: false,
            tallennetaan: false,
            poistetaan: false,
            kirjattavaKuitti: k,
            kuitti: kuitti,
            pvm: this._dateService.muotoilePaikallinenPaiva(kuitti.localPvm, 'fi'),
            selite: kuitti.selite,
            summa: this._annaSumma(kuitti)
          }
          return nk
        })
      }),
      tap(kuitit => {
        if (kuitit && kuitit.length > 0) {
          const sivut: KirjaukseenLiitettyjenTiedostojenSivut[] = []
          for (const kuitti of kuitit) {
            const sivu = this._kirjanpitoImageHandlerService.muunnaKirjattavaKuittiLiitetyiksiSivuiksi(kuitti.kirjattavaKuitti)
            sivut.push(sivu)
          }
        }
        return kuitit
      })
    )

    const kirjaamattomatNaytettavatKuititObservable = combineLatest([kaikkiNaytettavatKuitit, this._liitetytKuititSubject]).pipe(
      map(([kuitit, liitetytKuittiavaimet]) => {
        return kuitit.filter(a => {
          return !a.kirjattavaKuitti.j && !liitetytKuittiavaimet.has(a.kuitti.avain)
        })
      })
    )

    const kaikkiNaytettavatLaskut: Observable<NaytettavaLasku[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<KirjattavaLasku[]>([])
        }
        return this._firebase.firestoreCollection<KirjattavaLasku>(this._kirjanpitoUriService.annaKirjattavatLaskutCollectionUri(asiakas.avain))
          .where('kirjattuPvm', '==', null)
          .where('kirjanpitajanPoistama', '==', false)
          .listen()
      }),
      map(laskut => {
        return laskut.sort((a, b) => {
          const laskuA = a.lasku[a.lasku.length - 1]
          const laskuB = b.lasku[b.lasku.length - 1]
          return laskuA.summa - laskuB.summa
        })
      }),
      map(kirjattavatLaskut => {

        const naytettavatLaskut: NaytettavaLasku[] = []
        // let currentKuukausi: number = null
        for (const kirjattavaLasku of kirjattavatLaskut) {

          const laskurypas = kirjattavaLasku.lasku[kirjattavaLasku.lasku.length - 1]
          const lasku = this._laskuSharedService.annaViimeisinEiLuonnosLasku(laskurypas)
          const naytettavaLasku: NaytettavaLasku = {
            // kuunVaihde: false,
            tallennetaan: false,
            poistetaan: false,
            asiakas: lasku.asiakas.nimi,
            kirjattavaLasku: kirjattavaLasku,
            lasku: lasku,
            pvm: this._dateService.muotoilePaikallinenPaiva(lasku.pvml, 'fi'),
            summa: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(lasku.summa, 'fi'),
            viitenumero: this._laskuSharedService.annaMuotoiltuViitenumero(laskurypas)
          }

          naytettavatLaskut.push(naytettavaLasku)
        }
        return naytettavatLaskut
      }),
      lemonShare()
    )

    const laskutObservable = combineLatest([kaikkiNaytettavatLaskut, this._liitetytLaskutSubject]).pipe(
      map(([kirjattavatLaskut, liitetytLaskuavaimet]) => {
        return kirjattavatLaskut.filter(a => {
          return !liitetytLaskuavaimet.has(a.kirjattavaLasku.avain)
        })
      })
    )

    const laskutIlmanYhteisomyyntiaObservable = laskutObservable.pipe(
      map(laskut => {
        return laskut.filter(lasku => {
          return !this._onkoYhteisomyyntilasku(lasku)
        })
      })
    )

    this.kirjaamattomatLaskutObservable = combineLatest([laskutIlmanYhteisomyyntiaObservable, rajoitaKuukauteenObservable]).pipe(
      map(([laskut, rajoitaKuukauteen]) => {
        if (rajoitaKuukauteen) {
          return laskut.filter(lasku => {
            return !lasku.lasku.pvml || this._dateService.compareLocalMonths(lasku.lasku.pvml, '<=', rajoitaKuukauteen)
          })
        }
        return laskut
      })
    )
    this.kirjaamattomienLaskujenMaaraObservable = laskutIlmanYhteisomyyntiaObservable.pipe(
      map(laskut => {
        if (!laskut?.length) {
          return null
        }
        return { total: laskut.length, korostettuja: laskut.filter(l => l.kirjattavaLasku.korostettu).length }
      })
    )

    const yhteisomyyntilaskutObservable = laskutObservable.pipe(
      map(laskut => {
        return laskut.filter(lasku => {
          return this._onkoYhteisomyyntilasku(lasku)
        })
      })
    )

    this.kirjaamattomatYhteisomyyntiLaskutObservable = combineLatest([yhteisomyyntilaskutObservable, rajoitaKuukauteenObservable]).pipe(
      map(([laskut, rajoitaKuukauteen]) => {
        if (rajoitaKuukauteen) {
          return laskut.filter(lasku => {
            return !lasku.lasku.pvml || this._dateService.compareLocalMonths(lasku.lasku.pvml, '<=', rajoitaKuukauteen)
          })
        }
        return laskut
      })
    )
    this.kirjaamattomienYhteisomyyntiLaskujenMaaraObservable = yhteisomyyntilaskutObservable.pipe(
      map(laskut => {
        if (!laskut?.length) {
          return null
        }
        return { total: laskut.length, korostettuja: laskut.filter(l => l.kirjattavaLasku.korostettu).length }
      })
    )

    this.jatkuvatTositteetObservable = combineLatest([naytettavatRaahattavatObservable, kaikkiNaytettavatKuitit]).pipe(
      map(([raahatutTiedostot, kuitit]) => {
        const output: NaytettavaJatkuva[] = []
        for (const rt of raahatutTiedostot) {
          if (rt.raahattava.j) {
            const jatkuvaFromRaahattava: NaytettavaJatkuva = {
              avain: rt.avain,
              raahattava: rt,
              kuitti: null,
              selite: rt.raahattava.n
            }
            if (rt.raahattava.a) {
              jatkuvaFromRaahattava.summa = this._currencyService.formatoiRahaIlmanValuuttaSymbolia(rt.raahattava.a, 'fi')
            }
            output.push(jatkuvaFromRaahattava)
          }
        }
        for (const k of kuitit) {
          if (k.kirjattavaKuitti.j) {
            output.push({
              avain: k.kirjattavaKuitti.avain,
              raahattava: null,
              kuitti: k,
              pvm: k.pvm,
              selite: k.selite,
              summa: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(k.kirjattavaKuitti.a ?? k.kirjattavaKuitti.kuitti[k.kirjattavaKuitti.kuitti.length - 1].summa, 'fi')
            })
          }
        }
        return output
      })
    )

    this.jatkuvienTositteidenMaaraObservable = this.jatkuvatTositteetObservable.pipe(
      map(jatkuvat => {
        if (!jatkuvat?.length) {
          return null
        }
        return {
          total: jatkuvat.length,
          korostettuja: jatkuvat.filter(j => j.kuitti?.kirjattavaKuitti?.korostettu || j.raahattava?.raahattava?.ko).length
        }
      })
    )


    this.maksutavatObservable = combineLatest([this._rawMaksutavat, kirjaamattomatNaytettavatKuititObservable, rajoitaKuukauteenObservable]).pipe(
      map(([maksutavat, kirjaamattomat, rajoitaKuukauteen]) => {

        // ,
        // if (rajoitaKuukauteen) {
        //   console.log('Rajoita kuukauteen', rajoitaKuukauteen)
        //   return kuitit.filter(a => {
        //     const k = a.kuitti[a.kuitti.length - 1]
        //     console.log(k)
        //     return !liitetytKuittiavaimet.has(a.avain) &&
        //   })
        // }

        if (maksutavat && kirjaamattomat) {

          const ma: Map<string, MaksutapaJaKuitit> = new Map()

          // Initiate for all maksutavat
          for (const maksutapa of maksutavat) {
            const tunniste = maksutapa.tunniste + ''
            const mk: MaksutapaJaKuitit = {
              kuitit: [],
              maara: 0,
              korostettuja: 0,
              maksutapa: maksutapa,
              naytaLukumaara: maksutapa.tunniste === MAKSUTAPA_MYYNTITOSITE.tunniste,
              maksutavanTunniste: maksutapa.tunniste + ''
            }
            ma.set(tunniste, mk)
          }

          for (const kirjaamaton of kirjaamattomat) {
            const isFakeEinvoice = kirjaamaton.kuitti.maksutapa + '' === AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT_FAKE.tunniste + ''
            const usedPaymentMethodId = isFakeEinvoice ? AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT.tunniste + '' : kirjaamaton.kuitti.maksutapa + ''
            const maksutapaJaKuitit = ma.get(usedPaymentMethodId)
            if (maksutapaJaKuitit && kirjaamaton.kuitti) {
              maksutapaJaKuitit.maara++
              if (kirjaamaton.kirjattavaKuitti.korostettu) {
                maksutapaJaKuitit.korostettuja++
              }
              if (rajoitaKuukauteen) {
                if (kirjaamaton.kuitti.localPvm && this._dateService.compareLocalMonths(kirjaamaton.kuitti.localPvm, '<=', rajoitaKuukauteen)) {
                  maksutapaJaKuitit.kuitit.push(kirjaamaton)
                }
              } else {
                maksutapaJaKuitit.kuitit.push(kirjaamaton)
              }
            }
          }

          const unfiltered = Array.from(ma.values())
          const kaikki = unfiltered.filter(a => a.maara).sort((a, b) => a.maksutapa.nimi.localeCompare(b.maksutapa.nimi))
          for (const mk of kaikki) {

            if (mk.maksutapa.tunniste === TILIOTETOSITE_MAKSUTAPA_ID) {
              mk.kuitit.sort((a, b) => {
                const apvm = this._dateService.timestampToLocalDate(a.kuitti.pvm)
                const bpvm = this._dateService.timestampToLocalDate(b.kuitti.pvm)
                if (this._dateService.compareLocalDates(apvm, '==', bpvm)) {
                  return 0
                }
                if (this._dateService.compareLocalDates(apvm, '>', bpvm)) {
                  return -1
                }
                return 1
              })
            } else {
              mk.kuitit.sort((a, b) => a.kuitti.summa - b.kuitti.summa)
            }

            // mk.kuitit.sort((a, b) => {
            //   // let monthA = this._dateService.localMonthToNumber(a.kuitti.localPvm)
            //   // let monthB = this._dateService.localMonthToNumber(b.kuitti.localPvm)
            //   // let kuukausiaValissa = monthA - monthB
            //   // if (kuukausiaValissa === 0) {
            //   return a.kuitti.summa - b.kuitti.summa
            //   // }
            //   // return kuukausiaValissa
            // })

            // let currentKuukausi: number = null
            // for (const kirjaamatonKuitti of mk.kuitit) {
            //   if (currentKuukausi === null) {
            //     currentKuukausi = kirjaamatonKuitti.kuitti.localPvm.month
            //   } else if (currentKuukausi !== kirjaamatonKuitti.kuitti.localPvm.month) {
            //     currentKuukausi = kirjaamatonKuitti.kuitti.localPvm.month
            //     kirjaamatonKuitti.kuunVaihde = true
            //   }
            // }

          }
          return kaikki

        }
        return []
      }),
      tap(maksutavatJaKuitit => {
        this.maksutavatJoissaOnKirjaamattomiaTositteita.next(maksutavatJaKuitit.map(a => a.maksutapa))
      }),
      lemonShare()
    )

    this.valitunMaksutavanKuititObservable = combineLatest([this.maksutavatObservable, this.valittuMaksutapaObservable]).pipe(
      map(([maksutavatJaKuitit, valittuMaksutapa]) => {
        if (maksutavatJaKuitit && valittuMaksutapa) {
          for (const maksutapaJaKuitit of maksutavatJaKuitit) {
            if (maksutapaJaKuitit.maksutavanTunniste === valittuMaksutapa) {
              return maksutapaJaKuitit.kuitit
            }
          }
        }
        return null
      })
    )

    this.valittuKirjanpidonMaksutapaObservable.pipe(
      withLatestFrom(this.maksutavatObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([kirjanpidossaValittuMaksutapa, kuititMaksutavoittain]) => {
      if (kirjanpidossaValittuMaksutapa && kuititMaksutavoittain) {
        for (const maksutapaJaKuitit of kuititMaksutavoittain) {
          if (kirjanpidossaValittuMaksutapa.tunniste + '' === maksutapaJaKuitit.maksutavanTunniste) {
            this.valittuMaksutapa = maksutapaJaKuitit.maksutavanTunniste
            this._valittuMaksutapaSubject.next(maksutapaJaKuitit.maksutavanTunniste)
            break
          }
        }
      }
    })

    const onkoKirjaamattomiaKuittejaObservable = this.maksutavatObservable.pipe(
      map(maksutavat => {
        return maksutavat.length > 0
      })
    )

    this.onkoListaTyhjaObservable = combineLatest([this.kirjaamattomienYhteisomyyntiLaskujenMaaraObservable, this.kirjaamattomienLaskujenMaaraObservable, onkoKirjaamattomiaKuittejaObservable, this.kirjaamattomienRaahattavienMaaraObservable, this.jatkuvienTositteidenMaaraObservable]).pipe(
      map(([onkoKirjaamattomiaYhteisomyyntilaskuja, onkoKirjaamattomiaLaskuja, onkoKirjaamattomiaKuitteja, onkoKirjaamattomiaRaahattavia, jatkuviaTositteita]) => {
        return !onkoKirjaamattomiaYhteisomyyntilaskuja && !onkoKirjaamattomiaLaskuja && !onkoKirjaamattomiaKuitteja && !onkoKirjaamattomiaRaahattavia && !jatkuviaTositteita
      }),
      startWith(true)
    )

    this.ladataanObservable = combineLatest([this.kirjaamattomienYhteisomyyntiLaskujenMaaraObservable, this.kirjaamattomienLaskujenMaaraObservable, onkoKirjaamattomiaKuittejaObservable, this.kirjaamattomienRaahattavienMaaraObservable]).pipe(
      map(([onkoKirjaamattomiaYhteisomyyntilaskuja, onkoKirjaamattomiaLaskuja, onkoKirjaamattomiaKuitteja, onkoKirjaamattomiaRaahattavia]) => {
        return false
      }),
      startWith(true)
    )

  }

  private _annaSumma(kuitti: FirestoreTosite): string {
    if (kuitti.maksutapa !== TILIOTETOSITE_MAKSUTAPA_ID && kuitti.maksutapa !== PALKKATOSITE_MAKSUTAPA_ID) {
      return this._currencyService.formatoiRahaIlmanValuuttaSymbolia(0 - (kuitti.es ?? kuitti.summa), 'fi').replace(' ', '\u00a0')
    }
    return ''
  }

  valitseMaksutapa(maksutapa: string) {
    if (this._valittuMaksutapaSubject.value !== maksutapa) {
      this.valittuMaksutapa = maksutapa
      this._valittuMaksutapaSubject.next(maksutapa)
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  async siirraKohdistusSeuraavaanValittuun(event: KeyboardEvent) {
    const asHtmlElement = event.target as HTMLElement
    if (asHtmlElement?.tagName !== 'BODY') {
      return
    }
    if (this.valittuTosite) {
      this._stopEvent(event)
      const maksutavat = await firstValueFrom(this.maksutavatObservable)
      for (const maksutapa of maksutavat) {
        const index = maksutapa.kuitit.findIndex(k => k.kuitti.avain === this.valittuTosite)
        if (index > -1) {
          if (index + 1 < maksutapa.kuitit.length) {
            const seuraava = maksutapa.kuitit[index + 1]
            this._valitseTositeInternal(seuraava)
            setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
          }
          break
        }
      }
    } else if (this.valittuLasku) {
      this._stopEvent(event)
      const laskut = await firstValueFrom(this.kirjaamattomatLaskutObservable)
      const index = laskut.findIndex(k => k.lasku.avain === this.valittuLasku)
      if (index > -1 && index + 1 < laskut.length) {
        const seuraava = laskut[index + 1]
        this.valitseLaskuInternal(seuraava)
        setTimeout(() => {
          setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
        }, 25)
      }
    } else if (this.valittuRaahattava) {
      this._stopEvent(event)
      const raahattavat = await firstValueFrom(this.kirjaamattomatRaahattavatObservable)
      const index = raahattavat.findIndex(k => k.avain === this.valittuRaahattava)
      if (index > -1 && index + 1 < raahattavat.length) {
        const seuraava = raahattavat[index + 1]
        this.valitseRaahattavaInternal(seuraava)
        setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
      }
    } else if (this.valittuJatkuva) {
      this._stopEvent(event)
      const jatkuvat = await firstValueFrom(this.jatkuvatTositteetObservable)
      const index = jatkuvat.findIndex(k => k.avain === this.valittuJatkuva)
      if (index > -1 && index + 1 < jatkuvat.length) {
        const seuraava = jatkuvat[index + 1]
        this._valitseJatkuvaInternal(seuraava)
        setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
      }
    }
  }

  private _iteratorIsEmpty(iterable: IterableIterator<any>): boolean {
    for (const _ of iterable) { // eslint-disable-line no-unused-vars
      return false
    }
    return true
  }

  // @HostListener('document:keydown.space', ['$event'])
  // async hoidaEsikatselu(event: KeyboardEvent) {

  //   const asHtmlElement = event.target as HTMLElement
  //   if (asHtmlElement?.tagName !== 'BODY') {
  //     return
  //   }

  //   this._stopEvent(event)
  //   if (this._esikatseluAuki) {
  //     this._kirjanpitoImageHandlerService.lopetaEsikatselu()
  //     this._esikatseluAuki = false
  //   } else {
  //     this._esikatseluAuki = true
  //     if (
  //       (this.valittuLasku || this.valittuRaahattava || this.valittuTosite) &&
  //       this._valitutSivut
  //     ) {
  //       this._kirjanpitoImageHandlerService.aloitaEsikatselu(this._valitutSivut)
  //     }
  //   }

  // }

  @HostListener('document:keydown.arrowup', ['$event'])
  async siirraKohdistusEdelliseenValittuun(event: KeyboardEvent) {
    const asHtmlElement = event.target as HTMLElement
    if (asHtmlElement?.tagName !== 'BODY') {
      return
    }
    if (this.valittuTosite) {
      this._stopEvent(event)
      const maksutavat = await firstValueFrom(this.maksutavatObservable)
      for (const maksutapa of maksutavat) {
        const index = maksutapa.kuitit.findIndex(k => k.kuitti.avain === this.valittuTosite)
        if (index > -1) {
          if (index - 1 > -1) {
            const edellinen = maksutapa.kuitit[index - 1]
            this._valitseTositeInternal(edellinen)
            setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
          }
          break
        }
      }
    } else if (this.valittuLasku) {
      this._stopEvent(event)
      const laskut = await firstValueFrom(this.kirjaamattomatLaskutObservable)
      const index = laskut.findIndex(k => k.lasku.avain === this.valittuLasku)
      if (index > -1 && index - 1 > -1) {
        const edellinen = laskut[index - 1]
        this.valitseLaskuInternal(edellinen)
        setTimeout(() => {
          setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
        }, 25)
      }
    } else if (this.valittuRaahattava) {
      this._stopEvent(event)
      const raahattavat = await firstValueFrom(this.kirjaamattomatRaahattavatObservable)
      const index = raahattavat.findIndex(k => k.avain === this.valittuRaahattava)
      if (index > -1 && index - 1 > -1) {
        const edellinen = raahattavat[index - 1]
        this.valitseRaahattavaInternal(edellinen)
        setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
      }
    } else if (this.valittuJatkuva) {
      this._stopEvent(event)
      const jatkuvat = await firstValueFrom(this.jatkuvatTositteetObservable)
      const index = jatkuvat.findIndex(k => k.avain === this.valittuJatkuva)
      if (index > -1 && index - 1 > -1) {
        const edellinen = jatkuvat[index - 1]
        this._valitseJatkuvaInternal(edellinen)
        setTimeout(() => { this._scrollIntoView('.kirjaamaton-kortti.kortti-valittu') }, 25)
      }
    }
  }

  valitseTosite(event: MouseEvent, naytettavaKuitti: NaytettavaKuitti) {
    // this._changeDetector.markForCheck()
    // event.stopPropagation()
    // this._esikatseluAuki = true
    this._valitseTositeInternal(naytettavaKuitti)
  }

  private _valitseTositeInternal(naytettavaKuitti: NaytettavaKuitti) {

    this.valittuTosite = naytettavaKuitti.kuitti.avain
    this.valittuRaahattava = null
    this.valittuLasku = null
    this.valittuJatkuva = null

    this._valitutSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaKuittiLiitetyiksiSivuiksi(naytettavaKuitti.kirjattavaKuitti)

    // if (this._esikatseluAuki) {
    this._kirjanpitoImageHandlerService.aloitaEsikatselu({ tunniste: naytettavaKuitti.kirjattavaKuitti.avain, sivut: this._valitutSivut, naytettavaKuitti: naytettavaKuitti })
    // }

  }
  valitseJatkuva(event: MouseEvent, jatkuva: NaytettavaJatkuva) {
    this._valitseJatkuvaInternal(jatkuva)
  }

  private _valitseJatkuvaInternal(jatkuva: NaytettavaJatkuva) {

    this.valittuJatkuva = jatkuva.avain
    this.valittuTosite = null
    this.valittuRaahattava = null
    this.valittuLasku = null

    if (jatkuva.raahattava) {
      this._valitutSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaRaahattavaLiitetyiksiSivuiksi(jatkuva.raahattava)
      this._kirjanpitoImageHandlerService.aloitaEsikatselu({ tunniste: jatkuva.avain, sivut: this._valitutSivut, naytettavaRaahattava: jatkuva.raahattava })
    }
    if (jatkuva.kuitti) {
      this._valitutSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaKuittiLiitetyiksiSivuiksi(jatkuva.kuitti.kirjattavaKuitti)
      this._kirjanpitoImageHandlerService.aloitaEsikatselu({ tunniste: jatkuva.avain, sivut: this._valitutSivut, naytettavaKuitti: jatkuva.kuitti })
    }
  }

  private _scrollIntoView(query: string) {
    const el = document.querySelector(query)
    if (!el) {
      return
    }
    if (el['scrollIntoViewIfNeeded']) {
      (el as any).scrollIntoViewIfNeeded()
    } else {
      el.scrollIntoView(false)
    }
  }

  valitseLasku(event: MouseEvent, naytettavaLasku: NaytettavaLasku) {
    // this._changeDetector.markForCheck()
    // event.stopPropagation()
    // this._esikatseluAuki = true
    this.valitseLaskuInternal(naytettavaLasku)
  }

  valitseLaskuInternal(naytettavaLasku: NaytettavaLasku) {

    this.valittuTosite = null
    this.valittuRaahattava = null
    this.valittuLasku = naytettavaLasku.lasku.avain
    this.valittuJatkuva = null

    this._valitutSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaLaskuLiitetyiksiSivuiksi(naytettavaLasku.kirjattavaLasku)

    // if (this._esikatseluAuki) {7
    this._kirjanpitoImageHandlerService.aloitaEsikatselu({ tunniste: naytettavaLasku.kirjattavaLasku.avain, sivut: this._valitutSivut, naytettavaLasku: naytettavaLasku })
    // }

  }

  valitseRaahattava(event: MouseEvent, raahattava: NaytettavaRaahattava) {
    // this._changeDetector.markForCheck()
    // event.stopPropagation()
    // this._esikatseluAuki = true
    this.valitseRaahattavaInternal(raahattava)
  }

  valitseRaahattavaInternal(raahattava: NaytettavaRaahattava) {

    this.valittuTosite = null
    this.valittuRaahattava = raahattava.avain
    this.valittuLasku = null
    this.valittuJatkuva = null

    this._valitutSivut = this._kirjanpitoImageHandlerService.muunnaKirjattavaRaahattavaLiitetyiksiSivuiksi(raahattava)

    // if (this._esikatseluAuki) {
    this._kirjanpitoImageHandlerService.aloitaEsikatselu({ tunniste: raahattava.avain, sivut: this._valitutSivut, naytettavaRaahattava: raahattava })
    // }

  }

  /* BELOW THE DROP HERE TO DETACH HANDLING! */
  onDragEnter(event: DragEvent) {
    const element = event.target as HTMLElement
    element.classList.add('drag-remove-selected')
  }

  onDragLeave(event: DragEvent) {
    const element = event.target as HTMLElement
    element.classList.remove('drag-remove-selected')
  }

  onDrop(event: DragEvent) {

    //   const element = event.target as HTMLElement
    //   element.classList.remove('drag-remove-selected')
    //   document.body.classList.remove('dragging-remove')

    //   const sivuJaAvainAsString = event.dataTransfer.getData('poistasivu')
    //   if (sivuJaAvainAsString) {
    //     const sivuJaAvain = this._timestampProvider.fixTimestamps(JSON.parse(sivuJaAvainAsString)) as SivuJaAvain
    //     if (sivuJaAvain) {
    //       this.hoidaLiitoksenPoisto(sivuJaAvain)
    //     }
    //   }

  }

  // async hoidaLiitoksenPoisto(event: SivuJaAvain) {

  //   const naytettavaKirjaus: NaytettavaKirjaus = await this.naytettavaKirjausObservable)
  //   const kirjaus: Kirjaus = naytettavaKirjaus?.kirjaus
  //   const asiakas: AsiakkaanAvainTiedot = await this._asiakasService.nykyinenAsiakasAvainObservable)

  //   if (!asiakas || !kirjaus) {
  //     return
  //   }

  //   this._kirjanpitoImageHandlerService.hoidaLiitoksenPoisto(asiakas, event, naytettavaKirjaus).catch(err => {
  //     this._errorHandler.handleError(err)
  //   })

  // }

  /* ALLA RAAHAA LIITTÄÄKSESI TOIMINNALLISUUS */
  aloitaKuitinRaahaaminen(event: DragEvent, kirjaamatonKuitti: NaytettavaKuitti) {
    const kuittiAsString = JSON.stringify(kirjaamatonKuitti.kirjattavaKuitti)
    event.dataTransfer.setData('kuitti', kuittiAsString)
    document.body.classList.add('dragging')
  }

  aloitaLaskunRaahaaminen(event: DragEvent, kirjaamatonLasku: NaytettavaLasku) {
    const laskuAsString = JSON.stringify(kirjaamatonLasku.kirjattavaLasku)
    event.dataTransfer.setData('lasku', laskuAsString)
    document.body.classList.add('dragging')
  }

  aloitaRaahattavanRaahaaminen(event: DragEvent, kirjaamatonRaahattava: NaytettavaRaahattava) {
    const raahattavaAsString = JSON.stringify(kirjaamatonRaahattava)
    event.dataTransfer.setData('raahattava', raahattavaAsString)
    document.body.classList.add('dragging')
  }

  aloitaJatkuvanRaahaaminen(event: DragEvent, jatkuva: NaytettavaJatkuva) {
    if (jatkuva.kuitti) {
      this.aloitaKuitinRaahaaminen(event, jatkuva.kuitti)
    } else if (jatkuva.raahattava) {
      this.aloitaRaahattavanRaahaaminen(event, jatkuva.raahattava)
    }
  }

  lopetaRaahaaminen(event: DragEvent) {
    document.body.classList.remove('dragging')
  }

  lisaaKuittiJatkuviin(naytettavaKuitti: NaytettavaKuitti) {
    this._lisaaJatkuviinInternal({ kuittiAvain: naytettavaKuitti.kirjattavaKuitti.avain }, naytettavaKuitti)
  }

  lisaaRaahattavaJatkuviin(naytettavaRaahattava: NaytettavaRaahattava) {
    this._lisaaJatkuviinInternal({ raahatunAvain: naytettavaRaahattava.avain }, naytettavaRaahattava)
  }

  private async _lisaaJatkuviinInternal(avainData: Partial<JatkuvaTositeMuutosPyynto>, naytettavaObjekti: NaytettavaKuitti | NaytettavaLasku | NaytettavaRaahattava) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    try {
      const dialogData: AreYouSureDialogData = {
        rightAction: 'Muuta toistuvaksi',
        text: 'Haluatko varmasti muuttaa tositteen toistuvaksi?'
      }
      await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(kylla => {
        if (kylla) {
          naytettavaObjekti.tallennetaan = true
          this._changeDetector.markForCheck()
          const requestData: JatkuvaTositeMuutosPyynto = Object.assign(
            { asiakasAvain: asiakas.avain },
            avainData
          )
          return this._firebase.functionsCall<JatkuvaTositeMuutosPyynto, void>('kirjanpitoMuutaJatkuvaksiTositteeksi', requestData).then(() => {
            naytettavaObjekti.tallennetaan = false
            this.valittuJatkuva = avainData.kuittiAvain || avainData.raahatunAvain
            this.valitseMaksutapa('jatkuvat')
          })
        }
      })
    } catch (err) {
      naytettavaObjekti.tallennetaan = false
      this._errorHandler.handleError(err)
      this._changeDetector.markForCheck()
    }
  }

  paivitaKuitinKorostus(naytettavaKuitti: NaytettavaKuitti, korosta: boolean) {
    return this._korostaTosite({ kuittiAvain: naytettavaKuitti.kirjattavaKuitti.avain, korosta: korosta })
  }

  paivitaLaskunKorostus(naytettavaLasku: NaytettavaLasku, korosta: boolean) {
    return this._korostaTosite({ laskunAvain: naytettavaLasku.kirjattavaLasku.avain, korosta: korosta })
  }

  paivitaRaahattavanKorostus(naytettavaRaahattava: NaytettavaRaahattava, korosta: boolean) {
    return this._korostaTosite({ raahatunAvain: naytettavaRaahattava.avain, korosta: korosta })
  }
  paivitaJatkuvanKorostus(naytettavaJatkuva: NaytettavaJatkuva, korosta: boolean) {
    if (naytettavaJatkuva.kuitti) {
      this.paivitaKuitinKorostus(naytettavaJatkuva.kuitti, korosta)
    } else if (naytettavaJatkuva.raahattava) {
      this.paivitaRaahattavanKorostus(naytettavaJatkuva.raahattava, korosta)
    } else {
      this._errorHandler.handleError(new Error('Jatkuvan tositteen korostaminen epäonnistui! Ei kuittia eikä raahattavaa.'))
    }
  }

  private async _korostaTosite(avainData: Partial<PaivitaTositteenKorostusPyynto>) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }
    const requestData: PaivitaTositteenKorostusPyynto = Object.assign(
      { asiakasAvain: asiakas.avain, korosta: true },
      avainData
    )
    return this._firebase.functionsCall<PaivitaTositteenKorostusPyynto, void>('kirjanpitoPaivitaTositteenKorostus', requestData)
  }

  async liitaKuitti(naytettavaKuitti: NaytettavaKuitti) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const naytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)

    if (!asiakas || !naytettavaKirjaus?.kirjaus) {
      return
    }

    this._kirjanpitoTositeService.liitaKuittiKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, naytettavaKuitti.kirjattavaKuitti).catch(err => {
      this._errorHandler.handleError(err)
      this._snackbar.open('Tositteen liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
    })

  }

  async liitaLasku(naytettavaLasku: NaytettavaLasku) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const naytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)

    if (!asiakas || !naytettavaKirjaus?.kirjaus) {
      return
    }

    const laskuasetukset = await firstValueFrom(this._asiakasService.nykyisenAsiakkaanLaskuasetuksetObservable)
    this._kirjanpitoTositeService.liitaLaskuKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, naytettavaLasku.kirjattavaLasku, laskuasetukset).catch(err => {
      this._errorHandler.handleError(err)
      this._snackbar.open('Laskun liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
    })

  }

  async liitaRaahattava(naytettavaRaahattava: NaytettavaRaahattava) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const naytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)

    if (!asiakas || !naytettavaKirjaus?.kirjaus) {
      return
    }

    this._kirjanpitoTositeService.liitaRahaattuKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, naytettavaRaahattava).catch(err => {
      this._errorHandler.handleError(err)
      this._snackbar.open('Kirjanpitäjän lisäämän tiedoston liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
    })
  }

  async liitaJatkuva(naytettavaJatkuva: NaytettavaJatkuva) {
    if (naytettavaJatkuva.kuitti) {
      return this.liitaKuitti(naytettavaJatkuva.kuitti)
    }
    if (naytettavaJatkuva.raahattava) {
      return this.liitaRaahattava(naytettavaJatkuva.raahattava)
    }
  }

  async muokkaaYliajosummaa(naytettavaJatkuva: NaytettavaJatkuva) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    if (!asiakas) {
      return
    }

    const naytettavaObjekti = naytettavaJatkuva.kuitti || naytettavaJatkuva.raahattava

    try {
      const dialogData: TositeMuokkaaYliajosummaaDialogData = {
        naytettavaJatkuva: naytettavaJatkuva
      }
      await firstValueFrom(this._dialog.open(TositeMuokkaaYliajosummaaDialog, { data: dialogData }).afterClosed()).then(paivitetty => {

        if (paivitetty) {
          naytettavaObjekti.tallennetaan = true
          this._changeDetector.markForCheck()
          const requestData: JatkuvaTositeMuutosPyynto = {
            asiakasAvain: asiakas.avain
          }
          if (naytettavaJatkuva.kuitti) {
            requestData.kuittiAvain = naytettavaJatkuva.kuitti?.kirjattavaKuitti?.avain
            requestData.yliajosumma = naytettavaJatkuva.kuitti?.kirjattavaKuitti?.a
          }
          if (naytettavaJatkuva.raahattava) {
            requestData.raahatunAvain = naytettavaJatkuva.raahattava?.avain
            requestData.yliajosumma = naytettavaJatkuva.raahattava?.raahattava?.a
          }

          return this._firebase.functionsCall<JatkuvaTositeMuutosPyynto, void>('kirjanpitoPaivitaJatkuvanTositteenKirjattava', requestData).then(() => {
            naytettavaObjekti.tallennetaan = false
          })
        }
      })
    } catch (err) {
      naytettavaObjekti.tallennetaan = false
      this._errorHandler.handleError(err)
      this._changeDetector.markForCheck()
    }
  }

  async poistaKuitti(naytettavaKuitti: NaytettavaKuitti) {

    // console.log('POISTA KUITTI', naytettavaKuitti)

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    try {
      const dialogData: AreYouSureDialogData = {
        rightAction: 'Poista',
        text: 'Haluatko varmasti poistaa ' + naytettavaKuitti.pvm + ' päivätyn kuitin, jonka summa on ' + naytettavaKuitti.summa + '? Toimintoa ei voi perua.'
      }
      const areYouSureDialog = this._dialog.open(AreYouSureDialog, { data: dialogData })
      await firstValueFrom(areYouSureDialog.afterClosed()).then(poista => {
        if (poista) {
          naytettavaKuitti.poistetaan = true
          this._changeDetector.markForCheck()
          const requestData: KirjattavanKuitinPoistoPyynto = {
            asiakasAvain: asiakas.avain,
            kuittiAvain: naytettavaKuitti.kirjattavaKuitti.avain
          }
          return this._firebase.functionsCall<KirjattavanKuitinPoistoPyynto, void>('kirjanpitoKirjattavaKuittiPoista', requestData).then(() => {
            this._kirjanpitoImageHandlerService.lopetaEsikatselu()
          })
        }
      })
    } catch (err) {
      this._errorHandler.handleError(err)
      naytettavaKuitti.poistetaan = false
      this._changeDetector.markForCheck()
    }

  }

  async poistaLasku(naytettavaLasku: NaytettavaLasku) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    try {
      const dialogData: AreYouSureDialogData = {
        rightAction: 'Poista',
        text: 'Haluatko varmasti poistaa ' + naytettavaLasku.pvm + ' päivätyn laskun, jonka summa on ' + naytettavaLasku.summa + '? Toimintoa ei voi perua.'
      }
      await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(poista => {
        if (poista) {
          naytettavaLasku.poistetaan = true
          this._changeDetector.markForCheck()
          const requestData: KirjattavanLaskunPoistoPyynto = {
            asiakasAvain: asiakas.avain,
            laskuAvain: naytettavaLasku.kirjattavaLasku.avain
          }
          return this._firebase.functionsCall<KirjattavanLaskunPoistoPyynto, void>('kirjanpitoKirjattavaLaskuPoista', requestData).then(() => {
            this._kirjanpitoImageHandlerService.lopetaEsikatselu()
          })
        }
      })
    } catch (err) {
      this._errorHandler.handleError(err)
      naytettavaLasku.poistetaan = false
      this._changeDetector.markForCheck()
    }

  }

  async poistaRaahattava(raahattava: NaytettavaRaahattava) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    try {
      const dialogData: AreYouSureDialogData = {
        rightAction: 'Poista',
        text: 'Haluatko varmasti poistaa tiedoston ' + raahattava.raahattava.n + '? Toimintoa ei voi perua.'
      }
      await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(poista => {
        if (poista) {
          raahattava.poistetaan = true
          this._changeDetector.markForCheck()
          const requestData: KirjattavanRaahatunTiedostonPoistoPyynto = {
            asiakasAvain: asiakas.avain,
            raahatunAvain: raahattava.avain
          }
          return this._firebase.functionsCall<KirjattavanRaahatunTiedostonPoistoPyynto, void>('kirjanpitoKirjattavaRaahattuPoista', requestData).then(() => {
            this._kirjanpitoImageHandlerService.lopetaEsikatselu()
          })
        }
      })
    } catch (err) {
      this._errorHandler.handleError(err)
      raahattava.poistetaan = false
      this._changeDetector.markForCheck()
    }
  }

  async poistaJatkuva(jatkuva: NaytettavaJatkuva) {
    if (jatkuva.kuitti) {
      return this.poistaKuitti(jatkuva.kuitti)
    }
    if (jatkuva.raahattava) {
      return this.poistaRaahattava(jatkuva.raahattava)
    }
  }

  async poistaJatkuvatFlag(jatkuva: NaytettavaJatkuva) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    const muokattavaObjekti = jatkuva.kuitti || jatkuva.raahattava
    try {
      const dialogData: AreYouSureDialogData = {
        rightAction: 'Poista jatkuvista tositteista',
        text: 'Haluatko varmasti muuttaa tämän jatkuvan tositteen tavalliseksi tositteeksi?'
      }

      await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(kylla => {
        if (kylla) {
          muokattavaObjekti.poistetaan = true
          this._changeDetector.markForCheck()
          const requestData: JatkuvaTositeMuutosPyynto = {
            asiakasAvain: asiakas.avain,
            kuittiAvain: jatkuva.kuitti?.kirjattavaKuitti?.avain || null,
            raahatunAvain: jatkuva.raahattava?.avain || null,
          }
          return this._firebase.functionsCall<JatkuvaTositeMuutosPyynto, void>('kirjanpitoPoistaJatkuvaTositeFlag', requestData).then(async () => {
            const maksutapa = await this._loydaNaytettavanMaksutapa(jatkuva.kuitti, jatkuva.raahattava)
            if (maksutapa) {
              this.valitseMaksutapa(maksutapa)
            }
            muokattavaObjekti.poistetaan = false
            if (requestData.kuittiAvain) { this.valitseTosite(null, muokattavaObjekti as NaytettavaKuitti) }
            if (requestData.raahatunAvain) { this.valitseRaahattava(null, muokattavaObjekti as NaytettavaRaahattava) }
            this._changeDetector.markForCheck()
          })
        }
      })
    } catch (err) {
      muokattavaObjekti.poistetaan = false
      this._changeDetector.markForCheck()
      this._errorHandler.handleError(err)
    }
  }
  private async _loydaNaytettavanMaksutapa(kuitti: NaytettavaKuitti, raahattava: NaytettavaRaahattava): Promise<string> {
    if (kuitti) {
      if (kuitti.kuitti.maksutapa + '' === AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT_FAKE.tunniste + '') {
        return AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT.tunniste + ''
      }
      const maksutavat = await firstValueFrom(this._rawMaksutavat)
      const kuitinMaksutapa = maksutavat.find(mt => mt.tunniste === kuitti.kuitti.maksutapa)?.tunniste
      if (kuitinMaksutapa) {
        return '' + kuitinMaksutapa
      }
    }
    if (raahattava) {
      return 'raahattavat'
    }
    return null
  }
  private _onkoYhteisomyyntilasku(naytettavaLasku: NaytettavaLasku) {
    return naytettavaLasku.lasku.tyyppi === LaskunTyypit.EU_PALVELU.avain || naytettavaLasku.lasku.tyyppi === LaskunTyypit.EU_TAVARA.avain
  }

  private _stopEvent(event: MouseEvent | KeyboardEvent) {
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()
  }

  // public laskuLiitettiin(laskuAvain: string) {
  //   const current = this.liitetytLaskutSubject.value
  //   current.add(laskuAvain)
  //   this.liitetytLaskutSubject.next(current)
  // }

  // public kuittiLiitettiin(kuittiAvain: string) {
  //   const current = this.liitetytKuititSubject.value
  //   current.add(kuittiAvain)
  //   this.liitetytKuititSubject.next(current)
  // }

  // public laskunLiitosEpaonnistui(laskuAvain: string) {
  //   const current = this.liitetytLaskutSubject.value
  //   current.delete(laskuAvain)
  //   this.liitetytLaskutSubject.next(current)
  // }

  // public kuitinLiitosEpaonnistui(kuittiAvain: string) {
  //   const current = this.liitetytKuititSubject.value
  //   current.delete(kuittiAvain)
  //   this.liitetytKuititSubject.next(current)
  // }

  async avaaKuittiUudessaIkkunassa(kuitti: NaytettavaKuitti) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    // asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/:tositeAvain
    const tositeTyyppi: TositeTyyppi = 'kuitti'
    const url = '/asiakkaat/' + asiakas.avain + '/kirjanpidon-tosite/' + tositeTyyppi + '/' + kuitti.kirjattavaKuitti.avain
    const ikkunanAvain = 'tosite_' + asiakas.avain + '_' + kuitti.kirjattavaKuitti.avain
    this._avaaUudessaIkkunassa(ikkunanAvain, url)
  }

  async avaaLaskuUudessaIkkunassa(lasku: NaytettavaLasku) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    // asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/:tositeAvain
    const tositeTyyppi: TositeTyyppi = 'lasku'
    const url = '/asiakkaat/' + asiakas.avain + '/kirjanpidon-tosite/' + tositeTyyppi + '/' + lasku.kirjattavaLasku.avain
    const ikkunanAvain = 'tosite_' + asiakas.avain + '_' + lasku.kirjattavaLasku.avain
    this._avaaUudessaIkkunassa(ikkunanAvain, url)
  }

  async avaaRaahattavaUudessaIkkunassa(raahattava: NaytettavaRaahattava) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }

    // asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/:tositeAvain
    const tositeTyyppi: TositeTyyppi = 'raahattu'
    const url = '/asiakkaat/' + asiakas.avain + '/kirjanpidon-tosite/' + tositeTyyppi + '/' + raahattava.avain
    const ikkunanAvain = 'tosite_' + asiakas.avain + '_' + raahattava.avain
    this._avaaUudessaIkkunassa(ikkunanAvain, url)
  }

  private _avaaUudessaIkkunassa(avain: string, url: string) {
    const start = window.location.protocol + '//' + window.location.host
    const height = window.screen.height - 100 < 1920 ? window.screen.height - 100 : 1920
    const width = window.screen.width - 50 < 1080 ? window.screen.width - 50 : 1080
    window.open(start + url, avain, 'height=' + height + ',width=' + width + ',modal=yes,alwaysRaised=yes')
  }

  avaaJatkuvaUudessaIkkunassa(naytettavaJatkuva: NaytettavaJatkuva) {
    if (naytettavaJatkuva.kuitti) {
      this.avaaKuittiUudessaIkkunassa(naytettavaJatkuva.kuitti)
    } else if (naytettavaJatkuva.raahattava) {
      this.avaaRaahattavaUudessaIkkunassa(naytettavaJatkuva.raahattava)
    }
  }

}
