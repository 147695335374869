<div style="max-width: 800px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      Päätä sopimuskausi
    </lemon-dialog-header>

    <!-- <p *ngIf="kausi.sopimusAllekirjoitettu else notSignedYet">Sopimuskausi on alkanut {{ kausi.alkaa | lokaalipvm | async }} ja sopimus allekirjoitettiin {{ kausi.sopimusAllekirjoitettu | lokaalipvm | async }}.</p>
    <ng-template #notSignedYet>
      <p>Sopimuskausi on alkanut {{ kausi.alkaa | lokaalipvm | async }}, mutta sopimusta ei ole vielä allekirjoitettu.</p>
    </ng-template>

    <p>Asiakas voi maksaa palkkoja ja lähettää laskuja vielä päättymispäivän ajan.</p> -->

    <!-- <p *ngIf="!kausi.loppuu">Milloin yhteistyö asiakkaan kanssa päättyy?</p> -->

    <div [formGroup]="sopimuskausiFormGroup">

      <div style="display: flex;">
        <mat-form-field style="width: 220px; margin-left: 10px; margin-right: 10px;">
          <mat-label>Sopimus päättyy</mat-label>
          <input [name]="inputname" type="text" [matDatepickerFilter]="vainKuunViimeinenFilter" [max]="maksimiLoppuuPaivamaara" [min]="minimiLoppuuPaivamaara" [matDatepicker]="pickerLoppuu" formControlName="loppuu" matInput data-lpignore="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerLoppuu"></mat-datepicker-toggle>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.required && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Päättymispäivä puuttuu
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.edellinen && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Kausi ei voi loppua seuraavan alkamisen jälkeen.
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.min && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Sopimuskauden on alettava alkamisen jälkeen.
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.matDatepickerMin && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Uusi muutos voi alkaa aikaisintaan ensi kuussa.
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.loppuuSuurempiKuinAlkaa && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Päivämäärä on oltava alkamisen jälkeen.
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.kuunViimeinen && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Päivämäärä on oltava kuukauden viimeinen päivä.
          </mat-error>
          <mat-error *ngIf="sopimuskausiFormGroup.get('loppuu')?.errors?.hinnanOnMenneisyydessaOltavaNolla && (sopimuskausiFormGroup.get('loppuu')?.dirty || sopimuskausiFormGroup.get('loppuu')?.touched)">
            Kuukaudesta on lähtenyt lasku, valitse toinen päivä.
          </mat-error>
          <mat-datepicker #pickerLoppuu></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Päättymisen syy</mat-label>
          <mat-select formControlName="syy" [compareWith]="compareSyyt" required>
            <mat-option *ngFor="let syy of kaikkiValittavatSyyt" [value]="syy">
              {{ syy.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="sopimuskausiFormGroup.get('syy')?.errors?.required && (sopimuskausiFormGroup.get('syy')?.dirty || sopimuskausiFormGroup.get('syy')?.touched)">
            {{ 'lasku.validation.maa.required' | translate | async }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="irtisanominenMahdollistaAikaisintaanPaivamaaraObservable | async let pvm" style="margin: 1em; font-weight: bold;">Irtisanomisajan mukaan sopimus voi päättyä aikaisintaan {{ pvm | lokaalipvm | async }}. Huomaathan, että irtisanomisajasta voidaan poiketa vain painavalla syyllä.</div>

      <div style="padding-top: 25px;">
        <mat-form-field style="margin-left: 10px; margin-right: 10px;">
          <mat-label>Lisätiedot</mat-label>
          <input [name]="inputname" type="text" formControlName="lisatiedot" matInput data-lpignore="true" />
          <mat-hint *ngIf="valittuSyy?.lisatiedotVinkki">{{valittuSyy?.lisatiedotVinkki || null}}</mat-hint>
          <mat-error *ngIf="sopimuskausiFormGroup.get('lisatiedot')?.errors?.required && (sopimuskausiFormGroup.get('lisatiedot')?.dirty || sopimuskausiFormGroup.get('lisatiedot')?.touched)">
            Lisätiedot puuttuvat
          </mat-error>
        </mat-form-field>
      </div>
      <div style="padding-top: 50px;" *ngIf="!onkoPurku">
        <mat-form-field style="max-width: 220px; margin-left: 10px; margin-right: 10px;">
          <mat-label>Tositteiden toimitus loppuu</mat-label>
          <input [name]="inputname" type="text" [min]="minimiTositteetLoppuuPaivamaara" [matDatepicker]="pickerTositteetLoppuu" formControlName="tositteetLoppuu" matInput data-lpignore="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerTositteetLoppuu"></mat-datepicker-toggle>
          <mat-error *ngIf="sopimuskausiFormGroup.get('tositteetLoppuu')?.errors?.required && (sopimuskausiFormGroup.get('tositteetLoppuu')?.dirty || sopimuskausiFormGroup.get('tositteetLoppuu')?.touched)">
            Tositteiden loppumispäivä puuttuu
          </mat-error>
          <mat-error *ngIf="(sopimuskausiFormGroup.get('tositteetLoppuu')?.errors?.matDatepickerMin || sopimuskausiFormGroup.get('tositteetLoppuu')?.errors?.tositteetSuurempiKuinLoppuu) && (sopimuskausiFormGroup.get('tositteetLoppuu')?.dirty || sopimuskausiFormGroup.get('tositteetLoppuu')?.touched)">
            Tositteiden toimittamisen on loputtava sopimuksen loppumisen jälkeen
          </mat-error>
          <mat-datepicker #pickerTositteetLoppuu></mat-datepicker>
        </mat-form-field>
      </div>
      <div style="padding-top: 25px; margin-left: 10px; margin-right: 10px;" class="gray-text">

        <div *ngIf="!onkoPurku">
          <p>
            Sopimuskausi päättyy {{ kausi.loppuu | lokaalipvm | async }}. Asiakkaan Lemonaid-tunnukset
            suljetaan {{ kausi.loppuu | lokaalipvm | async }} lukuun ottamatta aineiston toimitusta joka
            on auki {{ kausi.tositteidenToimitusLoppuu | lokaalipvm | async }} saakka (päivän loppuun asti).
          </p>
          Asiakkaalle lähetetään seuraavat sähköpostit automaattisesti:
          <div style="padding-left: 10px;"><mat-checkbox formControlName="lahetaPaattymassa"></mat-checkbox> {{sopimusPaattyyPianEmailDate | lokaalipvm | async}} Sopimuksesi päättyy pian</div>
          <div style="padding-left: 10px;"><mat-checkbox formControlName="lahetaPaattynyt"></mat-checkbox> {{sopimusOnPaattynytEmailDate | lokaalipvm | async}} Sopimuksesi on päättynyt</div>
        </div>

        <div *ngIf="onkoPurku">
          <p>
            Sopimuskausi päättyy {{ kausi.loppuu | lokaalipvm | async }}. Asiakkaan Lemonaid-tunnukset
            suljetaan {{ kausi.loppuu | lokaalipvm | async }} myös aineiston toimittamisen osalta.
          </p>
          Purkutilanteessa asiakkaalle ei lähetetä automaattisia sähköposteja.
        </div>
        <!-- Asiakkaalle lähetetään sähköpostiviesti sopimuksen päättämisestä , jossa kerrotaan milloin eri ominaisuuksien käyttö päättyy.</p>

        <p>Palvelut päättyvä (viimeinen päivä jolloin vielä voi käyttää)</p>
        <ul>
          <li>Laskutus </li>
          <li>Palkkojen maksu Lemonaidissa {{ kausi.loppuu | lokaalipvm | async }}</li>
          <li>Tositteiden toimitus </li>
        </ul> -->
      </div>

    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
    <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
  </div>

  <div class="red-text" style="text-align: center; padding: 0 3em 30px 3em;" *ngIf="onkoLaskujenVastaanottoPaallaObservable | async">
    <p>Huom! Asiakkaalla on laskujen vastaanotto käytössä. Laskujen vastaanotto kytketään pois päältä sopimuksen päättyessä. Käy asiakkaan kanssa asia läpi, jotta tämä ei tule yllätyksenä.</p>
    <p>Asiakkaan tulisi avata uusi sähköisten laskujen vastaanotto-osoite toiselle palveluntarjoajalle ja ilmoittaa osoite kaikille toimittajilleen tai ilmoittaa kaikille toimittajille vaihtoehtoinen laskujen toimitustapa. <b>Päättymisen jälkeen osoitteeseen tulevat laskut menetetään.</b></p>
  </div>

</div>