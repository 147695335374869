<div class="detail-page">

  <app-asiakas-otsikko otsikko="Arkisto" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

  <div class="kuvaus">
    Arkistoon voidaan tallentaa dokumentteja ja tiedostoja, jotka saavat näkyä asiakkaalle. Kaikki arkistossa olevat tiedostot toimitetaan asiakkaalle aineiston toimituksen yhteydessä. Huom! esim. rahanpesuun liittyvät selvitykset, ja muu aineisto mikä ei saa näkyä asiakkaalle, on tallennettava muualle.
  </div>

  <button mat-button (click)="openDialog()" type="button" class="primarybutton" style="margin: 50px 0 30px 0">
    Lisää tiedosto
  </button>

  <table>
    <tr>
      <th>Tallenna</th>
      <th>Esikatsele</th>
      <th class="wide">Tiedoston nimi</th>
      <th class="wide">Lataaja</th>
      <th class="wide">Ladattu</th>
      <th class="extra-wide">Lisätiedot</th>
      <!-- <th class="wide">Tyyppi</th> -->
      <td>&nbsp;</td>
    </tr>

    <ng-container *ngFor="let file of arkistoFilesObservable | async">
      <tr>
        <td>
          <button mat-icon-button (click)="downloadFile(file)">
            <i class="fa fa-arrow-circle-o-down" style="font-size: 20px;"></i>
          </button>
        </td>
        <td style="text-align: center;">
          <button *ngIf="file.canPreview && !file.previewOpen" mat-icon-button (click)="previewFile(file)">
            <i class="fa fa-eye" style="font-size: 20px;"></i>
          </button>
          <button *ngIf="file.previewOpen" mat-icon-button (click)="stopFilePreview(file)">
            <i class="fa fa-eye-slash" style="font-size: 20px;"></i>
          </button>
        </td>
        <td class="wide">{{file.fileName}}</td>
        <td class="wide">{{file.lataajaName}}</td>
        <td class="wide">{{file.ladattuDateString}}</td>
        <td class="extra-wide">{{file.lisatiedot}}</td>
        <!-- <td class="wide">{{file.typeName}}</td> -->
        <td>
          <button mat-icon-button (click)="markAsDeleted(file)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="file.previewOpen">
        <td colspan="7">
          <div *ngIf="file.previewLoading else viewers" style="margin: 20px; text-align: center;">
            Esikatselua ladataan...
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <ng-template #viewers>
            <div app-pdf-viewer [url]="file.previewFileDataUrl | async" *ngIf="file.previewType === 'pdf'"></div>
            <div app-image-zoom [thumbImage]="file.previewFileDataUrl | async" *ngIf="file.previewType === 'jpg' || file.previewType === 'png'"></div>
          </ng-template>
        </td>
      </tr>
    </ng-container>
  </table>
</div>