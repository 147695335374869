<mat-dialog-content>

  <lemon-dialog-header>
    Kirjauksen raakadata
  </lemon-dialog-header>

  <h3 style="margin: 0;">Urit</h3>
  <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Kirjaus: <a [href]="kirjausEncodedUriObservable | async" target="_BLANK">{{kirjausUriObservable | async}}</a></div>
  <div style="padding-top: 0;   padding-left: 1em; padding-right: 1em;">Liitetyt tiedostot: <a [href]="kirjaukseenLiitettyjenTiedostojenEncodedUriObservable | async" target="_BLANK">{{kirjaukseenLiitettyjenTiedostojenUriObservable | async}}</a></div>

  <h3 style="margin: 0; margin-top: 1em;">Kirjaus</h3>
  <div style="padding: 1em;" [innerHTML]="data.naytettavaKirjaus | ljson"></div>

  <h3 style="margin: 0; margin-top: 1em;">Liitetyt tiedostot</h3>
  <div style="padding: 1em;" [innerHTML]="liitetytTiedostotObservable | async | ljson"></div>

</mat-dialog-content>
<mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</mat-dialog-actions>