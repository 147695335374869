import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'
import { KirjanpitajanRooli } from '../../_jaettu/lemonator/model/kirjanpitaja'
import { AsiakasComponentDataResolve } from '../_resolvers/asiakas.resolve'

import { Observable, combineLatest } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable()
export class AsiakasGuard  {

  constructor(
    private router: Router,
    private asiakasComponentDataResolve: AsiakasComponentDataResolve,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.asiakasComponentDataResolve.resolve(route), this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([asiakas, kirjanpitaja]) => {
        // console.log(asiakas, kirjanpitaja)
        if (kirjanpitaja) {
          if (kirjanpitaja.rooli === KirjanpitajanRooli.SUPER) {
            return true
          }
          if (asiakas) {
            // Tarkista vain, jos asiakkaalla on avain, eli on jo tallennettu..
            if (asiakas.avain) {
              if (asiakas.kasittelija === kirjanpitaja.uid || asiakas.kasittelijavara === kirjanpitaja.uid) {
                return true
              }
              if (kirjanpitaja.rooli === KirjanpitajanRooli.TAVALLINEN_JA_HOLVI && (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' || asiakas.kasittelijavara === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2')) {
                return true
              }
              if (
                (kirjanpitaja.uid === 'QL4CBlJTLdQLtbb4k8AURFYeg3I3' || kirjanpitaja.uid === 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1') &&
                (asiakas.kasittelija === 't86aMdfTkicMvOiKGGjKm4m3Rwm1' || asiakas.kasittelijavara === 't86aMdfTkicMvOiKGGjKm4m3Rwm1') // sales@lemontree.fi
              ) {
                return true
              }
              return false
            }
            return true
          }
        }
        return false
      }),
      tap(sallittu => {
        if (!sallittu) {
          this.router.navigate(['/kirjauduttupois'])
        }
      })
    )
  }

}
