import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Platform } from '@angular/cdk/platform'
import { DataSource } from '@angular/cdk/table'
import { MatDialog } from '@angular/material/dialog'

import { LEMONATOR_CF_API, LemonHttpService } from 'app/_angular/service/lemon-http.service'
import { LaskuLataaDialog, LaskuLataaDialogData } from './dialogit/lasku.lataa.dialog'

import { LoadingConfig } from 'app/_jaettu-angular/_components/loading.component'
import { LaskuKatseleComponentExistingData, LaskuKatseleComponentDataResolve } from '../_angular/_resolvers/lasku.resolve'
import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

import { LemonKuukausiDateAdapter } from '../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { WindowSizeService } from '../_jaettu-angular/service/window.service'

import { DateService } from '../_shared-core/service/date.service'
import { LaskunTila, LaskunAsiakas, LaskunListaustietorivi, LaskuBase, Lasku } from '../_jaettu/model/lasku'

import { LaskutUusiDataSourceService } from './laskut.uusi.datasource.service'
import { LaskunLokalisoituTila } from './laskut.firestore.datasource'

import { Subject } from 'rxjs'
import { debounceTime, takeUntil, take } from 'rxjs/operators'

import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export type LaskuProperties = 'nro' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'lataa' | undefined

export interface SummienRullausData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  edellinenLaskunTila: LaskunTila
}

@Component({
  selector: 'app-laskut',
  templateUrl: './laskut.component.html',
  styleUrls: ['./laskut.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class LaskutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef
  @ViewChild(MatPaginator) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  private ngUnsubscribe = new Subject<void>()

  form: UntypedFormGroup

  propertiesToDisplay: LaskuProperties[] = []
  tilat: LaskunLokalisoituTila[] = []

  naytaHuomautus = false
  naytaKaikkiSummat = true

  loadingSettings: LoadingConfig = {
    backdropBackgroundColour: 'rgba(0,0,0,0.15)',
    backdropBorderRadius: '4px',
    fullScreenBackdrop: false,
    primaryColour: 'rgba(255, 255, 255, 1)',
    secondaryColour: 'rgba(255, 255, 255, 1)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(255, 255, 255, 1)'
  }

  constructor(
    private router: Router,
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    private http: LemonHttpService,
    private lemonTranslation: LemonTranslationService,
    private laskuService: LaskuService,
    private windowSizeService: WindowSizeService,
    private translationService: LemonTranslationService,
    private dateAdapter: DateAdapter<Date>,
    private laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private asiakasService: AsiakasService,
    private laskutDataSourceService: LaskutUusiDataSourceService,
    private _fileSaverService: FileSaverService
  ) {

  }

  ngOnInit() {

    this._sort.sort({
      disableClear: true,
      id: 'nro',
      start: 'desc'
    })

    this.asetaListauksenKolumnit()

    this.translationService.currentLanguageObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kieli => {
      this.dateAdapter.setLocale(kieli)
    })

    // this.laskuService.asetuksetObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(asetukset => {
    //   if (asetukset && !asetukset.summahuomautusPiilotettu) {
    //     this.naytaHuomautus = true
    //   } else {
    //     this.naytaHuomautus = false
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this.laskuSummatService.summatObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(summat => {
    //   this.summat = summat
    //   if (summat && summat.length > 0) {
    //     const summa = summat[0]
    //     this.suurinSumma = summa
    //   }
    //   // if (summat && summat.length > 1) {
    //   //   this.naytaKaikkiSummat = true
    //   // } else {
    //   //   this.naytaKaikkiSummat = false
    //   // }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    this.laskutDataSourceService.laskunTilaObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tilat => {
      this.tilat = tilat
    }, error => {
      this.errorHandler.handleError(error)
    })

    // const vuosiKk = this.dataSource.getVuosiKk()
    // const date = vuosiKk && vuosiKk.vuosi && vuosiKk.kk ? new Date(vuosiKk.vuosi, vuosiKk.kk, 1) : null
    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(this.matDataSource.filter, []),
      // 'vuosikk': new FormControl( date, [] ),
      // 'vuosikkkohde': new FormControl( vuosiKk.kohde, [] ),
      // 'tila': new FormControl( this.dataSource.getTila(), [] )
    })

    // this.form.get('vuosikkkohde').valueChanges.subscribe((value: 'p' | 'e') => {
    //   this.dataSource.setVuosiKkKohde(value)
    // })
    // this.form.get('vuosikk').valueChanges.subscribe((value: Date) => {
    //   console.log('asetettu vuosikk; ', value)
    //   if (value) {
    //     this.dataSource.setVuosiKk(value.getFullYear(), value.getMonth())
    //   } else {
    //     this.dataSource.setVuosiKk(null, null)
    //   }
    // })
    // this.form.get('tila').valueChanges.subscribe((value: LaskunTila) => {
    //   this.dataSource.setTila(value)
    // })
    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        const val = value.trim().toLowerCase()
        this.matDataSource.filter = val
      } else {
        this.matDataSource.filter = null
      }
    })

    const currentSort = this.matDataSource.sort
    if (currentSort) {
      this._sort.sort({
        id: currentSort.active,
        disableClear: true,
        start: currentSort.direction === 'asc' ? 'asc' : 'desc'
      })
    }
    this.matDataSource.sort = this._sort

    // const currentSort = this.dataSource.getSort()
    // this.sort.sort({
    //   id: currentSort.active,
    //   disableClear: true,
    //   start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    // })
    // this.sort.sortChange.subscribe(sort => {
    //   this.dataSource.changeSort(sort)
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this._paginator.page.subscribe(page => {
    //   this.dataSource.changePage(page)
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this.route.data.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(( data: { data: SummienRullausData } ) => {

    //   if (data && data.data && data.data.kasiteltava && data.data.juurilasku && data.data.edellinenLaskunTila) {

    //     // console.log('paukutetaan')

    //     // this.dataSource.resetSearchToDefaults()

    //     // setTimeout(() => {

    //       // console.log('rullataan')
    //       // this.laskuSummatService.lisaaLaskunSummat(data.data, false)

    //     // }, 500)

    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  ngAfterViewInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(koko => {
      setTimeout(() => {
        this.asetaListauksenKolumnit()
      }, 250)
    })
  }

  private asetaListauksenKolumnit() {
    const leveys = this.laskutaulu.nativeElement.offsetWidth
    // if (tablenKoko < 500) {
    //   this.propertiesToDisplay = [                     'erapvm',         'avoinna'/*, 'merkitse'*/]
    // } else
    if (leveys < 600) {
      this.propertiesToDisplay = ['tila', 'erapvm', 'avoinna', 'lataa']
    } else if (leveys < 700) {
      this.propertiesToDisplay = ['tila', 'erapvm', 'summa', 'avoinna', 'lataa']
    } else if (leveys < 800) {
      this.propertiesToDisplay = ['tila', 'pvm', 'erapvm', 'summa', 'avoinna', 'lataa']
    } else {
      this.propertiesToDisplay = ['tila', 'nro', 'pvm', 'erapvm', 'summa', 'avoinna', 'lataa']
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  getDisplayFn() {
    return (val) => this.display(val)
  }

  private display(laskunAsiakas: LaskunAsiakas): string {
    if (laskunAsiakas && laskunAsiakas.nimi) {
      return laskunAsiakas.nimi
    }
    return ''
  }

  onCountoEndAvoinna() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  onCountoEndSaatu() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  onCountoEndEraantynyt() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  lataaPdf(rivi: LaskunListaustietorivi) {
    if (
      (rivi.kasiteltava.print && rivi.kasiteltava.print.done) ||
      (rivi.kasiteltava.email && rivi.kasiteltava.email.done) ||
      (rivi.kasiteltava.sahkoinen && rivi.kasiteltava.sahkoinen.done)
    ) {
      const pdfUri = this.laskuService.getPdfUrl(rivi.kasiteltava)
      const url = '/api/1/laskut/lataaPdf/' + pdfUri
      return this.http.getBinary('/laskuLataaPdf?a=' + encodeURIComponent(url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONATOR_CF_API).then(result => {
        const nimi = this.laskuService.annaPdfTiedostonNimi(rivi.juurilasku, rivi.kasiteltava, this.lemonTranslation.nykyinenKieli)
        this._fileSaverService.saveAs(result, nimi)
      }).catch(err => {
        this.errorHandler.handleError(err)
      })
    } else {
      const data: LaskuLataaDialogData = {
        juurilasku: rivi.juurilasku,
        kasiteltava: rivi.kasiteltava
      }
      this.dialog.open(LaskuLataaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
    }
  }

  muokkaa(rivi: LaskunListaustietorivi) {

    this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      take(1)
    ).subscribe(asiakas => {
      if (asiakas) {
        const existing: LaskuKatseleComponentExistingData = {
          juurilasku: rivi.juurilasku,
          kasiteltava: rivi.kasiteltava
        }
        this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(existing)
        this.router.navigate(['asiakkaat', asiakas.avain, 'laskutus', 'katsele', rivi.juuriAvain, rivi.avain])
      }
    })

  }

  // lisaa() {
  //   this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi']).then(result => {
  //     this.vasenValikkoService.paivitaAuki(false)
  //   })
  // }

  get dataSource(): DataSource<LaskunListaustietorivi> {
    return this.laskutDataSourceService.dataSourceTransformed
  }

  get matDataSource(): MatTableDataSource<Lasku> {
    return this.laskutDataSourceService
  }

  get lataa(): boolean {
    return this.laskutDataSourceService.lataa
  }

  async merkitseMaksetuksi(rivi: LaskunListaustietorivi) {
    // if (rivi.avoinna > 0) {
    //   await this.laskuService.merkitseLaskuTaysinMaksetuksi(rivi)
    // } else {
    //   const data: LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData = {
    //     rivi: rivi
    //   }
    //   const settings: MatDialogConfig = {
    //     data: data,
    //     panelClass: 'ilman-paddingia',
    //     maxWidth: '1600px'
    //     // ,
    //     // maxHeight: korkeus,
    //     // width: leveys
    //   }
    //   this.dialog.open(LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, settings)
    // }
  }

  // avaaKaikkiSummat() {
  //   const data: LaskuSummatKaikkiDialogData = {
  //     summat: this.summat
  //   }
  //   this.dialog.open(LaskuSummatKaikkiDialog, { data: data })
  // }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

}
