<div class="list-page">

  <app-asiakas-otsikko otsikko="Maksutavat" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>
  <!--
  <div style="display: flex; justify-content: flex-end; flex-flow: row; align-items: center; margin-bottom: 10px;">

    <button mat-button (click)="lisaaKayttaja()" type="button" class="primarybutton mat-elevation-z4">
      <mat-icon>add</mat-icon>
      Lisää käyttäjä
    </button>

  </div> -->

  <div>

    <!-- <div style="position: relative;">
      <mat-table matSort [dataSource]="kayttajatDataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="etunimi">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Etunimi </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.etunimi}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sukunimi">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sukunimi </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.sukunimi ? row.sukunimi : '&nbsp;'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sähköposti </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.email ? row.email : '&nbsp;'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="kayttajatNaytettavatKolumnit"></mat-header-row>
        <mat-row (click)="muokkaaKayttajaa(row)" *matRowDef="let row; columns: kayttajatNaytettavatKolumnit"></mat-row>

      </mat-table>
      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!kayttajatLataa && kayttajatDataSource?.filteredData?.length == 0">
        <div style="width: 100%; padding-top: 40px; text-align: center;">
          Yhtään käyttäjää ei löytynyt.
        </div>
      </div>
      <<div lemon-loading [show]="kayttajatLataa"></div>
    </div> -->
  </div>
  <div style="margin-left: 10px; padding-bottom: 5em;">
    <h2 style="margin: 0;">Pankkitilit, joihin olemme saaneet katseluoikeuden</h2>
    <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding: 10px; margin-bottom: 20px; margin-left: 20px; max-width: 600px; position: relative;" class="mat-elevation-z2">
      <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
      <h3 style="margin: 0;">Urit</h3>
      <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Bank Connections (Lemonaid): <a [href]="bankConnectionsEncodedUriObservable | async" target="_BLANK">{{bankConnectionsUriObservable | async}}</a></div>
    </div>
    <mat-progress-bar *ngIf="lataaIbaneja" mode="indeterminate"></mat-progress-bar>
    <div [style.hidden]="lataaIbaneja ? 'hidden' : 'visible'" style="margin-left: 10px">
      <table *ngIf="asiakkaanIbanitJaPankitObservable | async; let pankitJaIbanit else eiIbaneja">
        <thead>
          <tr>
            <th>Pankki</th>
            <th>BIC</th>
            <th>IBAN</th>
            <th>Lähde</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pankkiJaIban of pankitJaIbanit">
            <td [style.text-decoration]="pankkiJaIban.disabledInLemonator ? 'line-through' : 'none'">{{ pankkiJaIban.pankki }}</td>
            <td [style.text-decoration]="pankkiJaIban.disabledInLemonator ? 'line-through' : 'none'">{{ pankkiJaIban.bic }}</td>
            <td [style.text-decoration]="pankkiJaIban.disabledInLemonator ? 'line-through' : 'none'">{{ pankkiJaIban.iban }}</td>
            <td style="text-align: center;">{{ pankkiJaIban.source }}</td>
            <td>
              <span *ngIf="pankkiJaIban.disabledInLemonator; else disableBtn" style="font-weight: bold;">
                (Tarpeeton tili)
                <button class="secondarybutton" (click)="enableConnectedAccount(pankkiJaIban)">Aktivoi uudelleen</button>
              </span>
              <ng-template #disableBtn>
                <button class="secondarybutton" (click)="disableConnectedAccount(pankkiJaIban)">Merkitse tarpeettomaksi</button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #eiIbaneja>
        <div style="padding: 3em;">
          Yritys ei ole antanut oikeutta katsella yhtään pankkitiliä.
        </div>
      </ng-template>
    </div>
  </div>
  <div style="margin-left: 10px; display: flex; justify-content: space-between; flex-flow: row wrap; align-items: center;">
    <h2 style="margin: 0;">Maksutavat kirjanpidossa</h2>
    <button mat-button (click)="lisaaMaksutapa()" type="button" class="secondarybutton mat-elevation-z4" style="height: 40px; padding-left: 8px; margin: 0 2em;">
      <mat-icon>add</mat-icon>
      Lisää maksutapa
    </button>
  </div>
  <div style="margin-left: 10px;">
    <div style="padding: 20px;" class="dark-gray-text">
      Kirjanpidon maksutavat näkyvät Lemonaidissa valituilla käyttäjillä tositteiden toimittamisessa. Näillä maksutavoilla asiakas ei voi hoitaa rahaliikennettä tai maksaa laskuja.
    </div>
  </div>
  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding: 10px; margin-bottom: 20px; margin-left: 20px; max-width: 600px; position: relative;" class="mat-elevation-z2">
    <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonator): <a [href]="maksutavatEncodedUriObservable | async" target="_BLANK">{{maksutavatUriObservable | async}}</a></div>
    <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonaid eu asiakkaassa): <a [href]="maksutavatLemonaidCustomersEncodedUriObservable | async" target="_BLANK">{{maksutavatLemonaidCustomersUriObservable | async}}</a></div>
    <div style="padding-top: .5em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonaid usa realtime db, käyttäjittäin):</div>
    <div *ngFor="let kayttaja of maksutavatLemonaidRealtimeDbUriObservable | async" style="padding-left: 1em;">
      <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">{{kayttaja.kayttaja}}: <a [href]="kayttaja.encodedUri" target="_BLANK">{{kayttaja.uri}}</a></div>
    </div>
  </div>
  <div style="margin-top: 10px;">
    <mat-progress-bar *ngIf="lataaMaksutapoja" mode="indeterminate"></mat-progress-bar>
    <form (ngSubmit)="save()" [formGroup]="form" novalidate *ngIf="!lataaMaksutapoja">
      <table style="border-spacing: 0;" formArrayName="maksutavat">
        <tr>
          <th></th>
          <th>Kuva</th>
          <!-- <th style="width: 61px;">Tunniste</th> -->
          <th *ngIf="isHolviUlkopTositteetVisible" style="width: 150px;">Holvi ulkop. tositteet</th>
          <!-- <th *ngIf="isOmallaRahallaColumnVisible">Kenen rahalla?</th> -->
          <th>Nimi</th>
          <th>Kirjauksien muodostus</th>
          <th>Tilinumero</th>
          <th>Vastakirjaus</th>
          <th>Voimassa alkaen</th>
          <th>Voimassa loppuen</th>
          <th>Käytössä käyttäjillä</th>
          <th style="width: 41px;">Aktiivinen</th>
          <th style="width: 41px;">Valtuutus?</th>
          <!-- <th style="width: 41px;">Oletus</th>
        <th style="width: 81px;">Järjestys</th> -->
          <th style="width: 41px;"></th>
        </tr>
        <tr *ngFor="let maksutapa of maksutapaControllit; let i = index" [formGroupName]="i">
          <td>
            <div class="dark-gray-text" style="font-size: 30px; padding-right: 10px; padding-top: 14px;">{{maksutavatJaKayttajat[i].maksutapa.tunniste}}</div>
          </td>
          <td (click)="lisaaMaksutavanKuva(i)" style="padding-top: 6px; cursor: pointer;">
            <img [src]="'data:image/png;base64,' + maksutavatJaKayttajat[i].maksutapa.base64Kuva" style="width: 40px; border-radius: 40px; vertical-align: middle;" class="light-blue-background" />
          </td>
          <td *ngIf="isHolviUlkopTositteetVisible">
            <mat-checkbox [style.visibility]="maksutapa.get('kirjaustenLuontityyppi').value?.tunniste === 'g' ? 'visible' : 'hidden'" style="position: relative; top: 15px; left: 20px;" (change)="holviOmallaRahallaTiliChanged(maksutavatJaKayttajat[i].maksutapa.tunniste)" [checked]="holviOmallaRahallaTili === maksutavatJaKayttajat[i].maksutapa.tunniste"
              [disabled]="maksutapa.get('kirjaustenLuontityyppi').value?.tunniste !== 'g'">
            </mat-checkbox>
          </td>
          <!-- <td *ngIf="isOmallaRahallaColumnVisible">
            <mat-form-field *ngIf="maksutapa.get('kirjaustenLuontityyppi').value?.tunniste === 'g'" style="width: 180px;">
              <mat-select formControlName="omallaRahallaUid" placeholder="Valitse käyttäjä">
                <mat-option [value]="null">Kaikkien käyttäjien käytössä</mat-option>
                <mat-option *ngFor="let kayttaja of kaikkiKayttajatObservable | async" [value]="kayttaja.avain">
                  {{ kayttaja.etunimi + ' ' + kayttaja.sukunimi }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td> -->
          <!-- <td>
          <mat-form-field>
            <input decimalNumberField matInput floatLabel="never" formControlName="tunniste" placeholder="Tunniste" numberOfDecimals="0">
          </mat-form-field>
        </td> -->
          <td>
            <mat-form-field style="width: 175px;">
              <input type="text" formControlName="nimi" matInput required [name]="inputname" autocomplete="kirjanpitoaineistoarkki" placeholder="Nimi" data-lpignore="true" />
              <mat-error *ngIf="maksutapa.get('nimi')?.errors?.required && (maksutapa.get('nimi')?.dirty || maksutapa.get('nimi')?.touched)">
                Nimi puuttuu
              </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field style="width: 200px;">
              <mat-select formControlName="kirjaustenLuontityyppi" [compareWith]="compareLuontityypit" placeholder="Luontityyppi" required>
                <mat-option *ngFor="let kirjaustenLuontityyppi of kirjaustenLuontityypit" [value]="kirjaustenLuontityyppi" [disabled]="kirjaustenLuontityyppi?.alitunniste === 'holvi' && isHolviApiSelectionDisabled">
                  {{ kirjaustenLuontityyppi.nimi }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="maksutapa.get('kirjaustenLuontityyppi')?.errors?.required && (maksutapa.get('kirjaustenLuontityyppi')?.dirty || maksutapa.get('kirjaustenLuontityyppi')?.touched)">
                Kirjausten luontityyppi puuttuu
              </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field [style.visibility]="maksutapa.get('kirjaustenLuontityyppi').value?.tunniste === 's' ? 'visible' : 'hidden'" style="width: 170px;">
              <input type="text" formControlName="sahkoisenTiliotteenTunniste" matInput required [name]="inputname" [matAutocomplete]="auto" autocomplete="kirjanpitoaineistoarkki" placeholder="Tilinumero" data-lpignore="true" />
              <mat-autocomplete #auto="matAutocomplete" [panelWidth]="285">
                <mat-option *ngFor="let ibanJaPankki of asiakkaanIbanitJaPankitObservable | async" [value]="ibanJaPankki.iban">
                  {{ ibanJaPankki.iban }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="maksutapa.get('sahkoisenTiliotteenTunniste')?.errors?.required && (maksutapa.get('sahkoisenTiliotteenTunniste')?.dirty || maksutapa.get('sahkoisenTiliotteenTunniste')?.touched)">
                Tilinumero puuttuu
              </mat-error>
              <mat-error *ngIf="maksutapa.get('sahkoisenTiliotteenTunniste')?.errors?.invalidiban && (maksutapa.get('sahkoisenTiliotteenTunniste')?.dirty || maksutapa.get('sahkoisenTiliotteenTunniste')?.touched)">
                Tilinumero ei ole pätevä IBAN
              </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field style="width: 100px;">
              <input type="text" formControlName="oletusVastatili" [matAutocomplete]="tiliAuto" matInput required [name]="inputname" autocomplete="kirjanpitoaineistoarkki" placeholder="Vastatili" data-lpignore="true" />
              <mat-error *ngIf="maksutapa.get('oletusVastatili')?.errors?.required && (maksutapa.get('oletusVastatili')?.dirty || maksutapa.get('oletusVastatili')?.touched)">
                Vastakirjauksen tili puuttuu
              </mat-error>
              <mat-error *ngIf="maksutapa.get('oletusVastatili')?.errors?.notkirjanpitotili && (maksutapa.get('oletusVastatili')?.dirty || maksutapa.get('oletusVastatili')?.touched)">
                Lisää voimassa oleva kirjanpitotili
              </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field [style.visibility]="maksutapa.get('tilitapahtumatSynkronoidaanAlkaen').enabled ? 'visible' : 'hidden'" style="width: 100px;">
              <input type="text" [matDatepicker]="synkronoidaanAlkaenPicker" formControlName="tilitapahtumatSynkronoidaanAlkaen" matInput [name]="inputname" data-lpignore="true" />
              <mat-error *ngIf="maksutapa.get('tilitapahtumatSynkronoidaanAlkaen')?.errors?.required && (maksutapa.get('tilitapahtumatSynkronoidaanAlkaen')?.dirty || maksutapa.get('tilitapahtumatSynkronoidaanAlkaen')?.touched)">
                Tilitapahtumien synkronoinnin alku puuttuu
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="synkronoidaanAlkaenPicker"></mat-datepicker-toggle>
              <mat-datepicker #synkronoidaanAlkaenPicker startView="month"></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field [style.visibility]="maksutapa.get('tilitapahtumatSynkronoidaanLoppuen').enabled ? 'visible' : 'hidden'" style="width: 100px;">
              <input type="text" [matDatepicker]="synkronoidaanLoppuenPicker" formControlName="tilitapahtumatSynkronoidaanLoppuen" matInput [name]="inputname" data-lpignore="true" />
              <!-- <mat-error *ngIf="maksutapa.get('synkronoidaanLoppuenPicker')?.errors?.required && (maksutapa.get('synkronoidaanLoppuenPicker')?.dirty || maksutapa.get('synkronoidaanLoppuenPicker')?.touched)">
              Tilitapahtumien synkronoinnin loppu puuttuu
            </mat-error> -->
              <mat-datepicker-toggle matSuffix [for]="synkronoidaanLoppuenPicker"></mat-datepicker-toggle>
              <mat-datepicker #synkronoidaanLoppuenPicker startView="month"></mat-datepicker>
            </mat-form-field>
          </td>
          <td style="vertical-align: middle; padding-left: 20px; padding-right: 30px;">
            <div style="height: 16px;" *ngIf="maksutavatJaKayttajat[i].kayttajat.length > 1"></div>
            <div *ngFor="let kayttaja of maksutavatJaKayttajat[i].kayttajat" style="white-space: nowrap;">
              {{ kayttaja.sukunimi }} {{ kayttaja.etunimi }}
            </div>
          </td>
          <td style="text-align: center; padding-top: 20px;">
            <mat-checkbox formControlName="aktiivinen"></mat-checkbox>
          </td>
          <td style="text-align: center; padding-top: 20px;">
            <mat-checkbox [checked]="maksutavatJaKayttajat[i].onkoValtuutus" [disabled]="true"></mat-checkbox>
          </td>
          <!-- <td style="text-align: center;">
          <mat-radio-button [value]="i"></mat-radio-button>
        </td> -->
          <!-- <td style="text-align: center;">
          <button mat-icon-button (click)="maksutapaYlos(i)" type="button">
            <mat-icon>arrow_drop_up</mat-icon>
          </button>
          <button mat-icon-button (click)="maksutapaAlas(i)" type="button">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </td> -->
          <td class="dark-gray-text" style="padding-left: 10px;">
            <button *ngIf="maksutavatJaKayttajat[i].uusi" mat-icon-button (click)="poistaMaksutapa(i)" type="button">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </table>

      <mat-autocomplete #tiliAuto="matAutocomplete" panelWidth="300px">
        <mat-option *ngFor="let tili of suodatetutKirjanpitotilitObservable | async" [value]="tili.numero">
          {{ tili?.numero }} {{ tili?.nimi }}
        </mat-option>
      </mat-autocomplete>

      <div *ngIf="commonError" class="error-text">
        {{commonError}}
      </div>

      <div style="margin-left: 10px; padding-top: 2em; display: flex; justify-content: space-between; flex-flow: row wrap;">
        <div></div>
        <button mat-button (click)="save()" type="button" class="secondarybutton mat-elevation-z4" style="margin: 0 2em;">
          Tallenna
        </button>
      </div>

    </form>
  </div>

  <ng-container *ngIf="naytaMaksutilitObservable | async">

    <div style="margin-left: 10px; margin-top: 100px; display: flex; justify-content: space-between; flex-flow: row wrap; align-items: center;">
      <h2 style="margin: 0;">Maksutilit Lemonaidissa</h2>
      <!-- <button mat-button (click)="lisaaMaksuConfig()" type="button" class="secondarybutton mat-elevation-z4" style="height: 40px; padding-left: 8px; margin: 0 2em;">
        <mat-icon>add</mat-icon>
        Lisää maksutili
      </button> -->
    </div>
    <ng-container *ngIf="asiakkaanApixTiedotObservable | async let asiakkaanApixTiedot">
      <div style="margin-left: 10px;">
        <div style="margin: 20px;" *ngIf="!asiakkaanApixTiedot.paymentIsActive">HUOM! Asiakas ei ole ottanut maksuominaisuutta käyttöön Lemonaidissa. Alla olevat maksutilit eivät tule käyttöön ennen kuin asiakas on ottanut palvelun käyttöön.</div>
      </div>
    </ng-container>
    <!-- <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding: 10px; margin-bottom: 20px; margin-left: 20px; max-width: 600px; position: relative;" class="mat-elevation-z2">
    <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonator): <a [href]="maksutavatEncodedUriObservable | async" target="_BLANK">{{maksutavatUriObservable | async}}</a></div>
    <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonaid eu asiakkaassa): <a [href]="maksutavatLemonaidCustomersEncodedUriObservable | async" target="_BLANK">{{maksutavatLemonaidCustomersUriObservable | async}}</a></div>
    <div style="padding-top: .5em; padding-left: 1em; padding-right: 1em;">Maksutavat (Lemonaid usa realtime db, käyttäjittäin):</div>
    <div *ngFor="let kayttaja of maksutavatLemonaidRealtimeDbUriObservable | async" style="padding-left: 1em;">
      <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">{{kayttaja.kayttaja}}: <a [href]="kayttaja.encodedUri" target="_BLANK">{{kayttaja.uri}}</a></div>
    </div>
  </div> -->
    <div style="margin-top: 10px; margin-left: 10px;">
      <!-- <mat-progress-bar *ngIf="lataaMaksutapoja" mode="indeterminate"></mat-progress-bar> -->
      <form (ngSubmit)="saveMaksuConfigs()" [formGroup]="maksuConfigForm" novalidate *ngIf="!lataaMaksutapoja">

        <div style="max-width: 800px;">

          <div style="margin: 20px;" class="dark-gray-text">
            Lemonaidin maksutilit näkyvät kaikilla käyttäjillä, joilla on "Ostolaskujen maksaja" rooli. Näiltä tileillä asiakas voi maksaa laskuja Lemonaidissa.
            <ng-container *ngIf="asiakkaanOstolaskujenMaksajaRoolinKayttajatObservable | async; let asiakkaanOstolaskujenMaksajaRoolinKayttajat">
              <div *ngIf="asiakkaanOstolaskujenMaksajaRoolinKayttajat?.length > 0 else eiOstolaskunMaksajia" style="margin-left: 20px; margin-top: 5px;">
                <div>"Ostolaskujen maksaja" rooli löytyy seuraavilta käyttäjiltä:</div>
                <ul>
                  <li *ngFor="let kayttaja of asiakkaanOstolaskujenMaksajaRoolinKayttajat">
                    {{kayttaja.etunimi}} {{kayttaja.sukunimi}}
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-template #eiOstolaskunMaksajia>
              <div style="margin-left: 20px; margin-top: 5px;">
                "Ostolaskujen maksaja" roolia ei löydy yhdeltäkään aktiiviselta käyttäjältä. Lisää rooli kohdasta Lemonaid -> Käyttäjät.
              </div>
            </ng-template>
          </div>

          <div class="light-blue-text header">Maksutilit</div>
          <div class="content">
            <div formArrayName="configs" style="max-width: 750px;">
              <div *ngFor="let config of maksuConfigControllit; let i = index;" [formGroupName]="i">

                <mat-form-field style="max-width: 175px; margin-right: 10px;">
                  <input [name]="inputname" #debtorIdentifier="matInput" formControlName="debtorIdentifier" required placeholder="Maksatustunnus" matInput data-lpignore="true" />
                  <mat-error *ngIf="debtorIdentifier?.ngControl?.errors?.required && (debtorIdentifier?.ngControl?.dirty || debtorIdentifier?.ngControl?.touched)">
                    Maksatustunnus puuttuu
                  </mat-error>
                </mat-form-field>

                <mat-form-field style="max-width: 175px; margin-right: 10px;">
                  <input [name]="inputname" #iban="matInput" formControlName="iban" [matAutocomplete]="autoIban" placeholder="Tilinumero" required matInput data-lpignore="true" />
                  <mat-autocomplete #autoIban="matAutocomplete" [panelWidth]="285">
                    <mat-option *ngFor="let ibanJaPankki of asiakkaanIbanitJaPankitObservable | async" [value]="ibanJaPankki.iban">
                      {{ ibanJaPankki.iban }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="iban?.ngControl?.errors?.required && (iban?.ngControl?.dirty || iban?.ngControl?.touched)">
                    Tilinumero puuttuu
                  </mat-error>
                  <mat-error *ngIf="iban?.ngControl?.errors?.invalidiban && (iban?.ngControl?.dirty || iban?.ngControl?.touched)">
                    Tilinumero ei ole pätevä IBAN
                  </mat-error>
                </mat-form-field>

                <mat-form-field style="max-width: 125px; margin-right: 10px;">
                  <input [name]="inputname" #alkaa="matInput" [matDatepicker]="perusteAlkaaPicker" formControlName="start" placeholder="Alkaa" required matInput data-lpignore="true" />
                  <mat-datepicker-toggle matSuffix [for]="perusteAlkaaPicker"></mat-datepicker-toggle>
                  <mat-datepicker #perusteAlkaaPicker></mat-datepicker>
                  <mat-error *ngIf="alkaa.ngControl?.errors?.required && (alkaa.ngControl?.dirty || alkaa.ngControl?.touched)">
                    Alkamispäivä puuttuu
                  </mat-error>
                  <mat-error *ngIf="alkaa.ngControl?.errors?.loppuEnnenAlkua && (alkaa.ngControl?.dirty || alkaa.ngControl?.touched)">
                    Pitää alkaa ennen loppua
                  </mat-error>
                </mat-form-field>
                <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
                  <input [name]="inputname" #loppuu="matInput" type="text" [matDatepicker]="perusteLoppuuPicker" formControlName="end" placeholder="Loppuu" matInput data-lpignore="true" />
                  <mat-datepicker-toggle matSuffix [for]="perusteLoppuuPicker"></mat-datepicker-toggle>
                  <mat-error *ngIf="loppuu.ngControl?.errors?.loppuEnnenAlkua && (loppuu.ngControl?.dirty || loppuu.ngControl?.touched)">
                    Pitää loppua alun jälkeen
                  </mat-error>
                  <mat-datepicker #perusteLoppuuPicker></mat-datepicker>
                </mat-form-field>

              </div>

            </div>
            <div *ngIf="commonErrorPaymentConfigs" class="error-text">
              {{commonErrorPaymentConfigs}}
            </div>
            <div style="padding-top: 10px; padding-bottom: 1.25em;">
              <button mat-button (click)="lisaaMaksuConfig()" type="button" class="secondarybutton mat-elevation-z4">
                <mat-icon>add</mat-icon>
                Lisää maksutili
              </button>
            </div>
          </div>

          <div style="margin: 20px;" class="dark-gray-text">
            Lemonaidin maksujen hyväksyntätyökalut näkyvät kaikilla käyttäjillä, joilla on "Ostolaskujen hyväksyjä" rooli.
            <ng-container *ngIf="asiakkaanOstolaskujenHyvaksyjaRoolinKayttajatObservable | async; let asiakkaanOstolaskujenHyvaksyjaRoolinKayttajat">
              <div *ngIf="asiakkaanOstolaskujenHyvaksyjaRoolinKayttajat?.length > 0 else eiOstolaskunHyvaksyjia" style="margin-left: 20px; margin-top: 5px;">
                <div>"Ostolaskujen hyväksyjä" rooli löytyy seuraavilta käyttäjiltä:</div>
                <ul>
                  <li *ngFor="let kayttaja of asiakkaanOstolaskujenHyvaksyjaRoolinKayttajat">
                    {{kayttaja.etunimi}} {{kayttaja.sukunimi}}
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-template #eiOstolaskunHyvaksyjia>
              <div style="margin-left: 20px; margin-top: 5px;">
                "Ostolaskujen hyväksyjä" roolia ei löydy yhdeltäkään aktiiviselta käyttäjältä.
                <span style="font-weight: bold;">Ostolaskujen hyväksyntä ei siis ole päällä.</span>
                Lisää rooli kohdasta Lemonaid -> Käyttäjät.
              </div>
            </ng-template>
          </div>

          <!-- <div class="light-blue-text otsikko">Yhteystiedot</div> -->
          <mat-form-field style="width: 200px;">
            <mat-label>Hyväksyjien lukumäärä</mat-label>
            <input #hyvaksyjienLukumaaraInput="matInput" decimalNumberField type="text" [name]="inputname" formControlName="hyvaksyjienLukumaara" matInput [numberOfDecimals]="0" />
            <mat-error *ngIf="hyvaksyjienLukumaaraInput?.ngControl?.errors?.required && (hyvaksyjienLukumaaraInput?.ngControl?.dirty || hyvaksyjienLukumaaraInput?.ngControl?.touched)">
              Lukumäärä puuttuu
            </mat-error>
            <mat-error *ngIf="hyvaksyjienLukumaaraInput?.ngControl?.errors?.min && (hyvaksyjienLukumaaraInput?.ngControl?.dirty || hyvaksyjienLukumaaraInput?.ngControl?.touched)">
              Lukumäärän minimi on 1
            </mat-error>
          </mat-form-field>

        </div>

        <div style="margin-left: 10px; padding-top: 2em; display: flex; justify-content: space-between; flex-flow: row wrap;">
          <div></div>
          <button mat-button (click)="saveMaksuConfigs()" type="button" class="secondarybutton mat-elevation-z4" style="margin: 0 2em;">
            Tallenna
          </button>
        </div>

      </form>
    </div>
  </ng-container>

  <!-- <input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" accept="image/*" #fileInput /> -->

</div>