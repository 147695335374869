<div style="max-width: 600px;">
<mat-dialog-content>

    <lemon-dialog-header>
      Varoitus
     </lemon-dialog-header>
  
    <div style="color: red; text-align: center; padding-left: 3em; padding-right: 3em;">
        Haluatko varmasti julkaista uuden version käyttäehdoista? Julkaisemisen jälkeen KAIKKIEN käyttäjien on hyväksyttävä uudet käyttöehdot Lemonaidissa. Toimintoa EI voi perua.
    </div>
    
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-evenly; padding-left: 3em; padding-right: 3em;">
    <button (click)="peruta()" class="primarybutton" mat-button mat-dialog-close>Peruta</button>
    <button (click)="julkaise()"class="secondarybutton" mat-button mat-dialog-close>Julkaise</button>
  </mat-dialog-actions>
</div>