<div class="veroilmoitus-title">Elinkeinotoiminnan veroilmoitus</div>
<div class="veroilmoitus-subtitle">Liikkeen- tai ammatinharjoittaja (5) 2020</div>

<!-- #tabs -->
<mat-tab-group [(selectedIndex)]="activeTabIndex">
  <mat-tab label='1. Taustatiedot' #taustatiedot>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="asiakastiedot-part" class="blue-block">
      <div class="block-row">
        <div class="tili-num"></div>
        <div class="long-col">Nimi</div>
        <div class="short-col">{{ asiakkaanNimi }}</div>
      </div>
      <div app-veroilmoitus-row-read-only numero="010" [veroilmoitus]="veroilmoitus"></div>
      <div app-veroilmoitus-row-dater numero="054" [veroilmoitus]="veroilmoitus" [lukittu]="lukittu" [showPen]="false" [editing]="false"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="yhteystiedot-part" class="blue-block">
      <div app-veroilmoitus-row-read-only numero="041" [veroilmoitus]="veroilmoitus"></div>
      <div app-veroilmoitus-row-read-only numero="042" [veroilmoitus]="veroilmoitus"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="kirjanpitotapa-part" class="blue-block">
      <div app-veroilmoitus-row-radio [veroilmoitus]="veroilmoitus" numero="916" [options]="v916Options" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
    </div>

    <ng-container *ngIf="kaksinkertainenKirjanpito">
      <div app-veroilmoitus-part numero="paaoman-erittely-part" class="blue-block">
        <div app-veroilmoitus-row-numbe numero="748" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="741" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
        <div app-veroilmoitus-row-numbe numero="742" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
        <div app-veroilmoitus-row-read-only numero="743" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row"></div>
      </div>

      <div app-veroilmoitus-part numero="laskelma-korkokuluista-part" class="blue-block">
        <div app-veroilmoitus-row-numbe numero="659" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu"></div>
        <div app-veroilmoitus-row-numbe numero="677" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="650" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu"></div>
        <div app-veroilmoitus-row-numbe numero="651" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="652" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu"></div>
        <div app-veroilmoitus-row-numbe numero="653" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="percentage" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="383" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu" class="blue-dark-block fat-end-row"></div>
      </div>

      <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="rahan-nostot-part" class="blue-block">
        <div app-veroilmoitus-row-numbe numero="384" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu"></div>
        <div app-veroilmoitus-row-numbe numero="385" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" [lukittu]="lukittu"></div>
      </div>
    </ng-container>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>

  </mat-tab>
  <mat-tab label="2. Tuotot" #tuotot>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="tuotot-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="300" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="318" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="301" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="323" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="312" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="324" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-end"></div>
      <div app-veroilmoitus-row-read-only numero="tiedot-tuotoista-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row remove-num-col"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="elinkeino-ulkopuoliset-kulut-part" [optional]="true" (tallennaArvo)="tallenna($event)" class="blue-block">
      <div explanation>
        Tämän osan tietoja muokataan "Kirjanpidon ulkopuoliset vähennykset" -lomakkeelta. <button mat-button (click)="avaaKirjanpidonUlkopuolisetKulutLomake()" style="text-decoration: underline;">Avaa lomake</button>
      </div>
      <div app-veroilmoitus-row-check numero="379" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [options]="v379Options" [showPen]="true" class="group-end"></div>
      <div app-veroilmoitus-row-radio numero="380" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [options]="ajojenSelvitystyyppiOptions" direction="vertical" [showPen]="true" class="group-end"></div>
      <div class="divider"></div>
      <div app-veroilmoitus-row-numbe numero="271" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="km" [desimaaleja]="0" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="273" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="km" [desimaaleja]="0"></div>
      <div app-veroilmoitus-row-numbe numero="274" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="km" [desimaaleja]="0" class="group-end"></div>
      <div app-veroilmoitus-row-numbe numero="381" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" [naytaEhdotus]="true" [allowNegative]="false" class="group-start group-end"></div>
      <div app-veroilmoitus-row-numbe numero="275" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [allowNegative]="false" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="382" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [allowNegative]="false" class="group-end"></div>
      <div app-veroilmoitus-row-radio numero="396" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [options]="v396Options" direction="vertical" [showPen]="true" class="group-start group-end"></div>
      <div app-veroilmoitus-row-numbe numero="313" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="tuloutus-yksityiskaytosta-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="314" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-start" [naytaEhdotus]="true"></div>
      <div app-veroilmoitus-row-numbe numero="315" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-end"></div>
      <div app-veroilmoitus-row-numbe numero="tuloutus-yksityiskaytosta-yhteensa" [veroilmoitus]="veroilmoitus" [lukittu]="lukittu" format="currency" [showPen]="false" class="blue-dark-block fat-end-row remove-num-col"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="veronalaiset-tuotot-yhteensa-part" class="gray-dark-block">
      <div app-veroilmoitus-row-numbe numero="316" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="verovapaat-tuotot-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="317" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="osingot-ylijaamat-part" class="almond-block info-block">
      <div class="info-block-content">
        Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta, ilmoita ne tiedot OmaVerossa!
      </div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>
  </mat-tab>
  <mat-tab label="3. Kulut" #kulut>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="tiedot-kuluista-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="333" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="334" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="335" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="336" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="342" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [korvausparametrit]="v342Korvausparametrit"></div>
      <div app-veroilmoitus-row-numbe numero="343" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="347" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [korvausparametrit]="v347Korvausparametrit"></div>
      <div app-veroilmoitus-row-numbe numero="344" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="349" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="353" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-read-only numero="tiedot-kuluista-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row remove-num-col"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="poistot-part" [class.info-block]="muutaIrtainta" [class.almond-block]="muutaIrtainta" [class.blue-block]="!muutaIrtainta">

      <div app-veroilmoitus-row-radio numero="muuta-irtainta-omaisuutta" [veroilmoitus]="veroilmoitus" [options]="kyllaEiOptions" direction="vertical" (tallennaArvo)="tallenna($event)" [showPen]="false" [editing]="true" class="hide-num"></div>

      <ng-container *ngIf="muutaIrtainta">
        <div class="info-block-content"> Täytä lomake 62 <a href="https://www.vero.fi/OmaVero" target="_BLANK" style="color: inherit;">OmaVerossa</a>! </div>
        <div app-veroilmoitus-row-numbe numero="338" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="almond-dark-block fat-end-row"></div>
      </ng-container>

      <ng-container *ngIf="!muutaIrtainta">
        <div app-veroilmoitus-row-read-only numero="menojaannos-alussa" [veroilmoitus]="veroilmoitus" format="currency" class="hide-num group-start"></div>
        <div app-veroilmoitus-row-read-only numero="menojaannos-lopussa" [veroilmoitus]="veroilmoitus" format="currency" class="hide-num"></div>
        <div app-veroilmoitus-row-read-only numero="tilikauden-poistot" [veroilmoitus]="veroilmoitus" format="currency" class="hide-num group-end"></div>

        <div app-veroilmoitus-row-numbe #edit800 numero="800" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-start"></div>
        <div app-veroilmoitus-row-numbe #edit801 numero="801" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe #edit802 numero="802" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe #edit804 numero="804" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe #edit805 numero="805" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe #edit806 numero="806" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div class="ehdotusnappula-container group-end">
          <button class="primarybutton" (click)="taytaPoistotEhdotus()" *ngIf="!lukittu" mat-button>Hae ehdotukset</button>
        </div>

        <div app-veroilmoitus-row-numbe numero="338" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" format="currency" class="blue-dark-block fat-end-row"></div>
      </ng-container>

    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="yksityistalouteen-kuuluvan-auton-kaytto-part" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">

      <div explanation>
        Tämän osan tietoja muokataan "Kirjanpidon ulkopuoliset vähennykset" -lomakkeelta. <button mat-button (click)="avaaKirjanpidonUlkopuolisetKulutLomake()" style="text-decoration: underline;">Avaa lomake</button>
      </div>

      <div app-veroilmoitus-row-radio numero="416" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [options]="ajojenSelvitystyyppiOptions" direction="vertical" [showPen]="true" class="group-end"></div>

      <div app-veroilmoitus-row-numbe numero="389" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="km" [desimaaleja]="0" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="390" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="km" [desimaaleja]="0"></div>
      <div app-veroilmoitus-row-numbe numero="419" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="420" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>

      <div app-veroilmoitus-row-numbe numero="391" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start group-end"></div>

      <div app-veroilmoitus-row-numbe numero="392" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="blue-dark-block fat-end-row"></div>

    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">

      <div explanation>
        Tämän osan tietoja muokataan "Kirjanpidon ulkopuoliset vähennykset" -lomakkeelta. <button mat-button (click)="avaaKirjanpidonUlkopuolisetKulutLomake()" style="text-decoration: underline;">Avaa lomake</button>
      </div>

      <table class="group-start group-end table">
        <tr>
          <td class="tili-num-multi">406-410</td>
          <td>6 tunnin - 10 tunnin matkat</td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="406" [veroilmoitus]="veroilmoitus" format="string"></span><span class="end-marking">kpl</span>
          </td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="407" [veroilmoitus]="veroilmoitus" format="currency"></span><span class="end-marking">€/kpl</span>
          </td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="408" [veroilmoitus]="veroilmoitus" format="currency"></span><span class="end-marking">€</span>
          </td>
        </tr>
        <tr>
          <td class="tili-num-multi">401-405</td>
          <td>yli 10 tunnin matkat</td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="401" [veroilmoitus]="veroilmoitus" format="string"></span><span class="end-marking">kpl</span>
          </td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="402" [veroilmoitus]="veroilmoitus" format="currency"></span><span class="end-marking">€/kpl</span>
          </td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="403" [veroilmoitus]="veroilmoitus" format="currency"></span><span class="end-marking">€</span>
          </td>
        </tr>
        <tr>
          <td class="tili-num-multi">411-415</td>
          <td>Ulkomaanmatkat</td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="411" [veroilmoitus]="veroilmoitus" format="string"></span><span class="end-marking">kpl</span>
          </td>
          <td class="table-value-centered">-</td>
          <td class="table-value">
            <span app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="413" [veroilmoitus]="veroilmoitus" format="currency"></span><span class="end-marking">€</span>
          </td>
        </tr>
      </table>

      <div app-veroilmoitus-row-radio [veroilmoitus]="veroilmoitus" numero="kirjanpito-kuluja-vahennetty-kirjanpidossa" [options]="kyllaEiOptions" direction="vertical" (tallennaArvo)="tallenna($event)" [showPen]="false" [editing]="false" class="group-start group-end hide-num">
      </div>

      <div app-veroilmoitus-row-read-only numero="388" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row"></div>

    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part" class="blue-block">

      <div app-veroilmoitus-row-radio [veroilmoitus]="veroilmoitus" numero="tyohuone-vahennys-option" [options]="tyohuonevahennysOptions" direction="vertical" class="hide-num group-start group-end" [showPen]="false" [editing]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu"></div>

      <ng-container *ngIf="tyohuonevahennys">
        <div app-veroilmoitus-row-read-only numero="kaavamainen-tyohuonevahennys" [veroilmoitus]="veroilmoitus" format="currency" class="group-start hide-num"></div>
      </ng-container>

      <ng-container *ngIf="!tyohuonevahennys">
        <div app-veroilmoitus-row-numbe numero="431" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-start"></div>
        <div app-veroilmoitus-row-numbe numero="432" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="433" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true"></div>
        <div app-veroilmoitus-row-numbe numero="386" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      </ng-container>

      <div app-veroilmoitus-row-numbe numero="muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-end hide-num"></div>

      <div app-veroilmoitus-row-numbe numero="354" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="blue-dark-block fat-end-row"></div>

    </div>

    <div app-veroilmoitus-part numero="vahennyskelpoiset-kulut-part" [veroilmoitus]="veroilmoitus" class="gray-block">
      <div app-veroilmoitus-row-numbe numero="355" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part numero="elinkeinotoiminnan-tulos-part" [veroilmoitus]="veroilmoitus" class="gray-dark-block centered-row">
      <div class="block-row">
        <div class="tili-num">358<br />359</div>
        <div class="long-col">Elinkeinotoiminnan tulos</div>
        <div app-veroilmoitus-value-read-only numero="elinkeinotoiminnan-tulos" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="vahennyskelvottomat-kulut-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="365" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="366" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="367" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      <div app-veroilmoitus-row-read-only numero="vahennyskelvottomat-kulut-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row hide-num"></div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>
  </mat-tab>
  <mat-tab label="4. Varallisuuslaskelma" #varallisuus>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="kayttoomaisuus-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="704" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="706" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      <div app-veroilmoitus-row-read-only numero="kayttoomaisuus-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row hide-num"></div>
    </div>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="tavarat-ja-muu-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="708" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="709" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      <div app-veroilmoitus-row-read-only numero="tavarat-ja-muu-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row hide-num"></div>
    </div>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="rahoitusomaisuus-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="711" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="714" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="716" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      <div app-veroilmoitus-row-read-only numero="rahoitusomaisuus-yhteensa" [veroilmoitus]="veroilmoitus" format="currency" class="blue-dark-block fat-end-row hide-num"></div>
    </div>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="elinkeinotoiminnan-varat-part" class="gray-block">
      <div app-veroilmoitus-row-numbe numero="718" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="elinkeinotoimininnan-velat-part" class="blue-block">
      <div app-veroilmoitus-row-numbe numero="749" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="750" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency"></div>
      <div app-veroilmoitus-row-numbe numero="747" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-end"></div>
      <div app-veroilmoitus-row-numbe numero="725" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="blue-dark-block fat-end-row"></div>
    </div>

    <div app-veroilmoitus-part *ngIf="kaksinkertainenKirjanpito" numero="elinkeinotoiminnan-nettovarallisuus-part" class="gray-dark-block centered-row">
      <div class="block-row">
        <div class="tili-num">733<br />734</div>
        <div class="long-col">Elinkeinotoiminnan nettovarallisuus</div>
        <div app-veroilmoitus-value-read-only [veroilmoitus]="veroilmoitus" numero="elinkeinotoiminnan-nettovarallisuus" [veroilmoitus]="veroilmoitus" format="currency" class="short-col currency"></div>
        <div class="end-marking">€</div>
      </div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="toimintavaraus-ja-palkat-part" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">
      <div app-veroilmoitus-row-numbe numero="871" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" class="group-start"></div>
      <div app-veroilmoitus-row-numbe numero="394" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="vaatimus-tappion-vahentamisesta-part" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">
      <div app-veroilmoitus-row-numbe numero="373" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="currency" [showPen]="false" [editing]="true" class="group-start group-end"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="paaomatulon-enimmaismaara-part" class="blue-block">
      <div app-veroilmoitus-row-radio [veroilmoitus]="veroilmoitus" numero="395" [options]="ctrl395Options" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" [showPen]="false" [editing]="true"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="jaetaan-puolison-kesken-part" class="blue-block" [optional]="true" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu">
      <div app-veroilmoitus-row-numbe numero="369" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="percentage" [desimaaleja]="0" [showPen]="false" [editing]="true" class="group-start"></div>
      <div app-veroilmoitus-row-read-only numero="370" [veroilmoitus]="veroilmoitus" format="percentage"></div>
      <div app-veroilmoitus-row-numbe numero="371" [veroilmoitus]="veroilmoitus" (tallennaArvo)="tallenna($event)" [lukittu]="lukittu" format="percentage" [desimaaleja]="0" [showPen]="false" [editing]="true"></div>
      <div app-veroilmoitus-row-read-only numero="372" [veroilmoitus]="veroilmoitus" format="percentage" class="group-end"></div>
    </div>

    <div app-veroilmoitus-part [veroilmoitus]="veroilmoitus" numero="arvopaperit-ja-luovutusvoitot-part" class="almond-block info-block">
      <div class="info-block-content">
        Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!
      </div>
    </div>

    <button class="primarybutton" (click)="jatka()" *ngIf="!lukittu" style="margin-top: 20px; float: right;" mat-button>Jatka</button>
  </mat-tab>
  <mat-tab label="5. Lähetä">
    <ng-content></ng-content>
  </mat-tab>
</mat-tab-group>