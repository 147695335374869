<!-- <div>
  Käytettävä valmiusprosenttialgoritmi
  <mat-radio-group [formControl]="valmiusprosenttiAlgoFormControl" style="margin-left: 10px;">
    <mat-radio-button value="v">Vanha</mat-radio-button>
    <mat-radio-button value="u" style="margin-left: 10px">Uusi</mat-radio-button>
  </mat-radio-group>
</div> -->

<div style="display: flex; flex-flow: column; align-items: start;">
  <div>
    Sarakkeet:
    <button *ngFor="let sarake of kaikkiSarakkeetObservable | async" (click)="muokkaaSaraketta(sarake)" [class.dark-gray-text]="!sarake.aktiivinen" [class.light-blue-text]="sarake.aktiivinen" mat-button>{{ sarake.nimi }}</button>
    <button class="light-blue-text" mat-button (click)="lisaaSarake()">
      <mat-icon>add</mat-icon> Lisää sarake
    </button>
  </div>
  <mat-paginator style="margin-left: -10px;" [class.hidden]="(pageSizeObservable | async) === 9999999" [pageSize]="pageSizeObservable | async" [pageSizeOptions]="[250, 500, 1000, 10000, 9999999]"></mat-paginator>
</div>

<table class="a-l-table" matSort matSortActive="nimi" matSortDirection="asc" [style.min-width]="minwidthObservable | async">
  <thead class="light-blue-text">
    <tr class="a-l-r-tr">
      <th mat-sort-header="synkronoi" style="width: 50px; max-width: 50px; min-width: 50px; vertical-align: bottom; padding: 0;"></th>
      <th *ngIf="onkoKehittajaObservable | async" mat-sort-header="asiakasId" class="a-l-r-td-eka" style="width: 60px; max-width: 60px; min-width: 60px; vertical-align: bottom; padding: 0;">id</th>
      <th mat-sort-header="hinta" class="a-l-r-td-eka" style="width: 75px; max-width: 75px; min-width: 75px; vertical-align: bottom; padding: 0;">Hinta</th>
      <th mat-sort-header="nimi" class="a-l-r-td-toka" style="min-width: 250px; width: 250px; max-width: 250px; vertical-align: bottom;">Nimi</th>
      <th [mat-sort-header]="sarake.avain" *ngFor="let sarake of aktiivisetSarakkeetObservable | async" class="a-l-r-td-sarake" style="min-width: 75px; width: 75px; max-width: 75px; vertical-align: bottom;">{{sarake.nimi}}</th>
      <th mat-sort-header="yritysmuoto" class="a-l-r-td-kolmas" style="width: 65px; max-width: 65px; min-width: 65px; vertical-align: bottom;">Yritys-<br />muoto</th>
      <th mat-sort-header="alvjakso" class="a-l-r-td-neljas" style="width: 60px; max-width: 60px; min-width: 60px; vertical-align: bottom;">Alv-<br />jakso</th>
      <th *ngFor="let kuukausi of kuukaudetObservable | async" style="width: 50px; max-width: 50px; min-width: 50px; vertical-align: bottom;" class="a-l-r-td-ruutu">
        <span class="light-gray-text" style="font-size: 90%; font-weight: normal;">{{ kuukausi.year }}</span><br />
        <span style="font-size: 190%;">{{ kuukausi.month > 9 ? kuukausi.month : '0' + kuukausi.month }}</span>
        <div style="position: relative; width: 50px;">
          <span [style.width]="kuukausi.valmiusaste + '%'" style="height: 20px; display: block; background-color: rgb(119,215,91);"></span>
          <span style="font-weight: normal; font-size: 80%; position: absolute; top: 0; left: 0; bottom: 0; right: 0; color: rgba(0,0,0,0.87);">
            {{kuukausi.valmiusasteMuotoiltu}}%
          </span>
        </div>
      </th>
      <th mat-sort-header="verotilinsaldo" class="a-l-r-td-viides" style="width: 110px; max-width: 110px; min-width: 110px; vertical-align: bottom; padding: 0;">Verotilin saldo</th>
      <th class="a-l-r-td-kuudes" style="display: table-cell; vertical-align: bottom; min-width: 200px;">Muistiinpanot</th>
    </tr>
  </thead>
  <tbody *ngIf="kuukaudetObservable | async; let kuukaudet">
    <tr *ngFor="let asiakas of asiakkaatObservable | async" (click)="kayttajatJaTositteet(asiakas)" [class]="asiakas.luokat">
      <td style="text-align: center; width: 50px; height: 20px; overflow: hidden;">
        <mat-spinner *ngIf="asiakas.synkronoi" diameter="40"></mat-spinner>
        <img src="/assets/suomifi.svg" title="Suomi.fi -valtuutus puuttuu" style="background-color: rgb(0, 52, 121); display: inline-block; width: 11px; height: 11px; padding: 2px;" *ngIf="asiakas.bsf">
        <i title="Sopimus hyväksymättä" class="fa fa-pencil-square" style="font-size: 20px; padding: 1px; color: red" *ngIf="asiakas.sopimusHyvaksymatta"></i>
        <i title="Palaveri pitämättä" class="fa fa-phone-square" style="font-size: 20px; padding: 1px; color: orange" *ngIf="asiakas.palaveriPitamatta"></i>
        <i title="Pankkiyhteys puuttuu" class="fa fa-chain-broken gray-text" style="font-size: 20px; padding: 1px;" *ngIf="asiakas.pankkiyhteysPuuttuu"></i>
        <i title="Pankkiyhteys ei ole lisätty maksutapoihin" class="fa fa-chain-broken" style="font-size: 20px; padding: 1px; color: orange" *ngIf="asiakas.pankkiyhteysEiOleLisattyMaksutapoihin"></i>
        <i title="Riskiarvio tekemättä!" class="fa fa-user-secret" style="font-size: 20px; padding: 1px;" [style.color]="asiakas.edustajaTunnistamatta ? 'red' : 'black'" *ngIf="asiakas.riskiarvioTekematta"></i>
        <i [title]="'Omaverossa ALV-jakso on ' + asiakas.alv + '!'" class="fa fa-bomb" style="font-size: 25px; padding: 1px; color: black" *ngIf="asiakas.alv && !asiakas.bsf"></i>
        <img src="/assets/ytj-logo.png" title="YTJ-tiedot muuttuneet" style="background-color: transparent; display: inline-block; height: 11px; padding: 2px;" *ngIf="asiakas.ytj">
      </td>
      <td *ngIf="onkoKehittajaObservable | async" class="a-l-r-td-eka">{{asiakas.i}}</td>
      <td class="a-l-r-td-eka">{{asiakas.h}}</td>
      <td class="a-l-r-td-toka">{{asiakas.n}}
        <!-- <table class="testii" *ngIf="asiakas.palluroidenJakauma.length > 0" style="border-spacing: 0; table-layout: fixed; border: 1px solid blue;">
          <tr>
            <th>Kuukausi</th>
            <th>Hinta</th>
            <th>Ilmestynyt</th>
            <th> - </th>
            <th>Alkuperäinen päivätahti</th>
            <th>Eräpäivä</th>
            <th>Päiviä eräpäivään</th>
            <th>Lisäys</th>
          </tr>
          <tr *ngFor="let pallura of asiakas.palluroidenJakauma">
            <td>{{pallura.aika}}</td>
            <td>{{pallura.hinta}}</td>
            <td>{{pallura.palluraIlmestynyt | ldate | async }}</td>
            <td>{{pallura.paiviailmestymisenJaErapaivanValilla}}</td>
            <td>{{pallura.hinta / pallura.paiviailmestymisenJaErapaivanValilla}}</td>
            <td>{{pallura.erapaiva | ldate | async }}</td>
            <td>{{pallura.paiviaErapaivaan}}</td>
            <td>{{pallura.hinta / pallura.paiviaErapaivaan}}</td>
          </tr>
        </table> -->
      </td>
      <td [class]="'a-l-r-td-sarake ' + sarake.luokka" *ngFor="let sarake of asiakas.sarakkeet" (click)="muokkaaSarakeSolua($event, asiakas, sarake)">{{sarake.arvo}}</td>
      <td class="a-l-r-td-kolmas">{{asiakas.yritysmuoto}}</td>
      <td class="a-l-r-td-neljas">{{asiakas.alvIlmoitusjakso}}</td>
      <td class="a-l-r-td-ruutu" *ngFor="let kuukausi of asiakas.kuukaudet" (click)="clickRuutu($event, asiakas, kuukausi)" (contextmenu)="openContextMenu($event, contextMenuTrigger, spanTrigger, asiakas, kuukausi)" [class]="kuukausi.luokat">
        <span class="a-l-r-s-a1">{{kuukausi.vasenYlakulma}}</span>
        <span class="a-l-r-s-a2">{{kuukausi.vasenAlakulma}}</span>
        <span class="a-l-r-s-a3 ion-md-chatboxes"></span>
        <span class="a-l-r-s-a4">{{kuukausi.oikeaAlakulma}}</span>
        <!-- <span class="a-l-r-s-a5"></span> -->
        <span class="a-l-r-s-a6"></span>
      </td>
      <td class="a-l-r-td-viides">
        <div class="a-l-r-td-viides-c">
          <div class="a-l-r-td-viides-h">{{asiakas?.r}}</div>
          <div class="a-l-r-td-viides-s">{{asiakas?.omaverosaldo}}</div>
          <div class="a-l-r-td-viides-m">Huomioitavaa!</div>
        </div>
      </td>
      <td class="a-l-r-td-kuudes" (click)="muokkaaMuistiinpanoja($event, asiakas)">{{asiakas?.m}}</td>
    </tr>
  </tbody>
</table>

<div *ngIf="listauksenSummatiedotObservable | async; let summaustiedot" style="line-height: 50px; color: rgb(185,185,185); font-size: 13px; padding-left: 10px;">
  <span style="padding-right: 20px;">Asiakkaita {{ summaustiedot.asiakkaita }}</span>
  <!-- <span style="padding-right: 20px;">Veloitus {{ summaustiedot.veloitus | lmoney | async }}</span>
  <span>Keskihinta {{ summaustiedot.keskihinta | lmoney | async }}</span> -->
</div>

<div class="table-ladataan" style="width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!(lataaObservable | async) && (asiakkaidenLukumaaraObservable | async) === 0">
  <div style="width: 100%; padding-top: 40px; text-align: center;">
    {{'asiakkaat.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
  </div>
</div>
<div *ngIf="lataaObservable | async" style="position: relative; width: 100%; height: 200px;">
  <div lemon-loading [show]="lataaObservable | async"></div>
</div>

<form style="margin: 2em; display: flex; flex-flow: row wrap;" [formGroup]="latausForm" novalidate>
  <mat-form-field style="margin-right: 2em; min-width: 220px; max-width: 300px; width: 30%;">
    <mat-label>Lataa listalla näkyvien asiakkaiden</mat-label>
    <mat-select formControlName="latauslaajuus">
      <mat-option [value]="1">Laskutusosoitteet</mat-option>
      <mat-option [value]="2">Käyttäjien osoitteet</mat-option>
      <mat-option [value]="3">Kaikki osoitteet</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="lisaa-nappula-container" style="align-self: flex-start;">
    <button mat-button (click)="annaKaikkienKayttajienSahkopostiosoitteet()" type="button" class="primarybutton mat-elevation-z4">
      Lataa tiedot
    </button>
  </div>
</form>

<span #contextMenuTrigger="matMenuTrigger" #spanTrigger [matMenuTriggerFor]="kuukausiMenu" style="position: fixed;"></span>
<mat-menu #kuukausiMenu="matMenu">
  <ng-template matMenuContent let-kuukausi="kuukausi" let-asiakas="asiakas">
    <button mat-menu-item (click)="avaaKuukausistatus($event, asiakas, kuukausi)">Muokkaa yksityiskohtia</button>
    <button mat-menu-item (click)="avaaUudessaIkkunassa($event, asiakas, kuukausi)">Avaa uudessa ikkunassa</button>
  </ng-template>
</mat-menu>