import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { combineLatest, map, Subject, switchMap, takeUntil, tap } from 'rxjs'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSort } from '@angular/material/sort'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FormGroup, FormControl } from '@angular/forms'
import { AjastetunTyonTyyppi, AjastettuTyo, AjastettuTyoService } from 'app/_jaettu/eraajot/ajastettu/ajastetut-tyot.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { MatDialog } from '@angular/material/dialog'
import { AjastetutTyotDataDialog } from 'app/yllapito/ajastetut-tyot/ajastetut-tyot-data.dialog'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { MatPaginator } from '@angular/material/paginator'

interface AjastetutTyotForm {
  tyyppi: FormControl<string>,
  tulossaVaiHistoria: FormControl<'tulossa' | 'historia'>
}
@Component({
  templateUrl: './ajastetut-tyot.component.html',
  styleUrls: ['./ajastetut-tyot.component.css'],
  providers: []
})
export class AjastetutTyotComponent implements OnInit, OnDestroy, AfterViewInit {

  naytettavatKolumnit: string[] = ['kayttajaAvain', 'ajoaika', 'selite']

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  hakuform: FormGroup<AjastetutTyotForm>

  @ViewChild(MatSort, { static: true }) _sort: MatSort
  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator

  dataSource = new MatTableDataSource<{ data: AjastettuTyo<any>[] }>([])
  private _kirjanpitajaOnDevaaja: boolean

  ajastetunTyonTyyppi: { tyyppi: AjastetunTyonTyyppi, teksti: string }[] = []

  asiakas: Asiakas

  constructor(
    private _firebaseLemonator: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _asiakasService: AsiakasService,
    private _ajastettuTyoService: AjastettuTyoService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(onkoDevaaja => {
      this._kirjanpitajaOnDevaaja = onkoDevaaja
    })

    this.ajastetunTyonTyyppi = this._ajastettuTyoService.getAjastetunTyonTyypit()

    this.hakuform = new FormGroup<AjastetutTyotForm>({
      tyyppi: new FormControl<string>(null),
      tulossaVaiHistoria: new FormControl<'tulossa' | 'historia'>('tulossa')
    })

    const tyyppiControl = this.hakuform.get('tyyppi')
    const tulossaVaiHistoriaControl = this.hakuform.get('tulossaVaiHistoria')

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakas => {
      this.asiakas = asiakas
    })

    combineLatest([
      tyyppiControl.valueChanges,
      this._asiakasService.nykyinenAsiakasAvainObservable,
      tulossaVaiHistoriaControl.valueChanges
    ]).pipe(
      tap(() => this._ladataanService.aloitaLataaminen()),
      switchMap(([tyyppi, asiakas, tulossaVaiHistoria]) => {
        if (!asiakas || !tyyppi || !tulossaVaiHistoria) {
          return []
        }

        const collection = tulossaVaiHistoria === 'tulossa' ? this._ajastettuTyoService.collectionName : this._ajastettuTyoService.collectionNameHistory
        return this._firebaseLemonator.firestoreCollection<AjastettuTyo<any>>(collection)
          .orderBy('ajoaika', 'desc')
          .limit(1000)
          .where('asiakasAvain', '==', asiakas.avain)
          .listen().pipe(
            map(tyot => {
              // Overwrite selite field with standard explanation
              for (const tyo of tyot) {
                tyo.selite = this.ajastetunTyonTyyppi.find(t => t.tyyppi === tyo.tyyppi)?.teksti ?? tyo.selite
              }
              if (tyyppi && tyyppi !== 'all') {
                return tyot.filter(tyo => tyo.tyyppi === tyyppi)
              }
              return tyot
            })
          )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tyot => {
      this.dataSource.data = tyot
      this.dataSource.paginator = this._paginator
      this._ladataanService.lopetaLataaminen()
    })

    tyyppiControl.setValue('all')
    tulossaVaiHistoriaControl.setValue('tulossa')

  }

  ngAfterViewInit() {
    this.dataSource.sort = this._sort
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  katsoTiedot(rivi: AjastettuTyo<any>) {
    if (this._kirjanpitajaOnDevaaja) {
      this._dialog.open(AjastetutTyotDataDialog, { data: rivi })
    }
  }

}
