import { Component, Inject } from '@angular/core'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { HolviTransaction } from '../../../_jaettu-sans-lemonaid-angular/model/holvi-backend'


@Component({
  template: `
<div mat-dialog-content>

  <lemon-dialog-header>
    Tilitapahtuman tiedot
  </lemon-dialog-header>

<div [innerHTML]="data | ljson"></div>

</div>

<div mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</div>`
})
export class HolviTransactionDetailsDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HolviTransaction,
    private dialogRef: MatDialogRef<HolviTransactionDetailsDialog>
  ) {
  }
}
