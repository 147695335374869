import { Component, OnInit, ErrorHandler, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'

import { Timestamp } from '../../_shared-core/model/common'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../_shared-core/service/date.service'

import { YtjAsiakasDataHistoryVersion } from '../../_jaettu-lemonator/model/asiakas'
import { AsiakasUriService } from '../../_jaettu-lemonator/service/asiakas-uri.service'

import { firstValueFrom, Observable, Subject } from 'rxjs'
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

interface DisplayYtjAsiakasData extends YtjAsiakasDataHistoryVersion {
  onkoPostiosoite: boolean
  onkoKayntiosoite: boolean
  tiedotPaivitetty: string
  versioLuotu: string
  versionChangesMap: { [reason: string]: boolean }
}

@Component({
  selector: '[app-asiakas-ytj-tiedot]',
  templateUrl: './ytj-tiedot.component.html',
  styleUrls: ['./ytj-tiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasAsetuksetYtjTiedotComponent implements OnInit {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  selectedYtjAsiakasData: DisplayYtjAsiakasData
  ytjPrevActive: boolean
  ytjNextActive: boolean
  kuittaaMuutoksetNahdyiksiNakyvissaObservable: Observable<boolean>
  private _ytjLatestVersionTimestamp: Timestamp

  constructor(
    private _firebaseLemonator: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _asiakasUriService: AsiakasUriService
  ) { }

  ngOnInit() {

    this.kuittaaMuutoksetNahdyiksiNakyvissaObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        return !!asiakas?.ytjTiedotMuuttuneet
      })
    )

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((prev, curr) => {
        return (
          prev?.avain === curr?.avain &&
          prev?.ytjDataLastSynced?.toMillis() === curr?.ytjDataLastSynced?.toMillis()
        )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async asiakas => {
      if (asiakas) {
        await this._selectDisplayedYtjHistory('none', asiakas.avain)
      }
    })

  }

  public async kuittaaMuutoksetNahdyiksi(event: MouseEvent) {
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    event?.preventDefault()
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    if (asiakas.ytjTiedotMuuttuneet) {
      asiakas.ytjTiedotMuuttuneet = false
      return this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
    }
  }

  selectDisplayedYtjHistory(event: MouseEvent, direction: 'prev' | 'next' | 'none', asiakasAvain: string) {
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    event?.preventDefault()
    return this._selectDisplayedYtjHistory(direction, asiakasAvain)
  }

  private async _selectDisplayedYtjHistory(direction: 'prev' | 'next' | 'none', asiakasAvain: string) {

    this._ladataanService.aloitaLataaminen()

    const ytjTiedotHistoriaCollection = this._asiakasUriService.annaYtjTiedotHistoriaCollection(asiakasAvain)
    let ytjTiedotHistoriaQuery = this._firebaseLemonator.firestoreCollection<YtjAsiakasDataHistoryVersion>(ytjTiedotHistoriaCollection)

    if (direction === 'prev') {
      ytjTiedotHistoriaQuery = ytjTiedotHistoriaQuery.orderBy('versionCreated', 'desc').startAfter(this.selectedYtjAsiakasData.versionCreated).limit(1)
    } else if (direction === 'next') {
      ytjTiedotHistoriaQuery = ytjTiedotHistoriaQuery.orderBy('versionCreated', 'asc').startAfter(this.selectedYtjAsiakasData.versionCreated).limit(1)
    } else {
      ytjTiedotHistoriaQuery = ytjTiedotHistoriaQuery.orderBy('versionCreated', 'desc').limit(1)
    }

    const ytjTiedotHistoria: YtjAsiakasDataHistoryVersion = await ytjTiedotHistoriaQuery.get().then(resp => {
      if (resp.length === 1) {
        return resp[0]
      }
      return null
    }).catch(err => {
      this._errorHandler.handleError(err)
      return null
    })

    if (ytjTiedotHistoria) {

      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
      if (asiakas) {

        if (direction === 'none' && !this._ytjLatestVersionTimestamp) {
          this._ytjLatestVersionTimestamp = ytjTiedotHistoria.versionCreated
        }

        this.ytjPrevActive = ytjTiedotHistoria.previousVersions
        this.ytjNextActive = ytjTiedotHistoria.versionCreated.toMillis() !== this._ytjLatestVersionTimestamp?.toMillis()

        const versionChangesMap = {}
        for (const changeReason of ytjTiedotHistoria.versionChangeReason) {
          versionChangesMap[changeReason] = true
        }

        const a: DisplayYtjAsiakasData = Object.assign(
          {
            onkoPostiosoite: !!(ytjTiedotHistoria.postiosoite?.co || ytjTiedotHistoria.postiosoite?.katu || ytjTiedotHistoria.postiosoite?.lisatiedot || ytjTiedotHistoria.postiosoite?.maa || ytjTiedotHistoria.postiosoite?.postinro || ytjTiedotHistoria.postiosoite?.postitmp || ytjTiedotHistoria.postiosoite?.ulkom),
            onkoKayntiosoite: !!(ytjTiedotHistoria.kayntiosoite?.co || ytjTiedotHistoria.kayntiosoite?.katu || ytjTiedotHistoria.kayntiosoite?.lisatiedot || ytjTiedotHistoria.kayntiosoite?.maa || ytjTiedotHistoria.kayntiosoite?.postinro || ytjTiedotHistoria.kayntiosoite?.postitmp || ytjTiedotHistoria.kayntiosoite?.ulkom),
            versioLuotu: this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(ytjTiedotHistoria.versionCreated), 'fi'),
            tiedotPaivitetty: this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(asiakas.ytjDataLastSynced), 'fi'),
            versionChangesMap: versionChangesMap
          },
          ytjTiedotHistoria
        )
        this.selectedYtjAsiakasData = a
        this._changeDetectorRef.markForCheck()
      }

    }

    this._ladataanService.lopetaLataaminen()

  }
}
