import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core'
import { Router, ActivatedRoute, Data } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, UntypedFormArray } from '@angular/forms'
import { MatInput } from '@angular/material/input'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'

import { Kirjanpitaja, KirjanpitajanKuvanUploadRequest, KirjanpitajanKuvanUploadResponse, KirjanpitajanToimipiste, KirjanpitajaWorkTimeChange } from '../../_jaettu-lemonator/model/kirjanpitaja'

import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajaKopioija } from '../../_angular/service/kirjanpitaja/kirjanpitaja.kopioija'
import { KirjanpitajaComponentData } from '../../_angular/_resolvers/kirjanpitaja.resolve'
import { DateService } from '../../_shared-core/service/date.service'
import { DragAndDropService } from 'app/_jaettu-angular/service/drag-and-drop.service'
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService, UploadData } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjanpitajanToimipisteService } from 'app/_jaettu-lemonator/service/kirjanpitajan-toimipiste.service'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { MatDatepicker } from '@angular/material/datepicker'
import { TyoajanseurantaService } from 'app/_jaettu-lemonator/service/tyoajanseuranta.service'
import { TyoajanseurantaRecalcTyojono } from 'app/_jaettu-lemonator/model/tyoajanseuranta'
import { NumberMonth } from 'app/_shared-core/model/common'
import { DebugService } from 'app/_angular/service/debug.service'

@Component({
  templateUrl: './kirjanpitaja.component.html'
})
export class KirjanpitajaComponent implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) etunimiInput: MatInput
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  loading: boolean = false
  isDragging: boolean = false
  kuvanVirhe = ''
  namename = 'asdf ' + Math.random()
  donePercentageObservable: Observable<number>
  driverObservable: Observable<any>
  uploadTasks: UploadData

  kirjanpitajaUriObservable: Observable<string>
  kirjanpitajaEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  private _workHourRecalcStartMonth: NumberMonth

  form: UntypedFormGroup

  commonError: string = null
  kirjanpitaja: Kirjanpitaja
  kaikkiToimipisteet: KirjanpitajanToimipiste[] = []

  constructor(
    private _errorHandler: ErrorHandler,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjanpitajaKopioija: KirjanpitajaKopioija,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _dndService: DragAndDropService,
    private _firebase: FirebaseLemonator,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _snackbar: MatSnackBar,
    private _kirjanpitajanToimipisteService: KirjanpitajanToimipisteService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _tyoajanseurantaService: TyoajanseurantaService,
    private _debugService: DebugService
  ) {
    this.kirjanpitaja = this._kirjanpitajaKopioija.annaUusiKirjanpitaja()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.kirjanpitajaUriObservable = this._route.data.pipe(
      map((route: { data: KirjanpitajaComponentData }) => {
        if (route?.data?.kirjanpitaja?.avain) {
          return 'kirjanpitajat/' + route?.data?.kirjanpitaja?.avain
        }
        return ''
      })
    )
    this.kirjanpitajaEncodedUriObservable = this.kirjanpitajaUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.kaikkiToimipisteet = this._kirjanpitajanToimipisteService.annaKaikkiToimipisteet()

    // Create form
    this.form = new UntypedFormGroup({
      'etunimi': new UntypedFormControl('', [Validators.required]),
      'sukunimi': new UntypedFormControl('', [Validators.required]),
      'email': new UntypedFormControl('', [Validators.required, this.validateEmail]),
      'puhelin': new UntypedFormControl('', [Validators.pattern(/^[\d ()+-]+$/)]),
      'aloitti': new UntypedFormControl('', [Validators.required]),
      'toimipiste': new UntypedFormControl('', [Validators.required]),
      'workHourSettings': new UntypedFormArray([], FormValidators.vahintaanYksiArrayssaValidator)
    })

    // Bind to changes
    this.form.get('etunimi').valueChanges.subscribe(value => { this.kirjanpitaja.etunimi = this._validationService.processValue(value) })
    this.form.get('sukunimi').valueChanges.subscribe(value => { this.kirjanpitaja.sukunimi = this._validationService.processValue(value) })
    this.form.get('email').valueChanges.subscribe(value => { this.kirjanpitaja.email = this._validationService.processValue(value) })
    this.form.get('puhelin').valueChanges.subscribe(value => { this.kirjanpitaja.puhelin = this._validationService.processValue(value) })
    this.form.get('aloitti').valueChanges.subscribe(value => { this.kirjanpitaja.aloitti = this._validationService.processValue(this._dateService.dateToNumber(value)) })
    this.form.get('toimipiste').valueChanges.subscribe(value => { this.kirjanpitaja.toimipisteAvain = this._validationService.processValue(value) })

    this._route.data.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((data: Data) => {
      const componentData = data.data as KirjanpitajaComponentData
      this.form.reset()
      this.kirjanpitaja = this._kirjanpitajaKopioija.kopioiKirjanpitaja(componentData.kirjanpitaja)
      this.alustaLomakkeenTiedot(this.kirjanpitaja)

      setTimeout(() => {
        if (this.etunimiInput) {
          this.etunimiInput.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 25)
    })


    this._dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(dragging => {
      this.isDragging = dragging
    })

  }

  private validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  private alustaLomakkeenTiedot(kirjanpitaja: Kirjanpitaja) {
    this.form.get('etunimi').setValue(kirjanpitaja.etunimi)
    this.form.get('sukunimi').setValue(kirjanpitaja.sukunimi)
    this.form.get('email').setValue(kirjanpitaja.email)
    this.form.get('puhelin').setValue(kirjanpitaja.puhelin ? kirjanpitaja.puhelin : '')
    this.form.get('aloitti').setValue(this._dateService.numberToDate(kirjanpitaja.aloitti))
    this.form.get('toimipiste').setValue(kirjanpitaja.toimipisteAvain)


    const workHourSettingsArray = this.getWorkHourSettingsFormArray()
    workHourSettingsArray.clear()
    for (const workTimeChange of kirjanpitaja.workTimeSettings || []) {
      const workHourGroup = this._getWorkHourFormGroup(workTimeChange, false)
      workHourSettingsArray.push(workHourGroup)
    }
  }

  peruuta() {
    this._router.navigate(['yllapito', 'kirjanpitajat'])
  }

  async save() {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {


      await this._kirjanpitajaService.paivitaKirjanpitaja(this.kirjanpitaja)

      if (this._workHourRecalcStartMonth) {
        await this._createWorkHourRecalc(this.kirjanpitaja.avain, this._workHourRecalcStartMonth)
      }

    } catch (err: any) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpidolle. Tekninen virhe: "' + err.message + '".'

    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  get etunimi() {
    return this.get('etunimi')
  }

  get sukunimi() {
    return this.get('sukunimi')
  }

  get email() {
    return this.get('email')
  }
  get puhelin() {
    return this.get('puhelin')
  }
  get aloitti() {
    return this.get('aloitti')
  }

  private get(name: string): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }

  tiedostoaLadataan: boolean = false
  tiedostoaKasitellaan: boolean = false


  public fileOver() {
    this._dndService.setDragging(true)
  }

  public fileLeave() {
    this._dndService.setDragging(true)
  }

  // Load start when drag 'n dropped
  public async fileDrop(entries: NgxFileDropEntry[]): Promise<void> {
    this._dndService.setDragging(false)
    this.fileDropTiedostoToKuva(entries)
  }

  // Load start when clicked
  public lataa(event) {
    if (event.target.files) {
      const list: FileList = event.target.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      if (tiedostot.length > 1) {
        this.kuvanVirhe = 'Lataa vain yksi kuva.'
      }
      this.fileDropTiedostoToKuva(tiedostot)
    }
  }

  private _annaMinimiPaivamaara(control: AbstractControl): Date {
    if (control.enabled) {
      const d = new Date()
      d.setDate(1)
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      d.setMilliseconds(1)
      return this._dateService.lisaaKuukausia(d, 1)
    }
    return new Date(1980, 1, 1)
  }

  chosenMonthHandler(normalizedMonth: Date, formGroup: UntypedFormGroup, datepicker: MatDatepicker<Date>) {
    const control = formGroup.get('voimassaAlkaen') as UntypedFormControl
    const minDate = this._annaMinimiPaivamaara(control)
    if (this._dateService.kuukausiaValissa(normalizedMonth, minDate) > -1) {
      control.setValue(normalizedMonth)
      control.markAsTouched()
    }
    datepicker.close()
  }

  getWorkHourSettings(): UntypedFormGroup[] {
    return this.getWorkHourSettingsFormArray().controls as UntypedFormGroup[]
  }

  getWorkHourSettingsFormArray(): UntypedFormArray {
    return this.form.get('workHourSettings') as UntypedFormArray
  }

  deleteWorkHourChange(group: UntypedFormGroup) {
    const formArray = this.getWorkHourSettingsFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.kirjanpitaja.workTimeSettings.splice(index, 1)
    }
  }

  async addWorkHourChange() {
    const formArray = this.getWorkHourSettingsFormArray()

    const emptyChange: KirjanpitajaWorkTimeChange = {
      percentage: null,
      validFrom: this._dateService.dateToNumber(this._dateService.kuukaudenEnsimmainen(this._dateService.lisaaKuukausia(new Date(), 1))),
      changedAt: this._timestampService.now(),
      changedBy: (await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()).uid
    }
    const formGroup = this._getWorkHourFormGroup(emptyChange, true)

    if (!this.kirjanpitaja.workTimeSettings) {
      this.kirjanpitaja.workTimeSettings = []
    }
    this.kirjanpitaja.workTimeSettings.push(emptyChange)
    formArray.push(formGroup)
  }

  private _getWorkHourFormGroup(workHourChange: KirjanpitajaWorkTimeChange, focus: boolean): UntypedFormGroup {

    const paiva = this._dateService.numberToDate(workHourChange.validFrom)
    const workHourGroup = new UntypedFormGroup({
      'validFrom': new UntypedFormControl(paiva, [Validators.required, this._edellinenKuukausiValidator]),
      'percentage': new UntypedFormControl(workHourChange.percentage, [Validators.required, Validators.min(0), Validators.max(100)])
    })

    workHourGroup.get('validFrom').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (value: Date) => {
      workHourChange.validFrom = this._dateService.dateToNumber(value)

      this._tryToSetNewWorkHourRecalcStartMonth(workHourChange)

      workHourChange.changedAt = this._timestampService.now()
      workHourChange.changedBy = (await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()).uid
    })
    workHourGroup.get('percentage').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async value => {
      workHourChange.percentage = value

      this._tryToSetNewWorkHourRecalcStartMonth(workHourChange)

      workHourChange.changedAt = this._timestampService.now()
      workHourChange.changedBy = (await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()).uid
    })

    if (focus) {
      setTimeout(() => {
        this._changeDetectorRef.markForCheck()
      }, 10)
      this._changeDetectorRef.markForCheck()
    }

    return workHourGroup
  }

  async fileDropTiedostoToKuva(tiedostot: NgxFileDropEntry[]) {

    const tiedosto = tiedostot[0]

    const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)

    if (fileEnding !== 'jpg' && fileEnding !== 'jpeg') {
      this.kuvanVirhe = 'Lataa kuva JPG-muodossa.'
    }

    this._ladataanService.aloitaLataaminen()

    const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)
    const tiedostonNimi = this.kirjanpitaja.etunimi.toLowerCase() + '.' + this.kirjanpitaja.sukunimi.toLowerCase() + '.' + this._firebase.firestoreCreateId() + '.' + fileEnding
    const fileDataUrl = await this._tiedostojenLataamisService.getAsDataUrl(file)

    const fileBase64 = fileDataUrl
      .replace('data:', '')
      .replace(/^.+,/, '')

    const request: KirjanpitajanKuvanUploadRequest = {
      kirjanpitajanAvain: this.kirjanpitaja.avain,
      fileName: tiedostonNimi,
      fileBase64: fileBase64
    }

    return this._firebase.functionsCall<KirjanpitajanKuvanUploadRequest, KirjanpitajanKuvanUploadResponse>('kirjanpitajanKuvaUploadAndConvert', request).then(resp => {
      if (!resp || resp.e) {
        this._errorHandler.handleError(new Error(resp ? resp.e : 'unknown-error'))
        this._snackbar.open('Kuvan lataaminen epäonnistui, yritä uudelleen', 'OK', { duration: 5000 })
      } else {
        this._snackbar.open('Kuvan lataaminen onnistui', 'OK', { duration: 5000 })
      }
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })
  }

  private _edellinenKuukausiValidator = (control: UntypedFormControl) => {
    if (control.value) {
      const formArray = this.getWorkHourSettingsFormArray()
      const index = formArray.controls.indexOf(control.parent)
      if (index > 0) {
        const edellinen = formArray.controls[index - 1]
        if (edellinen) {
          const edellinenControl = edellinen.get('validFrom')
          if (edellinenControl && edellinenControl.value) {
            if (this._dateService.kuukausiaValissa(control.value, edellinenControl.value) < 1) {
              return { edellinen: true }
            }
          }
        }
      }
    }
    return null
  }

  private async _createWorkHourRecalc(kirjanpitajaAvain: string, selectedMonth: number) {

    const tyojonoAvain = this._firebase.firestoreCreateId()

    const uri = this._tyoajanseurantaService.getWorkHourRecalculationWorkQueue(kirjanpitajaAvain, tyojonoAvain)
    const data: TyoajanseurantaRecalcTyojono = {
      kirjanpitajaAvain: kirjanpitajaAvain,
      startMonth: selectedMonth
    }
    await this._firebase.firestoreSetData(uri, data)
  }

  private _tryToSetNewWorkHourRecalcStartMonth(workHourChange: KirjanpitajaWorkTimeChange) {

    try {
      const validFromAsNumberMonth = this._dateService.numberDateToNumberMonth(workHourChange.validFrom)
      if (!this._workHourRecalcStartMonth ||
        this._workHourRecalcStartMonth < validFromAsNumberMonth) {

        this._workHourRecalcStartMonth = validFromAsNumberMonth
      }
    } catch (err) {
      /** Catch conversion errors locally, no need to react to those. */
      console.log(err)
    }
  }


}
