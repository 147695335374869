<div class="list-page">

  <app-asiakas-otsikko otsikko="Asetukset, laskutus" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <form [formGroup]="form">

    <div style="display: flex; justify-content: space-between; flex-flow: row; align-items: center; margin-bottom: 10px; margin-top: 40px;">
      <div class="light-blue-text" style="font-size: 22px;">Laskujen toimitus</div>
      <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        Lisää kertalasku
      </button>
    </div>

    <div *ngIf="apixForwardConfigObservable | async let apixForwardConfig else oldLaskutus">
      <div *ngIf="apixForwardConfig.forwardActive || apixForwardConfig.paymentReceiveIsActive else oldLaskutus">
        <div *ngIf="apixForwardConfig.forwardActive">
          Asiakas on tilannut sähköisten laskujen välityksen pankkiin.
          Annettu osoite on:
          <ul>
            <li>Osoite: {{apixForwardConfig.forwardAddress.sahkoinenOsoite}}</li>
            <li>Välittäjä: {{apixForwardConfig.forwardAddress.sahkoinenValittaja}}</li>
          </ul>
          <div *ngIf="apixForwardConfig.forwardLemontreeLaskut">
            Asiakas on tilannut myös Lemon Tree:n laskut välitysosoitteeseen. Lähetystietoja ei voi muokata Lemonatorissa. Jos osoite on väärä, ota yhteys asiakkaaseen.
            <div>
              <mat-checkbox formControlName="estaAlvLaskut">Estä ALV-laskujen lähettäminen</mat-checkbox>
            </div>
          </div>
        </div>
        <div *ngIf="apixForwardConfig.paymentReceiveIsActive">
          Asiakas käyttää sähköisiä Lemonaid-ostolaskuja. Laskut toimitetaan Lemonaidiin osoitteeseen:
          <ul>
            <li>Osoite: {{apixForwardConfig.receiveAddress.sahkoinenOsoite}}</li>
            <li>Välittäjä: {{apixForwardConfig.receiveAddress.sahkoinenValittaja}}</li>
          </ul>
          <mat-checkbox formControlName="estaAlvLaskut">Estä ALV-laskujen lähettäminen</mat-checkbox>
        </div>
      </div>
    </div>

    <ng-template #oldLaskutus>
      <div>
        <mat-radio-group formControlName="spostiVaiEmail">
          <mat-radio-button [value]="'spostilasku'">Sähköpostilasku</mat-radio-button>
          <mat-radio-button [value]="'verkkolasku'">Verkkolasku</mat-radio-button>
        </mat-radio-group>
      </div>

      <div style="margin-top: 10px;" *ngIf="naytaSahkoinenLaskutus else emailLaskutus">
        <div *ngIf="asiakkaanOsoitetiedotPuuttuvat" class="lemon-error">Asiakkaan osoitetiedot puuttuvat. (Mene Asetukset -> Asiakastiedot ja tarkista, että katuosoite, postinumero ja postitoimipaikka on täytetty)</div>
        <div style="max-width: 450px;">
          <!-- <div class="light-blue-text" style="font-size: 20px; margin-bottom: 10px; line-height: 22px; margin-top: 40px;">Sähköinen laskutusosoite</div> -->
          <mat-form-field style="margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'lasku.sposti-dialog.verkkolasku.valittajatunnus' | translate | async }}</mat-label>
            <input type="text" [name]="namename" [matAutocomplete]="operaattoriauto" formControlName="sahkoinenLaskuValittaja" matInput data-lpignore="true" />
            <!-- <mat-error>{{fieldErrors.valittaja}}</mat-error> -->
          </mat-form-field>
          <mat-autocomplete #operaattoriauto="matAutocomplete" [displayWith]="valittajaDisplayFn">
            <mat-option *ngFor="let operaattori of verkkolaskuoperaattorit | async" [value]="operaattori">{{operaattori.tunnus}} {{operaattori.nimi}}</mat-option>
          </mat-autocomplete>

          <mat-form-field style="margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'lasku.sposti-dialog.verkkolasku.osoite' | translate | async }}</mat-label>
            <input type="text" [name]="namename" formControlName="sahkoinenLaskuOsoite" matInput data-lpignore="true" />
            <!-- <mat-error *ngIf="sahkoinenLaskuOsoiteControl?.errors?.required && (sahkoinenLaskuOsoiteControl?.dirty || sahkoinenLaskuOsoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.required' | translate | async}}
                  </mat-error> -->
            <mat-error *ngIf="sahkoinenLaskuOsoite?.errors?.valittaja && (sahkoinenLaskuOsoite?.dirty || sahkoinenLaskuOsoite?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.valittaja' | translate | async}}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-checkbox formControlName="estaAlvLaskut">Estä ALV-laskujen lähettäminen</mat-checkbox>
      </div>
    </ng-template>

    <ng-template #emailLaskutus>
      <div class="light-blue-text" style="font-size: 14px; padding-top: 20px; margin-left: 10px;">Laskun vastaanottajat</div>
      <div style="min-height: 150px; display: flex; flex-direction: column; justify-content: space-between;">
        <div style="min-height: 60px;">
          <mat-chip-grid #vastaanottajaChips>
            <mat-chip-option style="margin-top: 1em; margin-left: 20px;" *ngFor="let vastaanottaja of asiakas.laskunVastaanottajat" [selectable]="false" [removable]="true" (removed)="poistaLaskunVastaanottaja(vastaanottaja)">
              {{vastaanottaja}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-grid>
        </div>
        <!-- {{lisaavastaanottaja?.errors | json}} -->
        <div style="max-width: 400px; margin-left: 20px; margin-top: 10px;">
          <mat-form-field>
            <mat-label>Lisää laskun vastaanottaja</mat-label>
            <input [name]="namename" type="email" autocomplete="kirjanpitotietoarkki" [matChipInputFor]="vastaanottajaChips" (blur)="lisaaLaskunVastaanottaja()" formControlName="lisaavastaanottaja" (matChipInputTokenEnd)="lisaaLaskunVastaanottaja()" matInput data-lpignore="true" />
            <!-- <mat-icon matSuffix (click)="lisaa()">add</mat-icon> -->
            <mat-error *ngIf="lisaavastaanottaja?.errors?.min && (lisaavastaanottaja?.dirty || lisaavastaanottaja?.touched)">
              Lisää vähintään yksi vastaanottaja.
            </mat-error>
            <mat-error *ngIf="lisaavastaanottaja?.errors?.email && (lisaavastaanottaja?.dirty || lisaavastaanottaja?.touched)">
              Sähköpostiosoite on virheellinen.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <!-- <div [class.mat-form-field-invalid]="laskunKieli?.errors?.required" class="light-blue-text" style="font-size: 16px; padding-top: 20px; margin-left: 10px;">Laskun kieli</div>
    <mat-radio-group formControlName="laskunKieli" style="margin-left: 20px;">
      <mat-radio-button [class.red-text]="laskunKieli?.errors?.required && (laskunKieli?.dirty || laskunKieli?.touched)" value="fi">Suomi</mat-radio-button>
      <mat-radio-button [class.red-text]="laskunKieli?.errors?.required && (laskunKieli?.dirty || laskunKieli?.touched)" value="en">Englanti</mat-radio-button>
    </mat-radio-group> -->

    <div class="light-blue-text" style="font-size: 22px; margin-bottom: 0px; line-height: 30px; margin-top: 40px;">Viitenumero</div>
    <div style="max-width: 400px; margin-left: 10px;">
      <mat-form-field>
        <mat-label>Viitenumero</mat-label>
        <input [name]="namename" type="text" formControlName="viitenumero" matInput data-lpignore="true" />
        <mat-hint>Viitenumero luodaan automaattisesti sen puuttuessa</mat-hint>
        <mat-error *ngIf="viitenumero?.errors?.required && (viitenumero?.dirty || viitenumero?.touched)">
          Viitenumero puuttuu
        </mat-error>
        <mat-error *ngIf="viitenumero?.errors?.viitenumero && (viitenumero?.dirty || viitenumero?.touched)">
          Viitenumero on virheellinen
        </mat-error>
      </mat-form-field>
    </div>

    <div class="light-blue-text" style="font-size: 22px; margin-bottom: 0px; line-height: 30px; margin-top: 40px;">Hinnoittelu</div>

    <div style="max-width: 400px; margin-left: 10px; margin-bottom: 30px;">
      <mat-form-field>
        <mat-label>Kuukausihinnan perusteet</mat-label>
        <input [name]="namename" type="text" formControlName="hinnanPerusteet" matInput data-lpignore="true" />
      </mat-form-field>
    </div>

    <!-- valid: {{form.valid}}, invalid: {{form.invalid}} -->
    <div formArrayName="hinnat" style="max-width: 750px;">
      <div *ngFor="let hinta of annaHinnatControllit(); let i = index;" [formGroupName]="i">
        <div style="display: flex; justify-content: space-between;">
          <mat-form-field style="max-width: 120px; margin-left: 10px; margin-right: 10px;">
            <mat-label>Kuukausihinta</mat-label>
            <input #hintaInputti decimalNumberField [name]="namename" formControlName="hinta" matInput data-lpignore="true">
            <mat-error *ngIf="hinta.get('hinta')?.errors?.required && (hinta.get('hinta')?.dirty || hinta.get('hinta')?.touched)">
              Hinta puuttuu.
            </mat-error>
            <mat-error *ngIf="hinta.get('hinta')?.errors?.min && (hinta.get('hinta')?.dirty || hinta.get('hinta')?.touched)">
              Hinta on liian alhainen (vähintään 0,00).
            </mat-error>
            <mat-error *ngIf="hinta.get('hinta')?.errors?.max && (hinta.get('hinta')?.dirty || hinta.get('hinta')?.touched)">
              Hinta on liian suuri (enintään 2000,00).
            </mat-error>
          </mat-form-field>

          <mat-form-field style="max-width: 120px; margin-left: 10px; margin-right: 10px;">
            <mat-label>Voimassa alkaen</mat-label>
            <input [name]="namename" monthPicker type="text" [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" [matDatepicker]="picker" formControlName="voimassaAlkaen" matInput data-lpignore="true" required />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-error *ngIf="hinta.get('voimassaAlkaen')?.errors?.required && (hinta.get('voimassaAlkaen')?.dirty || hinta.get('voimassaAlkaen')?.touched)">
              Kuukausi puuttuu
            </mat-error>
            <mat-error *ngIf="hinta.get('voimassaAlkaen')?.errors?.edellinen && (hinta.get('voimassaAlkaen')?.dirty || hinta.get('voimassaAlkaen')?.touched)">
              Muutos ei voi olla samaan aikaan tai ennen edellistä muutosta.
            </mat-error>
            <mat-error *ngIf="hinta.get('voimassaAlkaen')?.errors?.min && (hinta.get('voimassaAlkaen')?.dirty || hinta.get('voimassaAlkaen')?.touched)">
              Uusi muutos voi alkaa aikaisintaan ensi kuussa.
            </mat-error>
            <mat-error *ngIf="hinta.get('voimassaAlkaen')?.errors?.matDatepickerMin && (hinta.get('voimassaAlkaen')?.dirty || hinta.get('voimassaAlkaen')?.touched)">
              Uusi muutos voi alkaa aikaisintaan ensi kuussa.
            </mat-error>
            <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, hinta, picker)"></mat-datepicker>
          </mat-form-field>

          <mat-form-field style="max-width: 400px; margin-left: 10px; margin-right: 10px;">
            <mat-label>Muutoksen syy</mat-label>
            <input [name]="namename" type="text" formControlName="syy" matInput data-lpignore="true" required />
            <mat-hint *ngIf="hinta.get('syy').enabled">Kerro miksi hinta muuttuu. Jos asiakassuhde loppuu, kerro myös syy lopettamiseen</mat-hint>
            <mat-error *ngIf="hinta.get('syy')?.errors?.required && (hinta.get('syy')?.dirty || hinta.get('syy')?.touched)">
              Muutoksen syy puuttuu
            </mat-error>
          </mat-form-field>

          <button [disabled]="hinta.get('hinta')?.disabled || 1 > i" [class.mat-elevation-z4]="!hinta.get('hinta')?.disabled" mat-icon-button (click)="poistaHintarivi(hinta)" type="button" style="padding:0;" class="secondarybutton">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mat-error *ngIf="annaHinnatFormArray()?.errors?.min && (annaHinnatFormArray()?.dirty || annaHinnatFormArray()?.touched)" style="margin-left: 10px; margin-top: 30px;">
      Ole hyvä ja syötä vähintään yksi hinta.
    </mat-error>

    <mat-error *ngIf="isLastPriceZero && (annaHinnatFormArray()?.dirty || annaHinnatFormArray()?.touched)" style="margin-left: 10px; margin-top: 30px;">
      Huom! Hinnan nollaaminen on tarpeellista vain poikkeustapauksissa.<br />
      Jos sopimus päättyy, menee tauolle tai pöytälaatikkoon, hintaa ei kuulu nollata.
    </mat-error>

  </form>

  <div style="padding-top: 10px; margin-left: 10px; margin-top: 50px; padding-bottom: 1.25em;">
    <button mat-button (click)="lisaaHintarivi()" type="button" class="secondarybutton mat-elevation-z4">
      <mat-icon>add</mat-icon>
      Lisää hinnan muutos
    </button>
  </div>

  <div style="max-width: 400px; display: flex; justify-content: space-evenly; padding-top: 40px; padding-bottom: 60px;">
    <button class="primarybutton" mat-button (click)="save()" type="button" style="padding: 0 18px;">{{'yleiset.tallenna' | translate | async}}</button>
  </div>

  <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 15px;">Asiakkaalle lähetetyt laskut</div>
  <app-laskut></app-laskut>

  <form *ngIf="naytaSuperObservable | async" [formGroup]="reskontratilanneForm">
    <h3>Anna reskontratilanne päivälle</h3>
    <mat-form-field style="max-width: 150px; margin-left: 10px; margin-right: 10px;">
      <mat-label>Päivämäärä</mat-label>
      <input matInput type="text" [name]="namename" [matDatepicker]="reskontratilannePicker" formControlName="reskontratilannePaiva" required>
      <mat-datepicker-toggle matSuffix [for]="reskontratilannePicker"></mat-datepicker-toggle>
      <mat-datepicker #reskontratilannePicker></mat-datepicker>
    </mat-form-field>
    <button mat-button (click)="annaSaldoPaivalle()" type="button" class="secondarybutton mat-elevation-z4">
      Lataa laskujen reskontratilanne
    </button>
  </form>

</div>