import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'

export interface KirjausMuutaPaivamaaraDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  asiakas: Asiakas
}

@Component({
  templateUrl: './kirjaus.muuta-pvm.dialog.html',
  styleUrls: ['./kirjaus.muuta-pvm.dialog.css']
})
export class KirjausMuutaPaivamaaraDialog implements OnInit {

  form: UntypedFormGroup
  namename: string = 'assf' + Math.random()
  ensimmainenPaiva: Date = new Date(2015, 1, 1)
  viimeinenPaiva: Date = new Date()

  private uusiPaivamaara: number = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausMuutaPaivamaaraDialogData,
    private _dialogRef: MatDialogRef<KirjausMuutaPaivamaaraDialog>,
    private _dateService: DateService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _formValidationService: FormValidationService
  ) {

    const asDate = this._dateService.numberToDate(this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p)
    this.form = new UntypedFormGroup({
      'kirjauksenPvm': new UntypedFormControl({ value: asDate, disabled: false }, [Validators.required]),
    })
    this.form.get('kirjauksenPvm').valueChanges.subscribe(value => {
      this.uusiPaivamaara = this._dateService.localDateToNumber(this._dateService.dateToLocalDate(value))
    })

    const ensimmainenTilikausi = this._asiakasJaettuService.annaEnsimmainenTilikausi(data.asiakas)
    if (ensimmainenTilikausi) {
      this.ensimmainenPaiva = this._dateService.localDateToDate(ensimmainenTilikausi.alkaa)
    }

    const viimeinenTilikausi = this._asiakasJaettuService.annaViimeinenTilikausi(data.asiakas)
    if (viimeinenTilikausi) {
      this.viimeinenPaiva = this._dateService.localDateToDate(viimeinenTilikausi.loppuu)
    }

  }

  ngOnInit() {

  }

  muuta() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (!this.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p === this.uusiPaivamaara) {
      this.data.naytettavaKirjaus.uusiPaivamaara = null
      this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this.data.naytettavaKirjaus.kirjaus.p, 'fi')
    } else {
      this.data.naytettavaKirjaus.uusiPaivamaara = this.uusiPaivamaara
      this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this.uusiPaivamaara, 'fi')
    }
    this._dialogRef.close(true)

  }

}
