import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { of as observableOf, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { Kirjanpitaja } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from '../service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajaKopioija } from '../service/kirjanpitaja/kirjanpitaja.kopioija'

export interface KirjanpitajaComponentData {
  kirjanpitaja: Kirjanpitaja
}

@Injectable()
export class KirjanpitajaComponentDataResolve  {

  private existingData: KirjanpitajaComponentData = null

  constructor(
    private kirjanpitajaService: KirjanpitajaService,
    private kirjanpitajaKopioija: KirjanpitajaKopioija
  ) {

  }

  asetaOlemassaolevaData(data: KirjanpitajaComponentData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<KirjanpitajaComponentData> {
    const kirjanpitajanAvain = route.params['kirjanpitajanAvain']
    return this.annaAsiakasObservable(kirjanpitajanAvain).pipe(
      map(kirjanpitaja => {
        const returnValue: KirjanpitajaComponentData = {
          kirjanpitaja: kirjanpitaja
        }
        return returnValue
      })
    )
  }

  private annaAsiakasObservable(kirjanpitajanAvain: string): Observable<Kirjanpitaja> {
    if (kirjanpitajanAvain === 'uusi') {
      return observableOf(this.kirjanpitajaKopioija.annaUusiKirjanpitaja())
    }
    if (this.existingData && this.existingData.kirjanpitaja && this.existingData.kirjanpitaja.avain === kirjanpitajanAvain) {
      return observableOf(this.existingData.kirjanpitaja)
    }
    return this.kirjanpitajaService.annaKirjanpitajaObservable(kirjanpitajanAvain).pipe(
      take(1)
    )
  }

}
