import { FirestoreProvider, FirestoreWriteBatch } from '../../../_jaettu/service/lasku/lasku-tallennus.service'
import { Timestamp } from '../../../_shared-core/model/common'
import { DateService } from '../../../_shared-core/service/date.service'
import { TimestampProvider } from '../../../_shared-core/service/timestamp-provider.interface'
import { PyhapaivatService } from '../../../_jaettu/service/pyhapaiva.service'

export type AjastetunTyonTyyppi = 'asiakkuuden-paattaminen-deaktivoi-kayttajat' | 'asiakkuuden-paattaminen-poista-muut-kuin-tosite-roolit' | 'tilinpaatos-allekirjoittamatta' | 'poytakirja-allekirjoittamatta' | 'pankkiyhteysvaltuutus-loppunut' | 'lopeta-apix-maksujen-vastaanotto'

export interface AjastettuTyo<T> {
  /** Jos tämä on asiakaskohtainen työ, täytä tämä */
  asiakasAvain?: string
  /** Jos tämä on käyttäjäkohtainen työ, täytä tämä. Jos tämä on täytetty, täytä myös asiakasAvain! */
  kayttajaAvain?: string
  /** Työn avain */
  avain: string
  /** täysi polku, johon työ (tyodata propertyn sisältö) siirretään */
  kohde: string
  /** */
  tyodata: T
  /** Milloin työ on ajastettu ajettavaksi */
  ajoaika: Timestamp
  /** Milloin työ ajettiin */
  ajettu: Timestamp | null
  /** Tyyppi suodattamista varten, esim. admin käyttöliittymää varten */
  tyyppi: AjastetunTyonTyyppi
  /** Lisäselite työstä, esim. admin käyttöliittymää varten */
  selite: string
}

export class AjastettuTyoService {

  ajastetunTyonTyypit: { tyyppi: AjastetunTyonTyyppi, teksti: string }[] = [
    { tyyppi: 'asiakkuuden-paattaminen-deaktivoi-kayttajat', teksti: 'Poistaa asiakkaan kaikkien käyttäjien kaikki roolit ja deaktivoi kaikki käyttäjät' },
    { tyyppi: 'asiakkuuden-paattaminen-poista-muut-kuin-tosite-roolit', teksti: 'Lähettää yhteyshenkilöille sähköpostin, joka muistuttaa sopimuksen loppumisesta' },
    { tyyppi: 'tilinpaatos-allekirjoittamatta', teksti: 'Lähettää yhteyshenkilöille SMS-muistutuksen allekirjoittamattomasta tilinpäätöksestä' },
    { tyyppi: 'poytakirja-allekirjoittamatta', teksti: 'Lähettää yhteyshenkilöille SMS-muistutuksen allekirjoittamattomasta yhtiökokouksen pöytäkirjasta' },
    { tyyppi: 'pankkiyhteysvaltuutus-loppunut', teksti: 'Lähettää pankkivaltuutuksen antajalle SMS-muistutuksen pankkiyhteysvaltuutuksen uusimisesta' },
    { tyyppi: 'lopeta-apix-maksujen-vastaanotto', teksti: 'Lopettaa asiakkaan Apixin verkkolaskujen vastaanoton sopimuksen' },
  ]

  constructor(
    private _dateService: DateService,
    private _timestampService: TimestampProvider,
    private _pyhapaivatService: PyhapaivatService
  ) { }

  collectionName = 'tyojono-ajastetut'
  collectionNameHistory = 'tyojono-ajastetut-historia'

  /** Get type and text */
  getAjastetunTyonTyypit() {
    return this.ajastetunTyonTyypit
  }

  /** Ajasta työ, tai ylikirjoita se kokonaisuudessaan jos se on jo olemassa. */
  public ajasta<T>(tyo: AjastettuTyo<T>, firestore: FirestoreProvider, batch: FirestoreWriteBatch) {
    const doc = firestore.annaDoc(this.collectionName + '/' + tyo.avain)
    batch.set(doc, tyo)
  }

  /** Ajastettu työ, tai ylikirjoita se kokonaisuudessaan jos se on jo olemassa. Käynnistyspäivää muutettaan, jotta se olisi AINA arkipäivä klo 12 (UTC). */
  public ajastaJaVarmistaSuomalainenLiikeaAikaJaArkipaiva<T>(tyo: AjastettuTyo<T>, firestore: FirestoreProvider, batch: FirestoreWriteBatch) {

    const ajoaika = this._dateService.timestampToDate(tyo.ajoaika)
    if (!ajoaika) { throw new Error('No ajoaika date present!') }

    if (ajoaika.getUTCHours() < 6) {
      ajoaika.setUTCHours(6)
      ajoaika.setUTCMinutes(0)
      ajoaika.setUTCSeconds(0)
      ajoaika.setUTCMilliseconds(0)
    }
    if (ajoaika.getUTCHours() > 14) {
      ajoaika.setUTCHours(14)
      ajoaika.setUTCMinutes(0)
      ajoaika.setUTCSeconds(0)
      ajoaika.setUTCMilliseconds(0)
    }

    const safeTime = this._pyhapaivatService.annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(ajoaika) // Set date to always be a workday.

    tyo.ajoaika = this._timestampService.dateToTimestamp(safeTime)
    this.ajasta(tyo, firestore, batch)
  }

  /** Poista ajastettu työ */
  public poistaAjastus(ajastettuTyoAvain: string, firestore: FirestoreProvider, batch: FirestoreWriteBatch) {
    const doc = firestore.annaDoc(this.collectionName + '/' + ajastettuTyoAvain)
    batch.delete(doc)
  }

  /** Poista ajastettu työ olemassa olevalla työllä */
  public poistaAjastusTyolla<T>(ajastettuTyo: AjastettuTyo<T>, firestore: FirestoreProvider, batch: FirestoreWriteBatch) {
    this.poistaAjastus(ajastettuTyo.avain, firestore, batch)
  }

}
