import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { MaaService, Maa } from '../_jaettu-angular/service/maa.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'
import { DateService } from '../_shared-core/service/date.service'
import { IbanService } from '../_shared-core/service/iban.service'
import { BicService } from '../_shared-core/service/bic.service'
import { ViitenumeroService } from '../_shared-core/service/viitenumero.service'

import { AsiakasService, LokalisoituYritysmuoto, LokalisoituAlvIlmoitusjakso } from '../_angular/service/asiakas/asiakas.service'
import { AsiakasKopioija } from '../_angular/service/asiakas/asiakas.kopioija'
import { KirjanpitajanTiedot, KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'
import { StringService } from 'app/_shared-core/service/string.service'

import { Subject, ReplaySubject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Kirjanpitaja } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpidonPeruste } from 'app/_jaettu/model/kayttaja'

@Component({
  templateUrl: './asiakas-lisaa.component.html',
  styleUrls: ['./asiakas-lisaa.component.css']
})
export class AsiakasLisaaComponent implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  suodatetutMaat: ReplaySubject<Maa[]> = new ReplaySubject(1)
  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  paivitaValidaatiot: any = null
  minMonth = new Date()
  asiakas: Asiakas = null
  inputname = 'asdfewqrphoki' + Math.random()

  yritysmuodot: LokalisoituYritysmuoto[] = null
  alvIlmoitusjaksot: LokalisoituAlvIlmoitusjakso[] = null
  ytunnusVirhe: boolean = false

  salesTeamMembers: Pick<Kirjanpitaja, 'avain' | 'etunimi' | 'sukunimi'>[] = this._kirjanpitajaService.getHardcodedSalesTeamKirjanpitajat()

  private _saveStarted: boolean

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _asiakasKopioija: AsiakasKopioija,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _snackbar: MatSnackBar,
    private _maaService: MaaService,
    private _dateService: DateService,
    private _ibanService: IbanService,
    private _bicService: BicService,
    private _viitenumeroService: ViitenumeroService,
    private _timestampService: TimestampService,
    private _codeCheckService: CodeCheckService,
    private _stringService: StringService,
    private _kirjanpitajaService: KirjanpitajaService
  ) {

    this.yritysmuodot = this._asiakasService.yritysmuodot
    this.alvIlmoitusjaksot = this._asiakasService.alvIlmoitusjaksot

    const d = new Date()
    d.setDate(1)
    this.minMonth = this._dateService.lisaaKuukausia(d, 1)

    this.asiakas = this._asiakasKopioija.annaUusiAsiakas()
    this.asiakas.hinnat = []
    this.asiakas.hinnat.push({
      hinta: null,
      voimassaAlkaen: this._dateService.dateToLocalDate(this.minMonth),
      muutoksenSyy: 'Aloitushinta',
      muutosPvm: this._timestampService.now()
    })
    this.asiakas.tilikaudet = []
    this.asiakas.tilikaudet.push({
      avain: this._firebase.firestoreCreateId(),
      alkaa: null,
      loppuu: null
    })
    this.asiakas.tilinumerot = []
    this.asiakas.tilinumerot.push({
      iban: null
    })
    this.asiakas.alvIlmoitusjaksot = []
    this.asiakas.alvIlmoitusjaksot.push({
      alvIlmoitusjakso: null,
      muutosPvm: this._timestampService.now(),
      voimassaAlkaen: { year: 1980, month: 1, day: 1 }
    })
    this.asiakas.sopimuskaudet = []
    this.asiakas.sopimuskaudet.push({
      avain: this._firebase.firestoreCreateId(),
      alkaa: this._dateService.currentLocalDate(),
      lisatiedot: null,
      lopettamisenSyy: null,
      lahetaPaattymassaEmail: null,
      lahetaPaattynytEmail: null,
      loppuu: null,
      tositteidenToimitusLoppuu: null,
      tauot: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false
    })
    this.asiakas.uudessaListassa = true
    this.asiakas.uudessaKirjanpitoAppissaAlkaen = this._dateService.localMonthToNumber(this._dateService.currentLocalMonth())

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private minimiKuukausValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    if (control.value) {
      const minDate = new Date()
      if (this._dateService.kuukausiaValissa(control.value, minDate) < 0) {
        return { min: true }
      }
    }
    return null
  }

  private validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  private validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

  voimassaAlkaen: UntypedFormControl = new UntypedFormControl(null, [Validators.required, this.minimiKuukausValidator])
  ngOnInit() {

    this.voimassaAlkaen.setValue(this.minMonth)
    const hinnanPerusteetFormControl = new UntypedFormControl('', [])

    this.form = new UntypedFormGroup({
      // Yhteystiedot
      'nimi': new UntypedFormControl('', [Validators.required]),
      'ytunnus': new UntypedFormControl('', [Validators.required]),
      'katuosoite': new UntypedFormControl('', []),
      'maa': new UntypedFormControl(this.asiakas.maa, []),
      'postinro': new UntypedFormControl('', []),
      'postitoimipaikka': new UntypedFormControl('', []),

      // Kirjanpito
      'yritysmuoto': new UntypedFormControl(null, [Validators.required]),
      'alvIlmoitusjakso': new UntypedFormControl(this.asiakas.alvIlmoitusjaksot[0].alvIlmoitusjakso, [Validators.required]),
      // 'tilikausi':              new FormControl(null,           [ Validators.required ]),
      'tilikausiAlkaa': new UntypedFormControl(null, []),
      'tilikausiLoppuu': new UntypedFormControl(null, []),
      // Pankkiyhteys
      // 'pankki':                 new FormControl(null,           [ Validators.required ] ),
      'iban': new UntypedFormControl(null, [this.ibanValidator]),
      // 'bic':                    new FormControl(null,           [ Validators.required, this.bicValidator ] ),

      // Laskutus
      'lisaavastaanottaja': new UntypedFormControl('', [this.validateEmail]),
      'voimassaAlkaen': this.voimassaAlkaen,
      'hinta': new UntypedFormControl(null, [Validators.required, Validators.min(0.00), Validators.max(2000)]),
      'hinnanPerusteet': hinnanPerusteetFormControl,
      'viitenumero': new UntypedFormControl(null, [this.validateViitenumero]),

      'saleDoneBy': new UntypedFormControl(null, [Validators.required])
    })

    // Bind to changes
    // Yhteystiedot
    this.form.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.nimi = this._validationService.processValue(value) })
    this.form.get('ytunnus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      this.asiakas.ytunnus = this._validationService.processValue(value)
      if (this.asiakas.ytunnus && !this._codeCheckService.isValidYTunnus(this.asiakas.ytunnus)) {
        this.ytunnusVirhe = true
      } else {
        this.ytunnusVirhe = false
      }
    })
    this.form.get('katuosoite').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.katuosoite = this._validationService.processValue(value) })
    this.form.get('maa').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.maa = this._validationService.processValue(value) })
    this.form.get('postinro').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.postinro = this._validationService.processValue(value) })
    this.form.get('postitoimipaikka').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.postitmp = this._validationService.processValue(value) })

    // Kirjanpito
    this.form.get('yritysmuoto').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.yritysmuoto = this._validationService.processValue(value) })
    this.form.get('alvIlmoitusjakso').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.alvIlmoitusjaksot[0].alvIlmoitusjakso = this._validationService.processValue(value) })
    // this.form.get('tilikausi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.tilikaudet[0].kausi = this.validationService.processValue(value) })
    this.form.get('tilikausiAlkaa').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value: Date) => { this.asiakas.tilikaudet[0].alkaa = this._dateService.dateToLocalDate(value) })
    this.form.get('tilikausiLoppuu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value: Date) => { this.asiakas.tilikaudet[0].loppuu = this._dateService.dateToLocalDate(value) })
    // Pankkiyhteys
    // this.form.get('pankki').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.tilinumerot[0].pankki = this.validationService.processValue(value) })
    this.form.get('iban').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {

      const iban = this._stringService.removeAllWhiteSpaces(this._validationService.processValue(value))
      this.asiakas.tilinumerot[0].iban = iban

      // const pankinNimi = this.bicService.annaPankinNimi(iban)
      // const bic = this.bicService.annaBicKoodi(iban)
      // if (pankinNimi) {
      //   this.form.get('pankki').setValue(pankinNimi)
      // }
      // if (bic) {
      //   this.form.get('bic').setValue(bic)
      // }

    })
    // this.form.get('bic').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value =>    { this.asiakas.tilinumerot[0].bic    = this.validationService.processValue(value) })

    // Laskutus
    this.form.get('voimassaAlkaen').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value: Date) => {
      this.asiakas.hinnat[0].voimassaAlkaen = this._dateService.dateToLocalDate(value)
    })
    this.form.get('hinta').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.hinnat[0].hinta = value })
    hinnanPerusteetFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.hinnanPerusteet = this._validationService.processValue(value) })
    this.form.get('viitenumero').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.viitenumero = this._validationService.processValue(value) })
    this.form.get('lisaavastaanottaja').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value: string) => {
      // console.log('suora', value)
      if (value && (value.indexOf(',') !== -1 || value.indexOf(' ') !== -1)) {
        this.lisaaEmail(this.form.get('lisaavastaanottaja'))
      }
    })

    this.saleDoneBy.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { this.asiakas.saleDoneBy = value })

    this._maaService.kaikkiMaatObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(maat => {
      this.suodatetutMaat.next(maat)
    })

    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kirjanpitajanTiedot => {

      const kirjanpitajanAvain = this._getKirjanpitajanAvain(kirjanpitajanTiedot)

      this.asiakas.kasittelija = kirjanpitajanAvain
      this.asiakas.kirjanpitajat = []

      const nyt = this._dateService.dateToLocalDate(new Date())
      nyt.day = 1

      this.asiakas.kirjanpitajat.push({
        kirjanpitajanAvain: kirjanpitajanAvain,
        voimassaoloAlkaa: nyt
      })
      setTimeout(() => {
        this.nimiInput.focus()
        this._changeDetectorRef.markForCheck()
      }, 10)
    })

  }

  private _getKirjanpitajanAvain(kirjanpitajanTiedot: KirjanpitajanTiedot): string {

    // Annika / Isabel
    // { avain: '259AJG2HoJctamvUlUYHIC1uonU2', etunimi: 'Annika', sukunimi: 'Toivonen' },
    // { avain: 'xm5x1jlOjaNoOnOOkHyKdmoU2VG3', etunimi: 'Isabel', sukunimi: 'Rikander' },
    if (
      kirjanpitajanTiedot.uid === '259AJG2HoJctamvUlUYHIC1uonU2' ||
      kirjanpitajanTiedot.uid === 'xm5x1jlOjaNoOnOOkHyKdmoU2VG3') {
      return 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' // Add customers created straight to Holvi list
    }

    // Sales Sanna / Laura, goes to sales list.
    // { avain: 'QL4CBlJTLdQLtbb4k8AURFYeg3I3', etunimi: 'Sanna', sukunimi: 'Vihersaari' },
    // { avain: 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1', etunimi: 'Laura', sukunimi: 'Pajunen' },
    if (
      kirjanpitajanTiedot.uid === 'QL4CBlJTLdQLtbb4k8AURFYeg3I3' ||
      kirjanpitajanTiedot.uid === 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1'
    ) {
      return 't86aMdfTkicMvOiKGGjKm4m3Rwm1' // sales@lemontree.fi
    }

    return kirjanpitajanTiedot.uid  // otherwise keep creator UID

  }

  private ibanValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
    // console.log(c.value, this.ibanService.isValidIban(c.value))
    if (c && c.value && !this._ibanService.isValidIban(c.value)) {
      return { 'iban': true }
    }
    return null
  }

  // private bicValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
  //   // console.log(c.value, this.ibanService.isValidIban(c.value))
  //   if (c && c.value && !this.bicService.validoiBic(c.value)) {
  //     return { 'bic': true }
  //   }
  //   return null
  // }

  async save() {

    if (!this.asiakas || !this.asiakas.laskunVastaanottajat || this.asiakas.laskunVastaanottajat.length < 1) {
      this.form.get('lisaavastaanottaja').setErrors({ 'min': true })
    }

    if (this.form.invalid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this.paivitaValidaatiot = {}
      return
    }

    if (this._saveStarted) {
      return
    }
    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const olemassaOleva = await this._asiakasService.annaAsiakkaatYtunnuksella(this.asiakas.ytunnus).then(vastaus => {
        for (const asiakas of vastaus?.loytyneet || []) {
          if (asiakas.asiakasAvain !== this.asiakas.avain) {
            return asiakas
          }
        }
        return null
      })

      if (olemassaOleva) {
        this._validationService.merkitseKokoLomakeKosketuksi(this.form)
        this.form.get('ytunnus').setErrors({ tupla: olemassaOleva })

        this._ladataanService.lopetaLataaminen()
        this._saveStarted = false
        return
      }

      const tilinumero = this.asiakas.tilinumerot[0]
      if (!tilinumero || !tilinumero.iban || !tilinumero.iban.trim()) {
        this.asiakas.tilinumerot = []
      }

      const tilikausi = this.asiakas.tilikaudet[0]
      if (!tilikausi || !tilikausi.alkaa || !tilikausi.loppuu) {
        this.asiakas.tilikaudet = []
      }

      this.asiakas.kirjanpidonPerusteet = [{
        alkaa: null,
        loppuu: null,
        peruste: KirjanpidonPeruste.MAKSU
      }]

      const kirjanpitajanTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      this.asiakas.sopimuskaudet[0].luoja = kirjanpitajanTiedot.uid
      this.asiakas.sopimuskaudet[0].paivittaja = kirjanpitajanTiedot.uid
      this.asiakas.sopimuskaudet[0].luotu = this._timestampService.now()
      this.asiakas.sopimuskaudet[0].paivitetty = this.asiakas.sopimuskaudet[0].luotu

      await this._asiakasService.paivitaAsiakas(this.asiakas, 'paivita-tilikausiin-perustuvat-tiedot', true, false)
      // This must be a separate call due to the way security rules currently work.
      let maksutavatSaveFailed = false
      await this._asiakasService.lisaaDefaultMaksutavatAsiakkaalle(this.asiakas).catch(err => {
        this._errorHandler.handleError(err)
        maksutavatSaveFailed = true
      })
      this._asiakasService.asetaNykyinenAsiakas(this.asiakas)
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
      this._router.navigate(['asiakkaat', this.asiakas.avain, 'asiakastiedot'])
      if (!maksutavatSaveFailed) {
        this._snackbar.open('Asiakas tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'top' })
      } else {
        this._snackbar.open('Asiakas tallennettiin onnistuneesti, mutta oletusmaksutapojen lisääminen epäonnistui.', 'OK', { duration: 5000, verticalPosition: 'top' })

      }
    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
      this._errorHandler.handleError(err)
    }

  }

  pankkiJaIbanTeksti(): string {
    const control = this.form.get('iban')

    if (control.valid && control.value) {
      const iban = control.value
      // console.log(iban)
      const pankinNimi = this._bicService.annaPankinNimi(iban)
      const bic = this._bicService.annaBicKoodi(iban)
      if (pankinNimi || bic) {
        let palautettava = ''
        palautettava += pankinNimi ? pankinNimi : 'Pankin nimeä ei pystytty tunnistamaan.'
        palautettava += bic ? ' (' + bic + ')' : ' BIC-koodia ei pystytty tunnistamaan.'
        return palautettava
      } else {
        return 'Pankkia tai BIC koodia ei pystytty tunnistamaan IBAN:ista'
      }
    }
    return ''
  }

  get katuosoite() {
    return this.get('katuosoite')
  }

  get nimi() {
    return this.get('nimi')
  }

  get ytunnus() {
    return this.get('ytunnus')
  }

  get postinro() {
    return this.get('postinro')
  }

  get postitoimipaikka() {
    return this.get('postitoimipaikka')
  }

  get maa() {
    return this.get('maa')
  }

  get hinta() {
    return this.get('hinta')
  }

  get lisaavastaanottaja() {
    return this.get('lisaavastaanottaja')
  }

  get yritysmuoto() {
    return this.get('yritysmuoto')
  }

  get alvIlmoitusjakso() {
    return this.get('alvIlmoitusjakso')
  }

  get tilikausi() {
    return this.get('tilikausi')
  }

  get tilikausiAlkaa() {
    return this.get('tilikausiAlkaa')
  }

  get tilikausiLoppuu() {
    return this.get('tilikausiLoppuu')
  }

  get iban() {
    return this.get('iban')
  }

  get viitenumero() {
    return this.get('viitenumero')
  }

  get hinnanPerusteet() {
    return this.get('hinnanPerusteet')
  }

  get saleDoneBy() {
    return this.get('saleDoneBy')
  }

  private get(name: string): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }

  name(): string {
    return '' + Math.random()
  }

  lisaaLaskunVastaanottaja() {
    const field = this.form.get('lisaavastaanottaja')
    field.updateValueAndValidity()
    this.lisaaEmail(field)
  }

  private lisaaEmail(field: AbstractControl) {
    // console.log('raaka', field.value)
    if ((field.value || '').trim()) {
      let arvo = field.value.trim()
      arvo = arvo.replace(/,/g, '').trim()
      // console.log('kasitelty', arvo)
      if (!new UntypedFormControl(arvo, [Validators.email]).errors) {
        this.asiakas.laskunVastaanottajat.push(arvo)
        field.setValue('')
      }
    }
  }

  poistaLaskunVastaanottaja(vastaanottaja: string): void {
    const index = this.asiakas.laskunVastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.asiakas.laskunVastaanottajat.splice(index, 1)
    }
  }

}
