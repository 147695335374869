<form [formGroup]="laskuForm" novalidate autocomplete="kirjanpitoaineistoarkki" style="width: 100%;">

  <div class="blue-background">
    <div style="margin-left: auto; margin-right: auto">

      <div class="ylapalkki">

        <button class="sulje" mat-button (click)="peruuta()" type="button">
          <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
        </button>

        <app-lasku-ylaosan-dropparit style="width: 100%;" [parent]="laskuFormAsJaettu"></app-lasku-ylaosan-dropparit>

      </div>

    </div>
  </div>

  <div style="margin-left: auto; margin-right: auto;">

    <div class="infoboksi">
      <div *ngIf="juurilasku.avain != kasiteltava.avain" style="text-align: center;">{{ 'lasku.muokkaus-info-teksti' | translate | async }}</div>
    </div>

    <div class="lasku-container">
      <div class="vasenp">
        <div class="ylaosantiedot">
          <div class="ylaosavasen" style="padding-bottom: 15px;">
            <mat-checkbox formControlName="lahetystyyppiPalkkapalvelu">Palkkapalvelun lasku</mat-checkbox>
          </div>
          <div class="ylaosaoikea" style="padding-bottom: 15px;"></div>
        </div>
      </div>
      <div class="oikeap"></div>
    </div>

    <div class="lasku-container">

      <div class="vasenp">

        <div class="ylaosantiedot">
          <div class="ylaosavasen">
            <app-lasku-asiakkaan-tiedot [parent]="laskuFormAsJaettu" [laskunTyyppiObservable]="laskunTyyppiObservable" [laskuObservable]="laskuObservable" (asiakasMuuttui)="paivitaValittuAsiakas()" [paivitaValidaatiot]="paivitaValidaatiot"></app-lasku-asiakkaan-tiedot>
          </div>
          <div class="ylaosaoikea">
            <app-lasku-muut-tiedot [parent]="laskuFormAsJaettu" [laskuObservable]="laskuObservable" [paivitaValidaatiot]="paivitaValidaatiot"></app-lasku-muut-tiedot>
          </div>
        </div>

        <!-- {{fieldErrors | json}} -->

        <app-lasku-rivit [parent]="laskuFormAsJaettu" [laskunLiitetiedostotObservable]="laskunLiitetiedostotObservable" [laskunVastaanottajanMaaObservable]="laskunVastaanottajanMaaObservable" [laskunTyyppiObservable]="laskunTyyppiObservable" [laskunTuotteetObservable]="laskunTuotteetObservable" [paivitaValidaatiot]="paivitaValidaatiot"></app-lasku-rivit>

        <div>
          <table style="width: 100%; text-align: right;">
            <tr style="font-size: 12px; color: rgb(153,153,153);">
              <td>
                {{'lasku.yhteensa-veroton' | translate | async}}
              </td>
              <td style="width: 150px; padding-right: 5px;">
                {{ alvitonSumma | lmoney:kasiteltava?.valuutta | async}}
              </td>
            </tr>
            <tr style="font-size: 12px; color: rgb(153,153,153);">
              <td>
                {{'lasku.alv' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr *ngIf="aiemminMaksettu" style="font-size: 12px; color: rgb(153,153,153);">
              <td>
                {{'lasku.yhteensa-verollinen' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma + alvitonSumma | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr *ngIf="aiemminMaksettu" style="font-size: 12px; color: rgb(153,153,153);">
              <td>
                {{'lasku.aiemmin-maksettu-tuote' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ aiemminMaksettu | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr style="font-size: 16px;" class="light-blue-text">
              <td>
                {{'pdf-perinteinen.maksettava-yhteensa' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma + alvitonSumma + aiemminMaksettu | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 4em;">
          {{'lasku.validation.asetukset.oletuksia-print' | translate | async}}
        </div>

        <div style="margin-top: 4em; margin-bottom: 4em; text-align: center;">
          <button *ngIf="lahetaEmailinaObservable | async" class="primarybutton" mat-button (click)="saveAndSendEmail()" type="button" style="margin-right: .5em; margin-left: .5em; margin-top: 1em;">
            <span>{{'lasku.spostilahetys' | translate | async}}</span>
          </button>
          <button *ngIf="lahetaSahkoisenaObservable | async" class="primarybutton" mat-button (click)="saveAndSendElectronic()" type="button" style="margin-right: .5em; margin-left: .5em; margin-top: 1em;">
            <span>Lähetä verkkolaskuna</span>
          </button>
        </div>

      </div>

      <div class="oikeap">
        <div style="margin: 14px 0; font-size: 15px;" class="light-blue-text">{{'lasku.esikatselu.otsikko' | translate | async}}</div>
        <lasku-pdf-esikatselu-perinteinen [tiedot]="esikatselutiedot | async"></lasku-pdf-esikatselu-perinteinen>
      </div>

    </div>

  </div>

</form>