import { Component, Inject, OnInit } from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

export interface KirjanpitoAineistonLatausUrlDialogData {
  url: string
}

@Component({
  templateUrl: './kirjanpito-aineiston-lataus-url.dialog.html'
})
export class KirjanpitoAineistonLatausUrlDialog implements OnInit {

  public url = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitoAineistonLatausUrlDialogData,
    private _dialogRef: MatDialogRef<KirjanpitoAineistonLatausUrlDialog>,
    private _clipboard: Clipboard,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.url = this._data.url
  }

  toClipboard() {
    if (this._clipboard.copy(this.url)) {
      this._snackbar.open('Linkki kopioitiin', null, { duration: 1500 })
    }
  }

}
