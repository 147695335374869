import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjauksenJaksotuspyynto, KirjauksenTallennusvastaus, KirjauksienLuontityyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { MUU_MAKSUTAPA_ID } from 'app/_jaettu/model/tosite'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

export interface KirjausJaksotaDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  maksutavat: AsiakkaanMaksutapa[]
  asiakas: Asiakas
}

@Component({
  templateUrl: './kirjaus.jaksota.dialog.html'
})
export class KirjausJaksotaDialog implements OnInit {

  form: UntypedFormGroup
  namename: string = 'assoiuhgfdetryiknvdwaqwsdfghjlpobxf' + Math.random()
  kaytettavatMaksutavat: AsiakkaanMaksutapa[]
  ensimmainenPaiva: Date = new Date(2015, 1, 1)
  viimeinenPaiva: Date = new Date()
  virhe: string = ''

  private _paivamaara: number = null
  private _maksutapa: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausJaksotaDialogData,
    private _dialogRef: MatDialogRef<KirjausJaksotaDialog>,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _ladataanService: LadataanService,
    private _firebase: FirebaseLemonator
  ) {

    const riviVirhe = this.data.naytettavaKirjaus.naytettavatKirjausrivit.find(naytettavaRivi => {
      return naytettavaRivi.tilivirhe ||
        naytettavaRivi.debetvirhe ||
        naytettavaRivi.kreditvirhe ||
        naytettavaRivi.alvvirhe
    })

    if (riviVirhe || this.data.naytettavaKirjaus.kirjauksenVirhe) {
      this.virhe = 'Kirjausta ei voi jaksottaa, koska se erottaa tai on muutoin virheellinen.'
    } else if (this.data.naytettavaKirjaus.tallentamattomiaMuutoksia) {
      this.virhe = 'Kirjausta ei voi jaksottaa, koska siinä on tallentamattomia muutoksia.'
    } else if (this.data.naytettavaKirjaus.kirjaus.jaksotus?.alkuperainen === this.data.naytettavaKirjaus.kirjaus.avain) {
      this.virhe = 'Kirjausta ei voi jaksottaa, koska se on jo jaksotettu.'
    } else if (
      this.data.naytettavaKirjaus.kirjaus.jaksotus?.jaksotus === this.data.naytettavaKirjaus.kirjaus.avain ||
      this.data.naytettavaKirjaus.kirjaus.jaksotus?.jaksotuksenVastakirjaus === this.data.naytettavaKirjaus.kirjaus.avain
    ) {
      this.virhe = 'Kirjausta ei voi jaksottaa, koska se on itsessään jaksotuskirjaus.'
    }

    this.kaytettavatMaksutavat = this.data.maksutavat?.filter(mt => {
      return mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE ||
        mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE
    }) || []

    const kirjauksenPaivamaara = this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p
    const asDate = this._dateService.numberToDate(kirjauksenPaivamaara)
    this.form = new UntypedFormGroup({
      'kirjauksenPvm': new UntypedFormControl({ value: asDate, disabled: false }, [Validators.required]),
      'maksutapa': new UntypedFormControl({ value: this.data.naytettavaKirjaus.kirjaus.maksutavanTunniste, disabled: false }, [Validators.required])
    })
    this.form.get('kirjauksenPvm').valueChanges.subscribe(value => {
      this._paivamaara = this._dateService.localDateToNumber(this._dateService.dateToLocalDate(value))
    })
    this.form.get('maksutapa').valueChanges.subscribe(value => {
      this._maksutapa = value
    })

    const muut = this.kaytettavatMaksutavat.find(mt => mt.tunniste === MUU_MAKSUTAPA_ID)
    if (muut) {
      this.form.get('maksutapa').setValue(muut.tunniste + '')
    }

    const ensimmainenTilikausi = this._asiakasJaettuService.annaEnsimmainenAvoinTilikausi(data.asiakas)
    if (ensimmainenTilikausi) {
      this.ensimmainenPaiva = this._dateService.localDateToDate(ensimmainenTilikausi.alkaa)
    }

    const viimeinenTilikausi = this._asiakasJaettuService.annaViimeinenTilikausi(data.asiakas)
    if (viimeinenTilikausi) {
      this.viimeinenPaiva = this._dateService.localDateToDate(viimeinenTilikausi.loppuu)
    }

    const tilikausi = this._asiakasJaettuService.annaTilikausiPaivalleNumber(data.asiakas, kirjauksenPaivamaara)
    const edellinenTilikausi = this._kirjanpitoJaettuService.annaEdellinenTilikausiJosAvoin(data.asiakas.tilikaudet, tilikausi)
    if (edellinenTilikausi) {
      this.form.get('kirjauksenPvm').setValue(this._dateService.localDateToDate(edellinenTilikausi.loppuu))
    }

  }

  ngOnInit() { }

  jaksota() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const pyynto: KirjauksenJaksotuspyynto = {
      asiakasAvain: this.data.asiakas.avain,
      jaksotuksenMaksutapaTunniste: this._maksutapa,
      jaksotuksenPaivamaara: this._paivamaara,
      kirjaus: this.data.naytettavaKirjaus.kirjaus
    }

    this._ladataanService.aloitaLataaminen()

    this._firebase.functionsCall<KirjauksenJaksotuspyynto, KirjauksenTallennusvastaus>('kirjanpitoKirjauksetJaksota', pyynto, { timeout: 540 * 1000 }).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()
    }).catch(err => this._errorHandler.handleError(err))

  }

}
