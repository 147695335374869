import { Component, ErrorHandler, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

import { KirjanpidonLadattavanAineistonTyojonodata, KirjanpidonLadattavaAineisto, KirjanpidonLadattavanAineistonTila, KirjanpidonLadattavanAineistonTyyppi } from '../../_jaettu-lemonator/model/kirjanpito'

import { Asiakas, Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpidonLadattavanAineistonLinkkipyynto, KirjanpidonLadattavanAineistonLinkkivastaus } from '../../_jaettu-lemonator/model/kirjanpito'

import { DateService } from 'app/_shared-core/service/date.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

import { Observable, Subject, of, combineLatest, firstValueFrom } from 'rxjs'
import { takeUntil, take, filter, switchMap, map } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { KirjanpitoAineistonLatausUrlDialogData, KirjanpitoAineistonLatausUrlDialog } from './kirjanpito-aineiston-lataus-url.dialog'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { DebugService } from 'app/_angular/service/debug.service'

interface KirjanpidonLadattavaAineistoJaKayttaja extends KirjanpidonLadattavaAineisto {
  kn: string
}

@Component({
  templateUrl: './kirjanpito-aineiston-lataus.component.html',
  styleUrls: ['./kirjanpito-aineiston-lataus.component.css']
})
export class KirjanpitoAineistonLatausComponent implements OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  latauksetUriObservable: Observable<string>
  latauksetEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  asiakasObservable: Observable<Asiakas>
  ladattavatAineistotObservable: Observable<KirjanpidonLadattavaAineistoJaKayttaja[]>
  name = 'asdfadsfrtyuipmvnbn' + Math.random()

  form: UntypedFormGroup
  alkaa: UntypedFormControl
  loppuu: UntypedFormControl
  kieli: UntypedFormControl

  alkaaMaxDate: Date = null
  loppuuMinDate: Date = null

  valittavatKielet: { nimi: string, tunniste: TuettuKieli }[] = [
    { nimi: 'Suomi', tunniste: 'fi' },
    { nimi: 'Englanti', tunniste: 'en' }
  ]

  constructor(
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _formValidationService: FormValidationService,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _timestampService: TimestampService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.latauksetUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'customers/' + avainTiedot.avain + '/downloadable-accounting-material'
        }
        return ''
      })
    )
    this.latauksetEncodedUriObservable = this.latauksetUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )

    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable

    this.alkaa = new UntypedFormControl(null, [Validators.required])
    this.loppuu = new UntypedFormControl(null, [Validators.required])
    this.kieli = new UntypedFormControl('fi', [Validators.required])
    this.form = new UntypedFormGroup({
      alkaa: this.alkaa,
      loppuu: this.loppuu,
      kieli: this.kieli
    })

    this.alkaa.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(alkupvm => {
      if (alkupvm) {
        this.loppuuMinDate = alkupvm // this._dateService.lisaaKuukausia(alkupvm, 1)
      } else {
        this.loppuuMinDate = null
      }
    })

    this.loppuu.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(loppupvm => {
      if (loppupvm) {
        this.alkaaMaxDate = loppupvm // this._dateService.lisaaKuukausia(loppupvm, -1)
      } else {
        this.alkaaMaxDate = null
      }
    })

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      filter(asiakas => !!asiakas),
      take(1)
    ).subscribe(asiakas => {
      if (asiakas.tilikaudet) {
        const now = this._dateService.currentLocalDate()
        let exactHit: Tilikausi = null
        let closestPrevious: Tilikausi = null
        let closestNext: Tilikausi = null
        for (const tilikausi of asiakas.tilikaudet) {
          if (
            this._dateService.compareLocalDates(tilikausi.alkaa, '<=', now) &&
            this._dateService.compareLocalDates(tilikausi.loppuu, '>=', now)
          ) {
            exactHit = tilikausi
          } else if (this._dateService.compareLocalDates(tilikausi.loppuu, '<', now)) {
            if (closestPrevious === null || this._dateService.compareLocalDates(tilikausi.loppuu, '>', closestPrevious.loppuu)) {
              closestPrevious = tilikausi
            }
          } else if (this._dateService.compareLocalDates(tilikausi.alkaa, '>', now)) {
            if (closestNext === null || this._dateService.compareLocalDates(tilikausi.alkaa, '<', closestNext.alkaa)) {
              closestNext = tilikausi
            }
          }
        }
        if (closestPrevious) {
          this.alkaa.setValue(this._dateService.localDateToDate(closestPrevious.alkaa))
          this.loppuu.setValue(this._dateService.localDateToDate(closestPrevious.loppuu))
        } else if (exactHit) {
          this.alkaa.setValue(this._dateService.localDateToDate(exactHit.alkaa))
          this.loppuu.setValue(this._dateService.localDateToDate(exactHit.loppuu))
        } else if (closestNext) {
          this.alkaa.setValue(this._dateService.localDateToDate(closestNext.alkaa))
          this.loppuu.setValue(this._dateService.localDateToDate(closestNext.loppuu))
        }
      }
    })

    const raakadataObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const listausUri = 'customers/' + asiakas.avain + '/downloadable-accounting-material'
          return this._firebaseLemonaid.firestoreCollection<KirjanpidonLadattavaAineisto>(listausUri)
            .orderBy('c', 'desc')
            .limit(200)
            .listen()
        }
        return of<KirjanpidonLadattavaAineisto[]>(null as KirjanpidonLadattavaAineisto[])
      })
    )

    this.ladattavatAineistotObservable = combineLatest([raakadataObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([raakadata, kirjanpitajatNimitiedotMap]) => {
        if (!raakadata || !kirjanpitajatNimitiedotMap) {
          return []
        }
        for (const raaka of raakadata) {
          const nimitiedot = kirjanpitajatNimitiedotMap.get(raaka.u)
          if (nimitiedot) {
            raaka['kn'] = nimitiedot.etunimi + ' ' + nimitiedot.sukunimi
          } else {
            raaka['kn'] = ''
          }
        }
        return raakadata as KirjanpidonLadattavaAineistoJaKayttaja[]
      })
    )

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async luoAineisto() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const kirjanpitaja = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      filter(kirj => !!kirj)
    ))

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable.pipe(
      filter(asks => !!asks)
    ))

    const alkupvm = this.alkaa.value
    const loppupvm = this.loppuu.value

    const tyojonoAvain = this._firebase.firestoreCreateId()
    const tyojonoEntry: KirjanpidonLadattavanAineistonTyojonodata = {
      asiakasAvain: asiakas.avain,
      ladattavanAineistonAvain: tyojonoAvain,
      lisatty: this._timestampService.now()
    }
    const listausEntry: KirjanpidonLadattavaAineisto = {
      u: kirjanpitaja.uid,
      l: tyojonoAvain,
      a: asiakas.avain,
      s: this._dateService.dateToNumber(alkupvm),
      e: this._dateService.dateToNumber(loppupvm),
      k: this.kieli.value,
      b: KirjanpidonLadattavanAineistonTila.PROSESSOIDAAN,
      c: this._timestampService.now(),
      t: KirjanpidonLadattavanAineistonTyyppi.KIRJANPITAJAN_TILAAMA,
      v: null
    }

    const tyojonoUri = 'tyojono/' + asiakas.avain + '/downloadable-accounting-material-1-create/' + tyojonoAvain
    const listausUri = 'customers/' + tyojonoEntry.asiakasAvain + '/downloadable-accounting-material/' + tyojonoEntry.ladattavanAineistonAvain

    await this._firebaseLemonaid.firestoreSetData(listausUri, listausEntry)
    await this._firebase.firestoreSetData(tyojonoUri, tyojonoEntry)

  }

  async lataaNyt(ladattavaAineisto: KirjanpidonLadattavaAineisto) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)

    const pyynto: KirjanpidonLadattavanAineistonLinkkipyynto = {
      asiakasAvain: asiakas.avain,
      ladattavanAineistonAvain: ladattavaAineisto.l
    }

    this._firebase.functionsCall<KirjanpidonLadattavanAineistonLinkkipyynto, KirjanpidonLadattavanAineistonLinkkivastaus>('kirjanpitoAineistoLataaZipLinkkiLyhyt', pyynto).then(vastaus => {
      if (vastaus.url) {
        window.open(vastaus.url, '_blank')
      }
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
    })

  }

  async luoLatauslinkki(ladattavaAineisto: KirjanpidonLadattavaAineisto) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)

    const pyynto: KirjanpidonLadattavanAineistonLinkkipyynto = {
      asiakasAvain: asiakas.avain,
      ladattavanAineistonAvain: ladattavaAineisto.l
    }

    this._firebase.functionsCall<KirjanpidonLadattavanAineistonLinkkipyynto, KirjanpidonLadattavanAineistonLinkkivastaus>('kirjanpitoAineistoLataaZipLinkkiPitka', pyynto).then(vastaus => {
      this._ladataanService.lopetaLataaminen()
      if (vastaus.url) {
        const data: KirjanpitoAineistonLatausUrlDialogData = {
          url: vastaus.url
        }
        this._dialog.open(KirjanpitoAineistonLatausUrlDialog, { data: data })
      }
    }).catch(err => {
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
    })

  }

  valitseTilikaudenAikavali(tilikausi: Tilikausi) {
    this.alkaa.setValue(this._dateService.localDateToDate(tilikausi.alkaa))
    this.loppuu.setValue(this._dateService.localDateToDate(tilikausi.loppuu))
  }

}
