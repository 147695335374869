<button mat-icon-button *ngIf="showPen && !lukittu" class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<ng-container *ngIf="editing && !lukittu; else readOnly">
  <div class="tili-num">{{ numero }}</div>
  <div class="long-col">{{ label }}<span *ngIf="help" [title]="help" class="ion-ios-help-circle-outline" style="margin-left: 15px; color: darkgray;"></span></div>
  <input [(ngModel)]="value" type="text" (blur)="onBlur()" (input)="onChange()" class="short-col input skip-space-toggle-quick" autocomplete="kirjanpitoaineistoarkkasdfi" data-lpignore="true" />
  <div class="end-marking save-icons-right">
    <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess" class="save-success-text" [@quickInSlowOut]="'in'">
      Summa tallennettiin
    </span>
    <mat-icon *ngIf="saveSuccess" class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    <span *ngIf="saveFailure" class="save-failure-text">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span>
    <mat-icon *ngIf="saveFailure" class="save-failure-icon" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
  </div>
  <div *ngIf="naytaEhdotus" style="flex-basis: 100%;" class="ehdotusnappula-container">
    <button class="primarybutton" (click)="ehdotus()" mat-button>Hae ehdotus</button>
  </div>
</ng-container>

<ng-template #readOnly>
  <div class="tili-num">{{ numero }}</div>
  <div class="long-col">{{ label }}</div>
  <div app-veroilmoitus-value-read-only [numero]="numero" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" [format]="'string'" [log]="log" class="short-col"></div>
  <div class="end-marking"></div>
</ng-template>