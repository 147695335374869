import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { AsiakkaanSopimuskaudenTila } from '../../_jaettu-lemonator/model/asiakas'

@Component({
  templateUrl: './sopimuskausi-tila.ikoni.html',
  selector: '[asiakkaan-sopimuskauden-tila]',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakkaanSopimuskausiTilaIkoni {

  @Input() tila: AsiakkaanSopimuskaudenTila
  @Input() hinta: string

  constructor() { }

}
