import { Injectable } from '@angular/core'
import { EnvironmentType } from 'app/app.environment'
import { environment } from 'environments/environment'

@Injectable()
export class DebugService {

  private _annaLemonaidProjekti() {
    if (
      environment.environment === EnvironmentType.LOCAL_DEV ||
      environment.environment === EnvironmentType.DEV
    ) {
      return 'dev-lemonaid'
    }
    return 'eu-lemonaid'
  }

  private _annaLemonaidUsaProjekti() {
    return 'lemonaid-9ef4a'
    // if (
    //   environment.environment === EnvironmentType.LOCAL_DEV ||
    //   environment.environment === EnvironmentType.DEV
    // ) {
    //   return 'dev-lemonaid'
    // }
    // return 'eu-lemonaid'
  }

  private _annaLemonatorProjekti() {
    if (
      environment.environment === EnvironmentType.LOCAL_DEV ||
      environment.environment === EnvironmentType.DEV
    ) {
      return 'dev-lemonator-web'
    }
    return 'eu-lemonator'
  }

  public createRealtimeDatabaseLinkLemonaid(uri: string): string {
    const projekti = this._annaLemonaidProjekti()
    return 'https://console.firebase.google.com/u/0/project/' + projekti + '/database/' + projekti + '/data/' + this.encodeUri(uri)
  }

  public createRealtimeDatabaseLinkLemonaidUsa(uri: string): string {
    const projekti = this._annaLemonaidUsaProjekti()
    return 'https://console.firebase.google.com/u/0/project/' + projekti + '/database/' + projekti + '/data/' + this.encodeUri(uri)
  }

  public createFirestoreLink(uri: string): string {
    return 'https://console.firebase.google.com/project/' + this._annaLemonatorProjekti() + '/firestore/data/' + this.encodeUri(uri.startsWith('/') ? uri : '/' + uri)
  }

  public createFirestoreLinkLemonaid(uri: string): string {
    return 'https://console.firebase.google.com/project/' + this._annaLemonaidProjekti() + '/firestore/data/' + this.encodeUri(uri.startsWith('/') ? uri : '/' + uri)
  }

  // https://console.firebase.google.com/u/0/project/lemonaid-9ef4a/storage/lemonaid-9ef4a.appspot.com/files~2F2563~2F-MHGrlisGLOflV2oOAXR
  public annaFirebaseBinaryLinkLemonaid(uri: string): string {
    const projekti = this._annaLemonaidProjekti()
    return 'https://console.firebase.google.com/u/0/project/' + projekti + '/storage/' + projekti + '.appspot.com/files~2F' + this.encodeUri(uri)
  }
  public annaFirebaseBinaryLinkLemonaidUsa(uri: string): string {
    return 'https://console.firebase.google.com/u/0/project/lemonaid-9ef4a/storage/lemonaid-9ef4a.appspot.com/files~2F' + this.encodeUri(uri)
  }

  // &pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&forceOnObjectsSortingFiltering=false
  public annaGCBinaryLinkLemonaid(uri: string): string {
    const projekti = this._annaLemonaidProjekti()
    return 'https://console.cloud.google.com/storage/browser/' + projekti + '.appspot.com/' + uri + '?project=' + projekti
  }
  public annaGCBinaryLinkLemonaidUsa(uri: string): string {
    return 'https://console.cloud.google.com/storage/browser/lemonaid-9ef4a.appspot.com/' + uri + '?project=lemonaid-9ef4a'
  }

  private encodeUri(uri: string): string {
    return uri.replace(/\//g, '~2F')
  }

}
