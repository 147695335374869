import { ChangeDetectionStrategy, Component, Input, OnChanges, HostBinding } from '@angular/core'
import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'

@Component({
  selector: '[app-veroilmoitus-row-read-only]',
  templateUrl: './veroilmoitus-row-read-only.component.html',
  styleUrls: ['./veroilmoitus-row.styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusRowReadOnlyComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges {

  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot
  @Input() numero: string
  @Input() korvausparametrit: Korvausparametrit
  @Input() desimaaleja: number = 2
  @Input() format: 'currency' | 'number' | 'string' | 'km' | 'percentage' | 'kpl'
  @Input() log: boolean = false
  @Input() repeatingMetadata: RepeatingMetadata

  @HostBinding('class') classes = 'block-row'

  label: string = ''
  end: string = ''

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero && this.veroilmoitus?.perustiedot?.asiakas) {
      this.label = this._localizeNumero(this.numero, this.veroilmoitus, this.korvausparametrit)
    }
    this.end = this._getEndMarking()
  }

  private _getEndMarking(): string {
    if (this.format === 'currency') {
      return '€'
    }
    if (this.format === 'km') {
      return 'km'
    }
    if (this.format === 'percentage') {
      return '%'
    }
    if (this.format === 'kpl') {
      return 'kpl'
    }
    return ''
  }

}
