<div style="text-align: center; max-width: 630px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      Lisää päiväsaldo manuaalisesti
    </lemon-dialog-header>

    <div style="padding: 0px 8%;">

      <form [formGroup]="bankBalanceForm">
        <div>
          <mat-form-field>
            <mat-label>Saldon päivä</mat-label>
            <input matInput formControlName="bankBalanceDate" [matDatepicker]="dayPicker" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
            <mat-datepicker #dayPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Saldon summa</mat-label>
            <input decimalNumberField matInput formControlName="bankBalanceSum" [numberOfDecimals]="2">
          </mat-form-field>
        </div>
      </form>

    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
    <span style="display: inline-block; width: 8vw; max-width: 4em;"></span>
    <button class="primarybutton" mat-button (click)="addBankBalance()">Lisää</button>
  </div>
</div>