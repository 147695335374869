import { Pipe, PipeTransform } from '@angular/core'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { TilinpaatosTranslationService } from 'app/_jaettu-lemonator/service/tilinpaatos-translation.service'


import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'translatetilinpaatos'
})
export class LemonatorKaannaTilinpaatosPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private tilinpaatosTranslationService: TilinpaatosTranslationService
  ) { }

  transform(avain: string, year: number, parametrit?: any): Observable<string> {
    // console.log(parametrit)
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!avain || !kieli) {
          return ''
        }
        // console.log(avain, kieli)
        return this.tilinpaatosTranslationService.localize(avain, year, kieli, parametrit)
      })
    )
  }

}

// @Pipe({
//   name: 'translatel'
// })
// export class LemonKaannaWithLocalePipe implements PipeTransform {

//   constructor(private lemonTranslationService: LemonTranslationService) { }

//   transform(avain: string, kieli: TuettuKieli, parametrit?: any): string {
//     if (!avain || !kieli) {
//       return ''
//     }
//     return this.lemonTranslationService.lokalisoiKielella(avain, kieli, parametrit)
//   }

// }
