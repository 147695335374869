import { Injectable } from '@angular/core'

import { FirebaseLemonator } from './firebase-lemonator.service'

import { EuVatSpecMap, LaskunAlv, LaskunTyyppi } from '../../_jaettu/model/lasku'

import { AlvLemonatorBaseService } from '../../_jaettu-lemonator/service/alv-lemonator-base.service'
import { AlvMaaritys, KirjanpitotilinAlvTyyppi, MyyntiAlvt, OstoAlvt } from 'app/_jaettu-lemonator/model/kirjanpito'

import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from 'app/_shared-core/service/date.service'

import { MaaService } from '../../_jaettu-angular/service/maa.service'

@Injectable()
export class AlvLemonatorService extends AlvLemonatorBaseService {

  constructor(
    protected _currencyService: CurrencyService,
    protected _maaService: MaaService,
    protected _dateService: DateService,
    private _firebase: FirebaseLemonator,
  ) {
    super(_currencyService, _maaService, _dateService)
  }

  private _mapCache: EuVatSpecMap = null
  public async annaAlvTyypinAlvt(alvTyyppi: KirjanpitotilinAlvTyyppi): Promise<AlvMaaritys[]> {
    return super.annaAlvTyypinAlvt(alvTyyppi, {
      provide: async (): Promise<EuVatSpecMap> => {
        if (!this._mapCache) {
          this._mapCache = await this._firebase.firestoreDoc<EuVatSpecMap>('admin/eu-vat-numbers-optimized').get()
        }
        return this._mapCache
      }
    })
  }

  private _map: Map<string, AlvMaaritys> = null
  public async annaAlvMaaritykset(): Promise<Map<string, AlvMaaritys>> {
    if (!this._map) {
      const etamyyntiPromise = this.annaAlvTyypinAlvt(KirjanpitotilinAlvTyyppi.ETAMYYNTI)
      this._map = new Map<string, AlvMaaritys>()
      MyyntiAlvt.kaikki.forEach(a => this._map.set(a.tunniste, a))
      OstoAlvt.kaikki.forEach(a => this._map.set(a.tunniste, a))
      const etamyynnit = await etamyyntiPromise
      for (const etamyynti of etamyynnit) {
        this._map.set(etamyynti.tunniste, etamyynti)
      }
    }
    return this._map
  }

  public async annaLaskutyypinAlvt(laskunTyyppi: LaskunTyyppi, maaKoodi: string): Promise<LaskunAlv[]> {
    return super.annaLaskutyypinAlvt(laskunTyyppi, maaKoodi, {
      provide: async (): Promise<EuVatSpecMap> => {
        if (!this._mapCache) {
          this._mapCache = await this._firebase.firestoreDoc<EuVatSpecMap>('admin/eu-vat-numbers-optimized').get()
        }
        return this._mapCache
      }
    })
  }

  public async annaMaaritys(tunniste: string): Promise<AlvMaaritys | null> {
    for (const suspect of MyyntiAlvt.kaikki) {
      if (tunniste === suspect.tunniste) {
        return suspect
      }
    }
    for (const suspect of OstoAlvt.kaikki) {
      if (tunniste === suspect.tunniste) {
        return suspect
      }
    }
    const etamyynnit = await this.annaAlvTyypinAlvt(KirjanpitotilinAlvTyyppi.ETAMYYNTI)
    for (const etamyynti of etamyynnit) {
      if (tunniste === etamyynti.tunniste) {
        return etamyynti
      }
    }
    return null
  }

}
