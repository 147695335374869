import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ViewChild, ElementRef, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { DateService } from '../../../../_shared-core/service/date.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { AsiakasUriService } from '../../../../_jaettu-lemonator/service/asiakas-uri.service'
import { KirjanpitajaService } from '../../../../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { Asiakas, DownloadRahanpesunSelvitysTiedostoRequest, DownloadRahanpesunSelvitysTiedostoResponse, RahanpesunSelvitys, RahanpesunSelvitysTiedosto } from '../../../../_jaettu-lemonator/model/asiakas'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of as observableOf, Subject } from 'rxjs'
import { switchMap, map, takeUntil, distinctUntilChanged } from 'rxjs/operators'

import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'


interface RahanpesunSelvitysPaivittajalla extends Pick<RahanpesunSelvitys, 'avain' | 'teksti' | 'tiedostot'> {
  paivittajanNimi: string
  paivitettyString: string
}

@Component({
  selector: 'app-rahanpesun-selvitykset',
  templateUrl: './rahanpesun-selvitykset.component.html',
  styleUrls: ['../tuntemistiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasAsetuksetRahanpesunSelvityksetComponent implements OnInit, OnDestroy {

  @Input() set asiakas(asiakas: Asiakas) {
    this._asiakasSubject.next(asiakas)
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  commonError: string

  namename = 'asdf ' + Math.random()

  lisaaSelvitysAktivoitu: boolean = false
  private _asiakasSubject: BehaviorSubject<Asiakas> = new BehaviorSubject(null)
  asiakasObservable: Observable<Asiakas> = this._asiakasSubject.asObservable()

  rahanpesunSelvityksetObservable: Observable<RahanpesunSelvitysPaivittajalla[]>
  private _cachedSelvitysAvain: string
  cachedSelvitysTiedostotMetadata: RahanpesunSelvitysTiedosto[] = []

  constructor(
    private _firebase: FirebaseLemonator,
    private _dateService: DateService,
    private _asiakasUriService: AsiakasUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _timestampService: TimestampService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      'teksti': new UntypedFormControl(null),
    })

    this.rahanpesunSelvityksetObservable = combineLatest([this.asiakasObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      takeUntil(this._ngUnsubscribe),
      switchMap(([asiakas, kirjanpitajienNimitiedotMap]) => {
        if (asiakas) {
          return this._firebase.firestoreCollection<RahanpesunSelvitys>(this._asiakasUriService.annaRahanpesunSelvitysCollection(asiakas.avain)).listen().pipe(
            distinctUntilChanged((prev, curr) => prev?.length === curr?.length),
            map(rahanpesunSelvitykset => {
              rahanpesunSelvitykset.sort((a, b) => a.paivitetty.toMillis() - b.paivitetty.toMillis())
              const rahanpesuSelvitysPaivittajalla: RahanpesunSelvitysPaivittajalla[] = []

              for (const selvitys of rahanpesunSelvitykset) {
                const kirjanpitaja = kirjanpitajienNimitiedotMap.get(selvitys.paivittaja)
                rahanpesuSelvitysPaivittajalla.push(this._createRahanpesunSelvitysPaivittajalla(selvitys, kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi))
              }

              return rahanpesuSelvitysPaivittajalla
            })
          )
        }
        return observableOf<RahanpesunSelvitysPaivittajalla[]>([])
      })
    )
  }
  get teksti(): UntypedFormControl {
    return this.form.get('teksti') as UntypedFormControl
  }

  async tallenna() {

    // If no text added or no files uploaded, don't do anything
    if (!this.teksti.value?.trim() &&
      !this.cachedSelvitysTiedostotMetadata?.length) {
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      const asiakas = this._asiakasSubject.value
      const kirjanpitaja = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()

      const selvitysAvain = this._cachedSelvitysAvain || this._firebase.firestoreCreateId()
      const doc = this._asiakasUriService.annaRahanpesunSelvitysUri(asiakas.avain, selvitysAvain)

      const data: RahanpesunSelvitys = {
        avain: selvitysAvain,
        teksti: this.teksti.value?.trim() || null,
        tiedostot: this.cachedSelvitysTiedostotMetadata,
        paivittaja: kirjanpitaja?.uid,
        paivitetty: this._timestampService.now()
      }

      await this._firebase.firestoreSetData(doc, data)

      // Reset field values and cache
      this._cachedSelvitysAvain = null
      this.cachedSelvitysTiedostotMetadata = []
      this.teksti.setValue(null)
      this.lisaaSelvitysAktivoitu = false

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open('Tallennus epäonnistui! Ole hyvä ja yritä uudelleen.')
    }

    this._ladataanService.lopetaLataaminen()

  }

  aktivoiLisaaSelvitys() {
    this.lisaaSelvitysAktivoitu = !this.lisaaSelvitysAktivoitu
  }

  removeFileFromCache(tiedosto: RahanpesunSelvitysTiedosto) {
    const tiedostoIndex = this.cachedSelvitysTiedostotMetadata.findIndex(inCache => inCache.avain === tiedosto.avain)
    this.cachedSelvitysTiedostotMetadata.splice(tiedostoIndex, 1)
  }

  uploadFile() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event: Event) {

    const target: HTMLInputElement = event.target as HTMLInputElement
    if (!target?.files?.length) {
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      for (let i = 0; i < target.files.length; i++) {

        const file = target.files.item(i)

        const avain = this._firebase.firestoreCreateId()
        const metadata: RahanpesunSelvitysTiedosto = {
          avain: avain,
          nimi: file.name,
          fileEnding: this._tiedostojenLataamisService.getFileEndingFromFile(file)
        }

        const asiakas = this._asiakasSubject.value

        const selvitysAvain = this._cachedSelvitysAvain ? this._cachedSelvitysAvain : this._firebase.firestoreCreateId()
        this._cachedSelvitysAvain = selvitysAvain
        this.cachedSelvitysTiedostotMetadata.push(metadata)

        const fileNameInStorage = metadata.avain + '.' + metadata.fileEnding
        const storageUri = this._asiakasUriService.annaRahanpesuSelvitysTiedostoStorageUri(asiakas.avain, selvitysAvain, fileNameInStorage)
        const bucket = environment.environment === EnvironmentType.PRODUCTION || environment.environment === EnvironmentType.BETA ? 'rahanpesun-selvitykset-eu' : 'rahanpesun-selvitykset-test'

        await firstValueFrom(this._tiedostojenLataamisService.upload(this._firebase, storageUri, file, bucket).doneObservable)
      }

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = 'Tiedostojen lataus epäonnistui!'
    }
    this._ladataanService.lopetaLataaminen()
  }

  downloadFile(tiedosto: RahanpesunSelvitysTiedosto, selvitysAvain?: string) {

    this._ladataanService.aloitaLataaminen()

    const reqData: DownloadRahanpesunSelvitysTiedostoRequest = {
      asiakasAvain: this._asiakasSubject.value?.avain,
      selvitysAvain: selvitysAvain ?? this._cachedSelvitysAvain,
      tiedosto: tiedosto
    }

    return this._firebase.functionsCall<DownloadRahanpesunSelvitysTiedostoRequest, DownloadRahanpesunSelvitysTiedostoResponse>('rahanpesunSelvitysLiiteDownload', reqData).then(resp => {
      if (!resp || resp.e || !resp.base64File) {
        this._errorHandler.handleError(new Error('Rahanpesun liiteet lataaminen epäonnistui! ' + resp?.e || 'no-response'))
        this._snackbar.open('Liitteen lataus epäonnistui! Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000, verticalPosition: 'bottom' })
      } else {
        this._fileSaverService.saveBase64AsGuessedType(resp.base64File, tiedosto.nimi)
      }
    }).catch(err => {
      this._errorHandler.handleError(err)
      this.commonError = 'Tiedoston lataus epäonnistui!'
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })

  }

  private _createRahanpesunSelvitysPaivittajalla(selvitys: RahanpesunSelvitys, kirjanpitajanNimi: string): RahanpesunSelvitysPaivittajalla {
    return {
      avain: selvitys.avain,
      teksti: selvitys.teksti,
      tiedostot: selvitys.tiedostot,
      paivittajanNimi: kirjanpitajanNimi,
      paivitettyString: this._dateService.muotoilePaivaJaAikaDate(selvitys.paivitetty.toDate(), 'fi')
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }
}
