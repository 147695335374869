<div class="list-page">

  <app-asiakas-otsikko otsikko="Asetukset, sopimukset" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Asiakas: <a [href]="asiakasEncodedUriObservable | async" target="_BLANK">{{asiakasUriObservable | async}}</a></div>

    <div style="margin: 20px;">
      <form [formGroup]="muokkaaAsiakkaanTietojaFormGroup">
        <mat-checkbox formControlName="naytaKayttajilleTervetuloaDialogi" [labelPosition]="'after'">Näytä kaikille käyttäjille Tervetuloa -dialogi</mat-checkbox><br />
        <mat-checkbox formControlName="vaadiKayttajiltaUusiTunnistautuminen" [labelPosition]="'after'">Vaadi kaikilta käyttäjiltä vahva tunnistautuminen</mat-checkbox><br />
        <mat-checkbox formControlName="vaadiPepTietojenPaivittaminen" [labelPosition]="'after'">Vaadi tuntemistietojen (PEP jne.) päivittäminen</mat-checkbox><br />
        <mat-checkbox formControlName="vaadiSopimuksenAllekirjoittaminenUudelleen" [labelPosition]="'after'">Vaadi sopimuksen allekirjoittaminen uudelleen</mat-checkbox><br />
        <button mat-button style="margin-top: 20px;" class="secondarybutton" (click)="muokkaaAsiakkaanAsetuksia()">Tallenna</button>
      </form>
    </div>

  </div>

  <ng-container *ngIf="asiakas.sopimuskaudet?.length else eiKausia">
    <div *ngFor="let sopimuskausi of sopimuskaudetObservable | async; let i = index;" style="margin-left: 20px; max-width: 1200px;">

      <div style="display: flex; justify-content: space-between; margin-bottom: 32px; margin-top: 10px;">
        <mat-card appearance="outlined" style="border-radius: 25px; width: 50%;">
          <mat-card-title>
            <div class="light-blue-text" [class.mat-form-field-invalid]="sopimuskausiError" style="display: flex; justify-content: space-between; font-size: 22px; margin-bottom: 10px; line-height: 30px;">
              <div>Sopimuskausi {{i+1}}</div>
              <div style="font-size: 13px; font-weight: 400; color: black;">
                <!-- <span [style.background-color]=" sopimuskausi.tilanVarikoodi" style="width: 15px; height: 15px; border-radius: 50%; display: inline-block; vertical-align: middle; margin-top: -2px; margin-right: 3px;"></span> -->
                <span asiakkaan-sopimuskauden-tila [tila]="sopimuskausi.tila"></span>
                {{ sopimuskausi.tilanNimi }}
              </div>
            </div>
          </mat-card-title>
          <mat-card-content>

            <table>
              <tr>
                <th style="text-align: left;">Jakson aloituspvm:</th>
                <td>{{ sopimuskausi.kausi.alkaa | lokaalipvm | async }}</td>
                <td></td>
              </tr>

              <ng-container *ngIf="sopimuskausi.hyvaksynnat.length > 0 else eiHyvaksyntaa">
                <tr *ngFor="let hyvaksynta of sopimuskausi.hyvaksynnat">
                  <th style="text-align: left;">Allekirjoitus:</th>
                  <td>{{hyvaksynta.hyvaksynta.pvm | ltimestamp | async}}</td>
                  <td>{{hyvaksynta.hyvaksyja}}</td>
                </tr>
              </ng-container>
              <ng-template #eiHyvaksyntaa>
                <tr>
                  <th style="text-align: left;">Allekirjoitus:</th>
                  <td>Ei vielä allekirjoitettu</td>
                  <td></td>
                </tr>
              </ng-template>
              <tr>
                <th style="text-align: left;">Laskutuksen aloitus:</th>
                <td>{{ sopimuskausi.laskutusAlkaa }}</td>
                <td></td>
              </tr>
              <ng-container *ngIf="sopimuskausi.devaaja">
                <tr>
                  <th style="text-align: left;">Luotu:</th>
                  <td>{{ sopimuskausi.kausi.luotu | ltimestamptime | async }}</td>
                  <td>{{ sopimuskausi.luoja }}</td>
                </tr>
                <tr>
                  <th style="text-align: left;">Päivitetty:</th>
                  <td>{{ sopimuskausi.kausi.paivitetty | ltimestamptime | async }}</td>
                  <td>{{ sopimuskausi.paivittaja }}</td>
                </tr>
              </ng-container>
            </table>

            <button *ngIf="sopimuskausi.naytaPakotaSopimuksenAllekirjoitus" style="margin-top: 15px; margin-bottom: 5px" mat-button (click)="pakotaSopimuksenAllekirjoittaminenUudelleen()" type="button" class="secondarybutton">
              <mat-icon>sync</mat-icon>
              Pakota sopimuksen allekirjoittaminen uudelleen
            </button>

            <button *ngIf="sopimuskausi.naytaPerutaSopimuksenPaattyminen" style="margin-top: 15px; margin-bottom: 5px" mat-button (click)="perutaSopimuksenPaattyminen(sopimuskausi.kausi.avain)" type="button" class="primarybutton">
              <mat-icon>cancel</mat-icon>
              Peruta sopimuksen päättyminen
            </button>

            <div *ngIf="sopimuskausi.tauot.length > 0" style="padding-top: 15px; padding-left: 10px;">
              <div style="font-weight: bold;">Sopimuskausi tauolla</div>
              <div *ngFor="let taukorivi of sopimuskausi.tauot">
                <table>
                  <tr>
                    <td>Aloituspvm:</td>
                    <td colspan="2">{{ taukorivi.tauko.alkaa | lokaalipvm | async }}</td>
                  </tr>
                  <tr>
                    <td>Lopetuspvm:</td>
                    <td colspan="2">{{ taukorivi.tauko.loppuu | lokaalipvm | async }}</td>
                  </tr>
                  <ng-container *ngIf="sopimuskausi.devaaja">
                    <tr>
                      <td>Luotu:</td>
                      <td>{{ taukorivi.tauko.luotu | ltimestamptime | async }}</td>
                      <td>{{ taukorivi.luoja }}</td>
                    </tr>
                    <tr>
                      <td>Päivitetty:</td>
                      <td>{{ taukorivi.tauko.paivitetty | ltimestamptime | async }}</td>
                      <td>{{ taukorivi.paivittaja }}</td>
                    </tr>
                  </ng-container>
                </table>
                <div *ngIf="taukorivi.tauko.muistutuspvm && taukorivi.naytetaankoMuokkaaNappi" style="text-align: left; display: flex; margin-bottom: 15px; padding-left: 30px; max-width: 600px; font-style: italic; margin-top: 10px; margin-bottom: 20px;" class="gray-text">
                  <div>
                    <mat-icon style="margin-left: 5px; margin-right: 5px; font-size: 20px;">info_outline</mat-icon>
                  </div>
                  <div>
                    Tauolla olosta muistutetaan seuraavaksi {{ taukorivi.tauko.muistutuspvm | lokaalipvm | async }}.
                  </div>
                </div>
                <span *ngIf="taukorivi.naytetaankoMuokkaaNappi" (click)="muokkaaTaukoa(sopimuskausi.kausi, taukorivi.tauko, false)" style="color: black; text-decoration: underline; cursor: pointer; display: inline-block; margin-top: 10px;">Muokkaa tauon päivämääriä</span>
              </div>
            </div>

            <div *ngIf="sopimuskausi.poytalaatikot.length > 0" style="padding-top: 15px; padding-left: 10px;">
              <div style="font-weight: bold;">Sopimus pöytälaatikossa</div>
              <div *ngFor="let taukorivi of sopimuskausi.poytalaatikot">
                <table>
                  <tr>
                    <td>Aloituspvm:</td>
                    <td colspan="2">{{ taukorivi.tauko.alkaa | lokaalipvm | async }}</td>
                  </tr>
                  <tr>
                    <td>Lopetuspvm:</td>
                    <td colspan="2">{{ taukorivi.tauko.loppuu | lokaalipvm | async }}</td>
                  </tr>
                  <ng-container *ngIf="sopimuskausi.devaaja">
                    <tr>
                      <td>Luotu:</td>
                      <td>{{ taukorivi.tauko.luotu | ltimestamptime | async }}</td>
                      <td>{{ taukorivi.luoja }}</td>
                    </tr>
                    <tr>
                      <td>Päivitetty:</td>
                      <td>{{ taukorivi.tauko.paivitetty | ltimestamptime | async }}</td>
                      <td>{{ taukorivi.paivittaja }}</td>
                    </tr>
                  </ng-container>
                </table>
                <!-- <div style="text-align: left; display: flex; margin-bottom: 15px; padding-left: 30px; max-width: 600px; font-style: italic; margin-top: 10px; margin-bottom: 20px;" class="gray-text">
                  <div>
                    <mat-icon style="margin-left: 5px; margin-right: 5px; font-size: 20px;">info_outline</mat-icon>
                  </div>
                  <div>
                    Asiakkaan käyttäjien aktiivisuus poistetaan {{ taukorivi.tauko.alkaa | lokaalipvm | async }} aamuyöllä.<br />
                    HUOM! Lemonator EI palauta käyttäjien aktiivisuutta. Palauta aktiivisuus manuaalisesti kun pöytälaatikkokausi päättyy.
                  </div>
                </div> -->
                <span *ngIf="taukorivi.naytetaankoMuokkaaNappi" (click)="muokkaaTaukoa(sopimuskausi.kausi, taukorivi.tauko, false)" style="color: black; text-decoration: underline; cursor: pointer; display: inline-block; margin-top: 10px;">Muokkaa pöytälaattikkojakson päivämääriä</span>
              </div>
            </div>

            <div *ngIf="sopimuskausi.kausi.loppuu">
              <hr style="margin-top: 35px; margin-bottom: 25px;" />
              <table>
                <tr>
                  <th style="text-align: left;">Lopetuspvm:</th>
                  <td>{{ sopimuskausi.kausi.loppuu | lokaalipvm | async }}</td>
                </tr>
                <tr>
                  <th style="text-align: left;">{{ sopimuskausi.syyOtsikko }}:</th>
                  <td>{{ sopimuskausi.syy }}</td>
                </tr>
                <tr>
                  <th style="text-align: left;">Lisätiedot:</th>
                  <td>{{ sopimuskausi.lisatiedot }}</td>
                </tr>
              </table>
            </div>

            <div *ngIf="!sopimuskausi.kausi.loppuu" style="margin-top: 15px; margin-bottom: 10px; text-align: right;">
              <button *ngIf="sopimuskausi.naytaLaitaTauolle" style="margin-right: 15px;" mat-button (click)="laitaPoytalaatikkoon(sopimuskausi.kausi)" type="button" class="secondarybutton">
                <!-- <mat-icon>block</mat-icon> -->
                Laita pöytälaatikkoon
              </button>
              <button *ngIf="sopimuskausi.naytaLaitaTauolle" style="margin-right: 15px;" mat-button (click)="laitaTauolle(sopimuskausi.kausi)" type="button" class="secondarybutton">
                <!-- <mat-icon>block</mat-icon> -->
                Laita tauolle
              </button>
              <button style="padding-left: 8px;" mat-button (click)="paataSopimuskausi(sopimuskausi.kausi)" type="button" class="secondarybutton">
                <mat-icon>block</mat-icon>
                Päätä sopimuskausi
              </button>
            </div>

            <div *ngIf="sopimuskausi.kausi.loppuu" style="margin-top: 15px; margin-bottom: 10px;">
              <span (click)="paataSopimuskausi(sopimuskausi.kausi)" style="color: black; text-decoration: underline; cursor: pointer; display: inline-block; margin-top: 10px;">Muokkaa sopimuksen päättymistä</span>
            </div>

          </mat-card-content>
        </mat-card>

        <div style="display: flex; align-items: flex-end;" *ngIf="sopimuskausi.kausi.loppuu">
          <div style="display: flex; margin-bottom: 15px; padding-left: 30px; max-width: 600px; font-style: italic;" class="gray-text">
            <div>
              <mat-icon style="margin-left: 5px; margin-right: 5px; font-size: 20px;">info_outline</mat-icon>
            </div>
            <div>
              <div *ngIf="!sopimuskausi.onkoPurku">
                <p>
                  Sopimuskausi päättyy {{ sopimuskausi.kausi.loppuu | lokaalipvm | async }}. Asiakkaan Lemonaid-tunnukset
                  suljetaan {{ sopimuskausi.kausi.loppuu | lokaalipvm | async }} lukuun ottamatta aineiston toimitusta joka
                  on auki {{ sopimuskausi.kausi.tositteidenToimitusLoppuu | lokaalipvm | async }} saakka (päivän loppuun asti).
                </p>
                <ng-container *ngIf="sopimuskausi.kausi.lahetaPaattymassaEmail || sopimuskausi.kausi.lahetaPaattynytEmail">
                  Asiakkaalle lähetetään {{ sopimuskausi.kausi.lahetaPaattymassaEmail && sopimuskausi.kausi.lahetaPaattynytEmail ? 'seuraavat sähköpostit' : 'seuraava sähköposti' }} automaattisesti:
                  <div *ngIf="sopimuskausi.kausi.lahetaPaattymassaEmail" style="padding-left: 10px;">- {{sopimuskausi.sopimusPaattyyPianEmailDate | lokaalipvm | async}} Sopimuksesi päättyy pian</div>
                  <div *ngIf="sopimuskausi.kausi.lahetaPaattynytEmail" style="padding-left: 10px;">- {{sopimuskausi.sopimusOnPaattynytEmailDate | lokaalipvm | async}} Sopimuksesi on päättynyt</div>
                </ng-container>
              </div>
              <div *ngIf="sopimuskausi.onkoPurku">
                <p>
                  *Sopimuskausi päättyy {{ sopimuskausi.kausi.loppuu | lokaalipvm | async }}. Asiakkaan Lemonaid-tunnukset
                  suljetaan {{ sopimuskausi.kausi.loppuu | lokaalipvm | async }} myös aineiston toimittamisen osalta.
                </p>
                Purkutilanteessa asiakkaalle ei lähetetä automaattisia sähköposteja.
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </ng-container>

  <ng-template #eiKausia>
    <div>Asiakkaalla ei ole yhtään sopimuskautta</div>
  </ng-template>

  <mat-error *ngIf="sopimuskausiError" style="margin: 10px;">{{ sopimuskausiError }}</mat-error>
  <div *ngIf="naytaLisaaObservable | async" style="padding-top: 10px; margin-left: 40px; margin-top: 30px; padding-bottom: 1.25em;">
    <button style="padding-left: 6px" mat-button (click)="lisaaSopimuskausi()" type="button" class="secondarybutton mat-elevation-z4">
      <mat-icon>add</mat-icon>
      Lisää sopimuskausi
    </button>
  </div>

</div>