import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Subject } from 'rxjs'

import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajaComponentDataResolve } from 'app/_angular/_resolvers/kirjanpitaja.resolve'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { PostmarkappDmarcReportsPyynto, PostmarkappDmarcReportsVastaus, PostmarkappDmarcReportsEntry } from '../../_jaettu-lemonator/model/integraatiot'

@Component({
  templateUrl: './dmarc-raportit.component.html'
})
export class DmarcRaportitComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) _sort: MatSort

  naytettavatKolumnit: string[] = ['id', 'domain', 'org']
  kirjanpitajatLataa: boolean = true

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  dataSource = new MatTableDataSource<PostmarkappDmarcReportsEntry>([])

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private kirjanpitajatService: KirjanpitajaService,
    private kirjanpitajaComponentDataResolve: KirjanpitajaComponentDataResolve,
    private _firebase: FirebaseLemonator
  ) {

    // this.dataSource.sortData = (data: Kirjanpitaja[], sort: MatSort): PostmarkappDmarcReportsEntry[] => {

    //   const active = sort.active
    //   const direction = sort.direction

    //   if (!active || direction === '') { return data }

    //   const directionMultiplier = direction === 'asc' ? 1 : -1

    //   const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    //   if (active === 'etunimi') {
    //     return data.sort((a, b) => {
    //       return collator.compare(a.etunimi, b.etunimi) * directionMultiplier
    //     })
    //   }

    //   if (active === 'sukunimi') {
    //     return data.sort((a, b) => {
    //       return collator.compare(a.sukunimi, b.sukunimi) * directionMultiplier
    //     })
    //   }

    //   if (active === 'email') {
    //     return data.sort((a, b) => {
    //       return collator.compare(a.email, b.email) * directionMultiplier
    //     })
    //   }

    //   return data

    // }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    this._sort.sort({
      disableClear: false,
      id: 'sukunimi',
      start: 'asc'
    })
    this.dataSource.sort = this._sort

    const payload: PostmarkappDmarcReportsPyynto = {}

    this._firebase.functionsCall<PostmarkappDmarcReportsPyynto, PostmarkappDmarcReportsVastaus>('integraatiotPostmarkappDmarcHaeRaportit', payload).then(r => {
      this.dataSource.data = r.reports || []
      this.kirjanpitajatLataa = false
    }).catch(err => {
      this.errorHandler.handleError(err)
    })
  }

  muokkaa(entry: PostmarkappDmarcReportsEntry) {
    console.log('click  ')
    this.router.navigate(['yllapito', 'postmarkapp', 'dmarc', entry.id])
  }

  get kirjanpitajatDataSource(): MatTableDataSource<PostmarkappDmarcReportsEntry> {
    return this.dataSource
  }

}
