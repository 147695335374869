import { KopioijaPalvelu } from '../../../_jaettu/service/kopioija.service'
import { Kirjanpitaja } from '../../../_jaettu-lemonator/model/kirjanpitaja'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'
import { Injectable } from '@angular/core'

@Injectable()
export class KirjanpitajaKopioija {

  constructor(
    private kopioijaPalvelu: KopioijaPalvelu,
    private timestampService: TimestampService
  ) {

  }

  public annaUusiKirjanpitaja(): Kirjanpitaja {
    return {
      avain: '',
      etunimi: '',
      sukunimi: '',
      luotu: this.timestampService.now(),
      luoja: '',
      paivitetty: this.timestampService.now(),
      paivittaja: '',
      poistettu: false,
      lemonaidUid: null,
      lemonaidAsiakasAvain: null,
      email: '',
      aloitti: null,
      workTimeSettings: []
    }
  }

  public kopioiKirjanpitaja(kopioitava: Kirjanpitaja): Kirjanpitaja {
    return this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville<Kirjanpitaja>(kopioitava)
  }

}
