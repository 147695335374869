/* eslint-disable @typescript-eslint/naming-convention */
import { Asiakas, Tilikausi } from '../../../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajanNimitiedot } from '../../../../_jaettu-lemonator/model/kirjanpitaja'
import { VeroilmoituksenAvainluvut, TilienSummanErityismarker, VeroilmoituksenValueConstants, VeroilmoitusDraft } from '../../../../_jaettu-lemonator/model/kirjanpito'
import { VeroilmoitusSummatService } from '../veroilmoitus-summat.service'
import { DateService } from '../../../../_shared-core/service/date.service'
import { VeroilmoituksenKaikkiTilisummat, VeroilmoituksenLaskija, VeroilmoituksenPaivityksenTulos, VeroilmoitusValidationError } from '../veroilmoitus-laskenta.service'
import { Ajoneuvo, KirjanpidonUlkopuolisetKulut } from '../../../../_jaettu/model/kayttaja'
import { IlmoitusRivi } from '../../base-ilmoitin-export.service'
import { Timestamp } from '../../../../_shared-core/model/common'
import { CurrencyService } from '../../../../_shared-core/service/currency.service'
import { VeroilmoitusBaseSpecialiazedCalculationService } from '../base-specialized-calculation.service'
import { AsiakasJaettuService } from '../../asiakas-jaettu.service'
import { CodeCheckService } from '../../../../_shared-core/service/code-check.service'

// eslint-disable-next-line no-shadow
export enum AutojenTyypit {
  HENKILOAUTOJA = '1',
  PAKETTIAUTOJA = '2',
  HENKILOAUTOJA_JA_PAKETTIAUTOJA = '3',
  MUITA_AJONEUVOJA = '4'
}

// eslint-disable-next-line no-shadow
export enum Erikoisarvot {
  KAAVAMAINEN_TYOHUONEVAHENNYS = 'tyohuone-vahennys-option',
  MUUT_ULKOPUOLISET_KULUT = 'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut',
  OSA_MUUT_ULKOPUOLISET_KULUT = 'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part',
  OSA_VAATIMUS_TAPPION_VAHENTAMISEEN_PAAOMATULOISTA = 'vaatimus-tappion-vahentamisesta-part',
  OSA_YRITYSTULON_JAKAMINEN_PUOLISON_KESKEN = 'jaetaan-puolison-kesken-part',
  OSA_ELINKEINOTOIMINNAN_ULKOPUOLISET_KULUT = 'elinkeino-ulkopuoliset-kulut-part',
  TIEDOT_TUOTOISTA_YHTEENSA = 'tiedot-tuotoista-yhteensa',
  OSA_YKSITYISTALOUTEEN_KUULUVAN_AUTON_KAYTTO = 'yksityistalouteen-kuuluvan-auton-kaytto-part',
  MUUTA_IRTAINTA_OMAISUUTTA = 'muuta-irtainta-omaisuutta',
  OSA_TILAPAISISTA_TYOMATKOISTA_AIHEUTUNEET_KUSTANNUKSET = 'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part',
  OSA_TOIMINTAVARAUS_JA_PALKAT = 'toimintavaraus-ja-palkat-part'
}

export class VeroilmoitusLaskenta2021TmiService extends VeroilmoitusBaseSpecialiazedCalculationService implements VeroilmoituksenLaskija {

  constructor(
    private _dateService: DateService,
    protected _currencyService: CurrencyService,
    protected _codeCheckService: CodeCheckService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {
    super(_currencyService, _codeCheckService)
  }

  public muodostaVeroilmoituksenLopullisetArvot(
    tietokannasta: VeroilmoitusDraft,
    eiTallennetut: VeroilmoituksenAvainluvut,
    tilienSummat: VeroilmoituksenKaikkiTilisummat,
    summatService: VeroilmoitusSummatService,
    asiakas: Asiakas,
    kirjanpitaja: KirjanpitajanNimitiedot,
    tilikausi: Tilikausi,
    kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut,
    muodostettu: Timestamp, // Tallennusaika
  ): VeroilmoituksenPaivityksenTulos {

    const tilikaudenSummat = tilienSummat.nykyinen?.tilikaudenSummat ?? {}
    const edellisenTilikaudenSummat = tilienSummat.edellinen?.tilikaudenSummat ?? {}

    // The list containing the produced values
    const arvot: IlmoitusRivi[] = []
    const oletukset: VeroilmoituksenAvainluvut = {}
    const affectedAccounts: Set<string> = new Set()

    this.tietokannastaLaskennoille = tietokannasta
    this.eiTallennetutLaskennoille = eiTallennetut
    this.oletuksetLaskennoille = oletukset

    if (!kirjanpitaja) {
      throw new Error('Kirjanpitäjä puuttuu')
    }

    const muodostettuAsDate = this._dateService.timestampToLocalDate(muodostettu)
    const vastuukirjanpitaja = this._asiakasJaettuService.annaVastuukirjanpitajaPaivalle(asiakas, muodostettuAsDate)
    const isHolviZen = vastuukirjanpitaja?.kirjanpitajanAvain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2'
    const accountantPhoneNumber = isHolviZen ?
      this._asiakasJaettuService.ZEN_TEAM_NUMBER // Use office number for Holvi Zen customers
      :
      kirjanpitaja.puhelin

    if (!accountantPhoneNumber) {
      throw new Error('Kirjanpitäjän puhelinnumero puuttuu')
    }

    if (!kirjanpitaja.email || !kirjanpitaja.email.endsWith('@lemontree.fi')) {
      throw new Error('Kirjanpitäjän email puuttuu')
    }

    // This is always disabled
    oletukset['kirjanpito-kuluja-vahennetty-kirjanpidossa'] = VeroilmoituksenValueConstants.FALSE

    // Calculate!

    // Specs here: https://lemontree-squad.monday.com/boards/615797797/pulses/615870883

    const formattedDateAndTimeStr = this._dateService.muotoileVeroFormaattiPaivaJaAikaDate(this._dateService.timestampToDate(muodostettu))
    arvot.push({ key: '000', value: 'VSY00521' }) // File identifier
    arvot.push({ key: '198', value: formattedDateAndTimeStr }) // Software-generated timestamp
    arvot.push({ key: '048', value: 'Tilitoimisto Lemon Tree Oy' }) // ID of the software that produced the file
    arvot.push({ key: '014', value: '2599105-8_LT' }) // ID of the software that produced the file (y-tunnus + 2 alphanums)

    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // Asiakastiedot:
    // Haetaan asiakkaan tiedoista Nimi,
    // 010 = Y - tunnus
    arvot.push({ key: '010', value: asiakas.ytunnus })

    // Lisätietojen antaja:
    // 041 = vastuukirjanpitäjän nimi
    arvot.push({ key: '041', value: kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi })
    // 042 = vastuukirjanpitäjän puhelinnumero
    arvot.push({ key: '042', value: accountantPhoneNumber })
    // 044 = vastuukirjanpitäjän sähköposti
    arvot.push({ key: '044', value: isHolviZen ? 'holvi@lemontree.fi' : kirjanpitaja.email })

    // Kirjanpitotapa 916:
    // Valinta: Yhdenkertainen / Kahdenkertainen -> jos valitaan kahdenkertainen aukeaa uusi kenttä alapuolelle "oman pääoman erittely".
    // Oletuksena valinta Lemon Treen asiakkailla kahdenkertainen Holvi - Bookkeepper asiakkailla yhdenkertainen.
    if (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Herra Holvi
      oletukset['916'] = VeroilmoituksenValueConstants.FALSE
    } else {
      oletukset['916'] = VeroilmoituksenValueConstants.TRUE
    }

    // 054 = tilikausi, jolta veroilmoitusta ollaan antamassa.
    const tilikausiFormatted = this._dateService.muotoileVeroFormaattiPaiva(tilikausi.alkaa) + '-' + this._dateService.muotoileVeroFormaattiPaiva(tilikausi.loppuu)
    arvot.push({ key: '054', value: tilikausiFormatted })

    const v916 = this._annaKayttajanMuokkaamaArvoTaiOletus('916')
    const kaksinkertainenKirjanpito = v916 === VeroilmoituksenValueConstants.TRUE
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '916', value: '1' })
    }

    // Oman pääoman erittely(ei näy / harmaa jos valittuna yhdenkertainen):
    // 748 = Oma pääoma tilikauden alussa: 2200 loppusaldo + 2250 loppusaldo
    // '0 - (tku2200 - tku2250)'
    const v748 = this._annaNumero('748', () => {
      const t2200 = summatService.laskeDebetMinusKredit('2200', tilikaudenSummat, affectedAccounts)
      const t2250 = summatService.laskeDebetMinusKredit('2250', tilikaudenSummat, affectedAccounts)
      return 0 - this._sum(t2200, t2250)
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '748', value: this._annaLuku(v748) })
    }

    // 741 = Yksityisnostot ja - sijoitukset: tilin 234 tapahtumien yhteenlaskettu määrä = kredit - debet. Jos debet suurempi kuin kredit luku negatiivinen. Edellisen tilikauden yksityisarvot päättävää automaattikirjausta ei oteta huomioon.
    const v741 = this._annaNumero('741', () => {
      const t234 = summatService.laskeKreditMinusDebet('234', tilikaudenSummat, affectedAccounts, TilienSummanErityismarker.TMI_234_SUODATETTU)
      const t234E = summatService.laskeKreditMinusDebet('234', edellisenTilikaudenSummat, affectedAccounts, TilienSummanErityismarker.TMI_234_SUODATETTU)
      return t234 - t234E
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '741', value: this._annaLuku(v741) })
    }

    // 742 = Tilikauden voitto tai tappio(kirjanpito): Tilikauden tulos kyseiseltä tilikaudelta.
    const v742 = this._annaNumero('742', () => {
      const tVoitto = summatService.laskeDebetMinusKredit('3', tilikaudenSummat, affectedAccounts)
      return 0 - tVoitto
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '742', value: this._annaLuku(v742) })
    }

    // 743 = Oma pääoma tilikauden lopussa: 748 + 741 + 742
    oletukset['743'] = this._sum(v748, v741, v742)
    const v743 = this._annaKayttajanMuokkaamaNumeroTaiOletus('743')
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '743', value: this._annaLuku(v743) })
    }
    // Laskelma korkokuluista:
    // 659 = jos oma pääoma yhteensä negatiivinen (743​ < 0) tilikauden päättyessä, tulee luku positiivisena tähän
    const muokattu659 = this._annaKayttajanMuokkaamaNumero('659')
    if (muokattu659 !== null && muokattu659 !== undefined) {
      arvot.push({ key: '659', value: this._annaLuku(muokattu659) })
    } else if (v743 < 0 && kaksinkertainenKirjanpito) {
      arvot.push({ key: '659', value: this._annaLuku(Math.abs(v743)) })
    }
    // 677 = avoin kenttä
    const v677 = Number(this._annaKayttajanMuokkaamaArvo('677') || 0)
    arvot.push({ key: '677', value: this._annaLuku(v677) })

    // 650 = mikäli tilikauden tulos on negatiivinen (742 < 0) tulee se positiivisena tähän
    const muokattu650 = this._annaKayttajanMuokkaamaNumero('650')
    if (muokattu650) {
      arvot.push({ key: '650', value: this._annaLuku(muokattu650) })
    } else if (v742 < 0 && kaksinkertainenKirjanpito) {
      arvot.push({ key: '650', value: this._annaLuku(Math.abs(v742)) })
    }

    // 651 = avoin kenttä
    const v651 = this._annaKayttajanMuokkaamaNumero('651') || 0
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '651', value: this._annaLuku(v651) })
    }

    // 652 = 659 + 677 - 650 - 651
    const v652 = this._annaNumero('652', () => {
      const v659_v652_laskentaaVarten = v743 < 0 ? Math.abs(v743) : 0
      const v650_v652_laskentaaVarten = v742 < 0 ? Math.abs(v742) : 0
      return Math.max(v659_v652_laskentaaVarten + v677 - v650_v652_laskentaaVarten - v651, 0)
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '652', value: this._annaLuku(v652) })
    }
    // console.log('v652: ' + arvot['652'], 'v659_v652_laskentaaVarten: ' + v659_v652_laskentaaVarten, 'v677: ' + v677, 'v650_v652_laskentaaVarten: ' + v650_v652_laskentaaVarten, 'v651: ' + v651)
    // 653 = avoin kenttä, mutta ehdottaa 0,75 %
    const v653 = this._annaNumero('653', () => {
      return 0.5
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '653', value: this._annaLuku(v653) })
    }

    // 383 = 652 * 653
    const v383 = this._annaNumero('383', () => {
      return Math.max(v652 * (v653 / 100), 0)
    })
    // console.log(kaksinkertainenKirjanpito, v383, v652, v653)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '383', value: this._annaLuku(v383) })
    }

    // Rahan nostot ja sijoitukset
    // Huom haetaan kalenterivuodelta ei tilikaudelta.
    // Yksityisarvot päättävää automaattikirjausta ei oteta huomioon.
    // 384 = haetaan 234 tilien debet summa 1.1. - 31.12.2021.
    const v384 = this._annaNumero('384', () => {
      return tilienSummat.nykyinen?.kalenterivuoden234DebetSumma ?? 0
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '384', value: this._annaLuku(v384) })
    }

    // 385 = haetaan 234 tilien kredit summa 1.1. - 31.12.2021.
    const v385 = this._annaNumero('385', () => {
      return tilienSummat.nykyinen?.kalenterivuoden234KreditSumma ?? 0
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '385', value: this._annaLuku(v385) })
    }

    // 222222 TUOTOT
    // 222222 TUOTOT
    // 222222 TUOTOT
    // 222222 TUOTOT
    // Tiedot tuotoista

    // 300 = tilit 30 + tilit 36

    const v300 = this._annaNumero('300', () => {
      const t30 = summatService.laskeDebetMinusKredit('30', tilikaudenSummat, affectedAccounts)
      return 0 - t30
    })
    arvot.push({ key: '300', value: this._annaLuku(v300) })

    // 318 = tili 3800
    const t3800 = summatService.laskeDebetMinusKredit('3800', tilikaudenSummat, affectedAccounts)
    const v318 = this._annaNumero('318', () => {
      return 0 - t3800
    })
    arvot.push({ key: '318', value: this._annaLuku(v318) })

    // tili 3990
    const t3990 = summatService.laskeDebetMinusKredit('3990', tilikaudenSummat, affectedAccounts)

    // 301 = tilit 37 - 3800
    const v301 = this._annaNumero('301', () => {
      const t37 = summatService.laskeDebetMinusKredit('37', tilikaudenSummat, affectedAccounts)
      return 0 - (t37 - t3800 - t3990)
    })
    arvot.push({ key: '301', value: this._annaLuku(v301) })

    // 323 = 9200 + 9290
    const v323 = this._annaNumero('323', () => {
      const t9200 = summatService.laskeDebetMinusKredit('9200', tilikaudenSummat, affectedAccounts)
      const t9290 = summatService.laskeDebetMinusKredit('9290', tilikaudenSummat, affectedAccounts)
      return 0 - (t9200 + t9290)
    })
    arvot.push({ key: '323', value: this._annaLuku(v323) })

    // 312 = tili 9870 jos kredit(jos debet tyhjä)
    const t9870D = summatService.laskeDebet('9870', tilikaudenSummat, affectedAccounts)
    const t9870K = summatService.laskeKredit('9870', tilikaudenSummat, affectedAccounts)
    const v312 = this._annaNumero('312', () => {
      if (t9870K - t9870D > 0) {
        return t9870K - t9870D
      }
      return 0
    })
    if (v312 !== 0) {
      arvot.push({ key: '312', value: this._annaLuku(v312) })
    }

    // 353 = 9870K - 9870D jos 9870K - 9870D > 0 ?
    const v353 = this._annaNumero('353', () => {
      if (t9870D - t9870K > 0) {
        return t9870D - t9870K
      }
      return 0
    })
    if (v353 !== 0) {
      arvot.push({ key: '353', value: this._annaLuku(v353) })
    }

    // 324 = täytettävä
    const v324 = this._annaKayttajanMuokkaamaNumero('324') || 0
    if (v324 > 0) {
      arvot.push({ key: '324', value: this._annaLuku(v324) })
    }

    // TODO: FIXME: This is not a good way to pass values..
    tietokannasta.arvotEditable[Erikoisarvot.TIEDOT_TUOTOISTA_YHTEENSA] = v300 + v318 + v301 + v323 + v312 + v324
    // Tiedot tuotoista yhteensä = summaa yllä olevat
    // Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot
    // Haetaan "kirjanpidon ulkopuoliset kulut" lomakkeelta. Eritellään tähän, mikäli ajoneuvolla on enemmän yritysajoa kuin yksityisajoa.
    const kaikkiAjoneuvot: Ajoneuvo[] = (kirjanpidonUlkopuolisetKulut?.ajoneuvot || [])
    const enemmanYritysajoaLista = kaikkiAjoneuvot.filter(a => {
      return a.kokonaisKmMaara > 0 && a.tyoAjoissaKm > 0 && a.kokonaisKmMaara / 2 < a.tyoAjoissaKm
    })

    const enemmanYksityisajoaLista = kaikkiAjoneuvot.filter(a => {
      return a.kokonaisKmMaara > 0 && a.tyoAjoissaKm > 0 && a.kokonaisKmMaara / 2 >= a.tyoAjoissaKm
    })

    const onkoUlkopuolisetKulutOletuksenaAuki = enemmanYritysajoaLista.length > 0
    const onkoUlkopuolisetKulutAuki = this._onkoOsaAuki(onkoUlkopuolisetKulutOletuksenaAuki, Erikoisarvot.OSA_ELINKEINOTOIMINNAN_ULKOPUOLISET_KULUT)
    oletukset[Erikoisarvot.OSA_ELINKEINOTOIMINNAN_ULKOPUOLISET_KULUT] = onkoUlkopuolisetKulutOletuksenaAuki ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE

    const t6880 = summatService.laskeDebetMinusKredit('6880', tilikaudenSummat, affectedAccounts)

    let v313 = this._annaNumero('313', () => 0)
    if (onkoUlkopuolisetKulutAuki) {

      const ajoneuvojenTyypit = this._annaKayttajanMuokkaamatAjoneuvojenTyypit() ?? this._annaAjoneuvojenTyypit(enemmanYritysajoaLista)

      // Käyttöomaisuuteen sisältyy
      // 1 = henkilöauto(ja)
      // 2 = pakettiauto(ja)
      // 3 = henkilöauto(ja) ja pakettiauto(ja) 4 = muita kulkuneuvoja
      // 379 = 1 = henkilöauto(ja) 2 = pakettiauto(ja) 3 = henkilöauto(ja) ja pakettiauto(ja) 4 = muita kulkuneuvoja. Yksikin muu kulkuneuvo = 4.
      arvot.push({ key: '002', value: ajoneuvojenTyypit.length + '' })
      for (const tyyppi of ajoneuvojenTyypit) {
        arvot.push({ key: '379', value: tyyppi })
      }

      const ajotiedot = this._annaKokonaiskilometrimaarat(enemmanYritysajoaLista)

      // 380 = haetaan lomakkeelta: 1 = ajopäiväkirjaan, 2 = muuhun käyttöselvitykseen
      const v380 = this._annaArvo('380', () => {
        if (kirjanpidonUlkopuolisetKulut.tiedotPerustuvat === 'ajopaivakirjaan') {
          return '1'
        } else if (kirjanpidonUlkopuolisetKulut.tiedotPerustuvat === 'muuhun selvitykseen') {
          return '2'
        }
        return ''
      })
      arvot.push({ key: '380', value: v380 + '' })

      // 271 = Työajoa, kilometriä: haetaan lomakkeelta
      const v271 = this._annaNumero('271', () => Math.round(ajotiedot.tyoajoaKm ?? 0))
      arvot.push({ key: '271', value: Math.round(v271) + '' })

      let v274 = this._annaNumero('274', () => Math.round(ajotiedot.kokonaisKm ?? 0))
      const kayttajanAntama273 = this._annaKayttajanMuokkaamaNumero('273')
      if (kayttajanAntama273 !== null && kayttajanAntama273 !== undefined) {
        v274 = this._annaNumero('274', () => Math.round(v271 + kayttajanAntama273))
      }

      // 273 = Yksityisajoa, kilometriä: 274 - 271
      const v273 = this._annaNumero('273', () => Math.round(v274 - v271))
      arvot.push({ key: '273', value: Math.round(v273) + '' })

      // 274 = Kokonaisajomaara, kilometriä: haetaan lomakkeelta
      arvot.push({ key: '274', value: Math.round(v274) + '' })

      // 381 = Täytettävä kenttä. "hae ehdotus" - nappula hakee summan tileiltä 7500..7599 + 6880.
      const v381 = this._annaNumero('381', () => {
        const t75007599 = summatService.laskeDebetMinusKreditTilialueelta(7500, 7599, tilikaudenSummat, affectedAccounts)
        return Math.max(t75007599 + t6880, 0)
      })
      arvot.push({ key: '381', value: this._annaLuku(v381) })

      // 396 = aina 1 (1 = on vähennetty kirjanpidossa, 2 = ei ole vähennetty)
      const v396 = this._annaArvo('396', () => '1')
      arvot.push({ key: '396', value: v396 + '' })

      // 275 = 381 / 274
      const v275 = this._annaNumero('275', () => v274 === 0 ? 0 : v381 / v274)
      arvot.push({ key: '275', value: this._annaLuku(v275) })

      // 382 = 273 * 275
      const v382 = this._annaNumero('382', () => v273 * v275)
      arvot.push({ key: '382', value: this._annaLuku(v382) })

      // 313 = 382
      v313 = this._annaNumero('313', () => isNaN(v382) ? 0 : v382)
      arvot.push({ key: '313', value: this._annaLuku(v313) })

    }

    // Tuloutus yksityiskäytöstä:
    // oletuksena valittu "ei", jos valittu "kyllä":

    // 314 = vapaa kenttä
    const v314 = this._annaNumero('314', () => {
      const t36 = summatService.laskeDebetMinusKredit('36', tilikaudenSummat, affectedAccounts)
      return 0 - t36
    })
    arvot.push({ key: '314', value: this._annaLuku(v314) })

    // 315 = vapaa kenttä
    const v315 = this._annaKayttajanMuokkaamaNumero('315') || 0
    arvot.push({ key: '315', value: this._annaLuku(v315) })

    // Tuloutus yksityiskäytöstä = 314 + 315
    oletukset['tuloutus-yksityiskaytosta-yhteensa'] = this._sum(v314, v315)

    // Veronalaiset tuotot yhteensä:
    // 316 = 300 + 318 + 301 + 323 + 324 + 313 + 314 + 315 + 312 + 301
    const v316 = this._annaNumero('316', () => {
      return this._sum(v300, v318, v323, v324, v313, v314, v315, v312, v301)
    })
    arvot.push({ key: '316', value: this._annaLuku(v316) })

    // Verovapaat tuotot
    // 317 = 3990 + 9240
    const v317 = this._annaNumero('317', () => {
      const t9240 = summatService.laskeDebetMinusKredit('9240', tilikaudenSummat, affectedAccounts)
      return 0 - this._sum(t3990, t9240)
    })
    arvot.push({ key: '317', value: this._annaLuku(v317) })

    // Muuta:
    // 319 = aina 0
    // 320 = aina 0
    // 321 = aina 0
    // 322 = aina 0
    // 325 = aina 0
    // 326 = aina 0
    // 327 = aina 0
    // 328 = aina 0
    // 312 = aina 0
    // arvot.push({ key: '319', value: '0,00' })
    // arvot.push({ key: '320', value: '0,00' })
    // arvot.push({ key: '321', value: '0,00' })
    // arvot.push({ key: '322', value: '0,00' })
    // arvot.push({ key: '325', value: '0,00' })
    // arvot.push({ key: '326', value: '0,00' })
    // arvot.push({ key: '327', value: '0,00' })
    // arvot.push({ key: '328', value: '0,00' })
    // arvot.push({ key: '312', value: '0,00' })

    // 333333 KULUT
    // 333333 KULUT
    // 333333 KULUT
    // 333333 KULUT
    // Tiedot kuluista
    // 333 = arvot 400 ja 440
    const v333 = this._annaNumero('333', () => {
      const t400 = summatService.laskeDebetMinusKredit('400', tilikaudenSummat, affectedAccounts)
      const t440 = summatService.laskeDebetMinusKredit('440', tilikaudenSummat, affectedAccounts)
      return this._sum(t400, t440)
    })
    arvot.push({ key: '333', value: this._annaLuku(v333) })

    // 334 = tili 445
    const v334 = this._annaNumero('334', () => {
      return summatService.laskeDebetMinusKredit('445', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '334', value: this._annaLuku(v334) })

    // 335 = tili 500
    const v335 = this._annaNumero('335', () => {
      return summatService.laskeDebetMinusKredit('500', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '335', value: this._annaLuku(v335) })

    // 336 = tili 600
    const v336 = this._annaNumero('336', () => {
      return summatService.laskeDebetMinusKredit('600', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '336', value: this._annaLuku(v336) })

    // 341 = tili 7990(ei omaa riviä käyttöliittymässä)
    const t7990 = summatService.laskeDebetMinusKredit('7990', tilikaudenSummat, affectedAccounts)
    const v341 = t7990
    arvot.push({ key: '341', value: this._annaLuku(v341) })

    // 342 = tili 7990 / 2
    oletukset['v342Korvaus1'] = t7990
    const v342 = this._annaNumero('342', () => this._currencyService.roundHalfUp(t7990 / 2, 2))
    arvot.push({ key: '342', value: this._annaLuku(v342) })

    // 343 = arvot 7720 + 7230 + 7310 + 7520
    const t7720 = summatService.laskeDebetMinusKredit('7720', tilikaudenSummat, affectedAccounts)
    const t7230 = summatService.laskeDebetMinusKredit('7230', tilikaudenSummat, affectedAccounts)
    const t7310 = summatService.laskeDebetMinusKredit('7310', tilikaudenSummat, affectedAccounts)
    const t7520 = summatService.laskeDebetMinusKredit('7520', tilikaudenSummat, affectedAccounts)
    const v343 = this._annaNumero('343', () => this._sum(t7720, t7230, t7310, t7520))
    arvot.push({ key: '343', value: this._annaLuku(v343) })

    // 346 = tili 9500(ei omaa riviä käyttöliittymässä)
    const t9500 = summatService.laskeDebetMinusKredit('9500', tilikaudenSummat, affectedAccounts)
    const v346 = t9500
    oletukset['v347Korvaus1'] = t9500
    arvot.push({ key: '346', value: this._annaLuku(v346) })

    // 347 = tili 9500 - 383
    const v347 = this._annaNumero('347', () => {
      return kaksinkertainenKirjanpito ? Math.max(t9500 - v383, 0) : v346
    })
    // console.log('SET v347Korvaus1', oletukset['v347Korvaus1'], v383)
    arvot.push({ key: '347', value: this._annaLuku(v347) })

    // 344 = arvot 70 - 7720 - 7230 - 7310 - 7520 - 7990 - 8800 - 8280
    const t8800 = summatService.laskeDebetMinusKredit('8800', tilikaudenSummat, affectedAccounts)
    const t8280 = summatService.laskeDebetMinusKredit('8280', tilikaudenSummat, affectedAccounts)
    const v344 = this._annaNumero('344', () => {
      const t70 = summatService.laskeDebetMinusKredit('70', tilikaudenSummat, affectedAccounts)
      return t70 - t7720 - t7230 - t7310 - t7520 - t7990 - t8800 - t8280
    })
    arvot.push({ key: '344', value: this._annaLuku(v344) })

    // 349 = tili 9600
    const v349 = this._annaNumero('349', () => {
      return summatService.laskeDebetMinusKredit('9600', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '349', value: this._annaLuku(v349) })

    oletukset['tiedot-kuluista-yhteensa'] = this._sum(v333, v334, v335, v336, v342, v343, v347, v344, v349, v353)

    // Poistot:
    // Jos valitset ei näkyy sininen ruutu, jos valitset kyllä näkyy punainen ruutu(vasemmalla).
    // Jäljellä oleva menojäännös tilikauden alussa = tilin 116 edellisen tilikauden loppusaldo
    // Jäljellä oleva menojäännös tilikauden alussa = tilin 116 tämän tilikauden loppusaldo
    oletukset[Erikoisarvot.MUUTA_IRTAINTA_OMAISUUTTA] = VeroilmoituksenValueConstants.FALSE
    // Tilikauden poistot = tili 6800 + 6880
    // 800 = vapaa kenttä(tilin 116 edellisen tilikauden loppusaldo)
    const t116E = summatService.laskeDebetMinusKredit('116', edellisenTilikaudenSummat, affectedAccounts)
    oletukset['800'] = t116E
    const v800 = this._annaKayttajanMuokkaamaNumeroTaiOletus('800') ?? 0
    arvot.push({ key: '800', value: this._annaLuku(v800) })

    // 801 = vapaa kenttä(tilin 116 debet yhteensä tilikauden aikana)
    const t116Debet = summatService.laskeDebet('116', tilikaudenSummat, affectedAccounts)
    const t116DebetE = summatService.laskeDebet('116', edellisenTilikaudenSummat, affectedAccounts)
    oletukset['801'] = oletukset['801'] = this._currencyService.roundHalfUp(t116Debet - t116DebetE, 2)
    const v801 = this._annaKayttajanMuokkaamaNumeroTaiOletus('801') ?? 0
    arvot.push({ key: '801', value: this._annaLuku(v801) })

    // 804 = vapaa kenttä (tili 68)
    const t68 = summatService.laskeDebetMinusKredit('68', tilikaudenSummat, affectedAccounts)
    oletukset['804'] = t68
    const v804 = this._annaKayttajanMuokkaamaNumeroTaiOletus('804') ?? 0
    arvot.push({ key: '804', value: this._annaLuku(v804) })

    // 802 = vapaa kenttä(tilin 116 kredit yhteensä tilikauden aikana)
    const t116Kredit = summatService.laskeKredit('116', tilikaudenSummat, affectedAccounts)
    const t116KreditE = summatService.laskeKredit('116', edellisenTilikaudenSummat, affectedAccounts)
    oletukset['802'] = this._currencyService.roundHalfUp(Math.max(0, t116Kredit - t116KreditE - v804), 2)
    const v802 = this._annaKayttajanMuokkaamaNumeroTaiOletus('802') ?? 0
    arvot.push({ key: '802', value: this._annaLuku(v802) })

    const t6800 = summatService.laskeDebetMinusKredit('6800', tilikaudenSummat, affectedAccounts)
    oletukset['menojaannos-alussa'] = t116E
    oletukset['menojaannos-lopussa'] = t116Debet - t116Kredit
    oletukset['tilikauden-poistot'] = this._sum(t6800, t6880)

    // 805 = vapaa kenttä(ei ehdotusta)
    const v805 = this._annaKayttajanMuokkaamaNumero('805') ?? 0
    arvot.push({ key: '805', value: this._annaLuku(v805) })

    // 806 = vapaa kenttä(tilin 116 tämän tilikauden loppusaldo)
    const t116 = summatService.laskeDebetMinusKredit('116', tilikaudenSummat, affectedAccounts)
    oletukset['806'] = t116
    const v806 = this._annaKayttajanMuokkaamaNumeroTaiOletus('806') ?? 0
    arvot.push({ key: '806', value: this._annaLuku(v806) })
    // Hae ehdotukset nappula = täyttä ylläolevat kentät ehdotukset suluissa

    // 338 = 804 + 805
    const v338 = this._annaNumero('338', () => v804 + v805)
    arvot.push({ key: '338', value: this._annaLuku(v338) })

    // 337 = 338
    arvot.push({ key: '337', value: this._annaLuku(v338) })

    // Yksityistalouteen kuuluvan auton käyttö
    // Kaikki luvut tulevat suoraan asiakkaan täyttämältä "kirjanpidon ulkopuoliset kulut" lomakkeelta. Autot, joilla enemmän yksityisajoa kuin yritysajoa ilmoitetaan tässä.
    // Jos lomakkeella ei tietoja, valinta "ei".
    const onkoYksityisajotOletuksenaAuki = enemmanYksityisajoaLista.length > 0
    const onkoYksityisajotAuki = this._onkoOsaAuki(onkoYksityisajotOletuksenaAuki, Erikoisarvot.OSA_YKSITYISTALOUTEEN_KUULUVAN_AUTON_KAYTTO)
    oletukset[Erikoisarvot.OSA_YKSITYISTALOUTEEN_KUULUVAN_AUTON_KAYTTO] = onkoYksityisajotOletuksenaAuki ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE

    let v392 = 0
    if (onkoYksityisajotAuki) {

      // 416 = ajopäiväkirja = 1, Muu selvitys = 2
      const v416 = this._annaArvo('416', () => {
        if (kirjanpidonUlkopuolisetKulut.tiedotPerustuvat === 'ajopaivakirjaan') {
          return '1'
        } else if (kirjanpidonUlkopuolisetKulut.tiedotPerustuvat === 'muuhun selvitykseen') {
          return '2'
        }
        return ''
      })
      arvot.push({ key: '416', value: v416 + '' })

      const kmTiedot = this._annaKokonaiskilometrimaarat(enemmanYksityisajoaLista)

      // 389 = kokonaiskilometrimäärä
      const v389 = this._annaNumero('389', () => Math.round(kmTiedot.kokonaisKm ?? 0))
      arvot.push({ key: '389', value: Math.round(v389) + '' })

      // 390 = työajot
      const v390 = this._annaNumero('390', () => Math.round(kmTiedot.tyoajoaKm ?? 0))
      arvot.push({ key: '390', value: Math.round(v390) + '' })

      // 419 = 0,44 euroa
      const v419 = this._annaNumero('419', () => 0.44)
      arvot.push({ key: '419', value: this._annaLuku(v419) })

      // 420 = 390 * 419
      const v420 = this._annaNumero('420', () => v390 * v419)
      arvot.push({ key: '420', value: this._annaLuku(v420) })

      // 391 = ei voi muuttaa = aina ei = aina 0
      const v391 = this._annaNumero('391', () => 0)
      arvot.push({ key: '391', value: this._annaLuku(v391) })

      // 392 = 420
      v392 = this._annaNumero('392', () => v420)
      arvot.push({ key: '392', value: this._annaLuku(v392) })

    }


    const onkokirjanpidonUlkopuolisetKulutAukiOletuksena = kirjanpidonUlkopuolisetKulut?.yli10hKotimaanMatkojenLukumaara > 0 ||
      kirjanpidonUlkopuolisetKulut?.yli6hKotimaanMatkojenLukumaara > 0 ||
      kirjanpidonUlkopuolisetKulut?.ulkomaanMatkapaivienLukumaara > 0 ||
      kirjanpidonUlkopuolisetKulut?.ulkomaanPaivarahojenYhteismaara > 0
    const onkokirjanpidonUlkopuolisetKulutAuki = this._onkoOsaAuki(onkokirjanpidonUlkopuolisetKulutAukiOletuksena, Erikoisarvot.OSA_TILAPAISISTA_TYOMATKOISTA_AIHEUTUNEET_KUSTANNUKSET)
    oletukset[Erikoisarvot.OSA_TILAPAISISTA_TYOMATKOISTA_AIHEUTUNEET_KUSTANNUKSET] = onkokirjanpidonUlkopuolisetKulutAukiOletuksena ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE

    // Tilipäisistä työmatkoista aiheutuneet elantokustannukset
    // Kaikki luvut tulevat suoraan asiakkaan täyttämältä "kirjanpidon ulkopuoliset kulut" lomakkeelta. Jos lomakkeella ei tietoja, valinta "ei".
    // Oletko vähentänyt matkakuluja kirjanpidossa aina "ei".
    let v388 = 0
    if (onkokirjanpidonUlkopuolisetKulutAuki) {
      // 401 = kokopäiväraha päivien lkm(lomakkeelta)
      const v401 = Math.round(kirjanpidonUlkopuolisetKulut.yli10hKotimaanMatkojenLukumaara || 0)
      arvot.push({ key: '401', value: v401 + '' })

      // 402 = 44 euroa
      const v402 = 44
      arvot.push({ key: '402', value: this._annaLuku(v402) })

      // 403 = 401 * 402
      const v403 = v401 * v402
      arvot.push({ key: '403', value: this._annaLuku(v403) })

      // 404 = aina 0
      arvot.push({ key: '404', value: '0,00' })

      // 405 = 403
      const v405 = v403
      arvot.push({ key: '405', value: this._annaLuku(v405) })

      // 406 = osapäiväraha päivien lkm(lomakkeelta)
      const v406 = Math.round(kirjanpidonUlkopuolisetKulut.yli6hKotimaanMatkojenLukumaara || 0)
      arvot.push({ key: '406', value: v406 + '' })

      // 407 = 20 euroa
      const v407 = 20
      arvot.push({ key: '407', value: this._annaLuku(v407) })

      // 408 = 406 * 407
      const v408 = v406 * v407
      arvot.push({ key: '408', value: this._annaLuku(v408) })

      // 409 = aina 0
      arvot.push({ key: '409', value: '0,00' })

      // 410 = 408
      const v410 = v408
      arvot.push({ key: '410', value: this._annaLuku(v410) })

      // 411 = ulkomaan päivärahojen päivien lkm(lomakkeelta)
      const v411 = Math.round(kirjanpidonUlkopuolisetKulut.ulkomaanMatkapaivienLukumaara || 0)
      arvot.push({ key: '411', value: v411 + '' })

      // 413 = ulkomaan päivärahojen yhteismäärä(lomakkeelta)
      const v413 = Math.round(kirjanpidonUlkopuolisetKulut.ulkomaanPaivarahojenYhteismaara || 0)
      arvot.push({ key: '413', value: this._annaLuku(v413) })

      // 414 = aina 0
      arvot.push({ key: '414', value: '0,00' })

      // 415 = 413
      const v415 = v413
      arvot.push({ key: '415', value: this._annaLuku(v415) })

      // 387 = aina 0
      arvot.push({ key: '387', value: '0,00' })

      // 388 = 405 + 410 + 415
      v388 = this._sum(v405, v410, v415)
      arvot.push({ key: '388', value: this._annaLuku(v388) })

    }

    // Ulkopuoliset vähennyskelpoiset kulut
    // Valinta Kaavamainen työhuonevähennys(default ) / todellisten kulujen mukaan. Jos kaavamainen haetaan lomakkeelta. Jos todellisten kulujen mukaan:
    oletukset[Erikoisarvot.KAAVAMAINEN_TYOHUONEVAHENNYS] = VeroilmoituksenValueConstants.TRUE
    const kaavamainenArvo = tietokannasta.arvotEditable[Erikoisarvot.KAAVAMAINEN_TYOHUONEVAHENNYS] || oletukset[Erikoisarvot.KAAVAMAINEN_TYOHUONEVAHENNYS]
    const onkoKaavamainenVahennys = kaavamainenArvo === VeroilmoituksenValueConstants.TRUE

    let kaavamainenVahennys = 0
    let v386 = 0
    if (onkoKaavamainenVahennys) {

      if (kirjanpidonUlkopuolisetKulut?.kotiYrityksenTyotilana) {
        if (kirjanpidonUlkopuolisetKulut.kotiYrityksenTyotilana === 'ei-vahennysta') {
          kaavamainenVahennys = 0
        } else if (kirjanpidonUlkopuolisetKulut.kotiYrityksenTyotilana === 'osa-aikainen') {
          kaavamainenVahennys = 460
        } else if (kirjanpidonUlkopuolisetKulut.kotiYrityksenTyotilana === 'paasaantoinen') {
          kaavamainenVahennys = 920
        } else if (kirjanpidonUlkopuolisetKulut.kotiYrityksenTyotilana === 'satunnainen') {
          kaavamainenVahennys = 230
        } else {
          throw new Error('Unknown kotiYrityksenTyotilana: ' + kirjanpidonUlkopuolisetKulut.kotiYrityksenTyotilana)
        }
      }

    } else {
      // 431 = vapaa kenttä
      const v431 = this._annaKayttajanMuokkaamaNumero('431') ?? 0
      arvot.push({ key: '431', value: this._annaLuku(v431) })

      // 432 = vapaa kenttä
      const v432 = this._annaKayttajanMuokkaamaNumero('432') ?? 0
      arvot.push({ key: '432', value: this._annaLuku(v432) })

      // 433 = vapaa kenttä
      const v433 = this._annaKayttajanMuokkaamaNumero('433') || 0
      arvot.push({ key: '433', value: this._annaLuku(v433) })

      // 386 = 431 / 432 * 433
      const jakolaskunTulos = v431 === 0 ? 0 : v432 / v431
      v386 = jakolaskunTulos * v433
      arvot.push({ key: '386', value: this._annaLuku(v386) })
    }

    oletukset['kaavamainen-tyohuonevahennys'] = kaavamainenVahennys

    // Muut kirjanpidon ulkopuoliset = vapaa kenttä
    const vMuutUlkopuoliset = this._annaKayttajanMuokkaamaNumero(Erikoisarvot.MUUT_ULKOPUOLISET_KULUT) || 0

    // 354 = Kaavamainen työhuonevähennys tai 386 + muut kirjanpidon ulkopuoliset
    const v354 = this._annaNumero('354', () => {
      if (onkoKaavamainenVahennys) {
        return kaavamainenVahennys + vMuutUlkopuoliset
      } else {
        return v386 + vMuutUlkopuoliset
      }
    })
    arvot.push({ key: '354', value: this._annaLuku(v354) })

    // Elinkeinotoiminnan vähennyskelpoiset kulut yht
    // 355 = 333 + 334 + 335 + 336 + 342 + 343 + 347 + 344 + 349 + 338 + 392 + 388 + 354
    const v355 = this._annaNumero('355', () => {
      return this._sum(v333, v334, v335, v336, v342, v343, v347, v344, v349, v338, v392, v388, v354, v353)
    })
    arvot.push({ key: '355', value: this._annaLuku(v355) })

    // Elinkeinotoiminnan tulos
    // 358 = 316 - 355 jos tulos positiivinen
    // 359 = 316 - 355 jos tulos negatiivinen
    const v358_v359 = v316 - v355
    oletukset['elinkeinotoiminnan-tulos'] = v358_v359
    if (v358_v359 > 0) {
      arvot.push({ key: '358', value: this._annaLuku(Math.abs(v358_v359)) })
    } else if (v358_v359 < 0) {
      arvot.push({ key: '359', value: this._annaLuku(Math.abs(v358_v359)) })
    } else {
      arvot.push({ key: '358', value: this._annaLuku(0) })
      arvot.push({ key: '359', value: this._annaLuku(0) })
    }

    // Vähennyslevottomat kulut
    // 365 = arvot 99
    const v365 = this._annaNumero('365', () => {
      return summatService.laskeDebetMinusKredit('99', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '365', value: this._annaLuku(v365) })

    // 366 = 9540
    const v366 = this._annaNumero('366', () => {
      return summatService.laskeDebetMinusKredit('9540', tilikaudenSummat, affectedAccounts)
    })
    arvot.push({ key: '366', value: this._annaLuku(v366) })

    // 367 = 8800 + 8280
    const v367 = this._annaNumero('367', () => {
      return this._sum(t8800, t8280)
    })
    arvot.push({ key: '367', value: this._annaLuku(v367) })

    oletukset['vahennyskelvottomat-kulut-yhteensa'] = this._sum(v365, v366, v367)

    // 364 = 392 + 388(ei omaa riviä käyttöliittymässä)
    const v364 = this._sum(v392, v388)
    arvot.push({ key: '364', value: this._annaLuku(v364) })

    // Muuta:

    // 444444 VARALLISUUSLASKELMA
    // 444444 VARALLISUUSLASKELMA
    // 444444 VARALLISUUSLASKELMA
    // 444444 VARALLISUUSLASKELMA
    // Käyttöomaisuus
    // 704 = tili 116 OK
    const v704 = this._annaNumero('704', () => t116)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '704', value: this._annaLuku(v704) })
    }

    // 706 = arvot 1300 + 1380 + 100 OK
    const v706 = this._annaNumero('706', () => {
      const t1300 = summatService.laskeDebetMinusKredit('1300', tilikaudenSummat, affectedAccounts)
      const t1380 = summatService.laskeDebetMinusKredit('1380', tilikaudenSummat, affectedAccounts)
      const t100 = summatService.laskeDebetMinusKredit('100', tilikaudenSummat, affectedAccounts)
      return this._sum(t1300, t1380, t100)
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '706', value: this._annaLuku(v706) })
    }

    oletukset['kayttoomaisuus-yhteensa'] = this._sum(v704, v706)

    // Tavarat ja muu vaihto - omaisuus
    // 708 = tili 150 - 1500 - 1540 - 1550
    const t1500 = summatService.laskeDebetMinusKredit('1500', tilikaudenSummat, affectedAccounts)
    const t1540 = summatService.laskeDebetMinusKredit('1540', tilikaudenSummat, affectedAccounts)
    const t1550 = summatService.laskeDebetMinusKredit('1550', tilikaudenSummat, affectedAccounts)
    const v708 = this._annaNumero('708', () => {
      const t150 = summatService.laskeDebetMinusKredit('150', tilikaudenSummat, affectedAccounts)
      return t150 - t1500 - t1540 - t1550
    })
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '708', value: this._annaLuku(v708) })
    }

    // 709 = 1500 + 1540 + 1550
    const v709 = this._annaNumero('709', () => t1500 + t1540 + t1550)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '709', value: this._annaLuku(v709) })
    }

    oletukset['tavarat-ja-muu-yhteensa'] = this._sum(v708, v709)

    // Rahoitusomaisuus
    // 711 = arvot 171 + 1600
    const t171 = summatService.laskeDebetMinusKredit('171', tilikaudenSummat, affectedAccounts)
    const t1600 = summatService.laskeDebetMinusKredit('1600', tilikaudenSummat, affectedAccounts)
    const v711 = this._annaNumero('711', () => this._sum(t171, t1600))
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '711', value: this._annaLuku(v711) })
    }

    // 714 = tili 1900
    const t1900 = summatService.laskeDebetMinusKredit('1900', tilikaudenSummat, affectedAccounts)
    const v714 = this._annaNumero('714', () => t1900)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '714', value: this._annaLuku(v714) })
    }

    // 716 = (160 - 1600) + 175 + 176 + 180
    const t160 = summatService.laskeDebetMinusKredit('160', tilikaudenSummat, affectedAccounts)
    const t175 = summatService.laskeDebetMinusKredit('175', tilikaudenSummat, affectedAccounts)
    const t176 = summatService.laskeDebetMinusKredit('176', tilikaudenSummat, affectedAccounts)
    const t180 = summatService.laskeDebetMinusKredit('180', tilikaudenSummat, affectedAccounts)
    const v716 = this._annaNumero('716', () => this._sum((t160 - t1600), t175, t176, t180))
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '716', value: this._annaLuku(v716) })
    }

    oletukset['rahoitusomaisuus-yhteensa'] = this._sum(v711, v714, v716)

    // Varat yhteensä
    // 718 = 704 + 706 + 708 + 709 + 711 + 714 + 716
    const v718 = this._annaNumero('718', () => this._sum(v704, v706, v708, v709, v711, v714, v716))
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '718', value: this._annaLuku(v718) })
    }

    // Velat
    // 749 = arvot 280
    const t280 = summatService.laskeDebetMinusKredit('280', tilikaudenSummat, affectedAccounts)
    const v749 = this._annaNumero('749', () => 0 - t280)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '749', value: this._annaLuku(v749) })
    }

    // 750 = arvot 260
    const t260 = summatService.laskeDebetMinusKredit('260', tilikaudenSummat, affectedAccounts)
    const v750 = this._annaNumero('750', () => 0 - t260)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '750', value: this._annaLuku(v750) })
    }

    // 747 (Oikaistu negatiivinen oma pääoma)
    // 652 (Oikaistu negatiivinen oma pääoma)
    // 725 (Elinkeinotoiminnan velat yhteensä)
    // 749 (Lyhytaikaiset velat)
    // 750 (Pitkäaikaiset velat)(edited)
    const v749plusv750ForCal = Math.max(this._sum(v749, v750), 0)
    const v747 = this._annaNumero('747', () => Math.min(v652, v749plusv750ForCal))
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '747', value: this._annaLuku(v747) })
    }

    // 725 = 749 + 750 - 747
    const v725 = this._annaNumero('725', () => this._sum(v749, v750) - v747)
    if (kaksinkertainenKirjanpito) {
      arvot.push({ key: '725', value: this._annaLuku(v725) })
    }

    // Elinkeinotoiminnan nettovarallisuus
    // 733 = 718 - 725 Täytetään jos positiivinen
    // 734 = 718 - 725 Täytetään jos negatiivinen
    const summaA = v718 - v725
    oletukset['elinkeinotoiminnan-nettovarallisuus'] = summaA
    if (summaA > 0) {
      if (kaksinkertainenKirjanpito) {
        arvot.push({ key: '733', value: this._annaLuku(summaA) })
      }
    } else if (summaA < 0) {
      if (kaksinkertainenKirjanpito) {
        arvot.push({ key: '734', value: this._annaLuku(Math.abs(summaA)) })
      }
    } else {
      if (kaksinkertainenKirjanpito) {
        arvot.push({ key: '733', value: this._annaLuku(0) })
        arvot.push({ key: '734', value: this._annaLuku(0) })
      }
    }

    // 871 = tili 2481
    const t2481 = summatService.laskeDebetMinusKredit('2481', tilikaudenSummat, affectedAccounts)
    const v871 = this._annaNumero('871', () => 0 - t2481)
    arvot.push({ key: '871', value: this._annaLuku(v871) })

    // Toimintavaraus ja palkat
    oletukset['toimintavaraus-ja-palkat-part'] = v871 !== 0 ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE

    // 394 = täytettävä
    const v394 = this._annaKayttajanMuokkaamaNumero('394') ?? 0
    arvot.push({ key: '394', value: this._annaLuku(v394) })

    // Pääomatulon enimmäismäärä
    // 395 = Oletuksena valittuna 20 %. = tyhjä.
    // 1 = Pääomatulon enimmäismäärä on 10 % nettovarallisuudesta, 2 = Jaettava yritystulo on kokonaan ansiotuloa (Jos vaatimusta ei esitetä, pääomatulon enimmäismäärä on 20 % nettovarallisuudesta.)
    oletukset['395'] = 'DEFAULT'
    const v395 = this._annaKayttajanMuokkaamaArvoTaiOletus('395')
    if (v395 && v395 !== oletukset['395']) {
      arvot.push({ key: '395', value: v395 + '' })
    }

    // Vaatimus tappion vähentämiseen pääomatuloista
    // ei / kyllä, jos kyllä:
    // 373 = täytettävä kenttä
    oletukset[Erikoisarvot.OSA_VAATIMUS_TAPPION_VAHENTAMISEEN_PAAOMATULOISTA] = VeroilmoituksenValueConstants.FALSE
    const vaatimusTappionVahentamisesta = tietokannasta.arvotEditable[Erikoisarvot.OSA_VAATIMUS_TAPPION_VAHENTAMISEEN_PAAOMATULOISTA] || oletukset[Erikoisarvot.OSA_VAATIMUS_TAPPION_VAHENTAMISEEN_PAAOMATULOISTA]
    if (vaatimusTappionVahentamisesta === VeroilmoituksenValueConstants.TRUE) {
      const v373 = this._annaKayttajanMuokkaamaNumero('373') ?? 0
      arvot.push({ key: '373', value: this._annaLuku(v373) })
    }

    // Yritystulo jaetaan puolisoiden kesken
    // oletuksena ei, jos kyllä:
    oletukset[Erikoisarvot.OSA_YRITYSTULON_JAKAMINEN_PUOLISON_KESKEN] = VeroilmoituksenValueConstants.FALSE
    const yritystuloJaetaan = tietokannasta.arvotEditable[Erikoisarvot.OSA_YRITYSTULON_JAKAMINEN_PUOLISON_KESKEN] || oletukset[Erikoisarvot.OSA_YRITYSTULON_JAKAMINEN_PUOLISON_KESKEN]
    if (yritystuloJaetaan === VeroilmoituksenValueConstants.TRUE) {
      // 369 = Täytettävä kenttä %
      const v369 = this._annaKayttajanMuokkaamaNumero('369') ?? 0
      arvot.push({ key: '369', value: this._annaLuku(v369) })
      // 370 = 100 % - 369
      const v370 = 100 - v369
      arvot.push({ key: '370', value: this._annaLuku(v370) })
      // 371 = Täytettävä kenttä %
      const v371 = this._annaKayttajanMuokkaamaNumero('371') ?? 0
      arvot.push({ key: '371', value: this._annaLuku(v371) })
      // 372 = 100 % - 371
      const v372 = 100 - v371
      arvot.push({ key: '372', value: this._annaLuku(v372) })
    }

    arvot.push({ key: '999', value: '1' })

    this.tietokannastaLaskennoille = null
    this.eiTallennetutLaskennoille = null
    this.oletuksetLaskennoille = null

    const erottavatTilitFilterSet = summatService.annaTilinJaLastenNumerot('237')
    erottavatTilitFilterSet.add('1910')
    erottavatTilitFilterSet.add('1920')
    erottavatTilitFilterSet.add('1930')
    erottavatTilitFilterSet.add('1999')

    const erottavatTilit = summatService.annaTilitJoissaOnErottavaSumma(affectedAccounts, tilikaudenSummat, (tili): boolean => {
      return !erottavatTilitFilterSet.has(tili)
    })

    const ret: VeroilmoituksenPaivityksenTulos = {
      arvot: arvot,
      oletukset: oletukset,
      erottavatTilit: erottavatTilit,
      virheet: null
    }

    const virheet: VeroilmoitusValidationError[] = this._validoi(arvot)
    if (virheet.length) {
      ret.virheet = virheet
    }

    return ret

  }

  private _annaKokonaiskilometrimaarat(ajoneuvot: Ajoneuvo[]): { kokonaisKm: number, tyoajoaKm: number } {
    const retVal: { kokonaisKm: number, tyoajoaKm: number } = {
      kokonaisKm: 0,
      tyoajoaKm: 0
    }
    for (const ajoneuvo of ajoneuvot) {
      retVal.kokonaisKm += ajoneuvo.kokonaisKmMaara || 0
      retVal.tyoajoaKm += ajoneuvo.tyoAjoissaKm || 0
    }
    retVal.kokonaisKm = Math.round(retVal.kokonaisKm)
    retVal.tyoajoaKm = Math.round(retVal.tyoajoaKm)
    return retVal
  }

  private _annaKayttajanMuokkaamatAjoneuvojenTyypit(): AutojenTyypit[] {

    const autoja = this._annaCheckboxArvo('379', AutojenTyypit.HENKILOAUTOJA)
    const pakuja = this._annaCheckboxArvo('379', AutojenTyypit.PAKETTIAUTOJA)
    const muita = this._annaCheckboxArvo('379', AutojenTyypit.MUITA_AJONEUVOJA)

    // If there is a value, return what ever those values dictate
    if (autoja || pakuja || muita) {
      const retVal: AutojenTyypit[] = []

      if (autoja === VeroilmoituksenValueConstants.TRUE && pakuja === VeroilmoituksenValueConstants.TRUE) {
        retVal.push(AutojenTyypit.HENKILOAUTOJA_JA_PAKETTIAUTOJA)
      } else if (autoja === VeroilmoituksenValueConstants.TRUE) {
        retVal.push(AutojenTyypit.HENKILOAUTOJA)
      } else if (pakuja === VeroilmoituksenValueConstants.TRUE) {
        retVal.push(AutojenTyypit.PAKETTIAUTOJA)
      }

      if (muita === VeroilmoituksenValueConstants.TRUE) {
        retVal.push(AutojenTyypit.MUITA_AJONEUVOJA)
      }

      return retVal
    }

    return null

  }

  // 1 = henkilöauto(ja) 2 = pakettiauto(ja) 3 = henkilöauto(ja) ja pakettiauto(ja) 4 = muita kulkuneuvoja. Yksikin muu kulkuneuvo = 4.
  private _annaAjoneuvojenTyypit(ajoneuvot: Ajoneuvo[]): AutojenTyypit[] {

    let henkiloautoja = 0
    let pakuja = 0
    let muita = 0
    for (const ajoneuvo of ajoneuvot) {
      if (ajoneuvo.tyyppi === 'muu') {
        muita++
      } else if (ajoneuvo.tyyppi === 'paketti') {
        pakuja++
      } else if (ajoneuvo.tyyppi === 'henkilo') {
        henkiloautoja++
      } else {
        throw new Error('Unknown ajoneuvo type: ' + ajoneuvo.tyyppi)
      }
    }

    const retVal: AutojenTyypit[] = []
    if (henkiloautoja > 0 && pakuja > 0) {
      retVal.push(AutojenTyypit.HENKILOAUTOJA_JA_PAKETTIAUTOJA)
    } else if (henkiloautoja > 0) {
      retVal.push(AutojenTyypit.HENKILOAUTOJA)
    } else if (pakuja > 0) {
      retVal.push(AutojenTyypit.PAKETTIAUTOJA)
    }

    if (muita > 0) {
      retVal.push(AutojenTyypit.MUITA_AJONEUVOJA)
    }

    return retVal
  }

  private _validoi(rivit: IlmoitusRivi[]): VeroilmoitusValidationError[] {

    const errors: VeroilmoitusValidationError[] = []
    const maps = this._mapValues(rivit)
    const numberMap = maps.numberMap
    const stringMap = maps.stringMap

    /**
     * TARKISTUKSET
     * 316
     *   316=300+318+301+313+314+315+320+322+326+328+323+312+324
     *   #1411;
     *   Liikevaihto (300) +
     *   Saadut avustukset ja tuet (318) +
     *   Liiketoiminnan muut tuotot (301) +
     *   Auton yksityiskäyttö (313) +
     *   Tavaroiden yksityiskäyttö (314) +
     *   Muu yksityiskäyttö (315) +
     *   Osingot julkisesti noteeratuista yhtiöistä, veronalainen osuus (320) +
     *   Osingot muista kuin julkisesti noteeratuista yhtiöistä , veronalainen osuus (322) +
     *   Ylijäämät julkisesti noteeratuista osuuskunnista kotimaasta,
     *   EU/ETA-alueelta ja maista, joiden kanssa Suomella on verosopimus, veronalainen osuus (326) +
     *   Ylijäämät muista kuin julkisesti noteeratuista osuuskunnista kotimaasta, EU/ETA-alueelta ja ETA-talousalueen ulkopuoleisista maista, veronalainen osuus (328) +
     *   Korkotuotot ja muut rahoitustuotot (323) +
     *   Varausten vähennys (312) +
     *   Muut veronalaiset tuotot (324)
     *   pitää olla yhtä suuri kuin Elinkeinotoiminnan veronalaiset tuotot yhteensä (316).
     */
    const v316CheckResult = this._sumFromMap(numberMap, '300', '318', '301', '313', '314', '315', '320', '322', '326', '328', '323', '312', '324')
    const v316CheckExpected = numberMap.get('316') ?? 0
    if (v316CheckResult !== v316CheckExpected) {
      errors.push({
        fields: '316',
        message: `316=300+318+301+313+314+315+320+322+326+328+323+312+324

Liikevaihto (300) +
Saadut avustukset ja tuet (318) +
Liiketoiminnan muut tuotot (301) +
Auton yksityiskäyttö (313) +
Tavaroiden yksityiskäyttö (314) +
Muu yksityiskäyttö (315) +
Osingot julkisesti noteeratuista yhtiöistä, veronalainen osuus (320) +
Osingot muista kuin julkisesti noteeratuista yhtiöistä, veronalainen osuus (322) +
Ylijäämät julkisesti noteeratuista osuuskunnista kotimaasta, EU / ETA - alueelta ja maista,
   joiden kanssa Suomella on verosopimus, veronalainen osuus (326) +
Ylijäämät muista kuin julkisesti noteeratuista osuuskunnista kotimaasta, EU / ETA - alueelta
   ja ETA - talousalueen ulkopuoleisista maista, veronalainen osuus (328) +
Korkotuotot ja muut rahoitustuotot (323) +
Varausten vähennys (312) +
Muut veronalaiset tuotot (324)

pitää olla yhtä suuri kuin

Elinkeinotoiminnan veronalaiset tuotot yhteensä (316).`
      })
    }

    /**
     * 355
     *   355=333+334+335+336+338+342+343+344+347+349+353+364+354
     *   #1034;
     *   Ostot ja varastojen muutokset (333) +
     *   Ulkopuoliset palvelut (334) +
     *   Palkat ja palkkiot (335) +
     *   Eläke ja henkilösivukulut (336) +
     *   Vähennyskelpoinen osuus poistoista (338) +
     *   Vähennyskelpoinen osuus 50 % (342) +
     *   Vuokrat (343) +
     *   Muut vähennyskelpoiset kulut (344) +
     *   Korkokulut vähennyskelpoinen osuus (347) +
     *   Muut rahoituskulut (349) +
     *   Varausten lisäykset (353) +
     *   Lisävähennykset (364) +
     *   Kirjanpidon ulkopuoliset vähennyskelpoiset kulut (354)
     *
     *   pitää olla yhtä suuri kuin Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä (355).
     */
    const v355CheckResult = this._sumFromMap(numberMap, '333', '334', '335', '336', '338', '342', '343', '344', '347', '349', '353', '364', '354')
    const v355CheckExpected = numberMap.get('355') ?? 0
    if (v355CheckResult !== v355CheckExpected) {
      errors.push({
        fields: '355',
        message: `355=333+334+335+336+338+342+343+344+347+349+353+364+354

Ostot ja varastojen muutokset (333) +
Ulkopuoliset palvelut (334) +
Palkat ja palkkiot (335) +
Eläke ja henkilösivukulut (336) +
Vähennyskelpoinen osuus poistoista (338) +
Vähennyskelpoinen osuus 50 % (342) +
Vuokrat (343) +
Muut vähennyskelpoiset kulut (344) +
Korkokulut vähennyskelpoinen osuus (347) +
Muut rahoituskulut (349) +
Varausten lisäykset (353) +
Lisävähennykset (364) +
Kirjanpidon ulkopuoliset vähennyskelpoiset kulut (354)

pitää olla yhtä suuri kuin

Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä (355).`
      })
    }

    /**
      * 341
      *   341 > 0 => 342 > 0
      *   #399;
      *   Jos on ilmoitettu tieto Edustuskulut (341) nollasta poikkeavalla arvolla, on ilmoitettava myös
      *   Vähennyskelpoinen osuus 50 % (342).
      */
    const v341 = numberMap.get('341') ?? 0
    const v342 = numberMap.get('342') ?? 0
    if (v341 > 0 && v342 < 0.005) {
      errors.push({
        fields: '341',
        message: `Jos on ilmoitettu tieto

Edustuskulut (341)

nollasta poikkeavalla arvolla, on ilmoitettava myös

Vähennyskelpoinen osuus 50 % (342).`
      })
    }

    /**
      * 342
      *   342 ≤ 1/2 * 341
      *   #834;
      *   Tieto Vähennyskelpoinen osuus 50 % (342) saa olla enintään 50 % tiedosta Edustuskulut (341).
      */
    if (v342 > this._currencyService.roundHalfUp(v341 / 2, 2)) {
      errors.push({
        fields: '342',
        message: 'Tieto Vähennyskelpoinen osuus 50 % (342) saa olla enintään 50 % tiedosta Edustuskulut (341).'
      })
    }

    /**
      * 342
      *   342 > 0 => 341 > 0
      *   #836;
      *   Jos on ilmoitettu tieto Vähennyskelpoinen osuus 50 % (342 ) nollasta poikkeavalla arvolla, on
      *   ilmoitettava Edustuskulut (341) nollasta poikkeavalla arvolla.
      */
    if (v342 > 0 && v341 < 0.005) {
      errors.push({
        fields: '342',
        message: `Jos on ilmoitettu tieto

Vähennyskelpoinen osuus 50 % (342)

nollasta poikkeavalla arvolla, on ilmoitettava

Edustuskulut (341)

nollasta poikkeavalla arvolla.`
      })
    }

    /**
     * 346, 347
     *   347 > 0 => 346 > 0 347 ≤ 346
     *   #837;
     *   Jos on ilmoitettu tieto Korkokulut vähennyskelpoinen osuus (347) nollasta poikkeavalla arvolla, on
     *   ilmoitettava Korkokulut (346), ja tiedon 347 oltava pienempi tai yhtä suuri kuin 346.
     */
    const v346 = numberMap.get('346') ?? 0
    const v347 = numberMap.get('347') ?? 0
    if (v347 > 0 && (v346 < 0.005 || v347 > v346)) {
      errors.push({
        fields: '346, 347',
        message: `Jos on ilmoitettu tieto

Korkokulut vähennyskelpoinen osuus (347)

nollasta poikkeavalla arvolla, on ilmoitettava

Korkokulut (346)

ja tiedon 347 oltava pienempi tai yhtä suuri kuin 346.`
      })
    }

    /**
     * 392, 388, 364
     *   364>0, 392≥0 tai 388≥0 ja 364 = 392 + 388
     *   #401;
     *   Jos on ilmoitettu tieto Lisävähennykset (364) nollasta poikkeavalla arvolla, on ilmoitettava joko Lisävähennys
     *   yksityisvaroihin kuuluvan auton käytöstä (392) tai Tilapäisistä työmatkoista aiheutuneet lisävähennykset yhteensä (388)
     *   tieto nollasta poikkeavalla arvolla.
     *   #402;
     *   Lisävähennykset (364) on oltava yhtä suuri kuin Lisävähennys yksityisvaroihin kuuluvan auton käytöstä (392)
     *   ja Tilapäisistä työmatkoista aiheutuneet lisävähennykset yhteensä (388) yhteensä.
     */
    const v392 = numberMap.get('392') ?? 0
    const v388 = numberMap.get('388') ?? 0
    const v364 = numberMap.get('364') ?? 0
    if (v364 > 0) {

      // #401
      if (v388 < 0.005 && v392 < 0.005) {
        errors.push({
          fields: '364, 392, 388',
          message: `Jos on ilmoitettu tieto

Lisävähennykset (364)

nollasta poikkeavalla arvolla, on ilmoitettava joko

Lisävähennys yksityisvaroihin kuuluvan auton käytöstä (392) tai
Tilapäisistä työmatkoista aiheutuneet lisävähennykset yhteensä (388)

tieto nollasta poikkeavalla arvolla.`
        })
      }

      // #402
      if (this._currencyService.roundHalfUp(v364, 2) !== this._currencyService.roundHalfUp(v388 + v392, 2)) {
        errors.push({
          fields: '364, 392, 388',
          message: `Lisävähennykset (364)

on oltava yhtä suuri kuin

Lisävähennys yksityisvaroihin kuuluvan auton käytöstä (392) +
Tilapäisistä työmatkoista aiheutuneet lisävähennykset yhteensä (388)`
        })
      }

    }

    /**
     * 358, 359
     *   358=316-355 jos tulos positiivinen
     *   359=-(316-355) jos tulos negatiivinen
     *   Elinkeinotoiminnan veronalaiset tuotot yhteensä (316) – Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä (355)
     *   on oltava yhtä suuri kuin Elinkeinotoiminnan tulos (358) tai Elinkeinotoiminnan tappio (359). Tunnuksille 358 ja 359 ei sallita
     *   negatiivisia arvoja, joten jos laskutulos on negatiivinen, miinusmerkki jätetään pois
     *   #403;
     *   Elinkeinotoiminnan veronalaiset tuotot yhteensä (316) – Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä (355) on
     *   oltava yhtä suuri kuin Elinkeinotoiminnan tulos (358) tai Elinkeinotoiminnan tappio (359).
     */
    const v358 = numberMap.get('358') ?? 0
    const v359 = numberMap.get('359') ?? 0
    const v316 = numberMap.get('316') ?? 0
    const v355 = numberMap.get('355') ?? 0

    const erotus = v316 - v355
    // console.log('v316(', v316, ') - v355(', v355, ') = ', this._annaLuku(erotus))
    // console.log('v358(', v358, ') - v359(', v359, ')')
    let error = erotus !== 0
    if (erotus > 0) {
      error = this._annaLuku(v358) !== this._annaLuku(erotus)
    } else if (erotus < 0) {
      error = this._annaLuku(v359) !== this._annaLuku(-erotus)
    }

    if (error) {

      // #403
      errors.push({
        fields: v358 > 0 ? '358, 316, 355' : '359, 316, 355',
        message: `Elinkeinotoiminnan veronalaiset tuotot yhteensä (316) –
Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä (355)

on oltava yhtä suuri kuin

Elinkeinotoiminnan tulos (358) tai
Elinkeinotoiminnan tappio (359)`
      })

    }

    /**
     * 358, 359
     *   #802;
     *   Vain toisessa tiedoista Elinkeinotoiminnan tulos (358) tai Elinkeinotoimin- nan tappio (359) voi olla nollasta poikkeava arvo.
     */
    if (v358 > 0 && v359 > 0) {

      // #802
      errors.push({
        fields: '358, 359',
        message: `Vain toisessa tiedoista

Elinkeinotoiminnan tulos (358) tai
Elinkeinotoiminnan tappio (359)

voi olla nollasta poikkeava arvo.`
      })

    }

    /**
     * 369
     *   369 + 370 = 100 %
     *   Jos tiedossa Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) tai Yrittäjäpuolison osuus elinkeinotoiminnan
     *   nettovarallisuudesta(370) on ilmoitettu osuuksia, on Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) +
     *   Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta(370) = 100 %.Tietoon 370 ei tarvitse ilmoittaa 0 - tietoa.
     *   #408;
     *   Jos tiedossa Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) tai Yrittäjäpuolison osuus elinkeinotoiminnan
     *   nettovarallisuudesta(370) on ilmoitettu osuuksia, on Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) +
     *   Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta(370) oltava = 100 %.
     */
    const v369 = numberMap.get('369') || null
    const v370 = numberMap.get('370') || null
    if ((v369 !== null || v370 !== null) && v369 + v370 !== 100) {
      // #408
      errors.push({
        fields: '369, 370',
        message: `Jos tiedossa

Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) tai
Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta(370)

on ilmoitettu osuuksia, on

Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta(369) +
Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta(370)

oltava = 100 %.`
      })
    }

    /**
     * 371
     *   371 + 372 = 100 %
     *   Jos tiedossa Yrittäjän työskentely liikkeessä(371) tai Yrittäjäpuolison työskentely liikkeessä(372) on ilmoitettu osuuksia,
     *   on Yrittäjän työskentely liikkeessä(371) + Yrittäjäpuolison työskentely liikkeessä(372) = 100 %. Tietoon 372 ei tarvitse
     *   ilmoittaa 0 - tietoa.
     *   #409;
     *   Jos tiedossa Yrittäjän työskentely liikkeessä(371) tai Yrittäjäpuolison työskentely liikkeessä(372) on ilmoitettu osuuksia,
     *   on Yrittäjän työskentely liikkeessä(371) + Yrittäjäpuolison työskentely liikkeessä(372) oltava = 100 %.
     */
    const v371 = numberMap.get('371') ?? 0
    const v372 = numberMap.get('372') ?? 0
    if ((v371 > 0 || v372 > 0) && v371 + v372 !== 100) {
      // #409
      errors.push({
        fields: '371, 372',
        message: `Jos tiedossa

Yrittäjän työskentely liikkeessä(371) tai
Yrittäjäpuolison työskentely liikkeessä(372)

on ilmoitettu osuuksia, on

Yrittäjän työskentely liikkeessä(371) +
Yrittäjäpuolison työskentely liikkeessä(372)

oltava = 100 %.`
      })
    }

    /**
     * 804 ei voi olla negatiivinen
     */
    const v804 = numberMap.get('804') ?? 0
    if (v804 < 0) {
      errors.push({
        fields: '804',
        message: 'Verovuoden poistot (804) ei voi olla negatiivinen'
      })
    }

    /**
     * 800, 801, 802, 806, 804, 805
     *   Jos 800 + 801 ≥802 -> 806 = 800 + 801 - 802 - 804 - 805
     *   #1456;
     *   Jos Verotuksessa jäljellä oleva menojäännös verovuoden alussa(800) + Lisäys verovuoden aikana(801) on suurempi tai yhtäsuuri
     *   kuin Luovutushinnat ja vakuutuskorvaukset(802) Verotuksessa jäljellä oleva menojäännös verovuoden lopussa(806) on oltava yhtä suuri
     *   kuin Verotuksessa jäljellä oleva menojäännös verovuoden alussa(800) + Lisäys verovuoden aikana(801) – Luovutushinnat ja
     *   vakuutuskorvaukset(802) – Verovuoden poistot(804) – Lisäpoisto(805)
     */
    const v800 = numberMap.get('800') ?? 0
    const v801 = numberMap.get('801') ?? 0
    const v802 = numberMap.get('802') ?? 0
    const v805 = numberMap.get('805') ?? 0
    const v806 = numberMap.get('806') ?? 0
    if (v800 + v801 >= v802) {
      if (this._annaLuku(v806) !== this._annaLuku(v800 + v801 - v802 - v804 - v805)) {
        // #1456
        errors.push({
          fields: '800, 801, 802, 804, 805, 806',
          message: `Jos

Verotuksessa jäljellä oleva menojäännös verovuoden alussa (800) +
Lisäys verovuoden aikana (801)

on suurempi tai yhtäsuuri kuin

Luovutushinnat ja vakuutuskorvaukset (802)

Verotuksessa jäljellä oleva menojäännös verovuoden lopussa (806)

on oltava yhtä suuri kuin

Verotuksessa jäljellä oleva menojäännös verovuoden alussa (800) +
Lisäys verovuoden aikana (801) -
Luovutushinnat ja vakuutuskorvaukset (802) -
Verovuoden poistot (804) -
Lisäpoisto (805)`
        })
      }
    }

    /**
     * 804
     *   Ehdollinen pakollisuus jos 804 > 0 myös 338 ≥ 0
     *   #424;
     *   Jos on ilmoitettu tieto Verovuoden poistot(804) nollasta poikkeavalla arvolla, on ilmoitettava myös
     *   Vähennyskelpoinen osuus poistoista(338).Jos tiedolla 338 ei ole ilmoitettavaa, annetaan tieto nollana.
     * 804
     *   Ehdollinen pakollisuus jos 804 > 0 myös 800 > 0 tai 801 > 0
     *   #425;
     *   Jos on ilmoitettu tieto Verovuoden poistot(804) nollasta poikkeavalla arvolla, on ilmoitettava myös
     *   nollasta poikkeavaa arvoa tiedoissa Verotuksessa jäljellä oleva menojäännös verovuoden alussa(800) tai
     *   Lisäys verovuoden aikana(801).
     */
    const v338m = numberMap.get('338') ?? -1
    if (v804 > 0) {
      // #424
      if (v338m < 0.005) {
        errors.push({
          fields: '804, 338',
          message: `Jos on ilmoitettu tieto

Verovuoden poistot (804)

nollasta poikkeavalla arvolla, on ilmoitettava myös

Vähennyskelpoinen osuus poistoista (338).

Jos tiedolla 338 ei ole ilmoitettavaa, annetaan tieto nollana.`
        })
      }
      // #425
      if (v800 < 0.005 && v801 < 0.005) {
        errors.push({
          fields: '804, 800, 801',
          message: `Jos on ilmoitettu tieto

Verovuoden poistot (804)

nollasta poikkeavalla arvolla, on ilmoitettava myös nollasta poikkeavaa arvoa tiedoissa

Verotuksessa jäljellä oleva menojäännös verovuoden alussa (800) tai
Lisäys verovuoden aikana (801).`
        })
      }
    }

    /**
     * 382
     *   Ehdollinen pakollisuus jos 382 > 0 ilmoitettava 396
     *   #339; Jos on ilmoitettu tieto Yksityisajojen osuus kuluista (km x kulut keskimäärin) (382) nollasta poikkeavalla arvolla,
     *   on ilmoitettava myös Yksityisajojen kulut on vähennetty / ei ole vähennetty kirjanpidossa menona (396).
     */
    const v382 = numberMap.get('382') ?? 0
    const v396 = stringMap.get('396')
    if (v382 > 0 && !v396) {
      errors.push({
        fields: '382, 396',
        message: `Jos on ilmoitettu tieto

Yksityisajojen osuus kuluista (km x kulut keskimäärin) (382)

nollasta poikkeavalla arvolla, on ilmoitettava myös

Yksityisajojen kulut on vähennetty / ei ole vähennetty kirjanpidossa menona (396).`
      })
    }

    /**
     * 396, 313
     *   Ehdollinen pakollisuus jos 396 = 1 313 > 0 313 = 382
     *   #824;
     *   Jos on ilmoitettu Yksityisajojen kulut on vähennetty kirjanpidossa menona(396) arvoksi 1, on ilmoitettava myös
     *   tieto Auton yksityiskäyttö (313), ja tiedon 313 oltava yhtä suuri kuin Yksityisajojen osuus kuluista (km x kulut keskimäärin (382).
     */
    const v313 = numberMap.get('313') ?? null
    if (v396 === '1') {
      if (v313 === null || v313 !== v382) {
        errors.push({
          fields: '313, 396',
          message: `
Jos on ilmoitettu Yksityisajojen kulut on vähennetty kirjanpidossa menona (396)
arvoksi 1, on ilmoitettava myös tieto Auton yksityiskäyttö (313), ja tiedon 313
oltava yhtä suuri kuin Yksityisajojen osuus kuluista (km x kulut keskimäärin (382).`
        })
      }
    }

    /**
     * 389
     *   Ehdollinen pakollisuus.Jos 390 > 0 myös 389 > 0 389 ≥ 390
     *   #825;
     *   Jos on ilmoitettu tieto Elinkeinotoiminnan ajot (km) (390) nollasta poikkeavalla arvolla, on ilmoitettava Kokonaiskilometrimäärä (km) (389). Tiedon 389 on oltava suurempi tai yhtä suuri kuin tieto 390.
     */
    const v390 = numberMap.get('390') ?? 0
    const v389 = numberMap.get('389') ?? 0
    if (v390 > 0) {
      if (v389 < 0.005 || v389 < v390) {
        errors.push({
          fields: '389, 390',
          message: `Jos on ilmoitettu tieto

Elinkeinotoiminnan ajot (km) (390)

nollasta poikkeavalla arvolla, on ilmoitettava

Kokonaiskilometrimäärä (km) (389).

Tiedon 389 on oltava suurempi tai yhtä suuri kuin tieto 390.`
        })
      }
    }

    /**
     * 718
     *   718 = 703 + 704 + 705 + 706 + 708 + 709 + 714 + 745 + 711 + 716
     *   #25;
     *
     *   Kiinteistöt, rakennukset ja rakennelmat (703) +
     *   Koneet ja kalusto (704) +
     *   Käyttöomaisuusarvopaperit (705) +
     *   Muu käyttöomaisuus (706) +
     *   Tavarat (708) +
     *   Muu vaihto - omaisuus (709) +
     *   Rahat (käteisvarat, ei pankkitalletuksia) (714) +
     *   Rahoitusomaisuusarvopaperit (745) +
     *   Myyntisaamiset (711) +
     *   Muu rahoitus - omaisuus (ei pankkitalletuksia) (716)
     *
     *   pitää olla yhtä suuri kuin Elinkeinotoiminnan varat yhteensä (718).
     */
    const v718 = numberMap.get('718') ?? 0
    // const v703 = numberMap.get('703') ?? 0
    // const v704 = numberMap.get('704') ?? 0
    // const v705 = numberMap.get('705') ?? 0
    // const v706 = numberMap.get('706') ?? 0
    // const v708 = numberMap.get('708') ?? 0
    // const v709 = numberMap.get('709') ?? 0
    // const v714 = numberMap.get('714') ?? 0
    // const v745 = numberMap.get('745') ?? 0
    // const v711 = numberMap.get('711') ?? 0
    // const v716 = numberMap.get('716') ?? 0
    const s = this._sumFromMap(numberMap, '703', '704', '705', '706', '708', '709', '714', '745', '711', '716')
    if (v718 !== s) {
      errors.push({
        fields: '718',
        message: `Kiinteistöt, rakennukset ja rakennelmat (703) +
Koneet ja kalusto (704) +
Käyttöomaisuusarvopaperit (705) +
Muu käyttöomaisuus (706) +
Tavarat (708) +
Muu vaihto - omaisuus (709) +
Rahat (käteisvarat, ei pankkitalletuksia) (714) +
Rahoitusomaisuusarvopaperit (745) +
Myyntisaamiset (711) +
Muu rahoitus - omaisuus (ei pankkitalletuksia) (716)

pitää olla yhtä suuri kuin Elinkeinotoiminnan varat yhteensä (718).`
      })
    }

    /**
     * 383
     *   Ehdollinen pakollisuus 747 > 0 383 ≥0
     *   #838;
     *   Jos ilmoitettu tieto Oikaistu negatiivinen oma pääoma (747) nollasta poikkeavalla arvolla, on ilmoitettava Korot,
     *   jotka eivät ole vähennyskelpoisia elinkeinotoiminnan tulolähteen tulosta (383). Jos tiedolla 383 ei ole ilmoitettavaa,
     *   annetaan tieto nollana.
     */
    const v747 = numberMap.get('747') ?? 0
    const v383m = numberMap.get('383') ?? -1
    if (v747 > 0 && v383m < 0.00) {
      errors.push({
        fields: '383',
        message: `Jos ilmoitettu tieto

Oikaistu negatiivinen oma pääoma (747)

nollasta poikkeavalla arvolla, on ilmoitettava

Korot, jotka eivät ole vähennyskelpoisia elinkeinotoiminnan tulolähteen tulosta (383).

Jos tiedolla 383 ei ole ilmoitettavaa, annetaan tieto nollana.`
      })
    }

    /**
     * 748, 741, 742, 743
     *   Ehdollinen pakollisuus
     *   Jos 916 = 1 on ilmoitettava 748≥0 741≥0 742≥0 743≥0
     *   #806;
     *   Jos on ilmoitettu tieto Kahdenkertainen kirjanpito (916), on ilmoitettava tiedot
     *   (Oma pääoma tilikauden alussa (748) ja / tai Yksityisnostot / -sijoitukset (741)
     *   ja / tai Tilikauden voitto / tappio(kirjanpito) (742)) ja Oma pääoma tilikauden lopussa (743).
     *   Jos tiedolla 748 ja / tai 741 ja / tai 742 ja 743 ei ole ilmoitettavaa, annetaan tieto nollana.
     */
    const v916 = stringMap.get('916')
    const v748n = numberMap.get('748') ?? null
    const v741n = numberMap.get('741') ?? null
    const v742n = numberMap.get('742') ?? null
    const v743n = numberMap.get('743') ?? null
    if (v916 === '1') {
      if (v748n === null || v741n === null || v742n === null || v743n === null) {
        errors.push({
          fields: '748, 741, 742, 743',
          message: `Jos on ilmoitettu tieto

Kahdenkertainen kirjanpito (916),

on ilmoitettava tiedot

(
  Oma pääoma tilikauden alussa (748) ja / tai
  Yksityisnostot / -sijoitukset (741) ja / tai
  Tilikauden voitto / tappio (kirjanpito) (742)
)
ja Oma pääoma tilikauden lopussa (743).

Jos tiedolla 748 ja / tai 741 ja / tai 742 ja 743 ei ole ilmoitettavaa, annetaan
tieto nollana.`
        })
      }
    }

    /**
     * 743
     *   916 = 1
     *   743 = 748 + 741 + 742
     *   Jos on ilmoitettu tieto Kahdenkertainen kirjanpito (916), Oma pääoma tilikauden alussa(748) +
     *   Yksityisnostot / -sijoitukset(741) + Tilikauden voitto / tappio(kirjanpito) (742) on yhtä suuri
     *   kuin Oma pääoma tilikauden lopussa (743).
     *   #410;
     *   Jos on ilmoitettu tieto Kahdenkertainen kirjanpito (916), Oma pääoma tilikauden alussa (748) +
     *   Yksityisnostot / -sijoitukset(741) + Tilikauden voitto / tappio(kirjanpito) (742) on yhtä suuri
     *   kuin Oma pääoma tilikauden lopussa (743).
     */
    const v743Sum = this._sumFromMap(numberMap, '748', '741', '742')
    const v743 = numberMap.get('743') ?? 0
    if (v916 === '1' && v743Sum !== v743) {
      errors.push({
        fields: '743',
        message: `Jos on ilmoitettu tieto

Kahdenkertainen kirjanpito (916),

Oma pääoma tilikauden alussa (748) +
Yksityisnostot / -sijoitukset (741) +
Tilikauden voitto / tappio(kirjanpito) (742)

on yhtä suuri kuin

Oma pääoma tilikauden lopussa (743).`
      })
    }

    /**
     * 871
     *   871 > 394x0,3
     *   Jos tiedon Verovuoden 2020 toimintavaraus (871) arvo on yli 30 % tiedon Verovuoden 2020 päättymistä edeltäneiden
     *   12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat (394) arvosta, annetaan virheilmoitus.
     *   #1320;
     *   Verovuonna ja aikaisemmin tehtyjen purkamattomien toimintavarausten yhteismäärä ei saa ylittää 30 %:a niistä palkoista,
     *   jotka on maksettu tilikauden päättymistä edeltäneiden 12 kuukauden aikana.
     */
    const v871 = numberMap.get('871') ?? 0
    const v394 = numberMap.get('394') ?? 0
    if (v871 > v394 * 0.3) {
      errors.push({
        fields: '871',
        message: `Verovuonna ja aikaisemmin tehtyjen purkamattomien toimintavarausten yhteismäärä
ei saa ylittää 30 %:a niistä palkoista, jotka on maksettu tilikauden päättymistä
edeltäneiden 12 kuukauden aikana.`
      })
    }

    /**
     * 733#734
     *   733 tai 734
     *   #991;
     *   Vain toisessa tiedoista Elinkeinotoiminnan positiivinen nettovarallisuus (733) tai Elinkeinotoiminnan
     *   negatiivinen nettovarallisuus (734) voi olla nollasta poikkeava arvo.
     */
    this.validateVainJompikumpiKentista('733', '734', errors, numberMap, `Vain toisessa tiedoista

Elinkeinotoiminnan positiivinen nettovarallisuus (733) tai
Elinkeinotoiminnan negatiivinen nettovarallisuus (734)

voi olla nollasta poikkeava arvo.`)


    /**
     * 969, 041, 042, 044, 048, 014
     *   #1446;
     *   Jos tieto Ilmoitettavia tietoja ei ole (1 = kyllä) (969) on annettu, ei muita kuin pakollisia tietoja,
     *   veroilmoitusta täydentävän henkilön tietoja (041, 044, 042), ohjelmistotietoa (048) ja Ilmoituksen
     *   tuottaneen ohjelmiston yksilöivä tieto (014) saa antaa.
     */
    const v969 = stringMap.get('969')
    if (v969 === '1') {
      let e: boolean = false
      for (const rivi of rivit) {
        if (rivi.key !== '041' && rivi.key !== '042' && rivi.key !== '044' && rivi.key !== '048' && rivi.key !== '014') {
          e = true
          break
        }
      }
      if (e) {
        errors.push({
          fields: '969, 041, 042, 044, 048, 014',
          message: `Jos tieto

Ilmoitettavia tietoja ei ole (1 = kyllä) (969)

on annettu, ei muita kuin pakollisia tietoja,

veroilmoitusta täydentävän henkilön tietoja (041, 044, 042),
ohjelmistotietoa (048) ja
Ilmoituksen tuottaneen ohjelmiston yksilöivä tieto (014)

saa antaa.`
        })
      }
    }

    /**
     * 316, 355
     *   358 tai 359
     *   #839;
     *   Jos jompikumpi tiedoista Elinkeinotoiminnan veronalaiset tuotot yhteensä (316) tai Elinkeinotoiminnan verotuksessa
     *   vähennyskelpoiset kulut yhteensä(355) on ilmoitettu, pitää ilmoittaa myös Elinkeinotoiminnan tulos (358) tai
     *   Elinkeinotoiminnan tappio (359) (molempiin kelpaa myös nolla).
     */
    if (v316 > 0 || v355 > 0) {
      const v358m = numberMap.get('358') ?? -1
      const v359m = numberMap.get('359') ?? -1
      if (v358m < 0.005 && v359m < 0.005 && (v358m < 0 || v359m < 0)) {
        errors.push({
          fields: '316, 355',
          message: `Jos jompikumpi tiedoista

Elinkeinotoiminnan veronalaiset tuotot yhteensä (316) tai
Elinkeinotoiminnan verotuksessa vähennyskelpoiset kulut yhteensä(355)

on ilmoitettu, pitää ilmoittaa myös

Elinkeinotoiminnan tulos (358) tai
Elinkeinotoiminnan tappio (359)

(molempiin kelpaa myös nolla).`
        })
      }
    }

    /**
     * 733 tai 734
     *   #835;
     *   Jos jompikumpi tiedoista Elinkeinotoiminnan varat yhteensä (718) tai Elinkeinotoiminnan velat yhteensä (725) on ilmoitettu,
     *   pitää ilmoittaa myös Elinkeinotoiminnan positiivinen nettovarallisuus (733) tai Elinkeinotoiminnan negatiivinen
     *   nettovarallisuus (734) (molempiin kelpaa myös nolla).
     */
    const v725n = numberMap.get('725') ?? null
    const v718n = numberMap.get('718') ?? null
    if (v718n !== null || v725n !== null) {
      const v733n = numberMap.get('733') ?? null
      const v734n = numberMap.get('734') ?? null
      if (v733n === null && v734n === null) {
        errors.push({
          fields: '733, 734',
          message: `Jos jompikumpi tiedoista

Elinkeinotoiminnan varat yhteensä (718) tai
Elinkeinotoiminnan velat yhteensä (725)

on ilmoitettu, pitää ilmoittaa myös

Elinkeinotoiminnan positiivinen nettovarallisuus (733) tai
Elinkeinotoiminnan negatiivinen nettovarallisuus (734)

(molempiin kelpaa myös nolla).`
        })
      }
    }

    /**
     * 749, 750, 725
     *   #1177;
     *   Jos jompikumpi tiedoista Lyhytaikaiset velat (749) tai Pitkäaikaiset velat (750) on annettu, on ilmoitettava myös
     *   velat yhteensä Elinkeinotoiminnan velat yhteensä (725).
     */
    const v749 = numberMap.get('749') ?? 0
    const v750 = numberMap.get('750') ?? 0
    // console.log(v749, v750)
    if (v749 > 0 || v750 > 0) {
      const v725m = numberMap.get('725') ?? -1
      // console.log(v725m)
      if (v725m < 0) {
        errors.push({
          fields: '749, 750, 725',
          message: `Jos jompikumpi tiedoista

Lyhytaikaiset velat (749) tai
Pitkäaikaiset velat (750)

on annettu, on ilmoitettava myös velat yhteensä

Elinkeinotoiminnan velat yhteensä (725).`
        })
      }
    }

    /**
     * 747, 749, 750
     *   747 > 749 + 750
     *   #1406;
     *   Tiedon Oikaistu negatiivinen oma pääoma (747) ei saa olla suurempi kuin tietojen Lyhytaikaiset velat (749) ja
     *   Pitkäaikaiset velat (750) summa.
     */
    // console.log(v747, v749, v750)
    if (v747 > v749 + v750) {
      errors.push({
        fields: '747, 750, 749',
        message: `Tiedon

Oikaistu negatiivinen oma pääoma (747)

ei saa olla suurempi kuin tietojen

Lyhytaikaiset velat (749) ja
Pitkäaikaiset velat (750)

summa.`
      })
    }

    this._tarkistaR13Pilkku2('364', numberMap, errors)
    this._tarkistaR13Pilkku2('358', numberMap, errors)
    this._tarkistaR13Pilkku2('359', numberMap, errors)
    this._tarkistaR13Pilkku2('373', numberMap, errors)
    this._tarkistaR13Pilkku2('800', numberMap, errors)
    this._tarkistaR13Pilkku2('801', numberMap, errors)
    this._tarkistaR13Pilkku2('802', numberMap, errors)
    this._tarkistaR13Pilkku2('804', numberMap, errors)
    this._tarkistaR13Pilkku2('805', numberMap, errors)
    this._tarkistaR13Pilkku2('806', numberMap, errors)

    this._tarkistaR13Pilkku2('381', numberMap, errors)
    this._tarkistaR13Pilkku2('275', numberMap, errors)
    this._tarkistaR13Pilkku2('382', numberMap, errors)
    this._tarkistaR13Pilkku2('384', numberMap, errors)
    this._tarkistaR13Pilkku2('385', numberMap, errors)

    this._tarkistaR13Pilkku2('433', numberMap, errors)
    this._tarkistaR13Pilkku2('386', numberMap, errors)

    this._tarkistaR13Pilkku2('402', numberMap, errors)
    this._tarkistaR13Pilkku2('403', numberMap, errors)
    this._tarkistaR13Pilkku2('404', numberMap, errors)
    this._tarkistaR13Pilkku2('405', numberMap, errors)
    this._tarkistaR13Pilkku2('407', numberMap, errors)
    this._tarkistaR13Pilkku2('408', numberMap, errors)
    this._tarkistaR13Pilkku2('409', numberMap, errors)
    this._tarkistaR13Pilkku2('410', numberMap, errors)
    this._tarkistaR13Pilkku2('413', numberMap, errors)
    this._tarkistaR13Pilkku2('414', numberMap, errors)
    this._tarkistaR13Pilkku2('415', numberMap, errors)

    this._tarkistaR13Pilkku2('387', numberMap, errors)
    this._tarkistaR13Pilkku2('388', numberMap, errors)

    this._tarkistaR13Pilkku2('419', numberMap, errors)
    this._tarkistaR13Pilkku2('420', numberMap, errors)

    this._tarkistaR13Pilkku2('391', numberMap, errors)
    this._tarkistaR13Pilkku2('392', numberMap, errors)

    this._tarkistaR13Pilkku2('659', numberMap, errors)
    this._tarkistaR13Pilkku2('677', numberMap, errors)
    this._tarkistaR13Pilkku2('650', numberMap, errors)
    this._tarkistaR13Pilkku2('651', numberMap, errors)
    this._tarkistaR13Pilkku2('652', numberMap, errors)
    this._tarkistaR13Pilkku2('383', numberMap, errors)

    this._tarkistaR13Pilkku2('703', numberMap, errors)
    this._tarkistaR13Pilkku2('704', numberMap, errors)
    this._tarkistaR13Pilkku2('705', numberMap, errors)
    this._tarkistaR13Pilkku2('706', numberMap, errors)

    this._tarkistaR13Pilkku2('708', numberMap, errors)
    this._tarkistaR13Pilkku2('709', numberMap, errors)

    this._tarkistaR13Pilkku2('749', numberMap, errors)
    this._tarkistaR13Pilkku2('750', numberMap, errors)
    this._tarkistaR13Pilkku2('747', numberMap, errors)
    this._tarkistaR13Pilkku2('725', numberMap, errors)

    this._tarkistaR13Pilkku2('733', numberMap, errors)
    this._tarkistaR13Pilkku2('734', numberMap, errors)

    this._tarkistaR13Pilkku2('871', numberMap, errors)
    this._tarkistaR13Pilkku2('394', numberMap, errors)
    this._tarkistaR13Pilkku2('393', numberMap, errors)

    this._tarkistaPlusD3Pilkku2('369', numberMap, errors)
    this._tarkistaPlusD3Pilkku2('370', numberMap, errors)
    this._tarkistaPlusD3Pilkku2('371', numberMap, errors)
    this._tarkistaPlusD3Pilkku2('372', numberMap, errors)
    this._tarkistaPlusD3Pilkku2('431', numberMap, errors)
    this._tarkistaPlusD3Pilkku2('432', numberMap, errors)

    this._tarkistaPlusN8('271', numberMap, errors)
    this._tarkistaPlusN8('273', numberMap, errors)
    this._tarkistaPlusN8('274', numberMap, errors)
    this._tarkistaPlusN8('389', numberMap, errors)
    this._tarkistaPlusN8('390', numberMap, errors)

    return errors

  }

}
