import { TuettuKieli } from '../../_shared-core/model/common'
import { Paatilikartta, Kirjanpitotili, AsiakkaanTilikartta } from '../model/kirjanpito'

export interface HierarkiaKirjanpitotili {
  kirjanpitotili: Kirjanpitotili
  lapset: HierarkiaKirjanpitotili[]
}

interface AsiakkaanTilikarttaJaPuretutTilit {
  tilikartta: AsiakkaanTilikartta
  tilit: Kirjanpitotili[]
}

export class TilikarttaJaettuService {

  constructor() { }

  annaKirjanpitotilinNimi(tili: Kirjanpitotili, kieli: TuettuKieli): string {
    if (tili?.localisedName && tili.localisedName[kieli]) {
      return tili.localisedName[kieli]
    }
    return tili?.nimi ?? ''
  }

  annaHierarkiaTili(tili: Kirjanpitotili, tilit: Kirjanpitotili[]): HierarkiaKirjanpitotili {
    const hierarkia: HierarkiaKirjanpitotili = {
      kirjanpitotili: tili,
      lapset: []
    }
    const lapset = this.annaTilinLapset(tili, tilit)
    for (const lapsi of lapset) {
      hierarkia.lapset.push(this.annaHierarkiaTili(lapsi, tilit))
    }
    hierarkia.lapset.sort((a, b) => {
      return Number(a.kirjanpitotili.numero) - Number(b.kirjanpitotili.numero)
    })
    return hierarkia
  }

  private annaTilinLapset(tili: Kirjanpitotili, tilit: Kirjanpitotili[]): Kirjanpitotili[] {
    return tilit.filter(a => a.vanhempi === tili.numero)
  }

  muutaTililistausHierarkiaksi(tilit: Kirjanpitotili[]): HierarkiaKirjanpitotili[] {
    const p: HierarkiaKirjanpitotili[] = []
    for (const tili of tilit) {
      if (!tili.vanhempi) {
        p.push(this.annaHierarkiaTili(tili, tilit))
      }
    }
    return p.sort((a, b) => {
      return Number(a.kirjanpitotili.numero) - Number(b.kirjanpitotili.numero)
    })
  }

  private _lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsi: HierarkiaKirjanpitotili, tilit: Kirjanpitotili[]) {
    tilit.push(lapsi.kirjanpitotili)
    if (lapsi.lapset) {
      for (const lapsenlapsi of lapsi.lapset) {
        this._lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsenlapsi, tilit)
      }
    }
  }

  annaKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const tilit: Kirjanpitotili[] = []
    for (const hierarkia of kaikki) {
      tilit.push(hierarkia.kirjanpitotili)
      if (hierarkia.lapset) {
        for (const lapsi of hierarkia.lapset) {
          this._lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsi, tilit)
        }
      }
    }
    return tilit
  }

  /**
   * Yhdistää päätilikartan asiakkaan tilimodifikaatioihin. Ei muokkaa päätilikarttaa
   * @param paatilikartta
   * @param asiakkaanTilikartta
   * @returns
   */
  yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta: Paatilikartta, asiakkaanTilikartta: AsiakkaanTilikartta): Kirjanpitotili[] {
    const paatilikartanTilitAlkuperaiset: Kirjanpitotili[] = paatilikartta && paatilikartta.tilit ? Object.keys(paatilikartta.tilit).map(key => paatilikartta.tilit[key]) : []
    // TODO: kopioi tilin PAITSI localizedName. Siitä pitäisi myös tehdä kopio
    const paatilikartanTilit: Kirjanpitotili[] = paatilikartanTilitAlkuperaiset.map(tili => { return { vanhempi: tili.vanhempi, numero: tili.numero, nimi: tili.nimi, oletusAlvKasittely: tili.oletusAlvKasittely, aktiivinen: tili.aktiivinen, alvTyyppi: tili.alvTyyppi, localisedName: tili.localisedName, reskontraActive: tili.reskontraActive } })
    const asiakkanTilikarttaJaPuretutTilit: AsiakkaanTilikarttaJaPuretutTilit = {
      tilikartta: asiakkaanTilikartta,
      tilit: asiakkaanTilikartta && asiakkaanTilikartta.tilit ? Object.keys(asiakkaanTilikartta.tilit).map(key => asiakkaanTilikartta.tilit[key]) : []
    }

    const kaikkiTilit = paatilikartanTilit.concat(asiakkanTilikarttaJaPuretutTilit.tilit)

    if (asiakkaanTilikartta && asiakkaanTilikartta.yliajotilit) {
      for (const tili of kaikkiTilit) {
        if (asiakkaanTilikartta.yliajotilit[tili.numero]) {
          tili.nimi = asiakkaanTilikartta.yliajotilit[tili.numero].nimi

          tili.localisedName = asiakkaanTilikartta.yliajotilit[tili.numero].localisedName ?? { fi: null, en: null }

          if (asiakkaanTilikartta.yliajotilit[tili.numero].alvTyyppi !== undefined) {
            tili.alvTyyppi = asiakkaanTilikartta.yliajotilit[tili.numero].alvTyyppi
          }
          if (asiakkaanTilikartta.yliajotilit[tili.numero].oletusAlvKasittely !== undefined) {
            tili.oletusAlvKasittely = asiakkaanTilikartta.yliajotilit[tili.numero].oletusAlvKasittely
          }
          if (asiakkaanTilikartta.yliajotilit[tili.numero].reskontraActive !== undefined) {
            tili.reskontraActive = asiakkaanTilikartta.yliajotilit[tili.numero].reskontraActive
          }
        }
      }
    }

    return kaikkiTilit
  }

  etsiTiliHierarkiasta(numero: string, kaikki: HierarkiaKirjanpitotili[]): HierarkiaKirjanpitotili | null {
    for (const hierarkia of kaikki) {
      if (hierarkia.kirjanpitotili.numero === numero) {
        return hierarkia
      }
      if (hierarkia.lapset) {
        const loytynyt = this.etsiTiliHierarkiasta(numero, hierarkia.lapset)
        if (loytynyt) {
          return loytynyt
        }
      }
    }
    return null
  }

  /** Palauttaa tilin 1777 ja kaikki lapset */
  annaSelvitettavatOstotTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const selvitettavatOstot = this.etsiTiliHierarkiasta('1777', kaikki)
      if (selvitettavatOstot) {
        this.flattaa(selvitettavatOstot, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 1755 ja kaikki lapset */
  annaSaamisetOsakkailtaJaOmaisiltaTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const saamisetOsakkailtaJaOmaisilta = this.etsiTiliHierarkiasta('1755', kaikki)
      if (saamisetOsakkailtaJaOmaisilta) {
        this.flattaa(saamisetOsakkailtaJaOmaisilta, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 3099 ja kaikki lapset */
  annaSelvitettavatMyynnitTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const selvitettavatMyynnit = this.etsiTiliHierarkiasta('3099', kaikki)
      if (selvitettavatMyynnit) {
        this.flattaa(selvitettavatMyynnit, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 190 kaikki lapset */
  annaRahaJaPankkisaatavatTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const rahaJaPankkisaamistili = this.etsiTiliHierarkiasta('190', kaikki)
      if (rahaJaPankkisaamistili?.lapset) {
        for (const hierarkiatili of rahaJaPankkisaamistili?.lapset) {
          this.flattaa(hierarkiatili, flatatty)
        }
      }
    }
    return flatatty
  }

  flattaa(hierarkiaTili: HierarkiaKirjanpitotili, flatatyt: Kirjanpitotili[]) {
    flatatyt.push(hierarkiaTili.kirjanpitotili)
    if (hierarkiaTili.lapset) {
      for (const h of hierarkiaTili.lapset) {
        this.flattaa(h, flatatyt)
      }
    }
  }

}
