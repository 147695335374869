import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ErrorHandler, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { AbstractControl, Validators, ValidationErrors, ValidatorFn, FormControl, FormGroup } from '@angular/forms'

import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatInput } from '@angular/material/input'

import { KirjanpitajanNimitiedot } from '../../_jaettu-lemonator/model/kirjanpitaja'
import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'
import { VeroilmoitusEmailTextAutosaveData, VeroilmoitusEmailLahetysTyojono, VeroilmoitusEmailLahetys, RaporttiRequest, RaporttiPdfResponse, VeroilmoitusFinal, EmailLiite, EmailLiiteDownloadRequest, TilinpaatosTaseErittely } from '../../_jaettu-lemonator/model/kirjanpito'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { TranslationService } from '../../_jaettu/service/translation.service'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitoUriService } from '../../_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitoJaettuService } from '../../_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { VeroilmoituksenPerustiedot } from './veroilmoitus.component'
import { EmailLahetysStatus, EmailLahetysStatusKoodi } from 'app/_jaettu/model/lasku'

import { take, map, takeUntil, startWith, switchMap, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, BehaviorSubject, firstValueFrom } from 'rxjs'

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { RaporttiType } from 'app/_jaettu/model/reports'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface LahetysForm {
  aihe: FormControl<string>
  tekstinAlku: FormControl<string>
  tekstinLoppu: FormControl<string>
  tuloslaskelma: FormControl<boolean>
  virallinenTuloslaskelma: FormControl<boolean>
  virallinenTase: FormControl<boolean>
  veroilmoitus: FormControl<boolean>
  taseErittely: FormControl<boolean>
  lahettaja: FormControl<string>
  piilokopioLahettajalle: FormControl<boolean>
  vastaanottajat: FormControl<string>
}
@Component({
  selector: '[app-veroilmoitus-lahetys]',
  templateUrl: './veroilmoitus-lahetys.component.html',
  styleUrls: ['./veroilmoitus-lahetys.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoVeroilmoitusLahetysComponent implements OnInit, OnDestroy {

  @Input() perustiedotObservable: Observable<VeroilmoituksenPerustiedot>
  @Input() viimeisinLahetettyVeroilmoitusObservable: Observable<VeroilmoitusFinal>
  @Input() voiLahettaaObservable: Observable<boolean>
  @Input() account9940DebetMinusKreditSumObservable: Observable<number>

  @Output() onkoSpostejaLahetetty: EventEmitter<boolean> = new EventEmitter()

  @ViewChild('recipientInput', { read: MatInput, static: true }) recipientField: MatInput
  @ViewChild('tekstiTextareaLoppu', { read: MatInput, static: true }) tekstiTextareaLoppu: MatInput
  @ViewChild('autosizeLoppu', { read: CdkTextareaAutosize, static: true }) autosizeLoppu: CdkTextareaAutosize
  @ViewChild('autosizeAlku', { read: CdkTextareaAutosize, static: true }) autosizeAlku: CdkTextareaAutosize

  lahetysForm: FormGroup<LahetysForm>
  nykyisenKirjanpitajanNimitiedotObservable: Observable<KirjanpitajanNimitiedot>
  attachmentsTotalSizeIfTooBigObservable: Observable<number>
  clientTypeObservable: Observable<'holvi' | 'regular' | 'unknown'>
  emailLiitteetObservable: Observable<EmailLiite[]>
  onkoMuuKuinOsakeyhtioObservable: Observable<{ result: boolean }>
  vastaanottajat: string[] = []
  account9940TextObservable: Observable<string>


  private _taseErittelyObservable: Observable<TilinpaatosTaseErittely>

  namename = 'toiughnwro' + Math.random()

  private _sendingInProgress: boolean = false
  private _asiakasObservable: Observable<Asiakas>
  private _selvitettavatTextLocationMarker: string = '\u200B'
  private _ngUnsubscribe = new Subject<void>()
  private _allowAutoSaving: boolean = false
  private _lahetysAvainSubject: BehaviorSubject<string> = new BehaviorSubject(null)


  latausVirhe: string
  include9940Text: boolean = false
  includeMainAutotext: boolean = true
  mainAutotext: string
  autosaveTimeTextObservable: Observable<string>
  asiakasAvain: string
  asiakkaanKieliObservable: Observable<TuettuKieli>

  pdfEiVoiLadata: string = 'Veroilmoitusta ei ole vielä lähetetty - PDF ei vielä ladattavissa'

  constructor(
    private _asiakasService: AsiakasService,
    private _translationService: TranslationService,
    private _dateService: DateService,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    private _kayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _formValidationService: FormValidationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _currencyService: CurrencyService,
    private _firebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) {
    this.nykyisenKirjanpitajanNimitiedotObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, this._kayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([asiakas, kirjanpitajatMap, kirjanpitaja]) => {
        this.asiakasAvain = asiakas?.avain || null
        if (!kirjanpitajatMap || !kirjanpitaja) {
          return null
        }
        if (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Holvi-asiakas
          return kirjanpitajatMap.get('QgPvtcCjoOdf6Zg7lgMwqLWp2BG2')
        }
        return kirjanpitajatMap.get(kirjanpitaja.uid)
      })
    )
  }

  public focus() {
    // console.log('focus 1')
    this._changeDetectorRef.markForCheck()
    setTimeout(() => {
      // console.log('focus 2')
      // if (!this.talousraporttienVastaanottajat || this.talousraporttienVastaanottajat.length < 1) {
      //   this.recipientField.focus()
      // } else {
      this.tekstiTextareaLoppu.focus()
      // }
      this._changeDetectorRef.markForCheck()
      setTimeout(() => {
        // console.log('focus 3')
        this.autosizeLoppu.resizeToFitContent(true)
        this.autosizeAlku.resizeToFitContent(true)
        this._changeDetectorRef.markForCheck()
      }, 50)
    }, 50)
  }

  ngOnInit() {

    this._asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.avain === b?.avain
      })
    )

    this.asiakkaanKieliObservable = this._asiakasObservable.pipe(
      map(asiakas => asiakas.laskunKieli || 'fi')
    )

    this.account9940TextObservable = combineLatest([this._asiakasObservable, this.account9940DebetMinusKreditSumObservable]).pipe(
      map(([asiakas, account9940Sum]) => {
        if (account9940Sum > 0) {
          return this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.account-9940-text-html', asiakas.laskunKieli || 'fi', {
            'sum': account9940Sum
          })
        }
        return null
      })
    )

    this.account9940TextObservable.pipe(
      take(1)
    ).subscribe(text => {
      if (!!text) {
        this.include9940Text = true
      }
    })

    this.lahetysForm = new FormGroup<LahetysForm>({
      aihe: new FormControl<string>(null),
      tekstinAlku: new FormControl<string>(null),
      tekstinLoppu: new FormControl<string>(null),
      tuloslaskelma: new FormControl<boolean>(false),
      virallinenTuloslaskelma: new FormControl<boolean>(false),
      virallinenTase: new FormControl<boolean>(false),
      veroilmoitus: new FormControl<boolean>(false),
      taseErittely: new FormControl<boolean>(false),
      lahettaja: new FormControl<string>({ value: null, disabled: true }),
      vastaanottajat: new FormControl<string>({ value: null, disabled: true }),
      piilokopioLahettajalle: new FormControl<boolean>(false)
    })

    this.onkoMuuKuinOsakeyhtioObservable = this._asiakasObservable.pipe(
      map(asiakas => { return { result: asiakas?.yritysmuoto !== Yritysmuoto.OSAKEYHTIO } })
    )

    const tunnistetutYhteyshenkilot = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        if (!kayttajat?.length) {
          return []
        }
        return kayttajat.filter(k => k.aktiivinen && k.roolit.HALLINTO_YHTEYSHENKILO && k.kayttajaTunnistettu === 'tunnistettu-nets')
      })
    )

    combineLatest([tunnistetutYhteyshenkilot, this._asiakasObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([yhteyshenkilot, asiakas]) => {
      this.vastaanottajat = yhteyshenkilot.map(yh => yh.email)
      if (asiakas.kirjanpitoviestienLisavastaanottajat) {
        this.vastaanottajat.push(...asiakas.kirjanpitoviestienLisavastaanottajat)
      }
      this.lahetysForm.get('vastaanottajat').setValue(this.vastaanottajat.join(', '))
    })

    this.piilokopioLahettajalle.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ piilokopioValittu: !!valittu })
    })
    this.tuloslaskelma.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ tulosValittu: !!valittu })
    })
    this.virallinenTuloslaskelma.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ virTulosValittu: !!valittu })
    })
    this.virallinenTase.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ virTaseValittu: !!valittu })
    })
    this.veroilmoitus.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ veroilmoitusValittu: !!valittu })
    })

    this.clientTypeObservable = this._asiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return 'unknown'
        }
        return this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
      })
    )

    const senderEmailObservable: Observable<string> = this._asiakasObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<string>(null)
        }
        const tyyppi = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
        if (tyyppi === 'holvi') {
          return observableOf<string>('no-reply@lemontree.fi')
        }
        return this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable.pipe(
          map(kirjanpitaja => kirjanpitaja?.email || null)
        )
      })
    )

    senderEmailObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(email => {
      this.lahetysForm.get('lahettaja').setValue(email)
    })

    this.emailLiitteetObservable = this._lahetysAvainSubject.asObservable().pipe(
      withLatestFrom(this._asiakasObservable),
      switchMap(([lahetysAvain, asiakas]) => {
        if (!asiakas) {
          return observableOf<EmailLiite[]>([])
        }
        const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksenLiitteetCollection(asiakas.avain, lahetysAvain)
        return this._firebase.firestoreCollection<EmailLiite>(uri)
          .where('poistettu', '==', false)
          .listen()
      }),
      takeUntil(this._ngUnsubscribe)
    )

    const raportitFormsObservable: Observable<string[]> = combineLatest([
      this._asiakasObservable,
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      this.virallinenTuloslaskelma.valueChanges.pipe(startWith(false)),
      this.virallinenTase.valueChanges.pipe(startWith(false)),
      this.veroilmoitus.valueChanges.pipe(startWith(false)),
      this.taseErittely.valueChanges.pipe(startWith(false)),
      // this.emailLiitteetObservable.pipe(startWith([]))
    ]).pipe(
      map(([asiakas, tuloslaskelma, virTulos, virTase, veroilmoitus, taseErittely/* , liitteet*/]) => {
        const kieli = asiakas.laskunKieli || 'fi'
        const namesOfSelected: string[] = []
        // if (asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
        //   if (virTulos) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.virallinen-tuloslaskelma', kieli)) }
        //   if (virTase) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.virallinen-tase', kieli)) }
        //   if (liitteet.length) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.liitetiedot', kieli)) }
        // } else {
        if (tuloslaskelma) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.tuloslaskelma', kieli)) }
        if (virTulos) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.virallinen-tuloslaskelma', kieli)) }
        if (virTase) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.virallinen-tase', kieli)) }
        // if (veroilmoitus && asiakas.yritysmuoto !== Yritysmuoto.TOIMINIMI) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.veroilmoitus', kieli)) }
        if (taseErittely) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.tase-erittelyt', kieli)) }
        // }
        return namesOfSelected
      }),
      distinctUntilChanged((a, b) => {
        return JSON.stringify(a) === JSON.stringify(b)
      })
    )

    this.attachmentsTotalSizeIfTooBigObservable = combineLatest([
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      this.virallinenTuloslaskelma.valueChanges.pipe(startWith(false)),
      this.virallinenTase.valueChanges.pipe(startWith(false)),
      this.veroilmoitus.valueChanges.pipe(startWith(false)),
      this.taseErittely.valueChanges.pipe(startWith(false)),
      this.emailLiitteetObservable.pipe(startWith([] as EmailLiite[]))
    ]).pipe(
      map(([tuloslaskelma, virTulos, virTase, veroilmoitus, taseErittely, liitteet]) => {
        let totalKoko = 0
        const averageReportSize = 100 * 1000 // 100 KB
        if (tuloslaskelma) { totalKoko += averageReportSize }
        if (virTulos) { totalKoko += averageReportSize }
        if (virTase) { totalKoko += averageReportSize }
        if (veroilmoitus) { totalKoko += averageReportSize }
        if (taseErittely) { totalKoko += averageReportSize }

        for (const liite of (liitteet || [])) {
          totalKoko += liite.koko || 512 * 1000 // 512 KB
        }

        if (totalKoko > Math.pow(10, 7)) {
          return this._currencyService.roundHalfUp(totalKoko / Math.pow(10, 6), 1)
        }
        return 0

      }),
      takeUntil(this._ngUnsubscribe)
    )

    // /** Currently only used for Oy extra texts */
    // const lisaRaportitFormsObservable: Observable<string[]> = combineLatest([
    //   this._asiakasObservable,
    //   this.tuloslaskelma.valueChanges.pipe(startWith(false)),
    //   this.veroilmoitus.valueChanges.pipe(startWith(false)),
    //   this.taseErittely.valueChanges.pipe(startWith(false))
    // ]).pipe(
    //   map(([asiakas, tuloslaskelma, veroilmoitus, taseErittely]) => {
    //     const namesOfSelected: string[] = []
    //     if (asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
    //       if (tuloslaskelma) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.pitka-tuloslaskelma', kieli)) }
    //       if (veroilmoitus) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.veroilmoitus-liitteineen', kieli)) }
    //       if (taseErittely) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.tase-erittelyt', kieli)) }
    //     }
    //     return namesOfSelected
    //   }),
    //   distinctUntilChanged((a, b) => {
    //     return JSON.stringify(a) === JSON.stringify(b)
    //   })
    // )

    const rawSavedTextsInDatabaseObservable: Observable<Partial<VeroilmoitusEmailTextAutosaveData>> = combineLatest([
      this.perustiedotObservable,
      this._asiakasObservable
    ]).pipe(
      switchMap(([perustiedot, asiakas]) => {

        this.aihe.setValue('')
        this.tekstinAlku.setValue('')
        this.tekstinLoppu.setValue('')

        if (!perustiedot || !asiakas) {
          return observableOf<VeroilmoitusEmailTextAutosaveData>(null)
        }

        const kieli = asiakas.laskunKieli || 'fi'

        const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakas.avain, perustiedot.tilikausi)
        const autosaveDoc = this._firebase.firestoreDoc<VeroilmoitusEmailTextAutosaveData>(uri)
        return autosaveDoc.listen().pipe(
          withLatestFrom(this._kayttajaService.kirjanpitajanTiedotObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable),
          map(([autosaved, kirjanpitajanTiedot, nimitiedotMap]) => {
            const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
            let defaultInputStartTextKey = null
            let defaultInputEndTextKey = null
            if (clientType === 'regular') {
              if (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI) {
                defaultInputStartTextKey = 'kirjanpito-lahetys-veroilmoitus.input-start-regular-tmi'
                defaultInputEndTextKey = 'kirjanpito-lahetys-veroilmoitus.input-end-regular-tmi'
              } else if (asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
                defaultInputStartTextKey = 'kirjanpito-lahetys-veroilmoitus.input-start-regular-oy'
                defaultInputEndTextKey = 'kirjanpito-lahetys-veroilmoitus.input-end-regular-oy'
              }
            } else {
              defaultInputStartTextKey = 'kirjanpito-lahetys-veroilmoitus.input-start-holvi'
              defaultInputEndTextKey = 'kirjanpito-lahetys-veroilmoitus.input-end-holvi'
            }

            const emailEndText = clientType === 'regular' && nimitiedotMap && kirjanpitajanTiedot.uid ? (nimitiedotMap.get(kirjanpitajanTiedot.uid)?.emailEndText?.[kieli] ?? '') : ''

            const newText: Partial<VeroilmoitusEmailTextAutosaveData> = {
              aihe: autosaved?.aihe?.trim() || this._annaAihe(perustiedot, asiakas),
              tekstinAlku: autosaved?.tekstinAlku || this._translationService.lokalisoi(defaultInputStartTextKey, kieli),
              tekstinLoppu: autosaved?.tekstinLoppu || this._translationService.lokalisoi(defaultInputEndTextKey, kieli) + (emailEndText ? ('\n\n' + emailEndText) : ''),
              paivittaja: autosaved?.paivittaja || null,
              paivitettu: autosaved?.paivitettu || null
            }
            return newText
          })
        )

      }),
      lemonShare()
    )

    this.autosaveTimeTextObservable = combineLatest([rawSavedTextsInDatabaseObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([dbTextDocument, nimitiedotMap]) => {
        if (dbTextDocument?.paivitettu) {
          if (nimitiedotMap) {
            const kirjanpitaja = nimitiedotMap.get(dbTextDocument.paivittaja)
            if (kirjanpitaja) {
              return 'Tallentanut ' + kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi + ' ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
            }
          }
          return 'Tallennettu ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
        }
        return ''
      })
    )

    rawSavedTextsInDatabaseObservable.pipe(
      takeUntil(this._ngUnsubscribe),
    ).subscribe(texts => {

      if (!this.aihe.value?.trim()) {
        this.aihe.setValue(texts?.aihe || '')
      }
      if (!this.tekstinAlku.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinAlku.setValue(texts?.tekstinAlku || '')
      }
      if (!this.tekstinLoppu.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinLoppu.setValue(texts?.tekstinLoppu || '')
      }
    })

    const mainAutotextObservable = combineLatest([
      raportitFormsObservable,
      this.perustiedotObservable,
      this.veroilmoitus.valueChanges.pipe(startWith(false))
    ]).pipe(
      map(([liitteet, perustiedot, veroilmoitusLiiteAttached]) => {

        if (!perustiedot || !perustiedot.asiakas) {
          return null
        }
        const kieli = perustiedot.asiakas.laskunKieli || 'fi'

        const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(perustiedot.asiakas)
        // const kuukaudenNimi = this._dateService.annaKuukaudenNimiPaikallinen(perustiedot, kieli)

        // Format liitteet list (final two should have ' ja ' between them)
        const ohessaKopioLahetetysta = veroilmoitusLiiteAttached ? this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.liitteena-veroilmoitus', kieli) : ''
        const liitteetFormatted: string = ohessaKopioLahetetysta + this._createLiitteetListText(liitteet, kieli)

        let mailTextKey = null
        if (clientType === 'regular') {
          if (perustiedot.asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI) {
            mailTextKey = 'kirjanpito-lahetys-veroilmoitus.email-generated-regular-tmi'
          } else if (perustiedot.asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
            mailTextKey = 'kirjanpito-lahetys-veroilmoitus.email-generated-regular-oy'
          }
        } else {
          mailTextKey = 'kirjanpito-lahetys-veroilmoitus.email-generated-holvi'
        }

        const fullText = this._translationService.lokalisoi(mailTextKey, kieli, {
          vuosi: perustiedot.tilikausi.loppuu.year,
          liitteetText: liitteetFormatted,
          yrityksenNimi: perustiedot.asiakas.nimi
        })
        return fullText
      })
    )

    mainAutotextObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(text => {
      this.mainAutotext = text
    })

    combineLatest([this.perustiedotObservable, this._asiakasObservable]).pipe(
      switchMap(([perustiedot, asiakas]) => {
        if (!perustiedot || !asiakas) {
          return observableOf<VeroilmoitusEmailTextAutosaveData>(null)
        }
        const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakas.avain, perustiedot.tilikausi)
        return this._firebase.firestoreDoc<VeroilmoitusEmailTextAutosaveData>(uri).listen().pipe(
          take(1),
          map(autosaveData => {

            const output: Partial<VeroilmoitusEmailTextAutosaveData> = {}

            output.lahetysAvain = autosaveData?.lahetysAvain

            // If regular client: piilokopio turned on by default and then set according to autosaved value
            // If Holvi client: piilokopio is always turned off
            const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
            if (clientType === 'holvi') {
              output.piilokopioValittu = false
              this.piilokopioLahettajalle.disable()
            } else {
              this.piilokopioLahettajalle.enable()
              output.piilokopioValittu = autosaveData?.piilokopioValittu ?? true
            }

            // Did we get data from db?
            if (autosaveData?.tulosValittu === true || autosaveData?.tulosValittu === false) {
              output.tulosValittu = autosaveData.tulosValittu
            } else { // Nope, use default
              output.tulosValittu = asiakas.yritysmuoto !== Yritysmuoto.OSAKEYHTIO
            }

            if (autosaveData?.virTulosValittu === true || autosaveData?.virTulosValittu === false) {
              output.virTulosValittu = autosaveData.virTulosValittu
            } else {
              output.virTulosValittu = asiakas.yritysmuoto !== Yritysmuoto.OSAKEYHTIO
            }

            if (autosaveData?.virTaseValittu === true || autosaveData?.virTaseValittu === false) {
              output.virTaseValittu = autosaveData.virTaseValittu
            } else {
              output.virTaseValittu = clientType !== 'holvi' && asiakas.yritysmuoto !== Yritysmuoto.OSAKEYHTIO
            }

            if (autosaveData?.veroilmoitusValittu === true || autosaveData?.veroilmoitusValittu === false) {
              output.veroilmoitusValittu = autosaveData.veroilmoitusValittu
            } else {
              output.veroilmoitusValittu = clientType !== 'holvi'
            }

            return output
          })
        )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(autosaved => {
      this._lahetysAvainSubject.next(autosaved?.lahetysAvain || this._firebase.firestoreCreateId())
      this._setCheckboxesFromAutosave(autosaved)
      this._allowAutoSaving = true
    })

    this._taseErittelyObservable = combineLatest([this._asiakasObservable, this.perustiedotObservable]).pipe(
      switchMap(([asiakas, perustiedot]) => {
        if (!asiakas || !perustiedot) {
          return observableOf<TilinpaatosTaseErittely>(null)
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, perustiedot.tilikausi)
        return this._firebase.firestoreDoc<TilinpaatosTaseErittely>(uri).listen()
      })
    )

    combineLatest([this._asiakasObservable, this._taseErittelyObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([asiakas, taseErittely]) => {
      if (asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI) {
        this.veroilmoitus.setValue(true)
        this.veroilmoitus.disable()
      } else {
        this.veroilmoitus.enable()
        this.veroilmoitus.setValue(false)
      }
      if (taseErittely && asiakas && asiakas.yritysmuoto !== Yritysmuoto.OSAKEYHTIO) {
        this.taseErittely.enable()
        this.taseErittely.setValue(true)
      } else {
        this.taseErittely.setValue(false)
        this.taseErittely.disable()
      }
    })

  }

  // async reload() {
  //   const perustiedot = await firstValueFrom(this.perustiedotObservable)
  //   const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(perustiedot.asiakas.avain, perustiedot.tilikausi)
  //   await this._firebase.firestoreDeleteDoc(uri)
  // }

  sahkopostejaOnLahetettyPaivittyi(lahetetty: boolean) {
    this.onkoSpostejaLahetetty.next(lahetetty)
  }

  private async _updateAutosaveData(data: Partial<VeroilmoitusEmailTextAutosaveData>): Promise<void> {

    if (!this._allowAutoSaving) {
      return
    }

    const perustiedot = await firstValueFrom(this.perustiedotObservable)
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    if (!perustiedot || !asiakas) {
      return
    }
    const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakas.avain, perustiedot.tilikausi)
    data.paivitettu = this._timestampService.now()
    return this._firebase.firestoreSetData(uri, data, { merge: true })
  }

  private _annaAihe(perustiedot: VeroilmoituksenPerustiedot, asiakas: Asiakas) {
    return `${asiakas.nimi}, ${this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.veroilmoitus', asiakas.laskunKieli || 'fi')} ${perustiedot.tilikausi.loppuu.year}`
  }

  private _removeMarkers(text: string): string {
    const liitteetMarkerRegExp = new RegExp(this._selvitettavatTextLocationMarker, 'g')
    return text.replace(liitteetMarkerRegExp, '')
  }

  async _resetAutosave(): Promise<void> {

    const asiakasAvainTiedotPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const perustiedotPromise = firstValueFrom(this.perustiedotObservable)

    const [asiakasAvainTiedot, perustiedot] = await Promise.all([asiakasAvainTiedotPromise, perustiedotPromise])

    const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakasAvainTiedot.avain, perustiedot.tilikausi)
    return this._firebase.firestoreDeleteDoc(uri)
  }

  // get vastaanottaja() {
  //   return this.lahetysForm.get('vastaanottaja')
  // }
  get aihe() {
    return this.lahetysForm.get('aihe')
  }
  get tekstinAlku() {
    return this.lahetysForm.get('tekstinAlku')
  }
  get tekstinLoppu() {
    return this.lahetysForm.get('tekstinLoppu')
  }
  get tuloslaskelma() {
    return this.lahetysForm.get('tuloslaskelma')
  }
  get virallinenTuloslaskelma() {
    return this.lahetysForm.get('virallinenTuloslaskelma')
  }
  get virallinenTase() {
    return this.lahetysForm.get('virallinenTase')
  }
  get veroilmoitus() {
    return this.lahetysForm.get('veroilmoitus')
  }
  get taseErittely() {
    return this.lahetysForm.get('taseErittely')
  }
  get piilokopioLahettajalle() {
    return this.lahetysForm.get('piilokopioLahettajalle')
  }

  // lisaaVastaanottaja() {
  //   const field = this.vastaanottaja
  //   field.updateValueAndValidity()
  //   this.lisaaEmail(field)
  // }
  // poistaVastaanottaja(vastaanottaja: string): void {
  //   const index = this.vastaanottajat.indexOf(vastaanottaja)
  //   if (index >= 0) {
  //     this.vastaanottajat.splice(index, 1)
  //   }
  // }
  validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  // async avaaTaseErittely() {
  //   const asiakas = await this._asiakasService.nykyinenAsiakasAvainObservable)
  //   this._router.navigate(['asiakkaat', asiakas.avain, 'kirjanpidon-ulkopuoliset-vahennykset'])
  // }

  async laheta() {

    // Avoid multiple sends
    if (this._sendingInProgress) {
      return
    }

    this._sendingInProgress = true

    if (!this.vastaanottajat?.length) {
      this._snackbar.open('Sähköpostia ei voi lähettää. Asiakkaalla ei ole aktiivisia yhteyshenkilöitä.', 'OK', { duration: 10000 })
      return
    }
    if (!this.lahetysForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.lahetysForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      // Obtain required user, etc. data
      const asiakasPromise = firstValueFrom(this._asiakasObservable)
      const perustiedotPromise = firstValueFrom(this.perustiedotObservable)
      const tallentavaKirjanpitajaPromise = this._kayttajaService.getKirjanpitajanTiedot()
      // const latestAlvIlmoitus = await this.alvIlmoitusObservable)
      const mailTextPromise = this._combineMailText()

      const [asiakas, perustiedot, tallentavaKirjanpitaja, mailText] = await Promise.all([asiakasPromise, perustiedotPromise, tallentavaKirjanpitajaPromise, mailTextPromise])

      if (!asiakas) {
        throw new Error('Ei asiakasta tallennettaessa!')
      }
      if (!tallentavaKirjanpitaja) {
        throw new Error('Ei tallentavaa kirjanpitäjää tallennettaessa!')
      }

      // Create saved documents
      const aika = this._timestampService.now()
      const lahetysAvain = this._lahetysAvainSubject.value
      const tyojonoId: string = this._firebase.firestoreCreateId()
      const tyojonoData: VeroilmoitusEmailLahetysTyojono = {
        asiakasAvain: asiakas.avain,
        veroilmoitusEmailLahetysAvain: lahetysAvain
      }
      const recipientStatuses: EmailLahetysStatus[] = []
      for (const recipient of this.vastaanottajat) {
        const emailLahetysStatus: EmailLahetysStatus = {
          email: recipient,
          status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
          viesti: null
        }
        recipientStatuses.push(emailLahetysStatus)
      }
      const tekstiWithBreaks = mailText.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      const tekstiWithBreaksWithoutMarkers = this._removeMarkers(tekstiWithBreaks)
      const lahetysData: VeroilmoitusEmailLahetys = {
        avain: lahetysAvain,
        kuukausi: perustiedot.kuukausi,
        tunniste: this._dateService.localDateToNumber(perustiedot.tilikausi.loppuu) + '_veroilmoitus',
        luoja: tallentavaKirjanpitaja.uid,
        luotu: aika,
        lahetetty: null,
        lisaaPiilokopio: this.piilokopioLahettajalle.value,
        recipients: recipientStatuses,
        kieli: asiakas.laskunKieli || 'fi',
        aihe: this.aihe.value,
        teksti: tekstiWithBreaksWithoutMarkers,
        liitaTuloslaskelma: this.tuloslaskelma.value,
        liitaVirallinenTuloslaskelma: this.virallinenTuloslaskelma.value,
        liitaVirallinenTase: this.virallinenTase.value,
        liitaVeroilmoitus: this.veroilmoitus.value,
        liitaTaseErittely: this.taseErittely.value
      }

      if (this.veroilmoitus.value) {
        const viimeisinLahetettyVeroilmoitus = await firstValueFrom(this.viimeisinLahetettyVeroilmoitusObservable)
        if (viimeisinLahetettyVeroilmoitus) {
          lahetysData.veroilmoitusAvain = viimeisinLahetettyVeroilmoitus.avain
        }
      }
      if (this.taseErittely.value) {
        const erittely: TilinpaatosTaseErittely = await firstValueFrom(this._taseErittelyObservable)
        lahetysData.taseErittely = erittely
      }

      // Emergency break to avoid empty email sends
      if (!lahetysData.aihe.replace(/\s/g, '') || !lahetysData.teksti.replace(/(?:<br\/>|\s)/g, '')) {
        this._ladataanService.lopetaLataaminen()
        this._sendingInProgress = false
        this._snackbar.open('Lähetys keskeytetty! Aihe tai teksti puuttuu', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })

        return
      }

      const lahetysUri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksetLahetysAvaimella(asiakas.avain, this._lahetysAvainSubject.value)
      const tyojonoUri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetysTyojonoEnsimmainenUri(asiakas.avain, tyojonoId)

      // Add to batch
      const batch = this._firebase.firestoreBatch()

      batch.set(tyojonoUri, tyojonoData)
      batch.set(lahetysUri, lahetysData)

      // Save
      await batch.commit()

      this._ladataanService.lopetaLataaminen()
      this._snackbar.open('Sähköposti lähetettiin onnistuneesti.', null, { duration: 5000 })
      this._sendingInProgress = false
      await this._resetAutosave()
      this._lahetysAvainSubject.next(this._firebase.firestoreCreateId())
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
      this._sendingInProgress = false
    }
  }

  async deleteAttachment(file: EmailLiite) {

    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasObservable)

    const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, file.avain)

    const markAsDeleted: Partial<EmailLiite> = { poistettu: true }
    await this._firebase.firestoreUpdateData<Partial<EmailLiite>>(uri, markAsDeleted)
    this._ladataanService.lopetaLataaminen()
  }

  // private lisaaEmail(field: AbstractControl) {
  //   if ((field.value || '').trim()) {
  //     let arvo = field.value.trim()
  //     arvo = arvo.replace(/,/g, '').trim()
  //     if (!new UntypedFormControl(arvo, [Validators.email]).errors) {
  //       if (!this.talousraporttienVastaanottajat) {
  //         this.talousraporttienVastaanottajat = []
  //       }
  //       this.talousraporttienVastaanottajat.push(arvo)
  //       field.setValue('')
  //     }
  //   }
  // }

  // Load start when clicked
  public lataa(event) {
    if (event.target.files) {
      const list: FileList = event.target.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      this.uploadFile(tiedostot)
    }
  }

  async uploadFile(tiedostot: NgxFileDropEntry[]) {

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const perustiedotPromise = firstValueFrom(this.perustiedotObservable)

    const [asiakas, perustiedot] = await Promise.all([asiakasPromise, perustiedotPromise])

    const batch = this._firebase.firestoreBatch()

    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)

      this._ladataanService.aloitaLataaminen()

      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      const avain = this._firebase.firestoreCreateId()
      const metadata: EmailLiite = {
        avain: avain,
        nimi: file.name,
        koko: file.size,
        ladattu: this._timestampService.now(),
        fileType: fileEnding,
        poistettu: false
      }

      const storageUri = this._kirjanpitoUriService.annaEmailLahetyksenLiiteStorageUri(asiakas.avain, metadata.avain, metadata.fileType)
      await firstValueFrom(this._tiedostojenLataamisService.upload(this._firebase, storageUri, file).doneObservable)

      const metadataUri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, avain)
      batch.set(metadataUri, metadata)
    }

    const autosaveUri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakas.avain, perustiedot.tilikausi)
    const data: Partial<VeroilmoitusEmailTextAutosaveData> = {
      lahetysAvain: this._lahetysAvainSubject.value
    }
    batch.set(autosaveUri, data, { merge: true })

    await batch.commit()

    this._ladataanService.lopetaLataaminen()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  public downloadVirTasePdf() {
    this._downloadPdf(RaporttiType.TASE_VIRALLINEN)
  }
  public downloadVirTulosPdf() {
    this._downloadPdf(RaporttiType.TULOS_VIRALLINEN)
  }
  public downloadVeroilmoitusPdf() {
    this._downloadPdf(RaporttiType.VEROILMOITUS)
  }
  public downloadTulosPdf() {
    this._downloadPdf(RaporttiType.TULOS)
  }
  public downloadTaseErittelyPdf() {
    this._downloadPdf(RaporttiType.TASE_ERITTELY)
  }

  private async _downloadPdf(reportType: RaporttiType) {
    this._ladataanService.aloitaLataaminen()

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const perustiedotPromise = firstValueFrom(this.perustiedotObservable)

    const [asiakas, perustiedot] = await Promise.all([asiakasPromise, perustiedotPromise])

    const pyynto: RaporttiRequest = {
      a: asiakas.avain,
      k: asiakas.laskunKieli || 'fi',
      w: reportType,
      s: this._dateService.localDateToNumber(perustiedot.tilikausi.alkaa),
      e: this._dateService.localDateToNumber(perustiedot.tilikausi.loppuu),
      ta: perustiedot.tilikausi.avain
    }

    if (reportType === RaporttiType.VEROILMOITUS) {
      const viimeisinLahetettyVeroilmoitus = await firstValueFrom(this.viimeisinLahetettyVeroilmoitusObservable)
      if (viimeisinLahetettyVeroilmoitus) {
        pyynto.va = viimeisinLahetettyVeroilmoitus.avain
      }
    }

    try {
      return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', pyynto).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          this._ladataanService.lopetaLataaminen()
          if (data.base64File) {
            const fileName = data.fileName || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.')
          }
        }
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }

  }

  private _createLiitteetListText(selectedLiitteet: string[], kieli: TuettuKieli): string {
    if (!selectedLiitteet || !selectedLiitteet.length) {
      return ''
    }
    const commaSeparatedLiitteet = selectedLiitteet.join(', ')
    const finalComma = commaSeparatedLiitteet.lastIndexOf(', ')
    const liitteetList: string = selectedLiitteet.length > 1 ?
      `${commaSeparatedLiitteet.substring(0, finalComma)} ${this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.and', kieli)}${commaSeparatedLiitteet.substring(finalComma + 1)}` : // add " ja", if more than one liite
      selectedLiitteet.toString()
    return this._translationService.lokalisoi('kirjanpito-lahetys-veroilmoitus.liitteet-text', kieli, { liitteet: liitteetList })
  }

  async tryAutosave() {

    const asiakasAvainTiedotPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const perustiedotPromise = firstValueFrom(this.perustiedotObservable)
    const kirjanpitajanTiedotPromise = this._kayttajaService.getKirjanpitajanTiedot()

    const aihe = this.aihe.value?.trim() || ''
    const alku = this.tekstinAlku.value || ''
    const loppu = this.tekstinLoppu.value || ''

    if (!aihe && !alku && !loppu) {
      return
    }

    const [asiakasAvainTiedot, kirjanpitajanTiedot, perustiedot] = await Promise.all([asiakasAvainTiedotPromise, kirjanpitajanTiedotPromise, perustiedotPromise])

    if (!asiakasAvainTiedot || !kirjanpitajanTiedot || !perustiedot) {
      return
    }

    const autosaveUri = this._kirjanpitoUriService.annaVeroilmoitusEmailTekstiAutosaveUri(asiakasAvainTiedot.avain, perustiedot.tilikausi)
    const data: Partial<VeroilmoitusEmailTextAutosaveData> = {
      aihe: aihe,
      tekstinAlku: alku,
      tekstinLoppu: loppu,
      paivittaja: kirjanpitajanTiedot.uid,
      paivitettu: this._timestampService.now()
    }

    return this._firebase.firestoreSetData(autosaveUri, data, { merge: true })

  }

  public showOrHideMainAutotext() {
    this.includeMainAutotext = !this.includeMainAutotext
  }

  showOrHide9940Box() {
    this.include9940Text = !this.include9940Text
  }

  private async _combineMailText(): Promise<string> {
    let output = ''
    output += this.tekstinAlku?.value || ''
    if (this.includeMainAutotext) {
      output = output.trimRight()
      output += '\n\n'
      output += this.mainAutotext || ''
    }
    if (this.include9940Text) {
      output = output.trimRight()
      output += '\n\n'
      output += await firstValueFrom(this.account9940TextObservable) || ''
    }
    output = output.trimRight()
    output += '\n\n'
    output += (this.tekstinLoppu?.value as string || '').trimLeft()
    return output
  }

  private _setCheckboxesFromAutosave(autosaved: Partial<VeroilmoitusEmailTextAutosaveData>): void {

    if (!autosaved) {
      return
    }

    if (autosaved.piilokopioValittu !== undefined && autosaved.piilokopioValittu !== null) {
      this.piilokopioLahettajalle.setValue(autosaved.piilokopioValittu)
    }
    if (autosaved.tulosValittu !== undefined && autosaved.tulosValittu !== null) {
      this.tuloslaskelma.setValue(autosaved.tulosValittu)
    }
    if (autosaved.virTulosValittu !== undefined && autosaved.virTulosValittu !== null) {
      this.virallinenTuloslaskelma.setValue(autosaved.virTulosValittu)
    }
    if (autosaved.virTaseValittu !== undefined && autosaved.virTaseValittu !== null) {
      this.virallinenTase.setValue(autosaved.virTaseValittu)
    }
    if (autosaved.veroilmoitusValittu !== undefined && autosaved.veroilmoitusValittu !== null) {
      this.veroilmoitus.setValue(autosaved.veroilmoitusValittu)
    }

  }

  async downloadAttachment(liite: EmailLiite) {
    const asiakas = await firstValueFrom(this._asiakasObservable)
    this._ladataanService.aloitaLataaminen()
    const downloadRequest: EmailLiiteDownloadRequest = {
      asiakasAvain: asiakas.avain,
      liite: liite
    }
    try {
      await this._firebase.functionsCall<EmailLiiteDownloadRequest, string>('kirjanpitoDownloadEmailLiite', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        this._fileSaverService.saveBase64AsGuessedType(data, liite.nimi)
        this._ladataanService.lopetaLataaminen()
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

}
