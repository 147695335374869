<mat-dialog-content>

  <lemon-dialog-header>
    Käyttäjän vahvan tunnistautumisen historia
  </lemon-dialog-header>

  <table style="padding-left: 3em; padding-right: 3em; border-spacing: 10px;">
    <tr *ngFor="let log of data">
      <td>{{ log.logTime | ltimestamptimel: 'fi'}}</td>
      <td style="font-weight: bold;">{{ log.step }}</td>
      <td style="font-size: 12px;">{{ log.details }}</td>
      <td>{{ log.env }}</td>
    </tr>
  </table>


</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button (click)="sulje()">Sulje</button>
</mat-dialog-actions>