import { BaseEntity, ErrorResponse } from '../../_shared-core/model/common'

/* eslint-disable @typescript-eslint/naming-convention */
export enum ArkistoFileType {
  INTERNAL,
  VISIBLE_FOR_CUSTOMER
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface ArkistoFile extends BaseEntity {
  avain: string
  asiakasAvain: string
  // tilikausiAvain?: string
  fileName: string
  lisatiedot: string
  // type: ArkistoFileType
}

export interface ArkistoDownloadFileRequest {
  asiakasAvain: string
  metadata: ArkistoFile
}

export interface ArkistoDownloadFileResponse extends ErrorResponse {
  base64File: string
}

export class ArkistoService {

  getBucketName() {
    return 'arkisto-public'
  }

  getArkistoFileStorageUri(asiakasAvain: string, file: Pick<ArkistoFile, 'avain'>) {
    if (!file.avain) {
      throw new Error('Arkisto file has no key')
    }
    return asiakasAvain + '/public-files/' + file.avain
  }

  getArkistoMetadataCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/arkisto/'
  }

  getArkistoMetadataUri(asiakasAvain: string, metadataAvain: string) {
    return this.getArkistoMetadataCollection(asiakasAvain) + metadataAvain
  }

}
