<div class="list-page" style="padding-bottom: 20px;">

  <app-asiakas-otsikko otsikko="Asetukset, kirjanpito" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <form [formGroup]="form">

    <div class="light-blue-text header">Perustiedot</div>
    <div class="content" style="max-width: 550px;">
      <div style="display: flex; justify-content: start; flex-flow: row; align-items: center;">
        <mat-form-field style="max-width: 180px; margin-right: 10px;">
          <mat-label>Yritysmuoto</mat-label>
          <mat-select formControlName="yritysmuoto" required>
            <mat-option *ngFor="let yritysmuoto of yritysmuodot" [value]="yritysmuoto.koodi">
              {{ yritysmuoto.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="yritysmuoto?.errors?.required && (yritysmuoto?.dirty || yritysmuoto?.touched)">
            Yritysmuoto puuttuu
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="light-blue-text header">Työlista</div>
    <div class="content" style="max-width: 550px;">

      <div style="transform: scale(0.9); transform-origin: center left;">
        <mat-radio-group formControlName="lista">
          <mat-radio-button [value]="false">Näytä vanhassa listassa</mat-radio-button><br />
          <mat-radio-button [value]="true">Näytä uudessa listassa</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- <mat-form-field style="max-width: 240px; margin-left: 20px;">
        <input matInput monthPicker type="text" [matDatepicker]="uudessaListassaAlkaenPicker" formControlName="uudessaListassaAlkaen" placeholder="Kirjanpito Lemonatorissa alkaen" required>
        <mat-datepicker-toggle matSuffix [for]="uudessaListassaAlkaenPicker"></mat-datepicker-toggle>
      </mat-form-field>
      <mat-datepicker #uudessaListassaAlkaenPicker></mat-datepicker> -->

      <div style="font-weight: bold; font-size: 110%; margin-bottom: 10px; margin-top: 30px; margin-left: 5px;">
        Aktiiviset kuukausiruudut
      </div>

      <div formArrayName="kkruutukaudet" style="max-width: 560px;">
        <div *ngFor="let kausi of annaKkruutujenKausiControllit(); let i = index;" [formGroupName]="i">
          <div style="display: flex; justify-content: space-between; padding-top: 15px;">

            <mat-form-field style="max-width: 280px; margin-left: 10px; margin-right: 10px;">
              <mat-label>Ensimmäinen tehtävä kuukausi</mat-label>
              <input #kkruutukausiAlkaaInput [name]="namename" type="text" [matDatepicker]="kkruutuAlkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required />
              <mat-datepicker-toggle matSuffix [for]="kkruutuAlkaaPicker"></mat-datepicker-toggle>
              <mat-error *ngIf="kausi.get('alkaa')?.errors?.required && (kausi.get('alkaa')?.dirty || kausi.get('alkaa')?.touched)">
                Päivämäärä puuttuu
              </mat-error>
              <mat-error *ngIf="kausi.get('alkaa')?.errors?.edellinen && (kausi.get('alkaa')?.dirty || kausi.get('alkaa')?.touched)">
                Muutos ei voi olla samaan aikaan tai ennen edellistä kautta.
              </mat-error>
              <mat-error *ngIf="kausi.get('alkaa')?.errors?.loppuEnnenAlkua && (kausi.get('alkaa')?.dirty || kausi.get('alkaa')?.touched)">
                On loputtava alkamisen jälkeen.
              </mat-error>
              <mat-error *ngIf="kausi.get('alkaa')?.errors?.alkuEnnenEdellisenLoppua && (kausi.get('alkaa')?.dirty || kausi.get('alkaa')?.touched)">
                Alettava myöhemmin kuin edellinen loppuu
              </mat-error>
              <mat-datepicker #kkruutuAlkaaPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="max-width: 280px; margin-left: 10px; margin-right: 10px;">
              <mat-label>Viimeinen tehtävä kuukausi</mat-label>
              <input [name]="namename" type="text" [matDatepicker]="kkruutuLoppuuPicker" formControlName="loppuu" matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="kkruutuLoppuuPicker"></mat-datepicker-toggle>
              <mat-error *ngIf="kausi.get('loppuu')?.errors?.required && (kausi.get('loppuu')?.dirty || kausi.get('loppuu')?.touched)">
                Päivämäärä puuttuu
              </mat-error>
              <mat-error *ngIf="kausi.get('loppuu')?.errors?.loppuEnnenAlkua && (kausi.get('loppuu')?.dirty || kausi.get('loppuu')?.touched)">
                On loputtava alkamisen jälkeen.
              </mat-error>
              <mat-error *ngIf="kausi.get('loppuu')?.errors?.loppuuPuuttuu && (kausi.get('loppuu')?.dirty || kausi.get('loppuu')?.touched)">
                Merkitse loppu
              </mat-error>
              <mat-datepicker #kkruutuLoppuuPicker></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button (click)="poistaKkruutujakso(kausi)" type="button" style="padding:0;" class="secondarybutton mat-elevation-z4">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <mat-error *ngIf="annaKkruutujenKaudetFormArray()?.errors?.min && (annaKkruutujenKaudetFormArray()?.dirty || annaKkruutujenKaudetFormArray()?.touched)" style="padding-top: 15px; margin-left: 10px;">
        Ole hyvä ja syötä vähintään yksi kausi.
      </mat-error>

      <mat-error *ngIf="kkruutuError" style="padding-top: 15px; margin-left: 10px;">
        {{kkruutuError}}
      </mat-error>

      <div style="padding-top: 15px; margin-left: 10px; padding-bottom: 1.25em;">
        <button mat-button (click)="lisaaKkruutujakso()" type="button" class="secondarybutton mat-elevation-z4">
          <mat-icon>add</mat-icon>
          Lisää työlistakausi
        </button>
      </div>

    </div>

    <!-- {{ viimeisinAlvIlmoitusObservable | async | json }} -->
    <!-- valid: {{form.valid}}, invalid: {{form.invalid}} -->


    <div class="light-blue-text header">ALV-ilmoitusjaksot
      <button mat-icon-button (click)="toggleMuokkaaMitaVain()" title="Muokkaa mitä tahansa arvoa" *ngIf="punakynaCanBeUsedObservable | async">
        <span class="fa fa-pencil" [class.red-text]="punakynaActiveSubject | async"></span>
      </button>
    </div>
    <div class="content">
      <div style="display: flex;">

        <div formArrayName="alvIlmoitusjaksot" style="max-width: 450px;">

          <div *ngFor="let jakso of annaAlvIlmoitusjaksoControllit(); let i = index;" [formGroupName]="i">
            <div style="display: flex; justify-content: space-between;">
              <mat-form-field style="max-width: 180px; margin-left: 10px; margin-right: 10px;">
                <mat-label>ALV-ilmoitusjakso</mat-label>
                <mat-select #alvIlmoitusjaksoSelect formControlName="jakso" required>
                  <!-- <mat-option [value]="null">
                  Valitse jakso
                </mat-option> -->
                  <mat-option *ngFor="let jakso of alvIlmoitusjaksot" [value]="jakso.koodi">
                    {{ jakso.nimi }}
                  </mat-option>
                </mat-select>

                <mat-error>
                  <ng-container *ngIf="jakso.get('jakso'); let j">
                    <ng-container *ngIf="(j.dirty || j.touched)">
                      <ng-container *ngIf="j.errors?.samajakso">Jakso ei voi olla sama kuin edellinen.</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>

              </mat-form-field>
              <mat-form-field style="max-width: 150px; margin-left: 10px; margin-right: 10px;">
                <mat-label>Voimassa alkaen</mat-label>
                <input matInput monthPicker #monthPicker type="text" [matDatepicker]="alvIlmoitusPicker" formControlName="voimassaAlkaen" required>
                <mat-datepicker-toggle matSuffix [for]="alvIlmoitusPicker"></mat-datepicker-toggle>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.required">Kuukausi puuttuu</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.edellinen">Muutos ei voi olla samaan aikaan tai ennen edellistä muutosta.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.min">Uusi muutos voi alkaa aikaisintaan {{ minimumAllowedDate | ldate | async }}.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.matDatepickerMin">Uusi muutos voi alkaa aikaisintaan ensi kuussa.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.a1_or_3_to12">Jakson vaihto pidempään on alettava tammikuussa.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.b1_or_3_to12">12kk jaksoon siirtyminen voi tapahtua vain seuraavan vuoden tammikuussa.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.a12_to_3">12kk jakso voidaan muuttaa 3kk jaksoksi aikaisintaan seuraavana tammi-, huhti-, heinä- tai marraskuuna.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.b12_to_3">12kk jälkeen tuleva 3kk jakson on alettava tammi-, huhti-, heinä- tai marraskuussa.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.a1_to_3">1kk jakso voidaan muuttaa 3kk jaksoksi aikaisintaan seuraavana tammi-, huhti-, heinä- tai marraskuuna.</mat-error>
                <mat-error *ngIf="(jakso.get('voimassaAlkaen')?.dirty || jakso.get('voimassaAlkaen')?.touched) && jakso.get('voimassaAlkaen')?.errors?.b1_to_3">1kk jälkeen tuleva 3kk jakson on alettava tammi-, huhti-, heinä- tai marraskuussa.</mat-error>
                <mat-datepicker #alvIlmoitusPicker></mat-datepicker>
              </mat-form-field>

              <!-- <mat-form-field style="max-width: 400px; margin-left: 10px; margin-right: 10px;">
              <input [name]="namename" type="text" formControlName="syy" placeholder="Muutoksen syy" matInput data-lpignore="true" required />
              <mat-hint *ngIf="jakso.get('syy').enabled">Kerro miksi jakso muuttuu. Jos asiakassuhde loppuu, kerro myös syy lopettamiseen</mat-hint>
              <mat-error *ngIf="jakso.get('syy')?.errors?.required && (jakso.get('syy')?.dirty || jakso.get('syy')?.touched)">
                Muutoksen syy puuttuu
              </mat-error>
            </mat-form-field> -->

              <button [disabled]="jakso.get('jakso')?.disabled || 1 > i" [class.mat-elevation-z4]="!jakso.get('jakso')?.disabled" mat-icon-button (click)="poistaAlvIlmoitusjaksorivi(jakso)" type="button" style="padding:0;" class="secondarybutton">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div style="margin-left: 80px; max-width: 550px;" class="dark-gray-text">
          <div style="font-size: 16px; margin-bottom: 10px; line-height: 20px; margin-top: 40px;">Miten ALV-ilmoitusjaksoa on mahdollista muuttaa?</div>
          <div style="margin: .5em 0;">
            ALV-jaksoa voi muuttaa vapaasti kunnes ensimmäinen ALV-ilmoitus on lähetetty. Tämän jälkeen muutokset menevät seuraavan taulukon mukaan. Mikäli tarvitset muutoksen jota ei tueta, ilmoita siitä tekniseen tukeen.
          </div>
          <table>
            <tr>
              <td style="width: 120px; vertical-align: top;">12kk -> 3kk</td>
              <td>Aikaisintaan seuraavana tammi-, huhti-, heinä- tai marraskuuna. Kuukauden on aina oltava jokin edellä mainituista.</td>
            </tr>
            <tr>
              <td style="width: 120px; vertical-align: top;">12kk -> 1kk</td>
              <td>Aikaisintaan seuraavan kuukauden alusta.</td>
            </tr>
            <tr>
              <td style="width: 120px; vertical-align: top;"> 3kk -> 1kk</td>
              <td>Aikaisintaan seuraavan kuukauden alusta.</td>
            </tr>
            <tr>
              <td style="width: 120px; vertical-align: top;"> 1kk -> 3kk</td>
              <td>Seuraavan kvartaalin alku.</td>
            </tr>
            <tr>
              <td style="width: 120px; vertical-align: top;"> 1kk -> 12kk</td>
              <td>Seuraavan vuoden tammikuu.</td>
            </tr>
            <tr>
              <td style="width: 120px; vertical-align: top;"> 3kk -> 12kk</td>
              <td>Seuraavan vuoden tammikuu.</td>
            </tr>
          </table>
        </div>

      </div>

      <mat-error *ngIf="annaAlvIlmoitusjaksotFormArray()?.errors?.min && (annaAlvIlmoitusjaksotFormArray()?.dirty || annaAlvIlmoitusjaksotFormArray()?.touched)" style="margin-left: 10px;">
        Ole hyvä ja syötä vähintään yksi jakso.
      </mat-error>

      <div style="padding-top: 10px; margin-left: 10px; padding-bottom: 1.25em;">
        <button mat-button (click)="lisaaAlvIlmoitusjaksorivi()" type="button" class="secondarybutton mat-elevation-z4">
          <mat-icon>add</mat-icon>
          Lisää ALV-ilmoitusjakso
        </button>
      </div>
    </div>

    <!-- <div class="light-blue-text header">Pankkitilit</div> -->
    <!-- <mat-hint *ngIf="annaTilinumeroControllit().length > 0" style="margin-left: 10px; padding-top: 10px; display: block;">Pankki ja BIC täytetään automaattisesti suomalaisille pankeille IBAN-kentän mukaan</mat-hint> -->
    <!-- <div formArrayName="tilinumerot" style="max-width: 400px;">
      <div *ngFor="let tilinumero of annaTilinumeroControllit(); let i = index;" [formGroupName]="i">
        <div style="display: flex; justify-content: space-between; padding-top: 5px;">
          <mat-form-field style="max-width: 300px; margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'laskuasetukset.iban' | translate | async }}</mat-label>
<input [name]="namename" #ibanInput type="text" formControlName="iban"  required matInput data-lpignore="true" />
            <mat-hint>{{pankkiJaIbanTeksti(tilinumero)}}</mat-hint>
            <mat-error *ngIf="tilinumero.get('iban')?.errors?.required && (tilinumero.get('iban')?.dirty || tilinumero.get('iban')?.touched)">
              IBAN puuttuu
            </mat-error>
            <mat-error *ngIf="tilinumero.get('iban')?.errors?.iban && (tilinumero.get('iban')?.dirty || tilinumero.get('iban')?.touched)">
              IBAN on virheellinen
            </mat-error>
          </mat-form-field> -->
    <!-- <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'laskuasetukset.pankki' | translate | async }}</mat-label>
<input [name]="namename" type="text" formControlName="pankki"  matInput required data-lpignore="true" />
            <mat-error *ngIf="tilinumero.get('pankki')?.errors?.required && (tilinumero.get('pankki')?.dirty || tilinumero.get('pankki')?.touched)">
              Pankki puuttuu
            </mat-error>
          </mat-form-field>
          <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'laskuasetukset.bic' | translate | async }}</mat-label>
<input [name]="namename" type="text" formControlName="bic"  matInput required data-lpignore="true" />
            <mat-error *ngIf="tilinumero.get('bic')?.errors?.required && (tilinumero.get('bic')?.dirty || tilinumero.get('bic')?.touched)">
              BIC puuttuu
            </mat-error>
            <mat-error *ngIf="tilinumero.get('bic')?.errors?.bic && (tilinumero.get('bic')?.dirty || tilinumero.get('bic')?.touched)">
              BIC on virheellinen
            </mat-error>
          </mat-form-field> -->
    <!-- <div> -->
    <!-- [disabled]="hinta.get('hinta')?.disabled || 1 > i" -->
    <!-- [class.mat-elevation-z4]="!hinta.get('hinta')?.disabled" -->
    <!-- <button mat-icon-button (click)="poistaTilinumero(tilinumero)" type="button" style="padding:0;" class="secondarybutton mat-elevation-z4">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <mat-error *ngIf="annaTilinumerotFormArray()?.errors?.min && (annaTilinumerotFormArray()?.dirty || annaTilinumerotFormArray()?.touched)" style="margin-left: 10px;">
      Ole hyvä ja syötä vähintään yksi pankkitili.
    </mat-error> -->

    <!-- <div style="padding-top: 10px; margin-left: 10px; padding-bottom: 1.25em;">
      <button mat-button (click)="lisaaTilinumero()" type="button" class="secondarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        Lisää pankkitili
      </button>
    </div> -->

    <div class="light-blue-text header">Kirjanpitosähköpostien lisävastaanottajat</div>
    <div class="content" style="max-width: 450px;">
      <div style="margin-top: .5em; margin-bottom: .5em; font-style: italic;">Käytä tätä ainoastaan yhteissähköposteille, esim. info&#64;firma.fi. Yksityishenkilöille menevät viestit toimitetaan pääsääntöisesti yhteyshenkilö-roolin omaaville Lemonaid-käyttäjille.</div>
      <mat-chip-grid #vastaanottajaChips>
        <mat-chip-option style="margin-top: 15px; margin-left: 10px; margin-bottom: 15px" *ngFor="let vastaanottaja of this.asiakas.kirjanpitoviestienLisavastaanottajat" [selectable]="false" [removable]="true" (removed)="poistaVastaanottaja(vastaanottaja)">
          {{vastaanottaja}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-grid>
      <mat-form-field style="margin-bottom: 15px; margin-top: 25px">
        <mat-label>Lisää vastaanottaja</mat-label>
        <input #recipientInput type="email" [matChipInputFor]="vastaanottajaChips" (blur)="lisaaVastaanottaja()" [formControl]="vastaanottajaFormControl" (matChipInputTokenEnd)="lisaaVastaanottaja()" matInput data-lpignore="true" class="email-lisaa-vastaanottaja" />
        <mat-error *ngIf="vastaanottajaFormControl?.errors?.email && (vastaanottajaFormControl?.dirty || vastaanottajaFormControl?.touched)">
          Sähköpostiosoite on virheellinen.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="light-blue-text header">Tilikaudet</div>
    <div class="content">
      <div formArrayName="tilikaudet" style="max-width: 340px;">
        <div *ngFor="let kausi of annaTilikausiControllit(); let i = index;" [formGroupName]="i">
          <div style="display: flex; justify-content: space-between; padding-top: 5px;">
            <!-- <mat-form-field style="max-width: 180px; margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'Tilikausi' }}</mat-label>
<input [name]="namename" type="text" formControlName="kausi"  matInput required data-lpignore="true" />
            <mat-error *ngIf="kausi.get('kausi')?.errors?.required && (kausi.get('kausi')?.dirty || kausi.get('kausi')?.touched)">
              Kausi puuttuu
            </mat-error>
          </mat-form-field> -->
            <mat-form-field style="max-width: 125px; margin-right: 10px;">
              <!-- [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" -->
              <mat-label>Alkaa</mat-label>
              <input [name]="namename" #tilikausiAlkaaInput type="text" [matDatepicker]="alkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required />
              <mat-datepicker-toggle matSuffix [for]="alkaaPicker"></mat-datepicker-toggle>
              <mat-error *ngIf="kausi.get('alkaa')?.errors?.required && (kausi.get('alkaa')?.dirty || kausi.get('alkaa')?.touched)">
                Päivämäärä puuttuu
              </mat-error>
              <mat-datepicker #alkaaPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
              <!-- [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" -->
              <mat-label>Loppuu</mat-label>
              <input [name]="namename" type="text" [matDatepicker]="loppuuPicker" formControlName="loppuu" matInput data-lpignore="true" required />
              <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle>
              <mat-error *ngIf="kausi.get('loppuu')?.errors?.required && (kausi.get('loppuu')?.dirty || kausi.get('loppuu')?.touched)">
                Päivämäärä puuttuu
              </mat-error>
              <mat-datepicker #loppuuPicker></mat-datepicker>
            </mat-form-field>
            <div style="width: 50px;">
              <button (click)="avaaTilikausi(i)" *ngIf="kausi.get('lukittu').value; else lukitseTemplate" mat-icon-button title="Tilikausi on lukittu, eikä sitä voi muokata. Avaa napauttamalla lukkoa">
                <mat-icon>lock</mat-icon>
              </button>
              <ng-template #lukitseTemplate>
                <button (click)="lukitseTilikausi(i)" mat-icon-button title="Tilikausi on muokattavissa. Lukitse napauttamalla lukkoa">
                  <mat-icon>lock_open</mat-icon>
                </button>
              </ng-template>
            </div>

            <!-- <div> -->
            <!-- [disabled]="hinta.get('hinta')?.disabled || 1 > i" -->
            <!-- [class.mat-elevation-z4]="!hinta.get('hinta')?.disabled" -->
            <!-- <button mat-icon-button (click)="poistaTilikausi(kausi)" type="button" style="padding:0;" class="secondarybutton mat-elevation-z4">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div> -->
          </div>
        </div>
      </div>

      <mat-error *ngIf="annaTilikaudetFormArray()?.errors?.min && (annaTilikaudetFormArray()?.dirty || annaTilikaudetFormArray()?.touched)">
        Ole hyvä ja syötä vähintään yksi tilikausi.
      </mat-error>

      <div style="padding-top: 10px; padding-bottom: 1.25em;">
        <button mat-button (click)="lisaaTilikausi()" type="button" class="secondarybutton mat-elevation-z4">
          <mat-icon>add</mat-icon>
          Lisää tilikausi
        </button>
      </div>
    </div>

    <div class="light-blue-text header">Kirjanpidon peruste</div>
    <div class="content">
      <div formArrayName="kirjanpidonPerusteet" style="max-width: 500px;">
        <div *ngFor="let peruste of annaKirjanpidonPerusteetControllit(); let i = index;" [formGroupName]="i">
          <div style="display: flex; justify-content: space-between; padding-top: 5px;">
            <mat-form-field style="width: 200px; margin-right: 20px;">
              <mat-select placeholder="Peruste" formControlName="peruste" style="width: 200px" required>
                <mat-option *ngFor="let peruste of kaikkiKirjanpidonPerusteet " [value]="peruste.tunnus">
                  {{ peruste.nimi }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="max-width: 125px; margin-right: 10px;">
              <input [name]="namename" monthPicker [matDatepicker]="perusteAlkaaPicker" formControlName="alkaa" placeholder="Alkaa" matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="perusteAlkaaPicker"></mat-datepicker-toggle>
              <mat-datepicker #perusteAlkaaPicker></mat-datepicker>
              <mat-error *ngIf="peruste.get('alkaa')?.errors?.loppuEnnenAlkua && (peruste.get('alkaa')?.dirty || peruste.get('alkaa')?.touched)">
                Pitää alkaa ennen loppua
              </mat-error>
            </mat-form-field>
            <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
              <!-- [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" -->
              <input [name]="namename" monthPicker type="text" [matDatepicker]="perusteLoppuuPicker" formControlName="loppuu" placeholder="Loppuu" matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="perusteLoppuuPicker"></mat-datepicker-toggle>
              <mat-error *ngIf="peruste.get('loppuu')?.errors?.alkuLopunJalkeen && (peruste.get('loppuu')?.dirty || peruste.get('loppuu')?.touched)">
                Pitää loppua alun jälkeen
              </mat-error>
              <mat-datepicker #perusteLoppuuPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <mat-error *ngIf="peruste?.errors?.loppuEnnenAlkua && (peruste?.dirty || peruste?.touched)">
            Alkupäivämäärä pitää olla ennen loppupäivää.
          </mat-error>
        </div>

        <mat-error *ngIf="form.get('kirjanpidonPerusteet')?.errors?.edellisenLoppu && (form.get('kirjanpidonPerusteet')?.dirty || form.get('kirjanpidonPerusteet')?.touched)">
          Edellisen jakson on päätyttävä päivää ennen seuraavan alkamista.
        </mat-error>
        <mat-error *ngIf="form.get('kirjanpidonPerusteet')?.errors?.samaPeruste && (form.get('kirjanpidonPerusteet')?.dirty || form.get('kirjanpidonPerusteet')?.touched)">
          Peräkkäisillä jaksoilla on oltava eri peruste.
        </mat-error>
      </div>

      <div style="padding-top: 10px; padding-bottom: 1.25em;">
        <button mat-button (click)="lisaaKirjanpidonPeruste()" type="button" class="secondarybutton mat-elevation-z4">
          <mat-icon>add</mat-icon>
          Lisää peruste
        </button>
      </div>
    </div>

    <div class="light-blue-text header">Automaattinen omaverotäsmäytys</div>
    <div class="content">
      <div style="display: flex; justify-content: space-between; width: 18em;">
        <div>Tilikausi</div>
        <div>Päällä</div>
      </div>
      <div formArrayName="tasmaytysTilikaudet">
        <div *ngFor="let tasmaytysTilikaudetFormGroup of tasmaytysTilikaudetFormGroupit; let i = index;" [formGroupName]="i" style="display: flex; justify-content: space-between; align-items: center; width: 17em;">
          <div>{{tasmaytysTilikaudetFormGroup.get('tilikaudenJakso').value}}</div>
          <mat-checkbox formControlName="tasmaytysAktiivinen">
          </mat-checkbox>
        </div>
        <div style="margin-top: 2em; font-style: italic; max-width: 800px;">Jos automaattinen omaverotäsmäytys otetaan pois päältä, kyseisen tilikauden omaverokirjaukset poistetaan seuraavassa automaattiajossa (seuraavana yönä). Jos se laitetaan takaisin päälle, omaverokirjaukset tehdään seuraavan automaattiajon yhteydessä (seuraavana yönä). Omaveroautomatiikka ajetaan vain avoimien tilikausien
          aktiivisille kuukausille.</div>
      </div>
    </div>

    <div style="padding: 20px; margin-top: 20px;" class="mat-elevation-z3" *ngIf="naytaSuper">

      <span class="dark-gray-text" style="font-style: italic;">* Lomakkeen tiedot näkyvät vain pääkäyttäjä-roolilla</span>

      <div class="light-blue-text header" style="margin-top: 10px;">Vastuukirjanpitäjät</div>
      <div class="content">
        <div formArrayName="voimassaolojaksot" style="max-width: 400px;">
          <div *ngFor="let jakso of annaKirjanpitajienVoimassaolojaksoControllit(); let i = index;" [formGroupName]="i">
            <div style="display: flex; justify-content: space-between;">

              <mat-form-field>
                <mat-label>Kirjanpitäjä</mat-label>
                <mat-select #kirjanpitajaSelect formControlName="kirjanpitaja">
                  <mat-option [value]="eiKirjanpitajaaAvain">
                    Ei kirjanpitäjää
                  </mat-option>
                  <mat-option *ngFor="let kirjanpitaja of kirjanpitajatObservable | async" [value]="kirjanpitaja.avain">
                    {{kirjanpitaja.etunimi}} {{kirjanpitaja.sukunimi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- [min]="annaMinimiPaivamaara(jakso.get('voimassaoloAlkaa'))" -->
              <mat-form-field style="max-width: 150px; margin-left: 10px; margin-right: 10px;">
                <mat-label>Voimassa alkaen</mat-label>
                <input [name]="namename" type="text" [matDatepicker]="kirjanpitajaVoimassaPicker" formControlName="voimassaoloAlkaa" matInput data-lpignore="true" required />
                <mat-datepicker-toggle matSuffix [for]="kirjanpitajaVoimassaPicker"></mat-datepicker-toggle>
                <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.required && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                  Päivämäärä puuttuu
                </mat-error>
                <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.edellinen && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                  Muutos ei voi olla samaan aikaan tai ennen edellistä jaksoa.
                </mat-error>
                <!-- <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.min && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                Uusi muutos voi alkaa aikaisintaan ensi kuussa.
              </mat-error>
              <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.matDatepickerMin && (voimassaAlkaen?.dirty || voimassaAlkaen?.touched)">
                Uusi muutos voi alkaa aikaisintaan ensi kuussa.
              </mat-error> -->
                <mat-datepicker #kirjanpitajaVoimassaPicker></mat-datepicker>
              </mat-form-field>
              <button [disabled]="jakso.get('voimassaoloAlkaa')?.disabled || 1 > i" [class.mat-elevation-z4]="!jakso.get('voimassaoloAlkaa')?.disabled" mat-icon-button (click)="poistaKirjanpitajanVoimassaolojakso(jakso)" type="button" style="padding:0;" class="secondarybutton">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <mat-error *ngIf="annaKirjanpitajienVoimassaolojaksotFormArray()?.errors?.min && (annaKirjanpitajienVoimassaolojaksotFormArray()?.dirty || annaKirjanpitajienVoimassaolojaksotFormArray()?.touched)" style="margin-left: 10px;">
          Ole hyvä ja syötä vähintään yksi jakso.
        </mat-error>

        <div style="padding-top: 10px; margin-left: 10px; padding-bottom: 1.25em;">
          <button mat-button (click)="lisaaKirjanpitajanVoimassaolojakso()" type="button" class="secondarybutton mat-elevation-z4">
            <mat-icon>add</mat-icon>
            Lisää vastuukirjanpitojakso
          </button>
        </div>
      </div>

      <div class="light-blue-text header">Varakirjanpitäjät</div>
      <div class="content">
        <div formArrayName="voimassaolojaksotvara" style="max-width: 400px;">
          <div *ngFor="let jakso of annaKirjanpitajienVoimassaolojaksovaraControllit(); let i = index;" [formGroupName]="i">
            <div style="display: flex; justify-content: space-between;">

              <mat-form-field>
                <mat-label>Varakirjanpitäjä</mat-label>
                <mat-select #varakirjanpitajaSelect formControlName="kirjanpitaja">
                  <mat-option [value]="eiKirjanpitajaaAvain">
                    Ei kirjanpitäjää
                  </mat-option>
                  <mat-option *ngFor="let kirjanpitaja of kirjanpitajatObservable | async" [value]="kirjanpitaja.avain">
                    {{kirjanpitaja.etunimi}} {{kirjanpitaja.sukunimi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- [min]="annaMinimiPaivamaara(jakso.get('voimassaoloAlkaa'))" -->
              <mat-form-field style="max-width: 150px; margin-left: 10px; margin-right: 10px;">
                <mat-label>Voimassa alkaen</mat-label>
                <input [name]="namename" type="text" [matDatepicker]="varaKirjanpitajaVoimassaPicker" formControlName="voimassaoloAlkaa" matInput data-lpignore="true" required />
                <mat-datepicker-toggle matSuffix [for]="varaKirjanpitajaVoimassaPicker"></mat-datepicker-toggle>
                <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.required && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                  Päivämäärä puuttuu
                </mat-error>
                <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.edellinen && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                  Muutos ei voi olla samaan aikaan tai ennen edellistä jaksoa.
                </mat-error>
                <!-- <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.min && (jakso.get('voimassaoloAlkaa')?.dirty || jakso.get('voimassaoloAlkaa')?.touched)">
                Uusi muutos voi alkaa aikaisintaan ensi kuussa.
              </mat-error>
              <mat-error *ngIf="jakso.get('voimassaoloAlkaa')?.errors?.matDatepickerMin && (voimassaAlkaen?.dirty || voimassaAlkaen?.touched)">
                Uusi muutos voi alkaa aikaisintaan ensi kuussa.
              </mat-error> -->
                <mat-datepicker #varaKirjanpitajaVoimassaPicker></mat-datepicker>
              </mat-form-field>
              <button [disabled]="jakso.get('voimassaoloAlkaa')?.disabled" [class.mat-elevation-z4]="!jakso.get('voimassaoloAlkaa')?.disabled" mat-icon-button (click)="poistaKirjanpitajanVoimassaolojaksovara(jakso)" type="button" style="padding:0;" class="secondarybutton">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- <mat-error *ngIf="annaKirjanpitajienVoimassaolojaksotFormArray()?.errors?.min && (annaKirjanpitajienVoimassaolojaksotFormArray()?.dirty || annaKirjanpitajienVoimassaolojaksotFormArray()?.touched)" style="margin-left: 10px;">
        Ole hyvä ja syötä vähintään yksi jakso.
      </mat-error> -->

        <div style="padding-top: 10px; margin-left: 10px; padding-bottom: 1.25em;">
          <button mat-button (click)="lisaaKirjanpitajanVoimassaolojaksovara()" type="button" class="secondarybutton mat-elevation-z4">
            <mat-icon>add</mat-icon>
            Lisää varakirjanpitojakso
          </button>
        </div>
      </div>

      <ng-container *ngIf="onkoDevaajaObservable | async">
        <div class="light-blue-text header">Kirjanpidon tyyppi</div>
        <div class="content">
          <div formArrayName="kirjanpidonTyypit" style="max-width: 340px;">
            <div *ngFor="let tyyppi of annaKirjanpidonTyypitControllit(); let i = index;" [formGroupName]="i">
              <div style="display: flex; justify-content: space-between; padding-top: 5px;">
                <mat-form-field style="width: 250px; margin-right: 20px;">
                  <mat-label>Tyyppi</mat-label>
                  <mat-select formControlName="tyyppi" required>
                    <mat-option *ngFor="let tyyppi of kaikkiKirjanpidonTyypit" [value]="tyyppi.tunnus">
                      {{ tyyppi.nimi }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="max-width: 125px; margin-right: 10px;">
                  <mat-label>Alkaa</mat-label>
                  <input [name]="namename" type="text" [matDatepicker]="tyyppiAlkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required />
                  <mat-datepicker-toggle matSuffix [for]="tyyppiAlkaaPicker"></mat-datepicker-toggle>
                  <mat-datepicker #tyyppiAlkaaPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="max-width: 125px; margin-left: 10px; margin-right: 10px;">
                  <mat-label>Loppuu</mat-label>
                  <input [name]="namename" type="text" [matDatepicker]="tyyppiLoppuuPicker" formControlName="loppuu" matInput data-lpignore="true" required />
                  <mat-datepicker-toggle matSuffix [for]="tyyppiLoppuuPicker"></mat-datepicker-toggle>
                  <mat-datepicker #tyyppiLoppuuPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div style="padding-top: 10px; padding-bottom: 1.25em;">
            <button mat-button (click)="lisaaKirjanpidonTyyppi()" type="button" class="secondarybutton mat-elevation-z4">
              <mat-icon>add</mat-icon>
              Lisää kirjanpidon tyyppi
            </button>
          </div>
        </div>
      </ng-container>

      <div class="light-blue-text header">Käynnistä Omaverotäsmäytys</div>
      <div class="content">
        <form [formGroup]="omaveroForm" *ngIf="naytaOmaveroTasmaytysObservable | async else tmiPuppeteer">
          <div style="max-width: 400px;">
            <mat-form-field>
              <mat-label>Tilikausi</mat-label>
              <mat-select #kirjanpitajaSelect formControlName="omaveroTilikausi" required>
                <mat-option *ngFor="let tilikausi of omaveroTilikaudetObservable | async" [value]="tilikausi.avain">
                  {{ tilikausi.alkaa | lokaalipvm | async }} - {{ tilikausi.loppuu | lokaalipvm | async }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="omaverotasmaytysError" style="padding-bottom: 1em;" class="error-text">{{omaverotasmaytysError}}</div>
          <button mat-button (click)="kaynnistaOmaverotasmaytys()" type="button" class="primarybutton mat-elevation-z4">
            Käynnistä Omaverotäsmäytys tilikaudelle
          </button>
        </form>
      </div>
    </div>

    <div style="display: flex; justify-content: space-evenly; padding-top: 40px; padding-bottom: 2em; max-width: 500px;">
      <button class="primarybutton" mat-button (click)="save()" type="button" style="padding: 0 18px;">{{'yleiset.tallenna' | translate | async}}</button>
    </div>

  </form>

  <ng-template #eiToiminimille>
    <div style="max-width: 400px;">
      Omaverotäsmäytys ei ole käytössä toiminimille.
    </div>
  </ng-template>

  <ng-template #tmiPuppeteer>
    <ng-container *ngIf="naytaPuppeteerOmaveroTasmaytysObservable | async else eiToiminimille">
      <form [formGroup]="omaveroFormPuppeteer">
        <div style="max-width: 400px;">
          <mat-form-field>
            <mat-label>Tilikausi</mat-label>
            <mat-select #kirjanpitajaSelect formControlName="omaveroTilikausi" required>
              <mat-option *ngFor="let tilikausi of omaveroTilikaudetObservable | async" [value]="tilikausi.avain">
                {{ tilikausi.alkaa | lokaalipvm | async }} - {{ tilikausi.loppuu | lokaalipvm | async }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Pankki</mat-label>
            <mat-select #kirjanpitajaSelect formControlName="omaveroPankki" required>
              <mat-option *ngFor="let pankki of omaveroPankitObservable | async" [value]="pankki.tunnus">
                {{ pankki.nimi }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Käyttäjätunnus pankkiin</mat-label>
            <input [name]="namename" type="text" formControlName="omaveroTunnus" matInput data-lpignore="true" required />
          </mat-form-field>
        </div>
        <div *ngIf="omaverotasmaytysErrorPuppeteer" style="padding-bottom: 1em;" class="error-text">{{omaverotasmaytysErrorPuppeteer}}</div>
        <button mat-button (click)="kaynnistaOmaverotasmaytysPuppeteer()" type="button" class="primarybutton mat-elevation-z4">
          Käynnistä Omaverotäsmäytys tilikaudelle
        </button>
      </form>
      <div style="margin: 2em;">
        Viimeisimmän ajon logit
        <pre><ng-container *ngFor="let rivi of omaveroTasmaytysLogRivitObservable | async">{{rivi}}</ng-container></pre>
      </div>
    </ng-container>
  </ng-template>

</div>