/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Yritysmuoto } from '../../../_jaettu/model/kayttaja'
import { TuettuKieli } from '../../../_shared-core/model/common'

export interface Korvausparametrit {
  [key: string]: string
}

export class VeroilmoitusTranslationService {

  localizeSilent(numero: string, yritysmuoto: Yritysmuoto, vuosi: number, kieli: TuettuKieli, parametrit?: Korvausparametrit) {
    let teksti = this._raakaString(numero, yritysmuoto, vuosi, kieli)
    if (teksti === undefined || teksti === null) {
      return ''
    }
    if (parametrit) {
      for (const key of Object.keys(parametrit)) {
        const value = parametrit[key]
        // console.log('replace ', '{{' + key + '}}', 'with', value)
        teksti = teksti.replace('{{' + key + '}}', value)
      }
    }
    return teksti
  }

  localize(numero: string, yritysmuoto: Yritysmuoto, vuosi: number, kieli: TuettuKieli, parametrit?: Korvausparametrit): string {
    let teksti = this._raakaString(numero, yritysmuoto, vuosi, kieli)
    if (teksti === undefined || teksti === null) {
      return 'Ei käännöstä avaimelle ' + numero + ' kielellä ' + kieli
    }
    if (parametrit) {
      for (const key of Object.keys(parametrit)) {
        const value = parametrit[key]
        // console.log('replace ', '{{' + key + '}}', 'with', value)
        teksti = teksti.replace('{{' + key + '}}', value)
      }
    }
    return teksti
  }
  private _getTextsByYear(vuosi: number) {
    if (vuosi === 2020) {
      return this._get2020Texts()
    } else if (vuosi === 2021) {
      return this._get2021Texts()
    } else if (vuosi === 2022) {
      return this._get2022Texts()
    } else if (vuosi === 2023) {
      return this._get2023Texts()
    } else if (vuosi === 2024) {
      return this._get2024Texts()
    } else {
      throw new Error('No translations available for year ' + vuosi)
    }
  }
  private _getOptionTextsByYear(vuosi: number) {
    if (vuosi === 2020) {
      return this._get2020OptionsTexts()
    } else if (vuosi === 2021) {
      return this._get2021OptionsTexts()
    } else if (vuosi === 2022) {
      return this._get2022OptionsTexts()
    } else if (vuosi === 2023) {
      return this._get2023OptionsTexts()
    } else if (vuosi === 2024) {
      return this._get2024OptionsTexts()
    } else {
      throw new Error('No translations available for year ' + vuosi)
    }
  }

  private _raakaString(numero: string, yritysmuoto: Yritysmuoto, vuosi: number, kieli: TuettuKieli): string | null {
    const kaannokset = this._getTextsByYear(vuosi)
    if (kaannokset) {
      const kaannoksetKielelle = kaannokset[kieli]
      if (kaannoksetKielelle) {
        const kannoksetYritysmuodolle = kaannoksetKielelle[yritysmuoto]
        if (kannoksetYritysmuodolle) {
          const avaintenOsat = numero.split('.')
          if (avaintenOsat.length > 1) {
            let nykyinen = kannoksetYritysmuodolle[avaintenOsat[0]]
            avaintenOsat.shift()
            for (const avaimenOsa of avaintenOsat) {
              if (nykyinen) {
                nykyinen = nykyinen[avaimenOsa]
              }
            }
            if (nykyinen) {
              return nykyinen
            }
          } else {
            return kannoksetYritysmuodolle[avaintenOsat[0]]
          }
        }
      }
    }
    return null
  }

  getLocalizedOptions(numero: string, yritysmuoto: Yritysmuoto, vuosi: number, kieli: TuettuKieli) {
    let tekstit = null
    const kaannokset = this._getOptionTextsByYear(vuosi)
    if (kaannokset) {
      const kaannoksetKielelle = kaannokset[kieli]
      if (kaannoksetKielelle) {
        const kaannoksetYritysmuodolle = kaannoksetKielelle[yritysmuoto]
        if (kaannoksetYritysmuodolle) {
          const kaannoksetNumerolle = kaannoksetYritysmuodolle[numero]
          if (kaannoksetNumerolle) {
            tekstit = kaannoksetNumerolle
          }
        }
      }
    }
    if (tekstit === undefined || tekstit === null) {
      console.error('Ei käännöksiä avaimelle ' + numero + ' kielellä ' + kieli)
      return null
    }
    return tekstit
  }

  /**
   * NB! Texts only valid for 2020
   */
  private _get2020OptionsTexts() {
    return {
      'fi': {
        't': {
          '916': {
            '0': 'Yhdenkertainen',
            '1': 'Kahdenkertainen'
          },
          '396': {
            '1': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
            '2': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa'
          },
          '379': {
            '1': 'Henkilöautoja',
            '2': 'Pakettiautoja',
            '4': 'Muita kulkuneuvoja'
          },
          '380': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '416': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '391': {
            '1': 'Kyllä',
            '0,00': 'Ei', // Hacky solution - see web component
          },
          '395': {
            'DEFAULT': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
            '1': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
            '2': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          },
          'muuta-irtainta-omaisuutta': {
            '1': 'Kyllä',
            '0': 'Ei'
          }
        },
        'o': {
          '185': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '586': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '169': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '177': {
            '1': 'Kyllä',
            '2': 'Ei, tehdään myöhemmin',
            '3': 'Ei, tilitarkastaja on jätetty valitsematta'
          },
          '178': {
            '1': 'Kyllä',
            '2': 'Ei'
          },
          '173': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '174': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '176': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '184': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '182': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
        }
      }
    }
  }

  /**
   * NB! Texts only valid for 2020
   */
  private _get2020Texts() {
    return {
      'fi': {
        't': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'hae-ehdotus': 'Hae ehdotus',
          'ajopaivakirjaan': 'Ajopäiväkirjaan',
          'muuhun-selvitykseen': 'Muuhun selvitykseen',
          'kpl': 'kpl',

          // Taustatiedot
          'taustatiedot': 'Taustatiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          'kirjanpitotapa-part': 'Kirjanpitotapa',
          '916': '',
          'yhdenkertainen': 'Yhdenkertainen',
          'kahdenkertainen': 'Kahdenkertainen',
          'paaoman-erittely-part': 'Oman pääoman erittely',
          '748': 'Oma pääoma tilikauden alussa',
          '741': 'Yksityisnostot ja -sijoitukset',
          '742': 'Tilikauden voitto tai tappio (kirjanpito)',
          '743': 'Oma pääoma tilikauden lopussa',
          'laskelma-korkokuluista-part': 'Laskelma korkokuluista, joita ei voi vähentää elinkeinotoiminnan kuluna',
          '659': 'Taseen mukainen negatiivinen oma pääoma',
          '677': 'Omaan pääomaan sisältyvät arvonkorotukset',
          '650': 'Tilikauden tappio',
          '651': 'Aikaisempien tilikausien tappiot, joiden kattamiseen ei ole kertynyt voittovaroja',
          '652': 'Oikaistu negatiivinen pääoma',
          '653': 'Peruskorko + 1 %',
          '383': 'Korot joita ei voi vähentää elinkeinotoiminnan kuluina',
          'rahan-nostot-part': 'Rahan nostot ja sijoitukset elinkeinotoimintaan kalenterivuoden aikana',
          '384': 'Rahan nostot 1.1. - 31.12',
          '385': 'Rahan sijoitukset 1.1. - 31.12',

          // Tuotot
          'tuotot': 'Tuotot',
          'tuotot-part': 'Tiedot tuotoista',
          '300': 'Liikevaihto',
          '318': 'Avustukset ja tuet',
          '301': 'Liiketoiminnan muut tuotot',
          '323': 'Korkotuotot ja muut rahoitustuotot',
          '312': 'Varausten vähennys',
          '324': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa)',
          'tiedot-tuotoista-yhteensa': 'Tiedot tuotoista yhteensä',
          'elinkeino-ulkopuoliset-kulut-part': 'Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot',
          '379': 'Elinkeinotoimintaan kuuluvat ajoneuvot',
          'henkiloautoja': 'Henkilöautoja',
          'pakettiautoja': 'Pakettiautoja',
          'muita-kulkuneuvoja': 'Muita kulkuneuvoja',
          '380': 'Käyttötiedot perustuvat',
          '271': 'Elinkeinotoiminnan ajot',
          '273': 'Yksityisajot',
          '274': 'Verovuoden kilometrimäärä yhteensä',
          '381': 'Kokonaismenot',
          '275': 'Kulut keskimäärin kilometriä kohden',
          '382': 'Yksityisajojen osuus kuluista',
          '396': '',
          'yksityisajojen-kulut-on': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
          'yksityisajojen-kuluja-ei-ole': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa',
          '313': 'Tulotettava yksityisajojen osuus yhteensä',
          'tuloutus-yksityiskaytosta-part': 'Tuloutus yksityiskäytöstä',
          '314': 'Tavaroiden yksityiskäyttö',
          '315': 'Muu yksityiskäyttö',
          'tuloutus-yksityiskaytosta-yhteensa': 'Tuloutus yksityiskäytöstä yhteensä',
          'veronalaiset-tuotot-yhteensa-part': '',
          '316': 'Veronalaiset tuotot yhteensä',
          'verovapaat-tuotot-part': 'Verovapaat tuotot',
          '317': 'Tuloslaskelman verovapaat tuotot',
          'osingot-ylijaamat-part': 'Osingot, ylijäämät',
          'jos-yritys-on-saanut-osinkoja': 'Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta tai yrityksellä on varauksia, ilmoita ne tiedot OmaVerossa!',

          // Kulut
          'kulut': 'Kulut',
          'tiedot-kuluista-part': 'Tiedot kuluista',
          '333': 'Ostot ja varaston muutokset',
          '334': 'Ulkopuoliset palvelut',
          '335': 'Palkat ja palkkiot',
          '336': 'Eläke- ja henkilösivukulut',
          '342': 'Edustuskulujen ({{summa}}) vähennyskelpoinen osuus',
          '343': 'Vuokrat',
          '347': 'Korkokulujen ({{summa}}) vähennyskelpoinen osuus',
          '344': 'Muut vähennyskelpoiset kulut',
          '349': 'Muut rahoituskulut',
          '353': 'Varausten lisäykset',
          'tiedot-kuluista-yhteensa': 'Tiedot kuluista yhteensä',
          'poistot-part': 'Poistot',
          'muuta-irtainta-omaisuutta': 'Onko muuta kuin irtainta käyttöomaisuutta',
          'tayta-lomake-62': 'Täytä lomake 62 OmaVerossa!',
          'menojaannos-alussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden alussa',
          'menojaannos-lopussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden lopussa',
          'tilikauden-poistot': 'Tilikauden poistot',
          '800': 'Verotuksessa jäljellä oleva poistettava arvo verovuoden alussa',
          '801': 'Lisäys verovuoden aikana',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '804': 'Verovuoden poistot',
          '805': 'Lisäpoisto (EVL 32)',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '338': 'Poistojen vähennyskelpoinen määrä yhteensä',
          'yksityistalouteen-kuuluvan-auton-kaytto-part': 'Yksityistalouteen kuuluvan auton käyttö yritystoiminnassa',
          '416': 'Käyttötiedot perustuvat',
          '389': 'Verovuoden kilometrimäärä yhteensä',
          '390': 'Elinkeinotoiminnan ajot',
          '419': 'Kilometrikorvaus (oma auto) /km',
          '420': 'Enimmäismäärä yhteensä (oma auto)',
          '391': 'Kirjanpidossa vähennetty yksityisvaroihin kuuluvan auton käytöstä',
          '392': 'Yksityistalouteen kuuluvan auton käyttö yhteensä',
          'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part': 'Tilapäisistä työmatkoista aiheutuneet elantokustannukset',
          '406-410': '6 tunnin - 10 tunnin matkat',
          '401-405': 'Yli 10 tunnin matkat',
          '411-415': 'Ulkomaanmatkat',
          'kirjanpito-kuluja-vahennetty-kirjanpidossa': 'Oletko vähentänyt matkakuluja kirjanpidossa?',
          '388': 'Työmatkojen kustannukset yhteensä',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'tyohuone-vahennys-option': '',
          'kaavamainen-tyohuonevahennys': 'Kaavamainen työhuonevähennys',
          'tyohuonevahennys-todellisten-mukaan': 'Työhuonevähennys todellisten kulujen mukaan',
          '431': 'Koko asunnon pinta-ala',
          '432': 'Asunnosta oman elinkeinotoiminnan käytössä',
          '433': 'Koko asunnon vuokra, vastike tai kiinteistön käyttökulut',
          '386': 'Oman elinkeinotoiminnan käytössä olevan osan osuus kuluista',
          '354': 'Muut kirjanpidon vähennyskelpoiset kulut yhteensä',
          'vahennyskelpoiset-kulut-part': '',
          '355': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          'elinkeinotoiminnan-tulos-part': '',
          '358': 'Elinkeinotoiminnan tulos',
          '359': 'Elinkeinotoiminnan tappio',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '365': 'Välittömät verot',
          '366': 'Sakot ja muut rangaistusmaksut',
          '367': 'Muut vähennyskelvottomat kulut',
          'vahennyskelvottomat-kulut-yhteensa': 'Vähennyskelvottomat kulut yhteensä',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '704': 'Koneet ja kalusto',
          '706': 'Muu käyttöomaisuus',
          'kayttoomaisuus-yhteensa': 'Arvo yhteensä',
          'tavarat-ja-muu-part': 'Tavarat ja muu vaihto-omaisuus',
          '708': 'Tavarat',
          '709': 'Muu vaihto-omaisuus',
          'tavarat-ja-muu-yhteensa': 'Arvo yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '711': 'Myyntisaamiset',
          '714': 'Käteisvarat (ei pankkitalletuksia)',
          '716': 'Muu rahoitusomaisuus (ei pankkitalletuksia eikä arvopapereita)',
          'rahoitusomaisuus-yhteensa': 'Arvo yhteensä',
          'elinkeinotoiminnan-varat-part': '',
          '718': 'Elinkeinotoimininnan varat yhteensä',
          'elinkeinotoimininnan-velat-part': 'Elinkeinotoiminnan velat',
          '749': 'Lyhytaikaiset velat',
          '750': 'Pitkäaikaiset velat',
          '747': 'Oikaistu negatiivinen oma pääoma',
          '725': 'Velat yhteensä',
          'elinkeinotoiminnan-nettovarallisuus-part': '',
          '733': 'Elinkeinotoiminnan positiivinen nettovarallisuus',
          '734': 'Elinkeinotoiminnan negatiivinen nettovarallisuus',
          'toimintavaraus-ja-palkat-part': 'Toimintavaraus ja maksetut palkat',
          '871': 'Toimintavaraus',
          '394': 'Verovuoden päättymistä edeltäneiden 12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat',
          'paaomatulon-enimmaismaara-part': 'Pääomatulon enimmäismäärä',
          '395': '',
          'enimmaismaaraa-on-20': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
          'enimmaismaaraa-on-10': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
          'yritystulo-kokonaan-ansiotulo': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          'vaatimus-tappion-vahentamisesta-part': 'Vaatimus tappion vähentämisestä pääomatuloista',
          '373': 'Pääomatuloista vähennettävän elinkeinotoiminnan tappion määrä',
          'jaetaan-puolison-kesken-part': 'Yritystulo jaetaan puolison kesken',
          '369': 'Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta',
          '370': 'Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta',
          '371': 'Yrittäjän työskentely liikkeessä',
          '372': 'Yrittäjäpuolison työskentely liikkeessä',
          'arvopaperit-ja-luovutusvoitot-part': 'Arvopaperit ja luovutusvoitot',
          'jos-yrityksella-on-kiinteistoja': 'Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!'
        },
        'o': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'poista-osakas': 'Poista osakas',
          'lisaa-uusi': 'Lisää uusi',
          'hae-ehdotus': 'Hae ehdotus',
          'hae-ehdotukset': 'Hae ehdotukset',
          'kirjanpito': 'Kirjanpito',
          'verotus': 'Verotus',
          'kpl': 'kpl',

          // Perustiedot
          'perustiedot': 'Perustiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          '072': 'Toimiala',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          '044': 'Sähköposti',
          '185': 'Onko kaikkien osakkaiden omistusosuus alle 10%?',
          'osakkeenomistajat-part': 'Osakkeenomistajat',
          'osakkeenomistajat-part-help': 'Ilmoita tiedot yhtiön osakkeenomistajista sekä heille ja heidän omaisilleen annetuista lainoista ja muista suorituksista. Jos osakkaita on enemmän kuin 10, ilmoita tiedot vain niistä osakkaista, jotka omistavat vähintään 10 % yhtiön osakkeista. Jos jokainen osakas omistaa yhtiöstä alle 10 %, tietoja ei tarvitse ilmoittaa. Osakaslainaa saaneen osakkaan ja omaisen tiedot on ilmoitettava aina riippumatta omistusosuudesta.',
          '701': 'Osakeenomistajan nimi',
          '703': 'Henkilötunnus tai Y-tunnus',
          '570': 'Osakkeiden lukumäärä',
          '706': 'Vuokrat',
          '708': 'Korot',
          '707': 'Luovutukset',
          '709': 'Muut suoritukset',
          '864': 'Osakaslainat',
          'osakkaiden-huoneistot-part': 'Osakkaiden käytössä olleet huoneistot',
          'osakkaiden-huoneistot-part-help': 'Ilmoita tiedot sellaisista yhtiön omistamista huoneistoista ja kiinteistöistä, joita yrittäjäosakas tai hänen perheensä on käyttänyt asuntonaan tilikauden aikana. Yrittäjäosakkaalla tarkoitetaan johtavassa asemassa olevaa henkilöä, joka omistaa yksinään yli 30 prosenttia tai yhdessä perheensä kanssa yli 50 prosenttia yhtiön osakkeista tai osakkeiden tuottamasta äänimäärästä (Työntekijän eläkelaki 7 §).',
          '722': 'Osakkaan henkilötunnus',
          '724': 'Kiinteistön, kiinteistöyhtion tai asunto-osakeyhtiön nimi',
          '729': 'Käyttöaika',
          '730': 'Huoneiston tai kiinteistön arvo',
          'jaettavat-osingot-part': 'Jaettavat osingot ja ylijäämät',
          'onko-yhtio-paattanyt': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'jaettavat-osingot': 'Jaettavat osingot',
          '537': 'Päivä, jolloin päätös osingon- tai ylijäämänjaosta on tehty',
          '538': 'Päivä, jolloin osinko tai ylijäämä on nostettavissa',
          '522': 'Jaettavan osingon tai ylijäämän määrä yhteensä',

          // 'vapaan-jaettavat-varat-part': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'vapaan-jaettavat-varat': 'Vapaan oman pääoman rahastosta jaettavat varat',
          '293': 'Vapaan oman pääoman rahastosta jaettava määrä yhteensä',
          '294': 'Päivä, jolloin päätös varojen jaosta on tehty',
          '295': 'Päivä, jolloin varat ovat nostetttavissa',
          'omistusmuutos-ja-paaoma-part': 'Omistusmuutos ja pääoma',
          '531': 'Verovuosi, jolloin yli puolet osakkeista tai osuuksista on vaihtanut omistajaa',
          '531-help': 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia.',
          '586': 'Onko yhtiö lunastanut, luovuttanut tai hankkinut omia osakkeitaan',
          '571': 'Yhtiön hallussa olevien omien osakkeiden lukumäärä tilikauden päättyessä',
          'osakepaaoman-muutokset-tilikauden-jalkeen': 'Osakepääoman muutokset tilikauden päättymisen jälkeen',
          'osakepaaoma-ei-ole-muutettu': 'Osakepääomaa ei ole muutettu',
          '591': 'Osakepääomaa on korotettu',
          '592': 'Osakepääomaa on alennettu',
          'onko-yhtio-luotto-sijoitus-tai-vakuuslaitos': 'MIKÄ NUMERO? Onko yhtiö luottolaitos, sijoituspalveluyritys tai vakuutuslaitos?',
          '169': 'Harjoittaako yhtiö ainoastaan muuta kuin elinkeinotoimintaa?',

          // Tuloverolaskelma
          'tuloverolaskelma': 'Tuloverolaskelma',
          'elinkeinotoiminnan-tuotot': 'Elinkeinotoiminnan tuotot',
          'liiketoiminta-ja-oma-kaytto-part': 'Liiketoiminta ja oma käyttö',
          '330': 'Liikevaihto',
          '331': 'Valmistus omaan käyttöön',
          'toiminnan-muut-tuotot-part': 'Toiminnan muut tuotot',
          '332-333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332-header': 'Kirjanpito',
          '333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot verotuksessa',
          '333-header': 'Verotus',
          '260': 'Käyttöomaisuuskiinteistöjen luovutusvoitot',
          '334': 'Muun käyttöomaisuuden luovutusvoitot',
          '335': 'Saadut avustukset ja tuet',
          '336': 'Muut tuotot',
          'rahoitustuotot-part': 'Rahoitustuotot',
          '337-338': 'Saadut osingot ja ylijäämät',
          '337': 'Saadut osingot ja ylijäämät',
          '337-header': 'Kirjanpito',
          '338': 'Saatujen osingojen ja ylijäämien verotuksessa',
          '338-header': 'Verotus',
          '339': 'Korkotuotot konserniyhteysosapuolilta',
          '340': 'Korkotuotot omistusyhteysyrityksistä',
          '341': 'Muut korkotuotot',
          '344': 'Rahoitusomaisuuden luovutusvoitot',
          '345': 'Muut rahoitustuotot',
          'muut-tuotot-part': 'Muut tuotot',
          '346-347': 'Palautuvat arvonalennukset',
          '346': 'Palautuvat arvonalennukset',
          '346-header': 'Kirjanpito',
          '347': 'Palautuvien arvonalennusten verotuksessa',
          '347-header': 'Verotus',
          '439': 'Saatu konserniavustus',
          '350-351': 'Varausten vähennykset',
          '350': 'Varausten vähennykset',
          '351': 'Varausten vähennysten verotuksessa',
          '261': 'Muun omaisuuden luovutusvoitot',
          '353': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa',
          'elinkeinotoiminnan-veronalaiset-tuotot-part': '',
          '354': 'Elinkeinotoiminnan veronalaiset tuotot yhteensä',
          'verottomat-erat-kirjanpidossa-part': 'Verottomat erät kirjanpidossa',
          '357': 'Veronpalautus',
          '320': 'Elokuvan tuotantotuki',
          '318': 'Luovutusvoitot, epäsuora tuloutus',
          '358': 'Tuloslaskelman muut verottomat tuotot',
          'elinkeinotoiminnan-kulut': 'Elinkeinotoiminnan kulut',
          'materiaalit-ja-palvelut-part': 'Materiaalit ja palvelut',
          '359': 'Ostot ja varastonmuutokset',
          '360': 'Ulkopuoliset palvelut',
          'henkilostokulut-part': 'Henkilöstökulut',
          '361': 'Palkat ja palkkiot',
          '362': 'Eläkekulut',
          '363': 'Muut henkilösivukulut',
          'poistot-ja-kayttoomaisuuden-arvoalentumiset-part': 'Poistot ja käyttöomaisuuden arvoalentumiset',
          '364-365': 'Poistot',
          '364': 'Poistot',
          '364-header': 'Kirjanpito',
          '365': 'Poistot verotuksessa',
          '365-header': 'Verotus',
          '366-367': 'Käyttöomaisuuden arvonalentumiset',
          '366': 'Käyttöomaisuuden arvonalentumiset',
          '367': 'Käyttöomaisuuden arvonalentumiset verotuksessa',
          '800': 'Verotuksessa jäljellä oleva menojäännös verovuoden alussa',
          '801': 'Lisäykset',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '803': 'Jälleenhankintavarauksella katettu osuus hankintamenosta',
          '804': 'Säännönmukainen poisto (EVL)',
          '805': 'Lisäpoisto (EVL 32 §) ja verohuojennuspoisto',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '807': 'Kirjanpidon tulosta rasittava suunnitelman mukainen poisto',
          '847': 'Poistoeron lisäys',
          '848': 'Poistoeron vähennys',
          '849': 'Poistoero yhteensä',
          'toiminnan-muut-kulut-part': 'Toiminnan muut kulut',
          '368-369': 'Edustuskulut',
          '368': 'Edustuskulut',
          '368-header': 'Kirjanpito',
          '369': 'Edustuskulut verotuksessa',
          '369-header': 'Verotus',
          '370-371': 'Annetut lahjoitukset',
          '370': 'Annetut lahjoitukset',
          '371': 'Annettujen lahjoitukset verotuksessa',
          '372-373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '372': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot verotuksessa',
          '374': 'Muut käyttöomaisuuden luovutustappiot',
          '375': 'Leasingmaksut',
          '376': 'Myyntisaamisten arvonalentumiset',
          '377': 'Muut vähennyskelpoiset kulut',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '378': 'Välittömät verot',
          '379': 'Veronkorotukset ja myöhästymismaksut',
          '380': 'Sakot ja muut rangaistusmaksut',
          '381': 'Fuusiotappio',
          '382': 'Käyttöomaisuusosakkeiden arvonalentumiset',
          '383': 'Pakolliset varaukset',
          '384': 'Muut vähennyskelvottomat kulut',
          'rahoituskulut-part': 'Rahoituskulut',
          '328': 'Korkokulut konserniyhteysosapuolille',
          'none1-328': 'Korkokulut konserniyhteysosapuolille',
          'none1-header': 'Kirjanpito',
          '328-header': 'Verotus',
          '386': 'Korkokulut omistusyhteysyrityksille',
          '387': 'Muut korkokulut',
          '398': 'Korkokuluna käsiteltävät rahoituskulut',
          '399': 'Korkokulujen oikaisu',
          '388': 'Konsernituki ja saamisten arvonalentumiset',
          '388-dnt': 'Konsernituki ja saamisten arvonalentumiset',
          '389': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '389-390': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '390': 'Muiden rahoitusomaisuuden menetysten ja arvonalentumisten verotus',
          '391': 'Rahoitusomaisuuden luovutustappiot',
          '392': 'Muut rahoituskulut',
          'muut-kulut-part': 'Muut kulut',
          'none1-327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '327-header': 'Verotus',
          '327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '436': 'Annettu konserniavustus',
          '394': 'Varausten lisäykset',
          '394-395': 'Varausten lisäykset',
          '395': 'Varausten lisäykset verotuksessa',
          '270': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '270-271': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '271': 'Muun omaisuuden luovutustappioiden ja arvonalentumisten verotus',
          '272': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '272-273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumisten verotus',
          '396': 'Muut vähennyskelpoiset kulut (ei tilikauden tuloslaskelmassa)',
          '504': 'Erät, joita ei oteta huomioon tappiota vahvistettaessa',
          '397': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          '500': 'Elinkeinotoiminnan tulos',
          '505': 'Elinkeinotoiminnan tappio',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '400': 'Aineettomat oikeudet',
          '401': 'Pitkävaikutteiset menot',
          '402': 'Kiinteistöt',
          '403': 'Koneet ja kalusto',
          '404': 'Ennakkomaksut',
          '405': 'Käyttöomaisuusarvopaperit',
          '406': 'Saamiset saman konsernin yrityksiltä',
          '407': 'Saamiset omistusyhteysyrityksiltä',
          '408': 'Muut käyttöomaisuussaamiset',
          '409': 'Muu käyttöomaisuus',
          '410': 'Käyttöomaisuus yhteensä',
          'vaihto-omaisuus-part': 'Vaihto-omaisuus',
          '411': 'Aineet ja tarvikkeet',
          '412': 'Keskeneräiset tuotteet',
          '413': 'Valmiit tuotteet',
          '414': 'Tavarat',
          '415': 'Vaihto-omaisuuskiinteistöt',
          '416': 'Vaihto-omaisuusarvopaperit',
          '417': 'Muu vaihto-omaisuus',
          '418': 'Vaihto-omaisuus yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '419': 'Myyntisaamiset',
          '420': 'Saamiset saman konsernin yrityksiltä',
          '421': 'Saamiset omistusyhteysyrityksiltä',
          '422': 'Lainasaamiset',
          '423': 'Muut saamiset',
          '424': 'Rahoitusomaisuusarvopaperit',
          '425': 'Siirtosaamiset',
          '463': 'Osatuloutuksen saamiset',
          '426': 'Rahat',
          '427': 'Pankkisaamiset',
          '428': 'Muu rahoitusomaisuus',
          '429': 'Rahoitusomaisuus yhteensä',
          'muu-omaisuus-part': 'Muu omaisuus',
          '430': 'Arvopaperit',
          '431': 'Kiinteistöt',
          '432': 'Osakaslainat',
          '437': 'Saamiset saman konsernin yrityksiltä',
          '438': 'Muut saamiset',
          '433': 'Muu tähän ryhmään kuuluva omaisuus',
          '434': 'Muu omaisuus yhteensä',
          '435': 'Varat yhteensä',
          'velat-part': 'Velat',
          '450': 'Joukkovelkakirjalainat',
          '451': 'Vaihtovelkakirjalainat',
          '452': 'Lainat rahoituslaitoksilta',
          '453': 'Ostovelat',
          '454': 'Velat saman konsernin yrityksille',
          '455': 'Velat omistusyhteysyrityksille',
          '456': 'Velat osakkaille',
          '457': 'Siirtovelat',
          '464': 'Saadut ennakot (pitkäaikaiset)',
          '465': 'Saadut ennakot (lyhytaikaiset)',
          '458': 'Muut velat',
          '459': 'Pääomalainat',
          '460': 'Velat yhteensä',
          '461': 'Lyhytaikaiset velat yhteensä',
          '462': 'Pitkäaikaiset velat yhteensä',
          '573': 'Nettovarallisuus',
          '574': 'Nettovarallisuus',
          'oma-paaoma': 'Oma pääoma',
          'sidottu-oma-paaoma-part': 'Sidottu oma pääoma',
          '470': 'Osake- tai osuuspääoma',
          '495': 'Muu sidottu oma pääoma',
          'vapaa-oma-paaoma-part': 'Vapaa oma pääoma',
          '496': 'Sijoitetun vapaan oman pääoman rahasto',
          '497': 'Muut rahastot',
          '474': 'Edellisten tilikausien voitto',
          '475': 'Edellisten tilikausien tappio',
          '476': 'Tilikauden voitto',
          '477': 'Tilikauden tappio',
          '478': 'Oma pääoma yhteensä',

          // Lisätiedot
          'lisatiedot': 'Lisätiedot',
          'lisatiedot-part': 'Lisätiedot',
          '173': 'Onko yhtiön tilinpäätös kansainvälisten tilinpäätösstandardien mukainen?',
          '174': 'Onko yhtiö tehnyt liiketoimintasiirron verovuoden aikana (EVL 52 d §)?',
          '176': 'Onko yhtiö hankkinut osakkeita osakevaihdossa verovuoden aikana? (EVL 52 f §)',
          '184': 'Onko yhtiöllä toimintaa sekä Manner-Suomessa että Ahvenanmaalla?',
          '182': 'Yhtiö on velvollinen laatimaan siirtohinnoitteludokumentaation (VML 14 a §)',
          'tilintarkastus-part': 'Tilintarkastus',
          '177': 'Onko tilintarkastus tehty?',
          '178': 'Sisältääkö tilintarkastuskertomus tilintarkastuslain 3 luvun N1 1,2,5 §: ssä tarkoitettuja kielteisiä lausuntoja, huomautuksia tai lisätietoja?'
        }
      }
    }
  }

  /**
   * NB! Texts only valid for 2021
   */
  private _get2021OptionsTexts() {
    return {
      'fi': {
        't': {
          '916': {
            '0': 'Yhdenkertainen',
            '1': 'Kahdenkertainen'
          },
          '396': {
            '1': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
            '2': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa'
          },
          '379': {
            '1': 'Henkilöautoja',
            '2': 'Pakettiautoja',
            '4': 'Muita kulkuneuvoja'
          },
          '380': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '416': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '391': {
            '1': 'Kyllä',
            '0,00': 'Ei', // Hacky solution - see web component
          },
          '395': {
            'DEFAULT': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
            '1': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
            '2': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          },
          'muuta-irtainta-omaisuutta': {
            '1': 'Kyllä',
            '0': 'Ei'
          }
        },
        'o': {
          '185': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '586': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '169': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '177': {
            '1': 'Kyllä',
            '2': 'Ei, tehdään myöhemmin',
            '3': 'Ei, tilitarkastaja on jätetty valitsematta'
          },
          '178': {
            '1': 'Kyllä',
            '2': 'Ei'
          },
          '173': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '174': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '176': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '184': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '182': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
        }
      }
    }
  }

  /**
   *  NB! Texts only valid for 2021
   */
  private _get2021Texts() {
    return {
      'fi': {
        't': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'hae-ehdotus': 'Hae ehdotus',
          'ajopaivakirjaan': 'Ajopäiväkirjaan',
          'muuhun-selvitykseen': 'Muuhun selvitykseen',
          'kpl': 'kpl',

          // Taustatiedot
          'taustatiedot': 'Taustatiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          'kirjanpitotapa-part': 'Kirjanpitotapa',
          '916': '',
          'yhdenkertainen': 'Yhdenkertainen',
          'kahdenkertainen': 'Kahdenkertainen',
          'paaoman-erittely-part': 'Oman pääoman erittely',
          '748': 'Oma pääoma tilikauden alussa',
          '741': 'Yksityisnostot ja -sijoitukset',
          '742': 'Tilikauden voitto tai tappio (kirjanpito)',
          '743': 'Oma pääoma tilikauden lopussa',
          'laskelma-korkokuluista-part': 'Laskelma korkokuluista, joita ei voi vähentää elinkeinotoiminnan kuluna',
          '659': 'Taseen mukainen negatiivinen oma pääoma',
          '677': 'Omaan pääomaan sisältyvät arvonkorotukset',
          '650': 'Tilikauden tappio',
          '651': 'Aikaisempien tilikausien tappiot, joiden kattamiseen ei ole kertynyt voittovaroja',
          '652': 'Oikaistu negatiivinen pääoma',
          '653': 'Peruskorko + 1 %',
          '383': 'Korot joita ei voi vähentää elinkeinotoiminnan kuluina',
          'rahan-nostot-part': 'Rahan nostot ja sijoitukset elinkeinotoimintaan kalenterivuoden aikana',
          '384': 'Rahan nostot 1.1. - 31.12',
          '385': 'Rahan sijoitukset 1.1. - 31.12',

          // Tuotot
          'tuotot': 'Tuotot',
          'tuotot-part': 'Tiedot tuotoista',
          '300': 'Liikevaihto',
          '318': 'Avustukset ja tuet',
          '301': 'Liiketoiminnan muut tuotot',
          '323': 'Korkotuotot ja muut rahoitustuotot',
          '312': 'Varausten vähennys',
          '324': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa)',
          'tiedot-tuotoista-yhteensa': 'Tiedot tuotoista yhteensä',
          'elinkeino-ulkopuoliset-kulut-part': 'Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot',
          '379': 'Elinkeinotoimintaan kuuluvat ajoneuvot',
          'henkiloautoja': 'Henkilöautoja',
          'pakettiautoja': 'Pakettiautoja',
          'muita-kulkuneuvoja': 'Muita kulkuneuvoja',
          '380': 'Käyttötiedot perustuvat',
          '271': 'Elinkeinotoiminnan ajot',
          '273': 'Yksityisajot',
          '274': 'Verovuoden kilometrimäärä yhteensä',
          '381': 'Kokonaismenot',
          '275': 'Kulut keskimäärin kilometriä kohden',
          '382': 'Yksityisajojen osuus kuluista',
          '396': '',
          'yksityisajojen-kulut-on': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
          'yksityisajojen-kuluja-ei-ole': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa',
          '313': 'Tulotettava yksityisajojen osuus yhteensä',
          'tuloutus-yksityiskaytosta-part': 'Tuloutus yksityiskäytöstä',
          '314': 'Tavaroiden yksityiskäyttö',
          '315': 'Muu yksityiskäyttö',
          'tuloutus-yksityiskaytosta-yhteensa': 'Tuloutus yksityiskäytöstä yhteensä',
          'veronalaiset-tuotot-yhteensa-part': '',
          '316': 'Veronalaiset tuotot yhteensä',
          'verovapaat-tuotot-part': 'Verovapaat tuotot',
          '317': 'Tuloslaskelman verovapaat tuotot',
          'osingot-ylijaamat-part': 'Osingot, ylijäämät',
          'jos-yritys-on-saanut-osinkoja': 'Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta tai yrityksellä on varauksia, ilmoita ne tiedot OmaVerossa!',

          // Kulut
          'kulut': 'Kulut',
          'tiedot-kuluista-part': 'Tiedot kuluista',
          '333': 'Ostot ja varaston muutokset',
          '334': 'Ulkopuoliset palvelut',
          '335': 'Palkat ja palkkiot',
          '336': 'Eläke- ja henkilösivukulut',
          '342': 'Edustuskulujen ({{summa}}) vähennyskelpoinen osuus',
          '343': 'Vuokrat',
          '347': 'Korkokulujen ({{summa}}) vähennyskelpoinen osuus',
          '344': 'Muut vähennyskelpoiset kulut',
          '349': 'Muut rahoituskulut',
          '353': 'Varausten lisäykset',
          'tiedot-kuluista-yhteensa': 'Tiedot kuluista yhteensä',
          'poistot-part': 'Poistot',
          'muuta-irtainta-omaisuutta': 'Onko muuta kuin irtainta käyttöomaisuutta',
          'tayta-lomake-62': 'Täytä lomake 62 OmaVerossa!',
          'menojaannos-alussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden alussa',
          'menojaannos-lopussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden lopussa',
          'tilikauden-poistot': 'Tilikauden poistot',
          '800': 'Verotuksessa jäljellä oleva poistettava arvo verovuoden alussa',
          '801': 'Lisäys verovuoden aikana',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '804': 'Verovuoden poistot',
          '805': 'Lisäpoisto (EVL 32)',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '338': 'Poistojen vähennyskelpoinen määrä yhteensä',
          'yksityistalouteen-kuuluvan-auton-kaytto-part': 'Yksityistalouteen kuuluvan auton käyttö yritystoiminnassa',
          '416': 'Käyttötiedot perustuvat',
          '389': 'Verovuoden kilometrimäärä yhteensä',
          '390': 'Elinkeinotoiminnan ajot',
          '419': 'Kilometrikorvaus (oma auto) /km',
          '420': 'Enimmäismäärä yhteensä (oma auto)',
          '391': 'Kirjanpidossa vähennetty yksityisvaroihin kuuluvan auton käytöstä',
          '392': 'Yksityistalouteen kuuluvan auton käyttö yhteensä',
          'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part': 'Tilapäisistä työmatkoista aiheutuneet elantokustannukset',
          '406-410': '6 tunnin - 10 tunnin matkat',
          '401-405': 'Yli 10 tunnin matkat',
          '411-415': 'Ulkomaanmatkat',
          'kirjanpito-kuluja-vahennetty-kirjanpidossa': 'Oletko vähentänyt matkakuluja kirjanpidossa?',
          '388': 'Työmatkojen kustannukset yhteensä',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'tyohuone-vahennys-option': '',
          'kaavamainen-tyohuonevahennys': 'Kaavamainen työhuonevähennys',
          'tyohuonevahennys-todellisten-mukaan': 'Työhuonevähennys todellisten kulujen mukaan',
          '431': 'Koko asunnon pinta-ala',
          '432': 'Asunnosta oman elinkeinotoiminnan käytössä',
          '433': 'Koko asunnon vuokra, vastike tai kiinteistön käyttökulut',
          '386': 'Oman elinkeinotoiminnan käytössä olevan osan osuus kuluista',
          '354': 'Muut kirjanpidon vähennyskelpoiset kulut yhteensä',
          'vahennyskelpoiset-kulut-part': '',
          '355': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          'elinkeinotoiminnan-tulos-part': '',
          '358': 'Elinkeinotoiminnan tulos',
          '359': 'Elinkeinotoiminnan tappio',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '365': 'Välittömät verot',
          '366': 'Sakot ja muut rangaistusmaksut',
          '367': 'Muut vähennyskelvottomat kulut',
          'vahennyskelvottomat-kulut-yhteensa': 'Vähennyskelvottomat kulut yhteensä',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '704': 'Koneet ja kalusto',
          '706': 'Muu käyttöomaisuus',
          'kayttoomaisuus-yhteensa': 'Arvo yhteensä',
          'tavarat-ja-muu-part': 'Tavarat ja muu vaihto-omaisuus',
          '708': 'Tavarat',
          '709': 'Muu vaihto-omaisuus',
          'tavarat-ja-muu-yhteensa': 'Arvo yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '711': 'Myyntisaamiset',
          '714': 'Käteisvarat (ei pankkitalletuksia)',
          '716': 'Muu rahoitusomaisuus (ei pankkitalletuksia eikä arvopapereita)',
          'rahoitusomaisuus-yhteensa': 'Arvo yhteensä',
          'elinkeinotoiminnan-varat-part': '',
          '718': 'Elinkeinotoimininnan varat yhteensä',
          'elinkeinotoimininnan-velat-part': 'Elinkeinotoiminnan velat',
          '749': 'Lyhytaikaiset velat',
          '750': 'Pitkäaikaiset velat',
          '747': 'Oikaistu negatiivinen oma pääoma',
          '725': 'Velat yhteensä',
          'elinkeinotoiminnan-nettovarallisuus-part': '',
          '733': 'Elinkeinotoiminnan positiivinen nettovarallisuus',
          '734': 'Elinkeinotoiminnan negatiivinen nettovarallisuus',
          'toimintavaraus-ja-palkat-part': 'Toimintavaraus ja maksetut palkat',
          '871': 'Toimintavaraus',
          '394': 'Verovuoden päättymistä edeltäneiden 12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat',
          'paaomatulon-enimmaismaara-part': 'Pääomatulon enimmäismäärä',
          '395': '',
          'enimmaismaaraa-on-20': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
          'enimmaismaaraa-on-10': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
          'yritystulo-kokonaan-ansiotulo': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          'vaatimus-tappion-vahentamisesta-part': 'Vaatimus tappion vähentämisestä pääomatuloista',
          '373': 'Pääomatuloista vähennettävän elinkeinotoiminnan tappion määrä',
          'jaetaan-puolison-kesken-part': 'Yritystulo jaetaan puolison kesken',
          '369': 'Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta',
          '370': 'Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta',
          '371': 'Yrittäjän työskentely liikkeessä',
          '372': 'Yrittäjäpuolison työskentely liikkeessä',
          'arvopaperit-ja-luovutusvoitot-part': 'Arvopaperit ja luovutusvoitot',
          'jos-yrityksella-on-kiinteistoja': 'Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!'
        },
        'o': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'poista-osakas': 'Poista osakas',
          'lisaa-uusi': 'Lisää uusi',
          'hae-ehdotus': 'Hae ehdotus',
          'hae-ehdotukset': 'Hae ehdotukset',
          'kirjanpito': 'Kirjanpito',
          'verotus': 'Verotus',
          'kpl': 'kpl',

          // Perustiedot
          'perustiedot': 'Perustiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          '072': 'Toimiala',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          '044': 'Sähköposti',
          '185': 'Onko kaikkien osakkaiden omistusosuus alle 10%?',
          'osakkeenomistajat-part': 'Osakkeenomistajat',
          'osakkeenomistajat-part-help': 'Ilmoita tiedot yhtiön osakkeenomistajista sekä heille ja heidän omaisilleen annetuista lainoista ja muista suorituksista. Jos osakkaita on enemmän kuin 10, ilmoita tiedot vain niistä osakkaista, jotka omistavat vähintään 10 % yhtiön osakkeista. Jos jokainen osakas omistaa yhtiöstä alle 10 %, tietoja ei tarvitse ilmoittaa. Osakaslainaa saaneen osakkaan ja omaisen tiedot on ilmoitettava aina riippumatta omistusosuudesta.',
          '701': 'Osakeenomistajan nimi',
          '703': 'Henkilötunnus tai Y-tunnus',
          '570': 'Osakkeiden lukumäärä',
          '706': 'Vuokrat',
          '708': 'Korot',
          '707': 'Luovutukset',
          '709': 'Muut suoritukset',
          '864': 'Osakaslainat',
          'osakkaiden-huoneistot-part': 'Osakkaiden käytössä olleet huoneistot',
          'osakkaiden-huoneistot-part-help': 'Ilmoita tiedot sellaisista yhtiön omistamista huoneistoista ja kiinteistöistä, joita yrittäjäosakas tai hänen perheensä on käyttänyt asuntonaan tilikauden aikana. Yrittäjäosakkaalla tarkoitetaan johtavassa asemassa olevaa henkilöä, joka omistaa yksinään yli 30 prosenttia tai yhdessä perheensä kanssa yli 50 prosenttia yhtiön osakkeista tai osakkeiden tuottamasta äänimäärästä (Työntekijän eläkelaki 7 §).',
          '722': 'Osakkaan henkilötunnus',
          '724': 'Kiinteistön, kiinteistöyhtion tai asunto-osakeyhtiön nimi',
          '729': 'Käyttöaika',
          '730': 'Huoneiston tai kiinteistön arvo',
          'jaettavat-osingot-part': 'Jaettavat osingot ja ylijäämät',
          'onko-yhtio-paattanyt': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'jaettavat-osingot': 'Jaettavat osingot',
          '537': 'Päivä, jolloin päätös osingon- tai ylijäämänjaosta on tehty',
          '538': 'Päivä, jolloin osinko tai ylijäämä on nostettavissa',
          '522': 'Jaettavan osingon tai ylijäämän määrä yhteensä',

          // 'vapaan-jaettavat-varat-part': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'vapaan-jaettavat-varat': 'Vapaan oman pääoman rahastosta jaettavat varat',
          '293': 'Vapaan oman pääoman rahastosta jaettava määrä yhteensä',
          '294': 'Päivä, jolloin päätös varojen jaosta on tehty',
          '295': 'Päivä, jolloin varat ovat nostetttavissa',
          'omistusmuutos-ja-paaoma-part': 'Omistusmuutos ja pääoma',
          '531': 'Verovuosi, jolloin yli puolet osakkeista tai osuuksista on vaihtanut omistajaa',
          '531-help': 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia.',
          '586': 'Onko yhtiö lunastanut, luovuttanut tai hankkinut omia osakkeitaan',
          '571': 'Yhtiön hallussa olevien omien osakkeiden lukumäärä tilikauden päättyessä',
          'osakepaaoman-muutokset-tilikauden-jalkeen': 'Osakepääoman muutokset tilikauden päättymisen jälkeen',
          'osakepaaoma-ei-ole-muutettu': 'Osakepääomaa ei ole muutettu',
          '591': 'Osakepääomaa on korotettu',
          '592': 'Osakepääomaa on alennettu',
          'onko-yhtio-luotto-sijoitus-tai-vakuuslaitos': 'MIKÄ NUMERO? Onko yhtiö luottolaitos, sijoituspalveluyritys tai vakuutuslaitos?',
          '169': 'Harjoittaako yhtiö ainoastaan muuta kuin elinkeinotoimintaa?',

          // Tuloverolaskelma
          'tuloverolaskelma': 'Tuloverolaskelma',
          'elinkeinotoiminnan-tuotot': 'Elinkeinotoiminnan tuotot',
          'liiketoiminta-ja-oma-kaytto-part': 'Liiketoiminta ja oma käyttö',
          '330': 'Liikevaihto',
          '331': 'Valmistus omaan käyttöön',
          'toiminnan-muut-tuotot-part': 'Toiminnan muut tuotot',
          '332-333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332-header': 'Kirjanpito',
          '333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot verotuksessa',
          '333-header': 'Verotus',
          '260': 'Käyttöomaisuuskiinteistöjen luovutusvoitot',
          '334': 'Muun käyttöomaisuuden luovutusvoitot',
          '335': 'Saadut avustukset ja tuet',
          '336': 'Muut tuotot',
          'rahoitustuotot-part': 'Rahoitustuotot',
          '337-338': 'Saadut osingot ja ylijäämät',
          '337': 'Saadut osingot ja ylijäämät',
          '337-header': 'Kirjanpito',
          '338': 'Saatujen osingojen ja ylijäämien verotuksessa',
          '338-header': 'Verotus',
          '339': 'Korkotuotot konserniyhteysosapuolilta',
          '340': 'Korkotuotot omistusyhteysyrityksistä',
          '341': 'Muut korkotuotot',
          '344': 'Rahoitusomaisuuden luovutusvoitot',
          '345': 'Muut rahoitustuotot',
          'muut-tuotot-part': 'Muut tuotot',
          '346-347': 'Palautuvat arvonalennukset',
          '346': 'Palautuvat arvonalennukset',
          '346-header': 'Kirjanpito',
          '347': 'Palautuvien arvonalennusten verotuksessa',
          '347-header': 'Verotus',
          '439': 'Saatu konserniavustus',
          '350-351': 'Varausten vähennykset',
          '350': 'Varausten vähennykset',
          '351': 'Varausten vähennysten verotuksessa',
          '261': 'Muun omaisuuden luovutusvoitot',
          '353': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa',
          'elinkeinotoiminnan-veronalaiset-tuotot-part': '',
          '354': 'Elinkeinotoiminnan veronalaiset tuotot yhteensä',
          'verottomat-erat-kirjanpidossa-part': 'Verottomat erät kirjanpidossa',
          '357': 'Veronpalautus',
          '320': 'Elokuvan tuotantotuki',
          '318': 'Luovutusvoitot, epäsuora tuloutus',
          '358': 'Tuloslaskelman muut verottomat tuotot',
          'elinkeinotoiminnan-kulut': 'Elinkeinotoiminnan kulut',
          'materiaalit-ja-palvelut-part': 'Materiaalit ja palvelut',
          '359': 'Ostot ja varastonmuutokset',
          '360': 'Ulkopuoliset palvelut',
          'henkilostokulut-part': 'Henkilöstökulut',
          '361': 'Palkat ja palkkiot',
          '362': 'Eläkekulut',
          '363': 'Muut henkilösivukulut',
          'poistot-ja-kayttoomaisuuden-arvoalentumiset-part': 'Poistot ja käyttöomaisuuden arvoalentumiset',
          '364-365': 'Poistot',
          '364': 'Poistot',
          '364-header': 'Kirjanpito',
          '365': 'Poistot verotuksessa',
          '365-header': 'Verotus',
          '366-367': 'Käyttöomaisuuden arvonalentumiset',
          '366': 'Käyttöomaisuuden arvonalentumiset',
          '367': 'Käyttöomaisuuden arvonalentumiset verotuksessa',
          '800': 'Verotuksessa jäljellä oleva menojäännös verovuoden alussa',
          '801': 'Lisäykset',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '803': 'Jälleenhankintavarauksella katettu osuus hankintamenosta',
          '804': 'Säännönmukainen poisto (EVL)',
          '805': 'Lisäpoisto (EVL 32 §) ja verohuojennuspoisto',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '807': 'Kirjanpidon tulosta rasittava suunnitelman mukainen poisto',
          '847': 'Poistoeron lisäys',
          '848': 'Poistoeron vähennys',
          '849': 'Poistoero yhteensä',
          'toiminnan-muut-kulut-part': 'Toiminnan muut kulut',
          '368-369': 'Edustuskulut',
          '368': 'Edustuskulut',
          '368-header': 'Kirjanpito',
          '369': 'Edustuskulut verotuksessa',
          '369-header': 'Verotus',
          '370-371': 'Annetut lahjoitukset',
          '370': 'Annetut lahjoitukset',
          '371': 'Annettujen lahjoitukset verotuksessa',
          '372-373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '372': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot verotuksessa',
          '374': 'Muut käyttöomaisuuden luovutustappiot',
          '375': 'Leasingmaksut',
          '376': 'Myyntisaamisten arvonalentumiset',
          '377': 'Muut vähennyskelpoiset kulut',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '378': 'Välittömät verot',
          '379': 'Veronkorotukset ja myöhästymismaksut',
          '380': 'Sakot ja muut rangaistusmaksut',
          '381': 'Fuusiotappio',
          '382': 'Käyttöomaisuusosakkeiden arvonalentumiset',
          '383': 'Pakolliset varaukset',
          '384': 'Muut vähennyskelvottomat kulut',
          'rahoituskulut-part': 'Rahoituskulut',
          '328': 'Korkokulut konserniyhteysosapuolille',
          'none1-328': 'Korkokulut konserniyhteysosapuolille',
          'none1-header': 'Kirjanpito',
          '328-header': 'Verotus',
          '386': 'Korkokulut omistusyhteysyrityksille',
          '387': 'Muut korkokulut',
          '398': 'Korkokuluna käsiteltävät rahoituskulut',
          '399': 'Korkokulujen oikaisu',
          '388': 'Konsernituki ja saamisten arvonalentumiset',
          '388-dnt': 'Konsernituki ja saamisten arvonalentumiset',
          '389': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '389-390': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '390': 'Muiden rahoitusomaisuuden menetysten ja arvonalentumisten verotus',
          '391': 'Rahoitusomaisuuden luovutustappiot',
          '392': 'Muut rahoituskulut',
          'muut-kulut-part': 'Muut kulut',
          'none1-327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '327-header': 'Verotus',
          '327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '436': 'Annettu konserniavustus',
          '394': 'Varausten lisäykset',
          '394-395': 'Varausten lisäykset',
          '395': 'Varausten lisäykset verotuksessa',
          '270': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '270-271': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '271': 'Muun omaisuuden luovutustappioiden ja arvonalentumisten verotus',
          '272': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '272-273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumisten verotus',
          '396': 'Muut vähennyskelpoiset kulut (ei tilikauden tuloslaskelmassa)',
          '504': 'Erät, joita ei oteta huomioon tappiota vahvistettaessa',
          '397': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          '500': 'Elinkeinotoiminnan tulos',
          '505': 'Elinkeinotoiminnan tappio',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '400': 'Aineettomat oikeudet',
          '401': 'Pitkävaikutteiset menot',
          '402': 'Kiinteistöt',
          '403': 'Koneet ja kalusto',
          '404': 'Ennakkomaksut',
          '405': 'Käyttöomaisuusarvopaperit',
          '406': 'Saamiset saman konsernin yrityksiltä',
          '407': 'Saamiset omistusyhteysyrityksiltä',
          '408': 'Muut käyttöomaisuussaamiset',
          '409': 'Muu käyttöomaisuus',
          '410': 'Käyttöomaisuus yhteensä',
          'vaihto-omaisuus-part': 'Vaihto-omaisuus',
          '411': 'Aineet ja tarvikkeet',
          '412': 'Keskeneräiset tuotteet',
          '413': 'Valmiit tuotteet',
          '414': 'Tavarat',
          '415': 'Vaihto-omaisuuskiinteistöt',
          '416': 'Vaihto-omaisuusarvopaperit',
          '417': 'Muu vaihto-omaisuus',
          '418': 'Vaihto-omaisuus yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '419': 'Myyntisaamiset',
          '420': 'Saamiset saman konsernin yrityksiltä',
          '421': 'Saamiset omistusyhteysyrityksiltä',
          '422': 'Lainasaamiset',
          '423': 'Muut saamiset',
          '424': 'Rahoitusomaisuusarvopaperit',
          '425': 'Siirtosaamiset',
          '463': 'Osatuloutuksen saamiset',
          '426': 'Rahat',
          '427': 'Pankkisaamiset',
          '428': 'Muu rahoitusomaisuus',
          '429': 'Rahoitusomaisuus yhteensä',
          'muu-omaisuus-part': 'Muu omaisuus',
          '430': 'Arvopaperit',
          '431': 'Kiinteistöt',
          '432': 'Osakaslainat',
          '437': 'Saamiset saman konsernin yrityksiltä',
          '438': 'Muut saamiset',
          '433': 'Muu tähän ryhmään kuuluva omaisuus',
          '434': 'Muu omaisuus yhteensä',
          '435': 'Varat yhteensä',
          'velat-part': 'Velat',
          '450': 'Joukkovelkakirjalainat',
          '451': 'Vaihtovelkakirjalainat',
          '452': 'Lainat rahoituslaitoksilta',
          '453': 'Ostovelat',
          '454': 'Velat saman konsernin yrityksille',
          '455': 'Velat omistusyhteysyrityksille',
          '456': 'Velat osakkaille',
          '457': 'Siirtovelat',
          '464': 'Saadut ennakot (pitkäaikaiset)',
          '465': 'Saadut ennakot (lyhytaikaiset)',
          '458': 'Muut velat',
          '459': 'Pääomalainat',
          '460': 'Velat yhteensä',
          '461': 'Lyhytaikaiset velat yhteensä',
          '462': 'Pitkäaikaiset velat yhteensä',
          '573': 'Nettovarallisuus',
          '574': 'Nettovarallisuus',
          'oma-paaoma': 'Oma pääoma',
          'sidottu-oma-paaoma-part': 'Sidottu oma pääoma',
          '470': 'Osake- tai osuuspääoma',
          '495': 'Muu sidottu oma pääoma',
          'vapaa-oma-paaoma-part': 'Vapaa oma pääoma',
          '496': 'Sijoitetun vapaan oman pääoman rahasto',
          '497': 'Muut rahastot',
          '474': 'Edellisten tilikausien voitto',
          '475': 'Edellisten tilikausien tappio',
          '476': 'Tilikauden voitto',
          '477': 'Tilikauden tappio',
          '478': 'Oma pääoma yhteensä',

          // Lisätiedot
          'lisatiedot': 'Lisätiedot',
          'lisatiedot-part': 'Lisätiedot',
          '173': 'Onko yhtiön tilinpäätös kansainvälisten tilinpäätösstandardien mukainen?',
          '174': 'Onko yhtiö tehnyt liiketoimintasiirron verovuoden aikana (EVL 52 d §)?',
          '176': 'Onko yhtiö hankkinut osakkeita osakevaihdossa verovuoden aikana? (EVL 52 f §)',
          '184': 'Onko yhtiöllä toimintaa sekä Manner-Suomessa että Ahvenanmaalla?',
          '182': 'Yhtiö on velvollinen laatimaan siirtohinnoitteludokumentaation (VML 14 a §)',
          'tilintarkastus-part': 'Tilintarkastus',
          '177': 'Onko tilintarkastus tehty?',
          '178': 'Sisältääkö tilintarkastuskertomus tilintarkastuslain 3 luvun N1 1,2,5 §: ssä tarkoitettuja kielteisiä lausuntoja, huomautuksia tai lisätietoja?'
        }
      }
    }
  }

  /**
   * NB! Texts only valid for 2022
   */
  private _get2022OptionsTexts() {
    return {
      'fi': {
        't': {
          '916': {
            '0': 'Yhdenkertainen',
            '1': 'Kahdenkertainen'
          },
          '396': {
            '1': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
            '2': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa'
          },
          '379': {
            '1': 'Henkilöautoja',
            '2': 'Pakettiautoja',
            '4': 'Muita kulkuneuvoja'
          },
          '380': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '416': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '391': {
            '1': 'Kyllä',
            '0,00': 'Ei', // Hacky solution - see web component
          },
          '395': {
            'DEFAULT': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
            '1': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
            '2': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          },
          'muuta-irtainta-omaisuutta': {
            '1': 'Kyllä',
            '0': 'Ei'
          }
        },
        'o': {
          '185': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '586': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '169': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '177': {
            '1': 'Kyllä',
            '2': 'Ei, tehdään myöhemmin',
            '3': 'Ei, tilitarkastaja on jätetty valitsematta'
          },
          '178': {
            '1': 'Kyllä',
            '2': 'Ei'
          },
          '173': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '174': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '176': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '184': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '182': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
        }
      }
    }
  }

  /**
   *  NB! Texts only valid for 2022
   */
  private _get2022Texts() {
    return {
      'fi': {
        't': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'hae-ehdotus': 'Hae ehdotus',
          'ajopaivakirjaan': 'Ajopäiväkirjaan',
          'muuhun-selvitykseen': 'Muuhun selvitykseen',
          'kpl': 'kpl',

          // Taustatiedot
          'taustatiedot': 'Taustatiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          'kirjanpitotapa-part': 'Kirjanpitotapa',
          '916': '',
          'yhdenkertainen': 'Yhdenkertainen',
          'kahdenkertainen': 'Kahdenkertainen',
          'paaoman-erittely-part': 'Oman pääoman erittely',
          '748': 'Oma pääoma tilikauden alussa',
          '741': 'Yksityisnostot ja -sijoitukset',
          '742': 'Tilikauden voitto tai tappio (kirjanpito)',
          '743': 'Oma pääoma tilikauden lopussa',
          'laskelma-korkokuluista-part': 'Laskelma korkokuluista, joita ei voi vähentää elinkeinotoiminnan kuluna',
          '659': 'Taseen mukainen negatiivinen oma pääoma',
          '677': 'Omaan pääomaan sisältyvät arvonkorotukset',
          '650': 'Tilikauden tappio',
          '651': 'Aikaisempien tilikausien tappiot, joiden kattamiseen ei ole kertynyt voittovaroja',
          '652': 'Oikaistu negatiivinen pääoma',
          '653': 'Peruskorko + 1 %',
          '383': 'Korot joita ei voi vähentää elinkeinotoiminnan kuluina',
          'rahan-nostot-part': 'Rahan nostot ja sijoitukset elinkeinotoimintaan kalenterivuoden aikana',
          '384': 'Rahan nostot 1.1. - 31.12',
          '385': 'Rahan sijoitukset 1.1. - 31.12',

          // Tuotot
          'tuotot': 'Tuotot',
          'tuotot-part': 'Tiedot tuotoista',
          '300': 'Liikevaihto',
          '318': 'Avustukset ja tuet',
          '301': 'Liiketoiminnan muut tuotot',
          '323': 'Korkotuotot ja muut rahoitustuotot',
          '312': 'Varausten vähennys',
          '324': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa)',
          'tiedot-tuotoista-yhteensa': 'Tiedot tuotoista yhteensä',
          'elinkeino-ulkopuoliset-kulut-part': 'Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot',
          '379': 'Elinkeinotoimintaan kuuluvat ajoneuvot',
          'henkiloautoja': 'Henkilöautoja',
          'pakettiautoja': 'Pakettiautoja',
          'muita-kulkuneuvoja': 'Muita kulkuneuvoja',
          '380': 'Käyttötiedot perustuvat',
          '271': 'Elinkeinotoiminnan ajot',
          '273': 'Yksityisajot',
          '274': 'Verovuoden kilometrimäärä yhteensä',
          '381': 'Kokonaismenot',
          '275': 'Kulut keskimäärin kilometriä kohden',
          '382': 'Yksityisajojen osuus kuluista',
          '396': '',
          'yksityisajojen-kulut-on': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
          'yksityisajojen-kuluja-ei-ole': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa',
          '313': 'Tulotettava yksityisajojen osuus yhteensä',
          'tuloutus-yksityiskaytosta-part': 'Tuloutus yksityiskäytöstä',
          '314': 'Tavaroiden yksityiskäyttö',
          '315': 'Muu yksityiskäyttö',
          'tuloutus-yksityiskaytosta-yhteensa': 'Tuloutus yksityiskäytöstä yhteensä',
          'veronalaiset-tuotot-yhteensa-part': '',
          '316': 'Veronalaiset tuotot yhteensä',
          'verovapaat-tuotot-part': 'Verovapaat tuotot',
          '317': 'Tuloslaskelman verovapaat tuotot',
          'osingot-ylijaamat-part': 'Osingot, ylijäämät',
          'jos-yritys-on-saanut-osinkoja': 'Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta tai yrityksellä on varauksia, ilmoita ne tiedot OmaVerossa!',

          // Kulut
          'kulut': 'Kulut',
          'tiedot-kuluista-part': 'Tiedot kuluista',
          '333': 'Ostot ja varaston muutokset',
          '334': 'Ulkopuoliset palvelut',
          '335': 'Palkat ja palkkiot',
          '336': 'Eläke- ja henkilösivukulut',
          '342': 'Edustuskulujen ({{summa}}) vähennyskelpoinen osuus',
          '343': 'Vuokrat',
          '347': 'Korkokulujen ({{summa}}) vähennyskelpoinen osuus',
          '344': 'Muut vähennyskelpoiset kulut',
          '349': 'Muut rahoituskulut',
          '353': 'Varausten lisäykset',
          'tiedot-kuluista-yhteensa': 'Tiedot kuluista yhteensä',
          'poistot-part': 'Poistot',
          'muuta-irtainta-omaisuutta': 'Onko muuta kuin irtainta käyttöomaisuutta',
          'tayta-lomake-62': 'Täytä lomake 62 OmaVerossa!',
          'menojaannos-alussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden alussa',
          'menojaannos-lopussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden lopussa',
          'tilikauden-poistot': 'Tilikauden poistot',
          '800': 'Verotuksessa jäljellä oleva poistettava arvo verovuoden alussa',
          '801': 'Lisäys verovuoden aikana',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '804': 'Verovuoden poistot',
          '805': 'Lisäpoisto (EVL 32)',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '338': 'Poistojen vähennyskelpoinen määrä yhteensä',
          'yksityistalouteen-kuuluvan-auton-kaytto-part': 'Yksityistalouteen kuuluvan auton käyttö yritystoiminnassa',
          '416': 'Käyttötiedot perustuvat',
          '389': 'Verovuoden kilometrimäärä yhteensä',
          '390': 'Elinkeinotoiminnan ajot',
          '419': 'Kilometrikorvaus (oma auto) /km',
          '420': 'Enimmäismäärä yhteensä (oma auto)',
          '391': 'Kirjanpidossa vähennetty yksityisvaroihin kuuluvan auton käytöstä',
          '392': 'Yksityistalouteen kuuluvan auton käyttö yhteensä',
          'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part': 'Tilapäisistä työmatkoista aiheutuneet elantokustannukset',
          '406-410': '6 tunnin - 10 tunnin matkat',
          '401-405': 'Yli 10 tunnin matkat',
          '411-415': 'Ulkomaanmatkat',
          'kirjanpito-kuluja-vahennetty-kirjanpidossa': 'Oletko vähentänyt matkakuluja kirjanpidossa?',
          '388': 'Työmatkojen kustannukset yhteensä',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'tyohuone-vahennys-option': '',
          'kaavamainen-tyohuonevahennys': 'Kaavamainen työhuonevähennys',
          'tyohuonevahennys-todellisten-mukaan': 'Työhuonevähennys todellisten kulujen mukaan',
          '431': 'Koko asunnon pinta-ala',
          '432': 'Asunnosta oman elinkeinotoiminnan käytössä',
          '433': 'Koko asunnon vuokra, vastike tai kiinteistön käyttökulut',
          '386': 'Oman elinkeinotoiminnan käytössä olevan osan osuus kuluista',
          '354': 'Muut kirjanpidon vähennyskelpoiset kulut yhteensä',
          'vahennyskelpoiset-kulut-part': '',
          '355': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          'elinkeinotoiminnan-tulos-part': '',
          '358': 'Elinkeinotoiminnan tulos',
          '359': 'Elinkeinotoiminnan tappio',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '365': 'Välittömät verot',
          '366': 'Sakot ja muut rangaistusmaksut',
          '367': 'Muut vähennyskelvottomat kulut',
          'vahennyskelvottomat-kulut-yhteensa': 'Vähennyskelvottomat kulut yhteensä',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '704': 'Koneet ja kalusto',
          '706': 'Muu käyttöomaisuus',
          'kayttoomaisuus-yhteensa': 'Arvo yhteensä',
          'tavarat-ja-muu-part': 'Tavarat ja muu vaihto-omaisuus',
          '708': 'Tavarat',
          '709': 'Muu vaihto-omaisuus',
          'tavarat-ja-muu-yhteensa': 'Arvo yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '711': 'Myyntisaamiset',
          '714': 'Käteisvarat (ei pankkitalletuksia)',
          '716': 'Muu rahoitusomaisuus (ei pankkitalletuksia eikä arvopapereita)',
          'rahoitusomaisuus-yhteensa': 'Arvo yhteensä',
          'elinkeinotoiminnan-varat-part': '',
          '718': 'Elinkeinotoimininnan varat yhteensä',
          'elinkeinotoimininnan-velat-part': 'Elinkeinotoiminnan velat',
          '749': 'Lyhytaikaiset velat',
          '750': 'Pitkäaikaiset velat',
          '747': 'Oikaistu negatiivinen oma pääoma',
          '725': 'Velat yhteensä',
          'elinkeinotoiminnan-nettovarallisuus-part': '',
          '733': 'Elinkeinotoiminnan positiivinen nettovarallisuus',
          '734': 'Elinkeinotoiminnan negatiivinen nettovarallisuus',
          'toimintavaraus-ja-palkat-part': 'Toimintavaraus ja maksetut palkat',
          '871': 'Toimintavaraus',
          '394': 'Verovuoden päättymistä edeltäneiden 12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat',
          'paaomatulon-enimmaismaara-part': 'Pääomatulon enimmäismäärä',
          '395': '',
          'enimmaismaaraa-on-20': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
          'enimmaismaaraa-on-10': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
          'yritystulo-kokonaan-ansiotulo': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          'vaatimus-tappion-vahentamisesta-part': 'Vaatimus tappion vähentämisestä pääomatuloista',
          '373': 'Pääomatuloista vähennettävän elinkeinotoiminnan tappion määrä',
          'jaetaan-puolison-kesken-part': 'Yritystulo jaetaan puolison kesken',
          '369': 'Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta',
          '370': 'Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta',
          '371': 'Yrittäjän työskentely liikkeessä',
          '372': 'Yrittäjäpuolison työskentely liikkeessä',
          'arvopaperit-ja-luovutusvoitot-part': 'Arvopaperit ja luovutusvoitot',
          'jos-yrityksella-on-kiinteistoja': 'Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!'
        },
        'o': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'poista-osakas': 'Poista osakas',
          'lisaa-uusi': 'Lisää uusi',
          'hae-ehdotus': 'Hae ehdotus',
          'hae-ehdotukset': 'Hae ehdotukset',
          'kirjanpito': 'Kirjanpito',
          'verotus': 'Verotus',
          'kpl': 'kpl',

          // Perustiedot
          'perustiedot': 'Perustiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          '072': 'Toimiala',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          '044': 'Sähköposti',
          '185': 'Onko kaikkien osakkaiden omistusosuus alle 10%?',
          'osakkeenomistajat-part': 'Osakkeenomistajat',
          'osakkeenomistajat-part-help': 'Ilmoita tiedot yhtiön osakkeenomistajista sekä heille ja heidän omaisilleen annetuista lainoista ja muista suorituksista. Jos osakkaita on enemmän kuin 10, ilmoita tiedot vain niistä osakkaista, jotka omistavat vähintään 10 % yhtiön osakkeista. Jos jokainen osakas omistaa yhtiöstä alle 10 %, tietoja ei tarvitse ilmoittaa. Osakaslainaa saaneen osakkaan ja omaisen tiedot on ilmoitettava aina riippumatta omistusosuudesta.',
          '701': 'Osakeenomistajan nimi',
          '703': 'Henkilötunnus tai Y-tunnus',
          '570': 'Osakkeiden lukumäärä',
          '706': 'Vuokrat',
          '708': 'Korot',
          '707': 'Luovutukset',
          '709': 'Muut suoritukset',
          '864': 'Osakaslainat',
          'osakkaiden-huoneistot-part': 'Osakkaiden käytössä olleet huoneistot',
          'osakkaiden-huoneistot-part-help': 'Ilmoita tiedot sellaisista yhtiön omistamista huoneistoista ja kiinteistöistä, joita yrittäjäosakas tai hänen perheensä on käyttänyt asuntonaan tilikauden aikana. Yrittäjäosakkaalla tarkoitetaan johtavassa asemassa olevaa henkilöä, joka omistaa yksinään yli 30 prosenttia tai yhdessä perheensä kanssa yli 50 prosenttia yhtiön osakkeista tai osakkeiden tuottamasta äänimäärästä (Työntekijän eläkelaki 7 §).',
          '722': 'Osakkaan henkilötunnus',
          '724': 'Kiinteistön, kiinteistöyhtion tai asunto-osakeyhtiön nimi',
          '729': 'Käyttöaika',
          '730': 'Huoneiston tai kiinteistön arvo',
          'jaettavat-osingot-part': 'Jaettavat osingot ja ylijäämät',
          'onko-yhtio-paattanyt': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'jaettavat-osingot': 'Jaettavat osingot',
          '537': 'Päivä, jolloin päätös osingon- tai ylijäämänjaosta on tehty',
          '538': 'Päivä, jolloin osinko tai ylijäämä on nostettavissa',
          '522': 'Jaettavan osingon tai ylijäämän määrä yhteensä',

          // 'vapaan-jaettavat-varat-part': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'vapaan-jaettavat-varat': 'Vapaan oman pääoman rahastosta jaettavat varat',
          '293': 'Vapaan oman pääoman rahastosta jaettava määrä yhteensä',
          '294': 'Päivä, jolloin päätös varojen jaosta on tehty',
          '295': 'Päivä, jolloin varat ovat nostetttavissa',
          'omistusmuutos-ja-paaoma-part': 'Omistusmuutos ja pääoma',
          '531': 'Verovuosi, jolloin yli puolet osakkeista tai osuuksista on vaihtanut omistajaa',
          '531-help': 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia.',
          '586': 'Onko yhtiö lunastanut, luovuttanut tai hankkinut omia osakkeitaan',
          '571': 'Yhtiön hallussa olevien omien osakkeiden lukumäärä tilikauden päättyessä',
          'osakepaaoman-muutokset-tilikauden-jalkeen': 'Osakepääoman muutokset tilikauden päättymisen jälkeen',
          'osakepaaoma-ei-ole-muutettu': 'Osakepääomaa ei ole muutettu',
          '591': 'Osakepääomaa on korotettu',
          '592': 'Osakepääomaa on alennettu',
          'onko-yhtio-luotto-sijoitus-tai-vakuuslaitos': 'MIKÄ NUMERO? Onko yhtiö luottolaitos, sijoituspalveluyritys tai vakuutuslaitos?',
          '169': 'Harjoittaako yhtiö ainoastaan muuta kuin elinkeinotoimintaa?',

          // Tuloverolaskelma
          'tuloverolaskelma': 'Tuloverolaskelma',
          'elinkeinotoiminnan-tuotot': 'Elinkeinotoiminnan tuotot',
          'liiketoiminta-ja-oma-kaytto-part': 'Liiketoiminta ja oma käyttö',
          '330': 'Liikevaihto',
          '331': 'Valmistus omaan käyttöön',
          'toiminnan-muut-tuotot-part': 'Toiminnan muut tuotot',
          '332-333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332-header': 'Kirjanpito',
          '333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot verotuksessa',
          '333-header': 'Verotus',
          '260': 'Käyttöomaisuuskiinteistöjen luovutusvoitot',
          '334': 'Muun käyttöomaisuuden luovutusvoitot',
          '335': 'Saadut avustukset ja tuet',
          '336': 'Muut tuotot',
          'rahoitustuotot-part': 'Rahoitustuotot',
          '337-338': 'Saadut osingot ja ylijäämät',
          '337': 'Saadut osingot ja ylijäämät',
          '337-header': 'Kirjanpito',
          '338': 'Saatujen osingojen ja ylijäämien verotuksessa',
          '338-header': 'Verotus',
          '339': 'Korkotuotot konserniyhteysosapuolilta',
          '340': 'Korkotuotot omistusyhteysyrityksistä',
          '341': 'Muut korkotuotot',
          '344': 'Rahoitusomaisuuden luovutusvoitot',
          '345': 'Muut rahoitustuotot',
          'muut-tuotot-part': 'Muut tuotot',
          '346-347': 'Palautuvat arvonalennukset',
          '346': 'Palautuvat arvonalennukset',
          '346-header': 'Kirjanpito',
          '347': 'Palautuvien arvonalennusten verotuksessa',
          '347-header': 'Verotus',
          '439': 'Saatu konserniavustus',
          '350-351': 'Varausten vähennykset',
          '350': 'Varausten vähennykset',
          '351': 'Varausten vähennysten verotuksessa',
          '261': 'Muun omaisuuden luovutusvoitot',
          '353': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa',
          'elinkeinotoiminnan-veronalaiset-tuotot-part': '',
          '354': 'Elinkeinotoiminnan veronalaiset tuotot yhteensä',
          'verottomat-erat-kirjanpidossa-part': 'Verottomat erät kirjanpidossa',
          '357': 'Veronpalautus',
          '320': 'Elokuvan tuotantotuki',
          '318': 'Luovutusvoitot, epäsuora tuloutus',
          '358': 'Tuloslaskelman muut verottomat tuotot',
          'elinkeinotoiminnan-kulut': 'Elinkeinotoiminnan kulut',
          'materiaalit-ja-palvelut-part': 'Materiaalit ja palvelut',
          '359': 'Ostot ja varastonmuutokset',
          '360': 'Ulkopuoliset palvelut',
          'henkilostokulut-part': 'Henkilöstökulut',
          '361': 'Palkat ja palkkiot',
          '362': 'Eläkekulut',
          '363': 'Muut henkilösivukulut',
          'poistot-ja-kayttoomaisuuden-arvoalentumiset-part': 'Poistot ja käyttöomaisuuden arvoalentumiset',
          '364-365': 'Poistot',
          '364': 'Poistot',
          '364-header': 'Kirjanpito',
          '365': 'Poistot verotuksessa',
          '365-header': 'Verotus',
          '366-367': 'Käyttöomaisuuden arvonalentumiset',
          '366': 'Käyttöomaisuuden arvonalentumiset',
          '367': 'Käyttöomaisuuden arvonalentumiset verotuksessa',
          '800': 'Verotuksessa jäljellä oleva menojäännös verovuoden alussa',
          '801': 'Lisäykset',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '803': 'Jälleenhankintavarauksella katettu osuus hankintamenosta',
          '804': 'Säännönmukainen poisto (EVL)',
          '805': 'Lisäpoisto (EVL 32 §) ja verohuojennuspoisto',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '807': 'Kirjanpidon tulosta rasittava suunnitelman mukainen poisto',
          '847': 'Poistoeron lisäys',
          '848': 'Poistoeron vähennys',
          '849': 'Poistoero yhteensä',
          'toiminnan-muut-kulut-part': 'Toiminnan muut kulut',
          '368-369': 'Edustuskulut',
          '368': 'Edustuskulut',
          '368-header': 'Kirjanpito',
          '369': 'Edustuskulut verotuksessa',
          '369-header': 'Verotus',
          '370-371': 'Annetut lahjoitukset',
          '370': 'Annetut lahjoitukset',
          '371': 'Annettujen lahjoitukset verotuksessa',
          '372-373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '372': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot verotuksessa',
          '374': 'Muut käyttöomaisuuden luovutustappiot',
          '375': 'Leasingmaksut',
          '376': 'Myyntisaamisten arvonalentumiset',
          '377': 'Muut vähennyskelpoiset kulut',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '378': 'Välittömät verot',
          '379': 'Veronkorotukset ja myöhästymismaksut',
          '380': 'Sakot ja muut rangaistusmaksut',
          '381': 'Fuusiotappio',
          '382': 'Käyttöomaisuusosakkeiden arvonalentumiset',
          '383': 'Pakolliset varaukset',
          '384': 'Muut vähennyskelvottomat kulut',
          'rahoituskulut-part': 'Rahoituskulut',
          '328': 'Korkokulut konserniyhteysosapuolille',
          'none1-328': 'Korkokulut konserniyhteysosapuolille',
          'none1-header': 'Kirjanpito',
          '328-header': 'Verotus',
          '386': 'Korkokulut omistusyhteysyrityksille',
          '387': 'Muut korkokulut',
          '398': 'Korkokuluna käsiteltävät rahoituskulut',
          '399': 'Korkokulujen oikaisu',
          '388': 'Konsernituki ja saamisten arvonalentumiset',
          '388-dnt': 'Konsernituki ja saamisten arvonalentumiset',
          '389': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '389-390': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '390': 'Muiden rahoitusomaisuuden menetysten ja arvonalentumisten verotus',
          '391': 'Rahoitusomaisuuden luovutustappiot',
          '392': 'Muut rahoituskulut',
          'muut-kulut-part': 'Muut kulut',
          'none1-327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '327-header': 'Verotus',
          '327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '436': 'Annettu konserniavustus',
          '394': 'Varausten lisäykset',
          '394-395': 'Varausten lisäykset',
          '395': 'Varausten lisäykset verotuksessa',
          '270': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '270-271': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '271': 'Muun omaisuuden luovutustappioiden ja arvonalentumisten verotus',
          '272': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '272-273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumisten verotus',
          '396': 'Muut vähennyskelpoiset kulut (ei tilikauden tuloslaskelmassa)',
          '504': 'Erät, joita ei oteta huomioon tappiota vahvistettaessa',
          '397': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          '500': 'Elinkeinotoiminnan tulos',
          '505': 'Elinkeinotoiminnan tappio',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '400': 'Aineettomat oikeudet',
          '401': 'Pitkävaikutteiset menot',
          '402': 'Kiinteistöt',
          '403': 'Koneet ja kalusto',
          '404': 'Ennakkomaksut',
          '405': 'Käyttöomaisuusarvopaperit',
          '406': 'Saamiset saman konsernin yrityksiltä',
          '407': 'Saamiset omistusyhteysyrityksiltä',
          '408': 'Muut käyttöomaisuussaamiset',
          '409': 'Muu käyttöomaisuus',
          '410': 'Käyttöomaisuus yhteensä',
          'vaihto-omaisuus-part': 'Vaihto-omaisuus',
          '411': 'Aineet ja tarvikkeet',
          '412': 'Keskeneräiset tuotteet',
          '413': 'Valmiit tuotteet',
          '414': 'Tavarat',
          '415': 'Vaihto-omaisuuskiinteistöt',
          '416': 'Vaihto-omaisuusarvopaperit',
          '417': 'Muu vaihto-omaisuus',
          '418': 'Vaihto-omaisuus yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '419': 'Myyntisaamiset',
          '420': 'Saamiset saman konsernin yrityksiltä',
          '421': 'Saamiset omistusyhteysyrityksiltä',
          '422': 'Lainasaamiset',
          '423': 'Muut saamiset',
          '424': 'Rahoitusomaisuusarvopaperit',
          '425': 'Siirtosaamiset',
          '463': 'Osatuloutuksen saamiset',
          '426': 'Rahat',
          '427': 'Pankkisaamiset',
          '428': 'Muu rahoitusomaisuus',
          '429': 'Rahoitusomaisuus yhteensä',
          'muu-omaisuus-part': 'Muu omaisuus',
          '430': 'Arvopaperit',
          '431': 'Kiinteistöt',
          '432': 'Osakaslainat',
          '437': 'Saamiset saman konsernin yrityksiltä',
          '438': 'Muut saamiset',
          '433': 'Muu tähän ryhmään kuuluva omaisuus',
          '434': 'Muu omaisuus yhteensä',
          '435': 'Varat yhteensä',
          'velat-part': 'Velat',
          '450': 'Joukkovelkakirjalainat',
          '451': 'Vaihtovelkakirjalainat',
          '452': 'Lainat rahoituslaitoksilta',
          '453': 'Ostovelat',
          '454': 'Velat saman konsernin yrityksille',
          '455': 'Velat omistusyhteysyrityksille',
          '456': 'Velat osakkaille',
          '457': 'Siirtovelat',
          '464': 'Saadut ennakot (pitkäaikaiset)',
          '465': 'Saadut ennakot (lyhytaikaiset)',
          '458': 'Muut velat',
          '459': 'Pääomalainat',
          '460': 'Velat yhteensä',
          '461': 'Lyhytaikaiset velat yhteensä',
          '462': 'Pitkäaikaiset velat yhteensä',
          '573': 'Nettovarallisuus',
          '574': 'Nettovarallisuus',
          'oma-paaoma': 'Oma pääoma',
          'sidottu-oma-paaoma-part': 'Sidottu oma pääoma',
          '470': 'Osake- tai osuuspääoma',
          '495': 'Muu sidottu oma pääoma',
          'vapaa-oma-paaoma-part': 'Vapaa oma pääoma',
          '496': 'Sijoitetun vapaan oman pääoman rahasto',
          '497': 'Muut rahastot',
          '474': 'Edellisten tilikausien voitto',
          '475': 'Edellisten tilikausien tappio',
          '476': 'Tilikauden voitto',
          '477': 'Tilikauden tappio',
          '478': 'Oma pääoma yhteensä',

          // Lisätiedot
          'lisatiedot': 'Lisätiedot',
          'lisatiedot-part': 'Lisätiedot',
          '173': 'Onko yhtiön tilinpäätös kansainvälisten tilinpäätösstandardien mukainen?',
          '174': 'Onko yhtiö tehnyt liiketoimintasiirron verovuoden aikana (EVL 52 d §)?',
          '176': 'Onko yhtiö hankkinut osakkeita osakevaihdossa verovuoden aikana? (EVL 52 f §)',
          '184': 'Onko yhtiöllä toimintaa sekä Manner-Suomessa että Ahvenanmaalla?',
          '182': 'Yhtiö on velvollinen laatimaan siirtohinnoitteludokumentaation (VML 14 a §)',
          'tilintarkastus-part': 'Tilintarkastus',
          '177': 'Onko tilintarkastus tehty?',
          '178': 'Sisältääkö tilintarkastuskertomus tilintarkastuslain 3 luvun N1 1,2,5 §: ssä tarkoitettuja kielteisiä lausuntoja, huomautuksia tai lisätietoja?'
        }
      }
    }
  }



  /**
   * NB! Texts only valid for 2023
   */
  private _get2023OptionsTexts() {
    return {
      'fi': {
        't': {
          '916': {
            '0': 'Yhdenkertainen',
            '1': 'Kahdenkertainen'
          },
          '396': {
            '1': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
            '2': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa'
          },
          '379': {
            '1': 'Henkilöautoja',
            '2': 'Pakettiautoja',
            '4': 'Muita kulkuneuvoja'
          },
          '380': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '416': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '391': {
            '1': 'Kyllä',
            '0,00': 'Ei', // Hacky solution - see web component
          },
          '395': {
            'DEFAULT': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
            '1': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
            '2': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          },
          'muuta-irtainta-omaisuutta': {
            '1': 'Kyllä',
            '0': 'Ei'
          }
        },
        'o': {
          '185': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '586': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '169': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '177': {
            '1': 'Kyllä',
            '2': 'Ei, tehdään myöhemmin',
            '3': 'Ei, tilitarkastaja on jätetty valitsematta'
          },
          '178': {
            '1': 'Kyllä',
            '2': 'Ei'
          },
          '173': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '174': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '176': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '184': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '182': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
        }
      }
    }
  }

  /**
   *  NB! Texts only valid for 2023
   */
  private _get2023Texts() {
    return {
      'fi': {
        't': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'hae-ehdotus': 'Hae ehdotus',
          'ajopaivakirjaan': 'Ajopäiväkirjaan',
          'muuhun-selvitykseen': 'Muuhun selvitykseen',
          'kpl': 'kpl',

          // Taustatiedot
          'taustatiedot': 'Taustatiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          'kirjanpitotapa-part': 'Kirjanpitotapa',
          '916': '',
          'yhdenkertainen': 'Yhdenkertainen',
          'kahdenkertainen': 'Kahdenkertainen',
          'paaoman-erittely-part': 'Oman pääoman erittely',
          '748': 'Oma pääoma tilikauden alussa',
          '741': 'Yksityisnostot ja -sijoitukset',
          '742': 'Tilikauden voitto tai tappio (kirjanpito)',
          '743': 'Oma pääoma tilikauden lopussa',
          'laskelma-korkokuluista-part': 'Laskelma korkokuluista, joita ei voi vähentää elinkeinotoiminnan kuluna',
          '659': 'Taseen mukainen negatiivinen oma pääoma',
          '677': 'Omaan pääomaan sisältyvät arvonkorotukset',
          '650': 'Tilikauden tappio',
          '651': 'Aikaisempien tilikausien tappiot, joiden kattamiseen ei ole kertynyt voittovaroja',
          '652': 'Oikaistu negatiivinen pääoma',
          '653': 'Peruskorko + 1 %',
          '383': 'Korot joita ei voi vähentää elinkeinotoiminnan kuluina',
          'rahan-nostot-part': 'Rahan nostot ja sijoitukset elinkeinotoimintaan kalenterivuoden aikana',
          '384': 'Rahan nostot 1.1. - 31.12',
          '385': 'Rahan sijoitukset 1.1. - 31.12',

          // Tuotot
          'tuotot': 'Tuotot',
          'tuotot-part': 'Tiedot tuotoista',
          '300': 'Liikevaihto',
          '318': 'Avustukset ja tuet',
          '301': 'Liiketoiminnan muut tuotot',
          '323': 'Korkotuotot ja muut rahoitustuotot',
          '312': 'Varausten vähennys',
          '324': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa)',
          'tiedot-tuotoista-yhteensa': 'Tiedot tuotoista yhteensä',
          'elinkeino-ulkopuoliset-kulut-part': 'Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot',
          '379': 'Elinkeinotoimintaan kuuluvat ajoneuvot',
          'henkiloautoja': 'Henkilöautoja',
          'pakettiautoja': 'Pakettiautoja',
          'muita-kulkuneuvoja': 'Muita kulkuneuvoja',
          '380': 'Käyttötiedot perustuvat',
          '271': 'Elinkeinotoiminnan ajot',
          '273': 'Yksityisajot',
          '274': 'Verovuoden kilometrimäärä yhteensä',
          '381': 'Kokonaismenot',
          '275': 'Kulut keskimäärin kilometriä kohden',
          '382': 'Yksityisajojen osuus kuluista',
          '396': '',
          'yksityisajojen-kulut-on': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
          'yksityisajojen-kuluja-ei-ole': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa',
          '313': 'Tulotettava yksityisajojen osuus yhteensä',
          'tuloutus-yksityiskaytosta-part': 'Tuloutus yksityiskäytöstä',
          '314': 'Tavaroiden yksityiskäyttö',
          '315': 'Muu yksityiskäyttö',
          'tuloutus-yksityiskaytosta-yhteensa': 'Tuloutus yksityiskäytöstä yhteensä',
          'veronalaiset-tuotot-yhteensa-part': '',
          '316': 'Veronalaiset tuotot yhteensä',
          'verovapaat-tuotot-part': 'Verovapaat tuotot',
          '317': 'Tuloslaskelman verovapaat tuotot',
          'osingot-ylijaamat-part': 'Osingot, ylijäämät',
          'jos-yritys-on-saanut-osinkoja': 'Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta tai yrityksellä on varauksia, ilmoita ne tiedot OmaVerossa!',

          // Kulut
          'kulut': 'Kulut',
          'tiedot-kuluista-part': 'Tiedot kuluista',
          '333': 'Ostot ja varaston muutokset',
          '334': 'Ulkopuoliset palvelut',
          '335': 'Palkat ja palkkiot',
          '336': 'Eläke- ja henkilösivukulut',
          '342': 'Edustuskulujen ({{summa}}) vähennyskelpoinen osuus',
          '343': 'Vuokrat',
          '347': 'Korkokulujen ({{summa}}) vähennyskelpoinen osuus',
          '344': 'Muut vähennyskelpoiset kulut',
          '349': 'Muut rahoituskulut',
          '353': 'Varausten lisäykset',
          'tiedot-kuluista-yhteensa': 'Tiedot kuluista yhteensä',
          'poistot-part': 'Poistot',
          'muuta-irtainta-omaisuutta': 'Onko muuta kuin irtainta käyttöomaisuutta',
          'tayta-lomake-62': 'Täytä lomake 62 OmaVerossa!',
          'menojaannos-alussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden alussa',
          'menojaannos-lopussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden lopussa',
          'tilikauden-poistot': 'Tilikauden poistot',
          '800': 'Verotuksessa jäljellä oleva poistettava arvo verovuoden alussa',
          '801': 'Lisäys verovuoden aikana',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '804': 'Verovuoden poistot',
          '805': 'Lisäpoisto (EVL 32)',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '338': 'Poistojen vähennyskelpoinen määrä yhteensä',
          'yksityistalouteen-kuuluvan-auton-kaytto-part': 'Yksityistalouteen kuuluvan auton käyttö yritystoiminnassa',
          '416': 'Käyttötiedot perustuvat',
          '389': 'Verovuoden kilometrimäärä yhteensä',
          '390': 'Elinkeinotoiminnan ajot',
          '419': 'Kilometrikorvaus (oma auto) /km',
          '420': 'Enimmäismäärä yhteensä (oma auto)',
          '391': 'Kirjanpidossa vähennetty yksityisvaroihin kuuluvan auton käytöstä',
          '392': 'Yksityistalouteen kuuluvan auton käyttö yhteensä',
          'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part': 'Tilapäisistä työmatkoista aiheutuneet elantokustannukset',
          '406-410': '6 tunnin - 10 tunnin matkat',
          '401-405': 'Yli 10 tunnin matkat',
          '411-415': 'Ulkomaanmatkat',
          'kirjanpito-kuluja-vahennetty-kirjanpidossa': 'Oletko vähentänyt matkakuluja kirjanpidossa?',
          '388': 'Työmatkojen kustannukset yhteensä',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'tyohuone-vahennys-option': '',
          'kaavamainen-tyohuonevahennys': 'Kaavamainen työhuonevähennys',
          'tyohuonevahennys-todellisten-mukaan': 'Työhuonevähennys todellisten kulujen mukaan',
          '431': 'Koko asunnon pinta-ala',
          '432': 'Asunnosta oman elinkeinotoiminnan käytössä',
          '433': 'Koko asunnon vuokra, vastike tai kiinteistön käyttökulut',
          '386': 'Oman elinkeinotoiminnan käytössä olevan osan osuus kuluista',
          '354': 'Muut kirjanpidon vähennyskelpoiset kulut yhteensä',
          'vahennyskelpoiset-kulut-part': '',
          '355': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          'elinkeinotoiminnan-tulos-part': '',
          '358': 'Elinkeinotoiminnan tulos',
          '359': 'Elinkeinotoiminnan tappio',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '365': 'Välittömät verot',
          '366': 'Sakot ja muut rangaistusmaksut',
          '367': 'Muut vähennyskelvottomat kulut',
          'vahennyskelvottomat-kulut-yhteensa': 'Vähennyskelvottomat kulut yhteensä',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '704': 'Koneet ja kalusto',
          '706': 'Muu käyttöomaisuus',
          'kayttoomaisuus-yhteensa': 'Arvo yhteensä',
          'tavarat-ja-muu-part': 'Tavarat ja muu vaihto-omaisuus',
          '708': 'Tavarat',
          '709': 'Muu vaihto-omaisuus',
          'tavarat-ja-muu-yhteensa': 'Arvo yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '711': 'Myyntisaamiset',
          '714': 'Käteisvarat (ei pankkitalletuksia)',
          '716': 'Muu rahoitusomaisuus (ei pankkitalletuksia eikä arvopapereita)',
          'rahoitusomaisuus-yhteensa': 'Arvo yhteensä',
          'elinkeinotoiminnan-varat-part': '',
          '718': 'Elinkeinotoimininnan varat yhteensä',
          'elinkeinotoimininnan-velat-part': 'Elinkeinotoiminnan velat',
          '749': 'Lyhytaikaiset velat',
          '750': 'Pitkäaikaiset velat',
          '747': 'Oikaistu negatiivinen oma pääoma',
          '725': 'Velat yhteensä',
          'elinkeinotoiminnan-nettovarallisuus-part': '',
          '733': 'Elinkeinotoiminnan positiivinen nettovarallisuus',
          '734': 'Elinkeinotoiminnan negatiivinen nettovarallisuus',
          'toimintavaraus-ja-palkat-part': 'Toimintavaraus ja maksetut palkat',
          '871': 'Toimintavaraus',
          '394': 'Verovuoden päättymistä edeltäneiden 12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat',
          'paaomatulon-enimmaismaara-part': 'Pääomatulon enimmäismäärä',
          '395': '',
          'enimmaismaaraa-on-20': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
          'enimmaismaaraa-on-10': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
          'yritystulo-kokonaan-ansiotulo': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          'vaatimus-tappion-vahentamisesta-part': 'Vaatimus tappion vähentämisestä pääomatuloista',
          '373': 'Pääomatuloista vähennettävän elinkeinotoiminnan tappion määrä',
          'jaetaan-puolison-kesken-part': 'Yritystulo jaetaan puolison kesken',
          '369': 'Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta',
          '370': 'Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta',
          '371': 'Yrittäjän työskentely liikkeessä',
          '372': 'Yrittäjäpuolison työskentely liikkeessä',
          'arvopaperit-ja-luovutusvoitot-part': 'Arvopaperit ja luovutusvoitot',
          'jos-yrityksella-on-kiinteistoja': 'Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!'
        },
        'o': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'poista-osakas': 'Poista osakas',
          'lisaa-uusi': 'Lisää uusi',
          'hae-ehdotus': 'Hae ehdotus',
          'hae-ehdotukset': 'Hae ehdotukset',
          'kirjanpito': 'Kirjanpito',
          'verotus': 'Verotus',
          'kpl': 'kpl',

          // Perustiedot
          'perustiedot': 'Perustiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          '072': 'Toimiala',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          '044': 'Sähköposti',
          '185': 'Onko kaikkien osakkaiden omistusosuus alle 10%?',
          'osakkeenomistajat-part': 'Osakkeenomistajat',
          'osakkeenomistajat-part-help': 'Ilmoita tiedot yhtiön osakkeenomistajista sekä heille ja heidän omaisilleen annetuista lainoista ja muista suorituksista. Jos osakkaita on enemmän kuin 10, ilmoita tiedot vain niistä osakkaista, jotka omistavat vähintään 10 % yhtiön osakkeista. Jos jokainen osakas omistaa yhtiöstä alle 10 %, tietoja ei tarvitse ilmoittaa. Osakaslainaa saaneen osakkaan ja omaisen tiedot on ilmoitettava aina riippumatta omistusosuudesta.',
          '701': 'Osakeenomistajan nimi',
          '703': 'Henkilötunnus tai Y-tunnus',
          '570': 'Osakkeiden lukumäärä',
          '706': 'Vuokrat',
          '708': 'Korot',
          '707': 'Luovutukset',
          '709': 'Muut suoritukset',
          '864': 'Osakaslainat',
          'osakkaiden-huoneistot-part': 'Osakkaiden käytössä olleet huoneistot',
          'osakkaiden-huoneistot-part-help': 'Ilmoita tiedot sellaisista yhtiön omistamista huoneistoista ja kiinteistöistä, joita yrittäjäosakas tai hänen perheensä on käyttänyt asuntonaan tilikauden aikana. Yrittäjäosakkaalla tarkoitetaan johtavassa asemassa olevaa henkilöä, joka omistaa yksinään yli 30 prosenttia tai yhdessä perheensä kanssa yli 50 prosenttia yhtiön osakkeista tai osakkeiden tuottamasta äänimäärästä (Työntekijän eläkelaki 7 §).',
          '722': 'Osakkaan henkilötunnus',
          '724': 'Kiinteistön, kiinteistöyhtion tai asunto-osakeyhtiön nimi',
          '729': 'Käyttöaika',
          '730': 'Huoneiston tai kiinteistön arvo',
          'jaettavat-osingot-part': 'Jaettavat osingot ja ylijäämät',
          'onko-yhtio-paattanyt': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'jaettavat-osingot': 'Jaettavat osingot',
          '537': 'Päivä, jolloin päätös osingon- tai ylijäämänjaosta on tehty',
          '538': 'Päivä, jolloin osinko tai ylijäämä on nostettavissa',
          '522': 'Jaettavan osingon tai ylijäämän määrä yhteensä',

          // 'vapaan-jaettavat-varat-part': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'vapaan-jaettavat-varat': 'Vapaan oman pääoman rahastosta jaettavat varat',
          '293': 'Vapaan oman pääoman rahastosta jaettava määrä yhteensä',
          '294': 'Päivä, jolloin päätös varojen jaosta on tehty',
          '295': 'Päivä, jolloin varat ovat nostetttavissa',
          'omistusmuutos-ja-paaoma-part': 'Omistusmuutos ja pääoma',
          '531': 'Verovuosi, jolloin yli puolet osakkeista tai osuuksista on vaihtanut omistajaa',
          '531-help': 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia.',
          '586': 'Onko yhtiö lunastanut, luovuttanut tai hankkinut omia osakkeitaan',
          '571': 'Yhtiön hallussa olevien omien osakkeiden lukumäärä tilikauden päättyessä',
          'osakepaaoman-muutokset-tilikauden-jalkeen': 'Osakepääoman muutokset tilikauden päättymisen jälkeen',
          'osakepaaoma-ei-ole-muutettu': 'Osakepääomaa ei ole muutettu',
          '591': 'Osakepääomaa on korotettu',
          '592': 'Osakepääomaa on alennettu',
          'onko-yhtio-luotto-sijoitus-tai-vakuuslaitos': 'MIKÄ NUMERO? Onko yhtiö luottolaitos, sijoituspalveluyritys tai vakuutuslaitos?',
          '169': 'Harjoittaako yhtiö ainoastaan muuta kuin elinkeinotoimintaa?',

          // Tuloverolaskelma
          'tuloverolaskelma': 'Tuloverolaskelma',
          'elinkeinotoiminnan-tuotot': 'Elinkeinotoiminnan tuotot',
          'liiketoiminta-ja-oma-kaytto-part': 'Liiketoiminta ja oma käyttö',
          '330': 'Liikevaihto',
          '331': 'Valmistus omaan käyttöön',
          'toiminnan-muut-tuotot-part': 'Toiminnan muut tuotot',
          '332-333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332-header': 'Kirjanpito',
          '333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot verotuksessa',
          '333-header': 'Verotus',
          '260': 'Käyttöomaisuuskiinteistöjen luovutusvoitot',
          '334': 'Muun käyttöomaisuuden luovutusvoitot',
          '335': 'Saadut avustukset ja tuet',
          '336': 'Muut tuotot',
          'rahoitustuotot-part': 'Rahoitustuotot',
          '337-338': 'Saadut osingot ja ylijäämät',
          '337': 'Saadut osingot ja ylijäämät',
          '337-header': 'Kirjanpito',
          '338': 'Saatujen osingojen ja ylijäämien verotuksessa',
          '338-header': 'Verotus',
          '339': 'Korkotuotot konserniyhteysosapuolilta',
          '340': 'Korkotuotot omistusyhteysyrityksistä',
          '341': 'Muut korkotuotot',
          '344': 'Rahoitusomaisuuden luovutusvoitot',
          '345': 'Muut rahoitustuotot',
          'muut-tuotot-part': 'Muut tuotot',
          '346-347': 'Palautuvat arvonalennukset',
          '346': 'Palautuvat arvonalennukset',
          '346-header': 'Kirjanpito',
          '347': 'Palautuvien arvonalennusten verotuksessa',
          '347-header': 'Verotus',
          '439': 'Saatu konserniavustus',
          '350-351': 'Varausten vähennykset',
          '350': 'Varausten vähennykset',
          '351': 'Varausten vähennysten verotuksessa',
          '261': 'Muun omaisuuden luovutusvoitot',
          '353': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa',
          'elinkeinotoiminnan-veronalaiset-tuotot-part': '',
          '354': 'Elinkeinotoiminnan veronalaiset tuotot yhteensä',
          'verottomat-erat-kirjanpidossa-part': 'Verottomat erät kirjanpidossa',
          '357': 'Veronpalautus',
          '320': 'Elokuvan tuotantotuki',
          '318': 'Luovutusvoitot, epäsuora tuloutus',
          '358': 'Tuloslaskelman muut verottomat tuotot',
          'elinkeinotoiminnan-kulut': 'Elinkeinotoiminnan kulut',
          'materiaalit-ja-palvelut-part': 'Materiaalit ja palvelut',
          '359': 'Ostot ja varastonmuutokset',
          '360': 'Ulkopuoliset palvelut',
          'henkilostokulut-part': 'Henkilöstökulut',
          '361': 'Palkat ja palkkiot',
          '362': 'Eläkekulut',
          '363': 'Muut henkilösivukulut',
          'poistot-ja-kayttoomaisuuden-arvoalentumiset-part': 'Poistot ja käyttöomaisuuden arvoalentumiset',
          '364-365': 'Poistot',
          '364': 'Poistot',
          '364-header': 'Kirjanpito',
          '365': 'Poistot verotuksessa',
          '365-header': 'Verotus',
          '366-367': 'Käyttöomaisuuden arvonalentumiset',
          '366': 'Käyttöomaisuuden arvonalentumiset',
          '367': 'Käyttöomaisuuden arvonalentumiset verotuksessa',
          '800': 'Verotuksessa jäljellä oleva menojäännös verovuoden alussa',
          '801': 'Lisäykset',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '803': 'Jälleenhankintavarauksella katettu osuus hankintamenosta',
          '804': 'Säännönmukainen poisto (EVL)',
          '805': 'Lisäpoisto (EVL 32 §) ja verohuojennuspoisto',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '807': 'Kirjanpidon tulosta rasittava suunnitelman mukainen poisto',
          '847': 'Poistoeron lisäys',
          '848': 'Poistoeron vähennys',
          '849': 'Poistoero yhteensä',
          'toiminnan-muut-kulut-part': 'Toiminnan muut kulut',
          '368-369': 'Edustuskulut',
          '368': 'Edustuskulut',
          '368-header': 'Kirjanpito',
          '369': 'Edustuskulut verotuksessa',
          '369-header': 'Verotus',
          '370-371': 'Annetut lahjoitukset',
          '370': 'Annetut lahjoitukset',
          '371': 'Annettujen lahjoitukset verotuksessa',
          '372-373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '372': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot verotuksessa',
          '374': 'Muut käyttöomaisuuden luovutustappiot',
          '375': 'Leasingmaksut',
          '376': 'Myyntisaamisten arvonalentumiset',
          '377': 'Muut vähennyskelpoiset kulut',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '378': 'Välittömät verot',
          '379': 'Veronkorotukset ja myöhästymismaksut',
          '380': 'Sakot ja muut rangaistusmaksut',
          '381': 'Fuusiotappio',
          '382': 'Käyttöomaisuusosakkeiden arvonalentumiset',
          '383': 'Pakolliset varaukset',
          '384': 'Muut vähennyskelvottomat kulut',
          'rahoituskulut-part': 'Rahoituskulut',
          '328': 'Korkokulut konserniyhteysosapuolille',
          'none1-328': 'Korkokulut konserniyhteysosapuolille',
          'none1-header': 'Kirjanpito',
          '328-header': 'Verotus',
          '386': 'Korkokulut omistusyhteysyrityksille',
          '387': 'Muut korkokulut',
          '398': 'Korkokuluna käsiteltävät rahoituskulut',
          '399': 'Korkokulujen oikaisu',
          '388': 'Konsernituki ja saamisten arvonalentumiset',
          '388-dnt': 'Konsernituki ja saamisten arvonalentumiset',
          '389': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '389-390': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '390': 'Muiden rahoitusomaisuuden menetysten ja arvonalentumisten verotus',
          '391': 'Rahoitusomaisuuden luovutustappiot',
          '392': 'Muut rahoituskulut',
          'muut-kulut-part': 'Muut kulut',
          'none1-327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '327-header': 'Verotus',
          '327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '436': 'Annettu konserniavustus',
          '394': 'Varausten lisäykset',
          '394-395': 'Varausten lisäykset',
          '395': 'Varausten lisäykset verotuksessa',
          '270': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '270-271': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '271': 'Muun omaisuuden luovutustappioiden ja arvonalentumisten verotus',
          '272': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '272-273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumisten verotus',
          '695': 'Tutkimus- ja kehittämistoiminnan väliaikainen lisävähennys',
          '697': 'Tutkimus- ja kehittämistoiminnan yleinen lisävähennys',
          '696': 'Ulkomaisen tytäryhtiön tappiot (konsernivähennys)',
          '396': 'Muut vähennyskelpoiset kulut (ei tilikauden tuloslaskelmassa)',
          '504': 'Erät, joita ei oteta huomioon tappiota vahvistettaessa',
          '397': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          '500': 'Elinkeinotoiminnan tulos',
          '505': 'Elinkeinotoiminnan tappio',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '400': 'Aineettomat oikeudet',
          '401': 'Pitkävaikutteiset menot',
          '402': 'Kiinteistöt',
          '403': 'Koneet ja kalusto',
          '404': 'Ennakkomaksut',
          '405': 'Käyttöomaisuusarvopaperit',
          '406': 'Saamiset saman konsernin yrityksiltä',
          '407': 'Saamiset omistusyhteysyrityksiltä',
          '408': 'Muut käyttöomaisuussaamiset',
          '409': 'Muu käyttöomaisuus',
          '410': 'Käyttöomaisuus yhteensä',
          'vaihto-omaisuus-part': 'Vaihto-omaisuus',
          '411': 'Aineet ja tarvikkeet',
          '412': 'Keskeneräiset tuotteet',
          '413': 'Valmiit tuotteet',
          '414': 'Tavarat',
          '415': 'Vaihto-omaisuuskiinteistöt',
          '416': 'Vaihto-omaisuusarvopaperit',
          '417': 'Muu vaihto-omaisuus',
          '418': 'Vaihto-omaisuus yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '419': 'Myyntisaamiset',
          '420': 'Saamiset saman konsernin yrityksiltä',
          '421': 'Saamiset omistusyhteysyrityksiltä',
          '422': 'Lainasaamiset',
          '423': 'Muut saamiset',
          '424': 'Rahoitusomaisuusarvopaperit',
          '425': 'Siirtosaamiset',
          '463': 'Osatuloutuksen saamiset',
          '426': 'Rahat',
          '427': 'Pankkisaamiset',
          '428': 'Muu rahoitusomaisuus',
          '429': 'Rahoitusomaisuus yhteensä',
          'muu-omaisuus-part': 'Muu omaisuus',
          '430': 'Arvopaperit',
          '431': 'Kiinteistöt',
          '432': 'Osakaslainat',
          '437': 'Saamiset saman konsernin yrityksiltä',
          '438': 'Muut saamiset',
          '433': 'Muu tähän ryhmään kuuluva omaisuus',
          '434': 'Muu omaisuus yhteensä',
          '435': 'Varat yhteensä',
          'velat-part': 'Velat',
          '450': 'Joukkovelkakirjalainat',
          '451': 'Vaihtovelkakirjalainat',
          '452': 'Lainat rahoituslaitoksilta',
          '453': 'Ostovelat',
          '454': 'Velat saman konsernin yrityksille',
          '455': 'Velat omistusyhteysyrityksille',
          '456': 'Velat osakkaille',
          '457': 'Siirtovelat',
          '464': 'Saadut ennakot (pitkäaikaiset)',
          '465': 'Saadut ennakot (lyhytaikaiset)',
          '458': 'Muut velat',
          '459': 'Pääomalainat',
          '460': 'Velat yhteensä',
          '461': 'Lyhytaikaiset velat yhteensä',
          '462': 'Pitkäaikaiset velat yhteensä',
          '573': 'Nettovarallisuus',
          '574': 'Nettovarallisuus',
          'oma-paaoma': 'Oma pääoma',
          'sidottu-oma-paaoma-part': 'Sidottu oma pääoma',
          '470': 'Osake- tai osuuspääoma',
          '495': 'Muu sidottu oma pääoma',
          'vapaa-oma-paaoma-part': 'Vapaa oma pääoma',
          '496': 'Sijoitetun vapaan oman pääoman rahasto',
          '497': 'Muut rahastot',
          '474': 'Edellisten tilikausien voitto',
          '475': 'Edellisten tilikausien tappio',
          '476': 'Tilikauden voitto',
          '477': 'Tilikauden tappio',
          '478': 'Oma pääoma yhteensä',

          // Lisätiedot
          'lisatiedot': 'Lisätiedot',
          'lisatiedot-part': 'Lisätiedot',
          '173': 'Onko yhtiön tilinpäätös kansainvälisten tilinpäätösstandardien mukainen?',
          '174': 'Onko yhtiö tehnyt liiketoimintasiirron verovuoden aikana (EVL 52 d §)?',
          '176': 'Onko yhtiö hankkinut osakkeita osakevaihdossa verovuoden aikana? (EVL 52 f §)',
          '184': 'Onko yhtiöllä toimintaa sekä Manner-Suomessa että Ahvenanmaalla?',
          '182': 'Yhtiö on velvollinen laatimaan siirtohinnoitteludokumentaation (VML 14 a §)',
          'tilintarkastus-part': 'Tilintarkastus',
          '177': 'Onko tilintarkastus tehty?',
          '178': 'Sisältääkö tilintarkastuskertomus tilintarkastuslain 3 luvun N1 1,2,5 §: ssä tarkoitettuja kielteisiä lausuntoja, huomautuksia tai lisätietoja?'
        }
      }
    }
  }

  /**
   * NB! Texts only valid for 2024
   */
  private _get2024OptionsTexts() {
    return {
      'fi': {
        't': {
          '916': {
            '0': 'Yhdenkertainen',
            '1': 'Kahdenkertainen'
          },
          '396': {
            '1': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
            '2': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa'
          },
          '379': {
            '1': 'Henkilöautoja',
            '2': 'Pakettiautoja',
            '4': 'Muita kulkuneuvoja'
          },
          '380': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '416': {
            '1': 'Ajopäiväkirjaan',
            '2': 'Muuhun selvitykseen'
          },
          '391': {
            '1': 'Kyllä',
            '0,00': 'Ei', // Hacky solution - see web component
          },
          '395': {
            'DEFAULT': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
            '1': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
            '2': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          },
          'muuta-irtainta-omaisuutta': {
            '1': 'Kyllä',
            '0': 'Ei'
          }
        },
        'o': {
          '185': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '586': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '169': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '177': {
            '1': 'Kyllä',
            '2': 'Ei, tehdään myöhemmin',
            '3': 'Ei, tilitarkastaja on jätetty valitsematta'
          },
          '178': {
            '1': 'Kyllä',
            '2': 'Ei'
          },
          '173': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '174': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '176': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '184': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
          '182': {
            '1': 'Kyllä',
            '0': 'Ei'
          },
        }
      }
    }
  }

  /**
     *  NB! Texts only valid for 2024
     */
  private _get2024Texts() {
    return {
      'fi': {
        't': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'hae-ehdotus': 'Hae ehdotus',
          'ajopaivakirjaan': 'Ajopäiväkirjaan',
          'muuhun-selvitykseen': 'Muuhun selvitykseen',
          'kpl': 'kpl',

          // Taustatiedot
          'taustatiedot': 'Taustatiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          'kirjanpitotapa-part': 'Kirjanpitotapa',
          '916': '',
          'yhdenkertainen': 'Yhdenkertainen',
          'kahdenkertainen': 'Kahdenkertainen',
          'paaoman-erittely-part': 'Oman pääoman erittely',
          '748': 'Oma pääoma tilikauden alussa',
          '741': 'Yksityisnostot ja -sijoitukset',
          '742': 'Tilikauden voitto tai tappio (kirjanpito)',
          '743': 'Oma pääoma tilikauden lopussa',
          'laskelma-korkokuluista-part': 'Laskelma korkokuluista, joita ei voi vähentää elinkeinotoiminnan kuluna',
          '659': 'Taseen mukainen negatiivinen oma pääoma',
          '677': 'Omaan pääomaan sisältyvät arvonkorotukset',
          '650': 'Tilikauden tappio',
          '651': 'Aikaisempien tilikausien tappiot, joiden kattamiseen ei ole kertynyt voittovaroja',
          '652': 'Oikaistu negatiivinen pääoma',
          '653': 'Peruskorko + 1 %',
          '383': 'Korot joita ei voi vähentää elinkeinotoiminnan kuluina',
          'rahan-nostot-part': 'Rahan nostot ja sijoitukset elinkeinotoimintaan kalenterivuoden aikana',
          '384': 'Rahan nostot 1.1. - 31.12',
          '385': 'Rahan sijoitukset 1.1. - 31.12',

          // Tuotot
          'tuotot': 'Tuotot',
          'tuotot-part': 'Tiedot tuotoista',
          '300': 'Liikevaihto',
          '318': 'Avustukset ja tuet',
          '301': 'Liiketoiminnan muut tuotot',
          '323': 'Korkotuotot ja muut rahoitustuotot',
          '312': 'Varausten vähennys',
          '324': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa)',
          'tiedot-tuotoista-yhteensa': 'Tiedot tuotoista yhteensä',
          'elinkeino-ulkopuoliset-kulut-part': 'Elinkeinotoiminnan kalustoon kuuluvat ajoneuvot',
          '379': 'Elinkeinotoimintaan kuuluvat ajoneuvot',
          'henkiloautoja': 'Henkilöautoja',
          'pakettiautoja': 'Pakettiautoja',
          'muita-kulkuneuvoja': 'Muita kulkuneuvoja',
          '380': 'Käyttötiedot perustuvat',
          '271': 'Elinkeinotoiminnan ajot',
          '273': 'Yksityisajot',
          '274': 'Verovuoden kilometrimäärä yhteensä',
          '381': 'Kokonaismenot',
          '275': 'Kulut keskimäärin kilometriä kohden',
          '382': 'Yksityisajojen osuus kuluista',
          '396': '',
          'yksityisajojen-kulut-on': 'Yksityisajojen kulut on vähennetty kirjanpidossa',
          'yksityisajojen-kuluja-ei-ole': 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa',
          '313': 'Tulotettava yksityisajojen osuus yhteensä',
          'tuloutus-yksityiskaytosta-part': 'Tuloutus yksityiskäytöstä',
          '314': 'Tavaroiden yksityiskäyttö',
          '315': 'Muu yksityiskäyttö',
          'tuloutus-yksityiskaytosta-yhteensa': 'Tuloutus yksityiskäytöstä yhteensä',
          'veronalaiset-tuotot-yhteensa-part': '',
          '316': 'Veronalaiset tuotot yhteensä',
          'verovapaat-tuotot-part': 'Verovapaat tuotot',
          '317': 'Tuloslaskelman verovapaat tuotot',
          'osingot-ylijaamat-part': 'Osingot, ylijäämät',
          'jos-yritys-on-saanut-osinkoja': 'Jos yritys on saanut osinkoja elinkeinotoimintaan kuuluvista osakkeista tai ylijäämiä osuuskunnasta tai yrityksellä on varauksia, ilmoita ne tiedot OmaVerossa!',

          // Kulut
          'kulut': 'Kulut',
          'tiedot-kuluista-part': 'Tiedot kuluista',
          '333': 'Ostot ja varaston muutokset',
          '334': 'Ulkopuoliset palvelut',
          '335': 'Palkat ja palkkiot',
          '336': 'Eläke- ja henkilösivukulut',
          '342': 'Edustuskulujen ({{summa}}) vähennyskelpoinen osuus',
          '343': 'Vuokrat',
          '347': 'Korkokulujen ({{summa}}) vähennyskelpoinen osuus',
          '344': 'Muut vähennyskelpoiset kulut',
          '349': 'Muut rahoituskulut',
          '353': 'Varausten lisäykset',
          'tiedot-kuluista-yhteensa': 'Tiedot kuluista yhteensä',
          'poistot-part': 'Poistot',
          'muuta-irtainta-omaisuutta': 'Onko muuta kuin irtainta käyttöomaisuutta',
          'tayta-lomake-62': 'Täytä lomake 62 OmaVerossa!',
          'menojaannos-alussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden alussa',
          'menojaannos-lopussa': 'Jäljellä oleva menojäännös kirjanpidossa tilikauden lopussa',
          'tilikauden-poistot': 'Tilikauden poistot',
          '800': 'Verotuksessa jäljellä oleva poistettava arvo verovuoden alussa',
          '801': 'Lisäys verovuoden aikana',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '804': 'Verovuoden poistot',
          '805': 'Lisäpoisto (EVL 32)',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '338': 'Poistojen vähennyskelpoinen määrä yhteensä',
          'yksityistalouteen-kuuluvan-auton-kaytto-part': 'Yksityistalouteen kuuluvan auton käyttö yritystoiminnassa',
          '416': 'Käyttötiedot perustuvat',
          '389': 'Verovuoden kilometrimäärä yhteensä',
          '390': 'Elinkeinotoiminnan ajot',
          '419': 'Kilometrikorvaus (oma auto) /km',
          '420': 'Enimmäismäärä yhteensä (oma auto)',
          '391': 'Kirjanpidossa vähennetty yksityisvaroihin kuuluvan auton käytöstä',
          '392': 'Yksityistalouteen kuuluvan auton käyttö yhteensä',
          'tilapaisista-tyomatkoista-aiheutuneet-kustannukset-part': 'Tilapäisistä työmatkoista aiheutuneet elantokustannukset',
          '406-410': '6 tunnin - 10 tunnin matkat',
          '401-405': 'Yli 10 tunnin matkat',
          '411-415': 'Ulkomaanmatkat',
          'kirjanpito-kuluja-vahennetty-kirjanpidossa': 'Oletko vähentänyt matkakuluja kirjanpidossa?',
          '388': 'Työmatkojen kustannukset yhteensä',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut-part': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'muut-kirjanpidon-ulkopuoliset-vahennyskelpoiset-kulut': 'Muut kirjanpidon ulkopuoliset vähennyskelpoiset kulut',
          'tyohuone-vahennys-option': '',
          'kaavamainen-tyohuonevahennys': 'Kaavamainen työhuonevähennys',
          'tyohuonevahennys-todellisten-mukaan': 'Työhuonevähennys todellisten kulujen mukaan',
          '431': 'Koko asunnon pinta-ala',
          '432': 'Asunnosta oman elinkeinotoiminnan käytössä',
          '433': 'Koko asunnon vuokra, vastike tai kiinteistön käyttökulut',
          '386': 'Oman elinkeinotoiminnan käytössä olevan osan osuus kuluista',
          '354': 'Muut kirjanpidon vähennyskelpoiset kulut yhteensä',
          'vahennyskelpoiset-kulut-part': '',
          '355': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          'elinkeinotoiminnan-tulos-part': '',
          '358': 'Elinkeinotoiminnan tulos',
          '359': 'Elinkeinotoiminnan tappio',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '365': 'Välittömät verot',
          '366': 'Sakot ja muut rangaistusmaksut',
          '367': 'Muut vähennyskelvottomat kulut',
          'vahennyskelvottomat-kulut-yhteensa': 'Vähennyskelvottomat kulut yhteensä',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '704': 'Koneet ja kalusto',
          '706': 'Muu käyttöomaisuus',
          'kayttoomaisuus-yhteensa': 'Arvo yhteensä',
          'tavarat-ja-muu-part': 'Tavarat ja muu vaihto-omaisuus',
          '708': 'Tavarat',
          '709': 'Muu vaihto-omaisuus',
          'tavarat-ja-muu-yhteensa': 'Arvo yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '711': 'Myyntisaamiset',
          '714': 'Käteisvarat (ei pankkitalletuksia)',
          '716': 'Muu rahoitusomaisuus (ei pankkitalletuksia eikä arvopapereita)',
          'rahoitusomaisuus-yhteensa': 'Arvo yhteensä',
          'elinkeinotoiminnan-varat-part': '',
          '718': 'Elinkeinotoimininnan varat yhteensä',
          'elinkeinotoimininnan-velat-part': 'Elinkeinotoiminnan velat',
          '749': 'Lyhytaikaiset velat',
          '750': 'Pitkäaikaiset velat',
          '747': 'Oikaistu negatiivinen oma pääoma',
          '725': 'Velat yhteensä',
          'elinkeinotoiminnan-nettovarallisuus-part': '',
          '733': 'Elinkeinotoiminnan positiivinen nettovarallisuus',
          '734': 'Elinkeinotoiminnan negatiivinen nettovarallisuus',
          'toimintavaraus-ja-palkat-part': 'Toimintavaraus ja maksetut palkat',
          '871': 'Toimintavaraus',
          '394': 'Verovuoden päättymistä edeltäneiden 12 kuukauden aikana maksetut ennakonpidätyksen alaiset palkat',
          'paaomatulon-enimmaismaara-part': 'Pääomatulon enimmäismäärä',
          '395': '',
          'enimmaismaaraa-on-20': 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta',
          'enimmaismaaraa-on-10': 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta',
          'yritystulo-kokonaan-ansiotulo': 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa',
          'vaatimus-tappion-vahentamisesta-part': 'Vaatimus tappion vähentämisestä pääomatuloista',
          '373': 'Pääomatuloista vähennettävän elinkeinotoiminnan tappion määrä',
          'jaetaan-puolison-kesken-part': 'Yritystulo jaetaan puolison kesken',
          '369': 'Yrittäjän osuus elinkeinotoiminnan nettovarallisuudesta',
          '370': 'Yrittäjäpuolison osuus elinkeinotoiminnan nettovarallisuudesta',
          '371': 'Yrittäjän työskentely liikkeessä',
          '372': 'Yrittäjäpuolison työskentely liikkeessä',
          'arvopaperit-ja-luovutusvoitot-part': 'Arvopaperit ja luovutusvoitot',
          'jos-yrityksella-on-kiinteistoja': 'Jos yrityksellä on kiinteistöjä, rakennuksia tai rakenelmia, arvopapereita tai käyttöomaisuusarvopapereiden ja -kiinteistöjen luovutusvoittoja, ilmoita niihin liityvät tiedot OmaVerossa!'
        },
        'o': {
          // Shared
          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'poista-osakas': 'Poista osakas',
          'lisaa-uusi': 'Lisää uusi',
          'hae-ehdotus': 'Hae ehdotus',
          'hae-ehdotukset': 'Hae ehdotukset',
          'kirjanpito': 'Kirjanpito',
          'verotus': 'Verotus',
          'kpl': 'kpl',

          // Perustiedot
          'perustiedot': 'Perustiedot',
          'asiakastiedot-part': 'Asiakastiedot',
          '010': 'Y-tunnus',
          '054': 'Tilikausi',
          '072': 'Toimiala',
          'yhteystiedot-part': 'Lisätietojen antajan yhteystiedot',
          '041': 'Nimi',
          '042': 'Puhelinnumero',
          '044': 'Sähköposti',
          '185': 'Onko kaikkien osakkaiden omistusosuus alle 10%?',
          'osakkeenomistajat-part': 'Osakkeenomistajat',
          'osakkeenomistajat-part-help': 'Ilmoita tiedot yhtiön osakkeenomistajista sekä heille ja heidän omaisilleen annetuista lainoista ja muista suorituksista. Jos osakkaita on enemmän kuin 10, ilmoita tiedot vain niistä osakkaista, jotka omistavat vähintään 10 % yhtiön osakkeista. Jos jokainen osakas omistaa yhtiöstä alle 10 %, tietoja ei tarvitse ilmoittaa. Osakaslainaa saaneen osakkaan ja omaisen tiedot on ilmoitettava aina riippumatta omistusosuudesta.',
          '701': 'Osakeenomistajan nimi',
          '703': 'Henkilötunnus tai Y-tunnus',
          '570': 'Osakkeiden lukumäärä',
          '706': 'Vuokrat',
          '708': 'Korot',
          '707': 'Luovutukset',
          '709': 'Muut suoritukset',
          '864': 'Osakaslainat',
          'osakkaiden-huoneistot-part': 'Osakkaiden käytössä olleet huoneistot',
          'osakkaiden-huoneistot-part-help': 'Ilmoita tiedot sellaisista yhtiön omistamista huoneistoista ja kiinteistöistä, joita yrittäjäosakas tai hänen perheensä on käyttänyt asuntonaan tilikauden aikana. Yrittäjäosakkaalla tarkoitetaan johtavassa asemassa olevaa henkilöä, joka omistaa yksinään yli 30 prosenttia tai yhdessä perheensä kanssa yli 50 prosenttia yhtiön osakkeista tai osakkeiden tuottamasta äänimäärästä (Työntekijän eläkelaki 7 §).',
          '722': 'Osakkaan henkilötunnus',
          '724': 'Kiinteistön, kiinteistöyhtion tai asunto-osakeyhtiön nimi',
          '729': 'Käyttöaika',
          '730': 'Huoneiston tai kiinteistön arvo',
          'jaettavat-osingot-part': 'Jaettavat osingot ja ylijäämät',
          'onko-yhtio-paattanyt': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'jaettavat-osingot': 'Jaettavat osingot',
          '537': 'Päivä, jolloin päätös osingon- tai ylijäämänjaosta on tehty',
          '538': 'Päivä, jolloin osinko tai ylijäämä on nostettavissa',
          '522': 'Jaettavan osingon tai ylijäämän määrä yhteensä',

          // 'vapaan-jaettavat-varat-part': 'Onko yhtiö päättänyt jakaa osinkoa tai varoja vapaan oman pääoman rahastosta',
          'vapaan-jaettavat-varat': 'Vapaan oman pääoman rahastosta jaettavat varat',
          '293': 'Vapaan oman pääoman rahastosta jaettava määrä yhteensä',
          '294': 'Päivä, jolloin päätös varojen jaosta on tehty',
          '295': 'Päivä, jolloin varat ovat nostetttavissa',
          'omistusmuutos-ja-paaoma-part': 'Omistusmuutos ja pääoma',
          '531': 'Verovuosi, jolloin yli puolet osakkeista tai osuuksista on vaihtanut omistajaa',
          '531-help': 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia.',
          '586': 'Onko yhtiö lunastanut, luovuttanut tai hankkinut omia osakkeitaan',
          '571': 'Yhtiön hallussa olevien omien osakkeiden lukumäärä tilikauden päättyessä',
          'osakepaaoman-muutokset-tilikauden-jalkeen': 'Osakepääoman muutokset tilikauden päättymisen jälkeen',
          'osakepaaoma-ei-ole-muutettu': 'Osakepääomaa ei ole muutettu',
          '591': 'Osakepääomaa on korotettu',
          '592': 'Osakepääomaa on alennettu',
          'onko-yhtio-luotto-sijoitus-tai-vakuuslaitos': 'MIKÄ NUMERO? Onko yhtiö luottolaitos, sijoituspalveluyritys tai vakuutuslaitos?',
          '169': 'Harjoittaako yhtiö ainoastaan muuta kuin elinkeinotoimintaa?',

          // Tuloverolaskelma
          'tuloverolaskelma': 'Tuloverolaskelma',
          'elinkeinotoiminnan-tuotot': 'Elinkeinotoiminnan tuotot',
          'liiketoiminta-ja-oma-kaytto-part': 'Liiketoiminta ja oma käyttö',
          '330': 'Liikevaihto',
          '331': 'Valmistus omaan käyttöön',
          'toiminnan-muut-tuotot-part': 'Toiminnan muut tuotot',
          '332-333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot',
          '332-header': 'Kirjanpito',
          '333': 'Käyttöomaisuusosakkeiden luovutus- ja purkuvoitot verotuksessa',
          '333-header': 'Verotus',
          '260': 'Käyttöomaisuuskiinteistöjen luovutusvoitot',
          '334': 'Muun käyttöomaisuuden luovutusvoitot',
          '335': 'Saadut avustukset ja tuet',
          '336': 'Muut tuotot',
          'rahoitustuotot-part': 'Rahoitustuotot',
          '337-338': 'Saadut osingot ja ylijäämät',
          '337': 'Saadut osingot ja ylijäämät',
          '337-header': 'Kirjanpito',
          '338': 'Saatujen osingojen ja ylijäämien verotuksessa',
          '338-header': 'Verotus',
          '339': 'Korkotuotot konserniyhteysosapuolilta',
          '340': 'Korkotuotot omistusyhteysyrityksistä',
          '341': 'Muut korkotuotot',
          '344': 'Rahoitusomaisuuden luovutusvoitot',
          '345': 'Muut rahoitustuotot',
          'muut-tuotot-part': 'Muut tuotot',
          '346-347': 'Palautuvat arvonalennukset',
          '346': 'Palautuvat arvonalennukset',
          '346-header': 'Kirjanpito',
          '347': 'Palautuvien arvonalennusten verotuksessa',
          '347-header': 'Verotus',
          '439': 'Saatu konserniavustus',
          '350-351': 'Varausten vähennykset',
          '350': 'Varausten vähennykset',
          '351': 'Varausten vähennysten verotuksessa',
          '261': 'Muun omaisuuden luovutusvoitot',
          '353': 'Muut veronalaiset tuotot (ei tilikauden tuloslaskelmassa',
          'elinkeinotoiminnan-veronalaiset-tuotot-part': '',
          '354': 'Elinkeinotoiminnan veronalaiset tuotot yhteensä',
          'verottomat-erat-kirjanpidossa-part': 'Verottomat erät kirjanpidossa',
          '357': 'Veronpalautus',
          '320': 'Elokuvan tuotantotuki',
          '318': 'Luovutusvoitot, epäsuora tuloutus',
          '358': 'Tuloslaskelman muut verottomat tuotot',
          'elinkeinotoiminnan-kulut': 'Elinkeinotoiminnan kulut',
          'materiaalit-ja-palvelut-part': 'Materiaalit ja palvelut',
          '359': 'Ostot ja varastonmuutokset',
          '360': 'Ulkopuoliset palvelut',
          'henkilostokulut-part': 'Henkilöstökulut',
          '361': 'Palkat ja palkkiot',
          '362': 'Eläkekulut',
          '363': 'Muut henkilösivukulut',
          'poistot-ja-kayttoomaisuuden-arvoalentumiset-part': 'Poistot ja käyttöomaisuuden arvoalentumiset',
          '364-365': 'Poistot',
          '364': 'Poistot',
          '364-header': 'Kirjanpito',
          '365': 'Poistot verotuksessa',
          '365-header': 'Verotus',
          '366-367': 'Käyttöomaisuuden arvonalentumiset',
          '366': 'Käyttöomaisuuden arvonalentumiset',
          '367': 'Käyttöomaisuuden arvonalentumiset verotuksessa',
          '800': 'Verotuksessa jäljellä oleva menojäännös verovuoden alussa',
          '801': 'Lisäykset',
          '802': 'Luovutushinnat ja vakuutuskorvaukset',
          '803': 'Jälleenhankintavarauksella katettu osuus hankintamenosta',
          '804': 'Säännönmukainen poisto (EVL)',
          '805': 'Lisäpoisto (EVL 32 §) ja verohuojennuspoisto',
          '806': 'Verotuksessa jäljellä oleva menojäännös verovuoden lopussa',
          '807': 'Kirjanpidon tulosta rasittava suunnitelman mukainen poisto',
          '847': 'Poistoeron lisäys',
          '848': 'Poistoeron vähennys',
          '849': 'Poistoero yhteensä',
          'toiminnan-muut-kulut-part': 'Toiminnan muut kulut',
          '368-369': 'Edustuskulut',
          '368': 'Edustuskulut',
          '368-header': 'Kirjanpito',
          '369': 'Edustuskulut verotuksessa',
          '369-header': 'Verotus',
          '370-371': 'Annetut lahjoitukset',
          '370': 'Annetut lahjoitukset',
          '371': 'Annettujen lahjoitukset verotuksessa',
          '372-373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '372': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot',
          '373': 'Käyttöomaisuusosakkeiden luovutus- ja purkutappiot verotuksessa',
          '374': 'Muut käyttöomaisuuden luovutustappiot',
          '375': 'Leasingmaksut',
          '376': 'Myyntisaamisten arvonalentumiset',
          '377': 'Muut vähennyskelpoiset kulut',
          'vahennyskelvottomat-kulut-part': 'Vähennyskelvottomat kulut',
          '378': 'Välittömät verot',
          '379': 'Veronkorotukset ja myöhästymismaksut',
          '380': 'Sakot ja muut rangaistusmaksut',
          '381': 'Fuusiotappio',
          '382': 'Käyttöomaisuusosakkeiden arvonalentumiset',
          '383': 'Pakolliset varaukset',
          '384': 'Muut vähennyskelvottomat kulut',
          'rahoituskulut-part': 'Rahoituskulut',
          '328': 'Korkokulut konserniyhteysosapuolille',
          'none1-328': 'Korkokulut konserniyhteysosapuolille',
          'none1-header': 'Kirjanpito',
          '328-header': 'Verotus',
          '386': 'Korkokulut omistusyhteysyrityksille',
          '387': 'Muut korkokulut',
          '398': 'Korkokuluna käsiteltävät rahoituskulut',
          '399': 'Korkokulujen oikaisu',
          '388': 'Konsernituki ja saamisten arvonalentumiset',
          '388-dnt': 'Konsernituki ja saamisten arvonalentumiset',
          '389': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '389-390': 'Muun rahoitusomaisuuden menetykset ja arvonalentumiset',
          '390': 'Muiden rahoitusomaisuuden menetysten ja arvonalentumisten verotus',
          '391': 'Rahoitusomaisuuden luovutustappiot',
          '392': 'Muut rahoituskulut',
          'muut-kulut-part': 'Muut kulut',
          'none1-327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '327-header': 'Verotus',
          '327': 'Osuuskunnan vähennyskelpoinen ylijäämäpalautus',
          '436': 'Annettu konserniavustus',
          '394': 'Varausten lisäykset',
          '394-395': 'Varausten lisäykset',
          '395': 'Varausten lisäykset verotuksessa',
          '270': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '270-271': 'Muun omaisuuden luovutustappiot ja arvonalentumiset',
          '271': 'Muun omaisuuden luovutustappioiden ja arvonalentumisten verotus',
          '272': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '272-273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumiset',
          '273': 'Muuhun omaisuuteen kuuluvien saamisten arvonalentumisten verotus',
          '695': 'Tutkimus- ja kehittämistoiminnan väliaikainen lisävähennys',
          '697': 'Tutkimus- ja kehittämistoiminnan yleinen lisävähennys',
          '698': 'Tutkimus- ja kehittämistoiminnan ylimääräinen lisävähennys',
          '696': 'Ulkomaisen tytäryhtiön tappiot (konsernivähennys)',
          '396': 'Muut vähennyskelpoiset kulut (ei tilikauden tuloslaskelmassa)',
          '504': 'Erät, joita ei oteta huomioon tappiota vahvistettaessa',
          '397': 'Elinkeinotoiminnan vähennyskelpoiset kulut yhteensä',
          '500': 'Elinkeinotoiminnan tulos',
          '505': 'Elinkeinotoiminnan tappio',

          // Varallisuuslaskelma
          'varallisuuslaskelma': 'Varallisuuslaskelma',
          'kayttoomaisuus-part': 'Käyttöomaisuus',
          '400': 'Aineettomat oikeudet',
          '401': 'Pitkävaikutteiset menot',
          '402': 'Kiinteistöt',
          '403': 'Koneet ja kalusto',
          '404': 'Ennakkomaksut',
          '405': 'Käyttöomaisuusarvopaperit',
          '406': 'Saamiset saman konsernin yrityksiltä',
          '407': 'Saamiset omistusyhteysyrityksiltä',
          '408': 'Muut käyttöomaisuussaamiset',
          '409': 'Muu käyttöomaisuus',
          '410': 'Käyttöomaisuus yhteensä',
          'vaihto-omaisuus-part': 'Vaihto-omaisuus',
          '411': 'Aineet ja tarvikkeet',
          '412': 'Keskeneräiset tuotteet',
          '413': 'Valmiit tuotteet',
          '414': 'Tavarat',
          '415': 'Vaihto-omaisuuskiinteistöt',
          '416': 'Vaihto-omaisuusarvopaperit',
          '417': 'Muu vaihto-omaisuus',
          '418': 'Vaihto-omaisuus yhteensä',
          'rahoitusomaisuus-part': 'Rahoitusomaisuus',
          '419': 'Myyntisaamiset',
          '420': 'Saamiset saman konsernin yrityksiltä',
          '421': 'Saamiset omistusyhteysyrityksiltä',
          '422': 'Lainasaamiset',
          '423': 'Muut saamiset',
          '424': 'Rahoitusomaisuusarvopaperit',
          '425': 'Siirtosaamiset',
          '463': 'Osatuloutuksen saamiset',
          '426': 'Rahat',
          '427': 'Pankkisaamiset',
          '428': 'Muu rahoitusomaisuus',
          '429': 'Rahoitusomaisuus yhteensä',
          'muu-omaisuus-part': 'Muu omaisuus',
          '430': 'Arvopaperit',
          '431': 'Kiinteistöt',
          '432': 'Osakaslainat',
          '437': 'Saamiset saman konsernin yrityksiltä',
          '438': 'Muut saamiset',
          '433': 'Muu tähän ryhmään kuuluva omaisuus',
          '434': 'Muu omaisuus yhteensä',
          '435': 'Varat yhteensä',
          'velat-part': 'Velat',
          '450': 'Joukkovelkakirjalainat',
          '451': 'Vaihtovelkakirjalainat',
          '452': 'Lainat rahoituslaitoksilta',
          '453': 'Ostovelat',
          '454': 'Velat saman konsernin yrityksille',
          '455': 'Velat omistusyhteysyrityksille',
          '456': 'Velat osakkaille',
          '457': 'Siirtovelat',
          '464': 'Saadut ennakot (pitkäaikaiset)',
          '465': 'Saadut ennakot (lyhytaikaiset)',
          '458': 'Muut velat',
          '459': 'Pääomalainat',
          '460': 'Velat yhteensä',
          '461': 'Lyhytaikaiset velat yhteensä',
          '462': 'Pitkäaikaiset velat yhteensä',
          '573': 'Nettovarallisuus',
          '574': 'Nettovarallisuus',
          'oma-paaoma': 'Oma pääoma',
          'sidottu-oma-paaoma-part': 'Sidottu oma pääoma',
          '470': 'Osake- tai osuuspääoma',
          '495': 'Muu sidottu oma pääoma',
          'vapaa-oma-paaoma-part': 'Vapaa oma pääoma',
          '496': 'Sijoitetun vapaan oman pääoman rahasto',
          '497': 'Muut rahastot',
          '474': 'Edellisten tilikausien voitto',
          '475': 'Edellisten tilikausien tappio',
          '476': 'Tilikauden voitto',
          '477': 'Tilikauden tappio',
          '478': 'Oma pääoma yhteensä',

          // Lisätiedot
          'lisatiedot': 'Lisätiedot',
          'lisatiedot-part': 'Lisätiedot',
          '173': 'Onko yhtiön tilinpäätös kansainvälisten tilinpäätösstandardien mukainen?',
          '174': 'Onko yhtiö tehnyt liiketoimintasiirron verovuoden aikana (EVL 52 d §)?',
          '176': 'Onko yhtiö hankkinut osakkeita osakevaihdossa verovuoden aikana? (EVL 52 f §)',
          '184': 'Onko yhtiöllä toimintaa sekä Manner-Suomessa että Ahvenanmaalla?',
          '182': 'Yhtiö on velvollinen laatimaan siirtohinnoitteludokumentaation (VML 14 a §)',
          'tilintarkastus-part': 'Tilintarkastus',
          '177': 'Onko tilintarkastus tehty?',
          '178': 'Sisältääkö tilintarkastuskertomus tilintarkastuslain 3 luvun N1 1,2,5 §: ssä tarkoitettuja kielteisiä lausuntoja, huomautuksia tai lisätietoja?'
        }
      }
    }
  }

}
