<div class="list-page kapea" style="max-width: 1200px;">

  <h2>Asiakkaan lähettämillä laskuilla näkyvät tiedot</h2>

  <!-- <button class="secondarybutton" mat-button (click)="asetaTervetuloaDialoginNakyvyys()">Muuta tervetuloadialogin näyttäminen</button> -->

  <div style="padding: 2em;">
    <mat-progress-bar *ngIf="!ladattu" mode="indeterminate"></mat-progress-bar>
    <img [style.visibility]="ladattu ? 'visible' : 'hidden'" [src]="logoObservable | async" style="max-height:125px; max-width:125px;" />
    <div [style.visibility]="ladattu ? 'visible' : 'hidden'" [innerHTML]="asetuksetObservable | async | ljson"></div>
  </div>

</div>