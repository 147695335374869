<button mat-button class="secondarybutton" (click)="luoTaikalinkki()">Luo taikalinkki</button>

<form [formGroup]="ulkopuolisetKulutForm" novalidate>
  <div style="margin-top: 50px;">

    <div class="apk-form-upd">Lomake päivitetty: {{ lomakePaivitetty }} {{ lomakkeenPaivittaja ? '(' + lomakkeenPaivittaja + ')' : '' }}</div>

    <div style="margin-top: 30px;">
      <mat-checkbox formControlName="eiIlmoitettavaa">
      </mat-checkbox>
      <span class="apk-checkbox-label">{{'ulkopuoliset-kulut.ei-ilmoitettavaa' | translate | async}}</span>
    </div>

    <div class="red-text common-error" style="text-align: center;" *ngIf="commonError">{{ commonError }}</div>

    <ng-container *ngIf="onIlmoitettavaa">
      <div class="apk-form-title">
        {{'ulkopuoliset-kulut.ajoneuvon-kaytto' | translate | async}}
      </div>
      <div formArrayName="ajoneuvot">
        <div class="ajnv-rivi" *ngFor="let ajoneuvotFormGroup of ajoneuvotFormGroupit; let i = index;" [formGroupName]="i">
          <mat-form-field class="ajnv-col">
            <mat-label>{{ 'ulkopuoliset-kulut.rekisteritunnus' | translate | async }}</mat-label>
            <input [name]="namename" type="text" formControlName="rekisteritunnus" autocomplete="nothing" matInput required data-lpignore="true" />
            <mat-error>
              <ng-container *ngIf="ajoneuvotFormGroup.get('rekisteritunnus'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'ulkopuoliset-kulut.field-error' | translate | async}}</ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ajnv-col">
            <mat-label>{{ 'ulkopuoliset-kulut.ajoneuvotyyppi' | translate | async }}</mat-label>
            <mat-select formControlName="tyyppi" required>
              <mat-option value="henkilo">{{ 'ulkopuoliset-kulut.henkiloauto' | translate | async }}</mat-option>
              <mat-option value="paketti">{{ 'ulkopuoliset-kulut.pakettiauto' | translate | async }}</mat-option>
              <mat-option value="muu">{{ 'ulkopuoliset-kulut.muu-ajoneuvo' | translate | async }}</mat-option>
            </mat-select>
            <mat-error>
              <ng-container *ngIf="ajoneuvotFormGroup.get('tyyppi'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'ulkopuoliset-kulut.field-error' | translate | async}}</ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ajnv-col">
            <mat-label>{{ 'ulkopuoliset-kulut.ajettu-yhteensa' | translate | async }}</mat-label>
            <input decimalNumberField [name]="namename" type="text" formControlName="kokonaisKmMaara" autocomplete="nothing" matInput required data-lpignore="true" />
            <mat-error>
              <ng-container *ngIf="ajoneuvotFormGroup.get('kokonaisKmMaara'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'ulkopuoliset-kulut.field-error' | translate | async}}</ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ajnv-col">
            <mat-label>{{ 'ulkopuoliset-kulut.ajettu-tyoajoa' | translate | async }}</mat-label>
            <input decimalNumberField [name]="namename" type="text" formControlName="tyoAjoissaKm" autocomplete="nothing" matInput required data-lpignore="true" />
            <mat-error>
              <ng-container *ngIf="ajoneuvotFormGroup.get('tyoAjoissaKm'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'ulkopuoliset-kulut.field-error' | translate | async}}</ng-container>
                  <ng-container *ngIf="!n.errors?.required && n?.errors?.toolarge">{{'ulkopuoliset-kulut.auton-kaytto-tyoajoissa-too-large-error' | translate | async}} </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <button mat-icon-button (click)="poistaAjoneuvo(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

        <button class="primarybutton" mat-button (click)="lisaaAjoneuvo(); $event.preventDefault();">
          <!-- {{'yleiset.jatka' | translate | async }} -->
          {{'ulkopuoliset-kulut.lisaa-ajoneuvo' | translate | async }}
        </button>
      </div>

      <div [class.mat-form-field-invalid]="tiedotPerustuvat?.errors?.required" class="apk-radio-container indented-in-large-view">
        <div [class.dark-gray-text]="!ajoneuvotFormGroupit.length" class="apk-radio-label">{{'ulkopuoliset-kulut.auton-tiedot-perustuvat' | translate | async}}
          <span *ngIf="ajoneuvotFormGroupit.length" aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
        </div>
        <mat-radio-group formControlName="tiedotPerustuvat" style="display: flex; flex-direction: column;">
          <mat-radio-button class="apk-radio-button" [class.field-error]="tiedotPerustuvat?.errors?.required" [value]="'ajopaivakirjaan'">{{'ulkopuoliset-kulut.ajopaivakirjaan' | translate | async}}</mat-radio-button>
          <mat-radio-button class="apk-radio-button" [class.field-error]="tiedotPerustuvat?.errors?.required" [value]="'muuhun selvitykseen'">{{'ulkopuoliset-kulut.muuhun-selvitykseen' | translate | async}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="apk-form-title">
        {{'ulkopuoliset-kulut.kotimaan-tyomatkat' | translate | async}}
      </div>
      <mat-form-field class="tyomatkat-input">
        <mat-label>{{ 'ulkopuoliset-kulut.kokopaivarahojen-lukumaara' | translate | async }}</mat-label>
        <input decimalNumberField matInput formControlName="yli10hKotimaanMatkojenLukumaara" class="alignLeft" [numberOfDecimals]="0">
        <mat-hint style="font-weight: bold;">{{ 'ulkopuoliset-kulut.yli-10h-lukumaara' | translate | async }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="tyomatkat-input">
        <mat-label>{{ 'ulkopuoliset-kulut.osapaivarahojen-lukumaara' | translate | async }}</mat-label>
        <input [name]="namename" decimalNumberField matInput formControlName="yli6hKotimaanMatkojenLukumaara" class="alignLeft" [numberOfDecimals]="0">
        <mat-hint style="font-weight: bold;">{{ 'ulkopuoliset-kulut.yli-6h-lukumaara' | translate | async }}</mat-hint>
      </mat-form-field>

      <div class="apk-form-title">
        {{'ulkopuoliset-kulut.ulkomaan-tyomatkat' | translate | async}}
      </div>
      <mat-form-field class="tyomatkat-input">
        <mat-label>{{ 'ulkopuoliset-kulut.ulkomaan-lukumaara' | translate | async }}</mat-label>
        <input [name]="namename" decimalNumberField matInput formControlName="ulkomaanMatkapaivienLukumaara" class="alignLeft" [numberOfDecimals]="1">
      </mat-form-field>
      <mat-form-field class="tyomatkat-input">
        <mat-label>{{ 'ulkopuoliset-kulut.ulkomaan-paivarahojen-yhteismaara' | translate | async }}</mat-label>
        <input [name]="namename" decimalNumberField matInput formControlName="ulkomaanPaivarahojenYhteismaara" class="alignLeft">
        <mat-hint style="font-weight: bold;">{{'ulkopuoliset-kulut.laske-ulkomaan-paivarahojen' | translate | async}}</mat-hint>
      </mat-form-field>

      <div class="apk-form-title">
        {{'ulkopuoliset-kulut.tyohuonevahennys' | translate | async}}
      </div>
      <mat-radio-group formControlName="kotiYrityksenTyotilana" style="display: flex; flex-direction: column;">
        <mat-radio-button [value]="'ei-vahennysta'">
          <div class="apk-radio-option">{{'ulkopuoliset-kulut.ei-tyohuonevahennysta' | translate | async}}</div>
        </mat-radio-button>
        <mat-radio-button [value]="'paasaantoinen'">
          <div class="apk-radio-option">{{'ajopaivakirja.kotia-paasaantoisesti' | translate: { vahennys: paasaantoinenKotiVahennys } | async}}</div>
        </mat-radio-button>
        <mat-radio-button [value]="'osa-aikainen'">
          <div class="apk-radio-option">{{'ajopaivakirja.kotia-osa-aikaisesti' | translate: { vahennys: osaaikainenKotiVahennys } | async}}</div>
        </mat-radio-button>
        <mat-radio-button [value]="'satunnainen'">
          <div class="apk-radio-option">{{'ajopaivakirja.kotia-satunnaisesti' | translate: { vahennys: satunnainenKotiVahennys } | async}}</div>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>

  <div style="margin-top: 30px;">
    <div>
      <button class="primarybutton" type="button" mat-button (click)="tallenna()">Tallenna</button>
    </div>
  </div>
</form>