import { SafeUrl } from '@angular/platform-browser'

import { FirestoreTosite, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto } from '../../../_jaettu/model/tosite'

import { BehaviorSubject, Observable } from 'rxjs'
import { Injectable } from '@angular/core'

export interface FirestoreTositteenMuutosKuvaMuokkaus extends FirestoreTositteenKuva {
  lokaalikuva: File | null
  nakyvissa: boolean
  ladataan: boolean
  kuvanUrlObservable: BehaviorSubject<SafeUrl>
}


@Injectable()
export class TositeKuvaService {

  private alkuperaistenKakku: { [kuitinAvain: string]: FirestoreTositteenAlkuperainenTiedosto[] } = {}
  private kuvienKakku: { [kuitinAvain: string]: BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]> } = {}
  private tallennettujenKakku: { [kuvanAvain: string]: { kuitinAvain: string, kuva: FirestoreTositteenMuutosKuvaMuokkaus } } = {}

  private lopullistenEkojenKuvienKakku: { [kuitinAvain: string]: SafeUrl } = {}

  private voiSiivotaTallennuksenJalkeenAvaimet: string[] = []

  public lisaaKuvaLopulliseenKakkuun(kuitti: FirestoreTosite, ekakuva: SafeUrl) {
    this.lopullistenEkojenKuvienKakku[kuitti.avain] = ekakuva
  }

  public annaKuvaLopullisestaKakusta(kuitti: FirestoreTosite): SafeUrl | null {
    return this.lopullistenEkojenKuvienKakku[kuitti.avain]
  }

  public annaKuva(kuva: FirestoreTositteenKuva) {
    if (kuva) {
      return this.tallennettujenKakku[kuva.avain]
    }
    return null
  }

  public annaKuitinKuvat(kuitti: FirestoreTosite): Observable<FirestoreTositteenMuutosKuvaMuokkaus[]> {
    return this.annaKuvienSubjectKuitille(kuitti)
  }

  public lisaaKuviaKakkuun(kuitti: FirestoreTosite, kuvat: FirestoreTositteenMuutosKuvaMuokkaus[]) {
    const subject = this.annaKuvienSubjectKuitille(kuitti)
    const nykyisetArvot = subject.value
    nykyisetArvot.push(...kuvat)
    subject.next(nykyisetArvot)
  }

  public siirraKuvatKuitille(kuiteista: FirestoreTosite[], kuittiin: FirestoreTosite): void {
    const kuittiinSubject = this.annaKuvienSubjectKuitille(kuittiin)
    const kuittiinArray = this.annaAlkuperaistenArrayKuitille(kuittiin)
    const nykyisetArvot = kuittiinSubject.value
    for (const kuitista of kuiteista) {

      // Siirrä kuvat
      const kuitistaSubject = this.annaKuvienSubjectKuitille(kuitista)
      nykyisetArvot.push(...kuitistaSubject.value)

      // Siirrä alkuperäiset
      const kuitistaArray = this.annaAlkuperaistenArrayKuitille(kuitista)
      kuittiinArray.push(...kuitistaArray)

      // Muuta tämän kuitin viittaukset uuteen paikkaan, jotta myöhemmät operaatiot kohdistuvat niihin
      // (Samat kamat useammalla avaimella map:eissa)
      this.lisaaViittauksetKuitille(kuitista, kuittiinSubject, kuittiinArray)

    }
    kuittiinSubject.next(nykyisetArvot)
  }

  public lisaaAlkuperainen(kuitti: FirestoreTosite, alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[]) {
    const arr = this.annaAlkuperaistenArrayKuitille(kuitti)
    arr.push(...alkuperaiset)
  }

  public poistaTositteenTiedot(kuitti: FirestoreTosite) {
    delete this.alkuperaistenKakku[kuitti.kuvakansio]
    delete this.kuvienKakku[kuitti.kuvakansio]
  }

  public siivoa(tallennettu?: FirestoreTosite) {
    for (const kuvakansio of this.voiSiivotaTallennuksenJalkeenAvaimet) {
      delete this.alkuperaistenKakku[kuvakansio]
      delete this.kuvienKakku[kuvakansio]
    }
    this.voiSiivotaTallennuksenJalkeenAvaimet = []

    if (tallennettu) {
      const subject = this.annaKuvienSubjectKuitille(tallennettu)
      for (const kuva of subject.value) {
        this.tallennettujenKakku[kuva.avain] = {
          kuitinAvain: tallennettu.avain,
          kuva: kuva
        }
        delete this.alkuperaistenKakku[tallennettu.kuvakansio]
        delete this.kuvienKakku[tallennettu.kuvakansio]
      }
    }
  }

  public asetaKuvatKuittiin(kuitti: FirestoreTosite) {

    const kuittiinSubject = this.annaKuvienSubjectKuitille(kuitti)
    const kuittiinArray = this.annaAlkuperaistenArrayKuitille(kuitti)
    const nykyisetArvot = kuittiinSubject.value

    if (!kuitti.kuvat) {
      kuitti.kuvat = {}
    }

    let i = 1
    for (const kuva of nykyisetArvot) {
      // Jos avain on sama kuin alkuperäinen, älä lisää sitä (löytyy jo alkuperäisistä..)
      if (kuva.alkuperaisenAvain === kuva.avain) {
        delete kuitti.kuvat[kuva.avain]
      } else {
        kuva.jarjestys = i
        kuitti.kuvat[kuva.avain] = kuva
        i++
      }
    }

    if (!kuitti.alkuperaiset) {
      kuitti.alkuperaiset = {}
    }

    for (const alkuperainen of kuittiinArray) {
      // Alkuperäinen kuva siirretään alkuperäisiin
      kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen
    }

  }

  // public onkoKuviaOptimoimatta(kuitti: FirestoreTosite) {
  //   const kuittiinSubject = this.annaKuvienSubjectKuitille(kuitti)
  //   const nykyisetArvot = kuittiinSubject.value
  //   for (const arvo of nykyisetArvot) {
  //     if (arvo.optimoidaan) {
  //       return true
  //     }
  //   }
  //   return false
  // }

  private lisaaViittauksetKuitille(kuitti: FirestoreTosite, subject: BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]>, arr: FirestoreTositteenAlkuperainenTiedosto[]) {
    this.kuvienKakku[kuitti.kuvakansio] = subject
    this.alkuperaistenKakku[kuitti.kuvakansio] = arr
    this.voiSiivotaTallennuksenJalkeenAvaimet.push(kuitti.kuvakansio)
  }

  private annaKuvienSubjectKuitille(kuitti: FirestoreTosite): BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]> {
    let subject = this.kuvienKakku[kuitti.kuvakansio]
    if (!subject) {
      subject = new BehaviorSubject([])
      this.kuvienKakku[kuitti.kuvakansio] = subject
    }
    return subject
  }

  private annaAlkuperaistenArrayKuitille(kuitti: FirestoreTosite): FirestoreTositteenAlkuperainenTiedosto[] {
    let arr = this.alkuperaistenKakku[kuitti.kuvakansio]
    if (!arr) {
      arr = []
      this.alkuperaistenKakku[kuitti.kuvakansio] = arr
    }
    return arr
  }

}
