<div style="margin: 30px 0;">
  <div class="light-blue-text otsikko">Muut selvitykset</div>

  <ng-container *ngFor="let selvitys of rahanpesunSelvityksetObservable | async">
    <div class="info-box" style="margin-bottom: 10px; max-width: 450px;">
      <mat-form-field>
        <textarea [value]="selvitys.teksti" [disabled]="true" autocomplete="nothing" matInput data-lpignore="true"></textarea>
      </mat-form-field>
      <div style="cursor: pointer; color: rgba(0, 0, 0, 0.38)" (click)="downloadFile(tiedosto, selvitys.avain)" *ngFor="let tiedosto of selvitys.tiedostot">
        {{ tiedosto.nimi }}
      </div>
    </div>
    <div style="margin-bottom: 50px; font-size: 11px;">
      {{ selvitys.paivittajanNimi }} ({{ selvitys.paivitettyString }})
    </div>
  </ng-container>

  <form [formGroup]="form">
    <div *ngIf="lisaaSelvitysAktivoitu">
      <mat-form-field style="max-width: 450px;">
        <mat-label>Selvitys</mat-label>
        <textarea [name]="namename" type="text" formControlName="teksti" autocomplete="nothing" matInput data-lpignore="true"></textarea>
      </mat-form-field>

      <div style="display: flex; align-items: center;" *ngFor="let cached of cachedSelvitysTiedostotMetadata">
        <div style="margin: 10px; cursor: pointer;" (click)="downloadFile(cached)">{{ cached.nimi }}</div>
        <mat-icon (click)="removeFileFromCache(cached)" style="cursor: pointer;">close</mat-icon>
      </div>
      <button class="primarybutton" style="display: block; margin-bottom: 50px;" (click)="uploadFile()" mat-flat-button>Lataa tiedosto</button>

    </div>
  </form>

  <div class="button-container">
    <button [class]="lisaaSelvitysAktivoitu ? 'secondarybutton' : 'primarybutton'" (click)="aktivoiLisaaSelvitys()" mat-flat-button>{{ lisaaSelvitysAktivoitu ? 'Peruuta' : 'Lisää selvitys' }}</button>
    <button *ngIf="lisaaSelvitysAktivoitu" class="primarybutton" style="margin-bottom: 10px;" (click)="tallenna()" mat-flat-button>Tallenna</button>
  </div>

  <div class="lemon-error" *ngIf="commonError">{{ commonError }}</div>


  <input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" accept="image/*,application/pdf" #fileInput multiple="multiple" />

</div>