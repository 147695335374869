<mat-dialog-content>

  <lemon-dialog-header>
    Tilitapahtuman raakadata
  </lemon-dialog-header>

  <h3>Lemon Treen versio</h3>
  <div style="padding-left: 3em; padding-right: 3em;" [innerHTML]="filteredData | ljson"></div>

  <h3 style="margin-top: 1em;">Lisätiedot</h3>
  <pre style="padding-left: 3em; padding-right: 3em;">{{lisatiedotObservable | async | json}}</pre>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <div style="width: 100%; padding-bottom: 1em;">
    <form [formGroup]="form" novalidate>
      <mat-form-field>
        <mat-label>Korjaa viitenumero</mat-label>
        <input type="text" formControlName="viitenumero" matInput [name]="'aasd' + name" />
        <mat-hint>Täytä vain jos viitenumero on virheellinen tai se puuttuu.</mat-hint>
        <mat-error *ngIf="viitenumero?.errors?.required && (viitenumero?.dirty || viitenumero?.touched)">
          Viitenumero puuttuu
        </mat-error>
        <mat-error *ngIf="viitenumero?.errors?.viitenumero && (viitenumero?.dirty || viitenumero?.touched)">
          Viitenumero on virheellinen
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="secondarybutton" mat-button (click)="kohdistaTilitapahtuma()">Kohdista tilitapahtuma</button>
</mat-dialog-actions>