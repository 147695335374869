<div class="list-page">
  <div class="light-blue-text otsikko">
    <div style="margin-right: auto">
      <button class="takaisin" mat-button (click)="back()" type="button">
        <i class="icon ion-md-arrow-back"></i>
      </button>
      Kirjanpitäjän asetukset
    </div>
  </div>
  <div class="alapalkki"></div>

  <div style="margin-left: 20px">
    <h3>Kirjanpito</h3>
    <mat-form-field style="width: 350px">
      <mat-label>Oletusraportti kirjanpidossa</mat-label>
      <mat-select [ngModel]="(kirjanpitajanAsetuksetObservable | async)?.kirjanpidonOletusraportti" (ngModelChange)="oletusRaporttiMuuttui($event)" required>
        <mat-option *ngFor="let rap of kirjanpidonRaportit" [value]="rap.avain"> {{ rap.nimi }} </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field style="width: 350px">
      <mat-label>Näkymissä, joissa on sekä tulos ja tase, näytetään...</mat-label>
      <mat-select [ngModel]="(kirjanpitajanAsetuksetObservable | async)?.kirjanpidonEnsinNaytettevaRaportti" (ngModelChange)="ensinNaytettavaRaporttiMuuttui($event)" required>
        <mat-option *ngFor="let rap of kumpiRaporttiEnsin" [value]="rap.avain"> {{ rap.nimi }} </mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <form [formGroup]="form">
      <h3>Tiedot</h3>
      <mat-form-field style="width: 350px">
        <mat-label>Puhelinnumero</mat-label>
        <input matInput type="tel" placeholder="Puhelinnumero" formControlName="phoneNumber" />
      </mat-form-field>

      <h3>Allekirjoituksen terveiset</h3>
      <div style="margin: 10px 0;">
        Suomeksi:<br />
        <textarea cdkTextareaAutosize style="width: 350px; font-size: 14px;" cdkAutosizeMinRows="4" type="text" formControlName="emailEndTextFi"></textarea>
      </div>
      <div style="margin: 10px 0;">
        Englanniksi:<br />
        <textarea cdkTextareaAutosize style="width: 350px; font-size: 14px;" cdkAutosizeMinRows="4" type="text" formControlName="emailEndTextEn"></textarea>
      </div>
      <div>
        <mat-checkbox formControlName="lomaviestiActive">
          Lomaviesti
        </mat-checkbox>
      </div>
      <div *ngIf="lomaviestiActive.value">
        <div style="margin: 10px 0;">
          Suomeksi:<br />
          <quill-editor [formControl]="lomaviestiFi"></quill-editor>
        </div>
        <div style="margin: 10px 0;">
          Englanniksi:<br />
          <quill-editor [formControl]="lomaviestiEn"></quill-editor>
        </div>
      </div>

      <div>
        <button class="primarybutton" style="margin: 25px 0;" mat-button (click)="saveForm()">Tallenna</button>
        <div class="red-text" *ngIf="commonError">{{commonError}}</div>
      </div>
    </form>


    <br />

    <h3>Sähköposti</h3>
    <ng-container *ngIf="kirjanpitajaObservable | async; let kirjanpitaja">
      <div class="light-blue-text" style="margin: 5px 0; line-height: 15px; font-size: 15px; font-weight: bold">Allekirjoitus, suomi</div>
      <div #signature app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="kirjanpitaja" kieli="fi"></div>
      <button class="primarybutton" mat-button (click)="copySignature(kirjanpitaja, 'fi')" type="button" style="margin-bottom: 20px;">Kopioi allekirjoitus</button>

      <div class="light-blue-text" style="margin: 5px 0; line-height: 15px; font-size: 15px; font-weight: bold">Allekirjoitus, englanti</div>
      <div #signature app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="kirjanpitaja" kieli="en"></div>
      <button class="primarybutton" mat-button (click)="copySignature(kirjanpitaja, 'en')" type="button">Kopioi allekirjoitus</button>
    </ng-container>
  </div>
</div>