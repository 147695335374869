import { Component, OnInit, Inject, ErrorHandler, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'

import { MatRadioButton, MatRadioGroup } from '@angular/material/radio'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

import { KirjanpitajanSarake, KirjanpitajanSarakkeenTila } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { ListausAsiakas } from 'app/_jaettu-lemonator/model/asiakas'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

export interface AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData {
  asiakas: ListausAsiakas
  kirjanpitajaUid: string
  sarake: KirjanpitajanSarake
  tilanAvain: string
}

@Component({
  templateUrl: './asiakkaan-kirjanpitajan-sarakkeen-arvon-muokkaus.dialog.html'
})
export class AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog implements OnInit {

  @ViewChild('grouppi', { read: MatRadioGroup, static: true }) grouppi: MatRadioGroup
  @ViewChildren('radbut', { read: MatRadioButton }) radbutit: QueryList<MatRadioButton>

  tilat: KirjanpitajanSarakkeenTila[] = []
  form: UntypedFormGroup
  tila: KirjanpitajanSarakkeenTila

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorHandler: ErrorHandler,
    private _formValidationService: FormValidationService,
    // private _ladataanService: LadataanService,
    private _asiakasService: AsiakasService
  ) {

    const sarake = this._data.sarake
    this.tilat = sarake.tilat ? sarake.tilat : []
    this.tila = this.tilat.find(a => a.avain === this._data.tilanAvain)

    this.form = new UntypedFormGroup({
      'tila': new UntypedFormControl({ value: this.tila ? this.tila : null, disabled: false }, [])
    })
    this.form.get('tila').valueChanges.subscribe(value => {
      this.tila = value
    })

  }

  ngOnInit() {
    setTimeout(() => {
      if (this.grouppi.selected) {
        this.grouppi.selected.focus()
      } else {
        this.radbutit.first.focus()
      }
      this._changeDetectorRef.markForCheck()
    }, 50)
  }

  tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    // this._ladataanService.aloitaLataaminen()
    this._asiakasService.tallennaSarakkeenArvo(this._data.asiakas, this._data.kirjanpitajaUid, this._data.sarake, this.form.get('tila').value).then(() => {
      // this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      // this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
    this._dialogRef.close(true)

  }

}
