<button (click)="minusMinute()" mat-icon-button class="secondarybutton" style="border: 0px; height: 24px; width: 40px; padding: 0 5px; min-width: unset; line-height: 24px; font-size: 16px; border-color: white;">
  <i class="fa fa-minus" style="font-size: 16px; vertical-align: unset;"></i>
</button>
<!-- style="margin-left: 10px;" -->
<span class="clock-container" [class.clock-running]="isClockRunningObservable | async">
  <button (click)="togglePause()" mat-icon-button style="border: 0px; height: 24px; width: 30px; padding: 0; min-width: unset; line-height: 24px; font-size: 18px;">
    <i *ngIf="isClockRunningObservable | async" class="fa fa-clock-o" style="font-size: 16px; vertical-align: unset; color: black;"></i>
    <i *ngIf="isClockRunningObservable | async" class="fa fa-pause" style="font-size: 15px; vertical-align: unset; color: black;"></i>
    <i *ngIf="!(isClockRunningObservable | async)" class="fa fa-play" style="font-size: 15px; vertical-align: unset; color: black;"></i>
  </button>
</span>
<span class="green-text" #timeElement style="line-height: 25px; font-size: 18px; margin-left: 0; margin-right: 10px;"></span>
<button (click)="plusMinute()" mat-icon-button class="secondarybutton" style="border: 0px; height: 24px; width: 40px; padding: 0 5px; min-width: unset; line-height: 24px; font-size: 16px; border-color: white;">
  <i class="fa fa-plus" style="font-size: 16px; vertical-align: unset;"></i>
</button>
<!-- Aika tietokannassa: {{currentTimeInDatabaseObservable | async}}
Aika kulunut: {{currentElapsedTimeObservable | async}} -->