<div style="text-align: center; max-width: 630px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      Aloita uusi sopimuskausi
    </lemon-dialog-header>

    <p style="text-align: left;">Sopimuskausi pitää merkitä alkavaksi päivämäärällä, jolloin asiakas ensimmäisen kerran aikoo käyttää Lemonaidia.</p>
    <p style="text-align: left;">Uusi sopimuskausi merkitään alkavaksi {{sopimuskausiFormGroup.get('alkaa').value | ldate | async }}.</p>

    <div [formGroup]="sopimuskausiFormGroup">

      <mat-form-field style="max-width: 200px; margin-left: 10px; margin-right: 10px; margin-bottom: 22px; margin-top: 44px;">
        <mat-label>Alkaa</mat-label>
        <input [name]="inputname" #sopimuskausiFormGroupAlkaaInputti type="text" [min]="minimiAlkaaPaivamaara" [matDatepicker]="pickerAlkaa" formControlName="alkaa" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="pickerAlkaa"></mat-datepicker-toggle>
        <mat-error *ngIf="sopimuskausiFormGroup.get('alkaa')?.errors?.required && (sopimuskausiFormGroup.get('alkaa')?.dirty || sopimuskausiFormGroup.get('alkaa')?.touched)">
          Alkamispäivä puuttuu
        </mat-error>
        <mat-error *ngIf="sopimuskausiFormGroup.get('alkaa')?.errors?.edellinen && (sopimuskausiFormGroup.get('alkaa')?.dirty || sopimuskausiFormGroup.get('alkaa')?.touched)">
          Uusi sopimuskausi ei voi alkaa samaan aikaan tai ennen edellisen loppumista.
        </mat-error>
        <mat-error *ngIf="sopimuskausiFormGroup.get('alkaa')?.errors?.min && (sopimuskausiFormGroup.get('alkaa')?.dirty || sopimuskausiFormGroup.get('alkaa')?.touched)">
          Uusi sopimuskausi ei voi alkaa samaan aikaan tai ennen edellisen loppumista.
        </mat-error>
        <mat-error *ngIf="sopimuskausiFormGroup.get('alkaa')?.errors?.matDatepickerMin && (sopimuskausiFormGroup.get('alkaa')?.dirty || sopimuskausiFormGroup.get('alkaa')?.touched)">
          Uusi sopimuskausi ei voi alkaa samaan aikaan tai ennen edellisen loppumista.
        </mat-error>
        <mat-datepicker #pickerAlkaa></mat-datepicker>
      </mat-form-field>

    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
    <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
  </div>
</div>