<div class="laheta-card">
  <div class="autosave-time">{{ autosaveTimeTextObservable | async }}</div>
  <div class="laheta-title light-blue-text">Lähetä asiakkaalle sähköposti
    <!-- <button style="margin-left: 20px;" (click)="_resetAutosave()">Poista tietokannasta</button> -->
  </div>
  <div class="laheta-body">
    <form [formGroup]="lahetysForm">
      <div style="margin: 0 10px;">
        <div *ngIf="vastaanottajat?.length; else eiVastaanottajia">
          <mat-form-field style="margin-bottom: 15px">
            <mat-label>Vastaanottajat</mat-label>
            <input type="text" [name]="namename" formControlName="vastaanottajat" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" />
          </mat-form-field>
        </div>
        <ng-template #eiVastaanottajia>
          <div style="color: #f44336;  margin-bottom: 2em; margin-left: 10px;">
            <div style="font-weight: bold;">Ei vastaanottajia.</div>
            <div style="font-size: 13px;">(Lisää käyttäjä, jolla on Yhteyshenkilö-rooli, navigoimalla <a [routerLink]="['/asiakkaat', asiakasAvain, 'kayttajat']">Lemonaid -> Käyttäjät</a>. Varmista, että Aktiivinen-täppä on päällä. Vaihtoehtoisesti voit käyttää yleistä vastaanotto-osoittetta, lisää sellainen <a [routerLink]="['/asiakkaat', asiakasAvain, 'kirjanpitotiedot']">Asetukset -> Kirjanpito
                -> Kirjanpitosähköpostien lisävastaanottajat</a>)</div>
          </div>
        </ng-template>
        <div style="display: flex; justify-content: space-between; margin-top: -10px; margin-bottom: -10px;">
          <mat-form-field style="margin-bottom: 15px; width: 420px">
            <mat-label>Lähettäjä</mat-label>
            <input type="email" [name]="namename" formControlName="lahettaja" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" />
          </mat-form-field>
          <mat-checkbox style="margin-top: 10px" formControlName="piilokopioLahettajalle">Piilokopio lähettäjälle</mat-checkbox>
        </div>
        <mat-form-field style="margin-bottom: 15px">
          <mat-label>Aihe</mat-label>
          <input [name]="namename" (blur)="tryAutosave()" formControlName="aihe" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" required class="email-aihe" />
          <!-- <button mat-icon-button matSuffix (click)="resetAihe()" title="Palauta alkuperäinen teksti">
            <mat-icon>settings_backup_restore</mat-icon>
          </button> -->
          <mat-error *ngIf="aihe?.errors?.required && (aihe?.dirty || aihe?.touched)">
            Lisää aihe.
          </mat-error>
        </mat-form-field>
        <div style="margin: 10px -20px 10px -20px; padding: 20px 20px 0 20px; border: 2px solid rgb(217,217,217);">
          <mat-form-field class="no-outline no-margin">
            <textarea #tekstiTextareaAlku (blur)="tryAutosave()" formControlName="tekstinAlku" matInput data-lpignore="true" matInput required cdkTextareaAutosize #autosizeAlku="cdkTextareaAutosize" [cdkAutosizeMinRows]="1" placeholder="Teksti 1"></textarea>
            <!-- <button mat-icon-button matSuffix (click)="resetTeksti1()" title="Palauta alkuperäinen teksti">
              <mat-icon>settings_backup_restore</mat-icon>
            </button> -->
            <mat-error *ngIf="tekstinAlku?.errors?.required && (tekstinAlku?.dirty || tekstinAlku?.touched)">
              Lisää teksti.
            </mat-error>
          </mat-form-field>
          <div class="autotext">
            <div [style.display]="includeMainAutotext ? 'block' : 'none'">{{ mainAutotext }}</div>
            <button mat-button [title]="includeMainAutotext ? 'Poista automaattisesti luotu teksti sähköpostista' : 'Lisää automaattisesti luotu teksti sähköpostiin'" style="position: absolute; top: 0px; right: 0px; margin: 0; line-height: 28px; min-width: 28px; font-size: 24px; padding: 0;" (click)="showOrHideMainAutotext()">{{ includeMainAutotext ? 'x' : '+' }}</button>
          </div>

          <div class="autotext">
            <div [style.display]="includeSelvitettavatAutotext ? 'block' : 'none'">{{ selvitettavatAutotext }}</div>
            <button mat-button [title]="includeSelvitettavatAutotext ? 'Poista automaattisesti luotu teksti sähköpostista' : 'Lisää automaattisesti luotu teksti sähköpostiin'" style="position: absolute; top: 0px; right: 0px; margin: 0; line-height: 28px; min-width: 28px; font-size: 24px; padding: 0;" (click)="showOrHideSelvitettavatAutotext()">{{ includeSelvitettavatAutotext ? 'x' : '+'
              }}</button>
          </div>

          <mat-form-field class="no-outline no-margin">
            <textarea #tekstiTextareaLoppu (blur)="tryAutosave()" formControlName="tekstinLoppu" placeholder="Teksti 2" matInput data-lpignore="true" matInput required cdkTextareaAutosize #autosizeLoppu="cdkTextareaAutosize" [cdkAutosizeMinRows]="4"></textarea>
            <!-- <button mat-icon-button matSuffix (click)="resetTeksti2()" title="Palauta alkuperäinen teksti">
              <mat-icon>settings_backup_restore</mat-icon>
            </button> -->
            <mat-error *ngIf="tekstinLoppu?.errors?.required && (tekstinLoppu?.dirty || tekstinLoppu?.touched)">
              Lisää teksti.
            </mat-error>
          </mat-form-field>
          <div app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="nykyisenKirjanpitajanNimitiedotObservable | async" [kieli]="asiakkaanKieliObservable | async"></div>
        </div>
        Liitteet
        <div class="check-wrapper">
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="tuloslaskelma">Tuloslaskelma</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadTulos()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="tase">Tase</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadTase()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="paakirja">Pääkirja</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadPaakirja()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="alvIlmoitus">ALV-ilmoitus</mat-checkbox>
            <button class="lataa-pdf-button" type="button" type="button" (click)="downloadAlvIlmoitus()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="selvitettavatOstot">Selvitettävät ostot</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadSelvitettavatOstot()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="selvitettavatMyynnit">Selvitettävät myynnit</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadSelvitettavatMyynnit()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
        </div>
        <div class="attachment-wrapper">
          <div class="attachment-row" *ngFor="let liite of emailLiitteetObservable | async;">
            <button mat-icon-button class="remove-btn small-icon-button" (click)="deleteAttachment(liite)"><i class="icon ion-ios-trash icon-btn"></i></button>
            <div class="attachment-text">{{ liite.nimi }}</div>
            <div class="attachment-size"> {{ liite.koko > (100 * 1000) ? ((liite.koko / (1000 * 1000)) | ldecimall: 1 : 'fi') : ((liite.koko / (1000 * 1000)) | ldecimall: 3 : 'fi') }} MT</div>
            <button class="lataa-pdf-button small-icon-button" type="button" type="button" (click)="downloadAttachment(liite)">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
        </div>

        <button mat-button (click)="fileInput.click()" type="button">
          + Lisää liitetiedosto
        </button>
        <div class="error-box" *ngIf="latausVirhe">{{ latausVirhe }}</div>

      </div>
    </form>
    <div class="error-msg" *ngIf="attachmentsTotalSizeIfTooBigObservable | async; let totalSize">Sähköpostin liitteiden kokonaiskoko ei saa ylittää 10 MT (Nykyinen koko: {{ totalSize }} MT)</div>

    <div style="display: flex; justify-content: space-evenly; padding-top: 20px;">
      <button class="primarybutton" mat-button (click)="laheta()" [disabled]="(attachmentsTotalSizeIfTooBigObservable | async) > 0" type="button">Lähetä</button>
    </div>
  </div>
</div>

<!-- <button mat-button (click)="reload()" type="button">
  TYHJENNÄ TÄSTÄ
</button> -->

<!-- <div app-kirjanpito-lahetys-alv [valittuKuukausiObservable]="valittuKuukausiObservable" [latestAlvIlmoitusObservable]="alvIlmoitusObservable" [naytaAlvIlmoitusValilehtiObservable]="naytaAlvIlmoitusValilehtiObservable" [erapaivaObservable]="erapaivaObservable"></div> -->
<div>
  <div style="margin: .5em; padding: 2em 0 1em 0;" class="light-blue-text laheta-title">Asiakkaalle lähetetyt sähköpostit</div>

  <div style="display: flex; height: 200px; justify-content: center; align-items: center" *ngIf="historyLoading">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
  <ng-container *ngIf="!sentThisMonth?.length && !historyLoading">
    <div style="margin-left: 35px">Asiakkaalle ei ole vielä lähetetty sähköposteja tässä kuussa.</div>
  </ng-container>

  <div *ngFor="let item of sentThisMonth" class="laheta-card">
    <div class="laheta-body laheta-body-history">
      <div *ngIf="item.firestoreLinkki" style="padding: 10px; margin-bottom: 30px; position: relative;" class="mat-elevation-z2">
        <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
        <h3 style="margin: 0;">Urit</h3>
        <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Lähetetty email: <a [href]="item.firestoreLinkkiEncoded" target="_BLANK">{{item.firestoreLinkki}}</a></div>
      </div>
      <mat-form-field style="margin-bottom: 15px">
        <mat-label>Vastaanottajat</mat-label>
        <input disabled="true" type="text" [value]="item.recipientsEmails" matInput />
      </mat-form-field>
      <mat-form-field style="margin-bottom: 15px">
        <mat-label>Aihe</mat-label>
        <input disabled="true" type="text" [value]="item.aihe" matInput />
      </mat-form-field>
      <div class="history-text">
        <span class="history-text-label">Teksti</span>
        <div [innerHTML]="item.teksti"></div>
      </div>
      <div *ngIf="item.liitaAlvIlmoitus || item.liitaSelvitettavatOstot || item.liitaSelvitettavatMyynnit || item.liitaTase || item.liitaTuloslaskelma || item.liitaPaakirja || item.liitteet.length" style="margin-top: 10px">
        <span style="color: grey">Liitteet</span>
        <div class="history-liite-wrapper">
          <li class="history-liite" *ngIf="item.liitaTuloslaskelma" (click)="downloadSentReport(item.avain, 'tuloslaskelma')">tuloslaskelma.pdf</li>
          <li class="history-liite" *ngIf="item.liitaTase" (click)="downloadSentReport(item.avain, 'tase')">tase.pdf</li>
          <li class="history-liite" *ngIf="item.liitaPaakirja" (click)="downloadSentReport(item.avain, 'paakirja')">paakirja.pdf</li>
          <li class="history-liite" *ngIf="item.liitaAlvIlmoitus" (click)="downloadSentReport(item.avain, 'alv-ilmoitus')">alv-ilmoitus.pdf</li>
          <li class="history-liite" *ngIf="item.liitaSelvitettavatOstot" (click)="downloadSentReport(item.avain, 'selvitettavat-ostot')">selvitettavat-ostot.pdf</li>
          <li class="history-liite" *ngIf="item.liitaSelvitettavatMyynnit" (click)="downloadSentReport(item.avain, 'selvitettavat-myynnit')">selvitettavat-myynnit.pdf</li>
          <li class="history-liite" *ngFor="let liite of item.liitteet" (click)="downloadAttachment(liite)">
            {{ liite.nimi }}
            <ng-container *ngIf="liite.firestoreLinkki">
              <a [href]="liite.firestoreLinkkiEncoded" onclick="event.stopPropagation()" target="_BLANK">{{liite.firestoreLinkki}}</a>
            </ng-container>
          </li>
        </div>
      </div>
    </div>
    <div class="history-title light-blue-text">{{ item.nimi }} {{ item.luotuDateStr}} </div>
  </div>
  <!-- <ng-container #noHistory class="history-title light-blue-text"></ng-container> -->
</div>

<input #fileInput type="file" style="visibility: hidden;" accept="*" multiple (change)="lataa($event)" />