import { AfterContentInit, Component, ErrorHandler, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { AsiakasKopioija } from 'app/_angular/service/asiakas/asiakas.kopioija'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { Subject, firstValueFrom, takeUntil } from 'rxjs'
import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'

import { Asiakas, AsiakkaanSopimuskaudenTauko, AsiakkaanSopimuskausi } from '../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { OutgoingSlackMessage } from 'app/_jaettu-lemonator/model/integraatiot'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'

export interface AsiakkaanSopimuskausiTaukoDialogData {
  asiakas: Asiakas
  kausi: AsiakkaanSopimuskausi
  tauko: AsiakkaanSopimuskaudenTauko
  uusi: boolean
}

interface TaukoFormGroup {
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
  muistutuspvm: FormControl<Date>
}

@Component({
  templateUrl: './sopimuskausi-tauko.dialog.html',
  styles: `
    .info {
     margin-left: 5px;
     font-size: 28px;
     color: gray;
     vertical-align: middle;
     cursor: pointer;
    }
  `
})
export class AsiakkaanSopimuskausiTaukoDialog implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) taukoAlkaaInputti: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _kausi: AsiakkaanSopimuskausi = null
  tauko: AsiakkaanSopimuskaudenTauko = null

  taukoFormGroup: FormGroup<TaukoFormGroup> = null
  inputname = 'mnbiggteagjopq' + Math.random()
  minimiAlkaaPaivamaara: Date = null
  maksimiLoppuuPaivamaara: Date = null

  muistutusPvmText: string = 'Tauolla oleva asiakas pitää kontaktoida aika-ajoin. Lemonator muistuttaa tauon olemassaolosta annettuna päivämääränä.'

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanSopimuskausiTaukoDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanSopimuskausiTaukoDialog>,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator,
    private _validationService: FormValidationService,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _asiakasKopioija: AsiakasKopioija,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _kopioijaPalvelu: KopioijaPalvelu,
    private _currencyService: CurrencyService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {

    this.minimiAlkaaPaivamaara = this._dateService.localDateToDate(this._data.kausi.alkaa)

    if (this._data.kausi.loppuu) {
      const loppuuMinus1 = this._dateService.lisaaPaiviaPaikallinen(this._data.kausi.loppuu, -1)
      this.maksimiLoppuuPaivamaara = this._dateService.localDateToDate(loppuuMinus1)
    } else {
      this.maksimiLoppuuPaivamaara = null
    }

    this._kausi = this._kopioijaPalvelu.cloneObjectDeep(this._data.kausi)
    this.tauko = this._kopioijaPalvelu.cloneObjectDeep(this._data.tauko)

    this.taukoFormGroup = this._annaFormGroupTauolle(this.tauko)

  }

  ngOnInit() { }

  ngAfterContentInit() {
    setTimeout(() => {
      this.taukoAlkaaInputti.focus()
    }, 250)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private _alkaaEnnenLoppuaValidator = (control: FormControl<Date>): ValidationErrors | null => {
    const l = this.taukoFormGroup?.get('loppuu')?.value
    const a = this.taukoFormGroup?.get('alkaa')?.value
    if (l && a && a > l) {
      return { 'loppuEnnenAlkua': true }
    }
    return null
  }

  private _annaFormGroupTauolle(tauko: AsiakkaanSopimuskaudenTauko): FormGroup<TaukoFormGroup> {
    const alkaaFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(tauko.alkaa), disabled: false }, [Validators.required, this._alkaaEnnenLoppuaValidator])
    const loppuuFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(tauko.loppuu), disabled: false }, [this._alkaaEnnenLoppuaValidator])
    const muistutuspvmFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(tauko.muistutuspvm), disabled: tauko.tyyppi === 'poytalaatikko' }, [Validators.required, FormValidators.minDateValidator(new Date())])
    const sopimuskausiGroup = new FormGroup<TaukoFormGroup>({
      'alkaa': alkaaFormControl,
      'loppuu': loppuuFormControl,
      'muistutuspvm': muistutuspvmFormControl
    })
    alkaaFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      tauko.alkaa = this._dateService.dateToLocalDate(value)
    })
    loppuuFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      tauko.loppuu = this._dateService.dateToLocalDate(value)
    })
    muistutuspvmFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      tauko.muistutuspvm = this._dateService.dateToLocalDate(value)
    })
    return sopimuskausiGroup
  }

  async tallenna() {
    if (!this.taukoFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.taukoFormGroup)
      return
    }

    try {
      const asiakasCopy = this._asiakasKopioija.kopioiAsiakas(this._data.asiakas)
      const kausiIndex = asiakasCopy.sopimuskaudet.findIndex(k => k.avain === this._kausi.avain)
      if (kausiIndex < 0) {
        throw new Error('No sopimuskausi index found!')
      }
      asiakasCopy.sopimuskaudet[kausiIndex] = this._kausi

      if (!this._kausi.tauot) { this._kausi.tauot = [] }
      const taukoIndex = this._kausi.tauot.findIndex(t => t.avain === this.tauko.avain)
      if (taukoIndex < 0) {
        this._kausi.tauot.push(this.tauko)
      } else {
        this._kausi.tauot[taukoIndex] = this.tauko
      }

      const kirjanpitajanTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      this.tauko.paivittaja = kirjanpitajanTiedot.uid
      if (!this.tauko.luotu) {
        this.tauko.luoja = kirjanpitajanTiedot.uid
        this.tauko.luotu = this._timestampService.now()
        this.tauko.paivitetty = this._kausi.luotu
      } else {
        this.tauko.paivitetty = this._timestampService.now()
      }

      this._kausi.paivittaja = kirjanpitajanTiedot.uid
      this._kausi.paivitetty = this._timestampService.now()

      const batch = this._firebase.firestoreBatch()

      if (this._data.uusi) {

        const nykyinenHinta = this._asiakasJaettuService.annaNykyinenHinta(asiakasCopy)
        const kirjanpitajienNimitiedotMap = await firstValueFrom(this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable)
        const kirjanpitajanNimitiedot = kirjanpitajienNimitiedotMap.get(kirjanpitajanTiedot.uid)
        const tauolleVaiPoytalaatikkoon = this._annaMeneeTauolleOtsikkoSlackviestiaVarten(this.tauko)

        const slackMessageUri = 'tyojono/' + asiakasCopy.avain + '/send-slack-message/' + this.tauko.avain
        const slackMessageData: OutgoingSlackMessage = {
          channel: 'lopettavat_asiakkaat',
          created: this._timestampService.now(),
          message: `Asiakas: *${asiakasCopy.nimi}*
Hinta: ${this._currencyService.formatoiRahaIlmanValuuttaSymbolia(nykyinenHinta, 'fi')}
${tauolleVaiPoytalaatikkoon}: ${this._dateService.muotoilePaikallinenPaiva(this.tauko.alkaa, 'fi')}
Kirjanpitäjä: ${kirjanpitajanNimitiedot.etunimi + ' ' + kirjanpitajanNimitiedot.sukunimi}
Linkki: ${this._annaAsiakkaanLemonatorLinkki(asiakasCopy)}`,
          senderUid: kirjanpitajanTiedot.uid
        }
        batch.set(slackMessageUri, slackMessageData)

      }

      await this._asiakasService.lisaaAsiakasBatchiin(asiakasCopy, kirjanpitajanTiedot, 'tilikaudet-eivat-voineet-muuttua', false, false, batch)
      await batch.commit()

      this._asiakasService.asetaNykyinenAsiakas(asiakasCopy)
      this._dialogRef.close()

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  private _annaMeneeTauolleOtsikkoSlackviestiaVarten(tauko: AsiakkaanSopimuskaudenTauko): string {
    if (tauko.tyyppi === 'poytalaatikko') {
      return 'Sopimus menee pöytälaatikkoon'
    } else if (tauko.tyyppi === 'tauko') {
      return 'Sopimus menee tauolle'
    }
    throw new Error('Tuntematon tauon tyyppi: ' + tauko.tyyppi)
  }

  private _annaAsiakkaanLemonatorLinkki(asiakas: Asiakas): string {
    if (environment.environment === EnvironmentType.PRODUCTION) {
      return `https://lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.BETA) {
      return `https://beta.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.DEV) {
      return `https://dev.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.LOCAL_DEV) {
      return `https://local-dev.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    }
    throw new Error('Unknown environment ' + environment.environment)
  }

}
