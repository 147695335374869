import { OnInit, Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core'

import { FormGroup, FormControl } from '@angular/forms'

import { Kirjanpitotili } from '../../_jaettu-lemonator/model/kirjanpito'
import { Reskontravienti } from '../../_jaettu-lemonator/model/reskontra'


import { Observable, Subject, map } from 'rxjs'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { NumberDate } from 'app/_shared-core/model/common'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { KirjanpitoReskontraUriService } from 'app/_jaettu-lemonator/service/kirjanpito-reskontra-uri.service'

interface MainForm {
  valittuTili: FormControl<string>
  pvm: FormControl<Date>
  vainAvoimet: FormControl<boolean>
}

interface RenderableReskontraRow {
  leftColumnVienti: Reskontravienti
  rightColumnConnectedViennit: Reskontravienti[]
  unconnectedRight: Reskontravienti
}

@Component({
  selector: '[app-kirjanpito-reskontra-single-box]',
  templateUrl: './reskontra-single-box.component.html',
  styleUrls: ['./reskontra-single-box.component.css'],
  animations: [
    trigger('blueBorderFade', [
      state('true', style({
        borderColor: '#346fdc',
      })),
      state('false', style({
        borderColor: 'rgb(124, 124, 124)',
      })),
      transition('true => false', [
        animate('0.6s'),
      ]),
    ])
  ]
})
export class KirjanpitoReskontraSingleBoxComponent implements OnInit, OnDestroy {

  @Output() openKirjaus: EventEmitter<{ kirjausAvain: string, pvm: NumberDate, maksutapa: number }> = new EventEmitter()
  @Input() isSelected: boolean = false
  @Input() vienti: Reskontravienti
  @Input() maksutapa: AsiakkaanMaksutapa
  /**
   * Only feed this to the component for admin users!
   */
  @Input() debugUrlStart: string = null

  private _ngUnsubscribe = new Subject<void>()

  namename: string = 'afasdfasdfayuiopålkjhgf' + new Date().getTime()
  showCommonError: boolean = false

  reskontraTilitObservable: Observable<Kirjanpitotili[]>
  renderableReskontraRowsObservable: Observable<RenderableReskontraRow[]>
  dynamicBoxHeight: string
  screenSizeObservable: Observable<{ small: boolean }>

  public set setBoxHeight(connections: number) {
    const height = (connections * 77.5) + 'px;'
    this.dynamicBoxHeight = height
  }

  form: FormGroup<MainForm>

  constructor(
    private _kayttajaService: KirjautunutKayttajaService,
    private _breakpointObserver: BreakpointObserver,
    private _reskontraUriService: KirjanpitoReskontraUriService
  ) { }

  ngOnInit() {
    this.screenSizeObservable = this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, '(min-width: 1280px) and (max-width: 1599.99px)']).pipe(
      map(br => {
        return { small: br.matches }
      })
    )
  }


  emitKirjausOpen(e: Event) {
    e.preventDefault()
    e.stopPropagation()

    const kirjausAvain = this.vienti.av.split(this._reskontraUriService.getVientiAvainSeparator())[0]
    this.openKirjaus.emit({ kirjausAvain: kirjausAvain, pvm: this.vienti.p, maksutapa: this.vienti.mt })
  }

  debugRedirectIfAdmin() {
    if (this.debugUrlStart) {
      const debugUrl = this.debugUrlStart + '/' + this.vienti.av
      window.open(
        debugUrl,
        '_blank'
      )
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
