import { Component, OnInit, ErrorHandler, OnDestroy, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { MatCheckbox } from '@angular/material/checkbox'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { Observable, Subject, firstValueFrom } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { AsiakkaanSopimuskausiService } from 'app/_jaettu-lemonator/service/sopimuskausi.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { AsiakkaanSopimuskaudenTauko } from 'app/_jaettu-lemonator/model/asiakas'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'

export interface TaukoMuistutusDialogData {
  asiakasAvain: string
}

@Component({
  templateUrl: './tauko-muistutus.dialog.html'
})
export class TaukoMuistutusDialog implements OnInit, OnDestroy {

  @ViewChild('sarakeAktiivinenCheckbox', { read: MatCheckbox, static: true }) sarakeAktiivinenCheckbox: MatCheckbox
  @ViewChild('sarakkeenNimiInput', { read: MatInput, static: true }) sarakkeenNimiInput: MatInput
  @ViewChildren('tilanNimiInput', { read: MatInput }) tilanNimiInputit: QueryList<MatInput>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: FormGroup<{
    muistutuspvm: FormControl<Date>
  }>

  namename: string = 'aussf' + Math.random()
  lisaa: boolean = false

  commonError: string
  taukoObservable: Observable<AsiakkaanSopimuskaudenTauko>

  tauonKesto: string = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: TaukoMuistutusDialogData,
    private _dialogRef: MatDialogRef<TaukoMuistutusDialog>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _asiakasService: AsiakasService,
    private _sopimuskausiService: AsiakkaanSopimuskausiService
  ) { }


  ngOnInit() {

    this.form = new FormGroup({
      'muistutuspvm': new FormControl<Date>(null, [Validators.required, FormValidators.minDateValidator(new Date())]),
    })

    this.taukoObservable = this._asiakasService.annaAsiakasObservable(this._data.asiakasAvain).pipe(
      map(asiakas => {
        if (!asiakas) {
          return null
        }
        const currentLocalDate = this._dateService.currentLocalDate()
        const nykyinenKausi = this._sopimuskausiService.annaKausiPaivamaaralle(asiakas.sopimuskaudet, currentLocalDate)
        const tauko = nykyinenKausi ? this._sopimuskausiService.annaTaukoJokaOnVoimassaPaivamaaralla(nykyinenKausi, currentLocalDate, 'tauko') : null
        return tauko
      })
    )

    this.taukoObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tauko => {
      if (tauko) {
        this.tauonKesto = this._calcAndFormatTauonKesto(tauko)
      } else {
        this.tauonKesto = ''
      }
    })

  }

  private _calcAndFormatTauonKesto(tauko: AsiakkaanSopimuskaudenTauko) {
    const currentLocalDate = this._dateService.currentLocalDate()
    const kuukausiaValissa = this._dateService.kuukausiaValissaPaikallinen(currentLocalDate, tauko.alkaa)
    const alkuPlusKuukaudet = this._dateService.lisaaKuukausiaPaikallinen(tauko.alkaa, kuukausiaValissa ?? 0)
    const paiviaIlmanKuukausiaValissa = this._dateService.paiviaValissaPaikallinen(currentLocalDate, alkuPlusKuukaudet)

    const paivaa = paiviaIlmanKuukausiaValissa > 1 ? ' päivää' : ' päivän'
    const kuukautta = (kuukausiaValissa > 1 ? ' kuukautta' : ' kuukauden')

    if (kuukausiaValissa < 1) {
      return paiviaIlmanKuukausiaValissa + paivaa
    }

    if (paiviaIlmanKuukausiaValissa < 1) {
      return 'tasan ' + kuukausiaValissa + kuukautta
    }

    return kuukausiaValissa + kuukautta + ' ja ' + paiviaIlmanKuukausiaValissa + paivaa
  }

  async tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakasPromise = firstValueFrom(this._asiakasService.annaAsiakasObservable(this._data.asiakasAvain))
      const kirjanpitajanTiedotPromise = this._kirjautunutKayttajaService.getKirjanpitajanTiedot()

      const [asiakas, kirjanpitajanTiedot] = await Promise.all([asiakasPromise, kirjanpitajanTiedotPromise])
      const currentLocalDate = this._dateService.currentLocalDate()
      const nykyinenKausi = this._sopimuskausiService.annaKausiPaivamaaralle(asiakas.sopimuskaudet, currentLocalDate)
      const tauko = nykyinenKausi ? this._sopimuskausiService.annaTaukoJokaOnVoimassaPaivamaaralla(nykyinenKausi, currentLocalDate) : null

      if (!tauko) {
        throw new Error('No tauko found on ' + currentLocalDate.day + '.' + currentLocalDate.month + '.' + currentLocalDate.year)
      }

      const muistutuspvm = this.form.get('muistutuspvm').value
      tauko.muistutuspvm = this._dateService.dateToLocalDate(muistutuspvm)
      tauko.paivittaja = kirjanpitajanTiedot.uid
      tauko.paivitetty = this._timestampService.now()

      await this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)

      this._dialogRef.close()

    } catch (err) {
      this._errorHandler.handleError(new Error('Tauko muistutus update failed! ' + err?.message))
      this.commonError = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
