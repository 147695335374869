<button *ngIf="showPen && !lukittu" mat-icon-button class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<div class="tili-num">{{ numero }}</div>
<div class="long-col" [class.has-label]="!!label">
  <div>{{ label }}</div>
  <div class="option-container" [class.two-col]="twoCol">
    <mat-radio-group *ngIf="!twoCol" (change)="tallennaMuuttunutArvo($event)" [(ngModel)]="value" [disabled]="!editing || lukittu">
      <ng-container *ngFor="let option of options">
        <mat-radio-button [value]="option.value" class="dense" style="margin-left: 10px;">{{option.label}}</mat-radio-button> <br *ngIf="direction === 'vertical'" />
      </ng-container>
    </mat-radio-group>
    <div *ngIf="!twoCol" class="option-container-indicator-container">
      <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </span>
      <span *ngIf="saveSuccess" class="save-success" [@quickInSlowOut]="'in'">
        <mat-icon [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
        Valinta tallennettiin
      </span>
      <span *ngIf="saveFailure" class="save-failure">
        <mat-icon [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
        Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
      </span>
    </div>
  </div>
</div>
<div class="short-col" style="display: flex; align-items: center; justify-content: flex-end;">
  <mat-radio-group *ngIf="twoCol" (change)="tallennaMuuttunutArvo($event)" [(ngModel)]="value" [disabled]="!editing || lukittu">
    <ng-container *ngFor="let option of options">
      <mat-radio-button [value]="option.value" class="dense" style="margin-left: 10px;">{{option.label}}</mat-radio-button> <br *ngIf="direction === 'vertical'" />
    </ng-container>
  </mat-radio-group>
</div>