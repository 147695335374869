
<mat-dialog-content>

  <lemon-dialog-header>
    Valitse tila
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate style="margin: 0;">
    <div style="padding-left: 3em; padding-right: 3em; padding-bottom: 1em;">
      <mat-radio-group style="display: flex; flex-direction: column; margin: 15px 0;" #grouppi aria-label="Valitse tila" (change)="tallenna()" formControlName="tila">
        <mat-radio-button #radbut [value]="null" style="margin: 5px;">
          <span style="font-weight: bold;">Tilaa ei valittu</span>
        </mat-radio-button>
        <mat-radio-button #radbut *ngFor="let tila of tilat" [value]="tila" style="margin: 5px;">
          <span style="font-weight: bold;" [class]="tila.vari">{{tila.nimi}}</span> {{tila.kuvaus}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Sulje</button>
</mat-dialog-actions>
