
import { Korvausparametrit, VeroilmoitusTranslationService } from '../../../_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'

export abstract class KirjanpitoVeroilmoitusBaseCommonComponent {

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService
  ) { }

  protected _localizeNumeroHeader(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, parametrit?: Korvausparametrit) {
    if (!veroilmoitus?.perustiedot?.asiakas) { return '' }
    const yritysmuoto = veroilmoitus.perustiedot.asiakas.yritysmuoto
    const vuosi = veroilmoitus.perustiedot.tilikausi.loppuu.year
    return this._veroilmoitusTranslationService.localizeSilent(numero + '-header', yritysmuoto, vuosi, veroilmoitus.kieli, parametrit)
  }

  protected _localizeNumero(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, parametrit?: Korvausparametrit) {
    if (!veroilmoitus?.perustiedot?.asiakas) { return '' }
    const yritysmuoto = veroilmoitus.perustiedot.asiakas.yritysmuoto
    const vuosi = veroilmoitus.perustiedot.tilikausi.loppuu.year
    return this._veroilmoitusTranslationService.localize(numero, yritysmuoto, vuosi, veroilmoitus.kieli, parametrit)
  }

  protected _localizeNumeroHelp(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, parametrit?: Korvausparametrit) {
    if (!veroilmoitus?.perustiedot?.asiakas) { return '' }
    const yritysmuoto = veroilmoitus.perustiedot.asiakas.yritysmuoto
    const vuosi = veroilmoitus.perustiedot.tilikausi.loppuu.year
    return this._veroilmoitusTranslationService.localizeSilent(numero + '-help', yritysmuoto, vuosi, veroilmoitus.kieli, parametrit)
  }

  protected _annaStringEhdotus(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot): string | null {
    const arvo = veroilmoitus?.oletukset[numero || 'asfd'] ?? null
    if (arvo !== null && arvo !== undefined) {
      return arvo + ''
    }
    return null
  }

  protected _annaEhdotus(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot): string | number | null {
    const oletukset = veroilmoitus?.oletukset
    if (oletukset) {
      return oletukset[numero || 'asfd'] ?? null
    }
    return null
  }

  protected annaTiedonTunniste(numero: string, repeatingMetadata?: RepeatingMetadata) {
    if (repeatingMetadata) {
      return repeatingMetadata.groupdIdentifier + '_' + repeatingMetadata.order + '_' + numero
    }
    return numero
  }

  protected _annaNumeroEhdotus(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot): number {
    const rawValue = this._annaEhdotus(numero, veroilmoitus)
    if (this._currencyService.onkoNumero(rawValue)) {
      return rawValue as number
    }
    return this._currencyService.muutaMerkkijonoNumeroksi(rawValue as string, 2)
  }

}
