import { Injectable, ErrorHandler } from '@angular/core'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Tilitapahtuma, TilitapahtumanLisatiedot, Tili } from '../../_jaettu/model/tiliote'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { EMPTY, BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { switchMap, map, tap, distinctUntilChanged } from 'rxjs/operators'
import { PaivamaaraAikavali } from '../../_shared-core/model/common'
import { DateService } from 'app/_shared-core/service/date.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

export interface ListausTilitapahtuma extends Tilitapahtuma {
  kknumero: number
  lisatiedotObservable: Observable<TilitapahtumanLisatiedot>
  dataUri: string
  dataUrl: string
}

@Injectable()
export class TilitapahtumatUusiDataSourceService extends MatTableDataSource<ListausTilitapahtuma> {

  private _tulleetTaiLahteneet: 'tulleet' | 'lahteneet' | null = null

  lataa: boolean = false

  private aikavaliSubject: BehaviorSubject<PaivamaaraAikavali> = new BehaviorSubject({ start: null, end: null })
  aikavaliObservable: Observable<PaivamaaraAikavali> = this.aikavaliSubject.asObservable()

  private tiliSubject: BehaviorSubject<Tili> = new BehaviorSubject(null)
  tiliObservable: Observable<Tili> = this.tiliSubject.asObservable().pipe(distinctUntilChanged())

  rawDataObservable: Observable<ListausTilitapahtuma[]>

  get alkaa(): Date {
    return this._dateService.localDateToDate(this.aikavaliSubject.value.start)
  }

  get loppuu(): Date {
    return this._dateService.localDateToDate(this.aikavaliSubject.value.end)
  }

  get tili(): Tili {
    return this.tiliSubject.value
  }

  set tili(tili: Tili) {
    this.tiliSubject.next(tili)
  }

  set alkaa(alkaa: Date) {
    const nykyinen = this.aikavaliSubject.value
    nykyinen.start = this._dateService.dateToLocalDate(alkaa)
    this.aikavaliSubject.next(nykyinen)
  }

  set loppuu(loppuu: Date) {
    const nykyinen = this.aikavaliSubject.value
    nykyinen.end = this._dateService.dateToLocalDate(loppuu)
    this.aikavaliSubject.next(nykyinen)
  }

  constructor(
    private _firebase: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _stringService: StringService,
    private _debugService: DebugService
  ) {

    super([])

    const nyt = this._dateService.dateToLocalDate(new Date())
    const ensiKuunEka = this._dateService.lisaaKuukausiaPaikallinen(nyt, 1)
    ensiKuunEka.day = 1
    const edellisenKuunEka = this._dateService.lisaaKuukausiaPaikallinen(nyt, -1)
    edellisenKuunEka.day = 1
    const kuunLoppu = this._dateService.lisaaPaiviaPaikallinen(ensiKuunEka, -1)
    this.aikavaliSubject.next({ start: edellisenKuunEka, end: kuunLoppu })

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    this.sortData = (data: ListausTilitapahtuma[], sort: MatSort): ListausTilitapahtuma[] => {

      const active = sort.active
      const direction = sort.direction

      // console.log('SORT BY', active, direction)

      if (!active || direction === '') {
        return data.sort((a, b) => {
          const valuea = a.jarjestys
          const valueb = b.jarjestys
          if ((valuea === null || valuea === undefined) && (valueb === null || valueb === undefined)) {
            return 0
          } else if (valuea === null || valuea === undefined) {
            return 1
          } else if (valueb === null || valueb === undefined) {
            return -1
          }
          return (valuea - valueb)
        })
      }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      if (active === 'kknumero' || active === 'pvm') {
        return data.sort((a, b) => {
          const valuea = a.jarjestys
          const valueb = b.jarjestys
          if ((valuea === null || valuea === undefined) && (valueb === null || valueb === undefined)) {
            return 0
          } else if (valuea === null || valuea === undefined) {
            return 1
          } else if (valueb === null || valueb === undefined) {
            return -1
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'summa') {
        return data.sort((a, b) => {
          const valuea = a.summa
          const valueb = b.summa
          if ((valuea === null || valuea === undefined) && (valueb === null || valueb === undefined)) {
            return 0
          } else if (valuea === null || valuea === undefined) {
            return -1 * directionMultiplier
          } else if (valueb === null || valueb === undefined) {
            return 1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'kohdistamatta') {
        return data.sort((a, b) => {
          const valuea = a.kohdistamatta
          const valueb = b.kohdistamatta
          if ((valuea === null || valuea === undefined) && (valueb === null || valueb === undefined)) {
            return 0
          } else if (valuea === null || valuea === undefined) {
            return -1 * directionMultiplier
          } else if (valueb === null || valueb === undefined) {
            return 1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      // if (active === 'nro') {
      //   return data.sort((a, b) => {
      //     const valuea = a.nro
      //     const valueb = b.nro
      //     if (valuea === null && valueb === null) {
      //       return 0
      //     } else if (valuea === null) {
      //       return 1 * directionMultiplier
      //     } else if (valueb === null) {
      //       return -1 * directionMultiplier
      //     }
      //     return (valuea - valueb) * directionMultiplier
      //   })
      // }

      // if (active === 'arvopvm') {
      //   return data.sort((a, b) => {
      //     const valuea = a.arvopvm ? a.arvopvm.toDate() : null
      //     const valueb = b.arvopvm ? b.arvopvm.toDate() : null
      //     if (valuea === null && valueb === null) {
      //       return 0
      //     } else if (valuea === null) {
      //       return 1 * directionMultiplier
      //     } else if (valueb === null) {
      //       return -1 * directionMultiplier
      //     }
      //     return (valuea.getTime() - valueb.getTime()) * directionMultiplier
      //   })
      // }

      // if (active === 'pvm') {
      //   return data.sort((a, b) => {
      //     const valuea = a.pvm ? a.pvm.toDate() : null
      //     const valueb = b.pvm ? b.pvm.toDate() : null
      //     if (valuea === null && valueb === null) {
      //       return 0
      //     } else if (valuea === null) {
      //       return 1 * directionMultiplier
      //     } else if (valueb === null) {
      //       return -1 * directionMultiplier
      //     }
      //     return (valuea.getTime() - valueb.getTime()) * directionMultiplier
      //   })
      // }

      if (active === 'viite') {
        return data.sort((a, b) => {
          const valuea = a.yliajoviite ? a.yliajoviite : a.viite
          const valueb = b.yliajoviite ? b.yliajoviite : b.viite
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      if (active === 'maksaja') {
        return data.sort((a, b) => {
          const valuea = a.maksaja
          const valueb = b.maksaja
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      if (active === 'avain') {
        return data.sort((a, b) => {
          const valuea = a.avain
          const valueb = b.avain
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      return data

    }

    this.filterPredicate = (tapahtuma: Tilitapahtuma, filter: string): boolean => {

      if (this._tulleetTaiLahteneet === 'lahteneet') {
        return tapahtuma.summa < 0
      } else if (this._tulleetTaiLahteneet === 'tulleet') {
        return tapahtuma.summa > 0
      }

      if (!filter) {
        return true
      }
      // Alla on oletus implementaatio: https://github.com/angular/material2/blob/4b15b78a445b21b8ebe3fba81f106f51e13e8756/src/lib/table/table-data-source.ts#L174

      // Transform the data into a lowercase string of all property values.
      const accumulator = (currentTerm: string, key: string) => currentTerm + (tapahtuma as { [key: string]: any })[key]
      const dataStr = Object.keys(tapahtuma).reduce(accumulator, '').toLowerCase()

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase()

      return dataStr.indexOf(transformedFilter) !== -1
    }

    this.rawDataObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.aikavaliObservable, this.tiliObservable]).pipe(
      tap(() => {
        this.lataa = true
      }),
      switchMap(([asiakas, aikavali, tili]) => {
        // Nollaa data source
        this.data = []
        // this.dataSource.paginator.pageIndex = 0
        if (asiakas) {
          this.lataa = true
          const query = this._firebase.firestoreCollection<Tilitapahtuma>('customers/' + asiakas.avain + '/bank-transactions')

          if (aikavali) {
            if (aikavali.start) {
              const alku = this._dateService.localDateToNumber(aikavali.start)
              query.where('p', '>=', alku)
            }
            if (aikavali.end) {
              const loppu = this._dateService.localDateToNumber(this._dateService.lisaaPaiviaPaikallinen(aikavali.end, 1))
              query.where('p', '<=', loppu)
            }
            if (tili) {
              query.where('iban', '==', this._stringService.removeAllWhiteSpaces(tili.iban))
            }
          }

          return query.listen().pipe(
            map(tapahtumat => {
              if (!tapahtumat) { return tapahtumat }
              return tapahtumat.sort((a, b) => {
                const valuea = a.jarjestys
                const valueb = b.jarjestys
                if ((valuea === null || valuea === undefined) && (valueb === null || valueb === undefined)) {
                  return 0
                } else if (valuea === null || valuea === undefined) {
                  return 1
                } else if (valueb === null || valueb === undefined) {
                  return -1
                }
                return (valuea - valueb)
              })
            }),
            map(tapahtumat => {
              const listausTapahtumat: ListausTilitapahtuma[] = []
              if (tapahtumat) {
                let kuukausinumero = 1
                let kuukausi: number = null
                for (const tapahtuma of tapahtumat) {
                  if (!kuukausi) {
                    kuukausi = tapahtuma.pvml.month
                  }
                  if (kuukausi !== tapahtuma.pvml.month) {
                    kuukausi = tapahtuma.pvml.month
                    kuukausinumero = 1
                  }
                  const uri = 'customers/' + asiakas.avain + '/bank-transactions/' + tapahtuma.avain
                  listausTapahtumat.push({
                    ...tapahtuma,
                    dataUri: uri,
                    dataUrl: this._debugService.createFirestoreLinkLemonaid(uri),
                    kknumero: kuukausinumero,
                    lisatiedotObservable: this._firebase.firestoreDoc<TilitapahtumanLisatiedot>('customers/' + asiakas.avain + '/bank-transactions/' + tapahtuma.avain + '/bank-transaction-details/' + tapahtuma.avain).listen()
                  })
                  kuukausinumero++
                }
              }
              return listausTapahtumat
            })
          )
        } else {
          this.lataa = false
          return EMPTY
        }
      }),
      tap(rivit => {
        this.lataa = false
        this.data = rivit
      })
    )

  }

  set tulleetTaiLahteneet(tulleetTaiLahteneet: 'tulleet' | 'lahteneet' | null) {
    this._tulleetTaiLahteneet = tulleetTaiLahteneet
    this.filter = this.filter
  }

  get tulleetTaiLahteneet(): 'tulleet' | 'lahteneet' | null {
    return this._tulleetTaiLahteneet
  }

  _filterData(data: ListausTilitapahtuma[]) {
    // If there is a filter string, filter out data that does not contain it.
    // Each data object is converted to a string using the function defined by filterTermAccessor.
    // May be overridden for customization.
    this.filteredData = !this.filter && !this._tulleetTaiLahteneet ? data : data.filter(obj => this.filterPredicate(obj, this.filter))

    if (this.paginator) { this._updatePaginator(this.filteredData.length) }

    return this.filteredData
  }

}
