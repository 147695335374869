import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { of as observableOf, Observable, combineLatest } from 'rxjs'
import { map, take, switchMap } from 'rxjs/operators'

import { Kirjanpitaja } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from '../service/kirjanpitaja/kirjanpitaja.service'
import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'

export interface TyoajanseurantaComponentData {
  kirjanpitaja: Kirjanpitaja
  kuukausi: number
  vuosi: number
  openYhteenveto: boolean
}

@Injectable()
export class TyoajanseurantaComponentDataResolve  {

  constructor(
    private kirjanpitajaService: KirjanpitajaService,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<TyoajanseurantaComponentData> {
    const kirjanpitajanAvain = route.params['kirjanpitajanAvain']
    const kuukausi = route.params['kuukausi'] || new Date().getMonth() + 1
    const vuosi = route.params['vuosi'] || new Date().getFullYear()

    return combineLatest([this.kirjanpitajaService.annaKirjanpitajaObservable(kirjanpitajanAvain), this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable]).pipe(
      switchMap(([paramKirjanpitaja, kirjanpitajanTiedot]) => {
        const returnValue: TyoajanseurantaComponentData = {
          kirjanpitaja: null,
          kuukausi: Number(kuukausi),
          vuosi: Number(vuosi),
          openYhteenveto: false
        }
        if (kirjanpitajanAvain === 'yhteenveto') {
          returnValue.openYhteenveto = true
        }
        if (!paramKirjanpitaja || kirjanpitajanTiedot.rooli !== KirjanpitajanRooli.SUPER) {
          return this.kirjanpitajaService.annaKirjanpitajaObservable(kirjanpitajanTiedot.uid).pipe(
            map(kirjautunut => {
              returnValue.kirjanpitaja = kirjautunut
              return returnValue
            }),
          )
        }
        returnValue.kirjanpitaja = paramKirjanpitaja
        return observableOf<TyoajanseurantaComponentData>(returnValue)
      }),
      take(1)
    )
  }
}
