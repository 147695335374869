import { Component, ChangeDetectionStrategy, Input, OnInit, ErrorHandler } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { Platform } from '@angular/cdk/platform'

import { DateService } from '../_shared-core/service/date.service'

import { LemonKuukausiDateAdapter } from '../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

@Component({
  selector: 'app-asiakas-lisaa-eka-lasku-kuukausi',
  templateUrl: './asiakas-lisaa.pvm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] }
  ]
})
export class AsiakasLisaaEkaLaskutuskuukausiComponent implements OnInit {

  @Input() kontrolli: UntypedFormControl
  @Input() minMonth: Date

  constructor(
    private dateService: DateService
  ) {

  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    if (this.dateService.kuukausiaValissa(normalizedMonth, this.minMonth) > -1) {
      this.kontrolli.setValue(normalizedMonth)
      this.kontrolli.markAsTouched()
    }
    datepicker.close()
  }

  ngOnInit() {

  }

}
