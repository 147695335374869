import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LoginInformation } from '../_jaettu/model/login'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { Observable, combineLatest, map, of } from 'rxjs'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'

interface LoginInformationDisplayData extends LoginInformation {
  emuloija: string
}
@Component({
  templateUrl: './kayttaja.login-history.dialog.html'
})
export class KayttajaLoginHistoryDialog implements OnInit {

  displayDataObservable: Observable<LoginInformationDisplayData[]>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoginInformation[],
    private _dialogRef: MatDialogRef<KayttajaLoginHistoryDialog>,
    private _kirjanpitajaService: KirjanpitajaService
  ) {
    this.displayDataObservable = combineLatest([of(data), this._kirjanpitajaService.kirjanpitajienNimitiedotObservable]).pipe(
      map(([dat, nimitiedot]) => {
        const res: LoginInformationDisplayData[] = []
        for (const l of dat) {
          const d: LoginInformationDisplayData = { ...l, emuloija: this._annaEmuloija(l, nimitiedot) }
          res.push(d)
        }
        return res
      })
    )

  }

  private _annaEmuloija(loginInfo: LoginInformation, nimitiedot: KirjanpitajanNimitiedot[]): string {
    if (!loginInfo.emulatedByKayttaAvain) {
      return 'käyttäjä itse'
    }
    const emuloija = nimitiedot.find(kp => kp.avain === loginInfo.emulatedByKayttaAvain || kp.lemonaidUid === loginInfo.emulatedByKayttaAvain)
    if (emuloija) {
      return emuloija.etunimi + ' ' + emuloija.sukunimi
    }
    return loginInfo.emulatedByKayttaAvain
  }

  ngOnInit() { }

  sulje() {
    this._dialogRef.close()
  }

}
