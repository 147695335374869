import { TuettuKieli } from '../../_shared-core/model/common'
import { KirjanpitajanNimitiedot, KirjanpitajanToimipiste } from '../model/kirjanpitaja'
import { KirjanpitajanToimipisteService } from './kirjanpitajan-toimipiste.service'

export class KirjanpitajanAllekirjoitusService {

  constructor(
    private _toimipisteService: KirjanpitajanToimipisteService
  ) { }

  getSignatureHtml(kirjanpitaja: KirjanpitajanNimitiedot, kieli: TuettuKieli): string {
    if (kirjanpitaja.avain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Herra Holvi
      return this._getHolviSignatureTemplate()
    }
    const defaultLemonImageUrl = 'https://storage.googleapis.com/lemon-public-images/lemon-tree-logo.jpg'
    const toimipiste = this._toimipisteService.annaToimipiste(kirjanpitaja.toimipisteAvain)
    return this._getRegularSignatureTemplate(kirjanpitaja, toimipiste, defaultLemonImageUrl, kieli)
  }

  async getSignatureText(kirjanpitaja: KirjanpitajanNimitiedot, kieli: TuettuKieli, htmlStripper: { getTextEmailFromHtmlEmail: (tekstiHtml: string) => Promise<string> }): Promise<string> {

    if (kirjanpitaja.avain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Herra Holvi
      return 'Holvi Zen kirjanpitotiimi'
    }

    const toimipiste = this._toimipisteService.annaToimipiste(kirjanpitaja.toimipisteAvain)

    const lomaviesti = kirjanpitaja.lomaviesti?.[kieli] ? await htmlStripper.getTextEmailFromHtmlEmail(kirjanpitaja.lomaviesti[kieli]) : ''

    // Notice that the used lomaviesti contains two rivinvaihto if it has text...
    const usedLomaviesti = lomaviesti ? `${lomaviesti}

` : ''

    // ...and for that reason lomaviesti has no space in it.
    return `${usedLomaviesti}${kirjanpitaja.etunimi} ${kirjanpitaja.sukunimi}
Tilitoimisto Lemon Tree Oy
${kirjanpitaja?.puhelin ? kirjanpitaja.puhelin + ' |' : ''}  ${kirjanpitaja.email}
www.lemontree.fi
${toimipiste.katuosoite}, ${toimipiste.postinro} ${toimipiste.kaupunki}`
  }

  private _getRegularSignatureTemplate(kirjanpitaja: KirjanpitajanNimitiedot, toimipiste: KirjanpitajanToimipiste, defaultLemonImageUrl: string, kieli: TuettuKieli) {
    const textStart = `<div>
${kirjanpitaja.lomaviesti?.[kieli] ?? ''}

<table style="color:rgb(0,0,0);border:0px;padding:0px;margin:0px">
  <tbody>
  <tr>
  <td>
  <table style="max-width:600px;direction:ltr">
  <tbody>
  <tr>
  <td>
  <table border="0" cellspacing="0" cellpadding="0" style="max-width:470px;padding-bottom:10px;margin-bottom:8px">
  <tbody>
  <tr>
  <td style="width: 10px; padding-right: 10px;">
  <img src=${kirjanpitaja.signatureImageUrl || defaultLemonImageUrl} width="93" height="92" alt="Kirjanpitäjän kuva" style="border-top-left-radius: 50%; border-top-right-radius:50%; border-bottom-right-radius: 50%; border-bottom-left-radius: 50%; width: 93px; height: 92px; max-width: 120px;">
  </td>
  <td>
  <table border="0" cellspacing="0" cellpadding="0" width="470" style="width:470px">
  <tbody>
  <tr valign="top">
  <td style="border-right-width:1px;border-right-style:solid;border-right-color:rgb(27,162,235)"></td>
  <td style="text-align:initial;font-stretch:normal;font-size:12px;line-height:normal;padding:0px 10px">
  <div style="margin-bottom:5px;margin-top:0px!important"><b>
  <font color="#241f5d">${kirjanpitaja.etunimi} ${kirjanpitaja.sukunimi}</font>
  </b><br>
  <font color="#646464">Tilitoimisto Lemon Tree Oy</font>
  </div>
  <table width="470" border="0" cellspacing="0" cellpadding="0" style="color:rgb(100,100,100);width:470px;margin-top:5px">
  <tbody>
  <tr>
    <td style="color:rgb(141,141,141);font-size:12px">`

    const puhelinText = `<div style="margin:0px"><a href="tel:${kirjanpitaja.puhelin}" style="color:rgb(141,141,141);text-decoration:none" target="_blank">${kirjanpitaja.puhelin}</a>&nbsp;<span style="color:rgb(27,162,235);display:inline-block">|</span>&nbsp;`

    const textEnd = `<span style="display:inline-block"><a href="mailto:${kirjanpitaja.email}" style="color:rgb(141,141,141);text-decoration:none" target="_blank">${kirjanpitaja.email}</a></span></div>
  </td>
  </tr>
  <tr>
  <td style="color:rgb(141,141,141);font-size:12px">
  <div style="margin:0px"><span style="white-space:nowrap;display:inline-block"><a href="http://www.lemontree.fi/" style="color:rgb(141,141,141);text-decoration:none" target="_blank"
  data-saferedirecturl="https://www.google.com/url?q=http://www.lemontree.fi/&amp;source=gmail&amp;ust=1567595719483000&amp;usg=AFQjCNFOdzIZtlaIaHtxndJ3pG8Zv4OFig">www.lemontree.fi</a></span></div>
  </td>
  </tr>
  <tr>
  <td style="color:rgb(141,141,141);font-size:12px"><span style="display:inline-block">${toimipiste.katuosoite}, ${toimipiste.postinro} ${toimipiste.kaupunki}</span></td>
  </tr>
  </tbody>
  </table>
  <div style="color:rgb(100,100,100);margin-top:10px"></div>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
</table>
</div>`

    return textStart + (kirjanpitaja.puhelin ? puhelinText : '') + textEnd
  }

  private _getHolviSignatureTemplate() {
    const html = `<table style="color:rgb(0,0,0);border:0px;padding:0px;margin:0px">
  <tbody>
    <tr>
      <td>
        <table style="max-width:600px;direction:ltr">
          <tbody>
            <tr>
              <td>
                <table border="0" cellspacing="0" cellpadding="0" style="max-width:470px;padding-bottom:10px;margin-bottom:8px">
                  <tbody>
                    <tr>
                      <td style="width: 10px; padding-right: 10px;">
                        <img src="https://storage.googleapis.com/lemon-public-images/holvi_logo.png" width="93" height="92" alt="Holvi" style="width: 93px;height: 92px;">
                      </td>
                      <td>
                        <table border="0" cellspacing="0" cellpadding="0" width="470" style="width:470px">
                          <tbody>
                            <tr valign="top">
                              <td style="border-right-width:1px;border-right-style:solid;border-right-color:#22272c"></td>
                              <td style="text-align:initial;font-stretch:normal;font-size:12px;line-height:normal;padding:0px 10px">
                                <div style="margin-bottom:5px;margin-top:0px!important"><b>
                                    <font color="#22272c">Holvi Zen kirjanpitotiimi</font>
                                  </b><br>
                                </div>
                                <table width="470" border="0" cellspacing="0" cellpadding="0" style="color:rgb(100,100,100);width:470px;margin-top:5px">
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>`

    return html
  }
}
