import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { KirjanpidonProjekti } from 'app/_jaettu-lemonator/model/kirjanpito'

import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: '[app-kirjanpito-projektien-tyylit]',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoProjektienTyylitComponent implements OnInit, OnDestroy {

  @Input() kirjanpidonProjektitObservable: Observable<KirjanpidonProjekti[]>

  private _ngUnsubscribe = new Subject<void>()
  private _colorCodeMap: Map<string, string> = new Map()

  // return {
  //   pun: 'red-text',
  //   sin: 'blue-text',
  //   vsi: 'light-blue-text',
  //   kel: 'yellow-text',
  //   vih: 'green-text',
  //   vio: 'purple-text',
  //   har: 'light-gray-text'
  // }

  private _styleSheet: CSSStyleSheet

  ngOnInit() {

    this._colorCodeMap.set('pun', 'rgb(255, 72, 102)')
    this._colorCodeMap.set('sin', '#2a8ff4')
    this._colorCodeMap.set('vsi', 'rgb(62, 203, 244)')
    this._colorCodeMap.set('kel', 'rgb(248, 203, 89)')
    this._colorCodeMap.set('vih', '#4bd76b')
    this._colorCodeMap.set('vio', '#f755f5')
    this._colorCodeMap.set('har', 'lightgray')

    // Find or create the stylesheet we're going to use
    const styleSheetElement = document.head.querySelector('#projektityylit') as HTMLStyleElement
    if (styleSheetElement) {
      this._styleSheet = styleSheetElement.sheet
    } else {
      // Create the style sheet element.
      const newStyleSheetElement = document.createElement<'style'>('style')
      newStyleSheetElement.id = 'projektityylit'

      // Append the style sheet element to the head.
      document.head.appendChild(newStyleSheetElement)
      this._styleSheet = newStyleSheetElement.sheet
    }

    this.kirjanpidonProjektitObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(projektit => {
      this._clearAllRules()
      if (projektit?.length) {
        for (const projekti of projektit) {
          const colorCode = this._colorCodeMap.get(projekti.color) ?? '#FF00FF'
          const rule = '.a' + projekti.avain + '.projekti-dot::before, .a' + projekti.avain + '.kr-projekti-dot, .a' + projekti.avain + '.kr-projekti-dot-selection { background-color: ' + colorCode + '; }'
          // console.log(rule)
          this._styleSheet.insertRule(rule)
          const textRule = '.a' + projekti.avain + '.projekti-teksti { color: ' + colorCode + '; }'
          this._styleSheet.insertRule(textRule)
        }
      }
    })

  }

  private _clearAllRules() {
    if (this._styleSheet) {
      for (let i = 0; i < this._styleSheet.cssRules.length; i++) {
        this._styleSheet.deleteRule(i)
      }
    }
  }

  ngOnDestroy() {
    this._clearAllRules()
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
