import { KirjanpitajanToimipiste } from '../model/kirjanpitaja'

export class KirjanpitajanToimipisteService {

  annaToimipiste(toimipisteAvain: string): KirjanpitajanToimipiste {
    if (toimipisteAvain === this._siltasaarenkatu.avain) {
      return this._siltasaarenkatu
    }
    if (toimipisteAvain === this._annankatu.avain) {
      return this._annankatu
    }
    if (toimipisteAvain === this._siltasaari.avain) {
      return this._siltasaari
    }
    throw new Error('Unknown toimipisteAvain ' + toimipisteAvain)
  }

  annaKaikkiToimipisteet(): KirjanpitajanToimipiste[] {
    return [this._siltasaarenkatu, this._annankatu, this._siltasaari]
  }

  private _annankatu: KirjanpitajanToimipiste = {
    avain: '86WlETolhGj7TwHYdijH',
    nimi: 'Annankatu',
    katuosoite: 'Annankatu 19',
    postinro: '00120',
    kaupunki: 'Helsinki',
    maa: 'Suomi',
    puhnro: '050 412 6828'
  }
  private _siltasaari: KirjanpitajanToimipiste = {
    avain: 'AYKyYbRKIhmGemk664QX',
    nimi: 'Siltasaari',
    katuosoite: 'Pitkänsillanranta 17',
    postinro: '00530',
    kaupunki: 'Helsinki',
    maa: 'Suomi',
    puhnro: ''
  }
  private _siltasaarenkatu: KirjanpitajanToimipiste = {
    avain: 'bEY5mLBGcVYhRYxz2hC1',
    nimi: 'Siltasaarenkatu',
    katuosoite: 'Siltasaarenkatu 8-10',
    postinro: '00530',
    kaupunki: 'Helsinki',
    maa: 'Suomi',
    puhnro: '010 517 6020'
  }
}
