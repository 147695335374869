<div>

  <div *ngIf="tulokset | async as asiakkaat">
    <div class="light-blue-text" style="font-size: 40px; margin-left: 40px; margin-bottom: 20px; line-height: 60px; margin-top: 25px;">Jaettavat asiakkaat</div>
    <table *ngIf="asiakkaat.length else eiAsiakkaita">
      <tr>
        <th></th>
        <th>Nimi</th>
        <th>Y-tunnus</th>
        <th>Hinta</th>
        <th>Muistiinpanot</th>
      </tr>
      <tr *ngFor="let asiakas of asiakkaat" [class]="asiakas.luokat">
        <td>
          <i title="Sopimus hyväksymättä" class="fa fa-pencil-square" style="font-size: 20px; padding: 1px; color: red" *ngIf="asiakas.bsh"></i>
        </td>
        <td><a [routerLink]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'" [href]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'">{{asiakas.n}}</a></td>
        <td>{{asiakas.y}}</td>
        <td>{{asiakas.h}}</td>
        <td class="sales-muistiinpanot" (click)="muokkaaMuistiinpanoja($event, asiakas)">{{asiakas.m}}</td>
      </tr>
    </table>
    <ng-template #eiAsiakkaita>
      <div style="padding: 3em;">Myynnin listalta ei löytynyt yhtään asiakasta.</div>
    </ng-template>
  </div>

</div>