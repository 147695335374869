import { DateService } from '../../_shared-core/service/date.service'
import { MaaBaseService } from '../../_jaettu/service/maa.service-base'
import { AlvMaaritys, KirjanpitotilinAlvTyyppi, MyyntiAlvt, OstoAlvt } from '../../_jaettu-lemonator/model/kirjanpito'

import { AlvBaseService, EuVatSpecMapProvider } from '../../_jaettu/service/alv-base.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { LaskunAlv, LaskunTyyppi } from '../../_jaettu/model/lasku'

export interface AlvMaaritysFinder {
  annaMaaritys(tunniste: string): Promise<AlvMaaritys>
}

export interface LaskunAlvMaaritysFinder {
  annaLaskutyypinAlvt(laskunTyyppi: LaskunTyyppi, maaKoodi: string): Promise<LaskunAlv[]>
}

export interface DocIdProvider {
  annaId(): string
}


export abstract class AlvLemonatorBaseService extends AlvBaseService {

  constructor(
    protected _currencyService: CurrencyService,
    protected _maaService: MaaBaseService,
    protected _dateService: DateService
  ) {
    super(_currencyService, _dateService)
  }

  alvMaarityksenJarjestaja(a: AlvMaaritys, b: AlvMaaritys): number {
    const amaakoodi = a.lyhenne.substr(0, 3)
    const bmaakoodi = b.lyhenne.substr(0, 3)
    if (amaakoodi !== bmaakoodi) {
      return amaakoodi.localeCompare(bmaakoodi)
    }
    if (a.lopetettu) {
      return 1
    } else if (b.lopetettu) {
      return -1
    }
    if (a.oletus) {
      return -1
    } else if (b.oletus) {
      return 1
    }
    return b.prosentti - a.prosentti
  }

  private async _annaOssTyypit(euVatSpecMapProvider: EuVatSpecMapProvider): Promise<AlvMaaritys[]> {
    const alvMap = await euVatSpecMapProvider.provide()
    const kaikki: AlvMaaritys[] = []
    for (const maaKoodi of Object.keys(alvMap)) {
      const alvtForCountry = alvMap[maaKoodi]
      if (!alvtForCountry?.length) { continue }
      const alvt: AlvMaaritys[] = alvtForCountry.map(rate => {
        const ret: AlvMaaritys = {
          prosentti: rate.p,
          tunniste: this._annaEtamyyntiTunniste(maaKoodi, rate.p),
          kaannetty: false,
          kirjaustili: '2946',
          lyhenne: maaKoodi + this._currencyService.formatoiDesimaali(rate.p, 2, 'fi'),
          nimi: this._capitalizeFirst(this._maaService.getName(maaKoodi, 'fi')) + ' ' + this._currencyService.formatoiDesimaaliSailytaNollat(rate.p, 2, 'fi') + '%'
        }
        if (rate.d) {
          ret.oletus = 1
        }
        if (rate.e) {
          ret.lopetettu = rate.e
          ret.nimi += ' (päättynyt ' + this._dateService.muotoileNumberPaivaKapea(rate.e, 'fi') + ')'
        }
        return ret
      })
      kaikki.push(...alvt)
    }
    kaikki.sort(this.alvMaarityksenJarjestaja)
    return kaikki
  }

  public async annaAlvTyypinAlvt(alvTyyppi: KirjanpitotilinAlvTyyppi, euVatSpecMapProvider: EuVatSpecMapProvider): Promise<AlvMaaritys[]> {
    if (alvTyyppi === KirjanpitotilinAlvTyyppi.MYYNTI) {
      return Promise.resolve<AlvMaaritys[]>(MyyntiAlvt.kaikki)
    } else if (alvTyyppi === KirjanpitotilinAlvTyyppi.OSTO) {
      return Promise.resolve<AlvMaaritys[]>(OstoAlvt.kaikki)
    } else if (alvTyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI_EI_REKISTEROITYNYT) {
      return Promise.resolve<AlvMaaritys[]>(MyyntiAlvt.suomi)
    } else if (KirjanpitotilinAlvTyyppi.ETAMYYNTI) {
      return this._annaOssTyypit(euVatSpecMapProvider)
    } else if (alvTyyppi === KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA) {
      return Promise.resolve<AlvMaaritys[]>([])
    }
    throw new Error('Unknown alvTyyppi ' + alvTyyppi)
  }

  private _capitalizeFirst(suspect: string): string {
    const str = suspect.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  public async annaAlvMaaritykset(euVatSpecMapProvider: EuVatSpecMapProvider): Promise<Map<string, AlvMaaritys>> {
    const etamyyntiPromise = this.annaAlvTyypinAlvt(KirjanpitotilinAlvTyyppi.ETAMYYNTI, euVatSpecMapProvider)
    const map: Map<string, AlvMaaritys> = new Map<string, AlvMaaritys>()
    MyyntiAlvt.kaikki.forEach(a => map.set(a.tunniste, a))
    OstoAlvt.kaikki.forEach(a => map.set(a.tunniste, a))
    const etamyynnit = await etamyyntiPromise
    for (const etamyynti of etamyynnit) {
      map.set(etamyynti.tunniste, etamyynti)
    }
    return map
  }

}
