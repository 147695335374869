<div [class.auki]="vasenValikkoAukiObservable | async" style="min-height: 100vh; background-color: white;" (drop)="dropped($event)" (dragover)="dragging($event)" (dragenter)="dragenter($event)" (dragleave)="dragleave($event)">
  <ng-container *ngIf="urlObservable | async; let url">
    <app-menu [style.display]="(vasenValikkoAukiObservable | async) ? 'block' : 'none'" [naytaSales]="naytaSalesObservable | async" [naytaSuper]="naytaSuperObservable | async" [naytaLisaaAsiakas]="naytaLisaaAsiakasObservable | async" [nykyinenAsiakas]="nykyinenAsiakasObservable | async" [url]="url">
      <!-- (vasemmanValikonSulkupyyntoOma)="suljeVasenValikkoOma($event)" -->
    </app-menu>
  </ng-container>
  <div style="width: 100%; min-height: 100vh;">
    <div app-browser-clock-check></div>
    <!-- <button class="vasemmanValikonAukaisija" style="padding: 0; margin: 0; min-width: unset; z-index: 999999;" *ngIf="naytaAvaaVasenValikkoNappiObservable | async" mat-icon-button (click)="avaaVasenValikko($event)">
      <mat-icon class="mat-24">menu</mat-icon>
    </button> -->
    <ng-container *ngIf="kirjanpitajaObservable | async; let kirjanpitaja">
      <div *ngIf="kirjanpitaja.impersonoituKayttaja && (vasenValikkoAukiObservable | async)" style="margin: 1em; padding: 1em; border: 1px dashed red; display: flex; justify-content: space-evenly; flex-flow: row wrap;">
        <div style="line-height: 36px;">
          Kirjaudut
          <span *ngIf="kirjanpitaja.impersonoituAsiakas">
            asiakkaan {{ kirjanpitaja.impersonoituAsiakas.nimi }} ({{kirjanpitaja.impersonoituAsiakas.ytunnus}})
          </span>
          käyttäjänä {{ kirjanpitaja.impersonoituKayttaja.etunimi }} {{ kirjanpitaja.impersonoituKayttaja.sukunimi }} ({{ kirjanpitaja.impersonoituKayttaja.email }}) Lemonaidiin.
        </div>
        <button class="primarybutton" mat-button (click)="lopetaKayttajanaKirjautuminen()">Lopeta käyttäjänä kirjautuminen</button>
      </div>
    </ng-container>
    <router-outlet></router-outlet>
  </div>
</div>
<div style="width: 100%;" #paaluukku></div>
<div lemon-loading class="kokosivu" [show]="ladataanObservable | async" [config]="ladataanOverlayConfig"></div>