import { TuettuKieli } from "../../_shared-core/model/common"
import { TemplateAlvType, TemplateCategory } from "../model/email-templates"

export class EmailTemplatesUriService {

  calcTemplateAvain(category: TemplateCategory, language: TuettuKieli, regularOrZen: 'reg' | 'zen', alvType: TemplateAlvType, hasAttachments: boolean, hasSelvittavia: boolean,) {
    return category + '_' + language + '_' + regularOrZen + '_' + alvType + '_' + (hasAttachments ? 'liit' : 'ei-liit') + '_' + (hasSelvittavia ? 'selv' : 'ei-selv')
  }

  getEmailTemplateDraftUri(templateAvain: string) {
    return 'admin/email-templates/drafts/' + templateAvain
  }

  getEmailTemplateFinalUri(templateAvain: string) {
    return 'admin/email-templates/final/' + templateAvain
  }

}