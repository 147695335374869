<mat-dialog-content>

  <lemon-dialog-header>
    {{ lisaa ? 'Lisää sarake' : 'Muokkaa saraketta ' + sarake?.nimi }}
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate style="margin: 0;">
    <div style="padding-left: 3em; padding-right: 3em; padding-bottom: 1em;">

      <mat-checkbox #sarakeAktiivinenCheckbox formControlName="aktiivinen">Aktiivinen</mat-checkbox>

      <mat-form-field style="margin: 1.5em 0;">
        <mat-label>Sarakkeen nimi</mat-label>
        <input type="text" #sarakkeenNimiInput [name]="namename" matInput formControlName="nimi" data-lpignore="true" required />
        <mat-error *ngIf="form.get('nimi')?.errors?.required && (form.get('nimi')?.dirty || form.get('nimi')?.touched)">Nimi puuttuu</mat-error>
      </mat-form-field>

      <table>
        <tbody formArrayName="tilat">
          <tr *ngFor="let tila of annaTilaControllit(); let i = index;" [formGroupName]="i">
            <td style="font-size: 30px; padding: 0 5px;" class="light-blue-text">{{ i + 1 }}</td>
            <td>
              <mat-form-field style="width: 175px;">
                <mat-label>Tilan nimi</mat-label>
                <input #tilanNimiInput type="text" [name]="namename" matInput formControlName="nimi" data-lpignore="true" required />
                <mat-error *ngIf="tila.get('nimi')?.errors?.required && (tila.get('nimi')?.dirty || tila.get('nimi')?.touched)">Nimi puuttuu</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field style="width: 175px;">
                <mat-label>Tilan kuvaus</mat-label>
                <input type="text" [name]="namename" matInput formControlName="kuvaus" data-lpignore="true" />
                <mat-error *ngIf="tila.get('kuvaus')?.errors?.required && (tila.get('kuvaus')?.dirty || tila.get('kuvaus')?.touched)">Kuvaus puuttuu</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field style="width: 175px;">
                <mat-label>Tilan väri</mat-label>
                <mat-select [class]="tila.get('vari').value" style="font-weight: bold;" formControlName="vari">
                  <mat-option [value]="null"><span style="font-weight: bold;">Oletus</span></mat-option>
                  <mat-option value="red-text"><span style="font-weight: bold;" class="red-text">Punainen</span></mat-option>
                  <mat-option value="blue-text"><span style="font-weight: bold;" class="blue-text">Sininen</span></mat-option>
                  <mat-option value="light-blue-text"><span style="font-weight: bold;" class="light-blue-text">Vaalean sininen</span></mat-option>
                  <mat-option value="yellow-text"><span style="font-weight: bold;" class="yellow-text">Keltainen</span></mat-option>
                  <mat-option value="green-text"><span style="font-weight: bold;" class="green-text">Vihreä</span></mat-option>
                  <mat-option value="purple-text"><span style="font-weight: bold;" class="purple-text">Violetti</span></mat-option>
                  <mat-option value="light-gray-text"><span style="font-weight: bold;" class="light-gray-text">Harmaa</span></mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-checkbox style="margin-left: 15px;" formControlName="oletus">Oletus</mat-checkbox>
            </td>
          </tr>
        </tbody>
      </table>

      <button #lisaaButton [disabled]="!form.get('aktiivinen').value" class="secondarybutton" mat-button (click)="lisaaTila()"><mat-icon>add</mat-icon> Lisää tila</button>

    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Sulje</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>