import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef, ViewChild, Input } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { KirjanpitoVeroilmoitusRadioOption } from '../components/veroilmoitus-row-radio.component'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { VeroilmoituksenValueConstants } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoVeroilmoitusBaseSpecializedComponent } from '../components/base-veroilmoitus.component'
import { Router } from '@angular/router'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { TallennaArvoEvent } from '../veroilmoitus.component'
import { VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { KirjanpitoVeroilmoitusRowNumberComponent } from '../components/veroilmoitus-row-number.component'
import { KirjanpitoVeroilmoitusRowNumberDoubleComponent } from '../components/veroilmoitus-row-number-double.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'
import { VeroilmoitusLaskenta2020OyService } from 'app/_jaettu-lemonator/service/veroilmoitus/oy/veroilmoitus-laskenta-2020-oy.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { firstValueFrom } from 'rxjs'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'

@Component({
  selector: '[app-kirjanpito-veroilmoitus-oy-2020]',
  templateUrl: './veroilmoitus-oy-2020.component.html',
  styleUrls: ['./veroilmoitus-oy-2020.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusOy2020Component extends KirjanpitoVeroilmoitusBaseSpecializedComponent implements OnInit, OnDestroy {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ViewChild('edit364_365', { static: false, read: KirjanpitoVeroilmoitusRowNumberDoubleComponent }) editComponent364_365: KirjanpitoVeroilmoitusRowNumberDoubleComponent
  @ViewChild('edit800', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent800: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit801', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent801: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit802', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent802: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit804', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent804: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit806', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent806: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit807', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent807: KirjanpitoVeroilmoitusRowNumberComponent

  @Input() lukittu: boolean

  kyllaEiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Ei', value: VeroilmoituksenValueConstants.FALSE }]
  kylla1Ei2Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Ei', value: '2' }]
  tilintarkastusOptions: KirjanpitoVeroilmoitusRadioOption[] = [
    { label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE },
    { label: 'Ei, tehdään myöhemmin.', value: '2' },
    { label: 'Ei, tilintarkastaja on jätetty valitsematta.', value: '3' }
  ]

  // v342Korvausparametrit: Korvausparametrit = {}
  // v347Korvausparametrit: Korvausparametrit = {}
  // v916Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yhdenkertainen', value: VeroilmoituksenValueConstants.FALSE }, { label: 'Kahdenkertainen', value: VeroilmoituksenValueConstants.TRUE }]
  // ajojenSelvitystyyppiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Ajopäiväkirjaan', value: '1' }, { label: 'Muuhun selvitykseen', value: '2' }]
  // v396Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yksityisajojen kulut on vähennetty kirjanpidossa', value: '1' }, { label: 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa', value: '2' }]

  // tyohuonevahennysOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kaavamainen työhuonevähennys', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Työhuonevähennys todellisten kulujen mukaan', value: VeroilmoituksenValueConstants.FALSE }]
  // ctrl395Options: KirjanpitoVeroilmoitusRadioOption[] = [
  //   { label: 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta', value: 'DEFAULT' },
  //   { label: 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta', value: '1' },
  //   { label: 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa', value: '2' }
  // ]

  osakasRepeatingMetadatas: RepeatingMetadata[] = []
  osakasHuoneistotRepeatingMetadatas: RepeatingMetadata[] = []
  osingotRepeatingMetadatas: RepeatingMetadata[] = []

  private _oyService: VeroilmoitusLaskenta2020OyService = null

  constructor(
    private _router: Router,
    protected _asiakasService: AsiakasService,
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _dateService: DateService,
    private _changeDetector: ChangeDetectorRef,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _codeCheckService: CodeCheckService
  ) {
    super(_currencyService, _veroilmoitusTranslationService, _changeDetector, _asiakasService)
    this._oyService = new VeroilmoitusLaskenta2020OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
  }

  nayta178Radio: boolean = false
  nayta260Teksti: boolean = false
  nayta332tai333Teksti: boolean = false
  nayta337tai338Teksti: boolean = false
  nayta439Teksti: boolean = false
  nayta261Teksti: boolean = false
  nayta372tai373Teksti: boolean = false
  nayta436Teksti: boolean = false
  nayta270tai271Teksti: boolean = false
  nayta402Teksti: boolean = false
  nayta405Teksti: boolean = false
  nayta424Teksti: boolean = false
  nayta430Teksti: boolean = false
  nayta431Teksti: boolean = false

  ngOnInit() {

    // Set editable values to form
    this.veroilmoitusObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(veroilmo => {

      this.veroilmoitus = veroilmo
      if (this.veroilmoitus) {

        this.asiakkaanNimi = this.veroilmoitus.perustiedot.asiakas.nimi
        this.osakasRepeatingMetadatas = this._oyService.parsiOsakkaidenMetadata(this.veroilmoitus.arvot)
        this.osakasHuoneistotRepeatingMetadatas = this._oyService.parsiOsakasHuoneistojenMetadata(this.veroilmoitus.arvot)
        this.osingotRepeatingMetadatas = this._oyService.parsiOsinkojenMetadata(this.veroilmoitus.arvot)

        this.nayta178Radio = this._getRawValue('177') === '1'
        this.nayta260Teksti = this._hasValue('260')
        this.nayta332tai333Teksti = this._hasValue('332') || this._hasValue('333')
        this.nayta337tai338Teksti = this._hasValue('337') || this._hasValue('338')
        this.nayta439Teksti = this._hasValue('439')
        this.nayta261Teksti = this._hasValue('261')
        this.nayta372tai373Teksti = this._hasValue('372') || this._hasValue('373')
        this.nayta436Teksti = this._hasValue('436')
        this.nayta270tai271Teksti = this._hasValue('270') || this._hasValue('271')
        this.nayta402Teksti = this._hasValue('402')
        this.nayta405Teksti = this._hasValue('405')
        this.nayta424Teksti = this._hasValue('424')
        this.nayta430Teksti = this._hasValue('430')
        this.nayta431Teksti = this._hasValue('431')

        // Korvausparametrit are input for localization texts
        // const v342KorvausValue = this._getValue('v342Korvaus1', 'currency') || 0
        // this.v342Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v342KorvausValue, 'fi')
        // const v347KorvausValue = this._getValue('v347Korvaus1', 'currency') || 0
        // this.v347Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v347KorvausValue, 'fi')

      } else {
        this.asiakkaanNimi = ''
        this.nayta178Radio = false
        this.nayta260Teksti = false
        this.nayta332tai333Teksti = false
        this.nayta337tai338Teksti = false
        this.nayta439Teksti = false
        this.nayta261Teksti = false
        this.nayta372tai373Teksti = false
      }

      this._changeDetector.markForCheck()
    })

  }

  poistaHuoneisto(repeatingMetadata: RepeatingMetadata) {
    const maxOrder = this.osakasHuoneistotRepeatingMetadatas.length
    this.poistaRepeatingItem(repeatingMetadata, maxOrder, (tallennettavatArvot) => {
      tallennettavatArvot.arvot.push({ numero: 'huoneistot', arvo: maxOrder - 1 })
    }, '722', '724', '729', '730')
  }

  poistaOsinko(repeatingMetadata: RepeatingMetadata) {
    const maxOrder = this.osingotRepeatingMetadatas.length
    this.poistaRepeatingItem(repeatingMetadata, maxOrder, (tallennettavatArvot) => {
      tallennettavatArvot.arvot.push({ numero: 'osingot', arvo: maxOrder - 1 })
    }, '537', '538', '522', '293', '294', '295')
  }

  lisaaHuoneisto() {
    const nyt = this.osakasHuoneistotRepeatingMetadatas.length
    const tallennettava: TallennaArvoEvent = {
      numero: 'huoneistot',
      tyyppi: 'one',
      arvo: nyt + 1
    }
    this.tallennaArvo.next(tallennettava)
  }

  lisaaOsingot() {
    const nyt = this.osingotRepeatingMetadatas.length
    const tallennettava: TallennaArvoEvent = {
      numero: 'osingot',
      tyyppi: 'one',
      arvo: nyt + 1
    }
    this.tallennaArvo.next(tallennettava)
  }

  async avaaTuntemistiedotLomake() {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    this._router.navigate(['asiakkaat', asiakas.avain, 'tuntemistiedot'])
  }

  taytaPoistotEhdotus() {
    this.editComponent364_365.tallennaAnnetutArvot(true, true, this._annaEhdotus('364'), this._annaEhdotus('365'))
    this.editComponent800.tallennaAnnettuArvo(this._annaEhdotus('800'))
    this.editComponent801.tallennaAnnettuArvo(this._annaEhdotus('801'))
    this.editComponent802.tallennaAnnettuArvo(this._annaEhdotus('802'))
    this.editComponent804.tallennaAnnettuArvo(this._annaEhdotus('804'))
    this.editComponent806.tallennaAnnettuArvo(this._annaEhdotus('806'))
    this.editComponent807.tallennaAnnettuArvo(this._annaEhdotus('807'))
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
