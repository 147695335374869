<mat-dialog-content>

  <lemon-dialog-header>
    Jatkuvan tositteen summan muokkaus
  </lemon-dialog-header>

  <div class="tosite-tiedot">
    <form [formGroup]="form" novalidate style="margin: 0;">
      <mat-form-field>
        <mat-label>Summa</mat-label>
        <input decimalNumberField matInput [numberOfDecimals]="2" class="alignLeft" formControlName="summa">
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>