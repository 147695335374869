import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { YhteisomyyntilaskujenLatauspyynto, YhteisomyyntilaskujenLatausvastaus } from '../_jaettu/lemonator/model/tositteiden-lataaminen'

import * as jszip from 'jszip'
import { firstValueFrom } from 'rxjs'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface YhteisomyyntilaskujenLataaminenDialogData {
  vuosi: number
  kuukausi: number
}

@Component({
  templateUrl: './yhteisomyyntilaskujen-lataaminen.dialog.html'
})
export class YhteisomyyntilaskujenLataaminenDialog implements OnInit {

  tiedostojaYhteensa = 0
  tiedostojaLadattu = 0
  commonError = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: YhteisomyyntilaskujenLataaminenDialogData,
    private dialogRef: MatDialogRef<YhteisomyyntilaskujenLataaminenDialog>,
    private errorHandler: ErrorHandler,
    private httpClient: HttpClient,
    private _firebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    // this.ladataanService.aloitaLataaminen()
    const paakansio = 'Yhteisömyyntilaskut ' + this.data.vuosi + '.' + this.data.kuukausi
    const lataustiedot: YhteisomyyntilaskujenLatauspyynto = {
      kuukausi: this.data.kuukausi,
      vuosi: this.data.vuosi
    }

    this._firebase.functionsCall<YhteisomyyntilaskujenLatauspyynto, YhteisomyyntilaskujenLatausvastaus>('tositteidenLataaminenAnnaYhteisomyyntilaskujenLatausosoitteet', lataustiedot).then(vastaus => {
      this.tiedostojaYhteensa = vastaus.tiedot.length

      const zip = new jszip()
      const promises: Promise<void>[] = []
      for (const tiedosto of vastaus.tiedot) {
        if (tiedosto.puuttuu) {
          const virhe = '\n\tPDF ON KATEISSA! \nTOIMITA TÄMÄ TIEDOSTO YLLÄPIDOLLE!\n\n' + JSON.stringify(tiedosto.puuttuvanTiedot, null, 2)
          zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, virhe)
        } else if (tiedosto.mitatoity) {
          const virhe = '\n\tLASKU ON MITATOITY! \n\n' + JSON.stringify(tiedosto.puuttuvanTiedot, null, 2)
          zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, virhe)
        } else if (tiedosto.poistettu) {
          const virhe = '\n\tLASKU ON POISTETTU! \n\n' + JSON.stringify(tiedosto.puuttuvanTiedot, null, 2)
          zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, virhe)
        } else {
          const promise = firstValueFrom(this.httpClient.get(tiedosto.url, {
            responseType: 'arraybuffer'
          })).then(arraybuffer => {
            this.tiedostojaLadattu++
            zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, arraybuffer)
          })
          promises.push(promise)
        }

      }

      return Promise.all(promises).then(() => {
        return zip.generateAsync({ type: 'blob' })
      })

    }).then(content => {
      const filename = paakansio + '.zip'
      this._fileSaverService.saveAs(content, filename)
      this.dialogRef.close()
    }).catch(error => {
      this.commonError = true
      this.errorHandler.handleError(error)
    })

  }

}
