import { OnInit, Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core'




import { Subject } from 'rxjs'
import { Kirjausrivi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { NumberDate } from 'app/_shared-core/model/common'
import { ReskontraOpenMessage } from './reskontra/reskontra.component'


@Component({
  selector: '[app-kirjanpito-reskontra-triangle]',
  templateUrl: './kirjanpito-reskontra-triangle.component.html',
  styleUrls: ['./kirjanpito-reskontra-triangle.component.css']
})
export class KirjanpitoReskontraTriangleComponent implements OnInit, OnDestroy {

  @Input() kirjausAvain: string
  @Input() rivi: Kirjausrivi
  @Input() pvm: NumberDate

  @Output() triangleClicked: EventEmitter<boolean> = new EventEmitter()

  private _ngUnsubscribe = new Subject<void>()

  private _triangleClickedChannel: BroadcastChannel

  namename: string = 'afasdfasdfayuiopålkjhgf' + new Date().getTime()

  constructor() { }

  ngOnInit() {
    this._triangleClickedChannel = new BroadcastChannel('reskontra-kirjausrivi-clicked')
  }

  async onTriangleClick(event: Event) {
    event.stopPropagation()
    event.preventDefault()

    const message: ReskontraOpenMessage = {
      kirjausAvain: this.kirjausAvain,
      kirjausriviAvain: this.rivi.av,
      tili: this.rivi.t,
      pvm: this.pvm,
      reskontraTila: this.rivi.r
    }
    this.triangleClicked.emit(true)
    setTimeout(() => {
      this._triangleClickedChannel.postMessage(message)
    }, 50) // Wait for tab change to finish
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
