import { Component, OnInit, ErrorHandler, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { MatSnackBar } from '@angular/material/snack-bar'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { AsiakasService, AsiakkaanAvainTiedot } from '../../../_angular/service/asiakas/asiakas.service'

import { takeUntil } from 'rxjs/operators'
import { Subject, Observable, firstValueFrom } from 'rxjs'

import { HolviAnnaAsiakkaanTilitapahtumatRequest, HolviAnnaAsiakkaanTilitapahtumatResponse, HolviCustomerDataCompany, HolviCustomerDataUser, HolviGetAttachmentRequest, HolviGetAttachmentResponse, HolviTransaction } from '../../../_jaettu-sans-lemonaid-angular/model/holvi-backend'
import { DateService } from '../../../_shared-core/service/date.service'
import { MatTableDataSource } from '@angular/material/table'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatDialog } from '@angular/material/dialog'
import { HolviTransactionDetailsDialog } from './holvi-api-transaction-details.dialog'
import { MatSort } from '@angular/material/sort'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface HolviTili {
  iban: string
  handle: string
}

interface HolviTransactionForm {
  valittuTili: FormControl<HolviTili>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

interface HolviTransactionListauksessa {
  /** Same as payment_audit_identifier */
  id: string
  /** time as date */
  date: Date
  amount: number
  /** payer_payee */
  maksajaTaiSaaja: string
  liitteet: HolviLiite[]
}

interface HolviLiite {
  nimi: string
  avain: string
}
@Component({
  selector: '[app-holvi-transactions]',
  templateUrl: './holvi-api-query.component.html',
  styleUrls: ['./holvi-api-query.component.css'],
})
export class HolviApiQueryComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) _sort: MatSort

  asiakasAvainObservable: Observable<AsiakkaanAvainTiedot>
  holviCustomerData: HolviCustomerDataCompany
  holviUserData: HolviCustomerDataUser
  form: FormGroup<HolviTransactionForm>
  commonError: string
  tilit: HolviTili[]

  private _cachedTransactionsListauksessa: { [poolHandle: string]: HolviTransactionListauksessa[] } = {}
  private _transactionDetailsMap: Map<string, HolviTransaction> = new Map()

  dataSource = new MatTableDataSource<HolviTransactionListauksessa>([])
  naytettavatKolumnit: string[] = ['id', 'date', 'amount', 'maksajaTaiSaaja', 'liitteet']

  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _snackbar: MatSnackBar,
    private _dateService: DateService,
    private _ladataanService: LadataanService,
    private _matDialog: MatDialog,
    private _formValidationService: FormValidationService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this.asiakasAvainObservable = this._asiakasService.nykyinenAsiakasAvainObservable

    this.form = new FormGroup<HolviTransactionForm>({
      valittuTili: new FormControl<HolviTili>(null),
      alkaa: new FormControl<Date>(this._dateService.kuukaudenEnsimmainen(new Date()), Validators.required),
      loppuu: new FormControl<Date>(new Date(), Validators.required)
    })

    // this.asiakasAvainObservable.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(asiakas => {
    //   if (asiakas?.avain) {
    //     this._ladataanService.aloitaLataaminen()
    //     return this.fetchTransactions(asiakas.avain)
    //       .finally(() => this._ladataanService.lopetaLataaminen())
    //   }
    // })

    this.valittuTili.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittuTili => {
      if (!valittuTili) {
        const allTransactions: HolviTransactionListauksessa[] = []
        for (const tili of this.tilit) {
          for (const transaction of this._cachedTransactionsListauksessa[tili.handle]) {
            allTransactions.push(transaction)
          }
        }
        this.holviTransactionDataSource.data = allTransactions || []
        return
      }
      this.holviTransactionDataSource.data = this._cachedTransactionsListauksessa[valittuTili.handle]
    })

    // this.alkaa.valueChanges.pipe(
    //   withLatestFrom(this.asiakasAvainObservable),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(([alkaa, asiakas]) => {
    //   if (alkaa) {
    //     this._ladataanService.aloitaLataaminen()
    //     return this.fetchTransactions(asiakas.avain)
    //       .finally(() => this._ladataanService.lopetaLataaminen())
    //   }
    // })


    // this.loppuu.valueChanges.pipe(
    //   withLatestFrom(this.asiakasAvainObservable),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(([loppuu, asiakas]) => {
    //   if (loppuu) {
    //     this._ladataanService.aloitaLataaminen()
    //     return this.fetchTransactions(asiakas.avain)
    //       .finally(() => this._ladataanService.lopetaLataaminen())
    //   }
    // })

    this._sort.sort({
      disableClear: false,
      id: 'date',
      start: 'asc'
    })
    this.dataSource.sort = this._sort
  }

  get valittuTili() {
    return this.form.get('valittuTili') as FormControl<HolviTili>
  }

  get alkaa() {
    return this.form.get('alkaa') as FormControl<Date>
  }

  get loppuu() {
    return this.form.get('loppuu') as FormControl<Date>
  }

  async hae() {
    const asiakas = await firstValueFrom(this.asiakasAvainObservable)
    const alkaa = this._dateService.dateToNumber(this.alkaa.value)
    const loppuu = this._dateService.dateToNumber(this.loppuu.value)
    if (!alkaa || !loppuu || !asiakas?.avain) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    this._ladataanService.aloitaLataaminen()
    await this.fetchTransactions(asiakas.avain).finally(() => this._ladataanService.lopetaLataaminen())
  }

  async fetchTransactions(asiakasAvain: string) {

    const req: HolviAnnaAsiakkaanTilitapahtumatRequest = {
      asiakasAvain: asiakasAvain,
      start: this._dateService.dateToNumber(this.alkaa.value),
      end: this._dateService.dateToNumber(this.loppuu.value)
    }

    return this._firebase.functionsCall<HolviAnnaAsiakkaanTilitapahtumatRequest, HolviAnnaAsiakkaanTilitapahtumatResponse>('holviFetchCustomerTransactions', req).then(resp => {
      if (!resp || resp.e) {
        if (resp.e === 'no-tokens') {
          this.commonError = 'Holvi-yhteys puuttuu.'
        } else if (resp.e === 'no-pools') {
          this.commonError = 'Asiakkaalla ei ole tilejä.'
        } else {
          this._errorHandler.handleError(new Error('Holvin tilitapahtumien haku epäonnistui! ' + resp?.e || 'no-response'))
          this.commonError = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
        }
      } else {
        this.holviCustomerData = resp.company
        this.holviUserData = resp.user

        const poolToIbanMap: Map<string, string> = new Map()
        for (const paymentAccount of this.holviCustomerData?.payment_accounts ?? []) {
          if (paymentAccount.pool_handle && paymentAccount.iban) {
            poolToIbanMap.set(paymentAccount.pool_handle, paymentAccount.iban)
          }
        }

        this.tilit = Object.keys(resp.transactions || {}).map(handle => {
          const tili: HolviTili = {
            handle: handle,
            iban: poolToIbanMap.get(handle) ?? handle
          }
          return tili
        })

        if (this.valittuTili.value && this.tilit.find(tili => tili.handle === this.valittuTili.value.handle)) { // IBAN filter is active
          this.holviTransactionDataSource.data = resp.transactions[this.valittuTili.value.handle].map(tr => this._convertToListausData(tr))
          return
        }

        if (this.tilit.length) {
          const allTransactions: HolviTransactionListauksessa[] = []
          for (const tili of this.tilit) {
            for (const transaction of resp.transactions[tili.handle]) {
              const listauksessa = this._convertToListausData(transaction)
              allTransactions.push(listauksessa)

              if (!this._cachedTransactionsListauksessa[tili.handle]) {
                this._cachedTransactionsListauksessa[tili.handle] = []
              }
              this._cachedTransactionsListauksessa[tili.handle].push(listauksessa)

              this._transactionDetailsMap.set(transaction.payment_audit_identifier, transaction)
            }
          }
          this.holviTransactionDataSource.data = allTransactions || []
        }
      }
    })

  }

  get holviTransactionDataSource(): MatTableDataSource<HolviTransactionListauksessa> {
    return this.dataSource
  }

  naytaDetaileja(listauksessa: HolviTransactionListauksessa) {
    const detailit = this._transactionDetailsMap.get(listauksessa.id)
    this._matDialog.open(HolviTransactionDetailsDialog, { data: detailit })
  }

  async downloadAttachment(liite: HolviLiite, event: MouseEvent) {
    event?.preventDefault()
    event?.stopPropagation()


    const asiakasAvaimet = await firstValueFrom(this.asiakasAvainObservable)

    const req: HolviGetAttachmentRequest = {
      asiakasAvain: asiakasAvaimet.avain,
      attachmentId: liite.avain
    }

    this._ladataanService.aloitaLataaminen()

    return this._firebase.functionsCall<HolviGetAttachmentRequest, HolviGetAttachmentResponse>('holviGetTransactionAttachment', req).then(resp => {
      if (!resp || resp.e || !resp.base64File?.length) {
        if (resp.e === 'no-tokens') {
          this.commonError = 'Holvi-yhteys puuttuu.'
        } else {
          throw new Error('Holvin tilitapahtuman liitteen lataus epäonnistui! ' + (resp?.e || 'no-response'))
        }
      } else {
        if (resp.base64File) {
          // Presume that nameless attachments are PDFs (created by Holvi's system)
          const fileName = liite.nimi || (liite.avain + '.pdf')
          this._fileSaverService.saveBase64AsGuessedType(resp.base64File, fileName)
        }
      }
    }).catch(err => {
      this._errorHandler.handleError(err)
      this._snackbar.open('Liitteen lataus epäonnistui! Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000, verticalPosition: 'bottom' })
    }).finally(() => this._ladataanService.lopetaLataaminen())

  }

  private _convertToListausData(holviTransaction: HolviTransaction): HolviTransactionListauksessa {

    const liitteet: HolviLiite[] = []

    if (holviTransaction.debts?.length > 0) {
      for (const debt of holviTransaction.debts) {
        for (const attachment of debt.attachments) {
          liitteet.push({
            nimi: attachment.original_filename,
            avain: attachment.code
          })
        }
      }
    }

    return {
      id: holviTransaction.payment_audit_identifier,
      date: this._dateService.parsiPaivaIsoFormaatista(holviTransaction.time),
      amount: +holviTransaction.amount,
      maksajaTaiSaaja: holviTransaction.payer_payee,
      liitteet: liitteet
    }
  }

}
