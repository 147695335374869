import { Component, ElementRef, Input, ViewChild, ChangeDetectionStrategy, ErrorHandler, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { PdfConversionJobSettingsWasm, PdfService } from 'app/_jaettu-angular/service/pdf.service'
import { PDFiumPage } from '@lemontree/pdfium-wasm'
import { DEFAULT_IMAGE_ZOOM, IMAGE_ZOOM_CHANGE_STEP, MAX_IMAGE_ZOOM, MIN_IMAGE_ZOOM } from './image-viewer.component'
import { firstValueFrom } from 'rxjs'

@Component({
  selector: '[app-pdf-viewer]',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnInit, OnDestroy {

  @ViewChild('all', { static: true }) canvasContainer: ElementRef<HTMLElement>

  private _url: string
  private _pdfDoc: PdfConversionJobSettingsWasm = null
  private _pdfSivut: PDFiumPage[] = []
  private _zoomMultiplier: number = DEFAULT_IMAGE_ZOOM
  private _parseStartAt: number = 0
  // private _kuvienTargetWidth: number = 800

  // @Input() set kuvienTargetWidth(width: number) {
  //   // console.log('SET kuvienTargetWidth', this._kuvienTargetWidth, width)
  //   this._kuvienTargetWidth = width
  //   this.canvasContainer.nativeElement.style.width = this._kuvienTargetWidth + 'px'
  // }
  @Input()
  set zoomMultiplier(zoomMultiplier: number) {
    const old = this._zoomMultiplier
    this._zoomMultiplier = zoomMultiplier
    if (this._zoomMultiplier < MIN_IMAGE_ZOOM) { this._zoomMultiplier = MIN_IMAGE_ZOOM }
    if (this._zoomMultiplier > MAX_IMAGE_ZOOM) { this._zoomMultiplier = MAX_IMAGE_ZOOM }
    if (old !== this._zoomMultiplier) {
      this._deleteAll()
      this.pdfLoadComplete.next(false)
      // console.log('SET ZOOM MULTIPLIER', this._kuvienTargetWidth, this._url)
      this._render()
    }
  }
  get zoomMultiplier(): number {
    return this._zoomMultiplier
  }

  @Input()
  set url(url: string) {
    this._url = url
    if (this._url) {
      const startedAt = Date.now()
      this._parseStartAt = startedAt
      this._deleteAll()
      // console.log('PDF complete: set as false')
      this.pdfLoadComplete.next(false)
      // console.log('SET URL', this._kuvienTargetWidth, this._url)
      if (this._url.startsWith('data:')) {
        fetch(this._url).then(res => res.arrayBuffer()).then(arrayBuffer => {
          return this._handleArrayBuffer(arrayBuffer, startedAt)
        }).catch(err => {
          this._errorHandler.handleError(err)
        })
      } else {
        firstValueFrom(this._http.get(this._url, { responseType: 'arraybuffer' })).then(arrayBuffer => {
          return this._handleArrayBuffer(arrayBuffer, startedAt)
        }).catch(err => {
          this._errorHandler.handleError(err)
        })
      }
    }
  }
  get url(): string {
    return this._url
  }

  private _rotationDegrees: '0' | '90' | '180' | '270' = '0'
  @Input()
  public set rotateDegrees(degrees: '0' | '90' | '180' | '270') {
    this._deleteAll()
    // console.log('SET ROTATION DEGREES', degrees)
    this._rotationDegrees = degrees
    this._render()
  }

  @Output() pdfLoadComplete: EventEmitter<boolean> = new EventEmitter()

  constructor(
    private _http: HttpClient,
    private _pdfService: PdfService,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    // this.canvasContainer.nativeElement.style.width = this._kuvienTargetWidth + 'px'
  }

  ngOnDestroy() {
    this._deleteAll()
    this._tryCleanupResources()
  }

  private async _tryCleanupResources() {
    for (const sivu of this._pdfSivut) {
      try {
        await sivu.destroy()
      } catch (ignored) { }
    }
    if (this._pdfDoc) {
      try {
        await this._pdfDoc.document.destroy()
      } catch (ignored) { }
    }
  }

  private async _handleArrayBuffer(arrayBuffer: ArrayBuffer, startedAt: number): Promise<void> {
    await this._tryCleanupResources()
    if (startedAt !== this._parseStartAt) {
      // console.log('ABORT!')
      return
    }
    this._pdfSivut.length = 0
    const uint8Array = new Uint8Array(arrayBuffer)
    if (startedAt !== this._parseStartAt) {
      // console.log('ABORT!')
      return
    }
    this._pdfDoc = await this._pdfService.yritaParsiaPdf(uint8Array)
    const sivut: PDFiumPage[] = []
    for (const sivu of this._pdfDoc.document.pages()) {
      if (startedAt !== this._parseStartAt) {
        // console.log('ABORT!')
        return
      }
      sivut.push(sivu)
    }
    if (startedAt !== this._parseStartAt) {
      // console.log('ABORT!')
      return
    }
    this._pdfSivut = sivut
    this._render()
  }

  zoomOut() {
    this.zoomMultiplier -= IMAGE_ZOOM_CHANGE_STEP
    if (this.zoomMultiplier < MIN_IMAGE_ZOOM) { this.zoomMultiplier = MIN_IMAGE_ZOOM }
    this._deleteAll()
    this._render()
  }

  zoomIn() {
    this.zoomMultiplier += IMAGE_ZOOM_CHANGE_STEP
    if (this.zoomMultiplier > MAX_IMAGE_ZOOM) { this.zoomMultiplier = MAX_IMAGE_ZOOM }
    this._deleteAll()
    this._render()
  }

  private _deleteAll() {
    // Remove all old
    let child = this.canvasContainer.nativeElement.lastElementChild
    while (child) {
      this.canvasContainer.nativeElement.removeChild(child)
      child = this.canvasContainer.nativeElement.lastElementChild
    }
  }

  private async _render() {

    let setPdfLoadAsComplete = true

    for (const page of this._pdfSivut) {

      const viewportForCalculations = page.getSize()
      const containerWidth = this.canvasContainer.nativeElement.clientWidth
      const canvasWidth = containerWidth * this.zoomMultiplier
      const targetWidth = canvasWidth * window.devicePixelRatio

      const kerroin = targetWidth / viewportForCalculations.width
      // console.log(this._kuvienTargetWidth, this.zoomMultiplier, targetWidth, viewportForCalculations.width, viewportForCalculations.width / 1080, viewportForCalculations.height, viewportForCalculations.height / 1920)

      const rendered = page.render(kerroin)

      const canvas = document.createElement('canvas')
      canvas.style.width = canvasWidth + 'px'
      canvas.width = rendered.width
      canvas.height = rendered.height

      const imageData = new ImageData(new Uint8ClampedArray(rendered.bitmap), rendered.width, rendered.height)

      const ctx = canvas.getContext('2d')
      ctx.putImageData(imageData, 0, 0)

      this.canvasContainer.nativeElement.appendChild(canvas)

      if (setPdfLoadAsComplete) {
        setPdfLoadAsComplete = false
        this.pdfLoadComplete.next(true)
        // console.log('PDF complete: set as true')
      }

    }

    if (setPdfLoadAsComplete) {
      setPdfLoadAsComplete = false
      this.pdfLoadComplete.next(true)
      // console.log('PDF complete: set as true')
    }

  }

}
