import { Component, ErrorHandler, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'
import { VasenValikkoService } from 'app/_jaettu-angular/service/vasen-valikko.service'

import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { GoogleAuthProvider, signInWithCredential, signInWithPopup } from 'firebase/auth'

@Component({
  templateUrl: './loggedout.component.html',
  styleUrls: ['./loggedout.component.css']
})
export class LoggedOutComponent implements OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  commonError: string = null
  kirjautuminenKaynnissa: boolean = false

  constructor(
    private _router: Router,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _vasenValikkoService: VasenValikkoService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService
  ) {
    setTimeout(() => {
      this._vasenValikkoService.paivitaAuki(false)
    }, 5)
    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kirjanpitajanTiedot => {
      if (kirjanpitajanTiedot) {
        this._vasenValikkoService.paivitaAuki(true)
        this._router.navigate(['/'])
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  getYear(): string {
    return new Date().getFullYear() + ''
  }

  login() {
    // this._router.navigate(['/kirjaudu'])
    this._tryLogin()
  }

  private async _tryLogin() {
    this.commonError = null
    this.kirjautuminenKaynnissa = true
    this._ladataanService.aloitaLataaminen()
    try {

      this._kirjautunutKayttajaService.kirjautuminenOnKaynnissaSubject.next(true)

      console.log('Haetaan redirect resulttia.')

      // This gives you a Google Access Token. You can use it to access the Google API.
      // const result = await getRedirectResult(this._firebase.auth)
      // const credential = result ? GoogleAuthProvider.credentialFromResult(result) : null

      console.log('Ei Google autentikointia, aloitetaan alusta.')
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ 'hd': 'lemontree.fi' })
      const result = await signInWithPopup(this._firebase.auth, provider)
      const credential = result ? GoogleAuthProvider.credentialFromResult(result) : null

      console.log('Saatu vastaus.', result)
      console.log('Tarkistetaan onko jo credentiaaleja.')
      if (result && result.user && credential) {

        console.log('Googlekirjautuminen aloitetaan.')
        const [res1, res2] = await Promise.all([
          signInWithCredential(this._firebase.auth, credential),
          signInWithCredential(this._firebaseLemonaid.auth, credential)
        ])

        console.log('Googlekirjautuminen on valmis.')
        const lemonatorFuncResult = this._firebase.functionsCall<null, string>('kirjaudu', null)
        const lemonaidFuncResult = this._firebaseLemonaid.functionsCall<null, string>('lemonatorKirjaudu', null)

        console.log('Aloita callablet.')
        const [lemonatorResult, lemonaidResult] = await Promise.all([lemonatorFuncResult, lemonaidFuncResult])

        console.log('Callablet valmiit')
        // console.log(lemonatorResult.data, lemonaidResult.data)
        await Promise.all([
          this._firebase.authSignOut(),
          this._firebaseLemonaid.authSignOut()
        ])

        console.log('Uloskirjautuminen on valmis.')
        const [lemonatorCustomResult, lemonaidCustomResult] = await Promise.all([
          this._firebase.authSignInWithCustomToken(lemonatorResult),
          this._firebaseLemonaid.authSignInWithCustomToken(lemonaidResult)
        ])

        console.log('Kirjautuminen on valmis.')
        setTimeout(() => {
          // this.kirjautuminenKaynnissa = false
          this._ladataanService.lopetaLataaminen()
          // this._kirjautunutKayttajaService.kirjautuminenOnKaynnissaSubject.next(false)
          window.location.href = '/'
        }, 1000)

        // .then(lemonatorToken => {
        //   const payload: { idToken: string } = {
        //     idToken: lemonatorToken
        //   }
        //   const url = environment.environment === EnvironmentType.DEV ? '/api/1/autentikointi/alustaKeksiDev' : '/api/1/autentikointi/alustaKeksi'
        //   return this._httpService.postJsonGetJsonWithCredentials<{ status: 'success', expires: number }, { idToken: string }>(url, payload, LEMONATOR_HTTPS_API)
        // }).then(result => {
        //   if (result && result.expires) {
        //     this._cookieAuthReady.next(true)
        //   }
        // })


      } else {
        this._tryLogin()
      }

    } catch (error) {
      this.commonError = 'Kirjautumisen aikana tapahtui virhe. Ilmoita tästä ylläpidolle.'
      this._errorHandler.handleError(error)
      this.kirjautuminenKaynnissa = false
      this._ladataanService.lopetaLataaminen()
    }

  }

}
