<h2 style="margin-top: 30px; margin-bottom: 15px; margin-left: 10px;">Tilinpäätöksen rekisteröinti</h2>

<div *ngIf="tilikaudenLoppumisestaOnYli8KkObservable | async">
  Tilikauden vaihteesta on yli 8kk, eikä rekisteröintiä voi enää tehdä Lemonatorista rajapinnan kautta. Rekisteröinti on tehtävä käsin:
  <a href="https://www.prh.fi/fi/tilinpaatokset/yrityksen_tilinpaatos_myohassa.html" _target="_BLANK">
    https://www.prh.fi/fi/tilinpaatokset/yrityksen_tilinpaatos_myohassa.html
  </a>.
</div>

<form [formGroup]="form">
  <div style="margin: 20px;">
    <mat-checkbox *ngIf="tilinpaatosAllekirjoitettamattaObservable | async; else lataaTilinpaatos" formControlName="tilinpaatosPerinteisesti">Tilinpäätös allekirjoitetaan perinteisesti</mat-checkbox>
  </div>

  <div *ngIf="form.get('tilinpaatosPerinteisesti').value" style="margin: 20px 40px;">
    <div *ngIf="tpUploadError" style="margin: 20px 0;" class="error-text">{{tpUploadError}}</div>
    <div *ngIf="liitetiedotLuomattaObservable | async" style="margin: 20px 0;" class="error-text">Liitetiedot ovat luomatta. Täytä liitetiedot ennen lataamista.</div>
    <button mat-button (click)="lisaaTilinpaatos()" [disabled]="liitetiedotLuomattaObservable | async" type="button" class="secondarybutton">
      + Lataa muualla allekirjoitettu tilinpäätös
    </button>
  </div>

  <div style="margin: 20px;">
    <mat-checkbox *ngIf="poytakirjaAllekirjoitettamattaObservable | async; else lataaYhtiokokouksenPoytakirja" formControlName="poytakirjaPerinteisesti">Yhtiökokouksen pöytäkirja allekirjoitetaan perinteisesti</mat-checkbox>
  </div>

  <div *ngIf="form.get('poytakirjaPerinteisesti').value" style="margin: 20px 40px;">

    <div *ngIf="pkUploadError" style="margin: 20px 0;" class="error-text">{{pkUploadError}}</div>
    <div *ngIf="liitetiedotLuomattaObservable | async" style="margin: 20px 0;" class="error-text">Liitetiedot ovat luomatta. Täytä liitetiedot ennen lataamista.</div>
    <div *ngIf="tilinpaatosAllekirjoitettamattaObservable | async" style="margin: 20px 0;" class="error-text">Tilinpäätöstä ei ole vielä allekirjoitettu tai ladattu.</div>
    <div *ngIf="(tilintarkastetaanObservable | async) && (tilintarkastusKertomusLataamatta | async)" style="margin: 20px 0;" class="error-text">Tilintarkastuskertomusta ei ole vielä ladattu.</div>
    <button mat-button (click)="lisaaPoytakirja()" [disabled]="(liitetiedotLuomattaObservable | async) || (tilinpaatosAllekirjoitettamattaObservable | async) || ((tilintarkastetaanObservable | async) && (tilintarkastusKertomusLataamatta | async))" type="button" class="secondarybutton">
      + Lataa muualla allekirjoitettu yhtiökokouksen pöytäkirja
    </button>
    <span *ngIf="poytakirjaUploadedObservable | async" style="margin: 5px; font-size: 12px;">PDF ladattu</span>

    <div style="margin-top: 30px;">
      Täytä ladatusta pöytäkirjasta seuraavat tiedot:
      <div style="margin-left: 20px; margin-top: 10px;">

        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <mat-form-field style="max-width: 155px; margin-right: 10px;">
            <mat-label>Kokouksen aika</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="meetingDate" required />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div class="time-container">
            <mat-form-field style="width: 30px">
              <input #startHours timeFormat [timeType]="'hours'" class="time-input" maxlength="2" type="text" formControlName="meetingHours" matInput autocomplete="kirjnpitomekiarki" cdkFocusInitial />
            </mat-form-field>
            <div class="sep-colon">:</div>
            <mat-form-field style="width: 30px">
              <input timeFormat [timeType]="'minutes'" class="time-input" maxlength="2" type="text" formControlName="meetingMinutes" matInput autocomplete="kirjnpitomekiarki" />
            </mat-form-field>
          </div>
        </div>

        <mat-form-field style="max-width: 350px;">
          <mat-label>Yrityksen voitto koskeva yhtiökokouksen päätös</mat-label>
          <textarea type="text" formControlName="voittoPaatos" matInput data-lpignore="true" class="skip-space-quick-toggle" cdkTextareaAutosize></textarea>
        </mat-form-field>

        <table>
          <tr>
            <td style="height: 65px; vertical-align: middle;">
              <mat-checkbox formControlName="dividendsDistributed">Osinkoja jaetaan</mat-checkbox>
            </td>
            <td *ngIf="dividendsDistributed.value" style="height: 65px; vertical-align: middle;">
              <mat-form-field style="width: 180px; margin-left: 30px;">
                <mat-label>Osingot nostettavissa</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="dividendsPayableDate" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <button mat-button [disabled]="(liitetiedotLuomattaObservable | async) || !(poytakirjaUploadedObservable | async) || (tilinpaatosAllekirjoitettamattaObservable | async) || ((tilintarkastetaanObservable | async) && (tilintarkastusKertomusLataamatta | async))" class="primarybutton" (click)="savePoytakirjaData()" style="margin-top: 20px">Tallenna pöytäkirja ja tiedot</button>
  </div>

</form>

<ng-template #lataaTilinpaatos>
  <div *ngIf="tilinpaatosAllekirjoitettuJarjestelmanUlkopuolellaObservable | async else omassaAllekirjoitettuTp">
    <div *ngFor="let prev of previoslySentThirdPartyTilinpaatokset | async">
      <button mat-button type="button" style="padding: 0 2px; vertical-align: middle;" (click)="downloadTilinpaatosPdfWithVersionKey(prev.avain)">
        <div style="display: flex; flex-direction: row; align-items: center; padding: 0 5px;">
          <div class="pdf-btn">
            <div>PDF</div>
            <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
          </div>
          <div style="padding-left: 10px;">Allekirjoitettu tilinpäätös</div>
        </div>
      </button>
      (Ladannut {{prev.kirjanpitaja}} {{prev.ladattu}})
    </div>
  </div>
</ng-template>
<ng-template #omassaAllekirjoitettuTp>
  <div>
    <button mat-button type="button" style="padding: 0 2px;" (click)="downloadTilinpaatosPdfWithVersionKey()">
      <div style="display: flex; flex-direction: row; align-items: center; padding: 0 5px;">
        <div class="pdf-btn">
          <div>PDF</div>
          <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
        </div>
        <div style="padding-left: 10px;">Allekirjoitettu tilinpäätös</div>
      </div>
    </button>
  </div>
  <div *ngIf="showHallituksenPoytakirjaDownloadBtnObservable | async">
    <button mat-button type="button" style="padding: 0 2px;" (click)="downloadHallituksenPoytakirjaPdf()">
      <div style="display: flex; flex-direction: row; align-items: center; padding: 0 5px;">
        <div class="pdf-btn">
          <div>PDF</div>
          <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
        </div>
        <div style="padding-left: 10px;">Hallituksen pöytäkirja</div>
      </div>
    </button>
  </div>
</ng-template>

<ng-template #lataaYhtiokokouksenPoytakirja>
  <div style="margin: 20px 0;">
    <div>
      <button mat-button type="button" style="padding: 0 2px;" (click)="downloadYhtiokokousPdf()">
        <div style="display: flex; flex-direction: row; align-items: center; padding: 0 5px;">
          <div class="pdf-btn">
            <div>PDF</div>
            <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
          </div>
          <div style="padding-left: 10px;">Yhtiökokouksen pöytäkirja</div>
        </div>
      </button>

    </div>
    <div style="margin-top: 20px">
      <button mat-button type="button" style="padding: 0 2px;" (click)="downloadTilinpaatosilmoitusPdf()">
        <div style="display: flex; flex-direction: row; align-items: center; padding: 0 5px;">
          <div class="pdf-btn">
            <div>PDF</div>
            <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
          </div>
          <div style="padding-left: 10px;">Tilinpäätösilmoitus PRH:lle</div>
        </div>
      </button>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="tilintarkastetaanObservable | async">
  <ng-container *ngIf="tilintarkastustiedotObservable | async; let tiedot else loading">

    <h2 class="otsikko" style="margin-top: 30px; margin-bottom: 15px; margin-left: 10px;">
      Tilintarkastuskertomus
      <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Lataa tilintarkastuskertomus tähän saatuasi sen tilintarkastajalta.')">
        <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
      </button>
    </h2>

    <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px; padding-left: 20px;">
      <h4 style="margin: 0;">Urit</h4>
      <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Tilintarkastus: käyttäjän tallentamat tiedot: <a [href]="tilintarkastustiedotEncodedUriObservable | async" target="_BLANK">{{tilintarkastustiedotUriObservable | async}}</a></div>
    </div>

    <div *ngIf="tilinpaatosAllekirjoitettamattaObservable | async" style="margin: 20px;" class="error-text">Tilinpäätös on allekirjoitettava Lemonaidissa tai ladattava ennen kertomusta.</div>

    <div style="padding: 0 20px;">
      <table *ngIf="tiedot.kertomus">
        <thead>
          <tr>
            <th>Tiedosto</th>
            <th>Lataa</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{tiedot.kertomus.nimi}}</td>
            <td>
              <button class="lataa-pdf-button" type="button" (click)="downloadFile(tiedot.kertomus)">
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button *ngIf="!tiedot.kertomus" class="secondarybutton" mat-button (click)="lisaaTilintarkastuskertomus()" [disabled]="tilinpaatosAllekirjoitettamattaObservable | async" type="button">
        {{ tiedot.kertomus ? 'Päivitä tilintarkastuskertomus' : '+ Lisää tilintarkastuskertomus' }}
      </button>
    </div>

  </ng-container>
</ng-container>

<div *ngIf="veroilmoitusNotificationObservable | async as veroilmoitusNotification" style="padding: 15px; font-weight: bold;">
  <div *ngIf="veroilmoitusNotification === 'has-not-been-reported-lemonator-added-resend'">
    <i class="fa fa-exclamation-triangle" style="color: #CE6A33; font-size: 1.5em;"></i> Lemonator on täyttänyt osingonjakotiedot yhtiökokouksen pöytäkirjalta veroilmoitukselle. Tarkista veroilmoitus ja lähetä se uudelleen.
  </div>
  <div *ngIf="veroilmoitusNotification === 'has-been-reported-poytakirja-says-not-check-correctness'">
    <i class="fa fa-exclamation-triangle" style="color: #CE6A33; font-size: 1.5em;"></i> Lemonator havaitsi, että veroilmoituksella on ilmoitettu jaettuja osinkoja, mutta yhtiökokouksen pöytäkirjan mukaan osinkoja ei jaeta.
    Tarkista veroilmoitus ja lähetä se tarvittaessa korjattuna uudelleen.
  </div>
  <div *ngIf="veroilmoitusNotification === 'has-been-reported-poytakirja-says-yes-check-correctness'">
    <i class="fa fa-exclamation-triangle" style="color: #CE6A33; font-size: 1.5em;"></i> Lemonator havaitsi, että veroilmoituksella on ilmoitettu jaettuja osinkoja. Tarkista, että määrä ja päiväys täsmäävät yhtiökokouksen pöytäkirjan kanssa.
    Lähetä veroilmoitus tarvittaessa korjattuna uudelleen.
  </div>
  <!-- has-not-been-reported-lemonator-did-not-add-noop -->
</div>

<div *ngif="!(rekisteroityObservable | async)" style="margin-top: 60px;">&nbsp;</div>

<div *ngIf="tilinpaatosAllekirjoitettamattaObservable | async" style="font-style: italic; margin: 20px; text-align: center;">Tilinpäätöstä ei ole vielä allekirjoitettu</div>
<div *ngIf="(tilintarkastetaanObservable | async) && (tilintarkastusKertomusLataamatta | async)" style="font-style: italic; margin: 20px; text-align: center;">Tilintarkastuskertomusta ei ole vielä ladattu</div>
<div *ngIf="yhtionkokouksenPoytakirjaAllekirjoitettamattaObservable | async" style="font-style: italic; margin: 20px; text-align: center;">Yhtiökokouksen pöytäkirjaa ei ole vielä allekirjoitettu</div>
<div style="text-align: center;" *ngIf="!(sendDisabledObservable | async) && !(rekisteroityObservable | async)">Lähetä tilinpäätös rekisteröitäväksi PRH:lle. Lähettämistä ei voi perua.</div>
<div class="error-text" *ngIf="errorTextSubject | async as errorText">{{errorText}}</div>

<div *ngIf="rekisteroityObservable | async; let rekisteroityBy; else rekisteroiTemplate" style="padding: 60px 0;">
  <div style="text-align: center;">{{rekisteroityBy}}</div>
</div>
<ng-template #rekisteroiTemplate>
  <div style="text-align: center; padding: 0 20px;">
    <button mat-button class="primarybutton" [disabled]="sendDisabledObservable | async" (click)="tallenna()">Lähetä</button>
  </div>
</ng-template>

<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>

<input style="visibility:hidden; height: 1px; position: absolute; left: -9999px;" (change)="tpFileChanged($event)" type="file" accept="application/pdf" #fileInputTilinpaatos />
<input style="visibility:hidden; height: 1px; position: absolute; left: -9999px;" (change)="pkFileChanged($event)" type="file" accept="application/pdf" #fileInputPoytakirja />
<input style="visibility:hidden; height: 1px; position: absolute; left: -9999px;" (change)="kertomusFileChanged($event)" type="file" accept="application/pdf" #fileInputKertomus />