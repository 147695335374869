<h2 style="margin-top: 30px; margin-left: 10px;">Lähetä tilinpäätös allekirjoitettavaksi</h2>

<div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px; padding-left: 20px;">
  <h4 style="margin: 0;">Urit</h4>
  <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Lähetykset: <a [href]="tilinpaatosLahetyksetEncodedUriObservable | async" target="_BLANK">{{tilinpaatosLahetyksetUriObservable | async}}</a></div>
  <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Status: <a [href]="tilinpaatosStatusEncodedUriObservable | async" target="_BLANK">{{tilinpaatosStatusUriObservable | async}}</a></div>
</div>

<div *ngIf="lukittuObservable | async" style="padding: 3em; font-weight: bold;">
  Tilinpäätös on rekisteröity. Tietoja ei voi enää muokata.
</div>

<ng-container *ngIf="!(tilintarkastetaanObservable | async) else tilintarkastetaanTemplate">

  <ng-container *ngIf="customTilinpaatosLadattuObservable | async else laheta">
    <div style="padding: 20px;">Allekirjoitettu tilinpäätös on ladattu manuaalisesti Lemonaidiin. Lähetystä ei voi tehdä. Jos tämä on mielestäsi virhe, ota yhteys tekniseen tukeen.</div>
  </ng-container>

  <div style="padding: 30px 0;">
    <div class="laheta-tab-title" style="text-align: center; margin-top: 30px;">Aikaisemmin allekirjoitettavaksi lähetetyt tilinpäätökset</div>
    <ng-container *ngIf="sentThisTilikausiObservable | async; let aikaisemmatTilinpaatokset else eiAiemminLahetettyja">
      <table style="margin: 10px auto">
        <tr>
          <th>Lähetetty</th>
          <th>Lähettäjä</th>
          <!-- <th>Lataa siirtotiedosto</th>
        <th>Lataa PDF</th> -->
        </tr>
        <tr *ngFor="let paatos of aikaisemmatTilinpaatokset">
          <td style="padding: 0 10px 0 0;">{{paatos.luotuDateStr}}</td>
          <td style="padding: 0 10px;">{{paatos.nimi}}</td>
          <!-- <td style="padding: 0 10px;">
          <button mat-button class="secondarybutton" (click)="lataaTyviTiedosto(ilmo)">Lataa siirtotiedosto</button>
        </td>
        <td style="padding: 0 0 0 10px;">
          <button mat-button class="secondarybutton" (click)="downloadPdf(ilmo)">Lataa PDF</button>
        </td> -->
        </tr>
      </table>
    </ng-container>
  </div>
</ng-container>

<ng-template #laheta>
  <div style="padding: 20px;">Lähetä -nappula siirtää tilinpäätöksen asiakkaan Lemonaidiin allekirjoitettavaksi.</div>

  <p class="left-padded-text" *ngIf="!(taseErittelyObservable | async)">Tase-erittelyä ei ole luotu. Ole hyvä ja luo tase-erittely ennen lähettämistä.</p>
  <p class="left-padded-text" *ngIf="liitetiedotLuomattaObservable | async">Liitetietoja ei ole luotu. Ole hyvä ja luo liitetiedot ennen lähettämistä.</p>
  <p class="left-padded-text" *ngIf="merkittyAllekirjoitettavaksiMuuallaObservable | async">Liitetietojen mukaan tilinpäätös allekirjoitetaan perinteisesti. Lähetystä ei voi jatkaa.</p>

  <div class="error-text left-padded-text" *ngIf="errorTextSubject | async as errorText">{{errorText}}</div>
  <div class="error-text left-padded-text" *ngIf="hallitusEiKunnossaObservable | async">Yrityksen hallituksen tiedot puuttuvat. Tarkista tuntemistiedot.</div>

  <div style="padding: 30px 0;">
    <div style="text-align: center; padding: 0 20px;">
      <button class="primarybutton" mat-button [disabled]="sendDisabledObservable | async" (click)="lahetaAllekirjoitettavaksi()" type="button">Lähetä allekirjoitettavaksi</button>
    </div>
  </div>

  <ng-container *ngIf="tilinpaatosUsersObservable | async; let userData; else noKyc">
    <div style="padding: 0 20px 20px 20px;">
      <h4 style="margin: 0">Allekirjoittajat:</h4>
      <div style="margin: 0 10px 15px;">
        <table style="width: 100%;">

          <tr style="vertical-align: bottom;">
            <th style="text-align: left;"></th>
            <th style="text-align: left;"></th>
            <th>Edellytykset<br />allekirjoittaa</th>
            <th>Tilinpäätös</th>
            <th>Varsinainen<br />yhtiökokous</th>
          </tr>
          <tr>
            <th style="text-align: left;">Hallitus</th>
            <th colspan="4"></th>
          </tr>
          <tr *ngFor="let hallitusUser of userData.hallitus">
            <td>{{ hallitusUser.name }}<ng-container *ngIf="hallitusUser.link"> <a matTooltip="Avaa käyttäjän tiedot uudessa ikkunassa" style="margin-left: 5px; font-size: 120%;" target="_blank" [href]="hallitusUser.link"><span class="fa fa-external-link"></span></a></ng-container></td>
            <td>{{ hallitusUser.role || 'Ei roolia' }}</td>
            <td style="text-align: center; font-size: 120%;">
              <span *ngIf="hallitusUser.lemonaidUidConnected" class="green-text">
                <i class="fa fa-check"></i>
              </span>
              <span *ngIf="!hallitusUser.lemonaidUidConnected" style="cursor: pointer;" class="red-text" [matTooltip]="eiLemonaidKayttajaaText">
                X <i class="icon ion-ios-information-circle-outline"></i>
              </span>
            </td>
            <td style="text-align: center;">
              <i class="fa fa-circle"></i>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colspan="5">&nbsp;</td>
          </tr>
          <tr>
            <th style="text-align: left;">Osakkaat</th>
            <th colspan="4"></th>
          </tr>
          <tr *ngFor="let osakasUser of userData.osakkaat">
            <td>{{ osakasUser.name }}<ng-container *ngIf="osakasUser.link"> <a matTooltip="Avaa käyttäjän tiedot uudessa ikkunassa" style="margin-left: 5px; font-size: 120%;" target="_blank" [href]="osakasUser.link"><span class="fa fa-external-link"></span></a></ng-container></td>
            <td></td>
            <td style="text-align: center; font-size: 120%;">
              <span *ngIf="osakasUser.lemonaidUidConnected" class="green-text">
                <i class="fa fa-check"></i>
              </span>
              <span *ngIf="!osakasUser.lemonaidUidConnected" style="cursor: pointer;" class="red-text" [matTooltip]="eiLemonaidKayttajaaText">
                X <i class="icon ion-ios-information-circle-outline"></i>
              </span>
            </td>
            <td></td>
            <td style="text-align: center;">
              <i class="fa fa-circle"></i>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
  <ng-template #noKyc>
    <div class="left-padded-text">Asiakas tuntemistietoja ei ole tallennettu</div>
  </ng-template>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding: 20px;">
    <button class="primarybutton" mat-button [disabled]="sendDisabledObservable | async" (click)="resetPoytakirja()" type="button">Tyhjennä pöytäkirja (Lähtee ILMAN varmistusta)</button>
    <div>Aikasemmat resetit:</div>
    <ng-container *ngIf="resetHistoryObservable | async as resetHistory else loading">
      <ng-container *ngIf="resetHistory.length else eiHistoryja">
        <div *ngFor="let history of resetHistory">
          {{history.kirjanpitaja}} {{history.aika}}
        </div>
      </ng-container>
    </ng-container>
  </div>

</ng-template>

<ng-template #eiHistoryja>
  Pöytäkirjaa ei ole resetoitu kertaakaan.
</ng-template>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>

<ng-template #tilintarkastetaanTemplate>
  <div style="padding: 20px;">
    Tilintarkastettava tilinpäätös on allekirjoitettava perinteisesti ja ladattava takaisin Lemonatoriin "Rekisteröinti" -välilehdellä.
    <br /><br />
    Voit lähettää tilinpäätöksen asiakkaalle alla olevan sähköpostin liitteenä.
  </div>
</ng-template>

<div app-tilinpaatos-email-lahetys [tilintarkastetaanObservable]="tilintarkastetaanObservable" [voiLahettaaObservable]="tilinpaatossahkoopostinVoiLahettaaObservable" [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [lukittuObservable]="lukittuObservable"></div>

<ng-template #eiAiemminLahetettyja>
  <div style="text-align: center; padding: 25px">
    Yhtään tilinpäätöstä ei ole vielä lähetetty.
  </div>
</ng-template>