import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'

import { VasenValikkoService } from '../_jaettu-angular/service/vasen-valikko.service'

import { KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'

import { Subject, Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-asiakas-otsikko',
  templateUrl: './asiakas-otsikko.component.html',
  styleUrls: ['./asiakas-otsikko.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasOtsikkoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() private paluuUrl: string
  @Input() asiakas: Asiakas
  @Input() otsikko: string
  @Input() naytaPalaa: boolean = false

  vasenValikkoOnAuki: Observable<boolean>

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  // verotilinSaldo: number = null
  // verotilinSaldoFormatoitu: string = '&nbsp;'
  synkronoi: boolean = false
  naytaId: boolean = false

  constructor(
    private _router: Router,
    // private currencyService: CurrencyService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _vasenValikkoService: VasenValikkoService
  ) {
    this.vasenValikkoOnAuki = this._vasenValikkoService.globaaliAukiObservable
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {
    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kirjanpitaja => {
      this.naytaId = kirjanpitaja && (kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2')
    })
  }

  ngOnChanges() {
    if (this.asiakas) {
      // this.verotilinSaldo = this.asiakas.omaverosaldo ? this.asiakas.omaverosaldo.saldo : null
      // this.verotilinSaldoFormatoitu = this.verotilinSaldo === null ? '&nbsp;' : this.currencyService.formatoiRaha(this.verotilinSaldo, 'EUR', 'fi')
      this.synkronoi = this.asiakas.synkkaustyot ? Object.keys(this.asiakas.synkkaustyot).length > 0 : false
    } else {
      // this.verotilinSaldo = null
      // this.verotilinSaldoFormatoitu = '&nbsp;'
      this.synkronoi = false
    }
  }

  peruuta() {
    // console.log('navigate', this.paluuUrl)
    this._router.navigate([this.paluuUrl])
  }

  valitseTeksti(event: any) {
    const range = window.document.createRange()
    range.selectNode(event)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }

}
