import { Injectable, ErrorHandler } from '@angular/core'

import { LemonTranslationService } from '../../../_jaettu-angular/service/lemon-translation.service'

import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { DateService } from '../../../_shared-core/service/date.service'
import { TuettuKieli } from '../../../_shared-core/model/common'
import { TositeUriService } from '../../../_jaettu/service/tosite/tosite-uri.service'

import { FirestoreTosite } from '../../../_jaettu/model/tosite'

import { Observable, of } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { AsiakasService } from '../asiakas/asiakas.service'
import { FirebaseLemonaid } from '../firebase-lemonator.service'

@Injectable()
export class TositeService {

  constructor(
    private errorHandler: ErrorHandler,
    private _firebaseLemonaid: FirebaseLemonaid,
    private tositeUriService: TositeUriService,
    private dateService: DateService,
    private currencyService: CurrencyService,
    private translationService: LemonTranslationService,
    private asiakasService: AsiakasService
  ) {

  }

  annaKuittiObservable(id: string): Observable<FirestoreTosite> {
    return this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this.annaKuittiObservableAsiakkaalle(asiakas.asiakasId + '', id)
        }
        return of(null)
      })
    )
  }

  annaKuittiObservableAsiakkaalle(asiakasId: string, id: string): Observable<FirestoreTosite> {
    const kuittiUri = this.tositeUriService.annaKuitinFirestoreUri(asiakasId, id)
    return this._firebaseLemonaid.firestoreDoc<FirestoreTosite>(kuittiUri).listen().pipe(
      map(kuitti => {
        if (kuitti) {
          delete kuitti['haku']
        }
        return kuitti
      })
    )
  }

  annaPdfTiedostonNimiMyyntitositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.myynti.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiOstotositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.osto.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiPalkkatositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.palkka.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiTiliotetositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.tiliote.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  private annaPdfTiedostonNimiLadattaessa(nimi: string, naytaSumma: boolean, kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    if (kuitti && kuitti.localPvm) {
      nimi += ' - ' + this.dateService.muotoilePaikallinenPaiva(kuitti.localPvm, kieli)
    }

    if (kuitti && naytaSumma) {
      nimi += ' - ' + this.currencyService.formatoiRaha(kuitti.summa, 'EUR', kieli)
    }
    nimi += '.pdf'

    return nimi
  }

}
