<mat-dialog-content>

  <lemon-dialog-header>
    Haluatko varmasti poistaa kirjauksen?
  </lemon-dialog-header>

  <div style="text-align: center;">
    Kirjaus merkitään poistetuksi. Toimintoa ei voi perua.
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 0; padding-right: 0;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <span style="display: inline-block; width: 8vw; max-width: 4em;"></span>
  <button class="primarybutton" mat-button (click)="poista()">Poista</button>
</mat-dialog-actions>