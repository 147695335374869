<div class="page">
  <form [formGroup]="form">
    <div class="filter-bar">

      <div class="filter-bar-first-row">
        <div class="rounded-input" style="width: 15em; margin-right: 15px;">
          <mat-select formControlName="valittuTili">
            <mat-option *ngFor="let tili of reskontraTilitObservable | async" [value]="tili.numero + ' ' + tili.nimi">{{tili.numero + ' ' + tili.nimi}}</mat-option>
          </mat-select>
        </div>

        <div class="rounded-input">
          <input [name]="namename" [matDatepicker]="dp" formControlName="pvm">
          <button mat-icon-button (click)="dp.open()" style="width: 20px; height: 20px; line-height: 20px; padding: 0">
            <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 16px;">calendar_today</mat-icon>
          </button>
          <mat-datepicker #dp startView="month"></mat-datepicker>
        </div>
      </div>

      <div class="filter-bar-second-row">
        <mat-slide-toggle style="margin-right: 15px;" aria-label="Näytä vain avoimet" formControlName="vainAvoimet">Näytä vain avoimet</mat-slide-toggle>

        <button mat-button style="font-size: 11px; margin-top: -7px" (click)="downloadPdf()">
          <i class="fa fa-arrow-down"></i>
          <br />
          <div style="line-height: 2px; padding-bottom: 10px;">PDF</div>
        </button>
      </div>
    </div>

    <div class="grid-parent" style="margin-bottom: 5px;">
      <div style="text-align: right;">
        Debet
      </div>
      <div></div>
      <div>
        Kredit
      </div>
    </div>

    <!-- <div *ngIf="selectedVientiSubject.value && selectedVientiSubject.value.a?.length" class="grid-parent resk-group">
      <div class="empty-outline" [style.visibility]="selectedVientiSubject.value?.d === 'd' ? 'visible' : 'hidden'"></div>
      <div></div>
      <div class="empty-outline" [style.visibility]=" selectedVientiSubject.value?.d==='k' ? 'visible' : 'hidden'"></div>
    </div> -->

    <div class="resk-group" [class.resk-group-border]="group.displayGroupBorder" *ngFor="let group of renderableReskontraGroups">

      <div class="grid-parent">
        <div>
          <div *ngFor="let leftCol of group.leftColumnViennit" app-kirjanpito-reskontra-single-box (openKirjaus)="onOpenKirjaus($event)" [debugUrlStart]="debugUrlStart" [maksutapa]="maksutavatMap.get(leftCol.mt)" (setBoxHeight)="group.rightColumnViennit?.length" [vienti]="leftCol" [isSelected]="selectedVientiSubject.value?.av=== leftCol.av" (click)="vientiClicked(leftCol, group.order, $event)"
            [attr.data-vientiavain]="leftCol.av"></div>
          <!-- <div class="empty-outline" style="margin-top: 10px;" *ngIf="selectedVientiSubject.value && selectedVientiSubject.value?.d === 'd' && selectedVientiSubject.value.order !== row.order"></div> -->
        </div>

        <div class="icon-container">
          <ng-container *ngIf="!group.loading; else loading">
            <!-- Vienti is not selected-->
            <i class="fa fa-chain green-chain" *ngIf="group.difference === 0 && group.hasVientiOnBothSides && selectedVientiSubject.value?.order !== group.order"></i>
            <i class="fa fa-chain orange-chain" *ngIf="!selectedVientiSubject.value && group.hasVientiOnBothSides && !!group.difference && selectedVientiSubject.value?.order !== group.order"></i>
            <button mat-icon-button *ngIf="!selectedVientiSubject.value  && !group.hasVientiOnBothSides && selectedVientiSubject.value?.order !== group.order" (click)="autoselectVienti(group, $event)">
              <i class="fa fa-chain gray-chain"></i>
            </button>

            <!-- Vienti IS selected -->
            <!-- And it is on the current renderable group -->
            <i class="blue-arrow fa fa-arrow-right" *ngIf="selectedVientiSubject.value && !selectedVientiSubject.value.a.length  && selectedVientiSubject.value.d === 'd' && selectedVientiSubject.value.order === group.order"></i>
            <i class="blue-arrow fa fa-arrow-left" *ngIf="selectedVientiSubject.value && !selectedVientiSubject.value.a.length && selectedVientiSubject.value.d === 'k' && selectedVientiSubject.value.order === group.order"></i>
            <button mat-icon-button *ngIf="selectedVientiSubject.value && selectedVientiSubject.value.a.length > 0  && selectedVientiSubject.value.order === group.order" (click)="detach(group, $event)">
              <i class="fa fa-chain-broken blue-chain" matTooltip="Irroita vienti"></i>
            </button>
            <!-- But it is NOT the current renderable group (i.e. it's possible to connect to this row)-->
            <button mat-icon-button *ngIf="selectedVientiSubject.value && group.difference !== 0 && selectedVientiSubject.value.order !== group.order" (click)="attach(group, $event)">
              <i class="fa fa-chain blue-chain" matTooltip="Yhdistä vienti"></i>
            </button>
          </ng-container>

          <ng-template #loading>
            <i class="fa fa-spinner spinning"></i>
          </ng-template>


        </div>

        <div>
          <div *ngFor="let rightCol of group.rightColumnViennit" app-kirjanpito-reskontra-single-box (openKirjaus)="onOpenKirjaus($event)" [vienti]="rightCol" [debugUrlStart]="debugUrlStart" [maksutapa]="maksutavatMap.get(rightCol.mt)" (setBoxHeight)="group.leftColumnViennit?.length" [isSelected]="selectedVientiSubject.value?.av === rightCol.av"
            (click)="vientiClicked(rightCol, group.order, $event)" [attr.data-vientiavain]="rightCol.av"></div>
          <!-- <div class="empty-outline" style="margin-top: 10px;" *ngIf="selectedVientiSubject.value && selectedVientiSubject.value?.d === 'k' && selectedVientiSubject.value.order !== row.order"></div> -->
        </div>

      </div>

      <div class="grid-parent" *ngIf="group.hasVientiOnBothSides">
        <div class="left-total">
          <div *ngIf="group.difference < 0" class="dark-gray-text">Puuttuu {{ -1* group.difference | lmoneyl: null : 'fi'}}</div>
        </div>
        <div></div>

        <div class="right-total">
          <div *ngIf="group.difference > 0" class="dark-gray-text">Puuttuu {{ group.difference | lmoneyl: null : 'fi'}}</div>
        </div>

      </div>
    </div>


    <!-- <div *ngIf="selectedVientiSubject.value && selectedVientiSubject.value.a?.length" class="grid-parent resk-group">
      <div class="empty-outline" [style.visibility]="selectedVientiSubject.value?.d === 'd' ? 'visible' : 'hidden'"></div>
      <div></div>
      <div class="empty-outline" [style.visibility]=" selectedVientiSubject.value?.d==='k' ? 'visible' : 'hidden'"></div>
    </div> -->

    <div class="grid-parent acc-total">
      <div style="text-align: right;">
        <div>{{totalDebit| lmoneyl: null : 'fi'}}</div>
        <div *ngIf="totalDebit > totalKredit">Saldo: {{totalDebit - totalKredit| lmoneyl: null : 'fi'}}</div>
      </div>
      <div></div>
      <div>
        <div>{{totalKredit| lmoneyl: null : 'fi'}}</div>
        <div *ngIf="totalKredit > totalDebit">Saldo: {{totalKredit - totalDebit| lmoneyl: null : 'fi'}}</div>
      </div>
    </div>

    <div lemon-loading [show]="loading"></div>
    <!-- <div class="resk-row">
      <div class="left-col" style="text-align: right;">
        Debet
      </div>
      <div class="right-col">
        Kredit
      </div>
    </div>

    <div *ngFor="let row of renderableReskontraRowsObservable | async" class="resk-row">
      <div class="left-col" *ngIf="row.leftColumnVienti" app-kirjanpito-reskontra-single-box [vienti]="row.leftColumnVienti" [connections]="row.rightColumnViennit?.length ?? 1" [isSelected]="selectedVientiSubject.value?.av === row.leftColumnVienti.av" (click)="vientiClicked(row.leftColumnVienti)"></div>
      <div class="right-col">
        <div *ngFor="let rightCol of row.rightColumnViennit">
          <i *ngIf="row.leftColumnVienti.c" class="fa fa-arrows-h btn conn-icon" (click)="detach(row.leftColumnVienti, rightCol)"></i>
          <i *ngIf="!row.leftColumnVienti.c" class="fa fa-arrow-left btn conn-icon" (click)="attach(row.leftColumnVienti, rightCol)"></i>
          <div app-kirjanpito-reskontra-single-box [vienti]="rightCol" [connections]="0" [isSelected]="selectedVientiSubject.value?.av === rightCol.av" (click)="vientiClicked(rightCol)"></div>
        </div>
        <div class="empty-outline"></div>
      </div>

      <div class="right-col" *ngIf="row.unconnectedRight" app-kirjanpito-reskontra-single-box [vienti]="row.unconnectedRight" [connections]="0" [isSelected]="selectedVientiSubject.value?.av === row.unconnectedRight.av" (click)="vientiClicked(row.unconnectedRight)"></div>
    </div> -->
  </form>
</div>