import { Component, ElementRef, Input, ViewChild, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'

export const DEFAULT_IMAGE_ZOOM = 1
export const MAX_IMAGE_ZOOM = 3
export const MIN_IMAGE_ZOOM = 1
export const IMAGE_ZOOM_CHANGE_STEP = 0.25

/**
 * https://developer.apple.com/documentation/webkitjs/gestureevent
 */
interface GestureEvent extends Event {
  /**
   * The delta rotation since the start of an event, in degrees, where clockwise is positive and counter-clockwise is negative.
   */
  rotation: number
  /**
   * The distance between two fingers since the start of an event, as a multiplier of the initial distance.
   */
  scale: number

  clientX: number
  clientY: number
}
@Component({
  selector: '[app-image-viewer]',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent {

  @ViewChild('imageOriginal', { static: true }) imageOriginal: ElementRef<HTMLImageElement>
  @ViewChild('imageRotated', { static: true }) imageRotated: ElementRef<HTMLImageElement>
  @ViewChild('zoomContainer', { static: true }) zoomContainer: ElementRef<HTMLElement>

  @Output() imageLoadComplete: EventEmitter<boolean> = new EventEmitter()
  @Output() imageLoadError: EventEmitter<any> = new EventEmitter()

  private _url: string
  private _zoomMultiplier: number = DEFAULT_IMAGE_ZOOM

  private _rotateDegrees: '0' | '90' | '180' | '270' = '0'
  @Input()
  public set rotateDegrees(degrees: '0' | '90' | '180' | '270') {
    this._rotateDegrees = degrees
    this._render()
  }
  public get rotateDegrees(): '0' | '90' | '180' | '270' {
    return this._rotateDegrees
  }

  @Output() zoomMultiplierChange = new EventEmitter<number>()
  @Input()
  set zoomMultiplier(multiplier: number) {
    let temp = multiplier
    if (temp < MIN_IMAGE_ZOOM) { temp = MIN_IMAGE_ZOOM }
    if (temp > MAX_IMAGE_ZOOM) { temp = MAX_IMAGE_ZOOM }
    if (temp !== this._zoomMultiplier) {
      this._zoomMultiplier = temp
      this._updateImageZoom()
    }
  }
  get zoomMultiplier(): number {
    return this._zoomMultiplier
  }

  @Input()
  set url(url: string) {
    if (url !== this._url) {
      // console.log('SET URL', url, this.url)
      this.imageLoadComplete.next(false)
      this._url = url
    } else {
      this.imageLoadComplete.next(true)
    }
  }
  get url(): string {
    return this._url
  }

  @Input()
    parentScrollContainer: ElementRef<HTMLElement>

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  private _rotatedImageIsFor: string = null
  private _render() {
    this._updateImageZoom()
    if (this._rotateDegrees === '0' || this._rotateDegrees === '180') {
      this.imageRotated.nativeElement.style.display = 'none'
      this.imageOriginal.nativeElement.style.display = 'block'
      if (this._rotateDegrees === '180') {
        this.imageOriginal.nativeElement.style.transform = 'rotate(180deg)'
      } else {
        this.imageOriginal.nativeElement.style.transform = null
      }
    } else {
      if (this._rotatedImageIsFor !== this.url) {
        const canvas = this._rotateImage(this.imageOriginal.nativeElement, this._rotateDegrees)
        this.imageRotated.nativeElement.src = canvas.toDataURL('png', 100)
        this._rotatedImageIsFor = this.url
      }
      this.imageRotated.nativeElement.style.display = 'block'
      this.imageOriginal.nativeElement.style.display = 'none'
      if (this._rotateDegrees === '270') {
        this.imageRotated.nativeElement.style.transform = 'rotate(180deg)'
      } else {
        this.imageRotated.nativeElement.style.transform = null
      }
    }
    this._changeDetectorRef.markForCheck()
  }

  private _rotateImage(img: HTMLImageElement, degreeRotation: '0' | '90' | '180' | '270'): HTMLCanvasElement {

    const outputCanvas = document.createElement('canvas')
    if (degreeRotation === '0' || degreeRotation === '180') {
      outputCanvas.width = img.naturalWidth
      outputCanvas.height = img.naturalHeight
    } else {
      outputCanvas.height = img.naturalWidth
      outputCanvas.width = img.naturalHeight
    }

    const ctx = outputCanvas.getContext('2d')

    // Translate to the center point of our image
    ctx.translate(outputCanvas.width * 0.5, outputCanvas.height * 0.5)
    // Perform the rotation
    // rotate by 90 degrees (==PI/2)
    const radians = Number(degreeRotation) / 180 * Math.PI
    ctx.rotate(radians)
    // Translate back to the top left of our image
    ctx.translate(-img.naturalWidth * 0.5, -img.naturalHeight * 0.5)
    // Finally we draw the image
    ctx.drawImage(img, 0, 0)

    return outputCanvas

  }

  private _updateImageZoom() {
    const imageWidth = 100 * this._zoomMultiplier
    this.imageOriginal.nativeElement.style.width = imageWidth + '%'
    this.imageRotated.nativeElement.style.width = imageWidth + '%'
  }

  onImageLoaded() {
    // console.log('Image load complete', this._url)
    this._render()
    this.imageLoadComplete.next(true)
  }

  onImageError(error: any) {
    // console.log('Image load error', this._url, error)
    this.imageLoadError.next(error)
    this.imageLoadComplete.next(true)
  }

  private _zoomMultiplierOnGestureStart: number = 0
  private _rotationOnGestureStart: number = 0
  onGestureStart(event: Event) {
    this._zoomMultiplierOnGestureStart = this._zoomMultiplier
    if (this._rotateDegrees === '0' || this._rotateDegrees === '90') {
      this._rotationOnGestureStart = 0
    } else {
      this._rotationOnGestureStart = 180
    }
    this._preventEvent(event)
  }

  onGestureEnd(event: Event) {
    this._zoomMultiplierOnGestureStart = 0
    this._rotationOnGestureStart = 0
    const imgElement = this._annaImageElementti()
    // Snap back after rotation.
    if (this._rotateDegrees === '180' || this._rotateDegrees === '270') {
      imgElement.style.transform = 'rotate(180deg)'
    } else {
      imgElement.style.transform = null
    }
    imgElement.style['transform-origin'] = null
    this._preventEvent(event)
  }

  onGestureChange(eventa: Event) {
    const event: GestureEvent = eventa as GestureEvent
    const imgElement = this._annaImageElementti()
    const oldImageRect = imgElement.getBoundingClientRect()
    this._setZoom(event.scale * this._zoomMultiplierOnGestureStart)
    const newImageRect = imgElement.getBoundingClientRect()
    this._correctContainerScrolling(event.clientX, event.clientY, oldImageRect, newImageRect, imgElement)

    // console.log(event.clientX, imgElement.x, newImageRect.left, event.clientY, imgElement.y, newImageRect.top)

    if (this._rotateDegrees === '0' || this._rotateDegrees === '90') {
      imgElement.style.transform = 'rotate(' + (this._rotationOnGestureStart + event.rotation) + 'deg)'
      const cursorXRelativeToImage = event.clientX - imgElement.x
      const cursorYRelativeToImage = event.clientY - imgElement.y
      imgElement.style['transform-origin'] = cursorXRelativeToImage + 'px ' + cursorYRelativeToImage + 'px'
    } else {
      const cursorXRelativeToImage = event.clientX - imgElement.x
      const cursorYRelativeToImage = event.clientY - imgElement.y
      // const cursorYRelativeToImage = imgElement.y + imgElement.clientHeight - event.clientY
      // const cursorXRelativeToImage = imgElement.x + imgElement.clientWidth - event.clientX

      const translateX = 0 - (imgElement.clientWidth - cursorXRelativeToImage * 2)
      const translateY = 0 - (imgElement.clientHeight - cursorYRelativeToImage * 2)

      imgElement.style['transform-origin'] = cursorXRelativeToImage + 'px ' + cursorYRelativeToImage + 'px'
      imgElement.style.transform = 'rotate(' + (this._rotationOnGestureStart + event.rotation) + 'deg) translate(' + translateX + 'px, ' + translateY + 'px)'
    }

    this._preventEvent(event)
  }

  onWheel(event: WheelEvent) {
    if (event.ctrlKey) {
      const imgElement = this._annaImageElementti()
      const oldImageRect = imgElement.getBoundingClientRect()
      this._setZoom(this._zoomMultiplier - event.deltaY * 0.01)
      const newImageRect = imgElement.getBoundingClientRect()
      this._correctContainerScrolling(event.clientX, event.clientY, oldImageRect, newImageRect, imgElement)
      this._preventEvent(event)
    }
  }

  private _correctContainerScrolling(cursorX: number, cursorY: number, oldImageRect: DOMRect, newImageRect: DOMRect, img: HTMLImageElement) {

    const widthDifference = newImageRect.width - oldImageRect.width
    const heightDifference = newImageRect.height - oldImageRect.height

    const cursorXRelativeToImage = cursorX - oldImageRect.left
    const cursorYRelativeToImage = cursorY - oldImageRect.top
    const tempX = cursorXRelativeToImage / oldImageRect.width
    const tempY = cursorYRelativeToImage / oldImageRect.height
    const adjustWidth = tempX * widthDifference
    const adjustHeight = tempY * heightDifference

    const newScrollLeft = this.zoomContainer.nativeElement.scrollLeft + Math.round(adjustWidth)
    this.zoomContainer.nativeElement.scrollLeft = Math.max(newScrollLeft, 0)

    if (this.parentScrollContainer) {
      const newScrollTop = this.parentScrollContainer.nativeElement.scrollTop + Math.round(adjustHeight)
      this.parentScrollContainer.nativeElement.scrollTop = Math.max(newScrollTop, 0)
    }

  }

  private _setZoom(zoomCandidate: number) {
    if (zoomCandidate < MIN_IMAGE_ZOOM) { zoomCandidate = MIN_IMAGE_ZOOM }
    if (zoomCandidate > MAX_IMAGE_ZOOM) { zoomCandidate = MAX_IMAGE_ZOOM }
    if (zoomCandidate !== this._zoomMultiplier) {
      this._zoomMultiplier = zoomCandidate
      this.zoomMultiplierChange.emit(this._zoomMultiplier)
      this._updateImageZoom()
    }
  }

  private _preventEvent(event: Event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
  }

  private _annaImageElementti(): HTMLImageElement {
    if (this._rotateDegrees === '0' || this._rotateDegrees === '180') {
      return this.imageOriginal.nativeElement
    }
    return this.imageRotated.nativeElement
  }

}
