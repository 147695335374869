import { Component, OnInit, ErrorHandler, Input } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'

import { Ajopaivakirja } from '../../_jaettu/model/kayttaja'

import { Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

@Component({
  selector: '[app-ajopaivakirja-lemonator]',
  templateUrl: './ajopaivakirja-lemonator.component.html',
  styleUrls: ['./ajopaivakirja-lemonator.component.css']
})
export class AjopaivakirjaLemonatorComponent implements OnInit {

  @Input() asiakasAvain: string

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  commonError: string

  paasaantoinenKotiVahennys: string = '920'
  osaaikainenKotiVahennys: string = '460'
  satunnainenKotiVahennys: string = '230'

  namename = 'asdf ' + Math.random()
  private _saveStarted: boolean
  private _ajopaivakirja: Ajopaivakirja

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _copyService: KopioijaPalvelu,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _snackbar: MatSnackBar,
    private _translationService: LemonTranslationService,
    private _timestampService: TimestampService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnInit() {

    this.form = new UntypedFormGroup({
      'kokonaisKmMaara': new UntypedFormControl(null),
      'tyoAjoissaKm': new UntypedFormControl(null, this.tooLargeTyoAjoissaKmValidator),
      'tiedotPerustuvat': new UntypedFormControl({ value: null, disabled: true }),
      'yli10hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'yli6hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'ulkomaanMatkapaivienLukumaara': new UntypedFormControl(null),
      'ulkomaanPaivarahojenYhteismaara': new UntypedFormControl(null),
      'kotiYrityksenTyotilana': new UntypedFormControl(null),
    })

    this.kokonaisKmMaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value) {
        this.tiedotPerustuvat.enable()
      } else {
        this.tiedotPerustuvat.disable()
      }
      this._ajopaivakirja.kokonaisKmMaara = this._validationService.processValue(value)
    })
    this.tyoAjoissaKm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.tyoAjoissaKm = this._validationService.processValue(value) })
    this.tiedotPerustuvat.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this._ajopaivakirja.tiedotPerustuvat = this._validationService.processValue(value)
    })
    this.yli10hKotimaanMatkojenLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.yli10hKotimaanMatkojenLukumaara = this._validationService.processValue(value) })
    this.yli6hKotimaanMatkojenLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.yli6hKotimaanMatkojenLukumaara = this._validationService.processValue(value) })
    this.ulkomaanMatkapaivienLukumaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.ulkomaanMatkapaivienLukumaara = this._validationService.processValue(value) })
    this.ulkomaanPaivarahojenYhteismaara.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.ulkomaanPaivarahojenYhteismaara = this._validationService.processValue(value) })
    this.kotiYrityksenTyotilana.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this._ajopaivakirja.kotiYrityksenTyotilana = this._validationService.processValue(value) })

    this._firebaseLemonaid.firestoreDoc<Ajopaivakirja>('customers/' + this.asiakasAvain + '/customer-ajopaivakirja/' + this.asiakasAvain + '_2019').listen().pipe(
      tap(() => this._ladataanService.aloitaLataaminen()),
      map(apk => {
        if (apk) {
          return apk
        }
        const blankApk: Ajopaivakirja = this.createBlankApk()
        return blankApk
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(apk => {
      this._ladataanService.lopetaLataaminen()
      this._ajopaivakirja = this._copyService.cloneObjectDeep(apk)
      this.alustaLomakkeenTiedot(this._ajopaivakirja)
    }, error => {
      this._errorHandler.handleError(error)
    })
  }

  get kokonaisKmMaara() {
    return this.form.get('kokonaisKmMaara')
  }
  get tyoAjoissaKm() {
    return this.form.get('tyoAjoissaKm')
  }
  get tiedotPerustuvat() {
    return this.form.get('tiedotPerustuvat')
  }
  get yli10hKotimaanMatkojenLukumaara() {
    return this.form.get('yli10hKotimaanMatkojenLukumaara')
  }
  get yli6hKotimaanMatkojenLukumaara() {
    return this.form.get('yli6hKotimaanMatkojenLukumaara')
  }
  get ulkomaanMatkapaivienLukumaara() {
    return this.form.get('ulkomaanMatkapaivienLukumaara')
  }
  get ulkomaanPaivarahojenYhteismaara() {
    return this.form.get('ulkomaanPaivarahojenYhteismaara')
  }
  get kotiYrityksenTyotilana() {
    return this.form.get('kotiYrityksenTyotilana')
  }

  async tallenna() {
    if (this._saveStarted) {
      return
    }
    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    if (!this.asiakasAvain) {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      return
    }

    // Show loading indicator
    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {
      const batch = this._firebaseLemonaid.firestoreBatch()

      const now = this._timestampService.now()
      this._ajopaivakirja.luotu = this._ajopaivakirja.luotu || now
      this._ajopaivakirja.paivitetty = now

      const kirjanpitajaTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      this._ajopaivakirja.luoja = this._ajopaivakirja.luoja || kirjanpitajaTiedot.uid
      this._ajopaivakirja.paivittaja = kirjanpitajaTiedot.uid

      // Set disabled field as null
      if (this.tiedotPerustuvat.disabled) {
        this.tiedotPerustuvat.setValue(null)
        this._ajopaivakirja.tiedotPerustuvat = null
      }
      // Calculate according to kokonaismäärä and tyoajomäärä
      // this._ajopaivakirja.onkoTyoajoissaYliPuoli = this.laskeOnkoTyoajoissaYliPuoli(this._ajopaivakirja)
      // Save main doc
      batch.set('customers/' + this.asiakasAvain + '/customer-ajopaivakirja/' + this.asiakasAvain + '_2019', this._ajopaivakirja)

      // Create history entry
      const historyCopy = this._copyService.cloneObjectDeep(this._ajopaivakirja)
      const historyAvain = this._firebaseLemonaid.firestoreCreateId()
      historyCopy.avain = historyAvain
      batch.set('customers/' + this.asiakasAvain + '/customer-ajopaivakirja/' + this.asiakasAvain + '_2019' + '/customer-ajopaivakirja-history/' + historyAvain, historyCopy)

      // Commit batch
      await batch.commit()

      // All is good, let's advance
      this._snackbar.open('Tallennus onnistui', 'OK', { duration: 5000, verticalPosition: 'top' })
      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
    } catch (error) {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    }
  }

  private alustaLomakkeenTiedot(apk: Ajopaivakirja) {
    // // NB! Don't change order for these - timing issue with subscriptions
    this.tiedotPerustuvat.setValue(apk.tiedotPerustuvat)
    this.kokonaisKmMaara.setValue(apk.kokonaisKmMaara)

    this.tyoAjoissaKm.setValue(apk.tyoAjoissaKm)
    this.yli10hKotimaanMatkojenLukumaara.setValue(apk.yli10hKotimaanMatkojenLukumaara)
    this.yli6hKotimaanMatkojenLukumaara.setValue(apk.yli6hKotimaanMatkojenLukumaara)
    this.ulkomaanMatkapaivienLukumaara.setValue(apk.ulkomaanMatkapaivienLukumaara)
    this.ulkomaanPaivarahojenYhteismaara.setValue(apk.ulkomaanPaivarahojenYhteismaara)
    this.kotiYrityksenTyotilana.setValue(apk.kotiYrityksenTyotilana)
  }

  private createBlankApk(): Ajopaivakirja {
    const blank: Ajopaivakirja = {
      asiakasAvain: null,
      avain: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false,
      kokonaisKmMaara: null,
      tyoAjoissaKm: null,
      onkoTyoajoissaYliPuoli: null,
      tiedotPerustuvat: null,
      kotiYrityksenTyotilana: null,
      yli10hKotimaanMatkojenLukumaara: null,
      yli6hKotimaanMatkojenLukumaara: null,
      ulkomaanMatkapaivienLukumaara: null,
      ulkomaanPaivarahojenYhteismaara: null
    }
    return blank
  }
  private tooLargeTyoAjoissaKmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const tyoajoissaKm = control.value
      const kokonaisKm = this.kokonaisKmMaara.value
      if (!kokonaisKm || tyoajoissaKm > kokonaisKm) {
        return { toolarge: true }
      }
      return null
    }
    return null
  }
  // private laskeOnkoTyoajoissaYliPuoli(ajopaivakirja: Ajopaivakirja): boolean {
  //   // if (ajopaivakirja.kokonaisKmMaara) {
  //   //   if (ajopaivakirja.tyoAjoissaKm) {
  //   //     return ajopaivakirja.tyoAjoissaKm / ajopaivakirja.kokonaisKmMaara * 100 > 50
  //   //   }
  //   //   return false
  //   // }
  //   return null
  // }

}
