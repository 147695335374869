<div class="list-page">

  <div>
    <h1>Kirjanpitäjä</h1>
  </div>

  <div *ngIf="kirjanpitaja?.avain && kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px; margin-left: 20px;">
    <h3 style="margin: 0 0 1em;">Urit</h3>
    <div style="padding-left: 1em; padding-right: 1em;">
      <div>Kirjanpitäjä (Lemonator): <a [href]="kirjanpitajaEncodedUriObservable | async" target="_BLANK">{{kirjanpitajaUriObservable | async}}</a></div>
    </div>
  </div>

  <form (ngSubmit)="save()" [formGroup]="form" novalidate>

    <div style="max-width: 450px;">
      <mat-form-field>
        <mat-label>Etunimi</mat-label>
        <input #etunimiInput type="text" formControlName="etunimi" matInput required />
        <mat-error *ngIf="etunimi?.errors?.required && (etunimi?.dirty || etunimi?.touched)">
          Etunimi puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sukunimi</mat-label>
        <input type="text" formControlName="sukunimi" matInput required />
        <mat-error *ngIf="sukunimi?.errors?.required && (sukunimi?.dirty || sukunimi?.touched)">
          Sukunimi puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sähköpostiosoite</mat-label>
        <input type="text" formControlName="email" matInput required />
        <mat-error *ngIf="email?.errors?.required && (email?.dirty || email?.touched)">
          Sähköpostiosoite puuttuu
        </mat-error>
        <mat-error *ngIf="email?.errors?.email && (email?.dirty || email?.touched)">
          Sähköpostiosoite on virheellinen
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Puhelinnumero</mat-label>
        <input type="text" formControlName="puhelin" matInput />
        <mat-error *ngIf="puhelin?.errors?.email && (puhelin?.dirty || puhelin?.touched)">
          Puhelinnumero on virheellinen
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Aloitti</mat-label>
        <input type="text" [matDatepicker]="aloittiPicker" formControlName="aloitti" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="aloittiPicker"></mat-datepicker-toggle>
        <mat-datepicker #aloittiPicker startView="month"></mat-datepicker>
        <mat-error *ngIf="aloitti?.errors?.required && (aloitti?.dirty || aloitti?.touched)">
          Lisää sopimuksen aloitusaika
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Toimipiste</mat-label>
        <mat-select formControlName="toimipiste" required>
          <mat-option *ngFor="let piste of kaikkiToimipisteet" [value]="piste.avain">
            {{ piste.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div formArrayName="workHourSettings" style="max-width: 750px;">
      <div *ngFor="let workHourChange of getWorkHourSettings(); let i = index;" [formGroupName]="i">
        <div style="display: flex;">
          <mat-form-field style=" max-width: 120px; margin-right: 10px;">
            <mat-label>Työaikaprosentti</mat-label>
            <input [name]="namename" matInput decimalNumberField [numberOfDecimals]="2" formControlName="percentage" data-lpignore="true">
            <mat-error *ngIf="workHourChange.get('percentage')?.errors?.required && (workHourChange.get('percentage')?.dirty || workHourChange.get('percentage')?.touched)">
              Prosentti puuttuu.
            </mat-error>
            <mat-error *ngIf="workHourChange.get('percentage')?.errors?.min && (workHourChange.get('percentage')?.dirty || workHourChange.get('percentage')?.touched)">
              Prosentti pitää olla 0 ja 100 välillä.
            </mat-error>
            <mat-error *ngIf="workHourChange.get('percentage')?.errors?.max && (workHourChange.get('percentage')?.dirty || workHourChange.get('percentage')?.touched)">
              Prosentti pitää olla 0 ja 100 välillä.
            </mat-error>
          </mat-form-field>

          <mat-form-field style="max-width: 220px; margin-right: 10px;">
            <mat-label>Voimassa alkaen</mat-label>
            <input monthPicker matInput [name]="namename" type="text" [matDatepicker]="workHourPicker" formControlName="validFrom" data-lpignore="true" required />
            <mat-datepicker-toggle matSuffix [for]="workHourPicker"></mat-datepicker-toggle>
            <mat-error *ngIf="workHourChange.get('validFrom')?.errors?.required && (workHourChange.get('validFrom')?.dirty || workHourChange.get('validFrom')?.touched)">
              Kuukausi puuttuu
            </mat-error>
            <mat-error *ngIf="workHourChange.get('validFrom')?.errors?.edellinen && (workHourChange.get('validFrom')?.dirty || workHourChange.get('validFrom')?.touched)">
              Muutos ei voi olla samaan aikaan tai ennen edellistä muutosta.
            </mat-error>
            <mat-datepicker #workHourPicker></mat-datepicker>
          </mat-form-field>


          <button [disabled]="workHourChange.get('percentage')?.disabled || 1 > i" [class.mat-elevation-z4]="!workHourChange.get('percentage')?.disabled" mat-icon-button (click)="deleteWorkHourChange(workHourChange)" type="button" style="padding:0;" class="secondarybutton">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>

      <mat-error *ngIf="getWorkHourSettingsFormArray()?.errors?.min && (getWorkHourSettingsFormArray()?.dirty || getWorkHourSettingsFormArray()?.touched)" style="margin-left: 10px;">
        Ole hyvä ja syötä vähintään yksi.
      </mat-error>
    </div>

    <div style="padding-top: 20px; margin-left: 10px; padding-bottom: 1.25em;">
      <button mat-button (click)="addWorkHourChange()" type="button" class="secondarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        Lisää työajan muutos
      </button>
    </div>
  </form>


  <div *ngIf="commonError" class="error-text">
    {{commonError}}
  </div>

  <div style="max-width: 450px;">
    <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
      <button class="secondarybutton" mat-button (click)="peruuta()" type="button">{{'yleiset.peruuta' | translate | async}}</button>
      <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
    </div>
  </div>

  <div>Allekirjoitus, suomi:</div>
  <div #signature app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="kirjanpitaja" kieli="fi"></div>

  <div>Allekirjoitus, englanti:</div>
  <div #signature app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="kirjanpitaja" kieli="en"></div>

  <div class="drag-drop-area" style="max-width: 450px;">
    <ngx-file-drop #droppi dropZoneClassName="kuva-container" (onFileOver)="fileOver()" (onFileLeave)="fileLeave()" (onFileDrop)="fileDrop($event)" (click)="fileInput.click()" class="droppi" style="cursor: pointer;">
      <ng-template ngx-file-drop-content-tmp>
        <div style="padding: 3em;">Vedä kirjanpitäjän kuva tähän tai klikkaa</div>
      </ng-template>
    </ngx-file-drop>
  </div>
  <div class="error-box" *ngIf="kuvanVirhe">{{ kuvanVirhe }}</div>

  <mat-progress-bar *ngIf="donePercentageObservable" mode="determinate" [value]="donePercentageObservable | async"></mat-progress-bar>

</div>
<input #fileInput type="file" style="visibility: hidden;" accept="image/*" (change)="lataa($event)" />