import { Component, OnInit, OnDestroy, ViewChild, ErrorHandler } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { MatInput } from '@angular/material/input'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { Observable, Subject, combineLatest, distinctUntilChanged, firstValueFrom, map, of, switchMap, takeUntil } from 'rxjs'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { MatDialog } from '@angular/material/dialog'
import { ArkistoDialogData, ArkistoUploadDialog } from './arkisto-upload.dialog'
import { ArkistoDownloadFileRequest, ArkistoDownloadFileResponse, ArkistoFile, ArkistoService } from 'app/_jaettu-lemonator/service/arkisto.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface ArkistoFileDisplay extends ArkistoFile {
  lataajaName: string
  ladattuDateString: string
  canPreview: boolean
  previewOpen: boolean
  previewLoading: boolean
  // previewFileByteArray: Promise<Uint8Array>
  previewFileDataUrl: Promise<string>
  previewType: PreviewType
}

type PreviewType = 'pdf' | 'jpg' | 'png'

@Component({
  templateUrl: './arkisto.component.html',
  styleUrls: ['./arkisto.component.css']
})
export class ArkistoComponent implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  asiakasObservable: Observable<Asiakas>

  arkistoFilesObservable: Observable<ArkistoFileDisplay[]>

  // typeOptions: { name: string, id: ArkistoFileType }[] = this._arkistoService.getArkistoFileTypesWithNames()

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _dialog: MatDialog,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _arkistoService: ArkistoService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _dateService: DateService,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {
    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      distinctUntilChanged((a, b) => a?.avain === b?.avain)
    )

    this.arkistoFilesObservable = combineLatest([this.asiakasObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      switchMap(([asiakas, kirjanpitajienNimitiedotMap]) => {

        if (!asiakas) {
          of([])
        }

        return this._firebase.firestoreCollection<ArkistoFile>(this._arkistoService.getArkistoMetadataCollection(asiakas.avain))
          .where('poistettu', '==', false)
          .listen().pipe(
            map(filesMetadata => {
              if (!filesMetadata?.length) {
                return []
              }

              return filesMetadata.map(file => {
                const kirjanpitaja = kirjanpitajienNimitiedotMap.get(file.luoja)
                const type = this._getPreviewType(file)
                const displayMetadata: ArkistoFileDisplay = {
                  ...file,
                  lataajaName: kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi,
                  ladattuDateString: this._dateService.muotoilePaiva(file.luotu, 'fi'),
                  canPreview: !!type,
                  previewOpen: false,
                  previewLoading: false,
                  previewFileDataUrl: null,
                  previewType: type
                }
                return displayMetadata
              })
            })
          )
      })
    )
  }

  async openDialog() {
    const dialogData: ArkistoDialogData = {
      asiakas: await firstValueFrom(this.asiakasObservable)
    }
    this._dialog.open(ArkistoUploadDialog, { data: dialogData })
  }

  private _getPreviewType(file: ArkistoFile): PreviewType {
    const f = file.fileName.toLowerCase()
    if (f.endsWith('.pdf')) {
      return 'pdf'
    } else if (f.endsWith('.jpg') || f.endsWith('.jpeg')) {
      return 'jpg'
    } else if (f.endsWith('.png')) {
      return 'png'
    }
    return null
  }

  async stopFilePreview(file: ArkistoFileDisplay) {
    file.previewOpen = false
    file.previewLoading = false
  }

  async previewFile(file: ArkistoFileDisplay) {
    file.previewOpen = true
    file.previewLoading = true
    file.previewType = this._getPreviewType(file)
    const fileDownloadPromise: Promise<string> = this._downloadFileAsBase64EncodedString(file).then(str => {
      file.previewLoading = false
      if (file.previewType === 'pdf') {
        return 'data:application/pdf;base64,' + str
      } else if (file.previewType === 'png') {
        return 'data:image/png;base64,' + str
      } else if (file.previewType === 'jpg') {
        return 'data:image/jpeg;base64,' + str
      }
      throw new Error('Unknown previewType: ' + file.previewType)
    })
    file.previewFileDataUrl = fileDownloadPromise
  }

  private async _downloadFileAsBase64EncodedString(file: ArkistoFile): Promise<string> {
    const asiakas = await firstValueFrom(this.asiakasObservable)

    const req: ArkistoDownloadFileRequest = {
      asiakasAvain: asiakas.avain,
      metadata: file
    }

    const response = await this._firebase.functionsCall<ArkistoDownloadFileRequest, ArkistoDownloadFileResponse>('arkistoDownloadUploadedFile', req).then(
      resp => {
        if (!resp || resp.e) {
          throw new Error('Failed to fetch arkisto file ' + file.avain)
        }
        return resp
      }
    )

    console.log(response.base64File.substring(0, 20))

    return response.base64File
  }

  async downloadFile(file: ArkistoFile) {

    this._ladataanService.aloitaLataaminen()

    try {
      const response = await this._downloadFileAsBase64EncodedString(file)
      this._fileSaverService.saveBase64AsGuessedType(response, file.fileName)
    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open('Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 10 * 1000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async markAsDeleted(metadata: ArkistoFile) {

    const areYouSureTexts: AreYouSureDialogData = {
      header: 'Poista tiedosto',
      rightAction: 'Poista',
      text: 'Oletko varma, että haluat poistaa arkistoitun tiedoston?'
    }
    const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: areYouSureTexts }).afterClosed())

    if (!isSure) {
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      const uri = this._arkistoService.getArkistoMetadataUri(metadata.asiakasAvain, metadata.avain)
      const deleteData: Pick<ArkistoFile, 'poistettu'> = {
        poistettu: true
      }
      await this._firebase.firestoreUpdateData(uri, deleteData)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open('Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 10 * 1000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
