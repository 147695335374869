import { Component, ErrorHandler, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms'

import { LuoEnnakkoverolaskutPyynto } from '../../_jaettu-lemonator/model/integraatiot'
import { DateService } from 'app/_shared-core/service/date.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus, AsiakkaanNimitiedot, LahetettavaEnnakkoverolasku } from 'app/_jaettu-lemonator/model/asiakas'

import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

interface Listaustieto {
  asiakas: string
  lahetysdata: LahetettavaEnnakkoverolasku
}

@Component({
  templateUrl: './holvi.component.html',
  styleUrls: ['./holvi.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YllapitoHolviComponent implements OnInit {

  lahetettavatEnnakkoverolaskutObservable: Observable<Listaustieto[]>
  lahetettyJoEnnakkoverolaskutObservable: Observable<Listaustieto[]>
  eiLahetettavatEnnakkoverolaskutObservable: Observable<Listaustieto[]>
  historiaEnnakkoverolaskutObservable: Observable<Listaustieto[]>

  ennakkoverolaskujenLuontiform: UntypedFormGroup
  commonError = ''
  name: string = 'ayuaiseaysdf' + Math.random()

  constructor(
    private errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private timestampService: TimestampProviderBase,
    private dateService: DateService,
    private ladataanService: LadataanService,
    private formValidationService: FormValidationService
  ) {

    const asiakkaatMapPromise = this._firebase.functionsCall<AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus>('annaAsiakkaidenNimitiedot', {}, { timeout: 540 * 1000 }).then(
      asiakkaatVastaus => {
        const m: Map<string, AsiakkaanNimitiedot> = new Map()
        if (asiakkaatVastaus?.nimitiedot) {
          for (const a of asiakkaatVastaus.nimitiedot) {
            m.set(a.avain, a)
          }
        }
        return m
      }
    )

    const historiaObservable: Observable<LahetettavaEnnakkoverolasku[]> = this._firebaseLemonaid.firestoreCollectionGroup<LahetettavaEnnakkoverolasku>('customer-vero-ennakkoverolaskut')
      .where('lahettaminenKasitelty', '>', this.timestampService.dateToTimestamp(new Date(1980, 12, 12)))
      .orderBy('lahettaminenKasitelty', 'asc')
      .limit(2000).listen()

    this.historiaEnnakkoverolaskutObservable = combineLatest([asiakkaatMapPromise, historiaObservable]).pipe(
      map(([asiakkaatMap, lahetettavat]) => {
        if (!asiakkaatMap || !lahetettavat) {
          return []
        }
        return lahetettavat.map(lahetettava => {
          const asiakas = asiakkaatMap.get(lahetettava.asiakasAvain)
          const listaustieto: Listaustieto = {
            asiakas: asiakas ? asiakas.nimi + ' (' + asiakas.ytunnus + ')' : 'Ei tiedossa (' + lahetettava.asiakasAvain + ')',
            lahetysdata: lahetettava
          }
          return listaustieto
        })
      }),
      lemonShare()
    )

    const lahetettavatObservable: Observable<LahetettavaEnnakkoverolasku[]> = this._firebaseLemonaid.firestoreCollectionGroup<LahetettavaEnnakkoverolasku>('customer-vero-ennakkoverolaskut').where('lahettaminenKasitelty', '==', null).listen()

    const kaikkiMahdollisestiLahetettavatEnnakkoverolaskutObservable: Observable<Listaustieto[]> = combineLatest([asiakkaatMapPromise, lahetettavatObservable]).pipe(
      map(([asiakkaatMap, lahetettavat]) => {
        if (!asiakkaatMap || !lahetettavat) {
          return []
        }
        return lahetettavat.map(lahetettava => {
          const asiakas = asiakkaatMap.get(lahetettava.asiakasAvain)
          const listaustieto: Listaustieto = {
            asiakas: asiakas ? asiakas.nimi + ' (' + asiakas.ytunnus + ')' : 'Ei tiedossa (' + lahetettava.asiakasAvain + ')',
            lahetysdata: lahetettava
          }
          return listaustieto
        })
      }),
      lemonShare()
    )

    this.lahetettyJoEnnakkoverolaskutObservable = kaikkiMahdollisestiLahetettavatEnnakkoverolaskutObservable.pipe(
      map(kaikki => {
        return kaikki.filter(a => {
          return a.lahetysdata.tila === 'lahetetaan' && a.lahetysdata.lahetetty
        })
      })
    )

    this.lahetettavatEnnakkoverolaskutObservable = kaikkiMahdollisestiLahetettavatEnnakkoverolaskutObservable.pipe(
      map(kaikki => {
        return kaikki.filter(a => {
          return a.lahetysdata.tila === 'lahetetaan' && !a.lahetysdata.lahetetty
        })
      })
    )

    this.eiLahetettavatEnnakkoverolaskutObservable = kaikkiMahdollisestiLahetettavatEnnakkoverolaskutObservable.pipe(
      map(kaikki => {
        return kaikki.filter(a => a.lahetysdata.tila === 'ei_laheteta')
      })
    )

  }

  ngOnInit() {

    this.ennakkoverolaskujenLuontiform = new UntypedFormGroup({
      kuukausi: new UntypedFormControl(new Date(), [Validators.required])
    })

  }

  get kuukausi(): AbstractControl {
    return this.ennakkoverolaskujenLuontiform.get('kuukausi')
  }

  luoEnnakkoverolaskut() {

    if (!this.ennakkoverolaskujenLuontiform.valid) {
      this.formValidationService.merkitseKokoLomakeKosketuksi(this.ennakkoverolaskujenLuontiform)
      return
    }

    this.ladataanService.aloitaLataaminen()

    const date = this.dateService.dateToLocalDate(this.kuukausi.value)
    const kuukausi: LocalMonth = {
      year: date.year,
      month: date.month
    }
    const payload: LuoEnnakkoverolaskutPyynto = {
      kuukausi: kuukausi
    }

    this._firebase.functionsCall<LuoEnnakkoverolaskutPyynto, void>('integraatiotOmaveroLuoEnnakkoverolaskut', payload, { timeout: 540 * 1000 }).then(() => {
      this.ladataanService.lopetaLataaminen()
    }).catch(error => {
      this.errorHandler.handleError(error)
      this.ladataanService.lopetaLataaminen()
    })
  }

  lahetaEnnakkoverolaskut() {
    this.ladataanService.aloitaLataaminen()
    const payload = {}

    this._firebase.functionsCall<{}, void>('integraatiotOmaveroLahetaEnnakkoverolaskut', payload, { timeout: 540 * 1000 }).then(() => {
      this.ladataanService.lopetaLataaminen()
    }).catch(error => {
      this.errorHandler.handleError(error)
      this.ladataanService.lopetaLataaminen()
    })
  }

}
