<ng-container *ngIf="onkoYritysmuodolleJaVuodelleLomakkeistoObservable | async; else eiTuetaTaiEiVielaValmis">

  <div *ngIf="erottavatTilitObservable | async; let erottavatTilit">
    <div class="error-text" style="margin: 1em 1em 1em 0; font-size: 120%;">
      Kaikkia kirjanpidon saldoja ei pystytty siirtämään veroilmoitukselle. Seuraavien tilien saldot pitää syöttää käsin:
    </div>
    <table style="margin: 1em">
      <tr>
        <th style="min-width: 50px; text-align: left;">Tili</th>
        <th style="min-width: 300px; text-align: left;">Nimi</th>
        <th>Debet</th>
        <th>Kredit</th>
        <th>Erotus</th>
      </tr>
      <tr *ngFor="let tili of erottavatTilit">
        <td style="padding: 0 10px 0 0;">{{tili.tili.numero}}</td>
        <td style="padding: 0 10px;">{{tili.tili.nimi}}</td>
        <td style="padding: 0 10px; text-align: right;">{{tili.debit | lmoney | async}}</td>
        <td style="padding: 0 10px; text-align: right;">{{tili.kredit | lmoney | async}}</td>
        <td style="padding: 0 0 0 10px; text-align: right;">{{tili.debit - tili.kredit | lmoney | async}}</td>
      </tr>
    </table>
  </div>

  <div *ngIf="ilmoitinErrorsObservable | async; let errors">
    <div class="error-text" style="margin: 1em 1em 1em 0; font-size: 120%;">
      Veroilmoitusta ei voi lähettää seuraavien virheiden takia:
    </div>
    <ng-container *ngFor="let error of errors">
      <div>Kentät:
        <button mat-button *ngFor="let field of error.fields" (click)="avaaKentta(field)">{{field}}</button>
        <pre style="margin: .5em 0 2em 1em; font-size: 12px; line-height: 14px; white-space: pre-line;">{{error.message}}</pre>
      </div>
    </ng-container>
  </div>

  <!-- <button mat-button (click)="lataaDraftVeroilmoitus()" class="secondarybutton">
    Lataa nykyinen, lähettämätön veroilmoitus
  </button> -->

  <ng-container *ngIf="yhtiomuotoJaVuosiObservable | async; let yhtiomuotoJaVuosi">
    <ng-container *ngIf="yhtiomuotoJaVuosi.y === 't'">
      <div *ngIf="lukittuObservable | async" style="padding: 20px;" class="red-text">
        <div>Vuoden {{yhtiomuotoJaVuosi.v}} veroilmoitusten lähettäminen on päättynyt. Muutokset ilmoituksiin on hoidettava järjestelmän ulkopuolella tehtävällä oikaisuvaatimuksella.</div>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2020" app-kirjanpito-veroilmoitus-tmi-2020 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2021" app-kirjanpito-veroilmoitus-tmi-2021 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2022" app-kirjanpito-veroilmoitus-tmi-2022 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2023" app-kirjanpito-veroilmoitus-tmi-2023 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="yhtiomuotoJaVuosi.y === 'o'">
      <div *ngIf="lukittuObservable | async" style="padding: 20px;" class="red-text">
        <div>Vuoden {{yhtiomuotoJaVuosi.v}} veroilmoitusten lähettäminen on päättynyt. Muutokset ilmoituksiin on hoidettava järjestelmän ulkopuolella tehtävällä oikaisuvaatimuksella.</div>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2020" app-kirjanpito-veroilmoitus-oy-2020 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2021" app-kirjanpito-veroilmoitus-oy-2021 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2022" app-kirjanpito-veroilmoitus-oy-2022 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2023" app-kirjanpito-veroilmoitus-oy-2023 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
      <div *ngIf="yhtiomuotoJaVuosi.v === 2024" app-kirjanpito-veroilmoitus-oy-2024 (laskeUudelleen)="laskeUudelleen()" (tallennaArvo)="tallennaArvo($event)" [lukittu]="lukittuObservable | async" [veroilmoitusObservable]="veroilmoitusObservable">
        <ng-container *ngTemplateOutlet="laheta"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="naytaLataaIlmoitusObservable | async">
    <button mat-button (click)="lataaDraftVeroilmoitus()" class="secondarybutton" style="margin: 25px 0;">
      Lataa veroilmoituksen ilmoitin.fi siirtotiedosto (Sisältää tallentamattomat muutokset)
    </button>
    <button mat-button (click)="downloadUnsavedPdf()" class="secondarybutton" style="margin-bottom: 25px;">
      Lataa veroilmoitus PDF (Sisältää tallentamattomat muutokset)
    </button>
  </div>

</ng-container>

<ng-template #laheta>
  <ng-container *ngIf="!(lukittuObservable | async) else lukittuAikaisemminLahetetyt">
    <div style="text-align: center; padding-top: 40px;" class="laheta-tab-title">Lähetä veroilmoitus verohallinnolle</div>
    <div *ngIf="commonErrorObservable | async; let commonError" class="error-text" style="font-size: 120%; line-height: 120%; padding: 15px;">{{commonError}}</div>
    <div style="text-align: center; margin-bottom: 30px;"><button class="primarybutton" (click)="tallennaVeroilmoitus()" mat-button>Lähetä</button></div>
    <ng-container *ngTemplateOutlet="aikaisemminLahetetyt"></ng-container>
    <div app-veroilmoitus-lahetys [account9940DebetMinusKreditSumObservable]="account9940DebetMinusKreditSumObservable" [voiLahettaaObservable]="veroilmoitussahkoopostinVoiLahettaaObservable" [perustiedotObservable]="perustiedotObservable" [viimeisinLahetettyVeroilmoitusObservable]="viimeisinLahetettyVeroilmoitusObservable"></div>
  </ng-container>
</ng-template>

<ng-template #lukittuAikaisemminLahetetyt>
  <ng-container *ngTemplateOutlet="aikaisemminLahetetyt"></ng-container>
  <div app-veroilmoitus-aikaisemmin-lahetetyt-spostit [perustiedotObservable]="perustiedotObservable"></div>
</ng-template>

<ng-template #aikaisemminLahetetyt>
  <div style="padding-bottom: 30px;">
    <div class="laheta-tab-title" style="text-align: center;">Aikaisemmin lähetetyt veroilmoitukset</div>
    <ng-container *ngIf="aikaisemmatVeroilmoituksetObservable | async; let aikaisemmatVeroilmoitukset else lataaAiemminLahetettyja">
      <ng-container *ngIf="aikaisemmatVeroilmoitukset.lahetetyt.length > 0 else eiAiemminLahetettyja">
        <table style="margin: 0 auto">
          <tr>
            <th>Lähetetty</th>
            <th>Lähettäjä</th>
            <th>Lataa siirtotiedosto</th>
            <th>Lataa PDF</th>
            <th *ngIf="aikaisemmatVeroilmoitukset.devaaja">Data</th>
          </tr>
          <tr *ngFor="let ilmo of aikaisemmatVeroilmoitukset.lahetetyt">
            <td style="padding: 0 10px 0 0;">{{ilmo.lahetetty}}</td>
            <td style="padding: 0 10px;">{{ilmo.lahettaja}}</td>
            <td style="padding: 0 10px;">
              <button mat-button class="secondarybutton" (click)="lataaTyviTiedosto(ilmo)">Lataa siirtotiedosto</button>
            </td>
            <td style="padding: 0 0 0 10px;">
              <button mat-button class="secondarybutton" (click)="downloadPdf(ilmo)">Lataa PDF</button>
            </td>
            <td *ngIf="aikaisemmatVeroilmoitukset.devaaja">
              <a [href]="ilmo.dataLinkEncoded" target="_BLANK">Firestore</a>
            </td>
          </tr>
        </table>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #lataaAiemminLahetettyja>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
  </div>
</ng-template>

<ng-template #eiAiemminLahetettyja>
  <div style="text-align: center; padding: 25px">
    Tilikaudella ei ole vielä lähetetty yhtään veroilmoitusta.
  </div>
</ng-template>

<ng-template #eiTuetaTaiEiVielaValmis>
  <div *ngIf="onkoYhtiomuotoTuettuObservable | async; else yhtiomuotoaEiTueta" style="text-align: center; margin: 25px 0;">
    Tämän vuoden lomakkeistoa ei ole vielä tehty. Tarkista myöhemmin uudelleen.
  </div>
</ng-template>

<ng-template #yhtiomuotoaEiTueta>
  <div style="text-align: center;  margin: 25px 0;">
    Automaattista veroilmoitusta ei tueta tälle yhtiömuodolle, tee veroilmoitus manuaalisesti OmaVerossa.
  </div>
</ng-template>

<div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="margin: 25px 0;">

  <h3 style="margin: 0;">Urit</h3>
  <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Käyttäjän tallentamat tiedot: <a [href]="veroilmoitusEncodedUriObservable | async" target="_BLANK">{{veroilmoitusUriObservable | async}}</a></div>

  <div style="font-size: 80%; color: darkgray;">

    <h2 style="margin: 40px 0 10px 0;">Punakynä arvot</h2>
    <ng-container *ngFor="let entry of veroilmoitusPunakynaEntries">
      <div>{{ entry[0] }}: {{ entry[1] }}</div>
    </ng-container>

    <h2 style="margin: 40px 0 10px 0;">Ei tallennettu</h2>
    <ng-container *ngFor="let entry of veroilmoitusEiTallennettuEntries">
      <div>{{ entry[0] }}: {{ entry[1] | json }}</div>
    </ng-container>

    <h2 style="margin: 40px 0 10px 0;">Muokatut arvot</h2>
    <ng-container *ngFor="let entry of veroilmoitusEditableEntries">
      <div>{{ entry[0] }}: {{ entry[1] }}</div>
    </ng-container>

    <h2 style="margin: 40px 0 10px 0;">Lähetettävä veroilmoitus</h2>
    <ng-container *ngFor="let entry of veroilmoitusStaticEntries; let i = index">
      <div>{{ i }}: {{entry.key}}: {{entry.value}}</div>
    </ng-container>

    <h2 style="margin: 40px 0 10px 0;">Oletukset</h2>
    <ng-container *ngFor="let entry of veroilmoitusOletuksetEntries">
      <div>{{ entry[0] }}: {{ entry[1] }}</div>
    </ng-container>

    <!-- <h2 style="margin: 40px 0 10px 0;">Tilikauden tilien summat</h2>
    <div>Avain on muotoa
      <pre style="display: inline-block; background-color: bisque; padding: 0 5px;">'t' + tilinumero + k|d</pre>,
      jossa k = kredit, d = debit
    </div>

    <div *ngFor="let smt of tilikaudenSummatEntries">
      <div>{{smt.kuvaus}}</div>
      <ng-container *ngFor="let entry of smt.summat">
        {{ entry[0] }}: {{ entry[1] }},
      </ng-container>
    </div>

    <h2 style="margin: 40px 0 10px 0;">Kalenterivuoden tilien summat</h2>
    <ng-container *ngFor="let entry of kalenterivuodenSummatEntries">
      <div>{{ entry[0] }}: {{ entry[1] }}</div>
    </ng-container> -->
  </div>

</div>