import { Component, ErrorHandler, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'

import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusOsakkaatComponent } from './veroilmoitus-osakkaat.component'


export interface KirjanpitoVeroilmoitusOsakkaatDialogData {
  veroilmoitusObservable: Observable<VeroilmoituksenMuokkaustiedot>
}

@Component({
  templateUrl: './veroilmoitus-osakkaat.dialog.html',
  styleUrls: []
})
export class KirjanpitoVeroilmoitusOsakkaatDialog implements OnInit {

  @ViewChild('osakkaatComp') osakkaatComp: KirjanpitoVeroilmoitusOsakkaatComponent

  veroilmoitusObservable: KirjanpitoVeroilmoitusOsakkaatDialogData['veroilmoitusObservable']
  commonError: string
  success = false
  virheet: any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitoVeroilmoitusOsakkaatDialogData,
    private _dialogRef: MatDialogRef<KirjanpitoVeroilmoitusOsakkaatDialog>,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnInit(): void {
    this.veroilmoitusObservable = this._data.veroilmoitusObservable
  }

  async tallenna() {
    try {
      const result = await this.osakkaatComp.tallenna()

      if (result === 'failed') {
        throw new Error(result)
      }

      this._dialogRef.close()

    } catch (err: any) {
      this._errorHandler.handleError(new Error('Veroilmoituksen osakkaiden tallentaminen epäonnistui! ' + err))
    }
  }
}
