<div style="margin: 30px 0;">
  <div class="light-blue-text otsikko">Riskiarvio</div>

  <ng-container *ngFor="let historia of riskiarvioHistoriatObservable | async">
    <div class="cont info-box" style="margin-bottom: 10px;">
      <mat-radio-group style="max-width: 200px;" [value]="historia.riskiarvio" [disabled]="true">
        <mat-radio-button value="'vahainen'">Vähäinen riski</mat-radio-button>
        <mat-radio-button value="'kohonnut'">Kohonnut riski</mat-radio-button>
        <mat-radio-button value="'korkea'">Korkea riski</mat-radio-button>
      </mat-radio-group>
      <mat-form-field style="max-width: 330px;">
        <mat-label>Lisätietoja</mat-label>
        <textarea matInput [value]="historia.risikiarvioLisa" [disabled]="true" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </div>
    <div style="margin-bottom: 50px; font-size: 11px;">
      {{ historia.paivittajanNimi }} ({{ historia.paivitettyString }})
    </div>
  </ng-container>

  <form [formGroup]="form">

    <div *ngIf="tehdaanUusiRiskiarvio" class="cont info-box">
      <mat-radio-group style="max-width: 200px;" formControlName="riskiarvio" aria-label="Valitse">
        <mat-radio-button value="'vahainen'">Vähäinen riski</mat-radio-button>
        <mat-radio-button value="'kohonnut'">Kohonnut riski</mat-radio-button>
        <mat-radio-button value="'korkea'">Korkea riski</mat-radio-button>
      </mat-radio-group>
      <mat-form-field style="max-width: 330px;">
        <mat-label>Lisätietoja</mat-label>
        <textarea matInput formControlName="riskiarvioLisa" cdkTextareaAutosize></textarea>
      </mat-form-field>

      <ng-container *ngIf="riskiarvioEditActiveErrorsObservable | async; let riskiarvioEditActiveErrors">
        <div class="lemon-error">
          <div>Riskiarviota ei voida tehdä koska: </div>
          <ul>
            <li *ngIf="riskiarvioEditActiveErrors.edustajaTunnistamatta">Asiakkaan edustaja on tunnistamatta</li>
            <li *ngIf="riskiarvioEditActiveErrors.kuvausPuuttuu">Kuvaus asiakkaan toiminnasta puuttuu</li>
            <li *ngIf="riskiarvioEditActiveErrors.tuntemistiedotAntamatta">Asiakasyhtiön tuntemistiedot on antamatta</li>
          </ul>
        </div>
      </ng-container>
    </div>
  </form>

  <div class="button-container">
    <button [class]="tehdaanUusiRiskiarvio ? 'secondarybutton' : 'primarybutton'" (click)="triggerRiskiarvioEdit()" mat-flat-button>{{ tehdaanUusiRiskiarvio ? 'Peruuta' : 'Tee uusi riskiarvio' }}</button>
    <button *ngIf="tehdaanUusiRiskiarvio" class="primarybutton" style="margin-bottom: 10px;" (click)="tallenna()" mat-flat-button>Tallenna</button>
  </div>

</div>