import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { DomSanitizer } from '@angular/platform-browser'
import { filter, takeWhile } from 'rxjs'

import { TilinpaatosLiitetiedot, TilinpaatosLiitetiedotKentat } from '../../_jaettu-lemonator/model/kirjanpito'
import { InfoDialog, InfoDialogData } from '../../_jaettu-angular/_components/info.dialog'

export type DoneCallbackHandlerFunction = (outcome: 'no-save-needed' | 'error' | 'success', error?: Error) => void
export interface TallennaArvoEvent {
  field: TilinpaatosLiitetiedotKentat
  type: 'text' | 'number' | 'checkbox'
  doneCallback?: DoneCallbackHandlerFunction
}

@Component({
  selector: '[app-kirjanpito-liitetiedot-multi-input-checkbox]',
  templateUrl: './liitetiedot-multi-input-checkbox.component.html',
  styleUrls: ['./liitetiedot-multi-input-checkbox.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s')
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoTilinpaatosLiitetiedotMultiInputCheckboxComponent implements OnInit {

  @Output() tallennaArvo: EventEmitter<TallennaArvoEvent> = new EventEmitter<TallennaArvoEvent>()

  @ViewChildren(MatInput) kirjoitusKomponentit: QueryList<MatInput>

  @Output() checkboxChanged: EventEmitter<void> = new EventEmitter()

  @Input() order: number
  private _example: string
  rawExample: string
  get example(): string {
    return this._example
  }
  @Input() set example(value: string) {
    this.rawExample = value
    this._example = this._sanitizer.bypassSecurityTrustHtml(value) as string
  }
  @Input() info: string
  @Input() fields: { field: TilinpaatosLiitetiedotKentat, type: 'text' | 'number', example?: string }[]
  @Input() checkboxField: TilinpaatosLiitetiedotKentat
  @Input() parent: string
  @Input() unsaved: TilinpaatosLiitetiedot
  @Input() liitetiedot: TilinpaatosLiitetiedot
  @Input() checkboxDisabled: boolean = false
  @Input() formDisabled: boolean = false

  namename = 'feoklpsfvmuysdfg' + Math.random()
  saving: boolean = false
  saveSuccess: boolean = false
  saveFailure: boolean = false

  constructor(
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // console.log('Init', this.field)
  }

  avaaInfoTeksti(teksti: string) {
    const data: InfoDialogData = {
      text: teksti,
      header: ''
    }
    this._dialog.open<InfoDialog, InfoDialogData>(InfoDialog, { data: data })
  }

  checkboxChangedEventHandler(event: boolean) {
    for (const f of this.fields) {
      this.unsaved.valitut[f.field] = !!event
      this.tallennaMuuttunutArvoWithType('checkbox', f.field)
    }
    this.unsaved.valitut[this.checkboxField] = !!event
    this.tallennaMuuttunutArvoWithType('checkbox', this.checkboxField)
    this.checkboxChanged.next()
    if (event) {
      this.kirjoitusKomponentit.changes.pipe(
        takeWhile(() => this.kirjoitusKomponentit.length < 1, true),
        filter(() => this.kirjoitusKomponentit.length > 0)
      ).subscribe(() => {
        this.kirjoitusKomponentit.first.focus()
      })
    }
  }

  textChangedEventHandler(field: TilinpaatosLiitetiedotKentat, event: string) {
    this.unsaved.tekstit[field] = event
  }

  numberChangedEventHandler(field: TilinpaatosLiitetiedotKentat, event: number) {
    this.unsaved.numerot[field] = event
    this.checkboxChanged.next()
  }

  tallennaMuuttunutArvoWithType(type: 'text' | 'number' | 'checkbox', field: TilinpaatosLiitetiedotKentat) {
    this.saveFailure = false
    this.saveSuccess = false
    this.saving = true
    const tallennaEvent: TallennaArvoEvent = {
      field: field,
      type: type,
      doneCallback: (outcome) => {
        this.saving = false
        if (outcome === 'error') {
          this.saveFailure = true
          this.saveSuccess = false
        } else if (outcome === 'success') {
          this.saveFailure = false
          this.saveSuccess = true
        } else {
          this.saveFailure = false
          this.saveSuccess = false
        }
        this._changeDetectorRef.markForCheck()
        setTimeout(() => {
          this.saveSuccess = false
          this._changeDetectorRef.markForCheck()
          setTimeout(() => {
            this._changeDetectorRef.markForCheck()
            this._changeDetectorRef.detectChanges()
          }, 250)
        }, 1000)
      }
    }
    this.tallennaArvo.next(tallennaEvent)
  }

}
