<form [formGroup]="form" class="teksti apk">
  <div class="red-text common-error" *ngIf="commonError">{{ commonError | translate | async }}</div>

  <div class="apk-form-title">
    {{'ajopaivakirja.oma-ajoneuvon-kaytto' | translate | async}}
  </div>
  <mat-form-field>
    <mat-label>{{ 'ajopaivakirja.auton-kayton-km-maara' | translate | async }}</mat-label>
    <input decimalNumberField matInput [numberOfDecimals]="0" class="alignLeft" formControlName="kokonaisKmMaara">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ ('ajopaivakirja.auton-kaytto-tyoajoissa' | translate | async) + ' (km)' }}</mat-label>
    <input decimalNumberField matInput [numberOfDecimals]="0" class="alignLeft" formControlName="tyoAjoissaKm">
    <mat-error *ngIf="tyoAjoissaKm?.errors?.toolarge && (tyoAjoissaKm?.dirty || tyoAjoissaKm?.touched)">{{'ajopaivakirja.auton-kaytto-tyoajoissa-too-large-error' | translate | async}}</mat-error>
  </mat-form-field>
  <div [class.mat-form-field-invalid]="tiedotPerustuvat?.errors?.required" [style.color]="tiedotPerustuvat?.errors?.required && (tiedotPerustuvat?.dirty || tiedotPerustuvat?.touched) ? 'red' : 'black'" class="apk-radio-container" style="margin-top: 20px">
    <div [class.dark-gray-text]="!kokonaisKmMaara?.value" class="apk-radio-label">{{'ajopaivakirja.auton-tiedot-perustuvat' | translate | async}}<span *ngIf="kokonaisKmMaara?.value" aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
    </div>
    <mat-radio-group [required]="kokonaisKmMaara?.value" formControlName="tiedotPerustuvat" style="display: flex; flex-direction: column;">
      <mat-radio-button class="apk-radio-button" [value]="'ajopaivakirjaan'">{{'ajopaivakirja.ajopaivakirjaan' | translate | async}}</mat-radio-button>
      <mat-radio-button class="apk-radio-button" [value]="'muuhun selvitykseen'">{{'ajopaivakirja.muuhun-selvitykseen' | translate | async}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="apk-form-title">
    {{'ajopaivakirja.tyomatkat' | translate | async}}
  </div>
  <mat-form-field>
    <mat-label>{{ 'ajopaivakirja.yli-10h-lukumaara' | translate | async }}</mat-label>
    <input decimalNumberField matInput [numberOfDecimals]="0" class="alignLeft" formControlName="yli10hKotimaanMatkojenLukumaara">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'ajopaivakirja.yli-6h-lukumaara' | translate | async }}</mat-label>
    <input decimalNumberField matInput [numberOfDecimals]="0" class="alignLeft" formControlName="yli6hKotimaanMatkojenLukumaara">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'ajopaivakirja.ulkomaan-lukumaara' | translate | async }}</mat-label>
    <input decimalNumberField matInput [numberOfDecimals]="0" class="alignLeft" formControlName="ulkomaanMatkapaivienLukumaara">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'ajopaivakirja.ulkomaan-paivarahojen-yhteismaara' | translate | async }}</mat-label>
    <input decimalNumberField matInput formControlName="ulkomaanPaivarahojenYhteismaara" class="alignLeft" [numberOfDecimals]="0">
  </mat-form-field>
  <div class="apk-form-title">
    {{'ajopaivakirja.tyohuonevahennys' | translate | async}}
  </div>
  <div>
    <mat-radio-group style="display: flex; flex-direction: column;" formControlName="kotiYrityksenTyotilana">
      <mat-radio-button [value]="'ei-vahennysta'">
        <div style="font-size: 14px; white-space: pre-wrap;">{{'ajopaivakirja.ei-tyohuonevahennysta' | translate | async}}</div>
      </mat-radio-button>
      <mat-radio-button [value]="'paasaantoinen'">
        <div style="white-space: pre-wrap;">{{'ajopaivakirja.kotia-paasaantoisesti' | translate: { vahennys: paasaantoinenKotiVahennys } | async}}</div>
      </mat-radio-button>
      <mat-radio-button [value]="'osa-aikainen'">
        <div style="white-space: pre-wrap;">{{'ajopaivakirja.kotia-osa-aikaisesti' | translate: { vahennys: osaaikainenKotiVahennys } | async}}</div>
      </mat-radio-button>
      <mat-radio-button [value]="'satunnainen'">
        <div style="white-space: pre-wrap;">{{'ajopaivakirja.kotia-satunnaisesti' | translate: { vahennys: satunnainenKotiVahennys } | async}}</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div style="margin-top: 30px;">
    <div>
      <button class="primarybutton" type="button" mat-button (click)="tallenna()">Tallenna</button>
    </div>
  </div>
</form>