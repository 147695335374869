
import { Component, OnInit, Inject, ErrorHandler, ViewChild, HostListener, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators, FormControl } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Kirjanpitotili, AlvMaaritys, OstoAlvt, MyyntiAlvt, LokalisoituKirjanpitotilinAlvTyyppi, LokalisoidutAlvtyypit, KirjanpitotilinAlvTyyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'

import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { takeUntil } from 'rxjs/operators'
import { Subject, firstValueFrom } from 'rxjs'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'

export interface AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData {
  tili: Kirjanpitotili
  asiakas: Asiakas
  lisaa: boolean
}

@Component({
  templateUrl: './kirjanpitotili-muokkaa-paatilikartan-tilia.dialog.html'
})
export class AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog implements OnInit, OnDestroy {

  @ViewChild('nimiFiInput', { read: MatInput, static: true }) nimiFiInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  commonError: string = ''
  form: UntypedFormGroup
  name = '' + Math.random()
  lisaa = false
  alvt: AlvMaaritys[] = [].concat(OstoAlvt.kaikki).concat(MyyntiAlvt.kaikki)
  lokalisoidutAlvtyypit: LokalisoituKirjanpitotilinAlvTyyppi[] = LokalisoidutAlvtyypit.kaikki

  vertaaAlvMaarityksia: (a: AlvMaaritys, b: AlvMaaritys) => boolean = (a: AlvMaaritys, b: AlvMaaritys): boolean => {
    return a && b && a.tunniste === b.tunniste
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog, boolean>,
    private _errorHandler: ErrorHandler,
    private _tilikarttaService: TilikarttaService,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _dialog: MatDialog
  ) {
    this.lisaa = this._data.lisaa
  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      'numero': new UntypedFormControl({ value: this._data.tili.numero, disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
      'nimiFi': new UntypedFormControl(this._data.tili.localisedName?.fi ?? this._data.tili.nimi, [Validators.required]),
      'nimiEn': new UntypedFormControl(this._data.tili.localisedName?.en ?? null, [Validators.required]),
      'alv': new UntypedFormControl(this._data.tili.oletusAlvKasittely, [Validators.required]),
      'alvtyyppi': new UntypedFormControl(null, [Validators.required]),
      'aktiivinen': new UntypedFormControl({ value: this._data.tili.aktiivinen, disabled: true }, []),
      'reskontraActive': new FormControl<boolean>(this._data.tili.reskontraActive)
    })

    this.alvtyyppi.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tyyppi => {
      if (tyyppi === KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA) {
        this.alv.setValue(null)
        this.alv.disable()
      } else {
        this.alv.enable()
      }
    })

    this.alvtyyppi.setValue(this._data.tili.alvTyyppi)

    this.nimiFiInput.focus()

    this.reskontraActive.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async isActive => {
      if (
        this._data.tili.reskontraActive &&  // Was active before
        !isActive // But was just marked as NOT active
      ) {
        const dialogData: AreYouSureDialogData = {
          text: 'Jos reskontra laitetaan pois päältä, kaikki reskontramerkinnät poistetaan.',
          rightAction: 'Kyllä',
        }
        const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

        if (!isSure) {
          this.reskontraActive.setValue(true)
        }
      }
    })
  }



  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  get numero(): AbstractControl {
    return this.form.get('numero')
  }

  get nimiFi(): AbstractControl {
    return this.form.get('nimiFi')
  }

  get nimiEn(): AbstractControl {
    return this.form.get('nimiEn')
  }

  get alv(): AbstractControl {
    return this.form.get('alv')
  }

  get alvtyyppi(): AbstractControl {
    return this.form.get('alvtyyppi')
  }

  get aktiivinen(): AbstractControl {
    return this.form.get('aktiivinen')
  }

  get reskontraActive(): AbstractControl {
    return this.form.get('reskontraActive')
  }

  @HostListener('document:keydown.esc')
  peruuta() {
    this._dialogRef.close(false)
  }

  poista() {
    this._ladataanService.aloitaLataaminen()
    this._tilikarttaService.poistaAsiakkaanPaatilikartanYliajoTili(this._data.asiakas, this._data.tili).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this._errorHandler.handleError(error)
    })
  }

  @HostListener('document:keydown.enter')
  async tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const localisedName: { [kieli in TuettuKieli]: string } = {
      'fi': null,
      'en': null
    }

    if (this.nimiFi.value) {
      localisedName.fi = this.nimiFi.value
    }
    if (this.nimiEn.value) {
      localisedName.en = this.nimiEn.value
    }
    const tili: Kirjanpitotili = {
      aktiivinen: !!this.aktiivinen.value,
      numero: this.numero.value,
      nimi: this.nimiFi.value,
      oletusAlvKasittely: this.alv.enabled ? this.alv.value : null,
      vanhempi: this._data.tili.vanhempi,
      alvTyyppi: this.alvtyyppi.value,
      localisedName: localisedName
    }

    if (!this.reskontraActive.disabled) {
      tili.reskontraActive = this.reskontraActive.value ?? false
    }

    this._tilikarttaService.tallennaAsiakkaanPaatilikartanYliajoTili(this._data.asiakas, tili).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this._errorHandler.handleError(error)
    })

  }

}
