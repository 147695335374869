<mat-dialog-content>

  <lemon-dialog-header>
    Työn raakadata
  </lemon-dialog-header>

  <div style="padding: 30px;">
    <a [href]="link" target="_blank">{{link}}</a>
  </div>

  <div style="padding: 15px 30px 30px 30px;">
    <h3>JSON</h3>
    <div [innerHTML]="data | ljson"></div>
  </div>

</mat-dialog-content>