/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input } from '@angular/core'
import { MatMenuTrigger, MatMenuPanel } from '@angular/material/menu'

@Directive({
  selector: '[rightClickMenuTriggerFor]',
  host: {
    'class': 'mat-menu-trigger',
    'aria-haspopup': 'true',
    '[attr.aria-expanded]': 'menuOpen || null',
    '[attr.aria-controls]': 'menuOpen ? menu.panelId : null',
    '(mousedown)': '_handleMousedown($event)',
    '(keydown)': '_handleKeydown($event)',
    '(click)': '_handleClick($event)',
    '(contextmenu)': 'handleContext($event)'
  }
})
export class RightClickMatMenuDirective extends MatMenuTrigger {

  @Input('rightClickMenuTriggerFor')
  get menuFor(): MatMenuPanel {
    return this.menu
  }
  set menuFor(v: MatMenuPanel) {
    this.menu = v
  }
  _handleClick(e: MouseEvent) {
    // left click
  }
  handleContext(e: MouseEvent) {
    e.preventDefault() // prevents the browsers context menu
    super._handleClick(e)
  }
}
