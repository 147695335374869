import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { DateService } from '../_shared-core/service/date.service'

import { LocalMonth } from '../_shared-core/model/common'

import { Subject, BehaviorSubject, Observable } from 'rxjs'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { map } from 'rxjs/operators'
import { KlikattuKirjaus } from './raportit.component'
import { Location } from '@angular/common'
import { KirjanpitoRaporttiValilehti } from '../_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpidonProjekti } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoService } from 'app/_angular/service/kirjanpito/kirjanpito.service'

export interface BroadcastChannelKlikattuRaportointikirjausPayload {
  maksutavanTunniste: string
  kirjauksenAvain: string
  kirjauksenKuukausi: LocalMonth
}

/**
 * Component that is served from asiakkaat/:asiakasAvain/kirjanpidon-raportit/:vuosi/:kuukausi/:tyyppi
 */
@Component({
  templateUrl: './raportit-standalone.component.html',
  styleUrls: ['./raportit-standalone.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitStandaloneComponent implements OnInit, OnDestroy {

  valittuKuukausiObservable: BehaviorSubject<LocalMonth> = new BehaviorSubject({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })
  asiakkaanTiedotObservable: Observable<string>
  valittuValilehti: KirjanpitoRaporttiValilehti = 'tase-ja-tulos'
  private _ngUnsubscribe = new Subject<void>()
  private _kirjausSelectedBroadcastChannel: BroadcastChannel

  kirjanpidonProjektitObservable: Observable<KirjanpidonProjekti[]> = this._kirjanpitoService.kirjanpidonProjektitObservable

  constructor(
    private _route: ActivatedRoute,
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _location: Location,
    private _kirjanpitoService: KirjanpitoService,
  ) { }

  ngOnInit() {

    this.asiakkaanTiedotObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas) {
          return asiakas.nimi + ' (' + asiakas.ytunnus + ')'
        }
        return ''
      })
    )

    const paramMap = this._route.snapshot.paramMap
    const asiakasAvain = paramMap.get('asiakasAvain')
    const vuosi = paramMap.get('vuosi')
    const kuukausi = paramMap.get('kuukausi')

    const typeSuspect = paramMap.get('tyyppi') as KirjanpitoRaporttiValilehti
    if (
      typeSuspect === 'paakirja' ||
      typeSuspect === 'tase-ja-tulos' ||
      typeSuspect === 'viralliset' ||
      typeSuspect === 'bruttolaskelma' ||
      typeSuspect === 'oss-myynnit'
    ) {
      this.valittuValilehti = typeSuspect
    } else if (typeSuspect) {
      throw new Error('Unknown report type "' + typeSuspect + '"')
    }

    this.valittuKuukausiObservable.next({ year: Number(vuosi), month: Number(kuukausi) })

    try {
      this._kirjausSelectedBroadcastChannel = new BroadcastChannel(asiakasAvain + '-kirjausnumero-selected')
    } catch (err) {
      console.error('Failed to initialize BroadcastChannel', err)
    }

  }

  ngOnDestroy() {
    if (this._kirjausSelectedBroadcastChannel) {
      this._kirjausSelectedBroadcastChannel.close()
      this._kirjausSelectedBroadcastChannel = null
    }
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  raporttikirjaustaKlikatiin(klikattuKirjaus: KlikattuKirjaus) {

    if (!klikattuKirjaus?.kirjausnumero || !this._kirjausSelectedBroadcastChannel) {
      return
    }

    this._kirjausSelectedBroadcastChannel.postMessage(klikattuKirjaus)

  }

  selectedReportTypeChanged(type: KirjanpitoRaporttiValilehti) {
    const paramMap = this._route.snapshot.paramMap
    const asiakasAvain = paramMap.get('asiakasAvain')
    const vuosi = paramMap.get('vuosi')
    const kuukausi = paramMap.get('kuukausi')
    this._location.replaceState('/asiakkaat/' + asiakasAvain + '/kirjanpidon-raportit/' + vuosi + '/' + kuukausi + '/' + type)
  }

}
