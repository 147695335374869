<table class="a-l-table" style="width: 700px; margin-left:15px; margin-right: 25px; border-spacing: 0; padding: 0;">
  <thead *ngIf="valittuKuukausiObservable | async; let valittuKuukausi" class="light-gray-text">
    <tr>
      <th></th>
      <th *ngFor="let kuukausi of naytettavatKuukaudetObservable | async" [class.valittu-otsikko]="valittuKuukausi.year === kuukausi.year && valittuKuukausi.month === kuukausi.month">{{kuukausi.month}}/{{ kuukausi.year - 2000 }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>

    <tr>
      <!-- <td style=" padding: 0;">
        <div *ngIf="onkoEdellisiaMuuttuneitaAlvIlmoituksiaObservable | async" class="iso-pallura-super mat-elevation-z2">
          !
        </div>
      </td>
      <td style="padding: 0;">
        <div *ngIf="(edellisetHyvaksyntaaVaativatObservable | async)?.length; let count" class="iso-pallura mat-elevation-z1">
          {{ count }}
        </div>
      </td> -->
      <td style="padding: 0; text-align: right;">
        <button mat-icon-button (click)="taaksepain($event)" class="dark-gray-text large-icon-button" style="margin: 0 5px;">
          <i style="font-size: 35px; line-height: 35px; width: 35px; height: 35px;" class="ion-ios-arrow-back mat-icon"></i>
        </button>
      </td>
      <td class="a-l-r-td-ruutu" *ngFor="let kuukausi of kasitellytKuukausiruudutObservable | async" [rightClickMenuTriggerFor]="kuukausiMenu" [matMenuTriggerData]="{ kuukausi: kuukausi }" (click)="valitseKuukausi($event, kuukausi)" [class]="kuukausi.luokat">
        <span class="a-l-r-valittu"></span>
        <span class="a-l-r-s-a1"></span>
        <span class="a-l-r-s-a2 fa">{{kuukausi.vasenAlakulma}}</span>
        <span class="a-l-r-s-a3 fa fa-comment" style="z-index: 999999;" [title]="kuukausi.m"></span>
        <span class="a-l-r-s-a4">{{kuukausi.oikeaAlakulma}}</span>
        <!-- <span class="a-l-r-s-a5"></span> -->
        <span class="a-l-r-s-a6"></span>
      </td>
      <td style="padding: 0; border-left: 1px solid lightgray; text-align: left;">
        <button mat-icon-button (click)="eteenpain($event)" class="dark-gray-text large-icon-button" style="margin: 0 5px;">
          <i style="font-size: 35px; line-height: 35px; width: 35px; height: 35px;" class="ion-ios-arrow-forward mat-icon"></i>
        </button>
      </td>
      <!-- <td style="padding: 0;">
        <div *ngIf="(seuraavatHyvaksyntaaVaativatObservable | async)?.length; let count" class="iso-pallura mat-elevation-z1">
          {{ count }}
        </div>
      </td>
      <td style="padding: 0;">
        <div *ngIf="onkoSeuraaviaMuuttuneitaAlvIlmoituksiaObservable | async" class="iso-pallura-super mat-elevation-z2">
          !
        </div>
      </td> -->
    </tr>
  </tbody>
</table>

<mat-menu #kuukausiMenu="matMenu">
  <ng-template matMenuContent let-kuukausi="kuukausi">
    <button mat-menu-item (click)="avaaKuukausistatus($event, kuukausi)">Muokkaa yksityiskohtia</button>
  </ng-template>
</mat-menu>