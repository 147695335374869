<button mat-icon-button *ngIf="showPen && !lukittu" class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<ng-container *ngIf="editing && !lukittu; else readOnly">
  <div class="tili-num">
    <span class="tili-num1">{{ numero1 }}</span>
    <br class="tili-num-break" />
    <span class="tili-num2">{{ numero2 }}</span>
  </div>
  <div class="long-col"><span style="line-height: 21px; vertical-align: middle; display: inline-block;">{{ label }}</span><span *ngIf="help" [title]="help" class="ion-ios-help-circle-outline" style="margin-left: 15px; color: darkgray;"></span></div>
  <input *ngIf="onkoNumeerinen" [(ngModel)]="value1" type="text" (blur)="onBlur1()" (input)="onChange1()" decimalNumberField [showAllDecimals]="true" [numberOfDecimals]="desimaaleja" class="short-col input currency col1" autocomplete="kirjanasdfpitoaineistoarkki" data-lpignore="true" />
  <input *ngIf="!onkoNumeerinen" [(ngModel)]="value1" type="text" (blur)="onBlur1()" (input)="onChange1()" class="short-col input col1" autocomplete="kirjanpitoaineistoarkkasdfi" data-lpignore="true" />
  <div style="width: 10px;"></div>
  <input *ngIf="onkoNumeerinen" [(ngModel)]="value2" type="text" (blur)="onBlur2()" (input)="onChange2()" decimalNumberField [showAllDecimals]="true" [numberOfDecimals]="desimaaleja" class="short-col input currency col2" autocomplete="kirjanasdfpitoaineistoarkki" data-lpignore="true" />
  <input *ngIf="!onkoNumeerinen" [(ngModel)]="value2" type="text" (blur)="onBlur2()" (input)="onChange2()" class="short-col input col2" autocomplete="kirjanpitoaineistoarkkasdfi" data-lpignore="true" />
  <div class="end-marking" [class.save-icons-right]="!onkoNumeerinen">
    {{ end }}
    <span *ngIf="header1" class="col-header col-header1" [class.currency]="onkoNumeerinen">{{header1}}</span>
    <span *ngIf="header2" class="col-header col-header2" [class.currency]="onkoNumeerinen">{{header2}}</span>
    <span *ngIf="saving1" class="saving saving1" [@slowInQuickOut]="'in'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess1" class="save-success-text save-success-text1" [@quickInSlowOut]="'in'">
      Summa tallennettiin
    </span>
    <mat-icon *ngIf="saveSuccess1" class="save-success-icon save-success-icon1" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    <!-- <span *ngIf="saveFailure1" class="save-failure-text1">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span> -->
    <mat-icon *ngIf="saveFailure1" class="save-failure-icon save-failure-icon1" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>

    <span *ngIf="saving2" class="saving" [@slowInQuickOut]="'in'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess2" class="save-success-text" [@quickInSlowOut]="'in'">
      Summa tallennettiin
    </span>
    <mat-icon *ngIf="saveSuccess2" class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    <span *ngIf="saveFailure2 || saveFailure1" class="save-failure-text">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span>
    <mat-icon *ngIf="saveFailure2" class="save-failure-icon" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
  </div>
  <div *ngIf="naytaEhdotus" style="flex-basis: 100%;" class="ehdotusnappula-container">
    <button class="primarybutton" (click)="ehdotus()" mat-button>Hae ehdotus</button>
  </div>
</ng-container>

<ng-template #readOnly>
  <div class="tili-num">
    <span class="tili-num1">{{ numero1 }}</span>
    <br class="tili-num-break" />
    <span class="tili-num2">{{ numero2 }}</span>
  </div>
  <div class="long-col">{{ label }}</div>
  <div app-veroilmoitus-value-read-only [numero]="numero1" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" [format]="format" [desimaaleja]="desimaaleja" [log]="log" class="short-col" [class.hide-col]="hideCol1" [class.currency]="onkoNumeerinen"></div>
  <div style="width: 10px;"></div>
  <div app-veroilmoitus-value-read-only [numero]="numero2" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" [format]="format" [desimaaleja]="desimaaleja" [log]="log" class="short-col" [class.hide-col]="hideCol2" [class.currency]="onkoNumeerinen"></div>
  <div class="end-marking">
    {{ end }}
    <span *ngIf="header1" class="col-header col-header1" [class.currency]="onkoNumeerinen">{{header1}}</span>
    <span *ngIf="header2" class="col-header col-header2" [class.currency]="onkoNumeerinen">{{header2}}</span>
  </div>
</ng-template>