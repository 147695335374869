import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ChangeDetectionStrategy } from '@angular/core'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Subject, Observable, BehaviorSubject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { ListausAsiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Pankki } from 'app/_jaettu/model/tiliote'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

interface Pankkiyhteystiedot {
  asiakasAvain: string
  asiakkaanNimi: string
  ytunnus: string
  /**
  * @param pankit Pankin koodi + ' ' + pankin nimi ; pankin koodi + ' ' + pankin nimi ; ...
  */
  pankit: string
}

@Component({
  selector: '[app-pankkiyhteydet-listaus]',
  templateUrl: './pankkien-listaus.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PankkienListausComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) _sort: MatSort

  naytettavatKolumnit: string[] = ['asiakasAvain', 'asiakkaanNimi', 'ytunnus', 'pankit']
  lataaObservable: BehaviorSubject<boolean> = new BehaviorSubject(true)
  ladattuObservable: BehaviorSubject<string> = new BehaviorSubject('0/0')

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  dataSource = new MatTableDataSource<Pankkiyhteystiedot>([])

  constructor(
    private _errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
  ) {

    this.dataSource.sortData = (data: Pankkiyhteystiedot[], sort: MatSort): Pankkiyhteystiedot[] => {
      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

      if (active === 'asiakkaanNimi') {
        return data.sort((a, b) => {
          return collator.compare(a.asiakkaanNimi, b.asiakkaanNimi) * directionMultiplier
        })
      }
      if (active === 'pankit') {
        return data.sort((a, b) => {
          return collator.compare(a.pankit, b.pankit) * directionMultiplier
        })
      }
      return data
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    this._sort.sort({
      disableClear: false,
      id: 'asiakkaanNimi',
      start: 'asc'
    })
    this.dataSource.sort = this._sort

    const kaikkiAsiakkaatObservable: Observable<ListausAsiakas[]> = this._firebase.firestoreCollectionGroup<ListausAsiakas>('asiakaslistaus').listen()
    kaikkiAsiakkaatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async asiakkaat => {
      this.lataaObservable.next(true)
      if (!asiakkaat?.length) {
        this.dataSource.data = []
        this.lataaObservable.next(false)
        return
      }

      const data: Pankkiyhteystiedot[] = []
      let valmiina = 0
      this.ladattuObservable.next(valmiina + '/' + asiakkaat.length)
      const promises: Promise<any>[] = []
      for (const asiakas of asiakkaat) {
        const promise = this._firebaseLemonaid.firestoreCollection<Pankki>('customers/' + asiakas.k + '/bank-banks').get().then(pankit => {
          const pankitString = pankit.reduce((a, b) => a + ' ; ' + b.avain + ' ' + b.nimi, '').substring(2).trim()
          const tiedot: Pankkiyhteystiedot = {
            asiakasAvain: asiakas.k,
            asiakkaanNimi: asiakas.n,
            ytunnus: asiakas.y,
            pankit: pankitString
          }
          data.push(tiedot)
          valmiina++
          this.ladattuObservable.next(valmiina + '/' + asiakkaat.length)
        }).catch(err => {
          this._errorHandler.handleError(err)
        })
        promises.push(promise)
        if (promises.length > 100) {
          await Promise.all(promises)
          promises.length = 0
        }
      }
      await Promise.all(promises)
      this.dataSource.data = data
      this.lataaObservable.next(false)
    }, error => this._errorHandler.handleError(error))

  }

  get pankkitiedotDataSource(): MatTableDataSource<Pankkiyhteystiedot> {
    return this.dataSource
  }

}
