import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'

@Component({
  selector: '[app-veroilmoitus-value-read-only]',
  template: '{{val}}',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusValueReadOnlyComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges {

  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot
  @Input() numero: string
  @Input() desimaaleja: number = 2
  @Input() format: 'currency' | 'number' | 'string' | 'km' | 'percentage' | 'kpl' | 'date'
  @Input() log: boolean = false
  @Input() repeatingMetadata: RepeatingMetadata

  val: string = ''

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _dateService: DateService
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero && this.veroilmoitus) {
      this.val = this._getValueAsString()
      if (this.log) {
        console.log('Display value for number ' + this.numero, this.val)
      }
    }
  }

  private _getValueAsString(): string {
    const identifier = this.annaTiedonTunniste(this.numero, this.repeatingMetadata)
    const value = this._getValue(identifier, this.veroilmoitus, this.repeatingMetadata)
    if (this.log) {
      console.log('Found value', value, 'for number', this.numero)
    }
    return this._formatValue(value)
  }

  private _formatValue(value: string | number): string {
    if (value === null || value === undefined) { return '' }
    if (this.format === 'date') {
      const asLocalDate = this._dateService.parsiVeroFormaattiPaiva(value as string)
      return this._dateService.muotoilePaikallinenPaiva(asLocalDate, this.veroilmoitus.kieli)
    } else if (this.format === 'currency') {
      const asNumber = this._currencyService.muutaMerkkijonoNumeroksiKaikkiDesimaalit(value + '')
      if (this.log) {
        console.log('Converted the value to a number', asNumber, 'for number', this.numero)
      }
      return this._currencyService.formatoiRahaIlmanValuuttaSymbolia(asNumber, this.veroilmoitus.kieli)
    } else if (this.format === 'number' || this.format === 'kpl') {
      const asNumber = this._currencyService.muutaMerkkijonoNumeroksiKaikkiDesimaalit(value + '')
      if (this.log) {
        console.log('Converted the value to a number', asNumber, 'for number', this.numero)
      }
      return this._currencyService.formatoiDesimaali(asNumber, this.desimaaleja, this.veroilmoitus.kieli)
    }
    return value + ''
  }

}
