import { Component, OnInit, Inject, ErrorHandler, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Asiakas, ListausAsiakas } from 'app/_jaettu-lemonator/model/asiakas'

import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { firstValueFrom, Subject } from 'rxjs'

export type OptimizedListausAsiakas = Pick<ListausAsiakas, 'k' | 'f' | 'n' | 'm'>
export interface AsiakkaanMuistiinpanotDialogData {
  asiakas: OptimizedListausAsiakas
}

export interface AsiakkaanMuistiinpanotDialogReturnData {
  muistiinpanot: string
  vari: string
}

@Component({
  templateUrl: './asiakkaan-muistiinpanot.dialog.html'
})
export class AsiakkaanMuistiinpanotDialog implements OnInit, OnDestroy {

  @ViewChild('muistiinpanotTextarea', { read: MatInput, static: true }) muistiinpanotTextarea: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  namename: string = 'assf' + Math.random()

  asiakas: OptimizedListausAsiakas = null
  muistiinpanot: string = null
  vari: string = null

  private _asiakasPromise: Promise<Asiakas>

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanMuistiinpanotDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanMuistiinpanotDialog>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService
  ) {

    this._asiakasPromise = firstValueFrom(this._asiakasService.annaAsiakasObservable(_data.asiakas.k))

    this.asiakas = this._data.asiakas
    this.muistiinpanot = this.asiakas ? this.asiakas.m || '' : ''
    this.vari = this.asiakas ? this.asiakas.f || null : null

    this.form = new UntypedFormGroup({
      'muistiinpanot': new UntypedFormControl({ value: this.muistiinpanot, disabled: false }, []),
      'vari': new UntypedFormControl({ value: this.vari, disabled: false }, [])
    })
    this.form.get('muistiinpanot').valueChanges.subscribe(value => {
      this.muistiinpanot = value
    })
    this.form.get('vari').valueChanges.subscribe(value => {
      this.vari = value
    })

  }

  ngOnInit() {
    setTimeout(() => {
      this.muistiinpanotTextarea.focus()
      this._changeDetectorRef.markForCheck()
    }, 50)
  }

  tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    this._asiakasPromise.then(asiakas => {
      return this._asiakasService.tallennaMuistiinpano(asiakas, this.muistiinpanot, this.vari)
    }).then(() => {
      this._ladataanService.lopetaLataaminen()
      const ret: AsiakkaanMuistiinpanotDialogReturnData = {
        muistiinpanot: this.muistiinpanot,
        vari: this.vari
      }
      this._dialogRef.close(ret)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
