import { AfterViewInit, Component, ErrorHandler, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { AsiakasKopioija } from 'app/_angular/service/asiakas/asiakas.kopioija'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { AjastettuTyo, AjastettuTyoService } from 'app/_jaettu/eraajot/ajastettu/ajastetut-tyot.service'
import { AsiakasLahetaPaattymisEmailTyojono, KayttajanRoolienPoistoData } from 'app/_jaettu/lemonator/model/kayttajahallinta'
import { Roolit } from 'app/_jaettu/model/kayttaja'
import { combineLatest, firstValueFrom, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs'
import { AsiakkaanSopimuskausiService, LokalisoituLopettamissyy } from 'app/_jaettu-lemonator/service/sopimuskausi.service'

import { Asiakas, AsiakkaanSopimuskaudenLopettamisenSyy, AsiakkaanSopimuskausi } from '../../_jaettu-lemonator/model/asiakas'
import { LocalDate } from 'app/_shared-core/model/common'
import { ComparisonOperators, DateService } from 'app/_shared-core/service/date.service'
import { OutgoingSlackMessage } from 'app/_jaettu-lemonator/model/integraatiot'

import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { ApixMaksutLopetaVastaanottoTyojonoData, ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { ApixUriService } from 'app/_jaettu/service/apix-uri.service'

export interface AsiakkaanSopimuskausiPaataDialogData {
  kausi: AsiakkaanSopimuskausi
  asiakas: Asiakas
}

interface SopimuskausiFormGroup {
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
  lahetaPaattymassa: FormControl<boolean>
  lahetaPaattynyt: FormControl<boolean>
  tositteetLoppuu: FormControl<Date>
  syy: FormControl<LokalisoituLopettamissyy>
  lisatiedot: FormControl<string>
}

@Component({
  templateUrl: './sopimuskausi-paata.dialog.html'
})
export class AsiakkaanSopimuskausiPaataDialog implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) sopimuskausiAlkaaInputti: MatInput

  private _asiakkaanSopimuskausiService = new AsiakkaanSopimuskausiService(this._dateService, this._timestampService)
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  kausi: AsiakkaanSopimuskausi = null
  sopimuskausiFormGroup: FormGroup<SopimuskausiFormGroup> = null
  inputname = 'mnbiggteagjopq' + Math.random()
  minimiAlkaaPaivamaara: Date = null
  maksimiLoppuuPaivamaara: Date = null
  minimiLoppuuPaivamaara: Date = null
  minimiTositteetLoppuuPaivamaara: Date = null
  kaikkiValittavatSyyt = this._asiakkaanSopimuskausiService.lokalisoidutSopimuksenLopettamissyyt
  sopimusPaattyyPianEmailDate: LocalDate = null
  sopimusOnPaattynytEmailDate: LocalDate = null
  valittuSyy: LokalisoituLopettamissyy = null
  onkoPurku: boolean = false
  onkoLaskujenVastaanottoPaallaObservable: Observable<boolean>

  irtisanominenMahdollistaAikaisintaanPaivamaaraObservable: Observable<LocalDate>
  private _firstPossibleEndDate: Observable<LocalDate>

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanSopimuskausiPaataDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanSopimuskausiPaataDialog>,
    private _dateService: DateService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _validationService: FormValidationService,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _asiakasKopioija: AsiakasKopioija,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _firebase: FirebaseLemonator,
    private _asiakasUriService: AsiakasUriService,
    private _ajastettuTyoService: AjastettuTyoService,
    private _timestampService: TimestampService,
    private _currencyService: CurrencyService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _copyService: KopioijaPalvelu,
    private _apixUriService: ApixUriService,
    private _lemonaidFirebase: FirebaseLemonaid
  ) {
    this.kausi = this._copyService.cloneObjectDeep(this._data.kausi)

    const edellinen = this._annaEdellinenKausi(this._data.asiakas, this.kausi)
    this.minimiAlkaaPaivamaara = edellinen?.loppuu ? this._dateService.lisaaPaivia(this._dateService.localDateToDate(edellinen.loppuu), 1) : new Date(1980, 0, 1)

    const seuraava = this._annaSeuraavaKausi(this._data.asiakas, this.kausi)
    this.maksimiLoppuuPaivamaara = seuraava?.alkaa ? this._dateService.lisaaPaivia(this._dateService.localDateToDate(seuraava.alkaa), -1) : new Date(2099, 0, 1)
    this.minimiLoppuuPaivamaara = this._dateService.lisaaPaivia(this.minimiAlkaaPaivamaara, 1)

    this._firstPossibleEndDate = of<LocalDate>(this._dateService.kuukaudenViimeinenPaikallinen(this._dateService.lisaaKuukausiaPaikallinen(this._dateService.currentLocalDate(), 1)))

    this.sopimuskausiFormGroup = this._annaFormGroupSopimuskaudelle(this.kausi, this._data.asiakas)

    this.onkoLaskujenVastaanottoPaallaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        const uri = 'customers/' + asiakas.avain + '/apix-received-invoice-config/' + asiakas.avain
        return this._lemonaidFirebase.firestoreDoc<ApixReceivedInvoiceConfig>(uri).listen().pipe(
          map(config => (config?.paymentReceiveIsActive || config?.receiveActive) ?? false)
        )
      })
    )
  }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sopimuskausiAlkaaInputti.focus()
    }, 500)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  vainKuunViimeinenFilter = (d: Date | null): boolean => {
    if (d <= this.minimiLoppuuPaivamaara) {
      return false
    }
    // Salli vain kuun viimeinen päivä
    if (!this.valittuSyy?.arvo || this._asiakkaanSopimuskausiService.onkoLopetus(this.valittuSyy?.arvo)) {
      return this._dateService.onkoKuunViimeinen(d)
    }
    return true
  }

  private _annaSeuraavaKausi(asiakas: Asiakas, nykyinen: AsiakkaanSopimuskausi): AsiakkaanSopimuskausi {
    if (!asiakas.sopimuskaudet || !nykyinen.loppuu) {
      return null
    }
    let seuraava: AsiakkaanSopimuskausi = null
    for (const kausi of asiakas.sopimuskaudet) {
      if (kausi.avain === nykyinen.avain) { continue }
      if (this._dateService.compareLocalDates(kausi.alkaa, '>', nykyinen.loppuu)) {
        if (seuraava === null || this._dateService.compareLocalDates(seuraava.alkaa, '>', kausi.alkaa)) {
          seuraava = kausi
        }
      }
    }
    return seuraava
  }

  private _annaEdellinenKausi(asiakas: Asiakas, nykyinen: AsiakkaanSopimuskausi): AsiakkaanSopimuskausi {
    if (!asiakas.sopimuskaudet || !nykyinen.alkaa) {
      return null
    }
    let edellinen: AsiakkaanSopimuskausi = null
    for (const kausi of asiakas.sopimuskaudet) {
      if (kausi.avain === nykyinen.avain) { continue }
      if (kausi.loppuu && this._dateService.compareLocalDates(kausi.loppuu, '<', nykyinen.alkaa)) {
        if (edellinen === null || this._dateService.compareLocalDates(edellinen.loppuu, '<', kausi.loppuu)) {
          edellinen = kausi
        }
      }
    }
    return edellinen
  }

  private _annaDefaultLahetaEmailitValinta(asiakas: Asiakas): boolean {
    const nykyinenVastuukirjanpitaja = this._asiakasJaettuService.annaVastuukirjanpitajaPaivalle(asiakas, this._dateService.currentLocalDate())
    const onkoHolviAsiakas = nykyinenVastuukirjanpitaja && nykyinenVastuukirjanpitaja.kirjanpitajanAvain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' // Herra holvi.
    return !onkoHolviAsiakas
  }

  private _annaFormGroupSopimuskaudelle(sopimuskausi: AsiakkaanSopimuskausi, asiakas: Asiakas): FormGroup<SopimuskausiFormGroup> {
    const lukossa = false // lukitseVanhat && this._lukitseSopimuskausirivi(sopimuskausi, last)

    const loppuuAsDate = this._dateService.localDateToDate(sopimuskausi.loppuu)
    if (loppuuAsDate) {
      this.minimiTositteetLoppuuPaivamaara = this._dateService.lisaaPaivia(loppuuAsDate, 1)
    }
    const loppuuFormControl = new FormControl<Date>({ value: loppuuAsDate, disabled: lukossa })
    const tositteetLoppuuFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(sopimuskausi.tositteidenToimitusLoppuu), disabled: lukossa })
    const alkaaFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(sopimuskausi.alkaa), disabled: lukossa }, [Validators.required, this._compareToDateValidator('<', loppuuFormControl, 'alkaaPienempiKuinLoppuu')])

    tositteetLoppuuFormControl.setValidators(this._annaTositteetLoppuuValidators(loppuuFormControl, sopimuskausi.lopettamisenSyy))
    loppuuFormControl.setValidators(this._annaLoppuuValidators(alkaaFormControl, sopimuskausi.lopettamisenSyy, asiakas))

    let valittavaSyy: LokalisoituLopettamissyy = null
    if (sopimuskausi.lopettamisenSyy) {
      valittavaSyy = this._asiakkaanSopimuskausiService.annaLokalisoituSyy(sopimuskausi.lopettamisenSyy)
    }

    this.valittuSyy = valittavaSyy
    this.onkoPurku = this._asiakkaanSopimuskausiService.onkoPurku(sopimuskausi.lopettamisenSyy)
    this.sopimusPaattyyPianEmailDate = this._asiakkaanSopimuskausiService.annaSopimusPaattyyPianEmailDate(sopimuskausi)
    this.sopimusOnPaattynytEmailDate = this._asiakkaanSopimuskausiService.annaSopimusOnPaattynytEmailDate(sopimuskausi)

    const paattymassaFormControl = new FormControl<boolean>({ value: false, disabled: lukossa })
    const paattynytFormControl = new FormControl<boolean>({ value: false, disabled: lukossa })

    const lisatiedotFormControl = new FormControl<string>({ value: sopimuskausi.lisatiedot ?? '', disabled: lukossa }, valittavaSyy?.lisatiedotPakollinen ? [Validators.required] : [])
    const syyFormControl = new FormControl<LokalisoituLopettamissyy>({ value: valittavaSyy, disabled: lukossa }, sopimuskausi.loppuu ? [Validators.required] : [])
    const sopimuskausiGroup = new FormGroup<SopimuskausiFormGroup>({
      'alkaa': alkaaFormControl,
      'loppuu': loppuuFormControl,
      'lahetaPaattymassa': paattymassaFormControl,
      'lahetaPaattynyt': paattynytFormControl,
      'tositteetLoppuu': tositteetLoppuuFormControl,
      'syy': syyFormControl,
      'lisatiedot': lisatiedotFormControl
    })

    paattymassaFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.lahetaPaattymassaEmail = value
    })

    paattymassaFormControl.setValue(sopimuskausi.lahetaPaattymassaEmail ?? this._annaDefaultLahetaEmailitValinta(asiakas))

    paattynytFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.lahetaPaattynytEmail = value
    })
    paattynytFormControl.setValue(sopimuskausi.lahetaPaattynytEmail ?? this._annaDefaultLahetaEmailitValinta(asiakas))

    alkaaFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.alkaa = this._dateService.dateToLocalDate(value)
      if (sopimuskausi.alkaa) {
        this.minimiLoppuuPaivamaara = this._dateService.lisaaPaivia(value, 1)
      }
    })
    loppuuFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.loppuu = this._dateService.dateToLocalDate(value)
      if (sopimuskausi.loppuu) {
        this.sopimusPaattyyPianEmailDate = this._asiakkaanSopimuskausiService.annaSopimusPaattyyPianEmailDate(sopimuskausi)
        this.sopimusOnPaattynytEmailDate = this._asiakkaanSopimuskausiService.annaSopimusOnPaattynytEmailDate(sopimuskausi)
        this.minimiTositteetLoppuuPaivamaara = this._dateService.lisaaPaivia(value, 1)
        if (!tositteetLoppuuFormControl.value) {
          const loppuvatTilikaudet = this._asiakasJaettuService.annaKuukaudenAikanaPaattyvatTilikaudet(this._data.asiakas, sopimuskausi.loppuu)
          if (loppuvatTilikaudet.length) {
            const seuraavaKuukausi = this._dateService.lisaaKuukausiaPaikallinen(sopimuskausi.loppuu, 1)
            const seuraavanKuunViimeinen = this._dateService.kuukaudenViimeinenPaikallinen(seuraavaKuukausi)
            tositteetLoppuuFormControl.setValue(this._dateService.localDateToDate(seuraavanKuunViimeinen))
          } else {
            const plus7Days = this._dateService.lisaaPaiviaPaikallinen(sopimuskausi.loppuu, 7)
            tositteetLoppuuFormControl.setValue(this._dateService.localDateToDate(plus7Days))
          }
        }
      }
      if (this.onkoPurku) {
        tositteetLoppuuFormControl.setValue(value)
        paattymassaFormControl.setValue(false)
        paattynytFormControl.setValue(false)
      }
    })
    this.irtisanominenMahdollistaAikaisintaanPaivamaaraObservable = combineLatest([
      this._firstPossibleEndDate,
      loppuuFormControl.valueChanges,
      syyFormControl.valueChanges
    ]).pipe(
      map(([firstPossible, loppuu, syy]) => {
        if (!syy || this._asiakkaanSopimuskausiService.onkoPurku(syy.arvo)) {
          return null
        }
        const value = this._dateService.dateToLocalDate(loppuu)
        if (value && this._dateService.compareLocalDates(value, '<', firstPossible)) {
          return firstPossible
        }
        return null
      })
    )
    syyFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.lopettamisenSyy = value?.arvo ?? null
      this.valittuSyy = value ?? null
      if (this.valittuSyy?.lisatiedotPakollinen) {
        lisatiedotFormControl.setValidators([Validators.required])
      } else {
        lisatiedotFormControl.setValidators([])
      }
      this.onkoPurku = this._asiakkaanSopimuskausiService.onkoPurku(sopimuskausi.lopettamisenSyy)
      if (this.onkoPurku) {
        tositteetLoppuuFormControl.setValue(loppuuFormControl.value)
        paattymassaFormControl.setValue(false)
        paattynytFormControl.setValue(false)
      }
      loppuuFormControl.setValidators(this._annaLoppuuValidators(alkaaFormControl, sopimuskausi.lopettamisenSyy, asiakas))
      tositteetLoppuuFormControl.setValidators(this._annaTositteetLoppuuValidators(loppuuFormControl, sopimuskausi.lopettamisenSyy))
      tositteetLoppuuFormControl.updateValueAndValidity()
      lisatiedotFormControl.updateValueAndValidity()
      loppuuFormControl.updateValueAndValidity()
    })
    lisatiedotFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.lisatiedot = value ?? null
    })
    tositteetLoppuuFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.tositteidenToimitusLoppuu = this._dateService.dateToLocalDate(value)
    })

    return sopimuskausiGroup
  }

  private _annaTositteetLoppuuValidators(loppuuFormControl: FormControl<Date>, syy: AsiakkaanSopimuskaudenLopettamisenSyy) {
    if (!syy || !this._asiakkaanSopimuskausiService.onkoPurku(syy)) {
      return [Validators.required, this._compareToDateValidator('>', loppuuFormControl, 'tositteetSuurempiKuinLoppuu')]
    }
    return []
  }

  private _annaLoppuuValidators(alkaaFormControl: FormControl<Date>, syy: AsiakkaanSopimuskaudenLopettamisenSyy, asiakas: Asiakas): ((ctrl: FormControl<any>) => ValidationErrors | null)[] {
    const validators = [Validators.required]

    if (!syy || this._asiakkaanSopimuskausiService.onkoLopetus(syy)) {
      validators.push(this._lastDayOfMonthValidator('kuunViimeinen'))
    }

    validators.push(this._tarkistaMenneisyydessaNollahinta('hinnanOnMenneisyydessaOltavaNolla', asiakas))

    validators.push(this._compareToDateValidator('>', alkaaFormControl, 'loppuuSuurempiKuinAlkaa'))
    return validators
  }

  private _tarkistaMenneisyydessaNollahinta(tunniste: string, asiakas: Asiakas): (ctrl: FormControl<Date>) => ValidationErrors | null {
    return (ctrl: FormControl<Date>) => {
      if (ctrl.value) {
        // Tarkista vain menneisyydessä olevat arvot
        if (ctrl.value < new Date()) {
          const mnth = this._dateService.dateToLocalMonth(ctrl.value)
          const hinta = this._asiakasJaettuService.annaHintaKuukaudelleJosEiTauollaJaSopimusVoimassa(asiakas, mnth)
          if (hinta && hinta.hinta > 0.00) {
            const res = {}
            res[tunniste] = true
            return res
          }
        }
      }
      return null
    }
  }

  private _lastDayOfMonthValidator(tunniste: string): (ctrl: FormControl<Date>) => ValidationErrors | null {
    return (ctrl: FormControl<Date>) => {
      if (ctrl.value) {
        const asLocal = this._dateService.dateToLocalDate(ctrl.value)
        const lastOfMonth = this._dateService.kuukaudenViimeinenPaikallinen(asLocal)
        if (this._dateService.compareLocalDates(asLocal, '!=', lastOfMonth)) {
          const res = {}
          res[tunniste] = true
          return res
        }
      }
      return null
    }
  }

  private _compareToDateValidator(operator: ComparisonOperators, toinen: AbstractControl<Date>, tunniste: string): (ctrl: FormControl<Date>) => ValidationErrors | null {
    return (ctrl: FormControl<Date>) => {
      // console.log('1', ctrl.value, toinen.value)
      if (ctrl.value && toinen.value) {
        if (!this._dateService.compareLocalDates(this._dateService.dateToLocalDate(ctrl.value), operator, this._dateService.dateToLocalDate(toinen.value))) {
          const res = {}
          res[tunniste] = true
          return res
        }
      }
      return null
    }
  }

  compareSyyt(o1: LokalisoituLopettamissyy, o2: LokalisoituLopettamissyy): boolean {
    return o1?.arvo === o2?.arvo
  }

  async tallenna() {
    if (!this.sopimuskausiFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.sopimuskausiFormGroup)
      return
    }
    this._ladataanService.aloitaLataaminen()

    const asiakasCopy = this._asiakasKopioija.kopioiAsiakas(this._data.asiakas)
    if (!asiakasCopy.sopimuskaudet) { asiakasCopy.sopimuskaudet = [] }
    const index = asiakasCopy.sopimuskaudet.findIndex(k => k.avain === this.kausi.avain)
    if (index < 0) {
      throw new Error('No index found!')
    }

    for (const ruutukausi of asiakasCopy.kkruutujenKaudet ?? []) {
      if (!ruutukausi.loppuu && this._dateService.compareLocalDates(ruutukausi.alkaa, '<', this.kausi.loppuu)) {
        ruutukausi.loppuu = this.kausi.loppuu
        break
      }
    }

    if (this.onkoPurku) {
      this.kausi.tositteidenToimitusLoppuu = this.kausi.loppuu
      this.kausi.lahetaPaattymassaEmail = false
      this.kausi.lahetaPaattynytEmail = false
    }

    asiakasCopy.sopimuskaudet[index] = this.kausi

    const kirjanpitajanTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    this.kausi.paivittaja = kirjanpitajanTiedot.uid
    this.kausi.paivitetty = this._timestampService.now()

    const batch = this._firebase.firestoreBatch()
    await this._asiakasService.lisaaAsiakasBatchiin(asiakasCopy, kirjanpitajanTiedot, 'tilikaudet-eivat-voineet-muuttua', false, false, batch)

    const poistakayttajienMuutRoolitKuinTositteetTyoAvain = this.kausi.avain + 'poista_roolit_sailyta_tositteet'
    const poistaKayttajienMuutRoolitKuinTositteetTyoUri = this._asiakasUriService.annaKayttajienRoolienPoistonTyoUri(asiakasCopy.avain, poistakayttajienMuutRoolitKuinTositteetTyoAvain)
    const poistakayttajienMuutRoolitKuinTositteet: KayttajanRoolienPoistoData = {
      asiakasAvain: asiakasCopy.avain,
      deaktivoiKayttajat: false,
      poistaKaikkiRoolit: false,
      poistettavatRoolit: null,
      sailytettavatRoolit: [Roolit.TOSITTEET_MYYNTI, Roolit.TOSITTEET_OSTO, Roolit.TOSITTEET_PALKKA, Roolit.TOSITTEET_TILI]
    }

    const roolienPoistopaiva = this._dateService.lisaaPaiviaPaikallinen(this.kausi.loppuu, 1)
    const roolienPoisto1Timestamp = this._asiakkaanSopimuskausiService.annaTimestampRoolienPoistotyolle(roolienPoistopaiva)
    const poistaKayttajienMuutRoolitKuinTositteetAjastettuTyo: AjastettuTyo<KayttajanRoolienPoistoData> = {
      ajettu: null,
      ajoaika: roolienPoisto1Timestamp,
      avain: poistakayttajienMuutRoolitKuinTositteetTyoAvain,
      kohde: poistaKayttajienMuutRoolitKuinTositteetTyoUri,
      selite: 'Poistaa asiakkaan ' + asiakasCopy.nimi + ' (' + asiakasCopy.ytunnus + ') kaikkien käyttäjien muut kuin tositteen toimitusroolit.',
      tyodata: poistakayttajienMuutRoolitKuinTositteet,
      tyyppi: 'asiakkuuden-paattaminen-poista-muut-kuin-tosite-roolit',
      asiakasAvain: asiakasCopy.avain
    }

    if (this.onkoPurku) {
      this._ajastettuTyoService.poistaAjastusTyolla(poistaKayttajienMuutRoolitKuinTositteetAjastettuTyo, this._firebase.firestoreProvider, batch)
    } else {
      this._ajastettuTyoService.ajasta(poistaKayttajienMuutRoolitKuinTositteetAjastettuTyo, this._firebase.firestoreProvider, batch)
    }



    const poistaKayttajienKaikkiRoolitJaDeaktivoiTyoAvain = this.kausi.avain + 'poista_roolit_ja_deaktivoi'
    const poistaKayttajienKaikkiRoolitJaDeaktivoiTyoUri = this._asiakasUriService.annaKayttajienRoolienPoistonTyoUri(asiakasCopy.avain, poistaKayttajienKaikkiRoolitJaDeaktivoiTyoAvain)
    const poistaKayttajienKaikkiRoolitJaDeaktivoi: KayttajanRoolienPoistoData = {
      asiakasAvain: asiakasCopy.avain,
      deaktivoiKayttajat: true,
      poistaKaikkiRoolit: true,
      poistettavatRoolit: null,
      sailytettavatRoolit: null
    }

    const roolienPoisto2Timestamp = this._asiakkaanSopimuskausiService.annaTimestampKayttajienDeaktivoinnille(this.kausi)
    const poistakayttajienKaikkiRoolitJaDeaktivoiAjastettuTyo: AjastettuTyo<KayttajanRoolienPoistoData> = {
      ajettu: null,
      ajoaika: roolienPoisto2Timestamp,
      avain: poistaKayttajienKaikkiRoolitJaDeaktivoiTyoAvain,
      kohde: poistaKayttajienKaikkiRoolitJaDeaktivoiTyoUri,
      selite: 'Poistaa asiakkaan ' + asiakasCopy.nimi + ' (' + asiakasCopy.ytunnus + ') kaikkien käyttäjien kaikki roolit ja deaktivoi kaikki käyttäjät.',
      tyodata: poistaKayttajienKaikkiRoolitJaDeaktivoi,
      tyyppi: 'asiakkuuden-paattaminen-deaktivoi-kayttajat',
      asiakasAvain: asiakasCopy.avain
    }
    this._ajastettuTyoService.ajasta(poistakayttajienKaikkiRoolitJaDeaktivoiAjastettuTyo, this._firebase.firestoreProvider, batch)



    const emailPaattymassaPvm = this._asiakkaanSopimuskausiService.annaSopimusPaattyyPianEmailDate(this.kausi)
    const emailPaattymassaTyoAvain = this.kausi.avain + 'email_paattymassa'
    const emailPaattymassaTyoUri = 'tyojono/' + asiakasCopy.avain + '/asiakkaan-sopimuksen-paatosemail-laheta-postmarkilla/' + emailPaattymassaTyoAvain
    const emailPaattymassa: AsiakasLahetaPaattymisEmailTyojono = {
      asiakasAvain: asiakasCopy.avain,
      sopimuskausiAvain: this.kausi.avain,
      kirjanpitaja: kirjanpitajanTiedot.uid,
      tyyppi: 'lopetus_paattymassa',
      lahetysPvm: emailPaattymassaPvm
    }
    const emailPaattymassaTimestamp = this._asiakkaanSopimuskausiService.annaTimestampSahkopostityolle(emailPaattymassaPvm)
    const lahetaEmailPaattymassaAjastettuTyo: AjastettuTyo<AsiakasLahetaPaattymisEmailTyojono> = {
      ajettu: null,
      ajoaika: emailPaattymassaTimestamp,
      avain: emailPaattymassaTyoAvain,
      kohde: emailPaattymassaTyoUri,
      selite: 'Lähettää asiakkaalle ' + asiakasCopy.nimi + ' (' + asiakasCopy.ytunnus + ') sähköpostin, joka muistuttaa sopimuksen loppumisesta.',
      tyodata: emailPaattymassa,
      tyyppi: 'asiakkuuden-paattaminen-poista-muut-kuin-tosite-roolit',
      asiakasAvain: asiakasCopy.avain
    }

    if (this.kausi.lahetaPaattymassaEmail) {
      this._ajastettuTyoService.ajasta(lahetaEmailPaattymassaAjastettuTyo, this._firebase.firestoreProvider, batch)
    } else {
      this._ajastettuTyoService.poistaAjastusTyolla(lahetaEmailPaattymassaAjastettuTyo, this._firebase.firestoreProvider, batch)
    }

    const emailPaattynytPvm = this._asiakkaanSopimuskausiService.annaSopimusOnPaattynytEmailDate(this.kausi)
    const emailPaattynytTyoAvain = this.kausi.avain + 'email_paattynyt'
    const emailPaattynytTyoUri = 'tyojono/' + asiakasCopy.avain + '/asiakkaan-sopimuksen-paatosemail-laheta-postmarkilla/' + emailPaattynytTyoAvain
    const emailPaattynyt: AsiakasLahetaPaattymisEmailTyojono = {
      asiakasAvain: asiakasCopy.avain,
      sopimuskausiAvain: this.kausi.avain,
      kirjanpitaja: kirjanpitajanTiedot.uid,
      tyyppi: 'lopetus_paattynyt',
      lahetysPvm: emailPaattynytPvm
    }
    const emailPaattynytTimestamp = this._asiakkaanSopimuskausiService.annaTimestampSahkopostityolle(emailPaattynytPvm)
    const lahetaEmailPaattynytAjastettuTyo: AjastettuTyo<AsiakasLahetaPaattymisEmailTyojono> = {
      ajettu: null,
      ajoaika: emailPaattynytTimestamp,
      avain: emailPaattynytTyoAvain,
      kohde: emailPaattynytTyoUri,
      selite: 'Lähettää asiakkaalle ' + asiakasCopy.nimi + ' (' + asiakasCopy.ytunnus + ') sähköpostin, joka muistuttaa sopimuksen loppumisesta.',
      tyodata: emailPaattynyt,
      tyyppi: 'asiakkuuden-paattaminen-poista-muut-kuin-tosite-roolit',
      asiakasAvain: asiakasCopy.avain
    }

    if (this.kausi.lahetaPaattynytEmail) {
      this._ajastettuTyoService.ajasta(lahetaEmailPaattynytAjastettuTyo, this._firebase.firestoreProvider, batch)
    } else {
      this._ajastettuTyoService.poistaAjastusTyolla(lahetaEmailPaattynytAjastettuTyo, this._firebase.firestoreProvider, batch)
    }

    // A copy is made out of this in the constructor, so this contains the original, un-altered data.
    // If there is no loppuu, this is the _first_ lopetus save.
    // In that case, we must send notifications to slack.
    if (!this._data.kausi.loppuu) {

      const nykyinenHinta = this._asiakasJaettuService.annaNykyinenHinta(asiakasCopy)
      const kirjanpitajienNimitiedotMap = await firstValueFrom(this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable)
      const kirjanpitajanNimitiedot = kirjanpitajienNimitiedotMap.get(kirjanpitajanTiedot.uid)

      const slackMessageUri = 'tyojono/' + asiakasCopy.avain + '/send-slack-message/' + this.kausi.avain
      const slackMessageData: OutgoingSlackMessage = {
        channel: 'lopettavat_asiakkaat',
        created: this._timestampService.now(),
        message: `Asiakas: *${asiakasCopy.nimi}*
Hinta: ${this._currencyService.formatoiRahaIlmanValuuttaSymbolia(nykyinenHinta, 'fi')}
Sopimus päättyy: ${this._dateService.muotoilePaikallinenPaiva(this.kausi.loppuu, 'fi')}
Päättymisen syy: ${this._asiakkaanSopimuskausiService.annaLokalisoituSyy(this.kausi.lopettamisenSyy).nimi}
Lisätiedot: ${this.kausi.lisatiedot || 'Kirjanpitäjä ei kirjoittanut lisätietoja'}
Kirjanpitäjä: ${kirjanpitajanNimitiedot.etunimi + ' ' + kirjanpitajanNimitiedot.sukunimi}
Linkki: ${this._annaAsiakkaanLemonatorLinkki(asiakasCopy)}`,
        senderUid: kirjanpitajanTiedot.uid
      }
      batch.set(slackMessageUri, slackMessageData)
    }

    const onkoLaskujenVastaanottoPaalla = await firstValueFrom(this.onkoLaskujenVastaanottoPaallaObservable)
    if (onkoLaskujenVastaanottoPaalla) {
      const paivanLoppu = this._timestampService.dateToTimestamp(this._dateService.paivanLoppu(new Date()))
      const sopimuskausiLoppuuTimestamp = this._timestampService.localDateToTimestamp(this._dateService.lisaaPaiviaPaikallinen(this.kausi.loppuu, 1))
      const myohempiTimestamp = (paivanLoppu.toMillis() > sopimuskausiLoppuuTimestamp.toMillis()) ? paivanLoppu : sopimuskausiLoppuuTimestamp
      const lopetaMaksujenVastaanottoTimestamp = this.onkoPurku ? paivanLoppu : myohempiTimestamp

      const lopetaMaksujenVastaanottoTyoData: ApixMaksutLopetaVastaanottoTyojonoData = {
        asiakasAvain: asiakasCopy.avain
      }
      const lopetaMaksujenVastaanottoTyojonAvain = this.kausi.avain + 'lopeta-apix-maksujen-vastaanotto'
      const lopetaMaksujenVastaanottoTyojonoUri = this._apixUriService.getLopetaMaksujenVastaanottoTyojonoUri(asiakasCopy.avain, lopetaMaksujenVastaanottoTyojonAvain)
      const lopetaApixMaksujenVastaanottoAjastettuTyo: AjastettuTyo<ApixMaksutLopetaVastaanottoTyojonoData> = {
        ajettu: null,
        ajoaika: lopetaMaksujenVastaanottoTimestamp,
        avain: lopetaMaksujenVastaanottoTyojonAvain,
        kohde: lopetaMaksujenVastaanottoTyojonoUri,
        selite: 'Lopettaa asiakkaan ' + asiakasCopy.nimi + ' (' + asiakasCopy.ytunnus + ') Apixin maksujen vastaanoton sopimuksen ja päivittää ApixReceivingInvoiceConfig asetukset.',
        tyodata: lopetaMaksujenVastaanottoTyoData,
        tyyppi: 'lopeta-apix-maksujen-vastaanotto',
        asiakasAvain: asiakasCopy.avain
      }
      this._ajastettuTyoService.ajasta(lopetaApixMaksujenVastaanottoAjastettuTyo, this._firebase.firestoreProvider, batch)
    }

    return batch.commit().then(async () => {
      this._asiakasService.asetaNykyinenAsiakas(asiakasCopy)
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })

  }

  private _annaAsiakkaanLemonatorLinkki(asiakas: Asiakas): string {
    if (environment.environment === EnvironmentType.PRODUCTION) {
      return `https://lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.BETA) {
      return `https://beta.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.DEV) {
      return `https://dev.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    } else if (environment.environment === EnvironmentType.LOCAL_DEV) {
      return `https://local-dev.lemonator.lemontree.fi/asiakkaat/${asiakas.avain}/sopimukset`
    }
    throw new Error('Unknown environment ' + environment.environment)
  }

}
