import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core'

import { Asiakas } from '../../../_jaettu-lemonator/model/asiakas'
import { ViiaSyncAccountTransactionsJobData } from '../../../_jaettu/model/aiia'

import { AsiakasService } from '../../../_angular/service/asiakas/asiakas.service'


import { Subject, Observable, of as observableof, combineLatest } from 'rxjs'
import { switchMap, startWith, map, takeUntil } from 'rxjs/operators'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

interface ViiaSyncAccountTransactionsJobDataForDisplay extends ViiaSyncAccountTransactionsJobData {
  lastDelayReason: string
}

@Component({
  selector: '[app-aiia-tyohistoriat]',
  templateUrl: './aiia-tyot.component.html',
  styleUrls: ['./aiia-tyot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiiaTyotComponent implements OnInit, OnDestroy {

  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()

  historiaObservable: Observable<ViiaSyncAccountTransactionsJobDataForDisplay[]>
  form: UntypedFormGroup
  // dataObservable: ReplaySubject<any> = new ReplaySubject(1)

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
  ) { }

  ngOnInit() {

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.asiakas = result
    })

    this.form = new UntypedFormGroup({
      'historianMaara': new UntypedFormControl(null, Validators.required)
    })

    this.historiaObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.form.get('historianMaara').valueChanges.pipe(startWith('50'))]).pipe(
      switchMap(([asiakas, historianMaara]) => {

        if (!asiakas) {
          return observableof<ViiaSyncAccountTransactionsJobDataForDisplay[]>([])
        }

        const uri = 'tyojono/viia/viia-update-transactions-jobs'
        return this._firebaseLemonaid.firestoreCollection<ViiaSyncAccountTransactionsJobDataForDisplay>(uri).where('asiakasAvain', '==', asiakas.avain).limit(Number(historianMaara)).listen()
      }),
      map(res => res.sort((a, b) => a.delayedTimes - b.delayedTimes)),
      map(res => res.map(a => {
        if (a.delayedReasons?.length > 0) {
          a.lastDelayReason = JSON.stringify(a.delayedReasons[a.delayedReasons.length - 1])
        } else {
          a.lastDelayReason = 'Not delayed yet'
        }
        return a
      }))
    )

    this.form.get('historianMaara').setValue('50')
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
