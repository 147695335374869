<h1 class="raportti-header">
  Tulos
</h1>

<div class="tase-ensin" *ngIf="tulosEnsin">
  <button class="report-btn tulos-ensin-button" mat-button (click)="taseEnsinClicked.emit()">
    <div>Tase ensin</div>
  </button>
</div>

<ng-container *ngIf="tuloslaskelmanDataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">
    <div *ngIf="data.e == 'a'">
      Valittu päivämääräväli ei ole millään Lemonatorista löytyvällä tilikaudella.
    </div>
    <div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; text-align: right; line-height: 13px;">Päivitetty {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>
    <table *ngIf="data.c == 'c4'" class="report-table" (click)="handleClick($event, data)">
      <thead>
        <tr>
          <td></td>
          <td>{{data.c1.s | lnumberpvml:'fi'}}<br /> - {{data.c1.e | lnumberpvml:'fi'}}</td>
          <td>{{data.c2.s | lnumberpvml:'fi'}}<br /> - {{data.c2.e | lnumberpvml:'fi'}}</td>
          <td *ngIf="data.c3">{{data.c3.s | lnumberpvml:'fi'}}<br /> - {{data.c3.e | lnumberpvml:'fi'}}</td>
          <td *ngIf="!data.c3">Vertailuaikaväli<br />ei tilikaudella</td>
          <td>{{data.c4.s | lnumberpvml:'fi'}}<br /> - {{data.c4.e | lnumberpvml:'fi'}}</td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
          <tr [attr.data-tnro]="row.a" class="report-row-lvl-{{row.l}} report-row-acc-nmbr-length-{{row.a.length}}" [class.report-row-expanded]="row.e" [class.report-row-sum]="row.s">
            <td class="n">{{row.n}}</td>
            <td>{{row.s1 | number:'1.2-2'}}</td>
            <td>{{row.s2 | number:'1.2-2'}}</td>
            <td>{{row.s3 | number:'1.2-2'}}</td>
            <td>{{row.s4 | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}}">
            <td colspan="5">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
          <tr *ngIf="row.e && row.d" class="report-row-lvl-{{row.l}}">
            <td colspan="5">
              <div *ngIf="row.d.length == 0">
                Tilille ei löytynyt yhtään vientiä.
              </div>
              <table *ngIf="row.d.length > 0">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.n">
                  <tr *ngFor="let d of row.d; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}}&nbsp;</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}}&nbsp;</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <table *ngIf="data.c == 'c3'" class="report-table" (click)="handleClick($event, data)">
      <thead>
        <tr>
          <td></td>
          <td>{{data.c1.s | lnumberpvml:'fi'}}<br /> - {{data.c1.e | lnumberpvml:'fi'}}</td>
          <td>{{data.c2.s | lnumberpvml:'fi'}}<br /> - {{data.c2.e | lnumberpvml:'fi'}}</td>
          <td>{{data.c3.s | lnumberpvml:'fi'}}<br /> - {{data.c3.e | lnumberpvml:'fi'}}</td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
          <tr [attr.data-tnro]="row.a" class="report-row-lvl-{{row.l}} report-row-acc-nmbr-length-{{row.a.length}}" [class.report-row-expanded]="row.e" [class.report-row-sum]="row.s">
            <td class="n">{{row.n}}</td>
            <td>{{row.s1 | number:'1.2-2'}}</td>
            <td>{{row.s2 | number:'1.2-2'}}</td>
            <td>{{row.s3 | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}}">
            <td colspan="4">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
          <tr *ngIf="row.e && row.d" class="report-row-lvl-{{row.l}}">
            <td colspan="4">
              <div *ngIf="row.d.length == 0">
                Tilille ei löytynyt yhtään vientiä.
              </div>
              <table *ngIf="row.d.length > 0">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.n">
                  <tr *ngFor="let d of row.d; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}}&nbsp;</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}}&nbsp;</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <table *ngIf="data.c == 'c2'" class="report-table" (click)="handleClick($event, data)">
      <thead>
        <tr>
          <td></td>
          <td>{{data.c1.s | lnumberpvml:'fi'}}<br /> - {{data.c1.e | lnumberpvml:'fi'}}</td>
          <td>{{data.c2.s | lnumberpvml:'fi'}}<br /> - {{data.c2.e | lnumberpvml:'fi'}}</td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
          <tr [attr.data-tnro]="row.a" class="report-row-lvl-{{row.l}} report-row-acc-nmbr-length-{{row.a.length}}" [class.report-row-expanded]="row.e" [class.report-row-sum]="row.s">
            <td class="n">{{row.n}}</td>
            <td>{{row.s1 | number:'1.2-2'}}</td>
            <td>{{row.s2 | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}}">
            <td colspan="3">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
          <tr *ngIf="row.e && row.d" class="report-row-lvl-{{row.l}}">
            <td colspan="3">
              <div *ngIf="row.d.length == 0">
                Tilille ei löytynyt yhtään vientiä.
              </div>
              <table *ngIf="row.d.length > 0">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.n">
                  <tr *ngFor="let d of row.d; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}}</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}}</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <table *ngIf="data.c == 'c1'" class="report-table" (click)="handleClick($event, data)">
      <thead>
        <tr>
          <td></td>
          <td>{{data.c1?.s | lnumberpvml:'fi'}}<br /> - {{data.c1?.e | lnumberpvml:'fi'}}</td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
          <tr [attr.data-tnro]="row.a" class="report-row-lvl-{{row.l}} report-row-acc-nmbr-length-{{row.a.length}}" [class.report-row-expanded]="row.e" [class.report-row-sum]="row.s">
            <td class="n">{{row.n}}</td>
            <td>{{row.s1 | number:'1.2-2'}}</td>
          </tr>
          <tr *ngIf="row.e && row.d == undefined" class="report-row-lvl-{{row.l}}">
            <td colspan="2">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
          <tr *ngIf="row.e && row.d" class="report-row-lvl-{{row.l}}">
            <td colspan="2">
              <div *ngIf="row.d.length == 0">
                Tilille ei löytynyt yhtään vientiä.
              </div>
              <table *ngIf="row.d.length > 0">
                <thead>
                  <tr>
                    <td>Laji</td>
                    <td>Tosite</td>
                    <td>Pvm</td>
                    <td>Selite</td>
                    <td>ALV</td>
                    <td>Debet</td>
                    <td>Kredit</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody [attr.data-n]="row.n">
                  <tr *ngFor="let d of row.d; let i = index;" [attr.data-i]="i" [class.laajennettu]="d.l">
                    <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
                    <td [attr.data-n]="d.n">{{d.n}}</td>
                    <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
                    <td>
                      <span *ngIf="d.a" class="blue-text space-after">{{d.a}} &nbsp;</span>
                      <span *ngIf="d.c" class="cursive-text space-after">{{d.c}} &nbsp;</span>
                      {{d.r}} {{d.e}}
                    </td>
                    <td>{{d.v}}</td>
                    <td>{{d.d | number:'1.2-2'}}</td>
                    <td>{{d.k | number:'1.2-2'}}</td>
                    <td>{{d.s | number:'1.2-2'}}</td>
                  </tr>
                  <tr class="loppusaldo">
                    <td></td>
                    <td></td>
                    <td colspan="2">Yhteensä</td>
                    <td></td>
                    <td>{{row.dds | number:'1.2-2'}}</td>
                    <td>{{row.dks | number:'1.2-2'}}</td>
                    <td>{{row.dks - row.dds | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>