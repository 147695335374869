<div class="detail-page">

  <app-asiakas-otsikko otsikko="Asetukset, tuntemistiedot" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Tuntemistiedot (Lemonator): <a [href]="asiakastiedotEncodedUriObservable | async" target="_BLANK">{{asiakastiedotUriObservable | async}}</a></div>
    <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Rahanpesun selvitykset (Lemonator): <a [href]="selvityksetEncodedUriObservable | async" target="_BLANK">{{selvityksetUriObservable | async}}</a></div>
    <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">KYC (Lemonaid): <a [href]="kycEncodedUriObservable | async" target="_BLANK">{{kycUriObservable | async}}</a></div>
  </div>

  <div style="max-width: 450px;">
    <div class="light-blue-text otsikko">Perustiedot</div>
    <div *ngIf="perustiedotObservable | async; let perustiedot" class="info-box">
      <div>{{ perustiedot.nimi }}</div>
      <div>{{ perustiedot.yritysmuoto }}</div>
      <div>Y-tunnus: {{ perustiedot.ytunnus }}</div>
      <div>Tilikausi: {{ perustiedot.tilikausi }}</div>
    </div>
  </div>

  <div style="max-width: 1200px;">
    <div app-asiakas-ytj-tiedot></div>
  </div>

  <div style="margin-top: 60px;">
    <div class="light-blue-text otsikko">Asiakkaan edustaja</div>
    <ng-container *ngIf="asiakkaanEdustajatObservable | async; let asiakkaanEdustajat">
      <table *ngIf="asiakkaanEdustajat?.length" class="info-box">
        <thead>
          <th>Edustajan nimi</th>
          <th>Tunnistetun henkilön nimi</th>
          <th>Henkilötunnus</th>
          <th>Tunnistustapa</th>
          <th>Tunnistautumisaika</th>
          <th>PEP</th>
          <th>Kansallisuus</th>
        </thead>
        <tr *ngFor="let ae of asiakkaanEdustajat">
          <td>{{ae.edustajanNimi}}</td>
          <td>{{ae.tunnistautujanNimi}}</td>
          <td>{{ae.hetu}}</td>
          <td>{{ae.tunnistustapa}}</td>
          <td>{{ae.tunnistautumisaika}}</td>
          <td>{{ae.pep}}</td>
          <td>{{ae.kansallisuus}}</td>
        </tr>
      </table>
    </ng-container>
  </div>

  <div style="max-width: 700px;">
    <div class="light-blue-text otsikko">Sopimukset</div>
    <div *ngIf="asiakkaanSopimusHyvaksynnatObservable | async; let asiakkaanSopimusHyvaksynnat" class="info-box">
      <div *ngFor=" let sopimusHyvaksynta of asiakkaanSopimusHyvaksynnat" class="cont" style="display: flex;">
        <div>{{ sopimusHyvaksynta.versio }}</div>
        <div>{{ sopimusHyvaksynta.aika }}</div>
        <div>{{ sopimusHyvaksynta.allekirjoittaja }}</div>
      </div>
    </div>
  </div>


  <div style="max-width: 700px;">
    <div class="otsikko" style="margin-bottom: 10px;">
      <div class="light-blue-text">Kuvaus toiminnasta</div>
      <div style="font-size: 11.2px; margin-left: 5px;" class="dark-gray-text"> (Täytä tämä Asiakastiedot-sivulla)</div>
    </div>
    <div class="info-box dark-gray-text">
      <div [innerHTML]="kuvausObservable | async"></div>
    </div>
  </div>

  <div style="max-width: 625px">
    <app-asiakkaan-riskiarvio [asiakas]="asiakasObservable | async" [riskiarvioEditActiveErrorsObservable]="riskiarvioEditActiveErrorsObservable"></app-asiakkaan-riskiarvio>
    <app-rahanpesun-selvitykset [asiakas]="asiakasObservable | async"></app-rahanpesun-selvitykset>
    <app-osakkaat-hallitus-edunsaajat [asiakas]="asiakasObservable | async" (tuntemistiedotAntamatta)="updateTuntemistiedotAntamattaValidation($event)"></app-osakkaat-hallitus-edunsaajat>
  </div>

</div>