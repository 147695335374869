<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <h1 class="light-blue-text">Holvi-integraatiot</h1>

  <mat-tab-group>
    <mat-tab label="Ennakkoverolaskut">
      <form [formGroup]="ennakkoverolaskujenLuontiform">
        <div style="margin: 2em;">
          <h2 class="light-blue-text">Ennakkoverolaskujen luonti ja lähetys</h2>
          <div style="padding-bottom: 20px;"></div>
          <mat-form-field style="max-width: 200px;">
            <!-- [min]="annaMinimiDate(jakso)" -->
            <mat-label>Laskujen kuukausi</mat-label>
            <input matInput monthPicker #monthPicker [matDatepicker]="picker" formControlName="kuukausi" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-error *ngIf="kuukausi?.errors?.required && (kuukausi?.dirty || kuukausi?.touched)">Kuukausi puuttuu</mat-error>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field><br />
          <button mat-button class="primarybutton" (click)="luoEnnakkoverolaskut()" style="margin-right: 1em;">Luo laskut</button>
          <button mat-button class="primarybutton" (click)="lahetaEnnakkoverolaskut()">Lähetä laskut</button>
        </div>
      </form>
      <div>
        <h3>Lähetetään</h3>
        <div *ngFor="let lahetettava of lahetettavatEnnakkoverolaskutObservable | async">
          <pre>Asiakas: {{ lahetettava.asiakas }}
Viitenumero: "{{ lahetettava?.lahetysdata?.viitenumero }}", Summa: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.summa }}", Eräpäivä: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.erapaiva | lokaalipvm | async }}", Operaattori: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}", Osoite: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}"
Lopputulos: "{{ lahetettava?.lahetysdata?.tila }}"</pre>
        </div>

        <h3>Lähetetty jo (Ei lähetetä uudelleen)</h3>
        <div *ngFor="let lahetettava of lahetettyJoEnnakkoverolaskutObservable | async">
          <pre>Asiakas: {{ lahetettava.asiakas }}
Viitenumero: "{{ lahetettava?.lahetysdata?.viitenumero }}", Summa: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.summa }}", Eräpäivä: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.erapaiva | lokaalipvm | async }}", Operaattori: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}", Osoite: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}"
Lopputulos: "{{ lahetettava?.lahetysdata?.tila }}"</pre>
        </div>

        <h3>Ei lähetetä</h3>
        <div *ngFor="let lahetettava of eiLahetettavatEnnakkoverolaskutObservable | async">
          <pre>Asiakas: {{ lahetettava.asiakas }}
Viitenumero: "{{ lahetettava?.lahetysdata?.viitenumero }}", Summa: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.summa }}", Eräpäivä: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.erapaiva | lokaalipvm | async }}", Operaattori: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}", Osoite: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}"
Lopputulos: "{{ lahetettava?.lahetysdata?.tila }}"</pre>
        </div>

        <h3>Lähetyshistoria</h3>
        <div *ngFor="let lahetettava of historiaEnnakkoverolaskutObservable | async">
          <pre>Asiakas: {{ lahetettava.asiakas }}
Viitenumero: "{{ lahetettava?.lahetysdata?.viitenumero }}", Summa: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.summa }}", Eräpäivä: "{{ lahetettava?.lahetysdata?.ennakkoveronEra?.erapaiva | lokaalipvm | async }}", Operaattori: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}", Osoite: "{{ lahetettava?.lahetysdata?.sahkoinenLaskutusosoite?.sahkoinenValittaja }}"
Lopputulos: "{{ lahetettava?.lahetysdata?.tila }}"</pre>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>