import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, NgZone, ErrorHandler } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs'
import { startWith, switchMap, tap } from 'rxjs/operators'
import { KlikattuKirjaus, RaporttienHakuvaihtoehdot } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiVirallinenTuloslaskelmaData, RaporttiVirallinenTuloslaskelmaDataResponse, RaporttiVirallinenTuloslaskelmarivi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Timestamp } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

@Component({
  selector: '[app-kirjanpito-tuloslaskelma-virallinen]',
  templateUrl: './tuloslaskelma-virallinen.component.html',
  styleUrls: ['./tuloslaskelma-virallinen.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitTuloslaskelmaVirallinenComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  @Input() paivitaArvotHiljaisestiSubject: BehaviorSubject<number>
  @Input() tulosEnsin: boolean

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()
  @Output() taseEnsinClicked: EventEmitter<boolean> = new EventEmitter()

  lastSucessfullyUpdated: Timestamp

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  tuloslaskelmanDataObservable: Observable<RaporttiVirallinenTuloslaskelmaData>

  constructor(
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _timestampService: TimestampService,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnInit() {

    this.tuloslaskelmanDataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasObservable,
      this.hakuvaihtoehdotObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([asiakas, hakuvaihtoehdot, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu || !asiakas) {
          return of<RaporttiVirallinenTuloslaskelmaData>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.TULOS_VIRALLINEN,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        // if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        // if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        // if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiVirallinenTuloslaskelmaDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })
      }),
      startWith<RaporttiVirallinenTuloslaskelmaData>({
        c1Header: null,
        c2Header: null,
        c: 'c2',
        data: []
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  focusOnKirjaus(kirjausnumero: string) {
    this.kirjaustaKlikattiin.emit({ kirjausnumero: kirjausnumero })
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiVirallinenTuloslaskelmarivi) {
    return index
  }

}
