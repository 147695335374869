<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <form [formGroup]="form" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <mat-form-field floatPlaceholder="never" style="width: 350px; margin-right: 1.5vw;">
          <mat-label>Tili</mat-label>
          <mat-select formControlName="tili">
            <mat-option [value]="null">kaikki</mat-option>
            <mat-option *ngFor="let tiliJaPankki of tilitJaPankitObservable | async" [value]="tiliJaPankki.tili">
              {{tiliJaPankki.pankki.nimi}} {{tiliJaPankki.formatoituIban}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatPlaceholder="never" style="width: 150px; margin-right: 1.5vw;">
          <mat-label>Näytä</mat-label>
          <mat-select formControlName="tulleetTaiLahteneet">
            <mat-option [value]="null">kaikki</mat-option>
            <mat-option [value]="'tulleet'">tilille tulleet rahat</mat-option>
            <mat-option [value]="'lahteneet'">tililtä lähteneet rahat</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 250px; margin-right: 1.5vw;">
          <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
          <mat-label>Etsi tilitapahtumia</mat-label>
          <input type="text" formControlName="vapaahaku" matInput [name]="'a' + name" />
        </mat-form-field>

        <div>
          <div style="display: flex; flex-flow: row wrap;">
            <mat-form-field style="width: 140px; margin-right: 10px;">
              <mat-label>Näytä alkaen</mat-label>
              <input matInput [matDatepicker]="dp1" formControlName="alkaa">
              <!-- <mat-hint>Alkaa</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="width: 140px;">
              <mat-label>..loppuen</mat-label>
              <input matInput [matDatepicker]="dp2" formControlName="loppuu">
              <!-- <mat-hint>Loppuu</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <mat-checkbox style="margin-left: 1em; display: block;" formControlName="naytaKohdistukset">Näytä kohdistukset</mat-checkbox>

      </div>

      <div class="lisaa-nappula-container">
        <button mat-button (click)="naytaTiedostonValintaDialogi()" type="button" class="primarybutton mat-elevation-z4">
          <i style="font-size: 22px; line-height: 24px;" class="icon ion-ios-cloud-upload mat-icon"></i>
          Lisää tiliote
        </button>
      </div>
    </div>

  </form>

  <!-- <div class="red-text" style="padding: 1em;">Tiliotteen lisääminen on toistaiseksi poissa käytöstä reskontramuutosten vuoksi. Ilmoitamme, kun se on uudelleen käytettävissä.</div> -->

  <div style="margin-bottom: 14px;">Työjonossa on {{kohdistamattomiaTilitapahtumiaObservable | async}} tilitapahtuman kohdistamista</div>

  <div *ngIf="isDragging">
    <ngx-file-drop #droppi dropZoneClassName="maksutapa-container" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (onFileDrop)="fileDrop($event)" class="droppi">
      <ng-template ngx-file-drop-content-tmp>
        <div class="maksutapa-laatikko" style="display: flex;">
          <div>Rahaa tiedostot tänne</div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>

  <div *ngIf="latausvirhe" class="red-text" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{latausvirhe}}</div>
  <div *ngIf="latausOnnistui" class="green-text" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{latausOnnistui}}</div>

  <div style="position: relative;" #laskutaulu>
    <table mat-table multiTemplateDataRows class="laskut-table" matSort [dataSource]="dataSource" [matSortDisableClear]="false" style="min-height: 94px;">

      <ng-container matColumnDef="kknumero">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
        <td mat-cell *matCellDef="let row"> {{row.kknumero}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="avain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Avain </th>
        <td mat-cell *matCellDef="let row"> {{row.avain}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="lisatiedot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"> Lisätiedot </th>
        <td mat-cell *matCellDef="let row"> {{row.lisatiedot}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="maksaja">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Maksaja </th>
        <td mat-cell *matCellDef="let row"> {{row.maksaja}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="viite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Viitenumero </th>
        <td mat-cell *matCellDef="let row"> {{row.yliajoviite ? row.yliajoviite : row.viite}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="viesti">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"> Viesti </th>
        <td mat-cell *matCellDef="let row"> {{row.viesti}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="pvm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pvm </th>
        <td mat-cell *matCellDef="let row"> {{ row.pvml | lokaalipvm | async }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- <ng-container matColumnDef="kirjauspvm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Kirjauspäivä </th>
        <td mat-cell *matCellDef="let row"> {{ row.kirjauspvml | lokaalipvm | async }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container> -->

      <ng-container matColumnDef="summa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Summa </th>
        <td mat-cell *matCellDef="let row">{{ row.summa | lmoneyl: row.valuutta: 'fi' }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ (yhteensaObservable | async) | lmoneyl: 'EUR' : 'fi' }}</td>
      </ng-container>

      <ng-container matColumnDef="saldo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
        <td mat-cell *matCellDef="let row">{{ row.saldo | lmoneyl: row.valuutta: 'fi' }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
        <td mat-cell *matCellDef="let row"><a [href]="row.dataUrl" target="_BLANK">{{row.dataUri}}</a></td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="kohdistamatta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Kohdistam. </th>
        <td mat-cell *matCellDef="let row">{{ row.kohdistamatta | lmoneyl: row.valuutta: 'fi' }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ (kohdistamattaYhteensaObservable | async) | lmoneyl: 'EUR': 'fi' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="propertiesToDisplay"></tr>
      <tr class="normirivi" mat-row (click)="muokkaa(row)" *matRowDef="let row; columns: propertiesToDisplay"></tr>
      <tr class="kohdistuksetrivi" mat-row *matRowDef="let row; columns: ['kohdistukset'];"></tr>
      <tr mat-footer-row *matFooterRowDef="propertiesToDisplay"></tr>

      <!-- Secondary Column -->
      <ng-container matColumnDef="kohdistukset">
        <td mat-cell [attr.colspan]="propertiesToDisplay.length" *matCellDef="let row">
          <table *ngIf="naytaKohdistukset">
            <!-- <thead>
              <tr>
                <th>Laskuavain</th>
                <th>Laskunro</th>
                <th>Laskunsaaja</th>
                <th>Luotu</th>
                <th>Suoritus</th>
              </tr>
            </thead> -->
            <tbody>
              <!-- TODO: FIXME: REMOVE $any WHEN IVY IS SMART ENOUGH.. -->
              <tr *ngFor="let kohdistus of $any(row.lisatiedotObservable | async)?.kohdistukset">
                <td>{{kohdistus.laskuAvain }}</td>
                <td>{{kohdistus.laskunro}}</td>
                <td>{{kohdistus.laskunsaaja}}</td>
                <td>{{kohdistus.luotu | ltimestamptime | async}}</td>
                <td>{{kohdistus.suoritus}}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </ng-container>

    </table>
    <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!dataSource?.lataa && dataSource?.filteredData?.length == 0">
      <div style="width: 100%; padding-top: 40px; text-align: center;">
        Hakuehdoilla ei löydy yhtään tilitapahtumaa
      </div>
    </div>
    <div lemon-loading [show]="dataSource?.lataa"></div>
  </div>

  <mat-paginator #paginator [pageSizeOptions]="[50, 200, 500]">
  </mat-paginator>

</div>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" #fileInput />