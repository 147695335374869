import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { BankBalanceAddManuallyRequest, BankBalanceAddManuallyResponse, SahkoisenTiliotteenAlatyyppi } from 'app/_jaettu/model/tiliote'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { DateService } from 'app/_shared-core/service/date.service'

export interface KirjanpitoLisaaTiliotteenSaldoDialogData {
  asiakasAvain: string
  maksutapa: AsiakkaanMaksutapa
}

@Component({
  templateUrl: './kirjanpito-lisaa-tiliotteen-saldo.dialog.html'
})
export class KirjanpitoLisaaTiliotteenSaldoDialog {

  year: number

  bankBalanceForm = new FormGroup<{
    'bankBalanceDate': FormControl<Date>
    'bankBalanceSum': FormControl<number>
  }>({
    'bankBalanceDate': new FormControl(null, Validators.required),
    'bankBalanceSum': new FormControl(null, Validators.required)
  })


  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitoLisaaTiliotteenSaldoDialogData,
    private _dialogRef: MatDialogRef<KirjanpitoLisaaTiliotteenSaldoDialog>,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _lemonatorFirebase: FirebaseLemonator,
    private _currencyService: CurrencyService
  ) { }

  async addBankBalance() {

    if (!this.bankBalanceForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.bankBalanceForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      if (this._data?.maksutapa?.sahkoisenTiliotteenAlatyyppi !== SahkoisenTiliotteenAlatyyppi.AIIA) {
        throw new Error('Maksutapa type is ' + this._data.maksutapa?.sahkoisenTiliotteenAlatyyppi)
      }

      const bankBalanceDate = this.bankBalanceForm.get('bankBalanceDate').value
      const bankBalanceSum = this.bankBalanceForm.get('bankBalanceSum').value

      const req: BankBalanceAddManuallyRequest = {
        asiakasAvain: this._data.asiakasAvain,
        iban: this._data.maksutapa.sahkoisenTiliotteenTunniste,
        date: this._dateService.dateToNumber(bankBalanceDate),
        balance: bankBalanceSum,
        source: 'tiliote'
      }

      const resp = await this._lemonatorFirebase.functionsCall<BankBalanceAddManuallyRequest, BankBalanceAddManuallyResponse>('addBankBalanceManually', req)

      if (!resp || resp.e) {
        throw new Error('Call to addBankBalanceManually failed: ' + resp?.e ?? 'No response')
      }

      this._snackbar.open('Päiväsaldo ' + this._currencyService.formatoiRaha(bankBalanceSum, 'EUR', 'fi') + ' lisätty päivämäärälle ' + this._dateService.muotoile(bankBalanceDate, 'dd.MM.yyyy'), 'OK')
      this._dialogRef.close()


    } catch (err) {
      console.error('Failed to add Bank balance', err)
      this._snackbar.open('Päiväsaldon lisääminen epäonnistui!', 'OK')
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }
}

