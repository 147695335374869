import { Component, OnInit, OnDestroy, ErrorHandler, ChangeDetectorRef } from '@angular/core'
import { Router } from '@angular/router'

import { KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { VasenValikkoService } from 'app/_jaettu-angular/service/vasen-valikko.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { GoogleAuthProvider, signInWithCredential, signInWithPopup } from 'firebase/auth'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  commonError: string
  year: string = new Date().getFullYear() + ''

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _router: Router,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _vasenValikkoService: VasenValikkoService
  ) {

    setTimeout(() => {
      this._vasenValikkoService.paivitaAuki(false)
      this._changeDetectorRef.markForCheck()
    }, 5)

    this._ladataanService.aloitaLataaminen()
    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kirjanpitajanTiedot => {
      if (kirjanpitajanTiedot) {
        this._ladataanService.lopetaLataaminen()
        this._vasenValikkoService.paivitaAuki(true)
        this._router.navigate(['/'])
      }
    })

  }

  ngOnInit() {
    this._tryLogin()
  }

  private async _tryLogin() {
    try {

      this._kirjautunutKayttajaService.kirjautuminenOnKaynnissaSubject.next(true)

      console.log('Haetaan redirect resulttia.')

      // This gives you a Google Access Token. You can use it to access the Google API.
      // const result = await getRedirectResult(this._firebase.auth)
      // const credential = result ? GoogleAuthProvider.credentialFromResult(result) : null

      console.log('Ei Google autentikointia, aloitetaan alusta.')
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ 'hd': 'lemontree.fi' })
      const result = await signInWithPopup(this._firebase.auth, provider)
      const credential = result ? GoogleAuthProvider.credentialFromResult(result) : null

      console.log('Saatu vastaus.', result)
      console.log('Tarkistetaan onko jo credentiaaleja.')
      if (result && result.user && credential) {

        console.log('Googlekirjautuminen aloitetaan.')
        const [res1, res2] = await Promise.all([
          signInWithCredential(this._firebase.auth, credential),
          signInWithCredential(this._firebaseLemonaid.auth, credential)
        ])

        console.log('Googlekirjautuminen on valmis.')
        const lemonatorFuncResult = this._firebase.functionsCall<null, string>('kirjaudu', null)
        const lemontreeFuncResult = this._firebaseLemonaid.functionsCall<null, string>('lemonatorKirjaudu', null)

        console.log('Aloita callablet.')
        const [lemonatorResult, lemontreeResult] = await Promise.all([lemonatorFuncResult, lemontreeFuncResult])

        console.log('Callablet valmiit')
        // console.log(lemonatorResult.data, lemonaidResult.data)
        await Promise.all([
          this._firebase.authSignOut(),
          this._firebaseLemonaid.authSignOut()
        ])

        console.log('Uloskirjautuminen on valmis.')
        const [lemonatorCustomResult, lemontreeCustomResult] = await Promise.all([
          this._firebase.authSignInWithCustomToken(lemonatorResult),
          this._firebaseLemonaid.authSignInWithCustomToken(lemontreeResult)
        ])

        console.log('Kirjautuminen on valmis.')
        setTimeout(() => {
          // this._kirjautunutKayttajaService.kirjautuminenOnKaynnissaSubject.next(false)
          window.location.href = '/'
        }, 1000)

        // .then(lemonatorToken => {
        //   const payload: { idToken: string } = {
        //     idToken: lemonatorToken
        //   }
        //   const url = environment.environment === EnvironmentType.DEV ? '/api/1/autentikointi/alustaKeksiDev' : '/api/1/autentikointi/alustaKeksi'
        //   return this._httpService.postJsonGetJsonWithCredentials<{ status: 'success', expires: number }, { idToken: string }>(url, payload, LEMONATOR_HTTPS_API)
        // }).then(result => {
        //   if (result && result.expires) {
        //     this._cookieAuthReady.next(true)
        //   }
        // })


      } else {
        this._tryLogin()
      }

    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Kirjautumisen aikana tapahtui virhe. Ilmoita tästä ylläpidolle.'
      this._errorHandler.handleError(error)
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
