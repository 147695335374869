import { Injectable } from '@angular/core'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { LaskunLokalisoituTila } from '../../laskut/laskut.firestore.datasource'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { LaskuTransformingWrappingDataSource } from '../../_jaettu-angular/laskut/transforming-lasku.datasource'

import { Lasku, LaskunTila, LaskunumeroTyyppi } from '../../_jaettu/model/lasku'
import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { FirebaseLemonaid } from '../../_angular/service/firebase-lemonator.service'
import { LaskuIndeksoija } from 'app/_jaettu/service/lasku/lasku.indeksoija'



@Injectable()
export class MaksumuistutusDataSourceService extends MatTableDataSource<Lasku> {

  private subscription: Subscription = null
  private laskunTilaSubject = new BehaviorSubject<LaskunLokalisoituTila[]>([])
  public laskunTilaObservable: Observable<LaskunLokalisoituTila[]> = this.laskunTilaSubject.asObservable()
  private _deletedInvoices: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set<string>())

  dataSourceTransformed: LaskuTransformingWrappingDataSource = null
  lataa: boolean = false

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService,
    private _lemonTranslationService: LemonTranslationService,
    private _laskuService: LaskuService,
    private _laskuSharedService: LaskuSharedService,
    private _currencyService: CurrencyService,
    private _laskuIndeksoija: LaskuIndeksoija
  ) {

    super([])

    this.dataSourceTransformed = new LaskuTransformingWrappingDataSource(this, this._currencyService, this._dateService, this._laskuSharedService, this._lemonTranslationService)
    this._lemonTranslationService.currentLanguageObservable.subscribe(kieli => {

      const naytettavatTilat: string[] = []

      naytettavatTilat.push(LaskunTila.kaikki)
      naytettavatTilat.push(LaskunTila.avoin)
      naytettavatTilat.push(LaskunTila.luottotappio)
      naytettavatTilat.push(LaskunTila.maksettu)
      naytettavatTilat.push(LaskunTila.eraantynyt)
      naytettavatTilat.push(LaskunTila.maksettuLiikaa)
      naytettavatTilat.push(LaskunTila.luonnos)
      naytettavatTilat.push(LaskunTila.poistettu)
      naytettavatTilat.push(LaskunTila.mitatoity)

      const localizationKeys = []
      for (const tila of naytettavatTilat) {
        const localizationKey = 'lasku.listaus.tila.' + tila
        localizationKeys.push(localizationKey)
      }

      const tilat: LaskunLokalisoituTila[] = []

      for (const tila of naytettavatTilat) {
        tilat.push({
          tunnus: tila,
          nimi: this._lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + tila, kieli)
        })
      }

      tilat.sort((a, b): number => {
        if (a.tunnus === LaskunTila.kaikki) {
          return -1
        } else if (b.tunnus === LaskunTila.kaikki) {
          return 1
        }
        return a.nimi.localeCompare(b.nimi)
      })

      this.laskunTilaSubject.next(tilat)

    })

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    this.sortData = (data: Lasku[], sort: MatSort): Lasku[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      if (active === 'summa') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.summa
          const valueb = kb.summa
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'avoinna') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.avoinna
          const valueb = kb.avoinna
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'nro') {
        return data.sort((a, b) => {
          const valuea = this._laskuIndeksoija.annaNumeroLajitteluaVarten(a)
          const valueb = this._laskuIndeksoija.annaNumeroLajitteluaVarten(b)
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
          // const valuea = a.nro
          // const valueb = b.nro
          // if (valuea === null && valueb === null) {
          //   return 0
          // } else if (valuea === null) {
          //   return 1 * directionMultiplier
          // } else if (valueb === null) {
          //   return -1 * directionMultiplier
          // }
          // return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'pvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.pvm.toDate() : null
          const valueb = kb.pvm ? kb.pvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'erapvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.erapvm.toDate() : null
          const valueb = kb.pvm ? kb.erapvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'tila') {
        return data.sort((a, b) => {
          const valuea = a.tila
          const valueb = b.tila
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      return data

    }

  }

  connect(): BehaviorSubject<Lasku[]> {
    setTimeout(() => {
      this.startSubsctiption()
    }, 0)
    return super.connect()
  }

  disconnect(): void {
    this.stopSubscription()
  }

  private stopSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
    this.lataa = false
  }

  public deleteInvoice(invoiceId: string) {
    this._deletedInvoices.value.add(invoiceId)
    this._deletedInvoices.next(this._deletedInvoices.value)
  }

  public deleteAllInvoices(invoiceId: string) {
    for (const lasku of this.data) {
      if (invoiceId !== lasku.avain) {
        this._deletedInvoices.value.add(lasku.avain)
      }
    }
    this._deletedInvoices.next(this._deletedInvoices.value)
  }

  private startSubsctiption() {
    this.lataa = true
    this.data = []

    const rawFirestoreObservable = this._firebaseLemonaid.firestoreCollection<Lasku>('laskut/' + this._laskuService.LEMONTREE_ASIAKAS_ID + '/laskut')
      .where('tila', '==', LaskunTila.eraantynyt) // erääntynyt
      .listen().pipe(
        map(snapshots => {
          for (const snapshot of snapshots) {
            delete snapshot['haku']
            if (snapshot.korvaus) {
              for (const korvaava of snapshot.korvaus) {
                delete korvaava['haku']
              }
            }
          }
          return snapshots
        })
      )

    combineLatest([rawFirestoreObservable, this._deletedInvoices]).pipe(
      map(([invoices, deletedSet]) => {
        return invoices.filter(lasku => (!lasku.korvaus || lasku.korvaus.filter(korvaava => korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS).length < 1) && !deletedSet.has(lasku.avain))
      }),
    ).subscribe(laskut => {
      this.data = laskut
      this.lataa = false
    })
  }

}

