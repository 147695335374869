<mat-dialog-content>

  <lemon-dialog-header>
    Muokkaa viitenumeroa
  </lemon-dialog-header>

  <div class="tosite-tiedot">
    <form [formGroup]="form" novalidate style="margin: 0;">
      <mat-form-field>
        <input type="text" matInput [name]="namename" formControlName="viitenumero" placeholder="Viitenumero" required data-lpignore="true" />
        <mat-error *ngIf="viitenumero?.errors?.viitenumero && (viitenumero?.dirty || viitenumero?.touched)">
          Viitenumero ei ole validi
        </mat-error>
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>