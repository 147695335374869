import { Component, OnInit, Inject, ErrorHandler, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { MatCheckbox } from '@angular/material/checkbox'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { AsiakkaanKuukausiruutuStatusSaveRequest, AsiakkaanKuukausiruutuStatusSaveResponse, KirjanpitoKuukausiruudunTilinpaatoksenTila, ListausAsiakas } from 'app/_jaettu-lemonator/model/asiakas'

import { Observable, of, Subject } from 'rxjs'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms'
import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KuukausiruudunTiedot } from './asiakkaat.datasource.service'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { map, takeUntil } from 'rxjs/operators'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'

export interface AsiakkaanKirjanpitostatusKuukaudelleDialogData {
  asiakas: ListausAsiakas
  kuukausi: KuukausiruudunTiedot
}

@Component({
  templateUrl: './asiakkaan-kirjanpitostatus-kuukaudelle.dialog.html',
  styleUrls: ['./asiakkaan-kirjanpitostatus-kuukaudelle.dialog.css'],
})
export class AsiakkaanKirjanpitostatusKuukaudelleDialog implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('kirjanpitoaikaInput', { read: MatInput, static: true }) kirjanpitoaikaInput: MatInput
  @ViewChild('kirjanpitoTehtyCheckbox', { read: MatCheckbox, static: true }) kirjanpitoTehtyCheckbox: MatCheckbox

  // @ViewChild('tilinpaatosaikaInput', { read: MatInput }) tilinpaatosaikaInput: MatInput
  @ViewChild('tilinpaatosTehtyCheckbox', { read: MatCheckbox }) tilinpaatosTehtyCheckbox: MatCheckbox

  // @ViewChild('veroilmoitusaikaInput', { read: MatInput }) veroilmoitusaikaInput: MatInput
  @ViewChild('veroilmoitusTehtyCheckbox', { read: MatCheckbox }) veroilmoitusTehtyCheckbox: MatCheckbox

  // @ViewChild('poytakirjaaikaInput', { read: MatInput }) poytakirjaaikaInput: MatInput
  @ViewChild('poytakirjaPvmInput', { read: MatInput }) poytakirjaPvmInput: MatInput
  @ViewChild('poytakirjaTehtyCheckbox', { read: MatCheckbox }) poytakirjaTehtyCheckbox: MatCheckbox

  @ViewChild('aktiivinenCheckbox', { read: MatCheckbox, static: true }) aktiivinenCheckbox: MatCheckbox

  @ViewChild('muistiinpanotTextarea', { read: MatInput, static: true }) muistiinpanotTextarea: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  private onkoTilikaudenLoppu = false
  private onkoMurrettuTilikausi = false
  private onkoTmi = false

  form: UntypedFormGroup
  namename: string = 'assf' + Math.random()

  lokalisoituKuukausiVuosi = ''
  asiakas: ListausAsiakas = null
  kuukausi: KuukausiruudunTiedot = null
  hinta: number = 0
  teho: string = ''
  naytaTilinpaatos = false
  naytaVeroilmoitus = false
  naytaPoytakirja = false

  kuukausiruutuUriObservable: Observable<string>
  kuukausiruutuEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  private _getKuukausiruutuUri(d: AsiakkaanKirjanpitostatusKuukaudelleDialogData): string {
    if (d?.asiakas?.k && d?.kuukausi?.k) {
      const lm = this._dateService.numberToLocalMonth(d.kuukausi.k)
      return this._asiakasUriService.annaListausRuutuUri(d.asiakas.k, lm)
    }
    return ''
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanKirjanpitostatusKuukaudelleDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanKirjanpitostatusKuukaudelleDialog>,
    private _errorHandler: ErrorHandler,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ladataanService: LadataanService,
    private _formValidationService: FormValidationService,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator,
    private _snackbar: MatSnackBar,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService,
    private _asiakasUriService: AsiakasUriService
  ) {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

    const kuukausiruutuUri = this._getKuukausiruutuUri(this._data)
    this.kuukausiruutuUriObservable = of(kuukausiruutuUri)
    this.kuukausiruutuEncodedUriObservable = this.kuukausiruutuUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    const localMonth = this._dateService.numberToLocalMonth(this._data.kuukausi.k)
    this.lokalisoituKuukausiVuosi = _dateService.annaKuukaudenNimiPaikallinen(localMonth, 'fi') + ' ' + localMonth.year

    this.asiakas = this._data.asiakas
    this.kuukausi = this._data.kuukausi

    this.onkoTmi = this.asiakas.c === Yritysmuoto.TOIMINIMI
    this.onkoTilikaudenLoppu = !!this.kuukausi.l

    // Onko murrettu tilikausi
    this.onkoMurrettuTilikausi = !!this.kuukausi.bmt

    if (this.kuukausi.h || this.kuukausi.h === 0) {
      this.hinta = this.kuukausi.h
    }

    this.naytaTilinpaatos = this.onkoTilikaudenLoppu && (!this.onkoTmi || this.onkoMurrettuTilikausi)
    this.naytaVeroilmoitus = this.onkoTilikaudenLoppu
    this.naytaPoytakirja = this.onkoTilikaudenLoppu && !this.onkoTmi

    if (this.hinta && this.kuukausi.ka) {
      this.teho = this._currencyService.formatoiDesimaali(this.hinta / this.kuukausi.ka * 60, 2, 'fi')
    }

    const kirjanopitoTehty = !!this.kuukausi.kp
    const tilinpaatosTehty = !!this.kuukausi.tp
    const poytakirjaTehty = !!this.kuukausi.pp
    const veroilmoitusTehty = !!this.kuukausi.vp
    const aktiivinen = !this.kuukausi.bd
    const alvOverride = !!this.kuukausi.baok

    const initialKirjanpitoAika = this.kuukausi.ka ? Math.round(this.kuukausi.ka) : null

    this.form = new UntypedFormGroup({
      'aktiivinen': new UntypedFormControl(aktiivinen, []),

      'alvIlmoitusHoidettuJarjestelmanUlkopuolella': new UntypedFormControl({ value: alvOverride, disabled: !aktiivinen }, []),

      'tiedotOsinkojenMaksamisesta': new UntypedFormControl({ value: this.kuukausi.tom, disabled: !aktiivinen }, []),

      'kirjanpitoTehty': new UntypedFormControl({ value: kirjanopitoTehty, disabled: !aktiivinen }, []),
      'kirjanpitoPvm': new UntypedFormControl({ value: this._dateService.numberToDate(this.kuukausi.kp), disabled: !kirjanopitoTehty }, []),
      'kirjanpitoAika': new UntypedFormControl(initialKirjanpitoAika, [Validators.required, Validators.min(1), Validators.max(1440), this.annaVainNumerotValidator()]),

      'tilinpaatosTehty': new UntypedFormControl({ value: tilinpaatosTehty, disabled: !aktiivinen }, []),
      'tilinpaatosPvm': new UntypedFormControl({ value: this._dateService.numberToDate(this.kuukausi.tp), disabled: !tilinpaatosTehty }, tilinpaatosTehty ? [Validators.required] : []),
      // 'tilinpaatosAika': new UntypedFormControl({ value: this.kuukausi.ta, disabled: !tilinpaatosTehty }, tilinpaatosTehty ? [Validators.required] : []),

      'veroilmoitusTehty': new UntypedFormControl({ value: veroilmoitusTehty, disabled: !aktiivinen }, []),
      'veroilmoitusPvm': new UntypedFormControl({ value: this._dateService.numberToDate(this.kuukausi.vp), disabled: !veroilmoitusTehty }, veroilmoitusTehty ? [Validators.required] : []),
      // 'veroilmoitusAika': new UntypedFormControl({ value: this.kuukausi.va, disabled: !veroilmoitusTehty }, veroilmoitusTehty ? [Validators.required] : []),

      'poytakirjaTehty': new UntypedFormControl({ value: poytakirjaTehty, disabled: !aktiivinen }, []),
      'poytakirjaPvm': new UntypedFormControl({ value: this._dateService.numberToDate(this.kuukausi.pp), disabled: !poytakirjaTehty }, poytakirjaTehty ? [Validators.required] : []),
      // 'poytakirjaAika': new FormControl(this.kuukausi.poytakirjaAika, poytakirjaTehty ? [ Validators.required ] : []),

      'viesti': new UntypedFormControl(this.kuukausi.m, [])
    })

  }

  private annaVainNumerotValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && typeof control.value === 'string' && !this._currencyService.onkoMerkkijonoKokonaisluku(control.value.trim())) {
        return { 'number': true }
      }
      return null
    }
  }

  ngOnInit() {

    this.form.get('alvIlmoitusHoidettuJarjestelmanUlkopuolella').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value) {
        this.kuukausi.baok = 1
      } else {
        delete this.kuukausi.baok
      }
    })
    this.form.get('tiedotOsinkojenMaksamisesta').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value) {
        this.kuukausi.tom = value
      } else {
        delete this.kuukausi.tom
      }
    })

    this.form.get('kirjanpitoTehty').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.muutaOnkoKirjanpitoriviEstetty(!!value, false, true)
    })
    this.form.get('kirjanpitoPvm').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.kp = this._dateService.dateToNumber(value)
      if (!this.kuukausi.kp) {
        delete this.kuukausi.kp
      }
    })
    this.form.get('kirjanpitoAika').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.ka = this._currencyService.muutaMerkkijonoNumeroksi(value, 0)
      if (!this.kuukausi.ka) {
        delete this.kuukausi.ka
      }
      if (this.hinta && this.kuukausi.ka) {
        this.teho = this._currencyService.formatoiDesimaali(this.hinta / this.kuukausi.ka * 60, 2, 'fi')
      }
    })

    this.form.get('tilinpaatosTehty').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.muutaOnkoTilinpaatosriviEstetty(!!value, false, true)
    })
    this.form.get('tilinpaatosPvm').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.tp = this._dateService.dateToNumber(value)
      if (!this.kuukausi.tp) {
        delete this.kuukausi.tp
      }
    })
    // this.form.get('tilinpaatosAika').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
    //   this.kuukausi.ta = this._currencyService.muutaMerkkijonoNumeroksi(value, 0)
    //   if (!this.kuukausi.ta) {
    //     delete this.kuukausi.ta
    //   }
    // })

    this.form.get('veroilmoitusTehty').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.muutaOnkoVeroilmoitusriviEstetty(!!value, false, true)
    })
    this.form.get('veroilmoitusPvm').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.vp = this._dateService.dateToNumber(value)
      if (!this.kuukausi.vp) {
        delete this.kuukausi.vp
      }
    })
    // this.form.get('veroilmoitusAika').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
    //   this.kuukausi.va = this._currencyService.muutaMerkkijonoNumeroksi(value, 0)
    //   if (!this.kuukausi.va) {
    //     delete this.kuukausi.va
    //   }
    // })

    this.form.get('poytakirjaTehty').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.tpp = KirjanpitoKuukausiruudunTilinpaatoksenTila.VALMIS
      this.muutaOnkoPoytakirjariviEstetty(!!value, false, true)
    })
    this.form.get('poytakirjaPvm').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.kuukausi.pp = this._dateService.dateToNumber(value)
      if (!this.kuukausi.pp) {
        delete this.kuukausi.pp
      }
    })
    // this.form.get('poytakirjaAika').valueChanges.subscribe(value => {
    //   this.kuukausi.poytakirjaAika = this._currencyService.muutaMerkkijonoNumeroksi(value, 0)
    // })

    this.form.get('aktiivinen').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {

      if (!value) {
        this.kuukausi.bd = 1
      } else {
        delete this.kuukausi.bd
      }

      if (value) {
        this.form.get('kirjanpitoTehty').enable()
        if (this.naytaTilinpaatos) {
          this.form.get('tilinpaatosTehty').enable()
        }
        if (this.naytaVeroilmoitus) {
          this.form.get('veroilmoitusTehty').enable()
        }
        if (this.naytaPoytakirja) {
          this.form.get('poytakirjaTehty').enable()
        }
        this.form.get('alvIlmoitusHoidettuJarjestelmanUlkopuolella').enable()
        this.form.get('tiedotOsinkojenMaksamisesta').enable()
      } else {
        this.form.get('alvIlmoitusHoidettuJarjestelmanUlkopuolella').disable()
        this.form.get('tiedotOsinkojenMaksamisesta').disable()
        this.form.get('kirjanpitoTehty').disable()
        // this.form.get('kirjanpitoTehty').setValue(null)
        // this.form.get('kirjanpitoPvm').setValue(null)
        // this.form.get('kirjanpitoAika').setValue(null)
        if (this.naytaTilinpaatos) {
          this.form.get('tilinpaatosTehty').disable()
          // this.form.get('tilinpaatosTehty').setValue(null)
          // this.form.get('tilinpaatosPvm').setValue(null)
          // this.form.get('tilinpaatosAika').setValue(null)
        }
        if (this.naytaVeroilmoitus) {
          this.form.get('veroilmoitusTehty').disable()
          // this.form.get('veroilmoitusTehty').setValue(null)
          // this.form.get('veroilmoitusPvm').setValue(null)
          // this.form.get('veroilmoitusAika').setValue(null)
        }
        if (this.naytaPoytakirja) {
          this.form.get('poytakirjaTehty').disable()
          // this.form.get('poytakirjaTehty').setValue(null)
          // this.form.get('poytakirjaPvm').setValue(null)
        }
      }
      this.muutaOnkoKirjanpitoriviEstetty(value && this.form.get('kirjanpitoTehty').value, true, false)
      if (this.naytaTilinpaatos) {
        this.muutaOnkoTilinpaatosriviEstetty(value && this.form.get('tilinpaatosTehty').value, true, false)
      }
      if (this.naytaVeroilmoitus) {
        this.muutaOnkoVeroilmoitusriviEstetty(value && this.form.get('veroilmoitusTehty').value, true, false)
      }
      if (this.naytaPoytakirja) {
        this.muutaOnkoPoytakirjariviEstetty(value && this.form.get('poytakirjaTehty').value, true, false)
      }

      this.asetaFocusLomakkeelle()
    })

    this.form.get('viesti').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value && value.trim()) {
        this.kuukausi.m = value
      } else {
        delete this.kuukausi.m
      }
    })

  }

  ngAfterViewInit() {
    this.asetaFocusLomakkeelle()
  }

  private asetaFocusLomakkeelle() {
    setTimeout(() => {
      if (!this.kuukausi.d) {
        if (this.kirjanpitoTehtyCheckbox && (!this.kuukausi || !this.kuukausi.kp)) {
          this.kirjanpitoTehtyCheckbox.focus()
        } else if (this.tilinpaatosTehtyCheckbox && (this.kuukausi && this.naytaTilinpaatos && !this.kuukausi.tp)) {
          this.tilinpaatosTehtyCheckbox.focus()
        } else if (this.veroilmoitusTehtyCheckbox && (this.kuukausi && this.naytaVeroilmoitus && !this.kuukausi.vp)) {
          this.veroilmoitusTehtyCheckbox.focus()
        } else if (this.poytakirjaTehtyCheckbox && (this.kuukausi && this.naytaPoytakirja && !this.kuukausi.pp)) {
          this.poytakirjaTehtyCheckbox.focus()
        } else {
          this.muistiinpanotTextarea.focus()
          // this.kirjanpitoaikaInput.focus()
        }
      }
      this._changeDetectorRef.markForCheck()
    }, 10)
  }

  private muutaOnkoTilinpaatosriviEstetty(value: boolean, alaAsetaFokusta: boolean, clearField: boolean) {
    if (value) {
      this.form.get('tilinpaatosPvm').enable()
      // this.form.get('tilinpaatosAika').enable()
      this.form.get('tilinpaatosPvm').setValidators([Validators.required])
      // this.form.get('tilinpaatosAika').setValidators([Validators.required, Validators.min(1), Validators.max(1440), this.annaVainNumerotValidator()])
      if (!this.kuukausi.tp &&
        // this.tilinpaatosaikaInput &&
        !alaAsetaFokusta) {
        this.form.get('tilinpaatosPvm').setValue(new Date())
        // setTimeout(() => {
        //   this.tilinpaatosaikaInput.focus()
        //   this._changeDetectorRef.markForCheck()
        // }, 10)
      }
    } else {
      this.form.get('tilinpaatosPvm').clearValidators()
      // this.form.get('tilinpaatosAika').clearValidators()
      if (clearField) {
        this.form.get('tilinpaatosPvm').setValue(null)
        // this.form.get('tilinpaatosAika').setValue(null)
      }
      this.form.get('tilinpaatosPvm').disable()
      // this.form.get('tilinpaatosAika').disable()
    }
  }

  private muutaOnkoKirjanpitoriviEstetty(value: boolean, alaAsetaFokusta: boolean, clearField: boolean) {
    if (value) {
      this.form.get('kirjanpitoPvm').setValidators([Validators.required])
      // this.form.get('kirjanpitoAika').setValidators([Validators.required, Validators.min(1), Validators.max(1440), this.annaVainNumerotValidator()])
      this.form.get('kirjanpitoPvm').enable()
      // this.form.get('kirjanpitoAika').enable()
      if (!this.kuukausi.kp &&
        // this.kirjanpitoaikaInput &&
        !alaAsetaFokusta) {
        this.form.get('kirjanpitoPvm').setValue(new Date())
        // setTimeout(() => {
        //   this.kirjanpitoaikaInput.focus()
        //   this._changeDetectorRef.markForCheck()
        // }, 10)
      }
    } else {
      this.form.get('kirjanpitoPvm').clearValidators()
      // this.form.get('kirjanpitoAika').clearValidators()
      if (clearField) {
        this.form.get('kirjanpitoPvm').setValue(null)
        // this.form.get('kirjanpitoAika').setValue(null)
      }
      this.form.get('kirjanpitoPvm').disable()
      // this.form.get('kirjanpitoAika').disable()
    }
  }

  private muutaOnkoVeroilmoitusriviEstetty(value: boolean, alaAsetaFokusta: boolean, clearField: boolean) {
    if (value) {
      this.form.get('veroilmoitusPvm').enable()
      // this.form.get('veroilmoitusAika').enable()
      this.form.get('veroilmoitusPvm').setValidators([Validators.required])
      // this.form.get('veroilmoitusAika').setValidators([Validators.required, Validators.min(1), Validators.max(1440), this.annaVainNumerotValidator()])
      if (!this.kuukausi.vp &&
        // this.veroilmoitusaikaInput &&
        !alaAsetaFokusta) {
        this.form.get('veroilmoitusPvm').setValue(new Date())
        // setTimeout(() => {
        //   this.veroilmoitusaikaInput.focus()
        //   this._changeDetectorRef.markForCheck()
        // }, 10)
      }
    } else {
      this.form.get('veroilmoitusPvm').clearValidators()
      // this.form.get('veroilmoitusAika').clearValidators()
      if (clearField) {
        this.form.get('veroilmoitusPvm').setValue(null)
        // this.form.get('veroilmoitusAika').setValue(null)
      }
      this.form.get('veroilmoitusPvm').disable()
      // this.form.get('veroilmoitusAika').disable()
    }
  }

  private muutaOnkoPoytakirjariviEstetty(value: boolean, alaAsetaFokusta: boolean, clearField: boolean) {
    if (value) {
      this.form.get('poytakirjaPvm').enable()
      // this.form.get('poytakirjaAika').enable()
      this.form.get('poytakirjaPvm').setValidators([Validators.required])
      // this.form.get('poytakirjaAika').setValidators([ Validators.min(1), Validators.required, Validators.max(1440), this.annaVainNumerotValidator() ])
      if (!this.kuukausi.pp && this.poytakirjaPvmInput && !alaAsetaFokusta) {
        this.form.get('poytakirjaPvm').setValue(new Date())
        setTimeout(() => {
          this.poytakirjaPvmInput.focus()
          this._changeDetectorRef.markForCheck()
        }, 10)
      }
    } else {
      this.form.get('poytakirjaPvm').clearValidators()
      // this.form.get('poytakirjaAika').clearValidators()
      if (clearField) {
        this.form.get('poytakirjaPvm').setValue(null)
      }
      // this.form.get('poytakirjaAika').setValue(null)
      this.form.get('poytakirjaPvm').disable()
      // this.form.get('poytakirjaAika').disable()
    }
  }

  // get tilinpaatosAika(): AbstractControl {
  //   return this.form.get('tilinpaatosAika')
  // }

  // get veroilmoitusAika(): AbstractControl {
  //   return this.form.get('veroilmoitusAika')
  // }

  get kirjanpitoAika(): AbstractControl {
    return this.form.get('kirjanpitoAika')
  }

  async tallenna() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    this._ladataanService.aloitaLataaminen()

    try {

      // Remove "waits for dividends to be registered", if accountant marks as registered.
      if (this.kuukausi.tom) {
        delete this.kuukausi.oor
      }

      const data: AsiakkaanKuukausiruutuStatusSaveRequest = {
        asiakasAvain: this._data.asiakas.k,
        ruutu: this.kuukausi
      }

      return this._firebase.functionsCall<AsiakkaanKuukausiruutuStatusSaveRequest, AsiakkaanKuukausiruutuStatusSaveResponse>('kuukausiruutuPaivitaStatus', data).then(resp => {
        this._ladataanService.lopetaLataaminen()
        if (!resp || resp.e) {
          this._errorHandler.handleError(new Error(resp ? resp.e : 'unknown-error'))
          this._snackbar.open('Tallennus epäonnistui, yritä uudelleen', 'OK', { duration: 5000 })
        } else {
          this._dialogRef.close()
        }
      })
    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
