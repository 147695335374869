import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Input, ViewChild, ErrorHandler, ElementRef, HostListener, NgZone } from '@angular/core'

import { NaytettavaKirjaus } from '../kirjanpito.component'
import { KirjanpitoImageHandlerService, TositeJaNaytettavaSivut } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { KirjanpitoImageService } from 'app/_angular/service/kirjanpito/kirjanpito-image.service'

import { KirjaukseenLiitettyjenTiedostojenSivut, Kirjaus, KirjauksenLiitetiedostonTallennuspyynto, KirjaukseenLiitetynTiedostonSivunTyyppi, KirjattavaKuitti, KirjattavaLasku } from 'app/_jaettu-lemonator/model/kirjanpito'

import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'

import { DragAndDropService } from 'app/_jaettu-angular/service/drag-and-drop.service'

import { Subject, Observable, combineLatest, of, merge, BehaviorSubject, firstValueFrom } from 'rxjs'
import { takeUntil, switchMap, map, tap, startWith, distinctUntilChanged, filter } from 'rxjs/operators'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { AsiakasService, AsiakkaanAvainTiedot } from 'app/_angular/service/asiakas/asiakas.service'
import { DomSanitizer } from '@angular/platform-browser'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { MatSnackBar } from '@angular/material/snack-bar'
import { NaytettavaRaahattava } from './tosite-kirjaamattomat.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Laskuasetukset } from 'app/_jaettu/model/lasku'
import { KirjanpitoTositeService } from 'app/_angular/service/kirjanpito/kirjanpito-tosite.service'
import { InfoDialog, InfoDialogData } from '../../_jaettu-angular/_components/info.dialog'
import { MatDialog } from '@angular/material/dialog'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

export interface KirjaukseenLiitettyjenTiedostojenSivutAvaimella extends KirjaukseenLiitettyjenTiedostojenSivut {
  avain: string
}

interface RawData {
  asiakas: AsiakkaanAvainTiedot
  kirjauksenAvain: string
  laskuasetukset: Laskuasetukset
  sivut: KirjaukseenLiitettyjenTiedostojenSivut
}

@Component({
  selector: '[app-kirjanpito-tosite-nayta-kirjauksen-tositteet]',
  templateUrl: './tosite-nayta-kirjauksen-tositteet.component.html',
  styleUrls: ['./tosite-nayta-kirjauksen-tositteet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeInAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(2000)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(0, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('fastFadeInOutAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(150)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoTositeNaytaKirjauksenTositteet implements OnInit, OnDestroy {

  @ViewChild('mainDiv', { static: false }) mainDivRef: ElementRef<HTMLDivElement>
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>

  private _ngUnsubscribe = new Subject<void>()

  naytettavatTositteetJaSivutObservable: BehaviorSubject<TositeJaNaytettavaSivut[]> = new BehaviorSubject([])

  eiYhtaanKuvaaObservable: Observable<boolean>
  showZoomControls: boolean = false
  showRotate: boolean = false

  private _metadataMapForMonth: Map<string, KirjaukseenLiitettyjenTiedostojenSivut> = new Map()

  @Input() naytettavaKirjausObservable: Observable<NaytettavaKirjaus>
  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Input() laskuasetuksetObservable: Observable<Laskuasetukset>
  @Input() naytaPoistetutKirjauksetObservable: Observable<boolean>
  @Input() isMainEnhancedOn: boolean = false

  private state: Map<string, Set<string>> = new Map()

  // @Output() sivuVaihdettiin: EventEmitter<void> = new EventEmitter()
  // @Output() ruksiaNapautettiin: EventEmitter<SivuJaAvain> = new EventEmitter()

  constructor(
    private _kirjanpitoTositeService: KirjanpitoTositeService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _timestampProvider: TimestampProviderBase,
    private _dndService: DragAndDropService,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _sanitizer: DomSanitizer,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService,
    private _kirjanpitoImageService: KirjanpitoImageService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _ngZone: NgZone,
    private _dialog: MatDialog,
    private _currencyService: CurrencyService
  ) { }

  ngOnInit() {

    console.log('Init')

    // combineLatest([this.valittuKuukausiObservable, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
    //   // Tyhjennä välimuisti
    //   tap(() => {
    //     this._metadataMapForMonth.clear()
    //   }),
    //   // Lataa kuukauden liitetyt tiedostot
    //   switchMap(([kuukausi, asiakas]) => {
    //     if (!kuukausi || !asiakas) {
    //       return of<{ sivut: KirjaukseenLiitettyjenTiedostojenSivutAvaimella[], kuukausi: LocalMonth, asiakas: AsiakkaanAvainTiedot }>(null)
    //     }
    //     const year = kuukausi.year - 2000
    //     const month = (kuukausi.month + '').padStart(2, '0')
    //     const startDay = '00'
    //     const endDay = '32'

    //     const start = Number(year + month + startDay)
    //     const end = Number(year + month + endDay)

    //     // console.log('Searching between ', start, end)

    //     const collectionUri = this._kirjanpitoUriService.annaKirjaukseenLiitettyjenTiedostojenCollectionUri(asiakas.avain)
    //     // console.log('Start search at ', new Date())
    //     return this._firebase.firestoreCollection<KirjaukseenLiitettyjenTiedostojenSivutAvaimella>(collectionUri)
    //       .where('p', '>', start).where('p', '<', end).orderBy('p', 'asc') // .limit(2500)
    //       .listenChanges().pipe(
    //         startWith([]),
    //         map(liitetytSivutDocs => {
    //           const all = liitetytSivutDocs.map(s => {
    //             const dat = s.doc.data()
    //             dat.avain = s.doc.id
    //             this._metadataMapForMonth.set(s.doc.id, s.doc.data())
    //             return dat
    //           })
    //           return { sivut: all, kuukausi: kuukausi, asiakas: asiakas }
    //         })
    //       )
    //   }),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(async data => {
    // if (data) {
    //   this._kirjanpitoImageService.lisaaKirjauksienTiedostotCacheen(data.asiakas, data.kuukausi, data.sivut)
    // }
    // })

    this._dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(dragging => {
      if (dragging) {
        document.body.classList.add('dragging')
      } else {
        document.body.classList.remove('dragging')
      }
    })

    // Näissä tapauksissa lisää
    merge(
      this._kirjanpitoImageHandlerService.tositteenLiittaminenAloitettiinObservable
      // .pipe(
      //   tap(a => a.tallennetaan = true)
      // )
      ,
      this._kirjanpitoImageHandlerService.tositteenLiitoksenPoistoEpaonnistuiObservable
      // .pipe(
      //   tap(a => {
      //     delete a.tallennetaan
      //     delete a.poistetaan
      //   })
      // )
    ).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tosite => {
      const all = this.naytettavatTositteetJaSivutObservable.value
      if (!tosite || !all) {
        return
      }
      if (!all.find(a => a.avain === tosite.avain)) {
        const set: Set<string> = new Set()
        for (const siv of tosite.sivut) {
          set.add(siv.avain)
        }
        this.state.set(tosite.avain, set)
        all.push(tosite)
        this.naytettavatTositteetJaSivutObservable.next([...all])
      }
    })

    // Näissä tapauksissa lopeta latausmerkintä
    merge(
      this._kirjanpitoImageHandlerService.tositteenLiittaminenOnnistuiObservable
    ).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tosite => {
      const all = this.naytettavatTositteetJaSivutObservable.value
      if (!tosite || !all) {
        return
      }
      const found = all.find(a => a.avain === tosite.avain)
      if (found) {
        delete found.tallennetaan
        this.naytettavatTositteetJaSivutObservable.next([...all])
      }
    })

    // Näissä tapauksissa poista
    merge(
      this._kirjanpitoImageHandlerService.tositteenLiittaminenEpaonnistuiObservable.pipe(
        tap(a => {
          delete a.tallennetaan
          delete a.poistetaan
        })
      ),
      this._kirjanpitoImageHandlerService.tositteenLiitoksenPoistoAloitettiinObservable.pipe(
        tap(a => a.poistetaan = true)
      )
    ).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tosite => {
      const all = this.naytettavatTositteetJaSivutObservable.value
      if (!tosite || !all) {
        return
      }
      const index = all.findIndex(a => a.avain === tosite.avain)
      if (-1 < index) {
        all.splice(index, 1)
        this.naytettavatTositteetJaSivutObservable.next([...all])
      }
    })

    const valitunKirjauksenAvainObservable = this.naytettavaKirjausObservable.pipe(
      map(naytettavaKirjaus => {
        return naytettavaKirjaus?.kirjaus?.avain || null
      }),
      distinctUntilChanged()
    )

    const rawDataObservable: Observable<RawData> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.laskuasetuksetObservable,
      valitunKirjauksenAvainObservable
    ]).pipe(
      switchMap(([asiakas, laskuasetukset, kirjauksenAvain]) => {
        this.naytettavatTositteetJaSivutObservable.next(null)
        this.state.clear()
        if (asiakas && laskuasetukset && kirjauksenAvain) {

          const uri = this._kirjanpitoUriService.annaKirjaukseenLiitettyjenTiedostojenUri(asiakas.avain, kirjauksenAvain)
          // console.log('Load from ' + uri)
          const doc = this._firebase.firestoreDoc<KirjaukseenLiitettyjenTiedostojenSivut>(uri)
          const obs = doc.listen().pipe(
            map(sivut => {
              // console.log('Got change from DB', sivut)
              if (sivut) {
                this._metadataMapForMonth.set(kirjauksenAvain, sivut)
              }
              const data: RawData = { asiakas: asiakas, kirjauksenAvain: kirjauksenAvain, sivut: sivut, laskuasetukset: laskuasetukset }
              return data
            })
          )

          const kakusta = this._metadataMapForMonth.get(kirjauksenAvain)
          if (kakusta) {
            return obs.pipe(
              startWith({ asiakas: asiakas, kirjauksenAvain: kirjauksenAvain, sivut: kakusta, laskuasetukset: laskuasetukset })
            )
          }

          return obs

        }
        return of<RawData>(null)
      }),
      distinctUntilChanged((a, b) => {
        if (
          (a && !b) ||
          (!a && b)
        ) {
          return false
        }
        if (!a && !b) {
          return true
        }
        if (a.kirjauksenAvain !== b.kirjauksenAvain) {
          return false
        }
        if (Object.keys(a.sivut?.t || []).length !== Object.keys(b.sivut?.t || []).length) {
          return false
        }
        return true
      })
    )

    combineLatest([rawDataObservable, this.naytaPoistetutKirjauksetObservable]).pipe(
      switchMap(([rawData, naytaPoistetutKirjaukset]) => {
        return this._muunnaNaytetyiksiTositteiksi(rawData, naytaPoistetutKirjaukset)
      }),
      filter(a => {
        // console.log('CHECK', a)
        if (a?.length) {
          for (const tos of a) {
            const tositteenSivutState = this.state.get(tos.avain)
            if (!tositteenSivutState) {
              return true
            }
            for (const siv of tos.sivut) {
              if (!tositteenSivutState.has(siv.avain)) {
                return true
              }
            }
          }
          return false
        }
        return true
      }),
      tap(a => {
        // console.log('PASS')
        // Update state
        if (a) {
          for (const tos of a) {
            const set: Set<string> = new Set()
            for (const siv of tos.sivut) {
              set.add(siv.avain)
            }
            this.state.set(tos.avain, set)
          }
        }
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tositteet => {
      // this._tyhjennaValittuTositeJaSivuJosTarpeellista(tositteet)
      // this._valitseEnsimmainenSivuJosEiValittua(tositteet)
      this.naytettavatTositteetJaSivutObservable.next(tositteet)

      if (this.mainDivRef) {
        this.mainDivRef.nativeElement.scrollTo(0, 0)
      }
      // console.log(sivut)
    })

  }

  private async _muunnaNaytetyiksiTositteiksi(rawData: RawData, naytaPoistetutKirjaukset: boolean): Promise<TositeJaNaytettavaSivut[]> {
    if (!rawData) {
      return null
    }
    const lisaaminenKesken = rawData.kirjauksenAvain ? this._kirjanpitoImageHandlerService.annaTositteetJoidenLisaaminenOnKesken(rawData.kirjauksenAvain) : null
    const poistoKesken = rawData.kirjauksenAvain ? this._kirjanpitoImageHandlerService.annaTositteetJoidenPoistaminenOnKesken(rawData.kirjauksenAvain) : null
    return this._kirjanpitoImageHandlerService.muunnaTositteiksiJaSivuiksi(rawData.asiakas, rawData.sivut, rawData.laskuasetukset, lisaaminenKesken, poistoKesken, naytaPoistetutKirjaukset)
  }

  lisaaTiedostoja() {
    this.fileInput.nativeElement.click()
  }
  async fileChanged(event: Event) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const naytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)
    const target: HTMLInputElement = event.target as HTMLInputElement

    if (!asiakas || !naytettavaKirjaus?.kirjaus || !target?.files?.length) {
      return
    }

    const list: FileList = target.files

    const files: File[] = []
    let firstTooBigFile: File = null
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i)
      files.push(file)
      if (file.size > 1024 * 1024 * 8) {
        firstTooBigFile = file
        break
      }
    }

    if (firstTooBigFile) {
      const data: InfoDialogData = {
        header: 'Tiedosto on liian suuri',
        text: 'Tiedosto ' + firstTooBigFile.name + ' on liian suuri. (' + this._currencyService.roundHalfUp(firstTooBigFile.size / (1024 * 1024), 2) + 'MT) Suurin sallittu koko on 8MT.'
      }
      this._dialog.open(InfoDialog, { data: data })
      return
    }


    const time = new Date().getTime()
    let o = 1
    for (const file of files) {
      this.liitaTiedostoKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, file, time, o)
      o++
    }

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  onDragEnter(event: DragEvent) {
    const element = event.target as HTMLElement
    element.classList.add('drag-selected')
  }

  onDragLeave(event: DragEvent) {
    const element = event.target as HTMLElement
    element.classList.remove('drag-selected')
  }

  @HostListener('document:dragover', ['$event'])
  @HostListener('document:drop', ['$event'])
  onDragDropFileVerifyZone(event: DragEvent) {
    const target = event?.target as HTMLElement
    if (
      target.matches('.kirjauksen-tositteet-drop-area-initally-hidden') ||
      target.matches('.kirjauksen-tositteet-drop-area')
    ) {
      // In drop zone. I don't want listeners later in event-chain to meddle in here
      // event.stopPropagation()
      event.dataTransfer.effectAllowed = 'copy'
      event.dataTransfer.dropEffect = 'copy'
    } else {
      // Outside of drop zone! Prevent default action, and do not show copy/move icon
      event.preventDefault()
      event.dataTransfer.effectAllowed = 'none'
      event.dataTransfer.dropEffect = 'none'
    }
  }

  async onDrop(event: DragEvent) {

    event.preventDefault()
    event.stopImmediatePropagation()

    this._dndService.setDragging(false)

    const element = event.target as HTMLElement
    element.classList.remove('drag-selected')
    document.body.classList.remove('dragging')

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const naytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)

    if (!asiakas || !naytettavaKirjaus?.kirjaus) {
      return
    }

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {

      const files: File[] = []
      let firstTooBigFile: File = null
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i]
        files.push(file)
        if (file.size > 1024 * 1024 * 8) {
          firstTooBigFile = file
          break
        }
      }

      if (firstTooBigFile) {
        const data: InfoDialogData = {
          header: 'Tiedosto on liian suuri',
          text: 'Tiedosto ' + firstTooBigFile.name + ' on liian suuri. (' + this._currencyService.roundHalfUp(firstTooBigFile.size / (1024 * 1024), 2) + 'MT) Suurin sallittu koko on 8MT.'
        }
        this._dialog.open(InfoDialog, { data: data })
        return
      }

      const currentTime = new Date().getTime()
      let o = 1
      for (const file of files) {
        this.liitaTiedostoKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, file, currentTime, o)
        o++
      }

    }

    const kuittiAsString = event.dataTransfer.getData('kuitti')
    if (kuittiAsString) {
      const kirjattavatKuitti = this._timestampProvider.fixTimestamps(JSON.parse(kuittiAsString)) as KirjattavaKuitti
      if (!kirjattavatKuitti) { return }
      this._kirjanpitoTositeService.liitaKuittiKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, kirjattavatKuitti).catch(err => {
        this._errorHandler.handleError(err)
        this._snackbar.open('Tositteen liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
      })
    }

    const laskuAsString = event.dataTransfer.getData('lasku')
    if (laskuAsString) {
      const kirjattavaLasku = this._timestampProvider.fixTimestamps(JSON.parse(laskuAsString)) as KirjattavaLasku
      if (!kirjattavaLasku) { return }
      const laskuasetukset = await firstValueFrom(this._asiakasService.nykyisenAsiakkaanLaskuasetuksetObservable)
      this._kirjanpitoTositeService.liitaLaskuKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, kirjattavaLasku, laskuasetukset).catch(err => {
        this._errorHandler.handleError(err)
        this._snackbar.open('Laskun liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
      })
    }

    const raahattavaAsString = event.dataTransfer.getData('raahattava')
    if (raahattavaAsString) {
      const kirjaamatonRaahattava = this._timestampProvider.fixTimestamps(JSON.parse(raahattavaAsString)) as NaytettavaRaahattava
      if (!kirjaamatonRaahattava) { return }
      this._kirjanpitoTositeService.liitaRahaattuKirjaukseen(asiakas, naytettavaKirjaus.kirjaus, kirjaamatonRaahattava).catch(err => {
        this._errorHandler.handleError(err)
        this._snackbar.open('Kirjanpitäjän lisäämän tiedoston liittäminen epäonnistui. Yritä uudelleen.', 'OK', { duration: 2500 })
      })
    }

  }

  async liitaTiedostoKirjaukseen(asiakas: AsiakkaanAvainTiedot, kirjaus: Kirjaus, file: File, currentTime: number, o: number) {

    const onkoPdf = file.name.endsWith('pdf') || file.type.toLowerCase() === 'application/pdf'

    const raahattujenSivut: KirjaukseenLiitettyjenTiedostojenSivut = { p: 0, t: {} }
    const kuvanAvain = this._firebase.firestoreCreateId()
    raahattujenSivut.t[kuvanAvain] = {
      o: currentTime + o,
      t: onkoPdf ? KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_PDF : KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_WEBP
    }

    const base64Data = await this._tiedostojenLataamisService.getAsDataUrl(file)
    const safeUrl = onkoPdf ? base64Data : this._sanitizer.bypassSecurityTrustUrl(base64Data)
    // const safeUrl = onkoPdf ? this._sanitizer.bypassSecurityTrustResourceUrl(base64Data) : this._sanitizer.bypassSecurityTrustUrl(base64Data)

    // Cache the image to be able to immediately view it
    this._kirjanpitoImageService.cacheOneImage(kuvanAvain, safeUrl)

    const kaikki = await this._kirjanpitoImageHandlerService.muunnaTositteiksiJaSivuiksi(asiakas, raahattujenSivut, null, null, null, false)
    const tosite = kaikki[0]
    this._ngZone.run(() => {
      this._kirjanpitoImageHandlerService.tositteenLisaaminenAloitettiin(kirjaus, tosite)
    })

    const lisattavaTiedosto: KirjauksenLiitetiedostonTallennuspyynto = {
      asiakasAvain: asiakas.avain,
      kirjausAvain: kirjaus.avain,
      kuvanAvain: kuvanAvain,
      base64Sisalto: base64Data,
      fileName: file.name,
      mimeType: file.type
    }

    // console.log('Aloitettiin tallennus', lisattavaTiedosto.fileName)
    this._firebase.functionsCall<KirjauksenLiitetiedostonTallennuspyynto, void>('kirjanpitoKirjauksetLiitetiedostotTallennaRajaytys', lisattavaTiedosto, { timeout: 540 * 1000 }).then(() => {
      // console.log('Tallennus onnistui', lisattavaTiedosto.fileName, raahattujenSivut)
      this._kirjanpitoImageHandlerService.tositteenLisaaminenOnnistui(kirjaus, tosite)
    }).catch(err => {
      // console.log('Tallennus epäonnistui', lisattavaTiedosto.fileName)
      this._snackbar.open('Tiedoston "' + file.name + '" lisääminen epäonnistui. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 2500 })
      this._errorHandler.handleError(err)
      this._kirjanpitoImageHandlerService.tositteenLisaaminenEpaonnistui(kirjaus, tosite)
    })

  }

}
