
<mat-dialog-content>

  <lemon-dialog-header>
    Reskontratapahtuman raakadata
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;" [innerHTML]="data | ljson"></div>
  
</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</mat-dialog-actions>
