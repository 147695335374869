import { OnInit, Component, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

import { KirjanpidonProjekti } from '../../_jaettu-lemonator/model/kirjanpito'

import { takeUntil, map } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-kirjanpito-projektit-dropdown',
  templateUrl: './projektit-dropdown.component.html',
  styles: [`.black-text {
    color: black;
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoProjektitDropdownComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  private kaikkiProjekti: KirjanpidonProjekti = {
    avain: 'kaikki',
    viewableInLemonator: true,
    usableInLemonator: false,
    viewableInLemonaid: false,
    color: 'black',
    nimi: 'Kaikki'
  }

  // private _projektitFromFirestoreObservable: Observable<KirjanpidonProjekti[]>
  displayedProjektitObservable: Observable<KirjanpidonProjekti[]>
  form: FormGroup

  @Input() projektitFromParentObservable: Observable<KirjanpidonProjekti[]>
  @Output() projektiValittu: EventEmitter<string> = new EventEmitter()

  constructor() { }

  ngOnInit() {

    const valittuProjektiControl = new FormControl<KirjanpidonProjekti>(null)
    this.form = new FormGroup({
      'valittuProjekti': valittuProjektiControl
    })
    valittuProjektiControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (!!val && val.avain !== this.kaikkiProjekti.avain) {
        this.projektiValittu.emit(val.avain)
      } else {
        this.projektiValittu.emit(null)
      }
    })

    this.displayedProjektitObservable = this.projektitFromParentObservable.pipe(
      map(projektit => {
        const raporteillaProjektit: KirjanpidonProjekti[] = projektit?.length ? projektit.filter(pr => pr.viewableInLemonator) : []
        raporteillaProjektit.unshift(this.kaikkiProjekti)
        return raporteillaProjektit
      })
    )

    valittuProjektiControl.setValue(this.kaikkiProjekti)

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  compareWith(p1: KirjanpidonProjekti, p2: KirjanpidonProjekti): boolean {
    return p1 && p2 ? p1.avain === p2.avain : p1 === p2
  }

}
