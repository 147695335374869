<div class="list-page">
  <app-asiakas-otsikko otsikko="Selaa tositteita" [paluuUrl]="'/asiakkaat'" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Kuitit (Lemonaid): <a [href]="kuititEncodedUriObservable | async" target="_BLANK">{{kuititUriObservable | async}}</a></div>
  </div>

  <form [formGroup]="form" novalidate style="padding: 0px; margin: 0;">

    <div class="hakukontrollit">

      <mat-form-field style="width: 183px; margin-right: 2em;">
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'kuitit.etsi' | translate | async }}</mat-label>
        <input type="text" formControlName="nimiFirestore" matInput />
      </mat-form-field>

      <mat-form-field style="width: 183px; margin: 0;">
        <mat-label>Näytä</mat-label>
        <mat-select #naytaKaikki>
          <mat-option [value]="false">
            Poistamattomat tositteet
          </mat-option>
          <mat-option [value]="true">
            Kaikki tositteet
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </form>

  <div *ngIf="kuvavirheAvain" class="red-text" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</div>

  <div style="padding-top: 20px;">

    <div *ngIf="!etsitaan">
      <table style="margin-left: auto; margin-right: auto;">
        <tr>
          <td>
            <button mat-icon-button (click)="edellinenKuukausi($event)" class="dark-gray-text large-icon-button" style="padding-right: 10px;">
              <i class="icon ion-ios-arrow-back mat-icon"></i>
            </button>
          </td>
          <td (click)="dp.open()" style="width: 240px; font-size: 28px; line-height: 34px; text-align: center; cursor: pointer;" class="light-blue-text">
            {{ otsikkoObservable | async }}
            <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
            <input matInput [matDatepicker]="dp" style="width:1px;visibility:hidden;">
          </td>
          <td>
            <button mat-icon-button (click)="seuraavaKuukausi($event)" class="dark-gray-text large-icon-button" style="padding-left: 10px;">
              <i class="icon ion-ios-arrow-forward mat-icon"></i>
            </button>
          </td>
        </tr>
      </table>
      <div class="kuukausi-summa dark-gray-text">
        {{ kuukaudenSummaObservable | async }}
      </div>
    </div>

    <div style="display: flex; width: 100%; flex-flow: row wrap; align-items: center; justify-content: center; padding-top: 25px;">

      <div *ngFor="let maksutapaJaKuva of maksutavatJaKuvatObservable | async" (click)="valitseMaksutapa(maksutapaJaKuva)" class="droppi maksutapa-container">

        <div class="maksutapa-laatikko lemon-mat-elevation-z4">

          <div class="kuittien-maara-maksutavalle" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]="maksutapaJaKuva.valittu && !maksutapaJaKuva.selvitettava" [class.red-text]="maksutapaJaKuva.valittu && maksutapaJaKuva.selvitettava">
            {{ maksutapaJaKuva.lukumaara }}
          </div>

          <img [src]="maksutapaJaKuva.kuva | async" [class.light-gray-background]="!maksutapaJaKuva.valittu" [class.light-blue-background]="maksutapaJaKuva.valittu && !maksutapaJaKuva.selvitettava" [class.red-background]="maksutapaJaKuva.valittu && maksutapaJaKuva.selvitettava" />

          <div class="maksutavan-nimi-container" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]="maksutapaJaKuva.valittu && !maksutapaJaKuva.selvitettava" [class.red-text]="maksutapaJaKuva.valittu && maksutapaJaKuva.selvitettava">
            <span style="display: inline-block; vertical-align: middle;">
              {{ maksutapaJaKuva.maksutapa.nimi }}
            </span>
          </div>

        </div>

        <div class="maksutapa-summa" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]="maksutapaJaKuva.valittu && !maksutapaJaKuva.selvitettava" [class.red-text]="maksutapaJaKuva.valittu && maksutapaJaKuva.selvitettava">
          {{ maksutapaJaKuva.summa | lmoney: 'EUR' | async }}
        </div>

      </div>
    </div>

  </div>

  <div>

    <div class="kortti-container" *ngIf="!(lataaObservable | async)">
      <ng-container *ngFor="let kuitti of naytettavatKuititObservable | async">
        <div [class.poistettu-kuitti]="kuitti.poistettu" class="kuitti-laatikko lemon-mat-elevation-z4" (click)="katsele(kuitti)">
          <div class="kuitti-laatikko-inner">
            <mat-icon *ngIf="kuitti.vihreaPilvi; else keltsupilvi" style="position: absolute; right: 5px; top: 5px;" class="green-text">cloud_done</mat-icon>
            <ng-template #keltsupilvi>
              <mat-icon style="position: absolute; right: 8px; top: 5px;" class="yellow-text flashit">cloud_upload</mat-icon>
            </ng-template>

            <div class="kuva-container">
              <img [src]="kuitti.ensimmainenKuva" (load)="kuvanLataaminenValmis(kuitti)" crossorigin="use-credentials" *ngIf="kuitti.ensimmainenKuva" loading="lazy" />
              <div lemon-loading [show]="kuitti.ladataan"></div>
            </div>

            <div class="tiedot-container">
              <div class="pvm dark-gray-text">{{ kuitti.localPvm | lokaalipvm | async }}</div>
              <div class="summa light-blue-text">{{ kuitti.summa | lmoney: 'EUR' | async }}</div>
              <div class="viiva light-gray-background"></div>
              <div class="el-kommentti" *ngIf="!kuitti?.w && (kuitti?.el || kuitti?.seller)">{{kuitti.el || kuitti.seller}}</div>
              <div class="selite-container">
                <div *ngIf="kuitti.w" class="dark-gray-text">{{ kuitti.w }}</div>
                <div *ngIf="!kuitti.w && kuitti.c" class="dark-gray-text">{{ kuitti.c }}</div>
                <div class="bottom-container">
                  <div class="selite dark-gray-text">{{ kuitti.selite }}</div>
                  <div *ngIf="kuitti.m" class="selv-kommentti">{{ kuitti.m }}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="lisatiedot dark-gray-text">Luotu: {{ kuitti.tallennettuKannykassa?.toDate() | ldatetime | async }} </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="naytaEiYhtaanObservable | async" class="table-ladataan" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px;">
        Hakuehdoilla ei löytynyt yhtään tositetta.
      </div>
    </div>
    <div *ngIf="lataaObservable | async" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px;">
        <div lemon-loading [show]="true"></div>
      </div>
    </div>

  </div>

</div>