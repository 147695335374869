<div class="detail-page">

  <app-asiakas-otsikko [otsikko]="otsikko" [naytaPalaa]="true" [paluuUrl]="'/asiakkaat/' + asiakas?.avain + '/laskutus'" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Lasku: <a [href]="laskuEncodedUriObservable | async" target="_BLANK">{{laskuUriObservable | async}}</a></div>
    <div style="padding-left: 1em; padding-right: 1em;">Mahdollinen Kirjattava lasku: <a [href]="kirjattavalaskuEncodedUriObservable | async" target="_BLANK">{{kirjattavalaskuUriObservable | async}}</a></div>
  </div>

  <div class="otsake-container">

    <!-- <button class="sulje light-blue-text" mat-button (click)="peruuta()" type="button">
      <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
    </button> -->

    <div class="otsake">
      <!-- <div *ngIf="naytaKorvatunOtsake()">
        <span>{{'lasku.katsele-muokattava-dialog.lasku' | translate | async}}</span>
        <h2 class="light-blue-text">{{annaKorvatunOtsake()}}</h2>
      </div>
      <div *ngIf="naytaTavallinenOtsake()">
        <span>{{'lasku.katsele-muokattava-dialog.lasku' | translate | async}}</span>
        <h2 class="light-blue-text">{{laskuSharedService.annaMuotoiltuLaskunumero(kasiteltava)}} {{kasiteltava?.asiakas?.nimi}}</h2>
      </div> -->
      <div *ngIf="naytaMaksuaikaa()">
        <span>{{'lasku.katsele-muokattava-dialog.maksuaikaa' | translate | async}}</span>
        <h2 [class.light-blue-text]="annaMaksuaikaa() > -1" [class.red-text]="annaMaksuaikaa() < 0">{{annaMaksuaikaa()}} pv</h2>
      </div>
      <div *ngIf="naytaSumma()">
        <span>
          {{'lasku.tila.' + juurilasku?.tila | translate | async}}
        </span>
        <div *ngIf="juurilasku?.tila == 'm' || juurilasku?.tila == 'ml' then maksettu else muut"></div>
        <ng-template #muut>
          <h2 [class.blue-text]="(vertaaYhtaSuuret(kasiteltava?.avoinna, kasiteltava?.summa) && juurilasku?.tila == 'a')" [class.red-text]="juurilasku?.tila == 'e'" [class.yellow-text]="vertaaEkaPienempiKuinToka(kasiteltava?.avoinna, kasiteltava?.summa) && vertaaEkaPienempiKuinToka(0, kasiteltava?.avoinna) && juurilasku?.tila == 'a'">{{kasiteltava?.avoinna | lmoney: kasiteltava?.valuutta | async}}
          </h2>
        </ng-template>
        <ng-template #maksettu>
          <h2 [class.purple-text]="juurilasku?.tila == 'ml'" [class.green-text]="juurilasku?.tila == 'm'">{{reskontraSummaObservable | async | lmoney: kasiteltava?.valuutta | async}}</h2>
        </ng-template>
      </div>
    </div>

  </div>

  <div class="nappula-container">

    <div class="nappulat">
      <!-- *ngIf="nakyvyystiedotObservable | async let nakyvyystiedot" -->

      <div>
        <!-- <button mat-button class="secondarybutton" (click)="muokkaa()" *ngIf="nakyvyystiedot.onkoMuokkaaNakyvissa">{{'lasku.katsele-muokattava-dialog.muokkaa' | translate | async}}</button> -->
        <!-- <button mat-button class="secondarybutton" (click)="naytaMuokkausEstettyDialogi()" *ngIf="nakyvyystiedot.onkoMuokkaaInfoNakyvissa">{{'lasku.katsele-muokattava-dialog.muokkaa' | translate | async}}</button> -->
        <!-- <button mat-button class="secondarybutton" >
          <mat-icon>info</mat-icon>
        </button> -->
        <!-- <button mat-button class="secondarybutton" (click)="kopioiUusiLasku()" *ngIf="nakyvyystiedot.onkoKopioiUusiLaskuNakyvissa">{{'lasku.katsele-muokattava-dialog.kopioi-uusi-lasku' | translate | async}}</button>
        <button mat-button class="secondarybutton" (click)="poistaLuonnos()" *ngIf="nakyvyystiedot.onkoPoistoLuonnosNakyvissa">{{'lasku.katsele-muokattava-dialog.poista-luonnos' | translate | async}}</button> -->
        <!-- <button mat-button class="secondarybutton" (click)="mitatoi()"  *ngIf="nakyvyystiedot.onkoMitatoiNakyvissa()">{{'lasku.katsele-muokattava-dialog.mitatoi' | translate | async}}</button> -->
        <!-- <button mat-button class="secondarybutton" (click)="hyvita()" *ngIf="nakyvyystiedot.onkoHyvitaNakyvissa">{{'lasku.katsele-muokattava-dialog.hyvita' | translate | async}}</button> -->
        <button mat-button class="secondarybutton" (click)="lataaLasku()" type="button" *ngIf="onkoLataaLaskuNakyvissa()">{{'lasku.katsele-muokattava-dialog.lataa-lasku' | translate | async}}</button>
      </div>

      <div>
        <!-- <button mat-button class="secondarybutton" (click)="merkitseOsittainMaksetuksi()" *ngIf="nakyvyystiedot.onkoMerkitseOsittainMaksetuksiNakyvissa">{{ 'lasku.katsele-muokattava-dialog.merkitse-osittain-maksetuksi' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="lahetaMuistutuslasku()" *ngIf="nakyvyystiedot.onkoMaksumuistutusNakyvissa">{{ 'lasku.katsele-muokattava-dialog.laheta-maksumuistutus' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="lahetaPerintaritarimuistutuslasku()" *ngIf="naytaPerintaritari && nakyvyystiedot.onkoMaksumuistutusNakyvissa">{{ 'lasku.katsele-muokattava-dialog.laheta-maksumuistutus' | translate | async }} (Perintäritari)</button>
        <button mat-button class="secondarybutton" (click)="peruutaLuottotappiomerkinta()" *ngIf="nakyvyystiedot.onkoPeruutaLuottotappioMerkintaNakyvissa">{{ 'lasku.katsele-muokattava-dialog.peruuta-merkitse-luottotappioksi' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="merkitseLuottotappioksi()" *ngIf="nakyvyystiedot.onkoMerkitseLuottotappioksiNakyvissa">{{ 'lasku.katsele-muokattava-dialog.merkitse-luottotappioksi' | translate | async }}</button> -->
      </div>

      <div *ngIf="kirjanpitajaOnDevaajaObservable | async">
        <button mat-button class="secondarybutton" (click)="laskeReskontraUudelleen()">LASKE RESKONTRA UUDELLEEN</button>
        <button mat-button class="secondarybutton" (click)="paivitaTilaJaIndeksoiUudelleen()">PAIVITA TILA JA INDEKSOI UUDELLEEN</button>
      </div>

    </div>

    <ng-container *ngIf="naytaEpaonnistunutSahkoinen()">
      <mat-card appearance="outlined" class="laheta-uudelleen">
        <mat-card-header>
          <mat-card-title>{{'lasku.katsele-muokattava-dialog.sahkoinen.lahettaminen-epaonnistui' | translate | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="sahkoinenForm" novalidate>

            <mat-form-field>
              <mat-label>{{ 'lasku.katsele-muokattava-dialog.sahkoinen.valittaja' | translate | async }}</mat-label>
              <mat-select formControlName="valittaja" required>
                <mat-option *ngFor="let operator of verkkolaskuoperaattorit" [value]="operator.tunnus">
                  {{ operator.nimi }} ({{ operator.tunnus }})
                </mat-option>
              </mat-select>
              <mat-error *ngIf="valittajaControl?.errors?.required && (valittajaControl?.dirty || valittajaControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.valittaja.required' | translate | async}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'lasku.katsele-muokattava-dialog.sahkoinen.osoite' | translate | async }}</mat-label>
              <input type="text" [name]="namename" formControlName="osoite" matInput required />
              <mat-hint>{{'lasku.katsele-muokattava-dialog.sahkoinen.virheellinen-osoite' | translate | async}}</mat-hint>
              <mat-error *ngIf="osoiteControl?.errors?.required && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.required' | translate | async}}</mat-error>
              <mat-error *ngIf="osoiteControl?.errors?.valittaja && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.valittaja' | translate | async}}</mat-error>
            </mat-form-field>

            <button mat-button class="primarybutton" (click)="lahetaSahkoinenUudelleen()" type="button">
              <!-- <mat-icon>present_to_all</mat-icon> -->
              {{'lasku.katsele-muokattava-dialog.uudelleen-lahetys' | translate | async}}
            </button>

          </form>
        </mat-card-content>
      </mat-card>
      <div><b>Osoitekyselyn tiedot</b></div>
      <pre>{{kasiteltava?.sahkoinen?.latestAddressQueryReponse | json}}</pre>
      <div><b>Lähetystiedot</b></div>
      <pre>{{kasiteltava?.sahkoinen?.latestSendResponse | json}}</pre>
    </ng-container>

    <ng-container *ngIf="naytaEpaonnistunutSposti()">
      <mat-card appearance="outlined" class="laheta-uudelleen">
        <mat-card-header>
          <mat-card-title>{{'lasku.katsele-muokattava-dialog.spostien-lahettaminen-epaonnistui' | translate | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let vastaanottaja of kasiteltava?.email?.vastaanottajat let i = index">
            <ng-container *ngIf="vastaanottaja.status == 3">
              <form novalidate>

                <mat-form-field>
                  <mat-label>{{ 'lasku.katsele-muokattava-dialog.spostin-lahetysvirhe' | translate | async }}</mat-label>
                  <input type="text" name="emailfix" [(ngModel)]="vastaanottaja.email" matInput required email />
                  <mat-error>{{'lasku.katsele-muokattava-dialog.virheellinen-sahkoposti' | translate | async}}</mat-error>
                  <mat-hint align="start">{{'lasku.katsele-muokattava-dialog.tekninen-virhe' | translate | async}}: {{vastaanottaja.viesti?.Description}} </mat-hint>
                </mat-form-field>

                <button mat-button class="secondarybutton" (click)="naytaKokoVirhe(vastaanottaja)">
                  <!-- <mat-icon>bug_report</mat-icon> -->
                  {{'lasku.katsele-muokattava-dialog.koko-virheen-naytto' | translate | async}}
                </button>
                <button mat-button class="primarybutton" (click)="lahetaEmailUudelleen($any(vastaanottaja))" type="button">
                  <!-- <mat-icon>present_to_all</mat-icon> -->
                  {{'lasku.katsele-muokattava-dialog.uudelleen-lahetys' | translate | async}}
                </button>

              </form>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>

  </div>

  <div style="max-width: 695px; width: 100%; margin-bottom: 25px;">
    <lasku-pdf-esikatselu-perinteinen [tiedot]="esikatselutiedot"></lasku-pdf-esikatselu-perinteinen>
  </div>

  <div *ngIf="kasiteltava?.liitteet && kasiteltava?.liitteet?.length > 0">
    <div class="light-blue-text" style="font-size: 1rem; margin-top: 40px; margin-bottom: 10px;">Liitetiedostot</div>
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let liite of kasiteltava?.liitteet" style="cursor: pointer;" (click)="lataaLiitetiedosto(liite)" [selectable]="false">
        {{liite.nimi}}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">{{ 'lasku.tapahtumaloki.otsikko' | translate | async }}</div>
    <mat-progress-bar mode="indeterminate" *ngIf="lokitietojaLadataanObservable | async"></mat-progress-bar>
    <div *ngFor="let loki of lokitiedotObservable | async">
      {{ loki.loki.pvm | ltimestamp | async }}: {{ 'lasku.tapahtumaloki.tyyppi' + loki.loki.toiminto | translate: loki.parametrit | async }}
    </div>
  </div>

  <!-- <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">
      {{ 'lasku.reskontra.otsikko' | translate | async }}
      Reskontrahistoria laskussa (Tarkemmat tiedot klikkaamalla)
    </div>
    <div *ngFor="let merkinta of juurilasku?.reskontra" (click)="naytaMerkinta(merkinta)" style="cursor: pointer;">
      {{ merkinta.pvm | ltimestamp | async }}: {{ merkinta.suoritus | lmoney: kasiteltava?.valuutta | async }}
    </div>
  </div> -->

  <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">
      <!-- {{ 'lasku.reskontra.otsikko' | translate | async }} -->
      Erillinen reskontrahistoria (Tarkemmat tiedot klikkaamalla)
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="reskontraaLadataanObservable | async"></mat-progress-bar>
    <div *ngFor="let merkinta of reskontraLokiObservable | async" (click)="naytaMerkinta(merkinta.data)" style="cursor: pointer;">
      {{ merkinta.teksti }}
    </div>
  </div>

</div>