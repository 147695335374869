import { LocalMonth } from '../../_shared-core/model/common'

export class AsiakasUriService {

  annaKayttajienRoolienPoistonTyoUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/poista-kayttajien-rooleja/' + tyoAvain
  }

  annaListausAsiakkaanPaivitysTyoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/listaus-asiakkaan-paivitys/' + tyojonoAvain
  }

  annaListausTilikausienPaivitysTyoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/listaus-tilikausien-paivitys/' + tyojonoAvain
  }

  annaListausHintojenPaivitysTyoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/listaus-hintojen-paivitys/' + tyojonoAvain
  }

  annaYtjTietojenPaivitysTyoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/ytj-tiedot/' + tyojonoAvain
  }
  annaListausSarakkeidenCollectionUri(kirjanpitajanAvain: string): string {
    return 'kirjanpitajat/' + kirjanpitajanAvain + '/asiakaslistauksen-sarakkeiden-arvot'
  }

  annaListausSarakkeenUri(kirjanpitajanAvain: string, asiakasAvain: string): string {
    return this.annaListausSarakkeidenCollectionUri(kirjanpitajanAvain) + '/' + asiakasAvain
  }

  annaListausSarakkeenHistoriaUri(kirjanpitajanAvain: string, asiakasAvain: string, tallennuksenAvain: string): string {
    return this.annaListausSarakkeenUri(kirjanpitajanAvain, asiakasAvain) + '/asiakaslistauksen-sarakkeiden-arvot-historia/' + tallennuksenAvain
  }

  annaAsiakasUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain
  }

  annaListausAsiakasUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/asiakaslistaus/' + asiakasAvain
  }

  annaListausRuutuCollectionUri(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/asiakaslistauksen-kuukausiruutu'
  }

  annaListausRuutuUri(asiakasAvain: string, kuukausi: LocalMonth): string {
    const month = ('' + kuukausi.month).padStart(2, '0')
    return this.annaListausRuutuCollectionUri(asiakasAvain) + '/' + asiakasAvain + '' + kuukausi.year + month
  }

  annaListausRuutuHistoriaUri(asiakasAvain: string, kuukausi: LocalMonth, historiaAvain: string): string {
    return this.annaListausRuutuUri(asiakasAvain, kuukausi) + '/asiakaslistauksen-kuukausiruutu-historia/' + historiaAvain
  }

  annaYtjTiedotUri(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/ytj-tiedot/' + asiakasAvain
  }

  annaYtjTiedotHistoriaCollection(asiakasAvain: string) {
    return this.annaYtjTiedotUri(asiakasAvain) + '/historia'
  }

  annaYtjTiedotHistoriaUri(asiakasAvain: string, historiaAvain: string) {
    return this.annaYtjTiedotHistoriaCollection(asiakasAvain) + '/' + historiaAvain
  }

  annaAsiakastiedotUri(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/asiakastiedot/' + asiakasAvain
  }

  annaAsiakastietojenHistoriaCollection(asiakasAvain: string) {
    return this.annaAsiakastiedotUri(asiakasAvain) + '/asiakastietojen-historia'
  }
  annaAsiakastietojenHistoriaUri(asiakasAvain: string, historiaAvain: string) {
    return this.annaAsiakastietojenHistoriaCollection(asiakasAvain) + '/' + historiaAvain
  }

  annaRiskiarvionHistoriaCollection(asiakasAvain: string) {
    return this.annaAsiakastiedotUri(asiakasAvain) + '/riskiarvion-historia'
  }

  annaRiskiarvionHistorianUri(asiakasAvain: string, historianAvain: string) {
    return this.annaAsiakastiedotUri(asiakasAvain) + '/riskiarvion-historia/' + historianAvain
  }

  annaRahanpesunSelvitysCollection(asiakasAvain: string) {
    return this.annaAsiakastiedotUri(asiakasAvain) + '/rahanpesun-selvitykset'
  }

  /** Storage bucket rahanpesu-selvitykset */
  annaRahanpesuSelvitysTiedostoStorageUri(asiakasAvain: string, selvitysAvain: string, fileName: string) {
    return asiakasAvain + '/' + selvitysAvain + '/' + fileName
  }

  annaRahanpesunSelvitysUri(asiakasAvain: string, selvitysAvain: string) {
    return this.annaRahanpesunSelvitysCollection(asiakasAvain) + '/' + selvitysAvain
  }

}
