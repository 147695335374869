import { animate, state, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component, Input, OnChanges, HostBinding, ChangeDetectorRef, Output, EventEmitter } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { TallennaCheckboxArvotEvent, VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'
import { firstValueFrom } from 'rxjs'

export interface KirjanpitoVeroilmoitusCheckOption {
  label: string
  value: string
  checked: boolean
}

@Component({
  selector: '[app-veroilmoitus-row-check]',
  templateUrl: './veroilmoitus-row-check.component.html',
  styleUrls: ['./veroilmoitus-row.styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s')
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoVeroilmoitusRowCheckComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges {

  @Output() tallennaArvo: EventEmitter<TallennaCheckboxArvotEvent> = new EventEmitter<TallennaCheckboxArvotEvent>()

  @Input() lukittu: boolean
  @Input() numero: string
  @Input() korvausparametrit: Korvausparametrit
  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot
  @Input() showPen: boolean = true
  @Input() editing: boolean = false
  @Input() repeatingMetadata: RepeatingMetadata

  @Input() options: KirjanpitoVeroilmoitusCheckOption[]
  @Input() direction: 'vertical' | 'horizontal' = 'vertical'

  @HostBinding('class') classes = 'block-row'

  label: string = ''
  help: string = null
  saving: boolean = false
  saveSuccess: boolean = false
  saveFailure: boolean = false

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero && this.veroilmoitus?.perustiedot?.asiakas) {

      // Set label
      this.label = this._localizeNumero(this.numero, this.veroilmoitus, this.korvausparametrit)
      this.help = this._localizeNumeroHelp(this.numero, this.veroilmoitus, this.korvausparametrit)

      const identifier = this.annaTiedonTunniste(this.numero, this.repeatingMetadata)

      // Enabled / Disabled state
      if (
        !this.editing &&
        this.veroilmoitus.punakynaNumbers &&
        this.veroilmoitus.punakynaNumbers[identifier] === 1
      ) {
        this.editing = true
      }

    } else {
      this.label = ''
      this.help = null
    }
  }

  tallennaMuuttunutArvo() {
    this.tallennaAnnettuArvo(false)
  }

  tallennaAnnettuArvo(poista: boolean, punakyna?: boolean) {
    this.saveFailure = false
    this.saveSuccess = false
    this.saving = true
    const identifier = this.annaTiedonTunniste(this.numero, this.repeatingMetadata)
    const tallennusData: TallennaCheckboxArvotEvent = {
      tyyppi: 'checkbox',
      numero: identifier,
      arvotCheckbox: [],
      doneCallback: (outcome) => {
        this.saving = false
        if (outcome === 'error') {
          this.saveFailure = true
          this.saveSuccess = false
        } else if (outcome === 'success') {
          this.saveFailure = false
          this.saveSuccess = true
        } else {
          this.saveFailure = false
          this.saveSuccess = false
        }
        this._changeDetectorRef.markForCheck()
        setTimeout(() => {
          this.saveSuccess = false
          this._changeDetectorRef.markForCheck()
          setTimeout(() => {
            this._changeDetectorRef.markForCheck()
            this._changeDetectorRef.detectChanges()
          }, 250)
        }, 1000)
      }
    }
    if (poista) {
      for (const option of this.options) {
        tallennusData.arvotCheckbox.push({ id: option.value, valittu: undefined })
      }
    } else {
      for (const option of this.options) {
        tallennusData.arvotCheckbox.push({ id: option.value, valittu: !!option.checked })
      }
    }
    if (punakyna === true) {
      tallennusData.setPunakynaOpen = true
    }
    if (punakyna === false) {
      tallennusData.removePunakynaOpen = true
    }
    this.tallennaArvo.next(tallennusData)
  }

  async toggleEdit() {

    const dialogText = this.editing ? 'Oletko varma, että haluat palauttaa automatiikan tälle riville? Huomaa, että menetät tällöin mahdollisen itse syöttämäsi arvon.' :
      'Tämän rivin arvo täytetään automaattisesti. Haluatko varmasti yliajaa automatiikan? Huomaa, että mahdolliset muutokset kirjanpidossa eivät tämän jälkeen enää päivity tälle riville.'

    const dialogData: AreYouSureDialogData = {
      rightAction: 'Kyllä',
      leftAction: 'Ei',
      text: dialogText,
      header: this.editing ? 'Palauta automatiikka?' : 'Muokkaa automaattista arvoa?'
    }

    await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed()).then(yes => {
      if (yes) {
        if (this.editing) {
          this.editing = false
          this.tallennaAnnettuArvo(true, false)
        } else {
          this.editing = true
          this.tallennaAnnettuArvo(false, true)
        }
      }
    })

  }

}
