<mat-dialog-content>

  <lemon-dialog-header>
    Käyttäjän login historia
  </lemon-dialog-header>

  <table style="padding-left: 3em; padding-right: 3em; border-spacing: 10px;">
    <tr style="font-weight: bold">
      <td>ID</td>
      <td>Laite</td>
      <td>OS</td>
      <td>Versio</td>
      <td>Pvm</td>
      <td>Kuka kirjautui?</td>
    </tr>
    <tr *ngFor="let login of displayDataObservable | async">
      <td>{{ login.avain }}</td>
      <td>{{ login.device }}</td>
      <td>{{ login.os }}</td>
      <td>{{ login.version }}</td>
      <td>{{ login.timestamp | ltimestamptimel: 'fi' }}</td>
      <td>{{ login.emuloija }}</td>
    </tr>
  </table>


</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button (click)="sulje()">Sulje</button>
</mat-dialog-actions>