import { Injectable } from '@angular/core'

import { MatSort, MatSortable } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Kayttaja } from '../_jaettu-lemonator/model/asiakas'
import { TositteenMaksutapa } from '../_jaettu/model/kayttaja'

import { Subscription, ReplaySubject, Observable } from 'rxjs'

export interface AsiakkaanKayttajatDataSourceContainer {
  dataSource: MatTableDataSource<Kayttaja>
  lataa: boolean
  virheObservable: Observable<Error>
  asiakkaanKaikkiMaksutavat: Observable<TositteenMaksutapa[]>
}

interface AsiakkaanKayttajatDataSourceContainerInternal extends AsiakkaanKayttajatDataSourceContainer {
  dataSource: MatTableDataSource<Kayttaja>
  lataa: boolean
  subscription: Subscription
  virheSubject: ReplaySubject<Error>
}

@Injectable()
export class AsiakkaanKayttajatDataSourceService {

  currentSort: MatSortable = {
    disableClear: false,
    id: 'sukunimi',
    start: 'asc'
  }

  constructor() { }

  public annaDataSourceAsiakkaalle(kayttajat: Kayttaja[]): AsiakkaanKayttajatDataSourceContainer {

    const virheSubject = new ReplaySubject<Error>(1)
    const asiakkaanKaikkiMaksutavat = new ReplaySubject<TositteenMaksutapa[]>(1)
    const paluuarvo: AsiakkaanKayttajatDataSourceContainerInternal = {
      dataSource: new MatTableDataSource([]),
      lataa: false,
      subscription: null,
      virheSubject: virheSubject,
      virheObservable: virheSubject.asObservable(),
      asiakkaanKaikkiMaksutavat: asiakkaanKaikkiMaksutavat.asObservable()
    }

    paluuarvo.dataSource.data = kayttajat || []

    paluuarvo.dataSource.sortData = (data: Kayttaja[], sort: MatSort): Kayttaja[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

      if (active === 'etunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.etunimi, b.sukunimi) * directionMultiplier
        })
      }

      if (active === 'sukunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.sukunimi, b.sukunimi) * directionMultiplier
        })
      }

      if (active === 'email') {
        return data.sort((a, b) => {
          return collator.compare(a.email, b.email) * directionMultiplier
        })
      }

      return data

    }


    return paluuarvo
  }

}
