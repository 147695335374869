<mat-form-field>
  <mat-label>Laskutus alkaa</mat-label>
  <input type="text" [min]="minMonth" [matDatepicker]="picker" [formControl]="kontrolli" required matInput data-lpignore="true" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-error *ngIf="kontrolli?.errors?.required && (kontrolli?.dirty || kontrolli?.touched)">
    Laskutuksen alkamiskuukausi puuttuu.
  </mat-error>
  <mat-error *ngIf="(kontrolli?.errors?.min || kontrolli?.errors?.matDatepickerMin) && (kontrolli?.dirty || kontrolli?.touched)">
    Laskutus voi alkaa aikaisintaan ensi kuussa.
  </mat-error>
  <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
</mat-form-field>