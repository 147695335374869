<div class="list-page kapea" style="max-width: 1200px;">

  <h2>Lataa yhteisömyyntilaskut</h2>

  <form [formGroup]="form" novalidate class="ylaosan-kontrollit" style="margin: 16px 0;">

    <div class="hakukontrollit">
      <mat-form-field>
        <mat-label>{{ 'laskut.rajaa-kuukauden-mukaan' | translate | async }}</mat-label>
        <input matInput [matDatepicker]="dp" formControlName="vuosikk">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>
    </div>

  </form>

  <div style="padding-bottom: 30px; font-size: 20px;">
    Valittu kuukausi: {{kuukaudenNimi}} {{kuukausi?.getFullYear()}}
  </div>

  <button mat-button (click)="lataa()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa yhteisömyyntilaskut
  </button>

  <button mat-button (click)="lataaCsv()" type="button" class="primarybutton mat-elevation-z4" style="margin-left: 30px;">
    <mat-icon>cloud_download</mat-icon>
    Lataa yhteenvetoilmoitus CSV-muodossa
  </button>

  <!--Ilmoitukset-->
  <h2 class="header">Yhteisömyynti-ilmoitukset</h2>

  <form [formGroup]="hakuform" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <mat-form-field floatPlaceholder="always" style="width: 300px; margin-right: 1.5vw;">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let tila of lahetyksenTila" [value]="tila.status">
              {{ tila.status }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div style="display: flex; flex-flow: row wrap;">
          <mat-form-field style="width: 200px; margin-right: 10px;">
            <mat-label>Näytä alkaen</mat-label>
            <input matInput [matDatepicker]="dp1" formControlName="start">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1 startView="year" (monthSelected)="chosenMonthHandlerStart($event, dp1)" panelClass="kuukausi-valitsin"></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 200px;">
            <mat-label>... loppuen</mat-label>
            <input matInput [matDatepicker]="dp2" formControlName="end">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2 startView="year" (monthSelected)="chosenMonthHandlerEnd($event, dp2)" panelClass="kuukausi-valitsin"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <div style="width:100%;">
    <div style="position: relative;">
      <mat-table mat-table multiTemplateDataRows matSort [dataSource]="dataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="asiakasNimi">
          <th mat-header-cell *matHeaderCellDef style="min-width: 330px;"> Asiakas </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 330px;">
            <ng-container *ngFor="let rivi of row.rivit; let first = first">
              <tr *ngIf="first">
                <td>
                  <a [href]="'/asiakkaat/' + row.asiakasAvain + '/asiakastiedot'" target="_BLANK">{{ row.asiakas }}</a>
                </td>
              </tr>
              <tr *ngIf="!first">
                <td>&nbsp;</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="jakso">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px;"> Jakso </th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 100px;">
            <ng-container *ngFor="let rivi of row.rivit; let first = first">
              <tr *ngIf="first">
                <td>{{ row.jakso | numbermonthtomonthyear | async }}</td>
              </tr>
              <tr *ngIf="!first">
                <td>&nbsp;</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="maaKoodi">
          <th mat-header-cell *matHeaderCellDef style="min-width: 50px;"> Maa</th>
          <td mat-cell class="mat-mdc-cell" *matCellDef="let row" style="min-width: 50px;">
            <ng-container *ngFor="let rivi of row.rivit">
              <tr>
                <td>{{ rivi.maaKoodi }}</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="alvTunnus">
          <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Ostajan VAT-tunnus </th>
          <td mat-cell *matCellDef="let row" style="min-width: 130px;">
            <ng-container *ngFor="let rivi of row.rivit">
              <tr>
                <td>{{ rivi.alvTunnus }}</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="tavaramyynnit">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Tavaramyynnit </th>
          <td mat-cell *matCellDef="let row" style="min-width: 100px;">
            <ng-container *ngFor="let rivi of row.rivit">
              <tr>
                <td>{{ rivi.tavaramyynnit | lmoney: 'EUR' | async }}</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="palvelumyynnit">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Palvelumyynnit </th>
          <td mat-cell *matCellDef="let row" style="min-width: 100px;">
            <ng-container *ngFor="let rivi of row.rivit">
              <tr>
                <td>{{ rivi.palvelumyynnit | lmoney: 'EUR' | async }}</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="kolmikantamyynnit">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Kolmikantamyynnit </th>
          <td mat-cell *matCellDef="let row" style="min-width: 100px;">
            <ng-container *ngFor="let rivi of row.rivit">
              <tr>
                <td>{{ rivi.kolmikantamyynnit | lmoney: 'EUR' | async }}</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="lahetetty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 170px;"> Ilmoitus lähetetty </th>
          <td mat-cell *matCellDef="let row" style="min-width: 170px;">
            <ng-container *ngFor="let rivi of row.rivit; let first = first">
              <tr *ngIf="first">
                <td>{{row.lahetetty.toDate() | ldatetime | async}}</td>
              </tr>
              <tr *ngIf="!first">
                <td>&nbsp;</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="min-width: 120px;"> Status </th>
          <td mat-cell *matCellDef="let row" style="min-width: 120px;">
            <ng-container *ngFor="let rivi of row.rivit; let first = first">
              <tr *ngIf="first">
                <td>
                  <ng-container *ngIf="row.status === 'success'; else epaOnnistui">
                    <div class="success-color">Lähetetty</div>
                  </ng-container>
                  <ng-template #epaOnnistui>
                    <div class="error-color">Epäonnistunut</div>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="!first">
                <td>&nbsp;</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="debug">
          <th mat-header-cell *matHeaderCellDef style="min-width: 180px;"> Debug</th>
          <td mat-cell *matCellDef="let row" style="min-width: 180px;">
            <ng-container *ngFor="let rivi of row.rivit; let first = first">
              <tr *ngIf="first">
                <td>
                  <a [href]="row.debugLink" target="_BLANK">
                    Linkki
                  </a>
                </td>
              </tr>
              <tr *ngIf="!first">
                <td>&nbsp;</td>
              </tr>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row *matRowDef="let row; columns: naytettavatKolumnit;"></mat-row>

      </mat-table>
    </div>
  </div>

</div>