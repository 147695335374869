import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, ErrorHandler, ChangeDetectorRef } from '@angular/core'

import { MatSnackBar } from '@angular/material/snack-bar'

import { TositeJaNaytettavaSivut, NaytettavaSivuUusi, KirjanpitoImageHandlerService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { IMAGE_ZOOM_CHANGE_STEP, MIN_IMAGE_ZOOM, MAX_IMAGE_ZOOM } from 'app/_angular/_components/image-viewer.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { NaytettavaKirjaus } from '../kirjanpito.component'
import { KirjaukseenLiitetynTiedostonSivu, Kirjaus } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasService, AsiakkaanAvainTiedot } from 'app/_angular/service/asiakas/asiakas.service'

import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs'
import { TositeTyyppi } from './tosite-standalone.component'

export interface SivuJaAvain {
  sivunAvain: string
  sivu: KirjaukseenLiitetynTiedostonSivu
}

@Component({
  selector: '[app-kirjanpito-tosite-naytto]',
  templateUrl: './tosite-naytto.component.html',
  styleUrls: ['./tosite-naytto.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeInAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(2000)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(0, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('fastFadeInOutAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(150)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoTositeNayttoComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  @Input() scrollContainerRef: ElementRef<HTMLDivElement>
  @Input() naytaRuksi: boolean
  @Input() piilotaYlaControllit: boolean = false
  @Input() naytettavaKirjausObservable: Observable<NaytettavaKirjaus>

  private _isMainEnhancedOn: boolean = false
  @Input() set isMainEnhancedOn(value: boolean) {
    this._updateMainEnhance(this.naytettavatTositteetJaSivutObservable.value, !!value)
    this._isMainEnhancedOn = !!value
  }
  get isMainEnhancedOn(): boolean { return this._isMainEnhancedOn }

  naytettavatTositteetJaSivutObservable = new BehaviorSubject<TositeJaNaytettavaSivut[]>(null)
  @Input() set naytettavatTositteetJaSivut(sivut: TositeJaNaytettavaSivut[]) {
    if (this.naytettavatTositteetJaSivutObservable.value && sivut) {
      for (const vanhaTosite of this.naytettavatTositteetJaSivutObservable.value) {
        for (const uusiTosite of sivut) {
          if (vanhaTosite.avain === uusiTosite.avain) {
            for (const vanhaSivu of vanhaTosite.sivut) {
              for (const uusiSivu of uusiTosite.sivut) {
                if (vanhaSivu.avain === uusiSivu.avain) {
                  uusiSivu.ladataan = vanhaSivu.ladataan
                  break
                }
              }
            }
            break
          }
        }
      }
    }
    this._updateMainEnhance(sivut, this.isMainEnhancedOn)
    this.naytettavatTositteetJaSivutObservable.next(sivut)
  }
  get naytettavatTositteetJaSivut(): TositeJaNaytettavaSivut[] {
    return this.naytettavatTositteetJaSivutObservable.value
  }

  private _updateMainEnhance(kaikki: TositeJaNaytettavaSivut[], isMainEnhanceOn: boolean) {
    if (!kaikki) { return }
    for (const tosite of kaikki) {
      for (const sivu of tosite.sivut ?? []) {
        this._enhance(sivu, isMainEnhanceOn)
      }
    }
  }

  @Output() liitaTositeValittuunKirjaukseen: EventEmitter<TositeJaNaytettavaSivut> = new EventEmitter()
  @Output() ruksiaNapautettiin: EventEmitter<TositeJaNaytettavaSivut> = new EventEmitter()

  constructor(
    private _errorHandler: ErrorHandler,
    private _changeDetector: ChangeDetectorRef,
    private _asiakasService: AsiakasService,
    private _snackbar: MatSnackBar,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService,
    // private _ngZone: NgZone
  ) { }

  time() {
    return new Date().getTime()
  }

  async irroitaTositeHandler(tosite: TositeJaNaytettavaSivut) {
    if (tosite && tosite.sivut?.length) {

      const naytettavaKirjaus: NaytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)
      const kirjaus: Kirjaus = naytettavaKirjaus?.kirjaus
      const asiakas: AsiakkaanAvainTiedot = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

      if (!asiakas || !kirjaus || !naytettavaKirjaus) {
        return
      }

      if (naytettavaKirjaus.kirjaus?.avain === tosite.avain) {
        this._snackbar.open('Tämän tositteen irroittaminen kirjauksesta ei ole mahdollista.', 'OK', { duration: 10 * 1000 })
        return
      }

      tosite.poistetaan = true
      return this._kirjanpitoImageHandlerService.hoidaLiitoksenPoisto(asiakas, tosite, naytettavaKirjaus).then(() => {
        delete tosite.poistetaan
        this._changeDetector.markForCheck()
      }).catch(err => {
        this._errorHandler.handleError(err)
        this._snackbar.open('Tositteen liitoksen purku epäonnistui. Yritä uudelleen.', null, { duration: 2500 })
        delete tosite.poistetaan
        this._changeDetector.markForCheck()
      })
    }
  }

  async tositeTositeUudessaIkkunassa(tosite: TositeJaNaytettavaSivut) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas) {
      return
    }
    // asiakkaat/:asiakasAvain/kirjanpidon-tosite/:tositeTyyppi/:tositeAvain
    const tositeTyyppi: TositeTyyppi = tosite.tyyppi
    const url = '/asiakkaat/' + asiakas.avain + '/kirjanpidon-tosite/' + tositeTyyppi + '/' + tosite.avain
    const ikkunanAvain = 'tosite_' + asiakas.avain + '_' + tosite.avain
    this._avaaUudessaIkkunassa(ikkunanAvain, url)
  }

  private _avaaUudessaIkkunassa(avain: string, url: string) {
    const start = window.location.protocol + '//' + window.location.host
    const height = window.screen.height - 100 < 1920 ? window.screen.height - 100 : 1920
    const width = window.screen.width - 50 < 1080 ? window.screen.width - 50 : 1080
    window.open(start + url, avain, 'height=' + height + ',width=' + width + ',modal=yes,alwaysRaised=yes')
  }

  async kopioiTositeHandler(tosite: TositeJaNaytettavaSivut) {
    if (tosite && tosite.sivut?.length) {
      const sivu = tosite.sivut[0]
      tosite.tallennetaan = true
      const payload: SivuJaAvain = { sivu: sivu.sivu, sivunAvain: sivu.avain }
      const naytettavaKirjaus: NaytettavaKirjaus = await firstValueFrom(this.naytettavaKirjausObservable)
      const kirjaus: Kirjaus = naytettavaKirjaus?.kirjaus
      const asiakas: AsiakkaanAvainTiedot = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

      if (!asiakas || !kirjaus) {
        return
      }

      return this._kirjanpitoImageHandlerService.hoidaTositteenLaittaminenNakyviin(asiakas, payload, naytettavaKirjaus).then(() => {
        delete tosite.tallennetaan
        this._changeDetector.markForCheck()
      }).catch(err => {
        this._errorHandler.handleError(err)
        this._snackbar.open('Tositteen kopiointi epäonnistui. Yritä uudelleen.', null, { duration: 2500 })
        delete tosite.tallennetaan
        this._changeDetector.markForCheck()
      })
    }
  }

  liitaValittuunKirjauksen(tosite: TositeJaNaytettavaSivut) {
    this.liitaTositeValittuunKirjaukseen.next(tosite)
  }

  ngOnInit() {

  }

  sivunLatausValmis(valmis: boolean, sivu: NaytettavaSivuUusi) {
    if (sivu && sivu.ladataan !== !valmis) {
      sivu.ladataan = !valmis
      // this._ngZone.run(() => {
      // console.log('LADATAAN', sivu.ladataan)
      //   this._changeDetector.markForCheck()
      // })
    }
  }

  sivunLatausVirhe(error: any, sivu: NaytettavaSivuUusi) {
    console.error(error)
    if (sivu) {
      sivu.virhe = error ?? 'Tuntematon virhe'
    }
  }

  zoomIn(sivu: NaytettavaSivuUusi) {
    // console.log('ZOOM IN 2')
    if (sivu?.zoomable) {
      const newMultiplier = Math.min(sivu.zoomMultiplier + IMAGE_ZOOM_CHANGE_STEP, MAX_IMAGE_ZOOM)
      // console.log('ZOOM IN 3', newMultiplier)
      sivu.zoomMultiplier = newMultiplier
    }
  }

  zoomOut(sivu: NaytettavaSivuUusi) {
    // console.log('ZOOM OUT 2')
    if (sivu?.zoomable) {
      const newMultiplier = Math.max(sivu.zoomMultiplier - IMAGE_ZOOM_CHANGE_STEP, MIN_IMAGE_ZOOM)
      // console.log('ZOOM OUT 3', newMultiplier)
      sivu.zoomMultiplier = newMultiplier
    }
  }

  rotateRight(sivu: NaytettavaSivuUusi) {
    if (sivu) {
      // console.log('OIKEALLE')
      // event.preventDefault()
      // event.stopImmediatePropagation()
      if (sivu.rotationDegrees === '90') {
        sivu.rotationDegrees = '180'
      } else if (sivu.rotationDegrees === '180') {
        sivu.rotationDegrees = '270'
      } else if (sivu.rotationDegrees === '270') {
        sivu.rotationDegrees = '0'
      } else if (sivu.rotationDegrees === '0') {
        sivu.rotationDegrees = '90'
      }
    }
  }

  private _enhance(sivu: NaytettavaSivuUusi, newValue: boolean) {
    if (sivu?.urlEnhanced) {
      if (sivu?.useEnhancedUrl && !newValue) {
        sivu.ladataan = true
        delete sivu.useEnhancedUrl
      } else if (newValue) {
        sivu.ladataan = true
        sivu.useEnhancedUrl = 1
      }
    }
  }

  enhance(sivu: NaytettavaSivuUusi) {
    if (sivu?.urlEnhanced) {
      if (sivu?.useEnhancedUrl) {
        sivu.ladataan = true
        delete sivu.useEnhancedUrl
      } else {
        sivu.ladataan = true
        sivu.useEnhancedUrl = 1
      }
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ruksiaPainettu(tosite: TositeJaNaytettavaSivut) {
    this.ruksiaNapautettiin.next(tosite)
  }

  trackbyTosite(index: number, tosite: TositeJaNaytettavaSivut) {
    // console.log('tosite', index, tosite.avain)
    return tosite.avain
  }

  trackBySivu(index: number, sivu: NaytettavaSivuUusi) {
    // console.log('sivu', index, sivu.avain)
    return sivu.avain
  }

}
