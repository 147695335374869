import { LemonaidFirebaseConfig } from './_jaettu-angular/base-firebase.service'

// eslint-disable-next-line no-shadow
export enum EnvironmentType {
  LOCAL_DEV,
  DEV,
  PRODUCTION,
  BETA
}

export interface Environment {
  enableVersionCheck: boolean
  environment: EnvironmentType
  airbrakeEnabled: boolean
  lemonatorFirebaseConfig: LemonaidFirebaseConfig
  lemonaidFirebaseConfig: LemonaidFirebaseConfig
  version: string
  host: string
  recaptchaSiteId: string
  lemonaidRecaptchaSiteId: string
}
