<mat-menu #tositeMenu="matMenu">
  <ng-template matMenuContent let-tosite="tosite" let-naytettavaKirjaus="naytettavaKirjaus">
    <button mat-menu-item (click)="tositeTositeUudessaIkkunassa(tosite)">Avaa uudessa ikkunassa</button>
    <button [disabled]="tosite.avain === naytettavaKirjaus?.kirjaus?.avain" mat-menu-item (click)="irroitaTositeHandler(tosite)">Irroita tosite kirjauksesta</button>
    <button mat-menu-item (click)="kopioiTositeHandler(tosite)">Kopioi tosite</button>
  </ng-template>
</mat-menu>

<div *ngFor="let tosite of naytettavatTositteetJaSivutObservable | async; let tositeIndex = index; trackBy: trackbyTosite" class="naytettava-tosite" [class.esikatselu]="naytaRuksi" draggable="true">

  <!-- <div *ngIf="tosite.poistetaan" [@fadeInAnimation]="'in'" style="text-align: center; margin: 30px;">
    <span style="display: inline-block; margin-bottom: 15px;">Tositetta irroitetaan</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <ng-container *ngIf="!tosite.poistetaan"> -->

  <!-- TOSITE -->
  <!-- {{ time() }} -->
  <div style="display: flex; justify-content: space-between;" *ngIf="!piilotaYlaControllit else ylaControllitSpacer">
    <div *ngIf="{ naytettavaKirjaus: (naytettavaKirjausObservable | async) } as subscribed ">
      <button *ngIf="!tosite.tallennetaan && !tosite.poistetaan && !naytaRuksi" mat-icon-button [matMenuTriggerFor]="tositeMenu" [matMenuTriggerData]="{ tosite: tosite, naytettavaKirjaus: subscribed.naytettavaKirjaus }" (click)="$event.stopPropagation()" style="margin-left: -10px;">
        <mat-icon>more_vert</mat-icon>
      </button>
      <button *ngIf="naytaRuksi && subscribed.naytettavaKirjaus" mat-button (click)="liitaValittuunKirjauksen(tosite)" style="line-height: 40px; padding-left: 0; font-size: 16px;">
        <mat-icon>attach_file</mat-icon>Liitä valittuun kirjaukseen
      </button>
    </div>
    <div *ngIf="tosite.kuittiTekstiPromise" style="flex-grow: 1; padding-top: 10px; padding-bottom: 10px;">
      <ng-container *ngIf="tosite.kuittiTekstiPromise | async; let kuittiTeksti else tositeLataa">
        {{ kuittiTeksti }}
      </ng-container>
    </div>
    <div *ngIf="tosite.luottotappio" style="flex-grow: 1; padding-top: 10px; padding-bottom: 10px;" class="red-text">
      Laskun avoinna oleva summa on merkitty luottotappioksi!
    </div>
    <button mat-icon-button *ngIf="naytaRuksi" (click)="ruksiaPainettu(tosite)" style="margin-right: -10px; font-size: 22px;">
      X
    </button>
    <div *ngIf="!naytaRuksi" style="margin-right: 2px; font-size: 16px; line-height: 40px; white-space: nowrap;">
      Tosite {{ tositeIndex + 1 }}/{{naytettavatTositteetJaSivut.length}}
    </div>
  </div>

  <ng-template #ylaControllitSpacer>
    <div>&nbsp;</div>
  </ng-template>

  <div *ngIf="tosite.tallennetaan" [@fadeInAnimation]="'in'" style="text-align: center; margin: 30px;">
    <span style="display: inline-block; margin-bottom: 15px;">Tositetta tallennetaan</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <!-- {{ tosite.lahde }} -->

  <!-- SIVUT -->
  <div #sivu [id]="sivu.avain" *ngFor="let sivu of tosite.sivut; let sivuIndex = index; trackBy: trackBySivu" class="tositteen-sivu">

    <div class="image-manipulation-tools">
      <button mat-icon-button *ngIf="sivu.zoomable" (click)="zoomOut(sivu)">
        <i class="fa fa-search-minus"></i>
      </button>
      <button mat-icon-button *ngIf="sivu.zoomable" (click)="zoomIn(sivu)">
        <i class="fa fa-search-plus"></i>
      </button>
      <button mat-icon-button *ngIf="sivu.rotatable" (click)="rotateRight(sivu)">
        <i class="fa fa-repeat"></i>
      </button>
      <button mat-icon-button *ngIf="sivu.urlEnhanced" [class.enhancingOn]="sivu.useEnhancedUrl" (click)="enhance(sivu)">
        <i class="fa fa-magic"></i>
      </button>
    </div>

    <div class="page-number">
      sivu {{sivuIndex + 1}}/{{tosite.sivut.length}}
    </div>

    <!-- draggable="true" (dragstart)="aloitaRaahaaminen($event)" (dragend)="lopetaRaahaaminen($event)" -->
    <!-- <div *ngIf="laskunEsikatselutiedotObservable | async; let esikatselutiedot else ladataanTaiVirhe"
                   [style.display]="valittuSivu?.sivu?.t === 'l' ? 'flex' : 'none'"> -->
    <div app-lasku-pdf-esikatselu-perinteinen-rajoitettu-leveyteen [@fastFadeInOutAnimation]="'in'" *ngIf="sivu.type == 'lasku'" [tiedot]="sivu.esikatselutiedot"></div>
    <!-- (click)="sivuaKlikattiin($event, naytettavatTositteetJaSivut, tosite, sivu)" -->
    <!-- </div> -->

    <!-- draggable="true" (dragstart)="aloitaRaahaaminen($event)" (dragend)="lopetaRaahaaminen($event)" -->
    <!-- [style.visibility]="(ladataanKuvaaObservable | async) ? 'hidden' : 'visible'" -->
    <ng-container *ngIf="sivu.type == 'kuitti'">
      <!-- KUITTI -->
      <ng-container *ngIf="sivu.ladataan then ladataan"></ng-container>
      <ng-container *ngIf="sivu.virhe then virhe"></ng-container>
      <div app-image-viewer [@fastFadeInOutAnimation]="'in'" [parentScrollContainer]="scrollContainerRef" [class.tositteen-sivua-ladataan]="sivu.ladataan" [class.tositteen-sivu-latausvirhe]="sivu.virhe" [rotateDegrees]="sivu.rotationDegrees" [url]="sivu.useEnhancedUrl ? sivu.urlEnhanced : sivu.url" [(zoomMultiplier)]="sivu.zoomMultiplier" (imageLoadComplete)="sivunLatausValmis($event, sivu)"
        (imageLoadError)="sivunLatausVirhe($event, sivu)"></div>
    </ng-container>
    <!-- (click)="sivuaKlikattiin($event, naytettavatTositteetJaSivut, tosite, sivu)" -->

    <ng-container *ngIf="sivu.type == 'kuitti-async'">
      <ng-container *ngIf="sivu.ladataan then ladataan"></ng-container>
      <ng-container *ngIf="sivu.virhe then virhe"></ng-container>
      <!-- ASYNC -->
      <div app-image-viewer *ngIf="sivu.ulrPromise | async; let url" [@fastFadeInOutAnimation]="'in'" [parentScrollContainer]="scrollContainerRef" [class.tositteen-sivua-ladataan]="sivu.ladataan" [class.tositteen-sivu-latausvirhe]="sivu.virhe" [rotateDegrees]="sivu.rotationDegrees" [url]="url" [(zoomMultiplier)]="sivu.zoomMultiplier" (imageLoadComplete)="sivunLatausValmis($event, sivu)"
        (imageLoadError)="sivunLatausVirhe($event, sivu)"></div>
    </ng-container>
    <!-- (click)="sivuaKlikattiin($event, naytettavatTositteetJaSivut, tosite, sivu)" -->

    <!-- <div  app-pdf-viewer [url]="safeUrl" [zoomMultiplier]="zoomMultiplier" [kuvienTargetWidth]="kuvienTargetWidth" [style.visibility]="(ladataanPdfaObservable | async) ? 'hidden' : 'visible'" (pdfLoadComplete)="pdfnLatausOnValmis($event)" draggable="true"
                    (dragstart)="aloitaRaahaaminen($event)" (dragend)="lopetaRaahaaminen($event)" style="text-align: center; position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: auto; padding-top: 40px;">
                  </div> -->
    <ng-container *ngIf="sivu.type == 'pdf'">
      <!-- PDF -->
      <ng-container *ngIf="sivu.ladataan then ladataan"></ng-container>
      <div app-pdf-viewer [@fastFadeInOutAnimation]="'in'" [class.tositteen-sivua-ladataan]="sivu.ladataan" [class.tositteen-sivu-latausvirhe]="sivu.virhe" [rotateDegrees]="sivu.rotationDegrees" [url]="sivu.url" [zoomMultiplier]="sivu.zoomMultiplier" (pdfLoadComplete)="sivunLatausValmis($event, sivu)"></div>
    </ng-container>
    <!-- (click)="sivuaKlikattiin($event, naytettavatTositteetJaSivut, tosite, sivu)" -->

    <!-- <div *ngIf="sivu.type == 'raahattu'" [kuvienTargetWidth]="targetWidthObservable | async" app-image-viewer [url]="sivu.url" style="text-align: center;"></div> -->

  </div>

  <!-- </ng-container> -->

</div>

<ng-template #virhe>
  <div [@fastFadeInOutAnimation]="'in'" style="text-align: center; margin: 60px 30px 0; position: absolute; top: 30px; left: 0; right: 0;">
    <div>
      Kuvan latauksessa tapahtui virhe.
      <!-- <button mat-button (click)="lataaUudelleen()">Yritä lataamista uudelleen napauttamalla tätä.</button> -->
    </div>
  </div>
</ng-template>

<ng-template #ladataan>
  <div [@fadeInAnimation]="'in'" style="text-align: center; margin: 60px 30px 0; position: absolute; top: 30px; left: 0; right: 0;">
    <span style="display: inline-block; margin-bottom: 15px;">Ladataan tositteen sivua</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #tositeLataa>
  <mat-progress-bar [@fadeInAnimation]="'in'" mode="indeterminate" style="margin-top: 8px; margin-left: 5px; margin-right: 10px; width: calc(100% - 20px);"></mat-progress-bar>
</ng-template>