<div class="list-page">

  <div style="display: flex; justify-content: flex-end; flex-flow: row; align-items: center; margin-bottom: 10px;">

    <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4">
      <mat-icon>add</mat-icon>
      Lisää kirjanpitaja
    </button>

  </div>

  <div>
    <div style="position: relative;">
      <mat-table matSort [dataSource]="kirjanpitajatDataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="etunimi">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Etunimi </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.etunimi}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="aktiivinen">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Aktiivinen </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="true" [checked]="!row.poistettu"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sukunimi">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sukunimi </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.sukunimi ? row.sukunimi : '&nbsp;'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sähköposti </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.email ? row.email : '&nbsp;'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row (click)="muokkaa(row)" *matRowDef="let row; columns: naytettavatKolumnit"></mat-row>

      </mat-table>
      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!kirjanpitajatLataa && kirjanpitajatDataSource?.filteredData?.length == 0">
        <div style="width: 100%; padding-top: 40px; text-align: center;">Yhtään kirjanpitäjää ei löytynyt.</div>
      </div>
      <div lemon-loading [show]="kirjanpitajatLataa"></div>
    </div>
  </div>

</div>