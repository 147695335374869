<div class="list-page kapea" style="max-width: 1200px;">

  <h2>Asiakkaan APIX-tiedot</h2>

  <div style="padding-left: 3em; padding-right: 3em;">
    <h3 style="margin: 2em 0;">Sopimukset</h3>

    <mat-progress-bar style="margin: 2em 0;" *ngIf="!ladattu" mode="indeterminate"></mat-progress-bar>

    <button style="margin-bottom: 2em;" class="secondarybutton" mat-button (click)="disableAllContracts()">Lopeta kaikki asiakkaan APIX-sopimukset</button>

    <table style="width: 100%;">
      <thead>
        <tr>
          <th style="width: 100px">Sopimus</th>
          <th style="width: 100px">Tila</th>
          <th>Parametrit</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sopimus of sopimuksetObservable | async">
          <td>{{sopimus.ContractType}}</td>
          <td style="text-align: center;">{{sopimus.ContractStatus}}</td>
          <td>{{sopimus.Parameters | ljson}}</td>
        </tr>
      </tbody>
    </table>

    <h3 style="margin: 2em 0;">Lähetetyt laskut</h3>
    <div [style.visibility]="ladattu ? 'visible' : 'hidden'" *ngFor="let lasku of laskutObservable | async">
      Laskun {{ lasku?.invoiceNumber }} lähetystyyppi: {{ lasku?.type }}, status: {{ lasku?.status }}
    </div>
    <div style="margin-bottom: 1em;">&nbsp;</div>
    <h3 [style.visibility]="ladattu ? 'visible' : 'hidden'">Raakadata: </h3>
    <div [style.visibility]="ladattu ? 'visible' : 'hidden'" [innerHTML]="laskutObservable | async | ljson"></div>
  </div>

</div>