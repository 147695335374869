import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { KirjauksenPoistovastaus, KirjauksenPoistopyyntoUusi, KirjauksenJaksotuksenPoistopyynto } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { firstValueFrom } from 'rxjs'

export interface KirjausPoistaDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
}

export interface KirjausPoistaDialogResult {
  poistoPromise: Promise<KirjauksenPoistovastaus>
  trueDeletion: boolean
}

@Component({
  templateUrl: './kirjaus.poista.dialog.html',
  styleUrls: ['./kirjaus.poista.dialog.css']
})
export class KirjausPoistaDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjausPoistaDialogData,
    private _dialogRef: MatDialogRef<KirjausPoistaDialog>,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
  ) {

  }

  ngOnInit() {

  }

  async poista() {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (asiakas) {

      if (this._data.naytettavaKirjaus.kirjaus.jaksotus) {
        const pyynto: KirjauksenJaksotuksenPoistopyynto = {
          asiakasAvain: asiakas.avain,
          jaksotus: this._data.naytettavaKirjaus.kirjaus.jaksotus,
          kirjausAvain: this._data.naytettavaKirjaus.kirjaus.avain
        }
        const vastausPromise: Promise<KirjauksenPoistovastaus> = this._firebase.functionsCall<KirjauksenJaksotuksenPoistopyynto, KirjauksenPoistovastaus>('kirjanpitoKirjauksetJaksotaPoista', pyynto)
        const result: KirjausPoistaDialogResult = {
          poistoPromise: vastausPromise,
          trueDeletion: !!this._data.naytettavaKirjaus.kirjaus.kirjausLuotu
        }
        this._dialogRef.close(result)
      } else {
        const pyynto: KirjauksenPoistopyyntoUusi = {
          asiakasAvain: asiakas.avain,
          kirjausAvain: this._data.naytettavaKirjaus.kirjaus.avain
        }
        const vastausPromise: Promise<KirjauksenPoistovastaus> = this._firebase.functionsCall<KirjauksenPoistopyyntoUusi, KirjauksenPoistovastaus>('kirjanpitoKirjauksetPoistaUusi', pyynto)
        const result: KirjausPoistaDialogResult = {
          poistoPromise: vastausPromise,
          trueDeletion: !!this._data.naytettavaKirjaus.kirjaus.kirjausLuotu
        }
        this._dialogRef.close(result)
      }

    }

  }

}
