import { OnInit, Component, ChangeDetectionStrategy, OnDestroy, ErrorHandler, ViewChildren, QueryList, NgZone } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatInput } from '@angular/material/input'

import { KirjanpidonProjekti } from '../../_jaettu-lemonator/model/kirjanpito'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { KirjanpitoService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { Subject, of as observableOf, Observable, BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { IFirestoreBatch } from 'app/_jaettu-angular/base-firebase.service'

@Component({
  selector: '[app-kirjanpito-projektit-listaus]',
  templateUrl: './projektit-listaus.component.html',
  styleUrls: ['./projektit-listaus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoProjektitListausComponent implements OnInit, OnDestroy {

  @ViewChildren('nimi', { read: MatInput }) nimiInputit: QueryList<MatInput>

  private _addedProjektitSubject: BehaviorSubject<UntypedFormGroup[]> = new BehaviorSubject([])
  projektitArrayObservable: Observable<UntypedFormGroup[]>

  private _ngUnsubscribe = new Subject<void>()

  projektiForm: UntypedFormGroup = new UntypedFormGroup({ 'projektitArray': new UntypedFormArray([]) })

  colorNameAndClassMap: {}
  namename: string = 'afasdfasdfayuiopålkjhgf' + new Date().getTime()

  private _saveStarted: boolean
  showCommonError: boolean = false

  constructor(
    private _firebase: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _asiakasService: AsiakasService,
    private _kirjanpitoService: KirjanpitoService,
    private _validationService: FormValidationService,
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
    this.colorNameAndClassMap = this._kirjanpitoService.getColorNameAndClassMap()
    const tietokannastaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<KirjanpidonProjekti[]>([])
        }
        return this._firebase.firestoreCollection<KirjanpidonProjekti>(this._kirjanpitoUriService.annaKirjanpitoProjektitCollection(asiakas.avain)).listen()
      }),
      map(projektit => {
        const formGroups: UntypedFormGroup[] = []
        if (projektit?.length) {
          projektit.sort((a, b) => { return (a.nimi || '').localeCompare((b.nimi || '')) })
          for (const projekti of projektit) {
            formGroups.push(this._createProjektiForm(projekti))
          }
        }
        return formGroups
      })
    )
    this.projektitArrayObservable = combineLatest([tietokannastaObservable, this._addedProjektitSubject]).pipe(
      map(([tietokannasta, projektit]) => {

        const projektitArray = this.projektiForm.get('projektitArray') as UntypedFormArray
        projektitArray.clear()

        for (const projekti of tietokannasta) {
          projektitArray.push(projekti)
        }

        for (const projekti of projektit) {
          projektitArray.push(projekti)
        }

        return projektitArray.controls as UntypedFormGroup[]
      })
    )
  }

  createNewProject() {
    const newProject: KirjanpidonProjekti = {
      avain: this._firebase.firestoreCreateId(),
      nimi: null,
      color: null,
      usableInLemonator: true,
      viewableInLemonator: true,
      viewableInLemonaid: false
    }
    this._addedProjektitSubject.value.push(this._createProjektiForm(newProject))
    this._addedProjektitSubject.next(this._addedProjektitSubject.value)
    setTimeout(() => {
      this._ngZone.run(() => {
        const last = this.nimiInputit.last
        if (last) { last.focus() }
      })
    }, 300)
  }

  private _createProjektiForm(projekti: KirjanpidonProjekti) {
    const form = new UntypedFormGroup({
      'avain': new UntypedFormControl(projekti.avain),
      'nimi': new UntypedFormControl(projekti.nimi, Validators.required),
      'vari': new UntypedFormControl(projekti.color, Validators.required),
      'usableInLemonator': new UntypedFormControl(projekti.usableInLemonator),
      'viewableInLemonator': new UntypedFormControl(projekti.viewableInLemonator),
      'viewableInLemonaid': new UntypedFormControl(projekti.viewableInLemonaid),
      'aktiivinen': new UntypedFormControl(projekti.viewableInLemonaid),
      'raporteilla': new UntypedFormControl(projekti.usableInLemonator)
    })
    return form
  }

  async saveValidProjektit() {

    if (!this.projektiForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.projektiForm)
      return
    }

    if (this._saveStarted) {
      return
    }
    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    const batch = this._firebase.firestoreBatch()
    const asiakastiedot = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const projektitArray = this.projektiForm.get('projektitArray') as UntypedFormArray
    const kaikkiProjektit = projektitArray.controls ? projektitArray.controls as UntypedFormGroup[] : []

    for (const form of kaikkiProjektit) {
      await this.addProjektiToBatch(asiakastiedot.avain, form, batch)
    }

    const toBePersisted = this._addedProjektitSubject.value
    this._addedProjektitSubject.next([])
    return batch.commit().then(() => {
      this._addedProjektitSubject.value.length = 0
      this._addedProjektitSubject.next(this._addedProjektitSubject.value)
    }).catch(error => {
      this._addedProjektitSubject.next(toBePersisted)
      this.showCommonError = true
      this._errorHandler.handleError(new Error('Projektit save failed ' + error?.toString()))
    }).finally(() => {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    })

  }

  private async addProjektiToBatch(asiakasAvain: string, form: UntypedFormGroup, batch: IFirestoreBatch) {
    const avain = form.get('avain').value
    const doc = this._kirjanpitoUriService.annaKirjanpitoProjektinUri(asiakasAvain, avain)
    const data: KirjanpidonProjekti = {
      avain: avain,
      nimi: form.get('nimi').value,
      color: form.get('vari').value,
      usableInLemonator: form.get('usableInLemonator').value,
      viewableInLemonator: form.get('viewableInLemonator').value,
      viewableInLemonaid: form.get('viewableInLemonaid').value
    }
    batch.set(doc, data)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
