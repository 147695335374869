<mat-dialog-content>

  <lemon-dialog-header>
    Kirjaa laskuperusteisena
  </lemon-dialog-header>

  <div class="tosite-tiedot">
    <form [formGroup]="form" novalidate style="margin: 0;">
      <mat-form-field>
        <input type="text" [max]="viimeinenPaiva" [min]="ensimmainenPaiva" [matDatepicker]="picker" [name]="namename" formControlName="kirjauksenPvm" placeholder="Laskun päivämäärä" required matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-error>Päivämäärä puuttuu</mat-error>
      </mat-form-field>
      <mat-datepicker #picker></mat-datepicker>
    </form>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="kopioi()">Kirjaa laskuperusteisena</button>
</mat-dialog-actions>