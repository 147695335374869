<div class="box">
  <form #osakkaatForm="ngForm" [formGroup]="form">

    <div class="lemon-error" *ngIf="osakkaatFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-osakas' | translate | async }}</div>

    <div class="top-info">
      <div *ngIf="isDataFromKyc" style="margin-bottom: 5px;">Osakkaat haettiin tuntemistietojen uusimmasta versiosta (tallennettu {{ tuntemistiedotTallennettu }}).</div>
      <div style="font-size: 11px;">Huom! Nämä tiedot tallennetaan vain veroilmoitukselle. Tämän lomakkeen tiedot eivät vaikuta asiakkaan tuntemistietoihin millään tavalla.</div>
    </div>

    <div formArrayName="osakkaat">
      <div *ngFor="let osakasFormGroup of osakasFormGroupit; let i = index;" [formGroupName]="i" class="kyc-row">
        <mat-form-field class="kyc-col">
          <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
          <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
          <mat-error>
            <ng-container *ngIf="osakasFormGroup.get('nimi'); let n">
              <ng-container *ngIf="(n.touched || osakkaatForm.submitted)">
                <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="kyc-col">
          <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu-ytunnus' | translate | async }}</mat-label>
          <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
          <mat-error>
            <ng-container *ngIf="osakasFormGroup.get('hetu'); let n">
              <ng-container *ngIf="(n.touched || osakkaatForm.submitted)">
                <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-required' | translate | async }}</ng-container>
                <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-invalid' | translate | async }}</ng-container>
                <ng-container *ngIf="n.errors?.ownytunnus">{{ 'lemonaid-sopimus-dialog.kyc.oma-ytunnus' | translate | async}}</ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="kyc-col">
          <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara' | translate | async }}</mat-label>
          <input decimalNumberField matInput formControlName="osakkeita" [numberOfDecimals]="0" autocomplete="nothing" required data-lpignore="true">
          <mat-error>
            <ng-container *ngIf="osakasFormGroup.get('osakkeita'); let n">
              <ng-container *ngIf="(n.touched || osakkaatForm.submitted)">
                <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara-puuttuu' | translate | async }}</ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <button mat-icon-button (click)="poistaOsakas(i); $event.preventDefault();">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <button class="secondarybutton kyc-lisaa" mat-button (click)="lisaaOsakas(); $event.preventDefault();">
      Lisää osakas
    </button>

    <!-- <button class="primarybutton kyc-lisaa" mat-button (click)="tallenna(); $event.preventDefault();">
      Tallenna
    </button> -->

    <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

  </form>
</div>