<mat-dialog-content>

  <lemon-dialog-header>
    Tositteiden lataushistoria
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <div *ngIf="commonError" class="error-text">
      Lataushistorian lataamisen tai tositteiden lataamismerkintöjen peruuttamisen aikana tapahtui virhe. Virhe on tallennettu. Ole hyvä ja ota yhteys ylläpitoon.
    </div>
    <table>
      <tr>
        <th>Vuosi/kk</th>
        <th>Latauspvm</th>
        <th>Lataaja</th>
        <th>Peru merkintä</th>
      </tr>
      <tr *ngFor="let latauskerta of latauskerrat">
        <td>{{latauskerta.vuosi}}/{{latauskerta.kuukausi}}</td>
        <td>{{latauskerta.lataamisaika | ltimestamp | async}}</td>
        <td>{{latauskerta.lataajanNimi}}</td>
        <td style="text-align: center;">
          <button mat-icon-button (click)="merkitseLataamattomaksi(latauskerta)">
            <mat-icon>undo</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>Sulje</button>
</mat-dialog-actions>