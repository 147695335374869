import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './changelog.dialog.html'
})
export class ChangelogDialog {

  constructor(
    private dialogRef: MatDialogRef<ChangelogDialog>
  ) {

  }

}
