
<mat-dialog-content>

  <lemon-dialog-header>
    {{'lasku.koko-virhe-dialog.otsikko' | translate | async}}
  </lemon-dialog-header>

  <pre style="padding-left: 3em; padding-right: 3em;">{{data.viesti | json}}</pre>
  
</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</mat-dialog-actions>
