<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <div style="display: flex; justify-content: flex-end; flex-flow: row; align-items: center; margin-bottom: 10px;">

    <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4" style="padding-left: 8px;">
      <mat-icon>add</mat-icon>
      Lisää tili
    </button>

  </div>

  <h1>Päätilikartta</h1>

  <div *ngIf="commonError" class="error-text">{{ commonError }}</div>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="example-list" [class.hidden]="loading" cdkDropList (cdkDropListDropped)="drop($event)">
    <div (click)="muokkaa(node.kirjanpitotili)" class="example-box" *ngFor="let node of flatatyt | async" [style.padding-left]="(node.level * 15) + 'px'" cdkDrag>
      {{node.name}}
    </div>
  </div>

</div>