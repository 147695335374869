import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, AfterContentInit, ErrorHandler, Input, ViewChild, ElementRef } from '@angular/core'

import { Subject, BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, takeUntil, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

import { DateService } from '../_shared-core/service/date.service'
import { Asiakas, KirjanpitoKuukausiruutu } from '../_jaettu-lemonator/model/asiakas'
import { AsiakasUriService } from '../_jaettu-lemonator/service/asiakas-uri.service'
import { KuukausiruudunTiedot } from '../asiakkaat/listaus/asiakkaat.datasource.service'
import { KirjanpitoKelloService } from '../_angular/service/kirjanpito/kirjanpito-kello.service'
import { KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjanpitajanTiimiService } from 'app/_jaettu-lemonator/service/kirjanpitajan-tiimi.service'

@Component({
  selector: '[app-kirjanpito-kello]',
  templateUrl: './kirjanpito-kello.component.html',
  styleUrls: ['./kirjanpito-kello.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
  // encapsulation: ViewEncapsulation.None,
  // animations: [
  //   // the fade-in/fade-out animation.
  //   trigger('fadeInAnimation', [
  //     // the "in" style determines the "resting" state of the element when it is visible.
  //     state('in', style({ opacity: 1 })),

  //     // fade in when created. this could also be written as transition('void => *')
  //     transition(':enter', [style({ opacity: 0 }), animate(2000)]),

  //     // fade out when destroyed. this could also be written as transition('void => *')
  //     transition(':leave', animate(0, style({ opacity: 0 })))
  //   ]),
  //   // the fade-in/fade-out animation.
  //   trigger('fastFadeInOutAnimation', [
  //     // the "in" style determines the "resting" state of the element when it is visible.
  //     state('in', style({ opacity: 1 })),

  //     // fade in when created. this could also be written as transition('void => *')
  //     transition(':enter', [style({ opacity: 0 }), animate(150)]),

  //     // fade out when destroyed. this could also be written as transition('void => *')
  //     transition(':leave', animate(150, style({ opacity: 0 })))
  //   ])
  // ]
})
export class KirjanpitoKelloComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('timeElement', { static: false }) timeElementRef: ElementRef<HTMLElement>
  @Input() currentRuutuObservable: Observable<KuukausiruudunTiedot>
  @Input() paused: boolean = false

  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _asiakasUriService: AsiakasUriService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _errorHandler: ErrorHandler,
    private _kirjanpitoKelloService: KirjanpitoKelloService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _firebase: FirebaseLemonator,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService
  ) { }

  minusMinute() {
    this._requestSaveChangeSubject.next({ change: -60, skipAdjust: false })
  }

  plusMinute() {
    this._requestSaveChangeSubject.next({ change: 60, skipAdjust: false })
  }

  togglePause() {
    if (this._secondsIntervalHandle) {
      this._kirjanpitoKelloService.pysaytaKello()
      this._stopClock()
    } else {
      this._startClock()
      this._kirjanpitoKelloService.kaynnistaKello()
    }
  }

  private _startClock() {
    if (this._clockStartedAt) {
      this._clockStartedAt += Date.now() - (this._clockStoppedAt || 0)
    } else {
      this._clockStartedAt = Date.now()
    }
    this._clockStoppedAt = null
    this._secondsIntervalHandle = window.setInterval(() => {
      const val = Math.floor((Date.now() - this._clockStartedAt) / 1000)
      this._secondsSinceLandSubject.next(val)
    }, 1000)
    this._isClockRunningSubject.next(true)
  }

  private _clockStoppedAt: number = null
  private _stopClock() {
    try {
      window.clearInterval(this._secondsIntervalHandle)
    } catch (err) {
      this._errorHandler.handleError(err)
    }
    this._secondsIntervalHandle = null
    this._clockStoppedAt = Date.now()
    this._isClockRunningSubject.next(false)
  }

  private _secondsSinceLandSubject = new BehaviorSubject<number>(0)
  private _isClockRunningSubject = new BehaviorSubject<boolean>(false)
  private _requestSaveChangeSubject = new Subject<{ change: number, skipAdjust: boolean }>()
  // secondsSinceLandObservable = this.secondsSinceLandSubject.asObservable()
  currentTimeInDatabaseObservable: Observable<string>
  isClockRunningObservable: Observable<boolean> = combineLatest([this._isClockRunningSubject.asObservable(), this._kirjanpitoKelloService.kelloOnPysaytettyObservable]).pipe(
    map(([runningLocally, pausedGlobally]) => {
      return runningLocally && !pausedGlobally
    })
  )
  // currentElapsedTimeObservable = this._secondsSinceLandSubject.pipe(
  //   map(seconds => {
  //     if (seconds) {
  //       const minuutit = Math.floor(seconds / 60)
  //       const sekunnit = Math.floor(seconds - (minuutit * 60))
  //       return minuutit + (sekunnit < 10 ? ':0' : ':') + sekunnit
  //     }
  //     return '0:00'
  //   })
  // )

  private _secondsIntervalHandle: number
  private _lastUpdateSeconds = 0
  private _clockStartedAt: number

  ngOnInit() {

    this._kirjautunutKayttajaService.kirjanpitajaOnItTiiminJasenObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(onkoIt => {

      if (onkoIt) {
        this._kirjanpitoKelloService.pysaytaKello()
        this._stopClock()
      }
    })


    const emitOnlyIfRuutuChangesRuutuObservable = this.currentRuutuObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a.k === b.k
      })
    )

    combineLatest([this._secondsSinceLandSubject, emitOnlyIfRuutuChangesRuutuObservable]).pipe(
      map(([seconds, ruutu]) => {
        const dbSeconds = (ruutu?.ka ?? 0) * 60
        const totalSeconds = dbSeconds + (seconds ?? 0)
        if (totalSeconds) {
          const minuutit = Math.floor(totalSeconds / 60)
          const sekunnit = Math.floor(totalSeconds - (minuutit * 60))
          return minuutit + (sekunnit < 10 ? ':0' : ':') + sekunnit
        }
        return '0:00'
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      // console.log('SET sfsdfdfdgfgsff sdfg fs', value, this.timeElementRef.nativeElement)
      this.timeElementRef.nativeElement.innerText = value
    }, error => { console.error('ERROR', error) })

    this.currentTimeInDatabaseObservable = this.currentRuutuObservable.pipe(
      map(ruutu => {
        if (ruutu && ruutu.ka) {
          const minuutit = Math.floor(ruutu.ka)
          const sekunnit = Math.round((ruutu.ka - minuutit) * 60)
          return minuutit + (sekunnit < 10 ? ':0' : ':') + sekunnit
        }
        return '0:00'
      })
    )

    emitOnlyIfRuutuChangesRuutuObservable.pipe(
      withLatestFrom(this._kirjanpitoKelloService.kelloOnPysaytettyObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([ruutu, pausedGlobally]) => {

      this._lastUpdateSeconds = 0
      try {
        window.clearInterval(this._secondsIntervalHandle)
      } catch (err) {
        this._errorHandler.handleError(err)
      }

      if (pausedGlobally) {
        this._clockStartedAt = null
        this._secondsSinceLandSubject.next(0)
      } else {
        this._clockStartedAt = Date.now()
        this._secondsIntervalHandle = window.setInterval(() => {
          this._secondsSinceLandSubject.next(Math.floor((Date.now() - this._clockStartedAt) / 1000))
        }, 1000)
        this._isClockRunningSubject.next(true)
      }

    })

    const timeSaveObservable: Observable<{ asiakas: Asiakas, ruutu: KirjanpitoKuukausiruutu, muutosSekuntia: number }> = this._requestSaveChangeSubject.pipe(
      withLatestFrom(this.currentRuutuObservable, this._asiakasService.nykyinenAsiakasObservable),
      map(([requestedChange, currentRuutu, asiakas]) => {

        // console.log('Save change was requested', requestedChange)

        if (!currentRuutu) {
          console.log('No current ruutu, skipping request')
          return null
        }

        if (!asiakas) {
          console.log('No current asiakas, skipping request')
          return null
        }

        if (requestedChange.change > 0) {
          if (!requestedChange.skipAdjust) {
            this._clockStartedAt = (this._clockStartedAt || 0) - requestedChange.change * 1000
            this._secondsSinceLandSubject.next((this._secondsSinceLandSubject.value || 0) + requestedChange.change)
            this._lastUpdateSeconds += requestedChange.change
          }
          return { ruutu: currentRuutu, muutosSekuntia: requestedChange.change, asiakas: asiakas }
        } else {
          const dbSecs = (currentRuutu.ka || 0) * 60
          const minusSecs = dbSecs > Math.abs(requestedChange.change) ? Math.abs(requestedChange.change) : dbSecs || 0
          if (!requestedChange.skipAdjust) {
            this._clockStartedAt = (this._clockStartedAt || 0) + minusSecs * 1000
            this._secondsSinceLandSubject.next((this._secondsSinceLandSubject.value || 0) - minusSecs)
            this._lastUpdateSeconds -= minusSecs
          }
          return { ruutu: currentRuutu, muutosSekuntia: -minusSecs, asiakas: asiakas }
        }

      })
    )

    timeSaveObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(req => {
      if (req) {
        const localMonth = this._dateService.numberToLocalMonth(req.ruutu.k)
        const uri = this._asiakasUriService.annaListausRuutuUri(req.asiakas.avain, localMonth)
        // console.log('Saving', req.muutos, 'to', uri)
        const updateData: Partial<KirjanpitoKuukausiruutu> = {
          ka: this._firebase.firestoreFieldIncrement(req.muutosSekuntia / 60)
        }
        this._firebase.firestoreUpdateData<Partial<KirjanpitoKuukausiruutu>>(uri, updateData).catch(error => {
          if (error?.toString && error?.toString().includes('No document to update')) {
            const ruutu = this._asiakasJaettuService.annaUusiKirjanpitoRuutu(req.asiakas, localMonth)
            ruutu.ka = this._firebase.firestoreFieldIncrement(req.muutosSekuntia / 60)
            return this._firebase.firestoreSetData(uri, ruutu, { merge: true })
          }
          throw error
        }).catch(err => {
          this._errorHandler.handleError(err)
        })
      }
    })

    this._secondsSinceLandSubject.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(sekat => {
      if (sekat % 15 === 0 && this._lastUpdateSeconds < sekat) {
        this._requestSaveChangeSubject.next({ change: 15, skipAdjust: true })
        this._lastUpdateSeconds = sekat
      }
    })

  }

  ngAfterContentInit() {
    // this.paivitaLeveys()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
