import { OnInit, OnDestroy, Component, Input, ChangeDetectorRef } from '@angular/core'
import { Observable, Subject, firstValueFrom, map, of as observableOf, switchMap, takeUntil, tap } from 'rxjs'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { VeroilmoitusDraft, VeroilmoitusOsakas, VeroilmoitusOsakkaatHistoria } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KnowYourCustomer } from 'app/_jaettu/model/kayttaja'
import { KycUriService } from 'app/_jaettu/service/kyc-uri.service'
import { FormArray, FormControl, FormGroup } from '@angular/forms'
import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { VeroilmoitusLaskentaService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-laskenta.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

interface OsakasFormGroup {
  'nimi': FormControl<string>
  'hetu': FormControl<string>
  'osakkeita': FormControl<number>
}

@Component({
  selector: '[app-kirjanpito-veroilmoitus-osakkaat]',
  templateUrl: './veroilmoitus-osakkaat.component.html',
  styleUrls: ['./veroilmoitus-osakkaat.component.css']
})
export class KirjanpitoVeroilmoitusOsakkaatComponent implements OnInit, OnDestroy {

  @Input() veroilmoitusObservable: Observable<VeroilmoituksenMuokkaustiedot>
  @Input() showSnackbar?: boolean

  namename = 'xasd' + Math.random()

  isDataFromKyc: boolean
  commonError: string

  tuntemistiedotTallennettu: string
  osakasFormGroup: FormGroup<OsakasFormGroup>

  private _cache: VeroilmoitusOsakas[] = []

  form: FormGroup<{
    'osakkaat': FormArray<FormGroup>
  }>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _asiakasService: AsiakasService,
    private _lemonaidFirebase: FirebaseLemonaid,
    private _lemonatorFirebase: FirebaseLemonator,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kycUriService: KycUriService,
    private _dateService: DateService,
    private _validationService: FormValidationService,
    private _snackbar: MatSnackBar,
    private _changeDetectionRef: ChangeDetectorRef,
    private _veroilmoitusLaskentaService: VeroilmoitusLaskentaService,
    private _timestampService: TimestampService
  ) {

  }

  ngOnInit(): void {

    this.form = new FormGroup({
      'osakkaat': new FormArray<FormGroup<OsakasFormGroup>>([], FormValidators.vahintaanYksiArrayssaValidator)
    })

    const veroilmoOsakkaatObservable: Observable<VeroilmoitusOsakas[]> = this.veroilmoitusObservable.pipe(
      map(veroilmoitus => veroilmoitus?.osakkaat ?? [])
    )

    const tuntemistiedotObservable: Observable<KnowYourCustomer> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(avaintiedot => {
        if (!avaintiedot) {
          return observableOf(null)
        }
        return this._lemonaidFirebase.firestoreDoc<KnowYourCustomer>(this._kycUriService.getCustomerKycDocUri(avaintiedot.avain)).listen()
      })
    )

    const combinedObservable: Observable<VeroilmoitusOsakas[]> = veroilmoOsakkaatObservable.pipe(
      switchMap(veroilmoitusOsakkaat => {
        if (veroilmoitusOsakkaat?.length) {
          this.isDataFromKyc = false
          this.tuntemistiedotTallennettu = ''
          return observableOf<VeroilmoitusOsakas[]>(veroilmoitusOsakkaat)
        }
        return tuntemistiedotObservable.pipe(
          tap(kyc => {
            this.isDataFromKyc = true
            this.tuntemistiedotTallennettu = this._dateService.muotoilePaivaJaAikaDate(kyc.paivitetty.toDate(), 'fi')
          }),
          map(kyc => {
            return kyc.osakkaat.map(osakas => this._veroilmoitusLaskentaService.convertKycToVeroilmoitusOsakas(osakas))
          })
        )
      })
    )

    combinedObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(osakkaat => {

      this._cache = []
      this.osakkaatFormArray.clear()

      for (const osakas of osakkaat) {
        this.lisaaOsakas(osakas)
      }

      this._changeDetectionRef.markForCheck()
    })

  }

  async tallenna() {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return 'failed'
    }

    const data: VeroilmoitusOsakas[] = [... this._cache]
    const [avaintiedot, veroilmoitus, kirjanpitajanTiedot] = await Promise.all([
      firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable),
      firstValueFrom(this.veroilmoitusObservable),
      this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    ])

    const tilikausiAvain = veroilmoitus?.perustiedot?.tilikausi?.avain
    if (!avaintiedot || !tilikausiAvain || !kirjanpitajanTiedot) {
      throw new Error('Avaimet puuttuvat')
    }

    const veroilmoitusUri = this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(avaintiedot.avain, veroilmoitus.perustiedot.tilikausi)
    const historiaAvain = this._lemonatorFirebase.firestoreCreateId()
    const osakkaatHistoriaUri = this._kirjanpitoUriService.annaVeroilmoituksenOsakkaatHistoriaUri(avaintiedot.avain, tilikausiAvain, historiaAvain)

    const veroilmoitusUpdateData: Pick<VeroilmoitusDraft, 'osakkaat'> = {
      osakkaat: data
    }
    const historiaData: VeroilmoitusOsakkaatHistoria = {
      osakkaat: data,
      muokattu: this._timestampService.now(),
      muokkaaja: kirjanpitajanTiedot.uid
    }
    const batch = this._lemonatorFirebase.firestoreBatch()
    batch.set(veroilmoitusUri, veroilmoitusUpdateData, { merge: true })
    batch.set(osakkaatHistoriaUri, historiaData)
    await batch.commit()

    if (this.showSnackbar) {
      this._snackbar.open('Osakkaiden tiedot lisätty veroilmoitukseen', 'OK', { duration: 3000 })
    }
    this.isDataFromKyc = false
    return 'success'

  }

  get osakkaatFormArray(): FormArray<FormGroup<OsakasFormGroup>> {
    return this.form.get('osakkaat') as FormArray<FormGroup<OsakasFormGroup>>
  }

  get osakasFormGroupit(): FormGroup<OsakasFormGroup>[] {
    return this.osakkaatFormArray.controls as FormGroup<OsakasFormGroup>[]
  }

  lisaaOsakas(firestoresta?: VeroilmoitusOsakas) {
    const osakas: VeroilmoitusOsakas = {
      nimi: firestoresta?.nimi || null,
      hetuTaiYtunnus: firestoresta?.hetuTaiYtunnus || null,
      osakkeita: firestoresta?.osakkeita || null
    }

    const osakasFormGroup = new FormGroup<OsakasFormGroup>({
      'nimi': new FormControl<string>(osakas.nimi),
      'hetu': new FormControl<string>(osakas.hetuTaiYtunnus),
      'osakkeita': new FormControl<number>(osakas.osakkeita)
    })

    osakasFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.nimi = value || null })
    osakasFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.hetuTaiYtunnus = value || null })
    osakasFormGroup.get('osakkeita').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.osakkeita = value || null })

    this.osakkaatFormArray.push(osakasFormGroup)
    this._cache.push(osakas)
  }

  poistaOsakas(index: number) {
    this.osakkaatFormArray.removeAt(index)
    this._cache.splice(index, 1)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
