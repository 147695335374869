<div style="display: flex; justify-content: space-between; flex-flow: row; align-items: center; margin-bottom: 10px;">
  <div style="width: 75px;">
    <mat-select placeholder="Kieli" [ngModel]="valittuKieliSubject | async" (selectionChange)="kieliMuuttui($event)" style="width: 100%;" required>
      <mat-option value="fi">Suomi</mat-option>
      <mat-option value="en">Englanti</mat-option>
    </mat-select>
  </div>
  <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4" style="padding-left: 8px;">
    <mat-icon>add</mat-icon>
    Lisää tili
  </button>
</div>

<div *ngIf="commonError" class="error-text">{{ commonError }}</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="example-list" [class.hidden]="loading" cdkDropList (cdkDropListDropped)="drop($event)">
  <ng-container *ngFor="let node of flatatyt | async">
    <div [class.draggable]="node.kirjanpitotili.numero.length > 4" [class.dark-gray-text]="5 > node.kirjanpitotili.numero.length" [class.notdraggable]="5 > node.kirjanpitotili.numero.length" class="example-box" (click)="muokkaa(node.kirjanpitotili)" [style.padding-left]="(node.level * 15) + 'px'" cdkDrag>
      {{node.name}}
      <div *ngIf="5 > node.kirjanpitotili.numero.length">
        <div *cdkDragPreview class="red-text" style="border: 1px solid red;">
          Päätilikartan tilejä EI voi järjestellä.
        </div>
      </div>
    </div>
  </ng-container>
</div>