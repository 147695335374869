import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, AfterViewInit } from '@angular/core'

import { MatInput } from '@angular/material/input'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { AsiakasSalesListaAsiakas, AsiakasSalesListaAsiakasResponse } from 'app/_jaettu-lemonator/model/asiakas'
import { AsiakasService, LokalisoituAlvIlmoitusjakso, LokalisoituYritysmuoto } from 'app/_angular/service/asiakas/asiakas.service'
import { AsiakkaanMuistiinpanotDialog, AsiakkaanMuistiinpanotDialogData, AsiakkaanMuistiinpanotDialogReturnData } from './listaus/asiakkaan-muistiinpanot.dialog'
import { MatDialog } from '@angular/material/dialog'

interface LaajennettuSalesAsiakas extends AsiakasSalesListaAsiakas {
  luokat: string
}

@Component({
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class AsiakasSalesListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) hakuInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  tulokset: BehaviorSubject<LaajennettuSalesAsiakas[]> = new BehaviorSubject(null)

  yritysmuodot: LokalisoituYritysmuoto[]
  alvjaksot: LokalisoituAlvIlmoitusjakso[]

  constructor(
    private _dialog: MatDialog,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator
  ) {
    this.alvjaksot = this._asiakasService.alvIlmoitusjaksot
    this.yritysmuodot = this._asiakasService.yritysmuodotJaEiTiedossa
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._asiakasService.asetaNykyinenAsiakas(null)
      this.search()
    }, 10)
  }

  private _setLuokat(asiakas: AsiakasSalesListaAsiakas) {
    if (asiakas.f === 'pun') {
      asiakas['luokat'] = ' a-l-r-tr-pun'
    } else if (asiakas.f === 'sin') {
      asiakas['luokat'] = ' a-l-r-tr-sin'
    } else if (asiakas.f === 'vsi') {
      asiakas['luokat'] = ' a-l-r-tr-vsi'
    } else if (asiakas.f === 'kel') {
      asiakas['luokat'] = ' a-l-r-tr-kel'
    } else if (asiakas.f === 'vih') {
      asiakas['luokat'] = ' a-l-r-tr-vih'
    } else if (asiakas.f === 'vio') {
      asiakas['luokat'] = ' a-l-r-tr-vio'
    }
  }

  async search() {

    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebase.functionsCall<null, AsiakasSalesListaAsiakasResponse>('asiakasSalesLista', null, { timeout: 540 * 1000 })
      if (resp.e || !resp.asiakkaat) {
        throw new Error('Error: ' + resp.e)
      }
      resp.asiakkaat.sort((a, b) => {
        const lowera = a.n.toLowerCase()
        const lowerb = b.n.toLowerCase()
        if (lowera !== lowerb) {
          return lowera.localeCompare(lowerb)
        }
        return a.y.localeCompare(b.y)
      })
      resp.asiakkaat.forEach(asiakas => this._setLuokat(asiakas))
      this.tulokset.next(resp.asiakkaat as LaajennettuSalesAsiakas[])
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async muokkaaMuistiinpanoja(event: MouseEvent, listausAsiakas: AsiakasSalesListaAsiakas) {
    event.stopPropagation()
    event.preventDefault()
    const dialogData: AsiakkaanMuistiinpanotDialogData = {
      asiakas: listausAsiakas
    }
    const dialogRef = this._dialog.open(AsiakkaanMuistiinpanotDialog, { autoFocus: false, data: dialogData })
    const ret: AsiakkaanMuistiinpanotDialogReturnData = await firstValueFrom(dialogRef.afterClosed())
    if (ret) {
      listausAsiakas.m = ret.muistiinpanot
      listausAsiakas.f = ret.vari
      this._setLuokat(listausAsiakas)
      this.tulokset.next(this.tulokset.value)
    }
  }

}
