export class YllapitoUriService {

  // Käyttöehdot
  annaLemonaidKayttoehtojenMainUri() {
    return 'admin/lemonaid-kayttoehdot'
  }
  annaLemonaidKayttoehtojenAvainUri() {
    return 'admin/lemonaid-kayttoehdot-avain'
  }
  annaLemonaidKayttoehtojenVersiotCollection() {
    return 'admin/lemonaid-kayttoehdot/lemonaid-kayttoehdot-versiot'
  }
  annaLemonaidKayttoehdonVersionUri(versionAvain: string) {
    return 'admin/lemonaid-kayttoehdot/lemonaid-kayttoehdot-versiot/' + versionAvain
  }
  annaLemonaidKayttoehdonTyojononUri(tyojonoAvain: string) {
    return 'tyojono/jzATU474P4BRCkEANZ1H/julkaise_uudet_kayttoehdot/' + tyojonoAvain
  }

  // Sopimukset
  annaSopimuksetMainUri() {
    return 'admin/sopimukset'
  }
  annaSopimuksetVersiotCollection() {
    return 'admin/sopimukset/sopimukset-versiot'
  }
  annaSopimuksetVersionUri(versionAvain: string) {
    return 'admin/sopimukset/sopimukset-versiot/' + versionAvain
  }
  annaSopimuksetTyojononUri(tyojonoAvain: string) {
    return 'tyojono/jzATU474P4BRCkEANZ1H/julkaise_uusi_sopimus/' + tyojonoAvain
  }
}
