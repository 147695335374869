import { Observable, BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable()
export class Aikakone {

  private nykyinenPaivaVaihdettiinSubject = new BehaviorSubject(null)
  nykyinenPaivaVaihdettiinObservable: Observable<Date | null> = this.nykyinenPaivaVaihdettiinSubject.asObservable()

  private _date: Date | null = null
  asetaYliajopäivämäärä(pvm: Date) {
    this._date = pvm
    this.nykyinenPaivaVaihdettiinSubject.next(pvm)
  }

  annanNykyinenPaivamaara(): Date {
    if (this._date) {
      return this._date
    }
    return new Date()
  }

}
