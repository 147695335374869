<span *ngIf="tila === 'v'" title="Sopimus voimassa">{{hinta}}</span>

<span *ngIf="tila === 'pa'" title="Sopimus päättynyt" class="asiakaslista-ikoni ion-ios-close-circle-outline"></span>

<span *ngIf="tila === 'p'" title="Sopimus päättymässä" class="asiakaslista-ikoni ion-ios-close-circle-outline blink-asiakaslista-x"></span>

<ng-container *ngIf="tila === 'pu'">
  <span title="Sopimus purettu" style="background-color: black; border-radius: 50%; width: 22px; height: 22px;" class="asiakaslista-ympyra"></span>
  <span title="Sopimus purettu" style="color: white; font-size: 13px; font-weight: bold;" class="asiakaslista-ympyra-kirjain">X</span>
</ng-container>

<ng-container *ngIf="tila === 't'">
  <span title="Sopimus tauolla" class="ion-ios-radio-button-off asiakaslista-ympyra"></span>
  <span title="Sopimus tauolla" class="asiakaslista-ympyra-kirjain">T</span>
</ng-container>

<ng-container *ngIf="tila === 'pl'">
  <span title="Pöytälaatikko" class="ion-ios-radio-button-off asiakaslista-ympyra"></span>
  <span title="Pöytälaatikko" class="asiakaslista-ympyra-kirjain">P</span>
</ng-container>

<ng-container *ngIf="tila === 'a'">
  <span title="Sopimus allekirjoittamatta" class="ion-ios-radio-button-off asiakaslista-ympyra"></span>
  <i title="Sopimus allekirjoittamatta" class="fa fa-pencil asiakaslista-ympyra-ikoni" aria-hidden="true"></i>
</ng-container>