import { AfterViewInit, Component, ErrorHandler, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatTableDataSource } from '@angular/material/table'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { DateService } from 'app/_shared-core/service/date.service'
import { Observable, Subject, combineLatest, startWith, switchMap, takeUntil } from 'rxjs'
import { OmaveroYhteisomyyntilaskuLahetetty } from '../../_jaettu-sans-lemonaid-angular/model/omavero'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { Platform } from '@angular/cdk/platform'
import { LemonKuukausiDateAdapter } from 'app/_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatSort } from '@angular/material/sort'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

interface YhteisoMyyntiIlmoituksetForm {
  status: FormControl<string>
  start: FormControl<Date>
  end: FormControl<Date>
}

@Component({
  templateUrl: './yhteisomyynti-ilmoitukset.component.html',
  styleUrls: ['./yhteisomyynti-ilmoitukset.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class YhteisomyyntiIlmoituksetComponent implements OnInit, OnDestroy, AfterViewInit {

  asiakas: Asiakas = null
  naytettavatKolumnit: string[] = ['jakso', 'maaKoodi', 'alvTunnus', 'tavaramyynnit', 'palvelumyynnit', 'kolmikantamyynnit', 'lahetetty', 'status']

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  dataSource = new MatTableDataSource<OmaveroYhteisomyyntilaskuLahetetty>([])
  ilmoituksetObservable: Observable<OmaveroYhteisomyyntilaskuLahetetty[]>
  hakuform: FormGroup<YhteisoMyyntiIlmoituksetForm>
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  lahetyksenTila: { status: string }[] = [
    { status: 'Onnistuneet' },
    { status: 'Virheelliset' },
    { status: 'Kaikki statukset' }
  ]

  constructor(
    private _asiakasService: AsiakasService,
    public _dateService: DateService,
    private _firebaseLemonator: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _debugService: DebugService
  ) {
  }

  ngOnInit(): void {

    this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((onDevaaja: boolean) => {
      if (onDevaaja) {
        this.naytettavatKolumnit = [...this.naytettavatKolumnit, 'debug']
      }
    })

    const today = new Date()
    this.hakuform = new FormGroup<YhteisoMyyntiIlmoituksetForm>({
      status: new FormControl<string>(''),
      start: new FormControl<Date>((this._dateService.lisaaKuukausia(today, -1)), Validators.required),
      end: new FormControl<Date>((this._dateService.lisaaKuukausia(today, -1)), Validators.required),
    })

    const start = this.hakuform.get('start')
    const end = this.hakuform.get('end')
    const status = this.hakuform.get('status')

    const statukset = {
      'Onnistuneet': ['success'],
      'Virheelliset': ['error'],
      'Kaikki statukset': ['success', 'error']
    }

    combineLatest([
      start.valueChanges.pipe(startWith(start.value)),
      end.valueChanges.pipe(startWith(end.value)),
      status.valueChanges.pipe(startWith(status.value)),
      this._asiakasService.nykyinenAsiakasAvainObservable
    ]).pipe(
      switchMap(([startValue, endValue, statusValue, asiakas]) => {
        if (!startValue || !endValue || !statusValue || !asiakas) {
          return []
        }
        const listausUri = this._kirjanpitoUriService.annaOmaveroYhteisomyyntilaskuLahetettyCollectionUri(asiakas.avain)
        const startNumberMonth = this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(startValue))
        const endNumberMonth = this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(endValue))

        return this._firebaseLemonator.firestoreCollection<OmaveroYhteisomyyntilaskuLahetetty>(listausUri)
          .orderBy('lahetetty', 'desc')
          .where('status', 'in', statukset[statusValue])
          .where('jakso', '>=', startNumberMonth)
          .where('jakso', '<=', endNumberMonth)
          .listen()
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(ilmoitukset => {
      this.dataSource.data = ilmoitukset
      this._ladataanService.lopetaLataaminen()
    })
    status.setValue(this.lahetyksenTila[0].status)

  }

  ngAfterViewInit() {
    this.dataSource.sort = this._sort
  }

  chosenMonthHandlerStart(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.hakuform.get('start').setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerEnd(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.hakuform.get('end').setValue(normalizedMonth)
    datepicker.close()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  getDebugLink(asiakasAvain: string, lahetysAvain: string): string {
    return this._debugService.createFirestoreLink(this._kirjanpitoUriService.annaOmaveroYhteisomyyntilaskuLahetettyDebugDataUri(asiakasAvain, lahetysAvain))
  }

}
