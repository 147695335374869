import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input } from '@angular/core'

import { AsiakkaalleLemonatoristaLahetettyLasku } from '../_jaettu-lemonator/model/asiakas'
import { LocalMonth } from '../_shared-core/model/common'
import { Lasku, Laskuasetukset } from '../_jaettu/model/lasku'

import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

import { DateService } from '../_shared-core/service/date.service'
import { LaskuPdfEsikatselutiedot } from 'app/_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'

import { map, switchMap } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, of } from 'rxjs'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { AlvLaskunLahetysService } from 'app/_jaettu-lemonator/service/alv-laskun-lahetys.service'

interface AsiakkaanMaksettuLaskuJaEsikatselutiedot {
  laskunLahetystiedot: AsiakkaalleLemonatoristaLahetettyLasku
  esikatselutiedot: LaskuPdfEsikatselutiedot
  laskunLahetyspaivamaara: string
}

@Component({
  selector: '[app-kirjanpito-lahetys-alv]',
  templateUrl: './kirjanpito-lahetys-alv.component.html',
  styleUrls: ['./kirjanpito-lahetys.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoLahetysAlvComponent implements OnInit, OnDestroy {

  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  // @Input() latestAlvIlmoitusObservable: Observable<AlvIlmoitus>
  // @Input() naytaAlvIlmoitusValilehtiObservable: Observable<boolean>
  // @Input() erapaivaObservable: Observable<LocalDate>

  // private _maara: UntypedFormControl = new UntypedFormControl(0, [Validators.required])
  // alvLaskuForm: UntypedFormGroup = new UntypedFormGroup({
  //   'maara': this._maara
  // })

  // alvLaskunVoiLahettaaObservable: Observable<boolean>
  // naytaKomponenttiObservable: Observable<boolean>
  // alvLaskunViitenumeroObservable: Observable<string>
  // alvLaskunSahkoinenLaskutusosoiteObservable: Observable<LaskunSahkoinenOsoite>
  // asiakkaanOsoitetiedotPuuttuvatObservable: Observable<boolean>
  // laskunEsikatselutiedotObservable: Observable<LaskuPdfEsikatselutiedot>
  // alvIlmoituksenSummaObservable: Observable<number | null>
  aikaisemminLahetetytLaskutObservable: Observable<AsiakkaanMaksettuLaskuJaEsikatselutiedot[]>

  // private _alvLaskunMaara: BehaviorSubject<number> = new BehaviorSubject(0)

  private ngUnsubscribe = new Subject<void>()

  constructor(
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _firebaseLemonaid: FirebaseLemonaid,
  ) { }

  ngOnInit() {

    // this.naytaKomponenttiObservable = combineLatest([this.naytaAlvIlmoitusValilehtiObservable, this._asiakasService.nykyisenAsiakkaanMaksutavatObservable]).pipe(
    //   map(([naytaAlvValilehti, maksutavat]) => {
    //     if (!naytaAlvValilehti) {
    //       return false
    //     }
    //     if (maksutavat) {
    //       for (const maksutapa of maksutavat) {
    //         if (maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE) {
    //           return true
    //         }
    //       }
    //     }
    //     return false
    //   })
    // )

    const asiakkaanMaksetutLaskutObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.valittuKuukausiObservable]).pipe(
      switchMap(([asiakas, kuukausi]) => {
        if (asiakas && kuukausi) {
          const maksutietojenPath = 'customers/' + asiakas.avain + '/customer-sent-vat-invoice' // + asiakas.avain + '___alv___' + kuukausi.year + '___' + kuukausi.month
          // console.log('Luetaan;', maksutietojenPath)
          const tunniste = '___alv___' + kuukausi.year + '___' + kuukausi.month
          // console.log('tunniste;', tunniste)
          return this._firebaseLemonaid.firestoreCollection<AsiakkaalleLemonatoristaLahetettyLasku>(maksutietojenPath)
            .where('tunniste', '==', tunniste)
            .listen()
        }
        return observableOf<AsiakkaalleLemonatoristaLahetettyLasku[]>([])
      })
    )

    const laskuAsetuksetObservable: Observable<Laskuasetukset> = this._firebaseLemonaid.firestoreDoc<Laskuasetukset>('/laskut/' + AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId).listen()
    this.aikaisemminLahetetytLaskutObservable = combineLatest([asiakkaanMaksetutLaskutObservable, laskuAsetuksetObservable]).pipe(
      switchMap(([aiemminMaksetut, laskuasetukset]) => {
        if (aiemminMaksetut.length < 1) {
          return of<AsiakkaanMaksettuLaskuJaEsikatselutiedot[]>([])
        }
        // console.log('Got', aiemminMaksetut.length)
        const items = aiemminMaksetut.map(aiemminMaksettu => {
          const maksutietojenPath = 'laskut/' + AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId + '/laskut/' + aiemminMaksettu.laskuAvain
          // console.log('Luetaan;', maksutietojenPath)
          return this._firebaseLemonaid.firestoreDoc<Lasku>(maksutietojenPath).listen().pipe(
            map(lasku => {
              // console.log('Map lasku')
              const date = this._dateService.timestampToDate(aiemminMaksettu.luotu)
              const lahetettyPvm = this._dateService.muotoilePaivaJaAikaDate(date, 'fi')
              const result: AsiakkaanMaksettuLaskuJaEsikatselutiedot = {
                esikatselutiedot: {
                  asetukset: laskuasetukset,
                  juurilasku: lasku,
                  kasiteltava: lasku
                },
                laskunLahetystiedot: aiemminMaksettu,
                laskunLahetyspaivamaara: lahetettyPvm
              }
              return result
            })
          )
        })
        return combineLatest(items).pipe(
          map(aikaisemmat => {
            // console.log('aikaisemmat', aikaisemmat.length)
            return aikaisemmat.sort((a, b) => {
              return b.laskunLahetystiedot.luotu.toMillis() - a.laskunLahetystiedot.luotu.toMillis()
            })
          })
        )
      })
    )

    // this.laskunLahetyspaivamaara = asiakkaanMaksettuLaskuObservable.pipe(
    //   map(lahetetty => {
    //     if (lahetetty) {
    //       const date = this._dateService.timestampToDate(lahetetty.luotu)
    //       return this._dateService.muotoilePaivaJaAikaDate(date, 'fi')
    //     }
    //     return ''
    //   })
    // )

    // this.maksettuLaskuObservable = asiakkaanMaksettuLaskuObservable.pipe(
    //   switchMap(maksettuLasku => {
    //     if (maksettuLasku) {
    //       const maksutietojenPath = 'laskut/' + AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId + '/laskut/' + maksettuLasku.laskuAvain
    //       // console.log('Luetaan;', maksutietojenPath)
    //       return this._lemonaidFirestore.doc<Lasku>(maksutietojenPath).valueChanges()
    //     }
    //     return observableOf<Lasku>(null)
    //   }),
    //   lemonShare()
    // )

    // const yhteystietoObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
    //   switchMap(asiakas => {
    //     if (asiakas) {
    //       const maksutietojenPath = 'customers/' + asiakas.avain + '/customer-vero-maksuyhteystiedot/' + asiakas.avain
    //       return this._firebaseLemonaid.firestoreDoc<VerojenMaksuyhteystiedot>(maksutietojenPath).listen()
    //     }
    //     return observableOf<VerojenMaksuyhteystiedot>(null)
    //   }),
    //   lemonShare()
    // )

    // this.alvLaskunViitenumeroObservable = yhteystietoObservable.pipe(
    //   map(yhteystiedot => {
    //     if (
    //       yhteystiedot &&
    //       yhteystiedot.omaAloitteisetViite &&
    //       yhteystiedot.omaAloitteisetViite.trim() &&
    //       this._viitenumeroService.onkoViitenumeroValidi(yhteystiedot.omaAloitteisetViite)
    //     ) {
    //       return yhteystiedot.omaAloitteisetViite.trim()
    //     }
    //     return ''
    //   })
    // )

    // this.alvLaskunSahkoinenLaskutusosoiteObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
    //   map(asiakas => {
    //     if (
    //       asiakas &&
    //       asiakas.sahkoinenLaskutusosoite &&
    //       asiakas.sahkoinenLaskutusosoite.sahkoinenOsoite &&
    //       asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja
    //     ) {
    //       return asiakas.sahkoinenLaskutusosoite
    //     }
    //     return null
    //   })
    // )

    // this.alvIlmoituksenSummaObservable = this.latestAlvIlmoitusObservable.pipe(
    //   map(ilmoitus => {
    //     if (ilmoitus) {
    //       return this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(ilmoitus)
    //     }
    //     return null
    //   })
    // )
    // this.asiakkaanOsoitetiedotPuuttuvatObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
    //   map(asiakas => {
    //     return !asiakas?.katuosoite ||
    //       !asiakas?.postitmp ||
    //       !asiakas?.postinro
    //   })
    // )

    // this.alvLaskunVoiLahettaaObservable = combineLatest([this.alvLaskunSahkoinenLaskutusosoiteObservable, yhteystietoObservable, this.alvIlmoituksenSummaObservable, this.asiakkaanOsoitetiedotPuuttuvatObservable]).pipe(
    //   map(([sahkoinenLaskutusosoite, yhteystiedot, alvIlmoituksenSumma, asiakkaanOsoitetiedotPuuttuvat]) => {
    //     if (
    //       alvIlmoituksenSumma > 0 &&
    //       sahkoinenLaskutusosoite &&
    //       sahkoinenLaskutusosoite.sahkoinenOsoite &&
    //       sahkoinenLaskutusosoite.sahkoinenValittaja &&
    //       yhteystiedot &&
    //       yhteystiedot.omaAloitteisetViite &&
    //       yhteystiedot.omaAloitteisetViite.trim() &&
    //       this._viitenumeroService.onkoViitenumeroValidi(yhteystiedot.omaAloitteisetViite) &&
    //       !asiakkaanOsoitetiedotPuuttuvat
    //     ) {
    //       return true
    //     }
    //     return false
    //   })
    // )

    // const alvLaskunLahetysService = this._annaService()
    // const laskuPreviewObservable = combineLatest([
    //   this._asiakasService.nykyinenAsiakasObservable,
    //   this.alvLaskunViitenumeroObservable,
    //   this._alvLaskunMaara,
    //   this.erapaivaObservable
    // ]).pipe(
    //   switchMap(([asiakas, viitenumero, maara, erapaiva]) => {
    //     // console.log('Preview lasku with', maara)
    //     if (!asiakas) {
    //       return null
    //     }
    //     const erapvm = this._timestampService.localDateToTimestamp(erapaiva)
    //     return alvLaskunLahetysService.annaTallennettavaLasku(asiakas, erapvm, maara, viitenumero)
    //   })
    // )

    // this.laskunEsikatselutiedotObservable = combineLatest([
    //   laskuPreviewObservable,
    //   laskuAsetuksetObservable
    // ]).pipe(
    //   map(([laskuPreview, asetukset]) => {
    //     const tiedot: LaskuPdfEsikatselutiedot = {
    //       asetukset: asetukset,
    //       juurilasku: laskuPreview,
    //       kasiteltava: laskuPreview
    //     }
    //     return tiedot
    //   })
    // )

    // this._maara.valueChanges.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(test => {
    //   this._alvLaskunMaara.next(test)
    // })

    // this.latestAlvIlmoitusObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(alvIlmoitus => {
    //   if (alvIlmoitus) {
    //     const summa = this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(alvIlmoitus)
    //     this._maara.setValue(summa)
    //   } else {
    //     this._maara.setValue(0)
    //   }
    // })

  }

  // private _annaService(): AlvLaskunLahetysService {
  //   const firestoreProvider: FirestoreProvider = {
  //     annaDeleteArvo: () => { return this._firebaseLemonaid.firestoreDeleteMarker() },
  //     annaUusiAvain: () => { return this._firebaseLemonaid.firestoreCreateId() },
  //     // THIS IS REALLY COUNTERINTUITIVE, BUT THE DOC TYPE CONTAINS NO DATA
  //     // AND THESE DAYS OUR FRONT END FIREBASE INTEGRATION LIBRARY EXPECTS THE URI,
  //     // NOT THE DOC TO BE PRESENT!
  //     annaDoc: (uri: string) => { return uri },
  //     annaUusiBatch: () => { return this._firebaseLemonaid.firestoreBatch() }
  //   }
  //   return new AlvLaskunLahetysService(
  //     this._translationService,
  //     this._dateService,
  //     firestoreProvider,
  //     this._timestampService,
  //     this._currencyService,
  //     this._viitenumeroService,
  //     this._laskuKopioija,
  //     this._stringService,
  //     this._laskuSharedService,
  //     this._laskuIndeksoija,
  //     this._laskuUriService,
  //     this._reskontraService,
  //     this._alvLemonatorService
  //   )
  // }

  // private _invoiceSendingPressed = false

  // async lahetaAlvLasku() {

  //   try {

  //     if (this._invoiceSendingPressed) {
  //       return
  //     }
  //     this._invoiceSendingPressed = true

  //     const maksettavaMaara = this._alvLaskunMaara.value
  //     if (maksettavaMaara < 0.01) {
  //       this._invoiceSendingPressed = false
  //       return
  //     }

  //     // Check that we're still allowed to send
  //     const allowed = await firstValueFrom(this.alvLaskunVoiLahettaaObservable)
  //     if (!allowed) {
  //       this._invoiceSendingPressed = false
  //       return
  //     }

  //     const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
  //     const viitenumero = await firstValueFrom(this.alvLaskunViitenumeroObservable)
  //     const kuukausi = await firstValueFrom(this.valittuKuukausiObservable)
  //     const erapaiva = await firstValueFrom(this.erapaivaObservable)

  //     if (!viitenumero || !kuukausi || !erapaiva) {
  //       this._invoiceSendingPressed = false
  //       return
  //     }

  //     this._ladataanService.aloitaLataaminen()

  //     const erapvm = this._timestampService.localDateToTimestamp(erapaiva)
  //     const alvLaskunLahetysService: AlvLaskunLahetysService = this._annaService()
  //     await alvLaskunLahetysService.lahetaAlvLasku(asiakas, erapvm, kuukausi, maksettavaMaara, viitenumero)
  //     this._snackbar.open('ALV-lasku lähettiin onnistuneesti.', 'OK', { duration: 2500, verticalPosition: 'top' })

  //   } catch (err) {
  //     this._errorHandler.handleError(err)
  //   } finally {
  //     this._ladataanService.lopetaLataaminen()
  //   }

  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
