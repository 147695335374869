
import { Pipe, PipeTransform } from '@angular/core'
import { NumberDate } from '../../_shared-core/model/common'
import { DateService } from '../../_shared-core/service/date.service'

@Pipe({
  name: 'numberDateToDate'
})
export class LemonatorNumberDateToDatePipe implements PipeTransform {

  constructor(
    private _dateService: DateService
  ) { }

  transform(date: NumberDate): Date {
    return this._dateService.numberToDate(date)
  }

}
