<mat-dialog-content>

  <lemon-dialog-header>
    <ng-container *ngIf="lisaa">Lisää kirjanpitotili</ng-container>
    <ng-container *ngIf="!lisaa">Muokkaa kirjanpitotiliä</ng-container>
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate>

    <div style="max-width: 350px; margin-bottom: 10px;">

      <!-- <pre>{{ numero?.errors | json }}</pre> -->

      <mat-form-field>
        <mat-label>Vanhempi</mat-label>
        <mat-select formControlName="vanhempi" required>
          <mat-option *ngFor="let tili of tilitObservable | async" [value]="tili.numero">{{tili.numero}} {{tili.nimi}}</mat-option>
        </mat-select>
        <mat-error *ngIf="vanhempi?.errors?.required && (vanhempi?.dirty || vanhempi?.touched)">
          Vanhempi puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Numero</mat-label>
        <input type="text" #numeroInput [name]="'aasd' + name" formControlName="numero" maxlength="5" minlength="5" decimalNumberField [numberOfDecimals]="0" matInput required class="skip-space-quick-toggle" />
        <mat-error *ngIf="numero?.errors?.vanhempipuuttuu && (numero?.dirty || numero?.touched)">
          Tilille ei löydy paikkaa hierarkiassa.
        </mat-error>
        <mat-error *ngIf="numero?.errors?.max || numero?.errors?.min && (numero?.dirty || numero?.touched)">
          Asiakaskohtaisen tilinumeron on oltava 5 merkkiä pitkä.
        </mat-error>
        <mat-error *ngIf="numero?.errors?.required && (numero?.dirty || numero?.touched)">
          Numero puuttuu
        </mat-error>
        <mat-error *ngIf="numero?.errors?.duplicate && (numero?.dirty || numero?.touched)">
          Tilinumero on jo käytössä.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nimi suomeksi</mat-label>
        <input type="text" #nimiFiInput [name]="'aasd' + name" formControlName="nimiFi" matInput required class="skip-space-quick-toggle" />
        <mat-error *ngIf="nimiFi?.errors?.required && (nimiFi?.dirty || nimiFi?.touched)">
          Nimi puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nimi englanniksi</mat-label>
        <input type="text" #nimiEnInput [name]="'aasda' + name" formControlName="nimiEn" matInput required class="skip-space-quick-toggle" />
        <mat-error *ngIf="nimiEn?.errors?.required && (nimiEn?.dirty || nimiEn?.touched)">
          Nimi puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>ALV-tyyppi</mat-label>
        <mat-select formControlName="alvtyyppi">
          <mat-option *ngFor="let lokalisoituAlvtyyppi of lokalisoidutAlvtyypit" [value]="lokalisoituAlvtyyppi.tyyppi">{{lokalisoituAlvtyyppi.nimi}}</mat-option>
        </mat-select>
        <mat-error *ngIf="alvtyyppi?.errors?.required && (alvtyyppi?.dirty || alvtyyppi?.touched)">
          Valinta puuttuu
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Oletus ALV-käsittely</mat-label>
        <mat-select formControlName="alv" [compareWith]="vertaaAlvMaarityksia">
          <mat-option *ngFor="let alv of alvt" [value]="alv">{{alv.nimi}}</mat-option>
        </mat-select>
        <mat-error *ngIf="alv?.errors?.required && (alv?.dirty || alv?.touched)">
          Oletus puuttuu
        </mat-error>
      </mat-form-field>
      <mat-checkbox formControlName="aktiivinen">Aktiivinen</mat-checkbox>
      <div style="margin-top:5px;">
        <mat-checkbox formControlName="reskontraActive">Reskontra päällä</mat-checkbox>
      </div>

    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="secondarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>