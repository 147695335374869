<mat-dialog-content>

  <lemon-dialog-header>
    Veroilmoituksen osakkaiden tiedot
  </lemon-dialog-header>

  <div app-kirjanpito-veroilmoitus-osakkaat #osakkaatComp [veroilmoitusObservable]="veroilmoitusObservable" [showSnackbar]="true"></div>
</mat-dialog-content>

<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>