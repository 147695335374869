/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild, HostBinding, HostListener, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { DEFAULT_IMAGE_ZOOM } from './image-viewer.component'
export interface Coord {
  x: number
  y: number
}

@Component({
  selector: '[app-image-zoom]',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageZoomComponent implements OnInit, OnChanges, OnDestroy {

  private static readonly validZoomModes: string[] = ['hover', 'toggle', 'click', 'hover-freeze']

  @HostBinding('style.cursor') cursorStyle = 'zoom-in'

  @ViewChild('zoomContainer', { static: true }) zoomContainer !: ElementRef
  @ViewChild('imageThumbnail', { static: true }) imageThumbnail !: ElementRef
  @ViewChild('fullSizeImage', { static: true }) fullSizeImage !: ElementRef

  // @Output() zoomScroll = new EventEmitter<number>()
  // @Output() zoomPosition = new EventEmitter<Coord>()
  @Output() kuvaaLadataan: EventEmitter<boolean> = new EventEmitter()

  public display: 'block' | 'none' = 'none'
  public fullImageTop: number
  public fullImageLeft: number
  public magnifiedWidth: number
  public magnifiedHeight: number
  public lensTop: number
  public lensLeft: number
  public enableLens = false
  public lensBorderRadius = 0

  public thumbImage: string
  public fullImage: string
  public thumbWidth: number
  public thumbHeight: number
  public fullWidth: number
  public fullHeight: number
  public lensWidth = 100
  public lensHeight = 100

  private zoomMode = 'click'
  private magnification = DEFAULT_IMAGE_ZOOM
  private enableScrollZoom = true
  private scrollStepSize = 0.05
  private circularLens = false

  private baseRatio: number
  private minZoomRatio
  private maxZoomRatio = 2
  private xRatio: number
  private yRatio: number
  private offsetLeft: number
  private offsetTop: number
  public zoomingEnabled = false
  private zoomFrozen = false
  private isReady = false
  public thumbImageLoaded = false
  private fullImageLoaded = false

  private latestMouseLeft: number
  private latestMouseTop: number

  private eventListeners: (() => void)[] = []

  constructor(private renderer: Renderer2, private _changeDetectorRef: ChangeDetectorRef) { }

  public heightVh = '75vh'

  @Input()
  public set thumbImageHeight(height: number) {
    this.heightVh = height + 'vh'

    const thumbImageLoadedBefore = this.thumbImageLoaded
    const fullImageLoadedBefore = this.fullImageLoaded
    const readyBefore = this.isReady
    this.setThumbImage = this.thumbImage
    this.thumbImageLoaded = thumbImageLoadedBefore
    this.fullImageLoaded = fullImageLoadedBefore
    this.isReady = readyBefore

    setTimeout(() => {
      this.calculateRatioAndOffset()
      this._changeDetectorRef.markForCheck()
    }, 250)
  }

  @Input('thumbImage')
  public set setThumbImage(thumbImage: string) {
    this.rotationDegrees = '0'
    this.thumbImageLoaded = false
    this.kuvaaLadataan.next(false)
    this.isReady = false
    this.thumbImage = thumbImage
    this.setFullImage = thumbImage
    this.zoomOff()
  }

  @Input('fullImage')
  public set setFullImage(fullImage: string) {
    this.fullImageLoaded = false
    this.isReady = false
    this.fullImage = fullImage
  }

  @Input('zoomMode')
  public set setZoomMode(zoomMode: string) {
    if (ImageZoomComponent.validZoomModes.some(m => m === zoomMode)) {
      this.zoomMode = zoomMode
    }
  }

  @Input('magnification')
  public set setMagnification(magnification: number) {
    this.magnification = Number(magnification) || this.magnification
    // this.zoomScroll.emit(this.magnification)
  }

  @Input('minZoomRatio')
  public set setMinZoomRatio(minZoomRatio: number) {
    const ratio = Number(minZoomRatio) || this.minZoomRatio || this.baseRatio || 0
    this.minZoomRatio = Math.max(ratio, this.baseRatio || 0)
  }

  @Input('maxZoomRatio')
  public set setMaxZoomRatio(maxZoomRatio: number) {
    this.maxZoomRatio = Number(maxZoomRatio) || this.maxZoomRatio
  }

  @Input('scrollStepSize')
  public set setScrollStepSize(stepSize: number) {
    this.scrollStepSize = Number(stepSize) || this.scrollStepSize
  }

  @Input('enableLens')
  public set setEnableLens(enable: boolean) {
    this.enableLens = Boolean(enable)
  }

  @Input('lensWidth')
  public set setLensWidth(width: number) {
    this.lensWidth = Number(width) || this.lensWidth
  }

  @Input('lensHeight')
  public set setLensHeight(height: number) {
    this.lensHeight = Number(height) || this.lensHeight
  }

  @Input('circularLens')
  public set setCircularLens(enable: boolean) {
    this.circularLens = Boolean(enable)
  }

  @Input('enableScrollZoom')
  public set setEnableScrollZoom(enable: boolean) {
    this.enableScrollZoom = Boolean(enable)
  }

  rotationDegrees: '0' | '90' | '180' | '270' = '0'
  @Input('rotateDegrees')
  public set setRotateDegrees(degrees: '0' | '90' | '180' | '270') {
    this.rotationDegrees = degrees
  }

  ngOnInit(): void {
    this.setUpEventListeners()
  }

  ngOnChanges() {
    if (this.enableLens) {
      if (this.circularLens) {
        this.lensBorderRadius = this.lensWidth / 2
      } else {
        this.lensBorderRadius = 0
      }
    }
    this.calculateRatioAndOffset()
    this.calculateImageAndLensPosition()
  }

  ngOnDestroy(): void {
    this.eventListeners.forEach((destroyFn) => destroyFn())
  }

  /**
   * Template helper methods
   */
  onThumbImageLoaded() {
    this.thumbImageLoaded = true
    this.kuvaaLadataan.next(true)
    this.checkImagesLoaded()
  }

  onFullImageLoaded() {
    this.fullImageLoaded = true
    this.checkImagesLoaded()
  }

  private setUpEventListeners() {
    // if (this.zoomMode === 'hover') {
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mouseenter', (event) => this.hoverMouseEnter(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mouseleave', () => this.hoverMouseLeave())
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mousemove', (event) => this.hoverMouseMove(event))
    //   )
    // } else if (this.zoomMode === 'toggle') {
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'click', (event) => this.toggleClick(event))
    //   )
    // } else if (this.zoomMode === 'click') {
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'click', (event) => this.clickStarter(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mouseleave', () => this.clickMouseLeave())
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mousemove', (event) => this.clickMouseMove(event))
    //   )
    // } else if (this.zoomMode === 'hover-freeze') {
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mouseenter', (event) => this.hoverFreezeMouseEnter(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mouseleave', () => this.hoverFreezeMouseLeave())
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mousemove', (event) => this.hoverFreezeMouseMove(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'click', (event) => this.hoverFreezeClick(event))
    //   )
    // }
    // if (this.enableScrollZoom) {
    //   // Chrome: 'mousewheel', Firefox: 'DOMMouseScroll', IE: 'onmousewheel'
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'mousewheel', (event) => this.onMouseWheel(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'DOMMouseScroll', (event) => this.onMouseWheel(event))
    //   )
    //   this.eventListeners.push(
    //     this.renderer.listen(this.zoomContainer.nativeElement, 'onmousewheel', (event) => this.onMouseWheel(event))
    //   )
    // }
    if (this.enableLens && this.circularLens) {
      this.lensBorderRadius = this.lensWidth / 2
    }
  }

  private checkImagesLoaded() {
    this.calculateRatioAndOffset()
    if (this.thumbImageLoaded && this.fullImageLoaded) {
      this.calculateImageAndLensPosition()
      this.isReady = true
    }
  }

  /**
   * Zoom position setters
   */
  private setZoomPosition(left: number, top: number) {
    this.latestMouseLeft = Number(left) || this.latestMouseLeft
    this.latestMouseTop = Number(top) || this.latestMouseTop

    // const c: Coord = {
    //   x: this.latestMouseLeft,
    //   y: this.latestMouseTop
    // }
    // this.zoomPosition.emit(c)
  }


  /**
   * Mouse wheel event
   */
  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    // Don't eat events if zooming isn't active
    if (!this.zoomingEnabled || this.zoomFrozen) {
      return
    }

    const direction = Math.max(Math.min(-event.deltaY, 1), -1)
    if (direction > 0) {
      // up
      this.setMagnification = Math.min(this.magnification + this.scrollStepSize, this.maxZoomRatio)
      // console.log('MouseWheel up', event.deltaY, this.magnification)
    } else {
      // down
      this.setMagnification = Math.max(this.magnification - this.scrollStepSize, this.minZoomRatio)
      // console.log('MouseWheel down', event.deltaY, this.magnification)
    }
    this.calculateRatio()
    this.calculateZoomPosition(event.offsetX, event.offsetY)

    // Prevent scrolling on page.
    event.returnValue = false // IE
    if (event.preventDefault) {
      event.preventDefault() // Chrome & FF
    }
  }

  /**
   * Hover mode
   */
  // private hoverMouseEnter(event: MouseEvent) {
  //   this.zoomOn(event)
  // }

  // private hoverMouseLeave() {
  //   this.zoomOff()
  // }

  // private hoverMouseMove(event: MouseEvent) {
  //   this.calculateZoomPosition(event)
  // }

  /**
   * Toggle mode
   */
  @HostListener('click', ['$event'])
  toggleClick(event: MouseEvent) {
    if (this.zoomingEnabled) {
      this.zoomOff()
    } else {
      // console.log('click x', event.offsetX, 'y', event.offsetY)
      this.zoomOn(event)
    }
  }

  /**
   * Click mode
   */
  clickStarter(event: MouseEvent) {
    if (this.zoomingEnabled === false) {
      this.zoomOn(event)
    }
  }

  // @HostListener('mouseleave')
  // private clickMouseLeave() {
  //   this.zoomOff()
  // }

  @HostListener('mousemove', ['$event'])
  clickMouseMove(event: MouseEvent) {
    // console.log('mousemove', event.offsetX, event.offsetY)
    if (this.zoomingEnabled) {
      this.calculateZoomPosition(event.offsetX, event.offsetY)
    }
  }

  /**
   * Hover freeze mode
   */
  // private hoverFreezeMouseEnter(event: MouseEvent) {
  //   if (this.zoomingEnabled && !this.zoomFrozen) {
  //     this.zoomOn(event)
  //   }
  // }

  // private hoverFreezeMouseLeave() {
  //   if (this.zoomingEnabled && !this.zoomFrozen) {
  //     this.zoomOff()
  //   }
  // }

  // private hoverFreezeMouseMove(event: MouseEvent) {
  //   if (this.zoomingEnabled && !this.zoomFrozen) {
  //     this.calculateZoomPosition(event)
  //   }
  // }

  // private hoverFreezeClick(event: MouseEvent) {
  //   if (this.zoomingEnabled && this.zoomFrozen) {
  //     this.zoomFrozen = false
  //     this.zoomOff()
  //   } else if (this.zoomingEnabled) {
  //     this.zoomFrozen = true
  //   } else {
  //     this.zoomOn(event)
  //   }
  // }

  /**
   * Private helper methods
   */
  private zoomOn(event: MouseEvent) {
    // console.log('Trying to zoom: ', this.isReady)
    if (this.isReady) {

      this.cursorStyle = 'zoom-out'
      this.zoomingEnabled = true
      this.calculateRatioAndOffset()
      this.display = 'block'

      if (this.rotationDegrees === '0') {
        this.calculateZoomPosition(event.offsetX, event.offsetY)
      } else if (this.rotationDegrees === '180') {
        this.calculateZoomPosition(this.thumbWidth - event.offsetX, this.thumbHeight - event.offsetY)
      } else if (this.rotationDegrees === '270') {
        // console.log('Set with position x', this.thumbHeight - event.offsetY, 'y', event.offsetX)
        this.calculateZoomPosition(event.offsetY, this.thumbWidth - event.offsetX)
      } else if (this.rotationDegrees === '90') {
        this.calculateZoomPosition(this.thumbWidth - event.offsetY, event.offsetX)
      }

    }
  }

  private zoomOff() {
    this.zoomingEnabled = false
    this.display = 'none'
    this.cursorStyle = 'zoom-in'
  }

  private calculateZoomPosition(cursorX: number, cursorY: number) {

    const newLeft = Math.max(Math.min(cursorX, this.thumbWidth), 0)
    const newTop = Math.max(Math.min(cursorY, this.thumbHeight), 0)

    this.setZoomPosition(newLeft, newTop)

    this.calculateImageAndLensPosition()
  }

  private calculateImageAndLensPosition() {
    let lensLeftMod = 0
    let lensTopMod = 0

    if (this.enableLens) {
      lensLeftMod = this.lensLeft = this.latestMouseLeft - this.lensWidth / 2
      lensTopMod = this.lensTop = this.latestMouseTop - this.lensHeight / 2
    }

    let mouseX = 0
    let mouseY = 0

    if (this.rotationDegrees === '0') {
      // console.log('0')
      mouseX = this.latestMouseLeft
      mouseY = this.latestMouseTop
    } else if (this.rotationDegrees === '180') {
      // console.log('180')
      mouseX = this.thumbWidth - this.latestMouseLeft
      mouseY = this.thumbHeight - this.latestMouseTop
    } else if (this.rotationDegrees === '270') {
      // console.log('270')
      mouseX = this.thumbHeight - this.latestMouseTop
      mouseY = this.latestMouseLeft
    } else if (this.rotationDegrees === '90') {
      // console.log('90')
      mouseX = this.latestMouseTop
      mouseY = this.thumbWidth - this.latestMouseLeft
    }

    // Apply a bit of easing
    const xCompleteness = mouseX / this.thumbWidth
    const yCompleteness = mouseY / this.thumbHeight

    const xEasing = this.easing(xCompleteness)
    const yEasing = this.easing(yCompleteness)

    const mousePositionXToUse = xEasing * this.thumbWidth
    const mousePositionYToUse = yEasing * this.thumbHeight

    // console.log('X', mouseX, xCompleteness, xEasing, mousePositionXToUse)
    // console.log('Y', mouseY, yCompleteness, yEasing, mousePositionYToUse)

    this.fullImageLeft = (mousePositionXToUse * -this.xRatio) - lensLeftMod
    this.fullImageTop = (mousePositionYToUse * -this.yRatio) - lensTopMod

  }

  // Easing loaned from here
  // This one is easeInOutQuint (acceleration until halfway, then deceleration)
  // https://gist.github.com/gre/1650294
  easing(t: number): number {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    // return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
  }

  private calculateRatioAndOffset() {
    this.thumbWidth = this.imageThumbnail.nativeElement.width
    this.thumbHeight = this.imageThumbnail.nativeElement.height

    // If lens is disabled, set lens size to equal thumb size and position it on top of the thumb
    if (!this.enableLens) {
      this.lensWidth = this.thumbWidth
      this.lensHeight = this.thumbHeight
      this.lensLeft = 0
      this.lensTop = 0
    }

    // getBoundingClientRect() ? https://stackoverflow.com/a/44008873
    // this.offsetTop = this.imageThumbnail.nativeElement.getBoundingClientRect().top
    // this.offsetLeft = this.imageThumbnail.nativeElement.getBoundingClientRect().left

    if (this.fullImage === undefined) {
      this.fullImage = this.thumbImage
    }

    if (this.fullImageLoaded) {
      this.fullWidth = this.fullSizeImage.nativeElement.naturalWidth
      this.fullHeight = this.fullSizeImage.nativeElement.naturalHeight

      this.baseRatio = Math.max(
        (this.thumbWidth / this.fullWidth),
        (this.thumbHeight / this.fullHeight))

      // console.log(this.fullWidth, this.fullHeight)

      // Don't allow zooming to smaller than thumbnail size
      this.minZoomRatio = Math.max(this.minZoomRatio || 0, this.baseRatio || 0)

      this.calculateRatio()
    }
  }

  private calculateRatio() {
    this.magnifiedWidth = (this.fullWidth * this.magnification)
    this.magnifiedHeight = (this.fullHeight * this.magnification)

    this.xRatio = (this.magnifiedWidth - this.thumbWidth) / this.thumbWidth
    this.yRatio = (this.magnifiedHeight - this.thumbHeight) / this.thumbHeight
  }

  vasemmalle(event: MouseEvent) {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.rotationDegrees === '90') {
      this.rotationDegrees = '0'
    } else if (this.rotationDegrees === '0') {
      this.rotationDegrees = '270'
    } else if (this.rotationDegrees === '270') {
      this.rotationDegrees = '180'
    } else if (this.rotationDegrees === '180') {
      this.rotationDegrees = '90'
    }
  }

  oikealle(event: MouseEvent) {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.rotationDegrees === '90') {
      this.rotationDegrees = '180'
    } else if (this.rotationDegrees === '180') {
      this.rotationDegrees = '270'
    } else if (this.rotationDegrees === '270') {
      this.rotationDegrees = '0'
    } else if (this.rotationDegrees === '0') {
      this.rotationDegrees = '90'
    }
  }

}
