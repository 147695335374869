import { Yritysmuoto } from "../../_jaettu/model/kayttaja"
import { Timestamp, TuettuKieli } from "../../_shared-core/model/common"

export enum TemplateCategory {
  KIRJANPITO = 'kirj',
  VEROILMOITUS = 'veroilm',
  TILINPAATOS = 'tilinp',
  SOPIMUS_PAATTYY = 'sop-paat',
  TAIKALINKKI = 'taika'
}

export enum TemplateAlvType {
  ALV_1KK = 'alv-1kk',
  ALV_3KK12KK_EI_MAKSUKUUKAUSI = 'alv-ei-maksuk',
  ALV_3KK12KK_MAKSUKUUKAUSI = 'alv-maksuk',
  ALVITON = 'alviton'
}

export enum EmailTemplateVariable {
  COMPANY_NAME = 'company_name',
  VASTUUKIRJANPITAJAN_NIMI = 'vastuukirjanpitajan_nimi',
  ALV_SUMMA = 'alv_summa',
  LIITTEET = 'liitteet'
}

export interface EmailTemplateBase {
  avain: string // Combination of all possible moving parts in the template
  updatedAt: Timestamp
  updatedBy: string
  kieli: TuettuKieli
  yritysmuoto: Yritysmuoto
  usedVariables: EmailTemplateVariable[]
}

export interface EmailTemplateFinal extends EmailTemplateBase {
  text: string
}

export interface EmailTemplateDraft extends EmailTemplateBase {
  editableParts: { [name: string]: EmailTemplateEditablePart }
}

export interface EmailTemplateEditablePart {
  name: string
  availableVariables: EmailTemplateVariable[]
}

export interface EmailTemplateDraftHistory extends EmailTemplateDraft { }

export interface EmailTemplateFinalHistory extends EmailTemplateFinal { }


