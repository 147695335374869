import { Injectable, ErrorHandler, ChangeDetectorRef } from '@angular/core'

import { DataSource } from '@angular/cdk/table'
import { CollectionViewer } from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { CurrencyService } from '../_shared-core/service/currency.service'
import { Lasku, LaskunListaustyyppi, LaskunumeroTyyppi, LaskunListaustietorivi, LaskunTila, LaskuBase, LaskuSahkoinenLahetysStatusKoodi, EmailLahetysStatusKoodi } from '../_jaettu/model/lasku'
import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { DateService } from '../_shared-core/service/date.service'

import { LaskuService } from '../_angular/service/lasku/lasku.service'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'

import { LaskunLokalisoituTila } from '../laskut/laskut.firestore.datasource'

import { EMPTY, BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

export interface AsiakkaanLaskunListaustietorivi extends LaskunListaustietorivi {
  lemontreeAsiakas: Asiakas
}

export interface AsiakkaanLaskunListaustieto extends AsiakkaanLaskunListaustietorivi {
  korvaavat: AsiakkaanLaskunListaustietorivi[]
}

export interface LaskuJaAsiakas extends Lasku {
  lemontreeAsiakas: Asiakas
}

class TransformingWrappingDataSource extends DataSource<AsiakkaanLaskunListaustietorivi> {

  constructor(
    private _backingDataSource: DataSource<LaskuJaAsiakas>,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _laskuSharedService: LaskuSharedService,
    private _kaytettavaKuukausi: number,
    private _kaytettavaVuosi: number
  ) {
    super()
  }

  connect(collectionViewer: CollectionViewer): Observable<AsiakkaanLaskunListaustietorivi[]> {
    return this._backingDataSource.connect(collectionViewer).pipe(
      map((laskut: LaskuJaAsiakas[]) => {
        return this.filterAndMap(laskut)
      })
    )
  }


  disconnect(collectionViewer: CollectionViewer): void {
    this._backingDataSource.disconnect(collectionViewer)
  }

  private filterAndMap(laskut: LaskuJaAsiakas[]): AsiakkaanLaskunListaustietorivi[] {
    const tulos: AsiakkaanLaskunListaustietorivi[] = []

    for (const lasku of laskut) {
      if (lasku.tila === LaskunTila.mitatoity) {
        continue
      }

      let otaLasku = false
      if (lasku.pvml.month === this._kaytettavaKuukausi && lasku.pvml.year === this._kaytettavaVuosi) {
        otaLasku = true
      } else if (lasku.korvaus) {
        for (const korvaava of lasku.korvaus) {
          if (korvaava.nrotyyppi !== LaskunumeroTyyppi.MUISTUTUS && korvaava.pvml.month === this._kaytettavaKuukausi && korvaava.pvml.year === this._kaytettavaVuosi) {
            otaLasku = true
          }
        }
      }

      if (otaLasku) {
        const listaustiedot = this.muunnaLaskuListatiedoksi(lasku)
        tulos.push(listaustiedot)
        for (const korvaava of listaustiedot.korvaavat) {
          tulos.push(korvaava)
        }
      }

    }
    return tulos
  }

  private muunnaLaskuListatiedoksi(juurilasku: LaskuJaAsiakas): AsiakkaanLaskunListaustieto {

    const viimeisinTavallinen = this._laskuSharedService.annaViimeisinTavallinenLasku(juurilasku)
    const muistutuksetJaHyvitykset = this._laskuSharedService.annaLaskuryppaanMuistutuksetJaHyvitykset(juurilasku)

    const korvaavienLukumaara = muistutuksetJaHyvitykset ? muistutuksetJaHyvitykset.length : 0
    const laskunro = this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, viimeisinTavallinen)

    const avoinnaRypas = juurilasku.avoinnaRypas
    const summaRypas = juurilasku.summaRypas

    // [class.blue-text]   = "(row.tyyppi == 't' || row.tyyppi == 'v') && "
    // [class.yellow-text] = "(row.tyyppi == 't' || row.tyyppi == 'v') && (vertaaEkaPienempiKuinToka(row.avoinna, row.summa) && vertaaEkaPienempiKuinToka(0, row.avoinna) && row.tila == 'a')"
    // [class.green-text]  = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'm'"
    // [class.red-text]    = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'e'"
    // [class.purple-text] = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'ml'"

    let vari = ''
    if ((avoinnaRypas === summaRypas || summaRypas < avoinnaRypas) && juurilasku.tila === LaskunTila.avoin) {
      vari = 'blue'
    } else if (avoinnaRypas < summaRypas && 0 < avoinnaRypas && juurilasku.tila === LaskunTila.avoin) {
      vari = 'yellow'
    } else if (juurilasku.tila === LaskunTila.maksettu) {
      vari = 'green'
    } else if (juurilasku.tila === LaskunTila.eraantynyt) {
      vari = 'red'
    } else if (juurilasku.tila === LaskunTila.maksettuLiikaa) {
      vari = 'purple'
    } else if (juurilasku.tila === LaskunTila.luottotappio || juurilasku.tila === LaskunTila.hyvitetty) {
      vari = 'mint'
    }

    // Käsittele päälasku
    const tulos: AsiakkaanLaskunListaustieto = {
      avain: viimeisinTavallinen.avain,
      tyyppi: korvaavienLukumaara > 0 ? LaskunListaustyyppi.VANHEMPI : LaskunListaustyyppi.TAVALLINEN,
      asiakas: viimeisinTavallinen.asiakas ? viimeisinTavallinen.asiakas.nimi : '',
      nro: laskunro,
      nrotyyppi: viimeisinTavallinen.nrotyyppi,
      pvm: this._dateService.annaPaikallinenPvm(viimeisinTavallinen.pvm, viimeisinTavallinen.pvml),
      erapvm: this._dateService.annaPaikallinenPvm(viimeisinTavallinen.erapvm, viimeisinTavallinen.erapvml),
      summa: summaRypas,
      avoinna: avoinnaRypas,
      tila: juurilasku.tila,
      juuriAvain: juurilasku.avain,
      valuutta: viimeisinTavallinen.valuutta,
      lemontreeAsiakas: juurilasku.lemontreeAsiakas,
      korvaavat: [],
      juurilasku: juurilasku,
      kasiteltava: viimeisinTavallinen,
      lahetysEpaonnistui: this.onkoEmailEpaonnistunut(viimeisinTavallinen) || this.onkoSahkoinenEpaonnistunut(viimeisinTavallinen),
      vari: vari
    }

    // Käsittele korvaavat
    let index = 1
    let muistutuslaskuri = 1
    let hyvityslaskuri = 1
    for (const muistutusTaiHyvitys of muistutuksetJaHyvitykset) {
      const vika = index === korvaavienLukumaara
      // const tila = !vika ? this._laskuSharedService.annaLaskunKorvaustila(lasku.korvaus[index]) : lasku.tila

      let asiakas = ''
      let summa = muistutusTaiHyvitys.summa
      if (muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        const kulut = this._laskuSharedService.annaMuistutuslaskunKulutTalleLaskulle(juurilasku, muistutusTaiHyvitys)
        summa = this._currencyService.muutaBigDecimalRahaksi(kulut.korot.add(kulut.kulut))
        asiakas = 'Muistutus ' + muistutuslaskuri
        muistutuslaskuri++
      } else if (muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
        asiakas = 'Hyvitys ' + hyvityslaskuri
        hyvityslaskuri++
      }

      tulos.korvaavat.push({
        avain: muistutusTaiHyvitys.avain,
        tyyppi: vika ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
        asiakas: asiakas,
        nro: muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.HYVITYS ? this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, muistutusTaiHyvitys) : '',
        nrotyyppi: muistutusTaiHyvitys.nrotyyppi,
        pvm: this._dateService.annaPaikallinenPvm(muistutusTaiHyvitys.pvm, muistutusTaiHyvitys.pvml),
        erapvm: null, // this._dateService.annaPaikallinenPvm(muistutusTaiHyvitys.erapvm, muistutusTaiHyvitys.erapvml),
        summa: summa,
        avoinna: avoinnaRypas,
        tila: '',
        juuriAvain: juurilasku.avain,
        valuutta: muistutusTaiHyvitys.valuutta,
        juurilasku: juurilasku,
        kasiteltava: muistutusTaiHyvitys,
        lemontreeAsiakas: juurilasku.lemontreeAsiakas,
        lahetysEpaonnistui: this.onkoEmailEpaonnistunut(muistutusTaiHyvitys) || this.onkoSahkoinenEpaonnistunut(muistutusTaiHyvitys),
        vari: vari
      })
      index++
    }

    return tulos

  }

  onkoSahkoinenEpaonnistunut(kasiteltava: LaskuBase): boolean {
    if (kasiteltava && kasiteltava.sahkoinen) {
      if (
        kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.LAHETETTY_VASTAANOTTAJALLE &&
        kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
      ) {
        return true
      }
    }
    return false
  }

  onkoEmailEpaonnistunut(kasiteltava: LaskuBase): boolean {
    if (kasiteltava && kasiteltava.email && kasiteltava.email.vastaanottajat) {
      for (const vastaanottaja of kasiteltava.email.vastaanottajat) {
        if (vastaanottaja.status === EmailLahetysStatusKoodi.LAHETYS_EPAONNISTUI) {
          return true
        }
      }
    }
    return false
  }

}

@Injectable()
export class KirjanpitajanAsiakkaidenLaskutDataSourceService extends MatTableDataSource<LaskuJaAsiakas> {

  private subscription: Subscription = null
  private laskunTilaSubject = new BehaviorSubject<LaskunLokalisoituTila[]>([])
  public laskunTilaObservable: Observable<LaskunLokalisoituTila[]> = this.laskunTilaSubject.asObservable()

  nykyisetAsiakkaatObservable: BehaviorSubject<Asiakas[]> = new BehaviorSubject([])

  dataSourceTransformed: TransformingWrappingDataSource = null
  lataa: boolean = false

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _lemonTranslationService: LemonTranslationService,
    private _laskuService: LaskuService,
    private _laskuSharedService: LaskuSharedService
  ) {

    super([])

    this.dataSourceTransformed = new TransformingWrappingDataSource(this, this._dateService, this._currencyService, this._laskuSharedService, 11, 2018)
    this._lemonTranslationService.currentLanguageObservable.subscribe(kieli => {

      const naytettavatTilat: string[] = []

      naytettavatTilat.push(LaskunTila.kaikki)
      naytettavatTilat.push(LaskunTila.avoin)
      naytettavatTilat.push(LaskunTila.luottotappio)
      naytettavatTilat.push(LaskunTila.maksettu)
      naytettavatTilat.push(LaskunTila.eraantynyt)
      naytettavatTilat.push(LaskunTila.maksettuLiikaa)
      naytettavatTilat.push(LaskunTila.hyvitetty)
      naytettavatTilat.push(LaskunTila.luonnos)
      naytettavatTilat.push(LaskunTila.poistettu)
      naytettavatTilat.push(LaskunTila.mitatoity)

      const localizationKeys = []
      for (const tila of naytettavatTilat) {
        const localizationKey = 'lasku.listaus.tila.' + tila
        localizationKeys.push(localizationKey)
      }

      const tilat: LaskunLokalisoituTila[] = []

      for (const tila of naytettavatTilat) {
        tilat.push({
          tunnus: tila,
          nimi: this._lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + tila, kieli)
        })
      }

      tilat.sort((a, b): number => {
        if (a.tunnus === LaskunTila.kaikki) {
          return -1
        } else if (b.tunnus === LaskunTila.kaikki) {
          return 1
        }
        return a.nimi.localeCompare(b.nimi)
      })

      this.laskunTilaSubject.next(tilat)

    })

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    this.sortData = (data: LaskuJaAsiakas[], sort: MatSort): LaskuJaAsiakas[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      // console.log('SORT BY', active)

      if (active === 'summa') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.summa
          const valueb = kb.summa
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'avoinna') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.avoinna
          const valueb = kb.avoinna
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'nro') {
        return data.sort((a, b) => {
          const valuea = a.nro
          const valueb = b.nro
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'pvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.pvm.toDate() : null
          const valueb = kb.pvm ? kb.pvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'erapvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.erapvm.toDate() : null
          const valueb = kb.pvm ? kb.erapvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'tila') {
        return data.sort((a, b) => {
          const valuea = a.tila
          const valueb = b.tila
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      return data

    }
  }

  connect(): BehaviorSubject<LaskuJaAsiakas[]> {
    setTimeout(() => {
      this.startSubsctiption()
      this._changeDetectorRef.markForCheck()
    }, 10)
    return super.connect()
  }

  disconnect(): void {
    this.stopSubscription()
  }

  private stopSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
    this.lataa = false
  }

  private startSubsctiption() {
    this.lataa = true
    this.subscription = this.nykyisetAsiakkaatObservable.pipe(
      switchMap(asiakkaat => {
        // Nollaa data source
        this.data = []
        this.filter = null
        if (asiakkaat && asiakkaat.length > 0) {
          this.lataa = true
          const observables: Observable<LaskuJaAsiakas[]>[] = []
          for (const asiakas of asiakkaat) {
            const observable = this._firebaseLemonaid.firestoreCollection<Lasku>('laskut/' + this._laskuService.LEMONTREE_ASIAKAS_ID + '/laskut').whereFree('asiakas.avain', '==', asiakas.avain).listen().pipe(
              map(laskut => {
                return laskut.map(lasku => {
                  const laskuJaAsiakas = lasku as LaskuJaAsiakas
                  laskuJaAsiakas.lemontreeAsiakas = asiakas
                  return laskuJaAsiakas
                })
              })
            )
            observables.push(observable)
          }
          return combineLatest(observables)
        } else {
          this.lataa = false
          return EMPTY
        }
      }),
      map(laskuarrayt => {
        const laskut = [].concat(...laskuarrayt)
        for (const lasku of laskut) {
          delete lasku['haku']
          if (lasku.korvaus) {
            for (const korvaava of lasku.korvaus) {
              delete korvaava['haku']
            }
          }
        }
        return laskut
      })
    ).subscribe(laskut => {
      this.lataa = false
      this.data = laskut
    }, err => {
      this.lataa = false
      this._errorHandler.handleError(err)
    })
  }

}
