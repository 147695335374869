<picture>
  <source srcset="/assets/taustat/login_720x512_crushed.webp 720w, /assets/taustat/login_1440x1024_crushed.webp 1440w, /assets/taustat/login_2880x2048_crushed.webp 2880w, /assets/taustat/login_4320x3072_crushed.webp 4320w" type="image/webp" sizes="100vw">
  <source srcset="/assets/taustat/login_720x512_crushed.png 720w, /assets/taustat/login_1440x1024_crushed.png 1440w, /assets/taustat/login_2880x2048_crushed.png 2880w, /assets/taustat/login_4320x3072_crushed.png 4320w" type="image/png" sizes="100vw">
  <img src="/assets/taustat/login_720x512_crushed.png" class="tausta">
</picture>

<div class="all-container">

  <!-- <mat-nav-list style="position: absolute; left: 4px; top: 4px; padding-top: 0;">
    <mat-list-item (click)="vaihdaKieli()">
      <div style="text-align: center; width: 100%; color:black;">Kieli / Language</div>
    </mat-list-item>
  </mat-nav-list> -->

  <div class="login-container">
    <div class="mat-elevation-z3" style="line-height: 24px; padding: 10px; background-color: rgba(255, 255, 255, 1); border-radius: 10px; margin: 10px;">
      <ng-container *ngIf="kirjautuminenKaynnissa else kirjaudu">
        <div style="margin: 3em;">Kirjaudutaan..</div>
      </ng-container>
      <ng-template #kirjaudu>
        <div class="red-text common-error" style="vertical-align: middle;">{{commonError}}</div>
        <div style="padding: 2em; text-align: justify;">
          <p>Järjestelmästä on nyt kirjauduttu pois.</p>
          <p>Huomaa, että Google-autentikointisi on edelleen voimassa. (Esim. GMail jne. palvelut.) Jos haluat päättää myös Google kirjautumisesi, klikkaa itsesi ulos oikeasta yläkulmasta <a href="https://myaccount.google.com">täällä</a>.</p>
        </div>
        <div style="padding-bottom: 2em; text-align: center;">
          <button class="primarybutton" type="button" mat-button (click)="login()">Kirjaudu uudelleen</button>
        </div>
      </ng-template>
    </div>
  </div>

  <span class="disclaimer">© TILITOIMISTO LEMON TREE OY {{getYear()}}</span>

</div>