import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ErrorHandler, Output, EventEmitter } from '@angular/core'

import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'
import { VeroilmoitusEmailLahetys, VeroilmoitusEmailLahetysHistoryPdfRequest, VirallinenRaporttiName, EmailLiite, EmailLiiteDownloadRequest } from '../../_jaettu-lemonator/model/kirjanpito'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../_shared-core/service/date.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { KirjanpitoUriService } from '../../_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { switchMap, tap, distinctUntilChanged, map } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, firstValueFrom } from 'rxjs'

import { VeroilmoituksenPerustiedot } from './veroilmoitus.component'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface VeroilmoitusLahetysHistoria extends VeroilmoitusEmailLahetys {
  recipientsEmails: string
  liitteetPromise: Promise<EmailLiite[]>
  nimi: string
  luotuDateStr: string
}

@Component({
  selector: '[app-veroilmoitus-aikaisemmin-lahetetyt-spostit]',
  templateUrl: './veroilmoitus-aikaisemmin-lahetetyt-spostit.component.html',
  styleUrls: ['./veroilmoitus-aikaisemmin-lahetetyt-spostit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoVeroilmoitusAikaisemminLahetetytComponent implements OnInit, OnDestroy {

  @Input() perustiedotObservable: Observable<VeroilmoituksenPerustiedot>
  @Output() onkoSpostejaLahetetty: EventEmitter<boolean> = new EventEmitter()

  private _asiakasObservable: Observable<Asiakas>
  private _ngUnsubscribe = new Subject<void>()

  sentThisTilikausiObservable: Observable<VeroilmoitusLahetysHistoria[]>

  constructor(
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _firebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this._asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.avain === b?.avain
      })
    )

    this.sentThisTilikausiObservable = combineLatest([
      this.perustiedotObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable
    ]).pipe(
      switchMap(([perustiedot, kirjanpitajatMap]) => {
        if (perustiedot?.asiakas && perustiedot?.tilikausi && kirjanpitajatMap) {
          const uri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksetCollection(perustiedot.asiakas.avain)
          return this._firebase.firestoreCollection<VeroilmoitusEmailLahetys>(uri)
            .where('tunniste', '==', this._dateService.localDateToNumber(perustiedot.tilikausi.loppuu) + '_veroilmoitus')
            .listen().pipe(
              map(lahetykset => {

                const historyEntries: VeroilmoitusLahetysHistoria[] = []
                for (const lahetys of lahetykset) {
                  const kirjanpitajaData = kirjanpitajatMap.get(lahetys.luoja)
                  const historyEntry: VeroilmoitusLahetysHistoria = {
                    avain: lahetys.avain,
                    kuukausi: lahetys.kuukausi,
                    tunniste: lahetys.tunniste,
                    luoja: lahetys.luoja,
                    luotu: lahetys.luotu,
                    lahetetty: lahetys.lahetetty,
                    recipients: lahetys.recipients,
                    kieli: lahetys.kieli,
                    aihe: lahetys.aihe,
                    teksti: lahetys.teksti,
                    liitaTuloslaskelma: lahetys.liitaTuloslaskelma,
                    liitaVirallinenTuloslaskelma: lahetys.liitaVirallinenTuloslaskelma,
                    liitaVirallinenTase: lahetys.liitaVirallinenTase,
                    liitaVeroilmoitus: lahetys.liitaVeroilmoitus,
                    liitaTaseErittely: lahetys.liitaTaseErittely,
                    taseErittely: lahetys.taseErittely,
                    veroilmoitusAvain: lahetys.veroilmoitusAvain,
                    veroilmoitus: lahetys.veroilmoitus,
                    lisaaPiilokopio: lahetys.lisaaPiilokopio,
                    isHolviClient: lahetys.isHolviClient,
                    recipientsEmails: lahetys.recipients.reduce((a, b) => a + b.email + ' ', ''),
                    nimi: kirjanpitajaData.etunimi + ' ' + kirjanpitajaData.sukunimi,
                    luotuDateStr: this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(lahetys.luotu), 'fi'),
                    liitteetPromise: null
                  }
                  const liitteetUri = this._kirjanpitoUriService.annaVeroilmoitusEmailLahetyksenLiitteetCollection(perustiedot.asiakas.avain, historyEntry.avain)
                  historyEntry.liitteetPromise = this._firebase.firestoreCollection<EmailLiite>(liitteetUri)
                    .where('poistettu', '==', false)
                    .get()
                  historyEntries.push(historyEntry)
                }

                return historyEntries.sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())

              })
            )
        }
        return observableOf<VeroilmoitusLahetysHistoria[]>(null as VeroilmoitusLahetysHistoria[])
      }),
      tap(data => {
        this.onkoSpostejaLahetetty.emit(data?.length > 0)
      })
    )

  }

  async downloadSentReport(lahetysAvain: string, reportType: VirallinenRaporttiName) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasObservable)

    const downloadRequest: VeroilmoitusEmailLahetysHistoryPdfRequest = {
      asiakasAvain: asiakas.avain,
      reportType: reportType,
      lahetysAvain: lahetysAvain
    }
    try {
      await this._firebase.functionsCall<VeroilmoitusEmailLahetysHistoryPdfRequest, string>('veroilmoitusEmailLahetysDownloadPdf', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        const filename = asiakas.nimi + '_' + reportType + '_' + lahetysAvain + '.pdf'
        this._fileSaverService.saveBase64As(data, filename, 'pdf')
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  async downloadAttachment(liite: EmailLiite) {
    const asiakas = await firstValueFrom(this._asiakasObservable)
    this._ladataanService.aloitaLataaminen()
    const downloadRequest: EmailLiiteDownloadRequest = {
      asiakasAvain: asiakas.avain,
      liite: liite
    }
    try {
      this._firebase.functionsCall<EmailLiiteDownloadRequest, string>('kirjanpitoDownloadEmailLiite', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        this._fileSaverService.saveBase64AsGuessedType(data, liite.nimi)
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
