import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot } from '@angular/router'

import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'
import { Lasku, LaskuBase, Laskuasetukset, UUDEN_LASKUN_AVAIN } from '../../_jaettu/model/lasku'

import { LaskuService } from '../service/lasku/lasku.service'

import { of as observableOf, Observable } from 'rxjs'
import { map, switchMap, take, filter } from 'rxjs/operators'
import { AsiakasService, AsiakkaanAvainTiedot } from '../service/asiakas/asiakas.service'

export interface LaskuKatseleComponentData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  tulosta: boolean
}

export interface LaskuKatseleComponentExistingData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
}

export interface LaskuComponentExistingData {
  juurilasku: Lasku
  asetukset: Laskuasetukset
}

export interface LaskuComponentData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
}

@Injectable()
export class LaskuComponentDataResolve  {

  private existingData: LaskuComponentExistingData = null

  constructor(
    private router: Router,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private laskuSharedService: LaskuSharedService
  ) {

  }

  asetaOlemassaolevaData(data: LaskuComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskuComponentData> {
    const id = route.params['id']
    const kasiteltavaAvain = route.params['kasiteltavaAvain']
    const asiakasAvain = route.params['asiakasAvain']
    if (id === UUDEN_LASKUN_AVAIN) {
      const lasku = this.laskuKopioija.annaUusiLasku()
      return this.haeAsetukset(lasku, lasku)
    } else if (
      this.existingData != null &&
      this.existingData.juurilasku &&
      this.existingData.juurilasku.avain === id
    ) {
      return this.hoidaMuokattavat(asiakasAvain, this.existingData.juurilasku, kasiteltavaAvain)
    } else {
      return this.laskuService.getLaskuObservable(id).pipe(
        take(1),
        switchMap(juurilasku => {

          if (!juurilasku) {
            this.router.navigate(['asiakkaat', asiakasAvain, 'laskutus'])
            return observableOf(null)
          }

          return this.hoidaMuokattavat(asiakasAvain, juurilasku, kasiteltavaAvain)

        })
      )
    }
  }

  private hoidaMuokattavat(asiakasAvain: string, juurilasku: Lasku, kasiteltavaAvain: string): Observable<LaskuComponentData> {
    const edellinen = this.laskuSharedService.annaViimeisinKasiteltavaLasku(juurilasku)
    if (kasiteltavaAvain === UUDEN_LASKUN_AVAIN) {
      const muokattavaksiJuuri = this.laskuKopioija.copyLasku(juurilasku)
      const kasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(edellinen, null)
      if (!muokattavaksiJuuri.korvaus) {
        muokattavaksiJuuri.korvaus = []
      }
      muokattavaksiJuuri.korvaus.push(kasiteltava)
      return this.haeAsetukset(muokattavaksiJuuri, kasiteltava)
    } else {
      if (edellinen.avain !== kasiteltavaAvain) {
        this.router.navigate(['asiakkaat', asiakasAvain, 'laskutus', 'laskut', juurilasku.avain, edellinen.avain])
        return observableOf(null)
      }
      const kopioidut = this.laskuKopioija.kopioiEiLahetettyMuokattavaksi(juurilasku, edellinen, null)
      return this.haeAsetukset(kopioidut.muokattavaJuuri, kopioidut.muokattavaKasiteltava)
    }
  }

  private haeAsetukset(juurilasku: Lasku, kasiteltava: LaskuBase): Observable<LaskuComponentData> {
    return this.laskuService.asetuksetObservable.pipe(
      take(1),
      map(asetukset => {
        const data: LaskuComponentData = {
          asetukset: asetukset,
          juurilasku: juurilasku,
          kasiteltava: kasiteltava
        }
        return data
      })
    )
  }

}

@Injectable()
export class LaskuKatseleComponentDataResolve  {

  private existingData: LaskuKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private laskuSharedService: LaskuSharedService
  ) {

  }

  asetaOlemassaolevaData(data: LaskuKatseleComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskuKatseleComponentData> {
    const id = route.params['id']
    const kasiteltavaAvain = route.params['kasiteltavaAvain']
    const asiakasAvain = route.params['asiakasAvain']
    const toiminto = route.params['toiminto']
    if (id === 'uusi') {
      const lasku = this.laskuKopioija.annaUusiLasku()
      return this.haeAsetukset({ juurilasku: lasku, kasiteltava: lasku }, toiminto)
    } else if (
      this.existingData != null &&
      this.existingData.juurilasku &&
      this.existingData.kasiteltava &&
      this.existingData.juurilasku.avain === id &&
      this.existingData.kasiteltava.avain === kasiteltavaAvain
    ) {
      return this.haeAsetukset(this.existingData, toiminto)
    } else {
      return this.laskuService.getLaskuObservable(id).pipe(
        take(1),
        switchMap(juurilasku => {

          if (!juurilasku) {
            this.router.navigate(['asiakkaat', asiakasAvain, 'laskutus'])
            return observableOf(null)
          }

          if (kasiteltavaAvain === 'uusi') {
            const edellinen = this.laskuSharedService.annaViimeisinKasiteltavaLasku(juurilasku)
            const kasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(edellinen, null)
            if (!juurilasku.korvaus) {
              juurilasku.korvaus = []
            }
            juurilasku.korvaus.push(kasiteltava)
            return this.haeAsetukset({ juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
          } else {
            const kasiteltava = this.laskuSharedService.annaKasiteltavaLasku(juurilasku, kasiteltavaAvain)
            return this.haeAsetukset({ juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
          }

        })
      )
    }
  }

  private haeAsetukset(existingData: LaskuKatseleComponentExistingData, toiminto: string): Observable<LaskuKatseleComponentData> {
    return this.laskuService.asetuksetObservable.pipe(
      take(1),
      map(asetukset => {
        const data: LaskuKatseleComponentData = {
          asetukset: asetukset,
          juurilasku: existingData.juurilasku,
          kasiteltava: existingData.kasiteltava,
          tulosta: toiminto === 'tulosta'
        }
        return data
      })
    )
  }

}

@Injectable()
export class LaskuSelaaKatseleComponentDataResolve  {

  private existingData: LaskuKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private laskuSharedService: LaskuSharedService,
    private asiakasService: AsiakasService
  ) {

  }

  asetaOlemassaolevaData(data: LaskuKatseleComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskuKatseleComponentData> {
    const id = route.params['id']
    const kasiteltavaAvain = route.params['kasiteltavaAvain']
    const asiakasAvain = route.params['asiakasAvain']
    const toiminto = route.params['toiminto']
    if (id === 'uusi') {
      const lasku = this.laskuKopioija.annaUusiLasku()
      return this.haeAsetuksetNykyiselleAsiakkaalle({ juurilasku: lasku, kasiteltava: lasku }, toiminto)
    } else if (
      this.existingData != null &&
      this.existingData.juurilasku &&
      this.existingData.kasiteltava &&
      this.existingData.juurilasku.avain === id &&
      this.existingData.kasiteltava.avain === kasiteltavaAvain
    ) {
      return this.haeAsetuksetNykyiselleAsiakkaalle(this.existingData, toiminto)
    } else {
      return this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
        filter<AsiakkaanAvainTiedot>(Boolean),
        take(1),
        switchMap(asiakas => {
          return this.laskuService.getLaskuObservableAsiakkaalle(asiakas.asiakasId + '', id).pipe(
            take(1),
            switchMap(juurilasku => {

              if (!juurilasku) {
                this.router.navigate(['asiakkaat', asiakasAvain, 'laskut', 'selaa'])
                return observableOf(null)
              }

              if (kasiteltavaAvain === 'uusi') {
                const edellinen = this.laskuSharedService.annaViimeisinKasiteltavaLasku(juurilasku)
                const kasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(edellinen, null)
                if (!juurilasku.korvaus) {
                  juurilasku.korvaus = []
                }
                juurilasku.korvaus.push(kasiteltava)
                return this.haeAsetukset(asiakas.asiakasId + '', { juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
              } else {
                const kasiteltava = this.laskuSharedService.annaKasiteltavaLasku(juurilasku, kasiteltavaAvain)
                return this.haeAsetukset(asiakas.asiakasId + '', { juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
              }

            })
          )
        })
      )
    }
  }

  private haeAsetuksetNykyiselleAsiakkaalle(existingData: LaskuKatseleComponentExistingData, toiminto: string): Observable<LaskuKatseleComponentData> {
    return this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      filter<AsiakkaanAvainTiedot>(Boolean),
      take(1),
      switchMap(asiakas => {
        return this.haeAsetukset(asiakas.asiakasId + '', existingData, toiminto)
      })
    )
  }

  private haeAsetukset(asiakasId: string, existingData: LaskuKatseleComponentExistingData, toiminto: string): Observable<LaskuKatseleComponentData> {
    return this.laskuService.getLaskuAsetuksetAsiakkaalleObservable(asiakasId).pipe(
      filter<Laskuasetukset>(Boolean),
      take(1),
      map(asetukset => {
        const data: LaskuKatseleComponentData = {
          asetukset: asetukset,
          juurilasku: existingData.juurilasku,
          kasiteltava: existingData.kasiteltava,
          tulosta: toiminto === 'tulosta'
        }
        return data
      })
    )
  }

}

// @Injectable()
// export class TuoteComponentDataResolve implements Resolve<AsiakkaanTuote> {

//   private existingData: AsiakkaanTuote = null

//   constructor(
//     private laskuService: LaskuService,
//     private laskuKopioija: LaskuKopioija
//   ) {

//   }

//   asetaOlemassaolevaData(data: AsiakkaanTuote) {
//     this.existingData = data
//   }

//   resolve(route: ActivatedRouteSnapshot): Observable<AsiakkaanTuote> {
//     const id = route.params['id']
//     if (id === 'uusi') {
//       return observableOf(this.laskuKopioija.annaUusiTuote())
//     } else if (this.existingData != null && this.existingData.$key === id) {
//       return observableOf(this.existingData)
//     } else {
//       return this.laskuService.getTuoteObservable(id).pipe(take(1))
//     }
//   }

// }

// @Injectable()
// export class AsiakasComponentDataResolve implements Resolve<LaskunAsiakas> {

//   private existingData: LaskunAsiakas = null

//   constructor(
//     private laskuService: LaskuService,
//     private laskuKopioija: LaskuKopioija
//   ) {

//   }

//   asetaOlemassaolevaData(data: LaskunAsiakas) {
//     this.existingData = data
//   }

//   resolve(route: ActivatedRouteSnapshot): Observable<LaskunAsiakas> {
//     const id = route.params['id']
//     if (id === 'uusi') {
//       return observableOf(this.laskuKopioija.annaUusiAsiakas())
//     } else if (this.existingData != null && this.existingData.avain === id) {
//       return observableOf(this.existingData)
//     } else {
//       return this.laskuService.getAsiakasObservable(id).pipe(take(1))
//     }
//   }

// }

// @Injectable()
// export class LaskutComponentDataResolve implements Resolve<SummienRullausData> {

//   private existingData: SummienRullausData = null

//   constructor() {

//   }

//   asetaOlemassaolevaData(data: SummienRullausData) {
//     this.existingData = data
//   }

//   resolve(route: ActivatedRouteSnapshot): Observable<SummienRullausData> {
//     if (this.existingData) {
//       const existing = this.existingData
//       this.existingData = null
//       return observableOf(existing)
//     } else {
//       return observableOf(null)
//     }
//   }

// }
