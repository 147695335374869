<button *ngIf="showPen && !lukittu" mat-icon-button class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<ng-container *ngIf="editing && !lukittu; else readOnly">
  <div class="tili-num">{{ numero }}</div>
  <div class="long-col">{{ label }}</div>
  <input type="text" [(ngModel)]="value" (blur)="tallennaMuuttunutArvo()" [matDatepicker]="alvIlmoitusPicker" class="short-col input">
  <div class="end-marking save-icons-right">
    <mat-datepicker-toggle style="position: absolute; top: -2px; left: 0;" [for]="alvIlmoitusPicker"></mat-datepicker-toggle>
    <mat-datepicker #alvIlmoitusPicker (closed)="tallennaMuuttunutArvo()"></mat-datepicker>
    <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess" class="save-success-text" [@quickInSlowOut]="'in'">
      Päivämäärä tallennettiin
    </span>
    <mat-icon *ngIf="saveSuccess" class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    <span *ngIf="saveFailure" class="save-failure-text">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span>
    <mat-icon *ngIf="saveFailure" class="save-failure-icon" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
  </div>
  <!-- <div *ngIf="naytaEhdotus" style="flex-basis: 100%;" class="ehdotusnappula-container">
    <button class="primarybutton" (click)="ehdotus()" mat-button>Hae ehdotus</button>
  </div> -->
</ng-container>

<ng-template #readOnly>
  <div class="tili-num">{{ numero }}</div>
  <div class="long-col">{{ label }}</div>
  <div app-veroilmoitus-value-read-only [numero]="numero" [veroilmoitus]="veroilmoitus" [repeatingMetadata]="repeatingMetadata" [format]="'date'" [log]="log" class="short-col" [class.currency]="false"></div>
  <div class="end-marking"></div>
</ng-template>