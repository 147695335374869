<div class="list-page kapea" style="max-width: 1200px;">

  <app-asiakas-otsikko otsikko="Kirjanpidon alustaminen" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <form [formGroup]="form">

    <mat-radio-group style="display: block; margin-bottom: 2em;" formControlName="kevytVaiTavallinen">
      <mat-radio-button style="margin: 5px;" [value]="'kevyt'">Kevyt</mat-radio-button>
      <mat-radio-button style="margin: 5px;" [value]="'tavallinen'">Tavallinen</mat-radio-button>
    </mat-radio-group>

    <mat-form-field style="display: block; width: 25em; margin-bottom: 2em;">
      <mat-label>Alusta lähtien</mat-label>
      <input type="text" [matDatepicker]="pvmPicker" formControlName="kirjanpitoAlku" matInput data-lpignore="true" required />
      <mat-datepicker-toggle matSuffix [for]="pvmPicker"></mat-datepicker-toggle>
      <mat-datepicker #pvmPicker startView="month"></mat-datepicker>
      <mat-error *ngIf="kirjanpitoAlku?.errors?.required && (kirjanpitoAlku?.dirty || kirjanpitoAlku?.touched)">
        Valitse päivämäärä
      </mat-error>
    </mat-form-field>

    <mat-select *ngIf="!maksutapa.disabled" placeholder="Alustettava maksutapa" formControlName="maksutapa" style="display: block; width: 25em; margin-bottom: 2em;" required>
      <mat-option *ngFor="let mt of maksutavatObservable | async" [value]="mt.tunniste">
        {{ mt.nimi }}
      </mat-option>
    </mat-select>
  </form>

  <button [disabled]="kirjanpitoAlku?.errors?.required" mat-raised-button color="primary" style="margin: 20px 0;" (click)="alusta()">Alusta</button>
  <div style="font-size: 10px">Huom! Alustaminen voi kestää jopa 10 minuuttia, älä sulje tätä ikkunaa.</div>

  <div *ngIf="failedMessage" class="lemon-error" style="margin: 20px 0;">
    {{ failedMessage }}
  </div>
</div>