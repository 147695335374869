/* eslint-disable @typescript-eslint/naming-convention */
import { Asiakas, Tilikausi } from '../../../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajanNimitiedot } from '../../../../_jaettu-lemonator/model/kirjanpitaja'
import { VeroilmoituksenAvainluvut, VeroilmoituksenValueConstants, VeroilmoitusDraft } from '../../../../_jaettu-lemonator/model/kirjanpito'
import { VeroilmoitusSummatService } from '../veroilmoitus-summat.service'
import { DateService } from '../../../../_shared-core/service/date.service'
import { OsingotLocatingCalculationService, VeroilmoituksenKaikkiTilisummat, VeroilmoituksenLaskija, VeroilmoituksenPaivityksenTulos, VeroilmoitusValidationError } from '../veroilmoitus-laskenta.service'
import { KirjanpidonUlkopuolisetKulut } from '../../../../_jaettu/model/kayttaja'
import { IlmoitusRivi } from '../../base-ilmoitin-export.service'
import { Timestamp } from '../../../../_shared-core/model/common'
import { CurrencyService } from '../../../../_shared-core/service/currency.service'
import { RepeatingMetadata, VeroilmoitusBaseSpecialiazedCalculationService } from '../base-specialized-calculation.service'
import { AsiakasJaettuService } from '../../asiakas-jaettu.service'
import { CodeCheckService } from '../../../../_shared-core/service/code-check.service'

/**
 * 701 V/P*   Osakkeenomistajan nimi    AN70
 * 703 V/P*   Henkilö- tai Y-tunnus     YTUNNUS || HETU
 * 570 V/P*   Osakkeiden lukumäärä      +N15
 * 706 *      Vuokrat                   R13,2
 * 708 *      Korot                     R13,2
 * 707 *      Luovutukset               R13,2
 * 709 *      Muut                      R13,2
 * 864 V/P*   Osakaslainat              R13,2
 */
export interface VeroilmoituksenOsakas {
  /** Osakkeenomistajan nimi */
  '701': string
  /** Henkilö- tai Y-tunnus */
  '703': string
  /** Osakkeiden lukumäärä */
  '570': number
  /** Vuokrat */
  '706': number
  /** Korot */
  '708': number
  /** Luovutukset */
  '707': number
  /** Muut */
  '709': number
  /** Osakaslainat */
  '864': number
}

export interface VeroilmoituksenOsakkaanKaytossaOllutHuoneisto {
  /** Osakkaan henkilötunnus */
  '722': string
  /** Kiinteistön, kiinteistöyhtiön tai asunto-osakeyhtiön nimi */
  '724': string
  /** Käyttöaika asuntona (ppkkvvvv - ppkkvvvv) */
  '729': string
  /** Nettovarallisuuslaskelmassa käytetty arvo */
  '730': number
}

// eslint-disable-next-line no-shadow
export enum RepeatingPartIdentifiers {
  HUONEISTOT = 'huoneistot',
  OSINGOT = 'osingot',
  VAPAASTA_PAAOMASTA_JAETUT = 'vapaasta',
  OSAKKAAT = 'osakkaat'
}

export class VeroilmoitusLaskenta2021OyService extends VeroilmoitusBaseSpecialiazedCalculationService implements VeroilmoituksenLaskija, OsingotLocatingCalculationService {

  constructor(
    private _dateService: DateService,
    protected _currencyService: CurrencyService,
    protected _codeCheckService: CodeCheckService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {
    super(_currencyService, _codeCheckService)
  }

  private _veroilmoituksenOsinkojenKentat: Set<string> = new Set(['537', '538', '522'])
  parsiOsinkojenMetadata(arvot: IlmoitusRivi[]): RepeatingMetadata[] {
    const indexOfFirst = arvot.findIndex(a => this._veroilmoituksenOsinkojenKentat.has(a.key))
    if (indexOfFirst > -1) {
      return this._parsiMetadata(arvot, RepeatingPartIdentifiers.OSINGOT, indexOfFirst - 1)
    }
    return []
  }

  private _veroilmoituksenVapaastaPaaomastaJaettujenKentat: Set<string> = new Set(['293', '294', '295'])
  parsiVapaastaPaaomastaJaettujenMetadata(arvot: IlmoitusRivi[]): RepeatingMetadata[] {
    const indexOfFirst = arvot.findIndex(a => this._veroilmoituksenVapaastaPaaomastaJaettujenKentat.has(a.key))
    if (indexOfFirst > -1) {
      return this._parsiMetadata(arvot, RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT, indexOfFirst - 1)
    }
    return []
  }

  private _veroilmoituksenOsakkaanKentat: Set<string> = new Set(['701', '703', '570', '706', '708', '707', '709', '864'])
  parsiOsakkaidenMetadata(arvot: IlmoitusRivi[]): RepeatingMetadata[] {
    const indexOf44 = arvot.findIndex(a => a.key === '044')
    if (indexOf44 > -1) {
      if (this._varmistaEttaEnsimmainenKenttaOnYksiSallituista(arvot, indexOf44 + 1, this._veroilmoituksenOsakkaanKentat)) {
        return this._parsiMetadata(arvot, RepeatingPartIdentifiers.OSAKKAAT, indexOf44 + 1)
      }
    }
    return []
  }

  parsiOsakasHuoneistojenMetadata(arvot: IlmoitusRivi[]): RepeatingMetadata[] {
    const indexOfFirst722 = arvot.findIndex(a => a.key === '722')
    if (indexOfFirst722 > 0) {
      return this._parsiMetadata(arvot, RepeatingPartIdentifiers.HUONEISTOT, indexOfFirst722 - 1)
    }
    return []
  }

  groupIlmoitusrivitByOsakkaat(arvot: IlmoitusRivi[]): IlmoitusRivi[][] {
    const repeatingMetadata = this.parsiOsakkaidenMetadata(arvot)
    return repeatingMetadata.map(repeat => super._getRivitForRepeatingPart(repeat, arvot))
  }
  groupIlmoitusrivitByOsakkaidenHuoneistot(arvot: IlmoitusRivi[]): IlmoitusRivi[][] {
    const repeatingMetadata = this.parsiOsakasHuoneistojenMetadata(arvot)
    return repeatingMetadata.map(repeat => super._getRivitForRepeatingPart(repeat, arvot))
  }
  groupIlmoitusrivitByOsingot(arvot: IlmoitusRivi[]): IlmoitusRivi[][] {
    const repeatingMetadata = this.parsiOsinkojenMetadata(arvot)
    return repeatingMetadata.map(repeat => super._getRivitForRepeatingPart(repeat, arvot))
  }
  groupIlmoitusrivitByVapaastaPaaomastaJaettujen(arvot: IlmoitusRivi[]): IlmoitusRivi[][] {
    const repeatingMetadata = this.parsiVapaastaPaaomastaJaettujenMetadata(arvot)
    return repeatingMetadata.map(repeat => super._getRivitForRepeatingPart(repeat, arvot))
  }


  public muodostaVeroilmoituksenLopullisetArvot(
    tietokannasta: VeroilmoitusDraft,
    eiTallennetut: VeroilmoituksenAvainluvut,
    tilienSummat: VeroilmoituksenKaikkiTilisummat,
    summatService: VeroilmoitusSummatService,
    asiakas: Asiakas,
    kirjanpitaja: KirjanpitajanNimitiedot,
    tilikausi: Tilikausi,
    kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut,
    muodostettu: Timestamp // Tallennusaika
  ): VeroilmoituksenPaivityksenTulos {

    const tilikaudenSummat = tilienSummat.nykyinen?.tilikaudenSummat ?? {}
    const edellisenTilikaudenSummat = tilienSummat.edellinen?.tilikaudenSummat ?? {}

    // The list containing the produced values
    const arvot: IlmoitusRivi[] = []
    const oletukset: VeroilmoituksenAvainluvut = {}
    const affectedAccounts: Set<string> = new Set()
    const ignoredAccounts: Set<string> = new Set()

    this.tietokannastaLaskennoille = tietokannasta
    this.eiTallennetutLaskennoille = eiTallennetut
    this.oletuksetLaskennoille = oletukset

    if (!kirjanpitaja) {
      throw new Error('Kirjanpitäjä puuttuu')
    }

    const muodostettuAsDate = this._dateService.timestampToLocalDate(muodostettu)
    const vastuukirjanpitaja = this._asiakasJaettuService.annaVastuukirjanpitajaPaivalle(asiakas, muodostettuAsDate)
    const isHolviZen = vastuukirjanpitaja?.kirjanpitajanAvain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2'
    const accountantPhoneNumber = isHolviZen ?
      this._asiakasJaettuService.ZEN_TEAM_NUMBER // Use office number for Holvi Zen customers
      :
      kirjanpitaja.puhelin

    if (!accountantPhoneNumber) {
      throw new Error('Kirjanpitäjän puhelinnumero puuttuu')
    }

    if (!kirjanpitaja.email || !kirjanpitaja.email.endsWith('@lemontree.fi')) {
      throw new Error('Kirjanpitäjän email puuttuu')
    }

    // Calculate!

    // Specs here: https://lemontree-squad.monday.com/boards/615797797/pulses/860432205

    const formattedDateAndTimeStr = this._dateService.muotoileVeroFormaattiPaivaJaAikaDate(this._dateService.timestampToDate(muodostettu))
    arvot.push({ key: '000', value: 'VSY06B21' }) // File identifier
    arvot.push({ key: '048', value: 'Tilitoimisto Lemon Tree Oy' }) // ID of the software that produced the file
    arvot.push({ key: '014', value: '2599105-8_LT' }) // ID of the software that produced the file (y-tunnus + 2 alphanums)
    arvot.push({ key: '198', value: formattedDateAndTimeStr }) // Software-generated timestamp

    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // 111111 TAUSTATIEDOT
    // Asiakastiedot:
    // Haetaan asiakkaan tiedoista Nimi,
    // 010 = Y - tunnus
    arvot.push({ key: '010', value: asiakas.ytunnus })

    // 054 = tilikausi, jolta veroilmoitusta ollaan antamassa.
    const tilikausiFormatted = this._dateService.muotoileVeroFormaattiPaiva(tilikausi.alkaa) + '-' + this._dateService.muotoileVeroFormaattiPaiva(tilikausi.loppuu)
    arvot.push({ key: '054', value: tilikausiFormatted })

    const v169 = this._asetaArvo('169', arvot, () => '0', (arvo) => arvo === '1')
    const v173 = this._asetaArvo('173', arvot, () => '0', (arvo) => arvo === '1')
    const v184 = this._asetaArvo('184', arvot, () => '0', (arvo) => arvo === '1')
    const v182 = this._asetaArvo('182', arvot, () => '0', (arvo) => arvo === '1')
    const v174 = this._asetaArvo('174', arvot, () => '0', (arvo) => arvo === '1')
    const v176 = this._asetaArvo('176', arvot, () => '0', (arvo) => arvo === '1')

    // Lisätietojen antaja:
    // 041 = vastuukirjanpitäjän nimi
    arvot.push({ key: '041', value: kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi })
    // 042 = vastuukirjanpitäjän puhelinnumero

    arvot.push({ key: '042', value: accountantPhoneNumber })
    // 044 = vastuukirjanpitäjän sähköposti
    arvot.push({ key: '044', value: isHolviZen ? 'holvi@lemontree.fi' : kirjanpitaja.email })

    // HUOM!!!!! 044 ja osakkaiden välillä EI SAA OLLA YHTÄÄN ENTRYÄ!!!!
    // HUOM!!!!! 044 ja osakkaiden välillä EI SAA OLLA YHTÄÄN ENTRYÄ!!!!
    // HUOM!!!!! 044 ja osakkaiden välillä EI SAA OLLA YHTÄÄN ENTRYÄ!!!!

    // Osakastiedot
    /**
     * 001 Osatietoryhmien lukumäärä     N8
     *
     * 701 V/P*   Osakkeenomistajan nimi    AN70
     * 703 V/P*   Henkilö- tai Y-tunnus     YTUNNUS || HETU
     * 570 V/P*   Osakkeiden lukumäärä      +N15
     * 706 *      Vuokrat                   R13,2
     * 708 *      Korot                     R13,2
     * 707 *      Luovutukset               R13,2
     * 709 *      Muut                      R13,2
     * 864 V/P*   Osakaslainat              R13,2
     *
     * 009 Osatietoryhmän loppumerkki: juokseva nro N8
     */
    // Get osakkaat defaults
    if (tietokannasta.osakkaat?.length) {
      arvot.push({ key: '001', value: tietokannasta.osakkaat.length + '' })
      let order = 1
      for (const osakas of tietokannasta.osakkaat) {

        const tunniste701 = this.annaTunniste('701', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste703 = this.annaTunniste('703', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste570 = this.annaTunniste('570', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste706 = this.annaTunniste('706', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste708 = this.annaTunniste('708', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste707 = this.annaTunniste('707', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste709 = this.annaTunniste('709', RepeatingPartIdentifiers.OSAKKAAT, order)
        const tunniste864 = this.annaTunniste('864', RepeatingPartIdentifiers.OSAKKAAT, order)

        const v701 = this._annaArvoString(tunniste701, () => osakas.nimi)
        const v703 = this._annaArvoString(tunniste703, () => (osakas.hetuTaiYtunnus ?? '').toUpperCase())
        const v570 = this._annaNumero(tunniste570, () => osakas.osakkeita)

        const v706 = this._annaNumero(tunniste706)
        const v708 = this._annaNumero(tunniste708)
        const v707 = this._annaNumero(tunniste707)
        const v709 = this._annaNumero(tunniste709)
        const v864 = this._annaNumero(tunniste864)

        arvot.push({ key: '701', value: v701 })
        arvot.push({ key: '703', value: v703 })
        arvot.push({ key: '570', value: Math.round(v570) + '' })
        this._setOptionalLuku('706', v706, arvot)
        this._setOptionalLuku('708', v708, arvot)
        this._setOptionalLuku('707', v707, arvot)
        this._setOptionalLuku('709', v709, arvot)
        this._setOptionalLuku('864', v864, arvot)

        arvot.push({ key: '009', value: order + '' })

        order++

      }
    }

    const v185 = this._asetaArvo('185', arvot, () => VeroilmoituksenValueConstants.FALSE, (arvo) => arvo === VeroilmoituksenValueConstants.TRUE)

    oletukset['osakkaiden-huoneistot-part'] = VeroilmoituksenValueConstants.FALSE
    const osakkaidenHuoneistotAuki = this._annaArvo('osakkaiden-huoneistot-part', () => VeroilmoituksenValueConstants.FALSE)
    if (osakkaidenHuoneistotAuki === VeroilmoituksenValueConstants.TRUE) {
      const huoneistojenLukumaara = this._annaNumero('huoneistot', () => 0)
      if (huoneistojenLukumaara > 0) {
        arvot.push({ key: '001', value: Math.round(huoneistojenLukumaara) + '' })
        for (let order = 1; order <= huoneistojenLukumaara; order++) {

          const tunniste722 = this.annaTunniste('722', RepeatingPartIdentifiers.HUONEISTOT, order)
          const tunniste724 = this.annaTunniste('724', RepeatingPartIdentifiers.HUONEISTOT, order)
          const tunniste729 = this.annaTunniste('729', RepeatingPartIdentifiers.HUONEISTOT, order)
          const tunniste730 = this.annaTunniste('730', RepeatingPartIdentifiers.HUONEISTOT, order)

          const v722 = this._annaArvo(tunniste722)
          const v724 = this._annaArvo(tunniste724)
          const v729 = this._annaArvo(tunniste729)
          const v730 = this._annaNumero(tunniste730)

          arvot.push({ key: '722', value: (v722 ?? '') + '' })
          arvot.push({ key: '724', value: (v724 ?? '') + '' })
          arvot.push({ key: '729', value: (v729 ?? '') + '' })
          arvot.push({ key: '730', value: this._annaLuku(v730) })

          arvot.push({ key: '009', value: order + '' })

        }
      }
    }

    const onkoOsingotOletuksenaAuki = false
    const onkoOsingotAuki = this._onkoOsaAuki(onkoOsingotOletuksenaAuki, 'jaettavat-osingot-part')
    oletukset['jaettavat-osingot-part'] = onkoOsingotOletuksenaAuki ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE
    if (onkoOsingotAuki) {
      const osinkojenLukumaara = this._annaNumero('osingot', () => 0)
      if (osinkojenLukumaara > 0) {
        arvot.push({ key: '001', value: Math.round(osinkojenLukumaara) + '' })
        for (let order = 1; order <= osinkojenLukumaara; order++) {

          const tunniste537 = this.annaTunniste('537', RepeatingPartIdentifiers.OSINGOT, order)
          const tunniste538 = this.annaTunniste('538', RepeatingPartIdentifiers.OSINGOT, order)
          const tunniste522 = this.annaTunniste('522', RepeatingPartIdentifiers.OSINGOT, order)

          const v537 = this._annaArvo(tunniste537)
          const v538 = this._annaArvo(tunniste538)
          const v522 = this._annaNumero(tunniste522)

          arvot.push({ key: '537', value: (v537 ?? '') + '' })
          arvot.push({ key: '538', value: (v538 ?? '') + '' })
          arvot.push({ key: '522', value: this._annaLuku(v522) })

          arvot.push({ key: '009', value: order + '' })

        }
      }
      const vapaanOmanPaaomanRahastostaJaetutLukumaara = this._annaNumero('vapaasta', () => 0)
      if (vapaanOmanPaaomanRahastostaJaetutLukumaara > 0) {
        arvot.push({ key: '001', value: Math.round(vapaanOmanPaaomanRahastostaJaetutLukumaara) + '' })
        for (let order = 1; order <= vapaanOmanPaaomanRahastostaJaetutLukumaara; order++) {

          const tunniste293 = this.annaTunniste('293', RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT, order)
          const tunniste294 = this.annaTunniste('294', RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT, order)
          const tunniste295 = this.annaTunniste('295', RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT, order)

          const v293 = this._annaNumero(tunniste293)
          const v294 = this._annaArvo(tunniste294)
          const v295 = this._annaArvo(tunniste295)

          arvot.push({ key: '294', value: (v294 ?? '') + '' })
          arvot.push({ key: '295', value: (v295 ?? '') + '' })
          arvot.push({ key: '293', value: this._annaLuku(v293) })

          arvot.push({ key: '009', value: order + '' })

        }
      }
    }

    const v591 = this._asetaNumero('591', arvot)
    const v592 = this._asetaNumero('592', arvot)
    const v586 = this._asetaArvo('586', arvot, () => '0', (arvo) => arvo === '1')

    const v571 = this._asetaArvo('571', arvot)

    // Tuloverolaskelma

    // Liiketoiminta ja oma käyttö
    const v330 = this._asetaNumero('330', arvot, () => 0 - summatService.laskeDebetMinusKredit('30', tilikaudenSummat, affectedAccounts))
    const v331 = this._asetaNumero('331', arvot, () => 0 - summatService.laskeDebetMinusKredit('36', tilikaudenSummat, affectedAccounts))

    // Toiminnan muut tuotot
    const v332 = this._asetaNumero('332', arvot)

    const v333 = this._asetaNumero('333', arvot, () => 0)

    const v260 = this._asetaNumero('260', arvot, () => 0)

    const t3700 = 0 - summatService.laskeDebetMinusKredit('3700', tilikaudenSummat, affectedAccounts)
    const v334 = this._asetaNumero('334', arvot, () => t3700)

    const t3800 = 0 - summatService.laskeDebetMinusKredit('3800', tilikaudenSummat, affectedAccounts)
    const v335 = this._asetaNumero('335', arvot, () => t3800)

    const v336 = this._asetaNumero('336', arvot, () => {
      const t37 = 0 - summatService.laskeDebetMinusKredit('37', tilikaudenSummat, affectedAccounts)
      const t3801 = 0 - summatService.laskeDebetMinusKredit('3801', tilikaudenSummat, affectedAccounts)
      const t3990 = 0 - summatService.laskeDebetMinusKredit('3990', tilikaudenSummat, affectedAccounts)
      return t37 - t3700 - t3800 - t3801 - t3990
    })

    // Rahoitustuotot
    // 337 = täytettävä
    const v337 = this._asetaNumero('337', arvot)
    // 338 = täytettävä
    const v338 = this._asetaNumero('338', arvot)
    // 339 = täytettävä
    const v339 = this._asetaNumero('339', arvot)
    // 340 = täytettävä
    const v340 = this._asetaNumero('340', arvot)
    // 341 = 9200...9289 - 9240
    const v341 = this._asetaNumero('341', arvot, () => {
      const t9200to9289 = 0 - summatService.laskeDebetMinusKreditTilialueelta(9200, 9289, tilikaudenSummat, affectedAccounts)
      const t9240 = 0 - summatService.laskeDebetMinusKredit('9240', tilikaudenSummat, affectedAccounts)
      return t9200to9289 - t9240
    })
    // 344 = täytettävä
    const v344 = this._asetaNumero('344', arvot)
    // 345 = 9290..9299
    const v345 = this._asetaNumero('345', arvot, () => {
      const t9290to9299 = 0 - summatService.laskeDebetMinusKreditTilialueelta(9290, 9299, tilikaudenSummat, affectedAccounts)
      return t9290to9299
    })

    // Muut tuotot
    // 346 = täytettävä
    const v346 = this._asetaNumero('346', arvot)
    // 347 = täytettävä
    const v347 = this._asetaNumero('347', arvot)
    // 439 = täytettävä
    const v439 = this._asetaNumero('439', arvot)
    // 350 = täytettävä
    const v350 = this._asetaNumero('350', arvot)
    // 351 = täytettävä
    const v351 = this._asetaNumero('351', arvot)
    // 261 = täytettävä
    const v261 = this._asetaNumero('261', arvot, () => 0)
    // 353 = täytettävä
    const v353 = this._asetaNumero('353', arvot)

    // ELINKEINOTOIMINNAN VERONALAISET TUOTOT YHTEENSÄ
    const v354 = this._asetaNumero('354', arvot, () => {
      // console.log(v330, v331, v333, v260, v334, v335, v336, v338, v339, v340, v341, v344, v345, v347, v439, v351, v261, v353)
      return this._sum(v330, v331, v333, v260, v334, v335, v336, v338, v339, v340, v341, v344, v345, v347, v439, v351, v261, v353)
    })

    // Verottomat erät
    // 357 = 9950 jos enemmän kredittiä
    const v357 = this._asetaNumero('357', arvot, () => {
      return Math.max(0, summatService.laskeKreditMinusDebet('9950', tilikaudenSummat, affectedAccounts))
    })
    // 320 = 3801
    const v320 = this._asetaNumero('320', arvot, () => {
      return 0 - summatService.laskeDebetMinusKredit('3801', tilikaudenSummat, affectedAccounts)
    })
    // 318 = täytettävä
    const v318 = this._asetaNumero('318', arvot)
    // 358 = 9240 + 3990
    const v358 = this._asetaNumero('358', arvot, () => {
      const t9240 = 0 - summatService.laskeDebetMinusKredit('9240', tilikaudenSummat, affectedAccounts)
      const t3990 = 0 - summatService.laskeDebetMinusKredit('3990', tilikaudenSummat, affectedAccounts)
      return this._sum(t9240, t3990)
    })

    // Ostot ja varastonmuutokset
    // // 359 = 400 + 440
    const t400 = summatService.laskeDebetMinusKredit('400', tilikaudenSummat, affectedAccounts)
    const t440 = summatService.laskeDebetMinusKredit('440', tilikaudenSummat, affectedAccounts)
    const v359 = this._asetaNumero('359', arvot, () => this._sum(t400, t440))
    // 360 = 445
    const t445 = summatService.laskeDebetMinusKredit('445', tilikaudenSummat, affectedAccounts)
    const v360 = this._asetaNumero('360', arvot, () => t445)

    // Henkilöstökulut
    // 361 = 500 + 540
    const t500 = summatService.laskeDebetMinusKredit('500', tilikaudenSummat, affectedAccounts)
    const t540 = summatService.laskeDebetMinusKredit('540', tilikaudenSummat, affectedAccounts)
    const v361 = this._asetaNumero('361', arvot, () => this._sum(t500, t540))
    // 362 = 6100...6199
    const t6100to6199 = summatService.laskeDebetMinusKreditTilialueelta(6100, 6199, tilikaudenSummat, affectedAccounts)
    const v362 = this._asetaNumero('362', arvot, () => {
      return t6100to6199
    })
    // 363 = 600 - 6100...6199
    const v363 = this._asetaNumero('363', arvot, () => {
      const t600 = summatService.laskeDebetMinusKredit('600', tilikaudenSummat, affectedAccounts)
      return t600 - t6100to6199
    })

    // Poistot ja käyttöomaisuuden arvonalentumiset
    // 364 = vapaa kenttä (tili 6800...6889)
    const t6800to6889 = summatService.laskeDebetMinusKreditTilialueelta(6800, 6889, tilikaudenSummat, affectedAccounts)
    // const t68 = summatService.laskeDebetMinusKredit('68', tilikaudenSummat, affectedAccounts)
    const v364 = this._asetaNumero('364', arvot, () => t6800to6889)
    // 365 = vapaa kenttä(tili 68)
    const v365 = this._asetaNumero('365', arvot, () => t6800to6889)
    // 366 = vapaa kenttä
    const t6960 = summatService.laskeDebetMinusKredit('6960', tilikaudenSummat, affectedAccounts)
    const v366 = this._asetaNumero('366', arvot, () => t6960)
    // 367 = vapaa kenttä
    const v367 = this._asetaNumero('367', arvot, () => t6960)

    // Toiminnan muut kulut
    // 368 = 7990
    const t7990 = summatService.laskeDebetMinusKredit('7990', tilikaudenSummat, affectedAccounts)
    const v368 = this._asetaNumero('368', arvot, () => t7990)
    // 369 = 7990 / 2
    const v369 = this._asetaNumero('369', arvot, () => t7990 / 2)
    // 370 = 8280 + 8285
    const t8280 = summatService.laskeDebetMinusKredit('8280', tilikaudenSummat, affectedAccounts)
    const t8285 = summatService.laskeDebetMinusKredit('8285', tilikaudenSummat, affectedAccounts)
    // console.log('v370 = t8280 + t8285 (' + t8280 + ' + ' + t8285 + ')')
    const v370 = this._asetaNumero('370', arvot, () => this._sum(t8280, t8285))
    // 371 = 8285
    const v371 = this._asetaNumero('371', arvot, () => t8285)
    // 372 = vapaa kenttä(jos arvoa, näytä teksti: erittele omaverossa lomakkeella 71A ja / tai 71B)
    const v372 = this._asetaNumero('372', arvot)
    // 373 = vapaa kenttä(jos arvoa, näytä teksti: erittele omaverossa lomakkeella 71A ja / tai 71B)
    const v373 = this._asetaNumero('373', arvot)
    // 374 = 8850
    const t8850 = summatService.laskeDebetMinusKredit('8850', tilikaudenSummat, affectedAccounts)
    const v374 = this._asetaNumero('374', arvot, () => t8850)
    // 375 = 7560 + 7710
    const t7560 = summatService.laskeDebetMinusKredit('7560', tilikaudenSummat, affectedAccounts)
    const t7710 = summatService.laskeDebetMinusKredit('7710', tilikaudenSummat, affectedAccounts)
    const v375 = this._asetaNumero('375', arvot, () => this._sum(t7560, t7710))
    // 376 = täytettävä kenttä
    const v376 = this._asetaNumero('376', arvot)
    // 377 = 70 - 7560 - 7710 - 7990 - 8280 - 8285 - 8770 - 8780 - 8800 - 8850 - 8860
    const t70 = summatService.laskeDebetMinusKredit('70', tilikaudenSummat, affectedAccounts)
    const t8770 = summatService.laskeDebetMinusKredit('8770', tilikaudenSummat, affectedAccounts)
    const t8800 = summatService.laskeDebetMinusKredit('8800', tilikaudenSummat, affectedAccounts)
    const t8860 = summatService.laskeDebetMinusKredit('8860', tilikaudenSummat, affectedAccounts)
    const t8780 = summatService.laskeDebetMinusKredit('8780', tilikaudenSummat, affectedAccounts)
    // console.log(t70, '-', t7560, '-', t7710, '-', t7990, '-', t8280, '-', t8285, '-', t8770, '-', t8780, '-', t8800, '-', t8850, '-', t8860, '=', t70 - t7560 - t7710 - t7990 - t8280 - t8285 - t8770 - t8780 - t8800 - t8850 - t8860)
    const v377 = this._asetaNumero('377', arvot, () => t70 - t7560 - t7710 - t7990 - t8280 - t8285 - t8770 - t8780 - t8800 - t8850 - t8860)

    // Vähennyskelvottomat kulut
    // 378 = 99 - 9950(jos enemmän kredittiä)
    const t99 = summatService.laskeDebetMinusKredit('99', tilikaudenSummat, affectedAccounts)
    const t9950speciale = Math.max(0, summatService.laskeKreditMinusDebet('9950', tilikaudenSummat, affectedAccounts))
    // console.log('t99', t99, 't9950speciale', t9950speciale)
    const v378 = this._asetaNumero('378', arvot, () => t99 + t9950speciale)
    // const v378 = this._asetaNumero('378', arvot, () => t99)
    // 379 = 8770 + 9540
    const t9540 = summatService.laskeDebetMinusKredit('9540', tilikaudenSummat, affectedAccounts)
    const v379 = this._asetaNumero('379', arvot, () => this._sum(t8770, t9540))
    // 380 = 8780
    const v380 = this._asetaNumero('380', arvot, () => t8780)
    // 381 = täytettävä kenttä
    const v381 = this._asetaNumero('381', arvot)
    // 382 = täytettävä kenttä
    const v382 = this._asetaNumero('382', arvot)
    // 383 = täytettävä kenttä
    const v383 = this._asetaNumero('383', arvot)
    // 384 = 8800
    const v384 = this._asetaNumero('384', arvot, () => t8800)

    // Rahoituskulut
    // 328 = täytettävä kenttä
    const v328 = this._asetaNumero('328', arvot)
    // 386 = täytettävä kenttä
    const v386 = this._asetaNumero('386', arvot)
    // 387 = 9500
    const t9500 = summatService.laskeDebetMinusKredit('9500', tilikaudenSummat, affectedAccounts)
    const v387 = this._asetaNumero('387', arvot, () => t9500)
    // 398 = täytettävä kenttä
    const v398 = this._asetaNumero('398', arvot)
    // 399 = täytettävä kenttä
    const v399 = this._asetaNumero('399', arvot)
    // 388 = täytettävä kenttä
    const v388 = this._asetaNumero('388', arvot)
    // 389 = täytettävä kenttä
    const v389 = this._asetaNumero('389', arvot)
    // 390 = täytettävä kenttä
    const v390 = this._asetaNumero('390', arvot)
    // 391 = täytettävä kenttä
    const v391 = this._asetaNumero('391', arvot)
    // 392 = 9600
    const t9600 = summatService.laskeDebetMinusKredit('9600', tilikaudenSummat, affectedAccounts)
    const v392 = this._asetaNumero('392', arvot, () => t9600)

    // Muut kulut
    // 327 = täytettävä kenttä
    const v327 = this._asetaNumero('327', arvot)
    // 436 = täytettävä kenttä
    const v436 = this._asetaNumero('436', arvot)
    //   (jos arvoa, näytä teksti: erittele omaverossa lomakkeella 65)
    // 394 = täytettävä kenttä
    const t9870 = summatService.laskeDebetMinusKredit('9870', tilikaudenSummat, affectedAccounts)
    const v394 = this._asetaNumero('394', arvot, () => t9870)
    // 395 = täytettävä kenttä
    const v395 = this._asetaNumero('395', arvot, () => t9870)
    // 270 = 8860
    const v270 = this._asetaNumero('270', arvot, () => t8860)
    //   (jos arvoa, näytä teksti: erittele omaverossa lomakkeella 71B)
    // 271 = täytettävä kenttä
    const v271 = this._asetaNumero('271', arvot, () => t8860)
    // 272 = täytettävä kenttä
    const v272 = this._asetaNumero('272', arvot)
    // 273 = täytettävä kenttä
    const v273 = this._asetaNumero('273', arvot)
    // 396 = täytettävä kenttä
    const v396 = this._asetaNumero('396', arvot)
    // 504 = täytettävä kenttä
    const v504 = this._asetaNumero('504', arvot)

    const v397 = this._asetaNumero('397', arvot, () => this._sum(v359, v360, v361, v362, v363, v365, v367, v369, v371, v373, v374, v375, v376, v377, v328, v386, v387, v398, v399, v390, v391, v392, v327, v436, v395, v271, v273, v396))

    // Elinekeinotoiminnan tulos
    // 500 = 354 - 397(jos positiivinen)
    // 505 = 354 - 397(jos negatiivinen)
    const tulos = v354 - v397
    oletukset['elinkeinotoiminnan-tulos'] = tulos
    if (tulos > 0) {
      arvot.push({ key: '500', value: this._annaLuku(Math.abs(tulos)) })
    } else if (tulos < 0) {
      arvot.push({ key: '505', value: this._annaLuku(Math.abs(tulos)) })
    } else {
      arvot.push({ key: '500', value: this._annaLuku(0) })
      arvot.push({ key: '505', value: this._annaLuku(0) })
    }

    // Käyttöomaisuus
    // 400 = 1030
    const t1030 = summatService.laskeDebetMinusKredit('1030', tilikaudenSummat, affectedAccounts)
    const v400 = this._asetaNumero('400', arvot, () => t1030)
    // 401 = 100 - 1030 - 1090
    const t100 = summatService.laskeDebetMinusKredit('100', tilikaudenSummat, affectedAccounts)
    const t1090 = summatService.laskeDebetMinusKredit('1090', tilikaudenSummat, affectedAccounts)
    const v401 = this._asetaNumero('401', arvot, () => t100 - t1030 - t1090)
    // 402 = 1110 + 1120
    const t1110 = summatService.laskeDebetMinusKredit('1110', tilikaudenSummat, affectedAccounts)
    const t1120 = summatService.laskeDebetMinusKredit('1120', tilikaudenSummat, affectedAccounts)
    const v402 = this._asetaNumero('402', arvot, () => this._sum(t1110, t1120))
    // 403 = 116
    const t116 = summatService.laskeDebetMinusKredit('116', tilikaudenSummat, affectedAccounts)
    const v403 = this._asetaNumero('403', arvot, () => t116)
    // 404 = 1380 + 1090
    const t1380 = summatService.laskeDebetMinusKredit('1380', tilikaudenSummat, affectedAccounts)
    const v404 = this._asetaNumero('404', arvot, () => this._sum(t1380, t1090))
    // 405 = 1440 + 1400 + 1420
    const t1400 = summatService.laskeDebetMinusKredit('1400', tilikaudenSummat, affectedAccounts)
    const t1420 = summatService.laskeDebetMinusKredit('1420', tilikaudenSummat, affectedAccounts)
    const t1440 = summatService.laskeDebetMinusKredit('1440', tilikaudenSummat, affectedAccounts)
    const v405 = this._asetaNumero('405', arvot, () => this._sum(t1400, t1420, t1440))
    // 406 = täytettävä kenttä
    const v406 = this._asetaNumero('406', arvot)
    // 407 = täytettävä kenttä
    const v407 = this._asetaNumero('407', arvot)
    // 408 = täytettävä kenttä
    const v408 = this._asetaNumero('408', arvot)
    // 409 = täytettävä kenttä
    const t1300 = summatService.laskeDebetMinusKredit('1300', tilikaudenSummat, affectedAccounts)
    const v409 = this._asetaNumero('409', arvot, () => t1300)

    const v410 = this._asetaNumero('410', arvot, () => this._sum(v400, v401, v402, v403, v404, v405, v406, v407, v408, v409))

    // Vaihto - omaisuus
    // 411 = 1500
    const t1500 = summatService.laskeDebetMinusKredit('1500', tilikaudenSummat, affectedAccounts)
    const v411 = this._asetaNumero('411', arvot, () => t1500)
    // 412 = 1510
    const t1510 = summatService.laskeDebetMinusKredit('1510', tilikaudenSummat, affectedAccounts)
    const v412 = this._asetaNumero('412', arvot, () => t1510)
    // 413 = 1520
    const t1520 = summatService.laskeDebetMinusKredit('1520', tilikaudenSummat, affectedAccounts)
    const v413 = this._asetaNumero('413', arvot, () => t1520)
    // 414 = 1530 + 1553
    const t1530 = summatService.laskeDebetMinusKredit('1530', tilikaudenSummat, affectedAccounts)
    const t1553 = summatService.laskeDebetMinusKredit('1553', tilikaudenSummat, affectedAccounts)
    const v414 = this._asetaNumero('414', arvot, () => this._sum(t1530, t1553))
    // 415 = 1541
    const t1541 = summatService.laskeDebetMinusKredit('1541', tilikaudenSummat, affectedAccounts)
    const v415 = this._asetaNumero('415', arvot, () => t1541)
    // 416 = 1543
    const t1543 = summatService.laskeDebetMinusKredit('1543', tilikaudenSummat, affectedAccounts)
    const v416 = this._asetaNumero('416', arvot, () => t1543)
    // 417 = 1540 + 1550
    const t1540 = summatService.laskeDebetMinusKredit('1540', tilikaudenSummat, affectedAccounts)
    const t1550 = summatService.laskeDebetMinusKredit('1550', tilikaudenSummat, affectedAccounts)
    const v417 = this._asetaNumero('417', arvot, () => this._sum(t1540, t1550))
    // 418 = 411 + 412 + 413 + 414 + 415 + 416 + 417
    const v418 = this._asetaNumero('418', arvot, () => this._sum(v411, v412, v413, v414, v415, v416, v417))

    // Rahoitusomaisuus
    // 419 = 1600 + 171
    const t1600 = summatService.laskeDebetMinusKredit('1600', tilikaudenSummat, affectedAccounts)
    const t171 = summatService.laskeDebetMinusKredit('171', tilikaudenSummat, affectedAccounts)
    const v419 = this._asetaNumero('419', arvot, () => this._sum(t171, t1600))
    // 420 = täytettävä kenttä
    const v420 = this._asetaNumero('420', arvot)
    // 421 = täytettävä kenttä
    const v421 = this._asetaNumero('421', arvot)
    // 422 = 175 + 1650 - 1755
    const t1650 = summatService.laskeDebetMinusKredit('1650', tilikaudenSummat, affectedAccounts)
    const t175 = summatService.laskeDebetMinusKredit('175', tilikaudenSummat, affectedAccounts)
    const t1755 = summatService.laskeDebetMinusKredit('1755', tilikaudenSummat, affectedAccounts)
    const v422 = this._asetaNumero('422', arvot, () => this._sum(t175, t1650) - t1755)
    // 423 = 176 + 1660 + 1667
    const t1660 = summatService.laskeDebetMinusKredit('1660', tilikaudenSummat, affectedAccounts)
    const t1667 = summatService.laskeDebetMinusKredit('1667', tilikaudenSummat, affectedAccounts)
    const t176 = summatService.laskeDebetMinusKredit('176', tilikaudenSummat, affectedAccounts)
    const v423 = this._asetaNumero('423', arvot, () => this._sum(t176, t1660, t1667))
    // 424 = 1860 + 1890
    const t1860 = summatService.laskeDebetMinusKredit('1860', tilikaudenSummat, affectedAccounts)
    const t1890 = summatService.laskeDebetMinusKredit('1890', tilikaudenSummat, affectedAccounts)
    const v424 = this._asetaNumero('424', arvot, () => this._sum(t1860, t1890))
    // 425 = 1680 + 180
    const t1680 = summatService.laskeDebetMinusKredit('1680', tilikaudenSummat, affectedAccounts)
    const t180 = summatService.laskeDebetMinusKredit('180', tilikaudenSummat, affectedAccounts)
    const v425 = this._asetaNumero('425', arvot, () => this._sum(t1680, t180))
    // 463 = täytettävä kenttä
    const v463 = this._asetaNumero('463', arvot)
    // 426 = 1900...1909
    const t1900to1909 = summatService.laskeDebetMinusKreditTilialueelta(1900, 1909, tilikaudenSummat, affectedAccounts)
    const v426 = this._asetaNumero('426', arvot, () => t1900to1909)
    // 427 = 1910...1999
    const t1910to1999 = summatService.laskeDebetMinusKreditTilialueelta(1910, 1999, tilikaudenSummat, affectedAccounts)
    const v427 = this._asetaNumero('427', arvot, () => t1910to1999)
    // 428 = täytettävä kenttä
    const v428 = this._asetaNumero('428', arvot)
    // 429 = 419 + 420 + 421 + 422 + 423 + 424 + 425 + 426 + 427 + 428 + v463
    const v429 = this._asetaNumero('429', arvot, () => this._sum(v419, v420, v421, v422, v423, v424, v425, v426, v427, v428, v463))

    // Muu omaisuus
    // 430 = 1450
    const t1450 = summatService.laskeDebetMinusKredit('1450', tilikaudenSummat, affectedAccounts)
    const v430 = this._asetaNumero('430', arvot, () => t1450)
    // 431 = täytettävä kenttä
    const v431 = this._asetaNumero('431', arvot)
    // 432 = 1755
    const v432 = this._asetaNumero('432', arvot, () => t1755)
    // 437 = 1630 + 173
    // const t1630 = summatService.laskeDebetMinusKredit('1630', tilikaudenSummat, affectedAccounts)
    // const t173 = summatService.laskeDebetMinusKredit('173', tilikaudenSummat, affectedAccounts)
    // , () => this._sum(t1630, t173)
    const v437 = this._asetaNumero('437', arvot)
    // 438 = täytettävä kenttä
    const v438 = this._asetaNumero('438', arvot)
    // 433 = täytettävä kenttä
    const v433 = this._asetaNumero('433', arvot)
    // 434 = 430 + 431 + 432 + 437 + 438 + 433
    const v434 = this._asetaNumero('434', arvot, () => this._sum(v430, v431, v432, v437, v438, v433))
    // VARAT YHTEENSÄ
    // 435 = 410 + 418 + 429 + 434
    const v435 = this._asetaNumero('435', arvot, () => this._sum(v410, v418, v429, v434))

    // Velat
    // 450 = täytettävä kenttä
    const v450 = this._asetaNumero('450', arvot)
    // 451 = täytettävä kenttä
    const v451 = this._asetaNumero('451', arvot)
    // 452 = 262 + 282
    const t262 = 0 - summatService.laskeDebetMinusKredit('262', tilikaudenSummat, affectedAccounts)
    const t282 = 0 - summatService.laskeDebetMinusKredit('282', tilikaudenSummat, affectedAccounts)
    const v452 = this._asetaNumero('452', arvot, () => this._sum(t262, t282))
    // 453 = 267 + 287
    const t267 = 0 - summatService.laskeDebetMinusKredit('267', tilikaudenSummat, affectedAccounts)
    const t287 = 0 - summatService.laskeDebetMinusKredit('287', tilikaudenSummat, affectedAccounts)
    const v453 = this._asetaNumero('453', arvot, () => this._sum(t267, t287))
    // 454 = 270 + 290
    const t270 = 0 - summatService.laskeDebetMinusKredit('270', tilikaudenSummat, affectedAccounts)
    const t290 = 0 - summatService.laskeDebetMinusKredit('290', tilikaudenSummat, affectedAccounts)
    const v454 = this._asetaNumero('454', arvot, () => this._sum(t270, t290))
    // 455 = 271 + 291
    const t271 = 0 - summatService.laskeDebetMinusKredit('271', tilikaudenSummat, affectedAccounts)
    const t291 = 0 - summatService.laskeDebetMinusKredit('291', tilikaudenSummat, affectedAccounts)
    const v455 = this._asetaNumero('455', arvot, () => this._sum(t271, t291))
    // 456 = 2940...2945
    const t2940to2945 = 0 - summatService.laskeDebetMinusKreditTilialueelta(2940, 2945, tilikaudenSummat, affectedAccounts)
    const v456 = this._asetaNumero('456', arvot, () => t2940to2945)
    // 457 = 276 + 295
    const t276 = 0 - summatService.laskeDebetMinusKredit('276', tilikaudenSummat, affectedAccounts)
    const t295 = 0 - summatService.laskeDebetMinusKredit('295', tilikaudenSummat, affectedAccounts)
    const v457 = this._asetaNumero('457', arvot, () => this._sum(t276, t295))
    // 464 = 264
    const t264 = 0 - summatService.laskeDebetMinusKredit('264', tilikaudenSummat, affectedAccounts)
    const v464 = this._asetaNumero('464', arvot, () => t264)
    // 465 = 286
    const t286 = 0 - summatService.laskeDebetMinusKredit('286', tilikaudenSummat, affectedAccounts)
    const v465 = this._asetaNumero('465', arvot, () => t286)
    // 458 = 275 + 292 - 2940...2945
    const t275 = 0 - summatService.laskeDebetMinusKredit('275', tilikaudenSummat, affectedAccounts)
    const t292 = 0 - summatService.laskeDebetMinusKredit('292', tilikaudenSummat, affectedAccounts)
    const v458 = this._asetaNumero('458', arvot, () => this._sum(t275, t292) - t2940to2945)
    // 459 = 261 + 281 + 238
    const t261 = 0 - summatService.laskeDebetMinusKredit('261', tilikaudenSummat, affectedAccounts)
    const t281 = 0 - summatService.laskeDebetMinusKredit('281', tilikaudenSummat, affectedAccounts)
    const t238 = 0 - summatService.laskeDebetMinusKredit('238', tilikaudenSummat, affectedAccounts)
    const v459 = this._asetaNumero('459', arvot, () => this._sum(t261, t281, t238))
    // VELAT YHTEENSÄ
    // 460 = 450 + 451 + 452 + 453 + 454 + 455 + 456 + 457 + 464 + 465 + 458 + 459
    const v460 = this._asetaNumero('460', arvot, () => this._sum(v450, v451, v452, v453, v454, v455, v456, v457, v464, v465, v458, v459))
    // 461 = 280
    const t280 = 0 - summatService.laskeDebetMinusKredit('280', tilikaudenSummat, affectedAccounts, undefined, ['2830'])
    const v461 = this._asetaNumero('461', arvot, () => t280)
    // 462 = 260
    const t260 = 0 - summatService.laskeDebetMinusKredit('260', tilikaudenSummat, affectedAccounts, undefined, ['2630'])
    const v462 = this._asetaNumero('462', arvot, () => t260)


    // 573 = 435 - 460(jos positiivinen)
    // 574 = 435 - 460(jos negatiivinen)
    const nettovarallisuus = v435 - v460
    oletukset['nettovarallisuus'] = nettovarallisuus
    if (nettovarallisuus < 0) {
      arvot.push({ key: '574', value: this._annaLuku(Math.abs(nettovarallisuus)) })
    } else if (nettovarallisuus > 0) {
      arvot.push({ key: '573', value: this._annaLuku(Math.abs(nettovarallisuus)) })
    } else {
      arvot.push({ key: '574', value: this._annaLuku(0) })
      arvot.push({ key: '573', value: this._annaLuku(0) })
    }

    // Oma pääoma
    // Sidottu oma pääoma
    // 470 = 200
    const t200 = 0 - summatService.laskeDebetMinusKredit('200', tilikaudenSummat, affectedAccounts)
    const v470 = this._asetaNumero('470', arvot, () => t200)
    // 495= 210 + 215
    const t210 = 0 - summatService.laskeDebetMinusKredit('210', tilikaudenSummat, affectedAccounts)
    const t215 = 0 - summatService.laskeDebetMinusKredit('215', tilikaudenSummat, affectedAccounts)
    const v495 = this._asetaNumero('495', arvot, () => this._sum(t210, t215))
    // Vapaa oma pääoma
    // 496 = 2060
    const t2060 = 0 - summatService.laskeDebetMinusKredit('2060', tilikaudenSummat, affectedAccounts)
    const v496 = this._asetaNumero('496', arvot, () => t2060)
    // 497 = 220 - 2060
    const t220 = 0 - summatService.laskeDebetMinusKredit('220', tilikaudenSummat, affectedAccounts)
    const v497 = this._asetaNumero('497', arvot, () => t220 - t2060)
    // 474 = 225(jos kredit)
    // 475 = 225(jos debet)
    const t225 = summatService.laskeDebetMinusKredit('225', tilikaudenSummat, affectedAccounts)
    let v474 = 0
    let v475 = 0
    if (t225 < 0) {
      v474 = this._asetaNumero('474', arvot, () => Math.abs(t225))
    } else if (t225 > 0) {
      v475 = this._asetaNumero('475', arvot, () => Math.abs(t225))
    } else {
      v474 = this._asetaNumero('474', arvot, () => Math.abs(0))
      v475 = this._asetaNumero('475', arvot, () => Math.abs(0))
    }

    // 476 = Kirjanpidon voitto
    // 477 = Kirjanpidon tappio
    const t3 = summatService.laskeDebetMinusKredit('3', tilikaudenSummat, ignoredAccounts)
    let v476 = 0
    let v477 = 0
    if (t3 < 0) {
      v476 = this._asetaNumero('476', arvot, () => Math.abs(t3))
    } else if (t3 > 0) {
      v477 = this._asetaNumero('477', arvot, () => Math.abs(t3))
    } else {
      v476 = this._asetaNumero('476', arvot, () => Math.abs(0))
      v477 = this._asetaNumero('477', arvot, () => Math.abs(0))
    }

    // OMA PÄÄOMA YHTEENSÄ
    // 478 = 20 + 2370
    const t20 = 0 - summatService.laskeDebetMinusKredit('20', tilikaudenSummat, affectedAccounts)
    const t2370 = 0 - t3
    console.log('t20', t20, 't2370', t2370)


    // 478 = 470 + 495 + 496 + 497 + 474 - 475 + 476 - 477
    const v478 = this._asetaNumero('478', arvot, () => this._currencyService.roundHalfUp(v470 + v495 + v496 + v497 + v474 - v475 + v476 - v477, 2))

    const v177 = this._asetaArvo('177', arvot, () => '3')
    if (v177 === '1') {
      const v178 = this._asetaArvo('178', arvot, () => '2')
    }

    const v531 = this._asetaArvo('531', arvot)

    arvot.push({ key: '999', value: '1' })

    // const formattedDateAndTimeStr = this._dateService.muotoileVeroFormaattiPaivaJaAikaDate(this._dateService.timestampToDate(muodostettu))

    const arvot2: IlmoitusRivi[] = []
    arvot2.push({ key: '000', value: 'VSY06221' }) // File identifier
    arvot2.push({ key: '198', value: formattedDateAndTimeStr }) // Software-generated timestamp
    arvot2.push({ key: '048', value: 'Tilitoimisto Lemon Tree Oy' }) // ID of the software that produced the file
    arvot2.push({ key: '014', value: '2599105-8_LT' }) // ID of the software that produced the file (y-tunnus + 2 alphanums)
    arvot2.push({ key: '010', value: asiakas.ytunnus }) // Verovelvollisen y- tai henkilötunnus
    arvot2.push({ key: '054', value: tilikausiFormatted }) // 054 = tilikausi, jolta veroilmoitusta ollaan antamassa.

    // 800 = vapaa kenttä(tilin 116 edellisen tilikauden loppusaldo)
    const v800 = this._asetaNumero('800', arvot2, () => summatService.laskeDebetMinusKredit('116', edellisenTilikaudenSummat, affectedAccounts))

    // 801 = vapaa kenttä(tilin 116 debet yhteensä tilikauden aikana)
    const v801 = this._asetaNumero('801', arvot2, () => {
      const v801EdellisenVuodenLoppusaldo = summatService.laskeDebet('116', edellisenTilikaudenSummat, affectedAccounts)
      const v801NykyisenVuodenLoppusaldo = summatService.laskeDebet('116', tilikaudenSummat, affectedAccounts)
      return v801NykyisenVuodenLoppusaldo - v801EdellisenVuodenLoppusaldo
    })

    // 803 = vapaa kenttä
    const v803 = this._asetaNumero('803', arvot2)
    // 804 = vapaa kenttä(tili 68)
    const v804 = this._asetaNumero('804', arvot2, () => t6800to6889)

    // 802 = vapaa kenttä(tilin 116 kredit yhteensä tilikauden aikana)
    const v802 = this._asetaNumero('802', arvot2, () => {
      const v802EdellisenVuodenLoppusaldo = summatService.laskeKredit('116', edellisenTilikaudenSummat, affectedAccounts)
      const v802NykyisenVuodenLoppusaldo = summatService.laskeKredit('116', tilikaudenSummat, affectedAccounts)
      return v802NykyisenVuodenLoppusaldo - v802EdellisenVuodenLoppusaldo - (v804 ?? 0)
    })
    // 805 = vapaa kenttä(ei ehdotusta)
    const v805 = this._asetaNumero('805', arvot2)
    // 806 = vapaa kenttä(tilin 116 tämän tilikauden loppusaldo)
    const v806 = this._asetaNumero('806', arvot2, () => summatService.laskeDebetMinusKredit('116', tilikaudenSummat, affectedAccounts))
    // 807 = vapaa kenttä(tili 68)
    const v807 = this._asetaNumero('807', arvot2, () => t6800to6889)
    // 847 = vapaa kenttä
    const v847 = this._asetaNumero('847', arvot2)
    // 848 = vapaa kenttä
    const v848 = this._asetaNumero('848', arvot2)
    // 849 = vapaa kenttä
    const v849 = this._asetaNumero('849', arvot2)

    arvot2.push({ key: '999', value: '2' })

    this.tietokannastaLaskennoille = null
    this.eiTallennetutLaskennoille = null
    this.oletuksetLaskennoille = null

    const erottavatTilitFilterSet = summatService.annaTilinJaLastenNumerot('237')
    const erottavatTilit = summatService.annaTilitJoissaOnErottavaSumma(affectedAccounts, tilikaudenSummat, (tili): boolean => {
      return !erottavatTilitFilterSet.has(tili)
    })

    const ret: VeroilmoituksenPaivityksenTulos = {
      arvot: arvot.concat(arvot2),
      oletukset: oletukset,
      erottavatTilit: erottavatTilit,
      virheet: null
    }

    const virheet: VeroilmoitusValidationError[] = this._validoi(arvot).concat(this._validoi62(arvot2))
    if (virheet.length) {
      ret.virheet = virheet
    }

    return ret

  }

  private _setOptionalLuku(numero: string, arvo: number, arvot: IlmoitusRivi[]) {
    if (arvo !== undefined && arvo !== null) {
      arvot.push({ key: numero, value: this._annaLuku(arvo) })
    }
  }

  private _validoi62(rivit: IlmoitusRivi[]): VeroilmoitusValidationError[] {
    const errors: VeroilmoitusValidationError[] = []

    const maps = this._mapValues(rivit)
    const numberMap = maps.numberMap
    const stringMap = maps.stringMap

    /**
     * Virhe tunnuksessa/positioissa 804 (450,00)
     * Säännönmukainen poisto (EVL) (804) saa olla enintään 25% seuraavan laskukaavan lopputuloksesta:
     *    Irtaimen käyttöomaisuuden verotuksessa jäljellä oleva menojäännös verovuoden alussa (Evl 30 ja 31§) (800) +
     *    Lisäykset (801) -
     *    Luovutushinnat ja vakuutuskorvaukset (802) -
     *    Katettu jälleenhankintavarauksella (803)
     */
    const v804 = this._sumFromMap(numberMap, '804')
    const kentan804MaksimitulosValitulos = (this._sumFromMap(numberMap, '800', '801') - this._sumFromMap(numberMap, '802', '803')) * 0.25
    const kentan804Maksimitulos = this._currencyService.roundHalfUp(kentan804MaksimitulosValitulos, 2)
    // console.log('v804', v804, 'kentan804Maksimitulos', kentan804Maksimitulos)
    if (v804 > kentan804Maksimitulos) {
      errors.push({
        fields: '804',
        message: `Säännönmukainen poisto (EVL) (804) saa olla enintään 25% seuraavan laskukaavan lopputuloksesta:
\tIrtaimen käyttöomaisuuden verotuksessa jäljellä oleva menojäännös verovuoden alussa(Evl 30 ja 31§) (800) +
\tLisäykset(801) -
\tLuovutushinnat ja vakuutuskorvaukset(802) -
\tKatettu jälleenhankintavarauksella(803)`
      })
    }

    /*
        808
        809
        810
        811
        812
    #1272; Säännönmukainen poisto (7 %) (812) saa olla enintään 7 % seuraavan laskukaavan lopputuloksesta: Rakennusten poistamaton hankintameno vero - vuoden alussa(evl 34.2 § 1k)(808) + Lisäykset(809) - Luovutetun hyödykkeen verotuksessa poistamaton hankintameno(810) - Katettu jälleenhankintavarauk - sella(811)
    */
    const v812 = this._sumFromMap(numberMap, '812')
    const kentan812MaksimitulosValitulos = (this._sumFromMap(numberMap, '808', '809') - this._sumFromMap(numberMap, '810', '811')) * 0.07
    const kentan812Maksimitulos = this._currencyService.roundHalfUp(kentan812MaksimitulosValitulos, 2)
    // console.log('v812', v812, 'kentan812Maksimitulos', kentan812Maksimitulos)
    if (v812 > kentan812Maksimitulos) {
      errors.push({
        fields: '812',
        message: `Säännönmukainen poisto (7 %) (812) saa olla enintään 7 % seuraavan laskukaavan lopputuloksesta:
\tRakennusten poistamaton hankintameno verovuoden alussa (evl 34.2 § 1k) (808) +
\tLisäykset (809) -
\tLuovutetun hyödykkeen verotuksessa poistamaton hankintameno (810) -
\tKatettu jälleenhankintavarauksella (811)`
      })
    }

    /*
        816
        817
        818
        819
        820
    #1273; Säännönmukainen poisto(4 %)(820) saa olla enintään 4 % seuraavan laskukaavan lopputuloksesta: Rakennusten poistamaton hankintameno vero - vuoden alussa(Evl 34.2 § 2k)(816) + Lisäykset(817) - Luovutetun hyödykkeen verotuksessa poistamaton hankintameno(818) - Katettu jälleenhankintavarauk - sella(819)
    */
    const v820 = this._sumFromMap(numberMap, '820')
    const kentan820MaksimitulosValitulos = (this._sumFromMap(numberMap, '816', '817') - this._sumFromMap(numberMap, '818', '819')) * 0.04
    const kentan820Maksimitulos = this._currencyService.roundHalfUp(kentan820MaksimitulosValitulos, 2)
    // console.log('v820', v820, 'kentan812Maksimitulos', kentan820Maksimitulos)
    if (v820 > kentan820Maksimitulos) {
      errors.push({
        fields: '820',
        message: `Säännönmukainen poisto (4 %) (820) saa olla enintään 4 % seuraavan laskukaavan lopputuloksesta:
\tRakennusten poistamaton hankintameno verovuoden alussa (Evl 34.2 § 2k) (816) +
\tLisäykset (817) -
\tLuovutetun hyödykkeen verotuksessa poistamaton hankintameno (818) -
\tKatettu jälleenhankintavarauksella (819)`
      })
    }

    return errors
  }

  private _validoi(rivit: IlmoitusRivi[]): VeroilmoitusValidationError[] {

    const errors: VeroilmoitusValidationError[] = []
    const maps = this._mapValues(rivit)
    const numberMap = maps.numberMap
    const stringMap = maps.stringMap

    const osakkaat = this.groupIlmoitusrivitByOsakkaat(rivit)
    const huoneistot = this.groupIlmoitusrivitByOsakkaidenHuoneistot(rivit)
    const osingot = this.groupIlmoitusrivitByOsingot(rivit)
    const vapaastaOmastaPaaomastaJaetut = this.groupIlmoitusrivitByVapaastaPaaomastaJaettujen(rivit)

    //     531 #1602; Verovuosi, jolloin vaihto on tapahtunut(vvvv)(531) on oltava väliltä 2011 - 2021
    const vaihtoverovuosi = numberMap.get('531')
    if (vaihtoverovuosi && (vaihtoverovuosi < 2010 || vaihtoverovuosi > 2020)) {
      errors.push({
        fields: '531',
        message: 'Verovuosi, jolloin vaihto on tapahtunut (vvvv) (531) on oltava väliltä 2011 - 2021'
      })
    }

    //     565 580
    // #1209; Jos tieto Korotus: maksullinen = 2, maksuton = 3(565) on ilmoitettu, myös tieto Osakkeiden lukumäärä muutoksen jälkeen(580) pitää antaa.
    // *
    //       Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia
    const v565 = stringMap.get('565')
    const v580 = numberMap.get('580')
    if (v565 === '2' || v565 === '3') {
      if (v580 === undefined || v580 === null) {
        errors.push({
          fields: '565, 580',
          message: 'Merkitse verovuosi, jolloin vaihto on tapahtunut, jos yli puolet osakkeista tai osuuksista on vaihtanut omistajaa verovuoden aikana. Jos vaihtuminen on tapahtunut vaiheittain usean vuoden aikana, merkitse se verovuosi, jota ennen tai jonka aikana syntyneet tappiot eivät omistajanvaihdosten vuoksi ole vähennyskelpoisia'
        })
      }
    }

    //     573#574
    //     500#505
    //     474#475
    //     476#477
    //     Tarkistetaan, että vain toisessa seuraavista kentistä on nollasta tai tyhjästä poikkeava arvo. Toisin sanoen ne ovat poissulkevia tunnuksia (vain toisessa tunnuksessa saa olla nollasta tai tyhjästä poikkeava arvo):
    //     573 tai 574
    //     500 tai 505
    //     474 tai 475
    //     476 tai 477
    //     #94; Kentän # poissulkeva kenttä & sisältää jo arvon. Vain jommankumman arvoista voi ilmoittaa
    //     500 - 505 573 - 574
    this.validateVainJompikumpiKentista('573', '574', errors, numberMap)
    this.validateVainJompikumpiKentista('500', '505', errors, numberMap)
    this.validateVainJompikumpiKentista('474', '475', errors, numberMap)
    this.validateVainJompikumpiKentista('476', '477', errors, numberMap)

    // #1210; Joko 500 tai 505 pitää antaa. Toisen tai molemmat voi antaa myös nollana.
    this.validateJompikumpiIlmoitettuTaiAinakinToinenNolla('500', '505', errors, numberMap)

    // #1211; Joko 573 tai 574 pitää antaa. Toisen tai molemmat voi antaa myös nollana.
    this.validateJompikumpiIlmoitettuTaiAinakinToinenNolla('573', '574', errors, numberMap)

    for (const osinko of osingot) {

      const v537 = this._etsiArvo('537', osinko) || null
      const v538 = this._etsiArvo('538', osinko) || null

      // #380; Kun "Jaettavaksi päätetty osinko" (522) on suurempi kuin nolla, niin "Osingonjakopäätös tehty ppkkvvvv" (537) ja Osinko nostettavissa pvm(538) pitää ilmoittaa.
      const v522 = this._etsiNumero('522', osinko) ?? 0
      if (v522 > 0) {
        if (!v537 || !v538) {
          errors.push({
            fields: '537, 538',
            message: 'Kun "Jaettavaksi päätetty osinko" (522) on suurempi kuin nolla, niin "Osingonjakopäätös tehty ppkkvvvv" (537) ja Osinko nostettavissa pvm (538) pitää ilmoittaa.'
          })
        }
      }

      if (v537) {

        // #1606 Kentän 537(osingonjakopäätöksen pvm) vuoden oltava verovuonna 2021 väliltä 2021 - 2023.
        const osingonjakoPaatoksenPvm = this._dateService.parsiVeroFormaattiPaiva(v537)
        if (osingonjakoPaatoksenPvm.year < 2021 || osingonjakoPaatoksenPvm.year > 2023) {
          errors.push({
            fields: '537',
            message: 'Osingonjakopäätöspäivämäärän vuoden on oltava väliltä 2021 - 2023'
          })
        }

        // #1386; Kun "Osingonjakopäätös tehty ppkkvvvv" (537) on ilmoitettu, niin "Jaettavaksi päätetty osinko" (522) pitää olla suurempi kuin nolla ja Osinko nostettavissa pvm (538) pitää antaa.
        if (v522 < 0.01 || !v538) {
          errors.push({
            fields: '537, 538',
            message: 'Kun "Osingonjakopäätös tehty ppkkvvvv" (537) on ilmoitettu, niin "Jaettavaksi päätetty osinko" (522) pitää olla suurempi kuin nolla ja Osinko nostettavissa pvm (538) pitää antaa.'
          })
        }

      }

      if (v538) {
        // #1607 Kentän 538(osinko nostettavissa pvm) vuoden oltava verovuonna 2021 väliltä 2021 - 2026
        const osinkoNostettavissaPvm = this._dateService.parsiVeroFormaattiPaiva(v538)
        if (osinkoNostettavissaPvm.year < 2021 || osinkoNostettavissaPvm.year > 2026) {
          errors.push({
            fields: '538',
            message: '"Osinko nostettavissa" päivämäärän vuoden on oltava väliltä 2021 - 2026'
          })
        }
      }

    }

    for (const vapaastaOmastaPaaomastaJaettu of vapaastaOmastaPaaomastaJaetut) {

      const v294 = this._etsiArvo('294', vapaastaOmastaPaaomastaJaettu) || null
      const v295 = this._etsiArvo('295', vapaastaOmastaPaaomastaJaettu) || null
      const v293 = this._etsiNumero('293', vapaastaOmastaPaaomastaJaettu) ?? 0

      if (v294) {

        // #1612; Kentän 294 (Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä) vuoden oltava vero vuonna 2021 väliltä 2021 - 2023.
        const vopJakoPaatoksenPvm = this._dateService.parsiVeroFormaattiPaiva(v294)
        if (vopJakoPaatoksenPvm.year < 2021 || vopJakoPaatoksenPvm.year > 2023) {
          errors.push({
            fields: '294',
            message: 'Kentän 294 (Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä) vuoden oltava vero vuonna 2021 väliltä 2021 - 2023.'
          })
        }

        // #1389; Jos "Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä" (294) on ilmoitettu, pitää "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) olla suurempi kuin nolla ja Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä (295) ilmoittaa. "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) ei voi olla nolla.
        if (v293 < 0.01 || !v295) {
          errors.push({
            fields: '293, 295',
            message: 'Jos "Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä" (294) on ilmoitettu, pitää "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) olla suurempi kuin nolla ja Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä (295) ilmoittaa. "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) ei voi olla nolla.'
          })
        }

      }

      if (v295) {
        // #1608; Kentän 295 (Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä) vuoden oltava verovuonna 2021 väliltä 2021 - 2026.
        const vopJakoPaatoksenPvm = this._dateService.parsiVeroFormaattiPaiva(v295)
        if (vopJakoPaatoksenPvm.year < 2021 || vopJakoPaatoksenPvm.year > 2026) {
          errors.push({
            fields: '295',
            message: 'Kentän 295 (Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä) vuoden oltava verovuonna 2021 väliltä 2021 - 2026.'
          })
        }
      }

      // #1388; Jos "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) on suurempi kuin nolla, pitää "Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä" (294) ja Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä (295) ilmoittaa.
      if (v293 > 0) {
        if (!v294 || !v295) {
          errors.push({
            fields: '294, 295',
            message: 'Jos "Vapaan oman pääoman rahastosta jaettavaksi päätetty määrä" (293) on suurempi kuin nolla, pitää "Vapaan oman pääoman rahastosta tehty varojen jakopäätöksen päivämäärä" (294) ja Vapaan oman pääoman rahastosta tehty varojen jako nostettavissa, päivämäärä (295) ilmoittaa.'
          })
        }
      }
    }

    let ainakinYksiAsetettu = false
    for (const osakas of osakkaat) {
      const v701 = this._etsiArvo('701', osakas)
      const v703 = this._etsiArvo('703', osakas)
      const v570 = this._etsiKokonaisluku('570', osakas)

      if (v703) {
        this._tarkistaHetuTaiYTunnus('703', v703, errors)
      }

      if (v701 && v703 && (v570 || v570 === 0)) {
        ainakinYksiAsetettu = true
      } else {
        // #330; Jos on annettu jokin kohdista "Osakkeiden lukumäärä" (570), "Osakkeenomistajan nimi" (701) tai "Henkilö- tai Y-tunnus" (703), täytyy ilmoittaa kaikki kolme tietoa. Tiedot voivat olla myös nollaa.
        errors.push({
          fields: '570, 701, 703',
          message: 'Jos on annettu jokin kohdista "Osakkeiden lukumäärä" (570), "Osakkeenomistajan nimi" (701) tai "Henkilö- tai Y-tunnus" (703), täytyy ilmoittaa kaikki kolme tietoa. Tiedot voivat olla myös nollaa.'
        })
      }

      // #846; Jos "Vuokrat" (706) tai "Luovutukset" (707) tai "Korot" (708) tai "Muut suoritukset" (709) tai "Osakaslainat" (864) on suurempi kuin nolla pitää antaa "Osakkeenomistajan nimi" (701) ja "Henkilö- tai Y-tunnus" (703) ja "Osakkeiden lukumäärä" (570). "Osakkeiden lukumäärä" (570) voi olla nolla.
      if (
        this._etsiNumero('706', rivit) ||
        this._etsiNumero('707', rivit) ||
        this._etsiNumero('708', rivit) ||
        this._etsiNumero('709', rivit) ||
        this._etsiNumero('864', rivit)
      ) {
        if (!v701 || !v703 || (v570 === null || v570 === undefined)) {
          errors.push({
            fields: '570, 701, 703',
            message: 'Jos "Vuokrat" (706) tai "Luovutukset" (707) tai "Korot" (708) tai "Muut suoritukset" (709) tai "Osakaslainat" (864) on suurempi kuin nolla pitää antaa "Osakkeenomistajan nimi" (701) ja "Henkilö - tai Y - tunnus" (703) ja "Osakkeiden lukumäärä" (570)."Osakkeiden lukumäärä" (570) voi olla nolla.'
          })
        }
      }

    }

    const v185 = stringMap.get('185')
    if (!v185 && !ainakinYksiAsetettu) {
      // #517; Jos kohtaa "Kaikkien osakkaiden omistusosuus alle 10 %" (185) ei ole ilmoitettu, on annettava ainakin yhden osakkaan(tunnukset 701, 703 ja 570) tiedot.
      errors.push({
        fields: '185',
        message: 'Jos kohtaa "Kaikkien osakkaiden omistusosuus alle 10 %" (185) ei ole ilmoitettu, on annettava ainakin yhden osakkaan(tunnukset 701, 703 ja 570) tiedot.'
      })
    }

    const v177 = stringMap.get('177')
    const v178 = stringMap.get('178')

    // #442; Jos tilintarkastus on suoritettu(177 = 1), pitää myös kertoa sisältääkö se kielteisiä lausuntoja vai ei(178).
    if (v177 === '1' && !v178) {
      errors.push({
        fields: '177, 178',
        message: 'Jos tilintarkastus on suoritettu(177 = 1), pitää myös kertoa sisältääkö se kielteisiä lausuntoja vai ei (178).'
      })
    }

    // #471; Jos tilintarkastusta ei ole suoritettu(177 = 2 tai 3), ei saa antaa tietoa, sisältääkö tilintarkastuskertomus kielteisiä lausuntoja (178).
    if (v177 !== '1' && v178) {
      errors.push({
        fields: '177, 178',
        message: 'Jos tilintarkastusta ei ole suoritettu(177 = 2 tai 3), ei saa antaa tietoa, sisältääkö tilintarkastuskertomus kielteisiä lausuntoja (178).'
      })
    }

    // 583 #889; Jos on ilmoitettu tieto Uuden osakkeen merkintähinta (583) nollasta poikkeavalla arvolla, on ilmoitettava myös Merkintähintaa käytettävä uuden osakkeen matemaattisena arvona (1=kyllä, 0=ei) (585).
    const v583 = numberMap.get('583') || 0
    const v585 = stringMap.get('585')
    if (v583 && (v585 !== '0' && v585 !== '1')) {
      errors.push({
        fields: '538, 585',
        message: 'Jos on ilmoitettu tieto Uuden osakkeen merkintähinta (583) nollasta poikkeavalla arvolla, on ilmoitettava myös Merkintähintaa käytettävä uuden osakkeen matemaattisena arvona (1=kyllä, 0=ei) (585).'
      })
    }

    for (const huoneisto of huoneistot) {

      const v722 = this._etsiArvo('722', huoneisto)
      const v724 = this._etsiArvo('724', huoneisto)
      const v729 = this._etsiArvo('729', huoneisto)
      const v730 = this._etsiNumero('730', huoneisto)

      // #333; Jos on annettu jokin tiedoista "Osakkaan henkilötunnus" (722), "Kiinteistön tai kiinteistö-/asunto-osakeyhtiön nimi" (724), "Käyttöaika asuntona ppkkvvvv – ppkkvvvv" (729) tai "Nettovarallisuuslaskelmassa käytetty arvo" (730), täytyy ilmoittaa kaikki tiedot.
      if (!v722 || !v724 || !v729 || (v730 === null || v730 === undefined)) {
        errors.push({
          fields: '722, 724, 729, 730',
          message: 'Jos on annettu jokin tiedoista "Osakkaan henkilötunnus" (722), "Kiinteistön tai kiinteistö-/asunto-osakeyhtiön nimi" (724), "Käyttöaika asuntona ppkkvvvv – ppkkvvvv" (729) tai "Nettovarallisuuslaskelmassa käytetty arvo" (730), täytyy ilmoittaa kaikki tiedot.'
        })
      }

    }

    // (330 + 331 + 333 + 260 + 334 + 335 + 336 + 338 + 339 + 340 + 341 + 343 + 344 + 563 + 345 + 347 + 439 + 351 + 352 + 261 + 353) - (359 + 360 + 361 + 362 + 363 + 365 + 367 + 369 + 371 + 373 + 374 + 375 + 376 + 377 + 328 + 386 + 387 + 398 + 399 + 390 + 391 + 392 + 327 + 436 + 395 + 271 + 273 + 396)
    const tulot = this._sumFromMap(numberMap, '330', '331', '333', '260', '334', '335', '336', '338', '339', '340', '341', '343', '344', '563', '345', '347', '439', '351', '352', '261', '353')
    const menot = this._sumFromMap(numberMap, '359', '360', '361', '362', '363', '365', '367', '369', '371', '373', '374', '375', '376', '377', '328', '386', '387', '398', '399', '390', '391', '392', '327', '436', '395', '271', '273', '396')
    const tulos = this._currencyService.roundHalfUp(tulot - menot, 2)

    // console.log('500', this._etsiNumero('500', rivit))
    if (tulos > 0) {
      // 500 === kaava
      // Laskutoimituksen tuloksen ollessa positiivinen, merkitään luku tunnukselle 500.
      if (this._etsiNumero('500', rivit) !== tulos) {
        errors.push({
          fields: '500',
          message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (' + this._etsiNumero('500', rivit) + ' !== ' + tulos + ')'
        })
      }
    } else if (tulos < 0) {
      // JOS 505 annettu, (0 - 505) - 504 === kaava
      // Laskutoimituksen tuloksen ollessa negatiivinen, pitää luku antaa ilman miinusmerkkiä ja sen pitää olla tunnuksilla 504 ja 505 annettujen tietojen yhteissumma.
      if (0 - this._etsiNumero('505', rivit) - this._etsiNumero('504', rivit) !== tulos) {
        errors.push({
          fields: '505',
          message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('505', rivit) + ' - ' + this._etsiNumero('504', rivit) + ' !== ' + tulos + ')'
        })
      }
    }












    // Laskutoimituksen tuloksen ollessa positiivinen, tulee summa tunnukselle 573. Laskutoimituksen tuloksen ollessa negatiivinen, tulee summa tunnukselle 574.
    // (400 + 401 + 402 + 403 + 404 + 405 + 406 + 407 + 408 + 409 + 411 + 412 + 413 + 414 + 415 + 416 + 417 + 419 + 420 + 421 + 422 + 423 + 424 + 425 + 463 + 426 + 427 + 428 + 430 + 431 + 432 + 437 + 438 + 433) - (450 + 451 + 452 + 453 + 454 + 455 + 456 + 457 + 464 + 465 + 458 + 459)
    const summa1 = this._sumFromMap(numberMap, '400', '401', '402', '403', '404', '405', '406', '407', '408', '409', '411', '412', '413', '414', '415', '416', '417', '419', '420', '421', '422', '423', '424', '425', '463', '426', '427', '428', '430', '431', '432', '437', '438', '433')
    const summa2 = this._sumFromMap(numberMap, '450', '451', '452', '453', '454', '455', '456', '457', '464', '465', '458', '459')
    const erotus = this._currencyService.roundHalfUp(summa1 - summa2, 2)
    if (erotus > 0) {
      if (this._etsiNumero('573', rivit) !== erotus) {
        errors.push({
          fields: '573',
          message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (' + this._etsiNumero('573', rivit) + ' !== ' + erotus + ')'
        })
      }
    } else if (erotus < 0) {
      if (0 - this._etsiNumero('574', rivit) !== erotus) {
        errors.push({
          fields: '574',
          message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('574', rivit) + ' !== ' + erotus + ')'
        })
      }
    }


    // ELINKEINOTOIMINNAN VERONALAISET TUOTOT YHTEENSÄ
    // 354 = 330 + 331 + 333 + 260 + 334 + 335 + 336 + 338 + 339 + 340 + 341 + 343 + 344 + 563 + 345 + 347 + 439 + 351 + 352 + 261 + 353
    const veronalaisetTuotot = this._sumFromMap(numberMap, '330', '331', '333', '260', '334', '335', '336', '338', '339', '340', '341', '343', '344', '563', '345', '347', '439', '351', '352', '261', '353')
    if (this._etsiNumero('354', rivit) !== veronalaisetTuotot) {
      errors.push({
        fields: '354',
        message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('354', rivit) + ' !== ' + veronalaisetTuotot + ')'
      })
    }


    // ELINKEINOTOIMINNAN VEROTUKSESSA VÄHENNYSKELPOISET KULUT YHTEENSÄ
    // 397 = 359 + 360 + 361 + 362 + 363 + 365 + 367 + 369 + 371 + 373 + 374 + 375 + 376 + 377 + 328 + 386 + 387 + 398 + 399 + 390 + 391 + 392 + 327 + 436 + 395 + 271 + 273 + 396.
    // 397 = 359 + 360 + 361 + 362 + 363 + 365 + 367 + 369 + 371 + 373 + 374 + 375 + 376 + 377 + 328 + 386 + 387 + 398 + 399 + 390 + 391 + 392 + 327 + 436 + 395 + 271 + 273 + 396
    const vahennyskelpoiset = this._sumFromMap(numberMap, '359', '360', '361', '362', '363', '365', '367', '369', '371', '373', '374', '375', '376', '377', '328', '386', '387', '398', '399', '390', '391', '392', '327', '436', '395', '271', '273', '396')
    if (this._etsiNumero('397', rivit) !== vahennyskelpoiset) {
      errors.push({
        fields: '397',
        message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('397', rivit) + ' !== ' + vahennyskelpoiset + ')'
      })
    }


    // VARAT YHTEENSÄ
    // 435 = 410 + 418 + 429 + 434
    const varat = this._sumFromMap(numberMap, '410', '418', '429', '434')
    if (this._etsiNumero('435', rivit) !== varat) {
      errors.push({
        fields: '435',
        message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('435', rivit) + ' !== ' + varat + ')'
      })
    }

    // VELAT YHTEENSÄ
    // 460 = 450 + 451 + 452 + 453 + 454 + 455 + 456 + 457 + 464 + 465 + 458 + 459
    const velat = this._sumFromMap(numberMap, '450', '451', '452', '453', '454', '455', '456', '457', '464', '465', '458', '459')
    if (this._etsiNumero('460', rivit) !== velat) {
      errors.push({
        fields: '460',
        message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('460', rivit) + ' !== ' + velat + ')'
      })
    }

    // OMA PÄÄOMA YHTEENSÄ
    // 478 = 470 + 495 + 496 + 497 + 474 - 475 + 476 - 477
    const paaoma = this._sumFromMapDebug(numberMap, '470', '495', '496', '497', '474', '476')
    const v475 = numberMap.get('475') || 0
    const v477 = numberMap.get('477') || 0
    console.log('475', v475)
    console.log('477', v477)
    const paaoma1 = this._currencyService.roundHalfUp(paaoma - v475 - v477, 2)
    console.log('Laskettu pääoma: ', paaoma1)
    console.log('Tileistä summattu pääoma: ', this._etsiNumero('478', rivit))
    if (this._etsiNumero('478', rivit) !== paaoma1) {
      errors.push({
        fields: '478',
        message: 'Tulos ei täsmää summauksen kanssa. Ota yhteys ylläpitoon. (0 - ' + this._etsiNumero('478', rivit) + ' !== ' + paaoma1 + ')'
      })
    }

    this._tarkistaPlusN15('570', numberMap, errors)
    this._tarkistaR13Pilkku2('706', numberMap, errors)
    this._tarkistaR13Pilkku2('708', numberMap, errors)
    this._tarkistaR13Pilkku2('707', numberMap, errors)
    this._tarkistaR13Pilkku2('709', numberMap, errors)
    this._tarkistaR13Pilkku2('864', numberMap, errors)

    this._tarkistaR13Pilkku2('730', numberMap, errors)
    this._tarkistaR13Pilkku2('522', numberMap, errors)
    this._tarkistaR13Pilkku2('293', numberMap, errors)
    this._tarkistaR13Pilkku2('591', numberMap, errors)
    this._tarkistaR13Pilkku2('592', numberMap, errors)
    this._tarkistaPlusN15('580', numberMap, errors)
    this._tarkistaR13Pilkku2('582', numberMap, errors)
    this._tarkistaR13Pilkku2('583', numberMap, errors)
    this._tarkistaPlusN15('571', numberMap, errors)

    this._tarkistaR13Pilkku2('500', numberMap, errors)
    this._tarkistaR13Pilkku2('504', numberMap, errors)
    this._tarkistaR13Pilkku2('505', numberMap, errors)

    this._tarkistaR13Pilkku2('400', numberMap, errors)
    this._tarkistaR13Pilkku2('401', numberMap, errors)
    this._tarkistaR13Pilkku2('402', numberMap, errors)
    this._tarkistaR13Pilkku2('403', numberMap, errors)
    this._tarkistaR13Pilkku2('404', numberMap, errors)
    this._tarkistaR13Pilkku2('405', numberMap, errors)
    this._tarkistaR13Pilkku2('406', numberMap, errors)
    this._tarkistaR13Pilkku2('407', numberMap, errors)
    this._tarkistaR13Pilkku2('408', numberMap, errors)
    this._tarkistaR13Pilkku2('409', numberMap, errors)
    this._tarkistaR13Pilkku2('410', numberMap, errors)

    this._tarkistaR13Pilkku2('411', numberMap, errors)
    this._tarkistaR13Pilkku2('412', numberMap, errors)
    this._tarkistaR13Pilkku2('413', numberMap, errors)
    this._tarkistaR13Pilkku2('414', numberMap, errors)
    this._tarkistaR13Pilkku2('415', numberMap, errors)
    this._tarkistaR13Pilkku2('416', numberMap, errors)
    this._tarkistaR13Pilkku2('417', numberMap, errors)
    this._tarkistaR13Pilkku2('418', numberMap, errors)
    this._tarkistaR13Pilkku2('419', numberMap, errors)
    this._tarkistaR13Pilkku2('420', numberMap, errors)
    this._tarkistaR13Pilkku2('421', numberMap, errors)
    this._tarkistaR13Pilkku2('422', numberMap, errors)
    this._tarkistaR13Pilkku2('423', numberMap, errors)
    this._tarkistaR13Pilkku2('424', numberMap, errors)
    this._tarkistaR13Pilkku2('425', numberMap, errors)
    this._tarkistaR13Pilkku2('463', numberMap, errors)
    this._tarkistaR13Pilkku2('426', numberMap, errors)
    this._tarkistaR13Pilkku2('427', numberMap, errors)
    this._tarkistaR13Pilkku2('428', numberMap, errors)
    this._tarkistaR13Pilkku2('429', numberMap, errors)

    this._tarkistaR13Pilkku2('430', numberMap, errors)
    this._tarkistaR13Pilkku2('431', numberMap, errors)
    this._tarkistaR13Pilkku2('432', numberMap, errors)
    this._tarkistaR13Pilkku2('437', numberMap, errors)
    this._tarkistaR13Pilkku2('438', numberMap, errors)
    this._tarkistaR13Pilkku2('433', numberMap, errors)
    this._tarkistaR13Pilkku2('434', numberMap, errors)
    this._tarkistaR13Pilkku2('435', numberMap, errors)

    this._tarkistaR13Pilkku2('450', numberMap, errors)
    this._tarkistaR13Pilkku2('451', numberMap, errors)
    this._tarkistaR13Pilkku2('452', numberMap, errors)
    this._tarkistaR13Pilkku2('453', numberMap, errors)
    this._tarkistaR13Pilkku2('454', numberMap, errors)
    this._tarkistaR13Pilkku2('455', numberMap, errors)
    this._tarkistaR13Pilkku2('456', numberMap, errors)
    this._tarkistaR13Pilkku2('457', numberMap, errors)

    this._tarkistaR13Pilkku2('464', numberMap, errors)
    this._tarkistaR13Pilkku2('465', numberMap, errors)
    this._tarkistaR13Pilkku2('458', numberMap, errors)
    this._tarkistaR13Pilkku2('459', numberMap, errors)
    this._tarkistaR13Pilkku2('460', numberMap, errors)
    this._tarkistaR13Pilkku2('461', numberMap, errors)
    this._tarkistaR13Pilkku2('462', numberMap, errors)
    this._tarkistaR13Pilkku2('573', numberMap, errors)
    this._tarkistaR13Pilkku2('574', numberMap, errors)

    this._tarkistaR13Pilkku2('470', numberMap, errors)
    this._tarkistaR13Pilkku2('495', numberMap, errors)

    this._tarkistaR13Pilkku2('496', numberMap, errors)

    this._tarkistaR13Pilkku2('474', numberMap, errors)
    this._tarkistaR13Pilkku2('475', numberMap, errors)
    this._tarkistaR13Pilkku2('476', numberMap, errors)
    this._tarkistaR13Pilkku2('477', numberMap, errors)

    this._tarkistaR13Pilkku2('677', numberMap, errors)
    this._tarkistaR13Pilkku2('678', numberMap, errors)
    this._tarkistaR13Pilkku2('679', numberMap, errors)
    this._tarkistaR13Pilkku2('680', numberMap, errors)

    return errors

  }

}
