<div class="laheta-card" style="margin-top: 25px;">

  <div class="laheta-title light-blue-text">Aikaisemmin asiakkaalle lähetetyt ALV-laskut</div>
  <div class="laheta-body">
    <div *ngFor="let aikaisempi of aikaisemminLahetetytLaskutObservable | async">
      Lähetetty {{ aikaisempi.laskunLahetyspaivamaara }}.
      <!-- {{ aikaisempi.esikatselutiedot | json }} -->
      <div style="margin: 10px; box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);" app-lasku-pdf-esikatselu-perinteinen-rajoitettu-leveyteen [tiedot]="aikaisempi.esikatselutiedot"></div>
    </div>
  </div>

  <!-- <div class="laheta-title light-blue-text">Lähetä asiakkaalle ALV-lasku</div>
  <div class="laheta-body">

    <div *ngIf="alvLaskunVoiLahettaaObservable | async; else cantSend">

      <div>Asiakkaan Holvi-tilille lähetetään sähköinen ALV-lasku. Lasku lähtee heti, eikä lähetystä voi perua.</div>

      <div style="padding: 1em;">

        Lasku lähetetään seuraavilla maksuyhteystiedoilla:
        <table style="margin-left: .5em; font-size: 90%;">
          <tr>
            <td style="padding-right: 30px;">Pankki</td>
            <td style="text-align: right;">
              Nordea (NDEAFIHH)
            </td>
          </tr>
          <tr>
            <td style="padding-right: 30px;">Tili</td>
            <td style="text-align: right;">
              FI64 1660 3000 1176 25
            </td>
          </tr>
          <tr>
            <td style="padding-right: 30px;">Viitenumero</td>
            <td style="text-align: right;">{{alvLaskunViitenumeroObservable | async}}</td>
          </tr>
          <tr>
            <td style="padding-right: 30px;">Summa</td>
            <td style="text-align: right;">
              <form [formGroup]="alvLaskuForm">
                <mat-form-field>
                  <input decimalNumberField matInput formControlName="maara" [numberOfDecimals]="2" required>
                </mat-form-field>
              </form>
            </td>
          </tr>
        </table>
        <div style="display: flex; justify-content: space-evenly; padding-top: 20px;">
          <button class="primarybutton" mat-button (click)="lahetaAlvLasku()" type="button">Lähetä</button>
        </div>

      </div>

      <div style="height: 15px;"></div>
      Lähetettävän laskun esikatselu
      <div style="margin: 10px; box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);" app-lasku-pdf-esikatselu-perinteinen-rajoitettu-leveyteen [tiedot]="laskunEsikatselutiedotObservable | async"></div>

    </div>

    <ng-template #cantSend>
      <div *ngIf="(alvIlmoituksenSummaObservable | async) > 0; else eiTarvitseLahettaa">
        ALV-laskua ei voi lähettää:
        <ul>
          <li *ngIf="!(alvLaskunViitenumeroObservable | async)">Oma-aloitteisten verojen viitenumero puuttuu. Ota yhteys ylläpitoon.</li>
          <li *ngIf="!(alvLaskunSahkoinenLaskutusosoiteObservable | async)">Asiakkaan sähköinen laskuosoite puuttuu. (Mene Asetukset -> Laskutus, valitse Laskujen toimitus: "Verkkolasku", syötä verkkolaskuosoite.)</li>
        </ul>
      </div>
      <div *ngIf="asiakkaanOsoitetiedotPuuttuvatObservable | async">Asiakkaan osoitetiedot puuttuvat. (Mene Asetukset -> Asiakastiedot ja tarkista, että katuosoite, postinumero ja postitoimipaikka on täytetty)</div>
      <ng-template #eiTarvitseLahettaa>
        ALV-laskua ei tarvitse lähettää, koska maksettava ALV on nolla tai valtio palauttaa asiakkaalle arvonlisäveroa.
      </ng-template>
    </ng-template>

  </div> -->

</div>