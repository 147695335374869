<div mat-dialog-content style="width: 350px;">
  <lemon-dialog-header>
    Lisää tiedosto
  </lemon-dialog-header>

  <form [formGroup]="form">

    <!-- <div>
      <div style="font-weight: bold;">Aineiston tyyppi</div>
      <mat-radio-group formControlName="uploadType" required>
        <mat-radio-button *ngFor="let opt of typeOptions" [value]="opt.id">{{opt.name}}</mat-radio-button>
      </mat-radio-group>

      <ul style="font-style: italic;">
        <li>sisäinen: Tiedostoa ei sisällytetä aineiston toimituspakettiin</li>
        <li>julkinen: Tiedosto sisällytetään aineiston toimituspakettiin</li>
      </ul>
    </div> -->

    <div style="margin: 2rem;">
      <div style="margin: 2rem 0;">
        <button *ngIf="!uploadedFile?.fileName else uploadedFileName" class="primarybutton" mat-button (click)="fileInput.click()" type="button">
          Valitse tiedosto
        </button>
        <ng-template #uploadedFileName>
          <div>{{uploadedFile?.fileName}}</div>
        </ng-template>
      </div>

      <div class="error-box" *ngIf="latausVirheObservable | async; let latausVirhe">{{ latausVirhe }}</div>

      <!-- <mat-form-field>
      <mat-select formControlName="tilikausiAvain" placeholder="Tilikausi">
        <mat-option *ngFor="let tk of tilikaudet" [value]="tk.avain">{{ tk.alkaa | lokaalipvm | async }} - {{ tk.loppuu | lokaalipvm | async }}</mat-option>
      </mat-select>
    </mat-form-field> -->

      <mat-form-field>
        <mat-label>Lisätiedot</mat-label>
        <input formControlName="lisatiedot" matInput data-lpignore="true" required>
      </mat-form-field>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <button class="secondarybutton" style="margin-right: 20px;" mat-button mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button [disabled]="!uploadedFile" (click)="save()">Tallenna</button>
</div>

<input style="visibility:hidden; height: 1px;" (change)="upload($event)" type="file" accept="*" #fileInput multiple="multiple" />