import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { MatCheckbox } from '@angular/material/checkbox'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { Kirjanpitaja } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajaComponentDataResolve } from 'app/_angular/_resolvers/kirjanpitaja.resolve'

@Component({
  templateUrl: './kirjanpitajat.component.html',
  styles: ['.mat-column-aktiivinen { max-width: 60px; text-align: center; justify-content:center; }']
})
export class KirjanpitajatComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) _sort: MatSort
  @ViewChild(MatCheckbox) naytaKaikkiCheckbox: MatCheckbox

  naytettavatKolumnit: string[] = ['aktiivinen', 'etunimi', 'sukunimi', 'email']
  kirjanpitajatLataa: boolean = true

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  dataSource = new MatTableDataSource<Kirjanpitaja>([])

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private kirjanpitajatService: KirjanpitajaService,
    private kirjanpitajaComponentDataResolve: KirjanpitajaComponentDataResolve
  ) {

    this.dataSource.sortData = (data: Kirjanpitaja[], sort: MatSort): Kirjanpitaja[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

      if (active === 'etunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.etunimi, b.etunimi) * directionMultiplier
        })
      }

      if (active === 'sukunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.sukunimi, b.sukunimi) * directionMultiplier
        })
      }

      if (active === 'aktiivinen') {
        return data.sort((a, b) => {
          return ((a.poistettu === b.poistettu) ? 0 : a.poistettu ? -1 : 1) * directionMultiplier
        })
      }

      if (active === 'email') {
        return data.sort((a, b) => {
          return collator.compare(a.email, b.email) * directionMultiplier
        })
      }

      return data

    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    this._sort.sort({
      disableClear: false,
      id: 'sukunimi',
      start: 'asc'
    })
    this.dataSource.sort = this._sort

    this.kirjanpitajatService.kirjanpitajatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kirjanpitajat => {
      this.dataSource.data = kirjanpitajat || []
      this.kirjanpitajatLataa = false
    }, error => {
      this.errorHandler.handleError(error)
    })

  }

  lisaa() {
    this.router.navigate(['yllapito', 'kirjanpitajat', 'uusi'])
  }

  muokkaa(kirjanpitaja: Kirjanpitaja) {
    this.kirjanpitajaComponentDataResolve.asetaOlemassaolevaData({ kirjanpitaja: kirjanpitaja })
    this.router.navigate(['yllapito', 'kirjanpitajat', kirjanpitaja.avain])
  }

  get kirjanpitajatDataSource(): MatTableDataSource<Kirjanpitaja> {
    return this.dataSource
  }

}
