import { Component, Input } from '@angular/core'


@Component({
  selector: 'lemon-dialog-header',
  templateUrl: './lemon-dialog-header.component.html'
})
export class LemonDialogHeader {

  @Input()
    naytaSuljeRuksi = true

}
