import { Injectable, Inject, ErrorHandler } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { Observable, ReplaySubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

type SessionStatus = 'valid' | 'invalid'

@Injectable()
export class CookieService {

  private _sessionCookieStatusSubject = new ReplaySubject<SessionStatus>()
  sessionCookieStatusObservable: Observable<SessionStatus> = this._sessionCookieStatusSubject.asObservable().pipe(
    distinctUntilChanged((prev, curr) => {
      if (prev === 'invalid' && curr === 'invalid') {
        return false
      }
      return prev === curr
    })
  )

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _errorHandler: ErrorHandler
  ) {
    this.checkSessionStatus()
  }

  private checkSessionStatus() {
    try {
      this.updateLemonSessionExpiredStatus()
    } catch (err) {
      this._errorHandler.handleError(err)
    }
    setTimeout(this.checkSessionStatus.bind(this), 30000)
  }

  setCookie(name: string, value: string, expires: Date) {
    let e = ''
    if (expires) {
      e = '; expires=' + expires.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + e + '; path=/'
  }

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;'
  }

  getCookie(cookieName: string): string | null {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(this._document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return null
  }

  private getLemonSessionExpirationTimeSeconds(): number | null {
    const cookieValue = this.getCookie('lemonse')
    // console.log('Got cookievalue', cookieValue)
    if (cookieValue) {
      const asNumber = Number(cookieValue)
      if (asNumber > 0 && !isNaN(asNumber)) {
        return asNumber
      }
    }
    return null
  }

  private updateLemonSessionExpiredStatus() {
    const newStatus: SessionStatus = this.hasLemonSessionExpired() ? 'invalid' : 'valid'
    // console.log('Check status', newStatus)
    this._sessionCookieStatusSubject.next(newStatus)
  }

  hasLemonSessionExpired(): boolean {
    const sessionExpirationTime = this.getLemonSessionExpirationTimeSeconds()
    if (sessionExpirationTime) {
      // Expire one hour before actual time.
      const hour = 60 * 60
      console.log('Check status at', new Date().toISOString(), 'will renew at', new Date(1000 * (sessionExpirationTime - hour)).toISOString())
      return sessionExpirationTime < (new Date().getTime() / 1000) + hour
    }
    return true
  }

}
