import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { KirjanpitajanAllekirjoitusService } from 'app/_jaettu-lemonator/service/kirjanpitajan-allekirjoitus.service'
import { KirjanpitajanNimitiedot } from '../_jaettu-lemonator/model/kirjanpitaja'
import { TuettuKieli } from 'app/_shared-core/model/common'

@Component({
  selector: '[app-kirjanpito-lahetys-signature]',
  template: '<div [innerHTML]="signatureHtml"></div>',
  styleUrls: ['./kirjanpito-lahetys-signature.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class KirjanpitoLahetysSignatureComponent {

  signatureHtml: SafeHtml

  private _nimitiedot: KirjanpitajanNimitiedot
  private _kieli: TuettuKieli

  @Input() set kirjanpitajanNimitiedot(nimitiedot: KirjanpitajanNimitiedot) {
    this._nimitiedot = nimitiedot
    this._updateSignature()
  }

  @Input() set kieli(kieli: TuettuKieli) {
    this._kieli = kieli
    this._updateSignature()
  }

  constructor(
    private _kirjanpitajanAllekirjoitusService: KirjanpitajanAllekirjoitusService,
    private _domSanitizer: DomSanitizer
  ) { }

  private _updateSignature() {
    if (this._nimitiedot && this._kieli) {
      this.signatureHtml = this._domSanitizer.bypassSecurityTrustHtml(this._kirjanpitajanAllekirjoitusService.getSignatureHtml(this._nimitiedot, this._kieli))
    } else {
      this.signatureHtml = ''
    }
  }

}
