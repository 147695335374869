import { Component, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Kayttaja } from '../_jaettu-lemonator/model/asiakas'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { HttpClient } from '@angular/common/http'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { firstValueFrom } from 'rxjs'

export interface AsiakkaanMaksutavatValitseKuvakeDialogData {
  nykyinen: string
}

@Component({
  templateUrl: './asiakkaan-maksutavat.valitse-kuvake.dialog.html'
})
export class AsiakkaanMaksutavatValitseKuvakeDialog {

  valittavissa: string[] = [
    'alv.png',
    'alv2.png',
    'alv3.png',
    'danske.png',
    'danske2.png',
    'hattu3.png',
    'hattu4.png',
    'holvi.png',
    'huutomerkki.png',
    'kassa-e.png',
    'kassa-l.png',
    'kassa-m.png',
    'nordea.png',
    'op.png',
    'porkkana.png',
    'saastopankki.png',
    'tosite.png',
    'x.png',
    'auto.png',
    'eyeswideshut.png',
    'hattu2.png',
    'kateinen.png',
    'kateinen_pienempi.png',
    'kateinen_testi.png',
    'kortti.png',
    'lentokone.png',
    'mastercard.png',
    'monopoli_hattu.png',
    'monopoli_hattu_valk.png',
    'monopoli_silitysrauta.png',
    'monopoli_sormustin.png',
    'myyntiraportti.png',
    'paypal.png',
    'ranskis.png',
    'revolut.png',
    'salama.png',
    'salama2.png',
    'silinterihattu.png',
    'ukkeli_silinterihattu.png',
    'ukko-k.png',
    'ukko-m.png',
    'ukko-o.png',
    'ukko-p.png',
    'ukko-t.png',
    'visa.png'
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Kayttaja,
    private _dialogRef: MatDialogRef<AsiakkaanMaksutavatValitseKuvakeDialog>,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _httpClient: HttpClient,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
  ) { }

  async valitseKuva(valittu: string) {
    try {
      this._ladataanService.aloitaLataaminen()
      const blobObservable = this._httpClient.get('/assets/maksutapakuvakkeet/' + valittu, { responseType: 'blob' })
      const blob = await firstValueFrom(blobObservable)
      const base64 = await this._tiedostojenLataamisService.getAsDataUrl(blob)
      const splitted = base64.split('base64,')
      this._dialogRef.close(splitted[1].trim())
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  peruuta() {
    this._dialogRef.close()
  }

}
