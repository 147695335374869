import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'

import { Subject, Observable, combineLatest, firstValueFrom } from 'rxjs'
import { takeUntil, map, startWith } from 'rxjs/operators'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { DateService } from '../../_shared-core/service/date.service'

import { YllapitoUriService } from '../../_jaettu-lemonator/service/yllapito-uri.service'
import { SopimuksenJulkaiseminenDialog, SopimuksenJulkaiseminenDialogData } from './sopimuksen-julkaiseminen.dialog'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { Sopimus } from 'app/_jaettu/model/sopimus'

@Component({
  templateUrl: './sopimukset.component.html'
})
export class SopimuksetComponent implements OnInit, OnDestroy {

  private ngUnsubsribe: Subject<void> = new Subject<void>()

  sopimustenVersiotObservable: Observable<{ aika: string, tekstifi: string, tekstien: string, kirjanpitaja: string }[]>
  usableVariablesSuomiObservable: Observable<string[]>
  usableVariablesEnglishObservable: Observable<string[]>

  textfi: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  texten: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  form: UntypedFormGroup = new UntypedFormGroup({
    'textfi': this.textfi,
    'texten': this.texten
  })

  clientVariables: string[] = [
    '{{yrityksen_nimi}}',
    '{{ytunnus}}',
    '{{katuosoite}}',
    '{{postinumero}}',
    '{{postitoimipaikka}}',
    '{{maa}}',
    '{{etunimi}}',
    '{{sukunimi}}'
  ]

  constructor(
    private _formValidationService: FormValidationService,
    private _firebase: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _timestampService: TimestampService,
    private _dateService: DateService,
    private _snackbar: MatSnackBar,
    private _error: ErrorHandler,
    private _dialog: MatDialog,
    private _uriService: YllapitoUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitajaService: KirjanpitajaService
  ) { }



  ngOnInit() {
    this._firebase.firestoreDoc<Sopimus>(this._uriService.annaSopimuksetMainUri()).listen().pipe(
      takeUntil(this.ngUnsubsribe)
    ).subscribe(data => {
      if (data && data.textInDiffLangs) {
        this.textfi.setValue(data.textInDiffLangs['fi'] || '')
        this.texten.setValue(data.textInDiffLangs['en'] || '')
      } else {
        this.textfi.setValue('')
        this.texten.setValue('')
      }
    })

    const sopimuksetObservable = this._firebase.firestoreCollection<Sopimus>(this._uriService.annaSopimuksetVersiotCollection()).listen()

    this.sopimustenVersiotObservable = combineLatest([this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, sopimuksetObservable]).pipe(
      map(([kirjanpitajienNimitiedotMap, kaikkiVersiot]) => {

        const versiotOrdered = kaikkiVersiot.sort((a, b) => b.pvm.toMillis() - a.pvm.toMillis())
        const output: { aika: string, tekstifi: string, tekstien: string, kirjanpitaja: string }[] = []

        for (const versio of versiotOrdered) {
          if (versio && versio.textInDiffLangs) {

            const kirjanpitaja = kirjanpitajienNimitiedotMap.get(versio.uid || '')
            const nimi = kirjanpitaja ? kirjanpitaja.sukunimi + ' ' + kirjanpitaja.etunimi : 'Tuntematon'

            output.push({
              aika: this._dateService.muotoilePaivaJaAikaDate(versio.pvm.toDate(), 'fi'),
              tekstifi: versio.textInDiffLangs['fi'],
              tekstien: versio.textInDiffLangs['en'],
              kirjanpitaja: nimi
            })

          }
        }

        return output
      }),
      takeUntil(this.ngUnsubsribe)
    )
    this.usableVariablesSuomiObservable = this.textfi.valueChanges.pipe(
      startWith(this.clientVariables),
      map(text => {
        if (!text) {
          return this.clientVariables
        }
        return this.clientVariables.filter(variable => !text.includes(variable))
      }),
      takeUntil(this.ngUnsubsribe)
    )
    this.usableVariablesEnglishObservable = this.texten.valueChanges.pipe(
      startWith(this.clientVariables),
      map(text => {
        if (!text) {
          return this.clientVariables
        }
        return this.clientVariables.filter(variable => !text.includes(variable))
      }),
      takeUntil(this.ngUnsubsribe)
    )

  }

  async save() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    const docData: Sopimus = {
      avain: 'sopimukset',
      pvm: this._timestampService.now(),
      textInDiffLangs: {},
      uid: kirjanpitajanTiedot.uid
    }
    docData.textInDiffLangs['fi'] = this.textfi.value
    docData.textInDiffLangs['en'] = this.texten.value

    await this._firebase.firestoreSetData(this._uriService.annaSopimuksetMainUri(), docData)

    this._ladataanService.lopetaLataaminen()
    this._snackbar.open('Tallentaminen onnistui', 'OK', { duration: 5000 })

    // TODO: Where is the error handling?

  }

  julkaise() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const dialogData: SopimuksenJulkaiseminenDialogData = {
      textInDiffLangs: {}
    }
    dialogData.textInDiffLangs['fi'] = this.textfi.value
    dialogData.textInDiffLangs['en'] = this.texten.value

    this._dialog.open(SopimuksenJulkaiseminenDialog, { data: dialogData })

  }

  ngOnDestroy() {
    this.ngUnsubsribe.next()
  }

}
