import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'

import { Tilitapahtuma } from '../../_jaettu/model/tiliote'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { Observable, combineLatest, of } from 'rxjs'
import { map, switchMap, tap, startWith } from 'rxjs/operators'

import { Lasku } from 'app/_jaettu/model/lasku'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

@Component({
  templateUrl: './tilitapahtumien-kohdistusdebug.component.html'
})
export class TilitapahtumienKohdistusdebugComponent implements OnInit {

  form: FormGroup
  name = '' + Math.random()
  laskutObservable: Observable<Lasku[]>
  laskutLataa: boolean = true
  tapahtumatObservable: Observable<Tilitapahtuma[]>
  tapahtumatLataa: boolean = true

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _firebase: FirebaseLemonaid,
    private _asiakasService: AsiakasService
  ) { }

  ngOnInit() {

    const viitenumeroFormControl = new FormControl<string>('', [Validators.required])
    this.form = new FormGroup({
      'viitenumero': viitenumeroFormControl
    })

    const viitenumeroObservable: Observable<string> = viitenumeroFormControl.valueChanges.pipe(
      startWith('')
    )

    this.laskutObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, viitenumeroObservable]).pipe(
      tap(() => {
        setTimeout(() => {
          this.laskutLataa = true
          this._changeDetectorRef.markForCheck()
        }, 10)
      }),
      switchMap(([asiakas, viitenumero]) => {
        if (asiakas && viitenumero && viitenumero.length > 3) {
          return this._firebase.firestoreCollection<Lasku>('laskut/' + asiakas.asiakasId + '/laskut')
            .where('viitenumero', '==', viitenumero)
            .listen()
        }
        return of([])
      }),
      tap(() => {
        setTimeout(() => {
          this.laskutLataa = false
          this._changeDetectorRef.markForCheck()
        }, 10)
      })
    )

    const tilitapahtumatViitteelleObservable: Observable<Tilitapahtuma[]> = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, viitenumeroObservable]).pipe(
      tap(() => { this.tapahtumatLataa = true }),
      switchMap(([asiakas, viitenumero]) => {
        if (asiakas && viitenumero && viitenumero.length > 3) {
          return this._firebase.firestoreCollection<Tilitapahtuma>('customers/' + asiakas.avain + '/bank-transactions')
            .where('viite', '==', viitenumero)
            .listen()
        }
        return of<Tilitapahtuma[]>([])
      })
    )

    const tilitapahtumatYliajoviitteelleObservable: Observable<Tilitapahtuma[]> = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, viitenumeroObservable]).pipe(
      tap(() => {
        setTimeout(() => {
          this.tapahtumatLataa = true
          this._changeDetectorRef.markForCheck()
        }, 10)
      }),
      switchMap(([asiakas, viitenumero]) => {
        if (asiakas && viitenumero && viitenumero.length > 3) {
          return this._firebase.firestoreCollection<Tilitapahtuma>('customers/' + asiakas.avain + '/bank-transactions')
            .where('yliajoviite', '==', viitenumero)
            .listen()
        }
        return of<Tilitapahtuma[]>([])
      })
    )

    this.tapahtumatObservable = combineLatest([tilitapahtumatViitteelleObservable, tilitapahtumatYliajoviitteelleObservable]).pipe(
      map(([viitteelle, yliajoviitteelle]) => {
        const kaikki: Tilitapahtuma[] = []
        return kaikki.concat(viitteelle).concat(yliajoviitteelle)
      }),
      tap(() => {
        setTimeout(() => {
          this.tapahtumatLataa = false
          this._changeDetectorRef.markForCheck()
        }, 10)
      })
    )

  }

}
