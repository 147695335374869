import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ErrorHandler, Output, EventEmitter, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

import { MatSnackBar } from '@angular/material/snack-bar'

import { KirjanpitajanNimitiedot } from '../../_jaettu-lemonator/model/kirjanpitaja'
import { Asiakas, Tilikausi } from '../../_jaettu-lemonator/model/asiakas'
import { TilinpaatosEmailTextAutosaveData, TilinpaatosEmailLahetysTyojono, TilinpaatosEmailLahetys, RaporttiRequest, RaporttiPdfResponse, EmailLiite, EmailLiiteDownloadRequest, TilinpaatosTaseErittely, TilinpaatosEmailLahetysHistoryPdfRequest, TilinpaatosRaporttiName, TilinpaatosLiitetiedot } from '../../_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from '../../_jaettu/model/reports'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { TranslationService } from '../../_jaettu/service/translation.service'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitoUriService } from '../../_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitoJaettuService } from '../../_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { EmailLahetysStatus, EmailLahetysStatusKoodi } from 'app/_jaettu/model/lasku'

import { take, map, takeUntil, startWith, switchMap, tap, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, BehaviorSubject, firstValueFrom } from 'rxjs'

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { MatInput } from '@angular/material/input'

interface LahetysForm {
  aihe: FormControl<string>
  tekstinAlku: FormControl<string>
  tekstinLoppu: FormControl<string>
  tuloslaskelma: FormControl<boolean>
  tilinpaatos: FormControl<boolean>
  taseErittely: FormControl<boolean>
  lahettaja: FormControl<string>
  vastaanottajat: FormControl<string>
  piilokopioLahettajalle: FormControl<boolean>
}
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface TilinpaatosEmailLahetysHistoria extends TilinpaatosEmailLahetys {
  recipientsEmails: string
  liitteet: EmailLiite[]
  nimi: string
  luotuDateStr: string
}

@Component({
  selector: '[app-tilinpaatos-email-lahetys]',
  templateUrl: './tilinpaatos-lahetys-email.component.html',
  styleUrls: ['./tilinpaatos-lahetys-email.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTilinpaatosLahetysEmailComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() paivitaArvotHiljaisestiObservable: Observable<number>
  @Input() voiLahettaaObservable: Observable<boolean>
  @Input() tilintarkastetaanObservable: Observable<boolean>
  @Input() lukittuObservable: Observable<boolean>

  @Output() onkoSpostejaLahetetty: EventEmitter<boolean> = new EventEmitter()

  @ViewChild('recipientInput', { read: MatInput, static: true }) recipientField: MatInput
  @ViewChild('tekstiTextareaLoppu', { read: MatInput, static: true }) tekstiTextareaLoppu: MatInput
  @ViewChild('autosizeLoppu', { read: CdkTextareaAutosize, static: true }) autosizeLoppu: CdkTextareaAutosize
  @ViewChild('autosizeAlku', { read: CdkTextareaAutosize, static: true }) autosizeAlku: CdkTextareaAutosize

  lahetysForm: FormGroup<LahetysForm>
  sentThisTilikausi: TilinpaatosEmailLahetysHistoria[]
  nykyisenKirjanpitajanNimitiedotObservable: Observable<KirjanpitajanNimitiedot>
  attachmentsTotalSizeIfTooBigObservable: Observable<number>
  clientTypeObservable: Observable<'holvi' | 'regular' | 'unknown'>
  emailLiitteetObservable: Observable<EmailLiite[]>
  vastaanottajat: string[] = []
  private _historyLoadingSubject: Subject<boolean> = new Subject()
  historyLoadingObservable: Observable<boolean> = this._historyLoadingSubject.asObservable()

  private _taseErittelyObservable: Observable<TilinpaatosTaseErittely>

  namename = 'toiughnwro' + Math.random()

  private _sendingInProgress: boolean = false
  private _asiakasObservable: Observable<Asiakas>
  private _selvitettavatTextLocationMarker: string = '\u200B'
  private _ngUnsubscribe = new Subject<void>()
  private _allowAutoSaving: boolean = false
  private _lahetysAvainSubject: BehaviorSubject<string> = new BehaviorSubject(null)

  asiakasAvain: string
  asiakkaanKieliObservable: Observable<TuettuKieli>
  latausVirhe: string
  includeMainAutotext: boolean = true
  mainAutotext: string
  autosaveTimeTextObservable: Observable<string>
  commonError: string = null

  constructor(
    private _asiakasService: AsiakasService,
    private _translationService: TranslationService,
    private _dateService: DateService,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    private _kayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _formValidationService: FormValidationService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _currencyService: CurrencyService,
    private _firebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) {
    this.nykyisenKirjanpitajanNimitiedotObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, this._kayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([asiakas, kirjanpitajatMap, kirjanpitaja]) => {
        this.asiakasAvain = asiakas?.avain || null
        if (!kirjanpitajatMap || !kirjanpitaja) {
          return null
        }
        if (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Holvi-asiakas
          return kirjanpitajatMap.get('QgPvtcCjoOdf6Zg7lgMwqLWp2BG2')
        }
        return kirjanpitajatMap.get(kirjanpitaja.uid)
      })
    )
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.tekstiTextareaLoppu.focus()
  //   }, 50)
  // }

  ngOnInit() {

    this.lahetysForm = new FormGroup<LahetysForm>({
      aihe: new FormControl<string>(null),
      tekstinAlku: new FormControl<string>(null),
      tekstinLoppu: new FormControl<string>(null),
      tuloslaskelma: new FormControl<boolean>(false),
      tilinpaatos: new FormControl<boolean>(false),
      taseErittely: new FormControl<boolean>(false),
      lahettaja: new FormControl<string>({ value: null, disabled: true }),
      vastaanottajat: new FormControl<string>({ value: null, disabled: true }),
      piilokopioLahettajalle: new FormControl<boolean>(false)
    })

    this.tilintarkastetaanObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tilintarkastettava => {
      if (tilintarkastettava) {
        this.tilinpaatos.enable()
        this.tilinpaatos.setValue(true)
      } else {
        this.tilinpaatos.setValue(false)
        this.tilinpaatos.disable()
      }
    })

    this._asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.avain === b?.avain
      })
    )

    this.asiakkaanKieliObservable = this._asiakasObservable.pipe(
      map(asiakas => asiakas.laskunKieli || 'fi')
    )

    const tunnistetutYhteyshenkilot = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        if (!kayttajat?.length) {
          return []
        }
        return kayttajat.filter(k => k.aktiivinen && k.roolit.HALLINTO_YHTEYSHENKILO && k.kayttajaTunnistettu === 'tunnistettu-nets')
      })
    )

    combineLatest([tunnistetutYhteyshenkilot, this._asiakasObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([yhteyshenkilot, asiakas]) => {
      this.vastaanottajat = yhteyshenkilot.map(yh => yh.email)
      if (asiakas.kirjanpitoviestienLisavastaanottajat) {
        this.vastaanottajat.push(...asiakas.kirjanpitoviestienLisavastaanottajat)
      }
      this.lahetysForm.get('vastaanottajat').setValue(this.vastaanottajat.join(', '))
    })

    this.piilokopioLahettajalle.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ piilokopioValittu: !!valittu })
    })
    this.tuloslaskelma.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ tulosValittu: !!valittu })
    })
    // this.virallinenTuloslaskelma.valueChanges.pipe(
    //   distinctUntilChanged(),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(valittu => {
    //   this._updateAutosaveData({ virTulosValittu: !!valittu })
    // })
    // this.virallinenTase.valueChanges.pipe(
    //   distinctUntilChanged(),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(valittu => {
    //   this._updateAutosaveData({ virTaseValittu: !!valittu })
    // })
    this.tilinpaatos.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ tilinpaatosValittu: !!valittu })
    })
    this.taseErittely.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittu => {
      this._updateAutosaveData({ taseErittelyValittu: !!valittu })
    })

    this.clientTypeObservable = this._asiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return 'unknown'
        }
        return this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
      })
    )

    const senderEmailObservable: Observable<string> = this._asiakasObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<string>(null)
        }
        const tyyppi = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
        if (tyyppi === 'holvi') {
          return observableOf<string>('no-reply@lemontree.fi')
        }
        return this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable.pipe(
          map(kirjanpitaja => kirjanpitaja?.email || null)
        )
      })
    )

    senderEmailObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(email => {
      this.lahetysForm.get('lahettaja').setValue(email)
    })

    this.emailLiitteetObservable = this._lahetysAvainSubject.asObservable().pipe(
      withLatestFrom(this._asiakasObservable),
      switchMap(([lahetysAvain, asiakas]) => {
        if (!asiakas) {
          return observableOf<EmailLiite[]>([])
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksenLiitteetCollection(asiakas.avain, lahetysAvain)
        return this._firebase.firestoreCollection<EmailLiite>(uri).where('poistettu', '==', false).listen()
      }),
      takeUntil(this._ngUnsubscribe)
    )

    this.attachmentsTotalSizeIfTooBigObservable = combineLatest([
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      // this.virallinenTuloslaskelma.valueChanges.pipe(startWith(false)),
      // this.virallinenTase.valueChanges.pipe(startWith(false)),
      this.tilinpaatos.valueChanges.pipe(startWith(false)),
      this.taseErittely.valueChanges.pipe(startWith(false)),
      this.emailLiitteetObservable.pipe(startWith([] as EmailLiite[]))
    ]).pipe(
      map(([tuloslaskelma, /* virTulos, virTase,*/ tilinpaatos, taseErittely, liitteet]) => {
        let totalKoko = 0
        const averageReportSize = 100 * 1000 // 100 KB
        if (tuloslaskelma) { totalKoko += averageReportSize }
        // if (virTulos) { totalKoko += averageReportSize }
        // if (virTase) { totalKoko += averageReportSize }
        if (tilinpaatos) { totalKoko += averageReportSize }
        if (taseErittely) { totalKoko += averageReportSize }

        for (const liite of (liitteet || [])) {
          totalKoko += liite.koko || 512 * 1000 // 512 KB
        }

        if (totalKoko > Math.pow(10, 7)) {
          return this._currencyService.roundHalfUp(totalKoko / Math.pow(10, 6), 1)
        }
        return 0

      }),
      takeUntil(this._ngUnsubscribe),
    )

    /** Currently only used for Oy extra texts */
    // const lisaRaportitFormsObservable: Observable<string[]> = combineLatest([
    //   this._asiakasObservable,
    //   this.tuloslaskelma.valueChanges.pipe(startWith(false)),
    //   this.tilinpaatos.valueChanges.pipe(startWith(false)),
    //   this.taseErittely.valueChanges.pipe(startWith(false))
    // ]).pipe(
    //   map(([asiakas, tuloslaskelma, tilinpaatos, taseErittely]) => {
    //     const namesOfSelected: string[] = []
    //     if (asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
    //       if (tuloslaskelma) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.pitka-tuloslaskelma', kieli)) }
    //       if (tilinpaatos) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tilinpaatos-liitteineen', kieli)) }
    //       if (taseErittely) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tase-erittelyt', kieli)) }
    //     }
    //     return namesOfSelected
    //   }),
    //   distinctUntilChanged((a, b) => {
    //     return JSON.stringify(a) === JSON.stringify(b)
    //   })
    // )

    const rawSavedTextsInDatabaseObservable: Observable<Partial<TilinpaatosEmailTextAutosaveData>> = combineLatest([
      this._asiakasObservable,
      this.selectedTilikausiObservable
    ]).pipe(
      switchMap(([asiakas, tilikausi]) => {

        this.aihe.setValue('')
        this.tekstinAlku.setValue('')
        this.tekstinLoppu.setValue('')

        if (!asiakas) {
          return observableOf<TilinpaatosEmailTextAutosaveData>(null)
        }

        const kieli = asiakas.laskunKieli || 'fi'

        const uri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakas.avain, tilikausi)
        return this._firebase.firestoreDoc<TilinpaatosEmailTextAutosaveData>(uri).listen().pipe(
          withLatestFrom(this._kayttajaService.kirjanpitajanTiedotObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable),
          map(([autosaved, kirjanpitajanTiedot, nimitiedotMap]) => {

            const defaultInputStartTextKey = 'kirjanpito-lahetys-tilinpaatos.input-start'
            const defaultInputEndTextKey = 'kirjanpito-lahetys-tilinpaatos.input-end'

            const clientType = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
            const emailEndText = clientType === 'regular' && nimitiedotMap && kirjanpitajanTiedot.uid ? (nimitiedotMap.get(kirjanpitajanTiedot.uid)?.emailEndText?.[kieli] ?? '') : ''

            const newText: Partial<TilinpaatosEmailTextAutosaveData> = {
              aihe: autosaved?.aihe?.trim() || this._annaAihe(asiakas, tilikausi),
              tekstinAlku: autosaved?.tekstinAlku || this._translationService.lokalisoi(defaultInputStartTextKey, kieli),
              tekstinLoppu: autosaved?.tekstinLoppu || this._translationService.lokalisoi(defaultInputEndTextKey, kieli) + (emailEndText ? ('\n\n' + emailEndText) : ''),
              paivittaja: autosaved?.paivittaja || null,
              paivitettu: autosaved?.paivitettu || null
            }
            return newText
          })
        )

      }),
      lemonShare()
    )

    this.autosaveTimeTextObservable = combineLatest([rawSavedTextsInDatabaseObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([dbTextDocument, nimitiedotMap]) => {
        if (dbTextDocument?.paivitettu) {
          if (nimitiedotMap) {
            const kirjanpitaja = nimitiedotMap.get(dbTextDocument.paivittaja)
            if (kirjanpitaja) {
              return 'Tallentanut ' + kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi + ' ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
            }
          }
          return 'Tallennettu ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
        }
        return ''
      })
    )

    rawSavedTextsInDatabaseObservable.pipe(
      takeUntil(this._ngUnsubscribe),
    ).subscribe(texts => {
      if (!this.aihe.value?.trim()) {
        this.aihe.setValue(texts.aihe)
      }
      if (!this.tekstinAlku.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinAlku.setValue(texts.tekstinAlku)
      }
      if (!this.tekstinLoppu.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinLoppu.setValue(texts.tekstinLoppu)
      }
    })

    const liitetiedotObservable: Observable<TilinpaatosLiitetiedot> = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
          // console.log('load current from ' + uri)
          return this._firebase.firestoreDoc<TilinpaatosLiitetiedot>(uri).listen()
        }
        return observableOf<TilinpaatosLiitetiedot>(null)
      }),
      lemonShare()
    )

    const raportitFormsObservable: Observable<string[]> = combineLatest([
      this._asiakasObservable,
      this.tilintarkastetaanObservable,
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      // this.virallinenTuloslaskelma.valueChanges.pipe(startWith(false)),
      // this.virallinenTase.valueChanges.pipe(startWith(false)),
      this.tilinpaatos.valueChanges.pipe(startWith(false)),
      this.taseErittely.valueChanges.pipe(startWith(false)),
      // this.emailLiitteetObservable.pipe(startWith([]))
    ]).pipe(
      map(([asiakas, tilintarkastetaan, tuloslaskelma, /** virTulos, virTase,*/ tilinpaatos, taseErittely /* , liitteet*/]) => {
        const kieli = asiakas.laskunKieli || 'fi'
        const namesOfSelected: string[] = []
        if (tuloslaskelma) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tuloslaskelma', kieli)) }
        if (tilinpaatos) {
          if (tilintarkastetaan) {
            namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tilinpaatos-to-besigned', kieli))
          } else {
            namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tilinpaatos', kieli))
          }
        }
        if (taseErittely) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tase-erittelyt', kieli)) }
        return namesOfSelected
      }),
      distinctUntilChanged((a, b) => {
        return JSON.stringify(a) === JSON.stringify(b)
      })
    )

    combineLatest([
      this._asiakasObservable,
      raportitFormsObservable,
      // lisaRaportitFormsObservable,
      this.selectedTilikausiObservable,
      liitetiedotObservable
    ]).pipe(
      map(([asiakas, liitteet, /* lisaliitteet,*/ tilikausi, liitetiedot]) => {

        if (!tilikausi || !liitetiedot) {
          return null
        }

        const kieli = asiakas.laskunKieli || 'fi'
        // const kuukaudenNimi = this._dateService.annaKuukaudenNimiPaikallinen(perustiedot, kieli)

        // Format liitteet list (final two should have ' ja ' between them)
        const liitteetFormatted: string = this._createLiitteetListText(liitteet, liitetiedot.tilintarkastetaan, kieli)
        // const lisaliitteetFormatted: string = this._createLisaLiitteetListText(lisaliitteet)

        let key = 'kirjanpito-lahetys-tilinpaatos.email-generated' // Set no-dividends-no-audit text as default

        const jaetaanOsinkoja = liitetiedot.osingonjako === 'esilaskettu-prosentti' || liitetiedot.osingonjako === 'annettu-maara'

        if (jaetaanOsinkoja && liitetiedot.tilintarkastetaan) {
          key = 'kirjanpito-lahetys-tilinpaatos.email-generated-tarkastettava-ja-osingonjako'
        } else if (jaetaanOsinkoja) {
          key = 'kirjanpito-lahetys-tilinpaatos.email-generated-osingonjako'
        } else if (liitetiedot.tilintarkastetaan) {
          key = 'kirjanpito-lahetys-tilinpaatos.email-generated-tarkastettava'
        }

        const fullText = this._translationService.lokalisoi(key, kieli, {
          vuosi: tilikausi.loppuu.year,
          liitteetText: liitteetFormatted,
          osingonjaonMaara: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(liitetiedot.osinkojaYhteensa, kieli)
        })

        return fullText

      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(text => {
      this.mainAutotext = text
    })

    combineLatest([this.selectedTilikausiObservable, this._asiakasObservable, this.tilintarkastetaanObservable]).pipe(
      switchMap(([tilikausi, asiakas, tilintarkastetaan]) => {
        if (!tilikausi || !asiakas) {
          return observableOf<TilinpaatosEmailTextAutosaveData>(null)
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakas.avain, tilikausi)
        return this._firebase.firestoreDoc<TilinpaatosEmailTextAutosaveData>(uri).listen().pipe(
          take(1),
          map(autosaveData => {

            const output: Partial<TilinpaatosEmailTextAutosaveData> = {}

            output.lahetysAvain = autosaveData?.lahetysAvain

            this.piilokopioLahettajalle.enable()
            output.piilokopioValittu = autosaveData?.piilokopioValittu ?? true

            // Did we get data from db?
            if (autosaveData?.tulosValittu === true || autosaveData?.tulosValittu === false) {
              output.tulosValittu = autosaveData.tulosValittu
            } else { // Nope, use default
              output.tulosValittu = true
            }
            if (tilintarkastetaan) {
              if (autosaveData?.tilinpaatosValittu === true || autosaveData?.tilinpaatosValittu === false) {
                output.tilinpaatosValittu = autosaveData.tilinpaatosValittu
              } else {
                output.tilinpaatosValittu = false
              }
            } else {
              output.tilinpaatosValittu = false
            }

            return output
          })
        )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(autosaved => {
      this._lahetysAvainSubject.next(autosaved.lahetysAvain || this._firebase.firestoreCreateId())
      this._setCheckboxesFromAutosave(autosaved)
      this._allowAutoSaving = true
    })


    combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.selectedTilikausiObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable
    ]).pipe(
      switchMap(([asiakas, tilikausi, kirjanpitajatMap]) => {
        if (asiakas && tilikausi) {
          this._historyLoadingSubject.next(true)
          const uri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksetCollection(asiakas.avain)
          const query = this._firebase.firestoreCollection<TilinpaatosEmailLahetys>(uri)
            .where('tunniste', '==', this._dateService.localDateToNumber(tilikausi.loppuu) + '_tilinpaatos')
          return query.listen().pipe(
            map(lahetykset => {
              const sortedLahetykset = lahetykset.sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())
              return sortedLahetykset.map(lahetys => {
                const kirjanpitajaData = kirjanpitajatMap.get(lahetys.luoja)
                const historyEntry: TilinpaatosEmailLahetysHistoria = Object.assign({}, lahetys,
                  {
                    recipientsEmails: lahetys.recipients.reduce((a, b) => a + b.email + ' ', ''),
                    liitteet: [],
                    nimi: kirjanpitajaData.etunimi + ' ' + kirjanpitajaData.sukunimi,
                    luotuDateStr: this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(lahetys.luotu), 'fi'),
                  })
                // historyEntry.teksti = historyEntry.teksti.replace(/<br\s*\/?>/gi, '\n')
                return historyEntry
              })
            })
          )
        }
        return observableOf<TilinpaatosEmailLahetysHistoria[]>([])
      }),
      tap(data => {
        this.onkoSpostejaLahetetty.emit(data && data.length > 0)
      }),
      withLatestFrom(this._asiakasService.nykyinenAsiakasAvainObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([lahetysHistoriat, asiakasAvaintiedot]) => {
      if (!lahetysHistoriat?.length) {
        this._historyLoadingSubject.next(false)
        return
      }
      const liitteetPromises: Promise<void>[] = []
      for (const historia of lahetysHistoriat) {
        const historiaUri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksenLiitteetCollection(asiakasAvaintiedot.avain, historia.avain)
        const promise = this._firebase.firestoreCollection<EmailLiite>(historiaUri)
          .where('poistettu', '==', false)
          .get().then(liitteet => {
            historia.liitteet = liitteet || []
          })
        liitteetPromises.push(promise)
      }
      await Promise.all(liitteetPromises)

      this.sentThisTilikausi = lahetysHistoriat
      this._historyLoadingSubject.next(false)
    })

    this._taseErittelyObservable = combineLatest([this._asiakasObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (!asiakas || !tilikausi) {
          return observableOf<TilinpaatosTaseErittely>(null)
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
        return this._firebase.firestoreDoc<TilinpaatosTaseErittely>(uri).listen()
      }),
      lemonShare()
    )

    this._taseErittelyObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(taseErittely => {
      if (!!taseErittely) {
        this.taseErittely.enable()
        this.taseErittely.setValue(true)
      } else {
        this.taseErittely.setValue(false)
        this.taseErittely.disable()
      }
    })

  }

  private async _updateAutosaveData(data: Partial<TilinpaatosEmailTextAutosaveData>): Promise<void> {

    if (!this._allowAutoSaving) {
      return
    }

    const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    if (!tilikausi || !asiakas) {
      return
    }
    const uri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakas.avain, tilikausi)
    data.paivitettu = this._timestampService.now()
    return this._firebase.firestoreSetData(uri, data, { merge: true })
  }

  private _annaAihe(asiakas: Asiakas, tilikausi: Tilikausi) {
    return `${asiakas.nimi}, ${this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.tilinpaatos', asiakas.laskunKieli || 'fi')} ${tilikausi.loppuu.year}`
  }

  private _removeMarkers(text: string): string {
    const liitteetMarkerRegExp = new RegExp(this._selvitettavatTextLocationMarker, 'g')
    return text.replace(liitteetMarkerRegExp, '')
  }

  async _resetAutosave(): Promise<void> {

    const asiakasAvainTiedotPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

    const [asiakasAvainTiedot, tilikausi] = await Promise.all([asiakasAvainTiedotPromise, tilikausiPromise])

    if (!asiakasAvainTiedot || !tilikausi) {
      return
    }

    const uri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakasAvainTiedot.avain, tilikausi)
    return this._firebase.firestoreDeleteDoc(uri)
  }

  // get vastaanottaja() {
  //   return this.lahetysForm.get('vastaanottaja')
  // }
  get aihe() {
    return this.lahetysForm.get('aihe')
  }
  get tekstinAlku() {
    return this.lahetysForm.get('tekstinAlku')
  }
  get tekstinLoppu() {
    return this.lahetysForm.get('tekstinLoppu')
  }
  get tuloslaskelma() {
    return this.lahetysForm.get('tuloslaskelma')
  }
  // get virallinenTuloslaskelma() {
  //   return this.lahetysForm.get('virallinenTuloslaskelma')
  // }
  // get virallinenTase() {
  //   return this.lahetysForm.get('virallinenTase')
  // }
  get tilinpaatos() {
    return this.lahetysForm.get('tilinpaatos')
  }
  get taseErittely() {
    return this.lahetysForm.get('taseErittely')
  }
  get piilokopioLahettajalle() {
    return this.lahetysForm.get('piilokopioLahettajalle')
  }

  // lisaaVastaanottaja() {
  //   const field = this.vastaanottaja
  //   field.updateValueAndValidity()
  //   this.lisaaEmail(field)
  // }
  // poistaVastaanottaja(vastaanottaja: string): void {
  //   const index = this.vastaanottajat.indexOf(vastaanottaja)
  //   if (index >= 0) {
  //     this.vastaanottajat.splice(index, 1)
  //   }
  // }

  // async avaaTaseErittely() {
  //   const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
  //   this._router.navigate(['asiakkaat', asiakas.avain, 'kirjanpidon-ulkopuoliset-vahennykset'])
  // }

  // async reload() {
  //   const asiakas = await firstValueFrom(this._asiakasObservable)
  //   const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
  //   const uri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakas.avain, tilikausi)
  //   await this._firebase.firestoreDeleteDoc(uri)
  // }

  async laheta() {

    // Avoid multiple sends
    if (this._sendingInProgress) {
      return
    }

    this._sendingInProgress = true

    if (!this.vastaanottajat?.length) {
      this._snackbar.open('Sähköpostia ei voi lähettää. Asiakkaalla ei ole aktiivisia yhteyshenkilöitä.', 'OK', { duration: 10000 })
      return
    }
    if (!this.lahetysForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.lahetysForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      // Obtain required user, etc. data
      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
      const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
      const tallentavaKirjanpitaja = await this._kayttajaService.getKirjanpitajanTiedot()
      // const latestAlvIlmoitus = await firstValueFrom(this.alvIlmoitusObservable)
      const mailText = this._combineMailText()

      if (!asiakas) {
        throw new Error('Ei asiakasta tallennettaessa!')
      }
      if (!tallentavaKirjanpitaja) {
        throw new Error('Ei tallentavaa kirjanpitäjää tallennettaessa!')
      }

      // Create saved documents
      const aika = this._timestampService.now()
      const lahetysAvain = this._lahetysAvainSubject.value
      const tyojonoId: string = this._firebase.firestoreCreateId()
      const tyojonoData: TilinpaatosEmailLahetysTyojono = {
        asiakasAvain: asiakas.avain,
        tilinpaatosEmailLahetysAvain: lahetysAvain
      }
      const recipientStatuses: EmailLahetysStatus[] = []
      for (const recipient of this.vastaanottajat) {
        const emailLahetysStatus: EmailLahetysStatus = {
          email: recipient,
          status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
          viesti: null
        }
        recipientStatuses.push(emailLahetysStatus)
      }
      const tekstiWithBreaks = mailText.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      const tekstiWithBreaksWithoutMarkers = this._removeMarkers(tekstiWithBreaks)
      const lahetysData: TilinpaatosEmailLahetys = {
        avain: lahetysAvain,
        tilikausi: tilikausi,
        tunniste: this._dateService.localDateToNumber(tilikausi.loppuu) + '_tilinpaatos',
        luoja: tallentavaKirjanpitaja.uid,
        luotu: aika,
        lahetetty: null,
        lisaaPiilokopio: this.piilokopioLahettajalle.value,
        recipients: recipientStatuses,
        kieli: asiakas.laskunKieli || 'fi',
        aihe: this.aihe.value,
        teksti: tekstiWithBreaksWithoutMarkers,
        liitaTuloslaskelma: this.tuloslaskelma.value,
        liitaTilinpaatos: this.tilinpaatos.value,
        liitaTaseErittely: this.taseErittely.value
      }

      // Emergency break to avoid empty email sends
      if (!lahetysData.aihe.replace(/\s/g, '') || !lahetysData.teksti.replace(/(?:<br\/>|\s)/g, '')) {
        this._ladataanService.lopetaLataaminen()
        this._sendingInProgress = false
        this._snackbar.open('Lähetys keskeytetty! Aihe tai teksti puuttuu', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })
        return
      }

      const lahetysUri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksetLahetysAvaimella(asiakas.avain, this._lahetysAvainSubject.value)
      const tyojonoUri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetysTyojonoEnsimmainenUri(asiakas.avain, tyojonoId)

      // Add to batch
      const batch = this._firebase.firestoreBatch()

      batch.set(tyojonoUri, tyojonoData)
      batch.set(lahetysUri, lahetysData)

      // Save
      await batch.commit()

      this._ladataanService.lopetaLataaminen()
      this._snackbar.open('Sähköposti lähetettiin onnistuneesti.', null, { duration: 5000 })
      this._sendingInProgress = false
      await this._resetAutosave()
      this._lahetysAvainSubject.next(this._firebase.firestoreCreateId())
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
      this._sendingInProgress = false
    }
  }

  async downloadSentReport(lahetysAvain: string, reportType: TilinpaatosRaporttiName) {

    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasObservable)

    const downloadRequest: TilinpaatosEmailLahetysHistoryPdfRequest = {
      asiakasAvain: asiakas.avain,
      reportType: reportType,
      lahetysAvain: lahetysAvain
    }
    try {
      this._firebase.functionsCall<TilinpaatosEmailLahetysHistoryPdfRequest, string>('tilinpaatosEmailLahetysDownloadPdf', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        const filename = asiakas.nimi + '_' + reportType + '_' + lahetysAvain + '.pdf'
        this._fileSaverService.saveBase64As(data, filename, 'pdf')
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  async downloadAttachment(liite: EmailLiite) {
    const asiakas = await firstValueFrom(this._asiakasObservable)

    this._ladataanService.aloitaLataaminen()
    const downloadRequest: EmailLiiteDownloadRequest = {
      asiakasAvain: asiakas.avain,
      liite: liite
    }
    try {
      this._firebase.functionsCall<EmailLiiteDownloadRequest, any>('kirjanpitoDownloadEmailLiite', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        this._fileSaverService.saveBase64AsGuessedType(data, liite.nimi)
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  async deleteAttachment(file: EmailLiite) {
    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasObservable)
    const uri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, file.avain)
    const markAsDeleted: Partial<EmailLiite> = { poistettu: true }
    await this._firebase.firestoreUpdateData<Partial<EmailLiite>>(uri, markAsDeleted)
    this._ladataanService.lopetaLataaminen()
  }

  // private lisaaEmail(field: AbstractControl) {
  //   if ((field.value || '').trim()) {
  //     let arvo = field.value.trim()
  //     arvo = arvo.replace(/,/g, '').trim()
  //     if (!new UntypedFormControl(arvo, [Validators.email]).errors) {
  //       if (!this.talousraporttienVastaanottajat) {
  //         this.talousraporttienVastaanottajat = []
  //       }
  //       this.talousraporttienVastaanottajat.push(arvo)
  //       field.setValue('')
  //     }
  //   }
  // }

  // Load start when clicked
  public lataa(event) {
    if (event.target.files) {
      const list: FileList = event.target.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      this.uploadFile(tiedostot)
    }
  }

  async uploadFile(tiedostot: NgxFileDropEntry[]) {

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

    const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

    const batch = this._firebase.firestoreBatch()

    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)

      this._ladataanService.aloitaLataaminen()

      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      const avain = this._firebase.firestoreCreateId()
      const metadata: EmailLiite = {
        avain: avain,
        nimi: file.name,
        koko: file.size,
        ladattu: this._timestampService.now(),
        fileType: fileEnding,
        poistettu: false
      }

      const storageUri = this._kirjanpitoUriService.annaEmailLahetyksenLiiteStorageUri(asiakas.avain, metadata.avain, metadata.fileType)
      await firstValueFrom(this._tiedostojenLataamisService.upload(this._firebase, storageUri, file).doneObservable)

      const metadataUri = this._kirjanpitoUriService.annaTilinpaatosEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, avain)
      batch.set(metadataUri, metadata)
    }

    const autosaveUri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakas.avain, tilikausi)
    const data: Partial<TilinpaatosEmailTextAutosaveData> = {
      lahetysAvain: this._lahetysAvainSubject.value
    }
    batch.set(autosaveUri, data, { merge: true })

    await batch.commit()

    this._ladataanService.lopetaLataaminen()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  public downloadTilinpaatosPdf() {
    this._downloadPdf(RaporttiType.TILINPAATOS)
  }
  public downloadTulosPdf() {
    this._downloadPdf(RaporttiType.TULOS)
  }
  public downloadTaseErittelyPdf() {
    this._downloadPdf(RaporttiType.TASE_ERITTELY)
  }

  private async _downloadPdf(reportType: RaporttiType) {
    this._ladataanService.aloitaLataaminen()

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

    const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

    const pyynto: RaporttiRequest = {
      a: asiakas.avain,
      k: asiakas.laskunKieli || 'fi',
      w: reportType,
      s: this._dateService.localDateToNumber(tilikausi.alkaa),
      e: this._dateService.localDateToNumber(tilikausi.loppuu),
      ta: tilikausi.avain
    }

    try {
      return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', pyynto).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          this._ladataanService.lopetaLataaminen()
          if (data.base64File) {
            const fileName = data.fileName || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.')
          }
        }
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }

  }

  private _createLiitteetListText(selectedLiitteet: string[], tilintarkastetaan: boolean, kieli: TuettuKieli): string {
    if (!selectedLiitteet || !selectedLiitteet.length) {
      return ''
    }
    const commaSeparatedLiitteet = selectedLiitteet.join(', ')
    const finalComma = commaSeparatedLiitteet.lastIndexOf(', ')
    const liitteetList: string = selectedLiitteet.length > 1 ?
      `${commaSeparatedLiitteet.substring(0, finalComma)} ${this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.and', kieli)}${commaSeparatedLiitteet.substring(finalComma + 1)}` : // add " ja", if more than one liite
      selectedLiitteet.toString()
    const key = tilintarkastetaan ? 'kirjanpito-lahetys-tilinpaatos.liitteet-text-tilintarkastetaan' : 'kirjanpito-lahetys-tilinpaatos.liitteet-text'
    return this._translationService.lokalisoi(key, kieli, { liitteet: liitteetList })
  }
  // private _createLisaLiitteetListText(selectedLiitteet: string[]): string {
  //   if (!selectedLiitteet || !selectedLiitteet.length) {
  //     return ''
  //   }
  //   const commaSeparatedLiitteet = selectedLiitteet.join(', ')
  //   const finalComma = commaSeparatedLiitteet.lastIndexOf(', ')
  //   const liitteetList: string = selectedLiitteet.length > 1 ?
  //     `${commaSeparatedLiitteet.substring(0, finalComma)} ${this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.and', 'fi')}${commaSeparatedLiitteet.substring(finalComma + 1)}` : // add " ja", if more than one liite
  //     selectedLiitteet.toString()
  //   return this._translationService.lokalisoi('kirjanpito-lahetys-tilinpaatos.liitteena-myos-text', 'fi', { liitteet: liitteetList })
  // }

  async tryAutosave() {

    const asiakasAvainTiedotPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
    const kirjanpitajanTiedotPromise = this._kayttajaService.getKirjanpitajanTiedot()

    const aihe = this.aihe.value?.trim() || ''
    const alku = this.tekstinAlku.value || ''
    const loppu = this.tekstinLoppu.value || ''

    if (!aihe && !alku && !loppu) {
      return
    }

    const [asiakasAvainTiedot, kirjanpitajanTiedot, tilikausi] = await Promise.all([asiakasAvainTiedotPromise, kirjanpitajanTiedotPromise, tilikausiPromise])

    if (!asiakasAvainTiedot || !kirjanpitajanTiedot || !tilikausi) {
      return
    }

    const autosaveUri = this._kirjanpitoUriService.annaTilinpaatosEmailTekstiAutosaveUri(asiakasAvainTiedot.avain, tilikausi)
    const data: Partial<TilinpaatosEmailTextAutosaveData> = {
      aihe: aihe,
      tekstinAlku: alku,
      tekstinLoppu: loppu,
      paivittaja: kirjanpitajanTiedot.uid,
      paivitettu: this._timestampService.now()
    }

    return this._firebase.firestoreSetData(autosaveUri, data, { merge: true })

  }

  public showOrHideMainAutotext() {
    this.includeMainAutotext = !this.includeMainAutotext
  }

  private _combineMailText(): string {
    let output = ''
    output += this.tekstinAlku?.value || ''
    if (this.includeMainAutotext) {
      output = output.trimRight()
      output += '\n\n'
      output += this.mainAutotext || ''
    }
    output = output.trimRight()
    output += '\n\n'
    output += (this.tekstinLoppu?.value as string || '').trimLeft()
    return output
  }

  private _setCheckboxesFromAutosave(autosaved: Partial<TilinpaatosEmailTextAutosaveData>): void {

    if (!autosaved) {
      return
    }

    if (autosaved.piilokopioValittu !== undefined && autosaved.piilokopioValittu !== null) {
      this.piilokopioLahettajalle.setValue(autosaved.piilokopioValittu)
    }
    if (autosaved.tulosValittu !== undefined && autosaved.tulosValittu !== null) {
      this.tuloslaskelma.setValue(autosaved.tulosValittu)
    }
    if (autosaved.taseErittelyValittu !== undefined && autosaved.taseErittelyValittu !== null) {
      this.taseErittely.setValue(autosaved.taseErittelyValittu)
    }
    // if (autosaved.virTulosValittu !== undefined && autosaved.virTulosValittu !== null) {
    //   this.virallinenTuloslaskelma.setValue(autosaved.virTulosValittu)
    // }
    // if (autosaved.virTaseValittu !== undefined && autosaved.virTaseValittu !== null) {
    //   this.virallinenTase.setValue(autosaved.virTaseValittu)
    // }
    if (autosaved.tilinpaatosValittu !== undefined && autosaved.tilinpaatosValittu !== null) {
      this.tilinpaatos.setValue(autosaved.tilinpaatosValittu)
    }

  }

}
