import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { MatTabGroup } from '@angular/material/tabs'
import { VersionTarkistusPalvelu } from '../../_angular/service/version-tarkistus.palvelu'

@Component({
  templateUrl: './pankkiyhteydet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PankkiyhteydetComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup

  // wasListausSelectedOnce: boolean = false

  constructor(
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu
  ) { }

  ngOnInit() {

    // this.tabGroup.selectedIndexChange.asObservable().pipe(
    //   takeUntil(this.ngUnsubscribe),
    //   takeWhile(() => !this.wasListausSelectedOnce, false)
    // ).subscribe(value => {
    //   if (value === 1) {
    //     this.wasListausSelectedOnce = true
    //   }
    // })

    this._versionTarkistusPalvelu.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versio => {
      this._versionTarkistusPalvelu.tarkistaVersio(versio)
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
