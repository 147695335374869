import { Component, OnInit, AfterContentChecked, Inject, ErrorHandler, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuSpostiService } from '../../_jaettu/service/lasku/lasku-sposti.service'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'

import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'
import { Kirjanpitaja } from '../../_jaettu-lemonator/model/kirjanpitaja'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { LaskuEmailEsikatselutiedot } from '../../_jaettu-angular/laskut/esikatselu/email.perinteinen.component'

import {
  Lasku,
  LaskuBase,
  Laskuasetukset,
  EmailLahetysStatus,
  EmailLahetysStatusKoodi,
  LaskuasetuksetSpostille,
  LaskunSahkopostipohja,
  LaskunSahkpostipohjanVari
} from '../../_jaettu/model/lasku'

import { ReplaySubject, Subject } from 'rxjs'
import { takeUntil, auditTime } from 'rxjs/operators'

export interface LaskuSpostiData {
  asiakas: Asiakas
  kirjanpitaja: Kirjanpitaja
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
}

@Component({
  templateUrl: './lasku.sposti.dialog.html',
  styleUrls: ['./lasku.sposti.dialog.css']
})
export class LaskuSpostiDialog implements OnInit, AfterContentChecked, OnDestroy {

  private spostiasetukset: LaskuasetuksetSpostille = null
  private tilaaEsikatseluPaivitysSubject: ReplaySubject<number> = new ReplaySubject(1)
  private ngUnsubscribe = new Subject<void>()

  esikatselutiedot: ReplaySubject<LaskuEmailEsikatselutiedot> = new ReplaySubject(1)
  emailForm: UntypedFormGroup
  asetuksissaOnOletustietojaVirhe = false
  fieldErrors: FieldErrors = {}

  email = {
    aihe: '',
    otsikko: '',
    teksti: '',
    vastaanottajat: []
  }

  varit: { [key in LaskunSahkpostipohjanVari]: string } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'header_taustavari': 'rgb(250,239,89)',
    'valiviiva': 'rgb(46,204,255)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'header_fontti': 'rgb(0,0,0)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'otsikko_fontti': 'rgb(0,0,0)'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuSpostiData,
    private dialogRef: MatDialogRef<LaskuSpostiDialog>,
    private validationService: FormValidationService,
    private laskuSpostiService: LaskuSpostiService,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler,
    private shared: LaskuSharedService,
    private ladataanService: LadataanService,
    private timestampService: TimestampService
  ) {

    if (!data.asetukset.spostiasetukset) {
      data.asetukset.spostiasetukset = {}
    }

    this.spostiasetukset = data.asetukset.spostiasetukset[data.kasiteltava.kieli]
    if (!this.spostiasetukset) {
      this.spostiasetukset = {
        aihe: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.aihe', data.kasiteltava),
        kieli: data.kasiteltava.kieli,
        teksti: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.teksti', data.kasiteltava),
        otsikko: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.otsikko', data.kasiteltava),
        template: LaskunSahkopostipohja.PERINTEINEN
      }
    }

    this.email.aihe = this.korvaaMuuttujat(this.spostiasetukset.aihe || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.aihe', data.kasiteltava))
    this.email.otsikko = this.korvaaMuuttujat(this.spostiasetukset.otsikko || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.otsikko', data.kasiteltava))
    this.email.teksti = this.korvaaMuuttujat(this.spostiasetukset.teksti || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.teksti', data.kasiteltava))

    if (data.kasiteltava.asiakas.laskunVastaanottajat) {
      for (const vastaanottaja of data.kasiteltava.asiakas.laskunVastaanottajat) {
        this.email.vastaanottajat.push(vastaanottaja)
      }
    }

    if (!data.kasiteltava.email) {
      data.kasiteltava.email = {
        start: this.timestampService.now(),
        done: null,
        aihe: '',
        otsikko: '',
        teksti: '',
        slogan: '',
        template: null,
        vastaanottajat: null
      }
    }
    data.kasiteltava.email.aihe = this.email.aihe
    data.kasiteltava.email.otsikko = this.email.otsikko
    data.kasiteltava.email.teksti = this.email.teksti
    data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
    data.kasiteltava.email.replyTo = this.data.kirjanpitaja.email
    data.kasiteltava.email.footer = 'footer1'
    data.kasiteltava.email.logoInFooter = true

  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('lasku.sposti', this.emailForm)
  }

  validateEmail = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  ngOnInit() {

    this.emailForm = new UntypedFormGroup({
      'aihe': new UntypedFormControl(this.email.aihe, [Validators.required]),
      'otsikko': new UntypedFormControl(this.email.otsikko, [Validators.required]),
      'teksti': new UntypedFormControl(this.email.teksti, [Validators.required]),
      'lisaavastaanottaja': new UntypedFormControl('', [this.validateEmail])
    })

    this.emailForm.get('aihe').valueChanges.subscribe(value => {
      this.email.aihe = value || ''
      this.data.kasiteltava.email.aihe = this.email.aihe
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('otsikko').valueChanges.subscribe(value => {
      this.email.otsikko = value || ''
      this.data.kasiteltava.email.otsikko = this.email.otsikko
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('teksti').valueChanges.subscribe(value => {
      this.email.teksti = value || ''
      this.data.kasiteltava.email.teksti = this.email.teksti
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('lisaavastaanottaja').valueChanges.subscribe((value: string) => {
      if (value && (value.indexOf(',') !== -1 || value.indexOf(' ') !== -1)) {
        this.lisaaEmail(this.emailForm.get('lisaavastaanottaja'))
      }
    })

    this.tilaaEsikatseluPaivitysSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      auditTime(250)
    ).subscribe(() => {
      if (this.data.asetukset && this.data.juurilasku && this.data.kasiteltava) {
        this.esikatselutiedot.next({
          asetukset: this.data.asetukset,
          juurilasku: this.data.juurilasku,
          kasiteltava: this.data.kasiteltava,
          maksumuistutus: false
        })
      }
    })

    this.tilaaEsikatseluPaivitysSubject.next(1)

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  lisaa() {
    const field = this.emailForm.get('lisaavastaanottaja')
    field.updateValueAndValidity()
    this.lisaaEmail(field)
  }

  private lisaaEmail(field: AbstractControl) {
    if (!field.valid) {
      return
    }
    if ((field.value || '').trim()) {
      let arvo = field.value.trim()
      arvo = arvo.replace(/,/g, '').trim()
      this.email.vastaanottajat.push(arvo)
    }
    field.setValue('')
    this.data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  poistaAsiakas(vastaanottaja: string): void {
    const index = this.email.vastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.email.vastaanottajat.splice(index, 1)
    }
    this.data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  private muodostaVastaanottaja() {
    const vastaanottajat: EmailLahetysStatus[] = []
    for (const vastaanottaja of this.email.vastaanottajat) {
      vastaanottajat.push({
        email: vastaanottaja.toLowerCase(),
        status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
        viesti: null
      })
    }
    return vastaanottajat
  }

  send() {

    if (!this.email || !this.email.vastaanottajat || this.email.vastaanottajat.length < 1) {
      this.emailForm.get('lisaavastaanottaja').setErrors({ 'min': true })
      return
    }

    // if (this.asetustenOletuksetService.tarkistaOnkoAsetuksetOletuksia(this.data.asetukset)) {
    //   this.asetuksissaOnOletustietojaVirhe = true
    //   return
    // }

    this.ladataanService.aloitaLataaminen()
    const vastaanottajat: EmailLahetysStatus[] = this.muodostaVastaanottaja()

    const postmarkTemplateId = 8372593 // this.laskuSpostiService.annaSahkopostipohjanPostmarkNumero(this.spostiasetukset.template, this.data.kasiteltava.kieli)
    this.laskuService.merkitseLaskuLahetetyksiSpostilla(this.data.asiakas, this.data.kirjanpitaja, postmarkTemplateId, this.data.juurilasku, this.data.kasiteltava, vastaanottajat, this.email.aihe, this.email.otsikko, this.email.teksti, this.data.asetukset.slogan, this.varit).then(result => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close(true)
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })

  }

  private korvaaMuuttujat(korvattava: string): string {
    return this.laskuSpostiService.korvaaMuuttujat(korvattava, this.data.asetukset, this.data.juurilasku, this.data.kasiteltava)
  }

}
