import { Component, ErrorHandler, ChangeDetectionStrategy, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl, FormGroup } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'

import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'
import { ListausAsiakas, AsiakkaatDataSourceService, AsiakkaatDataSourceStateService, ListausAsiakkaanSarake, KuukausiruudunTiedot } from './asiakkaat.datasource.service'
import { KuukausiJaValmiusaste } from './asiakkaat.component'
import { AsiakkaanMuistiinpanotDialogData, AsiakkaanMuistiinpanotDialog } from './asiakkaan-muistiinpanot.dialog'
import { KirjanpitajanSarake } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajanSarakkeenMuokkausDialogData, KirjanpitajanSarakkeenMuokkausDialog } from './kirjanpitajan-sarakkeen-muokkaus.dialog'
import { AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog, AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData } from './asiakkaan-kirjanpitajan-sarakkeen-arvon-muokkaus.dialog'
import { DateService } from 'app/_shared-core/service/date.service'
import { KayttajienTietojenLatauslaajuus, AsiakkaidenSpostiosoitteidenExportReq, AsiakkaidenSpostiosoitteidenExportResp } from 'app/_jaettu-lemonator/model/asiakas'

import { Observable, combineLatest, Subject, firstValueFrom } from 'rxjs'
import { map, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators'

import * as XLSX from 'xlsx'
import { AsiakkaanKirjanpitostatusKuukaudelleDialogData, AsiakkaanKirjanpitostatusKuukaudelleDialog } from './asiakkaan-kirjanpitostatus-kuukaudelle.dialog'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { MatMenuTrigger } from '@angular/material/menu'
import { KirjanpitoKuukausiStripService } from 'app/_angular/service/kirjanpito/kirjanpito-kuukausistrip.service'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

interface ListauksenSummatiedot {
  asiakkaita: number
  //  veloitus: number
  //  keskihinta: number
}

@Component({
  selector: 'app-asiakkaat-listaus-old',
  templateUrl: './asiakkaat-listaus.component-old.html',
  // styleUrls: ['./asiakkaat-listaus.component.css'], // TYYLIT OVAT PÄÄTYYLISSÄ TEHON TAKIA!!!
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakkaatListausComponentOld implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  kuukaudetObservable: Observable<KuukausiJaValmiusaste[]>
  lataaObservable: Observable<boolean>
  asiakkaatObservable: Observable<ListausAsiakas[]>

  asiakkaidenLukumaaraObservable: Observable<number>
  minwidthObservable: Observable<string>
  pageSizeObservable: Observable<number>
  listauksenSummatiedotObservable: Observable<ListauksenSummatiedot>
  onkoKehittajaObservable: Observable<boolean>
  kaikkiSarakkeetObservable: Observable<KirjanpitajanSarake[]>
  aktiivisetSarakkeetObservable: Observable<KirjanpitajanSarake[]>

  time: number
  latausForm: FormGroup<{ latauslaajuus: FormControl<number> }>
  valmiusprosenttiAlgoFormControl: FormControl<'u' | 'v'>

  private latauslaajuus: KayttajienTietojenLatauslaajuus = KayttajienTietojenLatauslaajuus.KaikkiOsoitteet
  private _asiakkaatDataSourceService: AsiakkaatDataSourceService
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _firebase: FirebaseLemonator,
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _router: Router,
    private _asiakasDataSourceStateService: AsiakkaatDataSourceStateService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _currencyService: CurrencyService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _copyService: KopioijaPalvelu,
    private _kirjanpitoKuukausStripService: KirjanpitoKuukausiStripService,
    private _asiakasUriService: AsiakasUriService,
    private _asiakkaatDataSourceStateService: AsiakkaatDataSourceStateService
  ) {

    // this.valmiusprosenttiAlgoFormControl = new FormControl<'u' | 'v'>('u')

    this._asiakkaatDataSourceService = new AsiakkaatDataSourceService(
      this._firebase, this._errorHandler, this._kirjautunutKayttajaService, this._currencyService,
      this._dateService, this._copyService, this._asiakasUriService,
      this._kirjanpitoKuukausStripService, this._asiakasDataSourceStateService)

    this._asiakasDataSourceStateService.vapaaTekstihakuOldListObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(filter => {
      this._asiakkaatDataSourceService.dataSourceForOldList.filter = filter || null
    })

  }

  openContextMenu(event: MouseEvent, menuTrigger: MatMenuTrigger, span: HTMLElement, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    event.preventDefault()
    span.style.left = event.clientX + 'px'
    span.style.top = event.clientY + 'px'
    console.log('HERE', { asiakas: asiakas, kuukausi: kuukausi })
    menuTrigger.menuData = { asiakas: asiakas, kuukausi: kuukausi }
    menuTrigger.openMenu()
  }

  ngOnInit() {

    const latauslaajuusFormControl = new FormControl<number>(KayttajienTietojenLatauslaajuus.KaikkiOsoitteet)
    this.latausForm = new FormGroup<{ latauslaajuus: FormControl<number> }>({ 'latauslaajuus': latauslaajuusFormControl })
    latauslaajuusFormControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this.latauslaajuus = value
    })

    this.kaikkiSarakkeetObservable = this._asiakasDataSourceStateService.kaikkiSarakkeetObservable
    this.aktiivisetSarakkeetObservable = this._asiakkaatDataSourceService.aktiivisetSarakkeetObservable

    this.lataaObservable = this._asiakkaatDataSourceService.lataaObservable
    this.asiakkaatObservable = this._asiakkaatDataSourceService.dataSourceForOldList.connect()
    this.asiakkaidenLukumaaraObservable = this.asiakkaatObservable.pipe(
      map(asiakkaat => {
        if (asiakkaat) {
          return asiakkaat.length
        }
        return 0
      })
    )

    this.kuukaudetObservable = combineLatest([
      this._asiakkaatDataSourceService.kaikkiAsiakkaatObservable,
      this._asiakkaatDataSourceService.naytettavatKuukaudetObservable
      // this.valmiusprosenttiAlgoFormControl.valueChanges.pipe(startWith(this.valmiusprosenttiAlgoFormControl.value))
    ]).pipe(
      map(([asiakkaat, kuukaudet, /* algoritmi */]) => {

        // console.time('Lasketaan valmiusasteita.')
        // console.log('ASIAKKAITA: ' + asiakkaat.length)
        // console.log('KUUKAUDET: ' + kuukaudet)

        const kuukausiMap: Map<number, { kk: KuukausiJaValmiusaste, kok: number, valm: number }> = new Map()
        for (const kuukausi of kuukaudet) {
          kuukausiMap.set(this._dateService.localMonthToNumber(kuukausi), { kk: kuukausi as KuukausiJaValmiusaste, kok: 0, valm: 0 })
        }

        for (const asiakas of asiakkaat) {
          if (asiakas.u || asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' || !asiakas.kuukaudet) {
            continue
          }
          // console.log(asiakas.kuukaudet)
          for (const kuukausi of asiakas.kuukaudet) {

            // Deaktivoitu tai ei osa tilikautta
            if (kuukausi.bd || !kuukausi.bot) {
              continue
            }

            const kk = kuukausiMap.get(kuukausi.k)
            if (!kk) {
              continue
            }

            // if (algoritmi === 'u') {
            if (
              !kuukausi.po && // Ei pöytälaatikossa
              !kuukausi.t && // Ei tauolla
              kuukausi.sv && // Sopimus voimassa
              kuukausi.h // On hinta
            ) {
              if (kuukausi.kp) {
                kk.valm += 10
              }
              kk.kok += 10
            }
            // } else {
            //   if (kuukausi.h) {
            //     if (kk) {
            //       if (kuukausi.kp) {
            //         kk.valm += kuukausi.h
            //       }
            //       kk.kok += kuukausi.h
            //     }
            //   }
            // }

          }
        }

        for (const kk of kuukausiMap.values()) {
          kk.kk.valmiusaste = kk.kok > 0 ? kk.valm / kk.kok * 100 : 0 // Math.floor(Math.random() * 100) + 1
          kk.kk.valmiusasteMuotoiltu = this._currencyService.formatoiDesimaali(kk.kk.valmiusaste, 2, 'fi')
        }

        // console.timeEnd('Lasketaan valmiusasteita.')

        return kuukaudet as KuukausiJaValmiusaste[]

      })
    )

    this.listauksenSummatiedotObservable = this.asiakkaatObservable.pipe(
      map(asiakkaat => {
        const paluuarvo: ListauksenSummatiedot = {
          asiakkaita: 0
          // ,
          // keskihinta: 0,
          // veloitus: 0
        }
        if (asiakkaat) {
          // for (const asiakas of asiakkaat) {
          //   if (asiakas.h) {
          //     const money = asiakas.h.split('(')
          //     const num = money[0].replace('€', '').trim().replace(',', '.')
          //     paluuarvo.veloitus += Number(num)
          //     paluuarvo.asiakkaita++
          //   }
          // }
          // if (paluuarvo.asiakkaita > 0) {
          //   paluuarvo.keskihinta = paluuarvo.veloitus / paluuarvo.asiakkaita
          // }
          // HUOM!! Käytetään asiakkaiden lukumäärään kaikki asiakkaita, vaikka keskimääräinen hinta lasketaan
          // vain asiakkaista, joilla on hinta!
          paluuarvo.asiakkaita = asiakkaat.length
        }
        return paluuarvo
      })
    )

    this.pageSizeObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        if (kirjanpitaja && kirjanpitaja.rooli === KirjanpitajanRooli.SUPER) {
          return 250
        }
        return 9999999
      }),
      distinctUntilChanged()
    )

    this._asiakkaatDataSourceService.dataSourceForOldList.paginator = this.paginator

    // const currentSort = this._asiakkaatDataSourceService.dataSourceForOldList.sort
    // if (currentSort) {
    //   this.sort.sort({
    //     id: currentSort.active,
    //     disableClear: false,
    //     start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    //   })
    // } else {
    //   this.sort.sort({
    //     disableClear: false,
    //     id: 'nimi',
    //     start: 'asc'
    //   })
    // }
    this._asiakkaatDataSourceService.dataSourceForOldList.sort = this.sort

    this.minwidthObservable = combineLatest([this.kuukaudetObservable, this.aktiivisetSarakkeetObservable]).pipe(
      map(([kuukaudet, sarakkeet]) => {
        if (kuukaudet && sarakkeet) {
          return ((kuukaudet.length * 50) + (sarakkeet.length * 80) + 925) + 'px'
        }
        return '925px'
      })
    )

    this.onkoKehittajaObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        if (kirjanpitaja && (kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2')) {
          return true
        }
        return false
      }),
      startWith(false)
    )

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
    this._asiakkaatDataSourceService?.destroy()
  }

  clickRuutu(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    event.stopPropagation()
    event.preventDefault()
    this.avaaKuukausistatus(event, asiakas, kuukausi)
  }

  /**
   * TODO: MYÖS KOMPONENTISSA KirjanpitoKuukausiStripComponent !!
   * @param event TODO:
   * @param asiakas
   * @param kuukausi
   */
  avaaKuukausistatus(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {

    const data: AsiakkaanKirjanpitostatusKuukaudelleDialogData = {
      asiakas: asiakas,
      kuukausi: this._copyService.cloneObjectDeep(kuukausi)
    }
    // if (asiakas.kuukausiruudut && asiakas.kuukausiruudut[kuukaudenAvain]) {
    this._dialog.open(AsiakkaanKirjanpitostatusKuukaudelleDialog, { data: data, autoFocus: false })
    // } else {
    //   const d: AsiakkaanKirjanpitostatusLisaaTilikausiDialogData = {
    //     asiakas: asiakas,
    //     status: status,
    //     kuukausi: { year: vuosi, month: kk }
    //   }
    //   const dialogRef = this._dialog.open(AsiakkaanKirjanpitostatusLisaaTilikausiDialog, { data: d, autoFocus: false })
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this._dialog.open(AsiakkaanKirjanpitostatusKuukaudelleDialog, { data: data, autoFocus: false })
    //     }
    //   })
    // }
  }
  avaaUudessaIkkunassa(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    event.preventDefault()
    event.stopPropagation()

    const lokaali: LocalMonth = this._dateService.numberToLocalMonth(kuukausi.k)
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/asiakkaat/${asiakas.k}/kirjanpito/${lokaali.year}/${lokaali.month}`])
    )

    window.open(url, '_blank')
  }

  async muokkaaMuistiinpanoja(event: MouseEvent, listausAsiakas: ListausAsiakas) {
    event.stopPropagation()
    event.preventDefault()
    const dialogData: AsiakkaanMuistiinpanotDialogData = {
      asiakas: listausAsiakas
    }
    this._dialog.open(AsiakkaanMuistiinpanotDialog, { autoFocus: false, data: dialogData })
  }

  kayttajatJaTositteet(asiakas: ListausAsiakas) {
    // this._asiakasComponentDataResolve.asetaOlemassaolevaData(asiakas)
    // this._asiakasService.asetaNykyinenAsiakas(asiakas)
    // if (asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
    //   this._router.navigate(['/asiakkaat/', asiakas.k, 'kirjanpito'])
    // } else {
    this._router.navigate(['/asiakkaat/', asiakas.k, 'tositteet', 'lataa'])
    // }
  }

  async annaKaikkienKayttajienSahkopostiosoitteet() {

    this._ladataanService.aloitaLataaminen()

    try {

      const naytettavatAsiakkaat = await firstValueFrom(this.asiakkaatObservable)
      const asiakkaidenAvaimet = naytettavatAsiakkaat.map(asiakas => asiakas.k)

      const requestData: AsiakkaidenSpostiosoitteidenExportReq = {
        scope: this.latauslaajuus,
        asiakkaidenAvaimet: asiakkaidenAvaimet
      }

      const response = await this._firebase.functionsCall<AsiakkaidenSpostiosoitteidenExportReq, AsiakkaidenSpostiosoitteidenExportResp>('asiakasListausOsoitteetExport', requestData)
      this._ladataanService.lopetaLataaminen()

      if (!response || response.e) {
        this._errorHandler.handleError(response.e)
      } else {

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(response.data)

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Käyttäjät')

        /* save to file */
        XLSX.writeFile(wb, 'Asiakkaiden_kayttajat.xlsx')

      }
    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    }

  }

  async lisaaSarake() {
    this.muokkaaSaraketta(null)
  }

  async muokkaaSaraketta(sarake: KirjanpitajanSarake) {

    const kirjanpitajaUid = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)

    const dialogData: KirjanpitajanSarakkeenMuokkausDialogData = {
      kirjanpitajaUid: kirjanpitajaUid,
      sarake: sarake
    }
    this._dialog.open(KirjanpitajanSarakkeenMuokkausDialog, { autoFocus: false, data: dialogData })

  }

  async muokkaaSarakeSolua(event: MouseEvent, asiakas: ListausAsiakas, sarake: ListausAsiakkaanSarake) {

    event.preventDefault()
    event.stopPropagation()

    const kirjanpitajaUid = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)
    const kaikkiSarakkeet = await firstValueFrom(this.kaikkiSarakkeetObservable)
    for (const s of kaikkiSarakkeet) {
      if (s.avain === sarake.sarakeAvain) {

        const data: AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData = {
          asiakas: asiakas,
          sarake: s,
          tilanAvain: sarake.tilaAvain,
          kirjanpitajaUid: kirjanpitajaUid
        }

        this._dialog.open(AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog, { data: data, autoFocus: false })
        break

      }
    }

  }

}
