import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { EmailLahetysStatus } from '../../_jaettu/model/lasku'

@Component({
  templateUrl: './lasku.koko-virhe.dialog.html'
})
export class LaskuKokoVirheDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmailLahetysStatus,
    private dialogRef: MatDialogRef<LaskuKokoVirheDialog>
  ) {

  }

  ngOnInit() {

  }

}
