<div style="max-width: 630px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      Määrittele {{ tauko.tyyppi === 'tauko' ? 'tauko' : 'pöytälaatikkokausi'}}
    </lemon-dialog-header>

    <p *ngIf="tauko.tyyppi === 'tauko'" class="dark-gray-text" style="text-align: center;">Tauon aikana asiakkaalle ei lähetetä jatkuvia laskuja. Lemonaid-tunnukset ovat asiakkaan käytössä koko kauden ajan rajoitetuin oikeuksin.</p>
    <p *ngIf="tauko.tyyppi === 'poytalaatikko'" class="dark-gray-text" style="text-align: center;">Pöytälaatikkokauden aikana asiakkaalle ei lähetetä jatkuvia laskuja. Lemonaid-tunnukset ovat asiakkaan käytössä koko kauden ajan rajoitetuin oikeuksin.</p>

    <div [formGroup]="taukoFormGroup" style="margin: 0 50px;">

      <mat-form-field style="max-width: 200px; margin-left: 10px; margin-right: 10px; margin-bottom: 22px; margin-top: 44px;">
        <mat-label>Aloituspvm</mat-label>
        <input [name]="inputname" #taukoAlkaaInputti type="text" [min]="minimiAlkaaPaivamaara" [matDatepicker]="pickerAlkaa" formControlName="alkaa" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="pickerAlkaa"></mat-datepicker-toggle>
        <mat-error *ngIf="taukoFormGroup.get('alkaa')?.errors?.required && (taukoFormGroup.get('alkaa')?.dirty || taukoFormGroup.get('alkaa')?.touched)">
          Aloituspäivä puuttuu
        </mat-error>
        <mat-error *ngIf="taukoFormGroup.get('alkaa')?.errors?.min && (taukoFormGroup.get('alkaa')?.dirty || taukoFormGroup.get('alkaa')?.touched)">
          {{ tauko.tyyppi === 'tauko' ? 'Tauko' : 'Pöytälaatikkokausi'}} ei voi alkaa samaan aikaan tai ennen edellisen loppumista. 1
        </mat-error>
        <mat-error *ngIf="taukoFormGroup.get('alkaa')?.errors?.matDatepickerMin && (taukoFormGroup.get('alkaa')?.dirty || taukoFormGroup.get('alkaa')?.touched)">
          {{ tauko.tyyppi === 'tauko' ? 'Tauko' : 'Pöytälaatikkokausi'}} ei voi alkaa samaan aikaan tai ennen edellisen loppumista. 2
        </mat-error>
        <mat-datepicker #pickerAlkaa></mat-datepicker>
      </mat-form-field>

      <!-- <pre>{{ taukoFormGroup.get('alkaa')?.errors | json }}</pre> -->

      <mat-form-field style="max-width: 200px; margin-left: 10px; margin-right: 10px; margin-bottom: 22px; margin-top: 44px;">
        <mat-label>Lopetuspvm</mat-label>
        <input [name]="inputname" #taukoLoppuuInputti type="text" [max]="maksimiLoppuuPaivamaara" [matDatepicker]="pickerLoppuu" formControlName="loppuu" matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="pickerLoppuu"></mat-datepicker-toggle>
        <mat-error *ngIf="taukoFormGroup.get('loppuu')?.errors?.required && (taukoFormGroup.get('loppuu')?.dirty || taukoFormGroup.get('loppuu')?.touched)">
          Lopetuspäivä puuttuu
        </mat-error>
        <mat-error *ngIf="taukoFormGroup.get('loppuu')?.errors?.min && (taukoFormGroup.get('loppuu')?.dirty || taukoFormGroup.get('loppuu')?.touched)">
          {{ tauko.tyyppi === 'tauko' ? 'Tauko' : 'Pöytälaatikkokausi'}} ei voi alkaa samaan aikaan tai ennen edellisen loppumista. 1
        </mat-error>
        <mat-error *ngIf="taukoFormGroup.get('loppuu')?.errors?.matDatepickerMin && (taukoFormGroup.get('loppuu')?.dirty || taukoFormGroup.get('loppuu')?.touched)">
          {{ tauko.tyyppi === 'tauko' ? 'Tauko' : 'Pöytälaatikkokausi'}} ei voi alkaa samaan aikaan tai ennen edellisen loppumista. 2
        </mat-error>
        <mat-datepicker #pickerLoppuu></mat-datepicker>
      </mat-form-field>

      <div *ngIf="tauko.tyyppi === 'tauko'">
        <mat-form-field style="max-width: 200px; margin-left: 10px; margin-right: 10px; margin-bottom: 22px;">
          <mat-label>Muistutuspvm</mat-label>
          <input [name]="inputname" type="text" [matDatepicker]="pickerMuistutus" formControlName="muistutuspvm" matInput data-lpignore="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerMuistutus"></mat-datepicker-toggle>
          <mat-error *ngIf="taukoFormGroup.get('muistutuspvm')?.errors?.required && (taukoFormGroup.get('muistutuspvm')?.dirty || taukoFormGroup.get('muistutuspvm')?.touched)">
            Muistutuspäivä puuttuu
          </mat-error>
          <mat-error *ngIf="taukoFormGroup?.get('muistutuspvm')?.errors?.minDate && (taukoFormGroup?.get('muistutuspvm')?.dirty || taukoFormGroup?.get('muistutuspvm')?.touched)">
            Pvm pitää olla tulevaisuudessa
          </mat-error>
          <mat-datepicker #pickerMuistutus></mat-datepicker>
        </mat-form-field>

        <i class="icon ion-ios-information-circle-outline info" [matTooltip]="muistutusPvmText" aria-hidden="true"></i>
      </div>
      <!-- <pre>{{ taukoFormGroup.get('loppuu')?.errors | json }}</pre> -->
    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
    <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
  </div>
</div>