import { Component, ErrorHandler, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { ArkistoFile, ArkistoService } from 'app/_jaettu-lemonator/service/arkisto.service'
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { BehaviorSubject, Subject, firstValueFrom, takeUntil } from 'rxjs'

export interface ArkistoDialogData {
  asiakas: Asiakas
  metadata?: ArkistoFile
}

interface MainForm {
  // tilikausiAvain: FormControl<string>
  lisatiedot: FormControl<string>
  // uploadType: FormControl<ArkistoFileType>
}

@Component({
  templateUrl: './arkisto-upload.dialog.html'
})
export class ArkistoUploadDialog implements OnInit {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: FormGroup
  latausVirheObservable: BehaviorSubject<string> = new BehaviorSubject(null)
  private _fileLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  fileLoadingObservable = this._fileLoadingSubject.asObservable().pipe(takeUntil(this._ngUnsubscribe))
  private _saveStarted: boolean = false

  // tilikaudet: Tilikausi[]

  // typeOptions: { name: string, id: ArkistoFileType }[] = this._arkistoService.getArkistoFileTypesWithNames()

  uploadedFile: { avain: string, fileName: string }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: ArkistoDialogData,
    private _dialogRef: MatDialogRef<ArkistoUploadDialog>,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _ladataanService: LadataanService,
    private _timestampService: TimestampService,
    private _lemonatorFirebase: FirebaseLemonator,
    private _arkistoService: ArkistoService,
    private _errorHandler: ErrorHandler,
    private _formValidationService: FormValidationService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) {
    _dialogRef.disableClose = true
  }

  ngOnInit() {

    this.form = new FormGroup<MainForm>({
      // 'tilikausiAvain': new FormControl<string>(this._data?.metadata?.tilikausiAvain ?? null),
      'lisatiedot': new FormControl<string>(this._data?.metadata?.lisatiedot ?? null, Validators.required),
      // 'uploadType': new FormControl<ArkistoFileType>(this._data?.metadata?.type ?? null, Validators.required)
    })

    // if (this._data?.metadata?.fileName) {
    //   this.uploadType.disable()
    // }

    // this.tilikaudet = this._data.asiakas.tilikaudet
  }

  // get uploadType(): AbstractControl<ArkistoFileType> {
  //   return this.form?.get('uploadType')
  // }


  async upload(event: Event) {
    this.latausVirheObservable.next(null)
    this._fileLoadingSubject.next(true)
    this._ladataanService.aloitaLataaminen()

    const inputTypeFile = event?.target as HTMLInputElement
    if (inputTypeFile?.files) {
      const list: FileList = inputTypeFile.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      try {
        await this._uploadFile(tiedostot)

        // this.uploadType.disable()
        this._fileLoadingSubject.next(false)
        this._ladataanService.lopetaLataaminen()

      } catch (err) {
        this._ladataanService.lopetaLataaminen()
        this._fileLoadingSubject.next(false)
        this.latausVirheObservable.next('Lataamisen aikana tapahtui virhe: ' + err)
      }
    }
  }

  private async _uploadFile(tiedostot: NgxFileDropEntry[]) {
    this._ladataanService.aloitaLataaminen()

    for (const tiedosto of tiedostot) {
      // const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)
      const avain = this._lemonatorFirebase.firestoreCreateId()

      this.uploadedFile = { avain: avain, fileName: file.name }

      // const type = this.uploadType.value
      const bucket = this._arkistoService.getBucketName() // this._arkistoService.getBucketName(type)
      const storageUri = this._arkistoService.getArkistoFileStorageUri(this._data.asiakas.avain, { avain: avain })

      const runningUpload = this._tiedostojenLataamisService.upload(this._lemonatorFirebase, storageUri, file, bucket)
      await firstValueFrom(runningUpload.doneObservable)
    }
  }

  async save() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (this._saveStarted) {
      return
    }
    this._saveStarted = true

    this._ladataanService.aloitaLataaminen()

    const kirjanpitaja = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    try {

      const asiakasAvain = this._data?.asiakas.avain
      const metadataAvain = this.uploadedFile.avain ?? this._data.metadata.avain

      const metadata: ArkistoFile = {
        avain: metadataAvain,
        fileName: this.uploadedFile.fileName ?? this._data.metadata.fileName,
        asiakasAvain: asiakasAvain,
        lisatiedot: this.form.get('lisatiedot').value,
        // type: this.uploadType.value,
        poistettu: false,
        paivitetty: this._timestampService.now(),
        luotu: this._data?.metadata?.luotu ?? this._timestampService.now(),
        paivittaja: kirjanpitaja.uid,
        luoja: this._data?.metadata?.luoja ?? kirjanpitaja.uid,
      }

      // if (this.form.get('tilikausiAvain').value) {
      //   metadata.tilikausiAvain = this.form.get('tilikausiAvain').value
      // }

      const uri = this._arkistoService.getArkistoMetadataUri(asiakasAvain, metadataAvain)
      await this._lemonatorFirebase.firestoreSetData(uri, metadata)

      this._dialogRef.close()

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    }
  }

}
