<div class="laheta-card">
  <div class="autosave-time">{{ autosaveTimeTextObservable | async }}</div>
  <div class="laheta-title light-blue-text">Lähetä asiakkaalle sähköposti
    <!-- <button style="margin-left: 20px;" (click)="_resetAutosave()">Poista tietokannasta</button> -->
  </div>
  <div class="laheta-body">
    <form [formGroup]="lahetysForm">
      <div style="margin: 0 10px;">
        <div *ngIf="vastaanottajat?.length; else eiVastaanottajia">
          <mat-form-field style="margin-bottom: 15px">
            <mat-label>Vastaanottajat</mat-label>
            <input type="text" [name]="namename" formControlName="vastaanottajat" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" />
          </mat-form-field>
        </div>
        <ng-template #eiVastaanottajia>
          <div style="color: #f44336;  margin-bottom: 2em; margin-left: 10px;">
            <div style="font-weight: bold;">Ei vastaanottajia.</div>
            <div style="font-size: 13px;">(Lisää käyttäjä, jolla on Yhteyshenkilö-rooli, navigoimalla <a [routerLink]="['/asiakkaat', asiakasAvain, 'kayttajat']">Lemonaid -> Käyttäjät</a>. Varmista, että Aktiivinen-täppä on päällä. Vaihtoehtoisesti voit käyttää yleistä vastaanotto-osoittetta, lisää sellainen <a [routerLink]="['/asiakkaat', asiakasAvain, 'kirjanpitotiedot']">Asetukset -> Kirjanpito
                -> Kirjanpitosähköpostien lisävastaanottajat</a>)</div>
          </div>
        </ng-template>
        <div style="display: flex; justify-content: space-between; margin-top: -10px; margin-bottom: -10px;">
          <mat-form-field style="margin-bottom: 15px; width: 420px">
            <mat-label>Lähettäjä</mat-label>
            <input type="email" [name]="namename" formControlName="lahettaja" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" />
          </mat-form-field>
          <mat-checkbox style="margin-top: 10px" formControlName="piilokopioLahettajalle">Piilokopio lähettäjälle</mat-checkbox>
        </div>
        <mat-form-field style="margin-bottom: 15px">
          <mat-label>Aihe</mat-label>
          <input [name]="namename" (blur)="tryAutosave()" formControlName="aihe" matInput data-lpignore="true" autocomplete="jokuihantajutonjutthei" required class="email-aihe" />
          <!-- <button mat-icon-button matSuffix (click)="resetAihe()" title="Palauta alkuperäinen teksti">
            <mat-icon>settings_backup_restore</mat-icon>
          </button> -->
          <mat-error *ngIf="aihe?.errors?.required && (aihe?.dirty || aihe?.touched)">
            Lisää aihe.
          </mat-error>
        </mat-form-field>
        <div style="margin: 10px -20px 10px -20px; padding: 20px 20px 0 20px; border: 2px solid rgb(217,217,217);">
          <mat-form-field class="no-outline no-margin">
            <textarea #tekstiTextareaAlku (blur)="tryAutosave()" formControlName="tekstinAlku" placeholder="Teksti 1" matInput data-lpignore="true" matInput required cdkTextareaAutosize #autosizeAlku="cdkTextareaAutosize" [cdkAutosizeMinRows]="1"></textarea>
            <!-- <button mat-icon-button matSuffix (click)="resetTeksti1()" title="Palauta alkuperäinen teksti">
              <mat-icon>settings_backup_restore</mat-icon>
            </button> -->
            <mat-error *ngIf="tekstinAlku?.errors?.required && (tekstinAlku?.dirty || tekstinAlku?.touched)">
              Lisää teksti.
            </mat-error>
          </mat-form-field>
          <div class="autotext">
            <div [style.display]="includeMainAutotext ? 'block' : 'none'">{{ mainAutotext }}</div>
            <button mat-button [title]="includeMainAutotext ? 'Poista automaattisesti luotu teksti sähköpostista' : 'Lisää automaattisesti luotu teksti sähköpostiin'" style="position: absolute; top: 0px; right: 0px; margin: 0; line-height: 28px; min-width: 28px; font-size: 24px; padding: 0;" (click)="showOrHideMainAutotext()">{{ includeMainAutotext ? 'x' : '+' }}</button>
          </div>

          <div class="autotext" *ngIf="account9940TextObservable | async; let account9940Text">
            <div [style.display]="include9940Text ? 'block' : 'none'" [innerHTML]="account9940Text"></div>
            <button mat-button [title]="include9940Text ? 'Poista teksti sähköpostista' : 'Lisää teksti sähköpostiin'" style="position: absolute; top: 0px; right: 0px; margin: 0; line-height: 28px; min-width: 28px; font-size: 24px; padding: 0;" (click)="showOrHide9940Box()">{{ include9940Text ? 'x' : '+' }}</button>
          </div>

          <mat-form-field class="no-outline no-margin">
            <textarea #tekstiTextareaLoppu (blur)="tryAutosave()" formControlName="tekstinLoppu" placeholder="Teksti 2" matInput data-lpignore="true" matInput required cdkTextareaAutosize #autosizeLoppu="cdkTextareaAutosize" [cdkAutosizeMinRows]="4"></textarea>
            <!-- <button mat-icon-button matSuffix (click)="resetTeksti2()" title="Palauta alkuperäinen teksti">
              <mat-icon>settings_backup_restore</mat-icon>
            </button> -->
            <mat-error *ngIf="tekstinLoppu?.errors?.required && (tekstinLoppu?.dirty || tekstinLoppu?.touched)">
              Lisää teksti.
            </mat-error>
          </mat-form-field>
          <div app-kirjanpito-lahetys-signature [kirjanpitajanNimitiedot]="nykyisenKirjanpitajanNimitiedotObservable | async" [kieli]="asiakkaanKieliObservable | async"></div>
        </div>
        Liitteet
        <div class="check-wrapper" *ngIf="onkoMuuKuinOsakeyhtioObservable | async as eiOleOsakeyhtio">
          <div *ngIf="eiOleOsakeyhtio.result" style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="tuloslaskelma">Tuloslaskelma</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadTulosPdf()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div *ngIf="eiOleOsakeyhtio.result" style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="virallinenTase">Virallinen tase</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadVirTasePdf()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div *ngIf="eiOleOsakeyhtio.result" style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="virallinenTuloslaskelma">Virallinen tulos</mat-checkbox>
            <button class="lataa-pdf-button" type="button" (click)="downloadVirTulosPdf()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="veroilmoitus">Veroilmoitus</mat-checkbox>
            <button class="lataa-pdf-button" type="button" type="button" (click)="downloadVeroilmoitusPdf()" [disabled]="!(voiLahettaaObservable | async)" [matTooltip]="!(voiLahettaaObservable | async)? pdfEiVoiLadata : ''">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div *ngIf="eiOleOsakeyhtio.result" style="display: flex; flex-direction: row">
            <mat-checkbox class="liitteet-list-item" formControlName="taseErittely">Tase-erittely</mat-checkbox>
            <button class="lataa-pdf-button" type="button" type="button" [disabled]="taseErittely.disabled" (click)="downloadTaseErittelyPdf()">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <!-- <div *ngIf="taseErittely.disabled">
            Tase-erittelyä ei ole tehty. Tee erittely napauttamalla "Tilinpäätös". -->
          <!-- <button mat-button (click)="avaaTaseErittely()" style="text-decoration: underline;">Avaa erittely</button> -->
          <!-- </div> -->
        </div>
        <div class="attachment-wrapper">
          <div class="attachment-row" *ngFor="let liite of emailLiitteetObservable | async;">
            <button mat-icon-button class="remove-btn small-icon-button" (click)="deleteAttachment(liite)"><i class="icon ion-ios-trash icon-btn"></i></button>
            <div class="attachment-text">{{ liite.nimi }}</div>
            <div class="attachment-size"> {{ liite.koko > (100 * 1000) ? ((liite.koko / (1000 * 1000)) | ldecimall: 1 : 'fi') : ((liite.koko / (1000 * 1000)) | ldecimall: 3 : 'fi') }} MT</div>
            <button class="lataa-pdf-button small-icon-button" type="button" type="button" (click)="downloadAttachment(liite)">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
        </div>


        <button mat-button (click)="fileInput.click()" type="button">
          + Lisää liitetiedosto
        </button>

      </div>
    </form>
    <div class="error-msg" *ngIf="attachmentsTotalSizeIfTooBigObservable | async; let totalSize">Sähköpostin liitteiden kokonaiskoko ei saa ylittää 10 MT (Nykyinen koko: {{ totalSize }} MT)</div>
    <div class="error-msg" *ngIf="!(voiLahettaaObservable | async)">Lähetä veroilmoitus ennen asiakassähköpostin lähettämistä</div>
    <div style="display: flex; justify-content: space-evenly; padding-top: 20px;">
      <button class="primarybutton" mat-button (click)="laheta()" type="button" [disabled]="!(voiLahettaaObservable | async) || (attachmentsTotalSizeIfTooBigObservable | async) > 0">Lähetä</button>
    </div>
  </div>
</div>

<!-- <button mat-button (click)="reload()" type="button">
  TYHJENNÄ TÄSTÄ
</button> -->

<div app-veroilmoitus-aikaisemmin-lahetetyt-spostit [perustiedotObservable]="perustiedotObservable" (onkoSpostejaLahetetty)="sahkopostejaOnLahetettyPaivittyi($event)"></div>

<input #fileInput type="file" style="visibility: hidden;" accept="*" multiple (change)="lataa($event)" />