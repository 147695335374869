import { Component, OnInit, OnDestroy, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LEMONATOR_CF_API, LemonHttpService } from '../../_angular/service/lemon-http.service'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'


import { of, Subject } from 'rxjs'
import { map, switchMap, takeUntil } from 'rxjs/operators'

import { AsiakkaalleLemonatoristaLahetettyLasku } from '../../_jaettu-lemonator/model/asiakas'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'
import { AlvLaskunLahetysService } from 'app/_jaettu-lemonator/service/alv-laskun-lahetys.service'
import { Lasku } from 'app/_jaettu/model/lasku'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface LaskuLataaLahetystiedostaDialogData {
  asiakasAvain: string
  alvIlmoituksenAvain: string
}

@Component({
  templateUrl: './lasku.lataa-lahetystiedosta.dialog.html'
})
export class LaskuLataaLahetystiedostaDialog implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuLataaLahetystiedostaDialogData,
    private dialogRef: MatDialogRef<LaskuLataaLahetystiedostaDialog>,
    private errorHandler: ErrorHandler,
    private laskuService: LaskuService,
    private laskuUriService: LaskuUriService,
    private http: LemonHttpService,
    private lemonTranslation: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this._firebaseLemonaid.firestoreDoc<AsiakkaalleLemonatoristaLahetettyLasku>('customers/' + this.data.asiakasAvain + '/customer-sent-vat-invoice/' + this.data.alvIlmoituksenAvain).listen().pipe(
      switchMap(lahetystieto => {
        if (lahetystieto?.laskuAvain) {
          const laskuUri = this.laskuUriService.getLaskuUri(AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId, lahetystieto.laskuAvain)
          return this._firebaseLemonaid.firestoreDoc<Lasku>(laskuUri).listen().pipe(
            map(lasku => {
              if (lasku) { delete lasku['haku'] }
              return lasku
            })
          )
        }
        return of<Lasku>(null)
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: juurilasku => {
        if (juurilasku) {
          const kasiteltava = juurilasku
          if (
            (kasiteltava.print && kasiteltava.print.done) ||
            (kasiteltava.email && kasiteltava.email.done) ||
            (kasiteltava.sahkoinen && kasiteltava.sahkoinen.done)
          ) {
            const pdfUri = this.laskuUriService.getPdfUri(AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId, kasiteltava.avain)
            const url = '/api/1/laskut/lataaPdf/' + pdfUri
            return this.http.getBinary('/laskuLataaPdf?a=' + encodeURIComponent(url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONATOR_CF_API).then(result => {
              const nimi = this.laskuService.annaPdfTiedostonNimi(juurilasku, kasiteltava, this.lemonTranslation.nykyinenKieli)
              this._fileSaverService.saveAs(result, nimi)
              this.dialogRef.close()
            }).catch(err => {
              this.errorHandler.handleError(err)
            })
          }
        }
      },
      error: err => {
        this.errorHandler.handleError(err)
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
