import { LocalDate } from '../../_shared-core/model/common'
import { DateService } from '../../_shared-core/service/date.service'

export interface Korko {
  prosentti: number
  alkaa: LocalDate
  loppuu: LocalDate
}

export enum Virheteksti {
  PÄIVÄMÄÄRÄ_VIRHEELLINEN = 'Virheellinen päivämäärä',
  PÄIVÄMÄÄRÄ_EI_SALLITTU = 'Ei sallittu päivämäärä',
  KORKO_PUUTTUU = 'Korko puuttuu',
}

/**
 * Vahvistettuja korkoja
 * -
 * Peruskorko
 * ({@link https://www.suomenpankki.fi/fi/Tilastot/korot/taulukot2/korot_taulukot/peruskoron_muutokset_uusi_fi/})
 *
 * Muut korot ({@link https://www.suomenpankki.fi/fi/Tilastot/korot/taulukot2/korot_taulukot/viitekorko_fi/})
 * - Viitekorko
 * - Lisäkorko, ei sovittu
 * - Viivästyskorko
 * - Lisäkorko, kaupallinen
 * - Viivästyskorko, kaupallinen
 *
 * Lisätietoa ({@link https://www.suomenpankki.fi/fi/Tilastot/korot/})
 */
export class VahvistettuKorkoService {
  private _peruskorot: Korko[] = [
    // 2024
    { prosentti: 4.25, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 3.75, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 2.5, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: -0.5, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: -0.5, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: -0.5, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: -0.25, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: -0.25, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: -0.25, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: -0.25, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: -0.25, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 0.25, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 0.25, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 0.5, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 0.5, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  private _viitekorot: Korko[] = [
    // 2024
    { prosentti: 4.5, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 4, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 2.5, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 0, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 0, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 0.5, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 0.5, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 0.5, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 0.5, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 0.5, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  private _eiSovitutLisäkorot: Korko[] = [
    // 2024
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  private _viivästyskorot: Korko[] = [
    // 2024
    { prosentti: 11.5, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 11, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 9.5, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 7, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 7, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 7.5, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 7.5, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 7.5, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 7.5, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 7.5, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  private _kaupallisetLisäkorot: Korko[] = [
    // 2024
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  private _kaupallisetViivästyskorot: Korko[] = [
    // 2024
    { prosentti: 12.5, alkaa: { day: 1, month: 1, year: 2024 }, loppuu: { day: 30, month: 6, year: 2024 } },
    // 2023
    { prosentti: 12, alkaa: { day: 1, month: 7, year: 2023 }, loppuu: { day: 31, month: 12, year: 2023 } },
    { prosentti: 10.5, alkaa: { day: 1, month: 1, year: 2023 }, loppuu: { day: 30, month: 6, year: 2023 } },
    // 2022
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2022 }, loppuu: { day: 31, month: 12, year: 2022 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2022 }, loppuu: { day: 30, month: 6, year: 2022 } },
    // 2021
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2021 }, loppuu: { day: 31, month: 12, year: 2021 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2021 }, loppuu: { day: 30, month: 6, year: 2021 } },
    // 2020
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2020 }, loppuu: { day: 31, month: 12, year: 2020 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2020 }, loppuu: { day: 30, month: 6, year: 2020 } },
    // 2019
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2019 }, loppuu: { day: 31, month: 12, year: 2019 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2019 }, loppuu: { day: 30, month: 6, year: 2019 } },
    // 2018
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2018 }, loppuu: { day: 31, month: 12, year: 2018 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2018 }, loppuu: { day: 30, month: 6, year: 2018 } },
    // 2017
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2017 }, loppuu: { day: 31, month: 12, year: 2017 } },
    { prosentti: 8, alkaa: { day: 1, month: 1, year: 2017 }, loppuu: { day: 30, month: 6, year: 2017 } },
    // 2016
    { prosentti: 8, alkaa: { day: 1, month: 7, year: 2016 }, loppuu: { day: 31, month: 12, year: 2016 } },
    { prosentti: 8.5, alkaa: { day: 1, month: 1, year: 2016 }, loppuu: { day: 30, month: 6, year: 2016 } },
    // 2015
    { prosentti: 8.5, alkaa: { day: 1, month: 7, year: 2015 }, loppuu: { day: 31, month: 12, year: 2015 } },
    { prosentti: 8.5, alkaa: { day: 1, month: 1, year: 2015 }, loppuu: { day: 30, month: 6, year: 2015 } },
    // 2014
    { prosentti: 8.5, alkaa: { day: 1, month: 7, year: 2014 }, loppuu: { day: 31, month: 12, year: 2014 } },
    { prosentti: 8.5, alkaa: { day: 1, month: 1, year: 2014 }, loppuu: { day: 30, month: 6, year: 2014 } },
  ]
  constructor(
    private _dateService: DateService,
  ) { }

  private onkoSallittuPäivämäärä(localDate: LocalDate): boolean {
    return localDate.year >= 2014 && localDate.year <= 2200
  }

  private async annaKorko(pvm: LocalDate, korot: Korko[]): Promise<Korko> {
    if (!this._dateService.onkoLocalDateValidi(pvm)) {
      throw new Error(`${Virheteksti.PÄIVÄMÄÄRÄ_VIRHEELLINEN}: ${JSON.stringify(pvm)}`)
    }

    if (!this.onkoSallittuPäivämäärä(pvm)) {
      throw new Error(`${Virheteksti.PÄIVÄMÄÄRÄ_EI_SALLITTU}: ${JSON.stringify(pvm)}`)
    }

    const korko = korot.find(k => this._dateService.onkoLocalDateKahdenValissa(pvm, k.alkaa, k.loppuu))
    if (korko) {
      return korko
    } else {
      throw Error(`${Virheteksti.KORKO_PUUTTUU}: ${JSON.stringify(pvm)}`)
    }
  }

  private async annaKorkoProsentti(korko: Promise<Korko>): Promise<number> {
    return (await korko).prosentti
  }

  public annaPeruskorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._peruskorot)
  }

  public annaPeruskorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaPeruskorko(pvm))
  }

  public annaViitekorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._viitekorot)
  }

  public annaViitekorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaViitekorko(pvm))
  }

  public annaEiSovittuLisäkorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._eiSovitutLisäkorot)
  }

  public annaEiSovittuLisäkorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaEiSovittuLisäkorko(pvm))
  }

  public annaViivästyskorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._viivästyskorot)
  }

  public annaViivästyskorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaViivästyskorko(pvm))
  }

  public annaKaupallinenLisäkorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._kaupallisetLisäkorot)
  }

  public annaKaupallinenLisäkorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaKaupallinenLisäkorko(pvm))
  }

  public annaKaupallinenViivästyskorko(pvm: LocalDate): Promise<Korko> {
    return this.annaKorko(pvm, this._kaupallisetViivästyskorot)
  }

  public annaKaupallinenViivästyskorkoProsentti(pvm: LocalDate): Promise<number> {
    return this.annaKorkoProsentti(this.annaKaupallinenViivästyskorko(pvm))
  }
}
