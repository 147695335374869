<ng-container *ngIf="naytettavaKirjausObservable | async; let valittuKirjaus else eiValittuaTemplate">
  <ng-container *ngIf="naytettavatTositteetJaSivutObservable | async; let naytettavatTositteetJaSivut else ladataanMetadataaTemplate">
    <div style="display: flex; height: calc(100vh - 40px); flex-direction: column; flex-grow: 1;" [class.sivuja]="naytettavatTositteetJaSivut.length">

      <div #mainDiv class="tositteet-container">

        <div app-kirjanpito-tosite-naytto [isMainEnhancedOn]="isMainEnhancedOn" [naytettavatTositteetJaSivut]="naytettavatTositteetJaSivut" [scrollContainerRef]="mainDivRef" [naytettavaKirjausObservable]="naytettavaKirjausObservable"></div>

        <div class="kirjauksen-tositteet-drop-area" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
          <button mat-icon-button class="drop-button" (click)="lisaaTiedostoja()">
            <mat-icon>add</mat-icon>
          </button>
          <div class="drop-text">Lisää uusi tosite plus-merkistä tai raahaa se tähän</div>
        </div>
        <div class="kirjauksen-tositteet-drop-area-initally-hidden" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
          <div class="drop-text">Pudota tiedosto tähän</div>
          <i class="drop-here-icon fa fa-arrow-down"></i>
        </div>

      </div>

    </div>
  </ng-container>
</ng-container>

<ng-template #ladataanMetadataaTemplate>
  <div class="kirjauksen-tositteet-ladataan-metadataa">
    <div style="margin-bottom: 1em;">Ladataan kirjaukseen liitettyjen tositteiden tietoja</div>
    <mat-progress-bar mode="indeterminate" style="margin: 20px 0; width: calc(100% - 40px);"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #eiValittuaTemplate>
  <div class="kirjauksen-tositteet-ei-valittua">
    <div><span class="virhe-teksti">Virhe!&nbsp;</span>Valitse kirjaus</div>
  </div>
</ng-template>

<input style="visibility:hidden; height: 1px; position: absolute; left: -9999px;" (change)="fileChanged($event)" type="file" accept="image/*,application/pdf" #fileInput multiple="multiple" />