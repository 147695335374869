<div class="list-page kapea" style="max-width: 1200px;">

  <h2>Asiakkaan verojen maksutiedot</h2>

  <div style="padding: 2em 0;">
    <h4>Viitenumerot</h4>
    <mat-progress-bar *ngIf="!yhteystietojaLadataan" mode="indeterminate"></mat-progress-bar>
    <div style="margin: 0 2em;" *ngIf="yhteystietoObservable | async, let yhteystiedot;">
      <div style="padding-bottom: 1em; color: gray; font-size: 80%;">Tiedot haettu OmaVerosta: {{ yhteystiedot.luotu | ltimestamptime | async }}</div>
      <table>
        <tbody>
          <tr>
            <td style="padding-right: 50px;">Oma-aloitteiset verot</td>
            <td>{{yhteystiedot.omaAloitteisetViite}}</td>
          </tr>
          <tr>
            <td>Tulovero</td>
            <td>{{yhteystiedot.yhteisonTuloveroViite || yhteystiedot.henkilonTuloveroViite}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div style="padding: 2em 0;">
    <h4>Ennakkoveron maksuohjelma</h4>
    <mat-progress-bar *ngIf="!ohjelmaaLadataan" mode="indeterminate"></mat-progress-bar>
    <div style="margin: 0 2em;" *ngIf="ohjelmaObservable | async, let ohjelma;">
      <div style="padding-bottom: 1em; color: gray; font-size: 80%;">Viimeksi haettu OmaVerosta: {{ ohjelma.luotu | ltimestamptime | async }}</div>
      <table>
        <thead>
          <tr>
            <th style="width: 150px;">Eräpäivä</th>
            <th style="width: 150px;">Summa</th>
            <th style="width: 150px;">Avoinna</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let era of ohjelma.erat">
            <td>{{ era.erapaiva | lokaalipvm | async }}</td>
            <td style="text-align: right;">{{ era.summa | lmoney | async }}</td>
            <td style="text-align: right;">{{ era.avoinna | lmoney | async }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>