<mat-dialog-content>

  <lemon-dialog-header>
    <span>Valitse kuvake napauttamalla</span>
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <div style="display: flex; flex-flow: row wrap;">
      <img *ngFor="let valittava of valittavissa" (click)="valitseKuva(valittava)" [src]="'/assets/maksutapakuvakkeet/' + valittava" style="width: 70px; border-radius: 70px; height: 70px; vertical-align: middle; cursor: pointer; margin: 10px;" class="light-blue-background" />
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" (click)="peruuta()" mat-button>Peruuta</button>
</mat-dialog-actions>