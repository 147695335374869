<mat-dialog-content>

  <lemon-dialog-header>
    Asiakkaan {{asiakas?.n}} muistiinpanot
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate style="margin: 0;">
    <div style="padding-left: 3em; padding-right: 3em;">
      <mat-form-field>
        <mat-label>Muistiinpanot</mat-label>
        <textarea [class.red-text]="vari === 'pun'" [class.blue-text]="vari === 'sin'" [class.light-blue-text]="vari === 'vsi'" [class.yellow-text]="vari === 'kel'" [class.green-text]="vari === 'vih'" [class.purple-text]="vari === 'vio'" #muistiinpanotTextarea formControlName="muistiinpanot" matInput
          cdkTextareaAutosize [name]="namename" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"></textarea>
      </mat-form-field>
      <div style="display: flex; align-items: center;">
        <div style="padding-right: 20px;">Väri:</div>
        <mat-radio-group formControlName="vari">
          <mat-radio-button [value]="null"><span>Oletus</span></mat-radio-button>
          <mat-radio-button value="pun"><span class="red-text">Punainen</span></mat-radio-button>
          <mat-radio-button value="sin"><span class="blue-text">Sininen</span></mat-radio-button>
          <mat-radio-button value="vsi"><span class="light-blue-text">Vaalean sininen</span></mat-radio-button>
          <mat-radio-button value="kel"><span class="yellow-text">Keltainen</span></mat-radio-button>
          <mat-radio-button value="vih"><span class="green-text">Vihreä</span></mat-radio-button>
          <mat-radio-button value="vio"><span class="purple-text">Violetti</span></mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div style="height: 10px;"></div>
  </form>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Sulje</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>