import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './kayttaja.haluatko-varmasti-lahettaa-taikalinkin.dialog.html'
})
export class KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _dialogRef: MatDialogRef<KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog>
  ) {

  }

  ngOnInit() {

  }

  tallenna() {
    this._dialogRef.close('yes-please-send')
  }

}
