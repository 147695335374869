import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { Tilitapahtuma, TilitapahtumanTuontityodata, TilitapahtumanLisatiedot } from '../../_jaettu/model/tiliote'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { ViitenumeroService } from '../../_shared-core/service/viitenumero.service'
import { EraajotYksiKerrallaanSuorittajaData, EraajotYksiKerrallaanSuorittajaTyyppi } from '../../_jaettu/model/eraajot'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { ListausTilitapahtuma } from './tilitapahtumat.uusi.datasource.service'

import { firstValueFrom, Observable } from 'rxjs'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

@Component({
  templateUrl: './tilitapahtuma-tiedot.dialog.html'
})
export class TilitapahtumanTiedotDialog implements OnInit {

  form: FormGroup
  yliajoviite: string
  name = '' + Math.random()
  lisatiedotObservable: Observable<TilitapahtumanLisatiedot>
  filteredData: Tilitapahtuma

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ListausTilitapahtuma,
    private _dialogRef: MatDialogRef<TilitapahtumanTiedotDialog>,
    private _errorHandler: ErrorHandler,
    private _kopioija: KopioijaPalvelu,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _firebaseLemonator: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _viitenumeroService: ViitenumeroService,
    private _validationService: FormValidationService,
    private _timestampService: TimestampService
  ) {

    this.lisatiedotObservable = this.data.lisatiedotObservable

    delete this.data.lisatiedotObservable
    this.filteredData = this._kopioija.cloneObjectDeep(data)
    this.data.lisatiedotObservable = this.lisatiedotObservable

    delete (this.filteredData as ListausTilitapahtuma).kknumero
    delete (this.filteredData as ListausTilitapahtuma).dataUri
    delete (this.filteredData as ListausTilitapahtuma).dataUrl

  }

  private validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

  ngOnInit() {

    const viitenumeroFormControl = new FormControl<string>(this.data.yliajoviite, [this.validateViitenumero])
    this.form = new FormGroup({
      'viitenumero': viitenumeroFormControl
    })
    viitenumeroFormControl.valueChanges.subscribe(value => {
      this.yliajoviite = value
    })

  }

  get viitenumero(): FormControl<string> {
    return this.get<string>('viitenumero')
  }

  private get<T>(name: string): FormControl<T> {
    if (this.form) {
      return this.form.get<string>(name) as FormControl<T>
    }
    return null
  }

  async kohdistaTilitapahtuma() {

    this.form.updateValueAndValidity()

    if (this.form.invalid) {
      // console.log(this.form.errors)
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()
    try {
      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

      if (!asiakas) {
        this._ladataanService.lopetaLataaminen()
        return
      }

      console.log('Asiakas löytyi')

      console.log('customers/' + asiakas.avain + '/bank-transactions/' + this.data.avain + '/bank-transaction-details/' + this.data.avain)
      const lisatiedot = await this._firebaseLemonaid.firestoreDoc<TilitapahtumanLisatiedot>('customers/' + asiakas.avain + '/bank-transactions/' + this.data.avain + '/bank-transaction-details/' + this.data.avain).get()

      if (!lisatiedot) {
        this._ladataanService.lopetaLataaminen()
        return
      }

      console.log('Lisätiedot löytyi')

      // Luo driver data
      const driverAvain = 'tilitapahtumien-tuonti_' + asiakas.avain

      const ajoData: EraajotYksiKerrallaanSuorittajaData = {
        luotu: this._timestampService.now(),
        asiakasAvain: asiakas.avain,
        asiakasId: asiakas.asiakasId + '',
        driverAvain: driverAvain,
        tyyppi: EraajotYksiKerrallaanSuorittajaTyyppi.AUTOMAATTIRESKONTRA
      }

      const tyodata: TilitapahtumanTuontityodata = {
        tilitapahtuma: this.filteredData,
        lisatty: this._firebaseLemonaid.firestoreServerTimestamp(),
        lisatiedot: lisatiedot,
        kohdistaUudelleen: true
      }

      if (this.yliajoviite) {
        tyodata.yliajoviite = this.yliajoviite
      }

      await this._firebaseLemonaid.firestoreSetData('tyojono_data/' + asiakas.avain + '/tilitapahtuman_tuonti/' + this.data.avain, tyodata)
      await this._firebaseLemonator.firestoreSetData('tyojono_ajurit/' + driverAvain, ajoData, { merge: true })

      // Sulje kaikki
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()

    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }

  }

}
