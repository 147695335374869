<mat-form-field id=projektit [formGroup]="form" style="width: 150px; border: none !important;" class="no-outline">
  <mat-label>Näytä projektit</mat-label>
  <mat-select [style.visibility]="select.value?.nimi === 'Kaikki' ? 'hidden' : 'visible' " formControlName="valittuProjekti" [compareWith]="compareWith" #select>
    <mat-select-trigger>
      <span [class]="select.value?.avain ? ('a' + select.value?.avain) : null || 'black-text'" class="projekti-teksti">{{select.value?.nimi || 'Kaikki'}}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let projekti of displayedProjektitObservable | async" [value]="projekti">
      <span [class]="'a' + projekti.avain || 'black-text'" class="projekti-teksti">{{projekti.nimi}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>