import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'

import { DateService } from '../../../../_shared-core/service/date.service'
import { KirjautunutKayttajaService } from '../../../../_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from '../../../../_jaettu-angular/service/timestamp-service'
import { AsiakasUriService } from '../../../../_jaettu-lemonator/service/asiakas-uri.service'
import { KirjanpitajaService } from '../../../../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Asiakas, Asiakastiedot, RiskiarvionHistoria } from '../../../../_jaettu-lemonator/model/asiakas'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'


import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of as observableOf, Subject } from 'rxjs'
import { switchMap, map, takeUntil, take, distinctUntilKeyChanged } from 'rxjs/operators'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'


interface RiskiarvioHistoriaPaivittajalla extends Pick<RiskiarvionHistoria, 'riskiarvio' | 'risikiarvioLisa'> {
  paivittajanNimi: string
  paivitettyString: string
}

@Component({
  selector: 'app-asiakkaan-riskiarvio',
  templateUrl: './asiakkaan-riskiarvio.component.html',
  styleUrls: ['../tuntemistiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasAsetuksetRiskiarvioComponent implements OnInit, OnDestroy {

  @Input() set asiakas(asiakas: Asiakas) {
    this._asiakasSubject.next(asiakas)
  }


  @Input() riskiarvioEditActiveErrorsObservable: Observable<{
    edustajaTunnistamatta: boolean
    tuntemistiedotAntamatta: boolean
    kuvausPuuttuu: boolean
  }>

  private _asiakasSubject: BehaviorSubject<Asiakas> = new BehaviorSubject(null)
  asiakasObservable: Observable<Asiakas> = this._asiakasSubject.asObservable()

  private _nykyisetAsiakastiedotObservable: Observable<Asiakastiedot>
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  commonError: string

  namename = 'asdf ' + Math.random()

  riskiarvioHistoriatObservable: Observable<RiskiarvioHistoriaPaivittajalla[]>
  tehdaanUusiRiskiarvio: boolean = false

  constructor(
    private _firebase: FirebaseLemonator,
    private _dateService: DateService,
    private _asiakasUriService: AsiakasUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _asiakasService: AsiakasService
  ) { }

  ngOnInit() {

    this._nykyisetAsiakastiedotObservable = this.asiakasObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreDoc<Asiakastiedot>(this._asiakasUriService.annaAsiakastiedotUri(asiakas.avain)).listen().pipe(
            map(asiakastiedot => {
              return asiakastiedot ?? null
            })
          )
        }
        return observableOf(null)
      })
    )

    this.form = new UntypedFormGroup({
      'riskiarvio': new UntypedFormControl({ value: null, disabled: true }),
      'riskiarvioLisa': new UntypedFormControl({ value: null, disabled: true }),
    })

    this.riskiarvioEditActiveErrorsObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(errorsObj => {
      if (!!errorsObj) {
        this.form.get('riskiarvio').disable()
        this.form.get('riskiarvioLisa').disable()
      } else {
        this.form.get('riskiarvio').enable()
        this.form.get('riskiarvioLisa').enable()
      }
    })

    this.riskiarvioHistoriatObservable = combineLatest([
      this.asiakasObservable.pipe(distinctUntilKeyChanged('avain')),
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]
    ).pipe(
      switchMap(([asiakas, kirjanpitajienNimitiedotMap]) => {
        if (asiakas && kirjanpitajienNimitiedotMap) {
          return this._firebase.firestoreCollection<RiskiarvionHistoria>(this._asiakasUriService.annaRiskiarvionHistoriaCollection(asiakas.avain)).orderBy('paivitetty', 'asc').listen().pipe(
            map(riskiarvionHistoriat => {
              const riskiarvioHistoriaPaivittajalla: RiskiarvioHistoriaPaivittajalla[] = []

              for (const historia of riskiarvionHistoriat) {
                const kirjanpitaja = kirjanpitajienNimitiedotMap.get(historia.paivittaja)
                riskiarvioHistoriaPaivittajalla.push(this._createRiskiarvioHistoriaPaivittajalla(historia, kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi))
              }

              return riskiarvioHistoriaPaivittajalla
            })
          )
        }
        return observableOf<RiskiarvioHistoriaPaivittajalla[]>([])
      })
    )

    this.riskiarvioHistoriatObservable.pipe(
      take(1)
    ).subscribe(historiat => {
      if (historiat.length) {
        const vikaHistoria = historiat[historiat.length - 1]
        this.form.get('riskiarvio').setValue(vikaHistoria.riskiarvio)
        this.form.get('riskiarvioLisa').setValue(vikaHistoria.risikiarvioLisa)
      }
    })
  }

  async tallenna() {

    if (this.tehdaanUusiRiskiarvio) {

      const asiakas = this._asiakasSubject.value
      const kirjanpitajaTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      const asiakastiedot = await firstValueFrom(this._nykyisetAsiakastiedotObservable)

      const riskiarvio = this.form.get('riskiarvio').value
      const riskiarvioLisa = this.form.get('riskiarvioLisa').value

      if (asiakastiedot) {
        asiakastiedot.riskiarvioStatus = 'annettu'

        const riskiarvioHistoriaAvain = this._firebase.firestoreCreateId()
        const riskiarvioHistoriaUri = this._asiakasUriService.annaRiskiarvionHistorianUri(asiakas.avain, riskiarvioHistoriaAvain)
        const riskiarvionHistoria: RiskiarvionHistoria = {
          asiakasAvain: asiakas.avain,
          riskiarvio: riskiarvio,
          risikiarvioLisa: riskiarvioLisa,
          paivitetty: this._timestampService.now(),
          paivittaja: kirjanpitajaTiedot.uid
        }

        const paivitaAsiakastiedotPromise = this._asiakasService.paivitaAsiakasJaTiedot(asiakas, asiakastiedot, 'tilikaudet-eivat-voineet-muuttua', false, false)
        const paivitaRiskiarvionHistoriaPromise = this._firebase.firestoreSetData(riskiarvioHistoriaUri, riskiarvionHistoria)

        await Promise.all([paivitaAsiakastiedotPromise, paivitaRiskiarvionHistoriaPromise])
      }
    }
    this.tehdaanUusiRiskiarvio = false
  }

  async triggerRiskiarvioEdit() {
    this.tehdaanUusiRiskiarvio = !this.tehdaanUusiRiskiarvio
  }

  private _createRiskiarvioHistoriaPaivittajalla(riskiarvioHistoria: RiskiarvionHistoria, kirjanpitajanNimi: string): RiskiarvioHistoriaPaivittajalla {
    return {
      riskiarvio: riskiarvioHistoria.riskiarvio,
      risikiarvioLisa: riskiarvioHistoria.risikiarvioLisa,
      paivittajanNimi: kirjanpitajanNimi,
      paivitettyString: this._dateService.muotoilePaivaJaAikaDate(riskiarvioHistoria.paivitetty.toDate(), 'fi')
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }
}
