
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { IlmoitusRivi } from 'app/_jaettu-lemonator/service/base-ilmoitin-export.service'
import { VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { Observable, Subject } from 'rxjs'
import { AvaaVeroilmoitusKenttaMessage, TallennaArvoBaseEvent, TallennaArvotEvent, VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseCommonComponent } from './base-common-veroilmoitus.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

@Component({
  template: ''
})
export abstract class KirjanpitoVeroilmoitusBaseSpecializedComponent extends KirjanpitoVeroilmoitusBaseCommonComponent {

  @Output() laskeUudelleen: EventEmitter<void> = new EventEmitter()
  @Output() tallennaArvo: EventEmitter<TallennaArvoBaseEvent> = new EventEmitter()
  @Input() veroilmoitusObservable: Observable<VeroilmoituksenMuokkaustiedot>

  protected _ngUnsubscribe = new Subject<void>()

  activeTabIndex: number = 0
  asiakkaanNimi: string
  veroilmoitus: VeroilmoituksenMuokkaustiedot = null

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _changeDetectorRef: ChangeDetectorRef,
    protected _asiakasService: AsiakasService
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  /**
   * Palauttaa kaikki numeron arvot mapissa, jossa avain on itse arvo.
   * @param numero
   * @param arvot
   */
  protected annaKaikkiArvotMapissa(numero: string, arvot: IlmoitusRivi[]): Map<string, IlmoitusRivi> {
    const kaikkiArvot = this._annaKaikkiArvot(numero, arvot)
    const map: Map<string, IlmoitusRivi> = new Map()
    for (const arvo of kaikkiArvot) {
      map.set(arvo.value, arvo)
    }
    return map
  }

  protected _annaKaikkiArvot(numero: string, arvot: IlmoitusRivi[]): IlmoitusRivi[] {
    return arvot.filter(arvo => arvo.key === numero)
  }

  protected _getValue(numero: string, dataType: 'string' | 'part', log?: 'log-value'): string | null
  protected _getValue(numero: string, dataType: 'currency', log?: 'log-value'): number | null
  protected _getValue(numero: string, dataType: 'string' | 'part' | 'currency' | 'checkbox', log?: 'log-value'): number | string | null

  protected _getValue(numero: string, dataType: 'string' | 'currency' | 'part' | 'checkbox', log?: 'log-value'): string | number | null {
    const rawValue = this._getRawValue(numero)
    if (log) {
      console.log('Got raw value for ' + numero, rawValue, typeof rawValue, dataType)
    }
    if (dataType === 'currency') {
      if (this._currencyService.onkoNumero(rawValue)) {
        return rawValue
      }
      return this._currencyService.muutaMerkkijonoNumeroksi(rawValue, 2)
    }
    // else if (dataType === 'integer') {
    //   if (this._currencyService.onkoNumero(rawValue)) {
    //     return Math.round(rawValue)
    //   }
    //   return Math.round(this._currencyService.muutaMerkkijonoNumeroksi(rawValue, 2))
    // }
    return rawValue + ''
  }

  protected _hasValue(numero: string): boolean {
    const val = this._getRawValue(numero)
    return !!val && val !== '0,00'
  }

  protected _getRawValue(numero: string, log?: 'log-value'): any {
    if (!this.veroilmoitus) { console.log('No veroilmo'); return null }
    const notSaved = this.veroilmoitus.locallyEditedNotPersistedValues[numero]
    if (notSaved !== undefined) {
      return notSaved
    }
    const fromDatabase = this.veroilmoitus.editedValuesFromDatabase[numero]
    if (fromDatabase !== undefined) {
      return fromDatabase
    }
    const valueFromStatic = this._getValueFromRows(numero, this.veroilmoitus.arvot)
    if (valueFromStatic !== undefined && valueFromStatic !== null) {
      return valueFromStatic
    }
    const oletus = this.veroilmoitus.oletukset[numero]
    if (oletus !== undefined && oletus !== null) {
      return oletus
    }
    // console.log('No value in anywhere for', numero)
    return null
  }

  private _getValueFromRows(num: string, vals: IlmoitusRivi[]): string | null {
    if (vals) {
      for (const val of vals) {
        if (val.key === num) {
          return val.value ?? null
        }
      }
    }
    return null
  }

  tallenna(event: TallennaArvoBaseEvent) {
    if (event.updateOnly) {
      if (this.veroilmoitus) {
        if (event.tyyppi === 'one') {
          if (event.arvo !== this.veroilmoitus.locallyEditedNotPersistedValues[event.numero]) {
            this.veroilmoitus.locallyEditedNotPersistedValues[event.numero] = event.arvo
            this.laskeUudelleen.next()
          }
        } else if (event.tyyppi === 'multiple') {
          let recalc = false
          for (const arvo of event.arvot) {
            if (arvo.arvo !== this.veroilmoitus.locallyEditedNotPersistedValues[arvo.numero]) {
              this.veroilmoitus.locallyEditedNotPersistedValues[arvo.numero] = arvo.arvo
              recalc = true
            }
          }
          if (recalc) {
            this.laskeUudelleen.next()
          }
        }
      }
      if (event.doneCallback) {
        event.doneCallback('no-save-needed')
      }
    } else {
      this.tallennaArvo.next(event)
    }
  }

  protected annaTiedonTunniste(numero: string, repeatingMetadata?: RepeatingMetadata) {
    if (repeatingMetadata) {
      return this.annaTiedonTunnisteRepeating(numero, repeatingMetadata.order, repeatingMetadata.groupdIdentifier)
    }
    return numero
  }

  protected annaTiedonTunnisteRepeating(numero: string, order: number, groupdIdentifier: string) {
    return groupdIdentifier + '_' + order + '_' + numero
  }

  protected _annaEhdotus(numero: string): string | number | null {
    const oletukset = this.veroilmoitus?.oletukset
    if (oletukset) {
      return oletukset[numero || 'asfd'] || null
    }
    return null
  }

  jatka() {
    this.activeTabIndex++
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 500)
  }

  trackByRepeatingMetadata = (index: number, obj: RepeatingMetadata): number => {
    return index
  }

  protected poistaRepeatingItem(repeatingMetadata: RepeatingMetadata, maxOrder: number, modifyArvotBeforeSave: (tallennettava: TallennaArvotEvent) => void, ...numerot: string[]) {

    const tallennettava: TallennaArvotEvent = {
      tyyppi: 'multiple',
      arvot: []
    }

    for (let i = repeatingMetadata.order; i < maxOrder; i++) {
      for (const numero of numerot) {
        const vanhaTunniste = this.annaTiedonTunnisteRepeating(numero, i + 1, repeatingMetadata.groupdIdentifier)
        const uusiTunniste = this.annaTiedonTunnisteRepeating(numero, i, repeatingMetadata.groupdIdentifier)
        const value = this._getRawValue(vanhaTunniste)
        tallennettava.arvot.push({ numero: uusiTunniste, arvo: value })
      }
    }

    for (const numero of numerot) {
      const tunniste = this.annaTiedonTunnisteRepeating(numero, maxOrder, repeatingMetadata.groupdIdentifier)
      tallennettava.arvot.push({ numero: tunniste, arvo: undefined })
    }

    modifyArvotBeforeSave(tallennettava)

    this.tallennaArvo.next(tallennettava)

  }


  protected async avaaVeroilmoitusKentta(event: MessageEvent<AvaaVeroilmoitusKenttaMessage>) {
    const kentta = await this._odotaKentanIlmestymista(event.data.numero)
    if (kentta) {
      this._scrollaaKenttaNakyviin(kentta)
    }
  }

  private async _odotaKentanIlmestymista(numero: string): Promise<HTMLDivElement> {

    const totalTabs = 4
    const query = 'div[numero="' + numero + '"]'

    await this._sleep(50)
    const div: HTMLDivElement = document.querySelector(query)

    // Div found from current tab
    if (div) { return div }


    // Looping through other tabs
    const startTab = this.activeTabIndex

    for (let i = 0; i < totalTabs; i++) {
      if (i === startTab) {
        continue
      }
      this.activeTabIndex = i
      this._changeDetectorRef.markForCheck()
      await this._sleep(500)
      const found: HTMLDivElement = document.querySelector(query)
      if (found) {
        return found
      }
    }
  }

  private _scrollaaKenttaNakyviin(el: HTMLElement): void {
    window.requestAnimationFrame(() => { })

    const boundRect = el.getBoundingClientRect()
    const scrollaaAlas = boundRect.top > window.innerHeight / 2

    this._blinkRed(el)

    if (scrollaaAlas) {
      const move = boundRect.top - window.innerHeight / 2

      // SCROLLAA VAIN NÄKYVIIN
      const yCoordinate = Math.max(window.scrollY + move, 0)
      const xCoordinate = window.scrollX
      if (yCoordinate !== window.scrollY) {
        window.scrollTo({
          behavior: 'smooth',
          left: xCoordinate,
          top: yCoordinate
        })
        return
      }
    } else {
      const scrollaa = boundRect.top < window.innerHeight / 2
      if (scrollaa) {
        const move = window.innerHeight / 2 - boundRect.top
        const yCoordinate = Math.max(window.scrollY - move, 0)
        const xCoordinate = window.scrollX
        if (yCoordinate !== window.scrollX) {
          window.scrollTo({
            behavior: 'auto',
            left: xCoordinate,
            top: yCoordinate
          })
          return
        }
      }
    }
  }

  private _blinkRed(el: HTMLElement) {

    el.classList.add('error-text')

    setTimeout(() => {
      el.classList.remove('error-text')
    }, 1200)

  }

  private _sleep(millis: number): Promise<any> {
    return new Promise(resolve => {
      setTimeout(resolve, millis)
    })
  }

  protected async startVeroilmoitus(asiakas: Asiakas, tilikausiAvain: string) {
    for (const tk of asiakas.tilikaudet) {
      if (tk.avain === tilikausiAvain) {
        tk.veroilmoitusStarted = 1
        break
      }
    }

    return this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
  }



}
