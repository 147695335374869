<picture>
  <source srcset="/assets/taustat/login_720x512_crushed.webp 720w, /assets/taustat/login_1440x1024_crushed.webp 1440w, /assets/taustat/login_2880x2048_crushed.webp 2880w, /assets/taustat/login_4320x3072_crushed.webp 4320w" type="image/webp" sizes="100vw">
  <source srcset="/assets/taustat/login_720x512_crushed.png 720w, /assets/taustat/login_1440x1024_crushed.png 1440w, /assets/taustat/login_2880x2048_crushed.png 2880w, /assets/taustat/login_4320x3072_crushed.png 4320w" type="image/png" sizes="100vw">
  <img src="/assets/taustat/login_720x512_crushed.png" class="tausta">
</picture>

<div class="all-container">

  <!-- <mat-nav-list style="position: absolute; left: 4px; top: 4px; padding-top: 0;">
    <mat-list-item (click)="vaihdaKieli()">
      <div style="text-align: center; width: 100%; color:black;">Kieli / Language</div>
    </mat-list-item>
  </mat-nav-list> -->

  <div class="login-container">
    <div class="mat-elevation-z3" style="line-height: 28px; padding: 10px; background-color: rgba(255, 255, 255, 1); border-radius: 10px; margin: 10px;">
      <div *ngIf="!commonError">Kirjaudutaan...</div>
      <div class="red-text common-error" style="vertical-align: middle;">{{commonError}}</div>
    </div>
    <!-- <button class="primarybutton" type="button" mat-button (click)="login()">Kirjaudu</button> -->
  </div>

  <span class="disclaimer">© TILITOIMISTO LEMON TREE OY {{year}}</span>

</div>