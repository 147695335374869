import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjauksienLuontityyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'

export interface KirjausSiirraDialogData {
  isYberSuper: boolean
  naytettavaKirjaus: NaytettavaKirjaus
  maksutavat: AsiakkaanMaksutapa[]
  asiakas: Asiakas
}

@Component({
  templateUrl: './kirjaus.siirra.dialog.html'
})
export class KirjausSiirraDialog implements OnInit {

  form: UntypedFormGroup
  namename: string = 'assf' + Math.random()
  kaytettavatMaksutavat: AsiakkaanMaksutapa[]
  ensimmainenPaiva: Date = new Date(2015, 1, 1)
  viimeinenPaiva: Date = new Date()

  private _uusiPaivamaara: number = null
  private _uusiMaksutapa: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausSiirraDialogData,
    private _dialogRef: MatDialogRef<KirjausSiirraDialog>,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {

    this.kaytettavatMaksutavat = this.data.isYberSuper ? (this.data.maksutavat || []) : (this.data.maksutavat?.filter(mt => {
      return mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE ||
        mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE
    }) || [])

    const asDate = this._dateService.numberToDate(this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p)
    this.form = new UntypedFormGroup({
      'kirjauksenPvm': new UntypedFormControl({ value: asDate, disabled: false }, [Validators.required]),
      'maksutapa': new UntypedFormControl({ value: this.data.naytettavaKirjaus.kirjaus.maksutavanTunniste, disabled: false }, [Validators.required])
    })
    this.form.get('kirjauksenPvm').valueChanges.subscribe(value => {
      this._uusiPaivamaara = this._dateService.localDateToNumber(this._dateService.dateToLocalDate(value))
    })
    this.form.get('maksutapa').valueChanges.subscribe(value => {
      this._uusiMaksutapa = value
    })

    const ensimmainenTilikausi = this._asiakasJaettuService.annaEnsimmainenTilikausi(data.asiakas)
    if (ensimmainenTilikausi) {
      this.ensimmainenPaiva = this._dateService.localDateToDate(ensimmainenTilikausi.alkaa)
    }

    const viimeinenTilikausi = this._asiakasJaettuService.annaViimeinenTilikausi(data.asiakas)
    if (viimeinenTilikausi) {
      this.viimeinenPaiva = this._dateService.localDateToDate(viimeinenTilikausi.loppuu)
    }

  }

  ngOnInit() {

  }

  siirra() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (!this._uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p === this._uusiPaivamaara) {
      this.data.naytettavaKirjaus.uusiPaivamaara = null
      this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this.data.naytettavaKirjaus.kirjaus.p, 'fi')
    } else {
      this.data.naytettavaKirjaus.uusiPaivamaara = this._uusiPaivamaara
      this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this._uusiPaivamaara, 'fi')
    }

    if (!this._uusiMaksutapa || this.data.naytettavaKirjaus.kirjaus.maksutavanTunniste === this._uusiMaksutapa) {
      this.data.naytettavaKirjaus.uusiMaksutapa = null
      // this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this.data.naytettavaKirjaus.kirjaus.p, 'fi')
    } else {
      this.data.naytettavaKirjaus.uusiMaksutapa = this._uusiMaksutapa
      // this.data.naytettavaKirjaus.tilitapahtumanPvm = this._dateService.muotoileNumberPaiva(this._uusiPaivamaara, 'fi')
    }

    this._dialogRef.close(true)

  }

}
