import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Platform } from '@angular/cdk/platform'
import { DataSource } from '@angular/cdk/table'
import { MatDialog } from '@angular/material/dialog'

import { LahetaMuistutusDialog, LahetaMuistutusDialogData } from './dialogit/laheta.dialog'

import { LoadingConfig } from 'app/_jaettu-angular/_components/loading.component'

import { LemonKuukausiDateAdapter } from '../../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { WindowSizeService } from '../../_jaettu-angular/service/window.service'

import { DateService } from '../../_shared-core/service/date.service'
import { LaskunTila, LaskunListaustietorivi, LaskuBase, Lasku } from '../../_jaettu/model/lasku'

import { MaksumuistutusDataSourceService } from './maksumuistutus.datasource.service'
import { LaskunLokalisoituTila } from '../../laskut/laskut.firestore.datasource'

import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'


export type LaskuProperties = 'nro' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'lataa' | 'asiakas' | 'kommentti' | undefined

export interface SummienRullausData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  edellinenLaskunTila: LaskunTila
}

@Component({
  selector: 'app-maksumuistutus',
  templateUrl: './maksumuistutus.component.html',
  styleUrls: ['./maksumuistutus.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class MaksumuistutusComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef
  @ViewChild(MatPaginator) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  private ngUnsubscribe = new Subject<void>()

  form: UntypedFormGroup

  propertiesToDisplay: LaskuProperties[] = []
  tilat: LaskunLokalisoituTila[] = []

  naytaHuomautus = false
  naytaKaikkiSummat = true

  loadingSettings: LoadingConfig = {
    backdropBackgroundColour: 'rgba(0,0,0,0.15)',
    backdropBorderRadius: '4px',
    fullScreenBackdrop: false,
    primaryColour: 'rgba(255, 255, 255, 1)',
    secondaryColour: 'rgba(255, 255, 255, 1)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(255, 255, 255, 1)'
  }

  constructor(
    private router: Router,
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    private windowSizeService: WindowSizeService,
    private translationService: LemonTranslationService,
    private dateAdapter: DateAdapter<Date>,
    private laskutDataSourceService: MaksumuistutusDataSourceService
  ) {

  }

  ngOnInit() {

    this._sort.sort({
      disableClear: true,
      id: 'nro',
      start: 'desc'
    })

    this.asetaListauksenKolumnit()

    this.translationService.currentLanguageObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kieli => {
      this.dateAdapter.setLocale(kieli)
    })

    this.laskutDataSourceService.laskunTilaObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tilat => {
      this.tilat = tilat
    }, error => {
      this.errorHandler.handleError(error)
    })

    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(this.matDataSource.filter, []),
    })

    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        const val = value.trim().toLowerCase()
        this.matDataSource.filter = val
      } else {
        this.matDataSource.filter = null
      }
    })

    const currentSort = this.matDataSource.sort
    if (currentSort) {
      this._sort.sort({
        id: currentSort.active,
        disableClear: true,
        start: currentSort.direction === 'asc' ? 'asc' : 'desc'
      })
    }
    this.matDataSource.sort = this._sort
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  ngAfterViewInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(koko => {
      setTimeout(() => {
        this.asetaListauksenKolumnit()
      }, 250)
    })
  }

  private asetaListauksenKolumnit() {
    const leveys = this.laskutaulu.nativeElement.offsetWidth
    // if (leveys < 600) {
    //   this.propertiesToDisplay = ['tila', 'erapvm', 'avoinna', 'lataa']
    // } else if (leveys < 700) {
    //   this.propertiesToDisplay = ['tila', 'erapvm', 'summa', 'avoinna', 'lataa']
    // } else if (leveys < 800) {
    //   this.propertiesToDisplay = ['tila', 'pvm', 'erapvm', 'summa', 'avoinna', 'lataa']
    // } else {
    this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'lataa', 'kommentti']
    // }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  poistaListalta(rivi: LaskunListaustietorivi) {
    this.laskutDataSourceService.deleteInvoice(rivi.avain)
  }

  poistaKaikkiListalta(rivi: LaskunListaustietorivi) {
    this.laskutDataSourceService.deleteAllInvoices(rivi.avain)
  }

  lahetaMuistutuslaskut() {
    const dialogData: LahetaMuistutusDialogData = { laskut: this.laskutDataSourceService.data }
    const lahetaDialogRef = this.dialog.open<LahetaMuistutusDialog, LahetaMuistutusDialogData>(LahetaMuistutusDialog, { 'data': dialogData, panelClass: 'ilman-paddingia' })
  }

  get dataSource(): DataSource<LaskunListaustietorivi> {
    return this.laskutDataSourceService.dataSourceTransformed
  }

  get matDataSource(): MatTableDataSource<Lasku> {
    return this.laskutDataSourceService
  }

  get lataa(): boolean {
    return this.laskutDataSourceService.lataa
  }

  async merkitseMaksetuksi(rivi: LaskunListaustietorivi) {
  }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

}
