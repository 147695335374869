import { Injectable } from '@angular/core'
import { BaseFirebaseService } from '../../_jaettu-angular/base-firebase.service'
import { environment } from 'environments/environment'

@Injectable()
export class FirebaseLemonator extends BaseFirebaseService {
  constructor() {
    super(environment.lemonatorFirebaseConfig, 'lemonator', environment.recaptchaSiteId)
  }
}

@Injectable()
export class FirebaseLemonaid extends BaseFirebaseService {
  constructor() {
    super(environment.lemonaidFirebaseConfig, 'lemonaid', environment.lemonaidRecaptchaSiteId)
  }
}
