import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { UntypedFormGroup, Validators, FormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'

import { Subject, Observable, BehaviorSubject } from 'rxjs'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { DateService } from '../../_shared-core/service/date.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

import { EmailTemplateDraft, EmailTemplateVariable, TemplateAlvType, TemplateCategory } from '../../_jaettu-lemonator/model/email-templates'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { EmailTemplatesUriService } from 'app/_jaettu-lemonator/service/email-templates-uri.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

@Component({
  templateUrl: './email-templates.component.html'
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {

  private _ngUnsubsribe: Subject<void> = new Subject<void>()

  sopimustenVersiotObservable: Observable<{ aika: string, tekstifi: string, tekstien: string, kirjanpitaja: string }[]>
  usableVariablesSuomiObservable: Observable<string[]>
  usableVariablesEnglishObservable: Observable<string[]>

  savedDataSubject: Subject<EmailTemplateDraft> = new Subject()

  templateCategories: { avain: TemplateCategory, nimi: string }[] = [
    { avain: TemplateCategory.KIRJANPITO, nimi: 'Kirjanpito' },
    { avain: TemplateCategory.VEROILMOITUS, nimi: 'Veroilmoitus' },
    { avain: TemplateCategory.TILINPAATOS, nimi: 'Tilinpäätös' },
    { avain: TemplateCategory.SOPIMUS_PAATTYY, nimi: 'Sopimus päättyy' },
    { avain: TemplateCategory.TAIKALINKKI, nimi: 'Taikalinkki' }
  ]

  alvTypes: { avain: TemplateAlvType, nimi: string }[] = [
    { avain: TemplateAlvType.ALV_1KK, nimi: 'Alvillinen 1kk' },
    { avain: TemplateAlvType.ALV_3KK12KK_EI_MAKSUKUUKAUSI, nimi: 'Alvillinen 3kk/12kk ei maksukuukausi' },
    { avain: TemplateAlvType.ALV_3KK12KK_MAKSUKUUKAUSI, nimi: 'Alvillinen 3kk/12kk maksukuukausi' },
    { avain: TemplateAlvType.ALVITON, nimi: 'Alviton' },
  ]

  yritysmuodot: { avain: Yritysmuoto, nimi: string }[] = [
    { avain: Yritysmuoto.TOIMINIMI, nimi: 'Toiminimi' },
    { avain: Yritysmuoto.OSAKEYHTIO, nimi: 'Osakeyhtiö' },
    { avain: Yritysmuoto.AVOINYHTIO, nimi: 'Avoin yhtiö' },
    { avain: Yritysmuoto.KOMMANDIITTIYHTIO, nimi: 'Kommandiittiyhtiö' },
    { avain: Yritysmuoto.OSUUSKUNTA, nimi: 'Osuuskunta' },
    { avain: Yritysmuoto.YHDISTYS, nimi: 'Yhdistys' }
  ]

  // selectedCategoryFormControl: FormControl<TemplateCategory> = new FormControl(TemplateCategory.KIRJANPITO, Validators.required)

  // Radio fields
  languageFormControl: FormControl<TuettuKieli> = new FormControl('fi', Validators.required)
  regularOrZenFormControl: FormControl<'reg' | 'zen'> = new FormControl('reg', Validators.required)
  hasAttachmentsFormControl: FormControl<boolean> = new FormControl(false, Validators.required)
  hasSelvitettaviaFormControl: FormControl<boolean> = new FormControl(false, Validators.required)
  alvTypeFormControl: FormControl<TemplateAlvType> = new FormControl(TemplateAlvType.ALV_1KK, Validators.required)

  // Input fields
  textStartFormControl: FormControl<string> = new FormControl('', Validators.required)
  textMidFormControl: FormControl<string> = new FormControl('', Validators.required)
  textEndFormControl: FormControl<string> = new FormControl('', Validators.required)

  yritysmuotoFormControl: FormControl<Yritysmuoto> = new FormControl(Yritysmuoto.TOIMINIMI, Validators.required)

  // Just a dummy start, will be overwritten by template versions
  form: UntypedFormGroup = new UntypedFormGroup({
    'kieli': this.languageFormControl
  })

  usableVariablesSubject: BehaviorSubject<EmailTemplateVariable[]> = new BehaviorSubject([])

  constructor(
    private _formValidationService: FormValidationService,
    private _firebase: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _timestampService: TimestampService,
    private _dateService: DateService,
    private _snackbar: MatSnackBar,
    private _error: ErrorHandler,
    private _dialog: MatDialog,
    private _emailTemplatesUriService: EmailTemplatesUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
  ) { }

  ngOnInit() {

    // const draftObservable = combineLatest([kieliObservable, yritysmuotoObservable, tavallinenTaiZenObservable, liitteetObservable, alvObservable, selvitettaviaObservable])
    //   .switchMap(([kieli, yritysmuoto, tavallinenTaiZen, liitteet, alv, selvitettavia]))


    // const valitunPohjanTekstitObservable: Observable<EmailTemplate> = combineLatest([
    //   this.yritysmuotoFormControl.valueChanges,
    //   this.pohjaTyyppiFormControl.valueChanges
    // ]).pipe(
    //   switchMap(([yritysmuoto, tyyppi]) => {
    //     if (!yritysmuoto && !tyyppi) {
    //       return observableOf(null)
    //     }

    //     const uri = this._sahkpostipohjatUriService.getSahkopostipohjaUri(yritysmuoto, tyyppi)
    //     return this._firebase.firestoreDoc<EmailTemplate>(uri).listen()
    //   })
    // )

    // valitunPohjanTekstitObservable.pipe(
    //   takeUntil(this._ngUnsubsribe)
    // ).subscribe(tekstit => {
    //   if (tekstit?.textInDiffLangs) {
    //     this.textFi.setValue(tekstit.textInDiffLangs.fi)
    //     this.textFi.setValue(tekstit.textInDiffLangs.en)
    //   }
    //   if (tekstit.supportedVariables) {
    //     return this.usableVariablesSubject.next(tekstit.supportedVariables)
    //   }
    // })

    // this.usableVariablesSuomiObservable = combineLatest([this.textFi.valueChanges, this.usableVariablesSubject]).pipe(
    //   takeUntil(this._ngUnsubsribe),
    //   map(([text, usableVariables]) => {
    //     if (!text) {
    //       return usableVariables
    //     }
    //     return usableVariables.filter(variable => !text.includes(variable))
    //   })
    // )

    // this.usableVariablesEnglishObservable = combineLatest([this.textEn.valueChanges, this.usableVariablesSubject]).pipe(
    //   takeUntil(this._ngUnsubsribe),
    //   map(([text, usableVariables]) => {
    //     if (!text) {
    //       return usableVariables
    //     }
    //     return usableVariables.filter(variable => !text.includes(variable))
    //   })
    // )
  }

  async save() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    //   const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    //   const tyyppi = this.pohjaTyyppiFormControl.value
    //   const yritysmuoto = this.yritysmuotoFormControl.value
    //   const docData: EmailTemplate = {
    //     updatedAt: this._timestampService.now(),
    //     updatedBy: kirjanpitajanTiedot.uid,
    //     textInDiffLangs: {
    //       'fi': this.textFi.value,
    //       'en': this.textFi.value
    //     },
    //     tyyppi: tyyppi,
    //     yritysmuoto: yritysmuoto,
    //     supportedVariables: this.usableVariablesSubject.value
    //   }
    //   await this._firebase.firestoreSetData(this._sahkpostipohjatUriService.getSahkopostipohjaUri(yritysmuoto, tyyppi), docData)

    //   this._ladataanService.lopetaLataaminen()
    //   this._snackbar.open('Tallentaminen onnistui', 'OK', { duration: 5000 })
  }

  ngOnDestroy() {
    this._ngUnsubsribe.next()
  }


  private async _startKirjanpitoTemplate() {
    this.form = new UntypedFormGroup({
      'kieli': this.languageFormControl,
      'textStart': this.textStartFormControl,
      'textMid': this.textMidFormControl,
      'textEnd': this.textEndFormControl,
      'regularOrZen': this.regularOrZenFormControl,
      'yritysmuoto': this.yritysmuotoFormControl,
      'hasAttachments': this.hasAttachmentsFormControl,
      'hasSelvitettavia': this.hasSelvitettaviaFormControl,
      'alvType': this.alvTypeFormControl
    })


    const templateAvain = this._emailTemplatesUriService.calcTemplateAvain(
      TemplateCategory.KIRJANPITO,
      this.languageFormControl.value,
      this.regularOrZenFormControl.value,
      this.alvTypeFormControl.value,
      this.hasAttachmentsFormControl.value,
      this.hasSelvitettaviaFormControl.value
    )
    const data: EmailTemplateDraft = await this._firebase.firestoreDoc<EmailTemplateDraft>(this._emailTemplatesUriService.getEmailTemplateDraftUri(templateAvain)).get()

    this.savedDataSubject.next(data)

  }

  async changeSelectedCategory(category: TemplateCategory) {

    if (category === TemplateCategory.KIRJANPITO) {
      await this._startKirjanpitoTemplate()
    } else {
      this.form = new UntypedFormGroup({})
      console.error('Unknown template category!')
      // throw new Error('Unknown template category!')
    }
  }

}
