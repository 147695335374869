import { Component, OnInit, Inject, ErrorHandler, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'
import { Kirjanpitaja } from '../_jaettu-lemonator/model/kirjanpitaja'
import { TositteidenLatauskerta } from '../_jaettu/lemonator/model/tositteiden-lataaminen'
import { KirjanpitajaService } from '../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Subject, Observable, combineLatest } from 'rxjs'
import { takeUntil, map } from 'rxjs/operators'
import { TositteidenLataustemerkintojenPerumispyynto } from '../_jaettu/lemonator/model/tositteiden-lataaminen'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { QueryDocumentSnapshot } from 'firebase/firestore'

export interface AsiakkaanTositteidenLataushistoriaDialogData {
  asiakas: Asiakas
}

interface ListausTositteidenLatauskerta extends TositteidenLatauskerta {
  avain: string
  lataajanNimi: string
}

@Component({
  templateUrl: './asiakkaan-tositteiden-lataushistoria.dialog.html'
})
export class AsiakkaanTositteidenLataushistoriaDialog implements OnInit, OnDestroy {

  private asiakas: Asiakas = null
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  latauskerrat: ListausTositteidenLatauskerta[] = []
  commonError = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AsiakkaanTositteidenLataushistoriaDialogData,
    private dialogRef: MatDialogRef<AsiakkaanTositteidenLataushistoriaDialog>,
    private errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonator,
    private kirjanpitajaService: KirjanpitajaService,
    private ladataanService: LadataanService
  ) {
    this.asiakas = this.data.asiakas
  }

  merkitseLataamattomaksi(kerta: ListausTositteidenLatauskerta) {
    this.ladataanService.aloitaLataaminen()
    const peruutustiedot: TositteidenLataustemerkintojenPerumispyynto = {
      asiakasAvain: this.asiakas.avain,
      asiakasId: this.asiakas.asiakasId,
      latausmerkinnanAvain: kerta.avain
    }

    this._firebase.functionsCall<TositteidenLataustemerkintojenPerumispyynto, void>('tositteidenLataaminenPerulatausmerkinnat', peruutustiedot).then(() => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close()
    }).catch(error => {
      this.commonError = true
      this.errorHandler.handleError(error)
      this.ladataanService.lopetaLataaminen()
    })
  }

  ngOnInit() {
    const latauskerratObservable: Observable<QueryDocumentSnapshot<TositteidenLatauskerta>[]> = this._firebase.firestoreCollection<TositteidenLatauskerta>('asiakkaat/' + this.asiakas.avain + '/tositteiden-latauskerrat').orderBy('lataamisaika', 'desc').listenSnapshots()

    combineLatest([latauskerratObservable, this.kirjanpitajaService.kirjanpitajatObservable]).pipe(
      takeUntil(this.ngUnsubscribe),
      map(([latauskerrat, kirjanpitajat]) => {
        return latauskerrat.map(b => {
          const a = b.data()
          const kirjanpitaja = this.annaKirjanpitaja(a.lataajaUid, kirjanpitajat)
          const mapattu: ListausTositteidenLatauskerta = {
            avain: b.id,
            kuukausi: a.kuukausi,
            ladatutTositteet: a.ladatutTositteet,
            lataajanNimi: kirjanpitaja ? (kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi) : a.lataajaUid,
            lataajaUid: a.lataajaUid,
            lataamisaika: a.lataamisaika,
            vuosi: a.vuosi
          }
          return mapattu
        })
      })
    ).subscribe(latauskerrat => {
      this.latauskerrat = latauskerrat
    }, error => {
      this.commonError = true
      this.errorHandler.handleError(error)
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  annaKirjanpitaja(uid: string, kirjanpitajat: Kirjanpitaja[]): Kirjanpitaja | null {
    for (const kirjanpitaja of kirjanpitajat) {
      if (kirjanpitaja.avain === uid) {
        return kirjanpitaja
      }
    }
    return null
  }

}
