<div mat-dialog-content>
  <lemon-dialog-header>
    Muutoshistoria
  </lemon-dialog-header>
  <div>
    <ul>
      <li>1.0.164 ~ 1.0.165 - 03.04.2019: Korjattu tositteiden selaamista.</li>
      <li>1.0.160 ~ 1.0.163 - 02.04.2019: Lisätty osoitetietojen lataus.</li>
      <li>1.0.159 - 26.03.2019: Korjattu kuukausistatusdialogin virheitä.</li>
      <li>1.0.153 ~ 1.0.158 - 26.03.2019: Lisätty tilitapahtumien kohdistamisen debug-näkymä.</li>
      <li>1.0.152 - 22.03.2019: Korjattu maksutapojen lajittelu.</li>
      <li>1.0.151 - 22.03.2019: Lisätty yhteysottopyyntöjen hallinta.</li>
      <li>1.0.149 ~ 1.0.150 - 19.03.2019: Bugikorjauksia.</li>
      <li>1.0.144 ~ 1.0.148 - 19.03.2019: Parannettu maksutapojen hallintaa.</li>
      <li>1.0.143 - 07.03.2019: Lisätty hinnan muutoksen syy tilastoihin. Korjattu vasemman valikon aktiivisuutta.</li>
      <li>1.0.138 ~ 1.0.142 - 07.03.2019: Lisätty hinnan muutoksen syy.</li>
      <li>1.0.137 - 28.02.2019: Korjattu nollalla jakobugi.</li>
      <li>1.0.136 - 22.02.2019: Poistettu tilikauden ja tilinumerojen pakollisuus.</li>
      <li>1.0.135 - 21.02.2019: Lisätty asiakaslistauksen alaosaan summatietoja.</li>
      <li>1.0.134 - 21.02.2019: Päivitetty kirjastoja.</li>
      <li>1.0.133 - 20.02.2019: Korjattu työlistan bugi. Optimointeja.</li>
      <li>1.0.132 - 19.02.2019: Ensimmäinen versio kirjanpitäjien ylläpitonäkymästä.</li>
      <li>1.0.120 ~ 1.0.131 - 19.02.2019: Ensimmäinen versio työlistanäkymästä.</li>
      <li>1.0.119 - 14.01.2019: Pieniä korjauksia tilastoihin.</li>
      <li>1.0.118 - 14.01.2019: Lisätty tulevaisuusdataa tilastoihin.</li>
      <li>1.0.117 - 07.01.2019: Korjattu muistutuslaskujen numeron laskeminen.</li>
      <li>1.0.116 - 07.01.2019: Korjattu tilitapahtumien lataaminen.</li>
      <li>1.0.115 - 04.01.2019: Pieniä korjauksia tilastoihin.</li>
      <li>1.0.114 - 04.01.2019: Lisätty kirjanpitäjät yhteenveto tilastoihin supereille.</li>
      <li>1.0.113 - 03.01.2019: Säädetty tilastojen värejä.</li>
      <li>1.0.112 - 03.01.2019: Käännetty jatkuvan ja kertalaskutuksen järjestys tilastoissa.</li>
      <li>1.0.111 - 03.01.2019: Lisätty toteutunut laskutus kirjanpitäjittäin supereille.</li>
      <li>1.0.110 - 03.01.2019: Lisätty laskutuksen muutos tilastoihin kaikille.</li>
      <li>1.0.109 - 02.01.2019: Lisätty laskutuksen muutos tilastoihin superusereille.</li>
      <li>1.0.107 ~ 1.0.108 - 01.01.2019: Tilastot + tilitapahtumat.</li>
      <li>1.0.106 - 29.12.2018: Korjauksia tilastoihin.</li>
      <li>1.0.105 - 29.12.2018: Ensimmäinen versio tilastoista.</li>
      <li>1.0.98 ~ 1.0.104 - 27.12.2018: Lisätty asiakkaan vastuukirjanpitäjät aikajanalle.</li>
      <li>1.0.97 - 11.12.2018: Lisätty asiakkaan hinta asiakkaiden + käyttäjien massaexportiin.</li>
      <li>1.0.96 - 10.12.2018: Lisätty asiakkaiden rajaaminen, joiden yhtiömuoto ei ole tiedossa.</li>
      <li>1.0.95 - 10.12.2018: Lisätty asiakkaiden rajaaminen yhtiömuodon mukaan.</li>
      <li>1.0.94 - 10.12.2018: Päivitetty laskut sähköisen laskutuksen osalta.</li>
      <li>1.0.93 - 30.11.2018: Lisätty kirjanpitäjäkohtainen lähetettyjen laskujen seuranta.</li>
      <li>1.0.92 - 14.11.2018: Korjattu summatietojen laskeminen tulevaisuuden osalta asiakaslistauksessa.</li>
      <li>1.0.91 - 14.11.2018: Korjattu summatiedot asiakaslistauksessa.</li>
      <li>1.0.85 ~ 1.0.90 - 13.11.2018: Lisätty kirjanpitäjien laskut näkymä palkanmaksua varten + Lisätty selailu lähetettyihin sähköisiin laskuihin.</li>
      <li>1.0.84 - 05.11.2018: Korjattu liitetiedostot laskutukseen.</li>
      <li>1.0.83 - 05.11.2018: Lisätty liitetiedostot laskutukseen.</li>
      <li>1.0.82 - 01.11.2018: Lisätty asiakkaan laskuasetusten katselu.</li>
      <li>1.0.81 - 24.10.2018: Korjattu rikkinäinen impersonointi.</li>
      <li>1.0.80 - 22.10.2018: Päivitetty Angular ja Angular Material -> 7.0.0.</li>
      <li>1.0.79 - 18.10.2018: Päivitetty kirjastot.</li>
      <li>1.0.78 - 18.10.2018: Lisätty tietoja ladatuista tilitapahtumista.</li>
      <li>1.0.77 - 10.10.2018: Parannettu http-kutsujen teknistä mekanismia.</li>
      <li>1.0.76 - 10.10.2018: Korjattu viitenumeron luonti alkamaan numeroilla 99, jotta ei mene päällekkäin laskunnumeroista generoitujen kanssa.</li>
      <li>1.0.75 - 09.10.2018: Lisätty reskontran tasapainoittaminen kertalaskuihin.</li>
      <li>1.0.74 - 07.10.2018: Tilitapahtumien listauksen päivittäminen.</li>
      <li>1.0.73 - 06.10.2018: Automaattireskontran käsin tehtävät kohdistukset.</li>
      <li>1.0.72 - 05.10.2018: Parannettu tilitapahtumien debuggausnäkymää - take 3.</li>
      <li>1.0.71 - 04.10.2018: Parannettu tilitapahtumien debuggausnäkymää - take 2.</li>
      <li>1.0.70 - 04.10.2018: Parannettu tilitapahtumien debuggausnäkymää.</li>
      <li>1.0.69 - 03.10.2018: Lisätty laskutussummat kotinäkymään.</li>
      <li>1.0.68 - 02.10.2018: Päivitetty kirjastot.</li>
      <li>1.0.67 - 02.10.2018: Korjattu asiakkaan hinta listauksessa.</li>
      <li>1.0.66 - 28.09.2018: Tupla-ytunnuksen tarkistus joka paikkaan.</li>
      <li>1.0.65 - 28.09.2018: Lisätty asiakkaan lisäämiseen tarkistus, ettei y-tunnus ole jo käytössä.</li>
      <li>1.0.64 - 25.09.2018: Päivitetty kirjastot + kokonaislaskutus + lisää rajaamisvaihtoehtoja asiakaslistaukseen.</li>
      <li>1.0.63 - 24.09.2018: Lisätty takaisin -nappula laskun katseluun.</li>
      <li>1.0.62 - 24.09.2018: Korjattu kiinteän viitenumeron asettaminen.</li>
      <li>1.0.61 - 24.09.2018: Parannettu laskujen listausta. Parannettu kertalaskun esikatselua.</li>
      <li>1.0.60 - 21.09.2018: Korjattu kertalaskun tallennus.</li>
      <li>1.0.59 - 21.09.2018: Kerta- ja jatkuvien laskujen sähköpostipohjien mukauttamiseen liittyviä muutoksia.</li>
      <li>1.0.58 - 18.09.2018: Korjattu ulkoasua ja navigointia.</li>
      <li>1.0.57 - 16.09.2018: Korjattu pakollisuuksia.</li>
      <li>1.0.56 - 14.09.2018: Lisätty asiakkaan luontiin paremmat nimet + kk/pvm valinta.</li>
      <li>1.0.55 - 13.09.2018: Estetty autocomplete etsikentässä.</li>
      <li>1.0.54 - 13.09.2018: Järjestä asiakaslista oletuksena nimen mukaan.</li>
      <li>1.0.53 - 13.09.2018: Lisätty tulevaisuuden hinta asiakaslistaukseen + asiakkaan lisäyksessä oletusmaa Suomi + käyttäjän lisäyksessä email-validaatio.</li>
      <li>1.0.52 - 13.09.2018: Korjattu pakollisuuksia asiakkaan tiedoissa.</li>
      <li>1.0.51 - 13.09.2018: Yhtenäistetty asetussivujen ulkoasua.</li>
      <li>1.0.50 - 13.09.2018: Lisätty kirjanpito-osio uuden asiakkaan luontiin.</li>
      <li>1.0.49 - 13.09.2018: Korjauksia kirjanpitoasetukset näkymään.</li>
      <li>1.0.48 - 13.09.2018: Lisätty ensimmäinen vedos kirjanpitoasetuksista.</li>
      <li>1.0.47 - 12.09.2018: Lisätty laskun vastaanottajat asiakkaalle.</li>
      <li>1.0.46 - 07.09.2018: Lisätty asiakkaan jatkuva hinnoittelu.</li>
      <li>1.0.45 - 07.09.2018: Korjattu kuittien indeksointia.</li>
      <li>1.0.44 - 06.09.2018: Käännöksen aikaiset optimoinnit käyttöön.</li>
      <li>1.0.43 - 06.09.2018: Korjattu laskujen tuotehakua (puuttuvat/tyhjät tuoterivit.).</li>
      <li>1.0.42 - 06.09.2018: Korjattu laskujen ALV-laskentaa.</li>
      <li>1.0.41 - 05.09.2018: Mahdollistettu emailin lähettäminen samaan osoitteeseen virhetilanteessa.</li>
      <li>1.0.40 - 05.09.2018: Korjattu laskun lataaminen.</li>
      <li>1.0.39 - 05.09.2018: Lisätty toimiva kertalaskutus.</li>
      <li>1.0.38 - 03.09.2018: Korjattu asiakkaan tallentaminen.</li>
      <li>1.0.37 - 03.09.2018: Sitruunalogosta kotiin.</li>
      <li>1.0.36 - 03.09.2018: Päivitetty kirjastot + muutettu Lemonaid kuvake.</li>
      <li>1.0.35 - 03.09.2018: Suuri navigaatiomuutos + enemmän tietoja asiakkaalle.</li>
      <li>1.0.34 - 31.08.2018: Korjattu kertalaskun asiakkaan tietojen täyttöä.</li>
      <li>1.0.33 - 31.08.2018: Aloitettu automaattitäyttö.</li>
      <li>1.0.32 - 31.08.2018: Ensimmäinen versio kertalaskun lisäämisestä.</li>
      <li>1.0.31 - 29.08.2018: Edelleen korjattu käyttäjän tietojen Lemonaid-vientiä.</li>
      <li>1.0.30 - 28.08.2018: Lisätty muokattu viimeksi -päivämäärä tositteiden selaamiseen.</li>
      <li>1.0.29 - 20.08.2018: Lisätty ensimmäinen versio yhteisömyyntilaskujen lataamisesta.</li>
      <li>1.0.28 - 20.08.2018: Parannettu tositteiden lataamisessa virheiden käsittelyä.</li>
      <li>1.0.27 - 20.08.2018: Parannettu tositteiden lataamisessa virheiden käsittelyä + päivitetty kirjastot.</li>
      <li>1.0.26 - 16.08.2018: Päivitetty Angular.</li>
      <li>1.0.25 - 14.08.2018:
        <ul>
          <li>Päivitetty kaikki kirjastot</li>
          <li>Korjattu / tiedostoja ladattaessa (Ei luo enää ihmeellistä alikansiota)</li>
          <li>Korjattu virhe, joka johti erilaisiin kansiorakenteisiin ladattaessa tiedostoja</li>
          <li>Muutettu tositteet -> lataa tositteita</li>
          <li>Funktiot Googlen Firestore Functioneissa päivitetty Node 6 -> Node 8</li>
        </ul>
      </li>
      <li>1.0.24 - 08.08.2018: Lisätty maksutapakuvakkeita.</li>
      <li>1.0.23 - 07.08.2018: Korjattu kirjaudu käyttäjänä ominaisuutta.</li>
      <li>1.0.22 - 07.08.2018: Korjattu vastuu- ja tuuraajakirjanpitäjän listauksesta katoavat muutokset.</li>
      <li>1.0.21 - 07.08.2018: Lisätty appin versio osaksi logitusta.</li>
      <li>1.0.20 - 05.08.2018: Parannettu päivämäärien validointia + päivitetty kaikki kirjastot.</li>
      <li>1.0.19 - 04.08.2018: Yksinkertaistettu sovelluksen version hallintaa.</li>
      <li>1.0.18 - 04.08.2018: Korjattu ei-oikeuksia Firestore ongelma uloskirjautumisen yhteydessä.</li>
      <li>1.0.12 - 1.0.17 - 04.08.2018: Otettu Firestoren uusi timestamp käyttöön.</li>
      <li>1.0.11 - 01.08.2018: Korjattu VAT-numero -> Y-tunnus</li>
      <li>1.0.10 - 01.08.2018: Lisätty poistettujen tositteiden katselu.</li>
      <li>1.0.9 - 01.08.2018: Korjattu virhe asiakkaan lisäämisessä.</li>
      <li>1.0.8 - 01.08.2018: Parannettu tositteiden selailu-sivua.</li>
      <li>1.0.7 - 31.07.2018: Korjattu muutoshistorian sisältö</li>
      <li>1.0.6 - 31.07.2018: Korjattu ALV-portaiden muokkaus</li>
      <li>1.0.5 - 30.07.2018: Lisätty tämä muutoshistoria-dialogi</li>
      <li>1.0.4 - 30.07.2018: 1.0.2 ominaisuuden testaaminen</li>
      <li>1.0.3 - 30.07.2018: 1.0.2 ominaisuuden testaaminen</li>
      <li>1.0.2 - 30.07.2018: Tehty automaattinen selaimen päivitys version päivittyessä</li>
      <li>1.0.1 - 29.07.2018: Korjattu tositteiden lataushistorian järjestys</li>
      <li>1.0.0 - 29.07.2018: Versiohistoria lisätty</li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>OK</button>
</div>
