<div class="list-page">

  <app-asiakas-otsikko otsikko="Aineiston lataus" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Lataukset (Lemonaid): <a [href]="latauksetEncodedUriObservable | async" target="_BLANK">{{latauksetUriObservable | async}}</a></div>
  </div>

  <div style="display: flex; flex-flow: row wrap;">

    <form [formGroup]="form">
      <div style="display: flex; justify-content: space-between; flex-flow: row wrap; width: 275px; margin-right: 40px;">
        <mat-form-field style="max-width: 125px;">
          <mat-label>Alkaa</mat-label>
          <input type="text" [max]="alkaaMaxDate" [name]="name" [matDatepicker]="alkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required />
          <mat-datepicker-toggle matSuffix [for]="alkaaPicker"></mat-datepicker-toggle>
          <mat-datepicker #alkaaPicker></mat-datepicker>
          <mat-error *ngIf="alkaa?.errors?.required && (alkaa?.dirty || alkaa?.touched)">Alkamiskuukausi puuttuu</mat-error>
          <mat-error *ngIf="alkaa?.errors?.matDatepickerMax && (alkaa?.dirty || alkaa?.touched)">Ei ole loppumiskuukautta ennen</mat-error>
        </mat-form-field>
        <mat-form-field style="max-width: 125px;">
          <mat-label>Loppuu</mat-label>
          <input type="text" [min]="loppuuMinDate" [name]="name" [matDatepicker]="loppuuPicker" formControlName="loppuu" matInput data-lpignore="true" required />
          <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle>
          <mat-datepicker #loppuuPicker></mat-datepicker>
          <mat-error *ngIf="loppuu?.errors?.required && (loppuu?.dirty || loppuu?.touched)">Loppumiskuukausi puuttuu</mat-error>
          <mat-error *ngIf="loppuu?.errors?.matDatepickerMin && (loppuu?.dirty || loppuu?.touched)">Ei ole alkamiskuukauden jälkeen</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field style="width: 275px;">
        <mat-label>Kieli</mat-label>
        <mat-select formControlName="kieli">
          <mat-option *ngFor="let valittavaKieli of valittavatKielet" [value]="valittavaKieli.tunniste">
            {{ valittavaKieli.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div>
      <div class="light-blue-text" style="font-size: 20px; margin-bottom: 4px; line-height: 22px; margin-top: -10px;">Tilikaudet</div>
      <ng-container *ngIf="asiakasObservable | async; let asiakas;">
        <ng-container *ngFor="let tilikausi of asiakas.tilikaudet">
          <div style="display: flex; align-items: center;">{{ tilikausi.alkaa | lokaalipvm | async }} - {{ tilikausi.loppuu | lokaalipvm | async }} <button mat-button (click)="valitseTilikaudenAikavali(tilikausi)">Valitse aikaväli</button></div>
        </ng-container>
      </ng-container>
    </div>

  </div>

  <button class="primarybutton" style="margin: 2em 0;" mat-button (click)="luoAineisto()" type="button">Luo aineisto</button>

  <div>
    <h2 style="font-size: 22px; line-height: 40px; padding: 15px 0 5px;" class="light-blue-text">Aikaisemmin luodut aineistot</h2>
    <ng-container *ngIf="ladattavatAineistotObservable | async; let ladattavatAineistot; else ladataanTemplate">
      <table style="border-spacing: 0">
        <thead>
          <tr>
            <th>Luotu</th>
            <th>Tila</th>
            <th>Aikaväli</th>
            <th>Lataa nyt</th>
            <th>Anna latauslinkki</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ladattavaAineisto of ladattavatAineistot">
            <td [title]="ladattavaAineisto.kn">
              {{ ladattavaAineisto.c | ltimestamptime | async }}
            </td>
            <td>
              <div *ngIf="ladattavaAineisto.b !== 'o' && ladattavaAineisto.b !== 'v'" style="width: 130px;">
                {{ ladattavaAineisto.b === 't' ? 'Tarkistetaan' : 'Luodaan' }}<mat-progress-spinner diameter="20" style="display: inline-block; margin-left: 10px; vertical-align: middle;" mode="indeterminate"></mat-progress-spinner>
              </div>
              <div *ngIf="ladattavaAineisto.b === 'o'">Valmis</div>
              <div *ngIf="ladattavaAineisto.b === 'v'">Virheellinen</div>
            </td>
            <td>{{ ladattavaAineisto.s | lnumberpvm | async }} - {{ ladattavaAineisto.e | lnumberpvm | async }}</td>
            <td style="text-align: center;">
              <button mat-button *ngIf="ladattavaAineisto.b === 'o'" (click)="lataaNyt(ladattavaAineisto)">Lataa</button>
            </td>
            <td style="text-align: center;">
              <button mat-button *ngIf="ladattavaAineisto.b === 'o'" (click)="luoLatauslinkki(ladattavaAineisto)">Latauslinkki</button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-template #ladataanTemplate>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>

  </div>


</div>