<div class="light-blue-text asiakasotsikko sisennetty">
  <div style="margin-right: auto;">
    <button *ngIf="naytaPalaa || !(vasenValikkoOnAuki | async)" class="asiakasotsikko-takaisin" mat-button (click)="peruuta()" type="button">
      <i class="icon ion-md-arrow-back"></i>
    </button>
    {{otsikko}}
  </div>
  <!-- <div style="text-align: right; margin-left: 10vw;">
    <div class="dark-gray-text" style="font-size: 12px; line-height: 14px; margin-bottom: -13px;">Omaveron saldo</div>
    <div [class.red-text]="0 > verotilinSaldo" [class.flashit]="0 > verotilinSaldo" [class.green-text]="verotilinSaldo > 0" [innerHTML]="verotilinSaldoFormatoitu"></div>
  </div> -->
  <div style="text-align: right; margin-left: 10vw;">
    <div (dblclick)="valitseTeksti(nimiDivi)" #nimiDivi>{{ asiakas?.nimi }}{{ naytaId ? (' (id: ' + asiakas?.asiakasId + ')') : '' }}</div>
    <div (dblclick)="valitseTeksti(ytunnusDivi)" #ytunnusDivi class="dark-gray-text" style="font-size: 12px; line-height: 14px; margin-top: -13px; padding-top: 10px;">{{ asiakas?.ytunnus }}</div>
  </div>
</div>
<div *ngIf="synkronoi" style="width: 100%;">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <div *ngIf="synkronoi" style="font-size: 10px; padding-top: 6px; padding-bottom: 6px; text-align: center;" class="gray-text">
    Asiakkaan tietoja siirretään Lemonaid-web, -ios ja -android sovelluksiin
  </div>
</div>