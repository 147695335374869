<div class="list-page" style="max-width: unset; padding: 2vh 1vw;">

  <!-- <app-asiakas-koti-otsikko></app-asiakas-koti-otsikko> -->

  <form [formGroup]="form" novalidate style="margin: 0 0 2em 0;">

    <!-- <div class="lisaa-nappula-container">
      <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4 icon">
        <mat-icon style="padding-right: 6px;">add</mat-icon>{{'asiakkaat.lisaa' | translate | async}}
      </button>
    </div> -->

    <div style="display: flex; flex-flow: row wrap; justify-content: space-between; align-items: center; padding: 0;">

      <!-- <div style="display: flex; flex-flow: row wrap; justify-content: flex-start; align-items: center; padding: 0;"> -->
      <mat-form-field style="width: 180px; margin-right: 25px;">
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'asiakkaat.etsi' | translate | async }}</mat-label>
        <input #hakuInput type="text" formControlName="nimi" matInput autocomplete="kirjatoautotätihattu" [name]="etsiName" data-lpignore="true" />
      </mat-form-field>
      <div style="display: flex; flex-flow: row wrap; align-items: center;">
        <mat-form-field style="width: 125px; margin-right: 25px;">
          <mat-label>Yhtiömuoto</mat-label>
          <mat-select formControlName="yhtiomuoto">
            <mat-option [value]="null">

            </mat-option>
            <mat-option *ngFor="let yhtiomuoto of yritysmuodot" [value]="yhtiomuoto.koodi">
              {{ yhtiomuoto.nimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 125px; margin-right: 25px;">
          <mat-label>Alv-jakso</mat-label>
          <mat-select formControlName="alvjakso">
            <mat-option [value]="null">

            </mat-option>
            <mat-option *ngFor="let alvjakso of alvjaksot" [value]="alvjakso.koodi">
              {{ alvjakso.nimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox style="width: 170px; margin-right: 25px;" formControlName="vaintoimiavaativat">Näytä vain erääntyvät</mat-checkbox>
      </div>
      <div style="display: flex; flex-flow: row wrap;">
        <mat-form-field style="width: 95px; margin-right: 25px;">
          <mat-label>Kuukaudesta</mat-label>
          <input matInput [matDatepicker]="dp1" formControlName="vuosikkalku" [min]="minMonthStart" [max]="maxMonthStart">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 startView="year" (monthSelected)="chosenMonthHandlerAlku($event, dp1)" panelClass="kuukausi-valitsin"></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 95px; margin-right: 25px;">
          <mat-label>Kuukauteen</mat-label>
          <input matInput [matDatepicker]="dp2" formControlName="vuosikkloppu" [min]="minMonthEnd" [max]="maxMonthEnd">
          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
          <mat-datepicker #dp2 startView="year" (monthSelected)="chosenMonthHandlerLoppu($event, dp2)" panelClass="kuukausi-valitsin"></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-radio-group formControlName="minuutitvaitehot" style="transform: scale(0.9);">
        <mat-radio-button [value]="'minuutit'">Näytä minuutit</mat-radio-button><br />
        <mat-radio-button [value]="'tehot'">Näytä tehot</mat-radio-button>
      </mat-radio-group>
      <!-- </div> -->

      <!-- <div class="summat" *ngIf="false && tyontekoInfoObservable | async; let tyontekoInfo">
        <div>
          <div class="otsikko">Etenemä</div>
          <div class="maara" [class.red-text]="0 > tyontekoInfo.progressio" [class.green-text]="tyontekoInfo.progressio >= 0">{{tyontekoInfo.progressio | lmoneyl: 'EUR':'fi'}}</div> -->
      <!-- <div class="tavoite">Kaava: (0 - (pallurat + huutomerkit)) + (Tavoite * päivien määrä)<br/>
            Arvot: (0 - ({{ tyontekoInfo.palluroidenSumma }} + {{ tyontekoInfo.huutomerkkienSumma }})) + ({{ tyontekoInfo.keskimaarainenPaivatavoite }} * {{ tyontekoInfo.paiviaSeuraavaanErapaivaan }})<br/>
            (Seuraava eräpäivä: {{tyontekoInfo.seuraavaErapaiva | ldate | async }}, josta laskettu arkipäivien määrä {{tyontekoInfo.paiviaSeuraavaanErapaivaan}})
          </div> -->
      <!-- </div> -->
      <!-- <div>
          <div class="otsikko">Maksettu</div>
          <div class="maara green-text" counto [step]="16.667" [countTo]="suurinSumma.saatu" [countFrom]="suurinSumma.saatuAlkuperainen" [duration]="1" (countoChange)="countoSaatu = $event" (countoEnd)="onCountoEndSaatu()">{{countoSaatu | lmoney: uusiValuutta | async}}</div>
        </div> -->
      <!-- <div>
          <div class="otsikko">Päivän veloitus</div>
          <div class="maara blue-text">{{tyontekoInfo.tehtyTanaan | lmoneyl: 'EUR':'fi'}}</div>
          <div class="tavoite">Tavoite {{tyontekoInfo.keskimaarainenPaivatavoite | lmoneyl: 'EUR':'fi'}}
            <span [class.red-text]="tyontekoInfo.dynaaminenSumma > 0" [class.green-text]="0 > tyontekoInfo.dynaaminenSumma">{{tyontekoInfo.dynaaminenSumma | lmoneyl: 'EUR':'fi'}}</span> -->
      <!-- <br/>
            Kaava: Kokonaislaskutus ({{tyontekoInfo.nykyinenKuukausilaskutus | lmoneyl: 'EUR':'fi'}}) / 20 -->
      <!-- </div>
        </div>
      </div>-->
    </div>

  </form>

  <app-asiakkaat-listaus-old></app-asiakkaat-listaus-old>

</div>