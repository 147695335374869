import { Component, ErrorHandler, ChangeDetectionStrategy, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl, FormGroup } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatMenuTrigger } from '@angular/material/menu'

import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { ListausAsiakas, AsiakkaatDataSourceService, ListausAsiakkaanSarake, KuukausiruudunTiedot, AsiakkaatDataSourceStateService } from './asiakkaat.datasource.service'
import { KuukausiJaValmiusaste } from './asiakkaat.component'
import { KayttajienTietojenLatauslaajuus, AsiakkaidenSpostiosoitteidenExportReq, AsiakkaidenSpostiosoitteidenExportResp } from 'app/_jaettu-lemonator/model/asiakas'
import { AsiakkaanMuistiinpanotDialogData, AsiakkaanMuistiinpanotDialog } from './asiakkaan-muistiinpanot.dialog'
import { KirjanpitajanSarake, KirjanpitajanSarakkeidenArvotAsiakkaalle, KirjanpitajanSarakkeenArvo } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajanSarakkeenMuokkausDialogData, KirjanpitajanSarakkeenMuokkausDialog } from './kirjanpitajan-sarakkeen-muokkaus.dialog'
import { AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog, AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData } from './asiakkaan-kirjanpitajan-sarakkeen-arvon-muokkaus.dialog'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { DateService } from 'app/_shared-core/service/date.service'

import { Observable, combineLatest, Subject, firstValueFrom } from 'rxjs'
import { map, startWith, takeUntil } from 'rxjs/operators'

import * as XLSX from 'xlsx'
import { AsiakkaanKirjanpitostatusKuukaudelleDialogData, AsiakkaanKirjanpitostatusKuukaudelleDialog } from './asiakkaan-kirjanpitostatus-kuukaudelle.dialog'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { KirjanpitoKuukausiStripService } from 'app/_angular/service/kirjanpito/kirjanpito-kuukausistrip.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'
import { TaukoMuistutusDialog, TaukoMuistutusDialogData } from './tauko-muistutus.dialog'

interface ListauksenSummatiedot {
  asiakkaita: number
  // veloitus: number
  // keskihinta: number
}

@Component({
  selector: 'app-asiakkaat-listaus',
  templateUrl: './asiakkaat-listaus.component.html',
  // styleUrls: ['./asiakkaat-listaus.component.css'], // TYYLIT OVAT PÄÄTYYLISSÄ TEHON TAKIA!!!
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakkaatListausComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  kuukaudetObservable: Observable<KuukausiJaValmiusaste[]>
  lataaObservable: Observable<boolean>
  asiakkaatObservable: Observable<ListausAsiakas[]>

  asiakkaidenLukumaaraObservable: Observable<number>
  minwidthObservable: Observable<string>
  // pageSizeObservable: Observable<number>
  listauksenSummatiedotObservable: Observable<ListauksenSummatiedot>
  onkoKehittajaObservable: Observable<boolean>
  kaikkiSarakkeetObservable: Observable<KirjanpitajanSarake[]>
  aktiivisetSarakkeetObservable: Observable<KirjanpitajanSarake[]>
  naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable: Observable<boolean>

  time: number
  latausForm: FormGroup<{ latauslaajuus: FormControl<number> }>
  omatVaiKirjanpitajanSarakkeetForm: FormGroup<{ omatVaiKirjanpitajanSarakkeet: FormControl<'o' | 'k'> }>
  // valmiusprosenttiAlgoFormControl: FormControl<'u' | 'v'>

  private latauslaajuus: KayttajienTietojenLatauslaajuus = KayttajienTietojenLatauslaajuus.KaikkiOsoitteet
  private _asiakkaatDataSourceService: AsiakkaatDataSourceService
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _router: Router,
    private _asiakasDataSourceStateService: AsiakkaatDataSourceStateService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _currencyService: CurrencyService,
    private _firebase: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _asiakasUriService: AsiakasUriService,
    private _dateService: DateService,
    private _copyService: KopioijaPalvelu,
    private _kirjanpitoKuukausStripService: KirjanpitoKuukausiStripService,
    private _asiakkaatDataSourceStateService: AsiakkaatDataSourceStateService
  ) {

    // this.valmiusprosenttiAlgoFormControl = new FormControl<'u' | 'v'>('u')

    this.naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable = combineLatest([
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
      this._asiakasDataSourceStateService.rajaaKirjanpitajaAvainObservable
    ]).pipe(
      map(([kirjanpitajanTiedot, rajaaKirjanpitajaAvain]) => {
        return kirjanpitajanTiedot?.rooli === KirjanpitajanRooli.SUPER && rajaaKirjanpitajaAvain && kirjanpitajanTiedot.uid !== rajaaKirjanpitajaAvain
      })
    )

    const nykyinen = this._asiakkaatDataSourceStateService.naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable.value
    const omatVaiKirjanpitajanSarakkeet: FormControl<'o' | 'k'> = new FormControl(nykyinen)
    this.omatVaiKirjanpitajanSarakkeetForm = new FormGroup({
      omatVaiKirjanpitajanSarakkeet: omatVaiKirjanpitajanSarakkeet
    })
    omatVaiKirjanpitajanSarakkeet.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => this._asiakkaatDataSourceStateService.naytaOmaVaiToisenKirjanpitajanSarakkeetValintaObservable.next(value))

    this._asiakkaatDataSourceService = new AsiakkaatDataSourceService(
      this._firebase, this._errorHandler, this._kirjautunutKayttajaService, this._currencyService,
      this._dateService, this._copyService, this._asiakasUriService,
      this._kirjanpitoKuukausStripService, this._asiakasDataSourceStateService
    )

    this._asiakasDataSourceStateService.vapaaTekstihakuObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(filter => {
      this._asiakkaatDataSourceService.dataSource.filter = filter || null
    })

  }

  openSarakeContextMenu(event: MouseEvent, sarakeContextMenuTrigger: MatMenuTrigger, sarakeMenuTriggerSpan: HTMLElement, sarake: KirjanpitajanSarake) {
    event.preventDefault()
    sarakeMenuTriggerSpan.style.left = event.clientX + 'px'
    sarakeMenuTriggerSpan.style.top = event.clientY + 'px'
    // console.log('HERE', { asiakas: asiakas, kuukausi: kuukausi })
    sarakeContextMenuTrigger.menuData = { sarake: sarake }
    sarakeContextMenuTrigger.openMenu()
  }

  tyhjennaSarake(event: MouseEvent, sarake: KirjanpitajanSarake) {
    const data: AreYouSureDialogData = {
      header: 'Haluatko varmasti tyhjentää kaikki sarakkeen arvot?',
      rightAction: 'Tyhjennä',
      text: 'Tämä toiminto tyhjentää kaikki sarakkeen "' + sarake.nimi + '" arvot. Tätä toimintoa ei voi perua.'
    }
    this._dialog.open(AreYouSureDialog, { data: data }).afterClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async result => {
      if (result) {

        this._ladataanService.aloitaLataaminen()

        try {
          await this._tyhjennaSarake(sarake, false)
          this._ladataanService.lopetaLataaminen()
        } catch (err) {
          this._errorHandler.handleError(err)
          this._ladataanService.lopetaLataaminen()
        }

      }
    })
  }

  private async _tyhjennaSarake(sarake: KirjanpitajanSarake, poista: boolean) {

    const kaytettavanKirjanpitajanAvain = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)
    const kirjautuneenKirjanpitajanAvain = await firstValueFrom(this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable.pipe(
      map(a => a.avain)
    ))
    const uri = this._asiakasUriService.annaListausSarakkeidenCollectionUri(kaytettavanKirjanpitajanAvain)
    const kaikkiSnapit = await this._firebase.firestoreCollection<KirjanpitajanSarakkeidenArvotAsiakkaalle>(uri).getSnaps()

    const now = this._dateService.dateToNumberTimestamp(new Date())

    let batch = this._firebase.firestoreBatch()
    let batchLaskuri = 0
    for (const asiakkaanArvotSnap of kaikkiSnapit) {
      const asiakkaanArvot = asiakkaanArvotSnap.data()
      if (asiakkaanArvot?.v && asiakkaanArvot.v[sarake.avain] && (poista || asiakkaanArvot.v[sarake.avain].v)) {

        const uusiArvo: KirjanpitajanSarakkeenArvo = {
          v: null,
          p: kirjautuneenKirjanpitajanAvain,
          u: now
        }

        asiakkaanArvot.v[sarake.avain] = uusiArvo

        const update = {}
        if (poista) {
          update['v.' + sarake.avain] = this._firebase.firestoreDeleteMarker()
        } else {
          update['v.' + sarake.avain] = uusiArvo
        }

        batch.updateRef(asiakkaanArvotSnap.ref, update)
        batchLaskuri++

        const tallennuksenAvain = this._firebase.firestoreCreateId()
        const historiaUri = this._asiakasUriService.annaListausSarakkeenHistoriaUri(kaytettavanKirjanpitajanAvain, asiakkaanArvotSnap.id, tallennuksenAvain)
        batch.set(historiaUri, asiakkaanArvot)
        batchLaskuri++

        if (batchLaskuri > 450) {
          await batch.commit()
          batch = this._firebase.firestoreBatch()
          batchLaskuri = 0
        }

      }
    }

    await batch.commit()

  }

  async siirraSarakeOikealle(event: MouseEvent, sarake: KirjanpitajanSarake) {
    const sarakkeet = await firstValueFrom(this.kaikkiSarakkeetObservable)
    const indexOfClicked = sarakkeet.findIndex(a => a.avain === sarake.avain)
    if (indexOfClicked + 1 < sarakkeet.length) {
      // Swap places
      const tmp = sarakkeet[indexOfClicked]
      sarakkeet[indexOfClicked] = sarakkeet[indexOfClicked + 1]
      sarakkeet[indexOfClicked + 1] = tmp
      return this._paivitaSarakkeidenJarjestys(sarakkeet)
    }
  }

  async siirraSarakeVasemmalle(event: MouseEvent, sarake: KirjanpitajanSarake) {
    const sarakkeet = await firstValueFrom(this.kaikkiSarakkeetObservable)
    const indexOfClicked = sarakkeet.findIndex(a => a.avain === sarake.avain)
    if (indexOfClicked > 0) {
      // Swap places
      const tmp = sarakkeet[indexOfClicked]
      sarakkeet[indexOfClicked] = sarakkeet[indexOfClicked - 1]
      sarakkeet[indexOfClicked - 1] = tmp
      return this._paivitaSarakkeidenJarjestys(sarakkeet)
    }
  }

  private async _paivitaSarakkeidenJarjestys(sarakkeet: KirjanpitajanSarake[]): Promise<void> {
    const kirjanpitajanAvain = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)
    const batch = this._firebase.firestoreBatch()
    let jarjestys = 1
    for (const sarake of sarakkeet) {
      const sarakeUri = 'kirjanpitajat/' + kirjanpitajanAvain + '/kirjanpitajan-sarakkeet/' + sarake.avain
      const sarakePartial: Partial<KirjanpitajanSarake> = {
        jarjestys: jarjestys
      }
      batch.update(sarakeUri, sarakePartial)
      jarjestys++
    }
    return batch.commit()
  }

  poistaSarake(event: MouseEvent, sarake: KirjanpitajanSarake) {
    const data: AreYouSureDialogData = {
      header: 'Haluatko varmasti poistaa sarakkeen?',
      rightAction: 'Poista',
      text: 'Tämä toiminto poistaa sarakkeen "' + sarake.nimi + '" pysyvästi. Tätä toimintoa ei voi perua.'
    }
    this._dialog.open(AreYouSureDialog, { data: data }).afterClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async result => {
      if (result) {

        this._ladataanService.aloitaLataaminen()

        try {
          await this._tyhjennaSarake(sarake, true)

          const kirjanpitajanAvain = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)
          const uri = 'kirjanpitajat/' + kirjanpitajanAvain + '/kirjanpitajan-sarakkeet/' + sarake.avain
          await this._firebase.firestoreDeleteDoc(uri)

          this._ladataanService.lopetaLataaminen()
        } catch (err) {
          this._errorHandler.handleError(err)
          this._ladataanService.lopetaLataaminen()
        }

      }
    })
  }

  openContextMenu(event: MouseEvent, menuTrigger: MatMenuTrigger, span: HTMLElement, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    event.preventDefault()
    span.style.left = event.clientX + 'px'
    span.style.top = event.clientY + 'px'
    // console.log('HERE', { asiakas: asiakas, kuukausi: kuukausi })
    menuTrigger.menuData = { asiakas: asiakas, kuukausi: kuukausi }
    menuTrigger.openMenu()
  }

  ngOnInit() {

    const latauslaajuusFormControl = new FormControl<number>(KayttajienTietojenLatauslaajuus.KaikkiOsoitteet)
    this.latausForm = new FormGroup<{ latauslaajuus: FormControl<number> }>({ 'latauslaajuus': latauslaajuusFormControl })
    latauslaajuusFormControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this.latauslaajuus = value
    })

    this.kaikkiSarakkeetObservable = this._asiakasDataSourceStateService.kaikkiSarakkeetObservable
    this.aktiivisetSarakkeetObservable = this._asiakkaatDataSourceService.aktiivisetSarakkeetObservable

    this.lataaObservable = this._asiakkaatDataSourceService.lataaObservable
    this.asiakkaatObservable = this._asiakkaatDataSourceService.dataSource.connect()
    this.asiakkaidenLukumaaraObservable = this.asiakkaatObservable.pipe(
      map(asiakkaat => {
        if (asiakkaat) {
          return asiakkaat.length
        }
        return 0
      })
    )

    this.kuukaudetObservable = combineLatest([
      this._asiakkaatDataSourceService.kaikkiAsiakkaatObservable,
      this._asiakkaatDataSourceService.naytettavatKuukaudetObservable
      // ,
      // this.valmiusprosenttiAlgoFormControl.valueChanges.pipe(startWith(this.valmiusprosenttiAlgoFormControl.value))
    ]).pipe(
      map(([asiakkaat, kuukaudet /* , algoritmi*/]) => {

        // console.time('Lasketaan valmiusasteita.')
        // console.log('ASIAKKAITA: ' + asiakkaat.length)
        // console.log('KUUKAUDET: ' + kuukaudet)

        const kuukausiMap: Map<number, { kk: KuukausiJaValmiusaste, kok: number, valm: number }> = new Map()
        for (const kuukausi of kuukaudet) {
          kuukausiMap.set(this._dateService.localMonthToNumber(kuukausi), { kk: kuukausi as KuukausiJaValmiusaste, kok: 0, valm: 0 })
        }

        for (const asiakas of asiakkaat) {
          if ((!asiakas.u && asiakas.p !== 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') || !asiakas.kuukaudet) {
            continue
          }
          // console.log(asiakas.kuukaudet)
          for (const kuukausi of asiakas.kuukaudet) {

            // Deaktivoitu tai ei osa tilikautta
            if (kuukausi.bd || !kuukausi.bot) {
              continue
            }

            const kk = kuukausiMap.get(kuukausi.k)
            if (!kk) {
              continue
            }

            // if (algoritmi === 'u') {
            // Oma logiikka ZEN-asiakkaille
            if (asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
              if (kuukausi.kp) {
                kk.valm += 10
              }
              kk.kok += 10
            } else if (
              !kuukausi.po && // Ei pöytälaatikossa
              !kuukausi.t && // Ei tauolla
              kuukausi.sv && // Sopimus voimassa
              kuukausi.h // On hinta
            ) {
              if (kuukausi.kp) {
                kk.valm += 10
              }
              kk.kok += 10
            }
            // } else {
            //   if (asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
            //     if (kuukausi.kp) {
            //       kk.valm += 10
            //     }
            //     kk.kok += 10
            //   } else if (kuukausi.h) {
            //     if (kuukausi.kp) {
            //       kk.valm += kuukausi.h
            //     }
            //     kk.kok += kuukausi.h
            //   }
            // }

          }
        }

        for (const kk of kuukausiMap.values()) {
          kk.kk.valmiusaste = kk.kok > 0 ? kk.valm / kk.kok * 100 : 0 // Math.floor(Math.random() * 100) + 1
          kk.kk.valmiusasteMuotoiltu = this._currencyService.formatoiDesimaali(kk.kk.valmiusaste, 2, 'fi')
        }

        // console.timeEnd('Lasketaan valmiusasteita.')

        return kuukaudet as KuukausiJaValmiusaste[]

      })
    )

    this.listauksenSummatiedotObservable = this.asiakkaatObservable.pipe(
      map(asiakkaat => {
        const paluuarvo: ListauksenSummatiedot = {
          asiakkaita: 0
          // ,
          // keskihinta: 0,
          // veloitus: 0
        }
        if (asiakkaat) {
          // for (const asiakas of asiakkaat) {
          //   if (asiakas.h) {
          //     const money = asiakas.h.split('(')
          //     const num = money[0].replace('€', '').trim().replace(',', '.')
          //     paluuarvo.veloitus += Number(num)
          //     paluuarvo.asiakkaita++
          //   }
          // }
          // if (paluuarvo.asiakkaita > 0) {
          //   paluuarvo.keskihinta = paluuarvo.veloitus / paluuarvo.asiakkaita
          // }
          // HUOM!! Käytetään asiakkaiden lukumäärään kaikki asiakkaita, vaikka keskimääräinen hinta lasketaan
          // vain asiakkaista, joilla on hinta!
          paluuarvo.asiakkaita = asiakkaat.length
        }
        return paluuarvo
      })
    )

    // this.pageSizeObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
    //   map(kirjanpitaja => {
    //     // if (kirjanpitaja && kirjanpitaja.rooli === KirjanpitajanRooli.SUPER) {
    //     return 150
    //     // }
    //     // return 9999999
    //   }),
    //   distinctUntilChanged()
    // )

    this._asiakkaatDataSourceService.dataSource.paginator = this.paginator

    // const currentSort = this._asiakkaatDataSourceService.dataSource.sort
    // if (currentSort) {
    //   this.sort.sort({
    //     id: currentSort.active,
    //     disableClear: false,
    //     start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    //   })
    // } else {
    //   this.sort.sort({
    //     disableClear: false,
    //     id: 'nimi',
    //     start: 'asc'
    //   })
    // }
    this._asiakkaatDataSourceService.dataSource.sort = this.sort

    this.minwidthObservable = combineLatest([this.kuukaudetObservable, this.aktiivisetSarakkeetObservable]).pipe(
      map(([kuukaudet, sarakkeet]) => {
        if (kuukaudet && sarakkeet) {
          return ((kuukaudet.length * 50) + (sarakkeet.length * 80) + 925) + 'px'
        }
        return '925px'
      })
    )

    this.onkoKehittajaObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        if (kirjanpitaja && (kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2')) {
          return true
        }
        return false
      }),
      startWith(false)
    )

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
    this._asiakkaatDataSourceService?.destroy()
  }

  clickRuutu(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    this._ladataanService.aloitaLataaminen()
    event.stopPropagation()
    event.preventDefault()
    const localMonth = this._dateService.numberToLocalMonth(kuukausi.k)
    this._router.navigate(['asiakkaat', asiakas.k, 'kirjanpito', localMonth.year, localMonth.month]).then(() => {
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
    })
  }

  /**
 * TODO: MYÖS KOMPONENTISSA KirjanpitoKuukausiStripComponent !!
 * @param event TODO:
 * @param asiakas
 * @param kuukausi
 */
  avaaKuukausistatus(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {

    const data: AsiakkaanKirjanpitostatusKuukaudelleDialogData = {
      asiakas: asiakas,
      kuukausi: this._copyService.cloneObjectDeep(kuukausi)
    }
    // if (asiakas.kuukausiruudut && asiakas.kuukausiruudut[kuukaudenAvain]) {
    this._dialog.open(AsiakkaanKirjanpitostatusKuukaudelleDialog, { data: data, autoFocus: false })
    // } else {
    //   const d: AsiakkaanKirjanpitostatusLisaaTilikausiDialogData = {
    //     asiakas: asiakas,
    //     status: status,
    //     kuukausi: { year: vuosi, month: kk }
    //   }
    //   const dialogRef = this._dialog.open(AsiakkaanKirjanpitostatusLisaaTilikausiDialog, { data: d, autoFocus: false })
    //   dialogRef.afterClosed().pipe(
    //     takeUntil(this._ngUnsubscribe)
    //   ).subscribe(result => {
    //     if (result) {
    //       this._dialog.open(AsiakkaanKirjanpitostatusKuukaudelleDialog, { data: data, autoFocus: false })
    //     }
    //   })
    // }
  }

  avaaUudessaIkkunassa(event: MouseEvent, asiakas: ListausAsiakas, kuukausi: KuukausiruudunTiedot) {
    event.preventDefault()
    event.stopPropagation()

    const lokaali: LocalMonth = this._dateService.numberToLocalMonth(kuukausi.k)
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/asiakkaat/${asiakas.k}/kirjanpito/${lokaali.year}/${lokaali.month}`])
    )

    window.open(url, '_blank')
  }

  async muokkaaMuistiinpanoja(event: MouseEvent, listausAsiakas: ListausAsiakas) {
    event.stopPropagation()
    event.preventDefault()
    const dialogData: AsiakkaanMuistiinpanotDialogData = {
      asiakas: listausAsiakas
    }
    this._dialog.open(AsiakkaanMuistiinpanotDialog, { autoFocus: false, data: dialogData })
  }

  kayttajatJaTositteet(asiakas: ListausAsiakas) {
    // this._asiakasComponentDataResolve.asetaOlemassaolevaData(asiakas)
    // this._asiakasService.asetaNykyinenAsiakas(asiakas)
    // if (asiakas.p === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
    //   this._router.navigate(['/asiakkaat/', asiakas.k, 'kirjanpito'])
    // } else {
    this._router.navigate(['/asiakkaat/', asiakas.k, 'asiakastiedot'])
    // }
  }

  async annaKaikkienKayttajienSahkopostiosoitteet() {

    this._ladataanService.aloitaLataaminen()

    try {

      const naytettavatAsiakkaat = await firstValueFrom(this.asiakkaatObservable)
      const asiakkaidenAvaimet = naytettavatAsiakkaat.map(asiakas => asiakas.k)

      const requestData: AsiakkaidenSpostiosoitteidenExportReq = {
        scope: this.latauslaajuus,
        asiakkaidenAvaimet: asiakkaidenAvaimet
      }
      const response = await this._firebase.functionsCall<AsiakkaidenSpostiosoitteidenExportReq, AsiakkaidenSpostiosoitteidenExportResp>('asiakasListausOsoitteetExport', requestData)
      this._ladataanService.lopetaLataaminen()

      if (!response || response.e) {
        this._errorHandler.handleError(response.e)
      } else {

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(response.data)

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Käyttäjät')

        /* save to file */
        XLSX.writeFile(wb, 'Asiakkaiden_kayttajat.xlsx')

      }
    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    }

  }

  async lisaaSarake() {
    this.muokkaaSaraketta(null)
  }

  async muokkaaSaraketta(sarake: KirjanpitajanSarake) {
    const kirjanpitajanAvain = await firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable)
    const dialogData: KirjanpitajanSarakkeenMuokkausDialogData = {
      kirjanpitajaUid: kirjanpitajanAvain,
      sarake: sarake
    }
    this._dialog.open(KirjanpitajanSarakkeenMuokkausDialog, { autoFocus: false, data: dialogData })
  }

  async muokkaaSarakeSolua(event: MouseEvent, asiakas: ListausAsiakas, sarake: ListausAsiakkaanSarake) {

    event.preventDefault()
    event.stopPropagation()

    const [kirjanpitajanAvain, kaikkiSarakkeet] = await Promise.all([firstValueFrom(this._asiakkaatDataSourceStateService.kirjanpitajanAvainSarakkeitaVartenObservable), firstValueFrom(this.kaikkiSarakkeetObservable)])
    for (const s of kaikkiSarakkeet) {
      if (s.avain === sarake.sarakeAvain) {

        const data: AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialogData = {
          asiakas: asiakas,
          sarake: s,
          tilanAvain: sarake.tilaAvain,
          kirjanpitajaUid: kirjanpitajanAvain
        }

        this._dialog.open(AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog, { data: data, autoFocus: false })
        break

      }
    }
  }

  openTaukoMuistutusDialog(asiakasAvain: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()

    const dialogData: TaukoMuistutusDialogData = {
      asiakasAvain: asiakasAvain
    }
    this._dialog.open<TaukoMuistutusDialog, TaukoMuistutusDialogData>(TaukoMuistutusDialog, { data: dialogData })
  }
}
