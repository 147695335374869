import { Component, ErrorHandler, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatInput } from '@angular/material/input'
import { Timestamp } from 'app/_shared-core/model/common'

import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'
import { AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus, AsiakkaanNimitiedot } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitoService, ListausAlvIlmoitus } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { AlvIlmoitus, IlmoitinIlmoituksenLahetysvirhe, IlmoitinIntegrationEntity, VeroilmoitusFinal } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AlvIlmoituksenTyviTiedostonLatauspyynto, AlvIlmoituksenTyviTiedostonLatausvastaus } from 'app/_jaettu-lemonator/model/kirjanpito'

import { Observable, BehaviorSubject, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { DebugService } from 'app/_angular/service/debug.service'
import { IlmoitusRivi } from 'app/_jaettu-lemonator/service/base-ilmoitin-export.service'
import { TilinpaatosPrhRekisterointi } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface OmaveroSaldokoosteenAikaleima {
  aikaleima: Timestamp
}

interface AlvIlmoJaTiedot {
  path: string
  asiakas: AsiakkaanNimitiedot
  ilmoitus: ListausAlvIlmoitus
  viimeisinVirhe?: IlmoitinIlmoituksenLahetysvirhe
}

interface VeroilmoJaTiedot {
  path: string
  asiakas: AsiakkaanNimitiedot
  ilmoitus: VeroilmoitusFinal
  kirjanpitaja: KirjanpitajanNimitiedot
  viimeisinVirhe?: IlmoitinIlmoituksenLahetysvirhe
  content: string
  url: string
  uri: string
}

interface PrhRekisterointiJaTiedot {
  path: string
  asiakas: AsiakkaanNimitiedot
  ilmoitus: TilinpaatosPrhRekisterointi
  kirjanpitaja: KirjanpitajanNimitiedot
  viimeisinVirhe?: IlmoitinIlmoituksenLahetysvirhe
  url: string
  uri: string
}

@Component({
  templateUrl: './verohallinto.component.html',
  styleUrls: ['./verohallinto.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YllapitoVerohallintoComponent implements OnInit {

  @ViewChild('kertisTextfield', { read: MatInput }) kertisTextfield: MatInput

  form: UntypedFormGroup
  loading = true
  commonError = ''
  name: string = 'ayuisesdf' + Math.random()
  kertisNroSubject: BehaviorSubject<number> = new BehaviorSubject(-1)
  kertisNroObservable: Observable<number> = this.kertisNroSubject.asObservable()

  saldotPaivitettyObservable: Observable<string>
  lahetetytIlmoituksetObservable: Observable<AlvIlmoJaTiedot[]>
  lahettamattomatVirheellisetObservable: Observable<AlvIlmoJaTiedot[]>
  lahettamattomatIlmoituksetObservable: Observable<AlvIlmoJaTiedot[]>
  lahettamattomatIlmoituksetMaaraObservable: Observable<number>

  lahetetytVeroilmoituksetObservable: Observable<VeroilmoJaTiedot[]>
  lahettamattomatVirheellisetVeroilmoituksetObservable: Observable<VeroilmoJaTiedot[]>
  lahettamattomatVeroilmoituksetObservable: Observable<VeroilmoJaTiedot[]>
  lahettamattomatVeroilmoituksetMaaraObservable: Observable<number>

  lahetetytPrhRekisteroinnitObservable: Observable<PrhRekisterointiJaTiedot[]>
  lahettamattomatVirheellisetPrhRekisteroinnitObservable: Observable<PrhRekisterointiJaTiedot[]>
  lahettamattomatPrhRekisteroinnitObservable: Observable<PrhRekisterointiJaTiedot[]>
  lahettamattomatPrhRekisteroinnitMaaraObservable: Observable<number>

  // omaveroMaksuyhteystiedotLogRivitObservable: Observable<string[]>
  // omaveroMaksuohjelmatLogRivitObservable: Observable<string[]>
  // omaveroSaldotLogRivitObservable: Observable<string[]>
  // ilmoitinAlvIlmoituksetObservable: Observable<string[]>

  private historiaaKerrallaVeroilmo: BehaviorSubject<number> = new BehaviorSubject(1)
  get historiaEntryjenMaaraVeroilmo(): number {
    return this.historiaaKerrallaVeroilmo.value
  }
  set historiaEntryjenMaaraVeroilmo(value: number) {
    this.historiaaKerrallaVeroilmo.next(value)
  }

  private historiaaKerrallaPrh: BehaviorSubject<number> = new BehaviorSubject(1)
  get historiaEntryjenMaaraPrh(): number {
    return this.historiaaKerrallaPrh.value
  }
  set historiaEntryjenMaaraPrh(value: number) {
    this.historiaaKerrallaPrh.next(value)
  }

  private historiaaKerralla: BehaviorSubject<number> = new BehaviorSubject(1)
  get historiaEntryjenMaara(): number {
    return this.historiaaKerralla.value
  }
  set historiaEntryjenMaara(value: number) {
    this.historiaaKerralla.next(value)
  }

  constructor(
    private errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonator,
    private dateService: DateService,
    private ladataanService: LadataanService,
    private timestampProvider: TimestampProviderBase,
    private kirjanpitajaService: KirjanpitajaService,
    private kirjanpitoService: KirjanpitoService,
    private kirjanpitoUriService: KirjanpitoUriService,
    private _debugService: DebugService,
    private _fileSaverService: FileSaverService
  ) {

    // this.omaveroMaksuyhteystiedotLogRivitObservable = database.list<PuppeteerLogEntry>('integraatiot/logs/omavero_maksuyhteystiedot/viimeisin/logs', ref => {
    //   return ref.orderByKey()
    // }).valueChanges().pipe(
    //   map(entries => {
    //     return entries.map(a => { return this.dateService.muotoilePaivaJaAikaDate(new Date(a.time), 'fi') + '\t' + a.entry + '\n' })
    //   })
    // )

    // this.omaveroSaldotLogRivitObservable = database.list<PuppeteerLogEntry>('integraatiot/logs/omavero_saldot/viimeisin/logs', ref => {
    //   return ref.orderByKey()
    // }).valueChanges().pipe(
    //   map(entries => {
    //     return entries.map(a => { return this.dateService.muotoilePaivaJaAikaDate(new Date(a.time), 'fi') + '\t' + a.entry + '\n' })
    //   })
    // )

    // this.omaveroMaksuohjelmatLogRivitObservable = database.list<PuppeteerLogEntry>('integraatiot/logs/omavero_maksuohjelmat/viimeisin/logs', ref => {
    //   return ref.orderByKey()
    // }).valueChanges().pipe(
    //   map(entries => {
    //     return entries.map(a => { return this.dateService.muotoilePaivaJaAikaDate(new Date(a.time), 'fi') + '\t' + a.entry + '\n' })
    //   })
    // )

    // this.ilmoitinAlvIlmoituksetObservable = database.list<PuppeteerLogEntry>('integraatiot/logs/ilmoitin_alv_ilmoitukset/viimeisin/logs', ref => {
    //   return ref.orderByKey()
    // }).valueChanges().pipe(
    //   map(entries => {
    //     return entries.map(a => { return this.dateService.muotoilePaivaJaAikaDate(new Date(a.time), 'fi') + '\t' + a.entry + '\n' })
    //   })
    // )

    // this.saldotPaivitettyObservable = firestore.doc<OmaveroSaldokoosteenAikaleima>('omavero-saldot-kooste/viimeisin-aikaleima').valueChanges().pipe(
    //   map(doku => {
    //     if (doku) {
    //       const asDate = this.dateService.timestampToDate(doku.aikaleima)
    //       return this.dateService.muotoilePaivaJaAikaDate(asDate, 'fi')
    //     }
    //     return ''
    //   })
    // )


    const asiakkaatMapObservable = this._firebase.functionsCall<AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus>('annaAsiakkaidenNimitiedot', {}, { timeout: 540 * 1000 }).then(
      asiakkaatVastaus => {
        const m: Map<string, AsiakkaanNimitiedot> = new Map()
        if (asiakkaatVastaus?.nimitiedot) {
          for (const a of asiakkaatVastaus.nimitiedot) {
            m.set(a.avain, a)
          }
        }
        return m
      }
    )

    const lahetetytVeroilmotSnapshotObservable = this.historiaaKerrallaVeroilmo.pipe(
      switchMap(maara => {
        const dateLessThan = this.timestampProvider.localDateToTimestamp({ year: 4000, month: 1, day: 1 })
        return this._firebase.firestoreCollectionGroup<IlmoitinIntegrationEntity>('kirjanpito-veroilmoitus-final').where('lahetetty', '<', dateLessThan).orderBy('lahetetty', 'desc').limit(maara).listenSnapshots()
      })
    )

    this.lahetetytVeroilmoituksetObservable = combineLatest([asiakkaatMapObservable, lahetetytVeroilmotSnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahetetytQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahetetytQuerySnapshot.map(a => {
          const ilmoitus = a.data() as VeroilmoitusFinal
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-veroilmoitus-final/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const uri = this.kirjanpitoUriService.annaVeroilmoituksenFinalUri(asiakas.avain, ilmoitus.avain)
          return {
            path: a.ref.path,
            ilmoitus: ilmoitus,
            asiakas: asiakas,
            content: this._ilmoitusRiviArrToString(ilmoitus.arvot),
            kirjanpitaja: kirjanpitajienNimitiedotMap.get(ilmoitus.creator),
            uri: uri,
            url: this._debugService.createFirestoreLink(uri)
          }
        })
      })
    )

    const lahettamattomatPrhRekisteroinniQuerySnapshotObservable = this._firebase.firestoreCollectionGroup<TilinpaatosPrhRekisterointi>('kirjanpito-prh-rekisteroinnit').where('lahetetty', '==', null).listenSnapshots()

    this.lahettamattomatPrhRekisteroinnitMaaraObservable = lahettamattomatPrhRekisteroinniQuerySnapshotObservable.pipe(
      map(snaps => snaps?.length ?? 0)
    )

    const kaikkiLahettamattomatPrhRekisteroinnitObservable: Observable<PrhRekisterointiJaTiedot[]> = combineLatest([asiakkaatMapObservable, lahettamattomatPrhRekisteroinniQuerySnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahetetytQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahetetytQuerySnapshot.map(a => {
          const ilmoitus = a.data()
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-prh-rekisteroinnit/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const uri = 'asiakkaat/' + asiakasAvain + '/kirjanpito-prh-rekisteroinnit/' + a.id
          return {
            path: a.ref.path,
            ilmoitus: ilmoitus,
            asiakas: asiakas,
            kirjanpitaja: kirjanpitajienNimitiedotMap.get(ilmoitus.creator),
            uri: uri,
            url: this._debugService.createFirestoreLink(uri)
          }
        })
      }),
      lemonShare()
    )

    this.lahettamattomatVirheellisetPrhRekisteroinnitObservable = kaikkiLahettamattomatPrhRekisteroinnitObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !!a.ilmoitus.lahetysvirheet)
        }
        return []
      }),
      map(virheelliset => {
        for (const virheellinen of virheelliset) {
          virheellinen.viimeisinVirhe = virheellinen.ilmoitus.lahetysvirheet[virheellinen.ilmoitus.lahetysvirheet.length - 1]
        }
        return virheelliset
      })
    )

    this.lahettamattomatPrhRekisteroinnitObservable = kaikkiLahettamattomatPrhRekisteroinnitObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !a.ilmoitus.lahetysvirheet)
        }
        return []
      })
    )

    const lahetetytPrhRekisteroinnitSnapshotObservable = this.historiaaKerrallaPrh.pipe(
      switchMap(maara => {
        const dateLessThan = this.timestampProvider.localDateToTimestamp({ year: 4000, month: 1, day: 1 })
        return this._firebase.firestoreCollectionGroup<TilinpaatosPrhRekisterointi>('kirjanpito-prh-rekisteroinnit').where('lahetetty', '<', dateLessThan).orderBy('lahetetty', 'desc').limit(maara).listenSnapshots()
      })
    )

    this.lahetetytPrhRekisteroinnitObservable = combineLatest([asiakkaatMapObservable, lahetetytPrhRekisteroinnitSnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahetetytQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahetetytQuerySnapshot.map(a => {
          const ilmoitus = a.data()
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-prh-rekisteroinnit/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const uri = 'asiakkaat/' + asiakasAvain + '/kirjanpito-prh-rekisteroinnit/' + a.id
          return {
            path: a.ref.path,
            ilmoitus: ilmoitus,
            asiakas: asiakas,
            kirjanpitaja: kirjanpitajienNimitiedotMap.get(ilmoitus.creator),
            uri: uri,
            url: this._debugService.createFirestoreLink(uri)
          }
        })
      })
    )

    const lahettamattomatVeroilmotQuerySnapshotObservable = this._firebase.firestoreCollectionGroup<VeroilmoitusFinal>('kirjanpito-veroilmoitus-final').where('lahetetty', '==', null).listenSnapshots()

    const kaikkiLahettamattomatVeroilmotObservable: Observable<VeroilmoJaTiedot[]> = combineLatest([asiakkaatMapObservable, lahettamattomatVeroilmotQuerySnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahettamattomatQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahettamattomatQuerySnapshot.map(a => {
          const ilmoitus = a.data() as VeroilmoitusFinal
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-veroilmoitus-final/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const uri = this.kirjanpitoUriService.annaVeroilmoituksenFinalUri(asiakas.avain, ilmoitus.avain)
          return {
            path: a.ref.path,
            ilmoitus: ilmoitus,
            asiakas: asiakas,
            content: this._ilmoitusRiviArrToString(ilmoitus.arvot),
            kirjanpitaja: kirjanpitajienNimitiedotMap.get(ilmoitus.creator),
            uri: uri,
            url: this._debugService.createFirestoreLink(uri)
          }
        }).sort((a, b) => {
          return a.ilmoitus.created.toMillis() - b.ilmoitus.created.toMillis()
        })
      }),
      lemonShare()
    )


    this.lahettamattomatVirheellisetVeroilmoituksetObservable = kaikkiLahettamattomatVeroilmotObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !!a.ilmoitus.lahetysvirheet)
        }
        return []
      }),
      map(virheelliset => {
        for (const virheellinen of virheelliset) {
          virheellinen.viimeisinVirhe = virheellinen.ilmoitus.lahetysvirheet[virheellinen.ilmoitus.lahetysvirheet.length - 1]
        }
        return virheelliset
      })
    )

    this.lahettamattomatVeroilmoituksetObservable = kaikkiLahettamattomatVeroilmotObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !a.ilmoitus.lahetysvirheet)
        }
        return []
      })
    )

    this.lahettamattomatVeroilmoituksetMaaraObservable = kaikkiLahettamattomatVeroilmotObservable.pipe(
      map(kaikki => kaikki ? kaikki.length : 0)
    )

    const lahetetytQuerySnapshotObservable = this.historiaaKerralla.pipe(
      switchMap(maara => {
        const dateLessThan = this.timestampProvider.localDateToTimestamp({ year: 4000, month: 1, day: 1 })
        return this._firebase.firestoreCollectionGroup<IlmoitinIntegrationEntity>('kirjanpito-alvilmoitukset').where('lahetetty', '<', dateLessThan).orderBy('lahetetty', 'desc').limit(maara).listenSnapshots()
      })
    )

    this.lahetetytIlmoituksetObservable = combineLatest([asiakkaatMapObservable, lahetetytQuerySnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahetetytQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahetetytQuerySnapshot.map(a => {
          const ilmoitus = a.data() as AlvIlmoitus
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-alvilmoitukset/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const listausIlmoitus = this.kirjanpitoService.muunnaAlvIlmoitusListausAlvIlmoitukseksi(true, asiakas, ilmoitus, kirjanpitajienNimitiedotMap)
          return {
            path: a.ref.path,
            ilmoitus: listausIlmoitus,
            asiakas: asiakas
          }
        })
      }),
      lemonShare()
    )

    const lahettamattomatQuerySnapshotObservable = this._firebase.firestoreCollectionGroup<AlvIlmoitus>('kirjanpito-alvilmoitukset').where('lahetetty', '==', null).listenSnapshots()

    const kaikkiLahettamattomatObservable: Observable<AlvIlmoJaTiedot[]> = combineLatest([asiakkaatMapObservable, lahettamattomatQuerySnapshotObservable, this.kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaatMap, lahettamattomatQuerySnapshot, kirjanpitajienNimitiedotMap]) => {
        return lahettamattomatQuerySnapshot.map(a => {
          const ilmoitus = a.data() as AlvIlmoitus
          const asiakasAvain = a.ref.path.split('asiakkaat/')[1].split('/kirjanpito-alvilmoitukset/')[0]
          const asiakas = asiakkaatMap.get(asiakasAvain)
          const listausIlmoitus = this.kirjanpitoService.muunnaAlvIlmoitusListausAlvIlmoitukseksi(true, asiakas, ilmoitus, kirjanpitajienNimitiedotMap)
          return {
            path: a.ref.path,
            ilmoitus: listausIlmoitus,
            asiakas: asiakas
          }
        }).sort((a, b) => {
          return a.ilmoitus.luotu.toMillis() - b.ilmoitus.luotu.toMillis()
        })
      }),
      lemonShare()
    )

    this.lahettamattomatVirheellisetObservable = kaikkiLahettamattomatObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !!a.ilmoitus.lahetysvirheet)
        }
        return []
      }),
      map(virheelliset => {
        for (const virheellinen of virheelliset) {
          virheellinen.viimeisinVirhe = virheellinen.ilmoitus.lahetysvirheet[virheellinen.ilmoitus.lahetysvirheet.length - 1]
        }
        return virheelliset
      })
    )

    this.lahettamattomatIlmoituksetObservable = kaikkiLahettamattomatObservable.pipe(
      map(kaikkiLahettamattomat => {
        if (kaikkiLahettamattomat) {
          return kaikkiLahettamattomat.filter(a => !a.ilmoitus.lahetysvirheet)
        }
        return []
      })
    )

    this.lahettamattomatIlmoituksetMaaraObservable = kaikkiLahettamattomatObservable.pipe(
      map(kaikki => kaikki ? kaikki.length : 0)
    )

  }

  private encodeUri(uri: string): string {
    return uri.replace(/\//g, '~2F')
  }

  ngOnInit() {

    this.form = new UntypedFormGroup({
      otpKayttajatunnus: new UntypedFormControl('wt57nd', [Validators.required]),
      otpSalasana: new UntypedFormControl(null, [Validators.required]),
      otpKertis: new UntypedFormControl(null, [Validators.required]),
      tilista: new UntypedFormControl(undefined, [
        Validators.pattern(/^[\d ]+$/)
      ]),
      tiliin: new UntypedFormControl(undefined, [
        Validators.pattern(/^[\d ]+$/)
      ])
    })

    this.kertisNroObservable.pipe(
      // TODO: TAKEUNTIL
    ).subscribe(kertisnro => {
      if (kertisnro > 0) {
        this.otpKayttajatunnus.disable()
        this.otpSalasana.disable()
        this.otpKertis.enable()
      } else {
        this.otpKayttajatunnus.enable()
        this.otpSalasana.enable()
        this.otpKertis.disable()
      }
    })

  }

  get otpKayttajatunnus(): AbstractControl {
    return this.form.get('otpKayttajatunnus')
  }

  get otpSalasana(): AbstractControl {
    return this.form.get('otpSalasana')
  }

  get otpKertis(): AbstractControl {
    return this.form.get('otpKertis')
  }

  // vieVeroilmot() {

  //   if (!this.form.valid) {
  //     this.formValidationService.merkitseKokoLomakeKosketuksi(this.form)
  //     return
  //   }

  //   this.ladataanService.aloitaLataaminen()

  //   if (this.kertisNroSubject.value < 1) {
  //     this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   } else {
  //     const omaveroCallablePayload: SuomiOtpFunktiokutsu = {
  //       kayttajatunnus: this.otpKayttajatunnus.value,
  //       salasana: this.otpSalasana.value,
  //       kertis: this.otpKertis.value
  //     }
  //     const omaveroCallable = this.functions.httpsCallable<SuomiOtpFunktiokutsu, void>('integraatiotIlmoitinVieVeroilmot', { timeout: 5400000 })
  //     firstValueFrom(omaveroCallable(omaveroCallablePayload)).then(() => {
  //       return this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value)
  //     }).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.otpKertis.setValue(null)
  //       this.kertisNroSubject.next(-1)
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   }

  // }

  // paivitaMaksuyhteystiedotOmaverosta() {

  //   if (!this.form.valid) {
  //     this.formValidationService.merkitseKokoLomakeKosketuksi(this.form)
  //     return
  //   }

  //   this.ladataanService.aloitaLataaminen()

  //   if (this.kertisNroSubject.value < 1) {
  //     this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   } else {
  //     const omaveroCallablePayload: SuomiOtpFunktiokutsu = {
  //       kayttajatunnus: this.otpKayttajatunnus.value,
  //       salasana: this.otpSalasana.value,
  //       kertis: this.otpKertis.value
  //     }
  //     const omaveroCallable = this.functions.httpsCallable<SuomiOtpFunktiokutsu, void>('integraatiotOmaveroHaeMaksutiedot', { timeout: 5400000 })
  //     omaveroCallable(omaveroCallablePayload)).then(() => {
  //       return this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value)
  //     }).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.otpKertis.setValue(null)
  //       this.kertisNroSubject.next(-1)
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   }

  // }

  // paivitaSaldotOmaverosta() {

  //   if (!this.form.valid) {
  //     this.formValidationService.merkitseKokoLomakeKosketuksi(this.form)
  //     return
  //   }

  //   this.ladataanService.aloitaLataaminen()

  //   if (this.kertisNroSubject.value < 1) {
  //     this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   } else {
  //     const omaveroCallablePayload: SuomiOtpFunktiokutsu = {
  //       kayttajatunnus: this.otpKayttajatunnus.value,
  //       salasana: this.otpSalasana.value,
  //       kertis: this.otpKertis.value
  //     }
  //     const omaveroCallable = this.functions.httpsCallable<SuomiOtpFunktiokutsu, void>('integraatiotOmaveroHaeSaldot', { timeout: 5400000 })
  //     omaveroCallable(omaveroCallablePayload)).then(() => {
  //       return this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value)
  //     }).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.otpKertis.setValue(null)
  //       this.kertisNroSubject.next(-1)
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   }

  // }

  // paivitaMaksuohjelmat() {

  //   if (!this.form.valid) {
  //     this.formValidationService.merkitseKokoLomakeKosketuksi(this.form)
  //     return
  //   }

  //   this.ladataanService.aloitaLataaminen()

  //   if (this.kertisNroSubject.value < 1) {
  //     this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   } else {
  //     const omaveroCallablePayload: SuomiOtpFunktiokutsu = {
  //       kayttajatunnus: this.otpKayttajatunnus.value,
  //       salasana: this.otpSalasana.value,
  //       kertis: this.otpKertis.value
  //     }
  //     const omaveroCallable = this.functions.httpsCallable<SuomiOtpFunktiokutsu, void>('integraatiotOmaveroHaeMaksuohjelmat', { timeout: 5400000 })
  //     omaveroCallable(omaveroCallablePayload)).then(() => {
  //       return this.annaSeuraavaKertisNumero(this.otpKayttajatunnus.value, this.otpSalasana.value)
  //     }).then(vastaus => {
  //       if (vastaus.numero) {
  //         this.kertisNroSubject.next(vastaus.numero)
  //         this.otpKertis.setValue(null)
  //         setTimeout(() => {
  //           this.kertisTextfield.focus()
  //           this._changeDetectorRef.markForCheck()
  //         }, 10)
  //       }
  //       this.ladataanService.lopetaLataaminen()
  //     }).catch(error => {
  //       this.otpKertis.setValue(null)
  //       this.kertisNroSubject.next(-1)
  //       this.errorHandler.handleError(error)
  //       this.ladataanService.lopetaLataaminen()
  //     })
  //   }

  // }

  // private annaSeuraavaKertisNumero(kayttajatunnus: string, salasana: string): Promise<SuomiOtpAnnaSeuraavaKertisnroVastaus> {
  //   const vertisCallablePayload: SuomiOtpAnnaSeuraavaKertisnroPyynto = {
  //     kayttajatunnus: kayttajatunnus,
  //     salasana: salasana
  //   }
  //   const kertisCallable = this.functions.httpsCallable<SuomiOtpAnnaSeuraavaKertisnroPyynto, SuomiOtpAnnaSeuraavaKertisnroVastaus>('integraatiotOtpHaeSeuraavaKertisnumero', { timeout: 540000 })
  //   return kertisCallable(vertisCallablePayload)).then(vastaus => {
  //     if (vastaus.error) {
  //       if (vastaus.error === 'wrong-password' || vastaus.error === 'unknown') {
  //         this.otpSalasana.setErrors({ pwd: true })
  //       } else if (vastaus.error === 'wrong-username' || vastaus.error === 'unknown') {
  //         this.otpKayttajatunnus.setErrors({ username: true })
  //       }
  //     }
  //     return vastaus
  //   })
  // }

  lataaTyviTiedostoVeroIlmo(ilmoitusJaTiedot: VeroilmoJaTiedot) {

    const ilmoitus = ilmoitusJaTiedot.ilmoitus
    const asiakas = ilmoitusJaTiedot.asiakas
    const tiedosto = this._ilmoitusRiviArrToString(ilmoitus.arvot || [])

    // TODO: This is copied from backend integration handler. Really should call backend here for the final file.
    const lahetyskanava = 'V'

    const lahetetty = this.dateService.timestampToDate(ilmoitus.created)
    const lahetettyPvm = this.dateService.muotoileVeroFormaattiPaivaDate(lahetetty)
    const lahetettyAika = this.dateService.muotoileVeroFormaattiAikaDate(lahetetty)

    // 8 chars from the end of the avain. Might be enough to identify or not.
    const tarkenne = ilmoitus.avain.substring(ilmoitus.avain.length - 8)

    const tiedostonNimi = `${lahetyskanava}${asiakas.ytunnus}_${lahetettyPvm}_${lahetettyAika}_${ilmoitus.year}_${tarkenne}.TXT`

    this._fileSaverService.saveStringAs(tiedosto, tiedostonNimi, 'txt')

  }

  private _ilmoitusRiviArrToString(rivit: IlmoitusRivi[]): string {
    let finalString = ''
    for (const rivi of rivit) {
      finalString += rivi.key + ':' + rivi.value + '\n'
    }
    return finalString.slice(0, -1) // chop off the last new line.
  }

  // const data: AlvIlmoituksenTyviTiedostonLatauspyynto = {
  //   asiakasAvain: ilmoitus.asiakas.avain,
  //   alvIlmoitusAvain: ilmoitus.ilmoitus.avain
  // }

  // const callable = this.functions.httpsCallable<AlvIlmoituksenTyviTiedostonLatauspyynto, AlvIlmoituksenTyviTiedostonLatausvastaus>('kirjanpitoAlvIlmoitusLataaTyvi')
  // return callable(data)).then(vastaus => {
  //   FileSaver.saveAs(new Blob([vastaus.base64Tiedosto], { type: 'text/plain' }), 'tyvi-export.txt', { autoBom: false })
  //   this.ladataanService.lopetaLataaminen()
  // }).catch(err => {
  //   this.errorHandler.handleError(err)
  //   this.ladataanService.lopetaLataaminen()
  // })

  lataaTyviTiedosto(ilmoitus: AlvIlmoJaTiedot) {

    this.ladataanService.aloitaLataaminen()

    const data: AlvIlmoituksenTyviTiedostonLatauspyynto = {
      asiakasAvain: ilmoitus.asiakas.avain,
      alvIlmoitusAvain: ilmoitus.ilmoitus.avain
    }

    return this._firebase.functionsCall<AlvIlmoituksenTyviTiedostonLatauspyynto, AlvIlmoituksenTyviTiedostonLatausvastaus>('kirjanpitoAlvIlmoitusLataaTyvi', data).then(vastaus => {
      this._fileSaverService.saveStringAs(vastaus.tiedosto, vastaus.nimi, 'txt')
      this.ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.errorHandler.handleError(err)
      this.ladataanService.lopetaLataaminen()
    })
  }

}
