<!-- <pre>{{ dataObservable | async | json }}</pre> -->

<div class="wrapper">
  <div class="title">Asiakkaan työjono</div>

  <div [formGroup]="form">
    <mat-radio-group formControlName="historianMaara" name="amnt">
      <mat-radio-button value="50">50</mat-radio-button>
      <mat-radio-button value="100">100</mat-radio-button>
      <mat-radio-button value="500">500</mat-radio-button>
      <mat-radio-button value="1000">1000</mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-container *ngIf="historiaObservable | async; let historiat">
    <table *ngIf="historiat.length > 0 else eiHistoriaa">
      <tr>
        <td>Alku</td>
        <td>Loppu</td>
        <td>Yritetty uudelleen</td>
        <td>Yritetään uudelleen</td>
        <td>Viimeisin syy</td>
      </tr>
      <tr *ngFor="let historia of historiat" style="border-bottom: 1px solid gray;">
        <td>{{ historia.start| lokaalipvml : 'fi' }}</td>
        <td>{{ historia.end | lokaalipvml : 'fi' }}</td>
        <td>{{ historia.delayedTimes }}</td>
        <td>{{ historia.tryAgainEarliest | ltimestamptimel: 'fi' }}</td>
        <td>{{ historia.lastDelayReason }}</td>
      </tr>
    </table>
    <ng-template #eiHistoriaa>
      <div style="margin: 30px;">
        Tietokannassa ei ole ajamattomia töitä.
      </div>
    </ng-template>
  </ng-container>

</div>