import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { MatCheckbox } from '@angular/material/checkbox'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { Asiakas, Kayttaja } from '../_jaettu-lemonator/model/asiakas'

import { KayttajaComponentDataResolve } from '../_angular/_resolvers/asiakas.resolve'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

@Component({
  templateUrl: './asiakkaan-kayttajat.component.html',
  styleUrls: ['./asiakkaan-kayttajat.component.css']
})
export class AsiakkaanKayttajatComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) _sort: MatSort
  @ViewChild(MatCheckbox) naytaKaikkiCheckbox: MatCheckbox

  kayttajatNaytettavatKolumnit: string[] = ['etunimi', 'sukunimi', 'email', 'aktiivinen']

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  asiakas: Asiakas = null
  dataSource = new MatTableDataSource<Kayttaja>([])

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private kayttajaComponentDataResolve: KayttajaComponentDataResolve,
    private asiakasService: AsiakasService
  ) {

    this.dataSource.sortData = (data: Kayttaja[], sort: MatSort): Kayttaja[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

      if (active === 'etunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.etunimi, b.etunimi) * directionMultiplier
        })
      }

      if (active === 'sukunimi') {
        return data.sort((a, b) => {
          return collator.compare(a.sukunimi, b.sukunimi) * directionMultiplier
        })
      }

      if (active === 'email') {
        return data.sort((a, b) => {
          return collator.compare(a.email, b.email) * directionMultiplier
        })
      }

      return data

    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    this._sort.sort({
      disableClear: false,
      id: 'sukunimi',
      start: 'asc'
    })
    this.dataSource.sort = this._sort

    this.asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asiakas => {
      this.asiakas = asiakas
    }, error => {
      this.errorHandler.handleError(error)
    })

    this.asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kayttajat => {
      this.dataSource.data = kayttajat || []
    }, error => {
      this.errorHandler.handleError(error)
    })

  }

  peruuta() {
    if (this.asiakas) {
      this.router.navigate(['asiakkaat', this.asiakas.avain, 'tositteet', 'lataa'])
    } else {
      this.router.navigate(['asiakkaat'])
    }
  }

  lisaaKayttaja() {
    if (this.asiakas) {
      this.router.navigate(['asiakkaat', this.asiakas.avain, 'kayttajat', 'uusi'])
    } else {
      this.router.navigate(['asiakkaat'])
    }
  }

  muokkaaKayttajaa(kayttaja: Kayttaja) {
    if (this.asiakas) {
      this.kayttajaComponentDataResolve.asetaOlemassaolevaData({ asiakas: this.asiakas, kayttaja: kayttaja, muidenKayttajienEmailit: this.dataSource?.data?.filter(k => k.avain !== kayttaja.avain)?.map(m => m.email) || [] })
      this.router.navigate(['asiakkaat', this.asiakas.avain, 'kayttajat', kayttaja.avain])
    }
  }

  get kayttajatDataSource(): MatTableDataSource<Kayttaja> {
    return this.dataSource
  }

  get kayttajatLataa(): boolean {
    return false
  }

}
