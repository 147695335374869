import { Injectable, ErrorHandler } from '@angular/core'

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { LaskunLokalisoituTila } from './laskut.firestore.datasource'

import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { LaskuTransformingWrappingDataSource } from '../_jaettu-angular/laskut/transforming-lasku.datasource'

import { Lasku, LaskunTila } from '../_jaettu/model/lasku'
import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { DateService } from '../_shared-core/service/date.service'
import { CurrencyService } from '../_shared-core/service/currency.service'

import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

import { EMPTY, BehaviorSubject, Observable, Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { LaskuIndeksoija } from 'app/_jaettu/service/lasku/lasku.indeksoija'


@Injectable()
export class LaskutUusiDataSourceService extends MatTableDataSource<Lasku> {

  private subscription: Subscription = null
  private laskunTilaSubject = new BehaviorSubject<LaskunLokalisoituTila[]>([])
  public laskunTilaObservable: Observable<LaskunLokalisoituTila[]> = this.laskunTilaSubject.asObservable()

  dataSourceTransformed: LaskuTransformingWrappingDataSource = null
  lataa: boolean = false

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _lemonTranslationService: LemonTranslationService,
    private _asiakasService: AsiakasService,
    private _laskuService: LaskuService,
    private _laskuSharedService: LaskuSharedService,
    private _currencyService: CurrencyService,
    private _laskuIndeksoija: LaskuIndeksoija
  ) {

    super([])

    this.dataSourceTransformed = new LaskuTransformingWrappingDataSource(this, this._currencyService, this._dateService, this._laskuSharedService, this._lemonTranslationService)
    this._lemonTranslationService.currentLanguageObservable.subscribe(kieli => {

      const naytettavatTilat: string[] = []

      naytettavatTilat.push(LaskunTila.kaikki)
      naytettavatTilat.push(LaskunTila.avoin)
      naytettavatTilat.push(LaskunTila.luottotappio)
      naytettavatTilat.push(LaskunTila.maksettu)
      naytettavatTilat.push(LaskunTila.eraantynyt)
      naytettavatTilat.push(LaskunTila.maksettuLiikaa)
      naytettavatTilat.push(LaskunTila.luonnos)
      naytettavatTilat.push(LaskunTila.poistettu)
      naytettavatTilat.push(LaskunTila.mitatoity)

      const localizationKeys = []
      for (const tila of naytettavatTilat) {
        const localizationKey = 'lasku.listaus.tila.' + tila
        localizationKeys.push(localizationKey)
      }

      const tilat: LaskunLokalisoituTila[] = []

      for (const tila of naytettavatTilat) {
        tilat.push({
          tunnus: tila,
          nimi: this._lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + tila, kieli)
        })
      }

      tilat.sort((a, b): number => {
        if (a.tunnus === LaskunTila.kaikki) {
          return -1
        } else if (b.tunnus === LaskunTila.kaikki) {
          return 1
        }
        return a.nimi.localeCompare(b.nimi)
      })

      this.laskunTilaSubject.next(tilat)

    })

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    this.sortData = (data: Lasku[], sort: MatSort): Lasku[] => {

      const active = sort.active
      const direction = sort.direction

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      // console.log('SORT BY', active)

      if (active === 'summa') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.summa
          const valueb = kb.summa
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'avoinna') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.avoinna
          const valueb = kb.avoinna
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'nro') {
        return data.sort((a, b) => {
          const valuea = this._laskuIndeksoija.annaNumeroLajitteluaVarten(a)
          const valueb = this._laskuIndeksoija.annaNumeroLajitteluaVarten(b)
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea - valueb) * directionMultiplier
          // const valuea = a.nro
          // const valueb = b.nro
          // if (valuea === null && valueb === null) {
          //   return 0
          // } else if (valuea === null) {
          //   return 1 * directionMultiplier
          // } else if (valueb === null) {
          //   return -1 * directionMultiplier
          // }
          // return (valuea - valueb) * directionMultiplier
        })
      }

      if (active === 'pvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.pvm.toDate() : null
          const valueb = kb.pvm ? kb.pvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'erapvm') {
        return data.sort((a, b) => {
          const ka = this._laskuSharedService.annaViimeisinKasiteltavaLasku(a)
          const kb = this._laskuSharedService.annaViimeisinKasiteltavaLasku(b)
          const valuea = ka.pvm ? ka.erapvm.toDate() : null
          const valueb = kb.pvm ? kb.erapvm.toDate() : null
          if (valuea === null && valueb === null) {
            return 0
          } else if (valuea === null) {
            return 1 * directionMultiplier
          } else if (valueb === null) {
            return -1 * directionMultiplier
          }
          return (valuea.getTime() - valueb.getTime()) * directionMultiplier
        })
      }

      if (active === 'tila') {
        return data.sort((a, b) => {
          const valuea = a.tila
          const valueb = b.tila
          return collator.compare(valuea, valueb) * directionMultiplier
        })
      }

      return data

    }

  }

  connect(): BehaviorSubject<Lasku[]> {
    setTimeout(() => {
      this.startSubsctiption()
    }, 0)
    return super.connect()
  }

  disconnect(): void {
    this.stopSubscription()
  }

  private stopSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
    this.lataa = false
  }

  private startSubsctiption() {
    this.lataa = true
    this.subscription = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        // Nollaa data source
        this.data = []
        // this.dataSource.paginator.pageIndex = 0
        this.filter = null
        if (asiakas) {
          this.lataa = true
          return this._firebaseLemonaid.firestoreCollection<Lasku>('laskut/' + this._laskuService.LEMONTREE_ASIAKAS_ID + '/laskut').whereFree('asiakas.avain', '==', asiakas.avain).listen()
        } else {
          this.lataa = false
          return EMPTY
        }
      }),
      map(snapshots => {
        for (const snapshot of snapshots) {
          delete snapshot['haku']
          if (snapshot.korvaus) {
            for (const korvaava of snapshot.korvaus) {
              delete korvaava['haku']
            }
          }
        }
        return snapshots
      })
    ).subscribe({
      next: laskut => {
        this.lataa = false
        this.data = laskut
      },
      error: err => {
        this.lataa = false
        this._errorHandler.handleError(err)
      }
    })
  }

}

