<div class="list-page">


  <h2>Sähköpostipohjat</h2>

  <mat-radio-group (change)="changeSelectedCategory($event?.value)" style="display: block; margin: 4em 0;">
    <mat-radio-button [value]="cat.avain" *ngFor="let cat of templateCategories">
      {{cat.nimi}}
    </mat-radio-button>
  </mat-radio-group>

  <form [formGroup]="form">

    <div *ngIf="form.get('kieli')">
      <mat-radio-group formControlName="kieli">
        <mat-radio-button value="fi">Suomi</mat-radio-button>
        <mat-radio-button value="en">Englanti</mat-radio-button>
      </mat-radio-group>
    </div>


    <div *ngIf="form.get('yritysmuoto')">
      <mat-radio-group formControlName="yritysmuoto">
        <mat-radio-button [value]="ym.avain" *ngFor="let ym of yritysmuodot">
          {{ym.nimi}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="form.get('regularOrZen')">
      <mat-radio-group formControlName="regularOrZen">
        <mat-radio-button value="reg">Tavallinen</mat-radio-button>
        <mat-radio-button value="zen">Holvi Zen</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="form.get('hasAttachments')">
      <mat-radio-group formControlName="hasAttachments">
        <mat-radio-button [value]="false">Kyllä</mat-radio-button>
        <mat-radio-button [value]="true">Ei</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="form.get('textStart')">
      <h3>Tekstin alku</h3>
      <quill-editor [formControl]="textStartFormControl"></quill-editor>
      <mat-error *ngIf="textStartFormControl?.errors?.required && (textStartFormControl?.dirty || textStartFormControl?.touched)">
        Teksti puuttuu
      </mat-error>
    </div>


    <div *ngIf="form.get('alvType')">
      <mat-radio-group formControlName="alvType">
        <mat-radio-button [value]="alv.avain" *ngFor="let alv of alvTypes">
          {{alv.nimi}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="form.get('textMid')">
      <h3>Tekstin keskiosa</h3>
      <quill-editor [formControl]="textMidFormControl"></quill-editor>
      <mat-error *ngIf="textMidFormControl?.errors?.required && (textMidFormControl?.dirty || textMidFormControl?.touched)">
        Teksti puuttuu
      </mat-error>
    </div>

    <div *ngIf="form.get('hasSelvitettavia')">
      <mat-radio-group formControlName="hasSelvitettavia">
        <mat-radio-button [value]="false">Kyllä</mat-radio-button>
        <mat-radio-button [value]="true">Ei</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="form.get('textEnd')">
      <h3>Tekstin loppu</h3>
      <quill-editor [formControl]="textEndFormControl"></quill-editor>
      <mat-error *ngIf="textEndFormControl?.errors?.required && (textEndFormControl?.dirty || textEndFormControl?.touched)">
        Teksti puuttuu
      </mat-error>
    </div>

    <!-- <table style="width: 100%;">
      <tr>
        <td style="vertical-align: top;">
          <div style="max-width: 600px; margin: 0 auto;">
            <h2>Suomi</h2>
  
            <div>
              <strong>Ei ole käytetty:</strong>
              <div *ngFor="let usableVarFi of usableVariablesSuomiObservable | async">{{usableVarFi}}</div>
            </div>
          </div>
        </td>

        <td style="vertical-align: top;">
          <div style="max-width: 600px; margin: 0 auto;">
            <h2>Englanti</h2>
            <quill-editor [formControl]="textEn"></quill-editor>
            <mat-error *ngIf="textEn?.errors?.required && (textEn?.dirty || textEn?.touched)">
              Pohja puuttuu
            </mat-error>
            <div>
              <strong>Have not been used:</strong>
              <div *ngFor="let usableVarEn of usableVariablesEnglishObservable | async">{{usableVarEn}}</div>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div style="max-width: 600px;">
      <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
        <button class="primarybutton" mat-button (click)="save()" type="button">Tallenna</button>
      </div>
    </div> -->
  </form>
</div>