
<mat-dialog-content>

  <lemon-dialog-header [naytaSuljeRuksi]="false">
    Tositteita ladataan
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <ul *ngIf="!commonError">
      <li>Lasketaan tositteiden määrää ja haetaan metatietoja...</li>
      <li *ngIf="tiedostojaYhteensa">Ladataan tositteita koneelle: {{tiedostojaLadattu}} / {{tiedostojaYhteensa}} valmiina</li>
      <li *ngIf="tiedostojaYhteensa && tiedostojaYhteensa == tiedostojaLadattu">Luodaan zip-tiedostoa...</li>
    </ul>
    <div *ngIf="commonError" class="error-text">
      Tositteiden lataamisen aikana tapahtui virhe. Virhe on tallennettu. Ole hyvä ja ota yhteys ylläpitoon.
    </div>
  </div>
  
</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button *ngIf="commonError" class="primarybutton" mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
