<div class="list-page">

  <div>
    <div style="width: 60px" [formGroup]="form">
      <mat-form-field>
        <mat-label>Kuukausia</mat-label>
        <input decimalNumberField matInput [numberOfDecimals]="0" formControlName="monthRange">
      </mat-form-field>
    </div>

    <div *ngIf="lataaObservable | async">
      <div lemon-loading [show]="true"></div>
    </div>

    <div style="position: relative;">
      <mat-table matSort [dataSource]="saldoTiedotDatasource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="paiva">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Päivä</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.paiva | lnumberpvm | async }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="iban">
          <mat-header-cell *matHeaderCellDef mat-sort-header>IBAN</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.iban }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="saldo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Saldo</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.saldo || '&nbsp;'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edited">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Muokattu</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.edited | ltimestamptime | async}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row *matRowDef="let row; columns: naytettavatKolumnit"></mat-row>

      </mat-table>

      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!(lataaObservable | async) && saldoTiedotDatasource?.filteredData?.length == 0">
        <div style="width: 100%; padding-top: 40px; text-align: center;">Tietoja ei löytynyt.</div>
      </div>

    </div>
  </div>

</div>