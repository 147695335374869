import { Component, OnInit, OnDestroy, ErrorHandler, ChangeDetectorRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'

import { LaskuKatseleComponentData } from '../../_angular/_resolvers/lasku.resolve'
import { AsiakasService, AsiakkaanAvainTiedot } from '../../_angular/service/asiakas/asiakas.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LemonHttpService, LEMONATOR_CF_API } from '../../_angular/service/lemon-http.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'

import {
  Lasku,
  LaskuBase,
  Laskuasetukset,
  EmailLahetysStatus,
  EmailLahetysStatusKoodi,
  LaskuToimintaloki,
  LaskunToimintalokiTyyppi,
  LaskunToimintalokiSpostiParametrit,
  LaskunTila,
  LaskunLiitetiedosto,
  LaskuReskontra,
  LaskunumeroTyyppi,
  LaskunToimintalokiSahkoinenParametrit,
  LaskuSahkoinenLahetysStatusKoodi
} from '../../_jaettu/model/lasku'

import { LaskuReskontraTiedotDialog } from '../dialogit/lasku.reskontra-tiedot.dialog'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { LaskuPdfEsikatselutiedot } from '../../_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'

import { Subject, combineLatest, Observable, of, firstValueFrom } from 'rxjs'
import { takeUntil, switchMap, map, startWith } from 'rxjs/operators'

import { LaskuLataaDialog, LaskuLataaDialogData } from '../dialogit/lasku.lataa.dialog'
import { LaskuKokoVirheDialog } from '../dialogit/lasku.koko-virhe.dialog'

import { LaskuSharedService, ReskontraService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from '../../_shared-core/service/date.service'
import { LaskuUriService } from '../../_jaettu/service/lasku/lasku-uri.service'
import { SahkoisenLaskunValittajaService, Verkkolaskuoperaattori } from '../../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'

import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { StringService } from 'app/_shared-core/service/string.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

interface KasiteltyLokitieto {
  parametrit: any
  loki: LaskuToimintaloki
}

interface KorjattavaEmailLahetysStatus extends EmailLahetysStatus {
  vanhaEmail: string
}

@Component({
  templateUrl: './lasku-selaa.katsele.component.html',
  styleUrls: ['../lasku.katsele.component.css']
})
export class LaskuSelaaKatseleComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  reskontraObservable: Observable<LaskuReskontra[]>
  reskontraLokiObservable: Observable<{ data: LaskuReskontra, teksti: string }[]>
  reskontraSummaObservable: Observable<number>
  reskontraaLadataanObservable: Observable<boolean>
  lokitiedotObservable: Observable<KasiteltyLokitieto[]>
  lokitietojaLadataanObservable: Observable<boolean>
  laskuUriObservable: Observable<string>
  laskuEncodedUriObservable: Observable<string>
  kirjattavalaskuUriObservable: Observable<string>
  kirjattavalaskuEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

  verkkolaskuoperaattorit: Verkkolaskuoperaattori[]

  lokit: LaskuToimintaloki[] = []
  data: LaskuKatseleComponentData = null
  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  asiakas: Asiakas = null
  asetukset: Laskuasetukset = null
  ladaataanToimintalokia = true
  esikatselutiedot: LaskuPdfEsikatselutiedot = null
  otsikko: string = null
  namename = 'asfrtegoq' + Math.random()

  sahkoinenForm: UntypedFormGroup

  private _buttonPushInflight: boolean = false

  annaOsoitteenValidointiFunktio(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const ctrlValue = this._stringService.removeAllWhiteSpaces(control.value)
        const valittajat = this.verkkolaskuoperaattorit
        for (const valittaja of valittajat) {
          if (this._stringService.removeAllWhiteSpaces(valittaja.tunnus) === ctrlValue) {
            return { 'valittaja': true }
          }
        }
      }
      return null
    }
  }

  constructor(
    // private router: Router,
    private _route: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _laskuService: LaskuService,
    private _errorHandler: ErrorHandler,
    public _laskuSharedService: LaskuSharedService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _dialog: MatDialog,
    // private laskuComponentDataResolve: LaskuComponentDataResolve,
    private _dateService: DateService,
    private _httpService: LemonHttpService,
    private _lemonTranslationService: LemonTranslationService,
    private _currencyService: CurrencyService,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    private _reskontraService: ReskontraService,
    private _asiakasService: AsiakasService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _laskuUriService: LaskuUriService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _stringService: StringService,
    private _validationService: FormValidationService,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _fileSaverService: FileSaverService
  ) {

  }

  // private _filter(value): Verkkolaskuoperaattori[] {
  //   if (!value) {
  //     return []
  //   }
  //   const tarkasteltavaArvo = (value + '').trim().toLowerCase()
  //   if (tarkasteltavaArvo === '') {
  //     return []
  //   }
  //   return this.sahkoisenLaskunValittajaService.annaKaikkiValittajat().filter(operaattori => operaattori.nimi.toLowerCase().includes(tarkasteltavaArvo) || operaattori.tunnus.toLowerCase().includes(tarkasteltavaArvo))
  // }

  ngOnInit() {

    this.laskuUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._route.data]).pipe(
      map(([asiakas, data]: [Asiakas, { data: LaskuKatseleComponentData }]) => {
        if (asiakas && data?.data?.juurilasku) {
          return this._laskuUriService.getLaskuUri(asiakas.asiakasId, data.data.juurilasku.avain)
        }
        return ''
      })
    )

    this.laskuEncodedUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._route.data]).pipe(
      map(([asiakas, data]: [Asiakas, { data: LaskuKatseleComponentData }]) => {
        if (asiakas && data?.data?.juurilasku) {
          const uri = this._laskuUriService.getLaskuUri(asiakas.asiakasId, data.data.juurilasku.avain)
          return this._debugService.createFirestoreLinkLemonaid(uri)
        }
        return ''
      })
    )

    this.kirjattavalaskuUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._route.data]).pipe(
      map(([asiakas, data]: [Asiakas, { data: LaskuKatseleComponentData }]) => {
        if (asiakas && data?.data?.juurilasku) {
          return this._kirjanpitoUriService.annaKirjattavanLaskunUriLaskulla(asiakas, data.data.juurilasku)
        }
        return ''
      })
    )
    this.kirjattavalaskuEncodedUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._route.data]).pipe(
      map(([asiakas, data]: [Asiakas, { data: LaskuKatseleComponentData }]) => {
        if (asiakas && data?.data?.juurilasku) {
          const kirjattavaLaskuUri = this._kirjanpitoUriService.annaKirjattavanLaskunUriLaskulla(asiakas, data.data.juurilasku)
          return this._debugService.createFirestoreLink(kirjattavaLaskuUri)
        }
        return ''
      })
    )

    this.sahkoinenForm = new UntypedFormGroup({
      'ytunnus': new UntypedFormControl('', []),
      'osoite': new UntypedFormControl('', [Validators.required, this.annaOsoitteenValidointiFunktio()]),
      'valittaja': new UntypedFormControl('', [Validators.required])
    })

    this.verkkolaskuoperaattorit = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()

    // this.sahkoinenForm.get('ytunnus').valueChanges.subscribe(value => {
    //   this.sahkoinen.ytunnus = value
    // })
    this.osoiteControl.valueChanges.subscribe(value => {
      this.kasiteltava.sahkoinen.osoite.sahkoinenOsoite = value?.trim()
    })
    this.valittajaControl.valueChanges.subscribe(value => {
      this.kasiteltava.sahkoinen.osoite.sahkoinenValittaja = value?.trim()
    })

    combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([data, asiakas]: [{ data: LaskuKatseleComponentData }, Asiakas]) => {

      this.asiakas = asiakas

      this.juurilasku = data.data.juurilasku
      this.kasiteltava = data.data.kasiteltava
      this.asetukset = data.data.asetukset
      this.data = data.data
      if (this.kasiteltava.email && this.kasiteltava.email.vastaanottajat) {
        for (const vastaanottaja of this.kasiteltava.email.vastaanottajat) {
          const korjattava = vastaanottaja as KorjattavaEmailLahetysStatus
          korjattava.vanhaEmail = korjattava.email
        }
      }
      if (this.kasiteltava.sahkoinen && this.kasiteltava.sahkoinen.osoite) {
        const valittajaTunnus = this.kasiteltava.sahkoinen.osoite.sahkoinenValittaja
        this.osoiteControl.setValue(this.kasiteltava.sahkoinen.osoite.sahkoinenOsoite)
        // This form field used to be a free-text input, so an extra comparison is needed here to catch invalid operator ID-s in legacy data.
        const haettuValittaja: Verkkolaskuoperaattori = this._sahkoisenLaskunValittajaService.annaValittaja(valittajaTunnus)
        const confirmedValittajaTunnus = haettuValittaja?.tunnus ?? null
        this.valittajaControl.setValue(confirmedValittajaTunnus)
      }
      this.esikatselutiedot = {
        asetukset: this.asetukset,
        juurilasku: this.juurilasku,
        kasiteltava: this.kasiteltava
      }
      if (this.naytaKorvatunOtsake()) {
        this.otsikko = this.annaKorvatunOtsake()
      }
      if (this.naytaTavallinenOtsake()) {
        this.otsikko = 'Lasku ' + this._laskuSharedService.annaMuotoiltuLaskunumero(this.juurilasku, this.kasiteltava)
      }
      if (data.data.tulosta) {
        setTimeout(() => {
          this.lataaLasku()
          this._changeDetectorRef.markForCheck()
        }, 25)
      }
    })

    // Lataa lokitiedot
    this.lokitiedotObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      switchMap(([data, asiakas]: [{ data: LaskuKatseleComponentData }, AsiakkaanAvainTiedot]) => {
        if (data && data.data && data.data.juurilasku && data.data.juurilasku.avain && asiakas?.asiakasId) {
          return this._laskuService.getLaskuToimintalokiObservableAsiakkaalle(asiakas.asiakasId + '', data.data.juurilasku.avain)
        } else {
          return of<LaskuToimintaloki[]>([])
        }
      }),
      map(kaikkiLokit => {
        const tyyppi = this.kasiteltava.nrotyyppi
        const lokit: KasiteltyLokitieto[] = []

        if (tyyppi === LaskunumeroTyyppi.HYVITYS || tyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          for (const loki of kaikkiLokit) {
            if (loki.lasku_uid === this.kasiteltava.avain) {
              lokit.push({ loki: loki, parametrit: this.annaLokiparametrit(loki) })
              if (lokit.length === 1) {
                loki.toiminto = LaskunToimintalokiTyyppi.LUOTU
              }
            }
          }
        } else {
          const sallitutAvaimet = this._laskuSharedService.annaLaskuryppaanPaalaskunEriVersiot(this.juurilasku).map(versio => { return versio.avain })
          for (const loki of kaikkiLokit) {
            if (sallitutAvaimet.includes(loki.lasku_uid)) {
              lokit.push({ loki: loki, parametrit: this.annaLokiparametrit(loki) })
            }
          }
        }
        return lokit
      }),
      lemonShare()
    )

    this.lokitietojaLadataanObservable = this.lokitiedotObservable.pipe(
      map(() => { return false }),
      startWith(true)
    )

    // Lataa reskontratiedot
    this.reskontraObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      switchMap(([data, asiakas]: [{ data: LaskuKatseleComponentData }, AsiakkaanAvainTiedot]) => {
        if (data && data.data && data.data.juurilasku && data.data.juurilasku.avain && asiakas?.asiakasId) {
          return this._laskuService.getLaskuReskontraObservableAsiakkaalle(asiakas.asiakasId + '', data.data.juurilasku.avain)
        } else {
          return of<LaskuReskontra[]>([])
        }
      }),
      lemonShare()
    )

    this.reskontraSummaObservable = this.reskontraObservable.pipe(
      map(reskontra => {
        const maksettu = this._reskontraService.annaReskontranSumma(reskontra)
        if (this.juurilasku && this.juurilasku.tila === LaskunTila.maksettu) {
          return this._currencyService.muutaBigDecimalRahaksi(maksettu)
        } else if (this.kasiteltava) {
          return this._currencyService.muutaBigDecimalRahaksi(maksettu.minus(this.kasiteltava.summa))
        }
        return 0
      })
    )

    this.reskontraaLadataanObservable = this.reskontraObservable.pipe(
      map(() => { return false }),
      startWith(true)
    )

    this.reskontraLokiObservable = combineLatest([this.reskontraObservable, this._lemonTranslationService.currentLanguageObservable, this._asiakasService.nykyisenAsiakkaanKayttajatObservable]).pipe(
      map(([reskontrat, kieli, kayttajat]) => {
        const kayttajienNimetMap: Map<string, string> = new Map()
        for (const kayttaja of kayttajat) {
          kayttajienNimetMap.set(kayttaja.avain, kayttaja.etunimi + ' ' + kayttaja.sukunimi)
        }
        return reskontrat.map(r => {
          return { data: r, teksti: this._annaReskontraLokinSelite(r, this.kasiteltava?.valuutta, kayttajienNimetMap, kieli) }
        })
      })
    )

    window.scroll({ top: 0, left: 0 })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  valittajaDisplayFn(operaattori?: Verkkolaskuoperaattori): string | undefined {
    if (operaattori && operaattori.nimi) {
      return operaattori.nimi + ' (' + operaattori.tunnus + ')'
    }
    if (operaattori instanceof String || typeof operaattori === 'string') {
      return operaattori as any as string
    }
    return undefined
  }

  async lataaLiitetiedosto(liitetiedosto: LaskunLiitetiedosto) {
    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (!asiakas?.avain) {
      this._ladataanService.lopetaLataaminen()
      return
    }
    const liitetiedostoUri = this._laskuService.getLiitetiedostoUriAsiakkaalle(asiakas.avain, liitetiedosto)
    const url = '/laskuLataaLiite?a=' + encodeURIComponent('/api/1/laskut/lataaLiitetiedosto/' + liitetiedostoUri) + '&time=' + encodeURIComponent(new Date().getTime())
    return this._httpService.getBinary(url, LEMONATOR_CF_API).then(result => {
      this._ladataanService.lopetaLataaminen()
      this._fileSaverService.saveAs(result, liitetiedosto.nimi)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  async lataaLasku() {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    if (!asiakas?.avain) {
      return
    }
    if (
      (this.kasiteltava.print && this.kasiteltava.print.done) ||
      (this.kasiteltava.email && this.kasiteltava.email.done) ||
      (this.kasiteltava.sahkoinen && this.kasiteltava.sahkoinen.done)
    ) {
      this._ladataanService.aloitaLataaminen()
      const pdfUri = this._laskuService.getPdfUrlAsiakkaalle(asiakas.asiakasId + '', this.kasiteltava)
      const url = '/api/1/laskut/lataaPdf/' + pdfUri
      return this._httpService.getBinary('/laskuLataaPdf?a=' + encodeURIComponent(url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONATOR_CF_API)
        .then(result => {
          const nimi = this._laskuService.annaPdfTiedostonNimiAsiakkaalle(asiakas, this.juurilasku, this.kasiteltava, this._lemonTranslationService.nykyinenKieli)
          this._ladataanService.lopetaLataaminen()
          this._fileSaverService.saveAs(result, nimi)
        }).catch(err => {
          this._ladataanService.lopetaLataaminen()
          this._errorHandler.handleError(err)
        })
    } else {
      const data: LaskuLataaDialogData = {
        juurilasku: this.juurilasku,
        kasiteltava: this.kasiteltava
      }
      const dialogRef = this._dialog.open(LaskuLataaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
    }
  }

  annaKorvatunOtsake(): string {
    const korvaava = this._laskuSharedService.annaKorvaavaLasku(this.juurilasku, this.kasiteltava)
    const korvaavanNumero = this._laskuSharedService.annaMuotoiltuLaskunumero(this.juurilasku, korvaava)
    return 'Korvattu laskulla ' + korvaavanNumero
  }

  naytaKorvatunOtsake(): boolean {
    return this._laskuSharedService.annaKorvaavaLasku(this.juurilasku, this.kasiteltava) && true
  }

  naytaTavallinenOtsake(): boolean {
    return !this.naytaKorvatunOtsake()
  }

  naytaSumma(): boolean {
    return !this.naytaKorvatunOtsake()
  }

  naytaMaksuaikaa(): boolean {
    return this.juurilasku && this.kasiteltava && (this.juurilasku.tila === LaskunTila.avoin || this.juurilasku.tila === LaskunTila.eraantynyt) && !this.naytaKorvatunOtsake()
  }

  annaMaksuaikaa(): number {
    if (this.kasiteltava && this.kasiteltava.erapvm) {
      return this._dateService.paiviaValissaTimestamp(this.kasiteltava.erapvm, this._timestampService.now())
    }
    return 0
  }

  // poistaLuonnos() {
  //   this.laskuService.poistaLuonnos(this.juurilasku, this.kasiteltava).then(() => {
  //     this.router.navigate(['/laskutus/laskut'])
  //   })
  // }

  // merkitseLuottotappioksi() {
  //   this.laskuService.merkitseLuottotappioksi(this.juurilasku, this.kasiteltava)
  // }

  // peruutaLuottotappiomerkinta() {
  //   this.laskuService.peruutaLuottotappiomerkinta(this.juurilasku, this.kasiteltava)
  // }

  // merkitseOsittainMaksetuksi() {
  //   const data: LaskuMerkitseOsittainMaksetuksiDialogData = {
  //     juurilasku: this.juurilasku,
  //     kasiteltava: this.kasiteltava
  //   }

  //   const settings: MatDialogConfig = {
  //     'data': data,
  //     panelClass: 'ilman-paddingia'
  //     // ,
  //     // maxWidth: '1600px',
  //     // maxHeight: korkeus,
  //     // width: leveys
  //   }
  //   const dialogRef = this.dialog.open(LaskuMerkitseOsittainMaksetuksiDialog, settings)
  // }

  // muokkaa() {

  //   // console.log(this.juurilasku.tila, this.juurilasku.tila === LaskunTila.luonnos)

  //   if (this.kasiteltava.print && this.kasiteltava.print.start) {

  //     const data: LaskuMuokkaaTulostettuaDialogData = {
  //       juurilasku: this.juurilasku,
  //       kasiteltava: this.kasiteltava,
  //       asetukset: this.asetukset
  //     }
  //     const settings: MatDialogConfig = {
  //       data: data
  //     }
  //     this.dialog.open(LaskuMuokkaaTulostettuaDialog, settings)

  //   } else {

  //     const data: LaskuComponentExistingData = {
  //       juurilasku: this.juurilasku,
  //       asetukset: this.asetukset
  //     }
  //     this.laskuComponentDataResolve.asetaOlemassaolevaData(data)

  //     if (this.juurilasku.tila === LaskunTila.luonnos) {
  //       this.router.navigate(['/laskutus/laskut/', this.juurilasku.avain, this.kasiteltava.avain])
  //     } else {
  //       this.router.navigate(['/laskutus/laskut/', this.juurilasku.avain, UUDEN_LASKUN_AVAIN])
  //     }

  //   }

  // }

  // mitatoi() {
  //   const data: LaskuHaluatkoVarmastiMitatoidaLaskunDialogData = {
  //     juurilasku: this.juurilasku,
  //     kasiteltava: this.kasiteltava
  //   }

  //   const settings: MatDialogConfig = {
  //     data: data
  //   }

  //   const dialogi = this.dialog.open(LaskuHaluatkoVarmastiMitatoidaLaskunDialog, settings)
  //   dialogi.afterClosed().pipe(
  //     takeUntil(this.ngUnsubscribe)
  //   ).subscribe(result => {
  //     if (result) {
  //       const uusiKasiteltava = result as LaskuBase
  //       this.router.navigate(['/laskutus/laskut/', this.juurilasku.avain, uusiKasiteltava.avain, 'katsele'])
  //     }
  //   })
  // }

  onkoLataaLaskuNakyvissa(): boolean {
    return this.kasiteltava &&
      (!!this.kasiteltava.print?.done || !!this.kasiteltava.email?.done || !!this.kasiteltava.sahkoinen?.done)
  }

  // onkoPoistoLuonnosNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //     this.juurilasku.tila === LaskunTila.luonnos
  // }

  // onkoMitatoiNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //   (
  //     this.juurilasku.tila === LaskunTila.avoin ||
  //     this.juurilasku.tila === LaskunTila.maksettuLiikaa ||
  //     this.juurilasku.tila === LaskunTila.maksettu ||
  //     this.juurilasku.tila === LaskunTila.eraantynyt
  //   )
  // }

  // onkoPeruutaLuottotappioMerkintaNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //     this.juurilasku.tila === LaskunTila.luottotappio
  // }

  // onkoMerkitseOsittainMaksetuksiNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //     this.juurilasku.tila !== LaskunTila.luottotappio &&
  //     this.laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(this.juurilasku, this.kasiteltava)
  // }


  // onkoMerkitseLuottotappioksiNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //     this.juurilasku.tila !== LaskunTila.luottotappio &&
  //     this.juurilasku.tila !== LaskunTila.maksettu &&
  //     this.juurilasku.tila !== LaskunTila.maksettuLiikaa &&
  //     this.laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(this.juurilasku, this.kasiteltava)
  // }

  // onkoMaksumuistutusNakyvissa(): boolean {
  //   return this.kasiteltava &&
  //     this.juurilasku.tila !== LaskunTila.luottotappio &&
  //     this.juurilasku.tila !== LaskunTila.maksettu &&
  //     this.juurilasku.tila !== LaskunTila.maksettuLiikaa &&
  //     this.laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(this.juurilasku, this.kasiteltava)
  // }

  private naytaMuokkausnappulat(): boolean {
    if (!this.kasiteltava) {
      return false
    }
    if (!this.kasiteltava.avain) {
      return false
    }
    if (!this.juurilasku.korvaus || this.juurilasku.korvaus.length < 1) {
      return this._laskuSharedService.onkoKasiteltavaMuuKuinMitatoity(this.juurilasku, this.kasiteltava)
    }
    if (this.juurilasku.korvaus[this.juurilasku.korvaus.length - 1].avain === this.kasiteltava.avain) {
      return this._laskuSharedService.onkoKasiteltavaMuuKuinMitatoity(this.juurilasku, this.kasiteltava)
    }
    return false
  }

  naytaEpaonnistunutSahkoinen(): boolean {

    if (!this.kasiteltava || !this.kasiteltava.sahkoinen) {
      return false
    }

    if (
      this.kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.LAHETETTY_VASTAANOTTAJALLE &&
      this.kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
    ) {
      return true
    }

    return false

  }

  naytaEpaonnistunutSposti(): boolean {

    if (!this.naytaMuokkausnappulat()) {
      return false
    }

    if (!this.kasiteltava || !this.kasiteltava.email || !this.kasiteltava.email.vastaanottajat) {
      return false
    }

    for (const vastaanottaja of this.kasiteltava.email.vastaanottajat) {
      if (vastaanottaja.status === EmailLahetysStatusKoodi.LAHETYS_EPAONNISTUI) {
        return true
      }
    }

    return false

  }

  naytaKokoVirhe(vastaanottaja: EmailLahetysStatus) {
    const dialogRef = this._dialog.open(LaskuKokoVirheDialog, { 'data': vastaanottaja, panelClass: 'ilman-paddingia' })
  }

  // lahetaEmailUudelleen(vastaanottaja: KorjattavaEmailLahetysStatus) {
  //   combineLatest([this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable, this._asiakasService.nykyinenAsiakasObservable]).pipe(
  //     take(1)
  //   ).subscribe(([kirjanpitaja, asiakas]) => {
  //     if (kirjanpitaja && asiakas) {
  //       this._laskuService.lahetaEmailUudelleen(this.juurilasku, this.kasiteltava, asiakas, kirjanpitaja, vastaanottaja, vastaanottaja.vanhaEmail).catch(error => {
  //         this._errorHandler.handleError(error)
  //       })
  //     }
  //   })
  // }

  // lahetaSahkoinenUudelleen() {
  //   if (!this.sahkoinenForm.valid) {
  //     this._validationService.merkitseKokoLomakeKosketuksi(this.sahkoinenForm)
  //     return
  //   }
  //   combineLatest([this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable, this._asiakasService.nykyinenAsiakasObservable]).pipe(
  //     take(1)
  //   ).subscribe(([kirjanpitaja, asiakas]) => {
  //     if (kirjanpitaja && asiakas) {
  //       this._laskuService.lahetaSahkoinenUudelleen(this.juurilasku, this.kasiteltava, asiakas, kirjanpitaja).catch(error => {
  //         this._errorHandler.handleError(error)
  //       })
  //     }
  //   })

  // }

  // lahetaMuistutuslasku() {

  //   const muokattavaksiJuuri = this.laskuKopioija.copyLasku(this.juurilasku)
  //   const muokattavaksiKasiteltava = this.laskuKopioija.kopioiLaskuMuistutusLaskuksi(this.kasiteltava)
  //   if (!muokattavaksiJuuri.korvaus) {
  //     muokattavaksiJuuri.korvaus = []
  //   }
  //   muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava.kopioitu)

  //   // TODO: MODAA KUN: https://github.com/angular/material2/issues/8493 on korjattu
  //   // PULL JOKA KORJAA: https://github.com/angular/material2/pull/9236
  //   const kaytettavaLeveys = window.innerWidth
  //   const kaytettavaKorkeus = window.innerHeight
  //   const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
  //   const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

  //   const data: LaskuMaksumuistutusSpostiDialogData = {
  //     juurilasku: muokattavaksiJuuri,
  //     kasiteltava: muokattavaksiKasiteltava.kopioitu,
  //     asetukset: this.asetukset,
  //     huomautuskulu: muokattavaksiKasiteltava.huomautuskulu,
  //     korkokulu: muokattavaksiKasiteltava.korkokulu
  //   }

  //   const settings: MatDialogConfig = {
  //     data: data,
  //     maxWidth: '1600px',
  //     maxHeight: korkeus,
  //     width: leveys
  //   }
  //   const dialogRef = this.dialog.open(LaskuMaksumuistutusSpostiDialog, settings)
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.router.navigate(['/laskutus/laskut/', muokattavaksiJuuri.avain, muokattavaksiKasiteltava.kopioitu.avain, 'katsele'])
  //     }
  //   })

  // }

  private annaLokiparametrit(loki: LaskuToimintaloki) {

    const paluuarvo: any = {}

    paluuarvo.lasku_nro = this._laskuSharedService.annaMuotoiltuLaskunumero(this.juurilasku, this.kasiteltava)

    if (
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SPOSTI ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_MAKSUMUISTUTUS_SPOSTI
    ) {
      if (loki.parametrit) {
        const parametrit = loki.parametrit as LaskunToimintalokiSpostiParametrit
        paluuarvo.osoitteet = parametrit.emailit ? parametrit.emailit.join(', ') : ''
      } else {
        paluuarvo.osoitteet = '?'
      }
    }
    if (
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOINEN ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_TULOSTA_TOISEN_HYVAKSYNTAJONOON_ODOTTAMAAN_HYVAKSYNTAA ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN
    ) {
      if (loki.parametrit) {
        const parametrit = loki.parametrit as LaskunToimintalokiSahkoinenParametrit
        const tunnus = parametrit.osoite && parametrit.osoite.sahkoinenValittaja ? parametrit.osoite.sahkoinenValittaja : ''
        const kaytettevaTunnus = (tunnus as any).tunnus ? (tunnus as any).tunnus : tunnus
        // console.log(kaytettevaTunnus)
        const nimi = this._sahkoisenLaskunValittajaService.annaValittajanNimi(kaytettevaTunnus)
        const valittaja = kaytettevaTunnus + (nimi ? ' (' + nimi + ')' : '')
        // console.log(valittaja)
        paluuarvo.sahkoinenOsoite = parametrit.osoite && parametrit.osoite.sahkoinenOsoite ? parametrit.osoite.sahkoinenOsoite : ''
        paluuarvo.sahkoinenValittaja = valittaja
      } else {
        paluuarvo.sahkoinenOsoite = '?'
        paluuarvo.sahkoinenValittaja = '?'
      }
    }

    return paluuarvo
  }

  get osoiteControl(): AbstractControl {
    return this.sahkoinenForm.get('osoite')
  }
  get valittajaControl(): AbstractControl {
    return this.sahkoinenForm.get('valittaja')
  }

  naytaMerkinta(merkinta: LaskuReskontra) {
    this._dialog.open(LaskuReskontraTiedotDialog, { data: merkinta })
  }

  // peruuta() {
  //   this.router.navigate(['asiakkaat', '/laskutus/laskut'])
  // }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

  async paivitaTilaJaIndeksoiUudelleen() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const kirjanpitaja = await firstValueFrom(this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable)

    if (!asiakas || !kirjanpitaja) {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
      return
    }

    try {
      await this._laskuService.paivitaTilaJaIndeksoiUudelleen(this.juurilasku, asiakas, kirjanpitaja)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async laskeReskontraUudelleen() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const kirjanpitaja = await firstValueFrom(this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable)

    if (!asiakas || !kirjanpitaja) {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
      return
    }

    try {
      await this._laskuService.laskeReskontraUudelleen(this.juurilasku, asiakas, kirjanpitaja)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  private _annaReskontraLokinSelite(reskontra: LaskuReskontra, valutta: string, kayttajienNimetMap: Map<string, string>, kieli: TuettuKieli): string {
    if (reskontra.tekijaUid === 'lemonator_system') {
      const maksajaPart = reskontra.maksaja ? (' (' + reskontra.maksaja + ')') : ''
      return `${this._dateService.muotoile(reskontra.luotu.toDate(), 'dd.MM.yyyy HH:mm:ss')} ${this._lemonTranslationService.lokalisoi('lasku.katsele-muokattava-dialog.automaattimerkinta-tilitapahtumasta')}: ${this._currencyService.formatoiRaha(reskontra.suoritus, valutta.toLowerCase(), kieli)}${maksajaPart}`
    }
    const kayttajanNimi = kayttajienNimetMap.get(reskontra.tekijaUid)
    return `${this._dateService.muotoile(reskontra.luotu.toDate(), 'dd.MM.yyyy HH:mm:ss')} ${this._lemonTranslationService.lokalisoi('lasku.katsele-muokattava-dialog.reskontraan-merkittiin-kasin')}: ${this._currencyService.formatoiRaha(reskontra.suoritus, valutta.toLowerCase(), kieli)} ${kayttajanNimi ? ('(' + kayttajanNimi + ')') : ''}`
  }

}
