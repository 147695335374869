<div *ngIf="screenSizeObservable | async; let screenSize" class="box-with-border gray-bg" [@blueBorderFade]="isSelected.toString()" [class.blue-border]="isSelected" [class.gray-border]="!isSelected">

  <div class="row" [class.reversed-cols]="vienti.d === 'k'">
    <div [style.text-align]="vienti.d === 'k' ? 'right' : 'left'" class="date-col">
      <div style="font-weight: bold;">{{ screenSize?.small ? (vienti.p | lnumberpvmlnarrow: 'fi') : (vienti.p | lnumberpvm | async)}}</div>
      <div>
        <div class="kirj-mt-btn" (click)="emitKirjausOpen($event)" [style.text-align]="vienti.d === 'k' ? 'right' : 'left'">
          <div class="mt-nimi">{{maksutapa?.nimi}}</div>
          <!-- <svg class="mt-icon ionicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M448 256L272 88v96C103.57 184 64 304.77 64 424c48.61-62.24 91.6-96 208-96v96z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
          </svg> -->
        </div>
      </div>

    </div>

    <div class="center-col">
      <div>{{vienti.m}}</div>
      <div>{{vienti.v}}</div>
      <div class="blue-text">{{vienti.l}}</div>
    </div>
    <div [style.text-align]="vienti.d === 'k' ? 'left' : 'right'" class="sum-col">
      <div [style.cursor]="debugUrlStart ? 'pointer': 'initial'" style="font-weight: bold;" (click)="debugRedirectIfAdmin()">{{vienti.s | lmoneyl: null : 'fi'}}</div>
      <!-- <div *ngIf="connections <= 1" class="light-gray-text">Avoinna: {{vienti.k | lmoneyl: null : 'fi'}}</div> -->
    </div>
  </div>



  <!-- <div *ngIf="connections > 1" style="display: flex; justify-content: flex-end; align-items: flex-end;"> -->
  <!-- <div class="light-gray-text">Avoinna: {{vienti.k | lmoneyl: null : 'fi'}}</div> -->
  <!-- </div> -->

</div>