import { Component, Input, OnInit, ChangeDetectionStrategy, OnDestroy, NgZone, ElementRef, ViewChild, AfterViewInit, ErrorHandler } from '@angular/core'

import { LaskuSharedService, LaskunSummat } from '../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuKorkoService } from '../../_jaettu/service/lasku/lasku-korko.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { AlvErittelynOsa } from '../../_jaettu/service/lasku/lasku-shared.service'
import { Lasku, LaskuBase, Laskuasetukset, LaskunumeroTyyppi } from '../../_jaettu/model/lasku'
import { TuettuKieli } from '../../_shared-core/model/common'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { WindowSizeService } from 'app/_jaettu-angular/service/window.service'

import { Big } from 'big.js'

import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LaskunLiitetiedosto } from '../../_jaettu/model/lasku'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LEMONATOR_CF_API, LemonHttpService } from 'app/_angular/service/lemon-http.service'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'
import { AsiakasService } from '../service/asiakas/asiakas.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface LaskuPdfEsikatselutiedot {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  rajoitaKorkeuteen?: number
}

type PreviewType = 'pdf' | 'jpg' | 'png'

interface LiiteJaData {
  liite: LaskunLiitetiedosto
  data: Promise<string>
  type: PreviewType
  loading: boolean
}

@Component({
  selector: '[app-lasku-pdf-esikatselu-perinteinen-rajoitettu-leveyteen]',
  styleUrls: ['./lasku.perinteinen-rajoitettu-leveyteen.component.css'],
  templateUrl: './lasku.perinteinen-rajoitettu-leveyteen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuPdfEsikatseluPerinteinenRajoitettuLeveyteenLemonator implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('esikatseluContainer', { static: true }) esikatseluContainer: ElementRef
  @ViewChild('kokopdflasku', { static: true }) kokopdflasku: ElementRef
  @ViewChild('alaosa', { static: true }) alaosa: ElementRef
  @ViewChild('ylaosa', { static: true }) ylaosa: ElementRef

  private _ngUnsubscribe = new Subject<void>()
  private _tiedot: LaskuPdfEsikatselutiedot = null

  kieli: TuettuKieli = 'fi'
  transformStyleObservable: BehaviorSubject<string> = new BehaviorSubject('scale(' + 695 / 595 + ')')
  tuotteetMinimumHeightStyleObservable: BehaviorSubject<string> = new BehaviorSubject('277px')
  spacerHeightStyleObservable: BehaviorSubject<string> = new BehaviorSubject('842px')
  lataaminenValmisObservable: BehaviorSubject<boolean> = new BehaviorSubject(false)

  alvErittely: AlvErittelynOsa[] = []
  virtuaaliviivakoodi = ''
  qrkoodi = ''
  mitatoity = false
  muistutus = false
  alvHuomautus: string = ''
  laskunSummat: LaskunSummat = null
  maksettuAiemmin: Big = null
  maksettavaaYhteensa: Big = null
  otsikko: string = ''
  iban: string = ''
  laskunumero: string = ''
  viitenumero: string = ''
  maa: string = ''
  laskuttajanMaa: string = ''
  maksuaikaa: number = 0
  pvm: string = ''
  toimituspvm: string = ''
  lokalisoituValuutta: string = ''
  lisatiedot: string = ''
  erapaiva: string = ''
  viivastysprosentti: string = ''
  muistutushuomautus: string = null

  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  asetukset: Laskuasetukset = null

  laskuLeftCoordinate: number = 0
  liitteetObservable: BehaviorSubject<LiiteJaData[]> = new BehaviorSubject([])

  @Input() naytaLatausmerkinta: boolean = true

  get tiedot() {
    return this._tiedot
  }
  @Input()
  set tiedot(t: LaskuPdfEsikatselutiedot) {

    if (!t) {
      this.lataaminenValmisObservable.next(false)
    }

    this._tiedot = t
    this.juurilasku = t ? t.juurilasku : null
    this.kasiteltava = t ? t.kasiteltava : null
    this.asetukset = t ? t.asetukset : null

    if (this.asetukset) {
      this.laskuttajanMaa = this.shared.annaLokalisoituMaa(this.asetukset.maakoodi, this.kasiteltava)
      if (this.asetukset.iban) {
        const trimmed = this.stringService.removeAllWhiteSpaces(this.asetukset.iban)
        this.iban = this.ibanService.formatoiIban(trimmed)
      } else {
        this.iban = ''
      }
    } else {
      this.laskuttajanMaa = ''
      this.iban = ''
    }

    if (this.juurilasku && this.kasiteltava) {
      this.virtuaaliviivakoodi = this.shared.annaMuotoiltuVirtuaaliviivakoodi(this.juurilasku, this.kasiteltava, this.asetukset)
      this.qrkoodi = this.shared.annaMuotoiltuQrkoodi(this.juurilasku, this.kasiteltava, this.asetukset)
      this.muistutus = this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS
      this.mitatoity = this.shared.onkoKasiteltavaMitatoity(this.juurilasku, this.kasiteltava)
      this.alvHuomautus = this.shared.annaAlvHuomautus(this.kasiteltava)
      this.laskunSummat = this.shared.annaLaskunSummat(this.kasiteltava)

      if (this.muistutus) {
        const aiemminMaksettuTaiHyvitetty = this.kasiteltava.summaReskontraLaskunPvm + Math.abs(this.kasiteltava.summaHyvityksetLaskunPvm)
        this.maksettuAiemmin = new Big('0').minus(aiemminMaksettuTaiHyvitetty)
        this.maksettavaaYhteensa = this.laskunSummat.yhteensaKaikki.minus(aiemminMaksettuTaiHyvitetty)
        this.muistutushuomautus = this.shared.annaLokalisoituMuistutus(this.juurilasku, this.kasiteltava)
      } else {
        this.maksettuAiemmin = null
        this.muistutushuomautus = null
        this.maksettavaaYhteensa = this.laskunSummat.yhteensaKaikki
      }

      this.otsikko = this.shared.annaLokalisoituLaskunOtsikko(this.juurilasku, this.kasiteltava, this.maksettavaaYhteensa)
      this.laskunumero = this.shared.annaMuotoiltuLaskunumero(this.juurilasku, this.kasiteltava)
      this.viitenumero = this.shared.annaMuotoiltuViitenumero(this.juurilasku)
      this.maksuaikaa = this.shared.laskeMaksuaikaa(this.kasiteltava)
      this.pvm = this.shared.formatoiPvm(this.kasiteltava.pvml, this.kasiteltava.pvm, this.kasiteltava)
      this.toimituspvm = this.shared.formatoiPvm(this.kasiteltava.toimituspvml, this.kasiteltava.toimituspvm, this.kasiteltava)
      this.lokalisoituValuutta = this.shared.annaLokalisoituValuutanNimi(this.kasiteltava)
      if (this.kasiteltava.asiakas) {
        this.maa = this.shared.annaLokalisoituMaa(this.kasiteltava.asiakas.maa, this.kasiteltava)
      } else {
        this.maa = ''
      }
      this.kieli = this.kasiteltava.kieli
      this.lisatiedot = this.annaLisatiedot()
      this.alvErittely = this.shared.annaLaskunSummat(this.kasiteltava).alvErittely.filter(osa => osa.alvKanta.prosentti > 0)
      this.erapaiva = this.annaErapaiva()
      const korkoprosentti = this.currencyService.muutaBigDecimalRahaksi(this.korkoService.annaViivastyskorkoProsentti(this.juurilasku))
      this.viivastysprosentti = this.currencyService.formatoiDesimaali(korkoprosentti, 2, this.kasiteltava.kieli) + ' %'

      if (this.kasiteltava.liitteet?.length) {
        const mapped = this.kasiteltava.liitteet.map(liite => {
          const liiteJaData: LiiteJaData = {
            data: null,
            liite: liite,
            type: this._getPreviewType(liite),
            loading: false
          }
          if (liiteJaData.type) {
            liiteJaData.loading = true
            liiteJaData.data = this._lataaLiitetiedostonDataUrlina(liiteJaData).then(url => {
              liiteJaData.loading = false
              this.liitteetObservable.next(this.liitteetObservable.value)
              return url
            })
          }
          return liiteJaData
        })
        this.liitteetObservable.next(mapped)
      } else {
        this.liitteetObservable.next([])
      }

      // Set timeout will set push it _after_ render to calculate the filler
      // Then we'll render again the changes and after that view

    } else {
      this.virtuaaliviivakoodi = ''
      this.qrkoodi = ''
      this.muistutus = false
      this.mitatoity = false
      this.alvHuomautus = ''
      this.laskunSummat = null
      this.maksettuAiemmin = null
      this.maksettavaaYhteensa = null
      this.otsikko = ''
      this.laskunumero = ''
      this.viitenumero = ''
      this.maa = ''
      this.maksuaikaa = 0
      this.pvm = ''
      this.lokalisoituValuutta = ''
      this.kieli = 'fi'
      this.lisatiedot = ''
      this.alvErittely = []
      this.muistutushuomautus = null
      this.erapaiva = ''
      this.liitteetObservable.next([])
      this.viivastysprosentti = ''
    }

    setTimeout(() => {
      this._ngZone.run(() => {
        this.laskeUudelleen()
        this.lataaminenValmisObservable.next(true)
      })
    }, 0)

  }

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    public shared: LaskuSharedService,
    private windowSizeService: WindowSizeService,
    private korkoService: LaskuKorkoService,
    private currencyService: CurrencyService,
    private stringService: StringService,
    private ibanService: IbanService,
    private _laskuUriService: LaskuUriService,
    private _ladataanService: LadataanService,
    private _httpService: LemonHttpService,
    private _asiakasService: AsiakasService,
    private _ngZone: NgZone,
    private _errorHandler: ErrorHandler,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      // console.log('change size')
      this.laskeUudelleen()
      // setTimeout(() => {
      // console.log('change size 2')
      //   this.laskeUudelleen()
      // }, 0)
    })
  }

  ngAfterViewInit() {
    // TODO: FIXME: WHEN ALL BROWSERS SUPPORT THIS, FEEL FREE TO REMOVE
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // el is visible
          this.laskeUudelleen()
          // console.log('UUDELLEEN')
          // this.changeDetectorRef.markForCheck()
        } else {
          // el is not visible
        }
      })
      observer.observe(this._elementRef.nativeElement)
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private annaErapaiva(): string {
    if (!this.kasiteltava || this.mitatoity || this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      return ''
    }
    // if (this.muistutus) {
    //   return this.shared.annaLokalisoituMerkkijono('pdf-perinteinen.heti', this.kasiteltava)
    // }
    return this.shared.formatoiPvm(this.kasiteltava.erapvml, this.kasiteltava.erapvm, this.kasiteltava)
  }

  private annaLisatiedot(): string {
    const lisatiedot = this.shared.annaLisatiedot(this.juurilasku, this.kasiteltava)
    return this.shared.valmisteleTeksti(lisatiedot)
  }

  private laskeUudelleen() {

    // this.paivitaEsikatselunTuotteidenKorkeusSpacer()

    const leveysEnnenTransformaatiota = this.esikatseluContainer.nativeElement.offsetWidth
    const uusiKerroin = Number(((leveysEnnenTransformaatiota) / 595).toFixed(2))

    this.paivitaEsikatselunKorkeusSpacer(uusiKerroin)
    if (this.transformStyleObservable.value !== 'scale(' + uusiKerroin + ')') {
      // console.log('skaalauskerroin asetataan:', this.transformStyleObservable.value, uusiKerroin)
      this.transformStyleObservable.next('scale(' + uusiKerroin + ')')
    }

  }

  // private paivitaEsikatselunTuotteidenKorkeusSpacer() {
  //   const ylaosanKorkeus = this.ylaosa ? this.ylaosa.nativeElement.offsetHeight : 225
  //   const alaosanKorkeus = this.alaosa ? this.alaosa.nativeElement.offsetHeight : 340
  //   const uusiSpacerKorkeus = Number((842 - (ylaosanKorkeus + alaosanKorkeus)).toFixed(0))
  //   // console.log('LASKE SPACER: ylaosa:', ylaosanKorkeus, this.ylaosa.nativeElement.offsetHeight, 'alaosa:', alaosanKorkeus, this.alaosa.nativeElement.offsetHeight, 'uusiSpacerKorkeus:', uusiSpacerKorkeus)
  //   const kaytettavaSpacerKorkeus = uusiSpacerKorkeus < 1 ? 10 : uusiSpacerKorkeus
  //   if (this.tuotteetMinimumHeightStyleObservable.value !== kaytettavaSpacerKorkeus + 'px') {
  //     // console.log('Spacerkorkeus asetetaan:', this.tuotteetMinimumHeightStyleObservable.value, kaytettavaSpacerKorkeus)
  //     this.tuotteetMinimumHeightStyleObservable.next(kaytettavaSpacerKorkeus + 'px')
  //   }
  // }

  private paivitaEsikatselunKorkeusSpacer(uusiKerroin: number) {

    const korkeusEnnenTransformaatiota = this.kokopdflasku.nativeElement.offsetHeight
    // console.log('KORKEUS ENNEN', korkeusEnnenTransformaatiota)
    const uusiKorkeus = Number((korkeusEnnenTransformaatiota * uusiKerroin).toFixed(0))
    const uusiKorkeusStr = (uusiKorkeus.toFixed(0)) + 'px'
    if (this.spacerHeightStyleObservable.value !== uusiKorkeusStr) {
      // console.log('UUSI KOKO KORKEUS', this.edellinenKorkeus, uusiKorkeus, (uusiKorkeus.toFixed(0)) + 'px')
      this.spacerHeightStyleObservable.next(uusiKorkeusStr)
    }
  }

  private _blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(blob)
    })
  }

  private async _lataaLiitetiedostonDataUrlina(liiteJaData: LiiteJaData): Promise<string> {
    const blob = await this._lataaLiitetiedostonData(liiteJaData.liite)
    const base64 = await this._blobToBase64(blob).then(base64Str => base64Str.split(';base64,')[1])
    if (liiteJaData.type === 'pdf') {
      return 'data:application/pdf;base64,' + base64
    } else if (liiteJaData.type === 'png') {
      return 'data:image/png;base64,' + base64
    } else if (liiteJaData.type === 'jpg') {
      return 'data:image/jpeg;base64,' + base64
    }
    throw new Error('Unknown type: ' + liiteJaData.type)
  }

  private async _lataaLiitetiedostonData(liitetiedosto: LaskunLiitetiedosto): Promise<Blob> {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const liitetiedostoUri = this._laskuUriService.annaLiitetiedostonCloudStorageUri(asiakas.avain, liitetiedosto)
    const url = '/laskuLataaLiite?a=' + encodeURIComponent('/api/1/laskut/lataaLiitetiedosto/' + liitetiedostoUri) + '&time=' + encodeURIComponent(new Date().getTime())
    return this._httpService.getBinary(url, LEMONATOR_CF_API)
  }

  async lataaLiitetiedosto(liitetiedosto: LaskunLiitetiedosto) {
    this._ladataanService.aloitaLataaminen()
    return this._lataaLiitetiedostonData(liitetiedosto).then(result => {
      this._ladataanService.lopetaLataaminen()
      this._fileSaverService.saveAs(result, liitetiedosto.nimi)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  private _getPreviewType(file: LaskunLiitetiedosto): PreviewType {
    const f = file.nimi.toLowerCase()
    if (f.endsWith('.pdf')) {
      return 'pdf'
    } else if (f.endsWith('.jpg') || f.endsWith('.jpeg')) {
      return 'jpg'
    } else if (f.endsWith('.png')) {
      return 'png'
    }
    return null
  }

}
