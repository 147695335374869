import { ErrorHandler, Injectable } from '@angular/core'

import { AsiakasService, AsiakkaanAvainTiedot } from '../asiakas/asiakas.service'
import { Kirjanpitaja } from '../../../_jaettu-lemonator/model/kirjanpitaja'

import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'

import { LaskuSharedService, ReskontraService } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { TuettuKieli } from '../../../_shared-core/model/common'
import { TranslationService } from '../../../_jaettu/service/translation.service'
import { DateService } from '../../../_shared-core/service/date.service'
import { Asiakas, Kayttaja } from '../../../_jaettu-lemonator/model/asiakas'
import { LaskuKopioija } from '../../../_jaettu/service/lasku/lasku.kopioija'
import { LaskuIndeksoija } from '../../../_jaettu/service/lasku/lasku.indeksoija'
// eslint-disable-next-line max-len
import { Laskuasetukset, Lasku, LaskuBase, EmailLahetysStatus, LaskuToimintaloki, EI_LASKUNUMEROA_NUMERO, LaskunSahkpostipohjanVari, LaskunLiitetiedosto, LaskuReskontra, LaskunumeroTyyppi, LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta, LaskunSahkoinenOsoite, LaskunSahkopostipohja, LaskunAsiakas, LaskunTyypit, LaskunAsiakastyyppi, LaskunLahetystapa, EmailLahetysStatusKoodi } from '../../../_jaettu/model/lasku'
import { LaskuUriService } from '../../../_jaettu/service/lasku/lasku-uri.service'
import { BatchManipulator, FirestoreProvider, FirestoreWriteBatch, LaskuTallennusService } from '../../../_jaettu/service/lasku/lasku-tallennus.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { ViitenumeroService } from '../../../_shared-core/service/viitenumero.service'
import { StringService } from '../../../_shared-core/service/string.service'
import { KayttajanTiedot } from '../../../_jaettu/model/kayttaja'

import { Observable, firstValueFrom } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { FirebaseLemonaid, FirebaseLemonator } from '../firebase-lemonator.service'
import { Timestamp } from 'app/_shared-core/model/common'
import { LaskuSpostiService } from 'app/_jaettu/service/lasku/lasku-sposti.service'
import { AlvService } from 'app/_jaettu-angular/service/alv.service'

@Injectable()
export class LaskuService {

  private laskuTallennusService: LaskuTallennusService

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public LEMONTREE_ASIAKAS_ID = 9
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public LEMONTREE_ASIAKAS_AVAIN = 'jzATU474P4BRCkEANZ1H'

  asetuksetObservable: Observable<Laskuasetukset>

  constructor(
    private errorHandler: ErrorHandler,
    private dateService: DateService,
    private currencyService: CurrencyService,
    private reskontraService: ReskontraService,
    private translationService: TranslationService,
    private laskuUriService: LaskuUriService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private timestampService: TimestampService,
    private shared: LaskuSharedService,
    private laskuKopioija: LaskuKopioija,
    private laskuIndeksoija: LaskuIndeksoija,
    private asiakasService: AsiakasService,
    private viitenumeroService: ViitenumeroService,
    private stringService: StringService,
    private _laskuSpostiService: LaskuSpostiService,
    private _alvService: AlvService,
  ) {

    const firestoreProvider: FirestoreProvider = {
      annaDeleteArvo: () => { return this._firebaseLemonaid.firestoreDeleteMarker() },
      annaUusiAvain: () => { return this._firebaseLemonaid.firestoreCreateId() },
      // THIS IS REALLY COUNTERINTUITIVE, BUT THE DOC TYPE CONTAINS NO DATA
      // AND THESE DAYS OUR FRONT END FIREBASE INTEGRATION LIBRARY EXPECTS THE URI,
      // NOT THE DOC TO BE PRESENT!
      annaDoc: (uri: string) => { return uri },
      annaUusiBatch: () => { return this._firebaseLemonaid.firestoreBatch() }
    }

    this.laskuTallennusService = new LaskuTallennusService(
      this.shared,
      this.laskuKopioija,
      this.laskuIndeksoija,
      this.currencyService,
      this.dateService,
      this.laskuUriService,
      this.translationService,
      this.timestampService,
      this.reskontraService,
      firestoreProvider,
      this.viitenumeroService,
      this.stringService
    )

    this.asetuksetObservable = this._firebaseLemonaid.firestoreDoc<Laskuasetukset>('/laskut/' + this.LEMONTREE_ASIAKAS_ID).listen()

  }

  getLaskuAsetuksetAsiakkaalleObservable(asiakasId: string): Observable<Laskuasetukset> {
    return this._firebaseLemonaid.firestoreDoc<Laskuasetukset>('/laskut/' + asiakasId).listen()
  }

  getLaskuObservable(id: string): Observable<Lasku> {
    const laskuUri = this.laskuUriService.getLaskuUri(this.LEMONTREE_ASIAKAS_ID, id)
    return this._firebaseLemonaid.firestoreDoc<Lasku>(laskuUri).listen().pipe(
      map(lasku => {
        if (lasku) {
          delete lasku['haku']
        }
        return lasku
      })
    )
  }

  getLaskuObservableAsiakkaalle(asiakasId: string, id: string): Observable<Lasku> {
    const laskuUri = this.laskuUriService.getLaskuUri(asiakasId, id)
    return this._firebaseLemonaid.firestoreDoc<Lasku>(laskuUri).listen().pipe(
      map(lasku => {
        if (lasku) {
          delete lasku['haku']
        }
        return lasku
      })
    )
  }

  async paivitaTilaJaIndeksoiUudelleen(juurilasku: Lasku, asiakas: AsiakkaanAvainTiedot, kirjanpitaja: Kirjanpitaja): Promise<void> {
    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)
    this.shared.paivitaLaskunTila(juurilasku, reskontra)
    return this.indeksoiUudelleen(juurilasku, asiakas, kirjanpitaja)
  }

  async indeksoiUudelleen(juurilasku: Lasku, asiakas: AsiakkaanAvainTiedot, kirjanpitaja: Kirjanpitaja): Promise<void> {
    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)
    const kayttajanTiedot: KayttajanTiedot = {
      asiakasAvain: asiakas.avain,
      asiakasId: asiakas.asiakasId + '',
      uid: kirjanpitaja.lemonaidUid
    }
    return this.laskuTallennusService.indeksoiUudelleen(kayttajanTiedot, juurilasku, reskontra)
  }

  async laskeReskontraUudelleen(juurilasku: Lasku, asiakas: AsiakkaanAvainTiedot, kirjanpitaja: Kirjanpitaja): Promise<void> {
    const kayttajanTiedot: KayttajanTiedot = {
      asiakasAvain: asiakas.avain,
      asiakasId: asiakas.asiakasId + '',
      uid: kirjanpitaja.lemonaidUid
    }
    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)
    return this.laskuTallennusService.laskeReskontraUudelleen(kayttajanTiedot, juurilasku, reskontra)
  }

  async merkitseLaskuLahetetyksiSpostilla(asiakas: Asiakas, kirjanpitaja: Kirjanpitaja, templateId: number, juurilasku: Lasku, kasiteltava: LaskuBase, vastaanottajat: EmailLahetysStatus[], aihe: string, otsikko: string, teksti: string, slogan: string, varit: { [key in LaskunSahkpostipohjanVari]: string }): Promise<void> {

    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)

    const manipulator: BatchManipulator = this._annaMyyntilaskuOstolaskuksiBatchManipulator(asiakas)

    // Asetetaan asiakkaan avain
    kasiteltava.asiakas.avain = asiakas.avain
    return this.paivitaLemonatorAsiakas(asiakas, kasiteltava).then(() => {
      const kayttajanTiedot: KayttajanTiedot = {
        asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN,
        asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
        uid: kirjanpitaja.lemonaidUid
      }
      return this.laskuTallennusService.merkitseLaskuLahetetyksiSpostilla(kayttajanTiedot, templateId, juurilasku, kasiteltava, reskontra, vastaanottajat, aihe, otsikko, teksti, slogan, asiakas.viitenumero, manipulator, varit, 'footer1', true)
    })
  }

  async merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(asiakas: Asiakas, osoite: LaskunSahkoinenOsoite, kirjanpitaja: Kirjanpitaja, juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {

    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)

    const manipulator: BatchManipulator = this._annaMyyntilaskuOstolaskuksiBatchManipulator(asiakas)

    // Asetetaan asiakkaan avain
    kasiteltava.asiakas.avain = asiakas.avain
    return this.paivitaLemonatorAsiakas(asiakas, kasiteltava).then(() => {
      const kayttajanTiedot: KayttajanTiedot = {
        asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN,
        asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
        uid: kirjanpitaja.lemonaidUid
      }
      return this.laskuTallennusService.merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, manipulator, asiakas.viitenumero)
    })

  }

  async merkitseLaskuLahetetyksiSahkoisesti(asiakas: Asiakas, osoite: LaskunSahkoinenOsoite, kirjanpitaja: Kirjanpitaja, juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {

    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)

    const manipulator: BatchManipulator = this._annaMyyntilaskuOstolaskuksiBatchManipulator(asiakas)

    // Asetetaan asiakkaan avain
    kasiteltava.asiakas.avain = asiakas.avain
    return this.paivitaLemonatorAsiakas(asiakas, kasiteltava).then(() => {
      const kayttajanTiedot: KayttajanTiedot = {
        asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN,
        asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
        uid: kirjanpitaja.lemonaidUid
      }
      return this.laskuTallennusService.merkitseLaskuLahetetyksiSahkoisesti(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, manipulator, asiakas.viitenumero)
    })

  }

  private _annaMyyntilaskuOstolaskuksiBatchManipulator(asiakas: Asiakas): BatchManipulator {
    const manipulator: BatchManipulator = {
      manipulate: (firestoreProvider: FirestoreProvider, batch: FirestoreWriteBatch, jl: Lasku, ka: LaskuBase) => {

        const id = firestoreProvider.annaUusiAvain()
        const sahkoiseksiTyodata: LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta = {
          aloitettu: this.timestampService.now(),
          kasiteltavaAvain: ka.avain,
          laskuAvain: jl.avain,
          myyvanAsiakkaanAvain: this.LEMONTREE_ASIAKAS_AVAIN,
          ostavanAsiakkaanAvain: asiakas.avain,
          myyvanAsiakkaanId: this.LEMONTREE_ASIAKAS_ID + '',
          uudelleenyrityksia: 0
        }
        const sahkoiseksiUri = this.laskuUriService.getTyojonoLaskuMyyntilaskuOstolaskuksi(sahkoiseksiTyodata.myyvanAsiakkaanAvain, id)
        const sahkoiseksiDoc = firestoreProvider.annaDoc(sahkoiseksiUri)
        batch.set(sahkoiseksiDoc, sahkoiseksiTyodata)

      }
    }
    return manipulator
  }

  private paivitaLemonatorAsiakas(asiakas: Asiakas, kasiteltava: LaskuBase): Promise<void> {
    // Päivitä asiakas Lemonatorissa
    asiakas.nimi = kasiteltava.asiakas.nimi
    asiakas.ytunnus = kasiteltava.asiakas.ytunnus
    asiakas.katuosoite = kasiteltava.asiakas.katuosoite
    asiakas.postinro = kasiteltava.asiakas.postinro
    asiakas.postitmp = kasiteltava.asiakas.postitmp
    asiakas.maa = kasiteltava.asiakas.maa
    asiakas.laskunKieli = kasiteltava.kieli
    asiakas.laskunTyyppi = kasiteltava.tyyppi
    asiakas.laskunValuutta = kasiteltava.valuutta

    if (kasiteltava.email && kasiteltava.email.vastaanottajat) {
      asiakas.laskunVastaanottajat = kasiteltava.email.vastaanottajat.map(vastaanottaja => { return vastaanottaja.email })
    }

    return this.asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
  }

  async lahetaEmailUudelleen(juurilasku: Lasku, kasiteltava: LaskuBase, asiakas: Asiakas, kirjanpitaja: Kirjanpitaja, vastaanottaja: EmailLahetysStatus, vanhaEmail: string): Promise<void> {
    // Päivitä virheellinen email Lemonator asiakkaassa
    if (asiakas.laskunVastaanottajat) {
      const index = asiakas.laskunVastaanottajat.indexOf(vanhaEmail)
      if (index > -1) {
        asiakas.laskunVastaanottajat.splice(index, 1)
      }
    } else {
      asiakas.laskunVastaanottajat = []
    }
    asiakas.laskunVastaanottajat.push(vastaanottaja.email)
    const kayttajanTiedot: KayttajanTiedot = {
      asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN,
      asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
      uid: kirjanpitaja.lemonaidUid
    }
    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)
    return this.asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false).then(() => {
      return this.laskuTallennusService.lahetaEmailUudelleen(kayttajanTiedot, juurilasku, kasiteltava, reskontra, vastaanottaja, vanhaEmail)
    })
  }

  async lahetaSahkoinenUudelleen(juurilasku: Lasku, kasiteltava: LaskuBase, asiakas: Asiakas, kirjanpitaja: Kirjanpitaja): Promise<void> {
    const kayttajanTiedot: KayttajanTiedot = {
      asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN,
      asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
      uid: kirjanpitaja.lemonaidUid
    }
    asiakas.sahkoinenLaskutusosoite = kasiteltava.sahkoinen.osoite
    const reskontra = await this._getLaskuReskontra(asiakas.asiakasId, juurilasku.avain)
    return this.asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false).then(() => {
      return this.laskuTallennusService.lahetaSahkoinenUudelleen(kayttajanTiedot, juurilasku, kasiteltava, reskontra)
    })
  }

  haeLaskunumeroArvio(lasku: LaskuBase): Promise<number> {
    if (lasku.nro && lasku.nro !== EI_LASKUNUMEROA_NUMERO) {
      return Promise.resolve(lasku.nro)
    }
    const numerotyyppi = lasku.nrotyyppi === LaskunumeroTyyppi.HYVITYS ? LaskunumeroTyyppi.TAVALLINEN : lasku.nrotyyppi
    return this.getLaskunumeroInternal(lasku.pvm, lasku.avain, numerotyyppi).then(number => {
      const year = lasku.pvm.toDate().getFullYear().toString().substring(2)
      return Number(year + number)
    })
  }

  private async getLaskunumeroInternal(laskupvm: Timestamp, laskuid: string, tyyppi: LaskunumeroTyyppi): Promise<number> {
    const uri = '/laskut/nrot/' + this.LEMONTREE_ASIAKAS_ID + '/' + tyyppi + '/' + laskupvm.toDate().getFullYear()
    return this._firebaseLemonaid.databaseGet(uri)
      .then(snapshot => {
        const values = snapshot.val()
        if (values) {
          if (laskuid) {
            let index = 1
            for (const numero of values) {
              if (numero === laskuid) {
                return index
              }
              index++
            }
            return index
          } else {
            return values.length + 1
          }
        } else {
          return 1
        }
      })
  }

  getLaskuReskontraObservableAsiakkaalle(asiakasId: string, laskuAvain: string): Observable<LaskuReskontra[]> {
    const uri = this.laskuUriService.getLaskuReskontraCollectionUri(asiakasId, laskuAvain)
    return this._firebaseLemonaid.firestoreCollection<LaskuReskontra>(uri).orderBy('pvm', 'asc').listen()
  }

  getLaskuToimintalokiObservableAsiakkaalle(asiakasId: string, laskuAvain: string): Observable<LaskuToimintaloki[]> {
    const uri = this.laskuUriService.getLaskuToimintalokiCollectionUri(asiakasId, laskuAvain)
    return this._firebaseLemonaid.firestoreCollection<LaskuToimintaloki>(uri).orderBy('pvm', 'asc').orderBy('toiminto', 'asc').listen()
  }

  private _getLaskuReskontra(asiakasId: number, laskuAvain: string): Promise<LaskuReskontra[]> {
    return firstValueFrom(this.getLaskuReskontraObservableAsiakkaalle(asiakasId + '', laskuAvain).pipe(filter(rskntr => !!rskntr)))
  }

  getLaskuReskontraObservable(laskuAvain: string): Observable<LaskuReskontra[]> {
    const uri = this.laskuUriService.getLaskuReskontraCollectionUri(this.LEMONTREE_ASIAKAS_ID, laskuAvain)
    return this._firebaseLemonaid.firestoreCollection<LaskuReskontra>(uri).orderBy('pvm', 'asc').listen()
  }

  getLaskuToimintalokiObservable(laskuAvain: string): Observable<LaskuToimintaloki[]> {
    const uri = this.laskuUriService.getLaskuToimintalokiCollectionUri(this.LEMONTREE_ASIAKAS_ID, laskuAvain)
    return this._firebaseLemonaid.firestoreCollection<LaskuToimintaloki>(uri).orderBy('pvm', 'asc').orderBy('toiminto', 'asc').listen()
  }

  getLiitetiedostoUriAsiakkaalle(asiakasAvain: string, liitetiedosto: LaskunLiitetiedosto): string {
    return this.laskuUriService.annaLiitetiedostonCloudStorageUri(asiakasAvain, liitetiedosto)
  }

  getLiitetiedostoUri(liitetiedosto: LaskunLiitetiedosto): string {
    return this.laskuUriService.annaLiitetiedostonCloudStorageUri(this.LEMONTREE_ASIAKAS_AVAIN, liitetiedosto)
  }

  getPdfUrlAsiakkaalle(asiakasId: string, lasku: LaskuBase): string {
    return this.laskuUriService.getPdfUri(asiakasId, lasku.avain)
  }

  getPdfUrl(lasku: LaskuBase): string {
    return this.laskuUriService.getPdfUri(this.LEMONTREE_ASIAKAS_ID, lasku.avain)
  }

  annaPdfTiedostonNimi(juurilasku: Lasku, kasiteltava: LaskuBase, kieli: TuettuKieli): string {
    let nimi = this.translationService.lokalisoi('lasku.tiedostonimen-alku-ladattaessa', kieli)
    const laskunumero = this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava)
    if (laskunumero) {
      nimi += ' ' + laskunumero
    }
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.nimi) {
      nimi += ' ' + kasiteltava.asiakas.nimi
    }
    if (kasiteltava && kasiteltava.pvm) {
      nimi += ' ' + this.dateService.muotoilePaiva(kasiteltava.pvm, kieli)
    }
    nimi += '.pdf'

    return nimi
  }

  public lemonatorAsiakasLaskunAsiakkaaksi(asiakas: Asiakas): LaskunAsiakas {
    return {
      avain: asiakas.avain,
      date: this.timestampService.now(),
      katuosoite: asiakas.katuosoite,
      laskunKieli: asiakas.laskunKieli || 'fi',
      laskunTyyppi: asiakas.laskunTyyppi || LaskunTyypit.TAVALLINEN.avain,
      laskunValuutta: asiakas.laskunValuutta || 'EUR',
      laskunVastaanottajat: asiakas.laskunVastaanottajat || [],
      maa: asiakas.maa,
      nimi: asiakas.nimi,
      postinro: asiakas.postinro,
      postitmp: asiakas.postitmp,
      ytunnus: asiakas.ytunnus,
      asiakastyyppi: LaskunAsiakastyyppi.YRITYS, // Kaikki Lemonatorin asiakkaat ovat yrityksiä
      sahkoinenosoite: {
        sahkoinenOsoite: null,
        sahkoinenValittaja: null
      },
      viimeisinLaskuLahetetty: LaskunLahetystapa.SAHKOPOSTI
    }
  }

  async sendLemonTreeReminderInvoice(alkuperainenLasku: Lasku, lahettajanLaskuasetukset: Laskuasetukset): Promise<void> {
    const lasku = this.laskuKopioija.copyLasku(alkuperainenLasku)
    const kasiteltava = this.shared.annaViimeisinTavallinenLasku(lasku)
    const asiakasPromise = this._firebase.firestoreDoc<Asiakas>('asiakkaat/' + kasiteltava.asiakas.avain).get()
    const asiakkaanKayttajatPromise = this._firebase.firestoreCollection<Kayttaja>('asiakkaat/' + kasiteltava.asiakas.avain + '/kayttajat').get()

    const [asiakas, asiakkaanKayttajat] = await Promise.all([asiakasPromise, asiakkaanKayttajatPromise])

    const lang = asiakas.laskunKieli || 'fi'
    const postmarkTemplateId = this._laskuSpostiService.annaSahkopostipohjanPostmarkNumero(LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN, lang)

    const laskunAsiakas = this.lemonatorAsiakasLaskunAsiakkaaksi(asiakas)
    const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(kasiteltava.tyyppi)
    if (!laskunTyyppi) {
      throw new Error('Ei LaskunTyyppiä avaimella: ' + kasiteltava.tyyppi)
    }

    const alvt = await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, asiakas.maa)
    if (!alvt?.length) {
      throw new Error('Ei ALV-määrityksiä tyypille ' + laskunTyyppi + ' ja maalle ' + asiakas.maa)
    }

    const muokattavaksiKasiteltava = this.laskuKopioija.kopioiLaskuMuistutusLaskuksi(lasku, kasiteltava, laskunAsiakas, alvt)
    if (!lasku.korvaus) {
      lasku.korvaus = []
    }
    lasku.korvaus.push(muokattavaksiKasiteltava.kopioitu)

    const muikkariAsetukset = lahettajanLaskuasetukset.muistutusSpostiasetukset ? lahettajanLaskuasetukset.muistutusSpostiasetukset[muokattavaksiKasiteltava.kopioitu.kieli] : null
    const rawAihe = muikkariAsetukset?.aihe || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.aihe', muokattavaksiKasiteltava.kopioitu)
    const rawOtsikko = muikkariAsetukset?.otsikko || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.otsikko', muokattavaksiKasiteltava.kopioitu)
    const rawTeksti = muikkariAsetukset?.teksti || this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.teksti', muokattavaksiKasiteltava.kopioitu)
    const aihe = this._laskuSpostiService.korvaaMuuttujat(rawAihe, lahettajanLaskuasetukset, lasku, muokattavaksiKasiteltava.kopioitu)
    const otsikko = this._laskuSpostiService.korvaaMuuttujat(rawOtsikko, lahettajanLaskuasetukset, lasku, muokattavaksiKasiteltava.kopioitu)
    const teksti = this._laskuSpostiService.korvaaMuuttujat(rawTeksti, lahettajanLaskuasetukset, lasku, muokattavaksiKasiteltava.kopioitu)

    let aktiivisetYhteyshenkilot = asiakkaanKayttajat.filter(kayttaja => kayttaja.aktiivinen && kayttaja.roolit.HALLINTO_YHTEYSHENKILO)

    if (aktiivisetYhteyshenkilot.length < 1) {
      aktiivisetYhteyshenkilot = asiakkaanKayttajat.length === 1 ? asiakkaanKayttajat : null
    }

    if (!aktiivisetYhteyshenkilot) {
      throw new Error('Asiakkalla ' + lasku.asiakas.nimi + ' ei ole aktiivista yhteyshenkilöä.')
    }

    const kayttajanTiedot: KayttajanTiedot = {
      uid: 'lemonator_system',
      asiakasId: this.LEMONTREE_ASIAKAS_ID + '',
      asiakasAvain: this.LEMONTREE_ASIAKAS_AVAIN
    }

    const aktiivisetYhteyshenkilotEmailStatus: EmailLahetysStatus[] = aktiivisetYhteyshenkilot.map((henkilo) => {
      const status: EmailLahetysStatus = {
        email: henkilo.email,
        status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
        viesti: null
      }
      return status
    })

    const reskontra = await this._getLaskuReskontra(this.LEMONTREE_ASIAKAS_ID, alkuperainenLasku.avain)
    return this.laskuTallennusService.merkitseLaskunMaksumuistutusLahetetyksiSpostilla(kayttajanTiedot, postmarkTemplateId, lasku, muokattavaksiKasiteltava.kopioitu, reskontra, aktiivisetYhteyshenkilotEmailStatus, aihe, otsikko, teksti, lahettajanLaskuasetukset.slogan, false)
  }

  annaPdfTiedostonNimiAsiakkaalle(asiakas: Asiakas, juurilasku: Lasku, kasiteltava: LaskuBase, kieli: TuettuKieli): string {
    let nimi = 'Lemon Treen asiakkaan ' + asiakas.nimi + ' (' + asiakas.ytunnus + ') lasku '
    const laskunumero = this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava)
    if (laskunumero) {
      nimi += ' ' + laskunumero
    }
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.nimi) {
      nimi += ' heidän asiakkaalle ' + kasiteltava.asiakas.nimi
    }
    if (kasiteltava && kasiteltava.pvm) {
      nimi += ' ' + this.dateService.muotoilePaiva(kasiteltava.pvm, kieli)
    }
    nimi += '.pdf'

    return nimi
  }

}
