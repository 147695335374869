import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { StrongAuthenticationSessionHistory } from '../_jaettu/model/tunnistaminen'


@Component({
  templateUrl: './kayttaja.strong-auth-history.dialog.html'
})
export class KayttajaStrongAuthHistoryDialog implements OnInit {

  strongAuthHistoryObservable: Observable<StrongAuthenticationSessionHistory>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StrongAuthenticationSessionHistory[],
    private _dialogRef: MatDialogRef<KayttajaStrongAuthHistoryDialog>
  ) { }

  ngOnInit() {
  }

  sulje() {
    this._dialogRef.close()
  }

}
