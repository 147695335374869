import { Component, OnDestroy, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { MatSelectChange } from '@angular/material/select'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Tilikausi, TilinpaatoksenTuloslaskelmanTyyppi } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { Timestamp } from 'app/_shared-core/model/common'
import { DateService } from 'app/_shared-core/service/date.service'

import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs'

import { KlikattuKirjaus } from './raportit.component'
import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

export type Valilehti = 'tase-erittely' | 'liitetiedot' | 'tilintarkastus' | 'rekisterointi' | 'lahetys'

@Component({
  selector: '[app-kirjanpito-tilinpaatos]',
  templateUrl: './tilinpaatos.component.html',
  styleUrls: ['./tilinpaatos.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTilinpaatosComponent implements OnInit, OnDestroy {

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  private _ngUnsubscribe = new Subject<void>()
  private _selectedTilikausiSubject: BehaviorSubject<Tilikausi> = new BehaviorSubject(null)

  selectedTab: Valilehti = 'tase-erittely'
  paivitaArvotHiljaisestiObservable: Observable<number>
  selectedTilikausiObservable: Observable<Tilikausi> = this._selectedTilikausiSubject.asObservable()
  tilikaudetObservable: Observable<Tilikausi[]>
  tilinpaatosStatusObservable: Observable<TilinpaatosStatus>
  tilinpaatosNotStarted: boolean = true

  tilinpaatoksenTuloslaskelma: TilinpaatoksenTuloslaskelmanTyyppi = null
  readonly TilinpaatoksenTuloslaskelmanTyyppi = TilinpaatoksenTuloslaskelmanTyyppi

  constructor(
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _firebase: FirebaseLemonator,
  ) {
    this.tilinpaatosStatusObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = 'asiakkaat/' + asiakas.avain + '/tilinpaatos-status/' + tilikausi.avain
          return this._firebase.firestoreDoc<TilinpaatosStatus>(uri).listen()
        }
        return of<TilinpaatosStatus>(null)
      }),
      lemonShare()
    )
  }

  ngOnInit() {
    this.tilikaudetObservable = this._asiakasService.nykyisenAsiakkaanTilikaudetObservable.pipe(
      tap(tilikaudet => {
        if (!this._selectedTilikausiSubject.value) {
          const year = new Date().getFullYear() - 1
          this._setTilikausi(tilikaudet.find(t => t.loppuu.year === year))
          if (!this._selectedTilikausiSubject.value && tilikaudet.length) {
            this._setTilikausi(tilikaudet[tilikaudet.length - 1])
          }
        }
      })
    )

    this.paivitaArvotHiljaisestiObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<{ u: Timestamp }>(null)
        }
        const raporttienUri = this._kirjanpitoUriService.annaRaportointikirjauksetPaivitettyUri(asiakas.avain)
        return this._firebase.firestoreDoc<{ u: Timestamp }>(raporttienUri).listen()
      }),
      map(doc => doc?.u?.toMillis() || Date.now())
    )

    this.selectedTilikausiObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tk => {
      this.tilinpaatosNotStarted = !tk?.tilinpaatosStarted
    })

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((asiakas) => {
      if (!this.tilinpaatoksenTuloslaskelma) {
        if (asiakas?.tilinpaatosRekisteroidaanBruttotuloslaskelmalla) {
          this.tilinpaatoksenTuloslaskelma = TilinpaatoksenTuloslaskelmanTyyppi.BRUTTO
        } else {
          this.tilinpaatoksenTuloslaskelma = TilinpaatoksenTuloslaskelmanTyyppi.VIRALLINEN
        }
      }
    })


    // this.tilintarkastetaanObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
    //   switchMap(([asiakas, tilikausi]) => {
    //     if (asiakas && tilikausi) {
    //       const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
    //       return this._firebase.firestoreDoc<TilinpaatosLiitetiedot>(uri).listen().then(
    //         map(liitetiedot => !!liitetiedot?.tilintarkastetaan)
    //       )
    //     }
    //     return of<boolean>(false)
    //   })
    // )

  }

  setTilikausiEvent(event: MatSelectChange) {
    this._setTilikausi(event.value as Tilikausi)
  }

  private _setTilikausi(tilikausi: Tilikausi) {
    if (
      tilikausi &&
      (
        !this._selectedTilikausiSubject.value ||
        this._dateService.compareLocalDates(tilikausi.loppuu, '!=', this._selectedTilikausiSubject.value.loppuu)
      )
    ) {
      this._selectedTilikausiSubject.next(tilikausi)
    }
  }

  async startTilinpaatos() {
    this.tilinpaatosNotStarted = false

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)

    for (const tk of asiakas.tilikaudet) {
      if (tk.avain === this._selectedTilikausiSubject.value?.avain) {
        tk.tilinpaatosStarted = 1
        tk.tilinpaatoksenTuloslaskelma = this.tilinpaatoksenTuloslaskelma
        break
      }
    }

    return this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
  }

  compareTilikaudet(o1: Tilikausi, o2: Tilikausi): boolean {
    return o1?.avain === o2?.avain
  }

  setSelectedTab(tab: Valilehti) {
    this.selectedTab = tab
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  emitKirjausData(klikattu: KlikattuKirjaus) {
    if (klikattu) {
      this.kirjaustaKlikattiin.emit(klikattu)
    }
  }
}
