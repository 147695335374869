export class HolviSharedUriService {

  startHolviTransactionFetchTyojonoUri(asiakasAvain: string, tyojonoAvain: string) {
    return this.startHolviTransactionFetchTyojonoCollection(asiakasAvain) + '/' + tyojonoAvain
  }

  startHolviTransactionFetchTyojonoCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/holvi-transaction-fetch'
  }

  getManualTransactionFetchLogCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/holvi-transaction-fetch-logs'
  }

  getManualTransactionFetchLogUri(asiakasAvain: string, logAvain: string) {
    return this.getManualTransactionFetchLogCollection(asiakasAvain) + '/' + logAvain
  }

}
