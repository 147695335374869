import { Injectable } from '@angular/core'

import { LaskunAsiakasTypeaheadBase, AsiakasTypeaheadResult, TuoteTypeaheadResult, LaskunTuoteTypeaheadBase } from '../../../_jaettu-angular/service/lasku/typeahead.service'
import { TypeaheadAsiakas, LaskunAsiakas, AsiakkaanTuote } from '../../../_jaettu/model/lasku'

@Injectable()
export class LaskunAsiakasTypeahead implements LaskunAsiakasTypeaheadBase {

  constructor() { }

  public annaAsiakas(typeaheadAsiakas: TypeaheadAsiakas): Promise<LaskunAsiakas> {
    return Promise.resolve(null)
  }

  public haeJaSuodata(value: string): Promise<AsiakasTypeaheadResult> {
    const res: AsiakasTypeaheadResult = {
      suodatetut: []
    }
    return Promise.resolve(res)
  }

}

@Injectable()
export class LaskunTuoteTypeahead implements LaskunTuoteTypeaheadBase {

  constructor() {}

  public getTuote(avain: string): Promise<AsiakkaanTuote> {
    return Promise.resolve(null)
  }

  public haeJaSuodata(value: string): Promise<TuoteTypeaheadResult> {
    const res: TuoteTypeaheadResult = {
      loytynyt: null,
      suodatetut: []
    }
    return Promise.resolve(res)
  }

}
