import { Component, OnInit, ErrorHandler } from '@angular/core'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { Observable, of } from 'rxjs'
import { tap, switchMap } from 'rxjs/operators'
import { EnnakkoveronMaksuohjelma, VerojenMaksuyhteystiedot } from '../../_jaettu-lemonator/model/asiakas'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'


@Component({
  templateUrl: './omavero-maksutiedot.component.html',
  styleUrls: ['./omavero-maksutiedot.component.css']
})
export class AsiakasOmaveroMaksutiedotComponent implements OnInit {

  yhteystietojaLadataan: boolean = false
  ohjelmaaLadataan: boolean = false

  yhteystietoObservable: Observable<VerojenMaksuyhteystiedot>
  ohjelmaObservable: Observable<EnnakkoveronMaksuohjelma>

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _firebaseLemonaid: FirebaseLemonaid,
  ) {

  }

  ngOnInit() {

    this.yhteystietoObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      tap(() => {
        this.yhteystietojaLadataan = false
      }),
      switchMap(asiakas => {
        if (asiakas) {
          const maksutietojenPath = 'customers/' + asiakas.avain + '/customer-vero-maksuyhteystiedot/' + asiakas.avain
          return this._firebaseLemonaid.firestoreDoc<VerojenMaksuyhteystiedot>(maksutietojenPath).listen()
        }
        return of<VerojenMaksuyhteystiedot>(null)
      }),
      tap(() => {
        this.yhteystietojaLadataan = true
      })
    )

    this.ohjelmaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      tap(() => {
        this.ohjelmaaLadataan = false
      }),
      switchMap(asiakas => {
        if (asiakas) {
          const maksutietojenPath = 'customers/' + asiakas.avain + '/customer-vero-ennakkovero-maksuohjelmat/' + asiakas.avain
          return this._firebaseLemonaid.firestoreDoc<EnnakkoveronMaksuohjelma>(maksutietojenPath).listen()
        }
        return of<EnnakkoveronMaksuohjelma>(null)
      }),
      tap(() => {
        this.ohjelmaaLadataan = true
      })
    )

  }

}
