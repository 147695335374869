import { Component, OnInit, OnDestroy, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LEMONATOR_CF_API, LemonHttpService } from '../../_angular/service/lemon-http.service'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'

import { Lasku, LaskuBase } from '../../_jaettu/model/lasku'
import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface LaskuLataaDialogData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
}

@Component({
  templateUrl: './lasku.lataa.dialog.html'
})
export class LaskuLataaDialog implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuLataaDialogData,
    private dialogRef: MatDialogRef<LaskuLataaDialog>,
    private errorHandler: ErrorHandler,
    private laskuService: LaskuService,
    private shared: LaskuSharedService,
    private http: LemonHttpService,
    private lemonTranslation: LemonTranslationService,
    private _fileSaverService: FileSaverService
  ) {
    this.juurilasku = data.juurilasku
    this.kasiteltava = data.kasiteltava
  }

  ngOnInit() {
    this.laskuService.getLaskuObservable(this.juurilasku.avain).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(juurilasku => {

      this.juurilasku = juurilasku
      this.kasiteltava = this.shared.annaKasiteltavaLasku(this.juurilasku, this.kasiteltava.avain)

      if (
        (this.kasiteltava.print && this.kasiteltava.print.done) ||
        (this.kasiteltava.email && this.kasiteltava.email.done) ||
        (this.kasiteltava.sahkoinen && this.kasiteltava.sahkoinen.done)
      ) {
        const pdfUri = this.laskuService.getPdfUrl(this.kasiteltava)
        const url = '/api/1/laskut/lataaPdf/' + pdfUri
        return this.http.getBinary('/laskuLataaPdf?a=' + encodeURIComponent(url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONATOR_CF_API).then(result => {
          const nimi = this.laskuService.annaPdfTiedostonNimi(this.juurilasku, this.kasiteltava, this.lemonTranslation.nykyinenKieli)
          this._fileSaverService.saveAs(result, nimi)
          this.dialogRef.close()
        }).catch(err => {
          this.errorHandler.handleError(err)
        })
      }

    }, err => {
      this.errorHandler.handleError(err)
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
