<div class="detail-page">

  <app-asiakas-otsikko otsikko="Kirjanpidon ulkopuoliset vähennykset" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" class="mat-elevation-z3" style="padding: 20px; margin: 20px; position: relative;">
    <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Ulkopuoliset kulut: <a [href]="ulkopuolisetKulutEncodedUriObservable | async" target="_BLANK">{{ulkopuolisetKulutUriObservable | async}}</a></div>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Valitse vuosi</mat-label>
    <mat-select required [formControl]="yearControl">
      <mat-option *ngFor="let year of yearRange" [value]="year">{{year}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="asiakas?.avain">
    <div *ngIf="selectedYear === 2019; else ulkopuoliset" app-ajopaivakirja-lemonator [asiakasAvain]="asiakas.avain"></div>
    <ng-template #ulkopuoliset>
      <div app-ulkopuoliset-kulut [asiakasAvain]="asiakas.avain" [setYear]="selectedYear"></div>
    </ng-template>
  </div>
</div>