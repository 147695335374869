import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ErrorHandler, ChangeDetectorRef } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable } from '@angular/material/table'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { WindowSizeService } from '../../_jaettu-angular/service/window.service'

import { Subject, Observable, of, combineLatest, firstValueFrom } from 'rxjs'
import { debounceTime, takeUntil, switchMap, map, startWith } from 'rxjs/operators'
import { TilitapahtumanTuontityodata, Pankki, Tili } from '../../_jaettu/model/tiliote'
import { DragAndDropService } from '../../_jaettu-angular/service/drag-and-drop.service'
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { LataaCamt053DokuPyynto, LataaCamt053DokuVastaus } from '../../_jaettu/model/tiliote'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TilitapahtumanTiedotDialog } from './tilitapahtuma-tiedot.dialog'
import { TilitapahtumatUusiDataSourceService, ListausTilitapahtuma } from './tilitapahtumat.uusi.datasource.service'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

export type TilitapahtumaProperties = 'kknumero' | 'avain' | 'pvm' | 'summa' | 'saldo' | 'kohdistamatta' | 'lisatiedot' | 'viite' | 'viesti' | 'maksaja' | 'data' | undefined

interface PankkiJaTili {
  tili: Tili
  pankki: Pankki
  formatoituIban: string
}

@Component({
  templateUrl: './tilitapahtumat.component.html',
  styleUrls: ['./tilitapahtumat.component.css']
})
export class TilitapahtumatComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput', { static: true }) fileInput
  @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatTable, { static: true }) table: MatTable<any>

  private ngUnsubscribe = new Subject<void>()

  form: FormGroup

  propertiesToDisplay: TilitapahtumaProperties[] = []
  naytaHuomautus = false
  naytaKaikkiSummat = true
  naytaKohdistukset = false

  kohdistamattaYhteensaObservable: Observable<number>
  yhteensaObservable: Observable<number>

  kohdistamattomiaTilitapahtumiaObservable: Observable<number>

  tilitJaPankitObservable: Observable<PankkiJaTili[]>

  isDragging = false
  latausvirhe = ''
  latausOnnistui = ''
  name = '' + Math.random()

  constructor(
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _windowSizeService: WindowSizeService,
    private _translationService: LemonTranslationService,
    private _dateAdapter: DateAdapter<Date>,
    private _dataSourceService: TilitapahtumatUusiDataSourceService,
    private _asiakasService: AsiakasService,
    private _dndService: DragAndDropService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _currencyService: CurrencyService,
    private _ladataanService: LadataanService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _ibanService: IbanService
  ) {

    this._dataSourceService.rawDataObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(rivit => {
      // NOOP
    })

    this.yhteensaObservable = this.dataSource.connect().pipe(
      map(rivit => {
        // console.log(rivit.length)_
        let yhteensa = 0
        for (const rivi of rivit) {
          // console.log('Lisää ' + rivi.summa + ' ' + (typeof rivi.summa))
          yhteensa += rivi.summa ? Number(rivi.summa) : 0
        }
        return yhteensa
      })
    )

    this.kohdistamattaYhteensaObservable = this.dataSource.connect().pipe(
      map(rivit => {
        let yhteensa = 0
        for (const rivi of rivit) {
          yhteensa += rivi.kohdistamatta ? Number(rivi.kohdistamatta) : 0
        }
        return yhteensa
      })
    )

    const pankitMapObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<Pankki[]>([])
        }
        return this._firebaseLemonaid.firestoreCollection<Pankki>('customers/' + asiakas.avain + '/bank-banks').listen()
      }),
      map(pankit => {
        const pankitMap: Map<string, Pankki> = new Map()
        for (const pankki of pankit) {
          pankitMap.set(pankki.avain, pankki)
        }
        return pankitMap
      })
    )

    const tilitObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<Tili[]>(null as Tili[])
        }
        return this._firebaseLemonaid.firestoreCollection<Tili>('customers/' + asiakas.avain + '/bank-accounts').listen()
      })
    )

    this.tilitJaPankitObservable = combineLatest([pankitMapObservable, tilitObservable]).pipe(
      map(([pankit, tilit]) => {
        if (pankit && tilit) {
          const tulokset: PankkiJaTili[] = []
          for (const tili of tilit) {
            const pankki = pankit.get(tili.pankinAvain)
            if (!pankki) { throw new Error('Pankki ei löytynyt avaimella ' + tili.pankinAvain) }
            const formatoituIban = this._ibanService.formatoiIban(tili.iban)
            tulokset.push({ tili: tili, pankki: pankki, formatoituIban: formatoituIban })
          }
          return tulokset
        }
        return []
      })
    )

  }

  naytaTiedostonValintaDialogi() {
    this.fileInput.nativeElement.click()
  }

  ngOnInit() {

    this.kohdistamattomiaTilitapahtumiaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebaseLemonaid.firestoreCollection<TilitapahtumanTuontityodata>('/tyojono_data/' + asiakas.avain + '/tilitapahtuman_tuonti').listen()
        }
        return of<TilitapahtumanTuontityodata[]>(null as TilitapahtumanTuontityodata[])
      }),
      map(tyot => {
        if (tyot) {
          return tyot.length
        }
        return 0
      }),
      startWith(0)
    )

    this._dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(dragging => {
      this.isDragging = dragging
    })

    // this._sort.sort({
    //   disableClear: true,
    //   id: 'nro',
    //   start: 'desc'
    // })

    this.asetaListauksenKolumnit()

    this._translationService.currentLanguageObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kieli => {
      this._dateAdapter.setLocale(kieli)
    })

    const vapaahakuFormControl = new FormControl<string>(this.dataSource.filter, [])
    const naytaKohdistuksetFormControl = new FormControl<boolean>(this.naytaKohdistukset, [])
    const tiliFormControl = new FormControl<Tili>(null, [])
    const alkaaFormControl = new FormControl<Date>(this._dataSourceService.alkaa, [])
    const loppuuFormControl = new FormControl<Date>(this._dataSourceService.loppuu, [])
    const tulleetTaiLahteneetFormControl = new FormControl<'tulleet' | 'lahteneet'>(this.dataSource.tulleetTaiLahteneet, [])

    this.form = new FormGroup({
      'vapaahaku': vapaahakuFormControl,
      'naytaKohdistukset': naytaKohdistuksetFormControl,
      'tili': tiliFormControl,
      'alkaa': alkaaFormControl,
      'loppuu': loppuuFormControl,
      'tulleetTaiLahteneet': tulleetTaiLahteneetFormControl
    })

    tulleetTaiLahteneetFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.dataSource.tulleetTaiLahteneet = value
    })
    alkaaFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.dataSource.alkaa = value
    })
    loppuuFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.dataSource.loppuu = value
    })
    tiliFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.dataSource.tili = value
    })
    naytaKohdistuksetFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.naytaKohdistukset = !!value
    })
    vapaahakuFormControl.valueChanges.pipe(
      debounceTime(100),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: string) => {
      if (value) {
        const val = value.trim().toLowerCase()
        this.dataSource.filter = val
      } else {
        this.dataSource.filter = null
      }
    })

    const currentSort = this._dataSourceService.sort
    if (currentSort) {
      this.sort.sort({
        id: currentSort.active,
        disableClear: true,
        start: currentSort.direction === 'asc' ? 'asc' : 'desc'
      })
    }
    this._dataSourceService.sort = this.sort

    // const currentSort = this.dataSource.getSort()
    // this.sort.sort({
    //   id: currentSort.active,
    //   disableClear: true,
    //   start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    // })
    // this.sort.sortChange.subscribe(sort => {
    //   this.dataSource.changeSort(sort)
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this._paginator.page.subscribe(page => {
    //   this.dataSource.changePage(page)
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this.route.data.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(( data: { data: SummienRullausData } ) => {

    //   if (data && data.data && data.data.kasiteltava && data.data.juurilasku && data.data.edellinenLaskunTila) {

    //     // console.log('paukutetaan')

    //     // this.dataSource.resetSearchToDefaults()

    //     // setTimeout(() => {

    //       // console.log('rullataan')
    //       // this.laskuSummatService.lisaaLaskunSummat(data.data, false)

    //     // }, 500)

    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

  }


  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.table.dataSource = this.dataSource

    this._windowSizeService.sizeObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(koko => {
      setTimeout(() => {
        this.asetaListauksenKolumnit()
        this._changeDetectorRef.markForCheck()
      }, 250)
    })
  }

  private asetaListauksenKolumnit() {
    const leveys = this.laskutaulu.nativeElement.offsetWidth
    // if (tablenKoko < 500) {
    //   this.propertiesToDisplay = [                     'erapvm',         'avoinna']
    // } else
    // if (leveys < 600) {
    //   this.propertiesToDisplay = ['kknumero', 'pvm', 'summa']
    // } else if (leveys < 700) {
    //   this.propertiesToDisplay = ['kknumero', 'pvm', 'summa', 'viite', 'maksaja']
    // } else if (leveys < 800) {
    //   this.propertiesToDisplay = ['kknumero', 'pvm', 'summa', 'viite', 'maksaja', 'viesti']
    // } else {
    this.propertiesToDisplay = ['kknumero', 'pvm', 'summa', 'saldo', 'kohdistamatta', 'viite', 'maksaja', 'viesti', 'lisatiedot', 'data']
    // }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  onCountoEndAvoinna() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  onCountoEndSaatu() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  onCountoEndEraantynyt() {
    // this.laskuSummatService.asetaSummatAlkuasentoon()
  }

  muokkaa(rivi: ListausTilitapahtuma) {
    this._dialog.open(TilitapahtumanTiedotDialog, { data: rivi })
  }

  // lisaa() {
  //   this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi']).then(result => {
  //     this.vasenValikkoService.paivitaAuki(false)
  //   })
  // }

  get dataSource(): TilitapahtumatUusiDataSourceService {
    return this._dataSourceService
  }

  get lataa(): boolean {
    return this._dataSourceService.lataa
  }

  // async merkitseMaksetuksi(rivi: LaskunListaustietorivi) {
  //   if (rivi.avoinna > 0) {
  //     await this.laskuService.merkitseLaskuTaysinMaksetuksi(rivi)
  //   } else {
  //     const data: LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData = {
  //       rivi: rivi
  //     }
  //     const settings: MatDialogConfig = {
  //       data: data,
  //       panelClass: 'ilman-paddingia',
  //       maxWidth: '1600px'
  //       // ,
  //       // maxHeight: korkeus,
  //       // width: leveys
  //     }
  //     this.dialog.open(LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, settings)
  //   }
  // }

  // avaaKaikkiSummat() {
  //   const data: LaskuSummatKaikkiDialogData = {
  //     summat: this.summat
  //   }
  //   this.dialog.open(LaskuSummatKaikkiDialog, { data: data })
  // }

  // private vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
  //   return Math.round(yksi * 100) === Math.round(kaksi * 100)
  // }

  // private vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
  //   return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  // }

  public fileOver(event) {
    this._dndService.setDragging(true)
  }

  public fileLeave(event) {
    this._dndService.setDragging(true)
  }

  public async fileDrop(entries: NgxFileDropEntry[]): Promise<void> {
    this._dndService.setDragging(false)
    this.lataaTiedosto(entries)
  }

  fileChanged(event) {
    const list: FileList = event.target.files
    const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
    this.lataaTiedosto(tiedostot)
  }

  private async lataaTiedosto(tiedostot: NgxFileDropEntry[]): Promise<any> {

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }

    this._ladataanService.aloitaLataaminen()

    const ladattavaTiedosto = tiedostot[0]

    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    const base64Promise = this._tiedostojenLataamisService.getFile(ladattavaTiedosto.fileEntry as FileSystemFileEntry).then(file => {
      return this._tiedostojenLataamisService.getAsDataUrl(file)
    }).then(dataUrl => {
      const base64Tiedosto = dataUrl.split('base64,')[1]
      return base64Tiedosto
    })

    return Promise.all([asiakasPromise, base64Promise]).then(result => {
      const asiakas = result[0]
      const base64 = result[1]
      const pyynto: LataaCamt053DokuPyynto = {
        asiakasAvain: asiakas.avain,
        asiakasId: asiakas.asiakasId + '',
        base64File: base64
      }
      return this._firebase.functionsCall<LataaCamt053DokuPyynto, LataaCamt053DokuVastaus>('lataaCamt053Doku', pyynto)
    }).then(omatulos => {
      if (!omatulos.onnistui) {
        this.latausvirhe = 'Tiedoston lataamisessa oli virhe. Ota yhteys ylläpitoon.'
      } else {
        this.latausOnnistui = 'Tiedosto ladattiin onnistuneesti: tallennettiin ' + omatulos.tallennettiinPankkeja + ' pankki(a), ' + omatulos.tallennettiinTileja + ' tili(ä) ja ' + omatulos.tallennettiinTapahtumia + ' tilitapahtuma(a).'
      }
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.latausvirhe = 'Tiedoston lataamisessa oli virhe. Ota yhteys ylläpitoon.'
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
    })

  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {

    this.latausvirhe = ''
    this.latausOnnistui = ''

    if (tiedostot.length < 1) {
      this.latausvirhe = 'Annettu syöte ei ole kelvollinen tiedosto.'
      return false
    }

    if (tiedostot.length > 1) {
      this.latausvirhe = 'Voit ladata vain yhden tiedoston kerrallaan.'
      return false
    }

    const supportedImageTypes = ['xml']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this._tiedostojenLataamisService.getFileSize(file) : -1

      // console.log(fileSize, file)

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        this.latausvirhe = 'Tiedoston tyyppi ei ole tuettu. Tuetut tyypit ovat: ' + supportedImageTypes.join(', ') + '.'
        return false
      }

      const maxKoko = 10 * 1024 * 1024
      if (fileSize > maxKoko) {
        const kokoMegatavuissaLokalisoitu = this._currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this._translationService.nykyinenKieli)
        const maxKokoLokalisoitu = this._currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this._translationService.nykyinenKieli)
        this.latausvirhe = 'Ladattava tiedosto voi olla maksimissaan ' + maxKokoLokalisoitu + ' megatavua. (Tiedoston koko oli nyt ' + kokoMegatavuissaLokalisoitu + ' megatavua.)'
        return false
      }

    }

    return true

  }

}
