import { Asiakas, AsiakkaanKirjanpitajanVoimassaolojakso, AsiakkaanMaksutapa, AlvIlmoitusjakso, AlvIlmoitusjaksoAikajaksolla, AsiakkaanKuukausihinta, KirjanpitoKuukausiruutu, Tilikausi, AsiakkaanSopimuskausi, AsiakkaanKuukausiruutukausi, KirjanpidonTyyppi } from '../model/asiakas'
import { DateService } from '../../_shared-core/service/date.service'
import { LocalDate, LocalMonth, NumberDate } from '../../_shared-core/model/common'
import { EI_KIRJANPITAJAA_AVAIN, KirjanpitajanAsiakas, KirjanpitajanAsiakkaat } from '../model/kirjanpitaja'
import { MAKSUTAPA_MYYNTILASKU, MAKSUTAPA_MYYNTITOSITE, MAKSUTAPA_PALKKATOSITE, MAKSUTAPA_TILIOTETOSITE, MAKSUTAPA_MUU, MAKSUTAPA_SAHKOISET_LASKUT, MAKSUTAPA_SAHKOISET_LASKUT_FAKE } from '../../_jaettu/model/kayttaja'
import { KirjauksienLuontityyppi } from '../model/kirjanpito'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { AsiakkaanSopimuskausiService } from './sopimuskausi.service'
import { AsiakasJaettuLemonaidService } from '../../_jaettu/service/asiakas-jaettu-lemonaid.service'

interface AsiakkaanKirjanpitajanVoimassaoloaikajakso extends AsiakkaanKirjanpitajanVoimassaolojakso {
  voimassaoloPaattyy: LocalDate
}

// interface SopimuskausienKuukausiTiedot {
//   count: number
//   aikajaksot: { alkaa: LocalDate, loppuu: LocalDate }[]
// }

export class AsiakkaanMaksutavat {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_SAHKOISET_LASKUT: AsiakkaanMaksutapa = {
    avain: 'maksutapa_sahkoiset_laskut',
    base64Kuva: MAKSUTAPA_SAHKOISET_LASKUT.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_SAHKOISET_LASKUT.nimiListauksessa,
    tunniste: MAKSUTAPA_SAHKOISET_LASKUT.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_SAHKOISET_LASKUT_FAKE: AsiakkaanMaksutapa = {
    avain: 'maksutapa_sahkoiset_laskut_fake',
    base64Kuva: MAKSUTAPA_SAHKOISET_LASKUT_FAKE.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_SAHKOISET_LASKUT_FAKE.nimiListauksessa,
    tunniste: MAKSUTAPA_SAHKOISET_LASKUT_FAKE.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_MUU: AsiakkaanMaksutapa = {
    avain: 'maksutapa_muu',
    base64Kuva: MAKSUTAPA_MUU.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_MUU.nimiListauksessa,
    tunniste: MAKSUTAPA_MUU.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_MYYNTILASKU: AsiakkaanMaksutapa = {
    avain: 'maksutapa_myyntilasku',
    base64Kuva: MAKSUTAPA_MYYNTILASKU.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_MYYNTILASKU.nimiListauksessa,
    tunniste: MAKSUTAPA_MYYNTILASKU.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_MYYNTITOSITE: AsiakkaanMaksutapa = {
    avain: 'maksutapa_myyntitosite',
    base64Kuva: MAKSUTAPA_MYYNTITOSITE.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_MYYNTITOSITE.nimiListauksessa,
    tunniste: MAKSUTAPA_MYYNTITOSITE.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_PALKKATOSITE: AsiakkaanMaksutapa = {
    avain: 'maksutapa_palkkatosite',
    base64Kuva: MAKSUTAPA_PALKKATOSITE.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_PALKKATOSITE.nimiListauksessa,
    tunniste: MAKSUTAPA_PALKKATOSITE.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA_TILIOTETOSITE: AsiakkaanMaksutapa = {
    avain: 'maksutapa_tiliotetosite',
    base64Kuva: MAKSUTAPA_TILIOTETOSITE.base64Kuva,
    kirjauksienLuontityyppi: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE,
    nimi: MAKSUTAPA_TILIOTETOSITE.nimiListauksessa,
    tunniste: MAKSUTAPA_TILIOTETOSITE.tunniste,
    aktiivinen: true,
    oletusVastatili: null,
    sahkoisenTiliotteenTunniste: null,
    tilitapahtumatSynkronoidaanAlkaen: null,
    tilitapahtumatSynkronoidaanLoppuen: null
  }

}

export class AsiakasJaettuService extends AsiakasJaettuLemonaidService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ZEN_TEAM_NUMBER = '010 517 6020'

  constructor(
    dateService: DateService,
    private currencyService: CurrencyService,
    private _sopimuskausiService: AsiakkaanSopimuskausiService
  ) {
    super(dateService)
  }

  public tilikausiOnKalenterivuosi(tilikausi: Tilikausi): boolean {
    return tilikausi.alkaa.day === 1 && tilikausi.alkaa.month === 1 && tilikausi.loppuu.day === 31 && tilikausi.loppuu.month === 12
  }

  /**
   * NOTE! THIS COMPARISON COMPARES ALL THE PROPERTIES SET IN annaUusiKirjanpitoRuutu
   * ALWAYS KEEP THE IMPLEMENTATION IN SYNC WITH THAT!!
   **/
  public compareIfKirjanpitoRuutuBasicDataHasChanges(a: KirjanpitoKuukausiruutu, b: KirjanpitoKuukausiruutu): boolean {

    // Compare existence
    if (!a && !b) {
      return false
    } else if (!a && b) {
      return true
    } else if (a && !b) {
      return true
    }

    // Compare basic data
    if (a.a !== b.a) { return true }
    if (a.k !== b.k) { return true }
    if (a.p !== b.p) { return true }
    if (a.alv !== b.alv) { return true }

    if (a.s !== b.s) { return true }
    if (a.h !== b.h) { return true }
    if (a.l !== b.l) { return true }
    if (a.bot !== b.bot) { return true }
    if (a.bmt !== b.bmt) { return true }

  }

  /**
   * NOTE! IF YOU CHANGE THIS, ALSO UPDATE compareIfKirjanpitoRuutuBasicDataHasChanges TO INCLUDE / EXCLUDE THE FIELDS ADDED OR REMOVED!!!
   */
  public annaUusiKirjanpitoRuutu(asiakas: Asiakas, kuukausi: LocalMonth): KirjanpitoKuukausiruutu {
    // Setup basic values
    const kuukausiAsNumber = this.dateService.localMonthToNumber(kuukausi)
    const ruutu: KirjanpitoKuukausiruutu = {
      a: asiakas.avain,
      k: kuukausiAsNumber,
      p: asiakas.kasittelija,
      alv: AlvIlmoitusjakso.TUNTEMATON
    }
    this.paivitaKirjanpitoRuudunTiedotAsiakkaasta(asiakas, ruutu)
    return ruutu
  }

  public annaTilikausiPaivalle(asiakas: Pick<Asiakas, 'tilikaudet'>, localDate: LocalDate): Tilikausi | null {
    const date = this.dateService.localDateToNumber(localDate)
    return this.annaTilikausiPaivalleNumber(asiakas, date)
  }

  public annaKuukaudenAikanaOlevatAvoimetTilikaudet(asiakas: Asiakas, kuukausi: LocalMonth): Tilikausi[] {
    return asiakas.tilikaudet.filter(tilikausi => {
      if (
        this.dateService.compareLocalMonths(tilikausi.alkaa, '<=', kuukausi) &&
        this.dateService.compareLocalMonths(kuukausi, '<=', tilikausi.loppuu) &&
        !tilikausi.lukittu
      ) {
        return true
      }
      return false
    })
  }

  public annaKuukaudenAikanaOlevatAutomaatiolleAvoimetTilikaudet(asiakas: Asiakas, kuukausi: LocalMonth): Tilikausi[] {
    return asiakas.tilikaudet.filter(tilikausi => {
      // The reasoning behind this algo: https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
      // a.start < b.end && b.start < a.end;
      if (
        this.dateService.compareLocalMonths(tilikausi.alkaa, '<=', kuukausi) &&
        this.dateService.compareLocalMonths(kuukausi, '<=', tilikausi.loppuu) &&
        !tilikausi.automationLocked &&
        !tilikausi.lukittu
      ) {
        return true
      }
      return false
    })
  }

  public annaKuukaudenAikanaOlevatTilikaudet(asiakas: Pick<Asiakas, 'tilikaudet'>, kuukausi: LocalMonth): Tilikausi[] {
    return asiakas.tilikaudet.filter(tilikausi => {
      // The reasoning behind this algo: https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
      // a.start < b.end && b.start < a.end;
      if (
        this.dateService.compareLocalMonths(tilikausi.alkaa, '<=', kuukausi) &&
        this.dateService.compareLocalMonths(kuukausi, '<=', tilikausi.loppuu)
      ) {
        return true
      }
      return false
    })
  }

  /**
   * Palauttaa kaikki tilikaudet, jotka osuvat vähintään yhden päivän annetulle aikavälille.
   */
  public annaAikavalillaOlevatTilikaudet(asiakas: Pick<Asiakas, 'tilikaudet'>, alku: LocalDate, loppu: LocalDate): Tilikausi[] {

    // Missing day means infinity, from times beginning or times end,
    // simulate it by replacing it with year 1 or 9999
    const interval1Start = alku ?? { year: 1, month: 1, day: 1 }
    const interval1End = loppu ?? { year: 9999, month: 12, day: 31 }

    return asiakas.tilikaudet.filter(tilikausi => {
      // The reasoning behind this algo: https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
      // a.start < b.end && b.start < a.end;
      if (
        this.dateService.compareLocalDates(tilikausi.alkaa, '<=', interval1End) &&
        this.dateService.compareLocalDates(interval1Start, '<=', tilikausi.loppuu)
      ) {
        return true
      }
      return false
    })

  }

  public annaKuukaudenAikanaPaattyvatTilikaudet(asiakas: Pick<Asiakas, 'tilikaudet'>, kuukausi: LocalMonth): Tilikausi[] {
    return asiakas.tilikaudet.filter(tilikausi => this.dateService.compareLocalMonths(kuukausi, '==', tilikausi.loppuu))
  }

  public annaKuukausiruutujaksoKuukaudelle(asiakas: Pick<Asiakas, 'kkruutujenKaudet'>, kuukausi: LocalMonth): AsiakkaanKuukausiruutukausi {
    return asiakas.kkruutujenKaudet?.find(kausi => {
      if (kausi.loppuu) {
        return this.dateService.compareLocalMonths(kausi.alkaa, '<=', kuukausi) && this.dateService.compareLocalMonths(kuukausi, '<=', kausi.loppuu)
      }
      return this.dateService.compareLocalMonths(kausi.alkaa, '<=', kuukausi)
    })
  }

  public onkoKuukausiAktiivinen(asiakas: Pick<Asiakas, 'kkruutujenKaudet'>, kuukausi: LocalMonth): boolean {
    return !!this.annaKuukausiruutujaksoKuukaudelle(asiakas, kuukausi)
  }

  public annaTilikausiPaivalleNumber(asiakas: Pick<Asiakas, 'tilikaudet'>, date: NumberDate): Tilikausi | null {
    if (asiakas.tilikaudet) {
      for (const tilikausi of asiakas.tilikaudet) {
        const alkaa = this.dateService.localDateToNumber(tilikausi.alkaa)
        const loppuu = this.dateService.localDateToNumber(tilikausi.loppuu)
        if (alkaa <= date && date <= loppuu) {
          return tilikausi
        }
      }
    }
    return null
  }

  public annaEnsimmainenTilikausi(asiakas: Asiakas): Tilikausi | null {
    if (asiakas.tilikaudet?.length) {
      return asiakas.tilikaudet.reduce((a, b) => this.dateService.compareLocalDates(a.alkaa, '<', b.alkaa) ? a : b)
    }
    return null
  }

  public annaEnsimmainenAvoinTilikausi(asiakas: Asiakas): Tilikausi | null {
    if (asiakas.tilikaudet?.length) {
      let min: Tilikausi = null
      for (const tilikausi of asiakas.tilikaudet) {
        if (tilikausi.lukittu) {
          continue
        }
        if (!min) {
          min = tilikausi
        } else if (this.dateService.compareLocalDates(tilikausi.alkaa, '<', min.alkaa)) {
          min = tilikausi
        }
      }
      return min
    }
    return null
  }

  public annaViimeinenTilikausi(asiakas: Asiakas): Tilikausi | null {
    if (asiakas.tilikaudet?.length) {
      return asiakas.tilikaudet.reduce((a, b) => this.dateService.compareLocalDates(a.loppuu, '>', b.loppuu) ? a : b)
    }
    return null
  }

  /**
 * Returns the previous tilikausi.
 * @param asiakas
 * @param date
 */
  public annaEdellinenTilikausiPaivalle(asiakas: Pick<Asiakas, 'tilikaudet'>, date: LocalDate): Tilikausi | null {
    if (asiakas.tilikaudet) {
      const nykyinen = this.annaTilikausiPaivalle(asiakas, date)
      if (nykyinen) {
        const dayAfterEnd = this.dateService.lisaaPaiviaPaikallinen(nykyinen.loppuu, -1)
        return this.annaTilikausiPaivalle(asiakas, dayAfterEnd)
      }
    }
    return null
  }

  /**
   * Returns the next tilikausi.
   * @param asiakas
   * @param date
   */
  public annaSeuraavaTilikausiPaivalle(asiakas: Asiakas, date: LocalDate): Tilikausi | null {
    if (asiakas.tilikaudet) {
      const nykyinen = this.annaTilikausiPaivalle(asiakas, date)
      if (nykyinen) {
        const dayAfterEnd = this.dateService.lisaaPaiviaPaikallinen(nykyinen.loppuu, 1)
        return this.annaTilikausiPaivalle(asiakas, dayAfterEnd)
      }
    }
    return null
  }

  /**
   * Returns the ending date of the last open tilikausi and the starting date of first open tilikausi.
   * Stops when first locked tilikausi is encountered.
   *
   * Example 1:
   *  Tilikausi 3: 01.01.2023 - 31.12.2023
   *  Tilikausi 2: 01.01.2022 - 31.12.2022 LOCKED
   *  Tilikausi 1: 01.01.2021 - 31.12.2021
   *
   *  return alku: 01.01.2023, loppu: 31.12.2023
   *
   * Example 2:
   *  Tilikausi 3: 01.01.2023 - 31.12.2023
   *  Tilikausi 2: 01.01.2022 - 31.12.2022
   *  Tilikausi 1: 01.01.2021 - 31.12.2021 LOCKED
   *
   *  return alku: 01.01.2022, loppu: 31.12.2023
   *
   * Example 3:
   *  Tilikausi 3: 01.01.2023 - 31.12.2023 LOCKED
   *  Tilikausi 2: 01.01.2022 - 31.12.2022
   *  Tilikausi 1: 01.01.2021 - 31.12.2021
   *
   *  return null
   *
   * @param tilikaudet Asiakkaan tilikaudet
   * @returns the resulting timespan or null if no suitable tilikaudet found
   */
  public annaAikajaksoSelvitettavatRaporttiaVarten(tilikaudet: Tilikausi[]): { alku: NumberDate, loppu: NumberDate } {

    const sorted: Tilikausi[] = tilikaudet.slice().sort((a, b) => {
      return this.dateService.localDateToNumber(b.alkaa) - this.dateService.localDateToNumber(a.alkaa)
    })

    let alkaa: LocalDate = null
    let loppuu: LocalDate = null
    for (const tilikausi of sorted) {

      // console.log(this.dateService.muotoilePaikallinenPaiva(tilikausi.alkaa, 'fi') + ' - ' + this.dateService.muotoilePaikallinenPaiva(tilikausi.loppuu, 'fi'))

      if (tilikausi.lukittu || tilikausi.automationLocked) { break }

      if (loppuu === null) {
        loppuu = tilikausi.loppuu
      }

      alkaa = tilikausi.alkaa
    }

    if (alkaa && loppuu) {
      return { alku: this.dateService.localDateToNumber(alkaa), loppu: this.dateService.localDateToNumber(loppuu) }
    }
    return null

  }

  /**
   * NOTE! IF YOU CHANGE THIS, ALSO UPDATE compareIfKirjanpitoRuutuBasicDataHasChanges TO INCLUDE / EXCLUDE THE FIELDS ADDED OR REMOVED!!!
   */
  public paivitaKirjanpitoRuudunTiedotAsiakkaasta(asiakas: Asiakas, ruutu: KirjanpitoKuukausiruutu) {

    const kuukausi = this.dateService.numberToLocalMonth(ruutu.k)

    // Setup basic values
    ruutu.a = asiakas.avain
    ruutu.p = asiakas.kasittelija

    // Only if asiakas has such
    if (asiakas.kasittelijavara) {
      ruutu.s = asiakas.kasittelijavara
    } else {
      delete ruutu.s
    }

    // Setup price
    const hintaObjekti = this.annaHintaObjektiKuukaudelle(asiakas, kuukausi)
    if (hintaObjekti?.hinta || hintaObjekti?.hinta === 0) {
      ruutu.h = this.currencyService.roundHalfUp(hintaObjekti.hinta, 2)
    } else {
      delete ruutu.h
    }

    // Setup ALV:
    const date = this.dateService.localDateToDate({ year: kuukausi.year, month: kuukausi.month, day: 15 })
    ruutu.alv = this.annaNykyinenAlvIlmoitusjaksoPaivalleIlmanObjektia(asiakas, date) || AlvIlmoitusjakso.TUNTEMATON

    // Setup is ending date
    let poistaOnkoTilikaudenLoppu = true
    let poistaOnkoTilikaudella = true
    let poistaOnkoMurrettuTilikausi = true
    if (asiakas.tilikaudet) {
      for (const tilikausi of asiakas.tilikaudet) {
        const alkaa = this.dateService.localMonthToNumber(tilikausi.alkaa)
        const loppuu = this.dateService.localMonthToNumber(tilikausi.loppuu)
        if (alkaa <= ruutu.k && ruutu.k <= loppuu) {
          poistaOnkoTilikaudella = false
          ruutu.bot = 1
          const onkoMurrettuTilikausi = !(tilikausi.alkaa.day === 1 && tilikausi.alkaa.month === 1 && tilikausi.loppuu.day === 31 && tilikausi.loppuu.month === 12)
          if (onkoMurrettuTilikausi) {
            poistaOnkoMurrettuTilikausi = false
            ruutu.bmt = 1
          }
        }
        if (tilikausi.loppuu.year === kuukausi.year && tilikausi.loppuu.month === kuukausi.month) {
          ruutu.l = tilikausi.loppuu.day
          poistaOnkoTilikaudenLoppu = false
        }
      }
    }

    if (poistaOnkoTilikaudenLoppu) {
      delete ruutu.l
    }

    if (poistaOnkoTilikaudella) {
      delete ruutu.bot
    }

    if (poistaOnkoMurrettuTilikausi) {
      delete ruutu.bmt
    }

  }

  public annaHintaKuukaudelleJaSeuraavaHinta(asiakas: Pick<Asiakas, 'hinnat'>, kuukausi: LocalMonth): { voimassaOleva: AsiakkaanKuukausihinta, seuraava: AsiakkaanKuukausihinta } {
    const nykyinenHinta = this.annaHintaObjektiKuukaudelle(asiakas, kuukausi)
    if (asiakas && asiakas.hinnat) {
      let loytynytHinta: AsiakkaanKuukausihinta = null
      for (const hinta of asiakas.hinnat) {
        if (this.dateService.compareLocalMonths(hinta.voimassaAlkaen, '>', kuukausi)) {
          if (loytynytHinta === null) {
            loytynytHinta = hinta
          } else {
            if (this.dateService.compareLocalMonths(hinta.voimassaAlkaen, '<', loytynytHinta.voimassaAlkaen)) {
              loytynytHinta = hinta
            }
          }
        }
      }
      return { voimassaOleva: nykyinenHinta, seuraava: loytynytHinta }
    }
    return { voimassaOleva: nykyinenHinta, seuraava: null }
  }

  public annaNykyinenHintaObjekti(asiakas: Pick<Asiakas, 'hinnat'>): AsiakkaanKuukausihinta | null {
    return this.annaHintaObjektiKuukaudelle(asiakas, this.dateService.currentLocalMonth())
  }

  // public annaHintaObjektiPaivalle(asiakas: Pick<Asiakas, 'hinnat'>, paiva: Date): AsiakkaanKuukausihinta | null {
  //   if (asiakas && asiakas.hinnat) {
  //     return this.annaHintaObjektiPaivalleListalta(asiakas.hinnat, paiva)
  //   }
  //   return null
  // }

  public annaHintaObjektiKuukaudelle(asiakas: Pick<Asiakas, 'hinnat'>, kuukausi: LocalMonth): AsiakkaanKuukausihinta | null {
    if (asiakas && asiakas.hinnat) {
      return this._annaHintaObjektiKuukaudelleListalta(asiakas.hinnat, kuukausi)
    }
    return null
  }

  public annaHintaKuukaudelleJosEiTauollaJaSopimusVoimassa(asiakas: Pick<Asiakas, 'hinnat' | 'sopimuskaudet'>, kuukausi: LocalMonth): AsiakkaanKuukausihinta | null {
    if (!asiakas || !asiakas.sopimuskaudet) {
      return null
    }
    const sopimusVoimassa = this.onkoSopimusVoimassaAinakinYhdenPaivanKuukaudessa(asiakas.sopimuskaudet, kuukausi)
    if (!sopimusVoimassa) {
      return null
    }
    const alku: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
    const loppu = this.dateService.kuukaudenViimeinenPaikallinen(alku)
    const tauko = this._sopimuskausiService.annaKokoAikavalinVoimassaOlevaTauko(asiakas, alku, loppu)
    if (tauko) {
      const h: AsiakkaanKuukausihinta = {
        hinta: 0,
        muutoksenSyy: tauko.tyyppi === 'poytalaatikko' ? 'Pöytälaatikossa' : 'Tauolla',
        muutosPvm: null,
        voimassaAlkaen: tauko.alkaa
      }
      return h
    }
    return this._annaHintaObjektiKuukaudelleListalta(asiakas.hinnat, kuukausi)
  }

  public annaHintaJosEiTauollaJaSopimusVoimassaPaivalla(asiakas: Pick<Asiakas, 'hinnat' | 'sopimuskaudet'>, date: LocalDate): AsiakkaanKuukausihinta | null {
    if (!asiakas || !asiakas.sopimuskaudet) {
      return null
    }
    const sopimuskausi = this._sopimuskausiService.annaKausiPaivamaaralle(asiakas.sopimuskaudet, date)

    const sopimusVoimassa = this._sopimuskausiService.onkoSopimuskausiVoimassa(sopimuskausi, date)
    if (!sopimusVoimassa) {
      return null
    }
    return this._annaHintaObjektiKuukaudelleListalta(asiakas.hinnat, date)
  }

  // public annaHintaObjektiPaivalleListalta(hinnat: AsiakkaanKuukausihinta[], paiva: Date): AsiakkaanKuukausihinta | null {
  //   return this.annaHintaObjektiLokaaliPaivalleListalta(hinnat, this.dateService.dateToLocalMonth(paiva))
  // }

  private _annaHintaObjektiKuukaudelleListalta(hinnat: AsiakkaanKuukausihinta[], kuukausi: LocalMonth): AsiakkaanKuukausihinta | null {
    if (hinnat) {
      let loytynytHinta: AsiakkaanKuukausihinta = null
      for (const hinta of hinnat) {
        if (this.dateService.compareLocalMonths(hinta.voimassaAlkaen, '<=', kuukausi)) {
          if (loytynytHinta === null) {
            // console.log('Valittiin', hinta)
            loytynytHinta = hinta
          } else {
            if (this.dateService.compareLocalDates(hinta.voimassaAlkaen, '>', loytynytHinta.voimassaAlkaen)) {
              // console.log('Valittiin', hinta, 'vanha', loytynytHinta)
              loytynytHinta = hinta
            }
          }
        }
      }
      return loytynytHinta
    }
    return null
  }

  public annaNykyinenJaSeuraavaHinta(asiakas: Pick<Asiakas, 'hinnat'>): { nykyinen: AsiakkaanKuukausihinta, seuraava: AsiakkaanKuukausihinta } {
    // if (asiakas.asiakasId === 9) { console.log(asiakas.hinnat) }
    const nykyinenHinta = this.annaNykyinenHintaObjekti(asiakas)
    if (asiakas && asiakas.hinnat) {
      // if (asiakas.asiakasId === 9) { console.log(asiakas.hinnat) }

      // The price is in effect for the full month.
      // The next price thus begins the first of next month.
      const now = this.dateService.currentLocalDate()
      now.day = 1
      const firstOfNextMonth = this.dateService.lisaaKuukausiaPaikallinen(now, 1)

      let loytynytHinta: AsiakkaanKuukausihinta = null
      for (const hinta of asiakas.hinnat) {
        if (this.dateService.compareLocalDates(hinta.voimassaAlkaen, '<', firstOfNextMonth)) {
          continue
        }
        if (loytynytHinta === null) {
          // console.log('Valittiin', hinta)
          loytynytHinta = hinta
        } else if (this.dateService.compareLocalDates(hinta.voimassaAlkaen, '<', loytynytHinta.voimassaAlkaen)) {
          // console.log('Valittiin', hinta, 'vanha', loytynytHinta)
          loytynytHinta = hinta
        }
      }
      return { nykyinen: nykyinenHinta, seuraava: loytynytHinta }
    }
    return { nykyinen: nykyinenHinta, seuraava: null }
  }

  public annaNykyinenAlvIlmoitusjakso(asiakas: Asiakas): AlvIlmoitusjakso | null {
    const loytynytJakso = this.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, new Date())
    return loytynytJakso ? loytynytJakso.alvIlmoitusjakso : null
  }

  public annaNykyinenAlvIlmoitusjaksoPaivalleIlmanObjektia(asiakas: Asiakas, paiva: Date): AlvIlmoitusjakso | null {
    const loytynytJakso = this.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, paiva)
    return loytynytJakso ? loytynytJakso.alvIlmoitusjakso : null
  }

  public annaNykyinenAlvIlmoitusjaksoObjekti(asiakas: Asiakas): AlvIlmoitusjaksoAikajaksolla | null {
    return this.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, new Date())
  }

  public annaSeuraavaAlvIlmoitusjakso(asiakas: Asiakas, j: AlvIlmoitusjaksoAikajaksolla): AlvIlmoitusjaksoAikajaksolla | null {
    if (asiakas && asiakas.alvIlmoitusjaksot) {
      let loytynytJakso: AlvIlmoitusjaksoAikajaksolla = null
      const paiva = this.dateService.localDateToDate(j.voimassaAlkaen)
      for (const jakso of asiakas.alvIlmoitusjaksot) {
        const jaksonPvm = this.dateService.localDateToDate(jakso.voimassaAlkaen)
        if (this.dateService.kuukausiaValissa(jaksonPvm, paiva) > 0) {
          if (loytynytJakso === null) {
            // console.log('Valittiin', jakso)
            loytynytJakso = jakso
          } else {
            const loytyneenPvm = this.dateService.localDateToDate(loytynytJakso.voimassaAlkaen)
            if (this.dateService.paiviaValissa(jaksonPvm, loytyneenPvm) < 0) {
              // console.log('Valittiin', jakso, 'vanha', loytynytJakso)
              loytynytJakso = jakso
            }
          }
        }
      }
      return loytynytJakso
    }
    return null
  }

  public annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas: Asiakas, paiva: Date): AlvIlmoitusjaksoAikajaksolla | null {
    if (asiakas && asiakas.alvIlmoitusjaksot) {
      let loytynytJakso: AlvIlmoitusjaksoAikajaksolla = null
      for (const jakso of asiakas.alvIlmoitusjaksot) {
        const jaksonPvm = this.dateService.localDateToDate(jakso.voimassaAlkaen)
        if (this.dateService.kuukausiaValissa(jaksonPvm, paiva) < 1) {
          if (loytynytJakso === null) {
            // console.log('Valittiin', jakso)
            loytynytJakso = jakso
          } else {
            const loytyneenPvm = this.dateService.localDateToDate(loytynytJakso.voimassaAlkaen)
            if (this.dateService.paiviaValissa(jaksonPvm, loytyneenPvm) > 0) {
              // console.log('Valittiin', jakso, 'vanha', loytynytJakso)
              loytynytJakso = jakso
            }
          }
        }
      }
      return loytynytJakso
    }
    return null
  }

  public ovatkoAsiakkaanAlvIlmoitusjaksotMuuttuneet(before: AlvIlmoitusjaksoAikajaksolla[], after: AlvIlmoitusjaksoAikajaksolla[]) {
    if (!before || !after || before.length !== after.length) {
      return true
    }
    const beforeOrdered = before.sort((a, b) => a?.muutosPvm?.toMillis() - b?.muutosPvm?.toMillis())
    const afterOrdered = before.sort((a, b) => a?.muutosPvm?.toMillis() - b?.muutosPvm?.toMillis())

    for (const [i, jaksoBefore] of beforeOrdered.entries()) {

      const jaksoAfter = afterOrdered[i]

      if (jaksoBefore?.alvIlmoitusjakso !== jaksoAfter?.alvIlmoitusjakso) {
        return true
      }

      if (!jaksoBefore?.voimassaAlkaen ||
        !jaksoAfter?.voimassaAlkaen ||
        this.dateService.compareLocalDates(jaksoBefore.voimassaAlkaen, '!=', jaksoAfter?.voimassaAlkaen)) {

        return true
      }
    }
  }

  public annaNykyinenVarakirjanpitaja(asiakas: Pick<Asiakas, 'kirjanpitajatvara'>): AsiakkaanKirjanpitajanVoimassaolojakso | null {
    return this.annaVarakirjanpitajaPaivalle(asiakas, this.dateService.dateToLocalDate(new Date()))
  }

  public annaVarakirjanpitajaPaivalle(asiakas: Pick<Asiakas, 'kirjanpitajatvara'>, date: LocalDate): AsiakkaanKirjanpitajanVoimassaolojakso | null {
    if (asiakas && asiakas.kirjanpitajatvara) {
      return this.annaKirjanpitajaPaivalle(asiakas.kirjanpitajatvara, date)
    }
    return null
  }

  public annaNykyinenVastuukirjanpitaja(asiakas: Pick<Asiakas, 'kirjanpitajat'>): AsiakkaanKirjanpitajanVoimassaolojakso | null {
    return this.annaVastuukirjanpitajaPaivalle(asiakas, this.dateService.dateToLocalDate(new Date()))
  }

  public annaVastuukirjanpitajaPaivalle(asiakas: Pick<Asiakas, 'kirjanpitajat'>, date: LocalDate): AsiakkaanKirjanpitajanVoimassaolojakso | null {
    if (asiakas && asiakas.kirjanpitajat) {
      return this.annaKirjanpitajaPaivalle(asiakas.kirjanpitajat, date)
    }
    return null
  }
  public olikoHolviAsiakasAnnettunaPaivana(asiakas: Pick<Asiakas, 'kirjanpitajat'>, date: LocalDate): boolean {
    const vastuuKirjanpitaja = this.annaVastuukirjanpitajaPaivalle(asiakas, date)
    return vastuuKirjanpitaja && vastuuKirjanpitaja.kirjanpitajanAvain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' // Herra Holvi
  }
  // Checks whether customer had Taneli Testinen as the main accountant AKA if the customer is no longer active in Lemonator
  public olikoTaneliTestisenAsiakasAnnettunaPaivana(asiakas: Pick<Asiakas, 'kirjanpitajat'>, date: LocalDate): boolean {
    const vastuuKirjanpitaja = this.annaVastuukirjanpitajaPaivalle(asiakas, date)
    return vastuuKirjanpitaja && vastuuKirjanpitaja.kirjanpitajanAvain === 'uekRGWgeLqecXk0Gh8el0s85SEt1' // Taneli Testinen
  }
  public olikoMinduuAsiakasAnnettunaPaivana(asiakas: Pick<Asiakas, 'kirjanpitajat'>, date: LocalDate): boolean {
    const vastuukirjanpitaja = this.annaVastuukirjanpitajaPaivalle(asiakas, date)
    return vastuukirjanpitaja && (
      vastuukirjanpitaja.kirjanpitajanAvain === 'ZHx7TJeK5xc159g2O82VTfa2QRz2' || // Eva Hakola
      vastuukirjanpitaja.kirjanpitajanAvain === 'h279Xe3JnVMBLmtBuOSMeOQvu2K3' // Johanna Kristo
    )
  }

  private annaKirjanpitajaPaivalle(kirjanpitajat: AsiakkaanKirjanpitajanVoimassaolojakso[], date: LocalDate): AsiakkaanKirjanpitajanVoimassaolojakso | null {
    if (kirjanpitajat) {
      let loytynytKirjanpitaja: AsiakkaanKirjanpitajanVoimassaolojakso = null
      for (const kirjanpitaja of kirjanpitajat) {
        // const alkaa = this.dateService.muotoilePaikallinenPaiva(kirjanpitaja.voimassaoloAlkaa, 'fi')
        // const alkaa2 = this.dateService.muotoilePaikallinenPaiva(date, 'fi')
        // console.log(alkaa, alkaa2, this.dateService.paiviaValissaPaikallinen(kirjanpitaja.voimassaoloAlkaa, date))
        if (this.dateService.compareLocalDates(kirjanpitaja.voimassaoloAlkaa, '<=', date)) {
          if (loytynytKirjanpitaja === null) {
            // console.log('Valittiin', kirjanpitaja)
            loytynytKirjanpitaja = kirjanpitaja
          } else {
            if (this.dateService.compareLocalDates(kirjanpitaja.voimassaoloAlkaa, '>', loytynytKirjanpitaja.voimassaoloAlkaa)) {
              // console.log('Valittiin', kirjanpitaja, 'vanha', loytynytKirjanpitaja)
              loytynytKirjanpitaja = kirjanpitaja
            }
          }
        }
      }
      return loytynytKirjanpitaja
    }
    return null
  }

  public paivitaKirjanpitajanAsiakkuuksienVoimassaoloaikajaksot(asiakas: Asiakas, kirjanpitajienAsiakkaat: KirjanpitajanAsiakkaat[], jaksot: AsiakkaanKirjanpitajanVoimassaoloaikajakso[], varajaksot: AsiakkaanKirjanpitajanVoimassaoloaikajakso[]): KirjanpitajanAsiakkaat[] {

    const tallennettavatKirjanpitajatMap: { [kirjanpitajanAvain: string]: KirjanpitajanAsiakkaat } = {}

    for (const jakso of jaksot) {
      if (jakso.kirjanpitajanAvain !== EI_KIRJANPITAJAA_AVAIN) {
        this.paivitaKirjanpitajanAsiakkuuksienVoimassaolojakso(asiakas, kirjanpitajienAsiakkaat, jakso, tallennettavatKirjanpitajatMap)
      }
    }

    for (const jakso of varajaksot) {
      if (jakso.kirjanpitajanAvain !== EI_KIRJANPITAJAA_AVAIN) {
        this.paivitaKirjanpitajanVaraasiakkuuksienVoimassaolojakso(asiakas, kirjanpitajienAsiakkaat, jakso, tallennettavatKirjanpitajatMap)
      }
    }

    const tallennettavatKirjanpitajat = Object.keys(tallennettavatKirjanpitajatMap).map(key => {
      return tallennettavatKirjanpitajatMap[key]
    })

    // Järjestä kirjanpitajan asiakkuudet a) asiakkaan mukaan b) alkamisajan mukaan
    for (const kirjanpitaja of tallennettavatKirjanpitajat) {
      if (kirjanpitaja.kirjanpitajanAsiakkaat) {
        kirjanpitaja.kirjanpitajanAsiakkaat = kirjanpitaja.kirjanpitajanAsiakkaat.sort((a, b) => {
          if (a.asiakasAvain === b.asiakasAvain) {
            return this.dateService.paiviaValissaPaikallinen(a.voimassaoloAlkaa, b.voimassaoloAlkaa)
          }
          return a.asiakasAvain.localeCompare(b.asiakasAvain)
        })
      }
      if (kirjanpitaja.kirjanpitajanAsiakkaatVara) {
        kirjanpitaja.kirjanpitajanAsiakkaatVara = kirjanpitaja.kirjanpitajanAsiakkaatVara.sort((a, b) => {
          if (a.asiakasAvain === b.asiakasAvain) {
            return this.dateService.paiviaValissaPaikallinen(a.voimassaoloAlkaa, b.voimassaoloAlkaa)
          }
          return a.asiakasAvain.localeCompare(b.asiakasAvain)
        })
      }
    }

    return tallennettavatKirjanpitajat

  }

  private paivitaKirjanpitajanVaraasiakkuuksienVoimassaolojakso(asiakas: Asiakas, kirjanpitajienAsiakkaat: KirjanpitajanAsiakkaat[], jakso: AsiakkaanKirjanpitajanVoimassaoloaikajakso, tallennettavatKirjanpitajatMap: { [kirjanpitajanAvain: string]: KirjanpitajanAsiakkaat }) {

    // Etsi kirjanpitäjä jaksolle
    let kirjanpitaja: KirjanpitajanAsiakkaat = null
    for (const k of kirjanpitajienAsiakkaat) {
      if (k.avain === jakso.kirjanpitajanAvain) {
        kirjanpitaja = k
        break
      }
    }

    if (!kirjanpitaja) {
      console.error(jakso)
      throw new Error('Jaksolle ei löytynyt kirjanpitajaa!!')
    }

    if (!kirjanpitaja.kirjanpitajanAsiakkaatVara) {
      kirjanpitaja.kirjanpitajanAsiakkaatVara = []
    }

    // Etsi onko jakso jo olemassa
    let kirjanpitajanAsiakas: KirjanpitajanAsiakas = null
    for (const k of kirjanpitaja.kirjanpitajanAsiakkaatVara) {
      if (
        k.asiakasAvain === asiakas.avain &&
        this.dateService.paiviaValissaPaikallinen(k.voimassaoloAlkaa, jakso.voimassaoloAlkaa) === 0
      ) {
        kirjanpitajanAsiakas = k
        break
      }
    }

    if (kirjanpitajanAsiakas) {
      if (
        (!kirjanpitajanAsiakas.voimassaoloPaattyy && !jakso.voimassaoloPaattyy) || // Molemmat määrittämättä
        this.dateService.paiviaValissaPaikallinen(kirjanpitajanAsiakas.voimassaoloPaattyy, jakso.voimassaoloPaattyy) === 0 // Samana päivänä
      ) {
        // NOOP
      } else {
        kirjanpitajanAsiakas.voimassaoloPaattyy = jakso.voimassaoloPaattyy
        tallennettavatKirjanpitajatMap[kirjanpitaja.avain] = kirjanpitaja
      }
    } else {
      kirjanpitajanAsiakas = {
        asiakasAvain: asiakas.avain,
        voimassaoloAlkaa: jakso.voimassaoloAlkaa,
        voimassaoloPaattyy: jakso.voimassaoloPaattyy
      }
      kirjanpitaja.kirjanpitajanAsiakkaatVara.push(kirjanpitajanAsiakas)
      tallennettavatKirjanpitajatMap[kirjanpitaja.avain] = kirjanpitaja
    }

  }

  private paivitaKirjanpitajanAsiakkuuksienVoimassaolojakso(asiakas: Asiakas, kirjanpitajienAsiakkaat: KirjanpitajanAsiakkaat[], jakso: AsiakkaanKirjanpitajanVoimassaoloaikajakso, tallennettavatKirjanpitajatMap: { [kirjanpitajanAvain: string]: KirjanpitajanAsiakkaat }) {

    // Etsi kirjanpitäjä jaksolle
    let kirjanpitaja: KirjanpitajanAsiakkaat = null
    for (const k of kirjanpitajienAsiakkaat) {
      if (k.avain === jakso.kirjanpitajanAvain) {
        kirjanpitaja = k
        break
      }
    }

    // TODO: HOIDA EI KIRJANPITÄJÄÄ KIRJANPITÄJÄ!!!
    if (!kirjanpitaja) {
      console.error(jakso)
      throw new Error('Jaksolle ei löytynyt kirjanpitajaa!!')
    }

    if (!kirjanpitaja.kirjanpitajanAsiakkaat) {
      kirjanpitaja.kirjanpitajanAsiakkaat = []
    }

    // Etsi onko jakso jo olemassa
    let kirjanpitajanAsiakas: KirjanpitajanAsiakas = null
    for (const k of kirjanpitaja.kirjanpitajanAsiakkaat) {
      if (
        k.asiakasAvain === asiakas.avain &&
        this.dateService.paiviaValissaPaikallinen(k.voimassaoloAlkaa, jakso.voimassaoloAlkaa) === 0
      ) {
        kirjanpitajanAsiakas = k
        break
      }
    }

    if (kirjanpitajanAsiakas) {
      if (
        (!kirjanpitajanAsiakas.voimassaoloPaattyy && !jakso.voimassaoloPaattyy) || // Molemmat määrittämättä
        this.dateService.paiviaValissaPaikallinen(kirjanpitajanAsiakas.voimassaoloPaattyy, jakso.voimassaoloPaattyy) === 0 // Samana päivänä
      ) {
        // NOOP
      } else {
        kirjanpitajanAsiakas.voimassaoloPaattyy = jakso.voimassaoloPaattyy
        tallennettavatKirjanpitajatMap[kirjanpitaja.avain] = kirjanpitaja
      }
    } else {
      kirjanpitajanAsiakas = {
        asiakasAvain: asiakas.avain,
        voimassaoloAlkaa: jakso.voimassaoloAlkaa,
        voimassaoloPaattyy: jakso.voimassaoloPaattyy
      }
      kirjanpitaja.kirjanpitajanAsiakkaat.push(kirjanpitajanAsiakas)
      tallennettavatKirjanpitajatMap[kirjanpitaja.avain] = kirjanpitaja
    }

  }

  public puraAsiakkaanKirjanpitajatVoimassaoloaikajaksoihin(asiakas: Asiakas): AsiakkaanKirjanpitajanVoimassaoloaikajakso[] {
    if (asiakas.kirjanpitajat) {
      return this.puraAsiakkaanKirjanpitajatVoimassaoloaikajaksoihinInternal(asiakas, asiakas.kirjanpitajat)
    }
    return []
  }

  public puraAsiakkaanVarakirjanpitajatVoimassaoloaikajaksoihin(asiakas: Asiakas): AsiakkaanKirjanpitajanVoimassaoloaikajakso[] {
    if (asiakas.kirjanpitajatvara) {
      return this.puraAsiakkaanKirjanpitajatVoimassaoloaikajaksoihinInternal(asiakas, asiakas.kirjanpitajatvara)
    }
    return []
  }

  private puraAsiakkaanKirjanpitajatVoimassaoloaikajaksoihinInternal(asiakas: Asiakas, kirjanpitajat: AsiakkaanKirjanpitajanVoimassaolojakso[]): AsiakkaanKirjanpitajanVoimassaoloaikajakso[] {
    const jaksot: AsiakkaanKirjanpitajanVoimassaoloaikajakso[] = []

    if (kirjanpitajat) {
      const jarjestetty = kirjanpitajat.sort((a, b) => {
        if (
          a.voimassaoloAlkaa.year === b.voimassaoloAlkaa.year &&
          a.voimassaoloAlkaa.month === b.voimassaoloAlkaa.month &&
          a.voimassaoloAlkaa.day === b.voimassaoloAlkaa.day
        ) {
          console.error(asiakas, a, b)
          throw new Error('Kahdella jaksolla on SAMA alkamispäivä!!')
        }
        if (a.voimassaoloAlkaa.year === b.voimassaoloAlkaa.year && a.voimassaoloAlkaa.month === b.voimassaoloAlkaa.month) {
          return a.voimassaoloAlkaa.day - b.voimassaoloAlkaa.day
        }
        if (a.voimassaoloAlkaa.year === b.voimassaoloAlkaa.year) {
          return a.voimassaoloAlkaa.month - b.voimassaoloAlkaa.month
        }
        return a.voimassaoloAlkaa.year - b.voimassaoloAlkaa.year
      })
      let edellinen: AsiakkaanKirjanpitajanVoimassaoloaikajakso | null = null
      for (const kirjanpitaja of jarjestetty) {
        const tamaJakso: AsiakkaanKirjanpitajanVoimassaoloaikajakso = {
          kirjanpitajanAvain: kirjanpitaja.kirjanpitajanAvain,
          voimassaoloAlkaa: kirjanpitaja.voimassaoloAlkaa,
          voimassaoloPaattyy: null
        }
        if (edellinen) {
          edellinen.voimassaoloPaattyy = this.dateService.lisaaPaiviaPaikallinen(tamaJakso.voimassaoloAlkaa, -1)
          jaksot.push(edellinen)
        }
        edellinen = tamaJakso
      }
      if (edellinen) {
        jaksot.push(edellinen)
      }
    }

    return jaksot
  }

  public etsiMaksutapa(tunniste: string, maksutavat: AsiakkaanMaksutapa[]): AsiakkaanMaksutapa {
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_MUU.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_MUU
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_MYYNTILASKU.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_MYYNTILASKU
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_MYYNTITOSITE.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_MYYNTITOSITE
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_PALKKATOSITE.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_PALKKATOSITE
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_TILIOTETOSITE.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_TILIOTETOSITE
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT
    }
    if (tunniste + '' === AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT_FAKE.tunniste + '') {
      return AsiakkaanMaksutavat.MAKSUTAPA_SAHKOISET_LASKUT_FAKE
    }
    if (maksutavat) {
      for (const maksutapa of maksutavat) {
        if (tunniste + '' === maksutapa.tunniste + '') {
          return maksutapa
        }
      }
    }
    return null
  }

  public annaKirjanpidonTyyppi(asiakas: Pick<Asiakas, 'avain' | 'kirjanpidonTyypit'>, date: LocalDate): KirjanpidonTyyppi {
    if (asiakas.kirjanpidonTyypit === undefined || asiakas.kirjanpidonTyypit === null) {
      return KirjanpidonTyyppi.KAHDENKERTAINEN
    }
    for (const tyyppi of asiakas.kirjanpidonTyypit) {
      if (
        (!tyyppi.alkaa || this.dateService.compareLocalDates(tyyppi.alkaa, '<=', date)) &&
        (!tyyppi.loppuu || this.dateService.compareLocalDates(date, '<=', tyyppi.loppuu))
      ) {
        return tyyppi.tyyppi
      }
    }
    throw new Error('Kirjanpidon tyyppiä ei löytynyt asiakkaalle ' + asiakas.avain)
  }

  public onkoSopimusVoimassaAinakinYhdenPaivanKuukaudessa(sopimuskaudet: AsiakkaanSopimuskausi[], kuukausi: LocalMonth): boolean {
    if (!sopimuskaudet) { return false }
    for (const sopimuskausi of sopimuskaudet) {
      if (
        this.dateService.compareLocalMonths(sopimuskausi.alkaa, '<=', kuukausi) &&
        (
          !sopimuskausi.loppuu ||
          this.dateService.compareLocalMonths(kuukausi, '<=', sopimuskausi.loppuu)
        )
      ) {
        return true
      }
    }
    return false
  }

  // public annaKuukaudenPaivienLukumaaraJolloinSopimusOnVoimassa(sopimuskaudet: AsiakkaanSopimuskausi[], kuukausi: LocalMonth): SopimuskausienKuukausiTiedot {
  //   const paivienMaara = this.dateService.paiviaKuukaudessaPaikallinenKuukausi(kuukausi)
  //   const days: boolean[] = new Array(paivienMaara).fill(false)
  //   const result: SopimuskausienKuukausiTiedot = {
  //     count: paivienMaara,
  //     aikajaksot: []
  //   }
  //   for (const sopimuskausi of sopimuskaudet ?? []) {
  //     if (this.dateService.compareLocalMonths(sopimuskausi.alkaa, '<', kuukausi)) {

  //       // Sopimuskausi on alkanut aikaisemmin kuin ja on toistaiseksi voimassa oleva _tai_ päättyy myöhemmin
  //       if (!sopimuskausi.loppuu || this.dateService.compareLocalMonths(sopimuskausi.loppuu, '>', kuukausi)) {
  //         const eka: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
  //         result.aikajaksot.push({ alkaa: eka, loppuu: this.dateService.kuukaudenViimeinenPaikallinen(eka) })
  //         result.count = paivienMaara
  //         return result
  //       }

  //       // Sopimuskausi on alkanut aikaisemmin ja päättyy tässä kuussa
  //       if (this.dateService.compareLocalMonths(sopimuskausi.loppuu, '==', kuukausi)) {
  //         const eka: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
  //         result.aikajaksot.push({ alkaa: eka, loppuu: sopimuskausi.loppuu })
  //         for (let i = 1; i <= sopimuskausi.loppuu.day; i++) {
  //           days[i] = true
  //         }
  //       }

  //     } else if (this.dateService.compareLocalMonths(sopimuskausi.alkaa, '==', kuukausi)) {

  //       // Sopimuskausi alkaa tässä kuussa ja on toistaiseksi voimassa oleva _tai_ päättyy myöhemmin
  //       if (!sopimuskausi.loppuu || this.dateService.compareLocalMonths(sopimuskausi.loppuu, '>', kuukausi)) {
  //         result.aikajaksot.push({ alkaa: sopimuskausi.alkaa, loppuu: this.dateService.kuukaudenViimeinenPaikallinen(sopimuskausi.alkaa) })
  //         for (let i = sopimuskausi.alkaa.day; i <= paivienMaara; i++) {
  //           days[i] = true
  //         }
  //       }

  //       // Sopimuskausi alkaa ja päättyy tässä kuussa
  //       if (this.dateService.compareLocalMonths(sopimuskausi.loppuu, '==', kuukausi)) {
  //         result.aikajaksot.push({ alkaa: sopimuskausi.alkaa, loppuu: sopimuskausi.loppuu })
  //         for (let i = sopimuskausi.alkaa.day; i <= sopimuskausi.loppuu.day; i++) {
  //           days[i] = true
  //         }
  //       }

  //     }
  //   }
  //   result.count = days.filter(day => day).length
  //   return result
  // }

  // public annaVoimassolevatSopimuskaudetKuukaudelle(sopimuskaudet: AsiakkaanSopimuskausi[], kuukausi: LocalMonth): AsiakkaanSopimuskausi[] {
  //   const result: AsiakkaanSopimuskausi[] = []
  //   for (const sopimuskausi of sopimuskaudet ?? []) {
  //     if (this.dateService.compareLocalMonths(sopimuskausi.alkaa, '==', kuukausi)) {
  //       result.push(sopimuskausi)
  //     } else if (
  //       this.dateService.compareLocalMonths(sopimuskausi.alkaa, '<', kuukausi) &&
  //       (
  //         !sopimuskausi.loppuu ||
  //         this.dateService.compareLocalMonths(sopimuskausi.loppuu, '==', kuukausi) ||
  //         this.dateService.compareLocalMonths(sopimuskausi.loppuu, '>', kuukausi)
  //       )
  //     ) {
  //       result.push(sopimuskausi)
  //     }
  //   }
  //   return result
  // }

  public annaNykyinenHinta(asiakas: Pick<Asiakas, 'hinnat'>): number | null {
    const loytynytHinta = this.annaNykyinenHintaObjekti(asiakas)
    return loytynytHinta ? loytynytHinta.hinta : null
  }

  public annaNykyinenTaiSeuraavaHinta(asiakas: Pick<Asiakas, 'hinnat'>): AsiakkaanKuukausihinta | null {
    // if (asiakas.asiakasId === 9) { console.log(asiakas.hinnat) }
    const nykyinenHinta = this.annaNykyinenHintaObjekti(asiakas)
    if (nykyinenHinta) {
      return nykyinenHinta
    }
    if (asiakas && asiakas.hinnat) {
      // if (asiakas.asiakasId === 9) { console.log(asiakas.hinnat) }
      const nyt = new Date()
      let loytynytHinta: AsiakkaanKuukausihinta = null
      for (const hinta of asiakas.hinnat) {
        const hinnanPvm = this.dateService.localDateToDate(hinta.voimassaAlkaen)
        if (this.dateService.kuukausiaValissa(hinnanPvm, nyt) < 1) {

        } else {
          if (loytynytHinta === null) {
            // console.log('Valittiin', hinta)
            loytynytHinta = hinta
          } else {
            const loytyneenPvm = this.dateService.localDateToDate(loytynytHinta.voimassaAlkaen)
            if (this.dateService.paiviaValissa(hinnanPvm, loytyneenPvm) < 0) {
              // console.log('Valittiin', hinta, 'vanha', loytynytHinta)
              loytynytHinta = hinta
            }
          }
        }
      }
      return loytynytHinta
    }
    return null
  }


  public getLatestPriceBeforeDate(asiakas: Pick<Asiakas, 'hinnat'>, excludeHinnatFromDate: LocalDate): AsiakkaanKuukausihinta | null {

    let latestPriceBeforeInputDate: AsiakkaanKuukausihinta = null

    for (const hinta of asiakas.hinnat) {

      if (this.dateService.compareLocalMonths(hinta.voimassaAlkaen, '<', excludeHinnatFromDate) &&
        (!latestPriceBeforeInputDate || this.dateService.compareLocalDates(hinta.voimassaAlkaen, '>', latestPriceBeforeInputDate.voimassaAlkaen))
      ) {
        latestPriceBeforeInputDate = hinta
      }
    }
    return latestPriceBeforeInputDate
  }

  public annaMuotoiltuHinta(hinta: AsiakkaanKuukausihinta) {
    if (hinta) {
      const hinnanPvm = this.dateService.localDateToDate(hinta.voimassaAlkaen)
      if (this.dateService.kuukausiaValissa(new Date(), hinnanPvm) < 0) {
        const date = this.dateService.localDateToDate(hinta.voimassaAlkaen)
        return this.currencyService.formatoiDesimaali(hinta.hinta, 2, 'fi') + ' (' + this.dateService.muotoileKuukausiJaVuosi(date, 'fi') + ') '
      }
      return this.currencyService.formatoiDesimaali(hinta.hinta, 2, 'fi')
    }
    return ''
  }

  public onkoSahkoinenLaskutus(asiakas: Asiakas): boolean {
    return !!(asiakas.sahkoinenLaskutusosoite && asiakas.sahkoinenLaskutusosoite.sahkoinenOsoite && asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja)
  }

}
