<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <form [formGroup]="form" novalidate>
    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit">
        <mat-form-field style="width: 250px; margin-right: 1.5vw;">
          <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
          <mat-label>Viitenumero</mat-label>
          <input type="text" formControlName="viitenumero" matInput [name]="'a' + name" />
        </mat-form-field>
      </div>
    </div>
  </form>

  <h1>Laskut</h1>
  <mat-spinner *ngIf="laskutLataa"></mat-spinner>
  <table [class.hidden]="laskutLataa" style="width: 100%; border-spacing: 10px 2px;">
    <thead>
      <tr>
        <th>Suoritus</th>
        <th>Aika</th>
        <th>Tekijä</th>
        <th>Lähde tai kohde</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let lasku of laskutObservable | async">
        <tr>
          <td colspan="4" style="color: gray; border-top: 1px solid darkgray">{{lasku.nro}} {{lasku.avain}}</td>
        </tr>
        <!-- <tr>
          <td colspan="4">
            <div *ngFor="let reskontra of lasku.reskontra">
              <div [innerHtml]="reskontra | json"></div>
              <div [innerHtml]="reskontra | ljson"></div>
            </div>
          </td>
        </tr> -->
        <!-- <tr *ngFor="let reskontra of lasku.reskontra">
          <td style="text-align: right;">{{ reskontra.suoritus | lmoneyl: 'EUR': 'fi' }}</td>
          <td>{{ reskontra.pvm | ltimestamptimel: 'fi' }}</td>
          <td>{{ reskontra.tekijaUid }}</td>
          <td>
            <ng-container *ngIf="reskontra.siirrettyTilitapahtumasta">Tuotu tilitapahtumasta {{ reskontra.siirrettyTilitapahtumasta }}</ng-container>
            <ng-container *ngIf="reskontra.siirrettyLaskuun">Siirretty laskuun {{ reskontra.siirrettyLaskuun }}</ng-container>
          </td>
        </tr> -->
      </ng-container>
    </tbody>
  </table>

  <h1>Tilitapahtumat</h1>
  <mat-spinner *ngIf="tapahtumatLataa"></mat-spinner>
  <table [class.hidden]="tapahtumatLataa" style="width: 100%; border-spacing: 10px 2px;">
    <thead>
      <tr>
        <th>Suoritus</th>
        <th>Aika</th>
        <th>Kohde</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let tapahtuma of tapahtumatObservable | async">
        <tr>
          <td colspan="3" style="color: gray; border-top: 1px solid darkgray">{{tapahtuma.avain}}, {{tapahtuma.summa | lmoneyl: 'EUR': 'fi'}}</td>
        </tr>
        <!-- <tr>
          <td colspan="3">
            <div [innerHtml]="tapahtuma | ljson"></div>
          </td>
        </tr> -->
        <!-- TODO: KOHDISTUKSET NYKYÄÄN OMASSA COLLECTIONISSA! -->
        <!-- <tr *ngFor="let kohdistus of tapahtuma.kohdistukset">
          <td style="text-align: right;">{{ kohdistus.suoritus | lmoneyl: 'EUR': 'fi' }}</td>
          <td>{{ kohdistus.luotu | ltimestamptimel: 'fi' }}</td>
          <td>
            Siirretty laskuun {{ kohdistus.laskuAvain }} {{ kohdistus.laskunro }} (Saaja: {{kohdistus.laskunsaaja}})
          </td>
        </tr> -->
      </ng-container>

    </tbody>
  </table>

</div>