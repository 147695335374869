import { Component, Inject, ErrorHandler, ViewChild, HostListener, AfterContentInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Kirjanpitotili, OstoAlvt, MyyntiAlvt, AlvMaaritys, LokalisoidutAlvtyypit, LokalisoituKirjanpitotilinAlvTyyppi, KirjanpitotilinAlvTyyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { Observable } from 'rxjs'
import { TuettuKieli } from 'app/_shared-core/model/common'

export interface KirjanpitotiliMuokkaaDialogData {
  tili: Kirjanpitotili
  lisaa: boolean
}

@Component({
  templateUrl: './kirjanpitotili-muokkaa.dialog.html'
})
export class KirjanpitotiliMuokkaaDialog implements AfterContentInit {

  @ViewChild('nimiFiInput', { read: MatInput, static: true }) nimiFiInput: MatInput
  @ViewChild('numeroInput', { read: MatInput, static: true }) numeroInput: MatInput

  commonError: string = ''
  form: UntypedFormGroup
  name = '' + Math.random()
  lisaa = false
  alvt: AlvMaaritys[] = []
  lokalisoidutAlvtyypit: LokalisoituKirjanpitotilinAlvTyyppi[] = LokalisoidutAlvtyypit.kaikki
  tilitObservable: Observable<Kirjanpitotili[]>

  vertaaAlvMaarityksia: (a: AlvMaaritys, b: AlvMaaritys) => boolean = (a: AlvMaaritys, b: AlvMaaritys): boolean => {
    return a && b && a.tunniste === b.tunniste
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: KirjanpitotiliMuokkaaDialogData,
    private dialogRef: MatDialogRef<KirjanpitotiliMuokkaaDialog>,
    private errorHandler: ErrorHandler,
    private tilikarttaService: TilikarttaService,
    private ladataanService: LadataanService,
    private validationService: FormValidationService
  ) {
    this.lisaa = this.data.lisaa
    this.tilitObservable = this.tilikarttaService.paatilikartanTilitObservable
  }

  private asetaAlvt(tyyppi: KirjanpitotilinAlvTyyppi) {
    if (tyyppi === KirjanpitotilinAlvTyyppi.MYYNTI) {
      this.alvt = [].concat(MyyntiAlvt.kaikki)
    } else if (tyyppi === KirjanpitotilinAlvTyyppi.OSTO) {
      this.alvt = [].concat(OstoAlvt.kaikki)
    } else if (tyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI_EI_REKISTEROITYNYT) {
      this.alvt = [].concat(MyyntiAlvt.suomi)
    } else {
      this.alvt = []
    }
  }

  ngAfterContentInit() {

    const kaytettavaTyyppi = this.data.tili.alvTyyppi || KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA
    const onEiAlvKasittelya = kaytettavaTyyppi === KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA || kaytettavaTyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI

    this.asetaAlvt(kaytettavaTyyppi)

    // Create form
    this.form = new UntypedFormGroup({
      'vanhempi': new UntypedFormControl(this.data.tili.vanhempi, []),
      'numero': new UntypedFormControl({ value: this.data.tili.numero, disabled: !this.data.lisaa }, [Validators.required]),
      'nimiFi': new UntypedFormControl(this.data.tili.localisedName?.fi ?? this.data.tili.nimi, [Validators.required]),
      'nimiEn': new UntypedFormControl(this.data.tili.localisedName?.en ?? null, [Validators.required]),
      'alv': new UntypedFormControl({ disabled: onEiAlvKasittelya, value: onEiAlvKasittelya ? null : this.data.tili.oletusAlvKasittely }, onEiAlvKasittelya ? [] : [Validators.required]),
      'alvtyyppi': new UntypedFormControl(kaytettavaTyyppi, [Validators.required]),
      'aktiivinen': new UntypedFormControl(this.data.tili.aktiivinen, [])
    })

    this.form.get('alvtyyppi').valueChanges.subscribe(value => {
      const alvField = this.form.get('alv')
      alvField.setValue(null)
      this.asetaAlvt(value)
      if (
        value &&
        value !== KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA &&
        value !== KirjanpitotilinAlvTyyppi.ETAMYYNTI
      ) {
        console.log('enable')
        alvField.enable()
        alvField.setValidators(Validators.required)
        alvField.updateValueAndValidity()
      } else {
        console.log('disable')
        alvField.clearValidators()
        alvField.updateValueAndValidity()
        alvField.disable()
      }
    })

    if (this.lisaa) {
      this.numeroInput.focus()
    } else {
      this.nimiFiInput.focus()
    }

  }

  get vanhempi(): AbstractControl {
    return this.form.get('vanhempi')
  }

  get numero(): AbstractControl {
    return this.form.get('numero')
  }

  get nimiFi(): AbstractControl {
    return this.form.get('nimiFi')
  }

  get nimiEn(): AbstractControl {
    return this.form.get('nimiEn')
  }

  get alv(): AbstractControl {
    return this.form.get('alv')
  }

  get alvtyyppi(): AbstractControl {
    return this.form.get('alvtyyppi')
  }

  get aktiivinen(): AbstractControl {
    return this.form.get('aktiivinen')
  }

  @HostListener('document:keydown.esc')
  peruuta() {
    this.dialogRef.close()
  }

  @HostListener('document:keydown.enter')
  tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this.ladataanService.aloitaLataaminen()

    const localisedName: { [kieli in TuettuKieli]: string } = {
      'fi': null,
      'en': null
    }

    if (this.nimiFi.value) {
      localisedName.fi = this.nimiFi.value
    }
    if (this.nimiEn.value) {
      localisedName.en = this.nimiEn.value
    }

    this.tilikarttaService.tallennaPaatilikartanTili({
      vanhempi: this.vanhempi.value ? this.vanhempi.value + '' : null,
      aktiivinen: !!this.aktiivinen.value,
      numero: this.numero.value ? this.numero.value + '' : null,
      nimi: this.nimiFi.value,
      oletusAlvKasittely: this.alv.value,
      alvTyyppi: this.alvtyyppi.value,
      localisedName: localisedName
    }).then(() => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close()
    }).catch(error => {
      this.ladataanService.lopetaLataaminen()
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this.errorHandler.handleError(error)
    })

  }

}
