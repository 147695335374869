<mat-dialog-content>

  <lemon-dialog-header>
    Muistutuslaskuja lähetetään
  </lemon-dialog-header>

  <div style="padding-left: 3em; padding-right: 3em;">
    <div style="text-align: center;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div style="margin-top: 30px; margin-bottom: 15px;">
      Älä sulje selainta, muistutusten lähetys on käynnissä.
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close class="primarybutton">{{'yleiset.peruuta' | translate | async}}</button>
</mat-dialog-actions>