
import { Component, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'

import { VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { KirjanpitoVeroilmoitusBaseCommonComponent } from './base-common-veroilmoitus.component'
import { VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { IlmoitusRivi } from 'app/_jaettu-lemonator/service/base-ilmoitin-export.service'
import { VeroilmoituksenAvainluvut } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'

@Component({
  template: ''
})
export abstract class KirjanpitoVeroilmoitusBaseRowComponent extends KirjanpitoVeroilmoitusBaseCommonComponent implements OnDestroy {

  protected _ngUnsubscribe = new Subject<void>()

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  protected _annaTallentamistaVarten(numero: string, arvo: number | string, onkoNumeerinen: boolean, veroilmoitus: VeroilmoituksenMuokkaustiedot): string | number | undefined {
    if (onkoNumeerinen) {
      return this._annaNumeroTallentamistaVarten(numero, arvo as number, veroilmoitus)
    }
    return this._annaArvoTallentamistaVarten(numero, arvo as string, veroilmoitus)
  }

  protected _annaNumeroTallentamistaVarten(numero: string, arvo: number, veroilmoitus: VeroilmoituksenMuokkaustiedot): number | undefined {
    if (arvo === undefined || veroilmoitus.oletukset[numero] === arvo) {
      return undefined
    }
    if (this._currencyService.onkoNumero(arvo)) {
      return arvo
    }
    return null
  }

  protected _annaArvoTallentamistaVarten(numero: string, arvo: string, veroilmoitus: VeroilmoituksenMuokkaustiedot): string | undefined {
    if (arvo === undefined || veroilmoitus.oletukset[numero] === arvo) {
      return undefined
    }
    if (arvo) {
      return arvo
    }
    return null
  }

  private _getValueFromRows(num: string, vals: IlmoitusRivi[], repeatingMetadata: RepeatingMetadata): string | null {
    if (vals) {
      if (repeatingMetadata) {
        for (let i = repeatingMetadata.startIndex; i <= repeatingMetadata.endIndex; i++) {
          const val = vals[i]
          if (val.key === num) {
            return val.value
          }
        }
      } else {
        for (const val of vals) {
          if (val.key === num) {
            return val.value
          }
        }
      }
    }
    return undefined
  }

  private _getValueFromAvainluvut(num: string, vals: VeroilmoituksenAvainluvut): string | number | null {
    if (vals) {
      return vals[num]
    }
    return undefined
  }

  protected _getStringValue(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, repeatingMetadata?: RepeatingMetadata): string | null {
    const val = this._getValue(numero, veroilmoitus, repeatingMetadata)
    if (val !== null) {
      return val + ''
    }
    return null
  }

  protected _getValue(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, repeatingMetadata?: RepeatingMetadata): string | number | null {
    const valueFromLocallyEdited = this._getValueFromAvainluvut(numero, veroilmoitus.locallyEditedNotPersistedValues)
    if (valueFromLocallyEdited !== undefined) {
      // console.log('valueFromLocallyEdited return', valueFromLocallyEdited, 'for', numero)
      return valueFromLocallyEdited
    }
    const valueFromEditable = this._getValueFromAvainluvut(numero, veroilmoitus.editedValuesFromDatabase)
    if (valueFromEditable !== undefined) {
      // console.log('valueFromEditable return', valueFromEditable, 'for', numero)
      return valueFromEditable
    }
    const valueFromStatic = this._getValueFromRows(numero, veroilmoitus.arvot, repeatingMetadata)
    if (valueFromStatic !== undefined) {
      // console.log('valueFromStatic return', valueFromStatic, 'for', numero)
      return valueFromStatic
    }
    const valueFromDefaults = this._getValueFromAvainluvut(numero, veroilmoitus.oletukset)
    if (valueFromDefaults !== undefined) {
      // console.log('valueFromDefaults return', valueFromDefaults, 'for', numero)
      return valueFromDefaults
    }
    return null
  }

  protected _getNumberValue(numero: string, veroilmoitus: VeroilmoituksenMuokkaustiedot, repeatingMetadata?: RepeatingMetadata): number {
    const rawValue = this._getValue(numero, veroilmoitus, repeatingMetadata)
    // console.log('Return', rawValue, 'for', numero, repeatingMetadata)
    if (this._currencyService.onkoNumero(rawValue)) {
      return rawValue as number
    }
    return this._currencyService.muutaMerkkijonoNumeroksi(rawValue as string, 2)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
