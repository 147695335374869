import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { AsiakasService } from '../asiakas/asiakas.service'

export interface AutosaveCache {
  searchFilter: string
  tiliFilterStart: number
  tiliFilterEnd: number
  startDate: Date
  endDate: Date
  lastViewedReport: 'tuloslaskelma' | 'tase' | 'paakirja'
}

@Injectable()
export class KirjanpitoRaportitAutosaveService {

  private _autosaveCacheSubject = new BehaviorSubject<AutosaveCache>({
    searchFilter: null,
    tiliFilterStart: null,
    tiliFilterEnd: null,
    startDate: null,
    endDate: null,
    lastViewedReport: null
  })

  constructor(
    private _asiakasService: AsiakasService
  ) {

    this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      distinctUntilChanged((a, b) => a?.avain === b?.avain)
    ).subscribe(asiakasAvain => this._restartCache())

  }

  getCache() {
    return this._autosaveCacheSubject.value
  }

  getSearchFilter() {
    return this._autosaveCacheSubject.value.searchFilter
  }
  setSearchFilter(val: string) {
    this._autosaveCacheSubject.value.searchFilter = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }
  getStartDateCached() {
    return this._autosaveCacheSubject.value.startDate
  }
  setStartDateCached(val: Date) {
    this._autosaveCacheSubject.value.startDate = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }
  getEndDateCached() {
    return this._autosaveCacheSubject.value.endDate
  }
  setEndDateCached(val: Date) {
    this._autosaveCacheSubject.value.endDate = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }
  getLastViewedReport() {
    return this._autosaveCacheSubject.value.lastViewedReport
  }
  setLastViewedReport(val: 'tuloslaskelma' | 'tase' | 'paakirja') {
    this._autosaveCacheSubject.value.lastViewedReport = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }
  getTiliFilterStart() {
    return this._autosaveCacheSubject.value.tiliFilterStart
  }
  setTiliFilterStart(val: number) {
    this._autosaveCacheSubject.value.tiliFilterStart = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }
  getTiliFilterEnd() {
    return this._autosaveCacheSubject.value.tiliFilterEnd
  }
  setTiliFilterEnd(val: number) {
    this._autosaveCacheSubject.value.tiliFilterEnd = val
    this._autosaveCacheSubject.next(this._autosaveCacheSubject.value)
  }

  private _restartCache() {
    this._autosaveCacheSubject.next({
      searchFilter: null,
      tiliFilterStart: null,
      tiliFilterEnd: null,
      startDate: null,
      endDate: null,
      lastViewedReport: null
    })
  }

}
