<button *ngIf="showPen && !lukittu" mat-icon-button class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<div class="tili-num">{{ numero }}</div>
<div class="long-col" [class.has-label]="!!label">
  <div>{{ label }}</div>
  <div class="option-container">
    <ng-container *ngFor="let option of options">
      <mat-checkbox class="dense" style="margin-left: 10px;" [disabled]="!editing || lukittu" [(ngModel)]="option.checked" (change)="tallennaMuuttunutArvo()">{{option.label}}</mat-checkbox> <br *ngIf="direction === 'vertical'" />
    </ng-container>
    <div class="option-container-indicator-container">
      <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </span>
      <span *ngIf="saveSuccess" class="save-success" [@quickInSlowOut]="'in'">
        <mat-icon [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
        Valinta tallennettiin
      </span>
      <span *ngIf="saveFailure" class="save-failure">
        <mat-icon [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
        Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
      </span>
    </div>
  </div>
</div>