import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'
import { FirebaseLemonator } from './firebase-lemonator.service'

interface SovelluksenVersio {
  versio: string
}

@Injectable()
export class VersionTarkistusPalvelu {

  sovelluksenVersioObservable: Observable<SovelluksenVersio>

  constructor(
    private _firebase: FirebaseLemonator,
  ) {
    this.sovelluksenVersioObservable = this._firebase.firestoreDoc<SovelluksenVersio>('admin/sovelluksen-versio').listen()
  }

  public tarkistaVersio(versio: SovelluksenVersio | null) {
    if (environment.enableVersionCheck && versio) {
      if (versio.versio !== environment.version) {
        location.reload()
      }
    }
  }

}
