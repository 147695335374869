import { Component, OnInit, Inject } from '@angular/core'
import { Validators, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { Kirjaus } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { NumberDate } from 'app/_shared-core/model/common'

export interface KirjausMuutaLaskuperusteiseksiDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  maksutavat: AsiakkaanMaksutapa[]
  asiakas: Asiakas
}

export interface KirjausMuutaLaskuperusteiseksiDialogReturnValue {
  muutettavaKirjaus: Kirjaus
  ostolaskuKirjauksenPaivamaara: NumberDate
}

interface Form {
  kirjauksenPvm: FormControl<Date>
}

@Component({
  templateUrl: './kirjaus.muuta-laskuperusteiseksi.dialog.html'
})
export class KirjausMuutaLaskuperusteiseksiDialog implements OnInit {

  form: FormGroup<Form>
  namename: string = 'assf' + Math.random()
  ensimmainenPaiva: Date = new Date(2015, 1, 1)
  viimeinenPaiva: Date = new Date()

  private _paivamaara: number = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausMuutaLaskuperusteiseksiDialogData,
    private _dialogRef: MatDialogRef<KirjausMuutaLaskuperusteiseksiDialog, KirjausMuutaLaskuperusteiseksiDialogReturnValue>,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {

    this.form = new FormGroup<Form>({
      'kirjauksenPvm': new FormControl<Date>({ value: null, disabled: false }, [Validators.required])
    })
    this.form.get('kirjauksenPvm').valueChanges.subscribe(value => {
      this._paivamaara = this._dateService.localDateToNumber(this._dateService.dateToLocalDate(value))
    })

    const ensimmainenTilikausi = this._asiakasJaettuService.annaEnsimmainenTilikausi(data.asiakas)
    if (ensimmainenTilikausi) {
      this.ensimmainenPaiva = this._dateService.localDateToDate(ensimmainenTilikausi.alkaa)
    }

    const viimeinenTilikausi = this._asiakasJaettuService.annaViimeinenTilikausi(data.asiakas)
    if (viimeinenTilikausi) {
      this.viimeinenPaiva = this._dateService.localDateToDate(viimeinenTilikausi.loppuu)
    }

    const kirjauksenPaivamaara = this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p
    const lastOfPreviousMonth = this._dateService.kuukaudenViimeinenNumber(this._dateService.lisaaKuukausiaNumero(kirjauksenPaivamaara, -1))
    const asDate = this._dateService.numberToDate(lastOfPreviousMonth)
    this.form.get('kirjauksenPvm').setValue(asDate)

  }

  ngOnInit() {

  }

  kopioi() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._dialogRef.close({ muutettavaKirjaus: this.data.naytettavaKirjaus.kirjaus, ostolaskuKirjauksenPaivamaara: this._paivamaara })

  }

}
