<mat-dialog-content>

  <form [formGroup]="form" novalidate style="margin: 0;">

    <lemon-dialog-header>
      {{asiakas?.n}}
      <div style="font-size: 14px; line-height: 20px; color: rgba(0, 0, 0, 0.87); font-weight: normal; text-align: center;">{{lokalisoituKuukausiVuosi}}</div>
      <mat-checkbox #aktiivinenCheckbox style="position: absolute; left: -13px; top: 3px; font-size: 14px; color: rgba(0, 0, 0, 0.87); font-weight: normal;" formControlName="aktiivinen">Aktiivinen</mat-checkbox>
    </lemon-dialog-header>

    <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
      <h3 style="margin: 0;">Urit</h3>
      <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Kuukausiruutu: <a [href]="kuukausiruutuEncodedUriObservable | async" target="_BLANK">{{kuukausiruutuUriObservable | async}}</a></div>
    </div>

    <div style="padding-left: 3em; padding-right: 3em;">
      <!-- <div *ngIf="commonError" class="error-text">
        Lataushistorian lataamisen tai tositteiden lataamismerkintöjen peruuttamisen aikana tapahtui virhe. Virhe on tallennettu. Ole hyvä ja ota yhteys ylläpitoon.
      </div> -->
      <table>
        <tr>
          <td>
            <div>Kirjanpitoon käytetty aika</div>
          </td>
          <td style="text-align: center;">
            <!-- {{kirjanpitoAika?.errors | json}} -->
            <mat-form-field>
              <mat-label>Aika</mat-label>
              <input type="text" #kirjanpitoaikaInput [name]="namename" matInput formControlName="kirjanpitoAika" data-lpignore="true" required />
              <mat-hint>Hinta: {{hinta}}, teho: {{teho}}</mat-hint>
              <mat-error *ngIf="kirjanpitoAika?.errors?.max">Käytetty aika voi olla maksimissaan {{kirjanpitoAika?.errors?.max?.max}} minuuttia</mat-error>
              <mat-error *ngIf="kirjanpitoAika?.errors?.required">Käytetty aika puuttuu</mat-error>
              <mat-error *ngIf="kirjanpitoAika?.errors?.min">Käytetyn ajan pitää olla vähintään {{kirjanpitoAika?.errors?.min?.min}} minuutti</mat-error>
              <mat-error *ngIf="kirjanpitoAika?.errors?.number">Käytetty aika voi sisältää ainoastaan numeroja</mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox #kirjanpitoTehtyCheckbox formControlName="kirjanpitoTehty">Kirjanpito tehty</mat-checkbox>
          </td>
          <td>
            <mat-form-field>
              <mat-label>Päivämäärä</mat-label>
              <input type="text" [matDatepicker]="picker" [name]="namename" formControlName="kirjanpitoPvm" required matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-error>Päivämäärä puuttuu</mat-error>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
          </td>
        </tr>
        <tr *ngIf="naytaTilinpaatos">
          <td>
            <mat-checkbox #tilinpaatosTehtyCheckbox formControlName="tilinpaatosTehty">Tilinpäätös tehty</mat-checkbox>
          </td>
          <td>
            <mat-form-field>
              <mat-label>Päivämäärä</mat-label>
              <input type="text" [matDatepicker]="picker" [name]="namename" formControlName="tilinpaatosPvm" required matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-error>Päivämäärä puuttuu</mat-error>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
          </td>
          <!-- <td style="text-align: center;">
            <mat-form-field>
              <input type="text" #tilinpaatosaikaInput [name]="namename" matInput formControlName="tilinpaatosAika" placeholder="Käytetty aika" data-lpignore="true" required />
              <mat-error *ngIf="tilinpaatosAika?.errors?.max">Käytetty aika voi olla maksimissaan {{tilinpaatosAika?.errors?.max?.max}} minuuttia</mat-error>
              <mat-error *ngIf="tilinpaatosAika?.errors?.required">Käytetty aika puuttuu</mat-error>
              <mat-error *ngIf="tilinpaatosAika?.errors?.min">Käytetyn ajan pitää olla vähintään {{tilinpaatosAika?.errors?.min?.min}} minuutti</mat-error>
              <mat-error *ngIf="tilinpaatosAika?.errors?.number">Käytetty aika voi sisältää ainoastaan numeroja</mat-error>
            </mat-form-field>
          </td> -->
        </tr>
        <tr *ngIf="naytaVeroilmoitus">
          <td>
            <mat-checkbox #veroilmoitusTehtyCheckbox formControlName="veroilmoitusTehty">Veroilmoitus tehty</mat-checkbox>
          </td>
          <td>
            <mat-form-field>
              <mat-label>Päivämäärä</mat-label>
              <input type="text" [matDatepicker]="picker" [name]="namename" formControlName="veroilmoitusPvm" required matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-error>Päivämäärä puuttuu</mat-error>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
          </td>
          <!-- <td style="text-align: center;">
            <mat-form-field>
              <input type="text" #veroilmoitusaikaInput [name]="namename" matInput formControlName="veroilmoitusAika" placeholder="Käytetty aika" data-lpignore="true" required />
              <mat-error *ngIf="veroilmoitusAika?.errors?.max">Käytetty aika voi olla maksimissaan {{veroilmoitusAika?.errors?.max?.max}} minuuttia</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.required">Käytetty aika puuttuu</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.min">Käytetyn ajan pitää olla vähintään {{veroilmoitusAika?.errors?.min?.min}} minuutti</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.number">Käytetty aika voi sisältää ainoastaan numeroja</mat-error>
            </mat-form-field>
          </td> -->
        </tr>
        <tr *ngIf="naytaPoytakirja">
          <td>
            <mat-checkbox #poytakirjaTehtyCheckbox formControlName="poytakirjaTehty">Tilinpäätös rekisteröity</mat-checkbox>
          </td>
          <td>
            <mat-form-field>
              <mat-label>Päivämäärä</mat-label>
              <input #poytakirjaPvmInput type="text" [matDatepicker]="picker" [name]="namename" formControlName="poytakirjaPvm" required matInput data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-error>Päivämäärä puuttuu</mat-error>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
          </td>
          <td style="text-align: center;">
            <!-- {{veroilmoitusAika?.errors | json}} -->
            <!-- <mat-form-field>
              <input type="text" [name]="namename" matInput formControlName="veroilmoitusAika" placeholder="Käytetty aika" data-lpignore="true" required />
              <mat-error *ngIf="veroilmoitusAika?.errors?.max">Käytetty aika voi olla maksimissaan {{veroilmoitusAika?.errors?.max?.max}} minuuttia</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.required">Käytetty aika puuttuu</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.min">Käytetyn ajan pitää olla vähintään {{veroilmoitusAika?.errors?.min?.min}} minuutti</mat-error>
              <mat-error *ngIf="veroilmoitusAika?.errors?.number">Käytetty aika voi sisältää ainoastaan numeroja</mat-error>
            </mat-form-field> -->
          </td>
        </tr>
      </table>

      <mat-form-field>
        <mat-label>Muistiinpanot</mat-label>
        <textarea #muistiinpanotTextarea formControlName="viesti" matInput [name]="namename" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"></textarea>
        <!-- cdkAutosizeMaxRows="5" -->
      </mat-form-field>
      <mat-checkbox formControlName="alvIlmoitusHoidettuJarjestelmanUlkopuolella">ALV-ilmoitus on hoidettu järjestelmän ulkopuolella</mat-checkbox>

      <div style="margin:2em 0;" *ngIf="naytaPoytakirja">
        <span style="margin-right: 4em;">Tiedot osinkojen maksamisesta</span>
        <mat-radio-group formControlName="tiedotOsinkojenMaksamisesta">
          <mat-radio-button value="ei">Ei makseta</mat-radio-button>
          <mat-radio-button value="ilm">Ilmoitettu</mat-radio-button>
        </mat-radio-group>
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Sulje</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>