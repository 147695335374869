import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, NgZone, ErrorHandler } from '@angular/core'

import { KlikattuKirjaus, RaporttienHakuvaihtoehdot } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiTaselaskelmaAccountRow, RaporttiTaselaskelmaData, RaporttiTaselaskelmaDataResponse } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs'
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { RaporttiType } from 'app/_jaettu/model/reports'
import { Timestamp } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'

@Component({
  selector: '[app-kirjanpito-taselaskelma]',
  templateUrl: './taselaskelma.component.html',
  styleUrls: ['./taselaskelma.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitTaselaskelmaComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  @Input() paivitaArvotHiljaisestiSubject: BehaviorSubject<number>
  @Input() taseEnsin: boolean

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()
  @Output() tulosEnsinClicked: EventEmitter<boolean> = new EventEmitter()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  taseDataObservable: Observable<RaporttiTaselaskelmaData>

  lastSucessfullyUpdated: Timestamp

  private _expandedAccountsBehaviorSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set())
  private _taysiVientihistoriaTilitSubject: BehaviorSubject<string[]> = new BehaviorSubject([])
  private _resetViewFunctions: (() => void)[] = []

  constructor(
    private _errorHandler: ErrorHandler,
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _currencyService: CurrencyService,
    private _timestampService: TimestampService,
    private _tilikarttaJaettuService: TilikarttaJaettuService
  ) { }

  ngOnInit() {

    const laskelmanRaakadataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.hakuvaihtoehdotObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this._taysiVientihistoriaTilitSubject,
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([asiakas, hakuvaihtoehdot, taysiVientihistoriaTilit, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu || !asiakas) {
          return of<RaporttiTaselaskelmaData>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.TASE,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        // if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        // if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        // if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }
        if (taysiVientihistoriaTilit.length > 0) { haku.ff = taysiVientihistoriaTilit }

        if (this._expandedAccountsBehaviorSubject.value.size > 0) {
          haku.f = Array.from(this._expandedAccountsBehaviorSubject.value)
        }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiTaselaskelmaDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error()
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })

      }),
      startWith<RaporttiTaselaskelmaData>({
        c: 'c1',
        r: [],
        c1: null
      })
    )

    const maksutavatMapObservable: Observable<Map<string, AsiakkaanMaksutapa>> = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => {
        const mappi = new Map<string, AsiakkaanMaksutapa>()
        for (const m of maksutavat) {
          mappi.set(m.tunniste + '', m)
        }
        return mappi
      })
    )

    const dataJaNimet = combineLatest([
      this.tilitMapObservable,
      laskelmanRaakadataObservable,
      maksutavatMapObservable
    ]).pipe(
      map(([tiliMap, laskelmanRaakadata, maksutavatMap]) => {
        if (!tiliMap || !laskelmanRaakadata || !maksutavatMap) {
          return null
        }
        if (laskelmanRaakadata.r?.length > 0) {
          let grandParent: '1' | '2' | '3' = '1'
          for (const r of laskelmanRaakadata.r) {

            if (r.a === '2') {
              grandParent = '2'
            } else if (r.a === '3') {
              grandParent = '3'
            }

            // || ['110', '170', '260', '280', '100', '116', '140', '150', '160'].includes(r.a)
            if (r.s && r.a.length < 4) {
              r.n = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') + ' yhteensä' // TODO: Add multi-lang support
            } else if (r.s) {
              r.n = 'YHTEENSÄ' // TODO: Add multi-lang support
            } else if (r.a.length > 3) {
              r.n = r.a + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') // TODO: Add multi-lang support
            } else {
              r.n = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') // TODO: Add multi-lang support
            }
            if (r.d) {
              let debitSumma = 0
              let kreditSumma = 0
              for (const d of r.d) {
                d.ma = maksutavatMap.get(d.m)?.nimi || ''
                debitSumma += d.d ?? 0
                kreditSumma += d.k ?? 0
              }
              r.dks = this._currencyService.roundHalfUp(kreditSumma, 2)
              r.dds = this._currencyService.roundHalfUp(debitSumma, 2)
              if (grandParent === '2') {
                r.dss = r.dks - r.dds + r.o1
              } else {
                r.dss = r.dds - r.dks + r.o1
              }

            }
          }
        }
        return laskelmanRaakadata
      })
    )

    this.taseDataObservable = combineLatest([dataJaNimet, this._expandedAccountsBehaviorSubject]).pipe(
      map(([data, rivienExpand]) => {
        if (data?.r) {
          for (const r of data.r) {
            if (rivienExpand.has(r.a)) {
              r.e = 1
            } else {
              delete r.e
            }
          }
        }
        return data
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    this._resetViewFunctions.forEach(a => a())
    this._resetViewFunctions = []
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  handleClick(event: MouseEvent, data: RaporttiTaselaskelmaData) {
    const element = event.target as HTMLElement
    if (element.dataset.n) {
      this.kirjaustaKlikattiin.emit({ kirjausnumero: element.dataset.n })
    } else if (element.dataset.a) {
      const arr = this._taysiVientihistoriaTilitSubject.value
      const indexOf = arr.indexOf(element.dataset.a) ?? -1
      if (indexOf > -1) {
        arr.splice(indexOf, 1)
      } else {
        arr.push(element.dataset.a)
      }
      this._taysiVientihistoriaTilitSubject.next(arr)
      const nextRow = element.parentElement?.nextSibling as HTMLTableRowElement
      if (nextRow) {
        nextRow.style.display = 'table-row'
        this._resetViewFunctions.push(function () {
          if (nextRow?.style) {
            nextRow.style.display = 'none'
          }
        })
      }
    } else if (element?.classList?.contains('n')) {
      const tilinumero = element.parentElement.dataset.tnro
      if (tilinumero?.length > 3) {
        const set = this._expandedAccountsBehaviorSubject.value
        if (set.has(tilinumero)) {
          set.delete(tilinumero)
        } else {
          set.add(tilinumero)
          this.paivitaArvotHiljaisestiSubject.next(this.paivitaArvotHiljaisestiSubject.value + 1)
        }
        this._expandedAccountsBehaviorSubject.next(set)
        event.preventDefault()
        event.stopPropagation()
      }
    } else if (data?.r) {
      const tr = this._findTr(element)
      if (tr?.dataset.i !== undefined) {
        const closestBody = tr.parentElement as HTMLTableSectionElement
        if (closestBody?.dataset?.n) {
          // console.log('secondTd found', closestBody)
          const tilinumero = closestBody.dataset.n
          const tilinItem = data.r.find(a => a.n === tilinumero)
          if (tilinItem?.d) {
            // console.log('item found', tilinItem)
            const d = tilinItem.d[Number(tr?.dataset.i)]
            if (d.l) {
              delete d.l
            } else {
              d.l = true
            }
          }
        }
      }
    }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiTaselaskelmaAccountRow) {
    return item.a
  }

}
