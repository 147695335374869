<div>

  <div style="margin: .5em; padding: 2em 0 1em 0;" class="light-blue-text laheta-title">Aiemmin lähetetyt sähköpostit</div>

  <ng-template #loading>
    <div style="display: flex; height: 200px; justify-content: center; align-items: center">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
  </ng-template>

  <ng-container *ngIf="sentThisTilikausiObservable | async let sentThisTilikausi else loading">
    <div *ngIf="!sentThisTilikausi?.length">
      <div style="margin-left: 35px">Tilikaudella ei ole vielä lähetetty yhtään sähköpostia.</div>
    </div>

    <div *ngFor="let item of sentThisTilikausi" class="laheta-card">
      <div class="laheta-body laheta-body-history">
        <mat-form-field style="margin-bottom: 15px">
          <mat-label>Vastaanottajat</mat-label>
          <input disabled="true" type="text" [value]="item.recipientsEmails" matInput />
        </mat-form-field>
        <mat-form-field style="margin-bottom: 15px">
          <mat-label>Aihe</mat-label>
          <input disabled="true" type="text" [value]="item.aihe" matInput />
        </mat-form-field>
        <div class="history-text">
          <span class="history-text-label">Teksti</span>
          <div [innerHTML]="item.teksti"></div>
        </div>
        <div *ngIf="item.liitaTuloslaskelma || item.liitaVirallinenTuloslaskelma || item.liitaVirallinenTase || item.liitaVeroilmoitus || item.liitaTaseErittely || (item.liitteetPromise | async)?.length" style="margin-top: 10px">
          <span style="color: grey">Liitteet</span>
          <div class="history-liite-wrapper">
            <li class="history-liite" *ngIf="item.liitaTuloslaskelma" (click)="downloadSentReport(item.avain, 'tuloslaskelma')">tuloslaskelma.pdf</li>
            <li class="history-liite" *ngIf="item.liitaVirallinenTuloslaskelma" (click)="downloadSentReport(item.avain, 'virallinen-tulos')">virallinen-tulos.pdf</li>
            <li class="history-liite" *ngIf="item.liitaVirallinenTase" (click)="downloadSentReport(item.avain, 'virallinen-tase')">virallinen-tase.pdf</li>
            <li class="history-liite" *ngIf="item.liitaVeroilmoitus" (click)="downloadSentReport(item.avain, 'veroilmoitus')">veroilmoitus.pdf</li>
            <li class="history-liite" *ngIf="item.liitaTaseErittely" (click)="downloadSentReport(item.avain, 'tase-erittely')">tase-erittely.pdf</li>
            <li class="history-liite" *ngFor="let liite of item.liitteetPromise | async" (click)="downloadAttachment(liite)">{{ liite.nimi }}</li>
          </div>
        </div>
      </div>
      <div class="history-title light-blue-text">{{ item.nimi }} {{ item.luotuDateStr}} </div>
    </div>
  </ng-container>

</div>