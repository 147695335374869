import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, NgZone, ErrorHandler } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of, firstValueFrom } from 'rxjs'
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { KlikattuKirjaus, RaporttienHakuvaihtoehdot } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiOssData, RaporttiOssDataResponse, RaporttiOssCountryRow } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { MaaService } from 'app/_jaettu-angular/service/maa.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'
import { AlvLemonatorService } from 'app/_angular/service/alv-lemonator.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Timestamp } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

@Component({
  selector: '[app-kirjanpito-oss]',
  templateUrl: './oss.component.html',
  styleUrls: ['./oss.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitOssComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  @Input() paivitaArvotHiljaisestiSubject: BehaviorSubject<number>

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  ossDataObservable: Observable<RaporttiOssData>
  lastSucessfullyUpdated: Timestamp

  private _laskelmanRaakadata: RaporttiOssData
  private _expandedAccountsBehaviorSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set())

  constructor(
    private _errorHandler: ErrorHandler,
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _maaService: MaaService,
    private _currencyService: CurrencyService,
    private _codeCheckService: CodeCheckService,
    private _alvLemonatorService: AlvLemonatorService,
    private _dateService: DateService,
    private _timestampService: TimestampService,
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    const laskelmanRaakadataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.hakuvaihtoehdotObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([asiakas, hakuvaihtoehdot, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu) {
          return of<RaporttiOssData>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.OSS,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        // if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        // if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

        if (this._expandedAccountsBehaviorSubject.value.size > 0) {
          haku.f = Array.from(this._expandedAccountsBehaviorSubject.value)
        }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiOssDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })

      }),
      startWith<RaporttiOssData>({ r: [] }),
      tap(data => this._laskelmanRaakadata = data)
    )

    const maksutavatMapObservable: Observable<Map<string, AsiakkaanMaksutapa>> = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => {
        const paymentMethodsMap = new Map<string, AsiakkaanMaksutapa>()
        for (const m of maksutavat) {
          paymentMethodsMap.set(m.tunniste + '', m)
        }
        return paymentMethodsMap
      })
    )

    const dataJaNimet = combineLatest([
      this.tilitMapObservable,
      laskelmanRaakadataObservable,
      maksutavatMapObservable
    ]).pipe(
      map(([tiliMap, laskelmanRaakadata, maksutavatMap]) => {
        if (!tiliMap || !laskelmanRaakadata || !maksutavatMap) {
          return null
        }
        if (laskelmanRaakadata.r?.length > 0) {
          for (const r of laskelmanRaakadata.r) {
            if (r.l === 1) {
              r.n = r.a + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi')
            } else {
              r.n = this._maaService.getName(r.m, 'fi') // tiliMap.get(r.)?.nimi || ''
            }
            if (r.d) {
              for (const d of r.d) {
                d.ma = maksutavatMap.get(d.m)?.nimi || ''
              }
            }
          }
        }
        return laskelmanRaakadata
      })
    )

    this.ossDataObservable = combineLatest([dataJaNimet, this._expandedAccountsBehaviorSubject]).pipe(
      map(([data, rivienExpand]) => {
        if (data?.r) {
          for (const r of data.r) {
            if (rivienExpand.has(r.a + '_' + r.v)) {
              r.e = 1
            } else {
              delete r.e
            }
          }
        }
        return data
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  handleClick(event: MouseEvent, data: RaporttiOssData) {
    const element = event.target as HTMLElement
    console.log('Handle click', element)
    if (element.dataset.n) {
      this.kirjaustaKlikattiin.emit({ kirjausnumero: element.dataset.n })
    } else if (element?.classList?.contains('n')) {
      const tilinumero = element.parentElement.dataset.tnro
      const vat = element.parentElement.dataset.vat
      const avain = tilinumero + '_' + vat
      const set = this._expandedAccountsBehaviorSubject.value
      if (set.has(avain)) {
        set.delete(avain)
      } else {
        set.add(avain)
        this.paivitaArvotHiljaisestiSubject.next(this.paivitaArvotHiljaisestiSubject.value + 1)
      }
      this._expandedAccountsBehaviorSubject.next(set)
      console.log('Added', avain)
      event.preventDefault()
      event.stopPropagation()
    } else if (data?.r) {
      const tr = this._findTr(element)
      if (tr?.dataset.i !== undefined) {
        const closestBody = tr.parentElement as HTMLTableSectionElement
        if (closestBody?.dataset?.n) {
          // console.log('secondTd found', closestBody)
          const tilinumero = closestBody.dataset.n
          const tilinItem = data.r.find(a => a.n === tilinumero)
          if (tilinItem?.d) {
            // console.log('item found', tilinItem)
            const d = tilinItem.d[Number(tr?.dataset.i)]
            if (d.l) {
              delete d.l
            } else {
              d.l = true
            }
          }
        }
      }
    }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiOssCountryRow) {
    return index
  }

  async createCsvExportFile() {
    const [asiakas, hakuvaihtoehdot] = await Promise.all([
      firstValueFrom(this._asiakasService.nykyinenAsiakasObservable),
      firstValueFrom(this.hakuvaihtoehdotObservable)
    ])
    const rows = await this._makeExportFileRows(this._laskelmanRaakadata, asiakas)
    let csv = 'Rivinro;Tunniste;Ilmoitustyyppi;Kulutusvaltio;Myyntityyppi;Verotyyppi;Veroprosentti;Peruste;Maara;Myyntipaikka;Korjauskulutusvaltio;Korjausverokausi;Korjausmaara\r\n'
    for (const row of rows) {
      csv += `${row.a};${this._s(row.b)};${this._s(row.c)};${this._s(row.d)};${this._s(row.e)};${this._s(row.f)};${this._n(row.g)};${this._n(row.h)};${this._n(row.i)};${this._s(row.j)};${this._s(row.k)};${this._s(row.l)};${this._s(row.m)}\r\n`
    }
    const alkaa = this._dateService.muotoilePaikallinenPaiva(this._dateService.numberToLocalDate(hakuvaihtoehdot.alkaa), 'fi')
    const loppuu = this._dateService.muotoilePaikallinenPaiva(this._dateService.numberToLocalDate(hakuvaihtoehdot.loppuu), 'fi')
    const filename = asiakas.ytunnus + ' OSS ' + alkaa + '-' + loppuu + ' OmaVero export.csv'
    this._fileSaverService.saveStringAs(csv, filename, 'csv')
  }

  private _n(v: number): string {
    if (v === null || v === undefined) {
      return ''
    }
    return this._currencyService.formatoiDesimaaliSailytaNollat(v, 2, 'fi')
  }

  private _s(v: string): string {
    if (!v) {
      return ''
    }
    return v
  }

  private async _makeExportFileRows(data: RaporttiOssData, asiakas: Asiakas): Promise<GovenrmentOssExportRow[]> {
    const ret: GovenrmentOssExportRow[] = []

    let myynninTyyppi: '1' | '2' = null
    for (const r of data.r) {
      if (r.l === 1) {
        if (r.a === '3361') {
          myynninTyyppi = '1'
        } else if (r.a === '3362') {
          myynninTyyppi = '2'
        } else {
          throw new Error('Unknown tili: ' + r.a)
        }
      } else if (r.l === 2) {
        if (!myynninTyyppi) {
          throw new Error('Ei myynnin tyyppiä.')
        }
        const maaritys = await this._alvLemonatorService.annaMaaritys(r.v)
        if (!maaritys) {
          throw new Error('Unknown määritys: ' + r.v)
        }

        const alpha2Key = this._maaService.getAlpha2Code(r.m)
        const alpha2KeyWithOverride = alpha2Key === 'GR' ? 'EL' : alpha2Key
        const row: GovenrmentOssExportRow = {
          a: ret.length + 1,
          b: this._codeCheckService.annaVatTunnus(asiakas.ytunnus),
          c: '1',
          d: alpha2KeyWithOverride,
          e: myynninTyyppi,
          f: maaritys.oletus ? '1' : '2',
          g: r.p,
          h: r.w,
          i: r.w * (r.p / 100),
          j: null,
          k: null,
          l: null,
          m: null
        }
        console.log(row)
        ret.push(row)

      }
    }
    return ret
  }

}




/**
 * A Juokseva rivinumero.
B Verovelvollisen arvonlisäverotunniste, jolla verovelvollinen käyttää unionin järjestelmää.
C
Ilmoitustiedon tyyppi. Sallitut arvot:

1 = myynnin ilmoittaminen (täytä kohdat D-J, mutta ei kohtia K-M)

2 = aiemman verokauden korjauksen ilmoittaminen (täytä kohdat (K-M, mutta ei kohtia D-J).

D Kulutusjäsenvaltion maakoodi. Tarkista maakoodi EU-maiden arvonlisäverotunnisteiden luettelosta.
E
Myynnin tyyppi. Sallitut arvot:

1 = tavara

2 = palvelu.

F
Verokannan tyyppi. Sallitut arvot:

1 = yleinen verokanta

2 = alennettu verokanta.

G Verokanta, jolla tavara tai palvelu on myyty. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua.
H Veron peruste euroissa. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua.
I Veron määrä euroissa. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua.
J
Jos palvelun myynti on tapahtunut kiinteästä toimipaikasta tai tavaran myynti lähetyspaikasta, joka on muu EU-maa kuin tunnistamisjäsenvaltio, ilmoita sarakkeessa kyseisen EU-maan arvonlisäverotunniste, tai verorekisterinumero, jos arvonlisäverotunnistetta ei ole.

Jos ilmoitat csv-tiedostolla uuden kiinteän toimipaikan tai tavaran lähetyspaikan, lisää toimipaikan tiedot tiedostolatauksen jälkeen ennen kuin lähetät ilmoituksen.

Jos verovelvollinen on markkinapaikka, jolla ei ole tunnistetta tavaroiden lähetyspaikassa, tässä ilmoitetaan vain tavaroiden lähetyspaikan maakoodi.

Jos kiinteistä toimipaikoista tai tavaroiden lähetyspaikoista ei ole myyntejä, tämä sarake on tyhjä.

K Sen kulutusjäsenvaltion maakoodi, jolle korjaus ilmoitetaan. Tarkista maakoodi EU-maiden arvonlisäverotunnisteiden luettelosta.
L Korjattava verokausi. Muodossa Qx/vvvv.
M
Korjauksen määrä euroissa. Määrä voi olla positiivinen (verokaudelle ilmoitetaan lisää maksettavaa veroa) tai negatiivinen (verokauden maksettavan veron määrää pienennetään).
 */

interface GovenrmentOssExportRow {
  /** A Juokseva rivinumero. */
  a: number
  /** B Verovelvollisen arvonlisäverotunniste, jolla verovelvollinen käyttää unionin järjestelmää. */
  b: string
  /** C
Ilmoitustiedon tyyppi. Sallitut arvot:

1 = myynnin ilmoittaminen (täytä kohdat D-J, mutta ei kohtia K-M)

2 = aiemman verokauden korjauksen ilmoittaminen (täytä kohdat (K-M, mutta ei kohtia D-J). */
  c: '1' | '2'
  /** D Kulutusjäsenvaltion maakoodi. Tarkista maakoodi EU-maiden arvonlisäverotunnisteiden luettelosta. */
  d: string
  /** E
Myynnin tyyppi. Sallitut arvot:

1 = tavara

2 = palvelu. */
  e: '1' | '2'
  /** F
Verokannan tyyppi. Sallitut arvot:

1 = yleinen verokanta

2 = alennettu verokanta. */
  f: '1' | '2'
  /** G Verokanta, jolla tavara tai palvelu on myyty. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua. */
  g: number
  /** H Veron peruste euroissa. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua. */
  h: number
  /** I Veron määrä euroissa. Kaksi desimaalia on sallittu ja desimaalierottimena käytetään pilkkua. */
  i: number
  /** J
Jos palvelun myynti on tapahtunut kiinteästä toimipaikasta tai tavaran myynti lähetyspaikasta, joka on muu EU-maa kuin tunnistamisjäsenvaltio, ilmoita sarakkeessa kyseisen EU-maan arvonlisäverotunniste, tai verorekisterinumero, jos arvonlisäverotunnistetta ei ole.

Jos ilmoitat csv-tiedostolla uuden kiinteän toimipaikan tai tavaran lähetyspaikan, lisää toimipaikan tiedot tiedostolatauksen jälkeen ennen kuin lähetät ilmoituksen.

Jos verovelvollinen on markkinapaikka, jolla ei ole tunnistetta tavaroiden lähetyspaikassa, tässä ilmoitetaan vain tavaroiden lähetyspaikan maakoodi.

Jos kiinteistä toimipaikoista tai tavaroiden lähetyspaikoista ei ole myyntejä, tämä sarake on tyhjä. */
  j: string
  /* K Sen kulutusjäsenvaltion maakoodi, jolle korjaus ilmoitetaan. Tarkista maakoodi EU - maiden arvonlisäverotunnisteiden luettelosta. **/
  k: string
  /* L Korjattava verokausi.Muodossa Qx / vvvv. **/
  l: string
  /* M
  Korjauksen määrä euroissa.Määrä voi olla positiivinen(verokaudelle ilmoitetaan lisää maksettavaa veroa) tai negatiivinen(verokauden maksettavan veron määrää pienennetään). **/
  m: string
}
