import { Environment, EnvironmentType } from '../app/app.environment'
import { LemonaidFirebaseConfig } from '../app/_jaettu-angular/base-firebase.service'

export const lemonatorFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyDtxydoqvkDhqFdVkdvllmfbiIfa0-R7ag',
  authDomain: 'kirjaudu.lemonator.lemontree.fi',
  databaseURL: 'https://eu-lemonator-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'eu-lemonator',
  storageBucket: 'eu-lemonator.appspot.com',
  messagingSenderId: '102849744255',
  appId: '1:102849744255:web:2b8879688bffe9884a1e4a',
  measurementId: 'G-MK32GJ5SHS',
  functionsRegion: 'europe-west1'
}

export const lemonaidFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyDZyAXlW9fQf-8Y4yYOAj1w3r2lKFsfthQ',
  authDomain: 'eu-lemonaid.firebaseapp.com',
  databaseURL: 'https://eu-lemonaid-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'eu-lemonaid',
  storageBucket: 'eu-lemonaid.appspot.com',
  messagingSenderId: '749820318095',
  appId: '1:749820318095:web:57f3c04012c48c03df7bfa',
  measurementId: 'G-QCVP293B7L',
  functionsRegion: 'europe-west1'
}

// // USA
// export const lemonatorFirebaseConfig: FirebaseOptions = {
//   apiKey: 'AIzaSyAmVxZo2yAjitQL2uQXf0WD3-dCJ652BNs',
//   authDomain: 'lemonator-web.firebaseapp.com',
//   databaseURL: 'https://lemonator-web.firebaseio.com',
//   projectId: 'lemonator-web',
//   storageBucket: 'lemonator-web.appspot.com',
//   messagingSenderId: '41629216980'
// }

// // USA
// export const lemonaidFirebaseConfig: FirebaseOptions = {
//   apiKey: 'AIzaSyCf2xZVo-4u5HeQmDp5lSM7asfPpJAEW9g',
//   authDomain: 'lemonaid-9ef4a.firebaseapp.com',
//   databaseURL: 'https://lemonaid-9ef4a.firebaseio.com',
//   projectId: 'lemonaid-9ef4a',
//   storageBucket: 'lemonaid-9ef4a.appspot.com',
//   messagingSenderId: '1003840181206'
// }

export const environment: Environment = {
  environment: EnvironmentType.BETA,
  airbrakeEnabled: true,
  enableVersionCheck: false,
  lemonatorFirebaseConfig: lemonatorFirebaseConfig,
  lemonaidFirebaseConfig: lemonaidFirebaseConfig,
  recaptchaSiteId: '6Ld7C0chAAAAADQ1ispikcxP60qR7CKX3TsDm7V1',
  lemonaidRecaptchaSiteId: '6LdFOUghAAAAAF5ooHUynJsSZSLN0xJxgOzGsc2x',
  version: 'BETA',
  host: 'https://beta.lemonator.lemontree.fi'
}
