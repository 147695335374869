<div class="list-page kapea" style="min-width: 70vw">

  <div style="position: relative;" #laskutaulu>
    <mat-table class="laskut-table" matSort [dataSource]="dataSource" [matSortDisableClear]="true" style="min-height: 160px;">

      <ng-container matColumnDef="nro">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.numero' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.nro}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asiakas">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.asiakas' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.asiakas}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="erapvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.erapaiva' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.erapvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lataa">
        <mat-header-cell *matHeaderCellDef>Poista listalta</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-button style="margin: 0; min-width: 32px; padding: 0 2px;" (click)="poistaListalta(row); $event.stopPropagation();">
            X
          </button>
          <button mat-button style="margin: 0; min-width: 32px; padding: 0 2px;" (click)="poistaKaikkiListalta(row); $event.stopPropagation();">
            X 2
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.laskun-pvm' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.pvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="summa">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.summa' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.summa | lmoney: row.valuutta | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="kommentti">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.kommentti' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.juurilasku.kommentti }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="avoinna">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.avoinna' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="(row.tyyppi == 'v' || row.tyyppi == 't') && row.tila !== 'lu'">
            {{ row.avoinna | lmoney: row.valuutta | async }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tila">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.tila' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row" [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'">
          <ng-container *ngIf="row.lahetysEpaonnistui; then huutomerkki else tyhja">

          </ng-container>
          <ng-template #huutomerkki>
            <table style="border-spacing: 0;">
              <tr>
                <td [title]="'laskut.lahettaminen-epaonnistui' | translate | async" style="color: red; font-size: 32px; font-weight: bold; vertical-align: middle; padding-right: 4px;">
                  !
                </td>
                <td style="vertical-align: top;">
                  <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
                    {{'lasku.tila.' + row.tila | translate | async}}
                  </ng-container>
                </td>
              </tr>
            </table>
          </ng-template>
          <ng-template #tyhja>
            <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
              {{'lasku.tila.' + row.tila | translate | async}}
            </ng-container>
          </ng-template>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="propertiesToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: propertiesToDisplay" [class.lemon-lapsi]="row.tyyppi == 'l'" [class.lemon-lapsi-viimeinen]="row.tyyppi == 'lv'" [class.lemon-vanhempi]="row.tyyppi == 'v'"></mat-row>

    </mat-table>

    <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!lataa && matDataSource.filteredData?.length == 0">
      <div style="width: 100%; padding-top: 40px; text-align: center;">
        {{'laskut.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
      </div>
    </div>
    <div lemon-loading [config]="loadingSettings" [show]="lataa"></div>
  </div>


  <button mat-button (click)="lahetaMuistutuslaskut()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>email</mat-icon>
    Lähetä muistutuslaskut
  </button>

</div>