<mat-dialog-content>

  <lemon-dialog-header>
    Muistutus
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate style="margin: 0;">
    <div class="dark-gray-text" style="width: 400px; padding-left: 3em; padding-right: 3em;">
      <p>Asiakas on hyvä kontaktoida jos tauko jatkuu pitkään. Tauko on nyt kestänyt {{tauonKesto}}.</p>
      <p>Valitse seuraava muistutusajankohta:</p>

      <mat-form-field style="max-width: 200px; margin-right: 10px;">
        <mat-label>Muistutuspvm</mat-label>
        <input name="Muistutuspvm" type="text" [matDatepicker]="pickerMuistutus" formControlName="muistutuspvm" matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="pickerMuistutus"></mat-datepicker-toggle>
        <mat-error *ngIf="form?.get('muistutuspvm')?.errors?.required && (form?.get('muistutuspvm')?.dirty || form?.get('muistutuspvm')?.touched)">
          Muistutuspäivä puuttuu
        </mat-error>
        <mat-error *ngIf="form?.get('muistutuspvm')?.errors?.minDate && (form?.get('muistutuspvm')?.dirty || form?.get('muistutuspvm')?.touched)">
          Pvm pitää olla tulevaisuudessa
        </mat-error>
        <mat-datepicker #pickerMuistutus></mat-datepicker>
      </mat-form-field>

      <!-- <i class="icon ion-ios-information-circle-outline info" [matTooltip]="muistutusPvmText" aria-hidden="true"></i> -->

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button (click)="tallenna()">Tallenna</button>
</mat-dialog-actions>