<div class="list-page">
  <h2>Sopimukset</h2>
  <form (ngSubmit)="save()" [formGroup]="form">
    <table style="width: 100%;">
      <tr>
        <td style="vertical-align: top;">
          <div style="max-width: 600px; margin: 0 auto;">
            <h2>Suomi</h2>
            <quill-editor [formControl]="textfi"></quill-editor>
            <mat-error *ngIf="textfi?.errors?.required && (textfi?.dirty || textfi?.touched)">
              Sopimus puuttuu
            </mat-error>
            <strong>Have not been used:</strong>
            <div *ngFor="let usableVarFi of usableVariablesSuomiObservable | async">{{usableVarFi}}</div>
          </div>
        </td>
        <td style="vertical-align: top;">
          <div style="max-width: 600px; margin: 0 auto;">
            <h2>Englanti</h2>
            <quill-editor [formControl]="texten"></quill-editor>
            <mat-error *ngIf="texten?.errors?.required && (texten?.dirty || texten?.touched)">
              Sopimus puuttuu
            </mat-error>
            <strong>Have not been used:</strong>
            <div *ngFor="let usableVarEn of usableVariablesEnglishObservable | async">{{usableVarEn}}</div>
          </div>
        </td>
      </tr>
    </table>
    <div style="max-width: 600px;">
      <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
        <button class="secondarybutton" mat-button (click)="save()" type="button">Tallenna</button>
        <button class="primarybutton" mat-button (click)="julkaise()" type="button">Julkaise</button>
      </div>
    </div>
  </form>
  <h2 style="margin-top: 100px">Julkaistut sopimukset</h2>
  <div *ngFor="let versio of sopimustenVersiotObservable | async" style="padding-left: 20px; padding-top: 50px;">
    <div class="light-blue-text" style="font-size: 12px; text-align: right; padding-right: 4px;">Julkaistu: {{versio.kirjanpitaja}} {{versio.aika}}</div>
    <div style="display: flex; justify-content: space-evenly;">
      <div style="max-width: 600px;">
        <h2>Suomi</h2>
        <div class="mat-elevation-z4" style="margin-left: 20px; max-width: 650px;">
          <div style="padding: 5px 20px 20px 20px; margin: 10px;" [innerHtml]="versio.tekstifi"></div>
        </div>
      </div>
      <div style="max-width: 600px;">
        <h2>Englanti</h2>
        <div class="mat-elevation-z4" style="margin-left: 20px; max-width: 650px;">
          <div style="padding: 5px 20px 20px 20px; margin: 10px;" [innerHtml]="versio.tekstien"></div>
        </div>
      </div>
    </div>
  </div>

</div>