import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ErrorHandler, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'

import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'

import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { Maa, MaaService } from 'app/_jaettu-angular/service/maa.service'
import { KycUriService } from '../../../../_jaettu/service/kyc-uri.service'

import { Asiakas } from '../../../../_jaettu-lemonator/model/asiakas'
import { BehaviorSubject, combineLatest, distinctUntilKeyChanged, map, Observable, of as observableOf, Subject, switchMap, takeUntil, tap } from 'rxjs'
import { CustomerBeneficiary, CustomerHallituksenJasen, CustomerOsakas, KnowYourCustomer, KnowYourCustomerSeenLog, Yritysmuoto } from '../../../../_jaettu/model/kayttaja'
import { TilinpaatosUserConversionWorkQueue } from 'app/_jaettu-lemonator/model/tilinpaatos'

import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'

interface KycLastSeenTexts {
  kayttajanNimi: string
  /** Converted from timestamp to string */
  seenAtString: string
}
@Component({
  selector: 'app-osakkaat-hallitus-edunsaajat',
  templateUrl: './osakkaat-hallitus-edunsaajat.component.html',
  styleUrls: ['../tuntemistiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasAsetuksetOsakkaatHallitusEdunsaajatComponent implements OnInit, OnDestroy {

  @Input() set asiakas(asiakas: Asiakas) {
    this._asiakasSubject.next(asiakas)
  }

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  commonError: string
  namename = 'asdf ' + Math.random()

  onkoTmi: boolean

  selectedHistorySubject: BehaviorSubject<KnowYourCustomer> = new BehaviorSubject(null)
  deletedOsakkaatText: string
  deletedHallitusText: string
  deletedEdunsaajatText: string

  private tunnistamistiedot: KnowYourCustomer = {
    asiakasAvain: null,
    avain: null,
    edunsaajat: [],
    hallitus: [],
    luoja: null,
    luotu: null,
    osakkaat: [],
    paivitetty: null,
    paivittaja: null,
    poistettu: false
  }

  private _asiakasSubject: BehaviorSubject<Asiakas> = new BehaviorSubject(null)
  asiakasObservable: Observable<Asiakas> = this._asiakasSubject.asObservable().pipe(distinctUntilKeyChanged('avain'))
  knowYourCustomerObservable: Observable<KnowYourCustomer>
  knowYourCustomerLastUpdatedObservable: Observable<string>
  kansallisuudetObservable: Observable<Maa[]>
  hallitusRoolitObservable: Observable<{ avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]>
  kycLastSeenLogObservable: Observable<KycLastSeenTexts>

  isBrowsingHistory: boolean
  prevActive: boolean
  nextActive: boolean
  versioLuotu: string
  historyChangesMap: Map<string, string> = new Map()

  minDate = new Date(1900, 1, 1)
  maxDate = new Date(2100, 1, 1)

  @Output() tuntemistiedotAntamatta: EventEmitter<boolean> = new EventEmitter()

  constructor(
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _copyService: KopioijaPalvelu,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _translationService: LemonTranslationService,
    private _timestampService: TimestampService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler,
    private _maaService: MaaService,
    private _dialog: MatDialog,
    private _asiakasService: AsiakasService,
    private _validationService: FormValidationService,
    private _dateService: DateService,
    private _kycUriService: KycUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      'osakkaat': new UntypedFormArray([], FormValidators.vahintaanYksiArrayssaValidator),
      'hallitus': new UntypedFormArray([], FormValidators.vahintaanYksiArrayssaValidator),
      'edunsaajat': new UntypedFormArray([])
    })

    this.knowYourCustomerObservable = this.asiakasObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          if (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI) {
            this.tuntemistiedotAntamatta.emit(false) // Tuntemistiedot not needed for tmi
            return observableOf<KnowYourCustomer>(null)
          }
          if (asiakas.yritysmuoto === Yritysmuoto.AVOINYHTIO || asiakas.yritysmuoto === Yritysmuoto.KOMMANDIITTIYHTIO) {
            this.hallitusFormArray.removeValidators(FormValidators.vahintaanYksiArrayssaValidator)
          }

          return this._firebaseLemonaid.firestoreDoc<KnowYourCustomer>(this._kycUriService.getCustomerKycDocUri(asiakas.avain)).listen().pipe(
            tap(kyc => {
              if (asiakas && asiakas.yritysmuoto !== Yritysmuoto.TOIMINIMI && asiakas.yritysmuoto !== Yritysmuoto.AVOINYHTIO && !kyc?.osakkaat?.length) {
                this.tuntemistiedotAntamatta.emit(true)
              } else {
                this.tuntemistiedotAntamatta.emit(false)
              }
            }),
            map(kyc => {
              if (kyc) {
                return kyc
              }
              const blankKyc: KnowYourCustomer = this._createBlankKyc()
              return blankKyc
            })
          )
        }
        return observableOf(null)
      })
    )

    this.knowYourCustomerLastUpdatedObservable = combineLatest([this.knowYourCustomerObservable, this._asiakasService.nykyisenAsiakkaanKayttajatObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([data, kayttajat, kirjanpitajienNimitiedotMap]) => {
        if (data?.paivitetty && data?.paivittaja && kayttajat) {
          let kayttajanNimi = null
          const kp = kirjanpitajienNimitiedotMap.get(data.paivittaja)
          if (kp) {
            kayttajanNimi = kp.etunimi + ' ' + kp.sukunimi + ' (kirjanpitäjä)'
          } else {
            const kayttaja = kayttajat.find(k => k.avain === data.paivittaja)
            kayttajanNimi = kayttaja ? (kayttaja.etunimi + ' ' + kayttaja.sukunimi) : 'Tuntematon käyttäjä'
          }
          const aikaleima = this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(data.paivitetty), 'fi')
          return kayttajanNimi + ' ' + aikaleima
        }
        return ''
      })
    )

    this.kycLastSeenLogObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._asiakasService.nykyisenAsiakkaanKayttajatObservable]).pipe(
      switchMap(([asiakas, kayttajat]) => {
        if (!asiakas?.avain) {
          return observableOf<KycLastSeenTexts>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<KnowYourCustomerSeenLog>(this._kycUriService.getCustomerKycSeenLogDocUri(asiakas.avain)).listen().pipe(
          map(kycSeenLog => {
            if (!kycSeenLog) {
              return null
            }
            const kayttaja = kayttajat?.find(k => k.avain === kycSeenLog.kayttajaAvain)
            const kycLastSeenTexts: KycLastSeenTexts = {
              kayttajanNimi: kayttaja ? (kayttaja.etunimi + ' ' + kayttaja.sukunimi) : 'Tuntematon käyttäjä',
              seenAtString: this._dateService.muotoilePaivaJaAikaDate(kycSeenLog.seenAt.toDate(), 'fi')
            }
            return kycLastSeenTexts
          })
        )
      }
      )
    )

    this.kansallisuudetObservable = this._maaService.kaikkiMaatObservable.pipe(
      map(kaikkiMaat => {
        const maatCopy = [...kaikkiMaat]
        // Make selecting a bit easier
        const suomi = kaikkiMaat.find(maa => maa.koodi === 'FIN')
        const ruotsi = kaikkiMaat.find(maa => maa.koodi === 'SWE')
        maatCopy.unshift({ nimi: '---', koodi: null })
        maatCopy.unshift(ruotsi)
        maatCopy.unshift(suomi)
        return maatCopy
      })
    )

    this.hallitusRoolitObservable = this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(kieli => {
        const roolit = [
          { avain: 'puheenjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-puheenjohtaja', kieli === 'en' ? 'en' : 'fi') },
          { avain: 'jasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-jasen', kieli === 'en' ? 'en' : 'fi') },
          { avain: 'toimitusjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.toimitusjohtaja', kieli === 'en' ? 'en' : 'fi') },
          { avain: 'varajasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-varajasen', kieli === 'en' ? 'en' : 'fi') }
        ] as { avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]

        return roolit
      })
    )

    combineLatest([
      this.knowYourCustomerObservable,
      this.selectedHistorySubject
    ]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([current, history]) => {
      if (current) {
        this.tunnistamistiedot = this._copyService.cloneObjectDeep(current)

        this.nextActive = current.paivitetty?.toMillis() !== history?.paivitetty?.toMillis()

        if (this.isBrowsingHistory && history) {
          this._alustaKycLomakkeenTiedot(history)
          this.form.disable()
        } else {
          this._alustaKycLomakkeenTiedot(this.tunnistamistiedot)
        }
        this._changeDetectorRef.markForCheck()
      }
    })

    this.asiakasObservable.pipe(
      map(asiakas => {
        return asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI
      })
    ).subscribe(onkoTmi => {
      this.onkoTmi = onkoTmi

      if (onkoTmi) {
        this.osakkaatFormArray.disable()
        this.hallitusFormArray.disable()
        this.edunsaajatFormArray.disable()
      }
      this.selectDisplayedHistory('init')
    })

  }

  get osakasFormGroupit(): UntypedFormGroup[] {
    return this.osakkaatFormArray.controls as UntypedFormGroup[]
  }
  get hallitusFormGroupit(): UntypedFormGroup[] {
    return this.hallitusFormArray.controls as UntypedFormGroup[]
  }
  get edunsaajatFormGroupit(): UntypedFormGroup[] {
    return this.edunsaajatFormArray.controls as UntypedFormGroup[]
  }
  get osakkaatFormArray(): UntypedFormArray {
    return this.form.get('osakkaat') as UntypedFormArray
  }
  get hallitusFormArray(): UntypedFormArray {
    return this.form.get('hallitus') as UntypedFormArray
  }
  get edunsaajatFormArray(): UntypedFormArray {
    return this.form.get('edunsaajat') as UntypedFormArray
  }

  lisaaUusiOsakas(firestoresta?: CustomerOsakas) {
    const osakas: CustomerOsakas = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      osakkeita: null,
      kansallisuus: null,
      pep: null,
      noFinnishId: null,
      birthDate: null,
      onkoYritys: null
    }

    const osakasFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(osakas.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(osakas.hetuTaiYtunnus, [Validators.required, FormValidators.finnishHetuYtunnusOrVatNumberValidator]),
      'osakkeita': new UntypedFormControl(osakas.osakkeita, [Validators.required, Validators.min(1)]),
      'kansallisuus': new UntypedFormControl(osakas.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(osakas.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(false),
      'onkoYritys': new UntypedFormControl(null, [Validators.required]),
      'birthDate': new UntypedFormControl({ value: osakas.birthDate ? this._dateService.localDateToDate(osakas.birthDate) : null, disabled: true }),
    })

    osakasFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.nimi = value || null })
    osakasFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.hetuTaiYtunnus = value || null })
    osakasFormGroup.get('osakkeita').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.osakkeita = value || null })
    osakasFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.kansallisuus = value || null })
    osakasFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.pep = value ?? null })
    osakasFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.noFinnishId = value ?? false
      if (osakas.noFinnishId) {
        osakasFormGroup.get('onkoYritys').enable()

        if (!osakasFormGroup.get('onkoYritys').value) {
          osakasFormGroup.get('birthDate').enable()
        }
        osakasFormGroup.get('hetu').removeValidators(FormValidators.finnishHetuYtunnusOrVatNumberValidator)
      } else {
        osakasFormGroup.get('onkoYritys').disable()
        osakasFormGroup.get('birthDate').disable()
        osakasFormGroup.get('hetu').addValidators(FormValidators.finnishHetuYtunnusOrVatNumberValidator)
      }
      osakasFormGroup.get('hetu').updateValueAndValidity()
    })

    osakasFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && osakasFormGroup.get('birthDate').valid) {
        osakas.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    osakasFormGroup.get('onkoYritys').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.onkoYritys = value ?? null
      if (value) {
        osakasFormGroup.get('birthDate').disable()
      } else {
        osakasFormGroup.get('birthDate').enable()
      }
    })

    // Note! Do not change order of these setValues
    osakasFormGroup.get('onkoYritys').setValue(osakas.onkoYritys)
    osakasFormGroup.get('noFinnishId').setValue(osakas.noFinnishId)

    this.osakkaatFormArray.push(osakasFormGroup)

    if (!firestoresta) {
      this.tunnistamistiedot.osakkaat.push(osakas)
    }
  }

  poistaOsakas(index: number) {
    this.osakkaatFormArray.removeAt(index)
    this.tunnistamistiedot.osakkaat.splice(index, 1)
  }
  lisaaUusiHallituksenJasen(firestoresta?: CustomerHallituksenJasen) {
    const jasen: CustomerHallituksenJasen = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null,
      noFinnishId: null,
      birthDate: null,
      rooli: null
    }

    const jasenFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(jasen.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(jasen.hetuTaiYtunnus, [Validators.required, FormValidators.finnishPersonalIdValidator]),
      'kansallisuus': new UntypedFormControl(jasen.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(jasen.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: jasen.birthDate ? this._dateService.localDateToDate(jasen.birthDate) : null, disabled: true }),
      'rooli': new UntypedFormControl(jasen.rooli, [Validators.required])
    })

    jasenFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.nimi = value || null })
    jasenFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.hetuTaiYtunnus = value || null })
    jasenFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.kansallisuus = value || null })
    jasenFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.pep = value ?? null })
    jasenFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      jasen.noFinnishId = value ?? false
      if (jasen.noFinnishId) {
        jasenFormGroup.get('birthDate').enable()
        jasenFormGroup.get('hetu').removeValidators(FormValidators.finnishPersonalIdValidator)
      } else {
        jasenFormGroup.get('birthDate').disable()
        jasenFormGroup.get('hetu').addValidators(FormValidators.finnishPersonalIdValidator)
      }
      jasenFormGroup.get('hetu').updateValueAndValidity()
    })

    jasenFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && jasenFormGroup.get('birthDate').valid) {
        jasen.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    jasenFormGroup.get('rooli').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.rooli = value || null })


    jasenFormGroup.get('noFinnishId').setValue(jasen.noFinnishId)
    this.hallitusFormArray.push(jasenFormGroup)

    if (!firestoresta) {
      this.tunnistamistiedot.hallitus.push(jasen)
    }
  }

  poistaHallituksenJasen(index: number) {
    this.hallitusFormArray.removeAt(index)
    this.tunnistamistiedot.hallitus.splice(index, 1)
  }

  lisaaUusiEdunsaaja(firestoresta?: CustomerBeneficiary) {
    const saaja: CustomerBeneficiary = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null
    }

    const saajaFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(saaja.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(saaja.hetuTaiYtunnus, [Validators.required, FormValidators.finnishPersonalIdValidator]),
      'kansallisuus': new UntypedFormControl(saaja.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(saaja.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: saaja.birthDate ? this._dateService.localDateToDate(saaja.birthDate) : null, disabled: true })
    })

    saajaFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.nimi = value || null })
    saajaFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.hetuTaiYtunnus = value || null })
    saajaFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.kansallisuus = value || null })
    saajaFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.pep = value ?? null })
    saajaFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      saaja.noFinnishId = value ?? false
      if (saaja.noFinnishId) {
        saajaFormGroup.get('birthDate').enable()
        saajaFormGroup.get('hetu').removeValidators(FormValidators.finnishPersonalIdValidator)
      } else {
        saajaFormGroup.get('birthDate').disable()
        saajaFormGroup.get('hetu').addValidators(FormValidators.finnishPersonalIdValidator)
      }
      saajaFormGroup.get('hetu').updateValueAndValidity()
    })

    saajaFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && saajaFormGroup.get('birthDate').valid) {
        saaja.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    saajaFormGroup.get('noFinnishId').setValue(saaja.noFinnishId)

    this.edunsaajatFormArray.push(saajaFormGroup)
    if (!this.tunnistamistiedot.edunsaajat) {
      this.tunnistamistiedot.edunsaajat = []
    }
    if (!firestoresta) {
      this.tunnistamistiedot.edunsaajat.push(saaja)
    }
  }

  poistaEdunsaaja(index: number) {
    this.edunsaajatFormArray.removeAt(index)
    this.tunnistamistiedot.edunsaajat.splice(index, 1)
  }

  async tallenna() {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    try {
      const asiakas = this._asiakasSubject.value

      const batch = this._firebaseLemonaid.firestoreBatch()

      // Remove unnecessary data (birthDate & onkoYritys fields are disabled when the osakas has a Finnish hetu/y-tunnus)
      for (const osakas of this.tunnistamistiedot.osakkaat) {
        if (!osakas.noFinnishId) {
          osakas.birthDate = null
          osakas.onkoYritys = null
        }
      }

      const now = this._timestampService.now()
      this.tunnistamistiedot.asiakasAvain = asiakas.avain
      this.tunnistamistiedot.avain = asiakas.avain
      this.tunnistamistiedot.luotu = this.tunnistamistiedot.luotu || now
      this.tunnistamistiedot.paivitetty = now

      const kirjanpitajaTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      this.tunnistamistiedot.luoja = this.tunnistamistiedot.luoja || kirjanpitajaTiedot.uid
      this.tunnistamistiedot.paivittaja = kirjanpitajaTiedot.uid

      // Save KYC data
      const saveKycDoc = this._kycUriService.getCustomerKycDocUri(asiakas.avain)
      batch.set(saveKycDoc, this.tunnistamistiedot)

      // Create history entry
      const historyCopy = this._copyService.cloneObjectDeep(this.tunnistamistiedot)
      const historyAvain = this._firebaseLemonaid.firestoreCreateId()
      const kycHistoryDoc = this._kycUriService.getCustomerKycHistoryDocUri(asiakas.avain, historyAvain)
      historyCopy.avain = historyAvain
      batch.set(kycHistoryDoc, historyCopy)

      // Commit batch
      await batch.commit()

      // Create work queue for KYC -> TilinpaatosUser conversion
      const conversionData: TilinpaatosUserConversionWorkQueue = {
        asiakasAvain: asiakas.avain,
        startedAt: this._timestampService.now()
      }
      await this._firebase.firestoreSetData('tyojono/' + asiakas.avain + '/tilinpaatos-user-conversion/' + this._firebase.firestoreCreateId(), conversionData)

      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()

      // All is good, let's advance
      this._snackbar.open('Tallennus onnistui', 'OK', { duration: 5000, verticalPosition: 'top' })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    }
  }

  private _alustaKycLomakkeenTiedot(kyc: KnowYourCustomer) {
    this._setEmptyForm()

    for (const osakas of kyc.osakkaat) {
      this.lisaaUusiOsakas(osakas)
    }

    for (const hallituksenJasen of kyc.hallitus) {
      this.lisaaUusiHallituksenJasen(hallituksenJasen)
    }
    if (kyc.edunsaajat) {
      for (const edunsaaja of kyc.edunsaajat) {
        this.lisaaUusiEdunsaaja(edunsaaja)
      }
    }
  }
  private _createBlankKyc(): KnowYourCustomer {
    const blank: KnowYourCustomer = {
      asiakasAvain: '',
      osakkaat: [],
      hallitus: [],
      edunsaajat: [],
      avain: '',
      paivitetty: null,
      paivittaja: '',
      luotu: null,
      luoja: '',
      poistettu: false
    }
    return blank
  }

  private _setEmptyForm() {
    this.osakkaatFormArray.clear()
    this.hallitusFormArray.clear()
    this.edunsaajatFormArray.clear()
  }

  async vaadiTuntemistietojenPaivitysta() {

    const data: AreYouSureDialogData = {
      header: 'Oletko varma?',
      rightAction: 'Kyllä',
      leftAction: 'Ei',
      text: 'Tämä aktivoi tuntemistietojen antamisen ponnahdusikkunan asiakkaan Lemonaid-tilillä.'
    }

    const dialogHandle = this._dialog.open(AreYouSureDialog, { data: data })
    dialogHandle.afterClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(yes => {
      if (yes) {
        const asiakas = this._asiakasSubject.value
        asiakas.tuntemistietojenStatus = 'vaatii-paivityksen'

        this._ladataanService.aloitaLataaminen()

        return this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
          .finally(() => {
            this._ladataanService.lopetaLataaminen()
          })
      }
    })
  }

  async selectDisplayedHistory(direction: 'prev' | 'next' | 'init') {

    const asiakas = this._asiakasSubject.value
    const historiaCollection = this._kycUriService.getCustomerKycHistoryCollection(asiakas.avain)
    let historiaQuery = this._firebaseLemonaid.firestoreCollection<KnowYourCustomer>(historiaCollection)
    const currentLatestDate = this.selectedHistorySubject.value?.paivitetty || this._timestampService.now()

    if (direction === 'prev') {
      historiaQuery = historiaQuery.orderBy('paivitetty', 'desc').startAfter(currentLatestDate).limit(2)
    } else if (direction === 'next') {
      historiaQuery = historiaQuery.orderBy('paivitetty', 'asc').startAfter(currentLatestDate).limit(2)
    } else {
      historiaQuery = historiaQuery.orderBy('paivitetty', 'desc').limit(2)
    }
    const historia: KnowYourCustomer = await historiaQuery.get().then(resp => {
      if (!resp?.length) {
        return null
      }
      this.isBrowsingHistory = true

      const curr = resp?.[0]
      let prev = null

      if (direction === 'next') {
        const next = resp?.[1]
        this.nextActive = !!next
        prev = this.selectedHistorySubject.value
      } else {
        prev = resp?.[1]
      }

      this.prevActive = !!prev

      // Reset values
      this.historyChangesMap.clear()
      this._findDeletionsInHistory(curr, prev)
      this._mapHistoryChanges(curr, prev)
      return curr

    }).catch(err => {
      this._errorHandler.handleError(err)
      return null
    })
    if (historia) {
      this.selectedHistorySubject.next(historia)
    }
    this._ladataanService.lopetaLataaminen()
  }
  resetDisplayedHistory() {
    this.selectedHistorySubject.next(null)
  }

  private _mapHistoryChanges(curr: KnowYourCustomer, prev: KnowYourCustomer) {
    if (!prev) {
      return
    }
    // Compare osakkaat
    for (const [idx, currOsakas] of (curr?.osakkaat || []).entries()) {

      const prevOsakas = prev.osakkaat?.find(osakas => osakas.hetuTaiYtunnus?.trim() === currOsakas.hetuTaiYtunnus?.trim() || osakas.nimi?.toLowerCase() === currOsakas.nimi?.trim().toLowerCase()) || prev.osakkaat?.[idx]
      if (!prevOsakas) {
        this._setAllFieldsChangedInMap(idx, 'o')
        continue
      }
      this._compareHistoriesAddToMapIfNeeded(idx, 'o', currOsakas, prevOsakas)
    }

    // Compare hallitus
    for (const [idx, currJasen] of (curr.hallitus || []).entries()) {
      const prevJasen = prev.hallitus?.find(jasen => jasen.hetuTaiYtunnus?.trim() === currJasen.hetuTaiYtunnus?.trim() || jasen.nimi?.toLowerCase() === currJasen.nimi?.trim().toLowerCase()) || prev.hallitus?.[idx]
      if (!prevJasen) {
        this._setAllFieldsChangedInMap(idx, 'h')
        continue
      }
      this._compareHistoriesAddToMapIfNeeded(idx, 'h', currJasen, prevJasen)
    }

    // Compare edunsaajat
    for (const [idx, currEdunsaaja] of (curr.edunsaajat || []).entries()) {
      const prevEdunsaaja = prev.edunsaajat?.find(edunsaaja => edunsaaja.hetuTaiYtunnus?.trim() === currEdunsaaja.hetuTaiYtunnus?.trim() || edunsaaja.nimi?.toLowerCase() === currEdunsaaja.nimi?.trim().toLowerCase()) || prev.edunsaajat?.[idx]
      if (!prevEdunsaaja) {
        this._setAllFieldsChangedInMap(idx, 'e')
        continue
      }
      this._compareHistoriesAddToMapIfNeeded(idx, 'e', currEdunsaaja, prevEdunsaaja)
    }

  }

  private _setAllFieldsChangedInMap(idx: number, osakasHallitusOrEdunsaaja: 'o' | 'h' | 'e') {
    this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'hetuTaiYtunnus', 'changed')
    this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'nimi', 'changed')
    this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'pep', 'changed')
    this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'kansallisuus', 'changed')

    if (osakasHallitusOrEdunsaaja === 'o') {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'osakkeita', 'changed')
    }
    if (osakasHallitusOrEdunsaaja === 'h') {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'rooli', 'changed')
    }
  }

  private _compareHistoriesAddToMapIfNeeded(idx: number, osakasHallitusOrEdunsaaja: 'o' | 'h' | 'e', curr: CustomerOsakas | CustomerHallituksenJasen | CustomerBeneficiary, prev: CustomerOsakas | CustomerHallituksenJasen | CustomerBeneficiary) {
    if (curr.hetuTaiYtunnus?.trim().toLowerCase() !== prev.hetuTaiYtunnus?.trim().toLowerCase()) {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'hetuTaiYtunnus', 'changed')
    }
    if (curr.nimi?.trim().toLowerCase() !== prev.nimi?.trim().toLowerCase()) {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'nimi', 'changed')
    }
    if (curr.kansallisuus !== prev.kansallisuus) {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'kansallisuus', 'changed')
    }
    if (curr.pep !== prev.pep) {
      this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'pep', 'changed')
    }
    if (osakasHallitusOrEdunsaaja === 'o') {
      if ((curr as CustomerOsakas).osakkeita !== (prev as CustomerOsakas).osakkeita) {
        this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'osakkeita', 'changed')
      }
    }
    if (osakasHallitusOrEdunsaaja === 'h') {
      if ((curr as CustomerHallituksenJasen).rooli !== (prev as CustomerHallituksenJasen).rooli) {
        this.historyChangesMap.set(idx + osakasHallitusOrEdunsaaja + 'rooli', 'changed')
      }
    }
  }

  private _findDeletionsInHistory(curr: KnowYourCustomer, prev: KnowYourCustomer) {
    // Reset values
    this.deletedOsakkaatText = null
    this.deletedHallitusText = null
    this.deletedEdunsaajatText = null

    const deletedOsakkaat = this._compareHistoriesForDeletions(curr.osakkaat, prev?.osakkaat)
    if (deletedOsakkaat.length) {
      this.deletedOsakkaatText = 'Poistettiin: ' + deletedOsakkaat.map(d => d.nimi + ' (' + d.hetuTaiYtunnus + ')').join(', ') + '.'
    }

    const deletedHallitus = this._compareHistoriesForDeletions(curr.hallitus, prev?.hallitus)
    if (deletedHallitus.length) {
      this.deletedHallitusText = 'Poistettiin: ' + deletedHallitus.map(d => d.nimi + ' (' + d.hetuTaiYtunnus + ')').join(', ') + '.'
    }

    const deletedEdunsaajat = this._compareHistoriesForDeletions(curr.edunsaajat, prev?.edunsaajat)
    if (deletedEdunsaajat.length) {
      this.deletedEdunsaajatText = 'Poistettiin: ' + deletedEdunsaajat.map(d => d.nimi + ' (' + d.hetuTaiYtunnus + ')').join(', ') + '.'
    }

  }


  private _compareHistoriesForDeletions(curr: CustomerOsakas[] | CustomerBeneficiary[] | CustomerHallituksenJasen[], prev: CustomerOsakas[] | CustomerBeneficiary[] | CustomerHallituksenJasen[]) {
    if (curr.length >= (prev?.length ?? 0)) {
      // There are more items in the current array than in the previous one, meaning that nothing was deleted.
      return []
    }
    const deletedSincePrev: any[] = []

    // Some osakkaat were deleted
    for (const p of (prev || [])) {
      const isDeleted = curr?.findIndex(c => p.hetuTaiYtunnus?.trim() === c.hetuTaiYtunnus?.trim() || p.nimi?.toLowerCase() === c.nimi?.trim().toLowerCase()) === -1
      if (isDeleted) {
        deletedSincePrev.push(p)
      }
    }
    return deletedSincePrev
  }

  editForm() {
    this.isBrowsingHistory = false
    this.selectedHistorySubject.next(null)
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
