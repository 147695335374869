import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import { AsiakasService, AsiakkaanAvainTiedot } from '../../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../../_shared-core/service/date.service'
import { MaaService } from '../../../_jaettu-angular/service/maa.service'

import { Yritysmuoto, UserKnowYourCustomer } from '../../../_jaettu/model/kayttaja'
import { Asiakas, Asiakastiedot, Kayttaja, Tilikausi } from '../../../_jaettu-lemonator/model/asiakas'
import { SopimuksenHyvaksynnanHistoria } from '../../../_jaettu/model/sopimus'

import { BehaviorSubject, combineLatest, Observable, of as observableOf, Subject } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { KycUriService } from '../../../_jaettu/service/kyc-uri.service'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'


interface Hyvaksyntatiedot {
  versio: string
  aika: string
  allekirjoittaja: string
}

interface AsiakkaanEdustaja {
  edustajanNimi: string
  tunnistautujanNimi: string
  hetu: string
  tunnistustapa: string
  tunnistautumisaika: string
  pep: string
  kansallisuus: string
}

@Component({
  templateUrl: './tuntemistiedot.component.html',
  styleUrls: ['./tuntemistiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsiakasAsetuksetTuntemistiedotComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  commonError: string

  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()
  onkoTmi: boolean

  perustiedotObservable: Observable<{ nimi: string, yritysmuoto: string, ytunnus: string, tilikausi: string }>

  private _tuntemistiedotAntamattaSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  tuntemistiedotAntamattaObservable: Observable<boolean> = this._tuntemistiedotAntamattaSubject.asObservable()

  asiakkaanEdustajatObservable: Observable<AsiakkaanEdustaja[]>
  asiakkaanSopimusHyvaksynnatObservable: Observable<Hyvaksyntatiedot[]>
  asiakasObservable: Observable<Asiakas>
  kuvausObservable: Observable<string>

  asiakastiedotUriObservable: Observable<string>
  asiakastiedotEncodedUriObservable: Observable<string>
  kycUriObservable: Observable<string>
  kycEncodedUriObservable: Observable<string>
  selvityksetUriObservable: Observable<string>
  selvityksetEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  private _riskiarvioEditActiveErrorsSubject: BehaviorSubject<{
    edustajaTunnistamatta: boolean
    tuntemistiedotAntamatta: boolean
    kuvausPuuttuu: boolean
  }> = new BehaviorSubject(null)
  riskiarvioEditActiveErrorsObservable = this._riskiarvioEditActiveErrorsSubject.asObservable()

  private _nykyisetAsiakastiedotObservable: Observable<Asiakastiedot>

  constructor(
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _maaService: MaaService,
    private _kycUriService: KycUriService,
    private _asiakasUriService: AsiakasUriService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) { }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.asiakastiedotUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return this._asiakasUriService.annaAsiakastiedotUri(avainTiedot.avain)
        }
        return ''
      })
    )
    this.asiakastiedotEncodedUriObservable = this.asiakastiedotUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )
    this.kycUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return this._kycUriService.getCustomerKycDocUri(avainTiedot.avain)
        }
        return ''
      })
    )
    this.kycEncodedUriObservable = this.kycUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )
    this.selvityksetUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return this._asiakasUriService.annaRahanpesunSelvitysCollection(avainTiedot.avain)
        }
        return ''
      })
    )
    this.selvityksetEncodedUriObservable = this.selvityksetUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable

    this.perustiedotObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas) {
          const currentTilikausi: Tilikausi = asiakas.tilikaudet.find(tilikausi => this._dateService.onkoLocalDateKahdenValissa(this._dateService.currentLocalDate(), tilikausi.alkaa, tilikausi.loppuu))
          const currentTilikausiText = currentTilikausi ? (this._dateService.muotoilePaikallinenPaiva(currentTilikausi.alkaa, 'fi') + '-' + this._dateService.muotoilePaikallinenPaiva(currentTilikausi.loppuu, 'fi')) : ''
          return { nimi: asiakas.nimi, yritysmuoto: this._annaYhtiomuodonNimi(asiakas.yritysmuoto), ytunnus: asiakas.ytunnus, tilikausi: currentTilikausiText }
        }
        return { nimi: '', yritysmuoto: '', ytunnus: '', tilikausi: '' }
      })
    )

    const asiakkaanYhteyshenkilotObservable: Observable<Kayttaja[]> = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        if (!kayttajat?.length) {
          return []
        }
        return kayttajat.filter(k => k.roolit.HALLINTO_YHTEYSHENKILO)
      })
    )

    this.asiakkaanEdustajatObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, asiakkaanYhteyshenkilotObservable]).pipe(
      switchMap(([asiakas, yhteyshenkilot]) => {
        if (!yhteyshenkilot?.length) {
          return Promise.resolve([])
        }
        return Promise.all(yhteyshenkilot.map(yh => this._annaEdustaja(asiakas, yh)))
      })
    )

    this.asiakkaanSopimusHyvaksynnatObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._asiakasService.nykyisenAsiakkaanKayttajatObservable]).pipe(
      switchMap(([avaintiedot, kayttajat]) => {

        if (!avaintiedot?.avain || !kayttajat?.length) {
          return observableOf<Hyvaksyntatiedot[]>([])
        }

        const observables: Observable<Hyvaksyntatiedot[]>[] = kayttajat.map(kayttaja => {
          return this._firebaseLemonaid.firestoreCollection<SopimuksenHyvaksynnanHistoria>('customers/' + avaintiedot.avain + '/customers-users/' + kayttaja.avain + '/sopimus-hyvaksynnat/').orderBy('pvm', 'desc').listen().pipe(
            map(hyvaksynnat => {
              return hyvaksynnat.map(hyv => {
                const allekirjoitajanNimi = kayttaja.etunimi + ' ' + kayttaja.sukunimi
                return { versio: 'Versio ' + hyv.sopimusAvain, aika: this._dateService.muotoilePaivaJaAikaDate(hyv.pvm.toDate(), 'fi'), allekirjoittaja: 'Allekirjoittaja: ' + allekirjoitajanNimi }
              })
            })
          )
        })
        return combineLatest(observables)
      }),
      map(arraysOfArrays => [].concat(...arraysOfArrays))
    )

    this._nykyisetAsiakastiedotObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreDoc<Asiakastiedot>(this._asiakasUriService.annaAsiakastiedotUri(asiakas.avain)).listen().pipe(
            map(asiakastiedot => {
              return asiakastiedot ?? null
            })
          )
        }
        return observableOf(null)
      })
    )

    this.kuvausObservable = this._nykyisetAsiakastiedotObservable.pipe(
      map(asiakastiedot => asiakastiedot?.kuvausKuvaus?.replace(/\n/g, '<br/>') || null)
    )


    combineLatest([
      asiakkaanYhteyshenkilotObservable,
      this.kuvausObservable,
      this.tuntemistiedotAntamattaObservable
    ]).pipe(
      map(([edustajat, kuvaus, tuntemistiedotAntamatta]) => {

        const edustajaTunnistamatta = edustajat.filter(edustaja => edustaja.kayttajaTunnistettu === 'tunnistettu-nets').length === 0

        if (edustajaTunnistamatta || !kuvaus || tuntemistiedotAntamatta) {
          return {
            edustajaTunnistamatta: edustajaTunnistamatta,
            kuvausPuuttuu: !kuvaus,
            tuntemistiedotAntamatta: tuntemistiedotAntamatta
          }
        }
        return null
      })
    ).subscribe(errors => {
      if (errors) {
        this._riskiarvioEditActiveErrorsSubject.next(errors)
      }
    })

  }

  private async _annaEdustaja(asiakas: AsiakkaanAvainTiedot, yhteyshenkilo: Kayttaja): Promise<AsiakkaanEdustaja> {
    const userKyc: UserKnowYourCustomer = await this._firebaseLemonaid.firestoreDoc<UserKnowYourCustomer>(this._kycUriService.getUserKycUri(asiakas.avain, yhteyshenkilo.avain)).get()

    const edustaja: AsiakkaanEdustaja = {
      edustajanNimi: yhteyshenkilo.etunimi + ' ' + yhteyshenkilo.sukunimi,
      tunnistautujanNimi: userKyc?.tunnistajanNimi || '',
      hetu: yhteyshenkilo.hetu,
      tunnistustapa: this._annaTunnistamistapa(yhteyshenkilo),
      tunnistautumisaika: userKyc?.luotu ? this._dateService.muotoilePaivaJaAikaDate(userKyc.luotu.toDate(), 'fi') : null,
      pep: userKyc ? (userKyc?.pep ? 'Kyllä' : 'Ei') : null,
      kansallisuus: userKyc?.kansallisuus ? this._maaService.getName(userKyc?.kansallisuus, 'fi') : null
    }
    return edustaja
  }


  private _annaYhtiomuodonNimi(yritysmuoto: Yritysmuoto) {
    switch (yritysmuoto) {
      case Yritysmuoto.AVOINYHTIO:
        return 'Avoin yhtiö'
      case Yritysmuoto.KOMMANDIITTIYHTIO:
        return 'Kommandiittiyhtiö'
      case Yritysmuoto.OSAKEYHTIO:
        return 'Osakeyhtiö'
      case Yritysmuoto.OSUUSKUNTA:
        return 'Osuuskunta'
      case Yritysmuoto.TOIMINIMI:
        return 'Toiminimi'
      case Yritysmuoto.YHDISTYS:
        return 'Yhdistys'
      default:
        return 'Tuntematon yhtiömuoto'
    }
  }

  private _annaTunnistamistapa(kayttaja: Kayttaja): string {
    if (kayttaja?.kayttajaTunnistettu === 'tunnistettu-hetulla') {
      return 'Hetu'
    }
    if (kayttaja?.kayttajaTunnistettu === 'tunnistettu-nets') {
      return 'Vahva tunnistaminen'
    }
    return null
  }

  updateTuntemistiedotAntamattaValidation(onAntamatta: boolean) {
    this._tuntemistiedotAntamattaSubject.next(onAntamatta)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
