import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DebugService } from 'app/_angular/service/debug.service'
import { AjastettuTyo, AjastettuTyoService } from 'app/_jaettu/eraajot/ajastettu/ajastetut-tyot.service'

@Component({
  templateUrl: './ajastetut-tyot-data.dialog.html',
  styleUrls: ['./ajastetut-tyot-data.dialog.css'],
})
export class AjastetutTyotDataDialog {

  link: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AjastettuTyo<any>,
    private _debugService: DebugService,
    private _ajastettuTyoService: AjastettuTyoService
  ) {
    this.link = this._debugService.createFirestoreLink(this._ajastettuTyoService.collectionName + '/' + data.avain)
  }

}
