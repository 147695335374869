<div class="tilinpaatos-container">

  <mat-form-field appearance="fill" style="margin-top: 20px;">
    <mat-label>Tilikausi</mat-label>
    <mat-select [value]="selectedTilikausiObservable | async" (selectionChange)="setTilikausiEvent($event)" [compareWith]="compareTilikaudet">
      <mat-option *ngFor="let tilikausi of tilikaudetObservable | async" [value]="tilikausi">{{ tilikausi?.loppuu?.year }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="!tilinpaatosNotStarted; else start" class="tilinpaatos-btn-group">
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'tase-erittely'" (click)="setSelectedTab('tase-erittely')">
      <div>Tase-erittely</div>
    </button>
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'liitetiedot'" (click)="setSelectedTab('liitetiedot')">
      <div>Liitetiedot</div>
    </button>
    <!-- <button *ngIf="tilintarkastetaanObservable | async" class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'tilintarkastus'" (click)="setSelectedTab('tilintarkastus')">
      <div>Tilintarkastus</div>
    </button> -->
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'lahetys'" (click)="setSelectedTab('lahetys')">
      <div>Lähetys</div>
    </button>
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'rekisterointi'" (click)="setSelectedTab('rekisterointi')">
      <div>Rekisteröinti</div>
    </button>
  </div>

  <ng-template #start>
    <div style="padding-bottom: 10px;">
      <div>Tilinpäätöksessä käytettävä tuloslaskelma:</div>
      <mat-radio-group name="tilinpaatoksenTuloslaskelma" [(ngModel)]="tilinpaatoksenTuloslaskelma">
        <mat-radio-button [value]="TilinpaatoksenTuloslaskelmanTyyppi.VIRALLINEN">Virallinen</mat-radio-button>
        <mat-radio-button [value]="TilinpaatoksenTuloslaskelmanTyyppi.BRUTTO">Brutto</mat-radio-button>
      </mat-radio-group>
    </div>

    <button mat-button class="primarybutton" (click)="startTilinpaatos()">Aloita tilinpäätöksen tekeminen</button>
  </ng-template>

  <ng-container *ngIf="!tilinpaatosNotStarted">
    <div *ngIf="selectedTab === 'tase-erittely'" app-kirjanpito-tilinpaatos-tase-erittely [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" (kirjaustaKlikattiin)="emitKirjausData($event)"></div>
    <div *ngIf="selectedTab === 'liitetiedot'" app-kirjanpito-tilinpaatos-liitetiedot [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
    <!-- <div *ngIf="selectedTab === 'tilintarkastus'" app-kirjanpito-tilinpaatos-tilintarkastus [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div> -->
    <div *ngIf="selectedTab === 'lahetys'" app-tilinpaatos-lahetys [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
    <div *ngIf="selectedTab === 'rekisterointi'" app-kirjanpito-tilinpaatos-rekisterointi [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
  </ng-container>

</div>