
export class ApixUriService {

  receivedInvoiceConfigCollectionName = 'apix-received-invoice-config'
  /**
   * Note! LemonAID URI
   */
  getReceivedInvoiceConfigUri(asiakasAvain: string) {
    return 'customers/' + asiakasAvain + '/' + this.receivedInvoiceConfigCollectionName + '/' + asiakasAvain
  }
  /**
   * Note! LemonAID URI
   */
  getReceivedInvoiceConfigHistoryUri(asiakasAvain: string, historyAvain: string) {
    return this.getReceivedInvoiceConfigUri(asiakasAvain) + '/' + this.receivedInvoiceConfigCollectionName + '-history/' + historyAvain
  }

  /** Note! LemonAID URI */
  getApixMetadataUri(asiakasAvain: string) {
    return 'customers/' + asiakasAvain + '/apix/' + asiakasAvain
  }


  /**
   * Note! LemonATOR URI
   */
  getReceivedInvoiceCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/apix-received-invoice'
  }
  /**
   * Note! LemonATOR URI
   */
  getReceivedInvoiceUri(asiakasAvain: string, invoiceAvain: string) {
    return this.getReceivedInvoiceCollection(asiakasAvain) + '/' + invoiceAvain
  }
  /**
  * Note! LemonATOR URI
  */
  getReceivedInvoiceTyojono1Uri(asiakasAvain: string, invoiceAvain: string) {
    return 'tyojono/' + asiakasAvain + '/apix-electronic-invoice-received-1/' + invoiceAvain
  }
  /**
  * Note! LemonATOR URI
  */
  getReceivedInvoiceTyojono2Uri(asiakasAvain: string, invoiceAvain: string) {
    return 'tyojono/' + asiakasAvain + '/apix-electronic-invoice-received-2/' + invoiceAvain
  }


  getLopetaMaksujenVastaanottoTyojonoUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/lopeta-maksujen-vastaanotto/' + tyojonoAvain
  }


}
