<div [formGroup]="projektiForm" class="projekti-container">
  <div *ngIf="projektitArrayObservable | async as kaikkiProjektitGroupit; else ladataan">
    <table formArrayName="projektitArray" *ngIf="kaikkiProjektitGroupit?.length; else eiProjekteja">
      <thead class="checkbox-title-row">
        <th></th>
        <th></th>
        <th class="checkbox-title">Lemonaidissa</th>
        <th class="checkbox-title">Kirjanpidossa</th>
        <th class="checkbox-title">Raporteilla</th>
      </thead>
      <tr *ngFor="let projektitFormGroup of kaikkiProjektitGroupit; let i = index;" [formGroupName]="i">
        <td>
          <mat-form-field class="projektin-nimi">
            <mat-label>Nimi</mat-label>
            <input #nimi [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
            <mat-error>
              <ng-container *ngIf="projektitFormGroup.get('nimi'); let n">
                <ng-container *ngIf="n?.errors?.required">Lisää nimi</ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="projektin-vari">
            <mat-label>Väri</mat-label>
            <mat-select [className]="colorNameAndClassMap[projektitFormGroup.get('vari').value]" style="font-weight: bold;" formControlName="vari" required>
              <mat-option value="pun"><span class="red-text">Punainen</span></mat-option>
              <mat-option value="sin"><span class="blue-text">Sininen</span></mat-option>
              <mat-option value="vsi"><span class="light-blue-text">Vaalean sininen</span></mat-option>
              <mat-option value="kel"><span class="yellow-text">Keltainen</span></mat-option>
              <mat-option value="vih"><span class="green-text">Vihreä</span></mat-option>
              <mat-option value="vio"><span class="purple-text">Violetti</span></mat-option>
              <mat-option value="har"><span class="light-gray-text">Harmaa</span></mat-option>
            </mat-select>
            <mat-error>
              <ng-container *ngIf="projektitFormGroup.get('vari'); let n">
                <ng-container *ngIf="n.errors?.required">Valitse väri</ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-checkbox class="checkbox" formControlName="viewableInLemonaid"></mat-checkbox>
        </td>
        <td>
          <mat-checkbox class="checkbox" formControlName="usableInLemonator"></mat-checkbox>
        </td>
        <td>
          <mat-checkbox class="checkbox" formControlName="viewableInLemonator"></mat-checkbox>
        </td>
      </tr>
    </table>
  </div>
  <ng-template #ladataan>
    <div style="padding: 90px 40px; text-align: center; font-size: 20px; color: darkgray;">
      Ladataan asiakkaan projekteja.
      <mat-progress-bar style="margin-top: 15px;" mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template>
  <ng-template #eiProjekteja>
    <div style="padding: 90px 40px; text-align: center; font-size: 20px; color: darkgray;">Asiakkaalla ei ole vielä yhtään projektia.</div>
  </ng-template>
</div>

<div *ngIf="showCommonError" class="error-text common-error">
  Projektien tallentamisen aikana tapahtui virhe. Virhe on tallennettu. Ole hyvä ja ota yhteys ylläpitoon.
</div>

<div class="dialog-action-container">
  <button mat-button (click)="createNewProject()" class="add-btn">
    <mat-icon>add</mat-icon> Lisää uusi projekti
  </button>
  <button (click)="saveValidProjektit()" class="primarybutton" mat-button>{{'yleiset.tallenna' | translate | async}}</button>
</div>