import { ListausAsiakas, AlvIlmoitusjakso, KirjanpitoKuukausiruutu, KirjanpitoKuukausiruudunTilinpaatoksenTila } from 'app/_jaettu-lemonator/model/asiakas'
import { KuukausiruudunTiedot, LukemanTyyppi } from 'app/asiakkaat/listaus/asiakkaat.datasource.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { Aikakone } from 'app/_jaettu-angular/service/aikakone'
import { Injectable } from '@angular/core'

@Injectable()
export class KirjanpitoKuukausiStripService {

  constructor(
    private _dateService: DateService,
    private _aikakone: Aikakone
  ) {

  }

  annaUusiKirjanpitoRuutuListaus(asiakas: ListausAsiakas, kuukausi: number): KirjanpitoKuukausiruutu {

    // Setup basic values
    const arvot: KirjanpitoKuukausiruutu = {
      a: asiakas.k,
      k: kuukausi,
      p: asiakas.p,
      alv: AlvIlmoitusjakso.TUNTEMATON
    }

    // Only if asiakas has such
    if (asiakas.s) {
      arvot.s = asiakas.s
    }

    return arvot

  }

  keraaRuudunLuokat(asiakas: ListausAsiakas, ruutu: KuukausiruudunTiedot, seuraavaRuutu: KirjanpitoKuukausiruutu, location: 'lista' | 'kirjanpito', valinta: LukemanTyyppi): string {

    // const logForAsiakasId = -1
    let luokat = 'a-l-r a-l-r-ot'

    const osaTilikautta = ruutu.bot

    if (
      !osaTilikautta ||
      (
        location === 'lista' &&
        asiakas.p !== 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' && // Herra Holvi
        (!asiakas.u || ruutu.k < asiakas.u)
      ) ||
      asiakas.bet // Asiakkaalla ei tilikausia
    ) {
      luokat += ' a-l-r-dis'
    }

    if (asiakas.bet) { // Asiakkaalla ei tilikausia
      luokat += ' a-l-r-et'
    }

    // Ruutu on deaktivoitu
    if (ruutu.bd) {
      luokat += ' a-l-r-har a-l-r-ei-aktiivinen'
    }

    if (ruutu.bl) {
      luokat += ' fa a-l-r-lukittu'
    }

    // Koko tilikausi on ok
    if (ruutu.bkt) {
      luokat += ' a-l-r-ok'
    }

    // Vasemman alakulman luokat
    if (location === 'lista' && ruutu.oor) {
      luokat += ' a-l-r-va-odottaa-osinko-rekisterointia'
    } else if (location === 'lista' && !ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_TILINPAATOKSEN_ALLEKIRJOITTAMISTA) {
      luokat += ' a-l-r-va-sinikyna'
    } else if (location === 'lista' && !ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_YHTIOKOKOUKSEN_POYTAKIRJAN_ALLEKIRJOITTAMISTA) {
      luokat += ' a-l-r-va-sininuija'
    } else if (location === 'lista' && !ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_REKISTEROINTIA) {
      luokat += ' a-l-r-va-oranssikyna'
    } else if (location === 'lista' && !ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_TILINTARKASTUSTA) {
      luokat += ' a-l-r-va-sinisuurennuslasi'
    } else if (valinta === 'minuutit') {
      // ruutu.va = ruutu.ka ? ruutu.ka : null
      if (ruutu.ka) {
        luokat += ' a-l-r-va-minuutit'
      }
    } else if (valinta === 'tehot') {
      // ruutu.va = ruutu.ka && ruutu.h ? this.laskeTeho(ruutu) : null
      if (ruutu.ka && ruutu.h) {
        luokat += ' a-l-r-va-tehot'
      }
    } else if (valinta === 'selvitettavat') {
      if (ruutu.d || ruutu.e) {
        luokat += ' a-l-r-va-selvitettavat'
      }
    }
    //  else {
    //   ruutu.va = null
    // }

    // Oikean alakulman luokat
    if (ruutu.u) {
      luokat += ' a-l-r-tospuusi'
    } else if (ruutu.c) {
      luokat += ' a-l-r-tosh'
    }

    // Email lähetetty ainakin kerran
    if (ruutu.bem) {
      luokat += ' a-l-r-email-lah'
    }

    if (osaTilikautta && (asiakas.u && ruutu.k >= asiakas.u)) {

      // ALV lähetys override (Accountant has marked the ALV ilmoitus to have been sent outside Lemonator.)
      if (ruutu.baok) {
        luokat += ' a-l-r-alvvih'
      } else if (ruutu.balv) { // ALV ilmoitus sent at least once
        if (ruutu.r) {
          luokat += ' a-l-r-alvpun'
        } else {
          luokat += ' a-l-r-alvvih'
        }
      } else if (!ruutu.bl) {

        // if (asiakas.k === 'Ljlf328RwpKfpGcfBa9X') {
        //   console.log('RUUTU ', ruutu)
        //   console.log('Asiakas ', asiakas)
        // }

        const yyyy = 2000 + Math.floor(ruutu.k / 100)
        const mm = ruutu.k % 100
        const nykyinenPvm = this._aikakone.annanNykyinenPaivamaara()
        // const nykyinenPvm = new Date(2020, 5, 8)

        // console.log('NYT', nykyinenPvm.getFullYear(), nykyinenPvm.getMonth() + 1, nykyinenPvm.getDate())
        // console.log(yyyy, mm, 13)

        if (ruutu.alv === AlvIlmoitusjakso.KK1) {

          if (new Date(yyyy, mm + 1, 13) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy, mm + 1, 6) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy, mm, 1) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        } else if (ruutu.alv === AlvIlmoitusjakso.KK3 && (mm === 3 || mm === 6 || mm === 9 || mm === 12 || seuraavaRuutu.alv !== ruutu.alv)) {

          if (new Date(yyyy, mm + 1, 13) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy, mm + 1, 6) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy, mm, 1) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        } else if (ruutu.alv === AlvIlmoitusjakso.KK12 && (mm === 12 || seuraavaRuutu.alv !== ruutu.alv)) {

          if (new Date(yyyy + 1, 2, 0) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy + 1, 2, -7) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy + 1, 1, 0) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        }

      }

    }

    // const haeHinta = (status && status.kirjanpitoAika) || (kuukaudenTiedot.pallurasin || kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun)
    // const hinta = haeHinta ? this._asiakasService.annaNykyinenHinta(asiakas, kuukausi) : null

    // if (hinta) {
    //   if (kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun) {
    //     asiakas.huutomerkkienSumma = asiakas.huutomerkkienSumma.plus(hinta.hinta)
    //   } else if (kuukaudenTiedot.pallurasin) {
    //     const erapaiva = this.annaSeuraavaErapaiva(asiakas.alvIlmoitusjakso, kuukausi)
    //     const palluraIlmestynyt = this.sininenPalluraIlmestynyt(asiakas.alvIlmoitusjakso, kuukausi)
    //     const paivia = this._dateService.laskeArkipaivatPaivienValilla(this._aikakone.annanNykyinenPaivamaara(), erapaiva)
    //     const paiviailmestymisenJaErapaivanValilla = this._dateService.laskeArkipaivatPaivienValilla(palluraIlmestynyt, erapaiva)
    //     asiakas.palluroidenJakauma.push({
    //       aika: kuukausi.year + '/' + kuukausi.month,
    //       hinta: hinta.hinta,
    //       erapaiva: erapaiva,
    //       paiviaErapaivaan: paivia,
    //       palluraIlmestynyt: palluraIlmestynyt,
    //       paiviailmestymisenJaErapaivanValilla: paiviailmestymisenJaErapaivanValilla
    //     })
    //     asiakas.vahennysProgressiosta = asiakas.vahennysProgressiosta.plus((hinta.hinta / paiviailmestymisenJaErapaivanValilla) * paivia)
    //     asiakas.dynaaminenHinta = asiakas.dynaaminenHinta.plus(paivia === 0 ? hinta.hinta : hinta.hinta / paivia)
    //     asiakas.palluroidenSumma = asiakas.palluroidenSumma.plus(hinta.hinta)
    //   }
    // }

    if (location === 'lista' && ruutu.ka && ruutu.kp) {
      if (ruutu.h) {
        const teho = this.laskeTeho(ruutu)
        if (teho < 100) { // [class.pun]='onkoTilikaudella && tehovari !== null && 100 > tehovari'
          luokat += ' a-l-r-pun-uusi'
        } else if (100 <= teho && teho <= 200) { // [class.kel]='onkoTilikaudella && tehovari !== null && 200 >= tehovari && tehovari >= 100'
          luokat += ' a-l-r-kel-uusi'
        } else { // [class.vih]='onkoTilikaudella && tehovari !== null && tehovari > 200'
          luokat += ' a-l-r-vih-uusi'
        }
        // const nyt = this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara())
        // if (status.kirjanpitoPvm && this._dateService.paiviaValissaPaikallinen(status.kirjanpitoPvm, nyt) === 0) {
        //   asiakas.paivanSaldo = asiakas.paivanSaldo.plus(hinta.hinta)
        // }
      } else {
        luokat += ' a-l-r-har'
      }
    }

    if (ruutu.m) {
      luokat += ' a-l-r-viesti'
    }

    if (ruutu.l) { // [class.sin-reuna]='onkoTilikaudenLoppu'
      const kuukausi = this._dateService.numberToLocalMonth(ruutu.k)

      // Koko tilikausi ok tai veroilmoitus on lähetetty
      if (
        (
          asiakas.c === Yritysmuoto.OSAKEYHTIO &&
          ruutu.vp &&
          ruutu.pp &&
          ruutu.tp &&
          ruutu.kp
        ) ||
        (
          asiakas.c !== Yritysmuoto.OSAKEYHTIO &&
          (ruutu.bkt || ruutu.vp)
        )
      ) {
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Valittu sininen tilikausi OK')
        // }
        luokat += ' a-l-r-sinr'
      } else {
        const kuukausia = asiakas.c === Yritysmuoto.TOIMINIMI || asiakas.c === Yritysmuoto.KOMMANDIITTIYHTIO || asiakas.c === Yritysmuoto.AVOINYHTIO ? 3 : 4
        const pvm = this._dateService.lisaaKuukausiaPaikallinen({ year: kuukausi.year, month: kuukausi.month, day: ruutu.l }, kuukausia)
        const nykyhetkestä = this._dateService.kuukausiaValissaPaikallinen(pvm, this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara()))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log(asiakas.n, nykyhetkestä, kuukausia)
        // }
        if (nykyhetkestä <= kuukausia) {
          luokat += ' a-l-r-punr'
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu punainen')
          // }
        } else {
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu sininen')
          // }
          luokat += ' a-l-r-sinr'
        }
      }
      const tilikaudenPaattymisenMaxPaivat = new Date(kuukausi.year, kuukausi.month, 0).getDate()
      if (ruutu.l !== tilikaudenPaattymisenMaxPaivat) {
        const px = (50 - Math.round((ruutu.l / tilikaudenPaattymisenMaxPaivat) * 50))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Left minus: ', px, 'for', asiakas.n, kuukausi)
        // }
        luokat += (' al-v' + px)
      }
    }

    // if (asiakas.i === logForAsiakasId) {
    //   console.log('Tulos', luokat)
    // }

    return luokat

  }

  keraaRuudunLuokatUusin(asiakas: ListausAsiakas, ruutu: KuukausiruudunTiedot, seuraavaRuutu: KirjanpitoKuukausiruutu, location: 'lista' | 'kirjanpito', valinta: LukemanTyyppi): string {

    // const logForAsiakasId = -1
    let luokat = 'a-l-r a-l-r-ot'

    // Asiakkaalla ei tilikausia
    if (asiakas.bet) {
      luokat += ' a-l-r-etn'
    }

    // Ruutu on deaktivoitu
    if (ruutu.bd) {
      luokat += ' a-l-r-har a-l-r-ei-aktiivinen'
    }

    // Ruutu on lukittu
    if (ruutu.bl) {
      luokat += ' fa a-l-r-lukittu'
    }

    // Koko tilikausi on ok
    if (ruutu.bkt) {
      luokat += ' a-l-r-ok'
    }

    // Vasemman alakulman luokat
    if (location === 'lista' && (ruutu.oor || (!!ruutu.tpp && ruutu.tpp !== KirjanpitoKuukausiruudunTilinpaatoksenTila.VALMIS))) {

      if (ruutu.oor) {
        luokat += ' a-l-r-va-odottaa-osinko-rekisterointia'
      }

      if (!ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_TILINPAATOKSEN_ALLEKIRJOITTAMISTA) {
        luokat += ' a-l-r-va-sinikyna'
      } else if (!ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_YHTIOKOKOUKSEN_POYTAKIRJAN_ALLEKIRJOITTAMISTA) {
        luokat += ' a-l-r-va-sininuija'
      } else if (!ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_REKISTEROINTIA) {
        luokat += ' a-l-r-va-oranssikyna'
      } else if (!ruutu.pp && ruutu.tpp === KirjanpitoKuukausiruudunTilinpaatoksenTila.ODOTTAA_TILINTARKASTUSTA) {
        luokat += ' a-l-r-va-sinisuurennuslasi'
      }

    } else if (valinta === 'minuutit') {
      // ruutu.va = ruutu.ka ? ruutu.ka : null
      if (ruutu.ka) {
        luokat += ' a-l-r-va-minuutit'
      }
    } else if (valinta === 'tehot') {
      // ruutu.va = ruutu.ka && ruutu.h ? this.laskeTeho(ruutu) : null
      if (ruutu.ka && ruutu.h) {
        luokat += ' a-l-r-va-tehot'
      }
    } else if (valinta === 'selvitettavat') {
      if (ruutu.d || ruutu.e) {
        luokat += ' a-l-r-va-selvitettavat'
      }
    }
    //  else {
    //   ruutu.va = null
    // }

    // Oikean alakulman luokat
    if (ruutu.u) {
      luokat += ' a-l-r-tospuusi'
    } else if (ruutu.c) {
      luokat += ' a-l-r-tosh'
    }

    // Email lähetetty ainakin kerran
    if (ruutu.bem) {
      luokat += ' a-l-r-email-lah'
    }

    if (asiakas.k === 'kNfkvHFDPuDk2Y0Gtwlu') {
      console.log(ruutu.k, ruutu.kj, ruutu.bot)
    }

    // Pöytälaatikossa tässä kuussa
    if (ruutu.po) {
      luokat += ' a-l-r-po'
    } else if (ruutu.t) {
      // Tauolla tässä kuussa
      luokat += ' a-l-r-t'
    } else if (!ruutu.kj && !ruutu.bot) {
      // Ruutu ei ole avatulla kuukaudella || ruutu ei ole tilikaudella

    }

    if (ruutu.kj && !ruutu.bot) {
      luokat += ' a-l-r-etn'
    }

    if (!ruutu.kj) {
      luokat += ' a-l-r-dis'
    }

    if (ruutu.bot) {

      // ALV lähetys override (Accountant has marked the ALV ilmoitus to have been sent outside Lemonator.)
      if (ruutu.baok) {
        luokat += ' a-l-r-alvvih'
      } else if (ruutu.balv) { // ALV ilmoitus sent at least once
        if (ruutu.r) {
          luokat += ' a-l-r-alvpun'
        } else {
          luokat += ' a-l-r-alvvih'
        }
      } else if (!ruutu.bl && ruutu.kj) {

        // if (asiakas.k === 'Ljlf328RwpKfpGcfBa9X') {
        //   console.log('RUUTU ', ruutu)
        //   console.log('Asiakas ', asiakas)
        // }

        const yyyy = 2000 + Math.floor(ruutu.k / 100)
        const mm = ruutu.k % 100
        const nykyinenPvm = this._aikakone.annanNykyinenPaivamaara()
        // const nykyinenPvm = new Date(2020, 5, 8)

        // console.log('NYT', nykyinenPvm.getFullYear(), nykyinenPvm.getMonth() + 1, nykyinenPvm.getDate())
        // console.log(yyyy, mm, 13)

        if (ruutu.alv === AlvIlmoitusjakso.KK1) {

          if (new Date(yyyy, mm + 1, 13) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy, mm + 1, 6) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy, mm, 1) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        } else if (ruutu.alv === AlvIlmoitusjakso.KK3 && (mm === 3 || mm === 6 || mm === 9 || mm === 12 || seuraavaRuutu.alv !== ruutu.alv)) {

          if (new Date(yyyy, mm + 1, 13) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy, mm + 1, 6) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy, mm, 1) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        } else if (ruutu.alv === AlvIlmoitusjakso.KK12 && (mm === 12 || seuraavaRuutu.alv !== ruutu.alv)) {

          if (new Date(yyyy + 1, 2, 0) < nykyinenPvm) { // On jo erääntynyt
            luokat += ' a-l-r-alvmus'
          } else if (new Date(yyyy + 1, 2, -7) < nykyinenPvm) { // Enää viikko aikaa
            luokat += ' a-l-r-alvora'
          } else if (new Date(yyyy + 1, 1, 0) < nykyinenPvm) { // Enää kuukausi aikaa
            luokat += ' a-l-r-alvsin'
          } else { // Yli kuukausi aikaa
            luokat += ' a-l-r-alvhar'
          }

        }

      }

    }

    // const haeHinta = (status && status.kirjanpitoAika) || (kuukaudenTiedot.pallurasin || kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun)
    // const hinta = haeHinta ? this._asiakasService.annaNykyinenHinta(asiakas, kuukausi) : null

    // if (hinta) {
    //   if (kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun) {
    //     asiakas.huutomerkkienSumma = asiakas.huutomerkkienSumma.plus(hinta.hinta)
    //   } else if (kuukaudenTiedot.pallurasin) {
    //     const erapaiva = this.annaSeuraavaErapaiva(asiakas.alvIlmoitusjakso, kuukausi)
    //     const palluraIlmestynyt = this.sininenPalluraIlmestynyt(asiakas.alvIlmoitusjakso, kuukausi)
    //     const paivia = this._dateService.laskeArkipaivatPaivienValilla(this._aikakone.annanNykyinenPaivamaara(), erapaiva)
    //     const paiviailmestymisenJaErapaivanValilla = this._dateService.laskeArkipaivatPaivienValilla(palluraIlmestynyt, erapaiva)
    //     asiakas.palluroidenJakauma.push({
    //       aika: kuukausi.year + '/' + kuukausi.month,
    //       hinta: hinta.hinta,
    //       erapaiva: erapaiva,
    //       paiviaErapaivaan: paivia,
    //       palluraIlmestynyt: palluraIlmestynyt,
    //       paiviailmestymisenJaErapaivanValilla: paiviailmestymisenJaErapaivanValilla
    //     })
    //     asiakas.vahennysProgressiosta = asiakas.vahennysProgressiosta.plus((hinta.hinta / paiviailmestymisenJaErapaivanValilla) * paivia)
    //     asiakas.dynaaminenHinta = asiakas.dynaaminenHinta.plus(paivia === 0 ? hinta.hinta : hinta.hinta / paivia)
    //     asiakas.palluroidenSumma = asiakas.palluroidenSumma.plus(hinta.hinta)
    //   }
    // }

    if (location === 'lista' && ruutu.ka && ruutu.kp) {
      if (ruutu.h) {
        const teho = this.laskeTeho(ruutu)
        if (teho < 100) { // [class.pun]='onkoTilikaudella && tehovari !== null && 100 > tehovari'
          luokat += ' a-l-r-pun-uusi'
        } else if (100 <= teho && teho <= 200) { // [class.kel]='onkoTilikaudella && tehovari !== null && 200 >= tehovari && tehovari >= 100'
          luokat += ' a-l-r-kel-uusi'
        } else { // [class.vih]='onkoTilikaudella && tehovari !== null && tehovari > 200'
          luokat += ' a-l-r-vih-uusi'
        }
        // const nyt = this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara())
        // if (status.kirjanpitoPvm && this._dateService.paiviaValissaPaikallinen(status.kirjanpitoPvm, nyt) === 0) {
        //   asiakas.paivanSaldo = asiakas.paivanSaldo.plus(hinta.hinta)
        // }
      } else {
        luokat += ' a-l-r-har'
      }
    }

    if (ruutu.m) {
      luokat += ' a-l-r-viesti'
    }

    if (ruutu.l) { // [class.sin-reuna]='onkoTilikaudenLoppu'
      const kuukausi = this._dateService.numberToLocalMonth(ruutu.k)

      // Koko tilikausi ok tai veroilmoitus on lähetetty
      if (
        (
          asiakas.c === Yritysmuoto.OSAKEYHTIO &&
          ruutu.vp &&
          ruutu.pp &&
          ruutu.tp &&
          ruutu.kp
        ) ||
        (
          asiakas.c !== Yritysmuoto.OSAKEYHTIO &&
          (ruutu.bkt || ruutu.vp)
        )
      ) {
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Valittu sininen tilikausi OK')
        // }
        luokat += ' a-l-r-sinr'
      } else {
        const kuukausia = asiakas.c === Yritysmuoto.TOIMINIMI || asiakas.c === Yritysmuoto.KOMMANDIITTIYHTIO || asiakas.c === Yritysmuoto.AVOINYHTIO ? 3 : 4
        const pvm = this._dateService.lisaaKuukausiaPaikallinen({ year: kuukausi.year, month: kuukausi.month, day: ruutu.l }, kuukausia)
        const nykyhetkestä = this._dateService.kuukausiaValissaPaikallinen(pvm, this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara()))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log(asiakas.n, nykyhetkestä, kuukausia)
        // }
        if (nykyhetkestä <= kuukausia) {
          luokat += ' a-l-r-punr'
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu punainen')
          // }
        } else {
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu sininen')
          // }
          luokat += ' a-l-r-sinr'
        }
      }
      const tilikaudenPaattymisenMaxPaivat = new Date(kuukausi.year, kuukausi.month, 0).getDate()
      if (ruutu.l !== tilikaudenPaattymisenMaxPaivat) {
        const px = (50 - Math.round((ruutu.l / tilikaudenPaattymisenMaxPaivat) * 50))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Left minus: ', px, 'for', asiakas.n, kuukausi)
        // }
        luokat += (' al-v' + px)
      }
    }

    // if (asiakas.i === logForAsiakasId) {
    //   console.log('Tulos', luokat)
    // }

    return luokat

  }

  keraaRuudunLuokatVanha(asiakas: ListausAsiakas, ruutu: KuukausiruudunTiedot, seuraavaRuutu: KirjanpitoKuukausiruutu): string {

    // const logForAsiakasId = -1
    let luokat = 'a-l-r a-l-r-ot'

    const osaTilikautta = ruutu.bot

    // Ei osa tilikautta, tai asiakkaalla ei lainkaan tilikausia
    if (!osaTilikautta || asiakas.bet) {
      luokat += ' a-l-r-dis'
    }

    // Asiakkaalla ei lainkaan tilikausia
    if (asiakas.bet) {
      luokat += ' a-l-r-et'
    }

    if (ruutu.bl) {
      luokat += ' fa a-l-r-lukittu'
    }

    // Koko tilikausi OK
    if (ruutu.bkt) {
      luokat += ' a-l-r-ok'
    }

    if (ruutu.la && !ruutu.le) { // Pelkästään käsiteltyjä: harmaa
      luokat += ' a-l-r-tosh'
    } else if (!ruutu.la && ruutu.le) { // Pelkästään uusia: sininen
      luokat += ' a-l-r-toss'
    } else if (ruutu.la && ruutu.le) { // Sekä että: punainen, näytetään vaan uudet + boldina
      luokat += ' a-l-r-tosp'
    }

    const aktiivinen = !ruutu.bd
    const kuukausi = this._dateService.numberToLocalMonth(ruutu.k)

    if (osaTilikautta && aktiivinen && !ruutu.kp && !ruutu.bl) {

      // if (asiakas.i === logForAsiakasId) {
      //   console.log('RUUTU ', ruutu)
      //   console.log('Asiakas ', asiakas)
      // }

      const nykyinenPvm = this._aikakone.annanNykyinenPaivamaara()
      // const nykyinenPvm = this._dateService.localDateToDate({ year: 2019, month: 3, day: 6 })

      if (ruutu.alv === AlvIlmoitusjakso.KK1) {

        const kuunalku = new Date(kuukausi.year, kuukausi.month - 1, 1)
        const kuukausiaValissa = this._dateService.kuukausiaValissa(nykyinenPvm, kuunalku)
        if ((kuukausiaValissa === 2 && nykyinenPvm.getDate() > 12) || kuukausiaValissa > 2) {
          // kuukaudenTiedot.huutomus = true
          luokat += ' a-l-r-huutomus'
        } else if (kuukausiaValissa === 2 && nykyinenPvm.getDate() > 4) {
          // kuukaudenTiedot.huutopun = true
          luokat += ' a-l-r-huutopun'
        } else if (kuukausiaValissa === 1 || kuukausiaValissa === 2) {
          // kuukaudenTiedot.pallurasin = true
          luokat += ' a-l-r-pallurasin'
        }

      } else if (ruutu.alv === AlvIlmoitusjakso.KK3) {

        const huutoMustaPvm = this.annaPaivamaara3kkHuutoMus(kuukausi)
        if (0 < this._dateService.paiviaValissa(nykyinenPvm, huutoMustaPvm)) {
          // kuukaudenTiedot.huutomus = true
          luokat += ' a-l-r-huutomus'
        } else {
          const huutoPunainenPvm = this.annaPaivamaara3kkHuutoPun(kuukausi)
          if (0 < this._dateService.paiviaValissa(nykyinenPvm, huutoPunainenPvm)) {
            // kuukaudenTiedot.huutopun = true
            luokat += ' a-l-r-huutopun'
          } else {
            const palluraSininenPvm = this.annaPaivamaara3kkPalluraSin(kuukausi)
            if (0 < this._dateService.paiviaValissa(nykyinenPvm, palluraSininenPvm)) {
              // kuukaudenTiedot.pallurasin = true
              luokat += ' a-l-r-pallurasin'
            }
          }
        }

      } else if (ruutu.alv === AlvIlmoitusjakso.KK12) {

        const huutoMustaPvm = this.annaPaivamaara12kkHuutoMus(kuukausi)
        if (0 < this._dateService.paiviaValissa(nykyinenPvm, huutoMustaPvm)) {
          // kuukaudenTiedot.huutomus = true
          luokat += ' a-l-r-huutomus'
        } else {
          const huutoPunainenPvm = this.annaPaivamaara12kkHuutoPun(kuukausi)
          if (0 < this._dateService.paiviaValissa(nykyinenPvm, huutoPunainenPvm)) {
            // kuukaudenTiedot.huutopun = true
            luokat += ' a-l-r-huutopun'
          } else {
            const palluraSininenPvm = this.annaPaivamaara12kkPalluraSin(kuukausi)
            if (0 < this._dateService.paiviaValissa(nykyinenPvm, palluraSininenPvm)) {
              // kuukaudenTiedot.pallurasin = true
              luokat += ' a-l-r-pallurasin'
            }
          }
        }

      }
    }

    // const haeHinta = (status && status.kirjanpitoAika) || (kuukaudenTiedot.pallurasin || kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun)
    // const hinta = haeHinta ? this._asiakasService.annaNykyinenHinta(asiakas, kuukausi) : null

    // if (hinta) {
    //   if (kuukaudenTiedot.huutomus || kuukaudenTiedot.huutopun) {
    //     asiakas.huutomerkkienSumma = asiakas.huutomerkkienSumma.plus(hinta.hinta)
    //   } else if (kuukaudenTiedot.pallurasin) {
    //     const erapaiva = this.annaSeuraavaErapaiva(asiakas.alvIlmoitusjakso, kuukausi)
    //     const palluraIlmestynyt = this.sininenPalluraIlmestynyt(asiakas.alvIlmoitusjakso, kuukausi)
    //     const paivia = this._dateService.laskeArkipaivatPaivienValilla(this._aikakone.annanNykyinenPaivamaara(), erapaiva)
    //     const paiviailmestymisenJaErapaivanValilla = this._dateService.laskeArkipaivatPaivienValilla(palluraIlmestynyt, erapaiva)
    //     asiakas.palluroidenJakauma.push({
    //       aika: kuukausi.year + '/' + kuukausi.month,
    //       hinta: hinta.hinta,
    //       erapaiva: erapaiva,
    //       paiviaErapaivaan: paivia,
    //       palluraIlmestynyt: palluraIlmestynyt,
    //       paiviailmestymisenJaErapaivanValilla: paiviailmestymisenJaErapaivanValilla
    //     })
    //     asiakas.vahennysProgressiosta = asiakas.vahennysProgressiosta.plus((hinta.hinta / paiviailmestymisenJaErapaivanValilla) * paivia)
    //     asiakas.dynaaminenHinta = asiakas.dynaaminenHinta.plus(paivia === 0 ? hinta.hinta : hinta.hinta / paivia)
    //     asiakas.palluroidenSumma = asiakas.palluroidenSumma.plus(hinta.hinta)
    //   }
    // }

    if (ruutu.ka && ruutu.kp) {
      luokat += ' a-l-r-n-h'
      if (ruutu.h) {
        const teho = this.laskeTeho(ruutu)
        if (teho < 100) { // [class.pun]='onkoTilikaudella && tehovari !== null && 100 > tehovari'
          luokat += ' a-l-r-pun'
        } else if (100 <= teho && teho <= 200) { // [class.kel]='onkoTilikaudella && tehovari !== null && 200 >= tehovari && tehovari >= 100'
          luokat += ' a-l-r-kel'
        } else { // [class.vih]='onkoTilikaudella && tehovari !== null && tehovari > 200'
          luokat += ' a-l-r-vih'
        }
        // const nyt = this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara())
        // if (status.kirjanpitoPvm && this._dateService.paiviaValissaPaikallinen(status.kirjanpitoPvm, nyt) === 0) {
        //   asiakas.paivanSaldo = asiakas.paivanSaldo.plus(hinta.hinta)
        // }
      } else {
        luokat += ' a-l-r-har'
      }
    }

    if (ruutu.m) {
      luokat += ' a-l-r-viesti'
    }

    if (!aktiivinen) { // [class.har]='onkoTilikaudella && !aktiivinen'
      luokat += ' a-l-r-har'
    }

    if (ruutu.l) { // [class.sin-reuna]='onkoTilikaudenLoppu'
      if (
        (
          asiakas.c === Yritysmuoto.OSAKEYHTIO &&
          ruutu.vp &&
          ruutu.pp &&
          ruutu.tp &&
          ruutu.kp
        ) ||
        (
          asiakas.c !== Yritysmuoto.OSAKEYHTIO &&
          (ruutu.bkt || ruutu.vp)
        )
      ) {
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Valittu sininen tilikausi OK')
        // }
        luokat += ' a-l-r-sinr'
      } else {
        const kuukausia = asiakas.c === Yritysmuoto.TOIMINIMI || asiakas.c === Yritysmuoto.KOMMANDIITTIYHTIO || asiakas.c === Yritysmuoto.AVOINYHTIO ? 3 : 4
        const pvm = this._dateService.lisaaKuukausiaPaikallinen({ year: kuukausi.year, month: kuukausi.month, day: ruutu.l }, kuukausia)
        const nykyhetkestä = this._dateService.kuukausiaValissaPaikallinen(pvm, this._dateService.dateToLocalDate(this._aikakone.annanNykyinenPaivamaara()))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log(asiakas.n, nykyhetkestä, kuukausia)
        // }
        if (nykyhetkestä <= kuukausia) {
          luokat += ' a-l-r-punr'
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu punainen')
          // }
        } else {
          // if (asiakas.i === logForAsiakasId) {
          //   console.log('Valittu sininen')
          // }
          luokat += ' a-l-r-sinr'
        }
      }
      const tilikaudenPaattymisenMaxPaivat = new Date(kuukausi.year, kuukausi.month, 0).getDate()
      if (ruutu.l !== tilikaudenPaattymisenMaxPaivat) {
        const px = (50 - Math.round((ruutu.l / tilikaudenPaattymisenMaxPaivat) * 50))
        // if (asiakas.i === logForAsiakasId) {
        //   console.log('Left minus: ', px, 'for', asiakas.n, kuukausi)
        // }
        luokat += (' al-v' + px)
      }
    }

    // if (asiakas.i === logForAsiakasId) {
    //   console.log('Tulos', luokat)
    // }

    return luokat

  }

  asetaVasenAlakulmaLista(ruutu: KuukausiruudunTiedot, valinta: LukemanTyyppi) {
    if (ruutu.po) {
      ruutu.vasenAlakulma = 'P'
    } else if (ruutu.t) {
      ruutu.vasenAlakulma = 'T'
    } else if (ruutu.tpp || ruutu.oor) {
      ruutu.vasenAlakulma = null
    } else {
      this.asetaVasenAlakulma(ruutu, valinta)
    }
  }


  asetaVasenAlakulma(ruutu: KuukausiruudunTiedot, valinta: LukemanTyyppi) {
    if (valinta === 'minuutit') {
      ruutu.vasenAlakulma = ruutu.ka ? ruutu.ka : null
    } else if (valinta === 'tehot') {
      ruutu.vasenAlakulma = ruutu.ka && ruutu.h ? this.laskeTeho(ruutu) : null
    } else if (valinta === 'selvitettavat') {
      ruutu.vasenAlakulma = this.annaSelvitettavatYhteensa(ruutu)
    } else {
      ruutu.vasenAlakulma = null
    }
  }

  annaSelvitettavatYhteensa(ruutu: KuukausiruudunTiedot): number {
    const yhteensa = (ruutu.d || 0) + (ruutu.e || 0)
    // console.log(ruutu, yhteensa)
    return yhteensa ? yhteensa : null
  }

  asetaOikeaAlakulma(ruutu: KuukausiruudunTiedot) {
    // console.log('Oikea alakulma', ruutu.k, ruutu)
    if (ruutu?.u) {
      // console.log('Set oa u', ruutu.u)
      ruutu.oikeaAlakulma = ruutu.u
    } else if (ruutu?.c) {
      // console.log('Set oa c', ruutu.c)
      ruutu.oikeaAlakulma = ruutu.c
    } else {
      // console.log('Set oa null')
      ruutu.oikeaAlakulma = null
    }
  }

  private laskeTeho(ruutu: KirjanpitoKuukausiruutu): number {
    return Math.round(ruutu.h / ruutu.ka * 60)
  }

  private annaPaivamaara3kkHuutoPun(kuukausi: LocalMonth): Date {
    if (kuukausi.month === 1 || kuukausi.month === 2 || kuukausi.month === 3) {
      return new Date(kuukausi.year, 4, 5)
    } else if (kuukausi.month === 4 || kuukausi.month === 5 || kuukausi.month === 6) {
      return new Date(kuukausi.year, 7, 5)
    } else if (kuukausi.month === 7 || kuukausi.month === 8 || kuukausi.month === 9) {
      return new Date(kuukausi.year, 10, 5)
    } else if (kuukausi.month === 10 || kuukausi.month === 11 || kuukausi.month === 12) {
      return new Date(kuukausi.year + 1, 1, 5)
    } else {
      throw new Error('Tuntematon kuukausi ' + JSON.stringify(kuukausi))
    }
  }

  private annaPaivamaara3kkHuutoMus(kuukausi: LocalMonth): Date {
    if (kuukausi.month === 1 || kuukausi.month === 2 || kuukausi.month === 3) {
      return new Date(kuukausi.year, 4, 12)
    } else if (kuukausi.month === 4 || kuukausi.month === 5 || kuukausi.month === 6) {
      return new Date(kuukausi.year, 7, 12)
    } else if (kuukausi.month === 7 || kuukausi.month === 8 || kuukausi.month === 9) {
      return new Date(kuukausi.year, 10, 12)
    } else if (kuukausi.month === 10 || kuukausi.month === 11 || kuukausi.month === 12) {
      return new Date(kuukausi.year + 1, 1, 12)
    } else {
      throw new Error('Tuntematon kuukausi ' + JSON.stringify(kuukausi))
    }
  }

  private annaPaivamaara3kkPalluraSin(kuukausi: LocalMonth): Date {
    if (kuukausi.month === 1 || kuukausi.month === 2 || kuukausi.month === 3) {
      return new Date(kuukausi.year, 3, 1)
    } else if (kuukausi.month === 4 || kuukausi.month === 5 || kuukausi.month === 6) {
      return new Date(kuukausi.year, 6, 1)
    } else if (kuukausi.month === 7 || kuukausi.month === 8 || kuukausi.month === 9) {
      return new Date(kuukausi.year, 9, 1)
    } else if (kuukausi.month === 10 || kuukausi.month === 11 || kuukausi.month === 12) {
      return new Date(kuukausi.year + 1, 0, 1)
    } else {
      throw new Error('Tuntematon kuukausi ' + JSON.stringify(kuukausi))
    }
  }

  private annaPaivamaara12kkHuutoPun(kuukausi: LocalMonth): Date {
    return new Date(kuukausi.year + 1, 1, 28)
  }

  private annaPaivamaara12kkHuutoMus(kuukausi: LocalMonth): Date {
    return new Date(kuukausi.year + 1, 2, 12)
  }

  private annaPaivamaara12kkPalluraSin(kuukausi: LocalMonth): Date {
    return new Date(kuukausi.year + 1, 0, 1)
  }

  // private sininenPalluraIlmestynyt(alvjakso: AlvIlmoitusjakso, kuukausi: LocalMonth): Date {
  //   switch (alvjakso) {
  //     case AlvIlmoitusjakso.KK1:
  //       const ensiKuussa = this._dateService.lisaaKuukausiaPaikallinen({ year: kuukausi.year, month: kuukausi.month, day: 1 }, 1)
  //       return this._dateService.localDateToDate(ensiKuussa)
  //     case AlvIlmoitusjakso.KK3:
  //       return this.annaPaivamaara3kkPalluraSin(kuukausi)
  //     case AlvIlmoitusjakso.KK12:
  //       return new Date(kuukausi.year + 1, 0, 1)
  //     case AlvIlmoitusjakso.EI:
  //       return new Date(kuukausi.year + 1, 0, 1)
  //     case AlvIlmoitusjakso.TUNTEMATON:
  //       return new Date(kuukausi.year + 1, 0, 1)
  //     default:
  //       throw new Error('Tuntematon alv-ilmoitusjakso ' + JSON.stringify(alvjakso))
  //   }
  // }

  // private annaSeuraavaErapaiva(alvjakso: AlvIlmoitusjakso, kuukausi: LocalMonth): Date {
  //   switch (alvjakso) {
  //     case AlvIlmoitusjakso.KK1:
  //       const ensiKuussa = this._dateService.lisaaKuukausiaPaikallinen({ year: kuukausi.year, month: kuukausi.month, day: 12 }, 2)
  //       return this._dateService.localDateToDate(ensiKuussa)
  //     case AlvIlmoitusjakso.KK3:
  //       if (kuukausi.month === 1 || kuukausi.month === 2 || kuukausi.month === 3) {
  //         return new Date(kuukausi.year, 5, 12)
  //       } else if (kuukausi.month === 4 || kuukausi.month === 5 || kuukausi.month === 6) {
  //         return new Date(kuukausi.year, 6, 12)
  //       } else if (kuukausi.month === 7 || kuukausi.month === 8 || kuukausi.month === 9) {
  //         return new Date(kuukausi.year, 11, 12)
  //       } else if (kuukausi.month === 10 || kuukausi.month === 11 || kuukausi.month === 12) {
  //         return new Date(kuukausi.year + 1, 1, 12)
  //       } else {
  //         throw new Error('Tuntematon kuukausi ' + JSON.stringify(kuukausi))
  //       }
  //     case AlvIlmoitusjakso.KK12:
  //       return new Date(kuukausi.year + 1, 1, 28)
  //     case AlvIlmoitusjakso.EI:
  //       return new Date(kuukausi.year + 1, 1, 28)
  //     case AlvIlmoitusjakso.TUNTEMATON:
  //       return new Date(kuukausi.year + 1, 1, 28)
  //     default:
  //       throw new Error('Tuntematon alv-ilmoitusjakso ' + JSON.stringify(alvjakso))
  //   }
  // }

}
