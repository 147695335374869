<div [style.visibility]="zoomingEnabled ? 'hidden' : 'visible'" style="position: absolute; right: 5px; bottom: 5px; width: 72px; display: flex; z-index: 2;">
  <!-- title="Käännä kuvaa 90° vasemmalle" -->
  <div (click)="vasemmalle($event)" class="image-rotate-button">
    <i class="fa fa-undo"></i>
  </div>
  <!-- title="Käännä kuvaa 90° oikealle" -->
  <div (click)="oikealle($event)" class="image-rotate-button">
    <i class="fa fa-repeat"></i>
  </div>
</div>
<div #zoomContainer [style.transform]="rotationDegrees ? 'rotate(' + rotationDegrees + 'deg)' : 'unset'" [style.cursor]="zoomingEnabled ? 'zoom-out' : 'zoom-in'" class="imageZoomContainer" [style.width.px]="thumbWidth" [style.height]="heightVh">
  <img #imageThumbnail [style.visibility]="zoomingEnabled || !thumbImageLoaded ? 'hidden' : 'visible'" class="imageZoomThumbnail" [src]="thumbImage" (load)="onThumbImageLoaded()" [style.height]="heightVh" style="width: 100%; object-fit: contain;" />
  <div [ngClass]="{'imageZoomFullContainer': true, 'imageZoomLensEnabled': enableLens}" [style.display]="display" [style.top.px]="lensTop" [style.left.px]="lensLeft" [style.width.px]="lensWidth" [style.height]="heightVh" [style.border-radius.px]="lensBorderRadius">
    <img #fullSizeImage class="imageZoomFull" [src]="fullImage" (load)="onFullImageLoaded()" [style.display]="display" [style.top.px]="fullImageTop" [style.left.px]="fullImageLeft" [style.width.px]="magnifiedWidth" [style.height.px]="magnifiedHeight" />
  </div>
</div>