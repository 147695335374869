import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ErrorHandler } from '@angular/core'

import { Asiakas } from '../../../_jaettu-lemonator/model/asiakas'
import { ViiaHaeIbanitLemonatorResponse, ViiaHaeIbanitLemonatorRequest, AiiaStartFetchManuallyTyojono, ViiaTransaction } from '../../../_jaettu/model/aiia'

import { AsiakasService } from '../../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../../_shared-core/service/date.service'


import { Subject, Observable, of as observableof, combineLatest, BehaviorSubject } from 'rxjs'
import { takeUntil, switchMap, tap, map } from 'rxjs/operators'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BankConsentLog, BankCustomerConsent, GetRawTransactionsQuery, GetRawTransactionsResponse } from 'app/_jaettu/model/banks'
import { BicService } from 'app/_shared-core/service/bic.service'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'


interface ViewableTransaction extends ViiaTransaction {
  showDetails: boolean
}

interface RawTransactionsState extends GetRawTransactionsResponse {
  showTable: boolean
  loading: boolean
  transactions: ViewableTransaction[]
}

interface AccountSelectingInfo {
  iban: string
  bic: string
  bank: string
}

@Component({
  selector: '[app-aiia-tilitapahtumat]',
  templateUrl: './aiia-tilitapahtumat.component.html',
  styleUrls: ['./aiia-tilitapahtumat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiiaTilitapahtumatComponent implements OnInit, OnDestroy {

  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()

  consentHtmlObservable: Observable<string>
  userLevelConsentsObservable: Observable<string>
  asiakkaanIbanitObservable: Observable<AccountSelectingInfo[]>
  private _historianMaaraSubject: BehaviorSubject<number> = new BehaviorSubject(50)
  historianMaaraObservable = this._historianMaaraSubject.asObservable()
  logsObservable: Observable<BankConsentLog[]>
  form: FormGroup<{
    'iban': FormControl<AccountSelectingInfo>
    'start': FormControl<Date>
    'end': FormControl<Date>
  }>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  rawTransactionsStateSubject = new BehaviorSubject<RawTransactionsState>({ showTable: false, loading: false, accountInfo: null, transactions: [], outcome: null })

  constructor(
    private _asiakasService: AsiakasService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _bicService: BicService,
    private _snackbar: MatSnackBar,
    private _bankConsentUriService: BankConsentUriService
  ) {

  }

  ngOnInit() {

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.asiakas = result
    })

    const endDate = new Date()
    const startDate = this._dateService.kuukaudenEnsimmainen(this._dateService.lisaaKuukausia(endDate, -2))

    const ibanFormControl = new FormControl<AccountSelectingInfo>(null, Validators.required)
    this.form = new FormGroup({
      iban: ibanFormControl,
      start: new FormControl<Date>(startDate, Validators.required),
      end: new FormControl<Date>(endDate, Validators.required)
    })

    const bankCustomerConsentObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas?.avain) {
          return observableof<BankCustomerConsent>(null)
        }
        const uri = this._bankConsentUriService.getBankConsentUri(asiakas.avain)

        return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(uri).listen()
      })
    )

    this.consentHtmlObservable = bankCustomerConsentObservable.pipe(
      map(consent => {
        if (!consent) {
          return '<p>Käyttäjillä ei ole pankkiyhteysroolia</p>'
        }
        if (!consent.dialogsToShow?.length &&
          !consent.forceDialogToShow?.length) {
          return '<p>Kaikki maksutavoissa olevat sähköiset tiliotteet toimivat.</p>'
        }
        let output = '<p>Pankkiyhteysikkuna on päällä:<br/><br/>'
        if (consent.forceDialogToShow?.length) {
          for (const d of consent.forceDialogToShow) {
            output += d + ' (pakotetusti päällä)'
          }
        }
        if (consent.dialogsToShow.length) {
          for (const d of consent.dialogsToShow) {
            output += // '<p>' + d.dialogToShow?.toUpperCase() + ' ' +
              'Pankin tunnus ' + (d.providerId ?? 'Tuntematon') + ', vanhentunut ' + this._dateService.muotoile(this._dateService.numberTimestampToDate(d.connectionLost), 'dd.MM.yyyy HH:mm:ss')
              + '<br/>' +
              (d.ibansMissing ? d.ibansMissing?.join(', ') : '')
              + '</p>'
          }
        }
        return output
      })
    )


    this.asiakkaanIbanitObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableof<AccountSelectingInfo[]>(null as AccountSelectingInfo[])
        }
        const ibanRequestData: ViiaHaeIbanitLemonatorRequest = {
          asiakasAvain: asiakas.avain
        }
        return this._firebaseLemonaid.functionsCall<ViiaHaeIbanitLemonatorRequest, ViiaHaeIbanitLemonatorResponse>('viiaGetCustomerIbansLemonatorProd', ibanRequestData).then(
          data => {
            if (!data || (data && data.e) || !data.ibanit) {
              return null
            }
            return data.ibanit.filter(iban => !!iban).map(iban => {
              const bic = this._bicService.annaBicKoodi(iban) || 'BIC ei tiedossa'
              const bank = this._bicService.annaPankinNimi(iban) || 'Pankin nimi ei tiedossa'
              const val: AccountSelectingInfo = { iban: iban, bic: bic, bank: bank }
              return val
            })
          }
        )
      }),
      tap(ibanit => {
        if (!ibanFormControl.value && ibanit?.length > 0) {
          ibanFormControl.setValue(ibanit[0])
        }
      })
    )


    this.logsObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.historianMaaraObservable]).pipe(
      switchMap(([asiakas, historianMaara]) => {
        if (!asiakas) {
          return observableof<BankConsentLog[]>([])
        }
        const uri = this._bankConsentUriService.getBankConsentLogsCollection(asiakas.avain)
        return this._firebaseLemonaid.firestoreCollection<BankConsentLog>(uri).orderBy('changedTime', 'desc').limit(Number(historianMaara)).listen()
      })
    )
  }

  async getRawAiiaTransactions() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this.rawTransactionsStateSubject.value.loading = true
    this.rawTransactionsStateSubject.value.showTable = true
    this.rawTransactionsStateSubject.next(this.rawTransactionsStateSubject.value)

    try {

      const asiakasAvain = this.asiakas.avain

      if (!asiakasAvain) {
        throw new Error('No asiakasAvain')
      }

      const iban = this.form.get('iban').value
      const startDate = this._dateService.dateToLocalDate(this.form.get('start').value)
      const endDate = this._dateService.dateToLocalDate(this.form.get('end').value)

      const requestPayload: GetRawTransactionsQuery = {
        asiakasAvain: asiakasAvain,
        iban: iban.iban,
        start: startDate,
        end: endDate
      }

      const response = await this._firebaseLemonaid.functionsCall<GetRawTransactionsQuery, GetRawTransactionsResponse>('aiiaGetRawAiiaTransactions', requestPayload, { timeout: 540 * 1000 })

      this.rawTransactionsStateSubject.value.accountInfo = response.accountInfo
      this.rawTransactionsStateSubject.value.outcome = response.outcome
      this.rawTransactionsStateSubject.value.transactions = response.transactions as ViewableTransaction[]
      this.rawTransactionsStateSubject.value.loading = false
      this.rawTransactionsStateSubject.next(this.rawTransactionsStateSubject.value)

      // this._snackbar.open('Käynnistin ' + this._dateService.muotoilePaikallinenPaiva(startDate, 'fi') + ' - ' + this._dateService.muotoilePaikallinenPaiva(endDate, 'fi') + ' tilitapahtumien latauksen tililtä ' + iban, 'OK')
    } catch (err) {
      this._errorHandler.handleError(new Error('Aiia get raw transactions request failed: ' + err?.message ?? 'unknown error'))
      this.rawTransactionsStateSubject.value.loading = false
      this.rawTransactionsStateSubject.next(this.rawTransactionsStateSubject.value)
    }

  }

  toggleShowDetails(transaction: ViewableTransaction) {
    if (transaction.showDetails) {
      delete transaction.showDetails
    } else {
      transaction.showDetails = true
    }
  }

  startAiiaFetch() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    try {

      const asiakasAvain = this.asiakas.avain

      if (!asiakasAvain) {
        throw new Error('No asiakasAvain')
      }

      const iban = this.form.get('iban').value
      const startDateNum = this._dateService.dateToNumber(this.form.get('start').value)
      const endDateNum = this._dateService.dateToNumber(this.form.get('end').value)

      const tyojonoData: AiiaStartFetchManuallyTyojono = {
        asiakasAvain: asiakasAvain,
        iban: iban.iban,
        start: startDateNum,
        end: endDateNum,
      }

      this._firebaseLemonaid.firestoreSetData(`tyojono/${asiakasAvain}/aiia-start-transactions-fetch/${this._firebaseLemonaid.firestoreCreateId()}`, tyojonoData)

      this._snackbar.open('Tilin ' + iban.iban + ' tilitapahtumien synkronointi kirjanpitoon on käynnistetty aikavälillä ' + this._dateService.muotoileNumberPaiva(startDateNum, 'fi') + ' - ' + this._dateService.muotoileNumberPaiva(endDateNum, 'fi') + '.', 'OK')
    } catch (err) {
      this._errorHandler.handleError(new Error('Aiia transaction manual fetch start failed: ' + err?.message ?? 'unknown error'))
    }

  }

  historianMaaraChanged(limit: string) {
    this._historianMaaraSubject.next(+limit)
  }

  // private _ibanValidator: ValidatorFn = (c: AbstractControl<AccountSelectingInfo>): ValidationErrors | null => {
  //   // console.log(c.value, this.ibanService.isValidIban(c.value))
  //   if (c && c.value && c.value.bank && !this._ibanService.isValidIban(c.value.bank)) {
  //     return { 'iban': true }
  //   }
  //   return null
  // }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
