<div class="list-page">

  <app-asiakas-otsikko otsikko="Lisää asiakas" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <form (ngSubmit)="save()" [formGroup]="form" novalidate>

    <div style="max-width: 450px;">
      <div class="light-blue-text" style="font-size: 18px; margin-bottom: 0px; line-height: 20px; margin-top: 55px;">Yhteystiedot</div>
      <div style="margin-left: 10px; margin-right: 10px;">
        <mat-form-field>
          <mat-label>{{ 'asiakas.nimi' | translate | async }}</mat-label>
          <input #nimiInput [name]="inputname" type="text" formControlName="nimi" matInput required data-lpignore="true" />
          <mat-error *ngIf="nimi?.errors?.required && (nimi?.dirty || nimi?.touched)">
            Nimi puuttuu
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'Y-tunnus' }}</mat-label>
          <input type="text" [name]="inputname" formControlName="ytunnus" required matInput data-lpignore="true" />
          <mat-hint *ngIf="ytunnusVirhe"><span class="lemon-error">Y-tunnus on virheellinen. Tämä ei estä tallentamista. Tarkista Y-tunnus</span></mat-hint>
          <mat-error *ngIf="ytunnus?.errors?.required && (ytunnus?.dirty || ytunnus?.touched)">
            Y-tunnus puuttuu
          </mat-error>
          <mat-error *ngIf="ytunnus?.errors?.tupla && (ytunnus?.dirty || ytunnus?.touched)">
            Y-tunnus on jo käytössä yrityksellä {{ytunnus?.errors?.tupla.nimi}}.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'lasku.katuosoite' | translate | async }}</mat-label>
          <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="katuosoite" matInput data-lpignore="true" />
          <!-- <mat-error *ngIf="katuosoite?.errors?.required && (katuosoite?.dirty || katuosoite?.touched)">
            {{ 'lasku.validation.katuosoite.required' | translate | async }}
          </mat-error> -->
        </mat-form-field>

        <div>
          <mat-form-field style="width: 48%; float: left;">
            <mat-label>{{ 'lasku.postinro' | translate | async }}</mat-label>
            <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="postinro" matInput data-lpignore="true" />
            <mat-error *ngIf="postinro?.errors?.required && (postinro?.dirty || postinro?.touched)">
              {{ 'lasku.validation.postinro.required' | translate | async }}
            </mat-error>
          </mat-form-field>
          <mat-form-field style="width: 48%; float: right;">
            <mat-label>{{ 'lasku.postitoimipaikka' | translate | async }}</mat-label>
            <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="postitoimipaikka" matInput data-lpignore="true" />
            <mat-error *ngIf="postitoimipaikka?.errors?.required && (postitoimipaikka?.dirty || postitoimipaikka?.touched)">
              {{ 'lasku.validation.postitoimipaikka.required' | translate | async }}
            </mat-error>
          </mat-form-field>
        </div>
        <div style="clear: both; display: block;"></div>

        <mat-form-field>
          <mat-label>{{ 'lasku.maa' | translate | async }}</mat-label>
          <mat-select formControlName="maa" style="width: 100%;">
            <mat-option *ngFor="let maa of suodatetutMaat | async" [value]="maa.koodi">
              {{ maa.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="maa?.errors?.required && (maa?.dirty || maa?.touched)">
            {{ 'lasku.validation.maa.required' | translate | async }}
          </mat-error>
        </mat-form-field>

      </div>

      <div class="light-blue-text" style="font-size: 18px; margin-bottom: 0px; line-height: 20px; margin-top: 55px;">Kirjanpito</div>
      <div style="margin-left: 10px; margin-right: 10px;">
        <mat-form-field style="width: 48%; float: left;">
          <mat-label>{{ 'Yritysmuoto' }}</mat-label>
          <mat-select formControlName="yritysmuoto" required>
            <mat-option *ngFor="let yritysmuoto of yritysmuodot" [value]="yritysmuoto.koodi">
              {{ yritysmuoto.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="yritysmuoto?.errors?.required && (yritysmuoto?.dirty || yritysmuoto?.touched)">
            Yritysmuoto puuttuu
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 48%; float: right;">
          <mat-label>{{ 'ALV-ilmoitusjakso' }}</mat-label>
          <mat-select formControlName="alvIlmoitusjakso" required>
            <mat-option *ngFor="let jakso of alvIlmoitusjaksot" [value]="jakso.koodi">
              {{ jakso.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="alvIlmoitusjakso?.errors?.required && (alvIlmoitusjakso?.dirty || alvIlmoitusjakso?.touched)">
            ALV-ilmoitusjakso puuttuu
          </mat-error>
        </mat-form-field>

        <div style="clear: both; display: block;"></div>
        <div style="display: flex; justify-content: space-between;">
          <!-- <mat-form-field style="max-width: 250px; margin-left: 0; margin-right: 10px;">
            <mat-label>{{ 'Tilikausi' }}</mat-label>
<input type="text" formControlName="tilikausi"  matInput required data-lpignore="true" />
            <mat-error *ngIf="tilikausi?.errors?.required && (tilikausi?.dirty || tilikausi?.touched)">
              Kausi puuttuu
            </mat-error>
          </mat-form-field> -->
          <mat-form-field style="width: 48%; float: left;">
            <!-- [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" -->
            <mat-label>Tilikausi alkaa</mat-label>
            <input type="text" [name]="inputname" [matDatepicker]="alkaaPicker" formControlName="tilikausiAlkaa" matInput data-lpignore="true" />
            <mat-datepicker-toggle matSuffix [for]="alkaaPicker"></mat-datepicker-toggle>
            <mat-error *ngIf="tilikausiAlkaa?.errors?.required && (tilikausiAlkaa?.dirty || tilikausiAlkaa?.touched)">
              Päivämäärä puuttuu
            </mat-error>
            <mat-datepicker #alkaaPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 48%; float: right;">
            <!-- [min]="annaMinimiPaivamaara(hinta.get('voimassaAlkaen'))" -->
            <mat-label>Tilikausi loppuu</mat-label>
            <input type="text" [name]="inputname" [matDatepicker]="loppuuPicker" formControlName="tilikausiLoppuu" matInput data-lpignore="true" />
            <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle>
            <mat-error *ngIf="tilikausiLoppuu?.errors?.required && (tilikausiLoppuu?.dirty || tilikausiLoppuu?.touched)">
              Päivämäärä puuttuu
            </mat-error>
            <mat-datepicker #loppuuPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div style="clear: both; display: block;"></div>
        <div style="display: flex; justify-content: space-between;">
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.iban' | translate | async }}</mat-label>
            <input type="text" [name]="inputname" formControlName="iban" matInput data-lpignore="true" />
            <mat-hint>{{pankkiJaIbanTeksti()}}</mat-hint>
            <mat-error *ngIf="iban?.errors?.required && (iban?.dirty || iban?.touched)">
              IBAN puuttuu
            </mat-error>
            <mat-error *ngIf="iban?.errors?.iban && (iban?.dirty || iban?.touched)">
              IBAN on virheellinen
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field style="max-width: 250px; margin-left: 10px; margin-right: 10px;">
            <mat-label>{{ 'laskuasetukset.pankki' | translate | async }}</mat-label>
<input type="text" formControlName="pankki"  matInput required data-lpignore="true" />
            <mat-error *ngIf="pankki?.errors?.required && (pankki?.dirty || pankki?.touched)">
              Pankki puuttuu
            </mat-error>
          </mat-form-field>
          <mat-form-field style="max-width: 250px; margin-left: 10px; margin-right: 0;">
            <mat-label>{{ 'laskuasetukset.bic' | translate | async }}</mat-label>
<input type="text" formControlName="bic"  matInput required data-lpignore="true" />
            <mat-error *ngIf="bic?.errors?.required && (bic?.dirty || bic?.touched)">
              BIC puuttuu
            </mat-error>
            <mat-error *ngIf="bic?.errors?.bic && (bic?.dirty || bic?.touched)">
              BIC on virheellinen
            </mat-error>
          </mat-form-field> -->
        </div>

      </div>


      <div class="light-blue-text" style="font-size: 18px; margin-bottom: 0px; line-height: 20px; margin-top: 55px;">Laskutus</div>
      <div style="margin-left: 10px; margin-right: 10px;">
        <!-- <div style="background-color: lightgray;">{{hinta?.errors | json}}</div> -->
        <mat-form-field style="width: 48%; float: left;">
          <mat-label>Kuukausihinta</mat-label>
          <input [name]="inputname" decimalNumberField matInput formControlName="hinta" required data-lpignore="true">
          <mat-error *ngIf="hinta?.errors?.required && (hinta?.dirty || hinta?.touched)">
            Hinta puuttuu.
          </mat-error>
          <mat-error *ngIf="hinta?.errors?.min && (hinta?.dirty || hinta?.touched)">
            Hinta on liian alhainen (vähintään 0,00).
          </mat-error>
          <mat-error *ngIf="hinta?.errors?.max && (hinta?.dirty || hinta?.touched)">
            Hinta on liian suuri (enintään 2000,00).
          </mat-error>
        </mat-form-field>

        <!-- <div style="background-color: lightgray;">{{voimassaAlkaen?.errors | json}}</div> -->
        <app-asiakas-lisaa-eka-lasku-kuukausi [minMonth]="minMonth" [kontrolli]="voimassaAlkaen" style="width: 48%; float: right;"></app-asiakas-lisaa-eka-lasku-kuukausi>

        <div style="clear: both; display: block;"></div>
        <mat-form-field>
          <mat-label>Kuukausihinnan perusteet</mat-label>
          <input [name]="inputname" type="text" formControlName="hinnanPerusteet" matInput data-lpignore="true" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Viitenumero</mat-label>
          <input type="text" [name]="inputname" formControlName="viitenumero" matInput data-lpignore="true" />
          <mat-hint>Viitenumero luodaan automaattisesti sen puuttuessa</mat-hint>
          <mat-error *ngIf="viitenumero?.errors?.required && (viitenumero?.dirty || viitenumero?.touched)">
            Viitenumero puuttuu
          </mat-error>
          <mat-error *ngIf="viitenumero?.errors?.viitenumero && (viitenumero?.dirty || viitenumero?.touched)">
            Viitenumero on virheellinen
          </mat-error>
        </mat-form-field>

        <!-- <div class="light-blue-text" style="font-size: 18px; margin-bottom: 15px; line-height: 20px; margin-top: 20px;">Laskujen vastaanottajat</div> -->
        <div style="width: 100%; margin-top: 1em; min-height: 50px;">
          <mat-chip-grid #vastaanottajaChips>
            <mat-chip-option *ngFor="let vastaanottaja of asiakas.laskunVastaanottajat" [selectable]="false" [removable]="true" (removed)="poistaLaskunVastaanottaja(vastaanottaja)">
              {{vastaanottaja}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-grid>
        </div>
        <!-- {{lisaavastaanottaja?.errors | json}} -->
        <mat-form-field>
          <mat-label>{{ 'Lisää laskun vastaanottaja *' }}</mat-label>
          <input type="email" [name]="inputname" autocomplete="kirjanpitotietoarkki" [matChipInputFor]="vastaanottajaChips" (blur)="lisaaLaskunVastaanottaja()" formControlName="lisaavastaanottaja" (matChipInputTokenEnd)="lisaaLaskunVastaanottaja()" matInput data-lpignore="true" />
          <!-- <mat-icon matSuffix (click)="lisaa()">add</mat-icon> -->
          <mat-error *ngIf="lisaavastaanottaja?.errors?.min && (lisaavastaanottaja?.dirty || lisaavastaanottaja?.touched)">
            Lisää vähintään yksi vastaanottaja.
          </mat-error>
          <mat-error *ngIf="lisaavastaanottaja?.errors?.email && (lisaavastaanottaja?.dirty || lisaavastaanottaja?.touched)">
            Sähköpostiosoite on virheellinen.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="light-blue-text" style="font-size: 18px; margin-bottom: 0px; line-height: 20px; margin-top: 55px;">Lisätiedot</div>
      <div style="margin-left: 10px; margin-right: 10px;">
        <mat-form-field>
          <mat-label>Myyjä</mat-label>
          <mat-select formControlName="saleDoneBy" style="width: 100%;">
            <mat-option *ngFor="let sales of salesTeamMembers" [value]="sales.avain">
              {{ sales.etunimi + ' ' + sales.sukunimi }}
            </mat-option>
            <mat-option value="lemonator_system">Muu</mat-option>
          </mat-select>
          <mat-error *ngIf="saleDoneBy?.errors?.required && (saleDoneBy?.dirty || saleDoneBy?.touched)">
            Valitse myyjä
          </mat-error>
        </mat-form-field>
      </div>

      <div style="display: flex; justify-content: space-evenly; margin-top: 2em; margin-bottom: 2em;">
        <!-- <button class="secondarybutton" mat-button routerLink="/asiakkaat" type="button">{{'yleiset.peruuta' | translate | async}}</button> -->
        <button class="secondarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
      </div>

    </div>

  </form>

</div>