<button *ngIf="showPen && !lukittu" mat-icon-button class="edit-pen" [class.edit-pen-active]="editing" (click)="toggleEdit()">
  <span class="fa fa-pencil"></span>
</button>

<ng-container *ngIf="editing && !lukittu; else readOnly">
  <div class="tili-num">{{ numero }}</div>
  <div class="date-range long-col">{{ label }}</div>
  <div class="date-range short-col">
    {{ formattedValue }}
    <mat-date-range-input [rangePicker]="picker" style="width: 1px; height: 1px; color: transparent; background-color: transparent; overflow: hidden; max-width: 1px; max-height: 1px; float: right;">
      <input matStartDate placeholder="Alkoi" [(ngModel)]="value1" (blur)="tallennaMuuttunutArvo()" style="width: 80px; text-align: center; background-color: white;">
      <input matEndDate placeholder="Loppui" [(ngModel)]="value2" (blur)="tallennaMuuttunutArvo()" style="width: 80px; text-align: center; background-color: white;">
    </mat-date-range-input>
  </div>
  <div class="date-range end-marking">
    <mat-datepicker-toggle style="position: absolute; top: -2px; left: 2px;" [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker (closed)="tallennaMuuttunutArvo()" #picker></mat-date-range-picker>
    <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess" class="save-success-text" [@quickInSlowOut]="'in'">
      Päivämääräväli tallennettiin
    </span>
    <mat-icon *ngIf="saveSuccess" class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    <span *ngIf="saveFailure" class="save-failure-text">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span>
    <mat-icon *ngIf="saveFailure" class="save-failure-icon" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
  </div>
  <!-- <div *ngIf="naytaEhdotus" style="flex-basis: 100%;" class="ehdotusnappula-container">
    <button class="primarybutton" (click)="ehdotus()" mat-button>Hae ehdotus</button>
  </div> -->
</ng-container>

<ng-template #readOnly>
  <div class="tili-num">{{ numero }}</div>
  <div class="long-col">{{ label }}</div>
  <div class="short-col-ext">{{ formattedValue }}</div>
  <!-- <div class="end-marking"></div> -->
</ng-template>