import { CodeCheckService } from '../../../_shared-core/service/code-check.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { VeroilmoituksenValueConstants, VeroilmoituksenAvainluvut, VeroilmoitusDraft } from '../../model/kirjanpito'
import { IlmoitusRivi } from '../base-ilmoitin-export.service'
import { VeroilmoitusValidationError } from './veroilmoitus-laskenta.service'

export interface RepeatingMetadata {
  startIndex: number
  endIndex: number
  order: number
  groupdIdentifier: string
}
export abstract class VeroilmoitusBaseSpecialiazedCalculationService {

  protected tietokannastaLaskennoille: VeroilmoitusDraft | null
  protected eiTallennetutLaskennoille: VeroilmoituksenAvainluvut | null
  protected oletuksetLaskennoille: VeroilmoituksenAvainluvut | null

  constructor(
    protected _currencyService: CurrencyService,
    protected _codeCheckService: CodeCheckService
  ) { }

  annaTunniste(numero: string, group: string, order: number) {
    return group + '_' + order + '_' + numero
  }

  protected _varmistaEttaEnsimmainenKenttaOnYksiSallituista(arvot: IlmoitusRivi[], aloitusindeksi: number, sallitut: Set<string>): boolean {
    if (aloitusindeksi > -1) {
      const howMany = arvot[aloitusindeksi]
      if (howMany.key === '001') {
        const current = arvot[aloitusindeksi + 1]
        return sallitut.has(current.key)
      }
    }
    return false
  }

  protected _parsiMetadata(arvot: IlmoitusRivi[], groupIdentifier: string, aloitusindeksi: number): RepeatingMetadata[] {
    const metadatas: RepeatingMetadata[] = []
    if (aloitusindeksi > -1) {
      const howMany = arvot[aloitusindeksi]
      if (howMany.key === '001') {
        let cont = true
        let index = aloitusindeksi + 1
        let order = 1
        let currentMetadata: RepeatingMetadata = {
          endIndex: 0,
          startIndex: index,
          groupdIdentifier: groupIdentifier,
          order: order
        }
        while (cont) {
          const current = arvot[index]
          if (current.key === '009') {
            currentMetadata.endIndex = index - 1
            metadatas.push(currentMetadata)
            order++
            currentMetadata = {
              endIndex: 0,
              startIndex: index + 1,
              groupdIdentifier: groupIdentifier,
              order: order
            }
            if (current.value === howMany.value) {
              cont = false
            }
          }
          index++
          if (index > 10000) { throw new Error('Looping way too long.') }
        }
      }
    }
    return metadatas
  }

  protected _annaCheckboxArvo(numero: string, id: string, log?: 'log'): VeroilmoituksenValueConstants {
    const identifier = numero + '_' + id
    if (this.eiTallennetutLaskennoille[identifier] !== undefined) {
      if (log) { console.log(identifier, 'return checkbox 1', this.eiTallennetutLaskennoille[identifier]) }
      return this.eiTallennetutLaskennoille[identifier] as VeroilmoituksenValueConstants
    }
    if (this.tietokannastaLaskennoille.arvotEditable[identifier] !== undefined) {
      if (log) { console.log(identifier, 'return checkbox 2', this.tietokannastaLaskennoille.arvotEditable[identifier]) }
      return this.tietokannastaLaskennoille.arvotEditable[identifier] as VeroilmoituksenValueConstants
    }
    return undefined
  }

  protected _annaArvo(numero: string, defaultProvider?: () => string, log?: 'log'): string | number {
    if (defaultProvider) {
      const calculated = defaultProvider()
      if (log === 'log') {
        console.log(numero, calculated)
      }
      this.oletuksetLaskennoille[numero] = calculated
    }
    return this._annaKayttajanMuokkaamaArvoTaiOletus(numero, log)
  }

  protected _annaArvoString(numero: string, defaultProvider?: () => string, log?: 'log'): string {
    if (defaultProvider) {
      const calculated = defaultProvider()
      if (log === 'log') {
        console.log(numero, calculated)
      }
      this.oletuksetLaskennoille[numero] = calculated
    }
    const arvo = this._annaKayttajanMuokkaamaArvoTaiOletus(numero, log)
    if (arvo === undefined) {
      return undefined
    } else if (arvo === null) {
      return null
    }
    return arvo + ''
  }

  protected _asetaNumero(numero: string, arvot: IlmoitusRivi[], defaultProvider?: () => number): number {
    const arvo = this._annaNumero(numero, defaultProvider)
    if (arvo !== undefined && arvo !== null) {
      arvot.push({ key: numero, value: this._annaLuku(arvo) })
    }
    return arvo || 0
  }

  protected _asetaArvo(numero: string, arvot: IlmoitusRivi[], defaultProvider?: () => string, condition?: (arvo: string | number) => boolean): string | number {
    const arvo = this._annaArvo(numero, defaultProvider)
    if (arvo !== undefined && arvo !== null && (!condition || condition(arvo))) {
      arvot.push({ key: numero, value: arvo + '' })
    }
    return arvo
  }

  protected _annaNumero(numero: string, defaultProvider?: () => number): number {
    if (defaultProvider) {
      const calculated = this._currencyService.roundHalfUp(defaultProvider(), 2)
      this.oletuksetLaskennoille[numero] = calculated
    }
    return this._annaKayttajanMuokkaamaNumeroTaiOletus(numero)
  }

  protected _mapValues(rivit: IlmoitusRivi[]): { numberMap: Map<string, number>, stringMap: Map<string, string> } {

    const numberMap: Map<string, number> = new Map()
    const stringMap: Map<string, string> = new Map()
    for (const rivi of rivit) {

      try {
        const asNum = Number(rivi.value.replace(',', '.'))
        if (!isNaN(asNum)) {
          numberMap.set(rivi.key, this._currencyService.roundHalfUp(asNum, 2))
        }
        stringMap.set(rivi.key, rivi.value)
      } catch (err) {
        console.error('Error while handling ' + rivi.key + ' with value ' + rivi?.value, err)
        throw err
      }

    }

    return { stringMap: stringMap, numberMap: numberMap }

  }

  protected _sum(...numbers: number[]): number {
    let sum = 0
    for (const number of numbers) {
      if (number) {
        sum += this._currencyService.roundHalfUp(number, 2)
      }
    }
    return this._currencyService.roundHalfUp(sum, 2)
  }

  protected _onkoOlemassaJaNegativiinen(map: Map<string, number>, key: string): boolean {
    const value = map.get(key) ?? null
    if (value !== null && this._currencyService.roundHalfUp(value, 2) < 0) {
      return true
    }
    return false
  }

  protected _sumFromMapDebug(map: Map<string, number>, ...keys: string[]): number {
    let sum = 0
    for (const key of keys) {
      sum += this._currencyService.roundHalfUp(map.get(key) ?? 0, 2)
      console.log('ADD ' + key, this._currencyService.roundHalfUp(map.get(key) ?? 0, 2), 'SUM', this._currencyService.roundHalfUp(sum, 2))
    }
    return this._currencyService.roundHalfUp(sum, 2)
  }

  protected _sumFromMap(map: Map<string, number>, ...keys: string[]): number {
    let sum = 0
    for (const key of keys) {
      sum += this._currencyService.roundHalfUp(map.get(key) ?? 0, 2)
    }
    return this._currencyService.roundHalfUp(sum, 2)
  }

  protected _onkoOsaAuki(oletus: boolean, numero: string): boolean {
    const kayttajanMuokkaama = this.tietokannastaLaskennoille.arvotEditable[numero]
    if (kayttajanMuokkaama === VeroilmoituksenValueConstants.TRUE) {
      return true
    }
    if (kayttajanMuokkaama === VeroilmoituksenValueConstants.FALSE) {
      return false
    }
    return oletus
  }

  protected _annaLuku(luku: number): string {
    return this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(luku, 'fi')
  }

  protected _annaKayttajanMuokkaamaArvoTaiOletus(numero: string, log?: 'log'): string | number | undefined {
    if (this.eiTallennetutLaskennoille[numero] !== undefined) {
      if (log) { console.log(numero, 'return 1', this.eiTallennetutLaskennoille[numero]) }
      return this.eiTallennetutLaskennoille[numero]
    }
    if (this.tietokannastaLaskennoille.arvotEditable[numero] !== undefined) {
      if (log) { console.log(numero, 'return 2', this.tietokannastaLaskennoille.arvotEditable[numero]) }
      return this.tietokannastaLaskennoille.arvotEditable[numero]
    }
    if (this.oletuksetLaskennoille[numero] !== undefined) {
      if (log) { console.log(numero, 'return 3', this.oletuksetLaskennoille[numero]) }
      return this.oletuksetLaskennoille[numero]
    }
    return undefined
  }

  protected _annaKayttajanMuokkaamaNumero(numero: string): number | null | undefined {
    const arvo = this._annaKayttajanMuokkaamaArvo(numero)
    if (arvo === undefined) {
      return undefined
    }
    if (arvo === null) {
      return null
    }
    if (this._currencyService.onkoNumero(arvo)) {
      return arvo as number
    }
    return Number(arvo)
  }

  protected _annaKayttajanMuokkaamaNumeroTaiOletus(numero: string, log?: true): number | null | undefined {
    const arvo = this._annaKayttajanMuokkaamaArvo(numero, log)
    if (arvo === undefined) {
      const oletus = this.oletuksetLaskennoille[numero]
      if (oletus !== undefined) {
        if (this._currencyService.onkoNumero(oletus)) {
          return oletus as number
        }
        if (this._currencyService.onkoMerkkijonoNumero(oletus + '')) {
          return this._currencyService.muutaMerkkijonoNumeroksiKaikkiDesimaalit(oletus + '')
        }
        throw new Error('Arvo this.oletuksetLaskennoille[' + numero + '], ' + oletus + ' ei ole numero!')
      }
      return undefined
    }
    if (arvo === null) {
      return null
    }
    if (this._currencyService.onkoNumero(arvo)) {
      return arvo as number
    }
    if (this._currencyService.onkoMerkkijonoNumero(arvo + '')) {
      return this._currencyService.muutaMerkkijonoNumeroksiKaikkiDesimaalit(arvo + '')
    }
    throw new Error('Arvo numerolle ' + numero + ', ' + arvo + ' ei ole numero!')
  }

  protected _annaKayttajanMuokkaamaArvo(numero: string, log?: true): number | string | undefined {
    if (this.eiTallennetutLaskennoille[numero] !== undefined) {
      if (log) { console.log('Return kayttajanMuokkaamatEiTallennetut ' + this.eiTallennetutLaskennoille[numero] + ' for ' + numero) }
      return this.eiTallennetutLaskennoille[numero]
    }
    if (this.tietokannastaLaskennoille.arvotEditable[numero] !== undefined) {
      if (log) { console.log('Return tietokannasta.arvotEditable ' + this.tietokannastaLaskennoille.arvotEditable[numero] + ' for ' + numero) }
      return this.tietokannastaLaskennoille.arvotEditable[numero]
    }
    return undefined
  }

  protected _getRivitForRepeatingPart(repeatingMetadata: RepeatingMetadata, arvot: IlmoitusRivi[]): IlmoitusRivi[] {
    return arvot.slice(repeatingMetadata.startIndex, repeatingMetadata.endIndex + 1)
  }


  protected validateVainJompikumpiKentista(kentta1: string, kentta2: string, errors: VeroilmoitusValidationError[], numberMap: Map<string, number>, customErrorMessage?: string) {
    const v1 = numberMap.get(kentta1) ?? 0
    const v2 = numberMap.get(kentta2) ?? 0
    if (v1 !== 0 && v2 !== 0) {
      errors.push({
        fields: kentta1 + ', ' + kentta2,
        message: customErrorMessage ? customErrorMessage : 'Vain toisessa kentässä voi olla nollasta tai tyhjästä poikkeava arvo'
      })
    }
  }

  protected validateJompikumpiIlmoitettuTaiAinakinToinenNolla(kentta1: string, kentta2: string, errors: VeroilmoitusValidationError[], numberMap: Map<string, number>, customKentat?: string, customErrorMessage?: string) {
    const v1 = numberMap.get(kentta1) ?? null
    const v2 = numberMap.get(kentta2) ?? null
    if (v1 === null && v2 === null) {
      errors.push({
        fields: customKentat ? customKentat : kentta1 + ', ' + kentta2,
        message: customErrorMessage ? customErrorMessage : 'Ainakin toinen kentistä on ilmoitettava tai molemmat nollina.'
      })
    }
  }

  protected _etsiArvo(numero: string, rivit: IlmoitusRivi[]): string | null {
    return rivit.find(a => a.key === numero)?.value ?? null
  }

  protected _etsiNumero(numero: string, rivit: IlmoitusRivi[]): number | null {
    try {
      const value = rivit.find(a => a.key === numero)?.value ?? null
      if (value?.charAt(value.length - 3) === ',') {
        const asNum = Number(value.replace(',', '.'))
        if (!isNaN(asNum)) {
          return this._currencyService.roundHalfUp(asNum, 2)
        }
      }
      return null
    } catch (err) {
      console.error('Error while handling ' + numero + ' with value ' + rivit.find(a => a.key === numero)?.value, err)
      throw err
    }
  }

  protected _etsiKokonaisluku(numero: string, rivit: IlmoitusRivi[]): number | null {
    const value = rivit.find(a => a.key === numero)?.value ?? null
    if (value) {
      const asNum = Number(value)
      if (!isNaN(asNum)) {
        return this._currencyService.roundHalfUp(asNum, 0)
      }
    }
    return null
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti +N15, eli
   * "+N - numeerista tietoa, kokonaisluku, ei desimaalierotinta. Miinusmerkki (-) alussa ei ole sallittu"
   * @param field Mikä kenttä, esim '470'
   * @param numberMap
   * @param errors
   */
  protected _tarkistaPlusN15(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenJaMaksimissaan(field, numberMap, errors, 999999999999999, 0)
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti +N8, eli
   * "+N - numeerista tietoa, kokonaisluku, ei desimaalierotinta. Miinusmerkki (-) alussa ei ole sallittu"
   * @param field Mikä kenttä, esim '470'
   * @param numberMap
   * @param errors
   */
  protected _tarkistaPlusN8(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenJaMaksimissaan(field, numberMap, errors, 99999999, 0)
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti R13,2, eli
   * "Rn,n - numeerista rahatietoa, jossa desimaalierotin (pilkku ,) on pakollinen.
   * Ensimmäinen n kertoo kokonaisluvun sallitun pituuden, toinen n kertoo annettavien
   * desimaalien määrän, negatiivisia lukuja ei sallita."
   * @param field Mikä kenttä, esim '470'
   * @param numberMap
   * @param errors
   */
  protected _tarkistaR13Pilkku2(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenJaMaksimissaan(field, numberMap, errors, 9999999999999.99, 2)
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti G13,2, eli
   * "Gn,n - numeerista rahatietoa, jossa desimaalierotin (pilkku ,) on pakollinen.
   * Ensimmäinen n kertoo kokonaisluvun sallitun pituuden, toinen n kertoo an-
   * nettavien desimaalien määrän, negatiiviset luvut sallitaan."
   * @param field Mikä kenttä, esim '470'
   * @param numberMap
   * @param errors
   */
  protected _tarkistaG13Pilkku2(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenTaiNegatiivinenJaMaksimissaan(field, numberMap, errors, 9999999999999.99, -9999999999999.99, 2)
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti +D3,2, eli
   * "+Dn,n - numeerista tietoa, jossa desimaalierotin (pilkku) on pakollinen ja
   * miinusmerkki (-) alussa ei ole sallittu. Ensimmäinen n kertoo kokonaisosan
   * sallitun maksimi pituuden, toinen n kertoo annettavien desimaalien määrän."
   * @param field Mikä kenttä, esim '470'
   * @param numberMap
   * @param errors
   */
  protected _tarkistaPlusD3Pilkku2(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenJaMaksimissaan(field, numberMap, errors, 999.99, 2)
  }

  /**
   * Tarkistaa verohallinnon ohjeen mukaisesti +D2,2, eli
   * "+Dn,n - numeerista tietoa, jossa desimaalierotin (pilkku) on pakollinen ja
   * miinusmerkki (-) alussa ei ole sallittu. Ensimmäinen n kertoo kokonaisosan
   * sallitun maksimi pituuden, toinen n kertoo annettavien desimaalien määrän."
   * @param field
   * @param numberMap
   * @param errors
   */
  protected _tarkistaPlusD2Pilkku2(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    this._tarkistaPositiivinenJaMaksimissaan(field, numberMap, errors, 99.99, 2)
  }

  protected _tarkistaHetuTaiYTunnus(field: string, value: string, errors: VeroilmoitusValidationError[]) {
    if (
      !value ||
      (
        value !== '0000000-0' &&
        !(value.endsWith('-UUUU') && value.length === 11) &&
        !this._codeCheckService.isValidFinnishPersonalId(value) &&
        !this._codeCheckService.isValidYTunnus(value)
      )
    ) {
      errors.push({
        fields: field,
        message: 'Y-tunnus tai henkilötunnus puuttuu tai se on virheellinen. Jos sinulla ei ole antaa suomalaista y- tai henkilötunnusta, käytä y-tunnusta 0000000-0 tai syntymäaika (ppkkvv)-UUUU. Mikäli syntymäaika ei ole tiedossa, käytä muotoa 010101-UUUU.'
      })
    }
  }

  protected _tarkistaPositiivinen(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    if (this._onkoOlemassaJaNegativiinen(numberMap, field)) {
      errors.push({
        fields: field,
        message: 'Arvo ei saa olla negatiivinen.'
      })
    }
  }

  protected _tarkistaPakollinen(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[]) {
    const value = numberMap.get(field)
    if (value === null || value === undefined) {
      errors.push({
        fields: field,
        message: 'Arvo puuttuu.'
      })
    }
  }

  private _tarkistaPositiivinenJaMaksimissaan(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[], upperLimit: number, decimals: number) {
    const value = numberMap.get(field) ?? null
    if (value !== null) {
      const rounded = this._currencyService.roundHalfUp(value, decimals)
      if (rounded < 0) {
        errors.push({
          fields: field,
          message: 'Arvo ei saa olla negatiivinen.'
        })
      } else if (rounded > upperLimit) {
        errors.push({
          fields: field,
          message: 'Arvo ei saa olla enempää kuin ' + this._currencyService.formatoiDesimaali(upperLimit, decimals, 'fi') + '.'
        })
      }
    }
  }

  private _tarkistaPositiivinenTaiNegatiivinenJaMaksimissaan(field: string, numberMap: Map<string, number>, errors: VeroilmoitusValidationError[], upperLimit: number, lowerLimit: number, decimals: number) {
    const value = numberMap.get(field) ?? null
    if (value !== null) {
      const rounded = this._currencyService.roundHalfUp(value, decimals)
      if (rounded < lowerLimit) {
        errors.push({
          fields: field,
          message: 'Arvo ei voi olla vähempää kuin ' + this._currencyService.formatoiDesimaali(lowerLimit, decimals, 'fi') + '.'
        })
      } else if (rounded > upperLimit) {
        errors.push({
          fields: field,
          message: 'Arvo ei saa olla enempää kuin ' + this._currencyService.formatoiDesimaali(upperLimit, decimals, 'fi') + '.'
        })
      }
    }
  }

}
