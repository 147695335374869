import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { firstValueFrom, Subject } from 'rxjs'
import { take, map } from 'rxjs/operators'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { PostmarkappDmarcReportPyynto, PostmarkappDmarcReportVastaus, PostmarkappDmarcReport } from 'app/_jaettu-lemonator/model/integraatiot'

@Component({
  templateUrl: './dmarc-raportti.component.html'
})
export class DmarcRaporttiComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  report: PostmarkappDmarcReport
  lataa: boolean = true

  constructor(
    private errorHandler: ErrorHandler,
    private router: Router,
    private route: ActivatedRoute,
    private _firebase: FirebaseLemonator
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {
    firstValueFrom(this.route.params.pipe(
      map(params => {
        return params.raporttiId
      }),
      take(1)
    )).then(id => {
      const payload: PostmarkappDmarcReportPyynto = {
        id: id
      }
      return this._firebase.functionsCall<PostmarkappDmarcReportPyynto, PostmarkappDmarcReportVastaus>('integraatiotPostmarkappDmarcHaeRaportti', payload)
    }).then(r => {
      this.report = r.report
      this.lataa = false
    }).catch(err => {
      this.errorHandler.handleError(err)
    })
  }

  peruuta() {
    this.router.navigate(['yllapito', 'postmarkapp', 'dmarc'])
  }

}
