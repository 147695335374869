import { animate, state, style, transition, trigger } from '@angular/animations'
import { OnDestroy, ChangeDetectionStrategy, Component, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

import { TallennaArvoEvent, VeroilmoituksenMuokkaustiedot } from '../veroilmoitus.component'
import { KirjanpitoVeroilmoitusBaseRowComponent } from './base-veroilmoitus-row.component'

import { Korvausparametrit, VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'

@Component({
  selector: '[app-veroilmoitus-part]',
  templateUrl: './veroilmoitus-part.component.html',
  styleUrls: ['./veroilmoitus-row.styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s') // Timings in string: 'duration delay easing'
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoVeroilmoitusPartComponent extends KirjanpitoVeroilmoitusBaseRowComponent implements OnChanges, OnDestroy {

  @Output() tallennaArvo: EventEmitter<TallennaArvoEvent> = new EventEmitter<TallennaArvoEvent>()

  @Input() lukittu: boolean

  @Input() numero: string
  @Input() korvausparametrit: Korvausparametrit
  @Input() veroilmoitus: VeroilmoituksenMuokkaustiedot

  @Input() optional: boolean = false

  label: string = ''
  help: string = ''
  saving: boolean = false
  saveSuccess: boolean = false
  saveFailure: boolean = false

  valueFormControl: UntypedFormControl = new UntypedFormControl()

  constructor(
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_currencyService, _veroilmoitusTranslationService)
  }

  ngOnChanges() {
    if (this.numero && this.veroilmoitus?.perustiedot?.asiakas) {
      this.label = this._localizeNumero(this.numero, this.veroilmoitus, this.korvausparametrit)
      this.help = this._localizeNumeroHelp(this.numero, this.veroilmoitus, this.korvausparametrit)
      const val = this._getValue(this.numero, this.veroilmoitus)
      if (this.valueFormControl.value !== val) {
        // console.log('PART WRITE, old:', this.valueFormControl.value, 'new:', val)
        this.valueFormControl.setValue(val, { emitViewToModelChange: false, onlySelf: true, emitEvent: false })
      }
    }
  }

  public tallennaMuuttunutArvo() {
    this.saveFailure = false
    this.saveSuccess = false
    this.saving = true
    const tallennusData: TallennaArvoEvent = {
      arvo: this._annaArvoTallentamistaVarten(this.numero, this.valueFormControl.value, this.veroilmoitus),
      numero: this.numero,
      tyyppi: 'one',
      doneCallback: (outcome) => {
        this.saving = false
        if (outcome === 'error') {
          this.saveFailure = true
          this.saveSuccess = false
        } else if (outcome === 'success') {
          this.saveFailure = false
          this.saveSuccess = true
        } else {
          this.saveFailure = false
          this.saveSuccess = false
        }
        this._changeDetectorRef.markForCheck()
        setTimeout(() => {
          this.saveSuccess = false
          this._changeDetectorRef.markForCheck()
        }, 1000)
      }
    }
    this.tallennaArvo.next(tallennusData)
  }

}
