import { LocalDate, TuettuKieli } from '../../_shared-core/model/common'
import { TranslationService } from '../../_jaettu/service/translation.service'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampProvider } from '../../_shared-core/service/timestamp-provider.interface'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { StringService } from '../../_shared-core/service/string.service'
import { LaskuSharedService, ReskontraService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuIndeksoija } from '../../_jaettu/service/lasku/lasku.indeksoija'
import { LaskuUriService } from '../../_jaettu/service/lasku/lasku-uri.service'
import { ViitenumeroService } from '../../_shared-core/service/viitenumero.service'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'
import { BatchManipulator, FirestoreProvider, FirestoreWriteBatch, LaskuTallennusService } from '../../_jaettu/service/lasku/lasku-tallennus.service'
import { Lasku, LaskuBase, LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta, LaskuReskontra, LaskunAsiakas, LaskunAsiakastyyppi, LaskunLahetystapa, LaskunLahetystyyppi, LaskunSahkoinenOsoite, LaskunTuote, LaskunTyypit, UUDEN_LASKUN_AVAIN } from '../../_jaettu/model/lasku'
import { LocalMonth } from '../../_shared-core/model/common'
import { Asiakas, AsiakkaalleLemonatoristaLahetettyLasku } from '../model/asiakas'
import { KayttajanTiedot } from '../../_jaettu/model/kayttaja'
import { LaskunAlvMaaritysFinder } from '../service/alv-lemonator-base.service'

export class AlvLaskunLahetysService {

  public static lahettavanAsiakkaanAsiakasId = 1598
  private lahettavanAsiakkaanAsiakasAvain = 'o5AlyootOS13jv3I0dyH'
  private lahettavanAsiakkaanLahettavanKayttajanAvain = 'd8egQwuMihaEh1oSAErRCIHSkdo2' // Vero Karhu -käyttäjä tällä asiakkaalla

  private _laskuTallennusService: LaskuTallennusService

  constructor(
    private _translationService: TranslationService,
    private _dateService: DateService,
    private _firestoreProvider: FirestoreProvider,
    private _timestampService: TimestampProvider,
    private _currencyService: CurrencyService,
    private _viitenumeroService: ViitenumeroService,
    private _laskuKopioija: LaskuKopioija,
    private _stringService: StringService,
    private _laskuSharedService: LaskuSharedService,
    private _laskuIndeksoija: LaskuIndeksoija,
    private _laskuUriService: LaskuUriService,
    private _reskontraService: ReskontraService,
    private _alvLemonatorService: LaskunAlvMaaritysFinder
  ) {

    this._laskuTallennusService = new LaskuTallennusService(
      this._laskuSharedService,
      this._laskuKopioija,
      this._laskuIndeksoija,
      this._currencyService,
      this._dateService,
      this._laskuUriService,
      this._translationService,
      this._timestampService,
      this._reskontraService,
      this._firestoreProvider,
      this._viitenumeroService,
      this._stringService
    )
  }

  public async annaTallennettavaLasku(asiakasJolleLahetetaan: Asiakas, erapvm: LocalDate, summa: number, viitenumero: string, siirraErapaiva: boolean): Promise<Lasku> {
    // Tee lasku
    const lasku: Lasku = this._laskuKopioija.annaUusiLasku()

    lasku.avain = UUDEN_LASKUN_AVAIN
    lasku.lahetystyyppi = LaskunLahetystyyppi.KERTA
    lasku.asiakas = this.lemonatorAsiakasLaskunAsiakkaaksi(asiakasJolleLahetetaan)

    // lasku.avain
    // lasku.date = this.timestampProvider.now()
    lasku.kieli = asiakasJolleLahetetaan.laskunKieli || 'fi'
    // lasku.korvaus
    lasku.viitenumero = viitenumero

    // Päivämäärät
    const pvm = this._timestampService.now()
    const localPvm = this._dateService.timestampToLocalDate(pvm)

    if (siirraErapaiva) {
      // Jotkin pankit, kuten OP, eivät ota vastaan sähköisiä laskuja jos eräpäivä on liian lähellä nykyhetkeä.
      const nytPlus5 = this._dateService.lisaaPaiviaPaikallinen(this._dateService.currentLocalDate(), 5)
      if (this._dateService.compareLocalDates(erapvm, '<', nytPlus5)) {
        erapvm = nytPlus5
      }
    }

    lasku.date = pvm

    lasku.toimituspvm = pvm
    lasku.toimituspvml = localPvm
    lasku.toimitusp = this._dateService.localDateToNumber(localPvm)
    lasku.pvm = pvm
    lasku.pvml = localPvm
    lasku.p = this._dateService.localDateToNumber(localPvm)
    lasku.erapvm = this._timestampService.localDateToTimestamp(erapvm)
    lasku.erapvml = erapvm
    lasku.erap = this._dateService.localDateToNumber(erapvm)

    // Lisää tuote
    const tuote = await this.annaLaskunTuote(lasku.asiakas, summa, lasku.kieli)
    lasku.tuotteet = []
    lasku.tuotteet.push(tuote)
    return lasku
  }

  public async lahetaAlvLasku(
    asiakasJolleLahetetaan: Asiakas,
    sahkoinenLaskutusosoite: LaskunSahkoinenOsoite,
    erapvm: LocalDate,
    kuukausi: LocalMonth,
    summa: number,
    viitenumero: string,
    lahetystapa: 'vie-toisen-hyvaksyntajonoon' | 'laheta-heti-sahkoisesti',
    siirraErapaiva: 'siirra-erapaiva' | 'erapaivaa-ei-siirreta',
    lahetystiedonAvain?: string
  ): Promise<void> {

    const lasku = await this.annaTallennettavaLasku(asiakasJolleLahetetaan, erapvm, summa, viitenumero, siirraErapaiva !== 'erapaivaa-ei-siirreta')

    // Tämä metodi luo uuden laskun (katso annaTallennettavaLasku -metodi), joten reskontrakin on pakosti tyhjä.
    const reskontra: LaskuReskontra[] = []

    const nyt = this._timestampService.now()
    const manipulator: BatchManipulator = {
      manipulate: (firestoreProvider: FirestoreProvider, batch: FirestoreWriteBatch, jl: Lasku, ka: LaskuBase) => {

        // Lisää jatkuvan laskun lähetystieto
        const tunniste = '___alv___' + kuukausi.year + '___' + kuukausi.month
        const lahetystieto: AsiakkaalleLemonatoristaLahetettyLasku = {
          avain: lahetystiedonAvain ?? firestoreProvider.annaUusiAvain(),
          asiakasAvain: asiakasJolleLahetetaan.avain,
          asiakasId: asiakasJolleLahetetaan.asiakasId + '',
          laskuAvain: lasku.avain,
          luoja: 'lemonator_system',
          luotu: nyt,
          paivitetty: nyt,
          paivittaja: 'lemonator_system',
          poistettu: false,
          tunniste: tunniste
        }

        // console.log('Kirjoitetaan:', 'customers/' + asiakasJolleLahetetaan.avain + '/customer-sent-vat-invoice/' + lahetystieto.avain)
        const doc = firestoreProvider.annaDoc('customers/' + asiakasJolleLahetetaan.avain + '/customer-sent-vat-invoice/' + lahetystieto.avain)
        batch.set(doc, lahetystieto)

        const id = firestoreProvider.annaUusiAvain()
        const sahkoiseksiTyodata: LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta = {
          aloitettu: this._timestampService.now(),
          kasiteltavaAvain: ka.avain,
          laskuAvain: jl.avain,
          myyvanAsiakkaanAvain: this.lahettavanAsiakkaanAsiakasAvain,
          ostavanAsiakkaanAvain: asiakasJolleLahetetaan.avain,
          myyvanAsiakkaanId: AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId + '',
          uudelleenyrityksia: 0
        }
        const sahkoiseksiUri = this._laskuUriService.getTyojonoLaskuMyyntilaskuOstolaskuksi(sahkoiseksiTyodata.myyvanAsiakkaanAvain, id)
        const sahkoiseksiDoc = firestoreProvider.annaDoc(sahkoiseksiUri)
        batch.set(sahkoiseksiDoc, sahkoiseksiTyodata)

      }
    }

    const kayttajanTiedot: KayttajanTiedot = {
      asiakasId: AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId + '',
      asiakasAvain: this.lahettavanAsiakkaanAsiakasAvain,
      uid: this.lahettavanAsiakkaanLahettavanKayttajanAvain
    }

    if (lahetystapa === 'vie-toisen-hyvaksyntajonoon') {
      return this._laskuTallennusService.merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(kayttajanTiedot, sahkoinenLaskutusosoite, lasku, lasku, reskontra, manipulator)
    } else if (lahetystapa === 'laheta-heti-sahkoisesti') {
      return this._laskuTallennusService.merkitseLaskuLahetetyksiSahkoisesti(kayttajanTiedot, sahkoinenLaskutusosoite, lasku, lasku, reskontra, manipulator)
    } else {
      throw new Error('Unkown lahetystapa ' + lahetystapa)
    }

  }

  private annaAlv(alvt: { prosentti: number, tunniste: string }[]): { prosentti: number, tunniste: string } {
    if (alvt.length === 1) {
      return alvt[0]
    }
    for (const alv of alvt) {
      if (alv.prosentti === 0) {
        return alv
      }
    }
    return alvt[0]
  }

  private async annaLaskunTuote(asiakas: LaskunAsiakas, kuukausihinta: number, kieli: TuettuKieli): Promise<LaskunTuote> {

    const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(asiakas.laskunTyyppi)
    const laskutyypinAlvt = await this._alvLemonatorService.annaLaskutyypinAlvt(laskunTyyppi, asiakas.maa)
    const valittuAlv = this.annaAlv(laskutyypinAlvt)

    return {
      ale: null,
      alv: valittuAlv,
      hinta: kuukausihinta,
      maara: 1,
      tuote: {
        alv: {},
        avain: 'kirjanpitoalv',
        date: this._timestampService.now(),
        hinta: kuukausihinta,
        nimi: kieli === 'en' ? 'VAT' : 'ALV'
      }
    }
  }

  private lemonatorAsiakasLaskunAsiakkaaksi(asiakas: Asiakas): LaskunAsiakas {
    return {
      avain: asiakas.avain,
      date: this._timestampService.now(),
      katuosoite: asiakas.katuosoite,
      laskunKieli: asiakas.laskunKieli || 'fi',
      laskunTyyppi: LaskunTyypit.TAVALLINEN.avain,
      laskunValuutta: 'EUR',
      laskunVastaanottajat: asiakas.laskunVastaanottajat || [],
      maa: asiakas.maa,
      nimi: asiakas.nimi,
      postinro: asiakas.postinro,
      postitmp: asiakas.postitmp,
      ytunnus: asiakas.ytunnus,
      asiakastyyppi: LaskunAsiakastyyppi.YRITYS, // Kaikki Lemonatorin asiakkaat ovat yrityksiä
      sahkoinenosoite: asiakas.sahkoinenLaskutusosoite,
      viimeisinLaskuLahetetty: LaskunLahetystapa.SAHKOINEN
    }
  }

}
