import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Kayttaja } from '../_jaettu-lemonator/model/asiakas'
import { VaihdaSalasanaPyynto, VaihdaSalasanaVastaus } from '../_jaettu/lemonator/model/kayttajahallinta'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

@Component({
  templateUrl: './kayttaja.vaihda-salasana.dialog.html'
})
export class KayttajaVaihdaSalasanaDialog implements OnInit {

  uusiSalasana: string = null
  commonError: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Kayttaja,
    private dialogRef: MatDialogRef<KayttajaVaihdaSalasanaDialog>,
    private errorHandler: ErrorHandler,
    private _firebaseLemonaid: FirebaseLemonaid,
    private ladataanService: LadataanService
  ) {

  }

  vaihdaSalasana() {
    this.ladataanService.aloitaLataaminen()
    const data: VaihdaSalasanaPyynto = {
      uid: this.data.avain
    }

    this._firebaseLemonaid.functionsCall<VaihdaSalasanaPyynto, VaihdaSalasanaVastaus>('lemonatorVaihdaSalasana', data).then(vastaus => {
      this.ladataanService.lopetaLataaminen()
      this.uusiSalasana = vastaus.pwd
    }).catch(error => {
      this.ladataanService.lopetaLataaminen()
      this.commonError = 'Salasanan vaihtamisen yhteydessä tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpidolle.'
      this.errorHandler.handleError(error)
    })
  }

  ngOnInit() {

  }

}
