import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'


import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'


import { Subject, Observable, of, BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs'
import { switchMap, map, tap } from 'rxjs/operators'
import { ApixLahetettyLasku, ApixHaeAsiakkaanLahetetytLaskutPyynto, ApixHaeAsiakkaanLahetetytLaskutVastaus, ApixSopimuksenTiedot, ApixHaeSopimuksetVastaus } from 'app/_jaettu/model/apix'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

export type AsiakasProperties = 'nimi' | 'ytunnus' | 'exportoi' | 'asiakasId'

@Component({
  templateUrl: './apix-laskujen-tiedot.component.html',
  styleUrls: ['./apix-laskujen-tiedot.component.css']
})
export class AsiakkaanApixLaskujenTiedotComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _reloadSopimuksetSubject = new BehaviorSubject(1)

  laskutObservable: Observable<ApixLahetettyLasku[]>
  sopimuksetObservable: Observable<ApixSopimuksenTiedot[]>
  ladattu: boolean = false

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _ladataanService: LadataanService
  ) {

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() {

    const apixLaskutJaSopimuksetObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this._reloadSopimuksetSubject]).pipe(
      tap(() => {
        this.ladattu = false
      }),
      switchMap(([asiakas, reload]) => {
        if (asiakas) {
          const params: ApixHaeAsiakkaanLahetetytLaskutPyynto = {
            asiakasAvain: asiakas.avain
          }
          return this._firebaseLemonaid.functionsCall<ApixHaeAsiakkaanLahetetytLaskutPyynto, ApixHaeAsiakkaanLahetetytLaskutVastaus>('apixHaeAsiakkaanLahetetytLaskut', params)
        }
        return of<ApixHaeAsiakkaanLahetetytLaskutVastaus>(null)
      }),
      tap(() => {
        this.ladattu = true
      }),
      lemonShare()
    )

    this.laskutObservable = apixLaskutJaSopimuksetObservable.pipe(
      map(data => {
        if (!data) {
          return []
        }
        return data.laskut
      })
    )

    this.sopimuksetObservable = apixLaskutJaSopimuksetObservable.pipe(
      map(data => {
        if (!data) {
          return []
        }
        return data.sopimukset
      })
    )

  }

  async disableAllContracts() {
    this._ladataanService.aloitaLataaminen()
    try {
      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      if (asiakas) {
        const params: ApixHaeAsiakkaanLahetetytLaskutPyynto = {
          asiakasAvain: asiakas.avain
        }
        await this._firebaseLemonaid.functionsCall<ApixHaeAsiakkaanLahetetytLaskutPyynto, ApixHaeSopimuksetVastaus>('apixDeaktivoiAsiakkaanKaikkiSopimukset', params)
        this._reloadSopimuksetSubject.next(Date.now())
        await this._sleep(2000)
      }
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _sleep(millis: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => { resolve() }, millis)
    })
  }

}
