<mat-checkbox class="cb pitka-label" [disabled]="checkboxDisabled || formDisabled" [ngModel]="liitetiedot.valitut[checkboxField]" (ngModelChange)="checkboxChangedEventHandler($event)">
  <ng-container *ngIf="order">
    {{ order }})
  </ng-container>
  {{ parent + '.' + checkboxField | translatetilinpaatos: 2021 | async }}
</mat-checkbox> <button *ngIf="info" mat-icon-button style="vertical-align: middle; top: -3px;" (click)="avaaInfoTeksti(info)">
  <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
</button>
<span *ngIf="saveSuccess">
  <span class="save-success-text" [@quickInSlowOut]="'in'" style=" color: gray;">
    Arvo tallennettiin
  </span>
  <mat-icon class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle'}">done</mat-icon>
</span>
<div style="height: 10px;">
  <div *ngIf="saving" class="saving" style="height: 10px; width: 100%; padding: 0 33px;" [@slowInQuickOut]="'in'">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="saveFailure">
    <span class="save-failure-text">
      Tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.
    </span>
    <mat-icon class="save-failure-icon" [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle'}">error_outline</mat-icon>
  </div>
</div>
<div style="display: flex;">
  <ng-container *ngFor="let f of fields">
    <div *ngIf="liitetiedot.valitut[f.field]" style="margin-left: 34px; padding-bottom: 10px;" [style.width]="70 / fields.length + '%'">
      <ng-container *ngIf="f.type === 'text'">
        <textarea [disabled]="formDisabled" matInput style="border: 1px dashed gray; padding: 3px; width: 100%; font-family: inherit; font-size: 14px;" (blur)="tallennaMuuttunutArvoWithType('text', f.field)" [ngModel]="liitetiedot.tekstit[f.field]" (ngModelChange)="textChangedEventHandler(f.field, $event)" cdkTextareaAutosize></textarea>
        <div *ngIf="f.example" [innerHTML]="f.example" style="padding: 8px; display: inline-block; color: gray;"></div>
      </ng-container>
      <ng-container *ngIf="f.type === 'number'">
        <mat-form-field style="margin-top: 5px;">
          <mat-label>{{ f.example }}</mat-label>
          <input [disabled]="formDisabled" class="skip-space-quick-toggle" decimalNumberField #numberField type="text" (blur)="tallennaMuuttunutArvoWithType('number', f.field)" [ngModel]="liitetiedot.numerot[f.field]" (ngModelChange)="numberChangedEventHandler(f.field, $event)" [name]="namename" matInput data-lpignore="true" />
        </mat-form-field>
      </ng-container>
    </div>
  </ng-container>
</div>