import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, AfterContentInit, Output, EventEmitter, ErrorHandler, ChangeDetectorRef, Input } from '@angular/core'

import { KirjanpitoImageHandlerService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { KirjanpitoImageService } from 'app/_angular/service/kirjanpito/kirjanpito-image.service'
import { WindowSizeService } from 'app/_jaettu-angular/service/window.service'

import { Subject, BehaviorSubject, Observable, of, combineLatest, firstValueFrom } from 'rxjs'
import { switchMap, map, filter, takeUntil } from 'rxjs/operators'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { LaskuSharedService } from 'app/_jaettu/service/lasku/lasku-shared.service'
import { MatSnackBar } from '@angular/material/snack-bar'

import { LocalMonth, KuukausiAikavali } from 'app/_shared-core/model/common'
import { DateService } from 'app/_shared-core/service/date.service'
import { KirjanpitoKuukausiruutu, ListausAsiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { KuukausiruudunTiedot } from 'app/asiakkaat/listaus/asiakkaat.datasource.service'
import { KirjanpitoKuukausiStripService } from 'app/_angular/service/kirjanpito/kirjanpito-kuukausistrip.service'
import { AsiakkaanKirjanpitostatusKuukaudelleDialogData, AsiakkaanKirjanpitostatusKuukaudelleDialog } from 'app/asiakkaat/listaus/asiakkaan-kirjanpitostatus-kuukaudelle.dialog'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { MatDialog } from '@angular/material/dialog'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

interface KirjanpitoStripinKuukausiruudunTiedot extends KuukausiruudunTiedot {
  heittaa?: 1
}

@Component({
  selector: '[app-kirjanpito-kuukausistrip]',
  templateUrl: './kirjanpito-kuukausistrip.component.html',
  styleUrls: ['./kirjanpito-kuukausistrip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
  // animations: [
  //   // the fade-in/fade-out animation.
  //   trigger('fadeInAnimation', [
  //     // the "in" style determines the "resting" state of the element when it is visible.
  //     state('in', style({ opacity: 1 })),

  //     // fade in when created. this could also be written as transition('void => *')
  //     transition(':enter', [style({ opacity: 0 }), animate(2000)]),

  //     // fade out when destroyed. this could also be written as transition('void => *')
  //     transition(':leave', animate(0, style({ opacity: 0 })))
  //   ]),
  //   // the fade-in/fade-out animation.
  //   trigger('fastFadeInOutAnimation', [
  //     // the "in" style determines the "resting" state of the element when it is visible.
  //     state('in', style({ opacity: 1 })),

  //     // fade in when created. this could also be written as transition('void => *')
  //     transition(':enter', [style({ opacity: 0 }), animate(150)]),

  //     // fade out when destroyed. this could also be written as transition('void => *')
  //     transition(':leave', animate(150, style({ opacity: 0 })))
  //   ])
  // ]
})
export class KirjanpitoKuukausiStripComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Output() kuukausiValittiin: EventEmitter<LocalMonth> = new EventEmitter()
  @Output() ruutuValittiin: EventEmitter<KirjanpitoStripinKuukausiruudunTiedot> = new EventEmitter()

  private _valittuAikavaliSubject: BehaviorSubject<KuukausiAikavali> = new BehaviorSubject(null)
  naytettavatKuukaudetObservable: Observable<LocalMonth[]> = this._valittuAikavaliSubject.pipe(
    map(aikavali => {
      return this._dateService.puraAikavali(aikavali)
    })
  )
  kasitellytKuukausiruudutObservable: Observable<KirjanpitoStripinKuukausiruudunTiedot[]>
  // edellisetHyvaksyntaaVaativatObservable: Observable<number>
  // seuraavatHyvaksyntaaVaativatObservable: Observable<number>

  // onkoEdellisiaMuuttuneitaAlvIlmoituksiaObservable: Observable<boolean>
  // onkoSeuraaviaMuuttuneitaAlvIlmoituksiaObservable: Observable<boolean>

  private _ngUnsubscribe = new Subject<void>()
  private _nykyinenListausAsiakasObservable: Observable<ListausAsiakas>

  constructor(
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _asiakasUriService: AsiakasUriService,
    private _firebase: FirebaseLemonator,
    private _kirjanpitoKuukausistripService: KirjanpitoKuukausiStripService,
    private _copyService: KopioijaPalvelu,
    private _dialog: MatDialog,
    private _windowSizeService: WindowSizeService,

    private _kirjanpitoImageService: KirjanpitoImageService,

    private _changeDetector: ChangeDetectorRef,
    private _laskuSharedService: LaskuSharedService,
    private _errorHandler: ErrorHandler,
    private _kirjanpitoImageHandlerService: KirjanpitoImageHandlerService,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit() {

    // this.edellisetHyvaksyntaaVaativatObservable = combineLatest(this._asiakasService.nykyinenAsiakasAvainObservable, this._valittuAikavaliSubject).pipe(
    //   switchMap(([asiakas, aikavali]) => {
    //     if (asiakas) {
    //       const start = this._dateService.localMonthToNumber(aikavali.start)
    //       const ruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
    //       return this._firestore.collection<KirjanpitoKuukausiruutu>(ruutuCollectionUri, query => {
    //         return query.where('u', '>', 0).where('k', '<', start)
    //       }).valueChanges()
    //     }
    //     return of<KirjanpitoKuukausiruutu[]>([])
    //   }),
    //   map(ruudut => {
    //     let sum = 0
    //     for (const ruutu of ruudut) {
    //       sum += ruutu.u ?? 0
    //     }
    //     return sum
    //   })
    // )

    // this.seuraavatHyvaksyntaaVaativatObservable = combineLatest(this._asiakasService.nykyinenAsiakasAvainObservable, this._valittuAikavaliSubject).pipe(
    //   switchMap(([asiakas, aikavali]) => {
    //     if (asiakas) {
    //       const end = this._dateService.localMonthToNumber(aikavali.end)
    //       const ruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
    //       return this._firestore.collection<KirjanpitoKuukausiruutu>(ruutuCollectionUri, query => {
    //         return query.where('u', '>', 0).where('k', '>', end)
    //       }).valueChanges()
    //     }
    //     return of<KirjanpitoKuukausiruutu[]>([])
    //   }),
    //   map(ruudut => {
    //     let sum = 0
    //     for (const ruutu of ruudut) {
    //       sum += ruutu.u ?? 0
    //     }
    //     return sum
    //   })
    // )

    // this.onkoEdellisiaMuuttuneitaAlvIlmoituksiaObservable = combineLatest(this._asiakasService.nykyinenAsiakasAvainObservable, this._valittuAikavaliSubject).pipe(
    //   switchMap(([asiakas, aikavali]) => {
    //     if (asiakas) {
    //       const start = this._dateService.localMonthToNumber(aikavali.start)
    //       const ruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
    //       return this._firestore.collection<KirjanpitoKuukausiruutu>(ruutuCollectionUri, query => {
    //         return query.where('r', '==', true).where('k', '<', start).limit(1)
    //       }).valueChanges()
    //     }
    //     return of<KirjanpitoKuukausiruutu[]>([])
    //   }),
    //   map(ruudut => {
    //     return ruudut.length > 0
    //   })
    // )

    // this.onkoSeuraaviaMuuttuneitaAlvIlmoituksiaObservable = combineLatest(this._asiakasService.nykyinenAsiakasAvainObservable, this._valittuAikavaliSubject).pipe(
    //   switchMap(([asiakas, aikavali]) => {
    //     if (asiakas) {
    //       const end = this._dateService.localMonthToNumber(aikavali.end)
    //       const ruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
    //       return this._firestore.collection<KirjanpitoKuukausiruutu>(ruutuCollectionUri, query => {
    //         return query.where('r', '==', true).where('k', '>', end).limit(1)
    //       }).valueChanges()
    //     }
    //     return of<KirjanpitoKuukausiruutu[]>([])
    //   }),
    //   map(ruudut => {
    //     return ruudut.length > 0
    //   })
    // )

    this.valittuKuukausiObservable.pipe(
      // takeWhile(value => !value, true),
      takeUntil(this._ngUnsubscribe),
      filter(value => !!value)
    ).subscribe(kuukausi => {
      const valittuAikavali = this._valittuAikavaliSubject.value
      if (
        !valittuAikavali ||
        !this._dateService.onkoLocalMonthKahdenValissa(kuukausi, valittuAikavali.start, valittuAikavali.end)
      ) {
        this._valittuAikavaliSubject.next({
          start: { year: kuukausi.year, month: 1 },
          end: { year: kuukausi.year, month: 12 }
        })
      }
    })

    const raakadataRuutuObservable: Observable<KirjanpitoKuukausiruutu[]> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this._valittuAikavaliSubject
    ]).pipe(
      switchMap(([asiakas, aikavali]) => {
        if (asiakas) {
          const start = this._dateService.localMonthToNumber(aikavali.start)
          // Hae raakadataa yksi kuukausi enemmän, jotta display menee oikein.
          const end = this._dateService.lisaaKuukausiaKuukaudenNumero(this._dateService.localMonthToNumber(aikavali.end), 1)
          // console.log('Hae välillä', start, end)
          const ruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)

          return this._firebase.firestoreCollection<KirjanpitoKuukausiruutu>(ruutuCollectionUri).where('k', '>=', start).where('k', '<=', end).listen()
        }
        return of<KirjanpitoKuukausiruutu[]>([])
      })
    )

    this._nykyinenListausAsiakasObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const listausAsiakasUri = this._asiakasUriService.annaListausAsiakasUri(asiakas.avain)

          return this._firebase.firestoreDoc<ListausAsiakas>(listausAsiakasUri).listen()
        }
        return of<ListausAsiakas>(null)
      }),
      lemonShare()
    )

    this.kasitellytKuukausiruudutObservable = combineLatest([
      raakadataRuutuObservable,
      this._nykyinenListausAsiakasObservable,
      this.naytettavatKuukaudetObservable,
      this.valittuKuukausiObservable
    ]).pipe(
      map(([ruudut, asiakas, kuukaudet, valittuKuukausi]) => {
        if (!ruudut || !asiakas || !kuukaudet || !valittuKuukausi) {
          return []
        }

        const valittuKk = this._dateService.localMonthToNumber(valittuKuukausi)
        const lopulliset: KirjanpitoStripinKuukausiruudunTiedot[] = []
        for (const kuukausi of kuukaudet) {
          const kk = this._dateService.localMonthToNumber(kuukausi)
          const nextKk = this._dateService.lisaaKuukausiaKuukaudenNumero(kk, 1)
          const ruutu = ruudut.find(a => a.k === kk) ?? this._kirjanpitoKuukausistripService.annaUusiKirjanpitoRuutuListaus(asiakas, kk)
          const nextRuutu = ruudut.find(a => a.k === nextKk) ?? this._kirjanpitoKuukausistripService.annaUusiKirjanpitoRuutuListaus(asiakas, nextKk)
          const tiedot = ruutu as KirjanpitoStripinKuukausiruudunTiedot
          tiedot.luokat = this._kirjanpitoKuukausistripService.keraaRuudunLuokatUusin(asiakas, tiedot, nextRuutu, 'kirjanpito', 'selvitettavat')
          if (tiedot.sh && tiedot.c && !tiedot.u) {
            tiedot.luokat += ' a-l-r-va-heittaa'
            tiedot.heittaa = 1
            tiedot.vasenAlakulma = null
          } else {
            delete tiedot.heittaa
            this._kirjanpitoKuukausistripService.asetaVasenAlakulma(tiedot, 'selvitettavat')
          }
          if (valittuKk === kk) {
            this.ruutuValittiin.next(tiedot)
            tiedot.luokat += ' valittukk'
          }
          this._kirjanpitoKuukausistripService.asetaOikeaAlakulma(tiedot)
          lopulliset.push(tiedot)
        }
        return lopulliset
      })
    )

  }

  ngAfterContentInit() {
    // this.paivitaLeveys()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  eteenpain(event: MouseEvent) {
    const current = this._valittuAikavaliSubject.value
    if (current.end.year < 2099 || (current.end.year === 2099 && current.end.month < 12)) {
      this._valittuAikavaliSubject.next({
        start: this._dateService.lisaaKuukausiaLocalMonth(current.start, 1),
        end: this._dateService.lisaaKuukausiaLocalMonth(current.end, 1)
      })
    }
  }

  taaksepain(event: MouseEvent) {
    const current = this._valittuAikavaliSubject.value
    if (current.start.year > 2010 || (current.start.year === 2010 && current.start.month > 1)) {
      this._valittuAikavaliSubject.next({
        start: this._dateService.lisaaKuukausiaLocalMonth(current.start, -1),
        end: this._dateService.lisaaKuukausiaLocalMonth(current.end, -1)
      })
    }
  }

  valitseKuukausi(event: MouseEvent, kuukausi: KirjanpitoStripinKuukausiruudunTiedot) {
    event.stopImmediatePropagation()
    event.preventDefault()
    const localMonth = this._dateService.numberToLocalMonth(kuukausi.k)
    this.kuukausiValittiin.next(localMonth)
    this.ruutuValittiin.next(kuukausi)
  }

  async avaaKuukausistatus(event: MouseEvent, kuukausi: KirjanpitoStripinKuukausiruudunTiedot) {
    const asiakas = await firstValueFrom(this._nykyinenListausAsiakasObservable)
    const data: AsiakkaanKirjanpitostatusKuukaudelleDialogData = {
      asiakas: asiakas,
      kuukausi: this._copyService.cloneObjectDeep(kuukausi)
    }
    this._dialog.open(AsiakkaanKirjanpitostatusKuukaudelleDialog, { data: data, autoFocus: false })
  }

}
