import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'
import { TositteidenLataustiedotVastaus, TositteidenLataustiedotPyynto } from '../_jaettu/lemonator/model/tositteiden-lataaminen'
import { TositteidenKuukausisummatEdit } from './asiakkaan-tositteet.component'

import * as jszip from 'jszip'
import { firstValueFrom } from 'rxjs'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'


export interface AsiakkaanTositteidenLataaminenDialogData {
  valittuKuukausi: TositteidenKuukausisummatEdit
  asiakas: Asiakas
  vainLataamattomat: boolean
}
@Component({
  templateUrl: './asiakkaan-tositteiden-lataaminen.dialog.html'
})
export class AsiakkaanTositteidenLataaminenDialog implements OnInit {

  private kuukausi: TositteidenKuukausisummatEdit = null
  private asiakas: Asiakas = null

  tiedostojaYhteensa = 0
  tiedostojaLadattu = 0
  commonError = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AsiakkaanTositteidenLataaminenDialogData,
    private dialogRef: MatDialogRef<AsiakkaanTositteidenLataaminenDialog>,
    private errorHandler: ErrorHandler,
    private httpClient: HttpClient,
    private _firebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) {
    this.asiakas = this.data.asiakas
    this.kuukausi = this.data.valittuKuukausi
  }

  ngOnInit() {
    const paakansio = this.asiakas.nimi + ' (' + this.asiakas.ytunnus + ') Tositteet ' + this.kuukausi.vuosi + '.' + this.kuukausi.kk
    const lataustiedot: TositteidenLataustiedotPyynto = {
      asiakasAvain: this.asiakas.avain,
      asiakasId: Number(this.asiakas.asiakasId),
      kuukausi: this.kuukausi.kk,
      vuosi: this.kuukausi.vuosi,
      vainLataamattomat: this.data.vainLataamattomat
    }

    this._firebase.functionsCall<TositteidenLataustiedotPyynto, TositteidenLataustiedotVastaus>('tositteidenLataaminenAnnaLatausosoitteet', lataustiedot).then(vastaus => {
      this.tiedostojaYhteensa = vastaus.tiedot.length
      return this.downloadAll(vastaus, paakansio)
    }).then(content => {
      this._fileSaverService.saveAs(content, paakansio + '.zip')
      this.dialogRef.close()
    }).catch(error => {
      this.commonError = true
      this.errorHandler.handleError(error)
    })
  }
  private async downloadAll(vastaus: TositteidenLataustiedotVastaus, paakansio: string) {
    const zip = new jszip()
    let promises: Promise<void>[] = []

    for (const tiedosto of vastaus.tiedot) {

      if (tiedosto.puuttuu) {
        this.tiedostojaLadattu++
        const virhe = '\n\tPDF ON KATEISSA! \tTOIMITA TÄMÄ TIEDOSTO YLLÄPIDOLLE!\n\n' + JSON.stringify(tiedosto.puuttuvanTiedot, null, 2)
        zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, virhe, { date: new Date(tiedosto.muokattuMillis) })
      } else {
        const promise = firstValueFrom(this.httpClient.get(tiedosto.url, {
          responseType: 'arraybuffer'
        })).then(arraybuffer => {
          this.tiedostojaLadattu++
          zip.folder(paakansio + '/' + tiedosto.kansio).file(tiedosto.nimi, arraybuffer, { date: new Date(tiedosto.muokattuMillis) })
        })
        promises.push(promise)
      }
      if (promises.length > 500) {
        await Promise.all(promises)
        promises = []
      }
    }
    await Promise.all(promises)

    return zip.generateAsync({ type: 'blob' })
  }

}
