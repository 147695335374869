import { Component, ErrorHandler, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AsiakasKopioija } from 'app/_angular/service/asiakas/asiakas.kopioija'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { DateService } from 'app/_shared-core/service/date.service'
import { Subject, takeUntil } from 'rxjs'

import { Asiakas, AsiakkaanSopimuskausi, PakotaSopimuksenUudelleenAllekirjoitusPyynto, PakotaSopimuksenUudelleenAllekirjoitusVastaus } from '../../_jaettu-lemonator/model/asiakas'

export interface AsiakkaanSopimuskausiUusiDialogData {
  asiakas: Asiakas
}

interface SopimuskausiFormGroup {
  alkaa: FormControl<Date>
}

@Component({
  templateUrl: './sopimuskausi-uusi.dialog.html'
})
export class AsiakkaanSopimuskausiUusiDialog implements OnInit, OnDestroy {

  // @ViewChild(MatInput, { static: true }) sopimuskausiAlkaaInputti: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _kausi: AsiakkaanSopimuskausi = null

  sopimuskausiFormGroup: FormGroup<SopimuskausiFormGroup> = null
  inputname = 'mnbiggteagjopq' + Math.random()
  minimiAlkaaPaivamaara: Date = null

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanSopimuskausiUusiDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanSopimuskausiUusiDialog>,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator,
    private _validationService: FormValidationService,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _asiakasKopioija: AsiakasKopioija,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService
  ) {

    const viimeisinKausi = this._data.asiakas.sopimuskaudet?.length > 0 ? this._data.asiakas.sopimuskaudet.reduce((prev, curr) => {
      if (this._dateService.compareLocalDates(prev.alkaa, '<', curr.alkaa)) {
        return curr
      }
      return prev
    }) : null
    // const uusiAlkaa = viimeisinKausi?.loppuu ?? this._dateService.currentLocalDate()
    const uusiAlkaa = this._dateService.lisaaPaiviaPaikallinen(viimeisinKausi?.loppuu ?? this._dateService.currentLocalDate(), 1)
    const sopimuskausi: AsiakkaanSopimuskausi = {
      avain: this._firebase.firestoreCreateId(),
      alkaa: uusiAlkaa,
      loppuu: null,
      lahetaPaattymassaEmail: null,
      lahetaPaattynytEmail: null,
      tositteidenToimitusLoppuu: null,
      lisatiedot: null,
      lopettamisenSyy: null,
      tauot: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false
    }

    this._kausi = sopimuskausi

    console.log(this._data.asiakas?.sopimuskaudet, viimeisinKausi)
    this.minimiAlkaaPaivamaara = viimeisinKausi?.loppuu ? this._dateService.lisaaPaivia(this._dateService.localDateToDate(viimeisinKausi.loppuu), 1) : new Date(1980, 0, 1)

    this.sopimuskausiFormGroup = this._annaFormGroupSopimuskaudelle(this._kausi)
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.sopimuskausiAlkaaInputti.focus()
    // }, 50)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private _annaFormGroupSopimuskaudelle(sopimuskausi: AsiakkaanSopimuskausi): FormGroup<SopimuskausiFormGroup> {

    const alkaaFormControl = new FormControl<Date>({ value: this._dateService.localDateToDate(sopimuskausi.alkaa), disabled: false }, [Validators.required])
    const sopimuskausiGroup = new FormGroup<SopimuskausiFormGroup>({
      'alkaa': alkaaFormControl
    })

    alkaaFormControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      sopimuskausi.alkaa = this._dateService.dateToLocalDate(value)
    })

    return sopimuskausiGroup
  }

  async tallenna() {
    if (!this.sopimuskausiFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.sopimuskausiFormGroup)
      return
    }
    this._ladataanService.aloitaLataaminen()
    const uudelleenAllekirjoitusPromise = this._pakotaSopimuksenAllekirjoittaminenUudelleen(this._data.asiakas)
    const asiakasCopy = this._asiakasKopioija.kopioiAsiakas(this._data.asiakas)
    if (!asiakasCopy.sopimuskaudet) { asiakasCopy.sopimuskaudet = [] }
    asiakasCopy.sopimuskaudet.push(this._kausi)

    const kirjanpitajanTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    this._kausi.luoja = kirjanpitajanTiedot.uid
    this._kausi.paivittaja = kirjanpitajanTiedot.uid
    this._kausi.luotu = this._timestampService.now()
    this._kausi.paivitetty = this._kausi.luotu

    const batch = this._firebase.firestoreBatch()
    await this._asiakasService.lisaaAsiakasBatchiin(asiakasCopy, kirjanpitajanTiedot, 'tilikaudet-eivat-voineet-muuttua', false, false, batch)
    await uudelleenAllekirjoitusPromise
    batch.commit().then(() => {
      this._asiakasService.asetaNykyinenAsiakas(asiakasCopy)
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  private _pakotaSopimuksenAllekirjoittaminenUudelleen(asiakas: Asiakas): Promise<any> {
    const pyynto: PakotaSopimuksenUudelleenAllekirjoitusPyynto = {
      asiakasAvain: asiakas.avain,
      naytaKayttajilleTervetuloaDialogi: true,
      vaadiKayttajiltaUusiTunnistautuminen: true,
      vaadiPepTietojenPaivittaminen: true,
      vaadiSopimuksenAllekirjoittaminenUudelleen: true
    }
    return this._firebase.functionsCall<PakotaSopimuksenUudelleenAllekirjoitusPyynto, PakotaSopimuksenUudelleenAllekirjoitusVastaus>('sopimuksenUudelleenAllekirjoittaminenPakotetusti', pyynto).then(() => {
    }).catch(error => {
      this._errorHandler.handleError(error)
    })
  }

}
