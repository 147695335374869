<div class="list-page kapea" style="max-width: 1400px; padding: 0 3vw 5vh 3vw;">

  <!-- <app-asiakas-koti-otsikko></app-asiakas-koti-otsikko> -->

  <div style="margin-top: 30px; display: flex; justify-content: right;">
    <form [formGroup]="tilastotForm" novalidate class="ylaosan-kontrollit" style="width: 800px; margin-right: 50px;">
      <mat-form-field *ngIf="naytaSuperObservable | async" style="width: 155px; margin-right: 20px;">
        <mat-label>Rajaa tiimin mukaan</mat-label>
        <mat-select formControlName="tiimi">
          <mat-option [value]="null">
            Kaikki
          </mat-option>
          <mat-option *ngFor="let tiimi of tiimitObservable | async" [value]="tiimi.avain">
            {{ tiimi.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="naytaSuperObservable | async" style="width: 200px; margin-right: 20px;">
        <mat-label>Rajaa kirjanpitäjän mukaan</mat-label>
        <mat-select formControlName="kirjanpitaja">
          <mat-option [value]="null">
            Kaikki
          </mat-option>
          <mat-option *ngFor="let kirjanpitaja of kirjanpitajienNimitiedotObservable | async" [value]="kirjanpitaja.avain">
            {{ kirjanpitaja.etunimi }} {{ kirjanpitaja.sukunimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 90px; margin-right: 20px;">
        <mat-label>Kuukaudesta</mat-label>
        <input matInput [matDatepicker]="dp1" formControlName="vuosikkalku">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1 startView="year" (monthSelected)="chosenMonthHandlerAlku($event, dp1)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="width: 90px;">
        <mat-label>Kuukauteen</mat-label>
        <input matInput [matDatepicker]="dp2" formControlName="vuosikkloppu">
        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
        <mat-datepicker #dp2 startView="year" (monthSelected)="chosenMonthHandlerLoppu($event, dp2)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>
    </form>
  </div>

  <div style="display: flex; flex-flow: row wrap;">

    <div style="max-width: 800px; flex-grow: 1;">

      <div style="margin: 20px 0;">
        <div style="font-size: 24px; margin-right: 10px; margin-bottom: 5px;">Kuukausilaskutus</div>
      </div>

      <canvas [class.hidden]="kuukausittainCanvasDataOnLataamatta" #kuukausittainCanvas></canvas>
      <table [class.hidden]="kuukausittainCanvasDataOnLataamatta" class="ftable">
        <tr *ngFor="let rivi of kuukausittaisenDatanRivitObservable | async" [class.bold-row]="rivi.bold" [class.mini-row]="!rivi.bold">
          <td *ngFor="let td of rivi.cols">{{td}}</td>
        </tr>
      </table>
      <div *ngIf="kuukausittainCanvasDataOnLataamatta" style="display: flex; justify-content: center;">
        <mat-spinner></mat-spinner>
      </div>

      <div style="display: flex; flex-flow: row wrap; align-items: center; margin: 60px 0 20px 0;">
        <div style="font-size: 24px; margin-right: 10px;">Jatkuvan laskutuksen hinnaston muutos kuukausittain</div>
      </div>

      <canvas [class.hidden]="kuukausittainenMuutosCanvasOnLataamatta" #kuukausittainenMuutosCanvas></canvas>
      <div *ngIf="kuukausittainenMuutosCanvasOnLataamatta" style="display: flex; justify-content: center;">
        <mat-spinner></mat-spinner>
      </div>

    </div>

    <div style="display: flex; flex-flow: column; align-items: center; flex-grow: 1;">
      <div style="margin: 23px;">
        <div class="light-blue-text" style="font-size: 18px; margin-bottom: 5px;">Asiakkaita</div>
        <div class="pallo blue">
          {{(asiakasInfoObservable | async)?.asiakkaitaJoillaHinta}}
        </div>
      </div>
      <div style="margin: 23px;">
        <div class="light-blue-text" style="font-size: 18px; margin-bottom: 5px;">Keskim. veloitus</div>
        <div class="pallo red">
          {{(asiakasInfoObservable | async)?.keskimaarainenhinta | ldecimal: 2 | async}}
        </div>
      </div>
      <div style="margin: 23px;">
        <div class="light-blue-text" style="font-size: 18px; margin-bottom: 5px;">Yhtiömuodot</div>
        <div class="pallo">
          <canvas #yhtiojakaumaCanvas></canvas>
        </div>
      </div>
    </div>

  </div>



  <ng-container *ngIf="naytaSuperObservable | async">
    <div style="margin: 2em 0; border: 1px dashed lightgray; padding: 1em;">
      <span>Nämä tiedot näkyvät vain pääkäyttäjälle</span>

      <div style="max-width: 1000px;">
        <div style="display: flex; flex-flow: row wrap; align-items: center; margin: 60px 0 20px 0;">
          <div style="font-size: 24px; margin-right: 10px;">Kuukausilaskutus kirjanpitäjittäin</div>
        </div>
        <canvas [class.hidden]="kuukausittainKirjanpitajittainCanvasOnLataamatta" #kuukausittainKirjanpitajittainCanvas></canvas>
        <div *ngIf="kuukausittainKirjanpitajittainCanvasOnLataamatta" style="display: flex; justify-content: center;">
          <mat-spinner></mat-spinner>
        </div>
      </div>

      <div style="display: flex; flex-flow: row wrap; align-items: center; margin: 60px 0 20px 0;">
        <div style="font-size: 24px; margin-right: 10px;">Hinnan muutokset asiakkaittain</div>
      </div>
      <table class="asiakkaat-table" style="border-spacing: 0;">
        <thead>
          <tr>
            <th>Asiakas</th>
            <th>Vastuukirjanpitäjä</th>
            <th *ngFor="let kuukausi of purettuAikavaliPlusKuukausiMolempiinPaihinObservable | async">{{kuukausi.year}}/{{kuukausi.month}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let asiakkaanHinnanMuutokset of asiakkaidenKuukausihintojenMuutoksetObservable | async">
            <td style="white-space: nowrap;">{{asiakkaanHinnanMuutokset.asiakas?.n}}</td>
            <td style="white-space: nowrap;">{{asiakkaanHinnanMuutokset.kirjanpitaja?.etunimi}} {{asiakkaanHinnanMuutokset.kirjanpitaja?.sukunimi}}</td>
            <td *ngFor="let kkhinta of asiakkaanHinnanMuutokset.hinnat" style="text-align: right; padding-left: 10px; white-space: nowrap;">
              <div [class.green-text]="kkhinta.muutos > 0" [class.red-text]="0 > kkhinta.muutos" [title]="kkhinta.syy">&nbsp;{{kkhinta.muutos | lmoney: 'EUR' | async}}</div>
              <div style="font-size: 8px; line-height: 6px;" class="light-gray-text">{{kkhinta.hinta | lmoney: 'EUR' | async}}</div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-weight: bold;">
            <td colspan="2" style="white-space: nowrap;">Poistuma</td>
            <td *ngFor="let kksumma of asiakkaidenKuukausihintojenSummatObservable | async" style="text-align: right; padding-left: 10px; white-space: nowrap;" [class.green-text]="kksumma.poistuma > 0" [class.red-text]="0 > kksumma.poistuma">{{kksumma.poistuma | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr style="font-weight: bold;">
            <td colspan="2" style="white-space: nowrap;">Lisäys</td>
            <td *ngFor="let kksumma of asiakkaidenKuukausihintojenSummatObservable | async" style="text-align: right; padding-left: 10px; white-space: nowrap;" [class.green-text]="kksumma.lisays > 0" [class.red-text]="0 > kksumma.lisays">{{kksumma.lisays | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr style="font-weight: bold;">
            <td colspan="2" style="white-space: nowrap;">Muutos</td>
            <td *ngFor="let kksumma of asiakkaidenKuukausihintojenSummatObservable | async" style="text-align: right; padding-left: 10px; white-space: nowrap;" [class.green-text]="kksumma.summa > 0" [class.red-text]="0 > kksumma.summa">{{kksumma.summa | lmoney: 'EUR' | async}}</td>
          </tr>
        </tfoot>
      </table>

      <!-- <div style="display: flex; flex-flow: row wrap; align-items: center; margin: 60px 0 20px 0;">
      <div style="font-size: 24px; margin-right: 10px;">Duplikaattilaskut viimeisen 3kk ajalta</div>
    </div>
    <div *ngIf="duplikaatitLataavatObservable | async" style="display: flex; justify-content: center;">
      <mat-spinner></mat-spinner>
    </div>
    <table *ngIf="!(duplikaatitLataavatObservable | async)" class="asiakkaat-table" style="border-spacing: 0;">
      <thead>
        <tr>
          <th style="padding-right: 5px;">Asiakas</th>
          <th style="padding-right: 5px;">Pvm</th>
          <th>Laskunro 1</th>
          <th style="padding-left: 5px;">Laskunro 2</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let duplikaatti of duplikaattiLaskutObservable | async">
          <td style="white-space: nowrap; padding-right: 5px;">{{duplikaatti.asiakas?.nimi}}</td>
          <td style="white-space: nowrap; padding-right: 5px;">{{duplikaatti.pvm | lokaalipvm | async}}</td>
          <td style="white-space: nowrap; text-align: right;">{{ duplikaatti.laskunro1 }}</td>
          <td style="white-space: nowrap; text-align: right; padding-left: 5px;">{{ duplikaatti.laskunro2 }}</td>
        </tr>
      </tbody>
    </table> -->

      <div>
        <div style="display: flex; flex-flow: row wrap; align-items: center; margin: 60px 0 20px 0;">
          <div style="font-size: 24px; margin-right: 10px;">Kirjanpitäjät</div>
        </div>

        <form [formGroup]="kirjanpitajatForm" novalidate style="margin: auto 0 auto auto;">
          <mat-form-field style="width: 90px; margin-right: 20px;">
            <mat-label>Kuukausi</mat-label>
            <input matInput [matDatepicker]="dp3" formControlName="vuosikkalku">
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 startView="year" (monthSelected)="chosenMonthHandlerKirjanpitajatAlku($event, dp3)" panelClass="kuukausi-valitsin"></mat-datepicker>
          </mat-form-field>
        </form>

        <div *ngIf="kirjanpitajittainTableLataa" style="display: flex; justify-content: center;">
          <mat-spinner></mat-spinner>
        </div>

        <!-- class="mat-elevation-z8" -->
        <mat-table [class.hidden]="kirjanpitajittainTableLataa" [dataSource]="kirjanpitajatDataSource" matSort matSortActive="kirjanpitaja" matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="kirjanpitaja">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Kirjanpitäjä</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element?.kirjanpitaja}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>Yhteensä</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="asiakkaita">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Asiakkaita</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.asiakkaita}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.asiakkaita}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="keskimveloitus">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Keskim. laskutus</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.keskimaarainenVeloitus | lmoney: 'EUR' | async}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.keskim | lmoney: 'EUR' | async}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="jatkuvakk1">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Jatkuva laskutus {{valittuKirjanpitajienKk1Observable | async}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.jatkuvakk1 | lmoney: 'EUR' | async}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.jatkuvakk1 | lmoney: 'EUR' | async}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="jatkuvakk2">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Jatkuva laskutus {{valittuKirjanpitajienKk2Observable | async}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.jatkuvakk2 | lmoney: 'EUR' | async}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.jatkuvakk2 | lmoney: 'EUR' | async}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="lisaykset">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Lisäykset</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.lisaykset | lmoney: 'EUR' | async}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.lisayksia | lmoney: 'EUR' | async}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="vahennykset">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Vähennykset</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.vahennykset | lmoney: 'EUR' | async}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.poistuma | lmoney: 'EUR' | async}}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="yhtiomuodot">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Yhtiömuodot {{valittuKirjanpitajienKk1Observable | async}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.yhtiomuotoString}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>{{kirjanpitajittainTableYhteensa.tmi}}/{{kirjanpitajittainTableYhteensa.oy}}/{{kirjanpitajittainTableYhteensa.muut}}</mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['kirjanpitaja', 'jatkuvakk1', 'lisaykset', 'vahennykset', 'jatkuvakk2', 'asiakkaita', 'keskimveloitus', 'yhtiomuodot']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['kirjanpitaja', 'jatkuvakk1', 'lisaykset', 'vahennykset', 'jatkuvakk2', 'asiakkaita', 'keskimveloitus', 'yhtiomuodot']"></mat-row>
          <mat-footer-row *matFooterRowDef="['kirjanpitaja', 'jatkuvakk1', 'lisaykset', 'vahennykset', 'jatkuvakk2', 'asiakkaita', 'keskimveloitus', 'yhtiomuodot']"></mat-footer-row>
        </mat-table>
      </div>

    </div>
    <ng-container *ngIf="naytaSpeccerObservable | async">
      <h3 style="margin-top: 8em;">
        Tästä alaspäin debug-dataa
      </h3>

      <h3 style="margin-top: 2em;">
        Toteutuneen kuukausiveloituksen data
        <mat-checkbox style="font-size: 10px;" class="light-gray-text" [formControl]="naytaTarkkaJakaumaFormControl" [labelPosition]="'after'">Näytä tarkka jakauma</mat-checkbox>
      </h3>
      <table style="text-align: right;">
        <tr>
          <th style="text-align: center;">Vuosi / kk</th>
          <th style="text-align: center;">Jatkuvalaskutus</th>
          <th style="text-align: center;">Kertalaskutus</th>
          <th style="text-align: center;">Tuntematon laskutus</th>
          <th style="text-align: center;">Yhteensä</th>
        </tr>
        <ng-container *ngFor="let kk of kuukausittainenJakaumaObservable | async">
          <tr>
            <td style="text-align: left;">{{kk.vuosi}} / {{kk.kuukausi}}</td>
            <td>{{kk.jatkuvalaskutus | lmoney: 'EUR' | async}}</td>
            <td>{{kk.kertalaskutus | lmoney: 'EUR' | async}}</td>
            <td>{{kk.tuntematon | lmoney: 'EUR' | async}}</td>
            <td>{{kk.yhteensa | lmoney: 'EUR' | async}}</td>
          </tr>
          <ng-container *ngIf="naytaTarkkaJakaumaFormControl.value">
            <tr *ngFor="let lahde of kk.lahteet" style="font-size: 8px; line-height: 8px;" class="light-gray-text">
              <td colspan="2" style="text-align: left;"></td>
              <td>{{lahde.juurilaskuAvain}}</td>
              <td>{{lahde.laskunro}} ({{lahde.lahetystyyppi === 'j' ? 'Jatkuva' : 'Kerta'}})</td>
              <td>{{lahde.summa | lmoney: 'EUR' | async}}</td>
            </tr>
          </ng-container>
        </ng-container>
      </table>

      <h3 style="margin-top: 2em;">Keskimääräiset hinnat</h3>
      Asiakkaita: {{(asiakasInfoObservable | async)?.asiakkaita}} <br />
      Asiakkaita, joilla on hinta nyt: {{(asiakasInfoObservable | async)?.asiakkaitaJoillaHinta}} <br />
      Keskimääräinen hinta: {{(asiakasInfoObservable | async)?.keskimaarainenhinta | lmoney: 'EUR' | async}} <br />

      Yhtiömuotojakauma:
      <table>
        <tr>
          <th>Nimi</th>
          <th>Määrä</th>
          <th>Keskimääräinen hinta</th>
        </tr>
        <tr *ngFor="let yhtiomuoto of (asiakasInfoObservable | async)?.yhtiomuotojakauma">
          <td>{{yhtiomuoto.nimi}}</td>
          <td style="text-align: right">{{yhtiomuoto.maara}}</td>
          <td style="text-align: right">{{yhtiomuoto.keskimaarainenHinta | lmoney: 'EUR' | async}}</td>
        </tr>
      </table>
    </ng-container>
    <h3 style="margin-top: 2em;">
      Laskutus kirjanpitäjittäin
      <mat-checkbox style="font-size: 10px;" class="light-gray-text" [formControl]="naytaTarkkaJakaumaKirjanpitajatFormControl" [labelPosition]="'after'">Näytä tarkka jakauma</mat-checkbox>
    </h3>
    <table style="text-align: right;">
      <tr>
        <th style="text-align: center;">Kirjanpitaja</th>
        <th style="text-align: center;">Vuosi / kk</th>
        <th style="text-align: center;">Jatkuvalaskutus</th>
        <th style="text-align: center;">Kertalaskutus</th>
        <th style="text-align: center;">Tuntematon laskutus</th>
        <th style="text-align: center;">Yhteensä</th>
      </tr>
      <ng-container *ngFor="let kk of kirjanpitajittainJakaumaObservable | async">
        <tr>
          <td style="text-align: left;">{{kk.kirjanpitaja}}</td>
          <td style="text-align: left;">{{kk.vuosi}} / {{kk.kuukausi}}</td>
          <td>{{kk.jatkuvalaskutus | lmoney: 'EUR' | async}}</td>
          <td>{{kk.kertalaskutus | lmoney: 'EUR' | async}}</td>
          <td>{{kk.tuntematon | lmoney: 'EUR' | async}}</td>
          <td>{{kk.yhteensa | lmoney: 'EUR' | async}}</td>
        </tr>
        <ng-container *ngIf="naytaTarkkaJakaumaKirjanpitajatFormControl.value">
          <tr *ngFor="let lahde of kk.lahteet" style="font-size: 8px; line-height: 8px;" class="light-gray-text">
            <td colspan="5">{{lahde.laskunro}} ({{lahde.lahetystyyppi === 'j' ? 'Jatkuva' : 'Kerta'}})</td>
            <td>{{lahde.summa | lmoney: 'EUR' | async}}</td>
          </tr>
        </ng-container>
      </ng-container>
    </table>

  </ng-container>

</div>