<div class="block-title">
  <div>{{label}}<span *ngIf="help" [matTooltip]="help" class="ion-ios-help-circle-outline" style="margin-left: 15px; color: darkgray;"></span></div>
  <div *ngIf="optional" class="block-optionality-control">
    <span *ngIf="saving" class="saving" [@slowInQuickOut]="'in'">
      Tallennetaan... <br />
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </span>
    <span *ngIf="saveSuccess" class="save-success" [@quickInSlowOut]="'in'">
      Valinta tallennettiin <mat-icon [ngStyle]="{'color':'#4bd76b','vertical-align':'middle','margin-bottom':'6px'}">done</mat-icon>
    </span>
    <span *ngIf="saveFailure" class="save-failure" [@quickInSlowOut]="'in'">
      VIRHE! <mat-icon [ngStyle]="{'color':'rgb(255, 72, 102)','vertical-align':'middle','margin-bottom':'6px'}">error_outline</mat-icon><br />
      Tallenna uudelleen
    </span>
    <mat-radio-group (change)="tallennaMuuttunutArvo()" [formControl]="valueFormControl" [disabled]="lukittu" aria-label="Vie veroilmoitukselle">
      <mat-radio-button [value]="'1'">Kyllä</mat-radio-button>
      <mat-radio-button [value]="'0'">Ei</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<ng-content select="[explanation]"></ng-content>
<div class="block-content" *ngIf="!optional || valueFormControl.value === '1'">
  <ng-content></ng-content>
</div>