import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { ViitenumeroService } from '../../_shared-core/service/viitenumero.service'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { map, switchMap } from 'rxjs/operators'
import { Observable, of as observableOf } from 'rxjs'
import { KirjaukseenLiitettyjenTiedostojenSivut } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { DebugService } from 'app/_angular/service/debug.service'

export interface KirjausDiagnoseHelpDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
}

@Component({
  templateUrl: './kirjaus.diagnose-help.dialog.html'
})
export class KirjausDiagnoseHelpDialog implements OnInit {

  liitetytTiedostotObservable: Observable<KirjaukseenLiitettyjenTiedostojenSivut>
  kirjausUriObservable: Observable<string>
  kirjausEncodedUriObservable: Observable<string>
  kirjaukseenLiitettyjenTiedostojenUriObservable: Observable<string>
  kirjaukseenLiitettyjenTiedostojenEncodedUriObservable: Observable<string>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausDiagnoseHelpDialogData,
    private dialogRef: MatDialogRef<KirjausDiagnoseHelpDialog>,
    private errorHandler: ErrorHandler,
    private kopioija: KopioijaPalvelu,
    private _firebase: FirebaseLemonator,
    private asiakasService: AsiakasService,
    private ladataanService: LadataanService,
    private viitenumeroService: ViitenumeroService,
    private validationService: FormValidationService,
    private kirjanpitoUriService: KirjanpitoUriService,
    private debugService: DebugService
  ) {

    this.kirjaukseenLiitettyjenTiedostojenUriObservable = asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot != null) {
          return this.kirjanpitoUriService.annaKirjaukseenLiitettyjenTiedostojenUri(avainTiedot.avain, data.naytettavaKirjaus.kirjaus.avain)
        }
        return ''
      })
    )

    this.kirjaukseenLiitettyjenTiedostojenEncodedUriObservable = this.kirjaukseenLiitettyjenTiedostojenUriObservable.pipe(
      map(uri => {
        return this.debugService.createFirestoreLink(uri)
      })
    )

    this.kirjausUriObservable = asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot != null) {
          return this.kirjanpitoUriService.annaKirjauksenUri(avainTiedot.avain, data.naytettavaKirjaus.kirjaus.avain)
        }
        return ''
      })
    )

    this.kirjausEncodedUriObservable = this.kirjausUriObservable.pipe(
      map(uri => {
        return this.debugService.createFirestoreLink(uri)
      })
    )

    this.liitetytTiedostotObservable = asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(avainTiedot => {
        if (avainTiedot) {
          const kirjaukseenLiitettyjenTiedostojenUri = this.kirjanpitoUriService.annaKirjaukseenLiitettyjenTiedostojenUri(avainTiedot.avain, data.naytettavaKirjaus.kirjaus.avain)
          return this._firebase.firestoreDoc<KirjaukseenLiitettyjenTiedostojenSivut>(kirjaukseenLiitettyjenTiedostojenUri).listen()
        }
        return observableOf(null)
      })
    )
  }

  ngOnInit() {

  }

}
