import { Injectable, ErrorHandler } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { DataSource } from '@angular/cdk/table'

import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs'
import { map, take, tap, switchMap } from 'rxjs/operators'

import { TositeKuvaService } from '../../_angular/service/tosite/tosite-kuva.service'
import { LEMONATOR_HTTPS_IMAGES_API } from '../../_angular/service/lemon-http.service'

import { FirestoreTosite, FirestoreTositteenKuva } from '../../_jaettu/model/tosite'
import { TositeUriService } from '../../_jaettu/service/tosite/tosite-uri.service'
import { KuitinSorttaukset } from '../../_jaettu/service/tosite/tosite.indeksoija'

import { AsiakasService, AsiakkaanAvainTiedot } from '../../_angular/service/asiakas/asiakas.service'
import { AsiakkaanMaksutapa } from '../../_jaettu-lemonator/model/asiakas'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { DocumentSnapshot } from 'firebase/firestore'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'


export interface ListausFirestoreKuitti extends FirestoreTosite {
  ensimmainenKuva: SafeUrl
  ladataan: boolean
  vihreaPilvi: boolean
}

export interface VuosiKk {
  vuosi: number
  kk: number
  kohde: 'p' | 'e'
}

export interface Hakukriteerit {
  vapaahaku: string
  naytaPoistetut: boolean
  maksutapa: string
  vuosikk: VuosiKk
}

@Injectable()
export class TositeSelailuListausFirebaseDataSource extends DataSource<ListausFirestoreKuitti> {

  private valittuMaksutapaSubject = new BehaviorSubject<AsiakkaanMaksutapa | null>(null)
  public valittuMaksutapaObservable: Observable<AsiakkaanMaksutapa | null> = this.valittuMaksutapaSubject.asObservable()

  private lataaSubject = new BehaviorSubject<boolean>(true)
  public lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private oletuskriteerit: Hakukriteerit = {
    vuosikk: {
      kk: new Date().getMonth(),
      vuosi: new Date().getFullYear(),
      kohde: 'p'
    },
    vapaahaku: null,
    naytaPoistetut: false,
    maksutapa: null
  }
  private hakukriteeritSubject = new BehaviorSubject<Hakukriteerit>(this.oletuskriteerit)
  public hakukriteeritObservable: Observable<Hakukriteerit> = this.hakukriteeritSubject.asObservable()

  private internalKuititObservable: Observable<ListausFirestoreKuitti[]>
  public kuititObservable: Observable<ListausFirestoreKuitti[]>
  public rivienMaaraObservable: Observable<number>

  private _internalSelvitettavatObservable: Observable<ListausFirestoreKuitti[]>
  selvitettavatObservable: Observable<ListausFirestoreKuitti[]>
  private _selvitettavatLataaSubject = new BehaviorSubject<boolean>(true)
  selvitettavatLataaObservable: Observable<boolean> = this._selvitettavatLataaSubject.asObservable()

  private pageEventSubject = new BehaviorSubject<PageEvent>({
    length: Number.MAX_SAFE_INTEGER,
    pageIndex: 0,
    pageSize: Number.MAX_SAFE_INTEGER
  })
  private sortSubject = new BehaviorSubject<Sort>({
    active: 'pvm',
    direction: 'desc'
  })

  private edellisetHakukriteerit: Hakukriteerit = this.kopioiHakukriteerienKamat(this.oletuskriteerit)
  private lastVisible: DocumentSnapshot[] = []
  private lastPageEvent: PageEvent = null
  private lastSort: Sort = null

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _tositeUriService: TositeUriService,
    private _kuittiKuvaService: TositeKuvaService,
    private _asiakasService: AsiakasService,
    private _sanitizer: DomSanitizer,
  ) {
    super()

    this._asiakasService.nykyisenAsiakkaanMaksutavatObservable.pipe(take(1)).subscribe(maksutavat => {
      if (!this.annaValittuMaksutapa()) {
        if (maksutavat && maksutavat.length > 0) {
          this.valittuMaksutapaSubject.next(maksutavat[0])
        } else {
          this.valittuMaksutapaSubject.next(null)
        }
      }
    })

    this._internalSelvitettavatObservable = combineLatest([this.hakukriteeritObservable, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      tap(() => this._selvitettavatLataaSubject.next(true)),
      switchMap(([hakukriteerit, asiakas]) => {
        if (!asiakas) {
          return of<ListausFirestoreKuitti[]>([])
        }
        const kuittienUri = this._tositeUriService.annaKuittienFirestoreCollectionUri(asiakas.asiakasId + '')
        let query = this._firebaseLemonaid.firestoreCollection<ListausFirestoreKuitti>(kuittienUri)
          .where('x', '==', true)

        if (!hakukriteerit.naytaPoistetut) {
          query = query.where('poistettu', '==', false)
        }

        return query.listen()
          .pipe(
            map(kuitit => {
              const sorted = kuitit.sort((a, b) => {
                if (a.localPvm.year !== b.localPvm.year) {
                  return b.localPvm.year - a.localPvm.year
                }
                if (a.localPvm.month !== b.localPvm.month) {
                  return b.localPvm.month - a.localPvm.month
                }
                if (a.localPvm.day !== b.localPvm.day) {
                  return b.localPvm.day - a.localPvm.day
                }
                return b.summa - a.summa
              })
              return sorted
            }),
            tap(kuitit => {
              if (kuitit) {
                for (const kuitti of kuitit) {

                  // Onko kuitti jo valmis?
                  kuitti.vihreaPilvi = true
                  if (kuitti.alkuperaiset) {
                    for (const alkuperaisenAvain of Object.keys(kuitti.alkuperaiset)) {
                      const alkuperainen = kuitti.alkuperaiset[alkuperaisenAvain]
                      if (!alkuperainen.kasitelty) {
                        kuitti.vihreaPilvi = false
                        break
                      }
                    }
                  }

                  let kuva: FirestoreTositteenKuva = null
                  for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
                    const mahdollinen = kuitti.kuvat[kuvanAvain]
                    if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
                      kuva = mahdollinen
                      if (kuva.jarjestys === 1) {
                        break
                      }
                    }
                  }

                  if (!kuva) {
                    kuitti.ladataan = false
                    kuitti.ensimmainenKuva = '/assets/noimage-blank.png'
                    continue
                  }

                  const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', kuitti.kuvakansio, kuva.avain, kuva.type)
                  kuitti.ensimmainenKuva = '/api/1/kuitit/kuvat/thumb/' + kuvanUrl

                }
              }
              this._selvitettavatLataaSubject.next(false)
            })
          )
      }),
      lemonShare()
    )

    this.selvitettavatObservable = combineLatest([this._internalSelvitettavatObservable, this.selvitettavatLataaObservable]).pipe(
      map(([kuitit, lataa]) => {
        if (lataa) {
          return []
        }
        return kuitit
      })
    )

    this.internalKuititObservable = combineLatest([this.hakukriteeritObservable, this.pageEventSubject, this.sortSubject, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      tap(() => {
        this.lataaSubject.next(true)
      }),
      switchMap(([hakukriteerit, pageEvent, sort, asiakas]) => {

        if (!asiakas) {
          return of<ListausFirestoreKuitti[]>([])
        }

        if (
          this.edellisetHakukriteerit.vapaahaku !== hakukriteerit.vapaahaku ||
          this.edellisetHakukriteerit.vuosikk.kk !== hakukriteerit.vuosikk.kk ||
          this.edellisetHakukriteerit.vuosikk.kohde !== hakukriteerit.vuosikk.kohde ||
          this.edellisetHakukriteerit.vuosikk.vuosi !== hakukriteerit.vuosikk.vuosi ||
          this.edellisetHakukriteerit.naytaPoistetut !== hakukriteerit.naytaPoistetut
        ) {
          this.pageEventSubject.value.pageIndex = 0
        }

        this.edellisetHakukriteerit = this.kopioiHakukriteerienKamat(hakukriteerit)

        if (this.lastSort !== sort) {
          this.lastVisible = []
        }
        this.lastSort = sort
        this.lastPageEvent = pageEvent

        const limitSize = pageEvent.pageSize + 1
        const sorttaus = KuitinSorttaukset.KAIKKI[sort.active]
        const poistettuS = hakukriteerit.naytaPoistetut ? '' : 'r1'
        const maksutapaS = hakukriteerit.maksutapa ? 'm' + hakukriteerit.maksutapa : ''
        const vuosikkS = hakukriteerit.vapaahaku ? '' : this.getVuosiKkInternal(hakukriteerit.vuosikk)
        const vapaahakuS = hakukriteerit.vapaahaku ? hakukriteerit.vapaahaku : ''
        const hakuavain = sorttaus.tunniste + poistettuS + maksutapaS + vuosikkS + vapaahakuS
        const searchProperty = 'haku.' + hakuavain
        const polku = this._tositeUriService.annaKuittienFirestoreCollectionUri(asiakas.asiakasId + '')

        let q = this._firebaseLemonaid.firestoreCollection<ListausFirestoreKuitti>(polku)
        if (sorttaus.tyyppi === 'string') {
          q = q.whereFree(searchProperty, '>', '')
        } else if (sorttaus.tyyppi === 'number') {
          q = q.whereFree(searchProperty, '>', -99999999999)
        } else if (sorttaus.tyyppi === 'date') {
          q = q.whereFree(searchProperty, '>', -9999)
        }

        const direction = sort.direction === 'asc' ? 'asc' : 'desc'
        q.orderByFree(searchProperty, direction)

        return q.listen().pipe(
          map(snapshots => {
            if (snapshots.length === limitSize) {
              snapshots.pop()
            }
            if (snapshots.length > 0) {
              this.lastVisible[this.lastPageEvent.pageIndex] = snapshots[snapshots.length - 1]['haku'][hakuavain]
            }
            for (const snapshot of snapshots) {
              delete snapshot['haku']
            }
            return snapshots.sort((a, b) => {
              if (a.localPvm.year !== b.localPvm.year) {
                return b.localPvm.year - a.localPvm.year
              }
              if (a.localPvm.month !== b.localPvm.month) {
                return b.localPvm.month - a.localPvm.month
              }
              if (a.localPvm.day !== b.localPvm.day) {
                return b.localPvm.day - a.localPvm.day
              }
              return b.summa - a.summa
            })
          }),
          map(rawKuitit => {

            // Filter out imageless selvitettävät (created by Lemonator kirjanpito when no tositteet are attached)
            const kuitit = vapaahakuS ? rawKuitit : rawKuitit.filter(k => !k.x)

            for (const kuitti of kuitit) {

              // Hoida kuva käyttöliittymään
              const lopullinenKuva = this._kuittiKuvaService.annaKuvaLopullisestaKakusta(kuitti)
              if (lopullinenKuva) {
                kuitti.ladataan = false
                kuitti.ensimmainenKuva = lopullinenKuva
              } else {
                kuitti.ladataan = true
                // kuitti.ensimmainenKuva = '/assets/noimage.png'
                this.paivitaKuva(kuitti, asiakas)
              }

              // Onko kuitti jo valmis?
              kuitti.vihreaPilvi = true
              if (kuitti.alkuperaiset) {
                for (const alkuperaisenAvain of Object.keys(kuitti.alkuperaiset)) {
                  const alkuperainen = kuitti.alkuperaiset[alkuperaisenAvain]
                  if (!alkuperainen.kasitelty) {
                    kuitti.vihreaPilvi = false
                    break
                  }
                }
              }

            }

            return kuitit
          })
        )
      }),
      tap(() => {
        setTimeout(() => { this.lataaSubject.next(false) }, 0)
      }),
      lemonShare()
    )

    this.kuititObservable = combineLatest([this.internalKuititObservable, this.lataaObservable]).pipe(
      map(kaikki => {
        if (kaikki[1]) {
          return []
        }
        return kaikki[0]
      })
    )

    this.rivienMaaraObservable = this.kuititObservable.pipe(
      map(kuitit => {
        return kuitit ? kuitit.length : 0
      })
    )

  }

  private kopioiHakukriteerienKamat(lahde: Hakukriteerit): Hakukriteerit {
    return {
      vapaahaku: lahde.vapaahaku,
      vuosikk: {
        kk: lahde.vuosikk.kk,
        kohde: lahde.vuosikk.kohde,
        vuosi: lahde.vuosikk.vuosi
      },
      maksutapa: lahde.maksutapa,
      naytaPoistetut: lahde.naytaPoistetut
    }
  }

  annaValittuMaksutapa(): AsiakkaanMaksutapa | null {
    return this.valittuMaksutapaSubject.value
  }

  vaihdaValittuMaksutapa(maksutapa: AsiakkaanMaksutapa | null) {
    this.valittuMaksutapaSubject.next(maksutapa)
  }

  getSearch(): string {
    return this.hakukriteeritSubject.value.vapaahaku
  }

  getPage(): PageEvent {
    return this.pageEventSubject.value
  }

  getSort() {
    return this.sortSubject.value
  }

  changePage(pageEvent: PageEvent): void {
    this.pageEventSubject.next(pageEvent)
  }

  changeSort(sort: Sort): void {
    this.sortSubject.next(sort)
  }

  changeSearch(vapaahaku: string): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vapaahaku = vapaahaku
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  getVuosiKk(): VuosiKk {
    return this.hakukriteeritSubject.value.vuosikk
  }

  getNaytaPoistetut(): boolean {
    return this.hakukriteeritSubject.value.naytaPoistetut
  }

  changeVuosiKkKohde(kohde: 'p' | 'e'): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vuosikk.kohde = kohde
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  changeVuosiKk(vuosi: number, kk: number): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vuosikk.vuosi = vuosi
    nykyisetArvot.vuosikk.kk = kk
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  changeMaksutapa(maksutapa: string): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.maksutapa = maksutapa
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  changeNaytaPoistetut(naytaPoistetut: boolean): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.naytaPoistetut = naytaPoistetut
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  resetSearchToDefaults() {
    this.hakukriteeritSubject.next(this.oletuskriteerit)
  }

  connect(): Observable<ListausFirestoreKuitti[]> {
    return this.kuititObservable
  }

  private getVuosiKkInternal(vuosikk: VuosiKk): string {
    if (vuosikk && vuosikk.vuosi && vuosikk.kohde && vuosikk.kk > -1) {
      return vuosikk.vuosi.toString().substring(2) + vuosikk.kohde + vuosikk.kk
    }
    return ''
  }

  disconnect() {

  }

  private paivitaKuva(kuitti: ListausFirestoreKuitti, asiakas: AsiakkaanAvainTiedot) {

    let kuva: FirestoreTositteenKuva = null
    // eslint-disable-next-line @typescript-eslint/no-shadow
    for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
      const mahdollinen = kuitti.kuvat[kuvanAvain]
      if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
        kuva = mahdollinen
        if (kuva.jarjestys === 1) {
          break
        }
      }
    }

    if (!kuva) {
      // this.errorHandler.handleError(new Error('Yhtään kuvaobjektia ei löytynyt kuitista ' + kuitti.avain))
      kuitti.ladataan = false
      kuitti.ensimmainenKuva = '/assets/noimage-error.jpg'
      return
    }


    // const kuvanAvain = pakattu ? sivunAvain + '_packed' : sivunAvain + '_quality'
    const kuvanAvain = kuva.avain + '_thumb'
    const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', kuitti.kuvakansio, kuvanAvain, 'webp')
    kuitti.ensimmainenKuva = LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrl

    return
    // src="https://kuvat.lemonator.lemontree.fi/api/1/kuvat/4/-MvsT8GXpUWaC5jDkTyD/pzBb3pxSDXc40g9nOkfQ_1_packed.webp"
    // src="https://kuvat.lemonator.lemontree.fi/api/1/kuvat/4/-MvsT8GXpUWaC5jDkTyD/pzBb3pxSDXc40g9nOkfQ_1_thumb.webp"
    // const kuvaValimuistista = this.kuittiKuvaService.annaKuva(kuva)
    // if (kuvaValimuistista &&
    //   kuvaValimuistista.kuva &&
    //   kuvaValimuistista.kuva.kuvanUrlObservable &&
    //   kuvaValimuistista.kuva.kuvanUrlObservable.value) {
    //   kuitti.ensimmainenKuva = kuvaValimuistista.kuva.kuvanUrlObservable.value
    //   kuitti.ladataan = false
    //   return
    // }

    // let lataa: boolean = true
    // if (kuva.alkuperaisenAvain && kuitti.alkuperaiset) {
    //   const alkuperainen = kuitti.alkuperaiset[kuva.alkuperaisenAvain]
    //   lataa = alkuperainen?.kasitelty
    // }

    // if (lataa) {
    //   const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(asiakas.asiakasId + '', kuitti.kuvakansio, kuva.avain, kuva.type)
    //   return this.httpService.getBinary('/kuittiLataaSkaalattuKuva?a=' + encodeURIComponent('/api/1/kuitit/kuvat/thumb/' + kuvanUrl), LEMONATOR_CF_API).then(blob => {
    //     // console.log('TÄÄLLÄ 3')
    //     const url = URL.createObjectURL(blob)
    //     return this.sanitizer.bypassSecurityTrustUrl(url)
    //   }).then(safeUrl => {
    //     // console.log('TÄÄLLÄ 3')
    //     // console.log('Returning', safeUrl)
    //     kuitti.ensimmainenKuva = safeUrl
    //     kuitti.ladataan = false
    //     this.kuittiKuvaService.lisaaKuvaLopulliseenKakkuun(kuitti, safeUrl)
    //   }).catch(error => {
    //     kuitti.ensimmainenKuva = '/assets/noimage.png'
    //     kuitti.ladataan = false
    //     this.errorHandler.handleError(error)
    //   })
    // }

  }

}
