import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kayttajanTiedot => !!kayttajanTiedot),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/kirjauduttupois'])
        }
      })
    )
  }

}
