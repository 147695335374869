import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { MatSnackBar } from '@angular/material/snack-bar'

import { map, switchMap, take, takeUntil } from 'rxjs/operators'
import { DateService } from '../../_shared-core/service/date.service'
import { Observable, of as observableOf, firstValueFrom, Subject } from 'rxjs'
import { Asiakas, AsiakkaanMaksutapa } from '../../_jaettu-lemonator/model/asiakas'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { KirjanpitoAlustaRequest, KirjanpitoAlustaResponse, Kirjaus, KirjanpitoKevytalustaRequest } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { MatDialog } from '@angular/material/dialog'

interface MainForm {
  'kevytVaiTavallinen': FormControl<'kevyt' | 'tavallinen'>
  'kirjanpitoAlku': FormControl<Date>
  'maksutapa': FormControl<AsiakkaanMaksutapa['tunniste']>
}

@Component({
  templateUrl: './kirjanpidon-alustaminen.component.html'
})
export class AsiakkaanKirjanpidonAlustaminenComponent implements OnInit, OnDestroy {

  asiakas: Asiakas = null
  failedMessage: string

  form: FormGroup<MainForm>

  latestHyvaksyttyKirjausObservable: Observable<Kirjaus>
  maksutavatObservable: Observable<AsiakkaanMaksutapa[]>
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {

    this.form = new FormGroup<MainForm>({
      'kevytVaiTavallinen': new FormControl<'kevyt' | 'tavallinen'>('tavallinen'),
      'kirjanpitoAlku': new FormControl<Date>(null, Validators.required),
      'maksutapa': new FormControl<AsiakkaanMaksutapa['tunniste']>({ value: null, disabled: true })
    })

    this.kevytVaiTavallinen.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kevytVaiTavallinen => {
      if (kevytVaiTavallinen === 'kevyt') {
        this.maksutapa.addValidators(Validators.required)
        this.maksutapa.enable()
      } else if (kevytVaiTavallinen === 'tavallinen') {
        this.maksutapa.clearValidators()
        this.maksutapa.disable()
      } else {
        throw new Error('Unknown option in kevytVaiTavallinen')
      }
    })

    this.latestHyvaksyttyKirjausObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<Kirjaus>(null)
        }
        return this._firebase.firestoreCollection<Kirjaus>(this._kirjanpitoUriService.annaKirjausCollectionUri(asiakas.avain))
          .where('kirjanpitajaHyvaksynyt', '==', true)
          .where('poistettu', '==', false)
          .orderBy('p', 'desc')
          .limit(1).listen().pipe(
            map(resp => resp?.[0])
          )
      })
    )

    this.latestHyvaksyttyKirjausObservable.pipe(
      take(1)
    ).subscribe(latest => {
      if (latest?.p) {
        this.kevytVaiTavallinen.setValue('kevyt')
      }
    })

    this.maksutavatObservable = this._asiakasService.nykyisenAsiakkaanMaksutavatObservable

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.asiakas = result
    })

  }

  get kevytVaiTavallinen() {
    return this.form.get('kevytVaiTavallinen')
  }

  get kirjanpitoAlku() {
    return this.form.get('kirjanpitoAlku')
  }

  get maksutapa() {
    return this.form.get('maksutapa')
  }

  async alusta() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    try {
      const kevytVaiTavallinen = this.kevytVaiTavallinen.value

      if (kevytVaiTavallinen === 'kevyt') {
        await this._kevytAlustus()
      } else if (kevytVaiTavallinen === 'tavallinen') {
        await this._tavallinenAlustus()
      } else {
        throw new Error('Unknown option ' + kevytVaiTavallinen)
      }
    } catch (err: any) {
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
      this.failedMessage = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private async _tavallinenAlustus() {
    const latestKirjaus = await firstValueFrom(this.latestHyvaksyttyKirjausObservable)

    if (latestKirjaus?.p) {

      const dialogText = 'Kirjanpidossa on hyväksyttyjä kirjauksia (esim. ' + this._dateService.muotoileNumber(latestKirjaus.p, 'dd.MM.yyyy') + ' ' + latestKirjaus.kirjausnumero + '). Oletko varma, että haluat alustaa tiedot?'

      const dialogData: AreYouSureDialogData = {
        rightAction: 'Kyllä',
        leftAction: 'Ei',
        text: dialogText,
        header: 'Kirjanpidossa on hyväksyttyjä kirjauksia'
      }

      const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

      if (!isSure) {
        return
      }
    }
    this._ladataanService.aloitaLataaminen()

    const asiakkaanAvaimet = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kirjanpidonAlku = this._dateService.dateToNumber(this.kirjanpitoAlku.value)

    const reqData: KirjanpitoAlustaRequest = {
      asiakasAvain: asiakkaanAvaimet.avain,
      kirjanpidonAlku: kirjanpidonAlku
    }

    const resp: KirjanpitoAlustaResponse = await this._firebase.functionsCall<KirjanpitoAlustaRequest, KirjanpitoAlustaResponse>('kirjanpitoAlustaAsiakkaanKirjanpito', reqData, { timeout: 540 * 1000 })

    if (!resp || resp.e) {
      switch (resp.e) {
        case 'unauthorized': {
          this.failedMessage = 'Alustaminen on kieletty.'
          break
        }
        case 'invalid-maksutavat': {
          this.failedMessage = 'Alustaminen keskeytetiin - maksutavoissa on täyttämättömiä kenttiä.'
          break
        }
        default: {
          throw new Error('Kirjanpidon alustaminen epäonnistui! ' + (resp?.e || 'no-response'))
        }
      }
    } else {
      this._snackbar.open('Asiakas onnistuneesti alustettu!', 'OK')
    }
  }

  private async _kevytAlustus() {

    if (!this.maksutapa.value) {
      throw new Error('No payment method selected!') // Extra safeguard
    }

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const latestKirjausInMaksutapa = await firstValueFrom(this._firebase.firestoreCollection<Kirjaus>(this._kirjanpitoUriService.annaKirjausCollectionUri(asiakas.avain))
      .where('kirjanpitajaHyvaksynyt', '==', true)
      .where('poistettu', '==', false)
      .where('maksutavanTunniste', '==', '' + this.maksutapa.value)
      .orderBy('p', 'desc')
      .limit(1).listen().pipe(
        map(response => response?.[0])
      ))

    const kirjanpidonAlku = this.kirjanpitoAlku.value

    if (latestKirjausInMaksutapa?.p && this._dateService.compareNumberDates(latestKirjausInMaksutapa.p, '>=', this._dateService.dateToNumber(kirjanpidonAlku))) {

      const kirjanpidonAlkuFormatted = this._dateService.muotoile(kirjanpidonAlku, 'dd.MM.yyyy')
      const latestKirjausPvmFormatted = this._dateService.muotoileNumber(latestKirjausInMaksutapa.p, 'dd.MM.yyyy')
      const dialogText = 'Kirjanpidossa on hyväksyttyjä kirjauksia ' + kirjanpidonAlkuFormatted + ' jälkeen (esim. ' + latestKirjausPvmFormatted + ' ' + latestKirjausInMaksutapa.kirjausnumero + '). Oletko varma, että haluat alustaa tiedot?'

      const dialogData: AreYouSureDialogData = {
        rightAction: 'Kyllä',
        leftAction: 'Ei',
        text: dialogText,
        header: 'Kirjanpidossa on hyväksyttyjä kirjauksia'
      }

      const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

      if (!isSure) {
        return
      }
    }

    this._ladataanService.aloitaLataaminen()

    const asiakasAvaimet = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kirjanpidonAlkuNum = this._dateService.dateToNumber(kirjanpidonAlku)

    const reqData: KirjanpitoKevytalustaRequest = {
      asiakasAvain: asiakasAvaimet.avain,
      kirjanpidonAlku: kirjanpidonAlkuNum,
      maksutavanTunniste: this.maksutapa.value
    }

    const resp: KirjanpitoAlustaResponse = await this._firebase.functionsCall<KirjanpitoKevytalustaRequest, KirjanpitoAlustaResponse>('kirjanpitoKevytalustaAsiakkaanKirjanpito', reqData, { timeout: 540 * 1000 })

    if (!resp || resp.e) {
      switch (resp.e) {
        case 'unauthorized': {
          this.failedMessage = 'Alustaminen on kieletty.'
          break
        }
        case 'invalid-maksutapa': {
          this.failedMessage = 'Alustaminen keskeytetiin - maksutavoissa on täyttämättömiä kenttiä.'
          break
        }
        default: {
          throw new Error('Kirjanpidon alustaminen epäonnistui! ' + (resp?.e || 'no-response'))
        }
      }
    } else {
      this._snackbar.open('Asiakas onnistuneesti alustettu!', 'OK')
    }
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
