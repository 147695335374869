<div *ngIf="asiakkaanIbanitObservable | async; let asiakkaanIbanit else loadingIbans">

  <div *ngIf="asiakkaanIbanit?.length; else eiIbaneja">
    <div class="title">Asiakkaan valtuuttamat tilinumerot</div>
    <div *ngFor="let iban of asiakkaanIbanit">
      {{ iban.iban }} ({{ iban.bic }}, {{ iban.bank }})
    </div>

    <form [formGroup]="form">

      <div class="title">Hae tilitapahtumat Aiiasta</div>

      <div style="width: 430px; padding-top: 5px;">
        <mat-form-field>
          <mat-label>IBAN</mat-label>
          <mat-select formControlName="iban">
            <mat-option *ngFor="let iban of asiakkaanIbanit" [value]="iban">
              {{ iban.iban }} ({{ iban.bic }}, {{ iban.bank }})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('iban')?.errors?.required && (form.get('iban')?.dirty || form.get('iban')?.touched)">
            IBAN puuttuu
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field style="width: 200px; margin-right: 30px;">
        <mat-label>Hae tilitapahtumat alkaen</mat-label>
        <input matInput type="text" [matDatepicker]="fetchStartPicker" formControlName="start" required>
        <mat-datepicker-toggle matSuffix [for]="fetchStartPicker"></mat-datepicker-toggle>
        <mat-error *ngIf="form.get('start')?.errors?.required && (form.get('start')?.dirty || form.get('start')?.touched)">
          Päivämäärä puuttuu
        </mat-error>
      </mat-form-field>

      <mat-datepicker #fetchStartPicker></mat-datepicker>

      <mat-form-field style="width: 200px;">
        <mat-label>Hae tilitapahtumat loppuen</mat-label>
        <input matInput type="text" [matDatepicker]="fetchEndPicker" formControlName="end" required>
        <mat-datepicker-toggle matSuffix [for]="fetchEndPicker"></mat-datepicker-toggle>
        <mat-error *ngIf="form.get('end')?.errors?.required && (form.get('end')?.dirty || form.get('end')?.touched)">
          Päivämäärä puuttuu
        </mat-error>
        <mat-error *ngIf="form.get('end')?.errors?.endBeforeStart && (form.get('end')?.dirty || form.get('end')?.touched)">
          Loppupäivämäärä on ennen alkua
        </mat-error>
      </mat-form-field>

      <mat-datepicker #fetchEndPicker></mat-datepicker>

      <div style="margin: 30px 0;">
        <button style="margin-right: 15px;" class="primarybutton" type="button" mat-button (click)="startAiiaFetch()">Synkronoi kirjanpitoon</button>
        <button class="primarybutton" type="button" mat-button (click)="getRawAiiaTransactions()">Hae tarkasteltavaksi</button>
      </div>
    </form>

    <div *ngIf="rawTransactionsStateSubject | async; let transactionState">
      <ng-container *ngIf="transactionState.showTable">
        <div *ngIf="!transactionState.loading else loadingTransactions">
          <div style="font-size: 20px; line-height: 20px; margin-bottom: 10px;">Tulos: {{transactionState.outcome}}</div>
          <div>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>Tilin tiedot:</mat-panel-title>
                </mat-expansion-panel-header>
                <pre>{{transactionState.accountInfo | json}}</pre>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <table style="margin: 10px 0; width: 100%;">
            <ng-container *ngFor="let transaction of transactionState.transactions">
              <tr style="border-bottom: 1px solid gray; cursor: pointer;" (click)="toggleShowDetails(transaction)">
                <td>{{ transaction.amount }}</td>
                <td>{{ transaction.date }}</td>
              </tr>
              <tr style="cursor: pointer;" *ngIf="transaction.showDetails" (click)="toggleShowDetails(transaction)">
                <td colspan="2">
                  <pre>{{transaction | json}}</pre>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
        <ng-template #loadingTransactions>
          <div style="margin: 30px; text-align: center;">
            Tilitapahtumia ladataan... <br />
            <mat-spinner style="display: inline-block;"></mat-spinner>
          </div>
        </ng-template>
      </ng-container>
    </div>

  </div>
  <ng-template #eiIbaneja>
    <div class="red-text" style="margin: 4em;"> Ei voimassa olevia tilejä.</div>
  </ng-template>
</div>
<ng-template #loadingIbans>
  <div style="margin: 30px; text-align: center;">
    Tilien metatietoja ladataan... <br />
    <mat-spinner style="display: inline-block;"></mat-spinner>
  </div>
</ng-template>