import { KopioijaPalvelu } from '../../../_jaettu/service/kopioija.service'
import { Kayttaja, AsiakkaanMaksutapa } from '../../../_jaettu-lemonator/model/asiakas'
import { TositteenLisaysprosessinOsaMobiilissa, TositteenLisaysprosessinOsanTunniste } from '../../../_jaettu/model/kayttaja'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'
import { Injectable } from '@angular/core'

@Injectable()
export class KayttajaKopioija {

  constructor(
    private timestampService: TimestampService,
    private kopioijaPalvelu: KopioijaPalvelu
  ) {

  }

  public annaUusiKayttaja(maksutavat: AsiakkaanMaksutapa[]): Kayttaja {
    const kayttaja: Kayttaja = {
      avain: '',
      etunimi: '',
      sukunimi: '',
      hetu: '',
      luotu: this.timestampService.now(),
      luoja: '',
      paivitetty: this.timestampService.now(),
      paivittaja: '',
      poistettu: false,
      aktiivinen: true,
      alvVahennysoikeusPortaat: [],
      email: '',
      prosessinOsat: this.annaDefaultProsessinOsat(),
      roolit: {},
      synkkaustyot: {},
      kirjauduKayttajanaUid: null,
      kirjauduKayttajanaAsiakasAvain: null,
      maksutavat: this.annaDefaultMaksutavat(maksutavat),
      puhelinnumero: '',
      asiointikieli: 'fi',
      kayttajaTunnistettu: 'ei-tunnistettu',
      tervetuloaDialogiNaytetty: false,
      sopimuksenHyvaksyntaStatus: 'ei-hyvaksytty',
      selvitettavienNakyvyys: 'kaikki'
    }

    kayttaja.roolit.LASKUTUS = true
    kayttaja.roolit.TOSITTEET_OSTO = true
    kayttaja.roolit.TOSITTEET_MYYNTI = true
    kayttaja.roolit.TUNNISTETAAN = true

    return kayttaja
  }

  private annaDefaultProsessinOsat(): TositteenLisaysprosessinOsaMobiilissa[] {
    const osat: TositteenLisaysprosessinOsaMobiilissa[] = []

    osat.push({
      otsikko: 'Kamera',
      tunniste: TositteenLisaysprosessinOsanTunniste.KAMERA
    })

    osat.push({
      otsikko: 'Tosite on maksettu',
      tunniste: TositteenLisaysprosessinOsanTunniste.MAKSUTAPA
    })

    osat.push({
      otsikko: 'Maksun määrä',
      tunniste: TositteenLisaysprosessinOsanTunniste.MAKSUN_MAARA
    })

    osat.push({
      otsikko: 'Lisätiedot',
      tunniste: TositteenLisaysprosessinOsanTunniste.PVM_SELITE
    })

    return osat
  }

  private annaDefaultMaksutavat(mt: AsiakkaanMaksutapa[]): number[] {
    const maksutavat: number[] = []
    let lisaaYkkonen = false
    let lisaaKakkonen = false
    if (mt) {
      for (const maksutapa of mt) {
        if (maksutapa.tunniste === 1) {
          lisaaYkkonen = true
        } else if (maksutapa.tunniste === 2) {
          lisaaKakkonen = true
        }
      }
    }
    if (lisaaYkkonen) {
      maksutavat.push(1)
    }
    if (lisaaKakkonen) {
      maksutavat.push(2)
    }
    return maksutavat
  }

  public kopioiKayttaja(kopioitava: Kayttaja): Kayttaja {
    return this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville<Kayttaja>(kopioitava)
  }

}
