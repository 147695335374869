import { Kayttaja } from '../model/asiakas'
import { CustomerHallituksenJasen, CustomerOsakas, KnowYourCustomer } from '../../_jaettu/model/kayttaja'
import { TilinpaatosHallitusUser, TilinpaatosOsakasUser, TilinpaatosUserData } from '../../_jaettu/model/tilinpaatos'
import { StringService } from '../../_shared-core/service/string.service'
import { TranslationService } from '../../_jaettu/service/translation.service'

export interface TilinpaatosViewableUser {
  name: string
  lemonaidUidConnected: boolean
  role?: string
  link: string // Lemonator /kayttajat/* URL
}

export interface TilinpaatosUsersViewableData {
  hallitus: TilinpaatosViewableUser[]
  osakkaat: TilinpaatosViewableUser[]
}

export class TilinpaatosUsersService {

  constructor(
    private _stringService: StringService,
    private _translationService: TranslationService
  ) { }

  convertKycToViewableData(kyc: KnowYourCustomer, kayttajat: Kayttaja[]) {
    const output: TilinpaatosUsersViewableData = {
      hallitus: [],
      osakkaat: []
    }

    for (const osakas of kyc.osakkaat) {
      const matchingLemonatorUser: Kayttaja = this._findLemonaidUserForOsakas(osakas, kayttajat)

      const tilinpaatosOsakasUser = this._createViewableUser(kyc.asiakasAvain, osakas.nimi, matchingLemonatorUser)
      output.osakkaat.push(tilinpaatosOsakasUser)
    }

    for (const boardMember of kyc.hallitus) {

      if (boardMember.rooli === 'varajasen') {
        continue
      }
      const matchingLemonatorUser: Kayttaja = this._findLemonaidUserForBoardMember(boardMember, kayttajat)
      const tilinpaatosHallitusUser = this._createViewableUser(kyc.asiakasAvain, boardMember.nimi, matchingLemonatorUser)
      if (boardMember.rooli) {
        const roleCode = boardMember.rooli === 'toimitusjohtaja' ? 'toimitusjohtaja' : 'hallituksen-' + boardMember.rooli
        tilinpaatosHallitusUser.role = this._translationService.lokalisoi('lemonaid-sopimus-dialog.kyc.' + roleCode, 'fi')
      }
      output.hallitus.push(tilinpaatosHallitusUser)
    }

    return output
  }

  private _createViewableUser(asiakasAvain: string, name: string, matchingLemonatorUser?: Kayttaja): TilinpaatosViewableUser {
    const viewableUser: TilinpaatosViewableUser = {
      lemonaidUidConnected: !!matchingLemonatorUser?.avain || false,
      name: name,
      link: matchingLemonatorUser ? ('https://lemonator.lemontree.fi/asiakkaat/' + asiakasAvain + '/kayttajat/' + matchingLemonatorUser.avain) : null
    }
    return viewableUser
  }

  convertKycToTilinpaatosUsers(kyc: KnowYourCustomer, kayttajat: Kayttaja[]): TilinpaatosUserData {

    const tilinpaatosUserData: TilinpaatosUserData = {
      hallitusUsers: [],
      osakkaatUsers: []
    }

    for (const osakas of kyc.osakkaat) {
      const matchingLemonatorUser: Kayttaja = this._findLemonaidUserForOsakas(osakas, kayttajat)

      const boardMembersNameSeparatorIndex = osakas.nimi.trim().lastIndexOf(' ')
      const boardMemberFirstName = osakas.nimi.substring(0, boardMembersNameSeparatorIndex)
      const boardMemberSurname = osakas.nimi.substring(boardMembersNameSeparatorIndex + 1)

      const tilinpaatosOsakasUser: TilinpaatosOsakasUser = {
        lemonaidUid: matchingLemonatorUser?.avain ?? null,
        etunimi: matchingLemonatorUser?.etunimi || boardMemberFirstName,
        sukunimi: matchingLemonatorUser?.sukunimi || boardMemberSurname,
        osakkeita: osakas.osakkeita
      }
      tilinpaatosUserData.osakkaatUsers.push(tilinpaatosOsakasUser)
    }

    for (const boardMember of kyc.hallitus) {

      const matchingLemonatorUser: Kayttaja = this._findLemonaidUserForBoardMember(boardMember, kayttajat)

      const boardMembersNameSeparatorIndex = boardMember.nimi.trim().lastIndexOf(' ')
      const boardMemberFirstName = boardMember.nimi.substring(0, boardMembersNameSeparatorIndex)
      const boardMemberSurname = boardMember.nimi.substring(boardMembersNameSeparatorIndex + 1)

      const tilinpaatosUser: TilinpaatosHallitusUser = {
        lemonaidUid: matchingLemonatorUser?.avain ?? null,
        role: boardMember.rooli ?? null,
        etunimi: matchingLemonatorUser?.etunimi || boardMemberFirstName,
        sukunimi: matchingLemonatorUser?.sukunimi || boardMemberSurname
      }
      tilinpaatosUserData.hallitusUsers.push(tilinpaatosUser)
    }

    return tilinpaatosUserData

  }

  private _findLemonaidUserForBoardMember = (boardMember: CustomerHallituksenJasen, kayttajat: Kayttaja[]) => {
    for (const kayttaja of kayttajat) {

      // Try to match by hetu, if that exists
      if (!boardMember.noFinnishId && boardMember.hetuTaiYtunnus) {
        if (!kayttaja.hetu) {
          continue
        }
        if (this._stringService.removeAllWhiteSpaces(boardMember.hetuTaiYtunnus).toUpperCase() === this._stringService.removeAllWhiteSpaces(kayttaja.hetu).toUpperCase()) {
          return kayttaja
        }
      } else {
        // Try to match by name otherwise
        const userNameWithoutAnyGaps = this._stringService.removeAllWhiteSpaces(kayttaja.etunimi + kayttaja.sukunimi)
        const boardMemberNameWithoutAnyGaps = this._stringService.removeAllWhiteSpaces(boardMember.nimi)
        if (userNameWithoutAnyGaps === boardMemberNameWithoutAnyGaps) {
          return kayttaja
        }
      }
    }
    return null
  }

  private _findLemonaidUserForOsakas = (osakas: CustomerOsakas, kayttajat: Kayttaja[]) => {
    for (const kayttaja of kayttajat) {

      // Try to match by hetu, if that exists
      if (!osakas.noFinnishId && osakas.hetuTaiYtunnus) {
        if (!kayttaja.hetu) {
          continue
        }
        if (this._stringService.removeAllWhiteSpaces(osakas.hetuTaiYtunnus).toUpperCase() === this._stringService.removeAllWhiteSpaces(kayttaja.hetu).toUpperCase()) {
          return kayttaja
        }
      } else {
        // Try to match by name otherwise
        const userNameWithoutAnyGaps = this._stringService.removeAllWhiteSpaces(kayttaja.etunimi + kayttaja.sukunimi)
        const boardMemberNameWithoutAnyGaps = this._stringService.removeAllWhiteSpaces(osakas.nimi)
        if (userNameWithoutAnyGaps === boardMemberNameWithoutAnyGaps) {
          return kayttaja
        }
      }
    }
    return null
  }

}
