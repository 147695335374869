<div class="list-page">

  <div>
    <div style="position: relative;">
      <mat-table matSort [dataSource]="kirjanpitajatDataSource" [matSortDisableClear]="true" style="min-height: 94px;">

        <ng-container matColumnDef="domain">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Domain </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.domain}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="org">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Organization </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.organization_name}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="naytettavatKolumnit"></mat-header-row>
        <mat-row (click)="muokkaa(row)" *matRowDef="let row; columns: naytettavatKolumnit"></mat-row>

      </mat-table>
      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!kirjanpitajatLataa && kirjanpitajatDataSource?.filteredData?.length == 0">
        <div style="width: 100%; padding-top: 40px; text-align: center;">Yhtään raporttia ei löytynyt.</div>
      </div>
      <div lemon-loading [show]="kirjanpitajatLataa"></div>
    </div>
  </div>

</div>