<div class="tab-container no-hidden-overflow-tabs">

  <!-- {{ selectedMonthObservable | async | json }} -->

  <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" style="margin: 2em;">

    <mat-tab label="Listaus">
      <div app-pankkiyhteydet-listaus></div>
    </mat-tab>

  </mat-tab-group>

</div>