<div class="kirjaamattomatDeleteDiv" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
  <div class="delete-drop-text">Irroita tosite kirjauksesta pudottamalla se tähän.</div>
  <i class="delete-drop-icon fa fa-arrow-down"></i>
</div>

<mat-menu #naytettavaKuittiMenu="matMenu">
  <ng-template matMenuContent let-naytettavaKuitti="naytettavaKuitti">
    <button mat-menu-item (click)="avaaKuittiUudessaIkkunassa(naytettavaKuitti)">Avaa uudessa ikkunassa</button>
    <button mat-menu-item [disabled]="!(naytettavaKirjausObservable | async)" (click)="liitaKuitti(naytettavaKuitti)">Liitä valittuna olevaan kirjaukseen</button>
    <button mat-menu-item (click)="lisaaKuittiJatkuviin(naytettavaKuitti)">Lisää toistuviin tositteisiin</button>
    <button mat-menu-item (click)="paivitaKuitinKorostus(naytettavaKuitti, !naytettavaKuitti?.kirjattavaKuitti?.korostettu)">{{ !naytettavaKuitti.kirjattavaKuitti?.korostettu ? 'Korosta' : 'Poista korostus' }}</button>
    <button mat-menu-item (click)="poistaKuitti(naytettavaKuitti)">Poista</button>
  </ng-template>
</mat-menu>

<mat-menu #naytettavaLaskuMenu="matMenu">
  <ng-template matMenuContent let-naytettavaLasku="naytettavaLasku">
    <button mat-menu-item (click)="avaaLaskuUudessaIkkunassa(naytettavaLasku)">Avaa uudessa ikkunassa</button>
    <button mat-menu-item [disabled]="!(naytettavaKirjausObservable | async)" (click)="liitaLasku(naytettavaLasku)">Liitä valittuna olevaan kirjaukseen</button>
    <!-- <button mat-menu-item (click)="lisaaLaskuJatkuviin(naytettavaLasku)">Lisää toistuviin tositteisiin</button> -->
    <button mat-menu-item (click)="paivitaLaskunKorostus(naytettavaLasku, !naytettavaLasku?.kirjattavaLasku?.korostettu)">{{ !naytettavaLasku?.kirjattavaLasku?.korostettu ? 'Korosta' : 'Poista korostus' }}</button>
    <button mat-menu-item (click)="poistaLasku(naytettavaLasku)">Poista</button>
  </ng-template>
</mat-menu>

<mat-menu #naytettavaRaahattavaMenu="matMenu">
  <ng-template matMenuContent let-naytettavaRaahattava="naytettavaRaahattava">
    <button mat-menu-item (click)="avaaRaahattavaUudessaIkkunassa(naytettavaRaahattava)">Avaa uudessa ikkunassa</button>
    <button mat-menu-item [disabled]="!(naytettavaKirjausObservable | async)" (click)="liitaRaahattava(naytettavaRaahattava)">Liitä valittuna olevaan kirjaukseen</button>
    <button mat-menu-item (click)="lisaaRaahattavaJatkuviin(naytettavaRaahattava)">Lisää toistuviin tositteisiin</button>
    <button mat-menu-item (click)="paivitaRaahattavanKorostus(naytettavaRaahattava, !naytettavaRaahattava?.raahattava?.ko)">{{ !naytettavaRaahattava?.raahattava?.ko ? 'Korosta' : 'Poista korostus' }}</button>
    <button mat-menu-item (click)="poistaRaahattava(naytettavaRaahattava)">Poista</button>
  </ng-template>
</mat-menu>

<mat-menu #naytettavaJatkuvaMenu="matMenu">
  <ng-template matMenuContent let-naytettavaJatkuva="naytettavaJatkuva">
    <button mat-menu-item (click)="avaaJatkuvaUudessaIkkunassa(naytettavaJatkuva)">Avaa uudessa ikkunassa</button>
    <button mat-menu-item [disabled]="!(naytettavaKirjausObservable | async)" (click)="liitaJatkuva(naytettavaJatkuva)">Liitä valittuna olevaan kirjaukseen</button>
    <button mat-menu-item (click)="muokkaaYliajosummaa(naytettavaJatkuva)">Muokkaa yliajosummaa</button>
    <button mat-menu-item (click)="poistaJatkuvatFlag(naytettavaJatkuva)">Poista jatkuvista tositteista</button>
    <button mat-menu-item (click)="paivitaJatkuvanKorostus(naytettavaJatkuva, !naytettavaJatkuva.kuitti?.kirjattavaKuitti?.korostettu && !naytettavaJatkuva.raahattava?.raahattava?.ko)">{{ !naytettavaJatkuva.kuitti?.kirjattavaKuitti?.korostettu && !naytettavaJatkuva.raahattava?.raahattava?.ko ? 'Korosta' : 'Poista
      korostus' }}</button>
    <button mat-menu-item (click)="poistaJatkuva(naytettavaJatkuva)">Poista</button>
  </ng-template>
</mat-menu>

<div *ngIf="tiedostoaPoistetaanObservable | async; else listaaLadataanTemplate" class="ei-sisaltoa">
  <div>Tositetta irroitetaan kirjauksesta.</div>
  <mat-progress-bar style="margin: 20px;" mode="indeterminate"></mat-progress-bar>
</div>

<ng-template #listaaLadataanTemplate>
  <div *ngIf="ladataanObservable | async; else listaTyhjaTemplate" class="ei-sisaltoa">
    <div>Kirjaamattomia tositteita ladataan.</div>
    <mat-progress-bar style="margin: 20px;" mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #listaTyhjaTemplate>
  <div *ngIf="onkoListaTyhjaObservable | async; else listaTemplate" class="ei-sisaltoa">
    <div>Ei yhtään liittämätöntä tositetta.</div>
  </div>
</ng-template>

<ng-template #listaTemplate>
  <div style="display: flex; flex-flow: row wrap; padding: 15px 20px 0 15px;">
    <button mat-button *ngFor="let maksutapaJaKuitit of maksutavatObservable | async" (click)="valitseMaksutapa(maksutapaJaKuitit.maksutapa.tunniste + '')" [class.valittu-maksutapa]="valittuMaksutapa == maksutapaJaKuitit.maksutavanTunniste" class="kirjaamattomia-button">
      <span class="kirjaamattomia-pallura mat-elevation-z1">{{ maksutapaJaKuitit.maara }}</span>
      <span>{{ maksutapaJaKuitit.maksutapa.nimi }}</span>
      <span *ngIf="maksutapaJaKuitit.korostettuja" class="korostettuja-pallura mat-elevation-z1">{{ maksutapaJaKuitit.korostettuja }}</span>
    </button>
    <button mat-button *ngIf="kirjaamattomienLaskujenMaaraObservable | async; let maara" (click)="valitseMaksutapa('laskut')" [class.valittu-maksutapa]="valittuMaksutapa == 'laskut'" class="kirjaamattomia-button">
      <span class="kirjaamattomia-pallura mat-elevation-z1">{{ maara.total }}</span>
      <span>Myyntilaskut</span>
      <span *ngIf="maara.korostettuja" class="korostettuja-pallura mat-elevation-z1">{{ maara.korostettuja }}</span>
    </button>
    <button mat-button *ngIf="kirjaamattomienYhteisomyyntiLaskujenMaaraObservable | async; let maara" (click)="valitseMaksutapa('yhteiso')" [class.valittu-maksutapa]="valittuMaksutapa == 'yhteiso'" class="kirjaamattomia-button">
      <span class="kirjaamattomia-pallura mat-elevation-z1">{{ maara.total }}</span>
      <span>Yhteisömyyntilaskut</span>
      <span *ngIf="maara.korostettuja" class="korostettuja-pallura mat-elevation-z1">{{ maara.korostettuja }}</span>
    </button>
    <button mat-button *ngIf="kirjaamattomienRaahattavienMaaraObservable | async; let maara" (click)="valitseMaksutapa('raahattavat')" [class.valittu-maksutapa]="valittuMaksutapa == 'raahattavat'" class="kirjaamattomia-button">
      <span class="kirjaamattomia-pallura mat-elevation-z1">{{ maara.total }}</span>
      <span>Omat tiedostot</span>
      <span *ngIf="maara.korostettuja" class="korostettuja-pallura mat-elevation-z1">{{ maara.korostettuja }}</span>
    </button>
    <button mat-button *ngIf="jatkuvienTositteidenMaaraObservable | async; let maara" (click)="valitseMaksutapa('jatkuvat')" [class.valittu-maksutapa]="valittuMaksutapa == 'jatkuvat'" class="kirjaamattomia-button">
      <span class="kirjaamattomia-pallura mat-elevation-z1">{{ maara.total }}</span>
      <span>Toistuvat liiketapahtumat</span>
      <span *ngIf="maara.korostettuja" class="korostettuja-pallura mat-elevation-z1">{{ maara.korostettuja }}</span>
    </button>

    <div style="width: 100%; height: 1px; background-color: darkgray; margin-top: 15px; margin-bottom: 15px;"></div>

    <mat-checkbox (change)="naytaMyosTulevaKuukaudet($event.checked)" *ngIf="valittuMaksutapa !== 'raahattavat' && valittuMaksutapa !== 'jatkuvat'">Näytä myös tulevat kuukaudet</mat-checkbox>

    <ng-container *ngIf="valitunMaksutavanKuititObservable | async; let kuitit">
      <div draggable="true" (dragstart)="aloitaKuitinRaahaaminen($event, kuitti)" (dragend)="lopetaRaahaaminen($event)" [class.korostettu]="kuitti.kirjattavaKuitti?.korostettu" class="kirjaamaton-kortti mat-elevation-z1" *ngFor="let kuitti of kuitit" (click)="valitseTosite($event, kuitti)" [class.kortti-valittu]="valittuTosite === kuitti.kuitti.avain">
        <div class="pvm-container">
          <div class="pvm">{{kuitti.pvm}}</div>
          <div class="summa light-blue-text">{{kuitti.summa}}</div>
        </div>
        <div class="explanation-container">
          {{kuitti.selite}}
        </div>
        <mat-spinner *ngIf="kuitti.poistetaan else kuittivalinnat" [diameter]="22"></mat-spinner>
        <ng-template #kuittivalinnat>
          <button mat-icon-button [disabled]="kuitti.tallennetaan" [matMenuTriggerFor]="naytettavaKuittiMenu" [matMenuTriggerData]="{naytettavaKuitti: kuitti}" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="valittuMaksutapa == 'raahattavat'">
      <div draggable="true" (dragstart)="aloitaRaahattavanRaahaaminen($event, raahattava)" (dragend)="lopetaRaahaaminen($event)" [class.korostettu]="raahattava?.raahattava?.ko" class="kirjaamaton-kortti mat-elevation-z1" *ngFor="let raahattava of kirjaamattomatRaahattavatObservable | async" (click)="valitseRaahattava($event, raahattava)"
        [class.kortti-valittu]="valittuRaahattava === raahattava.avain">
        {{raahattava.raahattava.n}}
        <mat-spinner *ngIf="raahattava.poistetaan else raahatunvalinnat" [diameter]="22"></mat-spinner>
        <ng-template #raahatunvalinnat>
          <button mat-icon-button [disabled]="raahattava.tallennetaan" [matMenuTriggerFor]="naytettavaRaahattavaMenu" [matMenuTriggerData]="{naytettavaRaahattava: raahattava}" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="valittuMaksutapa == 'yhteiso'">
      <div draggable="true" (dragstart)="aloitaLaskunRaahaaminen($event, lasku)" (dragend)="lopetaRaahaaminen($event)" [class.korostettu]="lasku?.kirjattavaLasku?.korostettu" class="kirjaamaton-kortti mat-elevation-z1" *ngFor="let lasku of kirjaamattomatYhteisomyyntiLaskutObservable | async" (click)="valitseLasku($event, lasku)" [class.kortti-valittu]="valittuLasku === lasku.lasku.avain">
        <div class="pvm-container">
          <div class="pvm">{{lasku.pvm}}</div>
          <div class="summa light-blue-text">{{lasku.summa}}</div>
        </div>
        <div class="explanation-container">
          {{lasku.asiakas}}
        </div>
        <mat-spinner *ngIf="lasku.poistetaan else laskuvalinnatk" [diameter]="22"></mat-spinner>
        <ng-template #laskuvalinnatk>
          <button mat-icon-button [disabled]="lasku.tallennetaan" [matMenuTriggerFor]="naytettavaLaskuMenu" [matMenuTriggerData]="{naytettavaLasku: lasku}" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="valittuMaksutapa == 'laskut'">
      <div draggable="true" (dragstart)="aloitaLaskunRaahaaminen($event, lasku)" (dragend)="lopetaRaahaaminen($event)" [class.korostettu]="lasku?.kirjattavaLasku?.korostettu" class="kirjaamaton-kortti mat-elevation-z1" *ngFor="let lasku of kirjaamattomatLaskutObservable | async" (click)="valitseLasku($event, lasku)" [class.kortti-valittu]="valittuLasku === lasku.lasku.avain">
        <div class="pvm-container">
          <div class="pvm">{{lasku.pvm}}</div>
          <div class="summa light-blue-text">{{lasku.summa}}</div>
        </div>
        <div class="explanation-container">
          <div>{{lasku.asiakas}}</div>
          <div>{{lasku.viitenumero}}</div>
        </div>
        <mat-spinner *ngIf="lasku.poistetaan else laskuvalinnat" [diameter]="22"></mat-spinner>
        <ng-template #laskuvalinnat>
          <button mat-icon-button [disabled]="lasku.tallennetaan" [matMenuTriggerFor]="naytettavaLaskuMenu" [matMenuTriggerData]="{naytettavaLasku: lasku}" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="valittuMaksutapa == 'jatkuvat'">
      <div draggable="true" (dragstart)="aloitaJatkuvanRaahaaminen($event, jatkuva)" (dragend)="lopetaRaahaaminen($event)" [class.korostettu]="jatkuva.kuitti?.kirjattavaKuitti?.korostettu || jatkuva.raahattava?.raahattava?.ko" class="kirjaamaton-kortti mat-elevation-z1" *ngFor="let jatkuva of jatkuvatTositteetObservable | async" (click)="valitseJatkuva($event, jatkuva)"
        [class.kortti-valittu]="valittuJatkuva === jatkuva.avain">
        <div class="pvm-container">
          <div class="pvm">{{jatkuva.pvm}}</div>
          <div class="summa light-blue-text">{{jatkuva.summa}}</div>
        </div>
        <div class="explanation-container">{{jatkuva.selite}}</div>
        <mat-spinner *ngIf="(jatkuva.kuitti?.poistetaan || jatkuva.raahattava?.poistetaan || jatkuva.kuitti?.tallennetaan || jatkuva.raahattava?.tallennetaan ) else jatkuvavalinnat" [diameter]="22"></mat-spinner>
        <ng-template #jatkuvavalinnat>
          <button mat-icon-button [matMenuTriggerFor]="naytettavaJatkuvaMenu" [matMenuTriggerData]="{naytettavaJatkuva: jatkuva}" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>

  </div>

</ng-template>