<div style="max-width: 1200px;">

  <!-- <form [formGroup]="form" novalidate class="ylaosan-kontrollit">

    <div class="lisaa-nappula-container">
      <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        {{'laskut.lisaa' | translate | async}}
      </button>
    </div>

    <div class="hakukontrollit">

      <mat-form-field floatPlaceholder="never">
        <mat-label>{{ 'laskut.nayta' | translate | async }}</mat-label>
<mat-select  formControlName="tila">
          <mat-option *ngFor="let tila of tilat" [value]="tila.tunnus">
            {{ tila.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'laskut.etsi' | translate | async }}</mat-label>
<input type="text" formControlName="nimiFirestore"  matInput />
      </mat-form-field>


      <mat-form-field>
        <mat-label>{{ 'laskut.rajaa-kuukauden-mukaan' | translate | async }}</mat-label>
<input matInput [matDatepicker]="dp"  formControlName="vuosikk">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>

    </div>

  </form> -->
  <!--
  <div *ngIf="suurinSumma" class="summatContainer">
    <div class="summat">
      <div>
        <div class="otsikko">{{'laskut.summat.avoinna' | translate | async}}</div>
        <div class="maara blue-text" counto [step]="16.667" [countTo]="suurinSumma.avoinna" [countFrom]="suurinSumma.avoinnaAlkuperainen" [duration]="1" (countoChange)="countoAvoinna = $event" (countoEnd)="onCountoEndAvoinna()">{{ countoAvoinna | lmoney: suurinSumma.valuutta | async}}</div>
      </div> -->
  <!-- <div>
        <div class="otsikko">Maksettu</div>
        <div class="maara green-text" counto [step]="16.667" [countTo]="suurinSumma.saatu" [countFrom]="suurinSumma.saatuAlkuperainen" [duration]="1" (countoChange)="countoSaatu = $event" (countoEnd)="onCountoEndSaatu()">{{countoSaatu | lmoney: suurinSumma.valuutta | async}}</div>
      </div> -->
  <!-- <div>
        <div class="otsikko">Erääntynyt</div>
        <div class="maara red-text"
        [class.red-text]    = "suurinSumma.eraantynyt != 0"
        [class.green-text]  = "suurinSumma.eraantynyt == 0"
        counto [step]="16.667" [countTo]="suurinSumma.eraantynyt" [countFrom]="suurinSumma.eraantynytAlkuperainen" [duration]="1" (countoChange)="countoEraantynyt = $event" (countoEnd)="onCountoEndEraantynyt()">{{countoEraantynyt | lmoney: suurinSumma.valuutta | async}}</div>
      </div> -->
  <!-- <div class="huomautukset">
        <span *ngIf="naytaKaikkiSummat" (click)="avaaKaikkiSummat()">{{'laskut.summat.kaikki' | translate | async}}</span>
        <span *ngIf="naytaHuomautus" style="margin-left: 2em;" (click)="avaaHuomautus()">{{'laskut.summat.huomautus' | translate | async}}</span>
      </div> -->
  <!-- </div>
  </div> -->

  <div style="position: relative;" #laskutaulu>
    <mat-table class="laskut-table" matSort [dataSource]="dataSource" [matSortDisableClear]="true" style="min-height: 160px;">

      <ng-container matColumnDef="nro">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.numero' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.nro}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asiakas">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.asiakas' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.asiakas}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="erapvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.erapaiva' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.erapvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lataa">
        <mat-header-cell *matHeaderCellDef>&nbsp;&nbsp;&nbsp;Lataa PDF </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-button class="mat-elevation-z4" style="margin: 0 10px;" (click)="lataaPdf(row); $event.stopPropagation();">
            <mat-icon style="height: 1.5rem; width: 1.85rem; margin-left: 10px;">cloud_download</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.laskun-pvm' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.pvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="summa">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.summa' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.summa | lmoney: row.valuutta | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="avoinna">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.avoinna' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="(row.tyyppi == 'v' || row.tyyppi == 't') && row.tila !== 'lu'">
            {{ row.avoinna | lmoney: row.valuutta | async }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tila">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.tila' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row" [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'">
          <ng-container *ngIf="row.lahetysEpaonnistui; then huutomerkki else tyhja">

          </ng-container>
          <ng-template #huutomerkki>
            <table style="border-spacing: 0;">
              <tr>
                <td [title]="'laskut.lahettaminen-epaonnistui' | translate | async" style="color: red; font-size: 32px; font-weight: bold; vertical-align: middle; padding-right: 4px;">
                  !
                </td>
                <td style="vertical-align: top;">
                  <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
                    {{'lasku.tila.' + row.tila | translate | async}}
                  </ng-container>
                </td>
              </tr>
            </table>
          </ng-template>
          <ng-template #tyhja>
            <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
              {{'lasku.tila.' + row.tila | translate | async}}
            </ng-container>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="merkitse">
        <mat-header-cell *matHeaderCellDef><span style="padding-left: 5px; padding-right: 10px;">{{'laskut.merkitse-maksetuksi' | translate | async}}</span></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-button [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'" [class.mat-elevation-z4]="row.avoinna > 0" class="maksetuksi-button"
            *ngIf="(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila != 'l' && row.tila != 'lu' && row.tila != 'mi' && row.tila != 'h'" (click)="merkitseMaksetuksi(row); $event.stopPropagation();">
            <mat-icon>done</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="propertiesToDisplay"></mat-header-row>
      <mat-row (click)="muokkaa(row)" *matRowDef="let row; columns: propertiesToDisplay" [class.lemon-lapsi]="row.tyyppi == 'l'" [class.lemon-lapsi-viimeinen]="row.tyyppi == 'lv'" [class.lemon-vanhempi]="row.tyyppi == 'v'"></mat-row>

    </mat-table>
    <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="!lataa && matDataSource.filteredData?.length == 0">
      <div style="width: 100%; padding-top: 40px; text-align: center;">
        {{'laskut.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
      </div>
    </div>
    <div lemon-loading [config]="loadingSettings" [show]="lataa"></div>
  </div>

  <!-- <mat-paginator
    #paginator
    [length]="dataSource?.rivienMaaraObservable | async"
    [pageSize]="dataSource?.page?.pageSize"
    [pageIndex]="dataSource?.page?.pageIndex"
    [pageSizeOptions]="[20, 50, 200]">
  </mat-paginator> -->

</div>