import { Component, OnInit, Inject } from '@angular/core'
import { Validators, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas, AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjauksienLuontityyppi, Kirjaus } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

export interface KirjausKopioiDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  maksutavat: AsiakkaanMaksutapa[]
  asiakas: Asiakas
}

interface Form {
  kirjauksenPvm: FormControl<Date>
  maksutapa: FormControl<string>
}

@Component({
  templateUrl: './kirjaus.kopioi.dialog.html'
})
export class KirjausKopioiDialog implements OnInit {

  form: FormGroup<Form>
  namename: string = 'assf' + Math.random()
  kaytettavatMaksutavat: AsiakkaanMaksutapa[]
  ensimmainenPaiva: Date = new Date(2015, 1, 1)
  viimeinenPaiva: Date = new Date()

  private _paivamaara: number = null
  private _maksutapa: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausKopioiDialogData,
    private _dialogRef: MatDialogRef<KirjausKopioiDialog, Kirjaus>,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _copyService: KopioijaPalvelu,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _firebase: FirebaseLemonator,
  ) {

    this.kaytettavatMaksutavat = this.data.maksutavat?.filter(mt => {
      return mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE ||
        mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE
    }) || []

    const asDate = this._dateService.numberToDate(this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p)
    this.form = new FormGroup<Form>({
      'kirjauksenPvm': new FormControl<Date>({ value: asDate, disabled: false }, [Validators.required]),
      'maksutapa': new FormControl<string>({ value: this.data.naytettavaKirjaus.kirjaus.maksutavanTunniste, disabled: false }, [Validators.required])
    })
    this.form.get('kirjauksenPvm').valueChanges.subscribe(value => {
      this._paivamaara = this._dateService.localDateToNumber(this._dateService.dateToLocalDate(value))
    })
    this.form.get('maksutapa').valueChanges.subscribe(value => {
      this._maksutapa = value
    })

    const ensimmainenTilikausi = this._asiakasJaettuService.annaEnsimmainenTilikausi(data.asiakas)
    if (ensimmainenTilikausi) {
      this.ensimmainenPaiva = this._dateService.localDateToDate(ensimmainenTilikausi.alkaa)
    }

    const viimeinenTilikausi = this._asiakasJaettuService.annaViimeinenTilikausi(data.asiakas)
    if (viimeinenTilikausi) {
      this.viimeinenPaiva = this._dateService.localDateToDate(viimeinenTilikausi.loppuu)
    }

  }

  ngOnInit() {

  }

  kopioi() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const copy: Kirjaus = this._copyService.cloneObjectDeep(this.data.naytettavaKirjaus.kirjaus)
    copy.avain = this._firebase.firestoreCreateId()

    for (const rivi of copy.rivit ?? []) {
      rivi.av = this._firebase.firestoreCreateId()
    }

    if (this._paivamaara && copy.p !== this._paivamaara) {
      copy.p = this._paivamaara
    }

    if (this._maksutapa && copy.maksutavanTunniste !== this._maksutapa) {
      copy.maksutavanTunniste = this._maksutapa
    }

    this._dialogRef.close(copy)

  }

}
