import { Timestamp } from '../../_shared-core/model/common'

// eslint-disable-next-line no-shadow
export enum EraajotYksiKerrallaanSuorittajaTyyppi {
  JATKUVA_LASKU_LEMONATOR = 'jll',
  AUTOMAATTIRESKONTRA = 'ar',
  KIRJANPITO_HOLVI_IMPORT = 'khi',
  YTJ_DATA_SYNC = 'ytj',
  HOLVI_API_IMPORT = 'hai',
  APIX_SAHKOISTEN_LASKUJEN_TUONTI = 'apx',
  PALKKAUSFI_AINEISTOT_IMPORT = 'pai',
  OMAVERO_TAPAHTUMIEN_HAKU = 'oth',
  OMAVERO_YHTEISOMYYNTILASKUT = 'oyl',
  BANK_BALANCE_CALC = 'bbc'
}
export interface EraajotYksiKerrallaanSuorittajaData {
  luotu: Timestamp
  asiakasAvain: string
  asiakasId: string
  driverAvain: string
  driverInvocations?: number
  driverInvocationStartTimes?: string[]
  errors?: string[]
  valmistumispvm?: number
  tyyppi: EraajotYksiKerrallaanSuorittajaTyyppi
}
